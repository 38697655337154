import api from "api";
import schemaBlocks from "entities/blockPages/schema";
import {normalize} from "normalizr";
import _ from 'lodash';

import * as pagesActions from "../pages/actions";
import * as questionsActions from "../questions/actions";
import * as answerActions from "../answers/actions";
import * as rowActions from "../rows/actions";
import * as columnActions from "../columns/actions";
import * as excludingsActions from "../excludings/actions";
import * as setsActions from "../sets/actions";
import * as surveyActions from "../survey/actions";
import * as filtersActions from "../filters/actions";
import * as pipingsActions from "../pipings/actions";
import * as filterSerialActions from "../filterSerial/actions";
import schemaSurvey from "entities/survey/schema";
import * as basketActions from "../baskets/actions";
import * as scalePointsActions from "../scalePoints/actions";
import * as javascriptActions from "../javascripts/actions";
import * as filterIterativeActions from "../filterIterative/actions";
import {setSurvey} from "../survey/actions";
import schemaQuestions from "../../../../../entities/question/schema";
import * as answersActions from "../answers/actions";
import * as basketsActions from "../baskets/actions";
import * as rowsActions from "../rows/actions";
import * as columnsActions from "../columns/actions";
import {questionAdded} from "../questions/actions";

export const SET = 'Survey/data/blocks/SET';
export const ADDED = 'Survey/data/blocks/ADDED';
export const DELETED = 'Survey/data/blocks/DELETED';
export const UPDATED = 'Survey/data/blocks/UPDATED';
export const SET_PAGES = 'Survey/data/blocks/SET_PAGES';
export const PAGE_ADDED = 'Survey/data/blocks/PAGE_ADDED';
export const PAGE_BUTTONS_HIDDEN = 'Survey/data/blocks/PAGE_BUTTONS_HIDDEN';

export function setPages(id, pages) {
  return {
    type: SET_PAGES,
    data: {
      id,
      pages
    }
  }
}

export function setBlocks(blocks) {
  return {
    type: SET,
    blocks
  }
}
export function blockAdded(block) {
  return {
    type: ADDED,
    block
  }
}
export function blockDeleted(block) {
  return {
    type: DELETED,
    block
  }
}
export function blockUpdated(block) {
  return {
    type: UPDATED,
    block
  }
}
export function pageAdded(page) {
  return {
    type: PAGE_ADDED,
    page
  }
}

export function blockPagesButtonsHidden(block) {
  return {
    type: PAGE_BUTTONS_HIDDEN,
    block
  }
}

export function addBlock(data) {
  return dispatch => {
    return api.setBlocks.post.block(data).then((block) => {
      dispatch(blockAdded(block));
      dispatch(setsActions.blockAdded(block));

      return block;
    })
  }
}

export function deleteBlock(data) {
  return dispatch => {
    return api.blockPages.delete(data).then((res) => {
      dispatch(blockDeleted({
        id: data.id,
        set: {
          id: res.block.setBlocks.id
        }
      }));
    });
  }
}

export function updateBlock(data) {
  return dispatch => {
    return api.blockPages.patch(data).then(block => {
      const normalized = normalize(block, schemaBlocks);
      dispatch(blockUpdated(normalized.entities.blocks[normalized.result]));

      return block;
    })
  }
}

export function updateBlockPagesButton(data) {
  return dispatch => {
    return api.blockPages.post.buttons(data).then(block => {
      const normalized = normalize(block, schemaBlocks);

      _.each(normalized.entities.pages, page => {
        dispatch(pagesActions.pageUpdated(page));
      });

      dispatch(blockPagesButtonsHidden(normalized.entities.blocks[normalized.result]));

      return block;
    });
  }
}

export function copyBlock(data) {
  return dispatch => {
    return api.blockPages.post.copy(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(scalePointsActions.setScalePoints(normalized.entities.scalePoints || {}));
      dispatch(javascriptActions.setJavascripts(normalized.entities.javascripts || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(pagesActions.setPages(normalized.entities.pages || {}));
      dispatch(setBlocks(normalized.entities.blocks || {}));
      dispatch(setsActions.setSets(normalized.entities.sets || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(filterIterativeActions.setFilterIterative(normalized.entities.filterIterative || {}));
      dispatch(setSurvey(survey));
    });
  }
}

export function autoPaginateBlock(data) {
  return dispatch => {
    return api.blockPages.patchAutoPaginate(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(pagesActions.setPages(normalized.entities.pages || {}));
      dispatch(setBlocks(normalized.entities.blocks || {}));
      dispatch(setsActions.setSets(normalized.entities.sets || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(surveyActions.setSurvey(survey));
    })
  }
}

export function removePageBreaks(data) {
  return dispatch => {
    return api.blockPages.removePageBreaks(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(surveyActions.setSurvey(survey));
      dispatch(setsActions.setSets(normalized.entities.sets || {}));
      dispatch(setBlocks(normalized.entities.blocks || {}));
      dispatch(pagesActions.setPages(normalized.entities.pages || {}));
    })
  }
}
export function addBlockFromTemplates(data) {
  return dispatch => {
    return api.blockPages.post.fromTemplates(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(scalePointsActions.setScalePoints(normalized.entities.scalePoints || {}));
      dispatch(javascriptActions.setJavascripts(normalized.entities.javascripts || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(pagesActions.setPages(normalized.entities.pages || {}));
      dispatch(setBlocks(normalized.entities.blocks || {}));
      dispatch(setsActions.setSets(normalized.entities.sets || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(filterIterativeActions.setFilterIterative(normalized.entities.filterIterative || {}));
      dispatch(setSurvey(survey));
    })
  }
}