import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Nav, NavItem, TabContent} from "reactstrap";
import TabTemplate from "./Tabs/TabTemplate";
import TabSummary from "./Tabs/TabSummary";
import TabAddresses from "./Tabs/TabAddresses";
import TabSettings from "./Tabs/TabSettings";
import TabSentHistory from "./Tabs/TabSentHistory";
import appRoutes from "appRoutes";
import {Route, Switch, NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import CardBody from "reactstrap/es/CardBody";
import SurveyCollectorValidity from '../SurveyCollectorValidity';
import ButtonSendPaused from "./ButtonSendPaused";
import EmailTestSend from "../EmailTestSend";

class CollectorEmail extends Component {

  render() {
    const {params} = this.props.match;

    return (
      <CardBody className="px-0 pb-0">
        <SurveyCollectorValidity collector={this.props.collector} />

        <div className="d-flex justify-content-end">
          <EmailTestSend collector={this.props.collector} />
          <ButtonSendPaused collector={this.props.collector} handleUpdate={this.props.handleUpdate}/>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink className="nav-link" exact to={appRoutes.survey.collectors.collector.view(params.survey, params.collector)}>
              <i className="fas fa-poll" />
              <FormattedMessage id={'surveyCollector.typeEmail.tabs.summary'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" exact to={appRoutes.survey.collectors.collector.email.sendEmail(params.survey, params.collector)}>
              <i className="fas fa-scroll" />
              <FormattedMessage id={'surveyCollector.typeEmail.tabs.template'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" exact to={appRoutes.survey.collectors.collector.email.respondents(params.survey, params.collector)}>
              <i className="fas fa-users" />
              <FormattedMessage id={'surveyCollector.typeEmail.tabs.addresses'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" exact to={appRoutes.survey.collectors.collector.email.settings(params.survey, params.collector)}>
              <i className="fas fa-cog" />
              <FormattedMessage id={'surveyCollector.typeEmail.tabs.settings'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" exact to={appRoutes.survey.collectors.collector.email.sentHistory(params.survey, params.collector)}>
              <i className="fas fa-history" />
              <FormattedMessage id={'surveyCollector.typeEmail.tabs.sentHistory'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="tab-body">
          <Switch>
            <Route path={appRoutes.survey.collectors.collector.view()} exact component={() => <TabSummary collector={this.props.collector}/>} />
            <Route path={appRoutes.survey.collectors.collector.email.sendEmail()} exact component={() => <TabTemplate collector={this.props.collector} handleUpdate={this.props.handleUpdate}/>} />
            <Route path={appRoutes.survey.collectors.collector.email.respondents()} exact component={() => <TabAddresses collector={this.props.collector} surveyId={params.survey}/>} />
            <Route path={appRoutes.survey.collectors.collector.email.settings()} exact component={() => <TabSettings collector={this.props.collector} handleUpdate={this.props.handleUpdate}/>} />
            <Route path={appRoutes.survey.collectors.collector.email.sentHistory()} exact component={() => <TabSentHistory collector={this.props.collector}/>} />
          </Switch>
        </TabContent>
      </CardBody>
    )
  }
}

CollectorEmail.propTypes = {
  collector: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      survey: PropTypes.string.isRequired,
      collector: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CollectorEmail;