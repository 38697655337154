import React from 'react';
import PropTypes from 'prop-types';
import Select from "../componentsReusable/Select";
import useDidMountEffect from "../utils/useDidMountEffect";
import api from "../api";
import {FormattedMessage} from "react-intl";

const BanCategorySelect = props => {
  const [options, setOptions] = React.useState([]);

  useDidMountEffect(() => {
    api.ban.get.categories().then(categories => {
      setOptions(categories.map(categoryId => ({
        label: <FormattedMessage id={"admin.respondents.ban.category-" + categoryId} />,
        value: categoryId
      })))
    })
  })

  return (
    <Select
      isClearable
      placeholder="Powód zbanowania"
      options={options}
      onChange={props.onChange}
    />
  );
};

BanCategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default BanCategorySelect;
