import React, {Component} from 'react';
import {Link, Route, Switch} from "react-router-dom";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import appRoutes from "../../../appRoutes";
import SwresearchCmsTable from "./components/SwresearchCmsTable";
import SwresearchCmsForm from "./components/SwresearchCmsForm";
import SwresearchCmsNav from "./components/SwresearchCmsNav";

class SwresearchCms extends Component {
  render() {
    return <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="float-left mr-4">Swresearch CMS</h2>

              <div>
                <Link to={appRoutes.admin.cms.swresearch.navEdit(1)}>Edytuj górną nawigacje</Link>
                <Link className="ml-3" to={appRoutes.admin.cms.swresearch.navEdit(2)}>Edytuj dolną nawigacje</Link>
              </div>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <Row>
              <Col>
                <Switch>
                  <Route path={appRoutes.admin.cms.swresearch.navEdit()} children={<SwresearchCmsNav />} />
                  <Route path={appRoutes.admin.cms.swresearch.list} children={<SwresearchCmsTable />} />
                  <Route path={appRoutes.admin.cms.swresearch.add} children={<SwresearchCmsForm />} />
                  <Route path={appRoutes.admin.cms.swresearch.edit()} children={<SwresearchCmsForm />} />
                </Switch>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>;
  }
}

SwresearchCms.propTypes = {};

export default SwresearchCms;
