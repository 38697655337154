import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input} from "reactstrap";
import {injectIntl} from "react-intl";
import QuestionAnswers from "../../../../Modals/QuestionAnswers";
import {v4 as uuidv4} from "uuid";
import Tooltip from "../../../../../../../../../components/Tooltip";

const detailTypes = ['equals', 'notEquals', 'greaterThenOrEquals', 'lesserThenOrEquals'];

class NumericMulti extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isNumericMultiDropdownDetailType: false,
      inputTooltipId: 'x' + uuidv4(),
    };

    this.toggleIsNumericMultiDropdownDetailType = this.toggleIsNumericMultiDropdownDetailType.bind(this);
  }

  componentWillMount() {
    const {condQuestion} = this.props;
    let condQuestionChanged = condQuestion;

    if(_.isUndefined(condQuestion.detailType) || !detailTypes.includes(condQuestion.detailType)){ condQuestionChanged.detailType = detailTypes[0]; }
    if(_.isUndefined(condQuestion.value)){ condQuestionChanged.value = ''; }
    if(_.isUndefined(condQuestion.answers)){ condQuestionChanged.answers = []; }

    if(condQuestion !== condQuestionChanged){
      NumericMulti.propTypes.handleCondChanged(condQuestionChanged);
    }
  }

  toggleIsNumericMultiDropdownDetailType(){
    this.setState({
      isNumericMultiDropdownDetailType: !this.state.isNumericMultiDropdownDetailType,
    })
  }

  render() {
    const {isNumericMultiDropdownDetailType} = this.state;
    const {intl, condQuestion, changeable} = this.props;

    return (
      <React.Fragment>
        <span>wprowadzi wartość </span>

        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isNumericMultiDropdownDetailType} toggle={this.toggleIsNumericMultiDropdownDetailType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.NumericMulti.detailType-' + condQuestion.detailType})}</DropdownToggle>
          <DropdownMenu>
            {detailTypes.map(detailType => <DropdownItem tag="a" key={detailType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              detailType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.NumericMulti.detailType-' + detailType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condDetail.NumericMulti.detailType-' + condQuestion.detailType})} </span>}

        {changeable ? <React.Fragment>
          <i id={this.state.inputTooltipId} className="fas fa-info-circle ml-1 text-warning" />
          <Tooltip msg={'Uwaga. Jeśli pytanie numeryczne lub z suwakiem zawiera miejsca po przecinku, aby ustawić filtr uwzględniający miejsca po przecinku wymień wszystkie cyfry. Np. 12,34 - ustaw 1234,   12345,1 - ustaw 123451'} id={this.state.inputTooltipId} />
          <Input
            className="w-25 d-inline ml-2"
            bsSize="xs"
            value={condQuestion.value} onChange={e => this.props.handleCondChanged({
            ...condQuestion,
            value: e.target.value,
          })} />
        </React.Fragment> : condQuestion.value}

        <span className="mx-1">w odpowiedziach</span>

        <QuestionAnswers
          answers={condQuestion.answers.map(answer => answer.answer)}
          question={condQuestion.question}
          changeable={changeable}
          handleAnswersChanged={answersArray => {
            let answers = answersArray.map(answer => {
              return {answer}
            });
            this.props.handleCondChanged({...condQuestion, answers});
          }}
        />

      </React.Fragment>
    );
  }
}

NumericMulti.defaultProps = {
  changeable: true
};

NumericMulti.propTypes = {
  condQuestion: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({
      answer: PropTypes.number.isRequired,
    })),
    question: PropTypes.number.isRequired,
    detailType: PropTypes.oneOf(detailTypes),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(NumericMulti);
