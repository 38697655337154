import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Tooltip, Button} from "reactstrap";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";


class WarningIcon extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
    };

    this.getWarnings = this.getWarnings.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  getWarnings(){
    const {survey} = this.props;

    let warnings = [];

    //warning if exists collectors type Panel
    // if(_.map(survey.collectors, collector => collector).filter(collector => ['Panel'].indexOf(collector.type) >= 0).length > 0){
    //   warnings.push(<Link key={warnings.length} to={appRoutes.survey.collectors.listType(survey.id, 'Panel')}>Kolektory Panel</Link>)
    // }

    return warnings;
  }

  render() {
    const {survey} = this.props;
    const {tooltip} = this.state;

    const warnings = this.getWarnings();

    if(warnings.length === 0){
      return null;
    }

    return (
      <Button color="" className={"m-0 p-0 " + this.props.className}>
        <i id={"tooltip-warning-icon-" + this.props.targetId + "-" + survey.id} className="fas fa-exclamation-triangle text-warning" />
        <Tooltip
          placement="bottom"
          autohide={false}
          isOpen={tooltip}
          target={"tooltip-warning-icon-" + this.props.targetId + "-" + survey.id}
          toggle={() => this.toggleTooltip()}
        >
          {warnings}
        </Tooltip>
      </Button>
    );
  }
}

WarningIcon.defaultProps = {
  className: '',
};

WarningIcon.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    collectors: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,),
  }).isRequired,
  className: PropTypes.string,
  targetId: PropTypes.string.isRequired,
};

export default WarningIcon;