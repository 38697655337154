import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Alert, Card, CardBody, CardFooter, CardHeader, Col, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {connect} from "react-redux";
import {updateSurvey} from "../../data/survey/actions";
import Button from "../../../../../components/Button";
import classnames from "classnames";

class SurveyTexts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      saving: false,
      data: {
        buttonPause: props.survey.buttonPause || '',
        buttonPrev: props.survey.buttonPrev || '',
        buttonClear: props.survey.buttonClear || '',
        buttonNext: props.survey.buttonNext || '',
        buttonEnd: props.survey.buttonEnd || '',
        buttonStart: props.survey.buttonStart || '',
        buttonQuestionPrevStatement: props.survey.buttonQuestionPrevStatement || '',
        buttonQuestionNextStatement: props.survey.buttonQuestionNextStatement || '',
      },
    };

    this.updateSurvey = this.updateSurvey.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen(){
    this.setState({
      open: !this.state.open,
    })
  }

  updateSurvey() {
    const {data} = this.state;

    this.setState({
      saving: true,
      errors: {},
    });

    this.props.updateSurvey({
      id: this.props.surveyId,
      survey: data
    }).then(survey => {
      this.setState({
        saving: false,
      });
    }).catch(res => {
      this.setState({
        saving: false,
      });
    });
  }

  render() {
    const {data, saving, open} = this.state;

    const buttons = [
      {key: 'buttonPrev', name: 'Poprzednia strona'},
      {key: 'buttonClear', name: 'Wyczyść odpowiedzi'},
      {key: 'buttonPause', name: 'Wstrzymanie wypełniania'},
      {key: 'buttonStart', name: 'Rozpoczęcie ankiety'},
      {key: 'buttonNext', name: 'Następna strona'},
      {key: 'buttonEnd', name: 'Zakończenie ankiety'},
      {key: 'buttonQuestionPrevStatement', name: 'Poprzednie stwierdzenie'},
      {key: 'buttonQuestionNextStatement', name: 'Następne stwierdzenie'},
    ];

    return (
      <Card className="border-l blue d-print-none">
        <CardHeader>
          <div className="float-left" data-toggle="collapse" data-target="#collapse2">
            <h4 className="float-left pointer" onClick={this.toggleOpen}>Treści przycisków w ankiecie <i className={classnames({
              'fas ml-2': true,
              'fa-chevron-down': !open,
              'fa-chevron-up': open,
            })} /> </h4>
          </div>
        </CardHeader>
        <hr className="my-0" />
        {this.props.user.userPlan.plan.survey.textsOverride && <div>

        </div>}
        {open && <CardBody>
          {this.props.user.userPlan.plan.survey.textsOverride
            ? <div className="d-flex flex-wrap justify-content-xl-start flex-column flex-lg-row">
                {buttons.map(button => {
                  return <div className="d-flex flex-column mx-2">
                    <small className="mb-2 mx-auto">{button.name}</small>
                    <Input value={data[button.key]} placeholder="Domyślna treść" onChange={e => this.setState({data: {...data, [button.key]: e.target.value}})} />
                  </div>
                })}
              </div>
            : <Alert color="warning" className="text-center">W Twoim planie nie masz możliwości zmieniać treści nawigacji ankiety</Alert>}
        </CardBody>}
        {open && this.props.user.userPlan.plan.survey.textsOverride && <CardFooter>
          <Button
            className={['ml-auto d-block']}
            onClick={this.updateSurvey}
            loading={saving}
          >Zapisz</Button>
        </CardFooter>}
      </Card>    );
  }
}

SurveyTexts.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    buttonPause: PropTypes.string,
    buttonPrev: PropTypes.string,
    buttonClear: PropTypes.string,
    buttonNext: PropTypes.string,
    buttonEnd: PropTypes.string,
    buttonStart: PropTypes.string,
    buttonQuestionPrevStatement: PropTypes.string,
    buttonQuestionNextStatement: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
    user: state.user,
  }
}

export default connect(mapStateToProps, {updateSurvey})(SurveyTexts);
