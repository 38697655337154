import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row} from 'reactstrap';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {addQuestionFromTemplate} from "scenes/Survey/Structure/data/questions/actions";
import Select from 'react-select';
import SelectQuestions from "scenes/Survey/Structure/components/SelectQuestions";

class TemplatesModal extends Component{

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      question: null,
      shift: {value: 0, label: <FormattedMessage id="templates.templatesModal.select.before"/>}
    };

    this.onShiftChange = this.onShiftChange.bind(this);
    this.onQuestionChange = this.onQuestionChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onShiftChange(select) {
    this.setState({
      shift: select
    });
  }

  onQuestionChange(questionId) {
    this.setState({
      question: questionId
    });
  }

  onSubmit() {
    let pageId;

    this.setState({...this.state, saving: true});

    if(this.state.question){
      _.each(this.props.pages, page => {
        if (page.questions.indexOf(this.state.question) > -1) {
          pageId = page.id;
          return false;
        }
      })
    }else{
      pageId = Object.values(this.props.pages)[0].id;
    }

    this.props.addQuestionFromTemplate({
      templates: this.props.questionTemplates,
      page: pageId,
      position: this.state.question ? (this.props.questions[this.state.question].position + this.state.shift.value) : 0,
    })
    .then(questions => {
      this.props.toggleModal()
    })
    .catch(res => {
      this.props.toggleModal()
    });
  }

  render() {
    return (
      <Modal isOpen toggle={this.props.toggleModal} className={'modal-danger'} size={'lg'}>
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="templates.templatesModal.modalHeader" /></ModalHeader>
        <hr className="m-0" />
        <ModalBody>
          {_.isEmpty(this.props.questions) && <Row className="mt-2">
            <Col>
              <div className="text-center py-3">Wstaw na początku ankiety</div>
            </Col>
          </Row>}

          {!_.isEmpty(this.props.questions) && <React.Fragment>
            <Row>
              <Col lg={3}>
                <FormattedMessage id="templates.templatesModal.label.shift"/>
              </Col>
              <Col lg={9}>
                <Select
                  clearable={false}
                  value={this.state.shift}
                  options={[
                    {value: 0, label: <FormattedMessage id="templates.templatesModal.select.before"/>},
                    {value: 1, label: <FormattedMessage id="templates.templatesModal.select.after"/>},
                  ]}
                  onChange={this.onShiftChange}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={3}>
                <FormattedMessage id="templates.templatesModal.label.question"/>
              </Col>
              <Col lg={9}>
                <SelectQuestions isMulti={false} value={this.state.question} onChange={this.onQuestionChange}/>
              </Col>
            </Row>
          </React.Fragment>}
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggleModal}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda"
              loading={this.state.saving}
              data-style={ZOOM_OUT}
              onClick={this.onSubmit}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    )
  }
}

TemplatesModal.propTypes = {
  questionTemplates: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  toggleModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    questions: state.survey.structure.data.questions,
    pages: state.survey.structure.data.pages
  }
}

export default connect(mapStateToProps, {addQuestionFromTemplate})(TemplatesModal);