import React, {PureComponent} from 'react';
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import AsyncSelect from 'react-select/lib/Async';
import api from "../../../api";
import LoadingSpinner from "../../LoadingSpinner";
import moment from "moment";


class SurveysSelectModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchTimeout: null,
      mapping: false,
      modalOpen: false,
      loading: false,
      values: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.fetchSurveys = this.fetchSurveys.bind(this);
  }

  getLabel(survey){
    return {
      value: survey.id,
      label: <div className="w-100">
        <p className="mb-0">{survey.name}</p>
        <small className="mb-0 text-muted">{survey.id}, {moment(survey.startAt).format('YYYY-MM-DD')} - {moment(survey.endAt).format('YYYY-MM-DD')}</small>
      </div>,
    }
  }

  mapPropsValuesToState(){
    if(_.isEmpty(this.props.values)){
      this.setState({
        values: []
      });
    }else{
      this.setState({
        mapping: true
      });

      api.user.post.searchSurveysAll({
        search: '',
        page: 1,
        pageSize: 999999,
        filters: {
          surveys: this.props.values.join(',')
        }
      })
      .then(data => {
        this.setState({
          mapping: false,
          values: data.surveys.map(survey => this.getLabel(survey))
        })
      })
      .catch(() => {
        this.setState({
          mapping: false,
        });
      });
    }
  }

  fetchSurveys(search, callback){
    clearTimeout(this.state.searchTimeout);

    this.setState({
      searchTimeout: setTimeout(() => {
        api.user.post.searchSurveysAll({
          search,
          page: 1,
          pageSize: 20,
        })
        .then(data => {
          callback(data.surveys.map(survey => this.getLabel(survey)));
        })
      }, 1000)
    });
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    }, () => {
      if(this.state.modalOpen){
        setTimeout(() => {
          this.mapPropsValuesToState()
        }, 1)
      }
    })
  }

  onSubmit(){
    if(this.props.onAccept){
      this.props.onAccept(this.state.values.map(value => value.value));
    }

    this.toggleModal();
  }

  render() {
    const {modalOpen, loading, values, mapping} = this.state;

    return <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal}>Lista ankiet</ModalHeader>
      <ModalBody>
        {mapping
          ? <LoadingSpinner width={20} classNames="my-0" />
          : <AsyncSelect
              isMulti
              cacheOptions
              loadOptions={this.fetchSurveys}
              onChange={values => this.setState({values})}
              value={values}
            />
        }
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={loading}
            data-style={ZOOM_OUT}
            onClick={this.onSubmit}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;
  }
}

SurveysSelectModal.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  onAccept: PropTypes.func,
};

export default SurveysSelectModal;