import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Col, Label} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import { connect } from 'react-redux';
import {addPage} from '../../../../data/pages/actions';
import Select from "react-select";
import SelectPages from "../../../SelectPages";
import _ from "lodash";

class PageModal extends Component {

  constructor(props) {
    super(props);

    let all = document.querySelectorAll("[data-type='pageCard']");
    let pageId;

    for (let i = 0; i < all.length; i++) {
      if (!all[i + 1]) {
        pageId = parseInt(all[i].getAttribute('data-id'));
        break;
      }

      let nextRect = all[i + 1].getBoundingClientRect();

      if (nextRect.top - nextRect.height - 80 > 0) {
        pageId = parseInt(all[i].getAttribute('data-id'));
        break;
      }
    }

    if (all.length === 0 && Object.keys(props.pages).length > 0) {
      pageId = _.sortBy(props.pages, page => page.position)[0].id;
    }

    this.state = {
      loading: false,
      shift: { value: 0, label: <FormattedMessage id="createElementButton.pageModal.label.shift.before"/>},
      page: Object.keys(props.pages).length > 0 ? pageId : null,
    };

    this.addPage = this.addPage.bind(this);
  }

  addPage() {
    this.setState({loading: true});

    const block = this.state.page
      ? _.find(this.props.blocks, block => block.pages.includes(this.state.page))
      : _.sortBy(this.props.blocks, block => block.position)[0];

    const position = this.state.page
      ? _.find(this.props.pages, page => page.id === this.state.page).position + this.state.shift.value
      : 0;

    const data = {
      block: block.id,
      page: {
        position: position
      }
    };

    this.props.addPage(data).then(() => {
      this.setState({loading: false});
      this.props.toggle();
    })
  }

  render() {
    const hasBlocks = Object.keys(this.props.blocks).length > 0;

    return (
      <Modal size="xl" isOpen={true} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}><FormattedMessage id="createElementButton.pageModal.modalHeader" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          {!hasBlocks ? <p><FormattedMessage id="createElementButton.pageModal.modalBody.noBlocks" /></p> : <React.Fragment>
            {!this.state.page ? <p><FormattedMessage id="createElementButton.pageModal.modalBody.firstPage"/></p> : <React.Fragment>
              <Row>
                <Col lg={3}>
                  <Label for="shift"><FormattedMessage id="createElementButton.pageModal.label.shift" /></Label>
                </Col>
                <Col lg={9}>
                  <Select
                    clearable={false}
                    value={this.state.shift}
                    options={[
                      { value: 0, label: <FormattedMessage id="createElementButton.pageModal.label.shift.before" />},
                      { value: 1, label: <FormattedMessage id="createElementButton.pageModal.label.shift.after" />},
                    ]}
                    onChange={shift => this.setState({shift: shift})}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={3}>
                  <Label for="shift"><FormattedMessage id="createElementButton.pageModal.label.page" /></Label>
                </Col>
                <Col lg={9}>
                  <SelectPages
                    isMulti={false}
                    onChange={page => this.setState({page})}
                    value={this.state.page}
                  />
                </Col>
              </Row>
            </React.Fragment>}
          </React.Fragment>}
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.add">{
            (message) => <LaddaButton
              className="btn btn-success btn-ladda"
              data-style={ZOOM_OUT}
              loading={this.state.loading}
              onClick={this.addPage}
              disabled={!hasBlocks}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggle}>{message}</Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    )
  }
}

PageModal.propTypes = {
  toggle: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  let blocks = [];
  let pages = [];

  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    let set = state.survey.structure.data.sets[setId];

    _.each(set.blockPages, blockId => {
      blocks.push(state.survey.structure.data.blocks[blockId]);

      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        pages.push(state.survey.structure.data.pages[pageId]);
      });
    });
  });

  return {
    blocks,
    pages
  }
}

export default connect(mapStateToProps, {addPage})(PageModal);