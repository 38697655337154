import React, {useState} from 'react';

export default () => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="w-100 bg-warning py-2 text-center">
      <p className="mb-0">
        <span>Aktualna wersja: 0.12</span>
        <span className="ml-4">Ostatni update: 12:00 04.10.2019</span>
        <a className="ml-4" onClick={() => setShowDetails(!showDetails)}>Zobacz więcej</a>
      </p>
      <div className={"mt-3 " + (showDetails ? "d-block" : "d-none")} style={{fontSize: '18px'}}>
        <p style={{fontSize: '18px'}}>Witamy w wersji testowej nowej odsłony serwisu ankietowego Ankieteo.  W tym miejscu publikujemy informacje o zmianach, które są dodawane do systemu.</p>

        <p style={{fontSize: '18px'}}>Pamiętaj, że:</p>
        <ol style={{fontSize: '18px'}}>
          <li>- Cały czas pracujmy nad systemem i projekty, które utworzysz w wersji testowej nie będą zachowane</li>
          <li>- W każdej chwili możesz zgłosić nam błąd w systemie lub propozycję funkcjonalności</li>
          <li>- Jeśli posiadasz konto w aktualnej wersji ankieteo, wszystkie Twoje projekty zostaną przeniesione do nowej wersji tuż przed publikacją stabilnej wersji systemu (1.0).</li>
          <li>- Trwają prace nad dokumentacją i helpem dla nowej wersji ankieteo, dlatego liczymy również na uwagi odnośnie intyicyjności interfejsu.</li>
        </ol>

        <p style={{fontSize: '18px'}}>Najważniejsze zmiany w wersji 0.12:</p>
        <ul style={{fontSize: '18px'}}>
          <li>Nowy, uproszczony sposób publikacji ankiet w bazie ankiet swpanel</li>
          <li>Poprawki w wyświetlaniu wykresów</li>
          <li>Poprawione obcojęzyczne wersje językowe ankiety</li>
          <li>ulubione typy pytań</li>
        </ul>
      </div>
    </div>
  )
}