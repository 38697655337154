import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {fetchPrizes, updatePrize} from "../actions";
import {connect} from "react-redux";
import PrizeForm from './PrizeForm';
import LoadingSpinner from "components/LoadingSpinner";

class PrizeEdit extends Component {

  componentDidMount(){
    this.props.fetchPrizes();
  }

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          {!this.props.prize
            ? <LoadingSpinner width={20} classNames="my-0" />
            : <Card>
              <CardHeader><FormattedMessage id="admin.prizeEdit.cardHeader" />: {this.props.prize.name}</CardHeader>
              <hr className="my-0" />
              <CardBody>
                <Row>
                  <Col>
                    <PrizeForm prizes={this.props.prizes} handleSave={this.props.updatePrize} prize={this.props.prize} />
                  </Col>
                </Row>
              </CardBody>
            </Card>}
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    prizes: state.admin.prizes.data.prizes,
    prize: state.admin.prizes.data.prizes[props.match.params.prize]
  }
}

export default connect(mapStateToProps, {fetchPrizes, updatePrize})(PrizeEdit);