import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Tooltip, Input, InputGroup, Button, InputGroupAddon} from "reactstrap";
import Select from 'react-select';
import classnames from "classnames";

class VideoPlayerDimensions extends Component {

  constructor(props) {
    super(props);
    const isCustom = !_.find([
      {playerWidth: 540, playerHeight: 315},
      {playerWidth: 640, playerHeight: 360},
      {playerWidth: 853, playerHeight: 480},
      {playerWidth: 1280, playerHeight: 720}
    ], dimensions => dimensions.playerWidth === props.question.playerWidth && dimensions.playerHeight === props.question.playerHeight);

    this.state = {
      question: {
        playerWidth: props.question.playerWidth,
        playerHeight: props.question.playerHeight
      },
      isCustom: !!isCustom,
      dimensionsTooltip: false,
      widthTooltip: false,
      heightTooltip: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(name) {
    this.setState({
      [name]: !this.state[name]
    });
  }

  onChange(e) {
    this.setState({
      ...this.state,
      question: {
        ...this.state.question,
        [e.target.name]: e.target.value,
      },
      errors: {}
    });
  }


  onSelectChange(e) {
    if (e.value === "Custom") {
      this.setState({isCustom: true});
    } else {
      this.setState({
        question: e.value,
        isCustom: false
      }, () => {
        this.props.handleUpdate(this.state.question)
      });
    }
  }

  render() {
    let { question, dimensionsTooltip, widthTooltip, heightTooltip, isCustom } = this.state;
    const options = [
      {label: "540x315", value: {playerWidth: 540, playerHeight: 315}},
      {label: "640x360", value: {playerWidth: 640, playerHeight: 360}},
      {label: "853x480", value: {playerWidth: 853, playerHeight: 480}},
      {label: "1280x720", value: {playerWidth: 1280, playerHeight: 720}},
      {label: "Własne", value: "Custom"}
    ];

    let value = isCustom
      ? {label: "Custom", value: "Custom"}
      : {label: question.playerWidth + "x" + question.playerHeight, value: {playerWidth: question.playerWidth, playerHeight: question.playerHeight}};

    return (<React.Fragment>
      <InputGroup className="min-w-0 float-left mr-1 w-100 w-lg-auto mb-1" id={"dimensions-" + this.props.question.id}>
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon active": true,
            })}
            color="secondary"
          >
            <i className="fak fa-film-play"/>
            <Tooltip
              delay={0}
              placement="top"
              isOpen={dimensionsTooltip}
              target={"dimensions-" + this.props.question.id}
              toggle={() => this.toggleTooltip('dimensionsTooltip')}
            >
              <FormattedMessage id="question.settings.videoPlayerDimensions.select.tooltip"/>
            </Tooltip>
          </Button>
        </InputGroupAddon>
        <Select
          classNamePrefix="react-select"
          className="react-select float-left"
          isClearable={false}
          value={value}
          options={options}
          onChange={(e) => this.onSelectChange(e)}
        />
      </InputGroup>

      {isCustom &&
        <React.Fragment>
          <InputGroup id={"playerWidth-" + this.props.question.id} className="float-left mr-1 w-100 w-lg-auto mb-1">
            <InputGroupAddon addonType="prepend">
              <Button
                className={classnames({
                  "btn-icon active": true,
                })}
                color="secondary"
              >
                <i className="fas fa-arrows-alt-h" />

                <Tooltip
                  delay={0}
                  placement="top"
                  isOpen={widthTooltip}
                  target={"playerWidth-" + this.props.question.id}
                  toggle={() => this.toggleTooltip('widthTooltip')}
                >
                  <FormattedMessage id="question.settings.videoPlayerDimensions.playerWidth.tooltip"/>
                </Tooltip>
              </Button>
            </InputGroupAddon>
            <Input type="number" name="playerWidth" id={"playerWidth-" + this.props.question.id} className="w-50 float-left" value={question.playerWidth}
                   onChange={e => this.onChange(e)} onBlur={() => this.props.handleUpdate(this.state.question)} invalid={!!this.props.errors.playerWidth}/>
            {this.props.errors.playerWidth && <p className="help-block text-danger small">{this.props.errors.playerWidth}</p>}
          </InputGroup>

          <InputGroup id={"playerHeight-" + this.props.question.id} className="float-left mr-1 w-100 w-lg-auto mb-1">
            <InputGroupAddon addonType="prepend">
              <Button
                className={classnames({
                  "btn-icon active": true,
                })}
                color="secondary"
              >
                <i className="fas fa-arrows-alt-v" />

                <Tooltip
                  delay={0}
                  placement="top"
                  isOpen={heightTooltip}
                  target={"playerHeight-" + this.props.question.id}
                  toggle={() => this.toggleTooltip('heightTooltip')}
                >
                  <FormattedMessage id="question.settings.videoPlayerDimensions.playerHeight.tooltip"/>
                </Tooltip>
              </Button>
            </InputGroupAddon>
            <Input type="number" name="playerHeight" id={"playerHeight-" + this.props.question.id} className="w-50 float-left" value={question.playerHeight}
                   onChange={e => this.onChange(e)} onBlur={() => this.props.handleUpdate(this.state.question)} invalid={!!this.props.errors.playerHeight}/>
            {this.props.errors.playerHeight && <p className="help-block text-danger small">{this.props.errors.playerHeight}</p>}
          </InputGroup>
        </React.Fragment>
      }
      </React.Fragment>
    )
  }
}

VideoPlayerDimensions.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    autoPlay: PropTypes.bool.isRequired,
    playerWidth: PropTypes.number.isRequired,
    playerHeight: PropTypes.number.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

export default VideoPlayerDimensions;
