import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Button, Tooltip} from "reactstrap";
import classnames from "classnames";

class Disabled extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: {
        disabled: props.page.disabled,
      },
      tooltip: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleDisabled = this.toggleDisabled.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleDisabled() {
    this.setState({
      page: {
        disabled: !this.state.page.disabled
      }
    }, () => {
      this.props.handleUpdate(this.state.page)
    });
  }

  render() {
    let { tooltip } = this.state;
    return (
      <Button
        className={classnames({
          "float-left mr-1 btn-icon mb-1": true,
          "active": this.state.page.disabled
        })}
        color={'secondary'}
        id="disabled"
        onClick={this.toggleDisabled}
      >
        <i className="fa-solid fa-power-off" />

        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target="disabled"
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="page.settings.disabled.button.tooltip"/>
        </Tooltip>
      </Button>
    )
  }
}

Disabled.propTypes = {
  page: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default Disabled;
