import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button, Alert} from "reactstrap";
import {deleteAddress} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';

class AddressDeleteButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    }
  }

  addressDelete(){
    this.setState({
      deleting: true
    });

    this.props.deleteAddress({
      id: this.props.address.id
    }).then(() => this.setState({deleting: false, modal: false}))
      .catch(() => {
      this.setState({
        deleting: false
      });
    });
  }

  toggleModalDelete(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { deleting } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModalDelete()}>
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2"><FormattedMessage id="addressDeleteButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Alert color="warning" className="text-center"><span className="font-weight-bold">UWAGA!</span><br />Jeśli pod ten adres zostały wysłane jakieś zaproszenia do ankiety, ten adres email nie będzie widoczny w historii wysyłki</Alert>
        <p><FormattedMessage id="addressDeleteButton.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.modal.delete.confirmation">{
              (message) => <LaddaButton
                className="btn btn-danger btn-ladda mb-0 ml-3"
                loading={deleting}
                data-style={ZOOM_OUT}
                onClick={() => this.addressDelete()}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggleModalDelete()}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="m-0 p-0" onClick={() => this.toggleModalDelete()}><i className="fas fa-times"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

AddressDeleteButton.propTypes = {
  address: PropTypes.object.isRequired,
};

export default connect(null, { deleteAddress })(AddressDeleteButton);