import api from "api";
import {ERROR, REQUEST, SUCCESS} from "../analysis/actions";

export const SET = 'Analysis/data/questionSummaries/SET';
export const UPDATED = 'Analysis/data/questionSummaries/UPDATED';

export function setQuestionSummaries(questionSummaries) {
  return {
    type: SET,
    questionSummaries
  }
}

export function questionSummaryUpdated(questionSummary) {
  return {
    type: UPDATED,
    questionSummary
  }
}


export function fetchQuestionSummaries(analysis, lang) {
  return dispatch => {
    dispatch({type: REQUEST});

    return api.analysis.get.questionSummaries(analysis, lang).then(questionSummariesData => {
      dispatch(setQuestionSummaries(questionSummariesData));

      dispatch({type: SUCCESS})
    }).catch(() => {
      dispatch({type: ERROR})
    });
  }
}

export function updateQuestionSummary(questionSummary) {
  return dispatch => {
    dispatch({type: REQUEST});

    return api.analysis.questionSummary.patch(questionSummary).then(questionSummaryData => {
      dispatch(questionSummaryUpdated(questionSummaryData));

      dispatch({type: SUCCESS});
    }).catch((err) => {
      dispatch({type: ERROR})
    });
  }
}