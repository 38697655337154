import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Nav, NavItem, TabContent} from "reactstrap";
import TabTokens from "./Tabs/TabTokens";
import TabSettings from "./Tabs/TabSettings";
import appRoutes from "appRoutes";
import {Route, Switch, NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import CardBody from "reactstrap/es/CardBody";

class CollectorTokens extends Component {

  render() {
    const {params} = this.props.match;

    return (
      <CardBody className="px-0 pb-0">
        <Nav tabs>
          <NavItem>
            <NavLink className="nav-link" exact to={appRoutes.survey.collectors.collector.view(params.survey, params.collector)}>
              <i className="fas fa-cog" />
              <FormattedMessage id={'surveyCollector.typeTokens.tabs.settings'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" exact to={appRoutes.survey.collectors.collector.tokens.tokens(params.survey, params.collector)}>
              <i className="fas fa-key" />
              <FormattedMessage id={'surveyCollector.typeTokens.tabs.tokens'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="tab-body">
          <Switch>
            <Route path={appRoutes.survey.collectors.collector.view()} exact component={() => <TabSettings collector={this.props.collector} handleUpdate={this.props.handleUpdate}/>} />
            <Route path={appRoutes.survey.collectors.collector.tokens.tokens()} exact component={() => <TabTokens collector={this.props.collector} surveyId={params.survey}/>} />
          </Switch>
        </TabContent>
      </CardBody>
    )
  }
}

CollectorTokens.propTypes = {
  collector: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      survey: PropTypes.string.isRequired,
      collector: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CollectorTokens;