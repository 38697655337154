import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Link, NavLink, Switch} from "react-router-dom";
import UserRoute from "routes/UserRoute";
import appRoutes from "appRoutes";

import NavSettings from "../../components/Header/components/NavSettings";
import {Collapse, Nav, Navbar, NavItem, NavLink as NavLinkReactstrap} from "reactstrap";
import {isMobile, isTablet} from "react-device-detect";

import Home from "scenes/Account/Home";
import Insights from "scenes/Account/Insights";
import Group from "scenes/Account/Group";
import Billing from "scenes/Account/Billing";
import ProfileCompany from "scenes/Account/Profile";
import Profile from "../../scenes/Profile/components/Profile";

import PasswordChangeRequired from "scenes/Account/PasswordChangeRequired";
import SettingsGeneral from "scenes/Account/Settings/SettingsGeneral";
import SettingsIntegration from "scenes/Account/Settings/SettingsIntegration";
import SettingsIntegrationSmtp from "scenes/Account/Settings/SettingsIntegrationSmtp";

import './style.scss';
import {userLogout} from "../../reducers/user/actions";
import UserAvatar from "../../components/UserAvatar";
import Footer from "../../components/Footer";
import isGroupManager from "../../utils/userGroupHelper";

class AccountContainer extends Component {
  render() {
    const {user} = this.props;

    return <React.Fragment>
      <div className="d-grid" style={{height: "100vh", gridTemplateRows: "auto 1fr auto"}}>
        <header style={{height: "auto"}} id="header">
          <NavSettings location={this.props.location} />
          <Navbar className="navbar-expand-lg pt-0">
            <Link to={appRoutes.home} className="logo-wrap m-0 pt-1 pt-lg-3 order-0"><img src="/dashboard/img/logo.png" alt="" style={isMobile || isTablet ? {maxHeight: 38} : {}}/></Link>
          </Navbar>
        </header>
        <div className="px-0 d-flex" style={{overflowY: "scroll", overflowX: "hidden"}}>
          <div className="shadow border-right mt-0 h-100 bg-white pt-4 px-2 d-flex flex-column" id="nav-left">
            <div className="d-flex align-items-center mb-3 border-bottom pb-3">
              <UserAvatar user={this.props.user} size={40} />
              <small className="ml-2 d-none d-lg-block">{this.props.user.email}</small>
            </div>

            <Nav vertical>
              <NavItem><NavLink exact className="nav-link" to={appRoutes.account.home}><i className="fas fa-home" /> <span>Home</span></NavLink></NavItem>
              {isGroupManager(user) && <NavItem><NavLink exact className="nav-link" to={appRoutes.account.insights}><i className="fas fa-chart-area" /> <span>Statystyki</span></NavLink></NavItem>}
              {isGroupManager(user) && <NavItem><NavLink exact className="nav-link" to={appRoutes.account.group}><i className="fas fa-users" /> <span>Użytkownicy</span></NavLink></NavItem>}
              <NavItem><NavLink exact className="nav-link" to={appRoutes.account.billing}><i className="fas fa-credit-card" /> <span>Zamówienia</span></NavLink></NavItem>
              {!isGroupManager(user) && <NavItem><NavLink exact className="nav-link" to={appRoutes.users.profile}><i className="fas fa-user" /> <span>Profil</span></NavLink></NavItem>}
              {isGroupManager(user) && <NavItem><NavLink exact className="nav-link" to={appRoutes.account.profile}><i className="fas fa-user" /> <span>Dane firmy</span></NavLink></NavItem>}
              {isGroupManager(user) && <NavItem><NavLink exact className="nav-link" to={appRoutes.account.settings}><i className="fas fa-cog" /> <span>Ustawienia</span></NavLink></NavItem>}
              {isGroupManager(user) && <NavItem><NavLink exact className="nav-link" to={appRoutes.account.integration}><i className="fas fa-puzzle-piece" /> <span>Integracje</span></NavLink></NavItem>}
            </Nav>
            <Nav vertical className="mt-auto">
              <NavItem className="border-top"><NavLinkReactstrap className="nav-link" onClick={this.props.userLogout}><i className="fa-solid fa-power-off" /> <span>Wyloguj</span></NavLinkReactstrap></NavItem>
            </Nav>
          </div>

          <div id="account" className="px-3 pt-3 w-100">
            <Switch>
              <UserRoute exact path={appRoutes.account.home} component={Home}/>
              {isGroupManager(user) && <UserRoute exact path={appRoutes.account.insights} component={Insights}/>}
              {isGroupManager(user) && <UserRoute exact path={appRoutes.account.group} component={Group}/>}
              <UserRoute exact path={appRoutes.account.billing} component={Billing}/>
              <UserRoute exact path={appRoutes.account.profile} component={ProfileCompany}/>
              <UserRoute exact path={appRoutes.users.profile} component={Profile}/>
              {isGroupManager(user) && <UserRoute exact path={appRoutes.account.passwordChangeRequired} component={PasswordChangeRequired}/>}
              {isGroupManager(user) && <UserRoute exact path={appRoutes.account.settings} component={SettingsGeneral}/>}
              {isGroupManager(user) && <UserRoute exact path={appRoutes.account.integration} component={SettingsIntegration}/>}
              {isGroupManager(user) && <UserRoute exact path={appRoutes.account.integrationSmtp} component={SettingsIntegrationSmtp}/>}
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  }
}

AccountContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {userLogout})(AccountContainer);
