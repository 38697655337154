import React from 'react';
import PropTypes from 'prop-types';
import {ReactSortable} from "react-sortablejs";
import SurveysCategorySurvey from "./SurveysCategorySurvey";
import {Alert} from "reactstrap";

const SurveysCategorySurveysList = props => {
  const [surveysMax, setSurveysMax] = React.useState(30);

  if(props.surveys.length === 0){
    return <ReactSortable
      group={{
        name: 'surveys',
      }}
      id={props.surveysCategory ? props.surveysCategory.id : undefined}
      animation={200}
      className="ReactTable table-wrap pt-3"
      list={[{}]}
      setList={() => {}}
      onEnd={props.onEndSurveys}
      handle={".handler-sortable-survey"}
    >
      <Alert color="warning" className="text-center">Brak ankiet w tej sekcji</Alert>
    </ReactSortable>
  }

  const getSurveys = () => {
    return props.surveys.slice(0, surveysMax);
  }

  return (
    <React.Fragment>
      <ReactSortable
        group={{
          name: 'surveys',
        }}
        id={props.surveysCategory ? props.surveysCategory.id : undefined}
        animation={200}
        className="ReactTable table-wrap pt-3"
        list={getSurveys()}
        setList={() => {}}
        onEnd={props.onEndSurveys}
        handle={".handler-sortable-survey"}
      >
        {getSurveys().map(survey => {
          return <div className="rt-tbody" key={survey.id}>
            <SurveysCategorySurvey
              sortableDisabled={props.sortableDisabled}
              survey={survey}
              onChange={() => props.onChange(survey.id)}
            />
          </div>
        })}
      </ReactSortable>
      {(props.surveys.length > surveysMax) && <div className="p-1 w-100 text-center">
        <span
          className="pointer d-block p-2"
          onClick={() => setSurveysMax(surveysMax + 30)}
        >Załaduj następne ({props.surveys.length - surveysMax})</span>
      </div>}
    </React.Fragment>
  );
};

SurveysCategorySurveysList.propTypes = {
  surveys: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    surveysCategorySurveyId: PropTypes.number.isRequired,
  }).isRequired,).isRequired,
  surveysCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  onEndSurveys: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  sortableDisabled: PropTypes.bool.isRequired,
};

export default SurveysCategorySurveysList;