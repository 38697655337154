import React, {Component} from 'react';
import {Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, Row, Form} from 'reactstrap';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';
import {toast} from "react-toastify";
import LoadingSpinner from "components/LoadingSpinner";
import appRoutes from 'appRoutes';

class PasswordReset extends Component {
  constructor(props){
    super(props);

    this.state = {
      data: {
        plainPassword: {
          first: '',
          second: ''
        },
      },
      checkingResetKey: true,
      loading: false,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const key = this.props.match.params.resetKey;

    api.user.post.passwordResetKey(key)
      .then(() => {
        this.setState({checkingResetKey: false});
      })
      .catch(e => {
        toast.error(<FormattedMessage id="passwordReset.message.invalidResetKey"/>);
        this.props.history.push(appRoutes.users.login);
      })
  }

  onChange(e){
    this.setState({
      data: {
        plainPassword: {
          ...this.state.data.plainPassword,
          [e.target.name]: e.target.value
        }
      }
    })
  }

  onSubmit(e){
    e.preventDefault();
    const key = this.props.match.params.resetKey;

    this.setState({ loading: true, errors: {} });
    api.user.post.passwordReset({data: this.state.data}, key)
      .then(() => {
        toast.success(<FormattedMessage id="passwordReset.message.passwordChanged"/>);
        this.props.history.push(appRoutes.users.login);
      })
      .catch(e => {
        this.setState({
          errors: e.response.data.form.errors,
          loading: false
        })
      })
  }

  render() {
    const { data, errors, loading, checkingResetKey } = this.state;

    return ( checkingResetKey ? <LoadingSpinner /> :
      <div className="app flex-row align-items-center">
        <Container>
          <Form onSubmit={this.onSubmit}>
            <Row className="justify-content-center">
              <Col md="8">
                <CardGroup className="mb-4">
                  <Card className="p-4">
                    <CardBody>
                      <h1><FormattedMessage id="passwordReset.form.cardBody" /></h1>
                      <p className="text-muted"><FormattedMessage id="passwordReset.form.cardBody.desc" /></p>

                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <div className="input-group-text">
                            <i className="fas fa-lock" />
                          </div>
                        </InputGroupAddon>
                        <FormattedMessage id="passwordReset.form.label.password.first">{
                          (message) =>
                            <Input type="password" name="first" placeholder={message} value={data.plainPassword.first} onChange={this.onChange} invalid={!!errors.plainPassword}/>
                        }</FormattedMessage>
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <div className="input-group-text">
                            <i className="fas fa-lock" />
                          </div>
                        </InputGroupAddon>
                        <FormattedMessage id="passwordReset.form.label.password.second">{
                          (message) =>
                            <Input type="password" name="second" placeholder={message} value={data.plainPassword.second} onChange={this.onChange} invalid={!!errors.plainPassword}/>
                        }</FormattedMessage>
                      </InputGroup>
                      {errors.plainPassword && <p className="help-block text-danger">{errors.plainPassword.first}</p>}

                      <Row className="mt-4">
                        <Col xs="6">
                          <FormattedMessage id="passwordReset.form.button">{
                            (message) => <LaddaButton
                              className="btn btn-primary btn-ladda mt-4"
                              loading={loading}
                              data-style={ZOOM_OUT}
                            >
                              {message}
                            </LaddaButton>
                          }</FormattedMessage>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                </CardGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

PasswordReset.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default PasswordReset;
