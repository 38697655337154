import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListGroup from "reactstrap/es/ListGroup";
import ListGroupItem from "reactstrap/es/ListGroupItem";

class MaxDiff extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {details, question} = this.props;

    const detailLeft = details.find(detail => detail.value === 1);
    const detailRight = details.find(detail => detail.value === 2);

    return (
      <ListGroup>
        <ListGroupItem>{question.leftText}: {detailLeft ? (question.answersImage ? <img src={detailLeft.answer.content} className="img-100"/> : <span dangerouslySetInnerHTML={{__html: detailLeft.answer.content}} className="d-inline-block img-30"/>) : <i>Brak wskazania</i>}</ListGroupItem>
        <ListGroupItem>{question.rightText}: {detailRight ? (question.answersImage ? <img src={detailRight.answer.content} className="img-100"/> : <span dangerouslySetInnerHTML={{__html: detailRight.answer.content}} className="d-inline-block img-30"/>) : <i>Brak wskazania</i>}</ListGroupItem>
      </ListGroup>
    )
  }
}

MaxDiff.propTypes = {
  question: PropTypes.shape({
    leftText: PropTypes.string.isRequired,
    rightText: PropTypes.string.isRequired,
    answersImage: PropTypes.bool.isRequired
  }).isRequired,
  details: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }).isRequired,
    value: PropTypes.oneOf([1,2]).isRequired,
  })).isRequired
};

export default MaxDiff;
