import React, {Component} from 'react';
import {Row, Col} from "reactstrap";
import WidgetContainer from '../../WidgetContainer';
import api from 'api';
import ListItems from "./components/ListItems";
import appRoutes from "../../../../../../../appRoutes";

class Collectors extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collectors: [],
      loading: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.getCollectors = this.getCollectors.bind(this);
  }

  componentDidMount() {
    this.getCollectors();
  }

  getCollectors() {
    this.setState({collectors: [], loading: true});
    api.widget.get.widget(this.props.widget).then(collectors => this.setState({collectors: Object.values(collectors), loading: false}));
  }

  handleCopy(collector) {
    this.props.history.push(appRoutes.survey.collectors.collector.view(this.props.match.params.survey, collector.id));
  }

  handleChange(collectorId, property, value) {
    let collectors = this.state.collectors;
    let collectorIndex = collectors.indexOf(_.find(collectors, collector => collector.id === collectorId));

    collectors[collectorIndex][property] = value;
    this.setState({collectors: collectors});
  }

  handleDelete(collectorId) {
    const collectors = this.state.collectors.filter(collector => collector.id !== collectorId);
    this.setState({collectors: collectors});
  }

  render() {
    return (
      <WidgetContainer widget={this.props.widget} handleDataChange={this.getCollectors}>
        <Row>
          <Col>
            <ListItems collectors={this.state.collectors} loading={this.state.loading} handleCollectorChange={this.handleChange} handleCollectorDelete={this.handleDelete} handleCollectorCopy={this.handleCopy}/>
          </Col>
        </Row>
      </WidgetContainer>
    )
  }
}

export default Collectors;