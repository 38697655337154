import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import CheckedDelete from "./components/CheckedDelete";
import ActionAdd from './components/Add';
import ActionReplace from "./components/Replace";
import ActionCopy from "./components/Copy";
import ActionSwap from "./components/Swap";
import ActionReverse from "./components/Reverse";
import {Button, Col, Row} from "reactstrap";

class RowsActions extends Component {

  render() {
    const {question, checkedRows, rows} = this.props;

    let rowsChecked =  _.map(checkedRows, id => rows.find(row => row.id === id) || false);
    rowsChecked = rowsChecked.filter(row => row !== false);

    return (
      <Row className="mt-2">
        <Col xs={12}>
          {rowsChecked.length > 0 &&
          <div className="direction-group w-100 label-filtr-wrap mb-0">
            <ul className="direction-left mb-0 p-0">
              <li className="float-left">
                <CheckedDelete question={question} rowsChecked={rowsChecked}/>
              </li>
            </ul>
          </div>}

          <div className="direction-group w-100 label-filtr-wrap d-none d-lg-block">
            <ul className="direction-left mb-0 p-0">
              <li className="float-left"><Button color="secondary" onClick={this.props.onCheckAll}>{rowsChecked.length >= rows.length ? <i className="fas fa-check-square" /> : <i className="far fa-check-square" />}</Button></li>
              <li className="float-left"><ActionReplace question={question}/></li>
              <li className="float-left"><ActionAdd question={question}/></li>
              <li className="float-left"><ActionCopy question={question}/></li>
              <li className="float-left"><ActionSwap question={question}/></li>
              <li className="float-left"><ActionReverse question={question}/></li>
            </ul>
          </div>
        </Col>
      </Row>
    )
  }
}

RowsActions.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  checkedRows: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  onCheckAll: PropTypes.func.isRequired,
};

export default RowsActions;