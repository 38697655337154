import {SET, DELETED, ADDED, UPDATED} from './actions';
import update from "immutability-helper";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.share;

    case DELETED:
      return update(state, {$unset: [action.id] });

    case ADDED:
      return {...state, [action.share.id]: action.share};

    case UPDATED:
      return {...state, [action.share.id]: action.share};

    default:
      return state;
  }
};