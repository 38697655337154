import React, {Component} from 'react';
import {Nav, NavItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import appRoutes from "appRoutes";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import NavigationAdmin from './NavigationAdmin';
import {connect} from "react-redux";

class NavigationAdminStats extends Component {
  render() {
    const {user} = this.props;

    return (
      <Nav className="navbar-nav m-0 mx-lg-5 align-items-lg-baseline w-50 w-lg-100 float-right d-inline-block d-lg-flex">
        {user.roles.includes('ROLE_SUPER_ADMIN') && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.stats.respondents} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminStats.respondents"/><span className="sr-only"><FormattedMessage id="navigation.adminStats.respondents"/></span></NavLink></NavItem>}
        {user.roles.includes('ROLE_SUPER_ADMIN') && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.stats.companies} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminStats.companies"/><span className="sr-only"><FormattedMessage id="navigation.adminStats.companies"/></span></NavLink></NavItem>}
        {user.roles.includes('ROLE_SUPER_ADMIN') && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.stats.prizes} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminStats.prizes"/><span className="sr-only"><FormattedMessage id="navigation.adminStats.prizes"/></span></NavLink></NavItem>}
        {user.roles.includes('ROLE_SUPER_ADMIN') && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.stats.surveyResponses} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminStats.surveyResponses"/><span className="sr-only"><FormattedMessage id="navigation.adminStats.surveyResponses"/></span></NavLink></NavItem>}
        {user.roles.includes('ROLE_SUPER_ADMIN') && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.stats.surveys} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminStats.surveys"/><span className="sr-only"><FormattedMessage id="navigation.adminStats.surveys"/></span></NavLink></NavItem>}
        {user.roles.includes('ROLE_SUPER_ADMIN') && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.stats.surveyPoints} onClick={() => NavigationAdmin.toggleNavbar(true)}>Ankiety-punkty</NavLink></NavItem>}
      </Nav>
    );
  }
}

NavigationAdminStats.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(NavigationAdminStats);
