import React, {Component} from 'react';
import {Card, CardBody, Col, Container, Input, InputGroup, InputGroupAddon, Row, Form} from 'reactstrap';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';
import {toast} from "react-toastify";
import appRoutes from 'appRoutes';
import CardHeader from "reactstrap/es/CardHeader";
import CardFooter from "reactstrap/es/CardFooter";
import {Link} from "react-router-dom";

class ForgotPassword extends Component {
  constructor(props){
    super(props);

    this.state = {
      data: {
        email: '',
      },
      loading: false,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    document.title = 'Odzyskaj hasło | ANKIETEO';
    document.querySelector('meta[name="description"]').setAttribute("content", 'Ankieteo - Odzyskiwanie hasła');
    document.querySelector('meta[name="keywords"]').setAttribute("content", 'ankieteo, ankietheo, ankieta, ankiety, sondaże, ankiety online, wywiady online, kwestionariusz, badania, kreator ankiet, plan, przypominanie hasła');
  }

  onChange(e){
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    })
  }

  onSubmit(e){
    e.preventDefault();

    this.setState({ loading: true, errors: {} });
    api.user.post.forgotPassword({data: this.state.data})
      .then(() => {
        toast.success(<FormattedMessage id="forgotPassword.message.sent"/>);
        this.props.history.push(appRoutes.users.login);
      })
      .catch(e => {
        this.setState({
          errors: e.response.data.form.errors,
          loading: false
        })
      })
  }

  render() {
    const { data, errors, loading } = this.state;

    return (
        <Container>
          <Row className="mh-100vh align-items-center justify-content-center">
            <Col md={12} lg={9}>
              <Form onSubmit={this.onSubmit}>
                <Row className="justify-content-center">
                  <Col md="8">
                      <Card>
                        <CardHeader><FormattedMessage tagName="h2" id="forgotPassword.form.cardBody" /></CardHeader>
                        <hr className="my-0" />
                        <CardBody>
                          <p className="text-muted"><FormattedMessage id="forgotPassword.form.cardBody.desc" /></p>

                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <div className="input-group-text">
                                <i className="fas fa-at" />
                              </div>
                            </InputGroupAddon>
                            <FormattedMessage id="forgotPassword.form.label.email">{
                              (message) =>
                                <Input type="text" name="email" placeholder={message} value={data.email} onChange={this.onChange} invalid={!!errors.email}/>
                            }</FormattedMessage>
                          </InputGroup>
                          {errors.email && <p className="help-block text-danger">{errors.email}</p>}
                        </CardBody>
                        <CardFooter>
                          <Link className="btn btn-secondary float-left mb-0" to={appRoutes.users.login}><i className="fas fa-arrow-left" /> <FormattedMessage id="forgotPassword.form.buttonBack" /></Link>
                          <FormattedMessage id="forgotPassword.form.button">{
                            (message) => <LaddaButton
                              className="btn btn-primary btn-ladda float-right mb-0"
                              loading={loading}
                              data-style={ZOOM_OUT}
                            >
                              {message}
                            </LaddaButton>
                          }</FormattedMessage>
                        </CardFooter>
                      </Card>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
    );
  }
}

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default ForgotPassword;
