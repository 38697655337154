import React, {Component} from 'react';
import {Form, Label, Input, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

class ColumnForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: props.column ? props.column.name : ''
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    const {errors} = this.props;

    return (
      <Form onSubmit={e => this.props.onSubmit(e, this.state)}>
        <Row>
          <div className="col-lg-3 col-12">
            <Label for="name"><FormattedMessage id="respondentBasesSettings.columnEditForm.label.name" /></Label>
          </div>
          <div className="col-lg-9 col-12">
            <Input type="text" name="name" id="name" value={this.state.name} onChange={this.onChange} invalid={!!errors.name}/>
            {errors.name && <div className="invalid-feedback">{errors.name.join('; ')}</div>}
          </div>
        </Row>
      </Form>
    )
  }
}

ColumnForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  column: PropTypes.object,
};


export default ColumnForm;