import React from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {connect} from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner";
import SurveyValidation from "../../componentsNew/Survey/SurveyValidation/SurveyValidation";

const SurveyValidationView = props => {
  return (
    <main>
      <Card>
        <CardHeader>
          <h2>Test ankiety</h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          {props.survey.id ? <SurveyValidation surveyId={props.survey.id}/> : <LoadingSpinner />}
        </CardBody>
      </Card>
    </main>
  );
};

SurveyValidationView.propTypes = {

};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps)(SurveyValidationView);
