import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, FormGroup, FormText, Label} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Select from "react-select";
import SelectAnswers from "../../../../../../../../../../../../../../../../../../../../SelectAnswers";

class Multi extends PureComponent {

  componentDidMount() {
    const {questionFrom} = this.props;
    if(!questionFrom.fetchType){
      this.props.onChange({fetchType: this.props.configQuestionFrom.fetchTypes[0]});
    }
  }

  render() {
    const {errors, questionFrom, configQuestionFrom} = this.props;

    return (
      <React.Fragment>
        <FormGroup row className="mb-1">
          <Label lg={3} className="text-right"><FormattedMessage id="pipingForm.questionFrom.label.fetchType" /></Label>
          <Col lg={9}>
            <Select
              placeholder="Wybierz..."
              value={questionFrom.fetchType ? {value: questionFrom.fetchType, label: <FormattedMessage id={'pipingForm.questionFrom.multi.fetchType.' + questionFrom.fetchType}>{(msg) => msg}</FormattedMessage>} : null}
              onChange={(e) => this.props.onChange({fetchType: e.value})}
              options={configQuestionFrom.fetchTypes.map(type => {
                return {
                  value: type,
                  label: <FormattedMessage id={'pipingForm.questionFrom.multi.fetchType.' + type}>{(msg) => msg}</FormattedMessage>,
                }
              })}
            />
            {_.has(errors, 'questionFrom.fetchType') && <FormText color="danger">errors.questionFrom.fetchType.join('; ')</FormText>}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-1">
          <Label lg={3} className="text-right"><FormattedMessage id="pipingForm.questionFrom.ranked.label.answers" /></Label>
          <Col lg={9}>
            <SelectAnswers
              placeholder="Wszystkie odpowiedzi lub wybierz z listy..."
              isMulti={true}
              questionId={questionFrom.question}
              value={questionFrom.answers || []}
              onChange={answers => this.props.onChange({answers})}
            />
            {_.has(errors, 'questionFrom.answers') && <FormText color="danger">errors.questionFrom.answers.join('; ')</FormText>}
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

Multi.propTypes = {
  configQuestionFrom: PropTypes.shape({
    fetchTypes: PropTypes.array.isRequired,
  }).isRequired,
  question: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  questionFrom: PropTypes.shape({
    fetchType: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Multi;