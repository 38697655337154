import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";

class ButtonOpenColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipIsOpen: false,
    };
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  render() {
    return (
      <a id={'isOpen-' + this.props.column.id} className={"fa-solid fa-keyboard ml-2" + (this.props.isOpen ? " text-primary" : "")} onClick={() => this.props.toggleOption('isOpen')}>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={this.state.tooltipIsOpen}
          target={'isOpen-' + this.props.column.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="columns.isOpen.button.tooltip"/>
        </Tooltip>
      </a>
    )
  }
}

ButtonOpenColumn.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOption: PropTypes.func.isRequired
};

export default ButtonOpenColumn;
