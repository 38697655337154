import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Select from "react-select";

class SelectAnswers extends Component {
  constructor(props){
    super(props);

    this.select = React.createRef();

    this.getOptions = this.getOptions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getLabel = this.getLabel.bind(this);
  }

  getOptions(){
    const {answers} = this.props;

    const ret = answers.map(answer => ({
      label: this.getLabel(answer),
      content: answer.content || '',
      value: answer.id,
    }));

    if(this.props.isMulti){
      ret.push({
        label: '-- Wybierz wszystkie --',
        content: '',
        value: 'ALL',
      });
    }

    return ret;
  }

  onChange(e){
    const {isMulti} = this.props;

    if(isMulti){
      this.select.current.setState({isOpen: true, menuIsOpen: true});
    }

    if(isMulti){
      if(e.filter(option => option.value === 'ALL').length > 0){
        this.props.onChange(this.props.answers.map(answer => answer.id))
      }else{
        this.props.onChange(e.map(obj => obj.value));
      }
    }else{
      this.props.onChange(e ? e.value : null);
    }
  }

  getLabel(answer) {
    return this.props.question.answersImage
      ? <span className="img-30">{answer.position + 1}. <img src={answer.content} /></span>
      : <span className="img-20" dangerouslySetInnerHTML={{__html: answer.position + 1 + '. ' + answer.stripedContent}}/>
  }

  render() {
    const {answers, value, isMulti, placeholder, className, isClearable} = this.props;

    return (
      <Select
        className={className}
        isClearable={isClearable}
        placeholder={placeholder ? placeholder : (isMulti ? "Wybierz odpowiedzi" : "Wybierz odpowiedź")}
        ref={this.select}
        value={value && (!isMulti ?
            {
              label: this.getLabel(answers.find(a => a.id === value)),
              value
            }
          :
          value.map(aId => {
            return {
              label: this.getLabel(answers.find(a => a.id === aId)),
              value: aId,
            }
          })
        )}
        isOpen={false}
        isMulti={isMulti}
        filterOption={(option, string) => {
          if (option.value && option.value.toString().toLowerCase().includes(string.toLowerCase())) return true;
          if (option.data.content && option.data.content.toLowerCase().includes(string.toLowerCase())) return true;
        }}
        onChange={this.onChange}
        options={this.getOptions()}
      />
    );
  }
}

SelectAnswers.defaultProps = {
  isMulti: true,
  isClearable: false,
  className: '',
};

SelectAnswers.propTypes = {
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  questionId: PropTypes.number,
  answers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    stripedContent: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
  }).isRequired,).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number,]),
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  let answers = [];

  if(!_.isUndefined(props.answers)){
    answers = props.answers;
  }else{
    answers = state.survey.structure.data.questions[props.questionId].answers.map(aId => state.survey.structure.data.answers[aId]);
    let position = 0;
    answers = answers.map(answer => {
      const ret = {
        ...answer,
        position
      };

      position++;
      return ret;
    });
  }

  return {
    answers,
    question: state.survey.structure.data.questions[props.questionId]
  }
}

export default connect(mapStateToProps)(SelectAnswers);