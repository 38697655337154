import React, {PureComponent} from 'react';
import {Alert} from "reactstrap";

class PlanPaymentEnd extends PureComponent {
  render() {
    return (
      <Alert color="success">Dziękujemy za opłacenie planu, wkrótce zostanie on aktywowany.</Alert>
    );
  }
}

export default PlanPaymentEnd;