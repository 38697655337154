import {ADDED, SET, USER_DELETED, USER_ADDED, USER_UPDATED} from "./actionTypes";
import update from "immutability-helper";

const initialState = [];

export const groupReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.groups;

    case ADDED:
      return {
        ...state,
        [action.group.id]: action.group
      };

    case USER_DELETED:
      return update(state, {[action.user.groupId]: {users: {$unset: [action.user.id]}}});

    case USER_ADDED:
      return {
        ...state,
        [action.user.groupId]: {
          ...state[action.user.groupId],
          users: {
            ...state[action.user.groupId].users,
            [action.user.id]: action.user
          }
        }
      };

    case USER_UPDATED:
      return {
        ...state,
        [action.user.groupId]: {
          ...state[action.user.groupId],
          users: {
            ...state[action.user.groupId].users,
            [action.user.id]: action.user
          }
        }
      };

    default:
      return state;
  }
};

export default groupReducer;