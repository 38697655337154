import React, {Component} from 'react';
import PropTypes from "prop-types";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";
import {ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ButtonDelete from "./ButtonDelete";
import ButtonEdit from "./ButtonEdit";
import Checkbox from "../../Checkbox";
import Button from "../../Button";
import {FormattedMessage} from "react-intl";

const ButtonDeleteBulk = (props) => {
  const [modal, setModal] = React.useState(false);
  const toggleModal = () => setModal(!modal);
  const [deleting, setDeleting] = React.useState(false);

  const deleteAction = () => {
    setDeleting(true);

    api.respondentSmsBases.respondents.deleteBulk(props.baseId, props.ids)
    .then(data => {
      setDeleting(false);
      toggleModal();
      props.onDeleted();
    })
    .catch(res => {
      setDeleting(false);
    })
  };

  return <React.Fragment>
    <Button color="danger" onClick={toggleModal}>Usuń zaznaczonych respondentów ({props.ids.length})</Button>
    {modal && <Modal isOpen={true} toggle={toggleModal} size="lg">
      <ModalHeader tag="h2" toggle={toggleModal}>Usuń respondentów</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        Czy na pewno chcesz usunąć zaznaczonych respondnetów?
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.delete">{
          (message) => <Button
            color="danger"
            loading={deleting}
            onClick={deleteAction}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
};

class TableList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: [],
      checked: [],
    };

    this.fetchData = this.fetchData.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
  }

  toggleSelectAll(){
    const {checked, data} = this.state;

    if(checked.length === data.length){
      this.setState({
        checked: [],
      }, () => {
        if (this.props.onCheckedChange) {
          this.props.onCheckedChange(this.state.checked);
        }
      });
    }else{
      this.setState({
        checked: data.map(d => d.id),
      }, () => {
        if (this.props.onCheckedChange) {
          this.props.onCheckedChange(this.state.checked);
        }
      });
    }
  }

  fetchData(state) {
    if(!state){
      state = this.state;
    }

    this.setState({
      searching: true,
      page: state.page,
      pageSize: state.pageSize
    });

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.respondentSmsBases.respondents.search(this.props.baseId, data)
    .then(res => {
      this.setState({
        searching: false,
        data: res.data,
        count: res.count
      });
    })
    .catch(e => this.setState({
      searching: false,
    }));
  }

  toggleChecked(id){
    const checked = _.xor(this.state.checked, [id]);
    this.setState({checked});
  };

  actions(row){
    return <ButtonGroup>
      <ButtonEdit respondent={row} onSaved={this.fetchData}><Button color=""><i className="fas fa-edit" /></Button></ButtonEdit>
      <ButtonDelete respondentId={row.id} onDeleted={this.fetchData}><Button color=""><i className="fas fa-trash" /></Button></ButtonDelete>
    </ButtonGroup>
  }

  render() {
    const columns = [
      {
        id: 'check',
        sortable: false,
        Header: x => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.checked.length > 0 && this.state.checked.length === this.state.data.length}
              onChange={() => this.toggleSelectAll()}
            />
          );
        },
        accessor: row => <Checkbox id="check" checked={this.state.checked.includes(row.id)} tooltip={false} onClick={() => this.toggleChecked(row.id)}/>,
        width: 45
      },
      {
        Header: 'Id',
        accessor: 'id',
        width: 50,
      }, {
        Header: 'Data dodania',
        accessor: 'createdAt',
        width: 170,
      }, {
        Header: 'Telefon',
        accessor: 'phone',
        width: 170,
      }, {
        Header: 'Kolumna 1',
        accessor: 'column_1',
      }, {
        Header: 'Kolumna 2',
        accessor: 'column_2',
      }, {
        Header: 'Kolumna 3',
        accessor: 'column_3',
      }, {
        Header: 'Kolumna 4',
        accessor: 'column_4',
      }, {
        Header: 'Kolumna 5',
        accessor: 'column_5',
      }, {
        Header: 'Kolumna 6',
        accessor: 'column_6',
      }, {
        Header: 'Kolumna 7',
        accessor: 'column_7',
      }, {
        Header: 'Kolumna 8',
        accessor: 'column_8',
      }, {
        Header: 'Kolumna 9',
        accessor: 'column_9',
      }, {
        Header: 'Kolumna 10',
        accessor: 'column_10',
      }, {
        id: 'actions',
        Header: 'Opcje',
        accessor: (row) => this.actions(row),
      },
    ];

    return <React.Fragment>
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={parseInt(this.state.count)}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />

      {this.state.checked.length > 0 && <ButtonDeleteBulk ids={this.state.checked} baseId={this.props.baseId} onDeleted={() => {
        this.setState({
          checked: []
        });
        this.fetchData(this.state);
      }} />}
    </React.Fragment>
  }
}

TableList.propTypes = {
  baseId: PropTypes.number.isRequired,
};

export default TableList;