import React, {Component} from 'react';
import PropTypes from "prop-types";
import AsyncSelect from 'react-select/lib/Async';
import api from "api";
import {isNull} from "lodash";

class SurveySelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchTimeout: false,
      modalAdd: false,
      loading: false,
      search: '',
      options: [],
      value: props.value ? props.value : false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  fetchData(search, callback){
    const {value} = this.state;

    clearTimeout(this.state.searchTimeout);
    this.setState({
      searchTimeout: setTimeout(() => {
        api.user.post.searchSurveys({
          page: 1,
          limit: 50,
          search,
        }, {
          sortField: 's.id',
          sortOrder: 'desc',
        })
            .then(res => {
              let options = res.surveys.map(survey => {
                return {
                  value: survey.id,
                  label: survey.name,
                }
              });

              this.setState({
                data: res.surveys,
              }, () => {
                callback(options);
              });

              if (Number.isInteger(value)) {
                const selected = options.find((survey) => survey.value === value)
                this.setState({
                  value: selected
                })
              }
            })
      }, 450)
    })
  }

  handleInputChange(search){
    this.setState({ search });
    return search;
  }

  render() {
    const {value, data} = this.state;

    return <React.Fragment>
      <AsyncSelect
          className={this.props.className}
          isClearable={true}
          placeholder={this.props.placeholder}
          loadingMessage={() => <span>Trwa wyszukiwanie <i className="fas fa-spin fa-spinner ml-2" /></span>}
          noOptionsMessage={() => <span><span className="ml-2">Nie znaleziono</span></span>}
          loadOptions={this.fetchData}
          onInputChange={this.handleInputChange}
          value={value}
          defaultOptions
          onChange={option => {
            if(isNull(option)){
              this.setState({
                value: null
              });

              this.props.onChange(null);
            }else{
              console.log('data', data);
              const survey = data.find(c => c.id === option.value);

              if(survey){
                this.setState({
                  value: {
                    value: survey.id,
                    label: survey.name,
                  }
                });

                this.props.onChange(survey);
              }else{
                this.setState({
                  value: {
                    value: option.value,
                    label: <i className="text-info">{option.value}</i>,
                  }
                });

                this.props.onChange(option.value);
              }
            }
          }}
      />
    </React.Fragment>
  }
}

SurveySelect.defaultProps = {
  className: '',
  placeholder: 'Wyszukaj ankietę...',
};

SurveySelect.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default SurveySelect;
