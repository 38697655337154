import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from "../../components/Tooltip";
import {connect} from "react-redux";
import {updateProfile} from "../../reducers/user/actions";

const SurveyUnassignedCategoryMove = props => {
  const change = () => {
    const formData = new FormData();
    formData.append('surveysCategoriesUnassignedPlacement', props.user.surveysCategoriesUnassignedPlacement === 'bottom' ? 'top' : 'bottom');
    props.updateProfile(formData);
  }

  return (
    <div>
      <i id={'category-unassigned-move-tooltip'} onClick={change} className={classnames({
        'fas pointer': true,
        'fa-arrow-up': props.user.surveysCategoriesUnassignedPlacement === 'bottom',
        'fa-arrow-down': props.user.surveysCategoriesUnassignedPlacement === 'top',
      })} />
      <Tooltip id={'category-unassigned-move-tooltip'} msg={props.user.surveysCategoriesUnassignedPlacement === 'bottom' ? 'Przenieś na górę' : 'Przenieś na dół'} />
    </div>
  );
};

SurveyUnassignedCategoryMove.propTypes = {
  surveysCategoriesUnassignedPlacement: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {updateProfile})(SurveyUnassignedCategoryMove);