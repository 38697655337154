import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import api from "api";

class SurveysModalDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      deleting: false
    }
  }

  surveyDelete(){
    this.setState({
      deleting: true
    });

    api.survey.delete.survey({
      id: this.props.survey.id
    }).then(() => {
      this.setState({deleting: false});
      this.props.handleDelete();
      this.props.toggleModal();
    });
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="surveys.listItems.modalDelete.modalHeader" /> <strong>{this.props.survey.nameWork || this.props.survey.name}</strong></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
          <p><FormattedMessage id="surveys.listItems.modalDelete.modalBody" /></p>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggleModal}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.modal.delete.confirmation">{
            (message) => <LaddaButton
              className="btn btn-danger btn-ladda"
              loading={this.state.deleting}
              data-style={ZOOM_OUT}
              onClick={() => this.surveyDelete()}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    )
  }
}

SurveysModalDelete.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default SurveysModalDelete;
