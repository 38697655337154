import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

class CompaniesStatsData extends Component {
  render() {
    return (
      <Row>
        <Col>
          <p><FormattedMessage id="admin.companiesStatsData.newCompanies"/>: {this.props.data.newCompanies}</p>
          <p><FormattedMessage id="admin.companiesStatsData.newSurveys"/>: {this.props.data.newSurveys}</p>
        </Col>
      </Row>
    )
  }
}

CompaniesStatsData.propTypes = {
  data: PropTypes.shape({
    newCompanies: PropTypes.string.isRequired,
    newSurveys: PropTypes.string.isRequired,
  }).isRequired
};

export default CompaniesStatsData;