import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {setHideInStructure} from "../../../../actions";
import Select from 'react-select';
import {setClosedSetCards, setClosedBlockCards, setClosedPageCards, setClosedQuestionCards, clearClosedCards} from "../../../../actions";
import {isTablet, isMobile} from "react-device-detect";

const types = {
  none: {
    name: 'none',
    icon: 'fas fa-sitemap',
    label: 'topbar.hideInStructure.dropdownItem.all',
  },
  sets: {
    name: 'sets',
    icon: 'fas fa-bars',
    label: 'topbar.hideInStructure.dropdownItem.sets',
  },
  blocks: {
    name: 'blocks',
    icon: 'fas fa-th-large',
    label: 'topbar.hideInStructure.dropdownItem.blocks',
  },
  pages: {
    name: 'pages',
    icon: 'fas fa-file',
    label: 'topbar.hideInStructure.dropdownItem.pages',
  },
  questions: {
    name: 'questions',
    icon: 'fab fa-quora',
    label: 'topbar.hideInStructure.dropdownItem.questions',
  },
  structure: {
    name: 'structure',
    icon: 'fas fa-stream',
    label: 'topbar.hideInStructure.dropdownItem.structure',
  },
  print: {
    name: 'print',
    icon: 'fas fa-stream',
    label: 'topbar.hideInStructure.dropdownItem.print',
  },
};

if (isTablet || isMobile) {
  types.minified = {
    name: 'minified',
    icon: 'fas fa-terminal',
    label: 'topbar.hideInStructure.dropdownItem.minified',
  }
}

class HideInStructure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {value: 'none', label: <span><i className={types.none.icon} /> <FormattedMessage id={types.none.label}/></span>}
    };

    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind("shift+a", () => this.setValue('none'));
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+a");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.hideInStructure !== this.props.hideInStructure){
      this.updateClosedCarsAction(this.props.hideInStructure);
    }
  }

  setValue(value){
    console.log('value', value);
    const questions = document.querySelectorAll('[role="question"]');
    let question = null;

    for(let i = 1; i < questions.length; i++){
      if (questions[i].getBoundingClientRect().top > 0) {
        question = document.getElementById(questions[i].getAttribute("id"));
        break;
      }
    }

    this.setState({value});
    this.props.setHideInStructure(value);

    if(question && value !== 'minified'){
      setTimeout(() => {
        this.props.mainRef.scrollTo({
          top: parseInt(question.getBoundingClientRect().top + this.props.mainRef.scrollTop) - this.props.mainRef.offsetTop,
          behavior: "auto"
        });
      }, 1)
    }
  }

  updateClosedCarsAction(type) {
    switch (type) {
      case 'sets':
        this.props.setClosedSetCards(this.props.sets);
        break;
      case 'blocks':
        this.props.setClosedBlockCards(this.props.blocks);
        break;
      case 'pages':
        this.props.setClosedPageCards(this.props.pages);
        break;
      case 'questions':
      case 'structure':
        this.props.setClosedQuestionCards(this.props.questions);
        break;
      case 'minified':
        break;
      case 'print':
      case 'none':
        this.props.clearClosedCards();
        break;
    }
  }

  render() {
    let options = [];
    _.map(types, (type, key) => options.push({value: type.name, label: <span key={key}><i className={type.icon}/> <FormattedMessage id={type.label}/></span>}));

    return (
      <Select
        className={"w-100 mb-0"}
        clearable={false}
        value={options.find(option => option.value === this.props.hideInStructure)}
        options={options}
        onChange={option => this.setValue(option.value)}
        isSearchable={false}
        menuPlacement={this.props.placement}
      />
    )
  }
}

HideInStructure.defaultProps = {
  placement: 'bottom'
};

function mapStateToProps(state) {
  return {
    hideInStructure: state.survey.structure.hideInStructure,
    sets: _.map(state.survey.structure.data.sets, set => set.id),
    blocks: _.map(state.survey.structure.data.blocks, set => set.id),
    pages: _.map(state.survey.structure.data.pages, set => set.id),
    questions: _.map(state.survey.structure.data.questions, set => set.id)
  }
}

export default connect(mapStateToProps, {setHideInStructure, setClosedSetCards, setClosedBlockCards, setClosedPageCards, setClosedQuestionCards, clearClosedCards})(HideInStructure);