import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input} from "reactstrap";
import {injectIntl} from "react-intl";

const detailTypes = ['equals', 'notEquals', 'greaterThenOrEquals', 'lesserThenOrEquals'];

class Nps extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isNpsDropdownDetailType: false,
    };

    this.toggleIsNpsDropdownDetailType = this.toggleIsNpsDropdownDetailType.bind(this);
  }

  componentWillMount() {
    const {condQuestion} = this.props;
    let condQuestionChanged = condQuestion;

    if(_.isUndefined(condQuestion.detailType) || !detailTypes.includes(condQuestion.detailType)){ condQuestionChanged.detailType = detailTypes[0]; }
    if(_.isUndefined(condQuestion.valueFrom)){ condQuestionChanged.valueFrom = 0; }
    if(_.isUndefined(condQuestion.valueTo)){ condQuestionChanged.valueTo = 10; }

    if(condQuestion !== condQuestionChanged){
      Nps.propTypes.handleCondChanged(condQuestionChanged);
    }
  }

  toggleIsNpsDropdownDetailType(){
    this.setState({
      isNpsDropdownDetailType: !this.state.isNpsDropdownDetailType,
    })
  }

  render() {
    const {isNpsDropdownDetailType} = this.state;
    const {intl, condQuestion, changeable} = this.props;

    return (
      <React.Fragment>
        <span> wybierze wartość od </span>

        {changeable ? <div className="d-flex align-items-center">
          <Input
            type="number"
            min={0}
            max={10}
            className="d-inline ml-2 mb-0"
            size="sm"
            value={condQuestion.valueFrom} onChange={e => this.props.handleCondChanged({
            ...condQuestion,
            valueFrom: e.target.value,
          })} />
          <span className="ml-2">do</span>
          <Input
            type="number"
            min={0}
            max={10}
            className="d-inline ml-2 mb-0"
            size="sm"
            value={condQuestion.valueTo} onChange={e => this.props.handleCondChanged({
            ...condQuestion,
            valueTo: e.target.value,
          })} />
        </div> : <span>{condQuestion.valueFrom} do {condQuestion.valueTo}</span>}

        {/*{changeable ? <Input*/}
        {/*  type="number"*/}
        {/*  min={0}*/}
        {/*  max={10}*/}
        {/*  className="w-25 d-inline ml-2"*/}
        {/*  size="xs"*/}
        {/*  value={condQuestion.valueFrom} onChange={e => this.props.handleCondChanged({*/}
        {/*  ...condQuestion,*/}
        {/*  valueFrom: e.target.value,*/}
        {/*})} /> : <span> {condQuestion.valueFrom} </span>}*/}

        {/*{changeable ? <Input*/}
        {/*  type="number"*/}
        {/*  min={0}*/}
        {/*  max={10}*/}
        {/*  className="w-25 d-inline ml-2"*/}
        {/*  size="xs"*/}
        {/*  value={condQuestion.valueTo} onChange={e => this.props.handleCondChanged({*/}
        {/*  ...condQuestion,*/}
        {/*  valueTo: e.target.value,*/}
        {/*})} /> : <span> {condQuestion.valueTo} </span>}*/}

      </React.Fragment>
    );
  }
}

Nps.defaultProps = {
  changeable: true
};

Nps.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number.isRequired,
    detailType: PropTypes.oneOf(detailTypes),
    value: PropTypes.string.isRequired,
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(Nps);