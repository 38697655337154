import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

class ModalDelete extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
    };

    this.deleteColumn = this.deleteColumn.bind(this);
  }

  deleteColumn(){
    this.setState({deleting: true});
    this.props.handleDelete();
  }

  render() {
    return <Modal isOpen={this.props.isOpen} toggle={(e) => this.props.toggleModal(e)}>
      <ModalHeader toggle={(e) => this.props.toggleModal(e)} tag="h2"><FormattedMessage id="column.modalDelete.modalHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="column.modalDelete.modalBody"/></p>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.modal.delete.confirmation">{
              (message) => <LaddaButton
                className="btn btn-danger btn-ladda mb-0 ml-3"
                data-style={ZOOM_OUT}
                onClick={() => this.deleteColumn()}
                loading={this.state.deleting}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={(e) => this.props.toggleModal(e)}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;
  }
}

ModalDelete.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default ModalDelete;