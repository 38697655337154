import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../components/Button";
import api from "../../../../api";

const PlanCopyModal = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [saving, setSaving] = React.useState(false);

  const copy = () => {
    setSaving(true);

    api.plan.copy(props.plan.id)
      .then(() => {
        props.onCopied();
        setSaving(false);
        setModalOpen(false);
      }).catch(() => {
        setSaving(false);
    })
  }

  return (
    <React.Fragment>
      <i className="fas fa-copy pointer p-1 mr-1" onClick={toggleModalOpen} />
      {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
        <ModalHeader toggle={toggleModalOpen}>Utwórz kopię planu</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <p>Czy na pewno utworzyć koppię planu <b>{props.plan.name}</b>?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
          <Button
            color="primary"
            loading={saving}
            onClick={copy}
          >Kopiuj</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

PlanCopyModal.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onCopied: PropTypes.func.isRequired,
};

export default PlanCopyModal;