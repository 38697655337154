import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {InputGroupAddon, Tooltip, Button} from 'reactstrap';
import classnames from "classnames";
import InputNumber from "components/InputNumber";

class TimeMinMax extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeMinTooltip: false,
      timeMaxTooltip: false,
      page: {
        timeMin: props.page.timeMin,
        timeMax: props.page.timeMax,
      }
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip(type) {
    this.setState({
      ...this.state,
      [type]: !this.state[type]
    });
  }

  onChange(value, target) {
    if (!value || value < 0) {
      value = 0;
    }

    this.setState({
      ...this.state,
      page: {
        ...this.state.page,
        [target]: value
      }
    }, () => {
      this.props.handleUpdate(this.state.page)
    });
  }

  render() {
    const {timeMinTooltip, timeMaxTooltip, page} = this.state;
    const {id} = this.props.page;

    return (
      <React.Fragment>
        <div
          id={"timeMin-" + this.props.page.id}
          className={classnames({
            "min-w-0 float-left mr-1 mb-1": true,
            "w-auto": page.timeMin === 0,
            "w-100 w-lg-auto input-group": page.timeMin > 0
          })}
        >
          <InputGroupAddon addonType="prepend">
            <Button
              className={classnames({
                "btn-icon": true,
                "active": page.timeMin > 0,
              })}
              color="secondary"
              onClick={() => {
                if(page.timeMin === 0){
                  this.onChange(1, 'timeMin')
                }else{
                  this.onChange(0, 'timeMin')
                }
              }}
            >
              <i className="fak fa-stopwatch-min"/>
              <Tooltip
                delay={0}
                placement="top"
                isOpen={timeMinTooltip}
                target={"timeMin-" + id}
                toggle={() => this.toggleTooltip('timeMinTooltip')}
              >
                <FormattedMessage id="page.settings.minTime.button.tooltip"/>
              </Tooltip>
            </Button>
          </InputGroupAddon>
          {page.timeMin > 0 && <InputNumber value={page.timeMin} min={0} max={3600} onChange={(value) => this.onChange(value, 'timeMin')}/>}
        </div>
        <div
          id={"timeMax-" + this.props.page.id}
          className={classnames({
            "min-w-0 float-left mr-1 mb-1": true,
            "w-auto": page.timeMax === 0,
            "w-100 w-lg-auto input-group": page.timeMax > 0
          })}
        >
          <InputGroupAddon addonType="prepend">
            <Button
              className={classnames({
                "btn-icon": true,
                "active": page.timeMax > 0,
              })}
              color="secondary"
              onClick={() => {
                if(page.timeMax === 0){
                  this.onChange(1, 'timeMax')
                }else{
                  this.onChange(0, 'timeMax')
                }
              }}
            >
              <i className="fak fa-stopwatch-max"/>
              <Tooltip
                delay={0}
                placement="top"
                isOpen={timeMaxTooltip}
                target={"timeMax-" + id}
                toggle={() => this.toggleTooltip('timeMaxTooltip')}
              >
                <FormattedMessage id="page.settings.maxTime.button.tooltip"/>
              </Tooltip>
            </Button>
          </InputGroupAddon>
          {page.timeMax > 0 && <InputNumber value={page.timeMax} min={0} max={3600} onChange={(value) => this.onChange(value, 'timeMax')}/>}
        </div>
      </React.Fragment>
    );
  }
}

TimeMinMax.propTypes = {
  page: PropTypes.shape({
    timeMin: PropTypes.number.isRequired,
    timeMax: PropTypes.number.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default TimeMinMax;
