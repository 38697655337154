import {
  PLAN_UPDATED,
  USER_PROFILE_UPDATED,
  COLUMN_UPDATED,
  GROUP_NAME_UPDATED,
  COLUMN_DELETED,
  COLUMN_ADDED,
  FAVORITE_QUESTIONS_UPDATED,
  SUBSCRIPTION_CANCELED
} from "./actions";
import update from 'immutability-helper';
import * as widgetsActions from "scenes/Dashboard/scenes/Widgets/data/widgets/actions";
import * as surveyActions from "scenes/Survey/Structure/data/survey/actions";

export const USER_LOGGED_IN = 'User/LOGGED_IN';
export const USER_LOGGED_OUT = 'User/USER_LOGGED_OUT';

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  user
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
  user: {}
});

const initialState = localStorage.jwt ? { token: localStorage.jwt } : {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case USER_LOGGED_IN:
      return action.user;

    case USER_LOGGED_OUT:
      return action.user;

    case USER_PROFILE_UPDATED:
      return {
        ...action.user,
        widgets: state.widgets,
      };

    case PLAN_UPDATED:
      return {
        ...state,
        userPlan: action.userPlan
      };

    case FAVORITE_QUESTIONS_UPDATED:
      return {
        ...state,
        favoriteQuestionTypes: action.favoriteQuestions
      };

    case COLUMN_ADDED:
      return update(state, {
        respondentBaseColumns: {
          [action.respondentBaseColumn.id]: {
            $set: action.respondentBaseColumn
          }
        }
      });

    case COLUMN_UPDATED:
      return update(state, {
        respondentBaseColumns: {
          [action.respondentBaseColumn.id]: {
            $set: action.respondentBaseColumn
          }
        }
      });

    case COLUMN_DELETED:
      return update(state, {
        respondentBaseColumns: {
          $set: _.filter(state.respondentBaseColumns, column => column.id !== action.columnId)
        }
      });

    case widgetsActions.ADDED:
      return update(state, {
        widgets: {
          $push: [action.widget.id]
        }
      });

    case widgetsActions.DELETED:
      return update(state, {
        widgets: {
          $set: state.widgets.filter(id => id !== action.widget.id)
        }
      });

    case GROUP_NAME_UPDATED:
      return update(state, {
        userGroup: {
          $set: action.groupName
        }
      });

    case surveyActions.TEMPLATE_ADDED:
      return update(state, {
        surveyTemplates: {
          $push: [action.template]
        }
      });

    case surveyActions.TEMPLATE_DELETED:
      return update(state, {
        surveyTemplates: {
          $set: state.surveyTemplates.filter(template => template.id !== action.templateId)
        }
      });

    case SUBSCRIPTION_CANCELED:
      return update(state, {
        userPlan: {
          isSubscribed: {
            $set: false
          }
        }
      });

    default:
      return state;
  }
};

export default reducer;