import React, {Component} from 'react';
import PropTypes from "prop-types";
import ModalDelete from "./components/ModalDelete";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import ModalCopy from "./components/ModalCopy";
import {FormattedMessage} from "react-intl";
import ModalAutoPagination from "./components/ModalAutoPagination";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import PagesAvailable from "components/PlanRestrictionsModals/modals/PagesAvailable";

class HeaderActions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownSettingsOpen: false,

      modalCopy: false,
      modalAutoPagination: false,
      modalDelete: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(type) {
    this.setState({
      [type]: !this.state[type]
    })
  }

  render() {
    const {page, optionsOpen, surveyPlan} = this.props;

    return (
      <ul className="float-right mb-0 pl-0 py-0">
        <li className="float-left">
          <Dropdown isOpen={this.state.dropdownSettingsOpen} toggle={() => this.toggle('dropdownSettingsOpen')}>
            <DropdownToggle className="m-0 p-0" color="">
              <span className="fas fa-ellipsis-v hoverable" />
            </DropdownToggle>
            {this.state.dropdownSettingsOpen && <DropdownMenu>
              <DropdownItem tag="a" onClick={() => surveyPlan.pages ? this.props.handleToggleOptions() : this.props.setPlanRestrictionsModals(PagesAvailable.getName())} className={optionsOpen ? 'text-primary' : ''}>
                <p><span className="fas fa-cog" style={optionsOpen ? {filter: 'none'} : {}}/></p> <FormattedMessage tagName="h5" id="page.headerActions.dropdownItem.settings"/>
              </DropdownItem>

              <DropdownItem tag="a" onClick={() => surveyPlan.pages ? this.toggle('modalAutoPagination') : this.props.setPlanRestrictionsModals(PagesAvailable.getName())}>
                <p><span className="fa-solid fa-rectangle-history"/></p> <FormattedMessage tagName="h5" id="page.headerActions.dropdownItem.autoPagination"/>
              </DropdownItem>

              <DropdownItem tag="a" onClick={() => surveyPlan.pages ? this.toggle('modalCopy') : this.props.setPlanRestrictionsModals(PagesAvailable.getName())}>
                <p><span className="fas fa-copy"/></p> <FormattedMessage tagName="h5" id="page.headerActions.dropdownItem.copy"/>
              </DropdownItem>

              <DropdownItem tag="a" onClick={() => this.toggle('modalDelete')}>
                <p><span className="fas fa-trash"/></p> <FormattedMessage tagName="h5" id="page.headerActions.dropdownItem.delete"/>
              </DropdownItem>
            </DropdownMenu>}
          </Dropdown>
          {this.state.modalAutoPagination && <ModalAutoPagination page={page} isOpen={this.state.modalAutoPagination} toggleModal={() => this.toggle('modalAutoPagination')}/>}
          {this.state.modalCopy && <ModalCopy page={page} isOpen={this.state.modalCopy} toggleModal={() => this.toggle('modalCopy')}/>}
          {this.state.modalDelete && <ModalDelete page={page} isOpen={this.state.modalDelete} toggleModal={() => this.toggle('modalDelete')}/>}
        </li>
      </ul>
    )
  }
}

HeaderActions.propTypes = {
  page: PropTypes.object.isRequired,
  handleToggleOptions: PropTypes.func.isRequired,
  optionsOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey,
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(HeaderActions);

