import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Form,FormGroup,Label,Col,Input,FormFeedback} from "reactstrap";
import api from "api";
import Select from "react-select";
import SelectQuestions from "../../../../../../Structure/components/SelectQuestions";
import {CrossTypeSelect, typeOptions} from "../ButtonCreate";
import DemographyValuesTable from "../ButtonCreate/DemographyValuesTable";

const demographyOptions = [
  {
    value: 'demography_age',
    label: 'Wiek respondenta',
  },
  {
    value: 'demography_sex',
    label: 'Płeć respondenta',
  },
  {
    value: 'demography_voivodeship',
    label: 'Województwo respondenta',
  },
  {
    value: 'demography_city_lm',
    label: 'Wielkość miejscowości respondenta',
  },
  {
    value: 'demography_education',
    label: 'Wykształcenie respondenta',
  },
  {
    value: 'demography_respondent_income',
    label: 'Dochód respondenta',
  },
]

export const DemographyTypeSelect = (props) => {
  const [value, setValue] = React.useState(props.value);

  return <Select
    options={demographyOptions}
    onChange={option => {
      setValue(option.value);
      props.onChange(option.value)
    }}
    value={demographyOptions.find(o => o.value === value)}
  />
}


class AdvancedForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      saving: false,
      errors: {},
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  changeData(field, value){
    this.setState({
      data: {
        ...this.state.data,
        [field]: value,
      }
    })
  }

  submit(e){
    e.preventDefault();
  }

  save(){
    const {data} = this.state;

    this.props.onSavingStart();

    this.setState({
      errors: {},
    })

    api.analysis.advanced.patch(this.props.data.id, data)
    .then(data => {
      this.props.onSavingStop();

      this.props.onSaved(data.data);
    })
    .catch(res => {
      this.props.onSavingStop();

      this.setState({
        errors: res.response.data
      })
    })
  }

  render() {
    const {data, errors} = this.state;

    console.log('data', data);

    const selectedType = typeOptions.find(type => type.value === data.type) || false;

    return <Form>
      <FormGroup row className="mb-2">
        <Label md={3}>Pytanie: </Label>
        <Col md={9}>
          <SelectQuestions
            isMulti={false}
            value={data.question}
            onChange={question => this.setState({data: {...this.state.data, question,}})}
          />
          {_.has(errors, 'children.name.errors') && <FormFeedback>{errors.children.name.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row className="mb-2">
        <Label md={3}>Zakres danych:</Label>
        <Col md={9}>
          <Select
            placeholder={"Wybierz zakres danych"}
            isDisabled
            value={{
              label: <div className="d-flex flex-column flex-wrap">
                <span>{selectedType.label}</span>
                <small>{selectedType.description}</small>
              </div>,
              value: selectedType.value,
            }}
            options={typeOptions.map(type => {
              return {
                label: <div className="d-flex flex-column flex-wrap">
                  <span>{type.label}</span>
                  <small>{type.description}</small>
                </div>,
                value: type.value,
              }
            })}
            onChange={option => this.setState({
              data: {
                ...data,
                type: option.value,
                crossType: false,
                questionCrossed: false,
                demographyValues: {
                  demography_age: [],
                  demography_city_lm: [],
                },
              }
            })}
          />
        </Col>
      </FormGroup>
      {data.type === 'crossTable' && <FormGroup className="mt-2" row>
        <Label md={3}>Skrzyżuj z:</Label>
        <Col md={9}>
          <CrossTypeSelect
            value={data.crossType}
            demographyAllow={this.props.collectorPanelAccess}
            onChange={crossType => {
              this.setState({
                data: {
                  ...data,
                  questionCrossed: false,
                  crossType,
                }
              })
            }}
          />
        </Col>
      </FormGroup>}
      {data.crossType === 'question' && <FormGroup className="mt-2" row>
        <Label md={3}>Skrzyżuj z pytaniem:</Label>
        <Col md={9}>
          <SelectQuestions
            isMulti={false}
            value={data.questionCrossed}
            onChange={questionCrossed => this.setState({data: {...data, questionCrossed}})}
          />
        </Col>
      </FormGroup>}
      {(_.map(demographyOptions, o => o.value).includes(data.crossType) || data.crossType === 'demography') && <FormGroup className="mt-2" row>
        <Label md={3}>Wybierz demografie: </Label>
        <Col md={9}>
          <DemographyTypeSelect
            value={data.crossType}
            demographyAllow={this.props.collectorPanelAccess}
            onChange={crossType => {
              let demographyValues = [];
              if(crossType === 'demography_age'){
                demographyValues = [
                  {name: 'do 24 lat', valueFrom: 0, valueTo: 24},
                  {name: '25-34 lat', valueFrom: 25, valueTo: 34},
                  {name: '35-49 lat', valueFrom: 35, valueTo: 49},
                  {name: 'Powyżej 50 lat', valueFrom: 50, valueTo: 99},
                ];
              }
              if(crossType === 'demography_city_lm'){
                demographyValues = [
                  {name: 'wieś miasto do 20 tys.', valueFrom: 0, valueTo: 20000},
                  {name: 'miasto 20-99 tys.', valueFrom: 20001, valueTo: 99999},
                  {name: 'miasto 100-199 tys.', valueFrom: 100000, valueTo: 199999},
                  {name: 'miasto 200-499 tys.', valueFrom: 200000, valueTo: 499999},
                  {name: 'miasto powyżej 500tys.', valueFrom: 500000, valueTo: 9999999},
                ];
              }

              this.setState({
                data: {
                  ...data,
                  questionCrossed: false,
                  crossType,
                  demographyValues,
                }
              })
            }}
          />
        </Col>
      </FormGroup>}
      {['demography_age', 'demography_city_lm'].includes(data.crossType) && <FormGroup className="mt-2" row>
        <Col>
          <h1 className="text-center mt-3 w-100">Ustaw przedziały </h1>
          <DemographyValuesTable
            values={data.demographyValues}
            onChange={demographyValues => this.setState({data: {...data, demographyValues}})}
          />
        </Col>
      </FormGroup>}
    </Form>
  }
}

AdvancedForm.defaultProps = {
  data: {
    question: false,
    type: '',
    questionCrossed: false,
    crossType: '',
    demographyValues: [],
  },
};

AdvancedForm.propTypes = {
  data: PropTypes.object,
  onSaved: PropTypes.func,
  onSavingStart: PropTypes.func,
  onSavingStop: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    collectorPanelAccess: state.user.userPlan.plan.survey.collectorPanelAccess,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(AdvancedForm);