import React, {Component} from 'react';
import { connect } from 'react-redux';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {updateSurvey} from "scenes/Survey/Structure/data/survey/actions";
import {FormattedMessage} from "react-intl";
import HelpModeIconModal from "../../../../../../../components/HelpModeIconModal";

class SurveyPauseFilters extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.changeStatus = this.changeStatus.bind(this);
  }

  changeStatus() {
    this.setState({loading: true});

    const data = {
      id: this.props.survey.id,
      survey: {
        filtersPaused: !this.props.survey.filtersPaused
      }
    };

    this.props.updateSurvey(data)
      .then(() => this.setState({paused: !this.props.survey.filtersPaused, loading: false}))
  }

  render() {
    return <div className="float-right">
      <span className={"mr-2 small " + (this.props.survey.filtersPaused ? 'text-danger' : 'text-success')}><FormattedMessage id={"filter.surveyPauseFilters.text." + (this.props.survey.filtersPaused ? 'paused' : 'unpaused')}/> <HelpModeIconModal><FormattedMessage id="filter.surveyPauseFilters.helpIconText"/></HelpModeIconModal></span>
      <LaddaButton data-style={ZOOM_OUT} loading={this.state.loading} className={"btn-ladda btn btn-" + (this.props.survey.filtersPaused ? 'danger' : 'success')} onClick={this.changeStatus}><FormattedMessage id={this.props.survey.filtersPaused ? "survey.filtersPaused.button.true" : "survey.filtersPaused.button.false"}/></LaddaButton>
    </div>
  }
}

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey
  }
}

export default connect(mapStateToProps, {updateSurvey})(SurveyPauseFilters);