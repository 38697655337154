import React, {useEffect, useRef, useState} from 'react';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, FontFamily, FontSize, FontColor, FontBackgroundColor, Strikethrough,
	Code, Link, BlockQuote, Underline, Alignment, List, Indent, PastePlainText, PasteFromOffice, MediaEmbed, SourceEditing, Markdown,
	Image, GeneralHtmlSupport, FullPage, AutoLink, AutoImage, ImageInsert, ImageResizeEditing, ImageResizeHandles, ImageTextAlternative, ImageTextAlternativeEditing,
	ImageResize,
	ImageStyle,
	ImageToolbar,
	LinkImage, HtmlEmbed, HorizontalLine, SpecialCharacters, SpecialCharactersEssentials,
	ImageUpload, PictureEditing, CloudServices, CKFinder, CKFinderUploadAdapter
} from 'ckeditor5';
import {coreTranslations} from 'ckeditor5/build/translations/pl.js';
import {connect} from "react-redux";

const HtmlEditor = props => {
	const {user, plan} = props;

	const ref = useRef( null );
	const [ isMounted, setMounted ] = useState( false );
	const [ isCKFinder, setIsCKFinder ] = useState( false );
	let toolbarItems = [
		'undo', 'redo', 'bold', 'italic', 'underline', 'strikethrough',
		'|',
		'bulletedList', 'numberedList', 'outdent', 'indent', 'heading', 'alignment',
		'|',
		'link', 'insertImageViaUrl', 'ckfinder',
		'|',
		'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
		'|',
		'blockQuote', 'specialCharacters', 'horizontalLine', 'sourceEditing'
	];

	if (plan && plan.multimedia.access === false) {
		toolbarItems = toolbarItems.filter((item) => {
			return item !== 'ckfinder';
		})
	}

	useEffect( () => {
		setMounted( true );

		return () => {
			setMounted( false );
		};
	}, [] );

	return <div>
		{isMounted && <CKEditor
			ref={ref}
			onReady={(e) => {
				e.editing.view.focus();
				e.ui.focusTracker.on( 'change:isFocused', ( evt, name, isFocused ) => {
					setTimeout(() => {
						const isModal = document.getElementById('ckf-modal')

						if ( !isFocused && !isModal) {

							if (isCKFinder) {
								setIsCKFinder(false)
							} else {
								props.onChange(e.getData());
								if (props.onBlur) {
									props.onBlur();
									e.destroy();
								}
							}
						}
					})
				} );
			}}
			onChange={(e) => {
				props.onChange(ref.current.editor.getData());
			}}
			config={{
				language: {
					ui: "pl",
					content: "pl"
				},
				toolbar: {
					withText: true,
					items: toolbarItems,
				},
				plugins: [
					Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, FontFamily, FontSize, FontColor, FontBackgroundColor, ImageTextAlternative, ImageTextAlternativeEditing,
					Strikethrough, Code, Link, Image, BlockQuote, Alignment, List, Indent, PastePlainText, PasteFromOffice, Underline, HtmlEmbed, HorizontalLine,
					LinkImage, ImageStyle, ImageResize, ImageToolbar, ImageUpload, PictureEditing, CloudServices, SpecialCharacters, SpecialCharactersEssentials,
					CKFinder, CKFinderUploadAdapter, MediaEmbed, GeneralHtmlSupport, SourceEditing, FullPage, AutoLink, AutoImage, ImageInsert, ImageResizeEditing, ImageResizeHandles
				],
				ckfinder: {
					uploadUrl: '/ckfinder',
					options: {
						onInit: () => {
							setIsCKFinder(true)
						}
					}
				},
				fontSize: {
					options: [
						'default',
						8,
						9,
						10,
						11,
						12,
						13,
						14,
						16,
						18,
						20,
						22,
						24,
						26,
						28,
						38,
						48,
						58,
						72
					]
				},
				resizeOptions: [
					{
						name: 'resizeImage:original',
						value: null,
						label: 'Original'
					},
					{
						name: 'resizeImage:custom',
						label: 'Custom',
						value: 'custom'
					},
					{
						name: 'resizeImage:25',
						value: '25',
						label: '25%'
					},
					{
						name: 'resizeImage:50',
						value: '50',
						label: '50%'
					},
					{
						name: 'resizeImage:100',
						value: '100',
						label: '100%'
					}
				],
				htmlSupport: {
					allow: [
						{
							name: 'iframe',
							attributes: true,
							classes: true,
							styles: true
						}
					]
				},
				heading: {
					options: [
						{ model: 'paragraph', title: 'Normalny', class: 'ck-heading_paragraph' },
						{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
						{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
						{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
					]
				},
				image: {
					toolbar: [
						'resizeImage',
						'insertImageViaUrl',
						'ckfinder',
						'|',
						'imageStyle:inline',
						'imageStyle:block',
						'imageStyle:side',
						'|',
						'imageTextAlternative',
						'linkImage',
					],
					defaultItem: 'imageStyle:inline',
					upload: {
						types: [
							'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg'
						]
					},
					styles: [
						'inline'
					],
					insert: {
						type: 'auto'
					}
				},
				link: {
					defaultProtocol: 'https://',
					decorators: {
						openInNewTab: {
							mode: 'manual',
							label: 'Otwórz w nowym oknie',
							defaultValue: true,
							attributes: {
								target: '_blank',
								rel: 'noopener noreferrer'
							}
						}
					}
				},
				initialData: props.data
			}}
			editor={ClassicEditor}
		/>}
	</div>
};

function mapStateToProps(state, props) {
	return {
		user: state.user,
		plan: state.user.userPlan.plan
	}
}

export default connect(mapStateToProps, { })(HtmlEditor);
