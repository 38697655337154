import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from "striptags";
import api from "../../../../../../../../../api";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import Select from "react-select";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";
import SelectAnswers from "../../../../../../../Structure/components/SelectAnswers";

class ColumnTfoot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      excluding: false,
      answer: false,
      content: null,
      modalIsOpen: false,
    };

    this.getRow = this.getRow.bind(this);
    this.getColumn = this.getColumn.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.save = this.save.bind(this);
  }

  getRow(rowId){
    return this.props.question.rows.find(row => row.id === rowId);
  }

  getColumn(columnId){
    return this.props.question.columns.find(column => column.id === columnId);
  }

  modalToggle(row = false, column = false){
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
      row,
      column,
      excluding: false,
      answer: false,
    });
  }

  save(){
    const {row, column, answer, excluding} = this.state;
    const {question, responsesSelected} = this.props;

    this.setState({
      saving: true,
    });

    let data = {
      responses: responsesSelected,
      row,
      column,
      excluding,
      answer,
    };

    api.questionResponse.question.patchDetails(question.id, data)
    .then(data => {
      this.setState({
        saving: false,
        row: false,
        column: false,
        answer: false,
        excluding: false,
        modalIsOpen: false,
      });

      this.props.onSaved();
    })
    .catch(res => {
      this.setState({
        saving: false,
      });
    });
  }

  render() {
    const {modalIsOpen, row, column, saving} = this.state;
    const {responsesSelected, question} = this.props;

    return <React.Fragment>
      {question.rows.map((row, key) => {
        return <React.Fragment>
          {question.columns.map((column, keyColumn) => {
            return <React.Fragment key={key.toString() + keyColumn.toString()}>
              <td>
                <Button
                  onClick={() => this.modalToggle(row.id, column.id)}
                  disabled={responsesSelected.length === 0}
                  size="sm"
                  color="secondary"
                  className="w-100 text-center"
                >Zmień</Button>
              </td>
            </React.Fragment>
          })}
        </React.Fragment>
      })}

      {question.excludings.length > 0 && <td>
        <Button
          onClick={() => this.modalToggle(false)}
          disabled={responsesSelected.length === 0}
          size="sm"
          color="secondary"
          className="w-100 text-center"
        >Zmień</Button>
      </td>}

      {modalIsOpen && <Modal isOpen={true} toggle={this.modalToggle} size="xl">
        <ModalHeader tag="h2" toggle={this.modalToggle}>Zmień odpowiedź dla wybranych wywiadów</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label md={3}>Liczba zaznaczonych wywiadów:</Label>
              <Col md={9} className="d-flex align-items-center">{responsesSelected.length}</Col>
            </FormGroup>
            {row && column && <FormGroup row>
              <Label md={3}>Zmiana dotyczy odpowiedzi:</Label>
              <Col md={9} className="d-flex align-items-center">{striptags(this.getRow(row).content)} - {striptags(this.getColumn(column).content)}</Col>
            </FormGroup>}
            {(row && column) && <FormGroup row>
              <Label md={3}>W zaznaczonych wywiadach zmień odpowiedź na:</Label>
              <Col md={9} className="d-flex align-items-center">
                <SelectAnswers
                  isMulti={false}
                  questionId={question.id}
                  placeholder="Usuń odpowiedź lub wybierz aby zmienić"
                  className="w-100"
                  onChange={answer => this.setState({answer})}
                />
              </Col>
            </FormGroup>}
            {(!row && !column) && <FormGroup row>
              <Label md={3}>W zaznaczonych wywiadach zmień na odpowiedź wykluczającą:</Label>
              <Col md={9} className="d-flex align-items-center">
                <SelectExcludings
                  questionId={question.id}
                  className="w-100"
                  onChange={excluding => this.setState({excluding: excluding || false})}
                />
              </Col>
            </FormGroup>}
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={this.modalToggle}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mb-0"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={() => this.save()}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>}
    </React.Fragment>;
  }
}

ColumnTfoot.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['matrixMulti']),
    rows: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
    columns: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
  }).isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default ColumnTfoot;