import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Single from "./Single";
import {Alert} from "reactstrap";
import Multi from "./Multi";
import Ranked from "./Ranked";
import Open from "./Open";
import MultiOpen from "./MultiOpen";

class QuestionFrom extends PureComponent {
  constructor(props) {
    super(props);

    this.getByType = this.getByType.bind(this);
  }

  getByType(){
    const {errors, question, configQuestionFrom, questionFrom, onChange} = this.props;

    const props = {
      errors,
      question,
      configQuestionFrom,
      questionFrom,
      onChange,
    };

    switch (question.type) {
      case 'single':
        return <Single {...props} />;
      case 'multi':
        return <Multi {...props} />;
      case 'ranked':
        return <Ranked {...props} />;
      case 'open':
        return <Open {...props} />;
      case 'multiOpen':
        return <MultiOpen {...props} />;
      default:
        return <Alert color="warning" className="text-center">Brak obsługi tego typu pytania</Alert>;
    }
  }

  render() {
    return this.getByType();
  }
}

QuestionFrom.propTypes = {
  errors: PropTypes.object.isRequired,
  configQuestionFrom: PropTypes.object.isRequired,
  question: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  questionFrom: PropTypes.shape({
    question: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default QuestionFrom;