import React, {Component} from 'react';
import PropTypes from "prop-types";
import SettingBool from "../../../SettingBool";
import VideoPlayerDimensions from "../../../VideoPlayerDimensions";

class VideoSettings extends Component {

  render() {
    const {question} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'required'} value={question.required} icon={<i className="fa-solid fa-message-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'autoPlay'} value={question.autoPlay} icon={<i className="fas fa-play" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'autoNextPage'} value={question.autoNextPage} icon={<i className="fas fa-arrow-right" />} handleUpdate={this.props.handleUpdate}/>
        <VideoPlayerDimensions question={question} handleUpdate={this.props.handleUpdate} errors={this.props.errors}/>
      </React.Fragment>
    )
  }
}

VideoSettings.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

export default VideoSettings;
