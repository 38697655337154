import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {updateSmtp} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import PropTypes from 'prop-types';
import SmtpsForm from './SmtpsForm';

class SmtpsButtonEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false,
      errors: []
    };

    this.saveSmtp = this.saveSmtp.bind(this);
    this.smtpsForm = React.createRef();
  }

  saveSmtp(e, data){
    e.preventDefault();
    this.setState({saving: true});

    this.props.updateSmtp({
      id: this.props.smtp.id,
      data: data
    })
      .then(() => {
      this.setState({
        modal: false,
        saving: false
      })
    })
      .catch((e) => {
        if(e.response.data.form.errors){
          this.setState({
            saving: false,
            errors: e.response.data.form.errors,
          });
        }
      });
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { saving, errors } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} size="lg">
      <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id="smtpsButtonEdit.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <SmtpsForm errors={errors} onSubmit={this.saveSmtp} ref={this.smtpsForm} smtp={this.props.smtp}/>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.save">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={e => this.saveSmtp(e, this.smtpsForm.current.state)}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggleModal()}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="m-0 p-0" onClick={() => this.toggleModal()}><i className="fas fa-edit"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

SmtpsButtonEdit.propTypes = {
  smtp: PropTypes.object.isRequired
};

export default connect(null, { updateSmtp }, null, {forwardRef: true})(SmtpsButtonEdit);