import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import api from "api";
import {Col, Form, Label, Row} from "reactstrap";
import FormGroup from "reactstrap/es/FormGroup";
import StatusesSelect from "./StatusesSelect";
import CollectorsSelect from "./CollectorsSelect";
import QuestionsSelect from "./QuestionsSelect";
import DateFromTo from "./DateFromTo";
import Switch from "../../Switch";


class CreateForm extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        filterStatus: [3],
        filterCollectors: [],
        visibleQuestions: [],
        filterDate: {},
        includeCollectorTest: false,
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handlerOnChangeStatuses = this.handlerOnChangeStatuses.bind(this);
    this.handlerOnChangeCollectors = this.handlerOnChangeCollectors.bind(this);
    this.handlerOnChangeQuestions = this.handlerOnChangeQuestions.bind(this);
    this.handlerOnChangeDateFromTo = this.handlerOnChangeDateFromTo.bind(this);
  }

  handlerOnChangeStatuses(filterStatus){
    this.setState({
      data: {
        ...this.state.data,
        filterStatus,
      }
    });
  }

  handlerOnChangeCollectors(filterCollectors){
    this.setState({
      data: {
        ...this.state.data,
        filterCollectors: filterCollectors.map(collector => {
          return {
            collector,
          };
        }),
      }
    });
  }

  handlerOnChangeQuestions(questions){
    this.setState({
      data: {
        ...this.state.data,
        visibleQuestions: questions,
      }
    });
  }

  handlerOnChangeDateFromTo(name, value){
    switch(name){
      case 'dateFrom':
        name = 'startDate';
        break;
      case 'dateTo':
        name = 'endDate';
        break;
    }

    this.setState({
      data: {
        ...this.state.data,
        filterDate: {
          ...this.state.data.filterDate,
          [name]: value ? value.format('YYYY-MM-DD HH:mm') : null
        }
      }
    })

  }

  onSubmit(){
    const {survey, analysisFiltering} = this.props;
    let {data} = this.state;

    if(!analysisFiltering){
      data = {};
    }

    this.props.onCreating();

    api.survey.post.analysis({
      survey: survey.id
    }, data, true).then(analysis => {
      this.props.onCreated(analysis);
    }).catch(() => {
      this.props.onError();
    });
  }

  render() {
    const {data} = this.state;
    const {survey, analysisFiltering} = this.props;

    return (
      <Form>
        {analysisFiltering && <Row>
          <Col md={6}>
            <FormGroup row>
              <Label lg={4}>Statusy wywiadów</Label>
              <Col lg={8}>
                <StatusesSelect value={data.filterStatus} onChange={this.handlerOnChangeStatuses}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={4}>Kolektory danych</Label>
              <Col lg={8}>
                <CollectorsSelect placeholder={'Wszystkie'} survey={survey} onChange={this.handlerOnChangeCollectors}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={4}>Zakres czasu</Label>
              <Col lg={8}>
                <DateFromTo dateFrom={data.filterDate.startDate} dateTo={data.filterDate.endDate} onChange={this.handlerOnChangeDateFromTo} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={4}>Dołącz dane z testowych kolektorów</Label>
              <Col lg={8} className="pt-3">
                <Switch checked={data.includeCollectorTest} onChange={includeCollectorTest => this.setState({data: {...data, includeCollectorTest}})} />
              </Col>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label>Wybierz pytania</Label>
              <Col>
                <QuestionsSelect survey={survey} onChange={this.handlerOnChangeQuestions} />
              </Col>
            </FormGroup>
          </Col>
        </Row>}
      </Form>
    );
  }
}

CreateForm.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onCreating: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    analysisFiltering: state.user.userPlan.plan.analysis.filtering,
  }
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(CreateForm);