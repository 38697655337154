import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import UserRoute from "routes/UserRoute";
import appRoutes from "../../appRoutes";

import PropTypes from "prop-types";
import SurveyStructure from "scenes/Survey/Structure";
import SurveyPreview from "scenes/Survey/Preview";
import SurveyCollectors from "scenes/Survey/Collectors";
import SurveyCollectorsType from "scenes/Survey/CollectorsType";
import SurveyCollectorPanelRealizationAutomate from "scenes/Survey/SurveyCollectorPanelRealizationAutomate";
import SurveyCollector from "scenes/SurveyCollector";
import SurveyAnalysis from "scenes/Survey/Analysis";
import SummaryView from "scenes/Survey/Summary/SummaryView";
import Reports from "scenes/Survey/Reports";
import SurveyResponseFakers from "scenes/Survey/SurveyResponseFakers";
import SurveyValidationView from "scenes/Survey/SurveyValidationView";
import SurveyCollectorsPanelEmailStats from "scenes/Survey/SurveyCollectorsPanelEmailStats";
import BulkEdit from "scenes/Survey/Collectors/components/BulkEdit/BulkEdit";
import Header from "../../components/Header";
import {connect} from "react-redux";
import KeyboardShortcutsInfo from "../../components/KeyboardShortcutsInfo";
import NotFound from "../../components/NotFound";
import Footer from "../../components/Footer";
import classnames from "classnames";

class SurveyContainer extends Component {

  render() {
    const {isAuthenticated, location, dashboardOptions} = this.props;

    return <React.Fragment>
      <div className="d-grid" style={{height: "100vh", gridTemplateRows: "auto 1fr auto"}}>
        {isAuthenticated && <Header location={location}/>}

        <div id="dashboard" className={classnames({
          "force-print": dashboardOptions.forcePrint,
          "allow-print-table": dashboardOptions.allowPrintTable
        })} style={{overflowY: "scroll", overflowX: "hidden"}}>
          <Switch>
            <UserRoute exact path={appRoutes.survey.summary()} component={SummaryView}/>

            <UserRoute exact path={appRoutes.survey.structure()} component={SurveyStructure}/>

            <UserRoute exact path={appRoutes.survey.preview.main()} component={SurveyPreview}/>
            <UserRoute exact path={appRoutes.survey.preview.question()} component={SurveyPreview}/>

            <UserRoute exact path={appRoutes.survey.collectors.list()} component={SurveyCollectors}/>
            <UserRoute exact path={appRoutes.survey.collectors.listType()} component={SurveyCollectorsType}/>
            <UserRoute exact path={appRoutes.survey.collectors.listPanelRealizationAutomate()} component={SurveyCollectorPanelRealizationAutomate}/>
            <UserRoute exact path={appRoutes.survey.collectors.collector.panel.edit()} component={BulkEdit}/>
            {/*<UserRoute exact path={appRoutes.survey.collectors.collector.panel.mobilePush()} component={BulkEdit}/>*/}
            <UserRoute exact path={appRoutes.survey.collectors.collector.panel.bulkSendEmail()} component={BulkEdit}/>
            <UserRoute exact path={appRoutes.survey.collectors.collector.panel.bulkSettings()} component={BulkEdit}/>
            <UserRoute path={appRoutes.survey.collectors.collector.view()} component={SurveyCollector}/>

            <UserRoute exact path={appRoutes.survey.analysis.main()} component={SurveyAnalysis}/>
            <UserRoute exact path={appRoutes.survey.analysis.questionSummaries()} component={SurveyAnalysis}/>
            <UserRoute exact path={appRoutes.survey.analysis.browse()} component={SurveyAnalysis}/>
            <UserRoute exact path={appRoutes.survey.analysis.browseResponse()} component={SurveyAnalysis}/>
            <UserRoute exact path={appRoutes.survey.analysis.modify()} component={SurveyAnalysis}/>
            <UserRoute exact path={appRoutes.survey.analysis.advanced()} component={SurveyAnalysis}/>
            <UserRoute exact path={appRoutes.survey.analysis.dataProcessing()} component={SurveyAnalysis}/>
            <UserRoute exact path={appRoutes.survey.analysis.responsesOperations()} component={SurveyAnalysis}/>

            <UserRoute exact path={appRoutes.survey.reports()} component={Reports}/>
            <UserRoute exact path={appRoutes.survey.surveyResponseFakers()} component={SurveyResponseFakers}/>
            <UserRoute exact path={appRoutes.survey.surveyValidation()} component={SurveyValidationView}/>
            <UserRoute exact path={appRoutes.survey.collectorsPanelEmailStats()} component={SurveyCollectorsPanelEmailStats}/>

            <Route component={NotFound}/>
          </Switch>
          <KeyboardShortcutsInfo />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  }
}

SurveyContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    dashboardOptions: state.dashboardOptions,
    isAuthenticated: Object.keys(state.user).length > 0,
  }
}

export default connect(mapStateToProps)(SurveyContainer);
