import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import { Col, Form, FormGroup, Label} from "reactstrap";
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import { connect } from 'react-redux';

class EditForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      response: {
        status: props.response.status,
      }
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      response: {
        ...this.state.response,
        [e.target.name]: e.target.value
      }
    });
  }

  render() {
    const {response} = this.state;
    const {errors, onSubmit, statuses} = this.props;

    const statusOptions = _.map(statuses, status => {
      return {
        label: <FormattedMessage id={"admin.responses.status-" + status} />,
        value: status
      }
    });

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.responses.editForm.label.status" /></Label>
          <Col lg={9} className="pt-1">
            <Select
              isClearable={false}
              value={_.find(statusOptions, option => option.value === response.status)}
              options={statusOptions}
              onChange={e => this.onChange({target: {name: 'status', value: e.value}})}
            />
            {errors.status && <p className="help-block text-danger">{errors.status}</p>}
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

EditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  response: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired,
};

export default connect(null, {}, null, {forwardRef: true})(EditForm);