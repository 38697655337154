import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import TransactionsList from "./components/TransactionsList";

class PlansTransactions extends Component {
  render() {
    return <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader><h2 className="float-left mr-4">Transakcje</h2></CardHeader>
          <hr className="my-0" />
          <CardBody>
            <Row>
              <Col>
                <TransactionsList />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>;
  }
}

export default PlansTransactions;