import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Label} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Switch from "components/Switch";
import Select from "react-select";
import { connect } from 'react-redux';

class TabMultiOpen extends PureComponent {

  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);
  }

  onChange(key, value) {
    let data = this.props.data.survey.questionSettings.multiOpen;
    data[key] = value;

    this.props.onChangeQuestionSettings('multiOpen', data);
  }

  render() {
    const {errors, data, questionConfig} = this.props;

    return (
      <React.Fragment>
        <FormGroup>
          <Label><FormattedMessage id="_.question.multiOpen.presentationType" /></Label>
          <Select
            isMulti
            value={_.map(data.survey.questionSettings.multiOpen.presentationTypes, type => {
              return {
                label: <FormattedMessage id={'_.question.multiOpen.presentationType.' + type} />,
                value: type,
              }
            })}
            onChange={e => {
              this.onChange('presentationTypes', e.map(val => val.value))
            }}
            options={_.map(questionConfig.presentationTypes, type => {
              return {
                label: <FormattedMessage id={'_.question.multiOpen.presentationType.' + type} />,
                value: type,
              }
            })}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}


TabMultiOpen.propTypes = {
  data: PropTypes.shape({
    survey: PropTypes.shape({
      questionSettings: PropTypes.shape({
        multiOpen: PropTypes.object.isRequired,
      }).isRequired
    }).isRequired,
  }),
  errors: PropTypes.object.isRequired,
  onChangeQuestionSettings: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    questionConfig: state.config.data.questions.multiOpen
  }
}

export default connect(mapStateToProps)(TabMultiOpen);
