import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Table, Input} from "reactstrap";

class DemographyValuesTable extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      count: props.values.length,
      values: props.values,
    };

    this.addValue = this.addValue.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  addValue(){
    let {values} = this.state;

    values.push({
      name: '',
      valueFrom: 0,
      valueTo: 0,
    });

    this.setState({
      values,
      count: values.length,
    })
  }

  onChange(rowKey, keyName, value){
    let {values} = this.state;

    values[rowKey][keyName] = value;

    this.setState({
      values,
    }, () => {
      this.props.onChange(values);
    })
  }

  onRemove(key){
    let {values} = this.state;

    values = values.filter((v, k) => k !== key);

    this.setState({
      values,
      count: values.length,
    }, () => this.props.onChange(values))
  }

  render() {
    const {values, count} = this.state;

    return <Table bordered size="sm">
      <thead>
      <tr>
        <th>Nazwa przedziału</th>
        <th>Wartość od</th>
        <th>Wartość do</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {values.map((value, key) => <tr key={key.toString()}>
        <td>
          <Input type="text" size="sm" value={value.name} className="mb-0" placeholder="Nazwa przedziału" onChange={e => this.onChange(key, 'name', e.target.value)} />
        </td>
        <td>
          <Input type="text" size="sm" value={value.valueFrom} className="mb-0" placeholder="Wartość od..." onChange={e => this.onChange(key, 'valueFrom', e.target.value)} />
        </td>
        <td>
          <Input type="text" size="sm" value={value.valueTo} className="mb-0" placeholder="Wartość do..." onChange={e => this.onChange(key, 'valueTo', e.target.value)} />
        </td>
        <td><i className="fas fa-times mx-auto text-danger pointer p-2" onClick={() => this.onRemove(key)} /></td>
      </tr>)}
      {values.length === 0 && <tr>
        <td colSpan={4} className="text-center"><i>Brak przedziałów</i></td>
      </tr>}
      </tbody>
      <tfoot>
      <tr>
        <td colSpan={2}>
          <Button color="primary" onClick={() => this.addValue()}><i className="fas fa-plus" /> Dodaj kolejny przedział</Button>
        </td>
        <td colSpan={2}>
          {count && <span className="d-block ml-auto mb-0 text-right mt-2">Liczba przedziałów: {count}</span>}
        </td>
      </tr>
      </tfoot>
    </Table>
  }
}

DemographyValuesTable.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DemographyValuesTable;