import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../componentsReusable/Button";
import api from "../../../../api";
import Icon from "../../../../componentsReusable/Icon";

const UserPrizeVerificationResendModal = props => {
  const [saving, setSaving] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  const send = () => {
    setSaving(true)

    api.userPrize.resendVerificationMail(props.userPrize.id)
      .then(() => {
        setModalOpen(false)
      })
      .finally(() => setSaving(false))
  }

  return <React.Fragment>
    <Icon tooltip={'Wyślij ponownie maila weryfikacyjnego'} className="fas fa-paper-plane cursor-pointer mr-1 p-1" onClick={toggleModalOpen} />
    {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
      <ModalHeader toggle={toggleModalOpen}>Wyślij maila weryfikującego</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <span>Potwierdź wysyłkę maila weryfikującego wymianę punktów na nagrodę</span>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
        <Button
          color="primary"
          onClick={send}
          loading={saving}
        >Wyślij</Button>
      </ModalFooter>
    </Modal>}
  </React.Fragment>;
};

UserPrizeVerificationResendModal.propTypes = {

};

export default UserPrizeVerificationResendModal;
