import React from 'react';
import Button from "../../../../../components/Button";
import {ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import SurveyFilterTextarea from "./SurveyFilterTextarea";
import Action from "../../../Structure/components/Filter/Action";
import Block from "../../../Structure/components/Filter/Block";
import {addFilter} from "../../../Structure/data/filters/actions";
import {connect} from "react-redux";
import _ from "lodash";
import FilterSerialCond from "./components/FilterSerialCond";
import FilterSerialAction from "./components/FilterSerialAction";
import FilterIterationCond from "./components/FilterIterationCond";
import FilterIterationActions from "./components/FilterIterationActions";
import {addFilterIterative} from "../../../Structure/data/filterIterative/actions";
import {addFilterSerial} from "../../../Structure/data/filterSerial/actions";
import FilterFromTextareaModal from "./FilterFromTextareaModal";

SurveyFilterFromTextarea.propTypes = {

};

function SurveyFilterFromTextarea(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  return (
    <React.Fragment>
      <Button className="ml-2" onClick={() => setModalOpen(!modalOpen)}><i className="fas fa-plus" /> Utwórz z pola tekstowego</Button>
      {modalOpen && <FilterFromTextareaModal
        onClose={toggleModalOpen}
      />}
    </React.Fragment>
  );
}


function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps, {addFilter, addFilterSerial, addFilterIterative})(SurveyFilterFromTextarea);