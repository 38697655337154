import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Tooltip } from "reactstrap";
import Select from 'react-select';
import { connect } from 'react-redux';
import {updateQuestion} from "scenes/Survey/Structure/data/questions/actions";
import classnames from "classnames";

class AnswersColumns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      saving: false,
      question: {
        answersColumns: props.question.answersColumns
      }
    };
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  };

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(e) {
    const { question } = this.props;
    this.setState({
      saving: true,
      question: {
        answersColumns: e.value
      }
    });
    this.props.updateQuestion({
      id: question.id,
      question: {
        answersColumns: e.value
      }
    }).then(() => {
      this.setState({
        saving: false
      });
    });

  }

  render() {
    const { question, tooltip, saving } = this.state;

    const options = [
        {label: 1, value: 1},
        {label: 2, value: 2},
        {label: 3, value: 3},
        {label: 4, value: 4},
        {label: 6, value: 6}
    ];

    return (
      <div
        id={"answersColumns-" + this.props.question.id}
        className={classnames({
          "min-w-0 float-left mr-1 mb-1": true,
          "w-auto": !question.answersColumns,
          "w-100 w-lg-auto input-group": question.answersColumns
        })}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon active": true,
            })}
            color="secondary"
          >
          <i className="fa-solid fa-line-columns"/>
            <Tooltip
              delay={0}
              placement="right"
              isOpen={tooltip}
              target={"answersColumns-" + this.props.question.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="question.settings.answersColumns.button.tooltip" />
            </Tooltip>
          </Button>
        </InputGroupAddon>
        <Select
          classNamePrefix="react-select"
          className="react-select float-left"
          style={{zIndex: 123}}
          isClearable={false}
          isLoading={saving}
          isDisabled={saving}
          onChange={this.onChange}
          value={{label: question.answersColumns, value: question.answersColumns}}
          options={options}
        />
      </div>
    )
  }
}

AnswersColumns.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answersColumns: PropTypes.number.isRequired
  }).isRequired,
  updateQuestion: PropTypes.func.isRequired
};

export default connect(null, { updateQuestion })(AnswersColumns);
