import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Input} from "reactstrap";
import classnames from "classnames";
import { connect } from 'react-redux';
import {updateSurvey} from '../../../Structure/data/survey/actions';

class TextSetting extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.survey[props.property] || '',
    };

    this.updateValue = this.updateValue.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.submit = this.submit.bind(this);
  }

  updateValue(e){
    this.setState({
      value: e.target.value
    });
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.submit()
  }

  submit() {
    const data = {
      id: this.props.survey.id,
      survey: {
        [this.props.property]: this.state.value
      }
    };

    this.props.updateSurvey(data)
      .then(() => this.props.handlerUpdate({}))
  }

  render() {
    const {value} = this.state;

    return (
      <Row className={classnames({
        'py-1 px-3 align-items-center': true,
        'inactive': value.length === 0,
      })}>
        <label className="col col-4 d-flex align-items-center"><span>{this.props.label}</span></label>
        <Col className="col-8">
          <Input className="mb-0" value={value} onChange={this.updateValue} onBlur={this.submit} onKeyPress={this.handleKeyPress}/>
        </Col>
      </Row>
    );
  }
}

TextSetting.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey
  }
}

export default connect(mapStateToProps, {updateSurvey})(TextSetting);