import React, {Component} from 'react';
import {Button, ButtonGroup} from "reactstrap";
import api from "api";

import {surveyLink} from "services/surveyLink";
import LoadingSpinner from "components/LoadingSpinner";
import SurveyOwnStyles from "../../SurveyOwnStyles";
import {toast} from "react-toastify";
import appRoutes from "appRoutes";
import Tooltip from "../../../../components/Tooltip";
import {Row, Col} from "reactstrap";
import './preview.scss';

class Preview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      iframe: true,
      width: window.innerWidth > 1200 ? 'desktop-mobile' : '100%',
      id: null,
      guid: false,
      sizes: [
        {
          icon: <span><i className="fas fa-desktop mr-2"/><i className={"fas fa-mobile-alt"} /></span>,
          buttonClass: "d-none d-xl-inline-block",
          width: 'desktop-mobile'
        },
        {
          icon: <i className="fas fa-desktop"/>,
          width: '100%'
        },
        {
          icon: <i className="fas fa-tablet-alt"/>,
          width: '768px'
        },
        {
          icon: <i className="fas fa-mobile-alt"/>,
          width: '395px'
        },
      ],
      disableValidations: false,
      questionsListPreview: false,
    };

    this.ownStylesUpdated = this.ownStylesUpdated.bind(this);
    this.getIframeSrc = this.getIframeSrc.bind(this);
    this.handleOnMessage = this.handleOnMessage.bind(this)
  }

  componentDidMount(){
    window.addEventListener('message', this.handleOnMessage);

    window.onbeforeunload = () => {
      api.surveyResponse.delete.temporaryResponses();
    };

    api.survey.get.temporaryCollector({
      id: this.props.match.params.survey
    }).then(collector => {
      this.setState({
        guid: collector.guid
      });
      api.surveyResponse.post.surveyResponse({guid: collector.guid}).then(response => {
        this.setState({
          id: response.surveyResponse.id
        });
      })
    }).catch(e => {
      toast.error(e.response.data.errors[0].message);
      this.props.history.push(appRoutes.surveys.list);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleOnMessage);
    window.onbeforeunload = null;
    api.surveyResponse.delete.temporaryResponses();
  }

  handleOnMessage(event) {
    const {guid, id} = this.state;
    const mobilePreview = document.getElementById("mobile-preview");
    const desktopPreview = document.getElementById("desktop-preview");

    if (desktopPreview && event.source === desktopPreview.contentWindow && mobilePreview) {
      if (Number.isInteger(event.data)) {
        mobilePreview.contentWindow.postMessage(event.data, "*");
      } else {
        mobilePreview.setAttribute("src", surveyLink(guid, false, true, id, null, event.data))
      }
    } else if (mobilePreview && event.source === mobilePreview.contentWindow && desktopPreview) {
      if (Number.isInteger(event.data)) {
        desktopPreview.contentWindow.postMessage(event.data, "*");
      } else {
        desktopPreview.setAttribute("src", surveyLink(guid, false, true, id, null, event.data))
      }
    }
  }

  ownStylesUpdated(){
    this.setState({
      iframe: false,
    }, () => this.setState({
        iframe: true,
      })
    )
  }

  changeSize(width){
    this.setState({
      width
    });
  }

  getIframeSrc(){
    const {guid, id} = this.state;
    if (!id) {
      return;
    }

    let url = surveyLink(guid, false, true, id, this.props.match.params.question);

    if(this.state.disableValidations){
      url += '&disableValidations=1'
    }

    if(this.state.questionsListPreview){
      url += '&questionsListPreview=1'
    }

    return url;
  }

  render() {
    const {sizes, width, guid, iframe, id, disableValidations, questionsListPreview} = this.state;

    if(!guid){
      return <LoadingSpinner/>;
    }

    return (
      <div className="p-0 d-flex flex-column h-100">
        <SurveyOwnStyles surveyId={parseInt(this.props.match.params.survey)} handlerOnUpdated={this.ownStylesUpdated} />
        {width === 'desktop-mobile' && <Row className={"d-none d-xl-flex flex-grow-1"}>
          <Col xl={8} className={"d-flex flex-grow-1"}>
            {iframe && id && <iframe id={'desktop-preview'}
                src={this.getIframeSrc()}
                className="border-0 my-0 mx-auto d-table flex-grow-1"
                style={{
                  width: "100%"
                }}
            />}
          </Col>
          <Col xl={4} className={"d-flex flex-grow-1"}>
            <div id={"phone-overlay"} className={"position-absolute"} />
            {iframe && id && <iframe id={"mobile-preview"}
                src={this.getIframeSrc()}
                className="border-0 my-0 mx-auto d-table position-relative"
                style={{
                  width: "360px",
                  height: "632px",
                  top: "90px"
                }}
            />}
          </Col>
        </Row>}
        {iframe && id && width !== 'desktop-mobile' && <iframe
            src={this.getIframeSrc()}
            className="border-0 my-0 mx-auto d-table flex-grow-1"
            style={{
              width,
            }}
        />}

        <div className="d-flex flex-row flex-nowrap">
          <ButtonGroup className="mt-2 d-none d-md-block">
            {sizes.map((s, key) => <Button className={s.buttonClass} key={key} color={s.width === width ? 'primary' : 'secondary'} onClick={() => { this.changeSize(s.width); }}>{s.icon}</Button>)}
          </ButtonGroup>

          <ButtonGroup className="mt-2 ml-2">
            <Button id="disableValidations-tooltip" color={this.state.disableValidations ? 'primary' : 'secondary'} onClick={() => { this.setState({disableValidations: !disableValidations}); }}><i className="fas fa-exclamation" /></Button>
            <Tooltip id={"disableValidations-tooltip"} msg={"Wyłącz walidację pytań"} />
            <Button id="questionsListPreview-tooltip" color={this.state.questionsListPreview ? 'primary' : 'secondary'} onClick={() => { this.setState({questionsListPreview: !questionsListPreview}); }}><i className="fas fa-bars" /></Button>
            <Tooltip id={"questionsListPreview-tooltip"} msg={"Wyświetl wszystkie pytania na jednym ekranie. Podgląd nie pozwala na testowanie filtrów, rotowań oraz losowań. Umożliwia szybki przegląd wyglądu pytań."} />
          </ButtonGroup>
        </div>
      </div>
    )
  }
}

export default Preview;