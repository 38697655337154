import React, {Component} from 'react';
import PropTypes from "prop-types";
import api from "api";
import ApplicationTable from "../../components/Application/ApplicationTable";
import SearchTextInput from "../../components/Application/ApplicationTable/SearchTextInput";
import {Alert, Badge, ButtonGroup, Modal, ModalHeader, ModalBody,ModalFooter} from "reactstrap";
import Button from "../../components/Button";

class DataProcessingTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      checked: [],
      excluding: null,
      modalOpen: false,
    }

    this.excludeChecked = this.excludeChecked.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  excludeChecked(value){
    this.setState({
      excluding: value
    });

    api.surveyResponse.dataProcessing.exclude(this.props.survey, this.state.checked, value)
    .then(data => {
      this.setState({
        excluding: null,
        modalOpen: false,
      });

      this.tableRef.current.fetchDataDelay(0);
    })
    .catch(data => {
      this.setState({
        excluding: null,
        modalOpen: false,
      });

      this.tableRef.current.fetchDataDelay(0);
    })
  }

  toggleModal(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {checked, excluding, modalOpen} = this.state;

    const columns = [
      {
        id: 'id',
        Header: 'Id wywiadu',
        width: 300,
        accessor: row => row.id
      },
      {
        id: 'respondent',
        Header: 'Respondent',
        width: 200,
        accessor: row => {
          if(row.user){
            return <span>P{row.user.id}</span>
          }
          if(row.unknown){
            return <span>O{row.unknown.id}</span>
          }

          return <span>Brak danych</span>
        }
      },
      {
        accessor: 'createdAt',
        Header: 'Data',
        width: 200,
      },
      {
        id: 'dataProcessingExclude',
        Header: 'Wywiad wykluczony',
        width: 170,
        accessor: row => row.dataProcessingExclude ? <Badge color="danger">Tak</Badge> : <Badge color="success">Nie</Badge>,
      },
      {
        id: 'dataProcessingIpRepeat',
        Header: 'Powtórzenia IP',
        width: 170,
        accessor: row => row.dataProcessingIpRepeat === undefined ? <i>Brak danych</i> : (row.dataProcessingIpRepeat ? <Badge color="danger">Tak</Badge> : <Badge color="success">Nie</Badge>),
      },
      {
        id: 'dataProcessingAnswersMatrixInLine',
        Header: 'Zaznaczanie w liniach',
        width: 170,
        accessor: row => row.dataProcessingAnswersMatrixInLine === undefined ? <i>Brak danych</i> : (row.dataProcessingAnswersMatrixInLine ? <Badge color="danger">Tak</Badge> : <Badge color="success">Nie</Badge>),
      },
      {
        id: 'dataProcessingResponseTime',
        Header: 'Czas trwania wywiadu',
        width: 170,
        accessor: row => row.dataProcessingResponseTime === undefined ? <i>Brak danych</i> : (row.dataProcessingResponseTime ? <Badge color="danger">Tak</Badge> : <Badge color="success">Nie</Badge>),
      },
    ];

    return <React.Fragment>
      <div className="d-flex justify-content-between mb-2">
        <div className="ml-auto">
          <SearchTextInput
            tableRef={this.tableRef}
          />
        </div>
      </div>
      <ApplicationTable
        isSelectable={true}
        ref={this.tableRef}
        columns={columns}
        fetchData={api.surveyResponse.dataProcessing.search}
        filters={{
          survey: this.props.survey
        }}
        onCheckedChange={checked => this.setState({checked})}
      />

      <ButtonGroup>
        <Button onClick={() => this.tableRef.current.toggleSelectAll()}>Zaznacz/Odznacz wszystko</Button>
        {checked.length > 0 && <React.Fragment>
          <Button color="danger" onClick={() => this.toggleModal()}>Wyklucz zaznaczone</Button>
          <Button color="success" onClick={() => this.excludeChecked(false)} loading={excluding === false}>Cofnij wykluczenie</Button>
        </React.Fragment>}
      </ButtonGroup>



      {modalOpen && <Modal isOpen={true} toggle={this.toggleModal} size="lg">
        <ModalHeader tag="h2" toggle={this.toggleModal}>Potwierdź wykluczenie wywiadów</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <Alert color="warning">Wykluczenie wywiadów z analiz spowoduje, że automatycznie nie będą one widoczne lub uwzględnione w: Podsumowaniu pytań (przeglądzie wykresów),  Przeglądarce wyników, Analizach statystycznych,  Eksporcie wyników do SPSS lub Excel w dowolnej formie. Wykluczenie wywiadów możesz cofnąć w każdej chwili.</Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={this.toggleModal}>Anuluj</Button>
          <Button
            color="danger"
            loading={excluding === true}
            onClick={() => this.excludeChecked(true)}
          >Wyklucz wywiady</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>;
  }
}

DataProcessingTable.propTypes = {
  survey: PropTypes.number.isRequired,
};

export default DataProcessingTable;
