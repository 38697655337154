import api from "api";
import _ from 'lodash';

export const SET = 'Reports/SET';
export const UPDATED = 'Reports/UPDATED';

export function setReports(reports) {
  return {
    type: SET,
    reports
  }
}

export function reportUpdated(report) {
  return {
    type: UPDATED,
    report
  }
}

export function fetchReports() {
  return dispatch => {
    return api.user.get.reports().then(reports => {
      let orderedReports = {};
      _.each(reports, report => orderedReports[report.id] = report);

      dispatch(setReports(orderedReports))
    });
  }
}

export function updateReport(data) {
  return dispatch => {
    return api.survey.patch.report(data).then(report => {
      dispatch(reportUpdated(report));
    })
  }
}