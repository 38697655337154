import React, {Component} from 'react';
import PropTypes from "prop-types";
import AnyChart from "anychart-react";
import anychart from 'anychart'
import reactHtmlParser from "react-html-parser";
import striptags from "striptags";

class ChartDifferential extends Component {
  render() {
    const {id, data, scaleSize} = this.props;

    let stage = anychart.graphics.create();
    var chart = anychart.verticalLine();

    chart.yScale().minimum(1);
    chart.yScale().maximum(scaleSize);
    chart.labels().enabled(true);
    chart.labels().fontWeight("bold");

    var xAxis1 = chart.xAxis(0);
    xAxis1.labels().format(row => reactHtmlParser(striptags(data[row.index].leftContent)));
    xAxis1.overlapMode('noOverlap');
    xAxis1.labels().wordWrap("break-word");
    xAxis1.labels().wordBreak("break-all");
    xAxis1.labels().hAlign("center");
    xAxis1.labels().width('20%');

    var xAxis2 = chart.xAxis(1);
    xAxis2.labels().format(row => reactHtmlParser(striptags(data[row.index].rightContent)));
    xAxis2.orientation("right");
    xAxis2.overlapMode('noOverlap');
    xAxis2.labels().wordWrap("break-word");
    xAxis2.labels().wordBreak("break-all");
    xAxis2.labels().hAlign("center");
    xAxis2.labels().width('20%');

    chart.line(data.map((row, i) => {
      if (row.avg === 0) {
        return null;
      }
      return {
        'x': i,
        'value': row.avg,
        'content': reactHtmlParser(striptags(row.leftContent)) + ' - ' + reactHtmlParser(striptags(row.rightContent))
      }
    })).name('Średnia');

    var tooltip = chart.tooltip();
    tooltip.titleFormat("{%content}");

    return (
      <AnyChart
        id={'question-' + id}
        instance={stage}
        height={400}
        charts={[chart]}
      />
    )
  }
}

ChartDifferential.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    leftContent: PropTypes.string.isRequired,
    rightContent: PropTypes.string.isRequired,
    description: PropTypes.string,
    avg: PropTypes.number.isRequired,
  }).isRequired).isRequired,
  scaleSize: PropTypes.number.isRequired
};

export default ChartDifferential;