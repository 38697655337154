import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appRouter from "appRoutes";
import {Link} from "react-router-dom";
import EditAction from "./Actions/EditAction";

class SurveysActions extends Component {

  render() {
    return (
      <div>
        <Link to={appRouter.survey.structure(this.props.survey.id)}><i className="fas fa-link" /></Link>
        <EditAction survey={this.props.survey} handleChange={this.props.handleChange} />
      </div>
    )
  }
}

SurveysActions.propTypes = {
  survey: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default SurveysActions;