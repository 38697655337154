import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";
import striptags from "striptags";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import reactHtmlParser from "react-html-parser";

const SLICE_VALUE = 5;

class QuestionSummaryConjoint extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tableSlice: true,
    };
  }

  placesDataFormat(row) {
    const {questionSummary} = this.props;
    let li = [];

    for(let place = 1; place <= questionSummary.question.answers.length; place++){
      li.push(<li key={place}>Miejsce {place} - {row['place_' + place]}</li>);
    }

    return (
      <ul className="mb-0">
        {li}
      </ul>
    )
  }

  render() {
    const {questionSummary} = this.props;
    const {tableSlice} = this.state;
    const dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    let series = [];
    for(let place = 1; place <= questionSummary.question.answers.length; place++){
      series.push('Miejsce ' + place);
    }

    return (
      <React.Fragment>
        <Chart id={questionSummary.question.id} type={questionSummary.chartType} n={questionSummary.data.n} data={{
          data: questionSummary.data.answers.map(answer => {
            let ret = [reactHtmlParser(striptags(questionSummary.question.answersImage ? answer.description : answer.content))[0]];

            for(let place = 1; place <= questionSummary.question.answers.length; place++){
              ret.push(answer['place_' + place]);
            }

            return ret;
          }),
          series: series
        }}/>
        {this.props.table && <React.Fragment>
          <BootstrapTable
            className={'table-wrap w-lg-50 mx-auto mt-3'}
            data={tableSlice ? questionSummary.data.answers.slice(0, SLICE_VALUE) : questionSummary.data.answers}
            striped hover
          >
            <TableHeaderColumn dataField='content' isKey={true}
                               dataFormat={(cell, row) => questionSummary.question.answersImage ?
                                 <img src={row.content} className="img-100"/> : row.content}><FormattedMessage
              id="analysis.questionSummary.multi.tableHeader.x"/></TableHeaderColumn>
            <TableHeaderColumn dataField='places'
                               dataFormat={(cell, row) => this.placesDataFormat(row)}><FormattedMessage
              id="analysis.questionSummary.multi.tableHeader.value"/></TableHeaderColumn>
          </BootstrapTable>
          {(tableSlice && questionSummary.data.answers.length > SLICE_VALUE) &&
            <p className="text-center"><span className="text-muted py-2 d-block pointer"
                                             onClick={() => this.setState({tableSlice: false})}>Kliknij, aby pokazać wszystkie odpowiedzi w tabeli</span>
            </p>}
        </React.Fragment>}
      </React.Fragment>
    )
  }
}

QuestionSummaryConjoint.defaultProps = {
  chart: true,
  table: true,
};

QuestionSummaryConjoint.propTypes = {
  chart: PropTypes.bool,
  table: PropTypes.bool,
};

export default QuestionSummaryConjoint;
