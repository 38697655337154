import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {UncontrolledTooltip} from "reactstrap";

class ButtonCopy extends Component {
  render() {
    return (
      <React.Fragment>
        <i id={"survey-filter-button-copy-" + this.props.filter.id} className="fas fa-copy mr-2 pointer" onClick={this.props.onClick}/>
        <UncontrolledTooltip target={"survey-filter-button-copy-" + this.props.filter.id} placement="bottom" delay={0}>
          <FormattedMessage id="_.button.copy"/>
        </UncontrolledTooltip>
      </React.Fragment>
    )
  }
}

ButtonCopy.propTypes = {
  onClick: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired
};

export default ButtonCopy;