import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import TabSummary from "../CollectorPanel/Tabs/TabSendEmail/Tabs/TabSummary";
import {Alert} from "reactstrap";

class Panel extends PureComponent {
  render() {
    const {collector} = this.props;

    if(!collector.sendEmail){
      return <Alert color="info">Brak wysyłki zaproszeń</Alert>
    }

    return <TabSummary collector={collector} />
  }
}

Panel.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sendEmail: PropTypes.object,
  }).isRequired,
};

export default Panel;