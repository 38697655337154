import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from 'reactstrap';
import NewsDeleteButton from "./NewsDeleteButton";
import NewsEditButton from "./NewsEditButton";
import NewsCopyButton from "./NewsCopyButton";
import Badge from "reactstrap/es/Badge";
import striptags from "striptags";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";

class NewsList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
    this.actions = this.actions.bind(this);
  }

  fetchData(state) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.news.post.searchNewses(data).then(res => {
      this.setState({searching: false, data: res.newses, count: res.count});
    });
  }

  actions(news){
    const handler = () => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});
    return (
      <ButtonGroup>
        <NewsEditButton news={news} handleChange={handler}/>
        <NewsCopyButton news={_.omit(news, ['id'])} handleChange={handler}/>
        <NewsDeleteButton news={news} handleChange={handler}/>
      </ButtonGroup>
    )
  }

  static formatPublished(news){
    return news.published ?
      <Badge className="w-100" style={{fontSize: '100%'}} color="success">Tak</Badge>
      :
      <Badge className="w-100" style={{fontSize: '100%'}} color="warning">Nie</Badge>
  }

  static reduceAndStripLongText(text) {
    text = striptags(text);
    return text.length > 50 ? text.substr(0, 50) + '...' : text
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="admin.newsList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      id: 'title',
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.title" />,
      accessor: news => <a href={news.uri} target="_blank">{news.title}</a>
    }, {
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.image" />,
      accessor: 'image'
    }, {
      id: 'shortDescription',
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.shortDescription" />,
      accessor: news => NewsList.reduceAndStripLongText(news.shortDescription)
    }, {
      id: 'description',
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.description" />,
      accessor: news => NewsList.reduceAndStripLongText(news.description)
    }, {
      Header: <FormattedMessage id="admin.newsList.listItems.tableHeader.places" />,
      accessor: 'places'
    }, {
      id: 'published',
      Header: <FormattedMessage id="admin.newsList.listItems.tableHeader.published" />,
      accessor: news => NewsList.formatPublished(news)
    }, {
      id: 'actions',
      Header: <FormattedMessage id="admin.newsList.listItems.tableHeader.actions" />,
      accessor: news => this.actions(news),
      sortable: false
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.newsList.listItems.table.noNews" />}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

export default NewsList;