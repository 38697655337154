import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {deletePiping} from "scenes/Survey/Structure/data/pipings/actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

class ButtonDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    }
  }

  pipingDelete(){
    this.setState({
      deleting: true
    });

    this.props.deletePiping({
      id: this.props.piping.id
    });
  }

  toggleModalDelete(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { deleting } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModalDelete()}
                               className={'modal-danger'}>
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2"><FormattedMessage id="pipings.listItems.buttonDelete.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="pipings.listItems.buttonDelete.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.modal.delete.confirmation">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={() => this.pipingDelete()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModalDelete()}>{message}</Button>
        }</FormattedMessage>

      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <i className="fas fa-times" onClick={() => this.toggleModalDelete()} />
        {modal}
      </React.Fragment>
    )
  }
}

ButtonDelete.propTypes = {
  piping: PropTypes.object.isRequired,
};

export default connect(null, { deletePiping })(ButtonDelete);