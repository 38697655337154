import {normalize} from "normalizr";
import _ from "lodash";
import api from "api";
import schemaQuestions from "entities/question/schema";
import {toast} from "react-toastify";
import * as answersActions from "../answers/actions";
import * as basketsActions from "../baskets/actions";
import * as rowsActions from "../rows/actions";
import * as columnsActions from "../columns/actions";
import * as questionsActions from "../questions/actions";
import * as excludingsActions from "../excludings/actions";
import * as pagesActions from "../pages/actions";
import * as filterSerialActions from "../filterSerial/actions";
import * as filtersActions from "../filters/actions";
import * as scalePointsActions from "../scalePoints/actions";
import * as javascriptActions from "../javascripts/actions";
import schemaSurvey from "../../../../../entities/survey/schema";
import * as setsActions from "../sets/actions";
import * as blocksActions from "../blocks/actions";
import * as pipingsActions from "../pipings/actions";
import * as filterIterativeActions from "../filterIterative/actions";
import {setSurvey} from "../survey/actions";

export const SET = 'Survey/data/questions/SET';
export const SET_ANSWERS = 'Survey/data/questions/SET_ANSWERS';
export const SET_BASKETS = 'Survey/data/questions/SET_BASKETS';
export const SET_ROWS = 'Survey/data/questions/SET_ROWS';
export const SET_COLUMNS = 'Survey/data/questions/SET_COLUMNS';
export const SET_EXCLUDINGS = 'Survey/data/questions/SET_EXCLUDINGS';
export const SET_SCALE_POINTS = 'Survey/data/questions/SET_SCALE_POINTS';
export const SET_JAVASCRIPTS = 'Survey/data/questions/SET_JAVASCRIPTS';
export const ADDED = 'Survey/data/questions/ADDED';
export const UPDATED = 'Survey/data/questions/UPDATED';
export const DELETED = 'Survey/data/questions/DELETED';
export const DELETEING = 'Survey/data/questions/DELETEING';
export const EXCLUDING_ADDED = 'Survey/data/questions/EXCLUDING_ADDED';
export const ATTRIBUTE_ADDED = 'Survey/data/questions/ATTRIBUTE_ADDED';
export const ANSWER_ADDED = 'Survey/data/questions/ANSWER_ADDED';
export const BASKET_ADDED = 'Survey/data/questions/BASKET_ADDED';
export const ROW_ADDED = 'Survey/data/questions/ROW_ADDED';
export const COLUMN_ADDED = 'Survey/data/questions/COLUMN_ADDED';
export const SCALE_POINT_ADDED = 'Survey/data/questions/SCALE_POINT_ADDED';
export const JAVASCRIPT_ADDED = 'Survey/data/questions/JAVASCRIPT_ADDED';
export const TYPE_UPDATED = 'Survey/data/questions/TYPE_UPDATED';
export const ANSWERS_REVERSED = 'Survey/data/questions/ANSWERS_REVERSED';
export const COLUMNS_REVERSED = 'Survey/data/questions/COLUMNS_REVERSED';
export const ROWS_REVERSED = 'Survey/data/questions/ROWS_REVERSED';


export function setQuestions(questions) {
  return {
    type: SET,
    questions
  }
}
export function setAnswers(id, answers) {
  return {
    type: SET_ANSWERS,
    data: {
      id,
      answers
    }
  }
}
export function setBaskets(id, baskets) {
  return {
    type: SET_BASKETS,
    data: {
      id,
      baskets
    }
  }
}
export function setRows(id, rows) {
  return {
    type: SET_ROWS,
    data: {
      id,
      rows
    }
  }
}
export function setColumns(id, columns) {
  return {
    type: SET_COLUMNS,
    data: {
      id,
      columns
    }
  }
}
export function setExcludings(id, excludings) {
  return {
    type: SET_EXCLUDINGS,
    data: {
      id,
      excludings
    }
  }
}
export function setScalePoints(id, scalePoints) {
  return {
    type: SET_SCALE_POINTS,
    data: {
      id,
      scalePoints
    }
  }
}
export function setJavascripts(id, javascripts) {
  return {
    type: SET_JAVASCRIPTS,
    data: {
      id,
      javascripts
    }
  }
}
export function questionAdded(question) {
  return {
    type: ADDED,
    question
  }
}
export function questionUpdated(question) {
  return {
    type: UPDATED,
    question
  }
}
export function questionDeleted(question) {
  return {
    type: DELETED,
    question
  }
}
export function questionDeleting(question) {
  return {
    type: DELETEING,
    question
  }
}
export function excludingAdded(excluding) {
  return {
    type: EXCLUDING_ADDED,
    excluding
  }
}
export function scalePointAdded(scalePoint) {
  return {
    type: SCALE_POINT_ADDED,
    scalePoint
  }
}
export function javascriptAdded(javascript) {
  return {
    type: JAVASCRIPT_ADDED,
    javascript
  }
}
export function answerAdded(answer) {
  return {
    type: ANSWER_ADDED,
    answer
  }
}
export function attributeAdded(attribute) {
  return {
    type: ATTRIBUTE_ADDED,
    attribute
  }
}
export function basketAdded(basket) {
  return {
    type: BASKET_ADDED,
    basket
  }
}
export function rowAdded(row) {
  return {
    type: ROW_ADDED,
    row
  }
}
export function columnAdded(column) {
  return {
    type: COLUMN_ADDED,
    column
  }
}

export function questionTypeUpdated(question, expiredId) {
  return {
    type: TYPE_UPDATED,
    question,
    expiredId
  }
}

export function answersReversed(questionId) {
  return {
    type: ANSWERS_REVERSED,
    questionId
  }
}

export function columnsReversed(questionId) {
  return {
    type: COLUMNS_REVERSED,
    questionId
  }
}

export function rowsReversed(questionId) {
  return {
    type: ROWS_REVERSED,
    questionId
  }
}


export function addQuestion(data) {
  return dispatch => {
    return api.page.post.question(data).then(questions => {
      questions.map(question => {
        dispatch(questionAdded(question));
        dispatch(pagesActions.questionAdded(question));
      });

      return questions;
    });
  }
}

export function copyQuestion(data) {
  return dispatch => {
    return api.question.post.copy(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answersActions.setAnswers(normalized.entities.answers || {}));
      dispatch(basketsActions.setBaskets(normalized.entities.baskets || {}));
      dispatch(rowsActions.setRows(normalized.entities.rows || {}));
      dispatch(columnsActions.setColumns(normalized.entities.columns || {}));
      dispatch(scalePointsActions.setScalePoints(normalized.entities.scalePoints || {}));
      dispatch(javascriptActions.setJavascripts(normalized.entities.javascripts || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(pagesActions.setPages(normalized.entities.pages || {}));
      dispatch(setsActions.setSets(normalized.entities.sets || {}));
      dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(filterIterativeActions.setFilterIterative(normalized.entities.filterIterative || {}));
      dispatch(setSurvey(survey));
    });
  }
}

export function updateQuestion(data) {
  return dispatch => {
    return api.question.patch.question(data).then(question => {
      const normalized = normalize(question, schemaQuestions);
      dispatch(questionUpdated(normalized.entities.questions[normalized.result]));

      return question;
    })
  }
}

export function replaceAnswers(id, answers, changeable = false) {
  return dispatch => {
    return api.question.patch.answersReplace(id, answers, changeable).then(question => {
      const normalized = normalize(question, schemaQuestions);

      _.map(normalized.entities.answers, answer => {
        dispatch(answersActions.answerAdded(answer));
      });

      dispatch(setAnswers(id,  Object.keys(normalized.entities.answers)));

      return question;
    })
  }
}

export function addAnswers(id, answers) {
  return dispatch => {
    return api.question.patch.answersAdd(id, answers).then(resAnswers => {
      _.map(resAnswers, answer => {
        dispatch(answersActions.answerAdded(answer));
        dispatch(questionsActions.answerAdded(answer));
      });

      return resAnswers;
    })
  }
}

export function addBaskets(id, baskets) {
  return dispatch => {
    return api.question.patch.basketsAdd(id, baskets).then(resBaskets => {
      _.map(resBaskets, basket => {
        dispatch(basketsActions.basketAdded(basket));
        dispatch(questionsActions.basketAdded(basket));
      });

      return resBaskets;
    })
  }
}

export function deleteQuestion(data) {
  return dispatch => {
    dispatch(questionDeleting(data));
    return api.question.delete.question(data).then(res => {
      let question = res.question;
      question.id = data.id;
      dispatch(questionDeleted(question));

      return question;
    })
  }
}


export function copyAnswers(id, data) {
  return dispatch => {
    return api.question.patch.answersCopy(id, data).then(question => {
      const normalized = normalize(question, schemaQuestions);

      _.map(normalized.entities.answers, answer => {
        dispatch(answersActions.answerAdded(answer));
      });

      _.map(normalized.entities.baskets, basket => {
        dispatch(basketsActions.basketAdded(basket));
      });

      _.map(normalized.entities.rows, row => {
        dispatch(rowsActions.rowAdded(row));
      });

      _.map(normalized.entities.columns, column => {
        dispatch(columnsActions.columnAdded(column));
      });

      dispatch(questionUpdated(normalized.entities.questions[normalized.result]));

      return question;
    })
  }
}

export function addQuestionFromTemplate(data) {
  return dispatch => {
    return api.question.post.fromTemplate(data).then(questions => {
      questions.map(question => {
        const normalized = normalize(question, schemaQuestions);

        _.map(normalized.entities.questionExcludings, excluding => {
          excluding.question = {id: question.id};
          dispatch(excludingsActions.excludingAdded(excluding));
        });

        _.map(normalized.entities.answers, answer => {
          answer.question = {id: question.id};
          dispatch(answersActions.answerAdded(answer));
        });

        _.map(normalized.entities.baskets, basket => {
          basket.question = {id: question.id};
          dispatch(basketsActions.basketAdded(basket));
        });

        _.map(normalized.entities.rows, row => {
          row.question = {id: question.id};
          dispatch(rowsActions.rowAdded(row));
        });

        _.map(normalized.entities.columns, column => {
          column.question = {id: question.id};
          dispatch(columnsActions.columnAdded(column));
        });

        dispatch(questionAdded(normalized.entities.questions[normalized.result]));
        dispatch(pagesActions.questionAdded(normalized.entities.questions[normalized.result]));
      });

      return questions;
    })
  }
}

export function addRows(id, rows) {
  return dispatch => {
    return api.question.patch.rowsAdd(id, rows).then(resRows => {
      _.map(resRows, row => {
        dispatch(rowsActions.rowAdded(row));
        dispatch(questionsActions.rowAdded(row));
      });

      return resRows;
    })
  }
}

export function addColumns(id, columns) {
  return dispatch => {
    return api.question.patch.columnsAdd(id, columns).then(resColumns => {
      _.map(resColumns, column => {
        dispatch(columnsActions.columnAdded(column));
        dispatch(questionsActions.columnAdded(column));
      });

      return resColumns;
    })
  }
}

export function replaceRows(id, rows, changeable = false) {
  return dispatch => {
    return api.question.patch.rowsReplace(id, rows, changeable).then(question => {
      const normalized = normalize(question, schemaQuestions);

      _.map(normalized.entities.rows, row => {
        dispatch(rowsActions.rowAdded(row));
      });

      dispatch(setRows(id,  Object.keys(normalized.entities.rows)));

      return question;
    })
  }
}

export function replaceColumns(id, columns, changeable = false) {
  return dispatch => {
    return api.question.patch.columnsReplace(id, columns, changeable).then(question => {
      const normalized = normalize(question, schemaQuestions);

      _.map(normalized.entities.columns, column => {
        dispatch(columnsActions.columnAdded(column));
      });

      dispatch(setColumns(id,  Object.keys(normalized.entities.columns)));

      return question;
    })
  }
}

export function changeQuestionType(data) {
  return dispatch => {
    return api.question.patch.questionType(data).then(response => {
      let question = response.question;
      if (response && response.error) {
        toast.error(response.error)
        return question;
      }
      let serialFilters = {};
      let filters = {};

      _.each(response.serialFilters, filter => serialFilters[filter.id] = filter);
      _.each(response.filters, filter => filters[filter.id] = filter);

      const normalized = normalize(question, schemaQuestions);

      _.map(normalized.entities.questionExcludings, excluding => {
        excluding.question = {id: question.id};
        dispatch(excludingsActions.excludingAdded(excluding));
      });

      _.map(normalized.entities.answers, answer => {
        answer.question = {id: question.id};
        dispatch(answersActions.answerAdded(answer));
      });

      _.map(normalized.entities.baskets, basket => {
        basket.question = {id: question.id};
        dispatch(basketsActions.basketAdded(basket));
      });

      _.map(normalized.entities.rows, row => {
        row.question = {id: question.id};
        dispatch(rowsActions.rowAdded(row));
      });

      _.map(normalized.entities.columns, column => {
        column.question = {id: question.id};
        dispatch(columnsActions.columnAdded(column));
      });

      dispatch(questionTypeUpdated(normalized.entities.questions[normalized.result], data.id));
      dispatch(filterSerialActions.setFilterSerial(serialFilters));
      dispatch(filtersActions.setFilters(filters));

      return question;
    })
  }
}

export function swapRowsAndColumns(id) {
  return dispatch => {
    return api.question.patch.swapRowsAndColumns(id).then(response => {
      const normalized = normalize(response.question, schemaQuestions);
      let filters = {};
      _.each(response.filters, filter => filters[filter.id] = filter);

      let question = normalized.entities.questions[normalized.result];
      let questionRows = question.rows;
      let questionColumns = question.columns;
      question.rows = [];
      question.columns = [];

      dispatch(questionUpdated(question));
      dispatch(rowsActions.setRows(normalized.entities.rows));
      dispatch(columnsActions.setColumns(normalized.entities.columns));

      question.rows = questionRows;
      question.columns = questionColumns;

      dispatch(questionUpdated(normalized.entities.questions[normalized.result]));
      dispatch(filtersActions.setFilters(filters));

      return question;
    }).catch(e => {
      toast.error(e.response.data.error)
    });
  }
}

export function reverseAnswers(id) {
  return dispatch => {
    return api.question.patch.reverseAnswers(id).then(answers => {
      dispatch(answersReversed(id));
      _.each(answers, answer => {
        dispatch(answersActions.answerUpdated(answer));
      });
    })
  }
}

export function reverseColumns(id) {
  return dispatch => {
    return api.question.patch.reverseColumns(id).then(columns => {
      dispatch(columnsReversed(id));
      _.each(columns, column => {
        dispatch(columnsActions.columnUpdated(column));
      });
    })
  }
}

export function reverseRows(id) {
  return dispatch => {
    return api.question.patch.reverseRows(id).then(rows => {
      dispatch(rowsReversed(id));
      _.each(rows, row => {
        dispatch(rowsActions.rowUpdated(row));
      });
    })
  }
}