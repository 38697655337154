import {
  SET,
} from './actions';

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.pages;

    default:
      return state;
  }
};