import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';


class ActionDelete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      deleting: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm(){
    this.setState({
      deleting: true,
    });
    this.props.onConfirmed()
      .then(res => {
        this.setState({
          deleting: false,
        });
        this.toggleModal();
      })
      .catch(res => {
        this.setState({
          deleting: false,
        });
      });
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {modalOpen, deleting} = this.state;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="md">
      <ModalHeader toggle={this.toggleModal}>Usuń kod JS</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        Potwierdź usunięcie kodu
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.delete">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda mb-0"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={this.onConfirm}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <i className="fas fa-trash pointer" onClick={this.toggleModal} />
        {modal}
      </React.Fragment>
    );
  }
}

ActionDelete.propTypes = {
  javascript: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onConfirmed: PropTypes.func.isRequired,
};

export default ActionDelete;