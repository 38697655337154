import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {InputGroup, InputGroupAddon, Tooltip, Button} from "reactstrap";
import Select from 'react-select';
import classnames from "classnames";

class PresentationType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      question: {
        presentationType: props.question.presentationType
      }
    };
    this.onChange = this.onChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(e) {
    this.setState({
      ...this.state,
      question: {
        ...this.state.question,
        presentationType: e.value
      }
    }, () => this.props.handleUpdate(this.state.question));
  };

  render() {
    const {presentationTypes, questionSetting} = this.props;

    const options = presentationTypes.map(presentationType => {
      return {
        label: <FormattedMessage id={"_.question." + this.props.question.type + ".presentationType." + presentationType}/>,
        value: presentationType,
        isDisabled: questionSetting.presentationTypes && questionSetting.presentationTypes.length !== 0 && !questionSetting.presentationTypes.includes(presentationType)
      }
    });

    const {tooltip, question} = this.state;

    return (
      <InputGroup className="mr-1 float-left w-100 w-lg-auto mb-1" id={"presentationType-" + this.props.question.id}>
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon active": true,
            })}
            color="secondary"
          >
            <i className="fa-solid fa-list-dropdown"/>
            <Tooltip
              delay={0}
              placement="right"
              isOpen={tooltip}
              target={"presentationType-" + this.props.question.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="question.settings.presentationType.title" />
            </Tooltip>
          </Button>
        </InputGroupAddon>
        <Select
          classNamePrefix="react-select"
          className="react-select float-left"
          isClearable={false}
          value={{value: question.presentationType, label: <FormattedMessage id={"_.question." + this.props.question.type + ".presentationType." + question.presentationType} />}}
          options={options}
          onChange={(e) => this.onChange(e)}
        />
      </InputGroup>
    );
  }
}

PresentationType.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    presentationType: PropTypes.string.isRequired
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    presentationTypes: state.config.data.questions[props.question.type].presentationTypes,
    questionSetting: state.user.userPlan.plan.survey.questionSettings[props.question.type]
  }
}

export default connect(mapStateToProps)(PresentationType);
