import React, {Component} from 'react';
import api from "api";
import ApplicationTable from "../../components/Application/ApplicationTable";
import {Link} from "react-router-dom";
import appRoutes from "../../appRoutes";
import DictionaryAddModal from "./DictionaryAddModal";
import history from "services/history";
import SearchTextInput from "../../components/Application/ApplicationTable/SearchTextInput";

class DictionariesTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  render() {
    const columns = [
      {
        id: 'name',
        Header: 'Nazwa',
        width: 300,
        accessor: row => <Link to={appRoutes.dictionaries.dictionary.wordsList(row.id)}>{row.name}</Link>
      },
      {
        accessor: 'wordsCount',
        Header: 'Liczba słów',
        width: 200,
      },
      {
        accessor: 'similarsCount',
        Header: 'Liczba słów podobnych',
        width: 200,
      },
      {
        Header: 'Data utworzenia',
        accessor: 'createdAt',
        width: 170,
      },
    ];

    return <React.Fragment>
      <div className="d-flex justify-content-between mb-2">
        <DictionaryAddModal onModalClose={() => {}} onAdded={dictionary => {
          history.push(appRoutes.dictionaries.dictionary.wordsList(dictionary.id))
        }} />

        <div className="ml-auto">
          <SearchTextInput
            tableRef={this.tableRef}
          />
        </div>
      </div>
      <ApplicationTable
        ref={this.tableRef}
        columns={columns}
        fetchData={api.dictionaries.search}
        deleteData={api.dictionaries.delete}
        filters={this.props.filters}
      />
    </React.Fragment>;
  }
}

export default DictionariesTable;
