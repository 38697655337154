import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import ModalDelete from "./components/ModalDelete";
import ModalCopy from "./components/ModalCopy";
import ModalAutoPagination from "./components/ModalAutoPagination";
import ModalRemovePageBreaks from "./components/ModalRemovePageBreaks";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import BlocksAvailable from "components/PlanRestrictionsModals/modals/BlocksAvailable";
import ModalSaveTemplate from "./components/ModalSaveTemplate";
import ClearContentInQuestions from "scenes/Survey/Structure/components/ClearContentInQuestions";

class HeaderActions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownSettingsOpen: false,
      modalCopy: false,
      modalAutoPagination: false,
      modalSaveTemplate: false,
      modalRemovePageBreaks: false,
      modalDelete: false,
      modalClearContentInQuestion: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(type) {
    this.setState({
      [type]: !this.state[type]
    })
  }

  render() {
    const {block, optionsOpen, surveyPlan} = this.props;

    return (
      <div className="d-flex align-self-start py-0">
        <ul className="d-flex align-content-start ml-auto mb-0 p-0 pl-2">
          <li className="float-left">
            <Dropdown isOpen={this.state.dropdownSettingsOpen} toggle={() => this.toggle('dropdownSettingsOpen')}>
              <DropdownToggle className="m-0 p-0" color="">
                <span className="fas fa-ellipsis-v hoverable" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag="a" onClick={() => surveyPlan.blocks ? this.props.handleToggleOptions() : this.props.setPlanRestrictionsModals(BlocksAvailable.getName())} className={optionsOpen ? 'text-primary' : ''}>
                  <p><span className="fas fa-cog" style={optionsOpen ? {filter: 'none'} : {}}/></p> <FormattedMessage tagName="h5" id="block.headerActions.dropdownItem.settings"/>
                </DropdownItem>

                <DropdownItem tag="a" onClick={() => surveyPlan.blocks ? this.toggle('modalAutoPagination') : this.props.setPlanRestrictionsModals(BlocksAvailable.getName())}>
                  <p><span className="fa-solid fa-rectangle-history"/></p> <FormattedMessage tagName="h5" id="block.headerActions.dropdownItem.autoPagination"/>
                </DropdownItem>

                <DropdownItem tag="a" onClick={() => this.toggle('modalClearContentInQuestion')}>
                  <p><span className="fa-solid fa-eraser" /></p> <h5>Usuń znaczniki w treści</h5>
                </DropdownItem>

                {surveyPlan.userBlockTemplates && <DropdownItem tag="a" onClick={() => surveyPlan.blocks ? this.toggle('modalSaveTemplate') : this.props.setPlanRestrictionsModals(BlocksAvailable.getName())}>
                  <p><span className="fas fa-file"/></p> <FormattedMessage tagName="h5" id="block.headerActions.dropdownItem.saveTemplate"/>
                </DropdownItem>}

                <DropdownItem tag="a" onClick={() => surveyPlan.blocks ? this.toggle('modalRemovePageBreaks') : this.props.setPlanRestrictionsModals(BlocksAvailable.getName())}>
                  <p><span className="fas fa-object-group"/></p> <FormattedMessage tagName="h5" id="block.headerActions.dropdownItem.removePageBreaks"/>
                </DropdownItem>

                  <DropdownItem tag="a" onClick={() => surveyPlan.blocks ? this.toggle('modalCopy') : this.props.setPlanRestrictionsModals(BlocksAvailable.getName())}>
                  <p><span className="fas fa-copy"/></p> <FormattedMessage tagName="h5" id="block.headerActions.dropdownItem.copy"/>
                </DropdownItem>

                <DropdownItem tag="a" onClick={() => this.toggle('modalDelete')}>
                  <p><span className="fas fa-trash"/></p> <FormattedMessage tagName="h5" id="block.headerActions.dropdownItem.delete"/>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {this.state.modalAutoPagination && <ModalAutoPagination block={block} isOpen={this.state.modalAutoPagination} toggleModal={() => this.toggle('modalAutoPagination')}/>}
            {this.state.modalSaveTemplate && <ModalSaveTemplate block={block} isOpen={this.state.modalSaveTemplate} toggleModal={() => this.toggle('modalSaveTemplate')}/>}
            {this.state.modalRemovePageBreaks && <ModalRemovePageBreaks block={block} isOpen={this.state.modalRemovePageBreaks} toggleModal={() => this.toggle('modalRemovePageBreaks')}/>}
            {this.state.modalCopy && <ModalCopy block={block} isOpen={this.state.modalCopy} toggleModal={() => this.toggle('modalCopy')}/>}
            {this.state.modalDelete && <ModalDelete block={block} isOpen={this.state.modalDelete} toggleModal={() => this.toggle('modalDelete')}/>}
            {this.state.modalClearContentInQuestion && <ClearContentInQuestions blockPagesId={block.id} toggleModal={() => this.toggle('modalClearContentInQuestion')}/>}
          </li>
        </ul>
      </div>
    )
  }
}

HeaderActions.propTypes = {
  block: PropTypes.object.isRequired,
  handleToggleOptions: PropTypes.func.isRequired,
  optionsOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey,
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(HeaderActions);
