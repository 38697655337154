import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';

class CollectorsModalCopy extends Component {

  constructor(props) {
    super(props);

    this.state = {
      copying: false
    }
  }

  copyCollector(){
    this.setState({copying: true});

    api.surveyCollector.post.copy(this.props.collector).then(collector => {
      this.setState({copying: false});
      this.props.toggleModal();
      this.props.handleCollectorCopy(collector);
    }).catch(() => {
        this.setState({copying: false});
      });
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
        <ModalHeader tag="h2" toggle={this.props.toggleModal}><FormattedMessage id="collectors.listItems.modalCopy.modalHeader" /></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
          <p><FormattedMessage id="collectors.listItems.modalCopy.modalBody" /></p>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.copy">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda"
              data-style={ZOOM_OUT}
              onClick={() => this.copyCollector()}
              loading={this.state.copying}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggleModal}>{message}</Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    )
  }
}

CollectorsModalCopy.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleCollectorCopy: PropTypes.func.isRequired,
};

export default CollectorsModalCopy;