import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

const SLICE_VALUE = 5;

class QuestionSummaryDifferential extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      tableSlice: true,
    };

    this.formatRow = this.formatRow.bind(this);
  }

  formatRow(cell, row) {
    return this.props.questionSummary.question.rowsImage
      ? <span><img src={row.leftContent} className="img-100"/> - <img src={row.rightContent} className="img-100"/></span>
      : <span dangerouslySetInnerHTML={{__html: row.leftContent + ' - ' + row.rightContent}} className="img-30 table-row"/>
  }

  render() {
    const {questionSummary} = this.props;
    const {tableSlice} = this.state;
    const dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <React.Fragment>
        <Chart
          id={questionSummary.question.id}
          type={questionSummary.chartType}
          data={questionSummary.data.data}
          scaleSize={questionSummary.question.scaleSize}
          n={questionSummary.data.n}
        />
        {this.props.table && <React.Fragment>
          <BootstrapTable
            className={'table-wrap w-lg-50 mx-auto mt-3'}
            data={tableSlice ? questionSummary.data.data.slice(0, SLICE_VALUE) : questionSummary.data.data}
            striped hover
          >
            <TableHeaderColumn dataField='row' isKey={true} dataFormat={this.formatRow}><FormattedMessage
              id="analysis.questionSummary.differential.tableHeader.x"/></TableHeaderColumn>
            <TableHeaderColumn dataField='avg' width="30%"
                               dataFormat={avg => avg === 0 ? 'Brak odpowiedzi' : avg}><FormattedMessage
              id="analysis.questionSummary.differential.tableHeader.avg"/></TableHeaderColumn>
          </BootstrapTable>
          {(tableSlice && questionSummary.data.data.length > SLICE_VALUE) &&
            <p className="text-center"><span className="text-muted py-2 d-block pointer"
                                             onClick={() => this.setState({tableSlice: false})}>Kliknij, aby pokazać wszystkie odpowiedzi w tabeli</span>
            </p>}
        </React.Fragment>}
      </React.Fragment>
    )
  }
}

QuestionSummaryDifferential.defaultProps = {
  chart: true,
  table: true,
};

QuestionSummaryDifferential.propTypes = {
  chart: PropTypes.bool,
  table: PropTypes.bool,
};

export default QuestionSummaryDifferential;
