import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {createAddress} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import AddressForm from './AddressForm';
import PropTypes from "prop-types";

class AddressCreateModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      errors: {}
    };
    this.form = React.createRef();
    this.saveAddress = this.saveAddress.bind(this);
  }

  saveAddress(e, data){
    e.preventDefault();

    this.setState({
      creating: true,
      errors: {},
    });

    this.props.createAddress(this.props.respondentBaseId, data)
    .then(() => {
      this.setState({
        creating: false,
      }, () => this.props.toggle())
    })
      .catch((e) => {
        if(e.response.data.errors){
          this.setState({
            creating: false,
            errors: _.pickBy(e.response.data.errors.children, err => !!err.errors),
          });
        }
    });
  }

  render() {
    const { creating, errors } = this.state;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle} tag="h2"><FormattedMessage id="addressCreateModal.modalHeader" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <AddressForm errors={errors} onSubmit={this.saveAddress} ref={this.form}/>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={creating}
                  data-style={ZOOM_OUT}
                  onClick={e => this.saveAddress(e, this.form.current.state)}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) =>
                  <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggle}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

AddressCreateModal.propTypes = {
  respondentBaseId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default connect(null, { createAddress }, null, {forwardRef: true})(AddressCreateModal);