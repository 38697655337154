import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, FormGroup, Label} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import striptags from "striptags";
import reactHtmlParser from "react-html-parser";

class QuestionsTo extends PureComponent {
  constructor(props) {
    super(props);

    this.isQuestionReplacable = this.isQuestionReplacable.bind(this);
  }

  isQuestionReplacable(question){
    const {piping, answers, rows, columns} = this.props;
    let matched = 0;

    const regex = /\[\[(\w)+\]\]/g;

    if(question.content && question.content.match(regex) && question.content.match(regex).indexOf('[[' + piping.replacableText + ']]') > -1){
      return true;
    }

    //answers
    if('answers' in question){
      _.map(question.answers, answer => {
        if(answers[answer].content && answers[answer].content.match(regex) && answers[answer].content.match(regex).indexOf('[[' + piping.replacableText + ']]') > -1){
          matched++;
        }
      });
      if(matched){
        return true;
      }
    }

    //rows
    if('rows' in question){
      _.map(question.rows, row => {
        if(rows[row].content && rows[row].content.match(regex) && rows[row].content.match(regex).indexOf('[[' + piping.replacableText + ']]') > -1){
          matched++;
        }
      });
      if(matched){
        return true;
      }
    }

    //columns
    if('columns' in question){
      _.map(question.columns, column => {
        if(columns[column].content && columns[column].content.match(regex) && columns[column].content.match(regex).indexOf('[[' + piping.replacableText + ']]') > -1){
          matched++;
        }
      });
      if(matched){
        return true;
      }
    }


    return false;
  }

  render() {
    const {questions} = this.props;

    return <FormGroup row>
      <Label lg={3} className="text-right"><FormattedMessage id="pipingForm.label.questionsTo" /></Label>
      <Col lg={9}>
        <ul className="mt-2">
          {_.map(questions, (question, key) => {
            if(this.isQuestionReplacable(question)){
              return <li key={key}>P{question.positionInSurvey}. {reactHtmlParser(striptags(question.content))}</li>
            }

            return null;
          })}
        </ul>
      </Col>
    </FormGroup>
  }
}

QuestionsTo.propTypes = {
  piping: PropTypes.shape({
    replacableText: PropTypes.string.isRequired,
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string.isRequired,
    positionInSurvey: PropTypes.number.isRequired,
  }),).isRequired,
};

function mapStateToProps(state) {
  return {
    questions: _.map(state.survey.structure.data.questions, question => question),
    answers: state.survey.structure.data.answers,
    rows: state.survey.structure.data.rows,
    columns: state.survey.structure.data.columns,
  }
}

export default connect(mapStateToProps)(QuestionsTo);