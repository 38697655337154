import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import Select from "react-select";
import _, {isNull, isUndefined} from "lodash";
import striptags from "striptags";
import QuestionInfo from "components/QuestionInfo";
import reactHtmlParser from "react-html-parser";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";


class SelectQuestions extends Component {
  constructor(props){
    super(props);

    this.select = React.createRef();

    this.questionLabel = this.questionLabel.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e){
    const {isMulti} = this.props;

    if(isMulti){
      this.select.current.setState({isOpen: true, menuIsOpen: true});
    }

    if(isMulti){
      this.props.onChange(e.map(obj => obj.value));
    }else{
      this.props.onChange(e.value);
    }
  }

  questionLabel(question){
    const {grayedToPositionInSurvey, grayedFromPositionInSurvey} = this.props;

    let style = {};

    if(
      (!isNull(grayedToPositionInSurvey) && question.positionInSurvey <= grayedToPositionInSurvey)
      || (!isNull(grayedFromPositionInSurvey) && question.positionInSurvey >= grayedFromPositionInSurvey)
    ){
      style.color = '#a6acb1';
    }

    return <div style={style}>
      <span className="mr-1">P{question.positionInSurvey}.</span>
      <QuestionInfo question={question} />
      <span className="ml-1" >
        {question.content ? <span className="img-20" dangerouslySetInnerHTML={{__html: question.stripedContent}}/>  : <i><FormattedMessage id="_.question.content.empty"/></i>}
      </span>
    </div>;
  }

  static questionLabelStatic(question){
    return <div>
      <span className="mr-1">P{question.positionInSurvey}.</span>
      <QuestionInfo question={question} />
      <span className="ml-1" >
        {question.content ? <span className="img-20" dangerouslySetInnerHTML={{__html: question.stripedContent}}/>  : <i><FormattedMessage id="_.question.content.empty"/></i>}
      </span>
    </div>;
  }

  render() {
    const {questions, value, isMulti, isLoading, className, enabledTypes, disabledTypesVisible, disabledFromPositionInSurvey, disabledToPositionInSurvey} = this.props;

    return (
      <Select
        placeholder={this.props.placeholder ? this.props.placeholder : (isMulti ? "Wybierz pytania" : "Wybierz pytanie")}
        className={className}
        ref={this.select}
        value={value && (!isMulti ?
            {
              label: this.questionLabel(questions.find(q => q.id === value)),
              value
            }
          :
          value.map(qId => {
            const question = questions.find(q => q.id === qId);
            return {
              label: this.questionLabel(question),
              value: qId,
            }
          })
        )}
        isOpen={false}
        isMulti={isMulti}
        isLoading={isLoading}
        filterOption={(option, string) => {
          if (option.value.toString().toLowerCase().includes(string.toLowerCase())) return true;
          if (option.data.content.toLowerCase().includes(string.toLowerCase())) return true;
        }}
        onChange={this.onChange}
        options={questions.filter(question => _.isEmpty(enabledTypes) || disabledTypesVisible || enabledTypes.indexOf(question.type) >= 0).map(question => {
          const isDisabled =
            (!_.isEmpty(enabledTypes) && enabledTypes.indexOf(question.type) < 0)
            || (!isUndefined(disabledFromPositionInSurvey) && disabledFromPositionInSurvey <= question.positionInSurvey)
            || (!isUndefined(disabledToPositionInSurvey) && disabledToPositionInSurvey >= question.positionInSurvey)
          ;

          return {
            isDisabled,
            label: this.questionLabel(question),
            content: question.content || '',
            value: question.id,
          }
        })}
      />
    );
  }
}

SelectQuestions.defaultProps = {
  isMulti: true,
  isLoading: false,
  className: "",
  enabledTypes: [],
  disabledTypesVisible: true,
  grayedToPositionInSurvey: null,
  grayedFromPositionInSurvey: null,
  placeholder: '',
};

SelectQuestions.propTypes = {
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  enabledTypes: PropTypes.array,
  disabledTypesVisible: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string,
    stripedContent: PropTypes.string,
    positionInSurvey: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  })),
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number,]),
  onChange: PropTypes.func.isRequired,
  disabledFromPositionInSurvey: PropTypes.number,
  disabledToPositionInSurvey: PropTypes.number,
  grayedToPositionInSurvey: PropTypes.number,
  grayedFromPositionInSurvey: PropTypes.number,
};

function mapStateToProps(state, props) {
  let questions = [];
  let positionInSurvey = 0;

  if(!_.isUndefined(props.questions)) {
    questions = props.questions;
  }else{
    _.each(state.survey.structure.data.survey.setBlocks, setId => {
      let set = state.survey.structure.data.sets[setId];

      _.each(set.blockPages, blockId => {
        _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
          _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
            positionInSurvey++;

            if (!!props.disabledTypesVisible && !_.isEmpty(props.enabledTypes) && !props.enabledTypes.includes(state.survey.structure.data.questions[questionId].type))
              return;

            let q = state.survey.structure.data.questions[questionId];
            q.positionInSurvey = positionInSurvey;
            questions.push(q)
          })
        });
      });
    });
  }

  return {
    questions,
  }
}

export default connect(mapStateToProps)(SelectQuestions);