import React, {Component} from 'react';
import {Card, CardHeader, CardBody, CardImgOverlay} from "reactstrap";
import LoadingSpinner from "components/LoadingSpinner";
import Options from "./components/Options";
import PropTypes from 'prop-types';
import {isBrowser} from "react-device-detect";
import classnames from "classnames";


class WidgetContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      deleting: false
    };
    this.triggerDeleting = this.triggerDeleting.bind(this);
    this.handleWidgetUpdating = this.handleWidgetUpdating.bind(this);
    this.handleWidgetUpdated = this.handleWidgetUpdated.bind(this);
  }

  triggerDeleting() {
    this.setState({deleting: true});
  }

  handleWidgetUpdating(){
    this.setState({
      updating: true,
    })
  }

  handleWidgetUpdated(){
    this.setState({
      updating: false,
    })
  }

  render() {
    const {deleting, updating} = this.state;
    let style = {
      overflowY: 'hidden'
    };

    if(deleting || updating){
      style.opacity = 0.3;
    }

    return (
      <React.Fragment>
        <Card style={style} className={classnames({
          "h-100 border-l": true,
          "blue": this.props.widget.type !== 'LastSeenSurveys',
          "magenta": this.props.widget.type === 'LastSeenSurveys'
        })}>
          <CardHeader>
            {isBrowser && <a className="fas fa-grip-vertical dragg float-left mt-13 mr-3 draggableWidget ml-2" />}
            <h4 className="float-left">{this.props.widget.name}</h4>
            <Options widget={this.props.widget} triggerDeleting={this.triggerDeleting} handleDataChange={this.props.handleDataChange} widgetUpdating={this.handleWidgetUpdating} widgetUpdated={this.handleWidgetUpdated}/>
          </CardHeader>
          <hr className="m-0" />
          <CardBody className="h-100" style={{overflowY: 'overlay'}}>
            {!updating && this.props.children}
          </CardBody>
        </Card>
        {this.state.deleting && <CardImgOverlay>
          <LoadingSpinner absolute={true}/>
        </CardImgOverlay>}
      </React.Fragment>
    )
  }
}

WidgetContainer.propTypes = {
  handleDataChange: PropTypes.func
};

export default WidgetContainer;


