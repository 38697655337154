import * as React from 'react';
import PropTypes from "prop-types";
import SelectQuestions from "scenes/Survey/Structure/components/SelectQuestions";
import {Col, FormGroup, Label} from "reactstrap";
import _ from "lodash";
import SelectRows from "scenes/Survey/Structure/components/SelectRows";
import {connect} from "react-redux";
import SelectColumns from "scenes/Survey/Structure/components/SelectColumns";
import SelectAnswers from "scenes/Survey/Structure/components/SelectAnswers";
import { v4 as uuidv4 } from 'uuid';
import Select from "react-select";
import {FormattedMessage} from "react-intl";

function DetailTypeSelect(props) {
  const [value, setValue] = React.useState(props.value);

  React.useEffect(() => {
    props.onChange(value);
  }, [value]);

  const options = ['selected', 'notSelected'].map(type => ({
    label: <FormattedMessage id={`filter.condDetail.Single.detailType-${type}`}/>,
    value: type,
  }))

  return (
    <Select
      options={options}
      value={options.find(o => o.value === value)}
      onChange={option => setValue(option.value)}
    />
  );
}

function CondForm(props) {
  const [from, setFrom] = React.useState(props.from);

  React.useEffect(() => {
    if(!from.key){
      setFrom({
        ...from,
        key: uuidv4(),
      })
    }
  }, []);

  React.useEffect(() => {
    props.onChange(from);
  }, [from]);

  return (
    <div className="p-2 mb-2 border">
      <div className="d-flex justify-content-between">
        <div />
        <h3 className="w-100 text-center">Warunek {props.num}</h3>
        <i className="fas fa-times text-danger pointer p-2" onClick={props.handleOnDelete} />
      </div>
      <FormGroup row className="mb-2">
        <Label md={4}>Jeżeli w pytaniu</Label>
        <Col md={8}>
          <SelectQuestions
            isMulti={false}
            onChange={question => setFrom({
              ...from,
              question,
              answers: [],
              rows: [],
              columns: [],
            })}
          />
        </Col>
      </FormGroup>
      {(from.question) && <FormGroup row className="mb-2">
        <Label md={4}>Respondent</Label>
        <Col md={8}>
          <DetailTypeSelect
            value={from.detailType}
            onChange={detailType => setFrom({
              ...from,
              detailType,
            })}
          />
        </Col>
      </FormGroup>}
      {(from.question && _.has(props.surveyData.questions[from.question], 'rows')) && <FormGroup row className="mb-2">
        <Label md={4}>w wybranych wierszach</Label>
        <Col md={8}>
          <SelectRows
            questionId={from.question}
            onChange={rows => setFrom({
              ...from,
              rows,
            })}
          />
        </Col>
      </FormGroup>}
      {(from.question && _.has(props.surveyData.questions[from.question], 'columns')) && <FormGroup row className="mb-2">
        <Label md={4}>odpowiedzi w jednej z kolumn</Label>
        <Col md={8}>
          <SelectColumns
            questionId={from.question}
            onChange={columns => setFrom({
              ...from,
              columns,
            })}
          />
        </Col>
      </FormGroup>}
      {(from.question && _.has(props.surveyData.questions[from.question], 'answers')) && <FormGroup row className="mb-2">
        <Label md={4}>odpowiedzi</Label>
        <Col md={8}>
          <SelectAnswers
            questionId={from.question}
            onChange={answers => setFrom({
              ...from,
              answers,
            })}
          />
        </Col>
      </FormGroup>}
    </div>
  );
}

CondForm.defaultProps = {
  from: {
    question: null,
    detailType: 'selected',
    answers: [],
    rows: [],
    columns: [],
  },
};

CondForm.propTypes = {
  from: PropTypes.shape({
    question: PropTypes.number,
    detailType: PropTypes.string,
    answers: PropTypes.array,
    rows: PropTypes.array,
    columns: PropTypes.array,
  }),
  handleOnDelete: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    surveyData: state.survey.structure.data,
  }
}

export default connect(mapStateToProps)(CondForm);