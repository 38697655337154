import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import SelectSets from "../../../../SelectSets";
import _ from "lodash";

class Sets extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalSets: props.action.sets || [],
    };

    this.toggleModalSets = this.toggleModalSets.bind(this);
  }

  toggleModalSets(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {action, sets, changeable} = this.props;
    const {modalOpen, modalSets} = this.state;

    if (!changeable) {
      if(action.sets){
        return action.sets.map(id => {
          const set = sets.find(b => b.id === id);

          return 'Zestaw ' + set.positionInSurvey;
        }).join('; ')
      }else{
        return <i>Brak zestawu</i>
      }
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalSets} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalSets}>Wybierz zestawy</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-75 d-inline-block">
          <SelectSets value={modalSets} onChange={sets => {
            this.setState({
              modalSets: sets,
            })
          }} />
        </div>
        <div className="w-25 d-inline-block pl-3">
          Wybranych zestawów: {modalSets.length}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleSetsChanged(modalSets);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={!_.isEmpty(action.sets) ? 'secondary' : 'warning'} className="mx-1 pointer" onClick={this.toggleModalSets}>{!_.isEmpty(action.sets) ? action.sets.map(id => {
          const set = sets.find(b => b.id === id);

          return 'Zestaw ' + set.positionInSurvey;
        }).join('; ') : 'Wybierz zestawy...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

Sets.defaultProps = {
  changeable: true
};

Sets.propTypes = {
  action: PropTypes.shape({
    sets: PropTypes.array,
  }).isRequired,
  handleSetsChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  let sets = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    positionInSurvey++;

    let s = state.survey.structure.data.sets[setId];
    s.positionInSurvey = positionInSurvey;
    sets.push(s);
  });

  return {
    sets
  }
}

export default connect(mapStateToProps)(Sets);