import update from 'immutability-helper';

import {
  SET,
  SET_ANSWERS,
  SET_BASKETS,
  SET_ROWS,
  SET_COLUMNS,
  SET_EXCLUDINGS,
  SET_SCALE_POINTS,
  SET_JAVASCRIPTS,
  ADDED,
  UPDATED,
  DELETED,
  DELETEING,
  EXCLUDING_ADDED,
  ANSWER_ADDED,
  BASKET_ADDED,
  ROW_ADDED,
  COLUMN_ADDED,
  SCALE_POINT_ADDED,
  JAVASCRIPT_ADDED,
  TYPE_UPDATED,
  ANSWERS_REVERSED,
  COLUMNS_REVERSED,
  ROWS_REVERSED, ATTRIBUTE_ADDED
} from './actions';

import * as basketsActions from "../baskets/actions";
import * as attributesActions from "../attributes/actions";
import * as answersActions from "../answers/actions";
import * as rowsActions from "../rows/actions";
import * as columnsActions from "../columns/actions";
import * as excludingsActions from "../excludings/actions";
import * as scalePointsActions from "../scalePoints/actions";

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case basketsActions.DELETED:
      return update(state, {
        [action.basket.question]: {
          baskets: {
            $set: state[action.basket.question].baskets.filter(id => id !== action.basket.id)
          }
        }
      });

    case attributesActions.DELETED:
      return update(state, {
        [action.attribute.question.id]: {
          attributes: {
            $set: state[action.attribute.question.id].attributes.filter(id => id !== action.attribute.id)
          }
        }
      });

    case answersActions.DELETED:
      return update(state, {
        [action.answer.question.id]: {
          answers: {
            $set: state[action.answer.question.id].answers.filter(id => id !== action.answer.id)
          }
        }
      });

    case rowsActions.DELETED:
      return update(state, {
        [action.row.question.id]: {
          rows: {
            $set: state[action.row.question.id].rows.filter(id => id !== action.row.id)
          }
        }
      });

    case columnsActions.DELETED:
      return update(state, {
        [action.column.question.id]: {
          columns: {
            $set: state[action.column.question.id].columns.filter(id => id !== action.column.id)
          }
        }
      });

    case excludingsActions.DELETED:
      return update(state, {
        [action.excluding.question.id]: {
          excludings: {
            $set: state[action.excluding.question.id].excludings.filter(id => id !== action.excluding.id)
          }
        }
      });

    case scalePointsActions.DELETED:
      return update(state, {
        [action.scalePoint.question.id]: {
          scalePoints: {
            $set: state[action.scalePoint.question.id].scalePoints.filter(id => id !== action.scalePoint.id)
          }
        }
      });

    case SET_ANSWERS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          answers: action.data.answers
        }
      };

    case SET_BASKETS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          baskets: action.data.baskets
        }
      };

    case SET_ROWS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          rows: action.data.rows
        }
      };

    case SET_COLUMNS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          columns: action.data.columns
        }
      };

    case ATTRIBUTE_ADDED:
      let attributes = state[action.attribute.question.id].attributes;
      attributes.push(action.attribute.id);
      return update(state, {
        [action.attribute.question.id]: {
          attributes: {
            $set: attributes,
          }
        }
      });

    case ANSWER_ADDED:
      let list = state[action.answer.question.id].answers;
      list.splice(action.answer.position, 0, action.answer.id);
      return update(state, {
        [action.answer.question.id]: {
          answers: {
            $set: list,
          }
        }
      });

    case BASKET_ADDED:
      let listBaskets = state[action.basket.question].baskets;
      listBaskets.splice(action.basket.position, 0, action.basket.id);
      return update(state, {
        [action.basket.question]: {
          baskets: {
            $set: listBaskets,
          }
        }
      });

    case ROW_ADDED:
      let rowList = state[action.row.question.id].rows;
      rowList.splice(action.row.position, 0, action.row.id);
      return update(state, {
        [action.row.question.id]: {
          rows: {
            $set: rowList,
          }
        }
      });

    case COLUMN_ADDED:
      let columnList = state[action.column.question.id].columns;
      columnList.splice(action.column.position, 0, action.column.id);
      return update(state, {
        [action.column.question.id]: {
          columns: {
            $set: columnList,
          }
        }
      });

    case EXCLUDING_ADDED:
      return update(state, {
        [action.excluding.question.id]: {
          excludings: {
            $push: [action.excluding.id]
          }
        }
      });

    case SCALE_POINT_ADDED:
      return update(state, {
        [action.scalePoint.question.id]: {
          scalePoints: {
            $push: [action.scalePoint.id]
          }
        }
      });

    case JAVASCRIPT_ADDED:
      return update(state, {
        [action.javascript.question.id]: {
          javascripts: {
            $push: [action.javascript.id]
          }
        }
      });

    case SET_EXCLUDINGS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          excludings: action.data.excludings
        }
      };

    case SET_SCALE_POINTS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          scalePoints: action.data.scalePoints
        }
      };

    case SET_JAVASCRIPTS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          javascripts: action.data.javascripts
        }
      };

    case SET:
      return action.questions;

    case ADDED:
      return {
        ...state,
        [action.question.id]: action.question
      };

    case UPDATED:
      return update(state, {
        [action.question.id]: {
          $set: action.question
        }
      });

    case DELETEING:
      return update(state, {
        [action.question.id]: {
          deleting: {$set: true}
        }
      });

    case DELETED:
      return update(state, {
        $unset: [action.question.id]
      });

    case TYPE_UPDATED:
      let newState =  {
        ...state,
        [action.question.id]: action.question
      };

      return update(newState, {
        $unset: [action.expiredId]
      });

    case ANSWERS_REVERSED:
      return update(state, {
        [action.questionId]: {
          answers: {
            $set: state[action.questionId].answers.reverse(),
          }
        }
      });

    case COLUMNS_REVERSED:
      return update(state, {
        [action.questionId]: {
          columns: {
            $set: state[action.questionId].columns.reverse(),
          }
        }
      });

    case ROWS_REVERSED:
      return update(state, {
        [action.questionId]: {
          rows: {
            $set: state[action.questionId].rows.reverse(),
          }
        }
      });

    default:
      return state;
  }
};