import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Row, Col, Label} from "reactstrap";
import {updateAnalysis} from "../../../../reducer/analysis/actions";
import StatusesSelect from "components/Survey/Analysis/StatusesSelect";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import AnalysisFilteringAvailable from "components/PlanRestrictionsModals/modals/AnalysisFilteringAvailable";
import Select from "react-select";

const orderOptions = [
	{
		label: "Domyślne",
		value: "default"
	},
	{
		label: "Alfabetycznie",
		value: "content"
	},
	{
		label: "Rosnąco",
		value: "answer.asc"
	},
	{
		label: "Malejąco",
		value: "answer.desc"
	}
];

function OrderAnswerAnalysis(props) {
	const [saving, setSaving] = React.useState(false);
	const [value, setValue] = React.useState(props.analysis.answersOrder)

	const handlerOnChange = (answersOrder) => {
		setSaving(true)
		setValue(answersOrder.value)

		let data = {
			id: props.analysis.id,
			analysis: {
				answersOrder: answersOrder.value
			}
		};

		props.updateAnalysis(data)
			.then(() => {
				setSaving(false)
				props.onSaved();
			})
			.catch(() => setSaving(false));
	}

	return (
		<React.Fragment>
			<Row>
				<Col>
					<Label>
						<FormattedMessage id="orderAnswer.header"/>
					</Label>
				</Col>
			</Row>
			<Row className="mb-4"
				onClick={() => props.analysisPlan.filtering ? null : props.setPlanRestrictionsModals(AnalysisFilteringAvailable.getName())}
			>
				<Col>
					<Select
						isLoading={saving}
						isDisabled={saving}
						placeholder={"Domyślnie"}
						options={orderOptions}
						onChange={handlerOnChange}
						value={value ? orderOptions.find((option) => value === option.value) : ""}
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}

function mapStateToProps(state) {
	return {
		analysis: state.survey.analysis.data.analysis,
		analysisPlan: state.user.userPlan.plan.analysis
	}
}

export default connect(mapStateToProps, {updateAnalysis, setPlanRestrictionsModals})(OrderAnswerAnalysis);