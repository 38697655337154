import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Row, Col, Label} from "reactstrap";
import {saveFilterCollector, deleteFilterCollector} from "../../../../reducer/filtersCollector/actions";
import {updateAnalysis} from "../../../../reducer/analysis/actions";
import CollectorsSelect from "components/Survey/Analysis/CollectorsSelect";
import Switch from "components/Switch";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import AnalysisFilteringAvailable from "components/PlanRestrictionsModals/modals/AnalysisFilteringAvailable";

class FiltersCollector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handlerOnChange = this.handlerOnChange.bind(this);
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
  }

  handleChangeSwitch(value, field) {
    this.setState({
      ...this.state,
      [field]: value
    });

    this.props.updateAnalysis({
      id: this.props.analysis.id,
      analysis: {
        [field]: value
      }
    }).then(() => this.props.onSaved());
  }

  handlerOnChange(e){
    const {filtersCollector} = this.props;
    const value = Object.values(filtersCollector).map(f => f.collector);

    const added = e.filter(v => value.indexOf(v) < 0);
    const removed = value.filter(v => e.indexOf(v) < 0);

    if(added.length > 0){
      added.map(collector => {
        const filter = {
          collector: collector,
          analysis: this.props.analysis.id
        };

        this.props.saveFilterCollector(filter)
        .then(() => {
          this.setState({loading: false});
          this.props.onSaved();
          this.props.onClosed();
        })
        .catch(() => this.setState({loading: false}));
      });

    }

    if(removed.length > 0){
      removed.map(collector => {
        const filterToDelete = Object.values(filtersCollector).filter(f => f.collector === collector)[0];

        this.props.deleteFilterCollector(filterToDelete)
        .then(() => {
          this.setState({loading: false});
          this.props.onSaved();
          this.props.onClosed();
        })
        .catch(() => this.setState({loading: false}));
      });

    }
  }

  render() {
    const {filtersCollector, analysis, analysisPlan} = this.props;

    return (
      <React.Fragment>
        <Row className="mt-4">
          <Col>
            <Label>
              <FormattedMessage id="filtersCollectors.header">{msg => {
                return <span className="float-left">{msg} ({Object.keys(filtersCollector).length})</span>
              }}</FormattedMessage>
            </Label>
          </Col>
        </Row>
        <Row onClick={() => this.props.analysisPlan.filtering ? null : this.props.setPlanRestrictionsModals(AnalysisFilteringAvailable.getName())}>
          <Col>
            <CollectorsSelect
              excludedTypes={['Test']}
              value={analysis.filterCollectors.map(id => filtersCollector[id].collector)}
              survey={{id: analysis.survey}}
              onChange={this.handlerOnChange}
              isDisabled={!analysisPlan.filtering}
            />
          </Col>
        </Row>
        <Row className="mt-2" onClick={() => this.props.analysisPlan.filtering ? null : this.props.setPlanRestrictionsModals(AnalysisFilteringAvailable.getName())}>
          <Col>
            <Label className="pr-2">Dołącz dane z testowych kolektorów</Label>
            <Switch checked={analysis.includeCollectorTest} disabled={!analysisPlan.filtering} onChange={value => this.handleChangeSwitch(value, 'includeCollectorTest')} />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

FiltersCollector.propTypes = {
  onDeleted: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  survey: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
    filtersCollector: state.survey.analysis.data.filtersCollector,
    collectors: state.survey.analysis.data.collectors,
    analysisPlan: state.user.userPlan.plan.analysis
  }
}

export default connect(mapStateToProps, {saveFilterCollector, deleteFilterCollector, updateAnalysis, setPlanRestrictionsModals})(injectIntl(FiltersCollector));
