import React, {Component} from 'react';
import {Nav, NavItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import appRoutes from "appRoutes";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import NavigationAdmin from './NavigationAdmin';
import {connect} from "react-redux";

class NavigationAdminCms extends Component {
  render() {
    const {user} = this.props;

    return (
      <Nav className="navbar-nav m-0 mx-lg-5 align-items-lg-baseline w-50 w-lg-100 float-right d-inline-block d-lg-flex">
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_REPORTER')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.cms.reports} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminCms.reports" /><span className="sr-only"><FormattedMessage id="navigation.adminCms.reports" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_REPORTER')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.cms.reportDownloads} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminCms.reportDownloads" /><span className="sr-only"><FormattedMessage id="navigation.adminCms.reportDownloads" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_REPORTER')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.cms.caseStudies} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.admin.caseStudies" /><span className="sr-only"><FormattedMessage id="navigation.admin.caseStudies" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_REPORTER')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.cms.mediaInfos} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.admin.mediaInfos" /><span className="sr-only"><FormattedMessage id="navigation.admin.mediaInfos" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_REPORTER')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.cms.newses} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminCms.newses" /><span className="sr-only"><FormattedMessage id="navigation.adminCms.newses" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_RANKINGS')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.cms.topSurveysRanks} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminCms.topSurveysRanks" /><span className="sr-only"><FormattedMessage id="navigation.adminCms.topSurveysRanks" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_RANKINGS')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.cms.socialRanks} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminCms.socialRanks" /><span className="sr-only"><FormattedMessage id="navigation.adminCms.socialRanks" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_ANKIETEO_CMS')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.cms.ankieteo.list} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminCms.ankieteo" /><span className="sr-only"><FormattedMessage id="navigation.adminCms.ankieteo" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_SWRESEARCH_CMS')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.cms.swresearch.list} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminCms.swresearch" /><span className="sr-only"><FormattedMessage id="navigation.adminCms.swresearch" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.cms.dashboardNotifies.list} onClick={() => NavigationAdmin.toggleNavbar(true)}>Ankieteo-dash</NavLink></NavItem>}
      </Nav>
    );
  }
}

NavigationAdminCms.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(NavigationAdminCms);
