import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Select from "react-select";
import {FormattedMessage} from "react-intl";
import Button from "../../../../components/Button";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from "../../../../api";

const ResponsesBulkStatusSelect = props => {
  const [value, setValue] = React.useState();
  const [saving, setSaving] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  const change = () => {
    setSaving(true)

    api.surveyResponse.patch.statuses({
      status: value,
      ids: props.checked
    }).then(() => {
      props.onUpdate();
      setModalOpen(false)
      setSaving(false)
    })
  }

  const onSelect = (e) => {
    setValue(e.value)
    setModalOpen(true)
  }

  const statusOptions = _.map(props.statuses, status => {
    return {
      label: <FormattedMessage id={"admin.responses.status-" + status} />,
      value: status
    }
  });

  return <React.Fragment>
    <Select
      options={statusOptions}
      placeholder="Zmień status zaznaczonych"
      className="w-100 w-lg-25 mt-3"
      loading={true}
      onChange={onSelect}
    />
    {modalOpen && <Modal isOpen>
      <ModalHeader tag="h2">Zmień status</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p>Potwierdź zmianę statusu</p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <LaddaButton
          className="btn btn-primary btn-ladda"
          loading={saving}
          data-style={ZOOM_OUT}
          onClick={change}
        >Zmień</LaddaButton>
      </ModalFooter>
    </Modal>}
  </React.Fragment>;
};

ResponsesBulkStatusSelect.propTypes = {
  statuses: PropTypes.array.isRequired,
  checked: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default ResponsesBulkStatusSelect;
