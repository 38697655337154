import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label
} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {copyQuestion} from "scenes/Survey/Structure/data/questions/actions";
import SelectQuestions from "scenes/Survey/Structure/components/SelectQuestions";
import Checkbox from "../../../../../../../../../../../../../../../../../../../../../../../components/Checkbox";

class ModalCopy extends Component {

  constructor(props) {
    super(props);

    this.state = {
      shift: { value: 1, label: <FormattedMessage id="question.headerActions.modalCopy.select.after"/>},
      reference: props.question.id,
      saving: false,
      copyOptions: {
        filters: false,
      },
    };

    this.onShiftChange = this.onShiftChange.bind(this);
    this.onReferenceChange = this.onReferenceChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onShiftChange(select) {
    this.setState({
      shift: select
    });
  }

  onReferenceChange(questionId) {
    this.setState({
      reference: questionId
    });
  }

  onSubmit() {
    this.setState({saving: true});

    const data = {
      question: this.props.question.id,
      reference: this.state.reference,
      position: this.props.questions[this.state.reference].position + this.state.shift.value,
      copyOptions: this.state.copyOptions,
    };

    this.props.copyQuestion(data).then(() => {
      this.setState({saving: false});
      this.props.toggleModal();
    }).catch((e) => {
      console.log('e', e);
      this.setState({saving: false});
    })
  }

  render() {
    const {shift, saving, reference} = this.state;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="lg">
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="question.headerActions.modalCopy.cardHeader"/></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <Row>
            <Col lg={3}>
              <Label for="shift"><FormattedMessage id="question.headerActions.modalCopy.label.shift"/></Label>
            </Col>
            <Col lg={9}>
              <Select
                clearable={false}
                value={shift}
                options={[
                  { value: 1, label: <FormattedMessage id="question.headerActions.modalCopy.select.after"/>},
                  { value: 0, label: <FormattedMessage id="question.headerActions.modalCopy.select.before"/>},
                ]}
                onChange={this.onShiftChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Label for="reference"><FormattedMessage id="question.headerActions.modalCopy.label.reference"/></Label>
            </Col>
            <Col lg={9}>
              <SelectQuestions isMulti={false} onChange={this.onReferenceChange} value={reference}/>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Label for="filters">Kopiuj filtry</Label>
            </Col>
            <Col lg={9}>
              <Checkbox id="copyOptions-filters" tooltip={false} checked={this.state.copyOptions.filters} onClick={() => this.setState({copyOptions: {...this.state.copyOptions, filters: !this.state.copyOptions.filters}})} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="question.headerActions.modalCopy.button.copy">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={saving}
                  data-style={ZOOM_OUT}
                  onClick={this.onSubmit}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggleModal}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalCopy.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    questions: state.survey.structure.data.questions
  }
}

export default connect(mapStateToProps, {copyQuestion})(ModalCopy);