import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Alert, ListGroup} from "reactstrap";
import FiltersQuestionItem from "./FiltersQuestionItem";

class FiltersQuestionList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      deleting: false
    };
  }

  render() {
    const {filtersQuestion} = this.props;

    if(_.isEmpty(filtersQuestion)){
      return <Alert color="warning" className="text-center mb-0"><FormattedMessage id="filtersQuestion.list.noFilters"/></Alert>
    }

    return (
      <ListGroup>
        {_.map(filtersQuestion, filterQuestion => <FiltersQuestionItem filter={filterQuestion} onDeleted={this.props.onDeleted} onEdit={this.props.onEdit} key={filterQuestion.id}/>)}
      </ListGroup>
    )
  }
}

FiltersQuestionList.propTypes = {
  onDeleted: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    filtersQuestion: state.survey.analysis.data.filtersQuestion,
  }
}

export default connect(mapStateToProps)(FiltersQuestionList);