import React, {Component} from 'react';
import PropTypes from "prop-types";
import ProfileFormRespondent from "./ProfileFormRespondent";
import ProfileFormCompany from "./ProfileFormCompany";
import LoadingSpinner from "components/LoadingSpinner";

class ProfileForm extends Component {

  render() {
    const {user} = this.props;

    switch (user.type){
      case 'Respondent':
        return <ProfileFormRespondent user={user}/>;

      case 'Company':
        return <ProfileFormCompany user={user}/>;

      default:
        return <LoadingSpinner/>
    }
  }
}

ProfileForm.propTypes = {
  user: PropTypes.object.isRequired
};

export default ProfileForm;