import {SET, DELETED, UPDATED} from './actionTypes';
import update from 'immutability-helper';

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.plans;

    case UPDATED:
      return update(state, {
        [action.plan.id]: {
          $set: action.plan
        }
      });

    case DELETED:
      return _.filter(state, item => item.id !== action.plan.id);

    default:
      return state;
  }
};