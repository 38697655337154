import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, Col, Label, Modal, Input, ModalBody, ModalFooter, Row} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {updateFilterSerial} from "scenes/Survey/Structure/data/filterSerial/actions";
import SelectQuestions from "scenes/Survey/Structure/components/SelectQuestions";
import CheckAnswers from "scenes/Survey/Structure/components/CheckAnswers";
import CheckRows from "scenes/Survey/Structure/components/CheckRows";
import CheckColumns from "scenes/Survey/Structure/components/CheckColumns";
import FilterBySelect from "../FilterBySelect";
import Select from "react-select";

class FilterSerialEditModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      filter: {
        id: props.filter.id,
        action: props.filter.action,
        hideQuestionToCondsMin: props.filter.hideQuestionToCondsMin,
        name: props.filter.name,
        questionFrom: {
          question: props.filter.questionFrom ? props.filter.questionFrom.question : null,
          answers: props.filter.questionFrom ? props.filter.questionFrom.answers : null,
          rows: props.filter.questionFrom ? props.filter.questionFrom.rows : null,
          columns: props.filter.questionFrom ? props.filter.questionFrom.columns : null,
          filterBy: props.filter.questionFrom ? props.filter.questionFrom.filterBy : null,
        },
        questionTo: {
          question: props.filter.questionTo.question,
          filterBy: props.filter.questionTo.filterBy
        },
      }
    };

    this.onSave = this.onSave.bind(this);
    this.onElementsChange = this.onElementsChange.bind(this);
    this.onAnswersChange = this.onAnswersChange.bind(this);
    this.onRowsChange = this.onRowsChange.bind(this);
    this.onColumnsChange = this.onColumnsChange.bind(this);

    this.onQuestionFromFilterByChange = this.onQuestionFromFilterByChange.bind(this);
    this.onQuestionToFilterByChange = this.onQuestionToFilterByChange.bind(this);
    this.onQuestionFromChange = this.onQuestionFromChange.bind(this);
    this.onQuestionToChange = this.onQuestionToChange.bind(this);
    this.renderCafeterySelect = this.renderCafeterySelect.bind(this);
  }

  onSave() {
    this.setState({saving: true});

    this.props.updateFilterSerial({filterSerial: this.state.filter})
      .then(() => {
        this.setState({saving: false});
        this.props.toggleModal();
      });
  }

  static isRowAndColumnType(questionType) {
    return ['matrix', 'matrixMulti'].includes(questionType);
  }

  onAnswersChange(answers) {
    this.onElementsChange(answers, 'answers')
  }

  onRowsChange(rows) {
    this.onElementsChange(rows, 'rows')
  }

  onColumnsChange(columns) {
    this.onElementsChange(columns, 'columns')
  }

  onElementsChange(elementsIds, elementsType) {
    this.setState({...this.state, filter: {...this.state.filter, questionFrom: {...this.state.filter.questionFrom, [elementsType]: elementsIds}}})
  }

  onQuestionFromFilterByChange(filterBy) {
    this.setState({...this.state, filter: {...this.state.filter, questionFrom: {...this.state.filter.questionFrom, filterBy, rows: [], columns: [], answers: []}}});
  }

  onQuestionToFilterByChange(filterBy) {
    this.setState({...this.state, filter: {...this.state.filter, questionTo: {...this.state.filter.questionTo, filterBy}}});
  }

  onQuestionFromChange(questionId) {
    const filterByKeys = ['answers', 'rows', 'columns'];
    let question = _.find(this.props.questions, question => question.id === questionId);
    const filterBy = _.find(filterByKeys, key => question.hasOwnProperty(key));

    this.setState({
      filter: {
        ...this.state.filter,
        questionFrom: {
          question: question.id,
          filterBy,
          answers: [],
          rows: [],
          columns: []
        }
      }
    })
  }

  onQuestionToChange(questionId) {
    const filterByKeys = ['answers', 'rows', 'columns'];
    let question = _.find(this.props.questions, question => question.id === questionId);
    const filterBy = _.find(filterByKeys, key => question.hasOwnProperty(key));

    this.setState({
      filter: {
        ...this.state.filter,
        questionTo: {
          question: question.id,
          filterBy,
        }
      }
    })
  }

  renderCafeterySelect(questionFrom) {
    switch (questionFrom.filterBy) {
      case 'rows':
        return <CheckRows
          questionId={questionFrom.question}
          onChange={this.onRowsChange}
          checked={questionFrom.rows}
        />;
      case 'columns':
        return <CheckColumns
          questionId={questionFrom.question}
          onChange={this.onColumnsChange}
          checked={questionFrom.columns}
        />;
      case 'answers':
        return <CheckAnswers
          questionId={questionFrom.question}
          onChange={this.onAnswersChange}
          checked={questionFrom.answers}
        />;
    }
  }

  render() {
    const actionOptions = [
      {label: 'Pokaż', value: 1},
      {label: 'Ukryj', value: 2},
    ];

    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="xl">
      <div className="modal-header">
        <h2 className="modal-title w-100">
          Filtr seryjny
        </h2>
        <button type="button" className="close" aria-label="Close" onClick={this.props.toggleModal}><span aria-hidden="true">×</span></button>
      </div>
      <hr className="my-0" />
      <ModalBody>
        <Row>
          <Col lg={3}>
            <Label for="name"><FormattedMessage id="question.filterSerial.editModal.label.name" /></Label>
          </Col>
          <Col lg={9}>
            <Input type="text" name="name" value={this.state.filter.name} onChange={e => this.setState({filter: {...this.state.filter, name: e.target.value}})}/>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label for="name">Akcja</Label>
          </Col>
          <Col lg={9}>
            <Select
              className="mb-2"
              options={actionOptions}
              value={actionOptions.find(option => option.value == this.state.filter.action)}
              onChange={option => {
                this.setState({
                  filter: {
                    ...this.state.filter,
                    action: option.value,
                  }
                })
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label for="name">Akcja</Label>
          </Col>
          <Col lg={9}>
            {this.state.filter.action === 1 && <div className="d-flex flex-row align-items-center">
              <span>Pokaż pytanie jeśli respondent wybierze co najmniej</span>
              <Input className="mx-2" size="sm" type="number" style={{width: '50px'}} onChange={e => {
                this.setState({
                  filter: {
                    ...this.state.filter,
                    hideQuestionToCondsMin: e.target.value,
                  }
                })

              }} value={this.state.filter.hideQuestionToCondsMin}/>
              <span>zaznaczonych poniżej odpowiedzi</span>
            </div>}
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label><FormattedMessage id="question.filterSerial.editModal.label.questionFrom"/></Label>
          </Col>
          <Col lg={9}>
            <SelectQuestions
            className="w-100"
            enabledTypes={['multi', 'matrix', 'ranked', 'conjoint', 'matrixMulti']}
            isMulti={false}
            isLoading={this.state.saving}
            onChange={this.onQuestionFromChange}
            value={this.state.filter.questionFrom.question}
          />
            {this.renderCafeterySelect(this.state.filter.questionFrom)}
          </Col>
        </Row>

        {(this.state.filter.questionFrom.question && FilterSerialEditModal.isRowAndColumnType(this.props.questions[this.state.filter.questionFrom.question].type)) &&
          <Row className="mt-2">
            <Col lg={3}>
              <Label><FormattedMessage id="question.filterSerial.label.filterBy"/></Label>
            </Col>
            <Col lg={9}>
              <FilterBySelect showLabel={false} filterQuestion={this.state.filter.questionFrom} onChange={this.onQuestionFromFilterByChange}/>
            </Col>
          </Row>
        }

        <Row className="mt-2">
          <Col lg={3}>
            <Label><FormattedMessage id="question.filterSerial.editModal.label.questionTo"/></Label>
          </Col>
          <Col lg={9}>
            <SelectQuestions
              className="w-100"
              enabledTypes={['single', 'multi', 'ranked', 'conjoint', 'matrix', 'matrixMulti']}
              isMulti={false}
              isLoading={this.state.saving}
              onChange={this.onQuestionToChange}
              value={this.state.filter.questionTo.question}
            />
         </Col>
        </Row>

        {(this.state.filter.questionTo.question && FilterSerialEditModal.isRowAndColumnType(this.props.questions[this.state.filter.questionTo.question].type)) &&
          <Row className="mt-2">
            <Col lg={3}>
              <Label><FormattedMessage id="question.filterSerial.label.filterBy"/></Label>
            </Col>
            <Col lg={9}>
              <FilterBySelect showLabel={false} filterQuestion={this.state.filter.questionTo} onChange={this.onQuestionToFilterByChange}/>
            </Col>
          </Row>
        }

      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.props.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={this.state.saving}
            data-style={ZOOM_OUT}
            onClick={this.onSave}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>
  }
}

FilterSerialEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    questions: state.survey.structure.data.questions
  }
}

export default connect(mapStateToProps, {updateFilterSerial}, null, { forwardRef: true })(FilterSerialEditModal);
