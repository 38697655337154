import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import Select from 'react-select';
import { connect } from 'react-redux';

class QuestionFromFilterBySelect extends Component {
  render() {
    const {questionFrom, question} = this.props;
    const availableFilterBys = ['answers', 'rows', 'columns'];
    const options = [];

    if (!questionFrom || !question) {
      return null;
    }

    _.each(availableFilterBys, key => {
      if (question.hasOwnProperty(key)) {
        options.push({
          label: <FormattedMessage id={"question.filterIterative.questionsToList.tableHeader.filterBy." + key} />,
          value: key
        });
      }
    });

    if (options.length < 2) {
      return null;
    }

    return (
      <div className="mb-2">
        <FormattedMessage id="filterIterative.questionFromFilterBySelect.filterBy" />:
        <Select
          value={{
            label: <FormattedMessage id={"question.filterIterative.questionsToList.tableHeader.filterBy." + questionFrom.filterBy} />,
            value: questionFrom.filterBy
          }}
          isMulti={false}
          onChange={e => this.props.onChange(e.value)}
          options={options}
        />
      </div>
    )
  }
}

QuestionFromFilterBySelect.propTypes = {
  questionFrom: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    question: props.questionFrom ? state.survey.structure.data.questions[props.questionFrom.question] : null
  }
}

export default connect(mapStateToProps)(QuestionFromFilterBySelect);
