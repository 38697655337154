import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import moment from "moment/moment";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import PropTypes from "prop-types";
import api from "api";
import UserPrizesActions from './UserPrizesActions';
import Checkbox from "components/Checkbox";
import UserPrizesBulkStatusSelect from './UserPrizesBulkStatusSelect';
import {sum} from "react-table/lib/aggregations";
import {Alert, Badge, Button} from "reactstrap";
import Tooltip from "../../../../components/Tooltip";
import ChangeStatus from "./ChangeStatus";

class UserPrizesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      shiftPressed: false,
      searching: false,
      data: [],
      checked: [],
      page: 0,
      pageSize: 200,
      count: 0,
      sorted: [],
      totalPoints: 0,
    };

    this.table = React.createRef();
    this.toggleChecked = this.toggleChecked.bind(this);
    this.handleBulkStatusUpdate = this.handleBulkStatusUpdate.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', e => {
      if (e.key === 'Shift') {
        this.setState({
          shiftPressed: true,
        })
      }
    });
    document.addEventListener('keyup', e => {
      if (e.key === 'Shift') {
        this.setState({
          shiftPressed: false,
        })
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const filtersWereChanged = this.props.filters !== nextProps.filters;

    if (filtersWereChanged) {
      this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, nextProps);
    }
  }

  toggleSelectAll(){
    const {checked, data} = this.state;

    if(checked.length === data.length){
      this.setState({
        checked: [],
      });
    }else{
      this.setState({
        checked: data.filter(d => d.status !== 100).map(d => d.id),
      });
    }
  }

  fetchData(state, props) {
    this.setState({searching: true, checked: [], page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        ...props.filters,
        sort: state.sorted
      }
    };

    api.user.post.searchUserPrizes(data).then(res => {
      this.setState({
        searching: false,
        data: res.userPrizes,
        count: res.count,
        totalPoints: _.sum(_.map(res.userPrizes, d => d.cost))
      });
    });
  }

  handleBulkStatusUpdate() {
    this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, this.props);
  }

  static tableRowStatusColor(status) {
    switch (status) {
      case 3:
        return "bg-light";
      case 5:
        return "bg-info";
      case 50:
        return "bg-warning";
      case 80:
        return "bg-danger";
      case 81:
        return "bg-danger";
      case 99:
        return "bg-success";
      case 100:
        return "bg-secondary";
    }
  }

  toggleChecked(id) {
    const {shiftPressed} = this.state;
    const tableRows = this.getCurrentRows();

    let checked = [];
    if(shiftPressed && this.state.checked.length > 0){
      const idStart = this.state.checked[this.state.checked.length-1];

      let adding = false;
      checked = _.merge(this.state.checked, tableRows.filter(_id => {
        if(_id === idStart){
          adding = true;
        }
        if(_id === id){
          adding = false;
        }


        return adding;
      }), [id]);
    }else{
      checked = _.xor(this.state.checked, [id]);
    }

    this.setState({checked});
  }

  getCurrentRows() {

    const pageSize = this.table.current.state.pageSize;
    const page = this.table.current.state.page;

    const currentRows = this.state.data.slice(page * pageSize, page * pageSize + pageSize);

    return _.map(currentRows, row => row.id);
  }

  render() {
    const {totalPoints} = this.state;

    const columns = [
      {
        id: 'check',
        accessor: userPrize => userPrize.status !== 100 && <Checkbox id="check" checked={this.state.checked.includes(userPrize.id)} tooltip={false} onClick={() => this.toggleChecked(userPrize.id)}/>,
        sortable: false
      },
      {
        Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.id" />,
        accessor: 'id'
      },
      {
        Header: 'IP',
        accessor: 'ip'
      },
      {
      id: 'user',
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.userId" />,
      accessor: userPrize => {
        const id = 'user-prize-' + userPrize.id + '-userid';

        return <div>
          <Badge color={userPrize.user.verified ? 'success' : 'warning'} className="border border-white" id={id} style={{fontSize: '1.5em'}}>{userPrize.user.id}</Badge>
          <Tooltip id={id} msg={userPrize.user.verified ? userPrize.user.verifiedMsg : 'Respondent niezweryfikowany'} />
        </div>
      }
    }, {
      id: 'prize',
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.prize" />,
      accessor: userPrize => userPrize.prize.name
    }, {
      id: 'createdAt',
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.createdAt" />,
      accessor: userPrize => moment(userPrize.createdAt).format('YYYY-MM-DD HH:mm')
    }, {
      id: 'status',
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.status" />,
      accessor: userPrize => <FormattedMessage id={"admin.userPrizes.status-" + userPrize.status} />
    }, {
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.cost" />,
      accessor: 'cost'
    }, {
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.name" />,
      accessor: 'name'
    }, {
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.city" />,
      accessor: 'city'
    }, {
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.postalCode" />,
      accessor: 'postalCode'
    }, {
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.address" />,
      accessor: 'address'
    }, {
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.transferType" />,
      accessor: 'transferType'
    }, {
      Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.payPalEmail" />,
      accessor: 'payPalEmail'
    },
      {
        Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.accountNumber" />,
        accessor: 'accountNumber'
      },
      {
        Header: 'Nazwa banku',
        accessor: 'bankName'
      },
      {
        id: 'emailChangedAt',
        Header: <FormattedMessage id="admin.userPrizes.listItems.tableHeader.emailChangedAt" />,
        accessor: (userPrize) => {
          return <div>
            {userPrize.isEmailChangeWarning && <span style={{display: "block"}} className="fas fa-exclamation-circle text-danger"/>}
            {userPrize.emailChangesAt && moment(userPrize.emailChangesAt).format('YYYY-MM-DD HH:mm')}
          </div>
        }
      },
      {
      id: 'actions',
      Header: <FormattedMessage id="_.table.header.actions"/>,
      accessor: userPrize => <UserPrizesActions userPrize={userPrize} statuses={this.props.statuses} handleChange={() => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, this.props)}/>,
      sortable: false
    }];

    return (
      <React.Fragment>
        <div className="d-flex justify-content-between align-items-center">
          <Alert className="mt-2">Suma punktów do wypłaty: {totalPoints}</Alert>

          <div>
            <ChangeStatus onChanged={() => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, this.props)} />
          </div>
        </div>
        <ReactTable
          minRows={0}
          className={'table-wrap'}
          data={this.state.data}
          columns={columns}
          showPagination={!_.isEmpty(this.state.data)}
          PaginationComponent={Pagination}
          noDataText={<FormattedMessage id="admin.userPrizes.listItems.table.noUserPrizes" />}
          loading={this.state.searching}
          defaultPageSize={50}
          getTrGroupProps={(state, rowInfo) => {
            return {className: UserPrizesList.tableRowStatusColor(rowInfo.original.status)};
          }}
          ref={this.table}
          onFetchData={this.fetchData}
          rowsCount={this.state.count}
          manual
          onSortedChange={sorted => this.setState({sorted})}
        />
        <Button className="mr-2" color={'secondary'} onClick={() => this.toggleSelectAll()}>Zaznacz/Odznacz wszystko {this.state.checked.length}</Button>

        {this.state.checked.length > 0 && <UserPrizesBulkStatusSelect statuses={this.props.statuses} checked={this.state.checked} onUpdate={this.handleBulkStatusUpdate}/>}
      </React.Fragment>
    )
  }
}

UserPrizesList.propTypes = {
  filters: PropTypes.shape({
    search: PropTypes.string,
    status: PropTypes.number
  }).isRequired,
  statuses: PropTypes.array.isRequired
};

export default UserPrizesList;
