import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col
} from "reactstrap";
import ListItems from './components/ListItems';
import {addFilter, updateFilter} from "scenes/Survey/Structure/data/filters/actions";
import striptags from "striptags";
import CardFooter from "reactstrap/es/CardFooter";
import HelpModeIconModal from "components/HelpModeIconModal";
import FilterEditModal from "./components/FilterEditModal";
import reactHtmlParser from "react-html-parser";

class Filters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      saving: false,
      dropdownAddFilterOpen: false,
      modalFilterOpen: false,
      modalPrepareFilterOpen: false,
    };

    this.filter = React.createRef();
    this.toggleEdit = this.toggleEdit.bind(this);
    this.toggleModalFilter = this.toggleModalFilter.bind(this);
    this.toggleModalPrepareFilter = this.toggleModalPrepareFilter.bind(this);
    this.saveFilter = this.saveFilter.bind(this);
  }

  saveFilter(){
    const {survey} = this.props;
    const filter = this.filter.current.getFilter();

    this.setState({
      saving: true
    });

    let data = {
      filter: {
        id: filter.id,
        name: filter.name,
        survey: survey.id,
        blocks: filter.blocks,
        actionsVisibility: filter.actions.filter(action => action.actionType === 'actionVisibility'),
        actionsJump: filter.actions.filter(action => action.actionType === 'actionJump')
      }
    };

    if (filter.hasOwnProperty('id')) {
      this.props.updateFilter(data)
      .then(() => {
        this.setState({
          ...this.state,
          saving: false,
          modalFilterOpen: false,
          editing: false,
        });
      })
      .catch(() => {
        this.setState({
          saving: false,
        });
      });
    } else {
      this.props.addFilter(data)
      .then(() => {
        this.setState({
          ...this.state,
          saving: false,
          modalFilterOpen: false,
          editing: false,
        });
      })
      .catch(() => {
        this.setState({
          saving: false,
        });
      });
    }
  }

  toggleEdit(edit) {
    this.setState({
      editing: edit,
      modalFilterOpen: true,
    });
  }

  toggleModalFilter(){
    this.setState({
      modalFilterOpen: !this.state.modalFilterOpen
    })
  }

  toggleModalPrepareFilter(){
    this.setState({
      modalPrepareFilterOpen: !this.state.modalPrepareFilterOpen
    })
  }

  render() {
    const {modalFilterOpen, modalPrepareFilterOpen, saving} = this.state;
    const {question} = this.props;

    const modalPrepareFilter = <Modal isOpen={modalPrepareFilterOpen} toggle={this.toggleModalPrepareFilter} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalPrepareFilter}>Utwórz filtr/regułę przejścia zawierającej pytanie: {reactHtmlParser(striptags(question.content))}</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <span>Pytanie -> {striptags(question.content)}</span>
        <p>będzie</p>
        <Row>
          <Col className="d-flex align-items-md-stretch">
            <Card className="border-l blue">
              <CardHeader><h2 className="text-center">Warunkiem</h2></CardHeader>
              <hr className="my-0" />
              <CardBody>
                Warunkiem nazywamy wskazanie w wybranym pytaniu kombinacji odpowiedzi wraz z operatorem logicznym (np. I, LUB, co najmniej N wybranych).
                Przykład warunku: Jeśli w pytaniu 1 respondent wskaże odpowiedzi a) lub b)
              </CardBody>
              <CardFooter>
                <Button className="d-block mb-0 w-50 mx-auto" color="primary" onClick={() => {
                  this.setState({
                    editing: {
                      name: 'Filtr',
                      blocks: [
                        {
                          children: [],
                          condsQuestion: [
                            {
                              question: question.id,
                              check: 'detail',
                            }
                          ]
                        }
                      ],
                      actions: [],
                    },
                    modalPrepareFilterOpen: false,
                    modalFilterOpen: true,
                  })
                }}>Wybierz</Button>
              </CardFooter>
            </Card>
          </Col>
          <Col className="d-flex align-items-md-stretch">
            <Card className="border-l blue">
              <CardHeader><h2 className="text-center">Akcją</h2></CardHeader>
              <hr className="my-0" />
              <CardBody>
                Akcją nazywamy każde działanie w ankiecie, które wydarzy się po spełnieniu warunku.
                Przykład akcji: Pokaż pytanie nr 10.
              </CardBody>
              <CardFooter>
                <Button className="d-block mb-0 w-50 mx-auto" color="primary" onClick={() => {
                  this.setState({
                    editing: {
                      name: 'Filtr',
                      blocks: [{
                        children: [],
                        condsQuestion: [],
                      }],
                      actions: [
                        {
                          actionType: 'actionVisibility',
                          visibility: true,
                          type: 'questions',
                          questions: [question.id]
                        }
                      ],
                    },
                    modalPrepareFilterOpen: false,
                    modalFilterOpen: true,
                  })
                }}>Wybierz</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.close">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModalPrepareFilter}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <Card>
        <CardHeader>
          <FormattedMessage id="question.filters.header">{msg => {
            return <h2>{msg} <HelpModeIconModal modalHeader="Filtry">
              <p>W filtrach możesz po spełnieniu określinych warunków ustawić nietypowe akcje w ankiecie takie jak ukrycie elementów ankiety, przeskok do innego miejsca w ankiecie lub całkowie jej zakończenie</p>
            </HelpModeIconModal></h2>
          }}</FormattedMessage>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          {<React.Fragment>
              <ListItems question={this.props.question} toggleEdit={this.toggleEdit}/>

              <Button color="primary" onClick={this.toggleModalPrepareFilter}>Utwórz filtr</Button>
            </React.Fragment>
          }
        </CardBody>

        <FilterEditModal isOpen={modalFilterOpen} filter={this.state.editing || undefined} toggleModal={this.toggleModalFilter} onSave={this.saveFilter} saving={saving} ref={this.filter} />
        {modalPrepareFilter}
      </Card>
    )
  }
}

Filters.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    answers: PropTypes.array,
    rows: PropTypes.array,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey
  }
}

export default connect(mapStateToProps, {addFilter, updateFilter})(Filters);
