import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from "api";

class SurveysModalClear extends Component {

  constructor(props) {
    super(props);

    this.state = {
      clearing: false,
    }
  }

  deleteResponses(){
    this.setState({
      clearing: true
    });

    api.survey.delete.responses({
      id: this.props.survey.id
    }).then(() => {
      this.setState({clearing: false});
      this.props.handleResponsesClear(this.props.survey.id);
      this.props.toggleModal();
    }).catch(() => {
      this.setState({clearing: false});
    });
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="surveys.listItems.modalClear.modalHeader" /> <strong>{this.props.survey.nameWork || this.props.survey.name}</strong></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
          <p><FormattedMessage id="surveys.listItems.modalClear.modalBody" /></p>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggleModal}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.modal.delete.confirmation">{
            (message) => <LaddaButton
              className="btn btn-danger btn-ladda"
              data-style={ZOOM_OUT}
              onClick={() => this.deleteResponses()}
              loading={this.state.clearing}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>

        </ModalFooter>
      </Modal>
    )
  }
}

SurveysModalClear.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleResponsesClear: PropTypes.func.isRequired
};

export default SurveysModalClear;
