import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {addRow} from "scenes/Survey/Structure/data/rows/actions";
import LoadingSpinner from "components/LoadingSpinner";
import {Input} from "reactstrap";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "components/PlanRestrictionsModals/modals/MultimediaAvailable";

class QuestionRowNew extends Component {

  constructor(props) {
    super(props);

    this.state = {
      autoFocus: props.autoFocus,
      saving: false,
      adding: props.autoFocus,
      row: {
        leftContent: '',
        rightContent: '',
        position: props.position,
      }
    };

    this.onChangeContent = this.onChangeContent.bind(this);
    this.saveRow = this.saveRow.bind(this);
    this.closeIfEmpty = this.closeIfEmpty.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.saveRow()
  }

  onChangeContent(content){
    if(this.state.saving){
      return;
    }

    this.setState({
      row: {
        ...this.state.row,
        leftContent: content.includes('|') ? content.substr(0, content.indexOf('|')) : content,
        rightContent: content.includes('|') ? content.substring(content.indexOf('|') + 1) : ""
      }
    })
  }

  saveRow(){
    this.setState({adding: false});
    if(this.state.saving){
      return;
    }

    const row = {
      ...this.state.row,
      position: this.props.position
    };

    if ((row.leftContent.trim()).length === 0)
      return false;

    this.setState({
      saving: true
    });
    
    this.props.addRow({
      question: this.props.question.id,
      row
    }).then(() => {
      this.setState({
        saving: false,
        row: {
          ...this.state.row,
          leftContent: '',
          rightContent: '',
        }
      });

      this.props.handlerAdded && this.props.handlerAdded();
    });
  }

  closeIfEmpty() {
    if ((this.state.row.content.trim()).length === 0) {
      this.props.handlerAdded && this.props.handlerAdded();
    }
  }

  render() {
    const {row, saving, adding, autoFocus} = this.state;
    const {question, plan} = this.props;

    if(question.rowsImage){
      return (
        <div className="line-wrap">
          <ul className="mb-0 ml-5">
            <li className="float-left">
              {saving ?
                <LoadingSpinner width={21} classNames={'my-0'}/>
                :
                <small
                  onClick={() => {
                    if (!plan.multimedia.access) {
                      this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
                      return;
                    }
                    CKFinder.popup({
                      chooseFiles: true,
                      width: 1014,
                      height: 700,
                      onInit: finder => {
                        finder.on('files:choose', e => {
                          this.onChangeContent(e.data.files.first().getUrl());
                          this.saveRow();
                        });
                      }
                    })
                  }}
                ><FormattedMessage id="rows.rowNew.inputImage.placeholder" /></small>
              }
            </li>
          </ul>
        </div>
      )
    }

    let editor = <Input value={row.content} onChange={e => this.onChangeContent(e.target.value)} onBlur={this.saveColumn} autoFocus={autoFocus} onKeyPress={this.handleKeyPress}/>

    return (
      <div className="line-wrap d-print-none">
        <ul className="mb-0 ml-lg-5 d-flex">
          <li className="flex-grow-1">

            {adding
              ? editor
              : saving
                ? <LoadingSpinner width={21} classNames="my-0"/>
                : <small onClick={() => this.setState({adding: true, autoFocus: true})}><i><FormattedMessage id="rows.rowNew.input.placeholder" /></i></small>
            }

          </li>
        </ul>
      </div>
    )
  }
}

QuestionRowNew.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rowsImage: PropTypes.bool.isRequired,
  }).isRequired,
  position: PropTypes.number.isRequired,
  handlerAdded: PropTypes.func,
  autoFocus: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {addRow, setPlanRestrictionsModals})(QuestionRowNew);