import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import classnames from "classnames";
import Select from "react-select";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";
import {Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";


class ColumnThead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      filterExcluding: false,
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterExcludingChange = this.onFilterExcludingChange.bind(this);
  }

  static getColSpan(question){
    return 1 + (question.excludings.length > 0 ? 1 : 0);
  }

  componentWillUnmount() {
    const {question} = this.props;

    this.props.onFilterChange('q' + question.id, false);
    this.props.onFilterChange('q' + question.id + 'excl', false);
  }

  onFilterChange(type, value){
    let {filter} = this.state;
    const {question} = this.props;
    if(value === ''){
      delete filter[type];
    }else{
      filter[type] = value;
    }

    this.setState({
      filter
    }, () => {
      const key = 'q' + question.id;

      this.props.onFilterChange(key, (this.state.filter === false || _.isEmpty(this.state.filter)) ? false : {
          question: question.id,
          answers: this.state.filter,
        }
      );
    });
  }

  onFilterExcludingChange(value){
    const {question} = this.props;

    if(value.length === 0){
      value = false;
    }

    this.setState({
      filterExcluding: value
    }, () => {
      const key = 'q' + question.id + 'excl';

      this.props.onFilterChange(key, this.state.filterExcluding === false ? false : {
          question: question.id,
          excludings: this.state.filterExcluding,
        }
      );
    });
  }

  render() {
    const {filter, filterExcluding} = this.state;
    const {question} = this.props;

    return <React.Fragment>
      <th
        className="text-center pointer border-left border-right"
        onClick={() => filter === false && this.setState({filter: null}) }
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filter !== false,
          'align-items-center': filter === false,
        })}>
          <div className="w-100">Wpisana odpowiedź</div>
          {filter !== false && <div className="d-flex">
            <InputGroup size="sm" className="mb-0">
              <InputGroupAddon addonType="prepend"><InputGroupText>Wartość od</InputGroupText></InputGroupAddon>
              <Input type="number" placeholder="Od 0" onBlur={(e) => this.onFilterChange('from', e.target.value === '' ? '' : Math.min(10, parseInt(e.target.value)))} />
            </InputGroup>
            <InputGroup size="sm" className="mb-0 ml-1">
              <InputGroupAddon addonType="prepend"><InputGroupText>Wartość do</InputGroupText></InputGroupAddon>
              <Input type="number" placeholder="Do 10" onBlur={(e) => this.onFilterChange('to', e.target.value === '' ? '' : Math.min(10, parseInt(e.target.value)))} />
            </InputGroup>
          </div>}
        </div>
      </th>
      {question.excludings.length > 0 && <th
        className="text-center border-left border-right pointer"
        onClick={() => filterExcluding === false && this.setState({filterExcluding: null})}
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filterExcluding !== false,
          'align-items-center': filterExcluding === false,
        })}>
          <div className="w-100">Wykluczająca</div>
          {filterExcluding !== false && <SelectExcludings
            isClearable={true}
            isMulti={true}
            value={filterExcluding}
            questionId={question.id}
            onChange={this.onFilterExcludingChange}
          />}
        </div>
      </th>}
    </React.Fragment>;
  }
}

ColumnThead.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['numeric']),
    excludings: PropTypes.array,
  }).isRequired,
};

export default ColumnThead;