import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row} from 'reactstrap';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Select from 'react-select';
import SelectBlocks from "scenes/Survey/Structure/components/SelectBlocks";
import {addBlockFromTemplates} from "../../../../scenes/Survey/Structure/data/blocks/actions";

class TemplatesAddModal extends Component{

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      block: null,
      shift: {value: 0, label: <FormattedMessage id="templates.templatesModal.select.before"/>}
    };

    this.onShiftChange = this.onShiftChange.bind(this);
    this.onBlockChange = this.onBlockChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onShiftChange(select) {
    this.setState({
      shift: select
    });
  }

  onBlockChange(blockId) {
    this.setState({
      block: blockId
    });
  }

  onSubmit() {
    let setId;

    this.setState({...this.state, saving: true});

    if(this.state.block){
      _.each(this.props.sets, set => {
        if (set.blockPages.indexOf(this.state.block) > -1) {
          setId = set.id;
          return false;
        }
      })
    }else{
      setId = Object.values(this.props.sets)[0].id;
    }

    this.props.addBlockFromTemplates({
      templates: this.props.blockTemplates,
      set: setId,
      position: this.state.block ? (this.props.blocks[this.state.block].position + this.state.shift.value) : 0,
    })
    .then(blocks => {
      this.props.toggleModal()
    })
    .catch(res => {
      this.props.toggleModal()
    });
  }

  render() {
    return (
      <Modal isOpen toggle={this.props.toggleModal} className={'modal-danger'} size={'lg'}>
        <ModalHeader toggle={this.props.toggleModal} tag="h2">Tworzenie bloków z szablonów</ModalHeader>
        <hr className="m-0" />
        <ModalBody>
          {_.isEmpty(this.props.blocks) && <Row className="mt-2">
            <Col>
              <div className="text-center py-3">Wstaw na początku ankiety</div>
            </Col>
          </Row>}

          {!_.isEmpty(this.props.blocks) && <React.Fragment>
            <Row>
              <Col lg={3}>
                <FormattedMessage id="templates.templatesModal.label.shift"/>
              </Col>
              <Col lg={9}>
                <Select
                  clearable={false}
                  value={this.state.shift}
                  options={[
                    {value: 0, label: 'Przed'},
                    {value: 1, label: 'Po'},
                  ]}
                  onChange={this.onShiftChange}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={3}>Wybierz blok</Col>
              <Col lg={9}>
                <SelectBlocks isMulti={false} value={this.state.block} onChange={this.onBlockChange}/>
              </Col>
            </Row>
          </React.Fragment>}
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggleModal}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda"
              loading={this.state.saving}
              data-style={ZOOM_OUT}
              onClick={this.onSubmit}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    )
  }
}

TemplatesAddModal.propTypes = {
  blockTemplates: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  toggleModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    blocks: state.survey.structure.data.blocks,
    sets: state.survey.structure.data.sets
  }
}

export default connect(mapStateToProps, {addBlockFromTemplates})(TemplatesAddModal);