import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button, Alert} from "reactstrap";
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import api from 'api';
import { connect } from 'react-redux';
import {topSurveysCollectorRemoved} from '../../../Structure/data/survey/actions';

class CollectorsModalDeleteBulk extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      deleting: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  collectorDelete(){
    this.setState({
      deleting: true
    });

    api.surveyCollector.delete.collectors({
      collectors: this.props.collectors,
    }).then(() => {
      this.setState({deleting: false});
      this.toggleModal();

      this.props.collectors.map((id) => {
        this.props.handleCollectorDelete(id);
      })
    });
  }

  render() {
    const { deleting } = this.state;

    const modal =
      <Modal isOpen={this.state.isOpen} toggle={this.toggleModal} size="lg">
        <ModalHeader toggle={this.toggleModal} tag="h2" className="bg-danger"><span className="text-white">Usuń zaznaczone kolektory</span></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
        <Alert color="danger">
          <div className="d-flex align-items-center">
            <i className="fas fa-exclamation-triangle fa-3x pr-3" />
            <div className="d-flex flex-column">
              <span className="font-weight-bold">Czy na pewno chcesz usunąć zaznaczone kolektory danych?</span>
              <span className="mt-2">Z kolektorami zostaną usunięte wszystkie odpowiedzi, które zostały zebrane w tych kolektorach</span>
            </div>
          </div>
        </Alert>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.modal.delete.confirmation">{
            (message) => <LaddaButton
              className="btn btn-danger btn-ladda"
              loading={deleting}
              data-style={ZOOM_OUT}
              onClick={() => this.collectorDelete()}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.toggleModal}>{message}</Button>
          }</FormattedMessage>

        </ModalFooter>
      </Modal>;

    return <React.Fragment>
      {modal}
      <Button color="danger" onClick={this.toggleModal}>Usuń zaznaczone</Button>
    </React.Fragment>

  }
}

CollectorsModalDeleteBulk.propTypes = {
  collectors: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleCollectorDelete: PropTypes.func.isRequired
};

export default connect(null, {topSurveysCollectorRemoved})(CollectorsModalDeleteBulk);