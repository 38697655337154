import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import PropTypes from 'prop-types';
import api from "api";

class ModalSaveTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({saving: true});

    api.user.questionTemplates.post(this.props.question.id, {}).then(() => {
      this.setState({saving: false});
      this.props.toggleModal();
    })
  }

  render() {
    const {saving} = this.state;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="question.headerActions.modalSaveTemplate.cardHeader"/></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
          <p><FormattedMessage id="question.headerActions.modalSaveTemplate.cardBody"/></p>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={saving}
                  data-style={ZOOM_OUT}
                  onClick={this.onSubmit}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggleModal}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalSaveTemplate.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default ModalSaveTemplate;