import React, {Component} from 'react';
import PropTypes from "prop-types";
import RandAnswers from "../../../RandAnswers";
import SettingBool from "../../../SettingBool";
import AnswersImage from "../../../AnswersImage";
import FetchCafeteryPositions from "../../../FetchCafeteryPositions";
import AnswersMinMax from "../../../AnswersMinMax";
import {connect} from "react-redux";

class BasketSettings extends Component {

  render() {
    const {question, questionSettingsPlan} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'requiredAll'} value={question.requiredAll} icon={<i className="fa-solid fa-message-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'rotateAnswers'} value={question.rotateAnswers} disabled={!questionSettingsPlan.basket.rotateAnswers} icon={<i className="fas fa-random" />} handleUpdate={this.props.handleUpdate}/>
        <AnswersImage question={question} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'reverseAnswers'} value={question.reverseAnswers} icon={<i className="fa-solid fa-retweet" />} handleUpdate={this.props.handleUpdate}/>
        <AnswersMinMax question={question} disabled={!questionSettingsPlan.minMaxCafetery} handleUpdate={this.props.handleUpdate}/>
        <RandAnswers question={question} disabled={!questionSettingsPlan.randCafetery} handleUpdate={this.props.handleUpdate}/>
        <FetchCafeteryPositions question={question} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'showVertically'} value={question.showVertically} icon={<i className="fas fa-grip-lines" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'stackedAnswers'} value={question.stackedAnswers} icon={<i className="fa-solid fa-layer-group" />} handleUpdate={this.props.handleUpdate}/>
      </React.Fragment>
    )
  }
}

BasketSettings.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    questionSettingsPlan: state.user.userPlan.plan.survey.questionSettings
  }
}

export default connect(mapStateToProps)(BasketSettings);
