import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input
} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';


import {addColumns} from "scenes/Survey/Structure/data/questions/actions";

class ActionAdd extends Component {

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      modal: false,
      saving: false,
    };

    this.onChangeText = this.onChangeText.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
    this.setState({ modal: !this.state.modal });
  }

  onChangeText(text){
    this.setState({
      text
    })
  }

  saveColumn(){
    const {text, saving} = this.state;
    const {question} = this.props;

    if(_.isEmpty(text) || saving){
      return;
    }

    let columns = [];

    text.split("\n").map(t => {
      if (t.trim()) {
        columns.push({
          content: t
        });
      }
    });

    this.setState({
      saving: true
    });
    this.props.addColumns(question.id, columns).then(() => {
      this.setState({
        text: '',
        saving: false,
        modal: false,
      });
    });
  }

  render() {
    const {text, saving} = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="columns.actions.add.cardHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="columns.actions.add.cardBody"/></p>
        <Form>
          <FormGroup>
            <Input
              type="textarea"
              disabled={saving}
              rows={10}
              onChange={(e) => this.onChangeText(e.target.value)}
              value={text}
              className="custom"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="columns.actions.add.button.confirmation">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={() => this.saveColumn()}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button onClick={this.toggleModal}><FormattedMessage id="columns.actions.add.button.add"/></Button>
        {this.state.modal && modal}
      </React.Fragment>
    )
  }
}

ActionAdd.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};


export default connect(null, {addColumns})(ActionAdd);