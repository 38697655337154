import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import { connect } from 'react-redux';
import FilterIterative from "../QuestionTo/components/FilterIterative";
import _ from "lodash";

class EditModal extends Component {
  render() {
    const {isOpen, toggleModal, filter, survey, questions} = this.props;

    return <Modal isOpen={isOpen} toggle={toggleModal} size="lg">
      <ModalHeader toggle={toggleModal} tag="h2"><FormattedMessage id="question.filterIterative.editModal.modalHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <FilterIterative filter={filter} surveyId={survey.id} questions={questions}/>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  }
}

EditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let enableQuestionTypes = ['single', 'multi', 'matrix', 'ranked', 'conjoint', 'matrixMulti', 'numericMulti'];
  let questionList = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          let question = state.survey.structure.data.questions[questionId];
          positionInSurvey++;

          if (enableQuestionTypes.includes(question.type)) {
            question.positionInSurvey = positionInSurvey;
            questionList.push(question)
          }
        })
      });
    });
  });

  return {
    survey: state.survey.structure.data.survey,
    questions: questionList,
  }
}

export default connect(mapStateToProps)(EditModal);
