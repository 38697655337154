import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import GroupUsersActions from './GroupUsersActions';
import PropTypes from 'prop-types';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import UserAvatar from "../../../components/UserAvatar";

class GroupUsersList extends Component {

  constructor(props) {
    super(props);

    this.formatPrivilageSurveyShareDefault = this.formatPrivilageSurveyShareDefault.bind(this);
  }

  formatPrivilageSurveyShareDefault(group){
    const {surveySharePrivilages} = this.props;

    return _.find(surveySharePrivilages, p => p.id === group.privilageSurveyShareDefault).name;
  }

  static confirmed(userGroup) {
    if(userGroup.blocked){
      return <FormattedMessage id="groupUsersList.listItems.blocked" />;
    }
    return userGroup.confirmed
      ? <FormattedMessage id="groupUsersList.listItems.confirmed.true" />
      : <FormattedMessage id="groupUsersList.listItems.confirmed.false" />;
  }

  render() {
    const {group} = this.props;

    const columns = [{
      id: 'avatar',
      accessor: group => <UserAvatar user={group.user} size={26} />
    }, {
      Header: <FormattedMessage id="groupUsersList.listItems.tableHeader.email" />,
      accessor: 'email'
    }, {
      id: 'privilageSurveyShareDefault',
      Header: <FormattedMessage id="groupUsersList.listItems.tableHeader.privilageSurveyShareDefault" />,
      accessor: group => this.formatPrivilageSurveyShareDefault(group)
    }, {
      Header: <FormattedMessage id="groupUsersList.listItems.tableHeader.createdAt" />,
      accessor: 'createdAt'
    }, {
      Header: <FormattedMessage id="groupUsersList.listItems.tableHeader.surveys" />,
      accessor: 'surveyShares'
    }, {
      id: 'confirmed',
      Header: <FormattedMessage id="groupUsersList.listItems.tableHeader.confirmed" />,
      accessor: group => GroupUsersList.confirmed(group)
    }, {
      id: 'actions',
      Header: <FormattedMessage id="groupUsersList.listItems.tableHeader.actions" />,
      accessor: user => <GroupUsersActions user={user} group={group}/>
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap mt-4'}
        data={Object.values(group.users)}
        columns={columns}
        showPagination={!_.isEmpty(group.users)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="groupUsersList.listItems.table.noUsers" />}
        defaultPageSize={10}
      />
    )
  }
}

GroupUsersList.propTypes = {
  group: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    surveySharePrivilages: state.config.data.group.surveySharePrivilage
  }
}

export default connect(mapStateToProps)(GroupUsersList);