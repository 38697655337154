import React, { useEffect, useRef } from 'react';

const checkIsGroupRole = (access, user, userGroup) => {
  if (!user) {
    return false;
  }

  if (!userGroup || userGroup.length === 0) {
    return true;
  }

  const groupUser = userGroup.find((entry) => entry.email === user.email)
  if (groupUser) {
    return groupUser.privilageSurveyShareDefault[access] === true;
  }

  return true;
}

export default checkIsGroupRole;