import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import { Col, Form, FormGroup, Label} from "reactstrap";
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import { connect } from 'react-redux';

class EditForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userPrize: {
        status: props.userPrize.status,
      }
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      userPrize: {
        ...this.state.userPrize,
        [e.target.name]: e.target.value
      }
    });
  }

  render() {
    const {userPrize} = this.state;
    const {errors, onSubmit, statuses} = this.props;

    const statusOptions = _.map(statuses, status => {
      return {
        label: <FormattedMessage id={"admin.userPrizes.status-" + status} />,
        value: status
      }
    });

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.userPrize.editForm.label.status" /></Label>
          <Col lg={9} className="pt-1">
            <Select
              isClearable={false}
              value={_.find(statusOptions, option => option.value === userPrize.status)}
              options={statusOptions}
              onChange={e => this.onChange({target: {name: 'status', value: e.value}})}
              isDisabled={this.props.userPrize.status === 100}
            />
            {errors.status && <p className="help-block text-danger">{errors.status}</p>}
            {this.state.userPrize.status === 100 && this.props.userPrize.status !== 100 && <p className="help-block text-danger mt-1"><FormattedMessage id="admin.userPrize.editForm.status.canceled.info" /></p>}
            {this.props.userPrize.status === 100 && <p className="help-block text-danger mt-1"><FormattedMessage id="admin.userPrize.editForm.status.canceled" /></p>}
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

EditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  userPrize: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired,
};

export default connect(null, {}, null, {forwardRef: true})(EditForm);