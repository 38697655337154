import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label} from "reactstrap";
import {ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import appRoutes from 'appRoutes';
import SmtpAccountSelect from 'components/SmtpAccountSelect';
import api from 'api';
import _ from "lodash";
import TabTemplate from "./TabTemplate";
import {toast} from "react-toastify";
import ModalSimple from "../../../../../../../componentsReusable/ModalSimple";

class TabSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      saving: false,
      data: {
        sendDateStart: props.collector.sendEmail && props.collector.sendEmail.sendDateStart ? moment(props.collector.sendEmail.sendDateStart).format('YYYY-MM-DD HH:mm') : null,
        sendHourFrom: props.collector.sendEmail ? props.collector.sendEmail.sendHourFrom : null,
        sendHourTo: props.collector.sendEmail ? props.collector.sendEmail.sendHourTo : null,
        reminder: {
          count: props.collector.sendEmail && props.collector.sendEmail.reminder ? props.collector.sendEmail.reminder.count : null,
          sendAfter: props.collector.sendEmail && props.collector.sendEmail.reminder ? props.collector.sendEmail.reminder.sendAfter : null,
        },
        smtp: props.collector.smtp ? props.collector.smtp : null
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleSmtpChange = this.handleSmtpChange.bind(this);
  }

  onChange(e){
    this.setState({
      data: {
        ...this.state.data,
        reminder: {
          ...this.state.data.reminder,
          [e.target.name]: parseInt(e.target.value) || 0
        }
      }
    });
  }

  handleSmtpChange(smtpId){
    this.setState({
      data:{
        ...this.state.data,
        smtp: smtpId
      }
    });
  }

  bulkEdit(data) {
    let bulkEditData = {
      sendDateStart: data.sendDateStart ? data.sendDateStart : undefined,
      sendHourFrom: data.sendHourFrom ? data.sendHourFrom : undefined,
      sendHourTo: data.sendHourTo ? data.sendHourTo : undefined,
      reminder: {
        count: data.reminder.count ? data.reminder.count : undefined,
        sendAfter: data.reminder.sendAfter ? data.reminder.sendAfter : undefined
      }
    };

    this.setState({saving: true});
    let toSend = {
      sendEmail: bulkEditData
    };
    api.surveyCollector.bulk(this.props.collector.ids, toSend)
    .then(() => {
      this.setState({saving: false, modalOpen: false});
      toast.success(<FormattedMessage id="collectors.bulkEditForm.toast.success" />);
      this.props.handleUpdate(toSend);
    })
    .catch(e => {
      this.setState({
        saving: false,
        errors: _.has(e, 'response.data.form.errors') ? e.response.data.form.errors : {},
      })
    });
  }

  onSubmit(e){
    e.preventDefault();
    const {data} = this.state;

    if (this.props.bulkEdit === true) {
      this.setState({modalOpen: true});
    } else {
      this.setState({saving: true});
      api.surveyCollector.patch({
        id: this.props.collector.id,
        surveyCollector: {
          sendEmail: data,
          smtp: data.smtp
        }
      }).then(collector => {
        this.setState({saving: false});
        this.props.handleUpdate(collector);
      }).catch(() => {
        this.setState({saving: false});
      });
    }
  }

  render() {
    const {collector} = this.props;
    const {data, saving, modalOpen} = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        {!this.props.bulkEdit && <a href={appRoutes.survey.collectors.collector.panel.send(collector.id)} target="_blank" className="mb-5 d-block">Wyślij zaproszenia</a>}

        <FormGroup>
          <Label for="sendDateStart"><FormattedMessage id="collectorPanel.settings.sendEmail.sendDateStart"/></Label>
          {<DatePicker
            dropdownMode={"select"}
            className="form-control"
            selected={this.state.data.sendDateStart ? moment(this.state.data.sendDateStart) : null}
            onChange={(sendDateStart) => {
              this.setState({data: {...data, sendDateStart: sendDateStart.format('YYYY-MM-DD HH:mm')}})
            }}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={30}
            dateFormat="Y-MM-DD HH:mm"
            locale={localStorage.language}
          />}
        </FormGroup>
        <FormGroup>
          <Label>Zakres godzin wysyłki</Label>
          <div className="d-flex flex-row flex-nowrap align-items-center">
            <InputGroup size={'sm'} className="mr-2" style={{ width: '100px' }}>
              <InputGroupAddon addonType={'prepend'}>
                <InputGroupText>Od</InputGroupText>
              </InputGroupAddon>
              <div className="form-control border-top-0 border-right-0 border-bottom-0">
                <DatePicker
                  className="mb-0"
                  isClearable
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  selected={data.sendHourFrom ? moment('1980-01-01 ' + data.sendHourFrom) : null}
                  onChange={(date) => this.setState({data: {...data, sendHourFrom: date ? date.format('HH:mm') : null}})}
                  locale={localStorage.language}
                  dropdownMode="select"
                />
              </div>
            </InputGroup>

            <InputGroup size={'sm'} style={{ width: '100px' }}>
              <InputGroupAddon addonType={'prepend'}>
                <InputGroupText>Do</InputGroupText>
              </InputGroupAddon>
              <div className="form-control border-top-0 border-right-0 border-bottom-0">
                <DatePicker
                  className="mb-0"
                  isClearable
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  timeFormat="HH:mm"
                  selected={data.sendHourTo ? moment('1980-01-01 ' + data.sendHourTo) : null}
                  onChange={(date) => this.setState({data: {...data, sendHourTo: date ? date.format('HH:mm') : null}})}
                  locale={localStorage.language}
                  dropdownMode="select"
                />
              </div>
            </InputGroup>
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="count"><FormattedMessage id="collectorPanel.settings.sendEmail.reminder.count"/></Label>
          <Input
            id="count"
            name="count"
            type="number"
            onChange={this.onChange}
            value={data.reminder.count}
            min={0}
          />
        </FormGroup>
        <FormGroup>
          <Label for="count"><FormattedMessage id="collectorPanel.settings.sendEmail.reminder.sendAfter"/></Label>
          <InputGroup>
            <Input
              id="sendAfter"
              name="sendAfter"
              type="number"
              onChange={this.onChange}
              value={data.reminder.sendAfter}
              min={0}
            />
            <InputGroupAddon addonType={"append"}>h</InputGroupAddon>
          </InputGroup>
        </FormGroup>
        {!this.props.bulkEdit && <FormGroup>
          <Label for="smtp"><FormattedMessage id="collectorPanel.settings.sendEmail.smtp.label"/></Label>
          <SmtpAccountSelect smtpId={collector.smtp} handleSmtpChange={this.handleSmtpChange}/>
        </FormGroup>}

        <ModalSimple onAccept={() => this.bulkEdit(data)}
            onCancel={() => {this.setState({modalOpen: false})}}
            modalOpen={modalOpen} headerText={"Masowa edycja"}
            bodyText={"Czy na pewno chcesz dokonać grupowej zmiany kolektorów?"}/>

        <div className="direction-group">
          <div className="direction-right">
            <LaddaButton
              loading={saving}
              className="btn btn-primary btn-ladda mt-2"
              data-style={ZOOM_OUT}
            >
              <FormattedMessage id="_.button.save"/>
            </LaddaButton>
          </div>
        </div>
      </Form>
    )
  }
}

TabSettings.defaultProps = {
  bulkEdit: false
}

TabSettings.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sendEmail: PropTypes.shape({
      reminder: PropTypes.shape({
        count: PropTypes.number.isRequired,
        sendAfter: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    smtp: PropTypes.number,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  bulkEdit: PropTypes.bool.isRequired
};

export default TabSettings;
