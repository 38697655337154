import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Pagination from "components/Pagination";
import {FormattedMessage} from "react-intl";
import ReactTable from "react-table";
import {forEach, isArray, xor} from "lodash";
import {Button, ButtonGroup, Row, Col} from "reactstrap";
import ButtonDelete from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/Filters/components/ListItems/components/ButtonDelete";
import ButtonEdit from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/Filters/components/ListItems/components/ButtonEdit";
import ButtonCopy from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/Filters/components/ListItems/components/ButtonCopy";
import ButtonPause from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/Filters/components/ListItems/components/ButtonPause";
import FilterEditModal from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/Filters/components/FilterEditModal";
import {addFilter, updateFilter} from "../../../Structure/data/filters/actions";
import Action from "../../../Structure/components/Filter/Action";
import Block from "../../../Structure/components/Filter/Block";
import _ from "lodash";
import FilterSerialEditModal  from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/FilterSerial/EditModal";
import SurveyPauseFilters from "./components/SurveyPauseFilters";
import FilterIterativeEditModal  from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/FilterIterative/EditModal";
import QuestionContentTooltip from "components/QuestionContentTooltip/QuestionContentTooltip";
import {toast} from "react-toastify";
import FiltersGroup from "../../../FiltersGroup";
import DeleteSelectedModal from "./components/DeleteSelectedModal";
import FiltersSerialGroup from "../../../FiltersSerialGroup";
import ButtonCopyFilterSerial
  from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/FilterSerial/QuestionTo/components/FilterSerial/components/ButtonCopyFilterSerial";
import ButtonCopyFilterIterative
  from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/FilterIterative/ButtonCopyFilterIterative";
import SurveyFilterFromTextarea from "./SurveyFilterFromTextarea";
import FilterSerialCond from "./components/FilterSerialCond";
import FilterSerialAction from "./components/FilterSerialAction";
import FilterIterationCond from "./components/FilterIterationCond";
import FilterNameColumn from "./FilterNameColumn";
import FilterIterationActions from "./components/FilterIterationActions";
import FiltersEditTextarea from "./FiltersEditTextarea";

class Filters extends Component {

  constructor(props) {
    super(props);
    this.actions = this.actions.bind(this);

    this.tableRef = React.createRef();

    this.state = {
      selected: [],
      editing: false,
      filter: null,
      filterSerial: null,
      filterIterative: null,
      saving: false
    };

    this.filter = React.createRef();
    this.getAllFilters = this.getAllFilters.bind(this);
    this.saveFilter = this.saveFilter.bind(this);
    this.toggleCreateFilter = this.toggleCreateFilter.bind(this);
    this.filterConditions = this.filterConditions.bind(this);
    this.filterActions = this.filterActions.bind(this);
    this.getCurrentDataTable = this.getCurrentDataTable.bind(this);
    this.toggleCreateFilter = this.toggleCreateFilter.bind(this);
  }

  getAllFilters(){
    return [...Object.values(this.props.filters), ...Object.values(this.props.serialFilters), ...Object.values(this.props.iterativeFilters)]
  }

  omitIdBlock(block){
    block = _.omit(block, ['id']);

    block.condsQuestion = block.condsQuestion.map(cond => _.omit(cond, ['id']));
    block.children = block.children.map(b => this.omitIdBlock(b));

    return block;
  }

  actions(filter) {
    const isFilterSerial = Object.values(this.props.serialFilters).includes(filter);
    const isFilterIterative = Object.values(this.props.iterativeFilters).includes(filter);

    return (
      <ButtonGroup>
        <ButtonPause filter={filter}/>
        <ButtonEdit filter={filter} onClick={() => {
          let data = isFilterSerial ? {editing: true, filterSerial: filter} : isFilterIterative ? {editing: true, filterIterative: filter} : {editing: true, filter};
          this.setState(data)
        }} />
        {(!isFilterSerial && !isFilterIterative) && <ButtonCopy filter={filter} onClick={() => {
          let filterCopy = _.clone(filter);
          filterCopy = _.omit(_.merge(filterCopy, {name: filterCopy.name + ' kopia'}), ['id']);
          filterCopy.blocks = filterCopy.blocks.filter(block => block.parent === false);

          filterCopy.actions = filterCopy.actions.map(action => _.omit(action, ['id']));
          filterCopy.blocks = filterCopy.blocks.map(b => this.omitIdBlock(b));

          let data = {editing: true, filter: filterCopy};
          this.setState(data)
        }}/>}
        {isFilterSerial && <ButtonCopyFilterSerial filter={filter} />}
        {isFilterIterative && <ButtonCopyFilterIterative filter={filter} />}
        <ButtonDelete filter={filter} isFilterSerial={isFilterSerial} isFilterIterative={isFilterIterative}/>
      </ButtonGroup>
    )
  }

  toggleCreateFilter() {
    this.setState({
      editing: true,
      filter: {
        name: 'Filtr',
        blocks: [
          {
            children: [],
            condsQuestion: []
          }
        ],
        actions: []
      }
    })
  }

  saveFilter() {
    const {survey} = this.props;
    const filter = this.filter.current.getFilter();

    this.setState({saving: true});

    let data = {
      filter: {
        id: filter.id,
        name: filter.name,
        survey: survey.id,
        blocks: filter.blocks,
        actionsVisibility: filter.actions.filter(action => action.actionType === 'actionVisibility'),
        actionsJump: filter.actions.filter(action => action.actionType === 'actionJump')
      }
    };

    const action = filter.hasOwnProperty('id') ? this.props.updateFilter : this.props.addFilter;

    action(data)
      .then(() => {
        this.setState({
          filter: null,
          editing: false,
        })
      })
      .catch(error => {
        const errors = error.response.data.form.errors;
        forEach(errors, error => {
          if(isArray(error)){
            forEach(error, e => toast.error(e));
          }else{
            toast.error(error);
          }
        });

      })
      .finally(() => {
        this.setState({
          saving: false
        })
      })
  }

  filterConditions(filter) {
    if (Object.values(this.props.filters).includes(filter)) {
      return <ol className="m-0 p-0 text-left">{_.map(filter.blocks, (block, key)=> !block.parent && <li key={key} style={{listStyleType: 'decimal'}}><Block block={block} changeable={false}/></li>)}</ol>
    } else if (Object.values(this.props.serialFilters).includes(filter)) {
      return <FilterSerialCond filter={filter} />
    } else {
      return <FilterIterationCond filter={filter} />
    }
  }

  filterActions(filter) {
    if (Object.values(this.props.filters).includes(filter)) {
      return <ol className="m-0 p-0 text-center">{_.map(filter.actions, (action, key)=> <li key={key} style={{listStyleType: 'decimal'}}><Action action={action} changeable={false}/></li>)}</ol>
    } else if (Object.values(this.props.serialFilters).includes(filter)) {
      return <FilterSerialAction filter={filter} />
    } else {
      return <FilterIterationActions filter={filter} />
    }
  }

  getCurrentDataTable(){
    const data = this.getAllFilters();

    const {page,pageSize} = this.tableRef.current.state;
    const from = page * pageSize;
    const to = from + pageSize;

    return data.slice(from, to);
  }

  toggleSelected(id) {
    const selected = xor(this.state.selected, [id]);

    this.setState({
      selected,
    })
  }

  toggleSelectAll(){
    const {selected} = this.state;

    const data = this.getCurrentDataTable();

    if(selected.length === data.length){
      this.setState({
        selected: [],
      });
    }else{
      this.setState({
        selected: data.map(d => d.id),
      });
    }
  }

  render() {
    const data = this.getAllFilters();

    const columns = [{
      id: 'id',
      Header: <FormattedMessage id="filters.listItems.tableHeader.id"/>,
      accessor: filter => filter.id,
      width: 65
    }, {
      id: 'name',
      Header: <FormattedMessage id="filters.listItems.tableHeader.name"/>,
      accessor: filter => <FilterNameColumn filter={filter} />
    }, {
      id: 'conditions',
      Header: <FormattedMessage id="filters.listItems.tableHeader.conditions"/>,
      accessor: filter => this.filterConditions(filter)
    }, {
      id: 'filterActions',
      Header: () => <div style={{ textAlign: "center" }}><FormattedMessage id="filters.listItems.tableHeader.filterActions"/></div>,
      accessor: filter => this.filterActions(filter)
    }, {
      id: 'actions',
      show: !this.props.hiddenColumns.includes('actions'),
      Header: <FormattedMessage id="filters.listItems.tableHeader.actions"/>,
      accessor: filter => this.actions(filter),
    }];

    if (this.props.selectable) {
      columns.unshift({
        id: "checkbox",
        accessor: "",
        Cell: ({original}) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected.includes(original.id)}
              onChange={() => this.toggleSelected(original.id)}
            />
          );
        },
        Header: x => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected.length > 0 && this.state.selected.length === this.getCurrentDataTable().length}
              onChange={() => this.toggleSelectAll()}
            />
          );
        },
        sortable: false,
        width: 45
      })
    }

    return (
      <React.Fragment>
        <Row className="d-print-none">
          <Col>
            <Button color="primary" onClick={this.toggleCreateFilter}><i className="fas fa-plus" /> <span>Utwórz filtr</span></Button>
            <FiltersGroup />
            <FiltersSerialGroup />
            <SurveyPauseFilters />
            {this.props.surveyPlan.filtersFromTextarea && <SurveyFilterFromTextarea/>}
          </Col>
        </Row>
        <Row className="d-none d-print-block">
          <h1 className="text-center">Lista filtrów</h1>
        </Row>
        <Row>
          <Col>
            <ReactTable
              ref={this.tableRef}
              minRows={0}
              className={'table-wrap'}
              data={data}
              getTrGroupProps={(state, rowInfo) => {
                return {
                  className: (
                    rowInfo.row._original.type === 'conditional' && (
                      (!rowInfo.row._original.actions || !rowInfo.row._original.actions.length)
                      || (!rowInfo.row._original.blocks)
                      || (rowInfo.row._original.blocks.length === 0 && !rowInfo.row._original.blocks[0].condsQuestion.length)
                    )
                    || rowInfo.row._original.type === 'serial' && (
                      (_.isEmpty(rowInfo.row._original.questionFrom) || _.isEmpty(rowInfo.row._original.questionTo))
                    )
                    || rowInfo.row._original.type === 'iterative' && (
                      (_.isEmpty(rowInfo.row._original.questionFrom) || _.isEmpty(rowInfo.row._original.questionsTo))
                    )
                  )
                    ? 'bg-warning'
                    : ''
                };
              }}
              columns={columns}
              showPagination={!_.isEmpty(this.props.filters)}
              PaginationComponent={Pagination}
              noDataText={<FormattedMessage id="filters.listItems.table.noFilters"/>}
              defaultPageSize={this.props.defaultPageSize}
              onSortedChange={() => this.setState({selected: []})}
              onPageChange={() => this.setState({selected: []})}
              onPageSizeChange={() => this.setState({selected: []})}
            />
            <Button className="mr-2" onClick={() => this.toggleSelectAll()}>Zaznacz/Odznacz wszystko</Button>
            {this.state.selected.length > 0 && <DeleteSelectedModal
              selected={this.state.selected.map(fId => data.find(f => f.id === fId))}
              onDeleted={() => this.setState({selected: [],})}
            />}
            {(this.props.surveyPlan.filtersFromTextarea && this.state.selected.length > 0) && <FiltersEditTextarea
              filters={this.state.selected.map(fId => data.find(f => f.id === fId))}
            />}
            {(this.state.editing && !!this.state.filter) && <FilterEditModal
              isOpen
              saving={this.state.saving}
              filter={this.state.filter}
              toggleModal={() => this.setState({editing: false, filter: null})}
              onSave={this.saveFilter}
              ref={this.filter}
            />
            }            {this.state.filterSerial && <FilterSerialEditModal
              isOpen={this.state.editing}
              filter={this.state.filterSerial}
              toggleModal={() => this.setState({editing: false, filterSerial: null})}
            />}
            {this.state.filterIterative && <FilterIterativeEditModal
              isOpen={this.state.editing}
              filter={this.state.filterIterative}
              toggleModal={() => this.setState({editing: false, filterIterative: null})}
            />}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Filters.defaultProps = {
  defaultPageSize: 10,
  hiddenColumns: [],
  selectable: true,
};

Filters.propTypes = {
  selectable: PropTypes.bool,
  hiddenColumns: PropTypes.array,
  defaultPageSize: PropTypes.number,
  filters: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    surveyPlan: state.user.userPlan.plan.survey,
    survey: state.survey.structure.data.survey,
    filters: state.survey.structure.data.filters,
    serialFilters: state.survey.structure.data.filterSerial,
    iterativeFilters: state.survey.structure.data.filterIterative,
    questions: questions,
  }
}

export default connect(mapStateToProps, {addFilter, updateFilter})(Filters);
