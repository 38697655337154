import {
  SET,
  UPDATED
} from './actions';

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.questionSummaries;


    case UPDATED:
      return state.map(questionSummary => {
        if(questionSummary.id === action.questionSummary.id){
          questionSummary = action.questionSummary
        }

        return questionSummary;
      });

    default:
      return state;
  }
};