import React, {Component} from 'react';
import {Card, CardHeader, CardBody} from "reactstrap";
import SurveySwitcherChecksTable from "../componentsNew/SurveySwitcherChecks/SurveySwitcherChecksTable";

class SurveySwitcherCheckView extends Component {

  componentDidMount() {
    document.title = 'Ping | ANKIETEO';
  }

  render() {
    return (
      <Card>
        <CardHeader className="p-0">
          <h2><strong>Ping</strong></h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <SurveySwitcherChecksTable />
        </CardBody>
      </Card>
    )
  }
}

export default SurveySwitcherCheckView