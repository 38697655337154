import React, {Component} from 'react';
import {Alert, Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import {connect} from "react-redux";
import FormSmsApiToken from "./components/FormSmsApiToken";
import SmtpAccountsTable from "./components/SmtpAccountsTable";
import {Link} from "react-router-dom";
import appRoutes from "../../../appRoutes";

class SettingsIntegrationSmtp extends Component {
  componentDidMount() {
    document.title = 'Ankieteo - Integracje SMTP';
  }

  render() {
    const {user} = this.props;

    return <Card className="h-100 mb-0">
      <CardHeader><h2><i className="fas fa-puzzle-piece mr-2" /> Integracje SMTP</h2></CardHeader>
      <hr className="my-0" />
      <CardBody>
        <SmtpAccountsTable />
      </CardBody>
      <CardFooter>
        <Link to={appRoutes.account.integration} className="btn btn-link"><i className="fas fa-arrow-left" /> Wróć</Link>
      </CardFooter>
    </Card>;
  }
}

function mapStateToProps(state) {
  return {
    collectorPanelAccess: state.user.userPlan.plan.survey.collectorPanelAccess,
    collectorSmsAccess: state.user.userPlan.plan.survey.collectorSmsAccess,
    user: state.user,
  }
}

export default connect(mapStateToProps)(SettingsIntegrationSmtp);