import * as React from 'react';
import {Badge, Card, CardBody, Col, Row, Tooltip} from "reactstrap";
import {connect} from "react-redux";
import api from "../../../api";
import {isWebview} from "../../../services/isWebview";

function AboutAnkieteo(props) {
  const [youtubeVideos, setYoutubeVideos] = React.useState([]);
  const darkMode = props.appDarkMode

  React.useEffect(() => {
    api.youtube.getGallery().then((data) => {
      setYoutubeVideos(data);
    })
  }, [])

  const aStyle = darkMode ? {fontWeight: 700, fontFamily: "Roboto-Medium"} : {}
  
  if (isWebview()) {
    return <div/>
  }

  return (
    <Col sm={12} lg={12} className="d-flex align-items-md-stretch mt-5 mt-lg-0 pt-3" id={"meet-ankieteo"}>
      <Card className="border-l red w-100">
        <CardBody>
          <Row>
            <Col sm={12} lg={4}>
              <h2 className={"mb-4"} style={{fontSize: '22px'}}>Poznaj Ankieteo i inne nasze produkty</h2>
              <p>Przeczytaj <a style={aStyle} href={"https://ankieteo.pl/instrukcja"} target={"_blank"}>Instrukcję Ankieteo</a></p>
              <p>Poznaj podstawowe <a style={aStyle} href={"https://ankieteo.pl/program-do-ankiet/funkcje"} target={"_blank"}>funkcje Ankieteo</a></p>
              <p>Prześledź sposoby <a style={aStyle} href={"https://ankieteo.pl/program-do-ankiet/respondenci"} target={"_blank"}>gromadzenia danych</a> w Ankieteo</p>
              <p>Sprawdź jak za darmo <a style={aStyle} href={"https://swpanel.pl/files/swpanel/zasady_publikacji_ankiet_w_bazach_ankiet.pdf"} target={"_blank"}>opublikować ankietę w panelu online </a>i zebrać kilkaset wywiadów (tylko PL)</p>
              <p>Poznaj sposoby <a style={aStyle} href={"https://ankieteo.pl/baza-wiedzy/analiza-wynikow"} target={"_blank"}>eksportu i analizy danych</a></p>
              <p>Zapoznaj się z <a style={aStyle} href={"https://swresearch.pl/raporty"} target={"_blank"}>Bazą raportów.</a> Raporty powstały w większości z pomocą Ankieteo</p>
              <p>Zajrzyj i sprawdź ceny szybkich i tanich badań reprezentatywnych w <a style={aStyle} href={"https://omniwatch48.pl/"} target={"_blank"}>Omniwatch48</a></p>
              <p>Kto stoi za Ankieteo? Poznaj<a style={aStyle} href={"https://swresearch.pl/team"} target={"_blank"}> nasz zespół</a></p>
            </Col>
            <Col sm={12} lg={4} className={"pb-5"}>
              <h2 className={"mb-4"} style={{fontSize: '22px'}}>Ankieteo w 5 minut</h2>
              <iframe
                  style={{
                    "width": "100%",
                    height: "100%"
                  }}
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                  id="player" type="text/html"
                  src="https://www.youtube.com/embed/lCWDOr4UK0Y?enablejsapi=1&origin=http://example.com"
                  frameBorder="0"
              />
            </Col>
            <Col sm={12} lg={4}>
              <h2 className={"mb-4 mt-sm-3 mt-lg-0"} style={{fontSize: '22px'}}>Następne</h2>
              {youtubeVideos.map((video) => {
                return <div className={"pb-3"}>
                  <Row>
                    <Col sm={3} className={"d-sm-none d-md-block"}>
                      <div className={"h-100"} onClick={() => {
                        window.open("https://www.youtube.com/watch?v=" + video.id, '_blank');
                      }} style={{
                        background: 'url(' + video.thumbnail +  ')',
                        backgroundRepeat: 'round',
                        backgroundSize: 'cover',
                        position: 'relative',
                        cursor: 'pointer'
                      }}>
                        <div style={{
                          position: 'absolute',
                          bottom: 0,
                          right: 0
                        }}>
                          <Badge color={'dark'} children={video.duration} />
                        </div>
                      </div>
                    </Col>
                    <Col sm={9}>
                      <h2 style={{cursor: "pointer"}} onClick={() => {
                        window.open("https://www.youtube.com/watch?v=" + video.id, '_blank');
                      }}>{video.title}</h2>
                      <p>{video.viewCount} Wyświetleń - {video.publishedAt}</p>
                    </Col>
                  </Row>
                </div>
              })}
              <a href={"https://www.youtube.com/@swresearchmedia4421"} target={"_blank"}>Więcej materialów video o Ankieteo</a>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}

AboutAnkieteo.propTypes = {

};

function mapStateToProps(state) {
  return {
    appDarkMode: state.user.appDarkMode
  }
}

export default connect(mapStateToProps, {})(AboutAnkieteo);