import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Card, CardHeader, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import ColumnEditButton from "./ColumnEditButton";
import ColumnDeleteButton from "./ColumnDeleteButton";
import { connect } from 'react-redux';
import ReactTable from "react-table";
import ColumnCreateModal from "./ColumnCreateModal";

class RespondentBasesSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      columnCreateModal: false
    }
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  static actions(column){
    return (
      <ul className="float-right mb-0 p-0">
        <li className="float-left mr-2">
          <ColumnEditButton column={column}/>
        </li>
        <li className="float-left mr-2">
          <ColumnDeleteButton column={column}/>
        </li>
      </ul>
    )
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="respondentBasesSettings.tableHeader.name" />,
      accessor: 'name'
    }, {
      id: 'actions',
      Header: <FormattedMessage id="respondentBasesSettings.tableHeader.actions" />,
      accessor: column => RespondentBasesSettings.actions(column)
    }];

    return (
      <Card>
        <CardHeader>
          <div className="direction-group">
            <div className="direction-left d-flex justify-content-start">
              <h2 className="float-left mr-4">Ustawienia kolumn</h2>
            </div>

            <div className="direction-right d-md-flex justify-content-md-end mt-1 mr-1">

              <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle('dropdownOpen')} className="d-md-none">
                <DropdownToggle className="m-0 p-0" color="">
                  <span className="fas fa-ellipsis-v hoverable" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" onClick={() => this.toggle('columnCreateModal')} >
                    <p><span className="fas fa-plus" /></p> <FormattedMessage tagName="h5" id="columnCreateButton.button"/>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <FormattedMessage id="columnCreateButton.button">{
                (message) =>
                  <Button color="primary" onClick={() => this.toggle('columnCreateModal')} className="ml-3 d-none d-md-block"><i className="fas fa-plus" /> {message}</Button>
              }</FormattedMessage>

              <ColumnCreateModal isOpen={this.state.columnCreateModal} toggle={() => this.toggle('columnCreateModal')}/>
            </div>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <ReactTable
            minRows={0}
            className={'table-wrap'}
            data={Object.values(this.props.columns)}
            columns={columns}
            showPagination={false}
          />
        </CardBody>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    columns: state.user.respondentBaseColumns
  };
}

export default connect(mapStateToProps)(RespondentBasesSettings);