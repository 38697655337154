import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, Input, InputGroup, InputGroupAddon, Tooltip} from "reactstrap";
import classnames from "classnames";


class SettingText extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(value){
    this.setState({
      value
    });
  }

  onBlur(){
    const {value} = this.state;

    this.props.handleUpdate({
      [this.props.name]: value
    })
  }

  render() {
    let { value, tooltip } = this.state;

    return (
      <InputGroup
        id={"value-" + this.props.name + '-' + this.props.question.id}
        className="min-w-0 float-left w-100 w-lg-auto mr-1 mb-1"
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": !_.isEmpty(value),
            })}
            color="secondary"
          >
            {this.props.icon}
          </Button>
        </InputGroupAddon>
        <Input value={value} onChange={e => this.onChange(e.target.value)} onBlur={this.onBlur}/>
        {/*{!_.isEmpty(value) && <Input value={value} onChange={this.onChange}/>}*/}
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={"value-" + this.props.name + '-' + this.props.question.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id={'_.question.' + this.props.question.type + '.' + this.props.name}/>
        </Tooltip>
      </InputGroup>
    )
  }
}

SettingText.defaultProps = {
  value: ''
};

SettingText.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  icon: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default SettingText;