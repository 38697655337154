import React, {Component} from 'react';
import PropTypes from "prop-types";
import { xor } from "lodash";
import Pagination from "../Pagination";
import ReactTable from "react-table";
import DeleteSelectedModal from "./ApplicationTable/DeleteSelectedModal";

class ApplicationTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      filters: props.filters,
      search: '',
      fetchDataDelay: null,
      searching: false,
      data: [],
      page: 1,
      pageSize: props.pageSize,
      totalSize: 0,
      sortField: 'id',
      sortOrder: 'DESC',
    }

    this.setSearch = this.setSearch.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.fetchDataDelay = this.fetchDataDelay.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  toggleSelected(id) {
    const selected = xor(this.state.selected, [id]);

    this.setState({
      selected,
    }, () => {
      if (this.props.onCheckedChange) {
        this.props.onCheckedChange(this.state.selected);
      }
    })
  }

  toggleSelectAll(){
    const {selected, data} = this.state;

    if(selected.length === data.length){
      this.setState({
        selected: [],
      }, () => {
        if (this.props.onCheckedChange) {
          this.props.onCheckedChange(this.state.selected);
        }
      });
    }else{
      this.setState({
        selected: data.map(d => d.id),
      }, () => {
        if (this.props.onCheckedChange) {
          this.props.onCheckedChange(this.state.selected);
        }
      });
    }
  }

  setSearch(search) {
    this.setState({
      page: 1,
      search,
    })
  }

  setFilters(filters) {
    this.setState({
      page: 1,
      filters,
    })
  }

  fetchDataDelay(delay = 1000){
    clearTimeout(this.state.fetchDataDelay);

    this.setState({
      fetchDataDelay: setTimeout(() => {
        this.fetchData({});
      }, delay)
    });
  }

  fetchData(options) {
    clearTimeout(this.state.fetchDataDelay);

    const sort = options.sorted;

    if (this.props.onCheckedChange) {
      this.props.onCheckedChange([]);
    }

    this.setState({
      selected: [],
      page: options.page ? options.page+1 : this.state.page,
      pageSize: options.pageSize ? options.pageSize : this.state.pageSize,
      sortField: (sort && sort[0]) ? sort[0]['id'] : this.state.sortField,
      sortOrder: (sort && sort[0]) ? (sort[0]['desc'] ? 'DESC' : 'ASC') : this.state.sortOrder,
    }, () => {
      const state = this.state;

      this.setState({
        searching: true,
        page: state.page,
        pageSize: state.pageSize
      });

      const data = {
        page: state.page,
        pageSize: state.pageSize,
        filters: state.filters,
        search: state.search,
      };

      const sort = {
        sortField: state.sortField,
        sortOrder: state.sortOrder,
      }

      this.props.fetchData(data, sort)
      .then(res => {
        this.setState({
          searching: false,
          data: res.data,
          total: res.total,
        });
      })
      .catch(e => this.setState({
        searching: false,
      }));
    });
  }

  render() {
    const {data} = this.state;

    let columns = [];

    if(this.props.isSelectable || this.props.deleteData){
      columns.push({
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected.includes(original.id)}
              onChange={() => this.toggleSelected(original.id)}
            />
          );
        },
        Header: x => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected.length > 0 && this.state.selected.length === data.length}
              onChange={() => this.toggleSelectAll()}
            />
          );
        },
        sortable: false,
        width: 45
      })
    }

    this.props.columns.map(c => columns.push(c));

    return <React.Fragment>
      {(this.props.deleteData && this.state.selected.length > 0) && <DeleteSelectedModal deleteFunc={this.props.deleteData} selected={this.state.selected} onDeleted={() => this.fetchData({})} />}

      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={this.state.pageSize}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={parseInt(this.state.total)}
        manual
        onSortedChange={sorted => this.setState({sorted})}
        getTrGroupProps={this.props.getTrGroupProps}
      />
    </React.Fragment>
  }
}

ApplicationTable.defaultProps = {
  filters: {},
  isSelectable: false,
  pageSize: 50,
};

ApplicationTable.propTypes = {
  isSelectable: PropTypes.bool,
  filters: PropTypes.object,
  columns: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired,
  onCheckedChange: PropTypes.func,
};

export default ApplicationTable;
