import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Table} from "reactstrap";
import AnswersSelect from './AnswersSelect';

class TableMatrixDropdown extends PureComponent {

  toggleAnswer(row, column, answers){
    let {details} = this.props;
    const detail = details.find(detail => detail.row === row.id && detail.column === column.id);

    if (detail) {
      details[details.indexOf(detail)].answers = answers;
    } else {
      details.push({row: row.id, column: column.id, answers});
    }

    this.props.handleDetailsChanged(details);
  }

  render() {
    const {rows, columns, details, answers, questionObject} = this.props;

    return (
      <Table className="border mt-3" style={{tableLayout: 'fixed'}}>
        <thead>
          <tr>
            <th />
            {columns.map((column, cKey) => <th key={cKey} className="text-center">
              {column.position+1}. {questionObject.columnsImage ? <img src={column.content} className="img-100"/> : <span className="question-badge img-30" dangerouslySetInnerHTML={{__html: column.stripedContent}}/>}
            </th>)}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rKey) => {
            return <tr key={rKey}>
              <td>
                {row.position+1}. {questionObject.rowsImage ? <img src={row.content} className="img-100"/> : <span className="question-badge img-30" dangerouslySetInnerHTML={{__html: row.stripedContent}}/>}
              </td>
              {columns.map((column, cKey) => {
                const detail = _.find(details, d => d.row === row.id && d.column === column.id);

                return (
                  <td className="text-center" key={cKey}>
                    <AnswersSelect question={questionObject} answers={answers} selectedAnswers={detail ? detail.answers : []} handleAnswersChanged={answers => this.toggleAnswer(row, column, answers)}/>
                  </td>
                )
              })}
            </tr>
          })}
        </tbody>
      </Table>
    );
  }
}

TableMatrixDropdown.propTypes = {
  question: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    stripedContent: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    stripedContent: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    stripedContent: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  details: PropTypes.arrayOf(PropTypes.shape({
    row: PropTypes.number.isRequired,
    column: PropTypes.number.isRequired,
    answers: PropTypes.array.isRequired,
  })),
  handleDetailsChanged: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    rows: state.survey.structure.data.questions[props.question].rows.map(rowId => state.survey.structure.data.rows[rowId]),
    columns: state.survey.structure.data.questions[props.question].columns.map(colId => state.survey.structure.data.columns[colId]),
    answers: state.survey.structure.data.questions[props.question].answers.map(ansId => state.survey.structure.data.answers[ansId]),
    questionObject: state.survey.structure.data.questions[props.question]
  }
}

export default connect(mapStateToProps)(TableMatrixDropdown);