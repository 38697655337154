import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {addTemplate} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import SurveyTemplatesForm from './SurveyTemplatesForm';
import PropTypes from "prop-types";

class SurveyTemplatesCreateButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      creating: false,
      errors: {}
    }

    this.templateForm = React.createRef();
  }

  templateCreate(e, data) {
    e.preventDefault();
    this.setState({creating: true, errors: {}});

    let form = new FormData();
    form.append('name', data.template.name);
    form.append('survey', data.template.survey);

    if (data.template.image) {
      form.append('image', data.template.image, data.template.image.name);
    }

    this.props.addTemplate(
      form
    ).then(() => {
      this.props.handleChange();
      this.setState({
        creating: false,
        modal: false
      });
    })
      .catch(res => {
        this.setState({
          creating: false,
          errors: res.response.data.form.errors
        });
      })
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { creating, errors } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} size="lg">
      <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id="admin.surveyTemplatesCreateButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <SurveyTemplatesForm errors={errors} onSubmit={this.templateCreate} ref={this.templateForm}/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={creating}
            data-style={ZOOM_OUT}
            onClick={e => this.templateCreate(e, this.templateForm.current.state)}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModal()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="primary" onClick={() => this.toggleModal()} className="mb-2"><i className="fas fa-plus" /> <FormattedMessage id="admin.surveyTemplatesCreateButton.button"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

SurveyTemplatesCreateButton.propTypes = {
  handleChange: PropTypes.func.isRequired
};

export default connect(null, { addTemplate }, null, {forwardRef: true})(SurveyTemplatesCreateButton);