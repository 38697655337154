import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Progress, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Button from "../../../../../../components/Button";
import {deleteFilter} from "../../../../Structure/data/filters/actions";
import {deleteFilterSerial} from "../../../../Structure/data/filterSerial/actions";
import {deleteFilterIterative} from "../../../../Structure/data/filterIterative/actions";

DeleteSelectedModal.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDeleted: PropTypes.func.isRequired,
};

function DeleteSelectedModal(props) {
  const [deleted, setDeleted] = React.useState(0);
  const [modalOpen, setModalOpen] = React.useState();
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [deleting, setDeleting] = React.useState(false);

  const deleteSelected = async () => {
    setDeleting(true);

    const del = key => {
      if(!props.selected[key]){
        setDeleted(0);
        setDeleting(false);
        setModalOpen(false);
        props.onDeleted();
        return;
      }

      let action = props.deleteFilter;
      if(props.selected[key].type === 'serial'){
        action = props.deleteFilterSerial;
      }
      if(props.selected[key].type === 'iterative'){
        action = props.deleteFilterIterative;
      }

      action({
        id: props.selected[key].id
      })
      .then(() => {
        setDeleted(key+1);
        del(key+1);
      })
      .catch(() => {
        setDeleted(key+1);
        del(key+1);
      });
    };

    del(0);
  }

  return <React.Fragment>
    <Button color="danger" onClick={toggleModalOpen}>Usuń zaznaczone</Button>

    <Modal isOpen={modalOpen} toggle={toggleModalOpen} size="lg">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Usuń zaznaczone filtry</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        {(!deleting && deleted === 0) && <span>Czy na pewno chcesz usunąć zaznaczone filtry?</span>}
        {(!deleting && deleted > 0) && <span>Liczba usuniętych filtrów: {deleted}</span>}
        {deleting && <div className="d-flex flex-column">
          <Progress
            color="danger"
            value={deleted+1}
            max={props.selected.length}
            children={<span>{deleted+1}/{props.selected.length}</span>}
          />
        </div>}

      </ModalBody>
      <ModalFooter>
        {(!deleting && deleted > 0)
          ? <FormattedMessage id="_.button.close">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
          }</FormattedMessage>
          : <React.Fragment>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
            }</FormattedMessage>
            <FormattedMessage id="_.button.delete">{
              (message) => <Button
                color="danger"
                loading={deleting}
                onClick={deleteSelected}
              >
                {message}
              </Button>
            }</FormattedMessage>
          </React.Fragment>
        }
      </ModalFooter>
    </Modal>
  </React.Fragment>;
}

export default connect(null, { deleteFilter, deleteFilterSerial, deleteFilterIterative })(DeleteSelectedModal);