import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import AddressesList from './AddressesList';
import AddressCreateModal from './AddressCreateModal';
import AddressesCreateModal from './AddressesCreateModal';
import AddressesImportCSV from './AddressesImportCSV'
import { connect } from 'react-redux';
import {fetchAddresses} from "../actions";
import {Button, Card, CardBody, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

class Addresses extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownSettingsOpen: false,
      createModal: false,
      createBulkModal: false,
      importModal: false
    }
  }

  componentDidMount() {
    this.props.fetchAddresses(this.props.match.params.respondentBase);
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <div className="direction-group">
            <div className="direction-left d-flex justify-content-start">
              <FormattedMessage tagName="h2" id="addresses.cardHeader" />
            </div>
            <div className="direction-group d-flex justify-content-end mt-1 mr-1">
              <div className="direction-right">

                <Dropdown isOpen={this.state.dropdownSettingsOpen} toggle={() => this.toggle('dropdownSettingsOpen')} className="d-md-none">
                  <DropdownToggle className="m-0 p-0" color="">
                    <span className="fas fa-ellipsis-v hoverable" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag="a" onClick={() => this.toggle('createModal')} >
                      <p><span className="fas fa-plus" /></p> <FormattedMessage tagName="h5" id="addressCreateModal.button"/>
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => this.toggle('createBulkModal')} >
                      <p><span className="fas fa-plus" /></p> <FormattedMessage tagName="h5" id="addressesCreateModal.button"/>
                    </DropdownItem>
                    <DropdownItem tag="a" onClick={() => this.toggle('importModal')} >
                      <p><span className="fas fa-plus" /></p> <FormattedMessage tagName="h5" id="addressesImportCSV.button"/>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>

                <Button color="primary" onClick={() => this.toggle('createModal')} className="ml-3 d-none d-md-block"><i className="fas fa-plus" /> <FormattedMessage id="addressCreateModal.button" /></Button>
                <Button color="primary" onClick={() => this.toggle('createBulkModal')} className="ml-3 d-none d-md-block"><i className="fas fa-plus" /> <FormattedMessage id="addressesCreateModal.button" /></Button>
                <Button color="primary" onClick={() => this.toggle('importModal')} className="ml-3 d-none d-md-block"><i className="fas fa-plus"/> <FormattedMessage id="addressesImportCSV.button" /></Button>

                <AddressCreateModal respondentBaseId={this.props.match.params.respondentBase} isOpen={this.state.createModal} toggle={() => this.toggle('createModal')}/>
                <AddressesCreateModal respondentBaseId={this.props.match.params.respondentBase} isOpen={this.state.createBulkModal} toggle={() => this.toggle('createBulkModal')}/>
                <AddressesImportCSV respondentBaseId={this.props.match.params.respondentBase} isOpen={this.state.importModal} toggle={() => this.toggle('importModal')}/>
              </div>
            </div>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <AddressesList onDeleted={() => this.props.fetchAddresses(this.props.match.params.respondentBase)} baseId={this.props.match.params.respondentBase} addresses={this.props.addresses} loading={this.props.loading}/>
        </CardBody>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    addresses: state.addresses
  }
}

export default connect(mapStateToProps, {fetchAddresses})(Addresses);