import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {updateReport} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import ReportForm from './ReportForm';
import PropTypes from 'prop-types';
import {toast} from "react-toastify";

class ReportEditButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      updating: false,
      errors: {}
    }

    this.form = React.createRef();
  }

  reportUpdate(e, data) {
    e.preventDefault();
    this.setState({updating: true});

    this.props.updateReport({
      data: data.report,
      id: this.props.report.id
    }).then(() => {
      this.setState({
        updating: false,
        modal: false
      })
      this.props.handleChange();
      toast.success(<FormattedMessage id="admin.reportEditButton.toast.success" />);
    }).catch(e => {
      this.setState({
        updating: false,
        errors: e.response.data.form.errors
      });
    });
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { updating, errors } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} size="xl">
      <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id="admin.reportEditButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <ReportForm errors={errors} onSubmit={this.reportUpdate} ref={this.form} report={this.props.report}/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={updating}
            data-style={ZOOM_OUT}
            onClick={e => this.reportUpdate(e, this.form.current.state)}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModal()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="primary" size="sm" onClick={() => this.toggleModal()}><i className="fas fa-edit" /> <FormattedMessage id="_.button.edit"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

ReportEditButton.propTypes = {
  report: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default connect(null, { updateReport }, null, {forwardRef: true})(ReportEditButton);