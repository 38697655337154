import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import CheckedDelete from "./components/CheckedDelete";
import {Col, Row} from "reactstrap";

class ScalePointsActions extends Component {

  render() {
    const {question, checkedScalePoints, scalePoints} = this.props;

    let scalePointsChecked =  _.map(checkedScalePoints, id => scalePoints.find(scalePoint => scalePoint.id === id) || false);
    scalePointsChecked = scalePointsChecked.filter(excluding => excluding !== false);

    return (
      <Row className="mt-2">
        <Col xs={12}>
          {scalePointsChecked.length > 0 &&
          <div className="direction-group w-100 label-filtr-wrap mb-0">
            <ul className="direction-left mb-0 p-0">
              <li className="float-left">
                <CheckedDelete question={question} scalePointsChecked={scalePointsChecked}/>
              </li>
            </ul>
          </div>}
        </Col>
      </Row>
    )
  }
}

ScalePointsActions.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  checkedScalePoints: PropTypes.array.isRequired,
  scalePoints: PropTypes.array.isRequired,
};

export default ScalePointsActions;