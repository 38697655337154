import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from "lodash";
import {Col, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import Switch from "../../../../../../components/Switch";

class SmtpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      data: props.data
    };
  }

  render() {
    const {data} = this.state;
    const {errors} = this.props;

    return (
      <div>
        <FormGroup className="mb-2" row>
          <Label md={4}>Nazwa</Label>
          <Col md={8}>
            <Input type="text" className="mb-0" invalid={!isEmpty(errors.name)} value={data.name}
                   onChange={e => this.setState({data: {...data, name: e.target.value}})}/>
            {!isEmpty(errors.name) && <FormFeedback>{errors.name.errors.join(' ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup className="mb-2" row>
          <Label md={4}>Host</Label>
          <Col md={8}>
            <Input type="text" className="mb-0" invalid={!isEmpty(errors.host)} value={data.host}
                   onChange={e => this.setState({data: {...data, host: e.target.value}})}/>
            {!isEmpty(errors.host) && <FormFeedback>{errors.host.errors.join(' ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup className="mb-2" row>
          <Label md={4}>Port</Label>
          <Col md={8}>
            <Input type="text" className="mb-0" invalid={!isEmpty(errors.port)} value={data.port}
                   onChange={e => this.setState({data: {...data, port: e.target.value}})}/>
            {!isEmpty(errors.port) && <FormFeedback>{errors.port.errors.join(' ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup className="mb-2" row>
          <Label md={4}>E-mail</Label>
          <Col md={8}>
            <Input type="text" className="mb-0" invalid={!isEmpty(errors.email)} value={data.email}
                   onChange={e => this.setState({data: {...data, email: e.target.value}})}/>
            {!isEmpty(errors.email) && <FormFeedback>{errors.email.errors.join(' ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup className="mb-2" row>
          <Label md={4}>Hasło</Label>
          <Col md={8}>
            <Input type="password" placeholder={!!data.id ? "Zostaw puste jeśli bez zmian" : ''} className="mb-0" invalid={!isEmpty(errors.password)} value={data.password} onChange={e => this.setState({data: {...data, password: e.target.value}})}/>
            {!isEmpty(errors.password) && <FormFeedback>{errors.password.errors.join(' ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup className="mb-2" row>
          <Label md={4}>SSL</Label>
          <Col md={8}>
            <Switch onChange={(value) => this.setState({data: {...data, ssl: value}})} checked={data.ssl} />
              {!isEmpty(errors.password) && <FormFeedback>{errors.password.errors.join(' ')}</FormFeedback>}
          </Col>
        </FormGroup>
      </div>
    );
  }
}

SmtpForm.defaultProps = {
  data: {
    name: '',
    host: '',
    port: '',
    email: '',
    password: '',
    ssl: false,
  }
};

SmtpForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    port: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    ssl: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SmtpForm;