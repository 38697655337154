import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import classnames from "classnames";

class Switch extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.ownStyles[this.props.keyName] ? props.ownStyles[this.props.keyName] : this.props.ownStylesDefault[this.props.keyName],
    };
  }

  updateValue(value){
    value = value ? this.props.checkedValue : this.props.uncheckedValue;

    this.setState({
      value
    });

    this.props.handlerUpdate({[this.props.keyName]: value});
  }

  render() {
    const {value} = this.state;
    const inactive = value === this.props.ownStylesDefault[this.props.keyName];

    return (
      <Row className={classnames({
        'pt-2 px-3 mb-2 align-items-center': true,
        'inactive': inactive,
      })}>
        <label className="col col-4 d-flex align-items-center"><span>{this.props.label}</span></label>
        <Col className="col-8">
          <label className={"switch"}>
            <input type="checkbox" checked={value === this.props.checkedValue} onChange={e => this.updateValue(e.target.checked)} />
            <span className="slider round" />
          </label>
        </Col>
      </Row>
    );
  }
}

Switch.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  uncheckedValue: PropTypes.any.isRequired,
  checkedValue: PropTypes.any.isRequired
};

export default Switch;