import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import classnames from "classnames";
import Select from "react-select";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";


class ColumnThead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      filterExcluding: false,
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterExcludingChange = this.onFilterExcludingChange.bind(this);
  }

  componentWillUnmount() {
    const {question} = this.props;

    this.props.onFilterChange('q' + question.id, false);
    this.props.onFilterChange('q' + question.id + 'excl', false);
  }

  static getColSpan(question){
    return (question.answersCount * 3) + question.excludings.length;
  }

  onFilterChange(position, option){
    let {filter} = this.state;
    const {question} = this.props;

    if(option === null){
      delete filter[position];
    }else{
      filter[position] = option.value;
    }

    this.setState({
      filter
    }, () => {
      const key = 'q' + question.id;

      this.props.onFilterChange(key, (this.state.filter === false || _.isEmpty(this.state.filter)) ? false : {
          question: question.id,
          answers: this.state.filter,
        }
      );
    });
  }

  onFilterExcludingChange(value){
    const {question} = this.props;

    if(value.length === 0){
      value = false;
    }

    this.setState({
      filterExcluding: value
    }, () => {
      const key = 'q' + question.id + 'excl';

      this.props.onFilterChange(key, this.state.filterExcluding === false ? false : {
          question: question.id,
          excludings: this.state.filterExcluding,
        }
      );
    });
  }

  render() {
    const {filter, filterExcluding} = this.state;
    const {question} = this.props;

    const options = [
      {
        label: 'Uzupełnił',
        value: 1,
      },
      {
        label: 'Nie uzupełnił',
        value: 0,
      },
    ];

    let th = [];
    for(let position = 0; position < question.answersCount; position++){
      th.push(<th
        className={classnames({
          'text-center pointer': true,
          'border-left': position === 0,
          'border-right': position === question.answersCount-1,
        })}
        onClick={() => filter[position] === undefined && this.setState({filter: {...filter, [position]: 1}}) }
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filter[position] !== undefined,
          'align-items-center': filter[position] === undefined,
        })}>
          <div className="w-100">Zaznaczenie {position+1} - Lat</div>
          {filter[position] !== undefined && <Select
            isClearable={true}
            options={options}
            onChange={option => this.onFilterChange(position, option)}
          />}
        </div>
      </th>);
      th.push(<th
        className={classnames({
          'text-center pointer': true,
        })}
        onClick={() => filter[position] === undefined && this.setState({filter: {...filter, [position]: 1}}) }
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filter[position] !== undefined,
          'align-items-center': filter[position] === undefined,
        })}>
          <div className="w-100">Zaznaczenie {position+1} - Lng</div>
          {filter[position] !== undefined && <Select
            isClearable={true}
            options={options}
            onChange={option => this.onFilterChange(position, option)}
          />}
        </div>
      </th>);
      th.push(<th
        className={classnames({
          'text-center pointer': true,
          'border-right': position === question.answersCount-1,
        })}
        onClick={() => filter[position] === undefined && this.setState({filter: {...filter, [position]: 1}}) }
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filter[position] !== undefined,
          'align-items-center': filter[position] === undefined,
        })}>
          <div className="w-100">Zaznaczenie {position+1} - Adres</div>
          {filter[position] !== undefined && <Select
            isClearable={true}
            options={options}
            onChange={option => this.onFilterChange(position, option)}
          />}
        </div>
      </th>);
    }

    return <React.Fragment>
      {th}

      {question.excludings.length > 0 && <th
        className="text-center border-left border-right pointer"
        onClick={() => filterExcluding === false && this.setState({filterExcluding: null})}
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filter !== false,
          'align-items-center': filter === false,
        })}>
          <div className="w-100">Wykluczająca</div>
          {filterExcluding !== false && <SelectExcludings
            isClearable={true}
            isMulti={true}
            value={filterExcluding}
            questionId={question.id}
            onChange={this.onFilterExcludingChange}
          />}
        </div>
      </th>}
    </React.Fragment>;
  }
}

ColumnThead.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['gMap']),
    answersCount: PropTypes.number.isRequired,
    excludings: PropTypes.array,
  }).isRequired,
};

export default ColumnThead;