import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import {Button, Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import HideSurveyNavigationButton
  from "../../../../../../Settings/components/NavigationButtons/components/HideSurveyNavigationButton";
import useAfterUpdateEffect from "../../../../../../../../../../../../../../../../../../utils/useAfterUpdateEffect";

const BooleanButton = (props) => {
  const [enabled, setEnabled] = React.useState(props.enabled);
  const [tooltip, setTooltip] = React.useState(false);

  useAfterUpdateEffect(() => {
    setEnabled(props.enabled)
  }, [props.enabled])

  return (
    <Button
      className={classnames({
        "float-left mr-1 btn-icon mb-1": true,
        "active": enabled,
      })}
      color={'secondary'}
      id={`hide-${props.id}-btn`}
      onClick={() => {
        setTooltip(!tooltip)
        setEnabled(!enabled)
        props.handleChange()
      }}
    >
      <i className={HideSurveyNavigationButton.handleIconType(props.id)} />

      <Tooltip
        delay={0}
        placement="top"
        isOpen={tooltip}
        target={`hide-${props.id}-btn`}
        toggle={() => setTooltip(!tooltip)}
      >
        <FormattedMessage id={`page.settings.navigation.button.${props.id}.tooltip`}/>
      </Tooltip>
    </Button>
  );
};

BooleanButton.propTypes = {

};

export default BooleanButton;
