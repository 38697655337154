import api from "api";
import * as types from './actionTypes';

export function setTemplates(templates) {
  return {
    type: types.SET,
    templates
  }
}

export function templateDeleted(template) {
  return {
    type: types.DELETED,
    template
  }
}

export function templateAdded(template) {
  return {
    type: types.ADDED,
    template
  }
}

export function deleteTemplate(template) {
  return dispatch => {
    return api.survey.delete.template(template.id).then(() => {
      dispatch(templateDeleted(template));
    })
  }
}

export function fetchTemplates() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.survey.get.globalTemplates().then(templates => {
      let orderedTemplates = {};
      _.map(templates, template => orderedTemplates[template.id] = template);

      dispatch(setTemplates(orderedTemplates));
      dispatch({type: types.SUCCESS});
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}

export function addTemplate(data) {
  return dispatch => {
    return api.survey.post.globalTemplate(data).then((template) => {
      dispatch(templateAdded(template));
    })
  }
}