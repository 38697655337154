import React, {Component} from 'react';
import { connect } from 'react-redux';
import {FormattedMessage} from "react-intl";
import {setDisabledQuestions} from 'scenes/Survey/Structure/actions';
import {Button, Tooltip} from "reactstrap";

class ButtonDisabledQuestions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      disabledQuestions: props.disabledQuestions,
    };

    this.toggleDisabledQuestions = this.toggleDisabledQuestions.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleDisabledQuestions() {
    this.setState({disabledQuestions: !this.state.disabledQuestions}, () => {
      this.props.setDisabledQuestions(this.state.disabledQuestions);
    })
  }

  toggleTooltip() {
    this.setState({tooltip: !this.state.tooltip})
  }

  render() {
    return (
      <Button className="mb-0" id="buttonDisabledQuestions" color={this.state.disabledQuestions ? 'primary' : 'secondary'} onClick={this.toggleDisabledQuestions}>
        <i className="fas fa-times"/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip}
          target="buttonDisabledQuestions"
          toggle={this.toggleTooltip}
        >
          <FormattedMessage id="topbar.advancedOptions.disabledQuestions.label"/>
        </Tooltip>
      </Button>
    )
  }
}

function mapStateToProps(state) {
  return {
    disabledQuestions: state.survey.structure.disabledQuestions,
  }
}

export default connect(mapStateToProps, {setDisabledQuestions})(ButtonDisabledQuestions);
