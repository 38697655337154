import React from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import useAfterUpdateEffect from "../../../../utils/useAfterUpdateEffect";
import api from "../../../../api";
import Link from "../../../../componentsReusable/Link";
import UserAvatar from "../../../UserAvatar";
import parseNotification, {
  TYPE_SURVEY_BREAKS_WARNING,
  TYPE_SURVEY_COLLECTOR_END_AT_REACHED, TYPE_SURVEY_COLLECTOR_REALIZATION_AUTOMATE_ENABLE,
  TYPE_SURVEY_COLLECTOR_RESPONSES_REACHED, TYPE_SURVEY_PAUSED_END_AT,
  TYPE_SURVEY_PAUSED_RESPONSES_MAX,
  TYPE_SURVEY_REPORT_CREATED
} from "../../../../utils/parseNotification";
import moment from "moment/moment";
import Tooltip from "../../../../componentsReusable/Tooltip";

import './style.scss'
import appRoutes from "../../../../appRoutes";
import classnames from "classnames";
import useDidMountEffect from "../../../../utils/useDidMountEffect";
import {connect} from "react-redux";

export const NotificationAvatar = ({notification}) => {
  if(
    notification.created_by_system == '1'
    || [TYPE_SURVEY_COLLECTOR_REALIZATION_AUTOMATE_ENABLE].includes(notification.type)
  ){
    switch(notification.type) {
      case TYPE_SURVEY_BREAKS_WARNING: {
        return <i className="fas fa-triangle-exclamation fa-3x text-danger" />
      }
      case TYPE_SURVEY_REPORT_CREATED: {
        return <i className="fas fa-triangle-exclamation fa-3x text-warning" />
      }
      case TYPE_SURVEY_PAUSED_RESPONSES_MAX:
      case TYPE_SURVEY_COLLECTOR_RESPONSES_REACHED: {
        return <i className="far fa-battery-full fa-3x text-success" />
      }
      case TYPE_SURVEY_PAUSED_END_AT:
      case TYPE_SURVEY_COLLECTOR_END_AT_REACHED: {
        return <i className="fas fa-hourglass-end fa-3x text-warning" />
      }
      case TYPE_SURVEY_COLLECTOR_REALIZATION_AUTOMATE_ENABLE: {
        return <i className="fa-solid fa-paw fa-2x text-secondary" />
      }
    }

    return <img src="/dashboard/img/ankieteo-small.png" style={{ maxHeight: 30, maxWidth: 30, }} />;
  }

  if(!notification.createdById){
    return ''
  }

  return <UserAvatar user={{
    firstname: notification.createdByFirstname,
    lastname: notification.createdByLastname,
    email: notification.createdByEmail,
    avatar: notification.createdByAvatar,
  }} />
}

const NotificationBox = ({notification}) => {

  return <div className="notification-box d-flex flex-row flex-nowrap w-100">
    <div className="notification-avatar d-flex align-items-center" style={{width: 50}}>
      <NotificationAvatar notification={notification} />
    </div>
    <div className="d-flex flex-column">
      <div className="notification-content">{parseNotification(notification)}</div>
      <React.Fragment>
        <span className="text-secondary mt-2 mr-auto" id={'id-' + notification.id}>{moment(notification.updated_at).fromNow()}</span>
        <Tooltip msg={notification.updated_at} id={'id-' + notification.id} />
      </React.Fragment>
    </div>
  </div>
}

const Notifications = props => {
  let intervalCountId = null;

  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [scrolled, setScrolled] = React.useState(false);

  useDidMountEffect(() => {
    getUnreadCount()
  })

  React.useEffect(() => {
    intervalCountId = setInterval(() => {
      getUnreadCount()
    }, 1000 * 30)

    return () => {
      clearInterval(intervalCountId)
    }
  }, []);

  useAfterUpdateEffect(() => {
    if(dropdownOpen){
      fetchData()
    }else{
      setNotifications([])
      setPage(1)
    }
  }, [dropdownOpen])

  const getUnreadCount = () => {
    api.notifications.unreadCount()
      .then(res => setCount(res.count))
  }

  const fetchData = () => {
    setLoading(true)

    api.notifications.search({page, pageSize: 50})
      .then(res => {
        setScrolled(false)
        setNotifications([
          ...notifications,
          ...res.data,
        ])
        setCount(0)
        setPage(page + 1)
      })
      .finally(() => setLoading(false))
  }

  const handlerOnScroll = (e) => {
    const el = document.getElementById('notifications-list');
    const percent = parseInt(((el.scrollTop + el.offsetHeight) / el.scrollHeight) * 100);

    if(!loading && percent >= 90){
      fetchData()
    }
  }

  if(!props.plan.notifications){
    return null;
  }

  return (
    <div id="notifications-dropdown" className="px-1">
      <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
        <DropdownToggle tag="a" className="p-0 position-relative">
          {count > 0 && <span className="notification-counter">{count > 99 ? '99+' : count}</span>}
          <i className={classnames({
            'fa-bell p-2 pointer': true,
            'far': count <= 0,
            'fas text-primary': count > 0,
          })} style={{ fontSize: 19 }} />
        </DropdownToggle>
        <DropdownMenu className="py-0" right>
          <DropdownItem className="bg-primary text-white py-2 rounded-top text-center d-flex justify-content-between align-items-center" header>
            <span />
            <span>Powiadomienia</span>
            <i className="fas fa-times p-1 pointer text-white" onClick={() => setDropdownOpen(false)} />
          </DropdownItem>
          <div id="notifications-list" onScroll={handlerOnScroll}>
            {notifications.map(notification => <Link className={classnames({
              'dropdown-item pr-0 pr-0 mr-0': true,
              'notification-selected': notification.read == '0',
            })} to={appRoutes.notifications + '?nid=' + notification.id} onClick={() => setDropdownOpen(false)}>
              <NotificationBox notification={notification} />
            </Link>)}
          </div>
          {
            loading
              ? <div className={classnames({
                'text-center': true,
                'py-2': notifications.length === 0
              })}><i className="fas fa-spin fa-spinner" /></div>
              : <Link to={appRoutes.notifications} className="dropdown-item bg-secondary text-white py-2 rounded-bottom text-center d-block w-100" header>Pokaż wszystkie</Link>
          }
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

Notifications.propTypes = {

};

function mapStateToProps(state) {
  return {
    plan: state.user.hasOwnProperty('id') && state.user.userPlan.plan ? state.user.userPlan.plan : {},
  }
}

export default connect(mapStateToProps)(Notifications);
