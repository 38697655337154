import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Table} from "reactstrap";
import { connect } from 'react-redux';
import {updateQuestion} from 'scenes/Survey/Structure/data/questions/actions';
import RandAndRotateQuestionOptions from "./components/RandAndRotateQuestionOptions";

class RandAndRotateQuestionsTable extends Component {

  render() {
    return (
      <Table>
        <thead>
          <tr>
            <th />
            {this.props.page.rotateQuestions && <th><small><FormattedMessage id="page.settings.table.header.rotate"/></small></th>}
            {this.props.page.randQuestions > 0 && <th><small><FormattedMessage id="page.settings.table.header.random"/></small></th>}
            {this.props.page.randQuestions > 0 && <th><small><FormattedMessage id="page.settings.table.header.randPriority"/></small></th>}
          </tr>
        </thead>
        <tbody>
        {this.props.page.questions.map(question => <RandAndRotateQuestionOptions page={this.props.page} question={this.props.questions[question]} key={question} />)}
        </tbody>
      </Table>
    )
  }
}

RandAndRotateQuestionsTable.propTypes = {
  page: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    questions: state.survey.structure.data.questions
  }
}

export default connect(mapStateToProps, {updateQuestion})(RandAndRotateQuestionsTable);