import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import Question from "../../Modals/Question";
import Page from "../../Modals/Page";
import Block from "../../Modals/Block";
import Set from "../../Modals/Set";
import {injectIntl} from "react-intl";
import SurveyEnd from "./SurveyEnd";
import Url from "./Url";

class ActionTypeJump extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: {
        visibility: false,
      }
    };
  }

  toggleIsOpen(name){
    this.setState({
      isOpen: {
        ...this.state.isOpen,
        [name]: !this.state.isOpen[name]
      }
    })
  }

  render() {
    const {action, intl, changeable} = this.props;
    const {isOpen} = this.state;

    return (
      <React.Fragment>
        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpen.type} toggle={() => this.toggleIsOpen('type')}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-' + action.type})}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              status: 4,
              page: null,
              block: null,
              set: null,
              type: 'surveyEnd',
            })}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-surveyEnd'})}</h5></DropdownItem>
            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              status: null,
              page: null,
              block: null,
              set: null,
              type: 'question',
            })}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-question'})}</h5></DropdownItem>
            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              status: null,
              question: null,
              block: null,
              set: null,
              type: 'page',
            })}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-page'})}</h5></DropdownItem>
            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              status: null,
              question: null,
              page: null,
              set: null,
              type: 'block',
            })}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-block'})}</h5></DropdownItem>
            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              status: null,
              question: null,
              page: null,
              block: null,
              type: 'set',
            })}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-set'})}</h5></DropdownItem>
            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              status: null,
              page: null,
              block: null,
              set: null,
              type: 'url',
              target: ''
            })}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-url'})}</h5></DropdownItem>
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-' + action.type})} </span>}

        {action.type === 'surveyEnd' && <SurveyEnd status={action.status} handleStatusChanged={status => this.props.handleActionChanged({...action, status})} changeable={changeable}/>}
        {action.type === 'question' && <Question question={action.question} handleQuestionChanged={question => this.props.handleActionChanged({...action, question})} changeable={changeable}/>}
        {action.type === 'page' && <Page page={action.page} handlePageChanged={page => this.props.handleActionChanged({...action, page})} changeable={changeable}/>}
        {action.type === 'block' && <Block block={action.block} handleBlockChanged={block => this.props.handleActionChanged({...action, block})} changeable={changeable}/>}
        {action.type === 'set' && <Set set={action.set} handleSetChanged={set => this.props.handleActionChanged({...action, set})} changeable={changeable}/>}
        {action.type === 'url' && <Url target={action.target} handleTargetChanged={target => this.props.handleActionChanged({...action, target})} changeable={changeable}/>}
      </React.Fragment>
    );
  }
}

ActionTypeJump.defaultProps = {
  changeable: true
};

ActionTypeJump.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.oneOf(['surveyEnd', 'question', 'page', 'block', 'set', 'url']).isRequired,
    visibility: PropTypes.bool,
    question: PropTypes.number,
    page: PropTypes.number,
    block: PropTypes.number,
    set: PropTypes.number,
  }).isRequired,
  handleActionChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired

};

export default injectIntl(ActionTypeJump);