import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import api from "../../api";

const Notify = props => {

  const disable = () => {
    props.onDisabled()
    api.dashboardNotifies.disableForUser(props.data.id)
  }

  return (
    <div className={classnames({
      [`mb-2 dashboard-notify notify-layout-${props.data.layout}`]: true,
    })}>
      <span className="notify-remove" onClick={disable}>
        <i className="fas fa-times" />
      </span>
      <div className="d-flex flex-column d-md-none">
        <div className="d-flex flex-row flex-nowrap align-items-center mb-3">
          <div className="notify-icon">
            <div className="icon-svg" />
          </div>
          <span className="notify-title">{props.data.title}</span>
        </div>

        <div className="notify-content">
          <div dangerouslySetInnerHTML={{__html: props.data.content}} />
        </div>
      </div>

      <div className="flex-row flex-nowrap d-none d-md-flex">
        <div className="notify-icon">
          <div className="icon-svg" />
        </div>
        <div className="d-flex flex-column flex-fill">
          <span className="notify-title mb-2">{props.data.title}</span>
          <div className="notify-content">
            <div dangerouslySetInnerHTML={{__html: props.data.content}} />
          </div>
        </div>
      </div>
    </div>
  );
};

Notify.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    layout: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export default Notify;
