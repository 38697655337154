import React, {useState} from "react";
import PropTypes from "prop-types";
import {Tooltip as TooltipReactstrap} from "reactstrap";

function Tooltip(props){
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return <TooltipReactstrap delay={props.delay} placement={props.placement} isOpen={tooltipOpen} target={props.id} toggle={toggle} style={props.style}>{props.msg}</TooltipReactstrap>;
}

Tooltip.defaultProps = {
  placement: 'bottom',
  delay: undefined,
  style: {},
};

Tooltip.propTypes = {
  delay: PropTypes.number,
  id: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
};

export default Tooltip;
