import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";
import {FormattedMessage} from "react-intl";
import {Tooltip} from "reactstrap";

const SurveyReportsWarning = ({surveyId, reportsCount}) => {
  const [tooltip, setTooltip] = React.useState(false);

  return (
    <React.Fragment>
      <i id={"tooltip-reportsCount-" + surveyId} className="fas fa-exclamation-triangle text-warning ml-2 pointer" />
      <Tooltip
        placement="bottom"
        autohide={false}
        isOpen={tooltip}
        target={"tooltip-reportsCount-" + surveyId}
        toggle={() => setTooltip(!tooltip)}
      >
        <Link to={appRoutes.survey.reports(surveyId)}><FormattedMessage id={"surveys.surveysReportsWarning.tooltip"}/>: {reportsCount}</Link>
      </Tooltip>
    </React.Fragment>
  );
};

SurveyReportsWarning.propTypes = {
  surveyId: PropTypes.number.isRequired,
  reportsCount: PropTypes.number.isRequired,
};

export default SurveyReportsWarning;