import update from 'immutability-helper';

import {
  SET,
  UPDATED
} from './actions';

import * as filtersQuestionActions from "../filtersQuestion/actions";
import * as filterCollectorsActions from "../filtersCollector/actions";
import * as filterDateActions from "../filterDate/actions";
import * as sharesActions from "../shares/actions";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case filtersQuestionActions.ADDED:
      let {filtersQuestion} = state;
      filtersQuestion.push(action.filterQuestion.id);

      return {
        ...state,
        filtersQuestion
      };

    case filtersQuestionActions.DELETED:
      return {
        ...state,
        filtersQuestion: state.filtersQuestion.filter(id => id !== action.id)
      };

    case filterCollectorsActions.DELETED:
      return {
        ...state,
        filterCollectors: state.filterCollectors.filter(id => id !== action.id)
      };

    case filterDateActions.UPDATED:
      return {
        ...state,
        filterDate: action.filter
      };

    case sharesActions.ADDED:
      let {shares} = state;
      shares.push(action.share.id);

      return {
        ...state,
        shares
      };

    case SET:
      return action.analysis;

    case UPDATED:
      return update(state, {
        $merge: action.analysis
      });

    default:
      return state;
  }
};