import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PrizesList from './PrizesList';
import appRoutes from 'appRoutes';
import {Link} from "react-router-dom";

class Prizes extends Component {

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">
                    <FormattedMessage id="admin.prizes.cardHeader"/>
                  </h2>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Link to={appRoutes.admin.prizes.create}>
                <Button className="mb-3" color="primary">
                  <i className="fas fa-plus"/>
                  <FormattedMessage id="admin.prizes.button.create"/>
                </Button>
              </Link>
              <Row>
                <Col>
                  <PrizesList />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Prizes;