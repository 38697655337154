import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input} from "reactstrap";
import {injectIntl, FormattedMessage} from "react-intl";
import QuestionAnswers from "../../../../Modals/QuestionAnswers";

const detailTypes = ['selected', 'notSelected'];
const condTypes = ['or', 'and', 'minMax'];

class Multi extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpenDropdownCondType: false,
      isOpenDropdownDetailType: false,
    };

    this.toggleIsOpenDropdownCondType = this.toggleIsOpenDropdownCondType.bind(this);
    this.toggleIsOpenDropdownDetailType = this.toggleIsOpenDropdownDetailType.bind(this);
  }

  componentWillMount() {
    const {condQuestion} = this.props;
    let condQuestionChanged = condQuestion;

    if(_.isUndefined(condQuestion.answers)){ condQuestionChanged.answers = []; }
    if(_.isUndefined(condQuestion.condType) || !condTypes.includes(condQuestion.condType)){ condQuestionChanged.condType = condTypes[0]; }
    if(_.isUndefined(condQuestion.detailType) || !detailTypes.includes(condQuestion.detailType)){ condQuestionChanged.detailType = detailTypes[0]; }

    if(condQuestion !== condQuestionChanged){
      Multi.propTypes.handleCondChanged(condQuestionChanged);
    }
  }

  toggleIsOpenDropdownCondType(){
    this.setState({
      isOpenDropdownCondType: !this.state.isOpenDropdownCondType,
    })
  }

  toggleIsOpenDropdownDetailType(){
    this.setState({
      isOpenDropdownDetailType: !this.state.isOpenDropdownDetailType,
    })
  }

  render() {
    const {isOpenDropdownDetailType, isOpenDropdownCondType} = this.state;
    const {intl, condQuestion, changeable} = this.props;

    return (
      <React.Fragment>
        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenDropdownDetailType} toggle={this.toggleIsOpenDropdownDetailType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Multi.detailType-' + condQuestion.detailType})}</DropdownToggle>
          <DropdownMenu>
            {detailTypes.map(detailType => <DropdownItem tag="a" key={detailType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              detailType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.Multi.detailType-' + detailType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condDetail.Multi.detailType-' + condQuestion.detailType})} </span>}

        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenDropdownCondType} toggle={this.toggleIsOpenDropdownCondType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Multi.condType-' + condQuestion.detailType + '-' + condQuestion.condType})}</DropdownToggle>
          <DropdownMenu>
            {condTypes.map(condType => <DropdownItem tag="a" key={condType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              condType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.Multi.condType-' + condQuestion.detailType + '-' + condType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condDetail.Multi.condType-' + condQuestion.detailType + '-' + condQuestion.condType})} </span>}

        {condQuestion.condType === 'minMax' && <React.Fragment>
          <FormattedMessage id="condQuestion-minMax-min"/>
          {changeable ? <Input
            type="number"
            className="d-inline mx-2"
            size="sm"
            style={{width: 50}}
            value={condQuestion.min} onChange={e => this.props.handleCondChanged({
            ...condQuestion,
            min: parseInt(e.target.value),
          })} /> : <span className="mx-1">{condQuestion.min}</span>}

          <FormattedMessage id="condQuestion-minMax-max"/>
          {changeable ? <Input
            type="number"
            className="d-inline ml-2"
            size="sm"
            style={{width: 50}}
            value={condQuestion.max} onChange={e => this.props.handleCondChanged({
            ...condQuestion,
            max: parseInt(e.target.value),
          })} /> : <span className="mx-1">{condQuestion.max}</span>}
        </React.Fragment>}

        <QuestionAnswers answers={condQuestion.answers.map(answer => answer.answer)} question={condQuestion.question} changeable={changeable} handleAnswersChanged={answersArray => {
          let answers = answersArray.map(answer => {
            return {
              answer
            }
          });

          this.props.handleCondChanged({...condQuestion, answers});
        }} />
      </React.Fragment>
    );
  }
}

Multi.defaultProps = {
  changeable: true
};

Multi.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number.isRequired,
    condType: PropTypes.oneOf(condTypes),
    detailType: PropTypes.oneOf(detailTypes),
    answers: PropTypes.arrayOf(PropTypes.shape({
      answer: PropTypes.number.isRequired,
    })),
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(Multi);