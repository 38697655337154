import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import ActionReplace from "./components/Replace";
import ActionAdd from "./components/Add";
import ActionCopy from "./components/Copy";
import CheckedDelete from "./components/CheckedDelete";
import ActionReverse from "./components/Reverse";
import {Button, Col, Row} from "reactstrap";

class AnswersActions extends Component {

  render() {
    const {question, checkedAnswers, answers} = this.props;

    let answersChecked =  _.map(checkedAnswers, id => answers.find(answer => answer.id === id) || false);
    answersChecked = answersChecked.filter(answer => answer !== false);

    return (
      <Row className="mt-2">
        <Col xs={12}>
          {answersChecked.length > 0 &&
          <div className="direction-group w-100 label-filtr-wrap mb-0">
            <ul className="direction-left mb-0 p-0">
              <li className="float-left">
                <CheckedDelete question={question} answersChecked={answersChecked}/>
              </li>
            </ul>
          </div>}

          <div className="direction-group w-100 label-filtr-wrap d-none d-lg-block">
            <ul className="direction-left mb-0 p-0">
              <li className="float-left"><Button color="secondary" onClick={this.props.onCheckAll}>{answersChecked.length >= answers.length ? <i className="fas fa-check-square" /> : <i className="far fa-check-square" />}</Button></li>
              <li className="float-left"><ActionReplace question={question}/></li>
              <li className="float-left"><ActionAdd question={question}/></li>
              <li className="float-left"><ActionCopy question={question}/></li>
              <li className="float-left"><ActionReverse question={question}/></li>
            </ul>
          </div>
        </Col>
      </Row>
    )
  }
}

AnswersActions.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  checkedAnswers: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  onCheckAll: PropTypes.func.isRequired,
};

export default AnswersActions;