import api from "api";
import * as types from './actionTypes';

export function setNews(news) {
  return {
    type: types.SET,
    news
  }
}

export function newsDeleted(news) {
  return {
    type: types.DELETED,
    news
  }
}

export function newsAdded(news) {
  return {
    type: types.ADDED,
    news
  }
}

export function newsUpdated(news) {
  return {
    type: types.UPDATED,
    news
  }
}

export function deleteNews(news) {
  return dispatch => {
    return api.news.delete(news).then(() => {
      dispatch(newsDeleted(news));
    })
  }
}

export function fetchNews() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.news.get.news().then(news => {
      let orderedNews = {};
      _.map(news, news => orderedNews[news.id] = news);

      dispatch(setNews(orderedNews));
      dispatch({type: types.SUCCESS});
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}

export function addNews(data) {
  return dispatch => {
    return api.news.post.news(data).then(news => {
      dispatch(newsAdded(news));
    })
  }
}

export function updateNews(data) {
  return dispatch => {
    return api.news.patch(data).then(news => {
      dispatch(newsUpdated(news));
    })
  }
}