import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Alert, Col, Row} from "reactstrap";
import Question from "./components/Question";
import ButtonAddQuestionList from "./components/ButtonAddQuestionList";
import ButtonCreate from "./components/Question/components/ButtonCreate";
import {ReactSortable} from "react-sortablejs";
import {FormattedMessage} from "react-intl";
import {updateQuestion} from "scenes/Survey/Structure/data/questions/actions";
import {setQuestions} from "scenes/Survey/Structure/data/pages/actions";
import {filterQuestions} from "services/surveyStructureFilter";
import classnames from "classnames";

class Questions extends Component {

  constructor(props) {
    super(props);

    this.onEnd = this.onEnd.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !nextProps.isStructureLoading;
  }

  onEnd(evt){
    if(evt.to === evt.from && evt.oldIndex === evt.newIndex){
      return false;
    }

    if(evt.to === evt.from){
      const {page} = this.props;
      let list = page.questions;

      const qId = parseInt(evt.item.attributes['data-id'].nodeValue);

      list.splice(evt.oldIndex, 1);
      list.splice(evt.newIndex, 0, qId);
      this.props.setQuestions(page.id, list);

      this.props.updateQuestion({
        id: qId,
        question: {
          position: evt.newIndex
        }
      });
    }else{
      const qId = parseInt(evt.item.attributes['data-id'].nodeValue);
      const oldPage = this.props.pages[parseInt(evt.from.parentElement.attributes['data-id'].nodeValue)];
      const newPage = this.props.pages[parseInt(evt.to.parentElement.attributes['data-id'].nodeValue)];
      //add qId to new page
      let newList = newPage.questions;
      newList.splice(evt.newIndex, 0, qId);
      this.props.setQuestions(newPage.id, newList);

      //remove qId from old page
      evt.from.appendChild(evt.item);
      const oldList = oldPage.questions.filter(id => id !== qId);
      this.props.setQuestions(oldPage.id, oldList);

      //update page and position in question
      this.props.updateQuestion({
        id: qId,
        question: {
          page: newPage.id,
          position: evt.newIndex
        }
      });
    }
  }

  render() {
    const {questions, page, hideInStructure} = this.props;
    let questionList = [];

    questions.map(question => {
      questionList.push(
        <div data-id={question.id} key={question.id} className={classnames({
          "card-wrap": true,
        })}>
          <Question question={question}/>
          {/*{hideInStructure !== 'structure' && <Row>*/}
          {/*  <Col xs={6} md={5} lg={4} xl={3}>*/}
          {/*    <ButtonAddQuestionList pageId={page.id} position={question.position + 1} classes="d-block ml-2 w-100" buttonColor='secondary'/>*/}
          {/*  </Col>*/}
          {/*  <Col xs={6} md={7} lg={8} xl={9}>*/}
          {/*    <ButtonCreate page={page} position={question.position + 1}/>*/}
          {/*  </Col>*/}
          {/*</Row>}*/}
        </div>);
    });
    
    return (
      <ReactSortable
        list={questionList.length > 0 ? questionList : [{}]}
        setList={() => {}}
        group={{
          name: 'questions',
          put: ['questions', 'page-new']
        }}
        handle=".handler"
        animation={100}
        onEnd={this.onEnd}
        filter=".sortable-ignore"
        className="child-wrap"
        data-id={page.id}

        id="sortable-page"
      >
      {
        (questionList.length > 0 ? questionList
          :
          (
            hideInStructure !== 'structure' && <Row className="animated fadeIn">
              <Col>
                <Alert className="text-center" color="warning"><FormattedMessage id="page.questions.alert.noQuestions" /></Alert>
                <Row className="mb-3">
                  <Col xs={12} md={5} lg={4} xl={3} className="order-1 order-md-0">
                    <ButtonAddQuestionList pageId={page.id} position={0} classes="d-block w-100" buttonColor='secondary'/>
                  </Col>
                  <Col xs={12} md={7} lg={8} xl={9} className="order-0 order-md-1 mb-2 mb-md-0">
                    <ButtonCreate page={page} position={0}/>
                  </Col>
                </Row>
              </Col>
            </Row>
          )
        )
      }
      </ReactSortable>
    )
  }
}

Questions.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.number.isRequired,
    questions: PropTypes.array.isRequired
  }).isRequired,
  isStructureLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state, props) {
  let questions = props.page.questions.map(id => state.survey.structure.data.questions[id]);
  questions = filterQuestions(questions, state.survey.structure);

  return {
    isStructureLoading: state.survey.structure.loading,
    questions: questions,
    pages: state.survey.structure.data.pages,
    hideInStructure: state.survey.structure.hideInStructure
  }
}

export default connect(mapStateToProps, {setQuestions, updateQuestion})(Questions);