import React, {Component} from 'react';
import api from "api";
import ApplicationTable from "../../components/Application/ApplicationTable";
import {Link} from "react-router-dom";
import appRoutes from "../../appRoutes";
import SurveySwitcherCheckAddModal from "./SurveySwitcherCheckAddModal";
import history from "services/history";
import SearchTextInput from "../../components/Application/ApplicationTable/SearchTextInput";
import {Badge} from "reactstrap";
import SurveySwitcherCheckHistoryModal from "componentsNew/SurveySwitcherChecks/SurveySwitcherCheckHistoryModal";
import SurveySwitcherCheckEditModal from "./SurveySwitcherCheckEditModal";

const CheckButton = (props) => {
  const [checking, setChecking] = React.useState(false);

  const check = () => {
    setChecking(true);

    api.surveySwitcherChecks.check(props.surveySwitcherCheck.id)
        .finally(() => {
          setChecking(false);
          props.onChecked();
        })
  }

  return <div>
    {!checking && <i className="fas fa-search p-1 ml-3 pointer" onClick={check}/>}
    {checking && <i className="fas fa-spinner fa-spin p-1 ml-3"/>}
  </div>
}

class SurveySwitcherChecksTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  render() {
    const columns = [
      {
        id: 'survey',
        Header: 'Ankieta',
        width: 300,
        accessor: row => row.surveyName
      },
      {
        id: 'url',
        Header: 'Adres Url',
        width: 400,
        accessor: row => row.url
      },
      {
        id: 'lastCheck',
        Header: 'Ostatnie sprawdzenie',
        width: 200,
        accessor: row => row.lastCheck
      },
      {
        id: 'isTextMatch',
        Header: 'Tekst musi być?',
        width: 100,
        accessor: row => row.isTextMatch ? 'TAK' : 'NIE'
      },
      {
        id: 'isCloseSurvey',
        Header: 'Zamykanie ankiety',
        width: 100,
        accessor: row => row.isCloseSurvey ? 'TAK' : 'NIE'
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: row => {
          let color = 'warning';

          if(row.status.valid === false){
            color = 'danger';
          }else if(row.status.valid === true){
            color = 'success';
          }


          return <div className="d-flex flex-nowrap align-items-center">
            <Badge color={color} style={{fontSize: '100%'}}>{row.status.msg}</Badge>
            <CheckButton
                surveySwitcherCheck={row}
                onChecked={() => {
                  this.tableRef.current.fetchData({})
                }}
            />
            <SurveySwitcherCheckEditModal
                surveySwitcherCheck={row}
                onModalClose={() => {}}
                onAdded={dictionary => this.tableRef.current.fetchData({})}
            />
          </div>
        }
      },
      {
        id: 'matchText',
        Header: 'Sprawdzany tekst',
        width: 200,
        accessor: row => row.matchText || <i className="text-secondary">Brak tekstu</i>
      },
      {
        id: 'history',
        Header: 'Historia',
        width: 80,
        accessor: row => <SurveySwitcherCheckHistoryModal surveySwitcherCheck={row} />
      },
    ];

    return <React.Fragment>
      <div className="d-flex justify-content-between mb-2">
        <SurveySwitcherCheckAddModal
            onModalClose={() => {}}
            onAdded={dictionary => this.tableRef.current.fetchData({})}
        />

        <div className="ml-auto">
          <SearchTextInput
              tableRef={this.tableRef}
          />
        </div>
      </div>
      <ApplicationTable
          ref={this.tableRef}
          columns={columns}
          fetchData={api.surveySwitcherChecks.search}
          deleteData={api.surveySwitcherChecks.delete}
          filters={this.props.filters}
      />
    </React.Fragment>;
  }
}

export default SurveySwitcherChecksTable;
