import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Button, Tooltip} from "reactstrap";
import {normalize} from "normalizr";
import schemaQuestions from "entities/question/schema";
import {rowUpdated} from "scenes/Survey/Structure/data/rows/actions";
import classnames from "classnames";

class RowsImage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: {
        rowsImage: props.question.rowsImage,
      },
      tooltip: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleRowsImage = this.toggleRowsImage.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleRowsImage() {
    this.setState({
      question: {
        rowsImage: !this.state.question.rowsImage
      }
    }, () => {
      this.props.handleUpdate(this.state.question).then(question => {
        const normalized = normalize(question, schemaQuestions);

        Object.values(normalized.entities.rows).map(row => {
          this.props.dispatch(rowUpdated(row));
        })
      })
    });
  }

  render() {
    let { question, tooltip } = this.state;
    return (
      <Button
        className={classnames({
          "float-left mr-1 btn-icon mb-1": true,
          "active": question.rowsImage,
        })}
        color={'secondary'}
        id={"rowsImage-" + this.props.question.id}
        onClick={this.toggleRowsImage}
      >
        <i className="fak fa-image-vertcal" />

        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={"rowsImage-" + this.props.question.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="question.settings.rowsImage.button.tooltip"/>
        </Tooltip>
      </Button>
    )
  }
}

RowsImage.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rowsImage: PropTypes.bool.isRequired
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default connect()(RowsImage);
