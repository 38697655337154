import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader, CardImgOverlay} from "reactstrap";
import {toggleQuestionCard} from "scenes/Survey/Structure/actions"

import HeaderActions from "./components/HeaderActions";
import Content from "./components/Content";
import Baskets from "./components/Baskets";
import Answers from "./components/Answers";
import Rows from "./components/Rows";
import Video from "./components/Video";
import Columns from "./components/Columns";
import Excludings from "./components/Excludings";
import Settings from "./components/Settings";
import Filters from "./components/Filters";
import LoadingSpinner from "components/LoadingSpinner";
import Pipings from "./components/Pipings";
import ScalePoints from "./components/ScalePoints";
import HelpModeIconModal from "components/HelpModeIconModal";
import DifferentialRows from "./components/DifferentialRows";
import {animateScroll as scroll} from 'react-scroll'
import Attributes from "./components/Attributes";

class Question extends Component {

  constructor(props) {
    super(props);
    this.state = {
      optionsOpen: false,
      pipingsOpen: false,
      filtersOpen: false,
      headerActions: true,
      loading: false,
      hovered: false,
    };
    this.onCardHeaderClick = this.onCardHeaderClick.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.togglePipings = this.togglePipings.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
  }

  toggleOptions() {
    this.setState({
        optionsOpen: !this.state.optionsOpen,
        filtersOpen: false,
        pipingsOpen: false,
    }, () => {
      if (this.state.optionsOpen) {
        const questionHeader = document.getElementById("question-header-" + this.props.question.id);
        const header = document.getElementById("header");
        const more = questionHeader.getBoundingClientRect().bottom - header.offsetHeight - 50;
        scroll.scrollMore(more, {containerId: 'structure', duration: 250});
      }
    });
    scroll.scrollToBottom();
  }

  togglePipings() {
    scroll.scrollToBottom();
    this.setState({
      pipingsOpen: !this.state.pipingsOpen,
      optionsOpen: false,
      filtersOpen: false,
    }, () => {
      if (this.state.pipingsOpen) {
        const questionHeader = document.getElementById("question-header-" + this.props.question.id);
        const header = document.getElementById("header");
        const more = questionHeader.getBoundingClientRect().bottom - header.offsetHeight - 50;
        scroll.scrollMore(more, {containerId: 'structure', duration: 250});
      }
    });
  }


  toggleFilters() {
    this.setState({
        filtersOpen: !this.state.filtersOpen,
        optionsOpen: false,
        pipingsOpen: false,
    }, () => {
      if (this.state.filtersOpen) {
        const questionHeader = document.getElementById("question-header-" + this.props.question.id);
        const header = document.getElementById("header");
        const more = questionHeader.getBoundingClientRect().bottom - header.offsetHeight - 50;
        scroll.scrollMore(more, {containerId: 'structure', duration: 250});
      }
    });
  }

  onCardHeaderClick(e) {
    if (e.target.getAttribute('data-toggleable') && this.props.hideInStructure !== 'none')
      this.props.toggleQuestionCard(this.props.question.id);
  }

  render() {
    const {question, closedQuestionCards, searchInStructure, hideInStructure, showQuestionsSettings} = this.props;
    const {headerActions, optionsOpen, pipingsOpen, filtersOpen, loading, hovered} = this.state;
    const gripStyle = hovered ? {opacity: 1} : {opacity: 0};
    let style = {};

    if(question.deleting || loading){
      style.opacity = 0.3
    }

    return (
      <React.Fragment>
        <Card data-id={question.id} data-type="questionCard"  style={style} role="question" id={`question-${question.id}`} className="border-l violet">
          <CardHeader id={"question-header-" + question.id} className="d-flex py-md-2" onMouseEnter={() => this.setState({hovered: true})} onMouseLeave={() => this.setState({hovered: false})}>
            <div title={'Id pytania: ' + question.id} className={"p-0 pt-3 pt-md-1 flex-grow-1 d-flex " + (headerActions ? '' : ' w-100') + (hideInStructure === 'minified' ? " w-75" : "")} style={{lineHeight: '14px'}} data-toggleable={true} onClick={e => this.onCardHeaderClick(e)}>
              <a className="handler fas fa-grip-vertical dragg float-left mr-2" style={gripStyle}/>
              <HelpModeIconModal className="float-left mr-2" modalHeader="Pytanie">
                <p><i className="fas fa-search"/> - Podejrzyj aktualny wygląd swojej ankiety</p>
                <p><i className="fas fa-cog"/> - Pokaż/ukryj dodatkowe ustawienia pytania</p>
              </HelpModeIconModal>
              <Content question={question} handleEditing={headerActions => this.setState({ headerActions })} searchInStructure={searchInStructure}/>
            </div>
            {headerActions && <HeaderActions
              question={question}
              optionsOpen={optionsOpen}
              pipingsOpen={pipingsOpen}
              filtersOpen={filtersOpen}
              handleToggleOptions={() => this.toggleOptions()}
              handleTogglePipings={() => this.togglePipings()}
              handleToggleFilters={() => this.toggleFilters()}
            />}
          </CardHeader>

          {(this.state.optionsOpen || showQuestionsSettings) && <Settings question={question} />}
          {this.state.pipingsOpen && <Pipings question={question} />}
          {this.state.filtersOpen && <Filters question={question} />}

          {['cbc'].includes(question.type) && <Attributes question={question} />}

          {!closedQuestionCards.includes(question.id) && <CardBody className="pt-0">
            {question.baskets && <Baskets question={question} />}
            {question.answers && <Answers question={question} />}
            {question.rows && question.type !== 'differential' && <Rows question={question} />}
            {question.type === 'differential' && <DifferentialRows question={question} />}
            {question.columns && <Columns question={question} />}
            {question.type === 'video' && <Video question={question} />}
            {question.scalePoints && ['slider', 'dragAndDrop'].indexOf(question.presentationType) > -1 && <ScalePoints question={question} />}

            <Excludings question={question} />
          </CardBody>}
        </Card>

        {(question.deleting || loading) && <CardImgOverlay>
          <LoadingSpinner absolute={true}/>
        </CardImgOverlay>}
      </React.Fragment>
    )
  }
}

Question.propTypes = {
  closedQuestionCards: PropTypes.array.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    content: PropTypes.string,
    answers: PropTypes.array,
    rows: PropTypes.array,
    columns: PropTypes.array,
    baskets: PropTypes.array,
    scalePoints: PropTypes.array,
    presentationType: PropTypes.string,
  }).isRequired
};


function mapStateToProps(state) {
  return {
    closedQuestionCards: state.survey.structure.closedQuestionCards,
    searchInStructure: state.survey.structure.searchInStructure,
    hideInStructure: state.survey.structure.hideInStructure,
    showQuestionsSettings: state.survey.structure.showQuestionsSettings,
  }
}

export default connect(mapStateToProps, {toggleQuestionCard})(Question);