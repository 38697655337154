import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, InputGroup, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PanelShippingList from "./PanelShippingList";

class PanelShipping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
    };

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(e) {
    this.setState({search: e.target.value})
  }

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <FormattedMessage tagName="h2" id="admin.panelShipping.cardHeader" />

              <div className="custom-search-input w-lg-100 float-right mt-1">
                <InputGroup className="p-0 mb-0">
                  <Input className="p-0" placeholder="Znajdź..." onChange={this.onSearch}/>
                  <span className="input-group-btn py-1">
                    <button className="btn" type="button"><i className="fas fa-search" /></button>
                  </span>
                </InputGroup>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Row>
                <Col>
                  <PanelShippingList ref={'list'} search={this.state.search} history={this.props.history} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default PanelShipping;
