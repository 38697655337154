import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Tooltip} from "reactstrap";
import classnames from "classnames";
import InputNumber from "components/InputNumber";

class AnswersStart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: {
        answersStart: props.question.answersStart,
      },
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(value){
    if(!value || value < 0){
      value = 0;
    }

    this.setState({
      question: {
        ...this.state.question,
        answersStart: value
      }
    },
      () => this.props.handleUpdate(this.state.question)
    );
  }

  render() {
    let { question, tooltip } = this.state;

    return (
      <div
        id={"answersStart-" + this.props.question.id}
        className={classnames({
          "min-w-0 float-left mr-1 mb-1": true,
          "w-auto": question.answersStart === 0,
          "w-100 w-lg-auto input-group": question.answersStart > 0
        })}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": question.answersStart > 0,
            })}
            color="secondary"
            onClick={() => {
              if(question.answersStart === 0){
                this.onChange(1)
              }else{
                this.onChange(0)
              }
            }}
          >
            <i className="fa-solid fa-input-pipe" />
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltip}
              target={"answersStart-" + this.props.question.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="question.settings.answersStart.button.tooltip"/>
            </Tooltip>
          </Button>
        </InputGroupAddon>
        {question.answersStart > 0 && <InputNumber value={question.answersStart} min={0} max={this.props.question.answersCount} onChange={(value) => this.onChange(value)}/>}
      </div>
    )
  }
}

AnswersStart.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answersStart: PropTypes.number.isRequired
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default AnswersStart;
