import React, {Component} from 'react';
import PropTypes from "prop-types";

import 'spinkit/css/spinkit.css';

class LoadingSpinner extends Component {

  render() {
    const {classNames, width, absolute} = this.props;
    let style = {
      width: width + 'px',
      height: width + 'px'
    };

    if(absolute){
      style.margin = 0;
      style.position = 'absolute';
      style.top = 'calc(50% - ' + (parseInt(width / 2)) + 'px)';
      style.left = 'calc(50% - ' + (parseInt(width / 2)) + 'px)';
    }

    return (
      <div id="loading-spinner" className={(classNames) + ' sk-circle'} style={style}>
        <div className="sk-circle1 sk-child"> </div>
        <div className="sk-circle2 sk-child"> </div>
        <div className="sk-circle3 sk-child"> </div>
        <div className="sk-circle4 sk-child"> </div>
        <div className="sk-circle5 sk-child"> </div>
        <div className="sk-circle6 sk-child"> </div>
        <div className="sk-circle7 sk-child"> </div>
        <div className="sk-circle8 sk-child"> </div>
        <div className="sk-circle9 sk-child"> </div>
        <div className="sk-circle10 sk-child"> </div>
        <div className="sk-circle11 sk-child"> </div>
        <div className="sk-circle12 sk-child"> </div>
      </div>
    )
  }
}

LoadingSpinner.defaultProps = {
  classNames: '',
  width: 40,
  absolute: false
};

LoadingSpinner.propTypes = {
  classNames: PropTypes.string,
  width: PropTypes.number,
  absolute: PropTypes.bool
};



export default LoadingSpinner;