import * as React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../components/Button";
import PropTypes from "prop-types";

function ModalComponent(props) {

    const acceptModal = () => {
        props.onAccept();
    }

	const closeModal = () => {
		props.onCancel();
	}

	return (
		<Modal isOpen={props.modalOpen} toggle={closeModal} size="lg">
            {props.headerText && <ModalHeader toggle={closeModal}>{props.headerText}</ModalHeader>}
			<hr className="my-0"/>
            {props.component && <ModalBody>{props.component}</ModalBody>}
			{props.acceptText || props.cancelText && <ModalFooter>
				<Button
					color="primary"
					onClick={() => acceptModal()}
				>{props.acceptText}</Button>
				<Button
					color="secondary"
					onClick={() => closeModal()}
				>{props.cancelText}</Button>
			</ModalFooter>}
		</Modal>
	);
}

ModalComponent.defaultProps = {
}

ModalComponent.propTypes = {
	modalOpen: PropTypes.bool.isRequired,
	onAccept: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	acceptText: PropTypes.string,
	cancelText: PropTypes.string,
	component: PropTypes.string.isRequired,
	headerText: PropTypes.string
};

export default ModalComponent;