import * as React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {ListGroupItem} from "reactstrap";
import AttributeLevelName from "./AttributeLevelName";
import AttributeLevelDelete from "./AttributeLevelDelete";

function AttributeLevel(props) {

  return (
    <ListGroupItem className="attribute-level d-flex justify-content-between align-items-center py-1 px-2">
      <AttributeLevelName level={props.level} />

      <AttributeLevelDelete attribute={props.attribute} level={props.level} />
    </ListGroupItem>
  );
}

AttributeLevel.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  attribute: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect()(AttributeLevel);