import api from "api";
import * as types from './actionTypes';

export function setResponses(responses) {
  return {
    type: types.SET,
    responses
  }
}

export function responseUpdated(response) {
  return {
    type: types.UPDATED,
    response
  }
}

export function fetchResponses() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.surveyResponse.get.surveyResponses().then(responses => {
      dispatch(setResponses(responses));
      dispatch({type: types.SUCCESS});
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}

export function updateResponse(data) {
  return dispatch => {
    return api.surveyResponse.patch.response(data).then(response => {
      dispatch(responseUpdated(response));
    })
  }
}