import update from 'immutability-helper';

import {
  SET,
  ADDED,
  DELETED,
  UPDATED
} from './actions';

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.widgets;

    case UPDATED:
      return update(state, {
        [action.widget.id]: {
          $set: action.widget
        }
      });

    case ADDED:
      return {
        ...state,
        [action.widget.id]: action.widget
      };

    case DELETED:
      return update(state, {
        $unset: [action.widget.id]
      });

    default:
      return state;
  }
};