import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import classnames from "classnames";

import {connect} from "react-redux";


class PatternsModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: false,
      modalOpen: true,
      saving: false,
    };
  }

  render() {
    const {modalOpen, saving, value} = this.state;
    const {bgList} = this.props;

    return <Modal id="patterns-modal" isOpen={modalOpen} size="xl" style={{zIndex: 5000}}>
      <ModalHeader toggle={this.toggleModal}>Wybierz tło z gotowych wzorów</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Row>
          {bgList.map(url => {
            return <Col xs={6} sm={4} md={3} lg={2}>
              <div
                className={classnames({
                  'bg-element d-flex': true,
                  'selected': value === url,
                })}
                onClick={() => this.setState({value: url})}
              >
                <div
                  style={{
                    backgroundImage: 'url('+url+')',
                  }}
                />
              </div>
            </Col>
          })}
        </Row>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={() => this.props.onClose()}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            disabled={!value}
            data-style={ZOOM_OUT}
            onClick={() => this.props.onSelect(value)}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;
  }
}

PatternsModal.propTypes = {
  value: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    bgList: state.config.data.survey.backgroundList,
  }
}

export default connect(mapStateToProps)(PatternsModal);