import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Route, Redirect} from "react-router-dom"
import appRoutes from '../appRoutes';
import LoadingSpinner from "components/LoadingSpinner";

const AdminRoute = ({ isAuthenticated, user, component: Component, path, ...rest}) => {

  let render = function (props) {
    if (isAuthenticated) {
      if (Array.isArray(user.roles)) {
        return (user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN')) ? <Component {...props} /> : <Redirect to={appRoutes.home}/>;
      } else {
        return <LoadingSpinner />
      }
    }else{
      return <Redirect to={appRoutes.users.login}/>;
    }
  };

  if(
    user.roles.includes('ROLE_SUPER_ADMIN')
    || (user.roles.includes('ROLE_ADMIN_SURVEYS_SWPANEL')
        && [appRoutes.admin.surveys.accepted, appRoutes.admin.surveys.waitingAccept].includes(path))
    || (user.roles.includes('ROLE_ADMIN_REPORTER')
        && [appRoutes.admin.cms.reports, appRoutes.admin.cms.reportDownloads, appRoutes.admin.cms.newses, appRoutes.admin.cms.caseStudies, appRoutes.admin.cms.mediaInfos].includes(path))
    || (user.roles.includes('ROLE_ADMIN_ANKIETEO_CMS')
        && [appRoutes.admin.cms.ankieteo.main, appRoutes.admin.cms.ankieteo.navEdit(), appRoutes.admin.cms.ankieteo.edit(), appRoutes.admin.cms.ankieteo.add, appRoutes.admin.cms.ankieteo.list].includes(path))
    || (user.roles.includes('ROLE_ADMIN_SWRESEARCH_CMS')
        && [appRoutes.admin.cms.swresearch.main, appRoutes.admin.cms.swresearch.navEdit(), appRoutes.admin.cms.swresearch.edit(), appRoutes.admin.cms.swresearch.add, appRoutes.admin.cms.swresearch.list].includes(path))
    || (user.roles.includes('ROLE_ADMIN_USER_RESPONDENTS')
        && [appRoutes.admin.users.respondents, appRoutes.admin.users.points, appRoutes.admin.users.logins, appRoutes.admin.users.respondentsCollectors].includes(path))
    || (user.roles.includes('ROLE_ADMIN_USER_COMPANIES')
        && [appRoutes.admin.users.companies].includes(path))
    || (user.roles.includes('ROLE_ADMIN_USER_PRIZE')
        && [appRoutes.admin.prizes.userPrizes].includes(path))
    || (user.roles.includes('ROLE_ADMIN_SURVEYS_RESPONSES')
        && [appRoutes.admin.surveys.responses].includes(path))
    || (user.roles.includes('ROLE_ADMIN_FILES')
        && [appRoutes.admin.files.files].includes(path))
    || (user.roles.includes('ROLE_ADMIN_RANKINGS')
        && [appRoutes.admin.cms.topSurveysRanks, appRoutes.admin.cms.socialRanks].includes(path))
    || (user.roles.includes('ROLE_ADMIN')
        && [appRoutes.admin.plans.transactions].includes(path))
    || (user.roles.includes('ROLE_ADMIN_PLANS')
        && [appRoutes.admin.settings.planCategories.list].includes(path))
    || (user.roles.includes('ROLE_ADMIN_SURVEYS')
        && [appRoutes.admin.surveys.list].includes(path))
    || (user.roles.includes('ROLE_ADMIN_SURVEY_SHIPPING')
        && [appRoutes.admin.shipping.email, appRoutes.admin.shipping.find, appRoutes.admin.shipping.panel].includes(path))
  ){

    return (
      <Route {...rest} children={props => render(props)} />
    )
  }

  return  null;
};

AdminRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: Object.keys(state.user).length > 0,
    user: state.user
  }
}

export default connect(mapStateToProps)(AdminRoute);
