import update from 'immutability-helper';

import {
  ADDED,
  DELETED,
  SET,
  UPDATED,
  SET_BLOCKS,
  BLOCK_ADDED
} from './actions';

import * as blocksActions from "../blocks/actions";

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case blocksActions.DELETED:
      return update(state, {
        [action.block.set.id]: {
          blockPages: {
            $set: state[action.block.set.id].blockPages.filter(id => id !== action.block.id)
          }
        }
      });

    case SET_BLOCKS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          blockPages: action.data.blocks
        }
      };

    case BLOCK_ADDED:
      let list = state[action.block.setBlocks.id].blockPages;
      list.splice(action.block.position, 0, action.block.id);

      return update(state, {
        [action.block.setBlocks.id]: {
          blockPages: {
            $set: list
          }
        }
      });

    case SET:
      return action.sets;

    case ADDED:
      return {
        ...state,
        [action.set.id]: action.set
      };

    case DELETED:
      return update(state, {$unset: [action.set.id] });

    case UPDATED:
      return update(state, {
        [action.set.id]: {
          $set: action.set
        }
      });

    default:
      return state;
  }
};