import {SET, INVITATION_REJECTED} from "./actionTypes";
import update from "immutability-helper";

const initialState = [];

export const invitationReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.invitations;

    case INVITATION_REJECTED:
      return update(state, {$unset: [action.groupId]});

    default:
      return state;
  }
};

export default invitationReducer;