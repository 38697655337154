import React, {Component} from 'react';
import {connect} from "react-redux";
import {Alert, Button} from "reactstrap";
import {deleteCancelUser} from "reducers/user/actions";
import moment from "moment";


class DeletingAccount extends Component {

  deleteCancel(){
    this.props.deleteCancelUser().then(() => {});
  }

  render() {
    const {user} = this.props;

    return (
      <main>
        <div className="direction-group">
          <div className="direction-left d-flex justify-content-start">
            <h1 className="d-flex justify-content-start float-left">Usuwanie konta</h1>
          </div>
        </div>

        <div>
          <Alert color="warning" className="text-center">
            Twoje konto zostanie usunięte do dnia {moment(user.deletedDate).format('YYYY-MM-DD')} 23:59.
          </Alert>

          <Button onClick={() => this.deleteCancel()}>Anuluj usunięcie mojego konta</Button>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, {deleteCancelUser})(DeletingAccount);