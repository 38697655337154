import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "../../Button";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import api from "../../../api";

class ButtonDelete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      deleting: false,
    };

    this.remove = this.remove.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  remove(){
    this.setState({
      deleting: true,
    });

    api.respondentSmsBases.delete(this.props.baseId)
    .then(data => {
      this.props.onDeleted();
    })
    .catch(res => {
      this.setState({
        deleting: false,
      });
    })
    ;
  }

  toggleModal(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {deleting, modalOpen} = this.state;

    return <React.Fragment>
      <div onClick={this.toggleModal}>{this.props.children}</div>
      <Modal isOpen={modalOpen} toggle={this.toggleModal} size="lg">
        <ModalHeader tag="h2" toggle={this.toggleModal}>Usuń baze</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <p>Czy na pewno chcesz usunąć bazę?</p>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className={['mb-0']} onClick={this.toggleModal}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.delete">{
            (message) => <Button
              color="primary"
              loading={deleting}
              onClick={this.remove}
            >
              {message}
            </Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    </React.Fragment>;
  }
}

ButtonDelete.propTypes = {
  onDeleted: PropTypes.func.isRequired,
  baseId: PropTypes.number.isRequired,
};

export default ButtonDelete;