import React, {Component} from 'react';
import appRoutes from "appRoutes";
import history from "services/history";
import PropTypes from 'prop-types';
import Select from "react-select";

class SelectAnalysis extends Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }


  onChange(option){
    history.push(appRoutes.survey.analysis.questionSummaries(this.props.analysis.survey, option.value));
  }

  render() {
    return (
      <Select
        className="py-2"
        value={{label: this.props.analysis.name, value: this.props.analysis.id}}
        isOpen={false}
        isMulti={false}
        isLoading={_.isEmpty(this.props.analyses)}
        onChange={this.onChange}
        options={this.props.analyses.map(analysis => {
          return {
            label: analysis.name,
            value: analysis.id,
          }
        })}
      />
    )
  }
}

SelectAnalysis.propTypes = {
  analysis: PropTypes.object.isRequired,
  analyses: PropTypes.array.isRequired
};

export default SelectAnalysis;