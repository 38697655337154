import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Input, Tooltip} from "reactstrap";
import classnames from "classnames";

class LibraryValuePatternText extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: {
        libraryValuePattern: props.question.libraryValuePattern || ''
      },
      isOpen: !!props.question.libraryValuePattern,
      libraryValueTooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  toggleTooltip() {
    this.setState({
      libraryValueTooltip: !this.state.libraryValueTooltip
    });
  }

  onChange(value){
    this.setState({
      question: {
        ...this.state.question,
        libraryValuePattern: value
      }
    });
  }

  handleKeyPress(e){
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  toggleIsOpen() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    let { isOpen, question, libraryValueTooltip } = this.state;

    if(!isOpen){
      return <Button className="mr-1 btn-icon float-left" color="secondary" id={"libraryValue-" + this.props.question.id} onClick={this.toggleIsOpen}>
        <i className="fas fa-tag" />
        <Tooltip
          delay={0}
          placement="top"
          isOpen={libraryValueTooltip}
          target={"libraryValue-" + this.props.question.id}
          toggle={() => this.toggleTooltip()}
        >Etykieta</Tooltip>
      </Button>
    }

    return (
      <div
        className={classnames({
          "mr-1 float-left w-25 p-0 mb-1": true,
          "input-group": isOpen,
        })}
      >
        <InputGroupAddon addonType="prepend" className="m-0">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": isOpen,
            })}
            color="secondary"
            onClick={() => {
              this.setState({
                isOpen: false,
                question: {
                  ...this.state.question,
                  libraryValuePattern: ''
                }
              }, () => {
                this.props.handleUpdate(this.state.question)
              });
            }}
            id={"libraryValue-" + this.props.question.id}
          >
            <i className="fas fa-tag" />
            <Tooltip
              delay={0}
              placement="top"
              isOpen={libraryValueTooltip}
              target={"libraryValue-" + this.props.question.id}
              toggle={() => this.toggleTooltip()}
            >Etykieta</Tooltip>
          </Button>
        </InputGroupAddon>
        {isOpen && <Input placeholder="'d.value' - domyślna wartość label" name="libraryValuePattern" value={question.libraryValuePattern} onChange={e => this.onChange(e.target.value)} onBlur={() => this.props.handleUpdate(this.state.question)} onKeyPress={this.handleKeyPress}/>}
      </div>
    )
  }
}

LibraryValuePatternText.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    libraryValuePattern: PropTypes.string,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default LibraryValuePatternText;