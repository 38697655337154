import React, {Component} from 'react';
import {Form, Label, Input, Row, Col} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

class RespondentBaseForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
        name: props.respondentBase ? props.respondentBase.name : ''
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    const {errors} = this.props;

    return (
      <Form onSubmit={e => this.props.onSubmit(e, this.state)}>
        <Row>
          <Col lg={3}>
            <Label for="name"><FormattedMessage id="respondentBaseForm.label.name" /></Label>
          </Col>
          <Col lg={9}>
            <Input type="text" name="name" id="name" value={this.state.name} onChange={this.onChange} invalid={!!errors.name}/>
            {errors.name && <div className="invalid-feedback">{errors.name.join('; ')}</div>}
          </Col>
        </Row>
      </Form>
    )
  }
}

RespondentBaseForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  respondentBase: PropTypes.object,
};


export default RespondentBaseForm;