import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Col, Form, FormGroup, Label} from 'reactstrap';
import {updateAnalysis} from "../../../reducer/analysis/actions";
import Select from 'react-select';

class SettingsLang extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      value: props.lang
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(inputValue) {
    const {analysisId} = this.props;
    const lang = inputValue.value;

    this.setState({
      saving: true,
      value: lang
    });

    this.props.updateAnalysis({
      id: analysisId,
      analysis: {
        lang
      }
    }).then(() => {
      this.props.onSaved(lang);
      this.setState({
        saving: false
      });
    });
  }

  render() {
    const {availableLanguages} = this.props;
    const {value, saving} = this.state;

    return (
      <Form>
        <FormGroup row className="mt-2">
          <Label lg={4} className="text-right">Język analizy:</Label>
          <Col lg={8}>
            <Select
              isClearable={false}
              isLoading={saving}
              isDisabled={saving}
              value={{
                value: value,
                key: value,
                label: <span><img src={'/dashboard/img/icon/'+ value + '.png'} height="20" /> <FormattedMessage id={'_.lang.'+value+'.text'}/></span>,
              }}
              options={availableLanguages.map(l => {
                return {
                  value: l,
                  label: <span><img src={'/dashboard/img/icon/' + l + '.png'} height="20" /> <FormattedMessage id={'_.lang.'+l+'.text'}/></span>,
                }
              })}
              onChange={this.onChange}
            />
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

SettingsLang.propTypes = {
  analysisId: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default connect(null, {updateAnalysis})(SettingsLang);