import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Switch from "components/Switch";
import InputNumber from "components/InputNumber";
import {updateBlock} from 'scenes/Survey/Structure/data/blocks/actions';

class RandAndRotatePageOptions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rotateInSet: props.block.rotateInSet,
      randomInSet: props.block.randomInSet,
      randPriority: props.block.randPriority,
    };
  }

  onChange(property, value) {
    this.setState({
      [property]: value
    });

    this.props.updateBlock({
      id: this.props.block.id,
      block: {
        [property]: value
      }
    });
  }

  render() {
    const {block, set} = this.props;

    return (
      <tr>
        <td>
          <FormattedMessage id="set.settings.block"/> {block.positionInSurvey + 1}
        </td>
        {set.rotateBlocks && <td>
          <Switch checked={this.state.rotateInSet} onChange={value => this.onChange('rotateInSet', value)}/>
        </td>}
        {set.randBlocks > 0 && <td>
          <Switch checked={this.state.randomInSet} onChange={value => this.onChange('randomInSet', value)}/>
        </td>}
        {set.randBlocks > 0 && this.state.randomInSet && <td>
          <InputNumber value={this.state.randPriority} min={0} onChange={value => this.onChange('randPriority', value)}/>
        </td>}
      </tr>
    )
  }
}

RandAndRotatePageOptions.propTypes = {
  set: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired
};

export default connect(null, {updateBlock})(RandAndRotatePageOptions);