import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {get, set, isBoolean, isNumber} from "lodash";
import Switch from "../../../components/Switch";
import {Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Select from "react-select";
import {connect} from "react-redux";
import api from "../../../api";

const PresentationTypeSelect = ({ qType, options, value, onChange }) => {
  return <Select
    isMulti
    value={_.map(value, type => {
      return {
        label: <FormattedMessage id={`_.question.${qType}.presentationType.` + type} />,
        value: type,
      }
    })}
    onChange={e => onChange(e.map(val => val.value))}
    options={_.map(options, type => {
      return {
        label: <FormattedMessage id={`_.question.${qType}.presentationType.` + type} />,
        value: type,
      }
    })}
  />
}

class PlanSettingValue extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      value: get(this.props.plan, this.props.path),
    }

    this.updateValue = this.updateValue.bind(this)
    this.save = this.save.bind(this)
  }

  updateValue(value){
    this.setState({
      value,
    }, () => {
      this.save(this.state.value);
    })
  }

  save(value){
    api.plan.patch({
      id: this.props.plan.id,
      data: set({}, this.props.path, value)
    })
  }

  render() {
    const {value} = this.state;
    const {config} = this.props;

    if(isBoolean(value)){
      return <div className="d-flex justify-content-end" style={{ width: '130px' }}>
        <Switch className="mb-0 mr-1" checked={value} onChange={value => {
          this.setState({
            value
          }, () => {
            this.save(value)
          })
        }} />
      </div>
    }

    if(isNumber(value)){
      return <div className="d-flex justify-content-end" style={{ width: '130px' }}>
        <Input
          size={'sm'}
          className="mb-0 py-0"
          style={{width: '100px'}}
          value={value}
          onBlur={() => {
            this.save(value)
          }}
          onKeyDown={e => {
            switch(e.key){
              case 'Enter': {
                this.save(value)
                break;
              }
            }
          }}
          onChange={e => this.setState({value: parseInt(e.target.value === '-' ? -1 : e.target.value)})}
        />
      </div>
    }

    if(this.props.path === 'support'){
      return <div className="d-flex justify-content-end" style={{ width: '130px' }}>
        <Input
          size={'sm'}
          type="textarea"
          rows={4}
          className="mb-0 py-0"
          style={{width: '100px'}}
          value={value}
          onBlur={() => {
            this.save(value)
          }}
          onChange={e => this.setState({value: e.target.value})}
        />
      </div>
    }

    if(this.props.path === 'survey.questionsAvailable'){
      return <div style={{width: '400px'}}>
        <Select
          isMulti
          value={_.map(value, type => {
            return {
              label: <FormattedMessage id={'_.question.' + type + '.typeName'} />,
              value: type,
            }
          })}
          onChange={e => {
            this.setState({
              value: e.map(val => val.value)
            }, this.save(e.map(val => val.value)))
          }}
          options={_.map(config.data.questions, (data, type) => {
            return {
              label: <FormattedMessage id={'_.question.' + type + '.typeName'} />,
              value: type,
            }
          })}
        />
      </div>
    }

    if(this.props.path === 'analysis.exportTypes'){
      return <div style={{width: '280px'}}>
        <Select
          isMulti
          value={_.map(value, type => {
            return {
              label: <FormattedMessage id={'_.analysis.exportType.' + type} />,
              value: type,
            }
          })}
          onChange={e => {
            this.setState({
              value: e.map(val => val.value)
            }, this.save(e.map(val => val.value)))
          }}
          options={_.map(config.data.analysis.exportTypes, (type) => {
            return {
              label: <FormattedMessage id={'_.analysis.exportType.' + type} />,
              value: type,
            }
          })}
        />
      </div>
    }

    if(this.props.path === 'survey.questionSettings.single'){
      return <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-column justify-content-center">
          <span>Rotowanie odp</span>
          <Switch className="mx-auto" stickToProp checked={value.rotateAnswers} onChange={rotateAnswers => this.updateValue({
            ...value,
            rotateAnswers,
          })} />
        </div>
        <div className="ml-2" style={{width: '250px'}}>
          <PresentationTypeSelect
            qType={'single'}
            options={config.data.questions.single.presentationTypes}
            value={value.presentationTypes}
            onChange={presentationTypes => this.updateValue({
              ...value,
              presentationTypes,
            })}
          />
        </div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.multi'){
      return <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-column justify-content-center">
          <span>Rotowanie odp</span>
          <Switch className="mx-auto" stickToProp checked={value.rotateAnswers} onChange={rotateAnswers => this.updateValue({
            ...value,
            rotateAnswers,
          })} />
        </div>
        <div className="ml-2" style={{width: '250px'}}>
          <PresentationTypeSelect
            qType={'multi'}
            options={config.data.questions.multi.presentationTypes}
            value={value.presentationTypes}
            onChange={presentationTypes => this.updateValue({
              ...value,
              presentationTypes,
            })}
          />
        </div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.ranked'){
      return <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-column justify-content-center">
          <span>Rotowanie odp</span>
          <Switch className="mx-auto" stickToProp checked={value.rotateAnswers} onChange={rotateAnswers => this.updateValue({
            ...value,
            rotateAnswers,
          })} />
        </div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.open'){
      return <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-column justify-content-center mr-2">
          <span>Max liczba znaków</span>
          <Switch className="mx-auto" stickToProp checked={value.charsLengthMax} onChange={charsLengthMax => this.updateValue({
            ...value,
            charsLengthMax,
          })} />
        </div>
        <div className="d-flex flex-column justify-content-center mr-2">
          <span>E-mail po ankiecie</span>
          <Switch className="mx-auto" stickToProp checked={value.sendEmail} onChange={sendEmail => this.updateValue({
            ...value,
            sendEmail,
          })} />
        </div>
        <div style={{width: '250px'}}><PresentationTypeSelect
          qType={'open'}
          options={config.data.questions.open.presentationTypes}
          value={value.presentationTypes}
          onChange={presentationTypes => this.updateValue({
            ...value,
            presentationTypes,
          })}
        /></div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.multiOpen'){
      return <div className="d-flex flex-row align-items-center">
        <div style={{width: '250px'}}><PresentationTypeSelect
          qType={'multiOpen'}
          options={config.data.questions.multiOpen.presentationTypes}
          value={value.presentationTypes}
          onChange={presentationTypes => this.updateValue({
            ...value,
            presentationTypes,
          })}
        /></div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.numeric'){
      return <div className="d-flex flex-row align-items-center">
        <div style={{width: '250px'}}><PresentationTypeSelect
          qType={'numeric'}
          options={config.data.questions.numeric.presentationTypes}
          value={value.presentationTypes}
          onChange={presentationTypes => this.updateValue({
            ...value,
            presentationTypes,
          })}
        /></div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.matrix'){
      return <div className="d-flex flex-row align-items-center">
        <div style={{width: '250px'}}><PresentationTypeSelect
          qType={'matrix'}
          options={config.data.questions.matrix.presentationTypes}
          value={value.presentationTypes}
          onChange={presentationTypes => this.updateValue({
            ...value,
            presentationTypes,
          })}
        /></div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.matrixMulti'){
      return <div className="d-flex flex-row align-items-center">
        <div style={{width: '250px'}}><PresentationTypeSelect
          qType={'matrixMulti'}
          options={config.data.questions.matrixMulti.presentationTypes}
          value={value.presentationTypes}
          onChange={presentationTypes => this.updateValue({
            ...value,
            presentationTypes,
          })}
        /></div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.numericMulti'){
      return <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-column justify-content-center">
          <span>Rotowanie odp</span>
          <Switch className="mx-auto" stickToProp checked={value.rotateAnswers} onChange={rotateAnswers => this.updateValue({
            ...value,
            rotateAnswers,
          })} />
        </div>
        <div className="ml-2" style={{width: '250px'}}>
          <PresentationTypeSelect
            qType={'numericMulti'}
            options={config.data.questions.numericMulti.presentationTypes}
            value={value.presentationTypes}
            onChange={presentationTypes => this.updateValue({
              ...value,
              presentationTypes,
            })}
          />
        </div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.basket'){
      return <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-column justify-content-center">
          <span>Rotowanie odp</span>
          <Switch className="mx-auto" stickToProp checked={value.rotateAnswers} onChange={rotateAnswers => this.updateValue({
            ...value,
            rotateAnswers,
          })} />
        </div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.maxDiff'){
      return <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-column justify-content-center">
          <span>Rotowanie odp</span>
          <Switch className="mx-auto" stickToProp checked={value.rotateAnswers} onChange={rotateAnswers => this.updateValue({
            ...value,
            rotateAnswers,
          })} />
        </div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.matrixDropdown'){
      return <div className="d-flex flex-row align-items-center">
        <div className="d-flex flex-column justify-content-center">
          <span>Rotowanie odp</span>
          <Switch className="mx-auto" stickToProp checked={value.rotateAnswers} onChange={rotateAnswers => this.updateValue({
            ...value,
            rotateAnswers,
          })} />
        </div>
        <div className="ml-2" style={{width: '250px'}}>
          <PresentationTypeSelect
            qType={'matrixDropdown'}
            options={config.data.questions.matrixDropdown.presentationTypes}
            value={value.presentationTypes}
            onChange={presentationTypes => this.updateValue({
              ...value,
              presentationTypes,
            })}
          />
        </div>
      </div>
    }

    if(this.props.path === 'survey.questionSettings.differential'){
      return <div className="d-flex flex-row align-items-center">
        <div className="ml-2" style={{width: '250px'}}>
          <PresentationTypeSelect
            qType={'differential'}
            options={config.data.questions.differential.presentationTypes}
            value={value.presentationTypes}
            onChange={presentationTypes => this.updateValue({
              ...value,
              presentationTypes,
            })}
          />
        </div>
      </div>
    }


    if(this.props.path === 'collectorPanelUsersExcluded'){
      return <div className="d-flex flex-row align-items-center">
        <div style={{width: '250px'}}><PresentationTypeSelect
            onChange={presentationTypes => this.updateValue({
              ...value,
              presentationTypes,
            })}
        /></div>
      </div>
    }

    return (
      <span>__INNE__: {this.props.path}</span>
    );
  }
}

PlanSettingValue.propTypes = {};

function mapStateToProps(state) {
  return {
    config: state.config,
  }
}

export default connect(mapStateToProps)(PlanSettingValue);
