import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {has} from "lodash";
import {Alert, Col, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import api from "../../../api";

class RespondentForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      saving: false,
      errors: [],
    };

    this.startSaving = this.startSaving.bind(this);
    this.stopSaving = this.stopSaving.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.save = this.save.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  startSaving(){
    this.setState({
      saving: true,
    });

    this.props.onSavingStart();
  }

  stopSaving(){
    this.setState({
      saving: false,
    });

    this.props.onSavingStop();
  }

  onSubmit(e) {
    e.preventDefault();

    this.save();
  }

  save() {
    const {data} = this.state;

    this.startSaving();

    if(this.props.data.id){
      api.respondentSmsBases.respondents.edit(this.props.data.id, data)
      .then(data => {
        this.stopSaving();
        this.props.onSaved(data.respondentSmsBase);
      })
      .catch(res => {
        this.stopSaving();

        this.setState({
          errors: res.response.data.errors
        })
      });

    }else{
      api.respondentSmsBases.respondents.add(this.props.baseId, data)
      .then(data => {
        this.stopSaving();
        this.props.onSaved(data.respondentSmsBase);
      })
      .catch(res => {
        this.stopSaving();

        this.setState({
          errors: res.response.data.errors
        })
      });

    }
  }

  onChange(e){
    const {data} = this.state;

    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value
      }
    })
  }

  render() {
    const {data, errors} = this.state;

    return <React.Fragment>
      {has(errors, 'errors') && <Alert color="danger">{errors.errors.join('; ')}</Alert>}
      <Form onSubmit={this.onSubmit}>
        <FormGroup row className="mb-1">
          <Label md={4}>Telefon</Label>
          <Col md={8}>
            <Input type="text" invalid={!!has(errors, 'children.phone.errors')} className="mb-0" name="phone" onChange={this.onChange} value={data.phone} />
            {has(errors, 'children.phone.errors') && <FormFeedback>{errors.children.phone.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-1">
          <Label md={4}>Kolumna 1</Label>
          <Col md={8}>
            <Input type="text" invalid={!!has(errors, 'children.column_1.errors')} className="mb-0" name="column_1" onChange={this.onChange} value={data.column_1} />
            {has(errors, 'children.column_1.errors') && <FormFeedback>{errors.children.column_1.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
      </Form>
    </React.Fragment>;
  }
}

RespondentForm.defaultProps = {
  data: {
    phone: '',
    column_1: '',
  },
};

RespondentForm.propTypes = {
  baseId: PropTypes.number,
  data: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onSavingStart: PropTypes.func.isRequired,
  onSavingStop: PropTypes.func.isRequired,
};

export default RespondentForm;