import React, {Component} from 'react';
import PropTypes from "prop-types";
import {NavLink, Route, Switch, withRouter} from "react-router-dom";
import routes from "appRoutes";
import QuestionSummaries from "./QuestionSummaries/QuestionSummaries";
import Browse from "./Browse/Browse";
import ViewExportModal from "./ViewExportModal";
import ShareModal from "./Share/ShareModal";
import {FormattedMessage} from 'react-intl';
import {
  ButtonGroup, DropdownMenu, DropdownItem,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Nav,
  NavItem,
  TabContent, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import HelpModeIconModal from "components/HelpModeIconModal";
import {connect} from "react-redux";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {updateAnalysis} from "../../reducer/analysis/actions";
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown";
import api from "api";
import Modify from "./Modify";
import Advanced from "./Advanced";
import DataProcessing from "./DataProcessing/DataProcessing";
import ResponsesOperations from "./ResponsesOperations/ResponsesOperations";
import "./style.scss";
import Button from "../../../../../components/Button";
import Tooltip from "../../../../../components/Tooltip";
import moment from "moment";
import checkIsGroupRole from "../../../../../utils/checkIsGroupRole";
import {
  Packer
} from "docx";
import { saveAs } from 'file-saver';
import questionsSummaryToDocxConverter from "../../../../../utils/converters/questionsSummaryToDocxConverter";
import Checkbox from "../../../../../components/Checkbox";
import questionsSummaryToPptxConverter from "../../../../../utils/converters/questionsSummaryToPptxConverter";

export const ButtonPrint = (props) => {
  const [modal, setModal] = React.useState(false);
  const toggleModal = () => setModal(!modal);
  const [printing, setPrinting] = React.useState(false);
  const [allowTables, setAllowTables] = React.useState(false);
  const [docLoading, setDocLoading] = React.useState(false);
  const [pptxLoading, setPptxLoading] = React.useState(false);
  const {summaries, analysis, survey} = props;

  const id = 'print-pdf';

  const printDoc = async () => {
    setDocLoading(true);
    let questionSummaries = [];
    props.revealAllQuestions();

    for (let i = 0; i < summaries.length; i++) {
      questionSummaries.push(await api.analysis.questionSummary.get(summaries[i].id));
    }

    setTimeout(async () => {
      const document = await questionsSummaryToDocxConverter(questionSummaries, survey)

      Packer.toBlob(document).then((blob) => {
        saveAs(blob, survey.name + ".docx");
      });

      setDocLoading(false);
    }, 300 * summaries.length)
  };

  const printPptx = async () => {
    setPptxLoading(true);
    let questionSummaries = [];

    props.revealAllQuestions();
    for (let i = 0; i < summaries.length; i++) {
      questionSummaries.push(await api.analysis.questionSummary.get(summaries[i].id));
    }
    setTimeout(() => {

      const pptx = questionsSummaryToPptxConverter(questionSummaries, survey)

      pptx.writeFile({ fileName: survey.name + '.pptx' });

      setPptxLoading(false);
    }, 300 * summaries.length)
  };

  const printPdf = () => {
    let title = document.title;
    setPrinting(true);
    document.title = title + ' - ' + moment().format('YYYY-MM-DD HH:mm');
    setTimeout(() => {
      toggleModal();
      if (props.print) {
        props.print(allowTables);
      } else {
        window.print();
      }
      setPrinting(false);
      document.title = title;
    }, 200);
  };

  return <React.Fragment>
    <Button id={id} onClick={toggleModal} className={['ml-2']}><i className="fas fa-download" /></Button>
    <Tooltip id={id} msg={"Drukuj / Pobierz"} />
    {modal && <Modal isOpen={true} toggle={toggleModal} size="xl">
      <ModalHeader tag="h2" toggle={toggleModal}><i className="fas fa-download"/> Pobierz / Drukuj</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <p>Po kliknięciu poniższego przycisku "Drukuj", na oknie drukowania w opcji "Urządzenie docelowe" wybierz opcję "Zapisz jako PDF" w celu pobrania raportu do pliku PDF.</p>
        <p>Zalecamy korzystanie z przeglądarki typu Chrome</p>
        <p>Uwaga: W zależności od zawartości analizy - ekran drukowania może się pojawić po kilkudziesięciu sekundach.</p>
        <Checkbox id={"allowTable"} checked={allowTables} onClick={() => setAllowTables(!allowTables)} text={"Czy mają się wyświetlać tabele?"} messageId={"Czy mają się wyświetlać tabele?"} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
              <Button color="secondary" className={['mb-0']} onClick={toggleModal}>{message}</Button>
        }</FormattedMessage>
        {survey && <Button
            loading={pptxLoading}
            onClick={printPptx}
        >Pobierz jako pptx</Button>}
        {survey && <Button
            loading={docLoading}
            onClick={printDoc}
        >Pobierz jako doc</Button>}
        <Button
            loading={printing}
            onClick={printPdf}
        >Drukuj</Button>
      </ModalFooter>
    </Modal>}
  </React.Fragment>;
};

class View extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      editing: false,
      errors: {},
      data: {
        name: this.props.analysis.name || '',
      },
      exportModal: false,
      shareModal: false
    };

    this.toggleEditing = this.toggleEditing.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({
      data: {
        name: e.target.value
      }
    });
  }

  toggle(property) {
    this.setState({
      [property]: !this.state[property]
    });
  }

  toggleEditing(){
    this.setState({
      editing: !this.state.editing
    });
  }

  cancelEditing(){
    this.setState({
      editing: false,
      errors: {},
      data: {
        name: this.props.analysis.name || ''
      }
    });
  }

  onSubmit() {
    this.setState({saving: true, errors: {}});

    this.props.updateAnalysis({
      id: this.props.analysis.id,
      analysis: {
        name: this.state.data.name
      }
    }).then(() => this.setState({saving: false, editing: false}))
        .catch(e => this.setState({saving: false, errors: e.response.data.form.errors}));
  }

  isAnalysis(pathname) {
    const split = pathname.split('/');
    const last = split[split.length - 1];

    return isNaN(parseInt(last)) === false;
  }

  render() {
    const {analysis, plan, userGroup, user} = this.props;
    const {errors} = this.state;
    const {pathname} = this.props.location;
    const isAnalysis = this.isAnalysis(pathname)

    return (
        <div className="d-flex flex-column flex-grow pb-lg-1" style={{
          height: '100%',
          margin: '0'
        }}>
          <div className="align-items-center pt-lg-2 d-none d-sm-flex d-print-none">
            {this.props.sidebarView !== 'hide' && <a className="active d-none d-lg-inline ml-0" onClick={() => this.props.handleSidebarRendering(this.props.sidebarView === 'normal' ? 'hide' : 'normal')}>
              <span className="fas fa-arrow-left text-dark p-1 pb-2"> </span>
            </a>}
            {this.props.sidebarView !== 'huge' && <a className="active d-none d-lg-inline ml-0" onClick={() => this.props.handleSidebarRendering(this.props.sidebarView === 'normal' ? 'huge' : 'normal')}>
              <span className="fas fa-arrow-right text-dark p-1 pb-2"> </span>
            </a>}

            {this.state.editing ? <InputGroup className="py-0 my-2 w-100 w-md-50">
                  <Input value={this.state.data.name} onChange={this.onChange}/>
                  <InputGroupAddon addonType="append" className="m-0">
                    <ButtonGroup>
                      <FormattedMessage id="_.button.save">
                        {msg => <LaddaButton
                            className="btn btn-primary btn-ladda float-right py-1"
                            loading={this.state.saving}
                            onClick={this.onSubmit}
                            data-style={ZOOM_OUT}
                        >
                          {msg}
                        </LaddaButton>}
                      </FormattedMessage>
                      <Button
                          className="py-1"
                          color="secondary"
                          onClick={this.cancelEditing}
                      >
                        <FormattedMessage id="_.button.cancel"/>
                      </Button>
                    </ButtonGroup>
                  </InputGroupAddon>
                </InputGroup> :
                <h2 className="ml-1" onClick={this.toggleEditing}>{analysis.name} <i className="fas fa-edit" /></h2>}

            <div className="ml-auto d-flex justify-content-end">
              <div className="direction-rightm">
                <HelpModeIconModal modalHeader="Analiza" className="float-left mt-2 mr-3 mr-lg-0 pt-1 pt-lg-0">
                  <p>Analiza pozwala obejrzeć wyniki wypełnień ankiety</p>
                </HelpModeIconModal>

                <div className="d-none d-lg-flex">
                  {checkIsGroupRole('accessExportAnalysis', user, userGroup) && <Button onClick={() => this.toggle('exportModal')} color="primary" className="ml-3 float-left" > <i className="fas fa-download" />  <FormattedMessage id="analysis.sidebar.view.exports"/></Button>}
                  <Button onClick={() => this.toggle('shareModal')} color="primary" className="ml-2"><i className="fas fa-share"/> <FormattedMessage id="view.shareButton.button"/></Button>
                  {isAnalysis === false && <ButtonPrint />}
                </div>
                <div className="d-flex d-lg-none">
                  <UncontrolledDropdown>
                    <DropdownToggle className="m-0 p-0" color="">
                      <span className="fas fa-ellipsis-v hoverable" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => this.toggle('exportModal')} tag="a"><FormattedMessage id="analysis.sidebar.view.exports"/></DropdownItem>
                      <DropdownItem onClick={() => this.toggle('shareModal')} tag="a"><FormattedMessage id="view.shareButton.button"/></DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <ViewExportModal isOpen={this.state.exportModal} toggle={() => this.toggle('exportModal')}/>
              <ShareModal analysisId={analysis.id} isOpen={this.state.shareModal} toggle={() => this.toggle('shareModal')}/>
            </div>
          </div>
          {errors.name && <p className="help-block text-danger">{errors.name.join('; ')}</p>}

          <Nav tabs className="pt-2 pt-sm-0 d-print-none">
            <NavItem>
              <NavLink className="nav-link nav-item" exact to={routes.survey.analysis.questionSummaries(analysis.survey, analysis.id)}>
                <i className="fas fa-chart-pie" />
                <FormattedMessage id="analysis.sidebar.view.questionSummaries">{msg => <span className="d-none d-lg-inline ml-3">{msg}</span>}</FormattedMessage>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link nav-item" to={routes.survey.analysis.browse(analysis.survey, analysis.id)}>
                <i className="fas fa-user-check" />
                <FormattedMessage id="analysis.sidebar.view.responses">{msg => <span className="d-none d-lg-inline ml-3">{msg}</span>}</FormattedMessage>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link nav-item" exact to={routes.survey.analysis.modify(analysis.survey, analysis.id)}>
                <i className="fas fa-database" />
                <FormattedMessage id="analysis.sidebar.view.modify">{msg => <span className="d-none d-lg-inline ml-3">{msg}</span>}</FormattedMessage>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link nav-item" exact to={routes.survey.analysis.advanced(analysis.survey, analysis.id)}>
                <i className="fas fa-chart-line" />
                <FormattedMessage id="analysis.sidebar.view.advanced">{msg => <span className="d-none d-lg-inline ml-3">{msg}</span>}</FormattedMessage>
              </NavLink>
            </NavItem>
            {plan.analysis.dataProcessing && <NavItem>
              <NavLink className="nav-link nav-item" exact
                  to={routes.survey.analysis.dataProcessing(analysis.survey, analysis.id)}>
                <i className="fas fa-chart-line"/>
                <FormattedMessage id="analysis.sidebar.view.dataProcessing">{msg => <span
                    className="d-none d-lg-inline ml-3">{msg}</span>}</FormattedMessage>
              </NavLink>
            </NavItem>}
            {plan.analysis.responsesOperations && <NavItem>
              <NavLink className="nav-link nav-item" exact to={routes.survey.analysis.responsesOperations(analysis.survey, analysis.id)}>
                <i className="fas fa-user-slash"/>
                <FormattedMessage id="analysis.sidebar.view.responsesOperations">{msg => <span className="d-none d-lg-inline ml-3">{msg}</span>}</FormattedMessage>
              </NavLink>
            </NavItem>}
          </Nav>

          <TabContent className="tab-body p-0 d-flex flex-column" id="analysis-tab-content">
            <Switch>
              <Route path={routes.survey.analysis.questionSummaries()} exact component={QuestionSummaries} />
              <Route path={routes.survey.analysis.browse()} exact component={Browse}/>
              <Route path={routes.survey.analysis.browseResponse()} exact component={Browse}/>
              <Route path={routes.survey.analysis.modify()} exact component={Modify}/>
              <Route path={routes.survey.analysis.advanced()} exact component={Advanced}/>
              {plan.analysis.dataProcessing && <Route path={routes.survey.analysis.dataProcessing()} exact component={DataProcessing}/>}
              {plan.analysis.responsesOperations && <Route path={routes.survey.analysis.responsesOperations()} exact component={ResponsesOperations}/>}
            </Switch>
          </TabContent>
        </div>
    )
  }
}

View.propTypes = {
  handleSidebarRendering: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
    plan: state.user.userPlan.plan,
    userGroup: state.user.userGroups,
    user: state.user,
  }
}

export default withRouter(connect(mapStateToProps, {updateAnalysis})(View));
