import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnyChart from "anychart-react";
import api from "api";
import LoadingSpinner from "../../../../../components/LoadingSpinner";

class ResponsesChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {},
    };

    this.getChart = this.getChart.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    setTimeout(() => {
      this.fetchData();
    }, 10);
  }

  fetchData(){
    const {dateFrom, dateTo} = this.props;
    this.setState({
      loading: true,
    });

    api.account.stats.responses({
      dateFrom,
      dateTo,
    })
      .then(res => {
        this.setState({
          loading: false,
          data: res.data,
        });
      })
      .catch(e => {
        this.setState({
          loading: false,
        })
      })
  }

  getChart(){
    const {data} = this.state;
    let stage = anychart.graphics.create();
    let chart = anychart.line();
    chart.legend(false);
    chart.title('Liczba wywiadów');

    chart.yScale().stackMode('value');
    chart.yScale().ticks().allowFractional(false);
    chart.yAxis().title('Liczba wywiadów');

    let series = chart.area(data);

    series.name('Liczba wywiadów');

    return <AnyChart instance={stage} charts={[chart]} height={550}/>
  }

  render() {
    const {loading} = this.state;

    if(loading){
      return <LoadingSpinner classNames="py-5 my-5" />
    }

    return this.getChart();
  }
}

ResponsesChart.propTypes = {};

export default ResponsesChart;