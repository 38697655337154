import React, {Component} from 'react';
import {Form, Label, Input, Col, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

class GroupForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: ''
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    const {errors} = this.props;

    return (
      <Form onSubmit={e => this.props.onSubmit(e, this.state)}>
        <Row>
          <Label lg={4} className="text-lg-right" for="name"><FormattedMessage id="groupForm.label.name" /></Label>
          <Col lg={8}>
            <Input type="text" name="name" id="name" value={this.state.name} onChange={this.onChange} invalid={!!errors.name}/>
            {errors.name && <div className="invalid-feedback">{errors.name.join('; ')}</div>}
          </Col>
        </Row>
      </Form>
    )
  }
}

GroupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};


export default GroupForm;