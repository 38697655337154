import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Form, Nav, NavItem, TabContent, NavLink, TabPane} from "reactstrap";
import TabGeneral from "./Tabs/TabGeneral";
import TabQuestionSettings from "./Tabs/TabQuestionSettings";
import {FormattedMessage} from "react-intl";

class PlanForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: props.plan,
      activeTab: 'general'
    };

    this.toggle = this.toggle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeNested = this.onChangeNested.bind(this);
    this.onChangePoints = this.onChangePoints.bind(this);
    this.onChangeQuestionSettings = this.onChangeQuestionSettings.bind(this);
  }

  toggle(tab) {
    this.setState({activeTab: tab})
  }

  onChange(key, value){
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      }
    })
  }

  onChangeNested(nestedName, key, value){
    this.setState({
      data: {
        ...this.state.data,
        [nestedName]: {
          ...this.state.data[nestedName],
          [key]: value,
        }
      }
    })
  }

  onChangePoints(key, value){
    this.setState({
      data: {
        ...this.state.data,
        survey: {
          ...this.state.data.survey,
          points: {
            ...this.state.data.survey.points,
            [key]: value,
          }
        }
      }
    })
  }

  onChangeQuestionSettings(key, value){
    this.setState({
      data: {
        ...this.state.data,
        survey: {
          ...this.state.data.survey,
          questionSettings: {
            ...this.state.data.survey.questionSettings,
            [key]: value,
          }
        }
      }
    })
  }

  onSubmit(e){
    e.preventDefault();

    this.props.handleSubmit();

    return false;
  }

  render() {
    const {data} = this.state;
    const {errors} = this.props;

    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink className="nav-link float-left" active={this.state.activeTab === 'general'} onClick={() => { this.toggle('general'); }}>
              <i className="fas fa-cog" />
             <span className="d-none d-lg-inline ml-2"><FormattedMessage id="plans.planForm.navLink.general"/></span>
            </NavLink>
            <NavLink className="nav-link float-left" active={this.state.activeTab === 'questionSettings'} onClick={() => { this.toggle('questionSettings'); }}>
              <i className="fas fa-question" />
             <span className="d-none d-lg-inline ml-2"><FormattedMessage id="plans.planForm.navLink.questionSettings"/></span>
            </NavLink>
          </NavItem>
        </Nav>
        <Form onSubmit={this.onSubmit}>
          <TabContent className="tab-body" activeTab={this.state.activeTab}>
            <TabPane tabId="general">
              <TabGeneral
                errors={errors}
                data={data}
                onChange={this.onChange}
                onChangeNested={this.onChangeNested}
                onChangePoints={this.onChangePoints}
              />
            </TabPane>
            <TabPane tabId="questionSettings">
              <TabQuestionSettings
                errors={errors}
                data={data}
                onChangeQuestionSettings={this.onChangeQuestionSettings}
              />
            </TabPane>
          </TabContent>
        </Form>
      </React.Fragment>
    );
  }
}

PlanForm.defaultProps = {
  plan: {
    name: '',
    onSale: false,
    discountable: false,
    requiredPasswordChange: false,
    passwordStrong: false,
    cint: false,
    surveysAutoRefresh: false,
    surveysBreakWarning: false,
    surveySwitcherCheck: false,
    surveySendEmailStats: false,
    surveysCategories: false,
    panelActiveUsers: false,
    demo: false,
    availableForRespondents: false,
    availableForCompanies: false,
    clients: false,
    price: 10000,
    quarterlyPrice: 30000,
    annualPrice: 120000,
    surveysMax: 0,
    surveysActiveMax: 0,
    widgets: false,
    survey: {
      collectorsMax: 0,
      collectorsTokensMax: 0,
      analysesMax: 0,
      responsesMax: 0,
      dateEndMax: 0,
      cookieBlockade: false,
      ipBlockade: false,
      languages: false,
      topSurveys: false,
      baseSurveys: false,
      piping: false,
      filters: false,
      filtersFromTextarea: false,
      quickFilterScreenoutFromAnswer: false,
      quotas: false,
      globalQuestionTemplates: false,
      ownQuestionsBase: false,
      textsOverride: false,
      allowRespondentsToHideSurveyOwner: false,
      collectorEmailMailFooterEdit: false,
      nameWork: false,
      historyChange: false,
      cafeteryDisable: false,
      surveyTemplates: false,
      panelPromoteAvailable: false,
      insidePanel: false,
      weights: false,
      inaccessibilityText: false,
      userQuestionTemplates: false,
      userBlockTemplates: false,
      sets: false,
      blocks: false,
      pages: false,
      accessDaysAfterCreation: 0,
      questionsAvailable: [],
      redirects: false,
      pause: false,
      limit: false,
      invitationRedirect: false,
      switcherGuid: false,
      integrations: false,
      googleAdsense: false,
      clientRequired: false,
      excludings: false,
      reportEnable: false,
      surveyResponseFakerEnable: false,
      reportFromSystem: false,
      points: {
        socialMinPoints: 0,
        socialMaxPoints: 0,
        cashMinPoints: 0,
        cashMaxPoints: 0,
        screenOutSocialMinPoints: 0,
        screenOutSocialMaxPoints: 0,
        screenOutCashMinPoints: 0,
        screenOutCashMaxPoints: 0,
        allowedToChangeSocialPoints: false,
        allowedToChangeCashPoints: false,
        allowedToChangeSocialScreenOutPoints: false,
        allowedToChangeCashScreenOutPoints: false,
      },
      questionSettings: {
        disabled: false,
        fileUpload: false,
        supplementMax: false,
        randCafetery: false,
        minMaxCafetery: false,
        openRegex: false,
        single: {
          rotateAnswers: false,
          presentationTypes: []
        },
        multi: {
          rotateAnswers: false,
          presentationTypes: []
        },
        ranked: {
          rotateAnswers: false,
          presentationTypes: []
        },
        maxDiff: {
          rotateAnswers: false,
          presentationTypes: []
        },
        numericMulti: {
          rotateAnswers: false,
          presentationTypes: []
        },
        basket: {
          rotateAnswers: false,
          presentationTypes: []
        },
        matrixDropdown: {
          rotateAnswers: false,
          presentationTypes: []
        },
        differential: {
          presentationTypes: []
        },
        matrix: {
          presentationTypes: []
        },
        matrixMulti: {
          presentationTypes: []
        },
        numeric: {
          presentationTypes: []
        },
        open: {
          presentationTypes: [],
          charsLengthMax: false
        },
      }
    },
    analysis: {
      exportTypes:  [],
      exportResponseTime: false,
      filtering: false,
      demographics: false,
      editableSurveyResponses: false,
      deletableSurveyResponses: false,
      dataProcessing: false,
      responsesOperations: false,
      filterUserIds: false,
      summary: false,
      responsePreview: false,
      responseModify: false,
      advanced: false,
      dictionaries: false,
      transposition: false,
    },
    multimedia: {
      access: false,
      maxTotalSizeKb: 0,
      maxImageFileSizeKb: 0,
      maxAudioFileSizeKb: 0,
      maxVideoFileSizeKb: 0,
    },
    respondentBase: {
      basesMax:  0,
      baseAddressesMax:  0,
    },
  }
};

PlanForm.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    demo: PropTypes.bool.isRequired,
    availableForRespondents: PropTypes.bool.isRequired,
    availableForCompanies: PropTypes.bool.isRequired,
    onSale: PropTypes.bool.isRequired,
    discountable: PropTypes.bool.isRequired,
    requiredPasswordChange: PropTypes.bool.isRequired,
    passwordStrong: PropTypes.bool.isRequired,
    cint: PropTypes.bool.isRequired,
    surveysAutoRefresh: PropTypes.bool.isRequired,
    surveysBreakWarning: PropTypes.bool.isRequired,
    surveySwitcherCheck: PropTypes.bool.isRequired,
    surveySendEmailStats: PropTypes.bool.isRequired,
    surveysCategories: PropTypes.bool.isRequired,
    panelActiveUsers: PropTypes.bool.isRequired,
    clients: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    quarterlyPrice: PropTypes.number.isRequired,
    annualPrice: PropTypes.number.isRequired,
    surveysMax: PropTypes.number.isRequired,
    surveysActiveMax: PropTypes.number.isRequired,
    widgets: PropTypes.bool.isRequired,
    survey: PropTypes.shape({
      collectorsMax: PropTypes.number.isRequired,
      collectorsTokensMax: PropTypes.number.isRequired,
      analysesMax: PropTypes.number.isRequired,
      responsesMax: PropTypes.number.isRequired,
      dateEndMax: PropTypes.number.isRequired,
      cookieBlockade: PropTypes.bool.isRequired,
      ipBlockade: PropTypes.bool.isRequired,
      languages: PropTypes.bool.isRequired,
      baseSurveys: PropTypes.bool.isRequired,
      topSurveys: PropTypes.bool.isRequired,
      piping: PropTypes.bool.isRequired,
      filters: PropTypes.bool.isRequired,
      filtersFromTextarea: PropTypes.bool.isRequired,
      quickFilterScreenoutFromAnswer: PropTypes.bool.isRequired,
      quotas: PropTypes.bool.isRequired,
      globalQuestionTemplates: PropTypes.bool.isRequired,
      ownQuestionsBase: PropTypes.bool.isRequired,
      textsOverride: PropTypes.bool.isRequired,
      allowRespondentsToHideSurveyOwner: PropTypes.bool.isRequired,
      collectorEmailMailFooterEdit: PropTypes.bool.isRequired,
      nameWork: PropTypes.bool.isRequired,
      historyChange: PropTypes.bool.isRequired,
      cafeteryDisable: PropTypes.bool.isRequired,
      surveyTemplates: PropTypes.bool.isRequired,
      panelPromoteAvailable: PropTypes.bool.isRequired,
      insidePanel: PropTypes.bool.isRequired,
      weights: PropTypes.bool.isRequired,
      sets: PropTypes.bool.isRequired,
      blocks: PropTypes.bool.isRequired,
      pages: PropTypes.bool.isRequired,
      inaccessibilityText: PropTypes.bool.isRequired,
      userQuestionTemplates: PropTypes.bool.isRequired,
      userBlockTemplates: PropTypes.bool.isRequired,
      redirects: PropTypes.bool.isRequired,
      pause: PropTypes.bool.isRequired,
      limit: PropTypes.bool.isRequired,
      invitationRedirect: PropTypes.bool.isRequired,
      switcherGuid: PropTypes.bool.isRequired,
      integrations: PropTypes.bool.isRequired,
      googleAdsense: PropTypes.bool.isRequired,
      clientRequired: PropTypes.bool.isRequired,
      excludings: PropTypes.bool.isRequired,
      reportEnable: PropTypes.bool.isRequired,
      surveyResponseFakerEnable: PropTypes.bool.isRequired,
      reportFromSystem: PropTypes.bool.isRequired,
      accessDaysAfterCreation: PropTypes.number.isRequired,
      questionsAvailable: PropTypes.array.isRequired,
      points: PropTypes.shape({
        socialMinPoints: PropTypes.number.isRequired,
        socialMaxPoints: PropTypes.number.isRequired,
        cashMinPoints: PropTypes.number.isRequired,
        cashMaxPoints: PropTypes.number.isRequired,
        screenOutSocialMinPoints: PropTypes.number.isRequired,
        screenOutSocialMaxPoints: PropTypes.number.isRequired,
        screenOutCashMinPoints: PropTypes.number.isRequired,
        screenOutCashMaxPoints: PropTypes.number.isRequired,
        allowedToChangeSocialPoints: PropTypes.bool.isRequired,
        allowedToChangeCashPoints: PropTypes.bool.isRequired,
        allowedToChangeSocialScreenOutPoints: PropTypes.bool.isRequired,
        allowedToChangeCashScreenOutPoints: PropTypes.bool.isRequired,
      }).isRequired,
      questionSettings: PropTypes.shape({
        disabled: PropTypes.bool.isRequired,
        fileUpload: PropTypes.bool.isRequired,
        supplementMax: PropTypes.bool.isRequired,
        randCafetery: PropTypes.bool.isRequired,
        minMaxCafetery: PropTypes.bool.isRequired,
        openRegex: PropTypes.bool.isRequired,
        single: PropTypes.object.isRequired,
        multi: PropTypes.object.isRequired,
        ranked: PropTypes.object.isRequired,
        maxDiff: PropTypes.object.isRequired,
        numericMulti: PropTypes.object.isRequired,
        basket: PropTypes.object.isRequired,
        matrixDropdown: PropTypes.object.isRequired,
        differential: PropTypes.object.isRequired,
        matrix: PropTypes.object.isRequired,
        matrixMulti: PropTypes.object.isRequired,
        numeric: PropTypes.object.isRequired,
        open: PropTypes.object.isRequired,
      }).isRequired
    }).isRequired,
    analysis: PropTypes.shape({
      exportTypes: PropTypes.array.isRequired,
      exportResponseTime: PropTypes.bool.isRequired,
      filtering: PropTypes.bool.isRequired,
      demographics: PropTypes.bool.isRequired,
      editableSurveyResponses: PropTypes.bool.isRequired,
      deletableSurveyResponses: PropTypes.bool.isRequired,
      dataProcessing: PropTypes.bool.isRequired,
      responsesOperations: PropTypes.bool.isRequired,
      filterUserIds: PropTypes.bool.isRequired,
      summary: PropTypes.bool.isRequired,
      responsePreview: PropTypes.bool.isRequired,
      responseModify: PropTypes.bool.isRequired,
      advanced: PropTypes.bool.isRequired,
      dictionaries: PropTypes.bool.isRequired,
      transposition: PropTypes.bool.isRequired,
    }).isRequired,
    multimedia: PropTypes.shape({
      access: PropTypes.bool.isRequired,
      maxTotalSizeKb: PropTypes.number.isRequired,
      maxImageFileSizeKb: PropTypes.number.isRequired,
      maxAudioFileSizeKb: PropTypes.number.isRequired,
      maxVideoFileSizeKb: PropTypes.number.isRequired,
    }),
    respondentBase: PropTypes.shape({
      basesMax:  PropTypes.number.isRequired,
      baseAddressesMax:  PropTypes.number.isRequired,
    }).isRequired,
  }),
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default connect(null, null, null, {forwardRef: true})(PlanForm);
