import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup, Button} from 'reactstrap';
import PrizeCategoryDeleteButton from './PrizeCategoryDeleteButton';
import appRoutes from 'appRoutes';
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import Pagination from "components/Pagination";

class PrizeCategoriesList extends Component {

  constructor(props) {
    super(props);
    this.expandComponent = this.expandComponent.bind(this);
  }

  static isExpandableRow(row) {
    return row.children.length > 0;
  }

  static expandColumnComponent(isExpanded, category) {
    if (!PrizeCategoriesList.isExpandableRow(category))
      return null;

    return (<div> {isExpanded ? '-' : '+'} </div>);
  }

  expandComponent(category) {
    return (
      <PrizeCategoriesList prizeCategories={category.children} level={this.props.level + 1}/>
    );
  }

  static actions(category){
    return (
      <ButtonGroup>
        <Link to={appRoutes.admin.prizes.categories.edit(category.id)}>
          <Button color="primary" size="sm">
            <i className="fas fa-edit" /> <FormattedMessage id="_.button.edit" />
          </Button>
        </Link>
        <PrizeCategoryDeleteButton prizeCategory={category}/>
      </ButtonGroup>
    )
  }

  render() {
    let data = [];
    _.map(this.props.prizeCategories, category => category.level === this.props.level && data.push(category));

    const columns = [{
      Header: <FormattedMessage id="admin.prizeCategoriesList.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="admin.prizeCategoriesList.tableHeader.name" />,
      accessor: 'name'
    }, {
      Header: <FormattedMessage id="admin.prizeCategoriesList.tableHeader.slug" />,
      accessor: 'slug'
    }, {
      id: 'actions',
      Header: <FormattedMessage id="admin.prizeCategoriesList.tableHeader.actions" />,
      accessor: category => PrizeCategoriesList.actions(category),
      sortable: false
    }, {
      Header: <FormattedMessage id="admin.prizeCategoriesList.tableHeader.children" />,
      expander: true,
      Expander: ({isExpanded, original}) => PrizeCategoriesList.expandColumnComponent(isExpanded, original),
      getProps: (state, rowInfo) => {
        if (rowInfo) {
          if (!PrizeCategoriesList.isExpandableRow(rowInfo.original)) {
            return {
              onClick: () => {}
            }
          }
        }
        return {
          className: 'show-pointer'
        }
      },
      style: {
        fontSize: 25,
        padding: "0",
        textAlign: "center",
        userSelect: "none"
      }
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={data}
        columns={columns}
        showPagination={!_.isEmpty(data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.prizeCategoriesList.table.noPrizeCategories" />}
        defaultPageSize={10}
        SubComponent={({original}) => this.expandComponent(original)}
      />
    )
  }
}

export default PrizeCategoriesList;