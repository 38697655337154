import React, {Component} from 'react';
import PropTypes from "prop-types";
import api from "api";
import ApplicationTable from "../../../../components/Application/ApplicationTable";
import SearchTextInput from "../../../../components/Application/ApplicationTable/SearchTextInput";
import DictionaryWordSimilarAddModal from "./DictionaryWordSimilarAddModal";

class DictionaryWordSimilarsTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  render() {
    const columns = [
      {
        Header: 'Podobne słowo',
        accessor: 'text',
        width: 200,
      },
    ];

    return <React.Fragment>
      <div className="d-flex justify-content-between mb-2">
        <DictionaryWordSimilarAddModal wordId={this.props.wordId} onModalClose={() => {}} onAdded={() => this.tableRef.current.fetchDataDelay(1)} />

        <div className="ml-auto">
          <SearchTextInput
            tableRef={this.tableRef}
          />
        </div>
      </div>

      <ApplicationTable
        ref={this.tableRef}
        columns={columns}
        fetchData={api.dictionaryWordSimilars.search}
        deleteData={api.dictionaryWordSimilars.delete}
        filters={{
          word: this.props.wordId
        }}
      />
    </React.Fragment>;
  }
}

DictionaryWordSimilarsTable.propTypes = {
  wordId: PropTypes.number.isRequired,
};

export default DictionaryWordSimilarsTable;
