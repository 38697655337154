import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from 'reactstrap';
import CodeDeleteButton from "./CodeDeleteButton";
import CodeEditButton from "./CodeEditButton";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";

class CodesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
    this.actions = this.actions.bind(this);
  }

  static onlyAuth(code){
    return code.onlyAuth
      ? <FormattedMessage id="admin.codesList.listItems.onlyAuth.true" />
      : <FormattedMessage id="admin.codesList.listItems.onlyAuth.false" />;
  }

  actions(code){
    const handler = () => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});
    return (
      <ButtonGroup>
        <CodeEditButton code={code} handleChange={handler}/>
        <CodeDeleteButton code={code} handleChange={handler}/>
      </ButtonGroup>
    )
  }

  fetchData(state) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.code.post.searchCodes(data).then(res => {
      this.setState({searching: false, data: res.codes, count: res.count});
    });
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="admin.codesList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="admin.codesList.listItems.tableHeader.name" />,
      accessor: 'name'
    }, {
      Header: 'Cookie name',
      accessor: 'cookieName'
    }, {
      id: 'code',
      Header: <FormattedMessage id="admin.codesList.listItems.tableHeader.code" />,
      accessor: row => row.code.substr(0, 50),
    }, {
      id: 'places',
      Header: <FormattedMessage id="admin.codesList.listItems.tableHeader.places" />,
      accessor: row => row.places.join(', '),
    }, {
      id: 'onlyAuth',
      Header: <FormattedMessage id="admin.codesList.listItems.tableHeader.onlyAuth" />,
      accessor: code => CodesList.onlyAuth(code)
    }, {
      id: 'actions',
      Header: <FormattedMessage id="admin.codesList.listItems.tableHeader.actions" />,
      accessor: code => this.actions(code),
      sortable: false
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.codesList.listItems.table.noCodes" />}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

export default CodesList;
