import React, {Component} from 'react';
import QuestionElements from './components/QuestionElements';
import QuestionListElements from './components/QuestionListElements';
import PageElements from './components/PageElements';
import BlockElements from './components/BlockElements';
import SetElements from './components/SetElements';
import PropTypes from 'prop-types';
import "./style.scss"

class Elements extends Component {

  render() {
    return (
      <React.Fragment>
        <QuestionElements searchText={this.props.searchText}/>
        <QuestionListElements searchText={this.props.searchText}/>
        <PageElements searchText={this.props.searchText}/>
        <BlockElements searchText={this.props.searchText}/>
        <SetElements searchText={this.props.searchText}/>
      </React.Fragment>
    )
  }
}

Elements.propTypes = {
  searchText: PropTypes.string
};

export default Elements;