import update from 'immutability-helper';

import { SET, ADDED, UPDATED, DELETED } from './actions';
import * as questionsActions from "../questions/actions";

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.rows;

    case ADDED:
      return {
        ...state,
        [action.row.id]: action.row
      };

    case UPDATED:
      return update(state, {
        [action.row.id]: {
          $set: action.row
        }
      });
    case DELETED:
      return update(state, {
        $unset: [action.row.id]
      });

    case questionsActions.TYPE_UPDATED:
      const ids = [];

      _.each(state, row => {
        if (row.question.id === action.expiredId)
          ids.push(row.id);
      });

      return update(state, {
        $unset: ids
      });

    default:
      return state;
  }
};