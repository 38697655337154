import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from "striptags";

class ColumnTbody extends Component {
  getExcluding(excludingId){
    return this.props.question.excludings.find(excluding => excluding.id === parseInt(excludingId));
  }

  render() {
    const {questionResponse, question} = this.props;
    let detail = false;

    if(questionResponse){
      if(questionResponse && questionResponse.details){
        detail = questionResponse.details[0];
      }
    }

    return <React.Fragment>
      <td className="text-center border-left border-right">
        {detail ? detail.value : <i>Brak odpowiedzi</i>}
      </td>
      {question.excludings.length > 0 && <td className="text-center border-left border-right">
        {(questionResponse && questionResponse.excluding) ? <div className="" dangerouslySetInnerHTML={{__html: striptags(this.getExcluding(questionResponse.excluding).content)}} /> : ''}
      </td>}

    </React.Fragment>;
  }
}

ColumnTbody.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['nps']),
    excludings: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
  }).isRequired,
  questionResponse: PropTypes.shape({
    details: PropTypes.arrayOf(PropTypes.shape({

    }).isRequired,),
  }),
};

export default ColumnTbody;