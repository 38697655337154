import docxBuilder from "../builders/docxBuilder";
import {AlignmentType} from "docx";
import moment from "moment";

const iterateByType = (toIterate, ids, text, fallbackText, builder) => {
	if (!ids || ids.length === 0) {
		return;
	}

	let j = 1;
	builder.addText(text);
	ids.forEach((id) => {
		const entity = toIterate[id]
		builder.addText(entity.content ? (j + ": " + entity.content) : (j + fallbackText))
		j++;
	})
	builder.addText("")
}

const surveyStructureToDocxConverter = (survey) => {
	const surveyData = survey.structure.data.survey;
	const questions = survey.structure.data.questions;
	const answers = survey.structure.data.answers;
	const scalePoints = survey.structure.data.scalePoints;
	const columns = survey.structure.data.columns;
	const rows = survey.structure.data.rows;
	const baskets = survey.structure.data.baskets;
	const excludings = survey.structure.data.excludings;
	const builder = new docxBuilder();
	const createdAt = moment().locale("pl").format("LLL")

	builder.addHeading("Struktura ankiety: " + surveyData.name, 1, {alignment: AlignmentType.CENTER});
	builder.addHeading("")
	builder.addText(createdAt, {}, {alignment: AlignmentType.CENTER})
	builder.addHeading("")
	builder.addHeading("Treść zaproszenia:", 5);
	builder.addHeading("")
	builder.addText(surveyData.invitationText);
	builder.addHeading("")

	let i = 1;
	builder.addHeading("Pytania:", 3)
	builder.addHeading("")
	Object.values(questions).forEach((question) => {
		const questionType = document.querySelector("#question-header-" + question.id + " .d-print-block").innerHTML;
		builder.addText("Typ pytania: " + questionType)
		builder.addHeading(question.content ? ("P" + i + " " + question.content) : ("P" + i + " " + "Brak treści pytania"), 5)
		builder.addText("")
		iterateByType(baskets, question.baskets, "Lista Koszyków:", ": Brak treści odpowiedzi", builder)
		iterateByType(answers, question.answers, "Odpowiedzi:", ": Brak treści odpowiedzi", builder)
		iterateByType(scalePoints, question.scalePoints, "Punkty na skali:", ": Brak treści odpowiedzi", builder)
		iterateByType(rows, question.rows, "Lista wierszy:", ": Brak treści odpowiedzi", builder)
		iterateByType(columns, question.columns, "Lista Kolumn:", ": Brak treści odpowiedzi", builder)

		if (question.type === 'video') {
			builder.addText("Nazwa pliku video:")
			builder.addText(question.video);
			builder.addText("")
		}

		iterateByType(excludings, question.excludings, "Lista odpowiedzi wykluczających:", ": Brak treści odpowiedzi", builder)

		i++;
	})

	builder.addHeading("Ekrany zakończenia:", 5)
	builder.addHeading("")
	surveyData.endingScreens.forEach((endingScreen) => {
		builder.addText(endingScreen.name)
		builder.addText("")
		builder.addText(endingScreen.content)
	})
	builder.addHeading("")

	const filterTableTds = document.querySelectorAll(".d-print-block .ReactTable.table-wrap .rt-tbody .rt-td");
	if (filterTableTds.length > 0) {
		builder.addHeading("Lista filtrów", 5)
		builder.addHeading("")
		const matrix = [["ID", "Nazwa", "Warunki", "Akcje"]];
		let array = [];
		let filterIterate = 1;
		filterTableTds.forEach((element) => {
			array.push(element.innerHTML);

			if (array.length === 4) {
				filterIterate = 1;
				matrix.push(array)
				array = [];
			} else {
				filterIterate++;
			}
		})
		builder.addTable(matrix)
	}

	builder.createAndDownload(surveyData.name);
}

export default surveyStructureToDocxConverter;