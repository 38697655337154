import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ExportForm from "../../../../../components/Survey/Analysis/ExportForm";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

class ViewExportModal extends Component{
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };

    this.exportForm = React.createRef();
  }

  render() {
    const {analysis, isOpen, toggle} = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle} tag="h2">Eksportuj dane</ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <ExportForm analysis={analysis} ref={this.exportForm} setLoading={loading => this.setState({loading})} onEnd={() => this.props.toggle()}/>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={toggle}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.download">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda"
              loading={this.state.loading}
              data-style={ZOOM_OUT}
              onClick={() => this.exportForm.current.onSubmit()}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    )
  }
}

ViewExportModal.propTypes = {
  exportTypes: PropTypes.array.isRequired,
  analysis: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    exportTypes: state.user.userPlan.plan.analysis.exportTypes,
    analysis: state.survey.analysis.data.analysis
  }
}

export default connect(mapStateToProps) (ViewExportModal);
