import api from "api";
import _ from 'lodash';
import {normalize} from "normalizr";
import schemaUser from 'entities/user/schema';

export const REQUEST = 'Dashboard/data/widgets/REQUEST';
export const SUCCESS = 'Dashboard/data/widgets/SUCCESS';
export const ERROR = 'Dashboard/data/widgets/ERROR';

export const SET = 'Dashboard/data/widgets/SET';
export const UPDATED = 'Dashboard/data/widgets/UPDATED';
export const ADDED = 'Dashboard/data/widgets/ADDED';
export const DELETED = 'Dashboard/data/widgets/DELETED';

export function setWidgets(widgets) {
  return {
    type: SET,
    widgets
  }
}

export function widgetUpdated(widget) {
  return {
    type: UPDATED,
    widget
  }
}

export function widgetAdded(widget) {
  return {
    type: ADDED,
    widget
  }
}

export function widgetDeleted(widget) {
  return {
    type: DELETED,
    widget
  }
}

export function addWidget(data) {
  return dispatch => {
    dispatch({type: REQUEST});
    return api.user.post.widget(data).then(widget => { dispatch(widgetAdded(widget)); dispatch({type: SUCCESS}) }).catch(() => { dispatch({type: ERROR})});
  }
}

export function updateWidgets(data) {
  return dispatch => {
    dispatch({type: REQUEST});
    return api.widget.patch.widgets(data).then(user => {
      const normalized = normalize(user, schemaUser);

      _.map(normalized.entities.widgets, widget => {
        dispatch(widgetUpdated(widget));
      });

      dispatch({type: SUCCESS})
    }).catch(() => { dispatch({type: ERROR})});
  }
}

export function updateWidget(data) {
  return dispatch => {
    dispatch({type: REQUEST});
    return api.widget.patch.widget(data).then(widget => {dispatch(widgetUpdated(widget)); dispatch({type: SUCCESS})}).catch(() => {dispatch({type: ERROR})});
  }
}

export function deleteWidget(data) {
  return dispatch => {
    dispatch({type: REQUEST});
    return api.widget.delete(data).then(() => {dispatch(widgetDeleted({id: data.id})); dispatch({type: SUCCESS}) }).catch(() => { dispatch({type: ERROR})});
  }
}
