import * as React from 'react';
import {connect} from "react-redux";
import {addAttribute} from "../../../../../../../../../../../../../../../../../../../data/attributes/actions";
import Button from "components/Button";

function CreateAttribute(props) {
  const [creating, setCreating] = React.useState(false);
  const toggleCreating = () => setCreating(!creating);

  const createAttribute = () => {
    setCreating(true);
    props.addAttribute({
      question: props.question.id,
    })
    .then(() => {
      setCreating(false)
    })
    .catch(() => {
      setCreating(false)
    })
  }

  return <Button
    onClick={createAttribute}
    loading={creating}
  ><i className="fas fa-plus" /> Utwórz nowy atrybut</Button>;
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps, {addAttribute})(CreateAttribute);