import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import appRoutes from "../../../../appRoutes";
import {isEmpty} from "lodash";
import Tooltip from "../../../../components/Tooltip";

const PanelSendEmailInfo = props => {

  if(isEmpty(props.collector.sendEmail)){
    return <React.Fragment>
      <i id={`c-sendEmail-info-${props.collector.id}`} className="fas fa-envelope mr-1" />
      <Tooltip id={`c-sendEmail-info-${props.collector.id}`} msg={'Brak wysyłki e-mail'} />
    </React.Fragment>
  }


  return <React.Fragment>
    <Link to={appRoutes.survey.collectors.collector.panel.sendEmail(props.surveyId, props.collector.id)}><i id={`c-sendEmail-info-${props.collector.id}`} className="fas fa-envelope text-success mr-1" /></Link>
    <Tooltip id={`c-sendEmail-info-${props.collector.id}`} msg={'Wysyłka e-mail'} />
  </React.Fragment>
};

PanelSendEmailInfo.propTypes = {

};

export default PanelSendEmailInfo;