import React from 'react';
import PropTypes from 'prop-types';
import Notify from "./Notify";
import "./styles.scss";
import useDidMountEffect from "../../utils/useDidMountEffect";
import api from "../../api";
import {isEmpty} from "lodash";

const DashboardNotifies = props => {
  const [notifies, setNotifies] = React.useState([]);

  useDidMountEffect(() => {
    api.dashboardNotifies.getForUser()
      .then(data => setNotifies(data.data))
  })

  if(isEmpty(notifies)){
    return null;
  }

  return (
    <div id="dashboard-notifies" className="d-flex flex-column my-3">
      {notifies.map(notify => <Notify data={notify} onDisabled={() => setNotifies([
        ...notifies.filter(n => n.id !== notify.id)
      ])} />)}
    </div>
  );
};

DashboardNotifies.propTypes = {

};

export default DashboardNotifies;
