import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Card, CardBody, CardHeader, Col, InputGroup, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import DatePicker from "react-datepicker";
import moment from "moment";

class StartEndDates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data
    };
  }

  onChange(property, value){
    this.setState({
      data: {
        ...this.state.data,
        [property]: value
      }
    }, () => {
      this.props.onChange(property, this.state.data[property])
    });
  }

  render() {
    const {errors} = this.props;
    const {data} = this.state;

    return (
      <Col className="d-flex align-items-md-stretch" xs={this.props.xs} lg={this.props.lg}>
        <Card className="w-100">
          <CardHeader className="text-center"><FormattedMessage tagName="h2" id="formDefaultProperty.label.startEndAt" /></CardHeader>
          <hr className="my-0"/>
          <CardBody>
            <Row>
              <Col sm={6} lg={12} xl={6}>
                <InputGroup className={"mb-0" + (this.props.disabled ? " not-active" : "")}>
                  <div className="input-group-prepend" style={{height: 32}}><span className="input-group-text px-1" style={{fontSize: '1em'}}>Od</span></div>
                  <div className="my-auto border-0 ml-1">
                    <DatePicker
                      dropdownMode="select"
                      className="form-control w-100 mb-0"
                      isClearable={false}
                      selected={data.startAt ? moment(data.startAt) : null}
                      onChange={(date) => this.onChange('startAt', date.format('YYYY-MM-DD'))}
                      dateFormat="Y-MM-DD"
                      locale={localStorage.language}
                      disabled={this.props.disabled}
                    />
                  </div>
                </InputGroup>
                {errors.startAt && <div className="invalid-feedback d-block mt-0">{errors.startAt}</div>}
              </Col>
              <Col sm={6} lg={12} xl={6} className="mt-1 mt-sm-0 mt-lg-1 mt-xl-0">
                <InputGroup className={"mb-0" + (this.props.disabled ? " not-active" : "")}>
                  <div className="input-group-prepend" style={{height: 32}}><span className="input-group-text px-1" style={{fontSize: '1em'}}>Do</span></div>
                  <div className="my-auto border-0 ml-1">
                    <DatePicker
                      dropdownMode="select"
                      className="form-control w-100 mb-0"
                      isClearable={false}
                      selected={data.endAt ? moment(data.endAt) : null}
                      onChange={(date) => this.onChange('endAt', date.format('YYYY-MM-DD'))}
                      dateFormat="Y-MM-DD"
                      locale={localStorage.language}
                      disabled={this.props.disabled}
                    />
                  </div>
                </InputGroup>
                {errors.endAt && <div className="invalid-feedback d-block mt-0">{errors.endAt}</div>}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

StartEndDates.defaultProps = {
  disabled: false,
  errors: {},
  xs: 12,
  lg: 4,
};

StartEndDates.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    startAt: PropTypes.string,
    endAt: PropTypes.string,
  }).isRequired,
  errors: PropTypes.object.isRequired,
  xs: PropTypes.oneOf([1,2,3,4,5,6,7,8,9,10,11,12]).isRequired,
  lg: PropTypes.oneOf([1,2,3,4,5,6,7,8,9,10,11,12]).isRequired,
};

export default StartEndDates;