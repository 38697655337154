import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import CollectorsList from "../../Collectors/components/CollectorsList";
import api from 'api';
import qs from "query-string";
import {toast} from "react-toastify";
import {map} from "lodash";
import appRoutes from "appRoutes";
import {Card, CardHeader, CardBody, Alert} from "reactstrap";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import Button from "../../../../components/Button";
import Tooltip from "../../../../components/Tooltip";

const ButtonPossibilityRangeCheckAll = () => {
  const handlerOnClick = () => {
    const target = document.querySelector('.collector-panel-posibility-range-count-trigger');
    if(target){
      target.click();
    }
  }

  return <React.Fragment>
    <Button onClick={handlerOnClick} id="sc-panel-posibility-range-all"><i className="fas fa-users" /></Button>
    <Tooltip id="sc-panel-posibility-range-all" msg="Kliknij aby sprawdzić szacowany zasięg każdego kolektora" />
  </React.Fragment>
}

class CollectorsType extends Component {

  constructor(props) {
    super(props);
    const {location} = this.props;

    this.state = {
      collectors: [],
      loading: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.refreshCollectors = this.refreshCollectors.bind(this);
  }

  componentDidMount() {
    this.refreshCollectors();
  }

  handleChange(collectorId, property, value) {
    let collectors = this.state.collectors;
    let collectorIndex = collectors.indexOf(_.find(collectors, collector => collector.id === collectorId));

    collectors[collectorIndex][property] = value;
    this.setState({collectors});
  }

  handleCopy(collector) {
    let collectors = this.state.collectors;
    collectors.unshift(collector);

    this.props.history.push(appRoutes.survey.collectors.collector.view(this.props.match.params.survey, collector.id));

    this.setState({collectors});
  }

  handleDelete(collectorId) {
    const collectors = this.state.collectors.filter(collector => collector.id !== collectorId);
    this.setState({collectors});
  }

  refreshCollectors() {

    this.setState({loading: true}, () => {
      api.survey.get.collectors({id: this.props.match.params.survey}, {type: this.props.match.params.type})
        .then(collectors => this.setState({collectors, loading: false}))
        .catch(e => {
          toast.error(e.response.data.errors[0].message);
          this.props.history.push(appRoutes.surveys.list);
        });
    });
  }

  render() {
    const {loading} = this.state;

    const surveyId = this.props.match.params.survey;
    return (
      <main>
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <h2><FormattedMessage id="survey.surveyStructure.cardHeader.collectors" /></h2>

            <div className="d-flex">
              {this.props.match.params.type.toLowerCase() === 'panel' && <ButtonPossibilityRangeCheckAll />}

              <Link title="Pobierz dane z tabeli do pliku CSV" className="btn btn-primary ml-2" to={api.survey.get.collectorsCsv({id: this.props.match.params.survey}, {type: this.props.match.params.type})} target="_blank"><i className="fas fa-download" /></Link>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <CollectorsList handleOnChange={() => this.refreshCollectors()} type={this.props.match.params.type} surveyId={surveyId} collectors={this.state.collectors} loading={this.state.loading} handleCollectorChange={this.handleChange} handleCollectorDelete={this.handleDelete} history={this.props.history} handleCollectorCopy={this.handleCopy}/>
          </CardBody>
        </Card>
      </main>
    )
  }
}

export default CollectorsType;
