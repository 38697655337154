import React from 'react';
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Tooltip from "./Tooltip";
import uuid from "react-uuid";

const Button = props => {
  const [bId, setbId] = React.useState(props.tooltip ? 'b-' + uuid() : undefined);

  let classes = ['btn btn-ladda d-flex align-items-center justify-content-center'];
  classes.push('btn-' + props.color);
  if(props.className){
    classes.push(props.className);
  }

  classes.push('btn-' + props.size);

  return <React.Fragment>
    <LaddaButton
      id={bId || props.id}
      className={classes.join(' ')}
      loading={props.loading}
      data-style={ZOOM_OUT}
      onClick={props.onClick}
      disabled={props.disabled}
    >{props.children}</LaddaButton>
    {props.tooltip && <Tooltip msg={props.tooltip} id={bId || props.id} />}
  </React.Fragment>
};

Button.defaultProps = {
  loading: false,
  color: 'primary',
  onClick: null,
  className: '',
  size: 'md',
  disabled: false,
};

Button.propTypes = {
  id: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.string,
};

export default Button;
