import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage, injectIntl} from "react-intl";
import _ from "lodash";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import PreviewVideoButton from 'components/Buttons/PreviewVideoButton';
import reactHtmlParser from "react-html-parser";
import striptags from "striptags";

class QuestionSummaryVideo extends Component {

  render() {
    const {intl} = this.props;
    const {questionSummary} = this.props;
    const data = _.concat([
      {
        x: intl.formatMessage({ id: 'analysis.questionSummary.video.status.watched' }),
        value: questionSummary.data.data.watched
      },
      {
        x: intl.formatMessage({ id: 'analysis.questionSummary.video.status.notWatched' }),
        value: questionSummary.data.data.notWatched
      },
    ], questionSummary.data.excludings.map(e => {
      return {
        x: reactHtmlParser(striptags(e.content))[0],
        value: parseInt(e.value)
      }
    }));
    const dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <React.Fragment>
        <PreviewVideoButton question={questionSummary.question} className="mb-2"/>
        <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
      </React.Fragment>
    }

    return (
      <React.Fragment>
        {questionSummary.question.video && <p>Nazwa otwarzanego pliku {questionSummary.question.video}</p>}
        <PreviewVideoButton question={questionSummary.question}/>
        <Chart id={questionSummary.question.id} type={questionSummary.chartType} n={questionSummary.data.n} data={data}/>
        {this.props.table && <BootstrapTable
          className={'table-wrap w-lg-50 mx-auto mt-3'}
          data={data}
          striped hover
        >
          <TableHeaderColumn dataField='x' isKey={true}><FormattedMessage
            id="analysis.questionSummary.video.tableHeader.x"/></TableHeaderColumn>
          <TableHeaderColumn dataField='value'><FormattedMessage id="analysis.questionSummary.video.tableHeader.value"/></TableHeaderColumn>
        </BootstrapTable>}
      </React.Fragment>
    )
  }
}

QuestionSummaryVideo.defaultProps = {
  chart: true,
  table: true,
};

export default injectIntl(QuestionSummaryVideo);
