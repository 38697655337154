import React, {Component} from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {copyFilterSerial} from "scenes/Survey/Structure/data/filterSerial/actions";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";

class ButtonCopyFilterSerial extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      copying: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.copyFilter = this.copyFilter.bind(this);
  }

  toggleModal() {
    this.setState({modal: !this.state.modal})
  }

  copyFilter() {
    this.setState({copying: true});

    this.props.copyFilterSerial(this.props.filter.id)
    .then(() => this.setState({
      modal: false,
      copying: false,
    }))
    .catch(() => this.setState({copying: false}));
  }

  render() {
    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2">Utwórz kopię filtra</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p>Czy na pewno utworzyć kopię filtra?</p>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <LaddaButton
              className="btn btn-primary btn-ladda mb-0 ml-3"
              data-style={ZOOM_OUT}
              onClick={this.copyFilter}
              loading={this.state.copying}
            >Tak, utwórz kopie</LaddaButton>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <span onClick={this.toggleModal}><i className="fas fa-copy float-right pointer mr-2"/></span>
        {modal}
      </React.Fragment>
    )
  }
}

ButtonCopyFilterSerial.propTypes = {
  filter: PropTypes.object.isRequired
};

export default connect(null, {copyFilterSerial})(ButtonCopyFilterSerial);
