import React, {Component} from 'react';
import PropTypes from "prop-types";
import SettingNumber from "../../../SettingNumber";

class Cbc extends Component {

  render() {
    const {question} = this.props;

    return (
      <React.Fragment>
        <SettingNumber float question={question} name={'numberConcepts'} value={question.numberConcepts} icon={<i className="fas fa-long-arrow-alt-up" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber float question={question} name={'numberOptionsPerConcept'} value={question.numberOptionsPerConcept} icon={<i className="fas fa-long-arrow-alt-up" />} handleUpdate={this.props.handleUpdate}/>
      </React.Fragment>
    )
  }
}

Cbc.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default Cbc;