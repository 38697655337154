import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {deleteTemplate} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import {toast} from "react-toastify";

class SurveyTemplatesDeleteButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    }
  }

  templateDelete(){
    this.setState({
      deleting: true
    });

    this.props.deleteTemplate({
      id: this.props.template.id
    }).then(() => {
      this.props.handleChange();
      this.setState({deleting: false, modal: false});
      toast.success(<FormattedMessage id="admin.surveyTemplatesDeleteButton.toast.success" />);
    }).catch(() => {
      this.setState({
        deleting: false
      });
    });
  }

  toggleModalDelete(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { deleting } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModalDelete()}>
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2"><FormattedMessage id="admin.surveyTemplatesDeleteButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="admin.surveyTemplatesDeleteButton.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.modal.delete.confirmation">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={() => this.templateDelete()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModalDelete()}>{message}</Button>
        }</FormattedMessage>

      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <i className="fas fa-times pointer" onClick={() => this.toggleModalDelete()}/>
        {modal}
      </React.Fragment>
    )
  }
}

SurveyTemplatesDeleteButton.propTypes = {
  template: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default connect(null, { deleteTemplate })(SurveyTemplatesDeleteButton);