import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from "striptags";

class ColumnTbody extends Component {
  getAnswer(answerId){
    return this.props.question.answers.find(answer => answer.id === parseInt(answerId));
  }

  getExcluding(excludingId){
    return this.props.question.excludings.find(excluding => excluding.id === parseInt(excludingId));
  }

  render() {
    const {questionResponse, question} = this.props;
    let detailLeft = false;
    let detailRight = false;

    if(questionResponse){
      if(!questionResponse.excluding && questionResponse.details.find(detail => detail.value === 1)){
        detailLeft = questionResponse.details.find(detail => detail.value === 1);
      }
      if(!questionResponse.excluding && questionResponse.details.find(detail => detail.value === 2)){
        detailRight = questionResponse.details.find(detail => detail.value === 2);
      }
    }

    return <React.Fragment>
      <td className="text-center border-left border-right">
        {detailLeft ? <div className="" dangerouslySetInnerHTML={{__html: striptags(this.getAnswer(detailLeft.answer).content)}} /> : <i>Brak odpowiedzi</i>}
      </td>
      <td className="text-center border-left border-right">
        {detailRight ? <div className="" dangerouslySetInnerHTML={{__html: striptags(this.getAnswer(detailRight.answer).content)}} /> : <i>Brak odpowiedzi</i>}
      </td>
      {question.excludings.length > 0 && <td className="text-center border-left border-right">
        {(questionResponse && questionResponse.excluding) ? <div className="" dangerouslySetInnerHTML={{__html: striptags(this.getExcluding(questionResponse.excluding).content)}} /> : ''}
      </td>}

    </React.Fragment>;
  }
}

ColumnTbody.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['maxDiff']),
    excludings: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
  }).isRequired,
  questionResponse: PropTypes.shape({
    details: PropTypes.arrayOf(PropTypes.shape({

    }).isRequired,),
  }),
};

export default ColumnTbody;