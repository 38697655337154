import React, {Component} from 'react';
import {Alert, TabPane, Row, Col, Form, FormGroup, Label, Card, CardHeader, CardBody, CardFooter} from "reactstrap";
import DataProcessingTable from "../../../../../../componentsNew/DataProcessing/DataProcessingTable";
import Switch from "../../../../../../components/Switch";
import Button from "../../../../../../components/Button";
import api from "../../../../../../api";
import SelectQuestions from "../../../../Structure/components/SelectQuestions";
import Tooltip from "../../../../../../components/Tooltip";
import {toast} from "react-toastify";

class DataProcessing extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      processing: false,
      data: {
        ipRepeat: false,
        answersMatrixInLine: false,
        questions: [],
        responseTime: false,
      }
    };
  }

  onChange(key, value){
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      }
    })
  }

  startDataProcessing(){
    const {data} = this.state;

    this.setState({
      processing: true,
    })

    api.surveyResponse.dataProcessing.process(this.props.match.params.survey, data)
    .then(data => {
      this.setState({
        processing: false,
      });

      this.tableRef.current.tableRef.current.fetchDataDelay(0)

      toast.success('Oznaczanie wywiadów zostało zakończone');
    })
    .catch(res => {
      this.setState({
        processing: false,
      });
    });
  }

  render() {
    const {data, processing} = this.state;

    return <TabPane
      id="data-processing"
      className="fade show p-0 p-lg-5 d-flex flex-column"
      style={{
        height: '100%',
        overflow: 'auto',
      }}
    >
      <Row>
        <Col>
          <Alert color="info" className="d-flex align-content-center">
            <i className="fas fa-info-circle mr-2 fa-2x" />
            <p className="mb-0">Data processing (beta) to proces sprawdzania wywiadów pod kątem IP, sposobów zaznaczania odpowiedzi (w matrycach/karuzelach) i czasów wypełniania. NIE jest on decydujący o tym, czy wywiad jest rzetelny lub nie, ale stanowi pewną wskazówkę, którą możesz ale nie musisz uwzględniać w analizie danych.  W wielu projektach nie ma wymogu, by maksymalnie jedna osoba w gospodarstwie domowym wypełniła ankietę (unikalność IP). W przypadkach wielu matryc odpowiedź zaznaczana w jednej kolumnie ma sens i nie jest przesłanką do przeklikiwania ankiety.  Mediana też może być złym wyznacznikiem w przypadku, gdy wiele wywiadów jest przerwanych i zawyża całkowity czas wypełniania ankiety. Aktualnie data processing jest kalibrowany i będzie uzupełniany o kolejne elementy</p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="mx-auto" style={{maxWidth: '550px'}}>
            <CardHeader tag="h1" className="text-center">Zakres oznaczenia wywiadów</CardHeader>
            <hr className="my-1" />
            <CardBody>
              <Form>
                <FormGroup row className="d-flex align-items-center">
                  <Label md={7}>
                    <span>Unikalność adresu IP</span>
                    <i id={'dataProcessing-ipRepeat'} className="fas fa-info-circle ml-2" />
                    <Tooltip id={'dataProcessing-ipRepeat'} msg={"Oznacza wywiady o co najmniej dwóch identycznych adresach IP, z których wypełniono ankietę."} />
                  </Label>
                  <Col md={5}>
                    <Switch checked={data.ipRepeat} onChange={ipRepeat => this.onChange( 'ipRepeat', ipRepeat)}/>
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex align-items-center">
                  <Label md={7}>
                    <span>Zaznaczanie w liniach</span>
                    <i id={'dataProcessing-answersMatrixInLine'} className="fas fa-info-circle ml-2" />
                    <Tooltip id={'dataProcessing-answersMatrixInLine'} msg={"Oznacza wywiady, w których respondent, w co najmniej jednym pytaniu dwuwymiarowym (matryca, karuzela), we wszystkich wierszach wybrał wszystkie odpowiedzi z jednej kolumny."} />
                  </Label>
                  <Col md={5}>
                    <Switch checked={data.answersMatrixInLine} onChange={answersMatrixInLine => this.onChange( 'answersMatrixInLine', answersMatrixInLine)}/>
                  </Col>
                </FormGroup>
                {data.answersMatrixInLine && <div>
                  <SelectQuestions
                    placeholder="Wszystkie lub wybierz"
                    className="w-100"
                    onChange={questions => this.setState({data: {...data, questions,}})}
                    enabledTypes={['matrix', 'matrixMulti']}
                  />
                </div>}
                <FormGroup row className="d-flex align-items-center">
                  <Label md={7}>
                    <span>Czas trwania wywiadu</span>
                    <i id={'dataProcessing-responseTime'} className="fas fa-info-circle ml-2" />
                    <Tooltip id={'dataProcessing-responseTime'} msg={"Oznacza wywiady, które zostały wypełnione w czasie krótszym niż połowa (50%) mediany wypełnienia przez wszystkich respondentów w danym projekcie."} />
                  </Label>
                  <Col md={5}>
                    <Switch checked={data.responseTime} onChange={responseTime => this.onChange( 'responseTime', responseTime)}/>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
            <CardFooter>
              <Button loading={processing} className="mx-auto d-block" onClick={() => this.startDataProcessing()}>Oznacz wywiady</Button>
            </CardFooter>
          </Card>

        </Col>
      </Row>
      <Row>
        <Col>
          <DataProcessingTable ref={this.tableRef} survey={this.props.match.params.survey} />
        </Col>
      </Row>
    </TabPane>;
  }
}

export default DataProcessing;