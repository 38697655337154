import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';
import {toast} from "react-toastify";

class RedirectsUploadCsvModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      errors: {},
      file: null
    };

    this.toggle = this.toggle.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]});
  }

  handleUploadFile(e) {
    this.setState({
      file: e.target.files[0]
    });
  }

  fileUpload(file) {
    const formData = new FormData();
    formData.append('file', file);
    this.setState({saving: true});

    api.survey.post.csvRedirects(this.props.surveyId, formData)
      .then(() => {
        this.setState({
          saving: false,
          file: null
        });
        this.props.handleCreate();
        this.props.toggleModal();
      })
      .catch((e) => {
        const errorMessage = e.response.data.form.errors.target ? e.response.data.form.errors.target[0] : e.response.data.form.errors[0];
        toast.error(errorMessage);
        this.setState({
          saving: false
        });
      });
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.fileUpload(this.state.file);
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="lg">
        <ModalHeader toggle={this.props.toggleModal} tag="div"><FormattedMessage tagName="h2" id="redirect.actions.uploadCsv.modalHeader"/></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <Form id="redirects-import-csv-form" onSubmit={(e) => this.onFormSubmit(e)} className="d-flex justify-content-center">
            <Input
              name="redirects-import-csv"
              type="file"
              placeholder="Import redirects"
              onChange={this.handleUploadFile}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  type="submit"
                  form="redirects-import-csv-form"
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={this.state.saving}
                  data-style={ZOOM_OUT}
                  disabled={!this.state.file}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggleModal}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

RedirectsUploadCsvModal.propTypes = {
  surveyId: PropTypes.string.isRequired,
  handleCreate: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default RedirectsUploadCsvModal
