import React, {useEffect} from 'react';
import "./styles.scss";
import useDidMountEffect from "../../../utils/useDidMountEffect";
import api from "../../../api";
import StageDetailsTable from "./StageDetailsTable";
import 'anychart/dist/js/anychart-core.min'
import 'anychart/dist/js/anychart-circular-gauge.min'
import './scoring.scss';
import {Button} from "reactstrap";
import SurveyScoringTable from "../../../scenes/Surveys/components/SurveyScoringTable";
import ModalComponent from "../../../componentsReusable/ModalComponent";
import {connect} from "react-redux";

const Scoring = props => {
	const {survey} = props;
	const [scoring, setScoring] = React.useState(null);
	const [details, setDetails] = React.useState(null);
	const [openHistoryModal, setOpenHistoryModal] = React.useState(false);

	useEffect(() => {
		anychart.onDocumentReady(function () {
			var gauge = anychart.gauges.circular();
			gauge
				.fill('#fff')
				.stroke(null)
				.padding(0)
				.margin(0)
				.startAngle(270)
				.sweepAngle(180);

			gauge
				.axis()
				.labels()
				.padding(5)
				.fontSize(14)
				.position('outside')
				.format('');

			gauge.data();
			gauge
				.axis()
				.scale()
				.minimum(48)
				.maximum(100)
				.ticks({ interval: 10 })
				.minorTicks({ interval: 5 });

			gauge
				.axis()
				.fill('#545f69')
				.width(0)
				.ticks({ type: 'line', fill: 'white', length: 2 });
			gauge
				.title()
				.useHtml(true)
				.padding(0)
				.fontColor('#212121')
				.hAlign('center')
				.margin([0, 0, 10, 0]);

			gauge.range(1, {
				from: 0,
				to: 100,
				position: 'inside',
				fill: ['red', 'orange', 'yellow', 'green'],
				startSize: 50,
				endSize: 50,
				radius: 100
			});

			gauge.range(0, {
				from: scoring,
				to: 100,
				position: 'inside',
				fill: '#eeeeee',
				startSize: 50,
				endSize: 50,
				radius: 100
			});

			gauge.container('scoringChart');
			gauge.draw();
		});
	}, [scoring])

	useDidMountEffect(() => {
		api.survey.get.validationScoring(props.surveyId).then(res => {
			setScoring(res.data.score);
			setDetails({details: res.data.details})
		})
	})

	return (
		<div class={"position-relative"}>
			<h1>Scoring</h1>
			<a style={{top: "7px", right: 0}} class={"position-absolute"} href={"https://swresearch.pl/wiedza/jakosc-ankiety"}>Przeczytaj więcej o Scoringu</a>

			{scoring && <div class={"position-relative"}>
				<h2 class={"text-center position-absolute"} style={{fontSize: "80px", left: 0, right: 0, bottom: "50px", zIndex: 100}}>{scoring}</h2>
				{scoring < 75 && <span class={"rate-container red"}>Terrible</span>}
				{scoring >= 75 && scoring < 85 && <span class={"rate-container yellow"}>Weak</span>}
				{scoring >= 85 && scoring < 94 && <span class={"rate-container light-green"}>Good</span>}
				{scoring >= 94 && <span class={"rate-container green"}>Excellent</span>}
				<div id="scoringChart" />
			</div>}

			{details && <React.Fragment>
				<h1 className="mt-4">Szczegóły</h1>
				{scoring < 100 && <StageDetailsTable stageData={details}/>}
				{scoring === 100 && <h2 class={"mb-5"}>Gratulacje za osiągnięcie 100 punktów w scoringu!</h2>}
			</React.Fragment>}

			<div class={"mt-4 mb-5"}>
				<Button color={"primary"} onClick={() => setOpenHistoryModal(true)}>Zobacz historie scoringu</Button>
				<ModalComponent headerText={"Historia zmian scoringu (sprawdzana co 1h)"} modalOpen={openHistoryModal} onAccept={() => setOpenHistoryModal(false)} onCancel={() => setOpenHistoryModal(false)} component={
					<SurveyScoringTable survey={survey} />
				} />
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		survey: state.survey.structure.data.survey,
	}
}

Scoring.propTypes = {};

export default connect(mapStateToProps)(Scoring);
