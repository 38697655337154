import React, {Component} from 'react';
import {Form, FormGroup, Label, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

class TopSurveysRankForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        surveyName: props.data ? props.data.surveyName : '',
        userName: props.data ? props.data.userName : '',
        rateAvg: props.data ? props.data.rateAvg : '',
        rateCount: props.data ? props.data.rateCount : '',
      }
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });
  }

  render() {
    let {data} = this.state;
    const {errors, onSubmit} = this.props;

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup>
          <Label for="surveyName">Ankieta</Label>
          <Input type="text" name="surveyName" id="surveyName" value={data.surveyName} invalid={!!errors.surveyName} onChange={this.onChange}/>
          {errors.surveyName && <p className="help-block text-danger">{errors.surveyName}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="userName">Użytkownik</Label>
          <Input type="text" name="userName" id="userName" value={data.userName} invalid={!!errors.userName} onChange={this.onChange}/>
          {errors.userName && <p className="help-block text-danger">{errors.userName}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="rateAvg">Średnia ocena</Label>
          <Input type="text" name="rateAvg" id="rateAvg" value={data.rateAvg} invalid={!!errors.rateAvg} onChange={this.onChange}/>
          {errors.rateAvg && <p className="help-block text-danger">{errors.rateAvg}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="rateCount">Liczba ocen</Label>
          <Input type="text" name="rateCount" id="rateCount" value={data.rateCount} invalid={!!errors.rateCount} onChange={this.onChange}/>
          {errors.rateCount && <p className="help-block text-danger">{errors.rateCount}</p>}
        </FormGroup>
      </Form>
    )
  }
}

TopSurveysRankForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  data: PropTypes.object
};

export default TopSurveysRankForm;