import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Card, CardHeader, CardBody} from 'reactstrap';
import { fetchReports } from 'reducers/reports/actions';
import {FormattedMessage} from "react-intl";
import SurveysReportsButtonFix from './SurveysReportsButtonFix';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import ReportsList from "../../Survey/Reports/components/ReportsList";

class SurveysReports extends Component {
  render() {
    return (
      <Card>
        <CardHeader><FormattedMessage tagName="h2" id="surveysReports.cardHeader" /></CardHeader>
        <hr className="my-0" />
        <CardBody>
          <ReportsList />
        </CardBody>
      </Card>
    )
  }
}

export default SurveysReports;