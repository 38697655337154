import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {InputGroup, InputGroupAddon, Tooltip, Button} from "reactstrap";
import CreatableSelect from 'react-select/lib/Creatable';
import classnames from "classnames";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";

class FileExtensions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      question: {
        fileExtensions: props.question.fileExtensions
      }
    };

    this.onChange = this.onChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(extensions) {
    let fileExtensions = extensions.map(extension => extension.value).join(', ').trim();

    this.setState({
      ...this.state,
      question: {
        ...this.state.question,
        fileExtensions: !!fileExtensions ? _.uniq(fileExtensions.split(',').map(extension => extension.trim())) : []
      }
    }, () => this.props.handleUpdate(this.state.question));
  };

  render() {
    const {tooltip, question} = this.state;
    const options = [
      {
        label: <FormattedMessage id="question.settings.fileExtensions.image"/>,
        value: 'jpeg, jpg, png, gif'
      },
      {
        label: <FormattedMessage id="question.settings.fileExtensions.video"/>,
        value: 'mp4, avi, mov'
      },
      {
        label: <FormattedMessage id="question.settings.fileExtensions.text"/>,
        value: 'pdf, doc, docx'
      },
      {
        label: <FormattedMessage id="question.settings.fileExtensions.presentation"/>,
        value: 'ppt, pptx'
      },
    ];

    return (
      <InputGroup className="mr-1 float-left w-100 w-lg-auto mb-1" id={"fileExtensions-" + this.props.question.id} onClick={() => this.props.disabled && this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName())}>
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon active": true,
            })}
            color="secondary"
          >
            <i className="fas fa-file-invoice"/>
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltip}
              target={"fileExtensions-" + this.props.question.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="question.settings.fileExtensions.title" />
            </Tooltip>
          </Button>
        </InputGroupAddon>
        <CreatableSelect
          classNamePrefix="react-select"
          className="react-select float-left"
          isMulti
          value={question.fileExtensions.map(extension => {return {label: extension, value: extension}})}
          onChange={this.onChange}
          options={options}
          isDisabled={this.props.disabled}
        />
      </InputGroup>
    );
  }
}

FileExtensions.defaultProps = {
  disabled: false
};

FileExtensions.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    fileExtensions: PropTypes.array.isRequired
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default connect(null, {setPlanRestrictionsModals})(FileExtensions);
