import {SET, UPDATED} from './actionTypes';
import _ from "lodash";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.userPrizes;

    case UPDATED:
      let userPrize = _.find(state, userPrize => userPrize.id === action.userPrize.id);
      state[state.indexOf(userPrize)] = {...userPrize, ...action.userPrize};
      return state;

    default:
      return state;
  }
};