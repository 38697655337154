import React, {Component} from 'react';
import PropTypes from "prop-types";
import api from "api";
import ApplicationTable from "../../../components/Application/ApplicationTable";
import {Link} from "react-router-dom";
import appRoutes from "../../../appRoutes";
import DictionaryWordImportModal from "./DictionaryWordImportModal";
import DictionaryAddModal from "../DictionaryAddModal";
import SearchTextInput from "../../../components/Application/ApplicationTable/SearchTextInput";
import DictionaryWordFromTextareaModal from "./DictionaryWordFromTextareaModal";

class DictionaryWordsTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  render() {
    const columns = [
      {
        id: 'word',
        Header: 'Nazwa',
        width: 300,
        accessor: row => <Link to={appRoutes.dictionaries.dictionary.word.similarList(row.dictionary.id, row.id)}>{row.word}</Link>
      },
      {
        accessor: 'similarsCount',
        Header: 'Słowa podobne',
        width: 200,
      },
      {
        Header: 'Data utworzenia',
        accessor: 'createdAt',
        width: 170,
      },
    ];

    return <React.Fragment>
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex">
          <DictionaryWordImportModal onModalClose={() => {}} dictionaryId={this.props.dictionaryId} onImported={() => {
            this.tableRef.current.fetchData({});
          }} />
          <DictionaryWordFromTextareaModal onModalClose={() => {}} dictionaryId={this.props.dictionaryId} onCreated={() => {
            this.tableRef.current.fetchData({});
          }} />
        </div>

        <div className="ml-auto">
          <SearchTextInput
            tableRef={this.tableRef}
          />
        </div>
      </div>

      <ApplicationTable
        ref={this.tableRef}
        columns={columns}
        fetchData={api.dictionaryWords.search}
        deleteData={api.dictionaryWords.delete}
        filters={{
          dictionary: this.props.dictionaryId
        }}
      />
    </React.Fragment>;
  }
}

DictionaryWordsTable.propTypes = {
  dictionaryId: PropTypes.number.isRequired,
};

export default DictionaryWordsTable;
