import api from "api";
import * as types from './actionTypes';

export function setUsers(users) {
  return {
    type: types.SET,
    users
  }
}

function userUpdated(user) {
  return {
    type: types.UPDATED,
    user
  }
}


function banUpdated(ban) {
  return {
    type: types.BAN_UPDATED,
    ban
  }
}

function userCreated(user) {
  return {
    type: types.ADDED,
    user
  }
}

export function fetchRespondents() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.user.get.respondents().then(users => {
      dispatch(setUsers(users));
      dispatch({type: types.SUCCESS});
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}

export function updateRespondent(data) {
  return dispatch => {
    return api.user.patch.respondentAdmin(data)
      .then(user => {
        dispatch(userUpdated(user));

        return user;
      });
  }
}

export function updateBan(data) {
  return dispatch => {
    return api.ban.patch(data)
      .then(ban => {
        dispatch(banUpdated(ban));

        return ban;
      });
  }
}

export function createRespondent(data) {
  return dispatch => {
    return api.user.post.respondent(data)
      .then(user => {
        dispatch(userCreated(user));

        return user;
      });
  }
}