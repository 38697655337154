import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from "striptags";
import api from "../../../../../../../../../api";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import Select from "react-select";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";

class ColumnTfoot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      position: false,
      excluding: false,
      field: null,
      content: null,
      modalIsOpen: false,
    };

    this.modalToggle = this.modalToggle.bind(this);
    this.save = this.save.bind(this);
  }

  modalToggle(position = false, field = null){
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
      position,
      field,
      excluding: false,
    });
  }

  save(){
    const {position, excluding, field, content} = this.state;
    const {question, responsesSelected} = this.props;

    this.setState({
      saving: true,
    });

    let data = {
      responses: responsesSelected,
      position,
      excluding,
      field,
      content,
    };

    api.questionResponse.question.patchDetails(question.id, data)
    .then(data => {
      this.setState({
        saving: false,
        position: false,
        excluding: false,
        field: null,
        content: null,
        modalIsOpen: false,
      });

      this.props.onSaved();
    })
    .catch(res => {
      this.setState({
        saving: false,
      });
    });
  }

  render() {
    const {modalIsOpen, position, field, saving} = this.state;
    const {responsesSelected, question} = this.props;

    const filedsMap = {
      lat: 'Lat',
      lng: 'Lng',
      formattedAddress: 'Adres'
    }

    let td = [];
    for(let position = 0; position < question.answersCount; position++){
      td.push(<td>
        <Button
          onClick={() => this.modalToggle(position, 'lat')}
          disabled={responsesSelected.length === 0}
          size="sm"
          color="secondary"
          className="w-100 text-center"
        >Zmień</Button>
      </td>);
      td.push(<td>
        <Button
          onClick={() => this.modalToggle(position, 'lng')}
          disabled={responsesSelected.length === 0}
          size="sm"
          color="secondary"
          className="w-100 text-center"
        >Zmień</Button>
      </td>);
      td.push(<td>
        <Button
          onClick={() => this.modalToggle(position, 'formattedAddress')}
          disabled={responsesSelected.length === 0}
          size="sm"
          color="secondary"
          className="w-100 text-center"
        >Zmień</Button>
      </td>);
    }

    return <React.Fragment>
      {td}

      {question.excludings.length > 0 && <td>
        <Button
          onClick={() => this.modalToggle(false)}
          disabled={responsesSelected.length === 0}
          size="sm"
          color="secondary"
          className="w-100 text-center"
        >Zmień</Button>
      </td>}

      {modalIsOpen && <Modal isOpen={true} toggle={this.modalToggle} size="xl">
        <ModalHeader tag="h2" toggle={this.modalToggle}>Zmień odpowiedź dla wybranych wywiadów</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label md={3}>Liczba zaznaczonych wywiadów:</Label>
              <Col md={9} className="d-flex align-items-center">{responsesSelected.length}</Col>
            </FormGroup>
            {position !== false && <FormGroup row>
              <Label md={3}>Zmiana dotyczy odpowiedzi:</Label>
              <Col md={9} className="d-flex align-items-center">Zaznaczenie {position+1} - {filedsMap[field]}</Col>
            </FormGroup>}
            {position !== false && <FormGroup row>
              <Label md={3}>W zaznaczonych wywiadach zmień odpowiedź na:</Label>
              <Col md={9} className="d-flex align-items-center">
                <Input type="text" placeholder="Zostaw puste aby usunąć odpowiedź lub uzupełnij" onChange={e => this.setState({content: e.target.value})} />
              </Col>
            </FormGroup>}
            {position === false && <FormGroup row>
              <Label md={3}>W zaznaczonych wywiadach zmień na odpowiedź wykluczającą:</Label>
              <Col md={9} className="d-flex align-items-center">
                <SelectExcludings
                  questionId={question.id}
                  className="w-100"
                  onChange={excluding => this.setState({excluding: excluding || false})}
                />
              </Col>
            </FormGroup>}
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={this.modalToggle}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mb-0"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={() => this.save()}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>}
    </React.Fragment>;
  }
}

ColumnTfoot.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['multiOpen']),
    answersCount: PropTypes.number.isRequired,
  }).isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default ColumnTfoot;