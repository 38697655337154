import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import SurveyShareForm from "../../../SurveyShare/components/SurveyShareForm";
import SurveyShareList from "../../../SurveyShare/components/SurveyShareList";

class SurveysModalShare extends Component {

  render() {
     return <Modal size="lg" onClosed={() => this.props.onChange()} isOpen={this.props.isOpen} toggle={() => this.props.toggleModal()}>
        <ModalHeader toggle={() => this.props.toggleModal()} tag="h2"><FormattedMessage id="surveyShareButton.modalHeader" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <SurveyShareForm surveyId={this.props.survey.id}/>
          <SurveyShareList surveyId={this.props.survey.id}/>
        </ModalBody>
      </Modal>;
  }
}

SurveysModalShare.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default connect(null, null, null, {forwardRef: true})(SurveysModalShare);