import React, {Component} from 'react';
import {connect} from "react-redux";
import {Alert} from "reactstrap";

import {fetchAnalysis} from "./../reducer/analysis/actions";
import {fetchQuestionSummaries} from "./../reducer/questionSummaries/actions";
import LoadingSpinner from "components/LoadingSpinner";
import Sidebar from "./Sidebar/Sidebar";
import View from "./View/View";

import api from "api";
import appRoutes from "appRoutes";
import history from "services/history";
import {toast} from "react-toastify";


class Analysis extends Component {

  constructor(props) {
    super(props);

    this.state = {
      analysisExists: false,
      loadingAnalysis: true,
      loadingQuestionSummaries: false,
      sidebarView: 'normal',
    };

    this.loadAnalysis = this.loadAnalysis.bind(this);
    this.loadFirstAnalysis = this.loadFirstAnalysis.bind(this);
    this.handleSidebarRendering = this.handleSidebarRendering.bind(this);
    this.fetchQS = this.fetchQS.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(_.isUndefined(nextProps.match.params.analysis)){
      this.loadFirstAnalysis();
    }else{
      if(nextProps.match.params.analysis !== this.props.match.params.analysis){
        this.loadAnalysis(nextProps.match.params.analysis);
      }
    }
  }

  componentDidMount(){
    if(_.has(this.props.match.params, 'analysis')){
      this.loadAnalysis(this.props.match.params.analysis);
    }else{
      this.loadFirstAnalysis();
    }
  }

  loadFirstAnalysis(){
    api.survey.get.analyses({
      id: this.props.match.params.survey
    }).then(data => {
      if(data.length > 0){
        history.push(appRoutes.survey.analysis.questionSummaries(this.props.match.params.survey, data[0].id));
      }
    }).catch(e => {
      toast.error(e.response.data.errors[0].message);
      this.props.history.push(appRoutes.surveys.list);
    });
  }

  loadAnalysis(id){
    this.setState({
      loadingAnalysis: true,
      analysisExists: false,
    });

    this.props.fetchAnalysis({
      id
    }).then(analysis => {
      if(analysis){
        this.setState({
          analysisExists: true,
          loadingAnalysis: false,
          loadingQuestionSummaries: true,
        });

        this.fetchQS(analysis.lang);
      }else{
        this.setState({
          analysisExists: false,
          loadingAnalysis: false,
          loadingQuestionSummaries: false,
        });
      }
    }).catch(e => {
      toast.error(e.response.data.errors[0].message);
      this.props.history.push(appRoutes.surveys.list);
    });
  }

  fetchQS(lang){
    this.setState({
      loadingQuestionSummaries: true,
    });

    this.props.fetchQuestionSummaries({
      id: this.props.match.params.analysis
    }, lang).then(() => {
      this.setState({
        loadingQuestionSummaries: false,
      });
    });
  }

  handleSidebarRendering(sidebarView){
    this.setState({
      sidebarView,
    })
  }

  render() {
    const {loadingAnalysis, analysisExists, loadingQuestionSummaries, sidebarView} = this.state;

    if(loadingAnalysis){
      return <LoadingSpinner/>
    }

    if(!analysisExists){
      return <Alert className="text-center">Brak dostępu do analizy</Alert>
    }

    return (
      <React.Fragment>
        <Sidebar onChangedData={this.fetchQS} view={sidebarView} />
        <main
          className="d-flex flex-grow flex-column"
          style={{
            overflow: 'hidden'
          }}
        >
          {loadingQuestionSummaries ? <LoadingSpinner/> : <View handleSidebarRendering={this.handleSidebarRendering} sidebarView={sidebarView} />}
        </main>
      </React.Fragment>
    )
  }
}

export default connect(null, {fetchAnalysis, fetchQuestionSummaries})(Analysis);
