import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Tooltip from "../../../../../../../../../../../../../../../../../../../../components/Tooltip";
import {Modal,ModalHeader,ModalBody,ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Button from "components/Button";
import getCKConfigByPlan
  from "../../../../../../../../../../../../../../../../../../../../utils/ckeditor/questionConfig";
import HtmlEditor from "../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";

CafeteryEditModal.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

function CafeteryEditModal(props){
  const [content, setContent] = React.useState(props.content);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  return <React.Fragment>
    <a id={'modal-edit-' + props.type + '-' + props.id} className={"fa-solid fa-pen-to-square ml-2"} onClick={toggleModalOpen} />
    <Tooltip id={'modal-edit-' + props.type + '-' + props.id} msg={"Edytuj treść odpowiedzi"} />
    {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="xl">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Edytuj treść</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <HtmlEditor
          onChange={e => setContent(e)}
          data={props.content}
          config={getCKConfigByPlan(props.plan)}
        />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <Button
            color={"primary"}
            loading={false}
            onClick={() => {
              props.onSave('<div>' + content + '</div>');
              toggleModalOpen();
            }}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
}

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps)(CafeteryEditModal)
