import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader} from "reactstrap";
import SurveysCategories from "../componentsNew/SurveysCategories/SurveysCategories";

const SurveysCategoriesView = props => {
  return (
    <SurveysCategories />
  );
};

SurveysCategoriesView.propTypes = {

};

export default SurveysCategoriesView;