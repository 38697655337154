import * as React from 'react';
import {connect} from "react-redux";
import LaddaButton from "react-ladda";
import {CardHeader, Col, Input, Label, Row} from "reactstrap";
import {Card, CardBody} from "reactstrap";
import api from "api";
import {isArray} from "lodash/fp/_util";


function ApiKeys(props) {
	const [publicKey, setPublicKey] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);

	if (!publicKey && !isLoading) {
		api.user.apiKeys.get().then((data) => {
			if (isArray(data)) {
				setPublicKey(data[0].publicKey);
			}
		})
	}

	function generateNewKey() {
		setIsLoading(true);
		api.user.apiKeys.post().then((data) => {
			setIsLoading(false);
			setPublicKey(data.publicKey);
		})
	}

	return (
		<Card>
			<CardHeader><h2>Klucze API</h2></CardHeader>
			<hr className="my-0" />
			<CardBody>
				<Row className="mb-3">
					<Col md={3}>
						<Label data-role="label" for="input-type-1">Klucz API</Label>
					</Col>
					<Col md={9}>
						<Input disabled={true} type="text" id="postalCode" name="postalCode" className="custom" placeholder="Brak klucza API" value={publicKey}/>
					</Col>
				</Row>
				<LaddaButton className="btn btn-success float-right" onClick={generateNewKey} disabled={isLoading}>
					Wygeneruj nowy klucz API
				</LaddaButton>
			</CardBody>
			<CardBody>
				<h2><b>Instrukcja</b></h2>
				<h2>Aby używać klucza API w requestach należy dodać header ApiKey: {publicKey ? publicKey : 'XXX'} przy każdym jego wykonaniu</h2>
				<h2>Korzystając z tego klucza otrzymujesz dostęp do każdej funkcjonalności, jakiej ma dostęp właściciej klucza</h2>
			</CardBody>
		</Card>
	);
}

ApiKeys.propTypes = {

};

function mapStateToProps(state) {
}

export default connect(mapStateToProps, {})(ApiKeys);