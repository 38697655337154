import * as React from 'react';
import PropTypes from "prop-types";
import Button from "../../../../components/Button";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import api from "../../../../api";
import {toast} from "react-toastify";

function ReportSendMailButton(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [sending, setSending] = React.useState(false);

  const send = () => {
    setSending(true);

    api.survey.reports.sendEmails(props.reportIds)
    .then(() => {
      setSending(false);
      setModalOpen(false);
      toast.success('Wiadomości e-mail zostały wysłane');
    })
    .catch(() => {
      setSending(false);
    })
  }

  return (
    <div>
      <Button
        color="primary"
        onClick={toggleModalOpen}
      >Wyślij maila</Button>
      {modalOpen && <Modal isOpen toggle={toggleModalOpen} size="md">
        <ModalHeader toggle={toggleModalOpen} tag="h2">Wyślij e-mail z informacją do respondentów</ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <p>Czy na pewno wysłać maila do wybranych respondentów z informacją o rozwiązanym problemie?</p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>

          <Button color="primary" loading={sending} className="mb-0" onClick={send}>Tak, wyślij</Button>
        </ModalFooter>
      </Modal>}
    </div>
  );
}

ReportSendMailButton.propTypes = {
  reportIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ReportSendMailButton;