import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from "react-intl";
import {updateSurvey} from "../../../../data/survey/actions";
import {Row, Col, Button, Input} from 'reactstrap';
import striptags from "striptags";
import HtmlEditor from "../../../../../../../componentsReusable/HtmlEditor";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import LoadingSpinner from "components/LoadingSpinner";
import {isMobile, isTablet} from "react-device-detect";
import getCKConfigByPlan from "utils/ckeditor/questionConfig";
import reactHtmlParser from "react-html-parser";

class ScreenOutText extends Component{
  constructor(props) {
    super(props);
    this.state = {
      screenOutText: props.screenOutText,
      screenOutToggle: props.screenOutToggle,
      editing: false,
      saving: false,
    };
    this.toggleEditing = this.toggleEditing.bind(this);
    this.handleScreenOutToggle = this.handleScreenOutToggle.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  toggleEditing(type) {
    this.setState({
      [type]: !this.state[type]
    });
  }

  onChange(value, type) {
    this.setState({
      [type]: value
    });
  }

  updateContent(){
    this.setState({
      saving: true
    });

    const data = {
      id: this.props.surveyId,
      survey: {
        screenOutText: this.state.screenOutText
      }
    };

    this.props.updateSurvey(data)
      .then((res) => {
        this.setState({
          saving: false,
          editing: false,
          screenOutText: res.screenOutText
        });
      })
      .catch(() => {
        this.setState({
          saving: false,
          editing: false
        });
      });
  }

  handleScreenOutToggle(){
    this.setState({
      screenOutText: ''
    }, () => this.updateContent());
    this.props.handleScreenOutToggle();

  }


  render() {
    const {screenOutText, editing, saving} = this.state;
    const {screenOutToggle, plan} = this.props;

    const editor = <React.Fragment>
      <Row>
        <Col>
          {(isMobile || isTablet)
            ? <Input value={screenOutText} onChange={e => this.onChange(e.target.value, 'screenOutText')} onBlur={this.updateContent} autoFocus={true} />
            : <HtmlEditor
              onChange={e => this.onChange(e, 'screenOutText')}
              data={screenOutText}
              config={getCKConfigByPlan(plan)}
            />}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mr-2"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={() => this.updateContent()}
            >{message}</LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) => <Button onClick={() => this.toggleEditing('editing')}>{message}</Button>
          }</FormattedMessage>
        </Col>
      </Row>
    </React.Fragment>;

    return (
      <React.Fragment>
        {screenOutToggle
          ?
            (
              <React.Fragment>
                <h2 className="mt-4"><FormattedMessage id="surveyStructure.endingText.screenOutText.content"/> <i className="fas fa-trash" onClick={() => this.handleScreenOutToggle()}> </i></h2>
                {editing ?
                  editor
                  :
                  screenOutText ?
                    <span onClick={() => this.toggleEditing('editing')}>{reactHtmlParser(striptags(screenOutText))}</span>
                    :
                    <i onClick={() => this.toggleEditing('editing')}><FormattedMessage id="surveyStructure.endingText.screenOutText.isEmpty"/></i>
                }
              </React.Fragment>
            ) : saving ? <LoadingSpinner width={25} classNames="my-0"/> : ''
        }
      </React.Fragment>
    );
  }
}

ScreenOutText.propTypes = {
  surveyId: PropTypes.string.isRequired,
  screenOutToggle: PropTypes.bool.isRequired,
  screenOutText: PropTypes.string,
  handleScreenOutToggle: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {updateSurvey})(ScreenOutText)
