import React, {Component} from 'react';
import PropTypes from "prop-types";
import SettingBool from "../../../SettingBool";
import AnswersImage from "../../../AnswersImage";
import RequiredCount from "../../../RequiredCount";
import RandAnswers from "../../../RandAnswers";
import FetchCafeteryPositions from "../../../FetchCafeteryPositions";
import SettingNumber from "../../../SettingNumber";
import AnswersColumns from "../../../AnswersColumns";
import {connect} from "react-redux";

class RankedSettings extends Component {

  render() {
    const {question, questionSettingsPlan} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'requiredAll'} value={question.requiredAll || false} icon={<i className="fa-solid fa-triangle-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'rotateAnswers'} value={question.rotateAnswers} disabled={!questionSettingsPlan.ranked.rotateAnswers} icon={<i className="fas fa-random" />} handleUpdate={this.props.handleUpdate}/>
        <AnswersImage question={question} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'reverseAnswers'} value={question.reverseAnswers} icon={<i className="fa-solid fa-retweet" />} handleUpdate={this.props.handleUpdate}/>
        <RequiredCount question={question} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'places'} value={question.places || 3} icon={<i className="fa-solid fa-arrow-down-1-9" />} handleUpdate={this.props.handleUpdate}/>
        <RandAnswers question={question} disabled={!questionSettingsPlan.randCafetery} handleUpdate={this.props.handleUpdate}/>
        <FetchCafeteryPositions question={question} handleUpdate={this.props.handleUpdate}/>
        <AnswersColumns question={question}/>
        <SettingBool question={question} name={'showVertically'} value={question.showVertically} icon={<i className="fa-solid fa-arrow-down-up-across-line" />} handleUpdate={this.props.handleUpdate}/>
      </React.Fragment>
    )
  }
}

RankedSettings.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    questionSettingsPlan: state.user.userPlan.plan.survey.questionSettings
  }
}

export default connect(mapStateToProps)(RankedSettings);
