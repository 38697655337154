import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';

class UserBulkUnbanModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.unban = this.unban.bind(this);
  }

  unban() {
    this.setState({loading: true});

    api.ban.delete.bulk({users: this.props.checked})
      .then(() => {
        this.props.onUpdate();
        this.setState({loading: false});
        this.props.toggle();
      })
  }

  render() {
    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
      <ModalHeader toggle={this.props.toggle} tag="h2"><FormattedMessage id="admin.respondents.userBulkUnbanModal.modalHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <FormattedMessage id="admin.respondents.userBulkUnbanModal.modalBody"/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={this.props.toggle}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="admin.respondents.userBulkUnbanModal.unban">{
          (message) => <LaddaButton
            className="btn btn-success btn-ladda"
            loading={this.state.loading}
            data-style={ZOOM_OUT}
            onClick={this.unban}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;
  }
}

UserBulkUnbanModal.propTypes = {
  checked: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default UserBulkUnbanModal;