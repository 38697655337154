import React, {Component} from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import ClientsForm from 'scenes/Clients/components/ClientsForm';
import {addClient} from "../../scenes/Clients/actions";
import ClientsAvailable from "../PlanRestrictionsModals/modals/ClientsAvailable";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";

class SurveyClientSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      adding: false,
      saving: false,
      errors: [],
      value: (props.clientId && props.clients[props.clientId]) && {value: props.clientId, label: props.clients[props.clientId].name}
    };

    this.onChange = this.onChange.bind(this);
    this.form = React.createRef();
  }

  saveClient(e, data){
    e.preventDefault();
    this.setState({saving: true, errors: []});

    this.props.addClient({
      data: data
    }).then((client) => {
      this.setState({
        adding: false,
        saving: false,
        value: {
          value: client.id,
          label: this.props.clients[client.id].name,
        }
      });

      this.props.changeHandler(client.id);
    })
    .catch((e) => {
      if(e.response.data.form.errors){
        this.setState({
          saving: false,
          errors: e.response.data.form.errors,
        });
      }
    });
  }

  onChange(e) {
    if(e.value === null){
      this.setState({
        adding: true
      })
    }else{
      this.setState({
        adding: false,
        value: e,
      });
      this.props.changeHandler(e.value);
    }
  }

  toggleAdding(){
    this.setState({
      adding: !this.state.adding
    })
  }

  render() {
    const {errors, saving} = this.state;

    let options = _.map(this.props.clients, client => {return {label: client.name, value: client.id}});

    options.unshift({
      label: '--Utwórz nowego klienta--',
      value: null
    });

    const modal = <Modal isOpen={this.state.adding} toggle={() => this.toggleAdding()} size="lg">
      <ModalHeader toggle={() => this.toggleAdding()} tag="h2"><FormattedMessage id="clientsButtonCreate.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <ClientsForm errors={errors} onSubmit={this.saveClient} ref={this.form}/>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.save">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={e => this.saveClient(e, this.form.current.state.data)}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggleAdding()}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <span onClick={() => !this.props.plan.clients && this.props.setPlanRestrictionsModals(ClientsAvailable.getName())}>
        <Select
          className="react-select"
          value={this.state.value}
          options={options}
          onChange={this.onChange}
          isDisabled={!this.props.plan.clients}
        />
        {modal}
      </span>
    )
  }
}

SurveyClientSelect.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  clientId: PropTypes.number
};

function mapStateToProps(state) {
  return {
    clients: state.clients.data.clients,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {addClient, setPlanRestrictionsModals})(SurveyClientSelect);