import * as React from 'react';
import {Form, Input, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardHeader, Row, Col} from "reactstrap";
import Pagination from "../../../components/Pagination";
import ReactTable from "react-table";
import api from "../../../api";
import {FormattedMessage} from "react-intl";
import Button from "../../../components/Button";

const ButtonAdd = (props) => {
  const [file, setFile] = React.useState(null);
  const [password, setPassword] = React.useState('');
  const [saving, setSaving] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  
  const save = () => {
    setSaving(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('password', password);

    api.applicationFile.addFile(formData)
    .then(data => {
      setSaving(false);
      toggleModalOpen();
      props.onAdded();
    })
    .catch(() => {
      setSaving(false);
    })
  }
    
  return <React.Fragment>
    <Button onClick={toggleModalOpen}><i className="fas fa-plus" /> Dodaj nowy plik</Button>
    {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="lg">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Dodaj nowy plik</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <Form onSubmit={(e) => { e.preventDefault(); save(); }}>
          <FormGroup>
            <Label>Wybierz plik</Label>
            <Col>
              <Input
                type="file"
                onChange={e => setFile(e.target.files[0])}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Label>Podaj hasło</Label>
            <Col>
              <Input
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.add">{
          (message) => <Button
            color={"primary"}
            loading={saving}
            onClick={save}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
}

const ButtonRemove = (props) => {
  const [deleting, setDeleting] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  const deleteFile = () => {
    setDeleting(true);

    api.applicationFile.removeFile(props.id)
    .then(() => {
      setDeleting(false);
      toggleModalOpen();
      props.onDeleted();
    })
    .catch(() => {
      setDeleting(false);
    })
  }

  return <React.Fragment>
    <i onClick={toggleModalOpen} className="fas fa-trash text-danger p-2 pointer" />
    {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="lg">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Potwierdź usunięcie</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        Czy na pewno usunąć plik?
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.delete">{
          (message) => <Button
            color={"danger"}
            loading={deleting}
            onClick={deleteFile}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
}

function ApplicationFiles(props) {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(50);
  const [searching, setSearching] = React.useState(false);
  const [count, setCount] = React.useState(0);

  const columns = [
    {
      id: 'filename',
      Header: 'Nazwa',
      accessor: row => row.filename
    },
    {
      id: 'size',
      Header: 'Rozmiar pliku',
      accessor: row => (row.size / 1024).toFixed(2) + ' kb'
    },
    {
      id: 'password',
      Header: 'Hasło',
      accessor: row => row.password
    },
    {
      id: 'link',
      Header: 'Link do pobrania',
      accessor: row => {
        const link = 'https://ankieteo.pl/files/' + row.filename;

        return <a href={link}>{link}</a>
      }
    },
    {
      id: 'actions',
      Header: 'Akcje',
      accessor: row => {

        return <div className="d-flex">
          <ButtonRemove id={row.id} onDeleted={() => fetchData({page, pageSize})} />
        </div>
      }
    },
  ];

  const fetchData = (state) => {
    setSearching(true);
    setPage(state.page);
    setPageSize(state.pageSize);

    const data = {
      page: state.page,
      pageSize: state.pageSize,
    };

    api.applicationFile.search(data)
    .then(data => {
      setSearching(false);
      setData(data.data);
      setCount(data.total);
    })
    .catch(res => {
      setSearching(false);
      setData([]);
      setCount(0);
    });
  }

  return (
    <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader><h2 className="float-left mr-4">Pliki</h2></CardHeader>
          <hr className="my-0" />
          <CardBody>
            <Row>
              <Col>
                <ButtonAdd onAdded={() => fetchData({page, pageSize})} />
                <ReactTable
                  minRows={0}
                  className={'table-wrap'}
                  data={data}
                  columns={columns}
                  showPagination={!_.isEmpty(data)}
                  PaginationComponent={Pagination}
                  loading={searching}
                  defaultPageSize={50}
                  page={page}
                  onFetchData={(state, props) => fetchData(state)}
                  rowsCount={count}
                  manual
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

ApplicationFiles.propTypes = {

};

export default ApplicationFiles;