import React, {useState} from "react";
import PropTypes from "prop-types";
import {Tooltip as TooltipReactstrap} from "reactstrap";

function Tooltip(props){
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return <TooltipReactstrap
    className={props.className}
    fade={props.fade}
    delay={0}
    placement={props.placement}
    trigger={'hover'}
    isOpen={tooltipOpen}
    target={props.id}
    toggle={toggle}
    container={props.container || document.body}
  >{props.msg}</TooltipReactstrap>;
};

Tooltip.defaultProps = {
  className: '',
  fade: false,
  placement: 'bottom',
};

Tooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  id: PropTypes.string.isRequired,
  msg: PropTypes.oneOfType([PropTypes.string, PropTypes.object,]).isRequired,
  fade: PropTypes.bool,
  container: PropTypes.any,
};

export default Tooltip;
