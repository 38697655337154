import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';
import RedirectForm from '../RedirectForm';

class RedirectsCreateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      errors: {}
    };

    this.toggle = this.toggle.bind(this);
    this.createRedirect = this.createRedirect.bind(this);
    this.redirectForm = React.createRef();
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]});
  }

  createRedirect(e, data) {
    e.preventDefault();
    this.setState({saving: true, errors: {}});

    console.log('data', data);

    api.survey.post.redirect(this.props.surveyId, data.redirect)
      .then(() => {
        this.setState({saving: false});
        this.props.handleCreate();
        this.props.toggleModal();
      })
      .catch(e => {
        this.setState({saving: false, errors: e.response.data.form.errors})
      });
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="lg">
        <ModalHeader toggle={this.props.toggleModal} tag="div"><FormattedMessage tagName="h2" id="redirect.actions.create.modalHeader"/></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <RedirectForm errors={this.state.errors} onSubmit={this.createRedirect} ref={this.redirectForm}/>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={this.state.saving}
                  data-style={ZOOM_OUT}
                  onClick={e => this.createRedirect(e, this.redirectForm.current.state)}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggleModal}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

RedirectsCreateModal.propTypes = {
  surveyId: PropTypes.string.isRequired,
  handleCreate: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default RedirectsCreateModal
