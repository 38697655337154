import * as React from 'react';
import "../../../componentsNew/Survey/SurveyValidation/scoring.scss";
import SurveyScoringIcon from "./SurveyScoringIcon";
import api from "../../../api";
import DefaultTable from "../../../componentsReusable/DefaultTable";
import Button from "../../../components/Button";
import {Col, Row} from "reactstrap";
import appRoutes from "../../../appRoutes";
import {Link} from "react-router-dom";

function SurveyScoringTable(props) {
	const {survey} = props;
	const [scoring, setScoring] = React.useState(0);
	const [refreshTable, setRefreshTable] = React.useState(false)

	const pageRequest = async (page, pageSize) => {
		return await api.survey.post.searchSurveysScorings(survey, {
			page: page,
			pageSize: pageSize
		});
	}

	const createScoring = () => {
		api.survey.get.validationScoring(survey.id).then(res => {
			setScoring(res.data.score);
			setRefreshTable(true);
			setTimeout(() => {
				setRefreshTable(false);
			})
		})
	}

	const columns = [{
		id: 'scoring',
		Header: "Wynik",
		accessor: scoring => <SurveyScoringIcon scoring={scoring.score} />,
		sortable: false
	}, {
		id: 'createdAt',
		Header: "Data",
		accessor: scoring => scoring.createdAt,
		sortable: false
	}];

	return (
		<React.Fragment>
			<div>
				<Row>
					<Col xs={6}>
						<Button color={"primary"} onClick={() => createScoring()}>Skanuj teraz</Button>
					</Col>
					{window.location.pathname !== appRoutes.survey.surveyValidation(survey.id) && <Col xs={6}>
						<Link class={"float-right"} to={appRoutes.survey.surveyValidation(survey.id)}>Sprawdź szczegóły</Link>
					</Col>}
				</Row>
				<div class={"mt-2 mb-5"}>
					<a class={"float-right"} href={"https://swresearch.pl/wiedza/jakosc-ankiety"}>Dowiedz się więcej o Scoringu</a>
				</div>
				{scoring > 0 && <h3 class={"mt-2"}>Aktualny wynik: <SurveyScoringIcon scoring={scoring} /></h3>}
			</div>
			{!refreshTable && <DefaultTable columns={columns} pageRequest={pageRequest} />}
		</React.Fragment>
	);

};

export default SurveyScoringTable;