import React, {Component} from 'react';
import PropTypes from "prop-types";
import AnyChart from "anychart-react";

class ChartHeat extends Component {

  render() {
    const {id, data, n} = this.props;
    let height = 30;
    height += (data.rowsLength * 65);

    let stage = anychart.graphics.create();
    var chart = anychart.heatMap(data.data);

    chart.labels().format(row => row.heat + " (" + Math.round(row.heat / n * 1000) / 10 + '%)');

    var tooltip = chart.tooltip();
    tooltip.format(row => "Liczba wskazań: " + row.heat + "\nProcentowo: " + Math.round(row.heat / n * 1000) / 10 + "%");

    return (
      <AnyChart
        id={'question-' + id}
        charts={[chart]}
        instance={stage}
        yAxis={{
          overlapMode: 'allowOverlap',
          labels: {
            width: '25%',
            wordWrap: 'break-word',
            wordBreak: 'break-all',
            hAlign: 'right'
          }
        }}
        xAxis={{
          overlapMode: 'allowOverlap',
          labels: {
            width: parseInt((75 / data.columnsLength), 10) + '%',
            wordWrap: 'break-word',
            wordBreak: 'break-all',
            hAlign: 'center'
          }
        }}
        height={height}
      />
    )
  }
}

ChartHeat.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    rowsLength: PropTypes.number.isRequired,
    columnsLength: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      x: PropTypes.string.isRequired,
      y: PropTypes.string.isRequired,
      heat: PropTypes.number.isRequired,
    }).isRequired,).isRequired
  }).isRequired,
  n: PropTypes.number.isRequired
};

export default ChartHeat;