import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Tooltip} from "reactstrap";
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";

class ButtonPreview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  render() {
    const {tooltip} = this.state;
    const {id, surveyId} = this.props;

    return (
      <Link to={appRoutes.survey.preview.question(surveyId, id)} id={'question-' + id + '-preview'}>
        <span className="fas fa-search text-dark hoverable" />
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={tooltip}
          target={'question-' + id + '-preview'}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="question.headerActions.buttonPreview.button.tooltip"/>
        </Tooltip>
      </Link>
    )
  }
}

ButtonPreview.propTypes = {
  id: PropTypes.number.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default ButtonPreview;