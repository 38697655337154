import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Switch from "components/Switch";
import api from "api";
import {clearContentInQuestions} from "scenes/Survey/Structure/data/survey/actions";
import {connect} from "react-redux";
import Select from "react-select";

const TAGS = [
  'p',
  'br',
  'b',
  'strong',
  'u',
  'i',
  'img',
  'span',
  'div',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

function TagsSelect(props) {
  const options = TAGS.map(tag => ({
    label: tag,
    value: tag,
  }))

  return (
    <Select
      className="w-100"
      isMulti
      options={options}
      value={props.value.map(tag => ({
        label: tag,
        value: tag,
      }))}
      onChange={options => props.onChange(options.map(o => o.value))}
    />
  );
}

class ClearContentInQuestions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      question: true,
      cafetery: false,
      tags: ['p', 'br'],
    };

    this.clear = this.clear.bind(this);
  }

  clear(){
    const {question, cafetery, tags} = this.state;

    this.setState({
      loading: true,
    })

    this.props.clearContentInQuestions({
      question,
      cafetery,
      tags,
      questionId: this.props.questionId,
      blockPagesId: this.props.blockPagesId,
      setBlocksId: this.props.setBlocksId,
    }).then(() => {
      this.setState({
        loading: false,
      });
      this.props.toggleModal();
    }).catch(() => {
      this.setState({
        loading: false,
      });
    })
  }

  render() {
    const {loading, cafetery, question, tags} = this.state;

    return (
      <Modal isOpen={true} toggle={this.props.toggleModal} size="lg">
        <ModalHeader tag="h2" toggle={this.props.toggleModal}>Potwierdź czyszczenie znaczników w treści</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label xs={5}>Znaczniki które <u className="font-weight-bold">nie zostaną</u> usunięte</Label>
              <Col className="d-flex align-items-center">
                <TagsSelect
                  value={tags}
                  onChange={tags => this.setState({tags})}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label xs={5}>Wyczyść znaczniki w treści pytań</Label>
              <Col className="d-flex align-items-center">
                <Switch checked={question} onChange={question => this.setState({question})} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label xs={5}>Wyczyść znaczniki w treści odpowiedzi</Label>
              <Col className="d-flex align-items-center">
                <Switch checked={cafetery} onChange={cafetery => this.setState({cafetery})} />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={this.props.toggleModal}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.confirm">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mb-0"
              loading={loading}
              disabled={!question && !cafetery}
              data-style={ZOOM_OUT}
              onClick={this.clear}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    );
  }
}

ClearContentInQuestions.propTypes = {
  questionId: PropTypes.number,
  blockPagesId: PropTypes.number,
  setBlocksId: PropTypes.number,
  clearContentInQuestions: PropTypes.func.isRequired,
};

export default connect(null, {clearContentInQuestions})(ClearContentInQuestions);