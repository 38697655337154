import React from 'react';
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody, Input, ModalFooter, Button} from "reactstrap";
import { connect } from 'react-redux';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';

function PasswordConfirmationModal(props) {
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const checkPassword = () => {
    setLoading(true);
    setErrors({});

    api.user.post.passwordConfirmation(password)
      .then(() => {
        setLoading(false);
        setPassword('');
        props.onSuccess();
      })
      .catch(e => {
        setLoading(false);
        setErrors({password: e.response.data.errors[0].message});
      })
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggleModal}>
      <ModalHeader toggle={props.toggleModal} tag="h2">Potwierdzenie hasła</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        Potwierdź decyzję podając hasło do konta
        <Input
          type="password"
          id="password"
          name="password"
          onChange={e => setPassword(e.target.value)}
          value={password}
          invalid={!!errors.password}
        />
        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={props.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.confirm">{
          (message) => <LaddaButton
            className="btn btn-success btn-ladda"
            loading={loading}
            data-style={ZOOM_OUT}
            onClick={checkPassword}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>
  );
}

PasswordConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func
};

export default connect(null, {})(PasswordConfirmationModal);