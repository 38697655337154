import api from "api";
import * as types from './actionTypes';

export function setPrizeCategories(prizeCategories) {
  return {
    type: types.SET,
    prizeCategories
  }
}

export function prizeCategoryDeleted(prizeCategory) {
  return {
    type: types.DELETED,
    prizeCategory
  }
}

export function prizeCategoryUpdated(prizeCategory) {
  return {
    type: types.UPDATED,
    prizeCategory
  }
}

export function prizeCategoryAdded(prizeCategory) {
  return {
    type: types.ADDED,
    prizeCategory
  }
}

export function fetchPrizeCategories() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.prizeCategory.get().then(prizeCategories => {
      let orderedPrizeCategories = {};
      _.map(prizeCategories, prizeCategory => orderedPrizeCategories[prizeCategory.id] = prizeCategory);

      dispatch(setPrizeCategories(orderedPrizeCategories));
      dispatch({type: types.SUCCESS});
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}

export function deletePrizeCategory(prizeCategory) {
  return dispatch => {
    return api.prizeCategory.delete(prizeCategory).then(() => {
      dispatch(prizeCategoryDeleted(prizeCategory));
    })
  }
}

export function updatePrizeCategory(data) {
  return dispatch => {
    return api.prizeCategory.patch(data).then(prizeCategory => {
      dispatch(prizeCategoryUpdated(prizeCategory));
    })
  }
}

export function addPrizeCategory(data) {
  return dispatch => {
    return api.prizeCategory.post(data).then((prizeCategory) => {
      dispatch(prizeCategoryAdded(prizeCategory));
    })
  }
}