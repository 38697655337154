import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {
  Button,
  Input,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup, Label, Col
} from "reactstrap";
import classnames from "classnames";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Switch from "components/Switch";
import HtmlEditor
  from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";
import {connect} from "react-redux";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";

class SendEmailModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      tooltip: false,
      saving: false,
      data: {
        sendEmail: !!props.question.sendEmail,
        sendEmailSubject: props.question.sendEmailSubject || '',
        sendEmailFromName: props.question.sendEmailFromName || '',
        sendEmailContent: props.question.sendEmailContent || '',
      }
    };

    this.modalToggle = this.modalToggle.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveData = this.saveData.bind(this);
  }

  modalToggle() {
    if(!this.props.questionSettingsPlan.open.sendEmail){
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());
      return;
    }

    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    });
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(name, value){
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  }

  saveData(){
    const {data} = this.state;

    this.setState({
      saving: true,
    });

    this.props.handleUpdate(data)
    .then(data => {
      this.setState({
        saving: false,
        modalIsOpen: false,
      });
    })
    .then(res => {
      this.setState({
        saving: false,
      });
    });
  }

  render() {
    let { modalIsOpen, tooltip, saving, data } = this.state;

    const modal = <Modal isOpen={modalIsOpen} toggle={this.modalToggle} size="xl">
      <ModalHeader tag="h2" toggle={this.modalToggle}>Ustawienia wiadomości wysyłanej po zakończeniu ankiety</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label md={3}>Wysłać maila po zakończeniu ankiety?</Label>
            <Col md={9} className="d-flex align-items-center">
              <Switch className="mb-0" onChange={value => this.onChange('sendEmail', value)} checked={data.sendEmail} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3}>Tytuł maila</Label>
            <Col md={9} className="d-flex align-items-center">
              <Input value={data.sendEmailSubject} onChange={e => this.onChange('sendEmailSubject', e.target.value)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3}>Od kogo</Label>
            <Col md={9} className="d-flex align-items-center">
              <Input value={data.sendEmailFromName} onChange={e => this.onChange('sendEmailFromName', e.target.value)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3}>Treść maila</Label>
            <Col md={9} className="d-flex align-items-center">
              <HtmlEditor
                config={{
                  width: '100%'
                }}
                data={data.sendEmailContent}
                onChange={event => this.onChange('sendEmailContent', event)}
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.modalToggle}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.saveData}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return <React.Fragment>
      <Button
        className={classnames({
          'mr-1 btn-icon float-left': true,
          'active': this.props.question.sendEmail,
          'disabled': !this.props.questionSettingsPlan.open.sendEmail,
        })}
        color="secondary"
        id={"sendEmail-" + this.props.question.id}
        onClick={this.modalToggle}
      >
        <i className="fa-solid fa-envelope" />
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={"sendEmail-" + this.props.question.id}
          toggle={() => this.toggleTooltip()}
        >Ustawienia wiadomości wysyłanej po zakończeniu ankiety</Tooltip>
      </Button>
      {modal}
    </React.Fragment>
  }
}

SendEmailModal.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    questionSettingsPlan: state.user.userPlan.plan.survey.questionSettings
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(SendEmailModal);
