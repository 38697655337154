import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';

class CollectorsModalDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      clearing: false
    }
  }

  deleteResponses(){
    this.setState({clearing: true});

    api.surveyCollector.delete.responses(this.props.collector)
    .then(() => {
      this.setState({clearing: false});
      this.props.handleCollectorChange(this.props.collector.id, 'endResponsesCount', 0);
      this.props.handleCollectorChange(this.props.collector.id, 'screenOutResponsesCount', 0);
      this.props.toggleModal();
    })
    .catch(() => {
      this.setState({clearing: false});
    });
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
        <ModalHeader tag="h2" toggle={this.props.toggleModal}><FormattedMessage id="collectors.listItems.modalClear.modalHeader" /> <span>{this.props.collector.name || <FormattedMessage tagName="h2" id={`_.surveyCollector.${this.props.collector.type}.name`}>{msg => msg + ' ' + this.props.collector.position}</FormattedMessage>}</span></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
          <p><FormattedMessage id="collectors.listItems.modalClear.modalBody" /></p>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.modal.delete.confirmation">{
            (message) => <LaddaButton
              className="btn btn-danger btn-ladda"
              data-style={ZOOM_OUT}
              onClick={() => this.deleteResponses()}
              loading={this.state.clearing}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggleModal}>{message}</Button>
          }</FormattedMessage>

        </ModalFooter>
      </Modal>
    )
  }
}

CollectorsModalDelete.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleCollectorChange: PropTypes.func.isRequired,
};

export default CollectorsModalDelete;
