import * as types from './actionTypes';
import api from 'api';

function surveySharesSet(surveyShares) {
  return {
    type: types.SET,
    surveyShares
  }
}

function surveyShareDeleted(surveyShare) {
  return {
    type: types.DELETED,
    surveyShare
  }
}

function surveyShareAdded(surveyShare) {
  return {
    type: types.ADDED,
    surveyShare
  }
}

export function fetchSurveyShares(surveyId) {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.survey.get.surveyShares(surveyId).then((surveyShares) => {
      let orderedSurveyShares = {};
      _.each(surveyShares, surveyShare => orderedSurveyShares[surveyShare.id] = surveyShare);

      dispatch(surveySharesSet(orderedSurveyShares));
      dispatch({type: types.SUCCESS});
    })
  }
}

export function deleteSurveyShare(surveyShare) {
  return dispatch => {
    return api.surveyShare.delete(surveyShare).then(() => {
      dispatch(surveyShareDeleted(surveyShare));
    })
  }
}

export function saveSurveyShare(data) {
  return dispatch => {
    return api.survey.post.surveyShare(data).then(surveyShare => {
      dispatch(surveyShareAdded(surveyShare));
    })
  }
}