import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Button, Col, Label, Row} from "reactstrap";
import {updateAnalysis} from "../../../../reducer/analysis/actions";
import _ from 'lodash';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import QuestionsSelect from "components/Survey/Analysis/QuestionsSelect";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import AnalysisFilteringAvailable from "components/PlanRestrictionsModals/modals/AnalysisFilteringAvailable";

class VisibleQuestions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      checked: this.getChecked(),
      expanded: [],
      saving: false
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  getChecked() {
    let checked = [];
    this.props.analysis && this.props.analysis.visibleQuestions.length === 0
      ? _.each(this.props.questions, question => checked.push(question.id))
      : _.each(this.props.analysis.visibleQuestions, question => checked.push(question.id));

    return checked;
  }

  onSave() {
    this.setState({...this.state, saving: true});
    let data = this.state.checked.length < Object.keys(this.props.questions).length ? this.state.checked : [];

    this.props.updateAnalysis({
      id: this.props.analysis.id,
      analysis: {
        visibleQuestions: data
      }
    }).then(() => {
      this.setState({...this.state, saving: false});
      this.props.onSaved()
    });
  }

  onCancel() {
    let checked = [];
    _.each(this.props.questions, question => checked.push(question.id));
    this.setState({
      ...this.state,
      checked: checked
    }, () => this.onSave());
  }

  render() {
    return (
      <React.Fragment>
        <Row className="mt-4">
          <Col>
            <Label>
              <FormattedMessage id="visibleQuestions.header">{msg => {
                return <span className="float-left">{msg} ({this.state.checked.length})</span>
              }}</FormattedMessage>
            </Label>
          </Col>
        </Row>
        <Row onClick={() => this.props.analysisPlan.filtering ? null : this.props.setPlanRestrictionsModals(AnalysisFilteringAvailable.getName())}>
          <Col>
            <QuestionsSelect survey={{id: this.props.analysis.survey}} disabled={!this.props.analysisPlan.filtering} onChange={checked => this.setState({ checked })} checked={_.map(this.props.analysis.visibleQuestions, question => question.id)}/>
            <div className="mt-3 float-right">
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-sm btn-primary btn-ladda"
                  loading={this.state.saving}
                  data-style={ZOOM_OUT}
                  onClick={this.onSave}
                  disabled={!this.props.analysisPlan.filtering}
                >{message}</LaddaButton>
              }</FormattedMessage>
              <Button color="secondary" size="sm" disabled={!this.props.analysisPlan.filtering} onClick={this.onCancel}><FormattedMessage id="_.button.cancel"/></Button>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

VisibleQuestions.propTypes = {
  onSaved: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
    sets: state.survey.analysis.data.sets,
    blocks: state.survey.analysis.data.blocks,
    pages: state.survey.analysis.data.pages,
    questions: state.survey.analysis.data.questions,
    analysisPlan: state.user.userPlan.plan.analysis
  }
}

export default connect(mapStateToProps, {updateAnalysis, setPlanRestrictionsModals})(VisibleQuestions);