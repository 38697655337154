import React from "react";
import PropTypes from "prop-types";
import {Input} from 'reactstrap';

const defaultButton = props => <button {...props}>{props.children}</button>;

export default class Pagination extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visiblePages: this.getVisiblePages(null, this.getPagesCount(props)),
      pageSizes: [10, 50, 100, 250],
    };

    this.changePage = this.changePage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.getPagesCount(this.props) !== this.getPagesCount(nextProps)) {
      this.setState({
        visiblePages: this.getVisiblePages(null, this.getPagesCount(nextProps))
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages(visiblePages, totalPages) {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages(page, total) {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.getPagesCount(this.props));

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.getPagesCount(this.props))
    });

    this.props.onPageChange(page - 1);
  }

  getPagesCount(props) {
    if (!props.rowsCount) {
      return props.pages;
    }

    return Math.ceil((props.rowsCount || props.data.length) / props.pageSize);
  }

  render() {
    const {PageButtonComponent = defaultButton} = this.props;
    const {visiblePages, pageSizes} = this.state;
    const activePage = this.props.page + 1;

    return (
      <div className="d-flex justify-content-between">
        <small>
          Liczba wierszy: {this.props.rowsCount || this.props.data.length}
        </small>
        <div className="d-flex align-items-center mt-1">
          <Input type="select" name="select" className="float-right w-auto mb-0" value={this.props.pageSize} onChange={e => this.props.onPageSizeChange(e.target.value)}>
            {pageSizes.map(v => <option>{v}</option>)}
          </Input>
          <ul className="pagination mb-0">
            <li className="page-item">
              <PageButtonComponent
                className="page-link"
                onClick={() => {
                  if (activePage === 1) return;
                  this.changePage(activePage - 1);
                }}
                disabled={activePage === 1}
              >
                <span className="icon-left" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </PageButtonComponent>
            </li>
            <li className="page-item">
              {visiblePages.map((page, index, array) => {
                return (
                  <PageButtonComponent
                    key={page}
                    className={
                      activePage === page
                        ? "d-inline-block page-link active"
                        : "d-inline-block page-link"
                    }
                    onClick={this.changePage.bind(null, page)}
                  >
                    {array[index - 1] + 2 < page ? `...${page}` : page}
                  </PageButtonComponent>
                );
              })}
            </li>
            <li className="page-item">
              <PageButtonComponent
                className="page-link"
                onClick={() => {
                  if (activePage === this.getPagesCount(this.props)) return;
                  this.changePage(activePage + 1);
                }}
                disabled={activePage === this.getPagesCount(this.props)}
              >
                <span className="icon-right" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </PageButtonComponent>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  PageButtonComponent: PropTypes.any,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  previousText: PropTypes.string,
  nextText: PropTypes.string,
  rowsCount: PropTypes.number,
  data: PropTypes.array,
  pageSize: PropTypes.number,
};