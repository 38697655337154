import React, {Component} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import {withRouter} from "react-router-dom";

import {FormattedMessage} from "react-intl";
import HelpModeIconModal from "../../../components/HelpModeIconModal";
import TableList from "../../../components/RespondentSms/Base/TableList";
import ButtonAdd from "../../../components/RespondentSms/Base/ButtonAdd";
import appRoutes from "../../../appRoutes";

class Bases extends Component {
  render() {
    return <Card>
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Bazy respondentów SMS <HelpModeIconModal modalHeader="Bazy respondentów SMS">
              <p>Lista baz respondentów sms do zapraszania do wypełniania ankiet</p>
            </HelpModeIconModal></h2>
          </div>

          <ButtonAdd onAdded={base => this.props.history.push(appRoutes.respondentSmsBase.base(base.id))} />
        </div>
      </CardHeader>
      <hr className="my-0" />
      <CardBody>
        <TableList />
      </CardBody>
    </Card>;
  }
}

export default withRouter(Bases);