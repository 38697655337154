import React, {Component} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import StatNumbers from "./components/StatNumbers";
import ResponsesChart from "./components/ResponsesChart";
import moment from "moment";
import DatePicker from "react-datepicker";

class Insights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      dateTo: moment().format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    document.title = 'Ankieteo - Statystyki';
  }

  render() {
    const {dateFrom, dateTo} = this.state;

    return <Card className="h-100 mb-0">
        <CardHeader className="d-flex justify-content-between">
          <h2><i className="fas fa-chart-area mr-2" /> Statystyki</h2>

          <div className="d-flex h-100 justify-content-center align-items-center">
            <DatePicker
              dropdownMode="select"
              className="form-control w-100 mb-0"
              isClearable={false}
              selected={moment(dateFrom)}
              onChange={(date) => this.setState({dateFrom: date.format('YYYY-MM-DD')})}
              dateFormat="Y-MM-DD"
              locale={localStorage.language}
            />
            <div className="px-2 d-flex align-items-center"> - </div>
            <DatePicker
              dropdownMode="select"
              className="form-control w-100 mb-0"
              isClearable={false}
              selected={moment(dateTo)}
              onChange={(date) => this.setState({dateTo: date.format('YYYY-MM-DD')})}
              dateFormat="Y-MM-DD"
              locale={localStorage.language}
            />
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <StatNumbers dateFrom={dateFrom} dateTo={dateTo} />

          <div className="mt-5">
            <ResponsesChart dateFrom={dateFrom} dateTo={dateTo} />
          </div>
        </CardBody>
      </Card>
  }
}

export default Insights;