import React, {Component} from 'react';
import {Card, CardHeader, CardBody} from "reactstrap";
import NotificationsTable from "../componentsNew/Notifications/NotificationsTable";

class NotificationsView extends Component {

  componentDidMount() {
    document.title = 'Historia powiadomień | ANKIETEO';
  }

  render() {
    return (
      <Card>
        <CardHeader className="p-0">
          <h2><strong>Historia powiadomień</strong></h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <NotificationsTable />
        </CardBody>
      </Card>
    )
  }
}

export default NotificationsView
