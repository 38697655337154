import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import {saveGroup} from '../actions.js';
import GroupForm from './GroupForm'
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import GroupsAvailable from "components/PlanRestrictionsModals/modals/GroupsAvailable";

class GroupCreateButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      creating: false,
      errors: {}
    };

    this.groupForm = React.createRef();
    this.saveGroup = this.saveGroup.bind(this);
  }

  saveGroup(e, data){
    e.preventDefault();
    this.setState({creating: true, errors: {}});

    this.props.saveGroup({
      group: {
        name: data.name
      }
    }).catch(e => {
      this.setState({
        creating: false,
        errors: e.response.data.form.errors,
      });
    });
  }

  toggleCreateModal(){
    if (!this.props.plan.groups) {
      this.props.setPlanRestrictionsModals(GroupsAvailable.getName());
      return;
    }
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { creating, errors } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleCreateModal()}>
      <ModalHeader toggle={() => this.toggleCreateModal()} tag="h2"><FormattedMessage id="groupCreateButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <GroupForm onSubmit={this.saveGroup} ref={this.groupForm} errors={errors}/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={creating}
            data-style={ZOOM_OUT}
            onClick={e => this.saveGroup(e, this.groupForm.current.state)}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleCreateModal()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <FormattedMessage id="groupCreateButton.button">{
          (message) =>
            <Button color="primary" className={this.props.plan.groups ? '' : 'disabled'} size="sm" onClick={() => this.toggleCreateModal()}><i className="fas fa-plus" /> {message}</Button>
        }</FormattedMessage>
        {modal}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, { saveGroup, setPlanRestrictionsModals }, null, {forwardRef: true})(GroupCreateButton);