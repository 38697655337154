import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import WidgetsAvailable from "./components/WidgetsAvailable";

class CreateModal extends Component {

  render() {
    return (
      <Modal isOpen={this.props.isOpen} size="xl" toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle} tag={"h2"}><FormattedMessage id="dashboard.buttonCreate.modalHeader" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <WidgetsAvailable toggleModal={this.props.toggle}/>
        </ModalBody>
      </Modal>
    )
  }
}

CreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default CreateModal;