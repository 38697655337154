import React, {Component} from 'react';
import api from "api";
import ApplicationTable from "components/Application/ApplicationTable";
import SearchTextInput from "components/Application/ApplicationTable/SearchTextInput";
import {Link} from "react-router-dom";
import appRoutes from "../../../../appRoutes";
import Select from "react-select";

class DashboardNotifiesTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      filters: {
        level: 0,
      }
    }

    this.changeFilter = this.changeFilter.bind(this);
  }

  changeFilter(key, value){
    this.setState({
      filters: {
        ...this.state.filters,
        [key]: value
      }
    }, () => {
      this.tableRef.current.setFilters(this.state.filters);
      this.tableRef.current.fetchDataDelay(1);
    })
  }

  render() {
    const {filters} = this.state;

    const columns = [
      {
        id: 'title',
        Header: 'Tytuł',
        accessor: row => row.title
      },
      {
        id: 'dateFrom',
        Header: 'Data od',
        accessor: row => row.dateFrom
      },
      {
        id: 'dateTo',
        Header: 'Data do',
        accessor: row => row.dateTo
      },
      {
        id: 'layout',
        Header: 'Layout',
        accessor: row => row.layout
      },
      {
        id: 'plans',
        Header: 'Plany',
        accessor: row => row.plans.map(plan => plan.name).join(', ')
      },
      {
        id: 'action',
        Header: '',
        width: 100,
        accessor: row => {
          return <div className="d-flex">
            <Link to={appRoutes.admin.cms.dashboardNotifies.edit(row.id)}><i className="fas fa-edit" /></Link>
          </div>
        }
      },
    ];

    return <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <Link className="btn btn-primary d-block" to={appRoutes.admin.cms.dashboardNotifies.add}><i className="fas fa-plus" /> Dodaj</Link>
        <div className="d-flex ml-auto">
          <SearchTextInput
            tableRef={this.tableRef}
          />
        </div>
      </div>
      <ApplicationTable
        ref={this.tableRef}
        columns={columns}
        fetchData={api.dashboardNotifies.search}
        deleteData={api.dashboardNotifies.delete}
        filters={filters}
      />
    </React.Fragment>;
  }
}

export default DashboardNotifiesTable;
