import React, {Component} from 'react';
import api from "api";
import ApplicationTable from "components/Application/ApplicationTable";
import appRoutes from "appRoutes";
import history from "services/history";
import SummaryAddModal from "scenes/Survey/Summary/components/SummaryAddModal";

class SummaryTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  render() {
    const columns = [
      {
        id: 'name',
        Header: 'Nazwa',
        width: 220,
        accessor: row => row.name,
      },
      {
        id: 'password',
        Header: 'Hasło',
        width: 220,
        accessor: row => row.password,
      },
      {
        id: 'link',
        Header: 'Link',
        accessor: row => <a href={document.location.origin + appRoutes.surveyShared.summary(row.guid)} target="_blank">{document.location.origin + appRoutes.surveyShared.summary(row.guid)}</a>,
      },
    ];

    return <React.Fragment>
      <div className="d-flex justify-content-between mb-2">
        <SummaryAddModal
          surveyId={this.props.surveyId}
          onModalClose={() => {}}
          onAdded={dictionary => {
            this.tableRef.current.fetchData({})
          }}
        />

        <div className="ml-auto">
          {/*<SearchTextInput*/}
          {/*  tableRef={this.tableRef}*/}
          {/*/>*/}
        </div>
      </div>
      <ApplicationTable
        ref={this.tableRef}
        columns={columns}
        fetchData={api.surveyShared.summary.search}
        deleteData={api.surveyShared.summary.delete}
        filters={this.props.filters}
      />
    </React.Fragment>;
  }
}

export default SummaryTable;
