import * as React from 'react';
import {Card, CardBody, CardHeader, Col, Row, Tooltip} from "reactstrap";
import CaseStudiesList from "./caseStudiesList";
import CaseStudyCreateButton from "./components/CaseStudyCreateButton";

function CaseStudies() {

	return (
		<Row className="animated fadeIn">
			<Col>
				<Card>
					<CardHeader>
						<div className="direction-group">
							<div className="direction-left d-flex justify-content-start">
								<h2 className="float-left mr-4">
									Case Study
								</h2>
							</div>
						</div>
					</CardHeader>
					<hr className="my-0" />
					<CardBody>
						<Row>
							<Col>
								<CaseStudiesList />
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		</Row>
	)
}

export default CaseStudies;