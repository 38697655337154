import _ from "lodash";
import { combineReducers } from 'redux';
import groupReducer from './groupReducer';
import invitationReducer from './invitationReducer';
import {ERROR, REQUEST, SUCCESS} from "./actionTypes";

const dataReducer = combineReducers({
  groups: groupReducer,
  invitations: invitationReducer,
});

const loadingReducer = (state, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case SUCCESS:
    case ERROR:
      return false;
    default:
      return state;
  }
};

const initialState = { loading: false };
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      const rest = _.omit(state, Object.keys(initialState));
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: dataReducer(rest.data, action)
      };
  }
};

