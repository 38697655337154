import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import Select from "react-select";

class SelectBaskets extends Component {
  constructor(props){
    super(props);

    this.select = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  onChange(option){
    const {isMulti} = this.props;

    if(isMulti){
      this.select.current.setState({isOpen: true, menuIsOpen: true});
    }

    if(isMulti){
      this.props.onChange(option.map(obj => obj.value));
    }else{
      this.props.onChange(option ? option.value: false);
    }
  }


  render() {
    const {baskets, value, isMulti, className, isClearable} = this.props;

    return (
      <Select
        ref={this.select}
        isClearable={isClearable}
        className={className}
        value={value && (!isMulti ?
            {
              label: <span className="img-20" dangerouslySetInnerHTML={{__html: baskets.find(b => b.id === value).position + '.' + baskets.find(b => b.id === value).stripedContent}}/>,
              value
            }
          :
          value.map(bId => {
            return {
              label: <span className="img-20" dangerouslySetInnerHTML={{__html: baskets.find(b => b.id === bId).position + '. ' + baskets.find(b => b.id === bId).stripedContent}}/>,
              value: bId,
            }
          })
        )}
        isOpen={false}
        isMulti={isMulti}
        onChange={this.onChange}
        options={baskets.map(basket => {
          return {
            label: <span className="img-20" dangerouslySetInnerHTML={{__html: basket.position + '. ' + basket.stripedContent}}/>,
            value: basket.id,
          }
        })}
      />
    );
  }
}

SelectBaskets.defaultProps = {
  isMulti: true,
  isClearable: false,
  className: '',
};

SelectBaskets.propTypes = {
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
  baskets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    stripedContent: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number,]),
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  let baskets = state.survey.structure.data.questions[props.questionId].baskets.map(bId => state.survey.structure.data.baskets[bId]);

  let position = 0;
  baskets = baskets.map(basket => {
    position++;
    return {
      ...basket,
      position
    }
  });

  return {
    baskets,
  }
}

export default connect(mapStateToProps)(SelectBaskets);