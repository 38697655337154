import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import SliderValue from "../components/SliderValue";
import Header from "./Header";
import Instruction from "./Instruction";
import Validation from "./Validation";
import AnswerArea from "./AnswerArea";
import CafeteryWrap from "./CafeteryWrap";
import CafeteryWrapExcluding from "./CafeteryWrapExcluding";
import Radio from "./Radio";
import Checkbox from "./Checkbox";
import Buttons from "./Buttons";
import {isBrowser} from "react-device-detect";
import CafeteryWrapStatement from "./CafeteryWrapStatement";

class Question extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(){
    if(!document.getElementById('color-picker')){
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  render() {
    const {isOpen} = this.state;

    const props = {
      ownStyles: this.props.ownStyles,
      ownStylesDefault: this.props.ownStylesDefault,
      handlerUpdate: this.props.handlerUpdate,
    };

    return (
      <Dropdown toggle={this.toggle} isOpen={isOpen} inNavbar>
        <DropdownToggle className="py-3"><i className="far fa-list-alt" /></DropdownToggle>
        {isOpen && <DropdownMenu className="p-2" style={{
          height: isBrowser ? 'auto' : window.innerHeight - 100,
          width: isBrowser ? 650 : window.innerWidth,
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}>
          <div className="d-block">
            <h3 className="text-center">Pytania</h3>

            <Button color="" onClick={(e) => this.toggle(e)} className="ml-auto px-3" style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}><i className="fas fa-times mt-1"/> </Button>
          </div>
          <hr className="my-0 mb-2"/>
          <SliderValue
            {...props}
            keyName="$question-borderRadius"
            label="Zaokrąglonie rogów pytania"
            minValue={0}
            maxValue={25}
            stepValue={1}
          />
          <hr className="my-0 mb-2"/>
          <SliderValue
            {...props}
            keyName="$question-header-fontSize"
            label="Wielkość czcionki treści pytania"
            minValue={0}
            maxValue={35}
            stepValue={1}
          />
          <hr className="my-0"/>
          <Header {...props} />
          <hr className="my-0"/>
          <Instruction {...props} />
          <hr className="my-0"/>
          <Validation {...props} />
          <hr className="my-0 mb-2"/>
          <SliderValue
            {...props}
            keyName="$question-answerArea-fontSize"
            label="Wielkość czcionki w obszarze odpowiedzi"
            minValue={0}
            maxValue={35}
            stepValue={1}
          />
          <hr className="my-0"/>
          <Buttons {...props} />
          <hr className="my-0"/>
          <Radio {...props} />
          <hr className="my-0"/>
          <Checkbox {...props} />
          <hr className="my-0"/>
          <AnswerArea {...props} />
          <hr className="my-0 mb-2"/>
          <SliderValue
            {...props}
            measure={'ms'}
            keyName="$question-cafeteryWrap-transitionBgColor"
            label="Czas animacji zaznaczania odpowiedzi"
            minValue={0}
            maxValue={1500}
            stepValue={5}
          />
          <hr className="my-0"/>
          <CafeteryWrapStatement {...props} />
          <hr className="my-0"/>
          <CafeteryWrap {...props} />
          <hr className="my-0"/>
          <CafeteryWrapExcluding {...props} />
        </DropdownMenu>}
      </Dropdown>
    );
  }
}

Question.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  ownStyles: PropTypes.object,
  ownStylesDefault: PropTypes.object,
};

export default Question;
