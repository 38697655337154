import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import api from "../../../../../../../../../api";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";

class ColumnTfoot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      value1: false,
      excluding: false,
      modalIsOpen: false,
    };

    this.modalToggle = this.modalToggle.bind(this);
    this.save = this.save.bind(this);
  }

  modalToggle(value1 = false){
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
      value1,
      excluding: false,
    });
  }

  save(){
    const {value1, excluding} = this.state;
    const {question, responsesSelected} = this.props;

    this.setState({
      saving: true,
    });

    let data = {
      responses: responsesSelected,
      value1,
      excluding,
    };

    api.questionResponse.question.patchDetails(question.id, data)
    .then(data => {
      this.setState({
        saving: false,
        value1: false,
        excluding: false,
        modalIsOpen: false,
      });

      this.props.onSaved();
    })
    .catch(res => {
      this.setState({
        saving: false,
      });
    });
  }

  render() {
    const {modalIsOpen, saving, value1} = this.state;
    const {responsesSelected, question} = this.props;

    return <React.Fragment>
      <td className="border-left border-right">
        <Button disabled={responsesSelected.length === 0} size="sm" color="secondary" className="w-100 text-center" onClick={() => this.modalToggle('')}>Zmień</Button>
      </td>
      {question.excludings.length > 0 && <td>
        <Button
          onClick={() => this.modalToggle(false)}
          disabled={responsesSelected.length === 0}
          size="sm"
          color="secondary"
          className="w-100 text-center"
        >Zmień</Button>
      </td>}


      {modalIsOpen && <Modal isOpen={modalIsOpen} toggle={() => this.modalToggle(false)} size="xl">
        <ModalHeader tag="h2" toggle={() => this.modalToggle(false)}>Zmień odpowiedź dla wybranych wywiadów</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label md={3}>Liczba zaznaczonych wywiadów:</Label>
              <Col md={9} className="d-flex align-items-center">{responsesSelected.length}</Col>
            </FormGroup>
            {value1 !== false && <FormGroup row>
              <Label md={3}>W zaznaczonych wywiadach zmień odpowiedź na:</Label>
              <Col md={9} className="d-flex align-items-center">
                <Input type="text" onChange={e => this.setState({value1: e.target.value})} />
              </Col>
            </FormGroup>}
            {value1 === false && <FormGroup row>
              <Label md={3}>W zaznaczonych wywiadach zmień na odpowiedź wykluczającą:</Label>
              <Col md={9} className="d-flex align-items-center">
                <SelectExcludings
                  questionId={question.id}
                  className="w-100"
                  onChange={excluding => this.setState({excluding: excluding || false})}
                />
              </Col>
            </FormGroup>}
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={() => this.modalToggle(false)}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mb-0"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={() => this.save()}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>}

    </React.Fragment>;
  }
}

ColumnTfoot.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['numeric']),
  }).isRequired,
  responsesSelected: PropTypes.arrayOf(PropTypes.number,),
  onSaved: PropTypes.func.isRequired,
};

export default ColumnTfoot;