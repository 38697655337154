import React, {Component, useState} from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import overlayFactory from 'react-bootstrap-table2-overlay';
import api from "api";
import {
  ButtonGroup,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label, ListGroup, ListGroupItem, ListGroupItemHeading,
  Modal, ModalBody, ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import reactHtmlParser from "react-html-parser";
import striptags from "striptags";
import QuestionInfo from "../QuestionInfo";
import {getQuestionTemplateName} from "../../services/questionTypes";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import CreateQuestionTemplate from "../QuestionTemplates/components/CreateQuestionTemplate";
import Checkbox from "../Checkbox";
import Button from "../../../../survey/src/components/Button";
import "./style.scss";

const PreviewCafetery = (props) => {
  const {question} = props;
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  return <div className="mr-2 pointer">
    <i className="fas fa-search" onClick={toggle} />
    {modalOpen && <Modal isOpen={true} toggle={toggle} size="xl">
      <ModalHeader tag="h2" toggle={toggle}>Podgląd kafeterii</ModalHeader>
      <hr className="my-0"/>
      <ModalBody className="question-preview">
        <div dangerouslySetInnerHTML={{__html: question.content}} />

        {question.answers && <ListGroup>
          <ListGroupItemHeading className="h2">Lista odpowiedzi:</ListGroupItemHeading>
          {question.answers.map(answer => {
            return <ListGroupItem className="p-2" key={'a-' + answer.id.toString()}>{question.answersImage ? <img src={answer.content} /> : <div dangerouslySetInnerHTML={{__html: answer.content}} />}</ListGroupItem>
          })}
        </ListGroup>}

        {question.rows && <ListGroup className="mt-4">
          <ListGroupItemHeading className="h2">Lista wierszy:</ListGroupItemHeading>
          {question.rows.map(row => {
            return <ListGroupItem className="p-2" key={'r-' + row.id.toString()}>{question.rowsImage ? <img src={row.content} /> : <div dangerouslySetInnerHTML={{__html: row.content}} />}</ListGroupItem>
          })}
        </ListGroup>}

        {question.columns && <ListGroup className="mt-4">
          <ListGroupItemHeading className="h2">Lista kolumn:</ListGroupItemHeading>
          {question.columns.map(column => {
            return <ListGroupItem className="p-2" key={'c-' + column.id.toString()}>{question.columnsImage ? <img src={column.content} /> : <div dangerouslySetInnerHTML={{__html: column.content}} />}</ListGroupItem>
          })}
        </ListGroup>}
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.close">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggle}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </div>
};

const RemotePagination = ({ loading, data, page, sizePerPage, onTableChange, totalSize, sortField, sortOrder, fetchData }) => {
  const columns = [
    {
      dataField: 'content',
      text: 'Treść pytania',
      sort: false,
      editable: false,
      formatter: (cell, row) => {
        return <div className="d-flex align-items-center">
          <PreviewCafetery question={row[0]} />
          <div>
            {row[0].content ? reactHtmlParser(striptags(row[0].content)) : <i>Brak treści pytania</i>}
          </div>
        </div>;
      }
    },
    {
      dataField: 'survey',
      text: 'Ankieta',
      sort: false,
      editable: false,
      headerStyle: {
        width: '350px'
      },
      formatter: (cell, row) => {
        return row.surveyName;
      }
    },
    {
      dataField: 'type',
      text: 'Rodzaj pytania',
      sort: false,
      editable: false,
      headerStyle: {
        width: '210px'
      },
      formatter: (cell, row) => {
        return <span><QuestionInfo question={row[0]} /> <FormattedMessage id={`_.question.${getQuestionTemplateName(row[0])}.typeName`}/></span>;
      }
    },
    {
      dataField: 'id',
      text: 'Id',
      sort: true,
      editable: false,
      headerStyle: {
        width: '110px'
      },
      formatter: (cell, row) => {
        return row[0].id;
      }
    },
    {
      dataField: 'createdAt',
      text: 'Data',
      sort: false,
      editable: false,
      headerStyle: {
        width: '80px'
      },
      formatter: (cell, row) => {
        return row.surveyCreatedAt ? moment(row.surveyCreatedAt).format('YYYY-MM-DD') : <i>Brak daty</i>;
      }
    },
    {
      dataField: 'actions',
      text: 'Opcje',
      editable: false,
      headerStyle: {
        width: '160px'
      },
      formatter: (cell, row) => {
        return <ButtonGroup className="d-flex justify-content-end">
          <CreateQuestionTemplate onSaved={() => {}} questionTemplate={{
            id: row[0].id,
            content: row[0].content,
            description: '',
            tags: [],
          }} />
        </ButtonGroup>
      }
    }
  ];

  return <BootstrapTable
    classes="table-sm"
    striped
    hover
    loading={loading}
    remote
    sort={{
      dataField: sortField,
      order: sortOrder,
    }}
    keyField="id"
    data={ data }
    columns={ columns }
    pagination={ paginationFactory({ page, sizePerPage, totalSize }) }
    onTableChange={(type, values) => onTableChange(type, values) }
    overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(0,0,0,0.3)'}) } }) }
    noDataIndication={() => !loading ? 'Brak danych' : 'Pobieram dane...'}
    cellEdit={ cellEditFactory({
      mode: 'click',
      blurToSave: true,
    }) }
  />
};

class SurveyQuestionsBase extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      search: '',
      filters: {
        templateCreated: false,
      },
      searchTimeout: null,
      sortField: 'id',
      sortOrder: 'desc',
    };

    this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  handleFilterChange(key, value){
    this.setState({
      filters: {
        ...this.state.filters,
        [key]: value
      }
    }, () => this.fetchData());
  }

  handleOnChangeSearch(e){
    clearTimeout(this.state.searchTimeout);
    const search = e.target.value;

    if(e.keyCode === 13){
      this.setState({
        search,
        searchTimeout: null,
      }, () => {
        this.fetchData();
      });
    }else{
      this.setState({
        search,
        searchTimeout: setTimeout(() => {
          this.fetchData();
        }, 1000)
      });
    }
  }

  handleTableChange(type, values) {
    switch (type) {
      case 'pagination': {
        this.setState({
          sizePerPage: values.sizePerPage,
          page: values.page,
        }, () => {
          this.fetchData()
        });
        break;
      }
      case 'sort': {
        this.setState({
          sizePerPage: values.sizePerPage,
          page: values.page,
          sortField: values.sortField,
          sortOrder: values.sortOrder,
        }, () => {
          this.fetchData()
        });
        break;
      }
      default: {
        return;
      }
    }
  };

  fetchData() {
    const {sizePerPage, page, search, filters, sortField, sortOrder} = this.state;

    this.setState({loading: true});

    api.user.surveysQuestions.search({
      page,
      limit: sizePerPage,
      search,
      filters,
    }, {
      sortField,
      sortOrder,
    })
    .then((res) => {
      this.setState({
        loading: false,
        data: res.data,
        totalSize: res.total,
      });
    })
    .catch(res => {
      this.setState({loading: false});
    })
  };

  render() {
    const {loading, data, page, sizePerPage, totalSize, sortField, sortOrder} = this.state;

    return <React.Fragment>
      <Row className="mb-2 d-flex justify-content-end">
        <Col>
          <div className="d-flex justify-content-end">
            <div className="d-flex align-items-center mr-3">
              <input type="checkbox" style={{width: '30px'}} className="mr-1 form-control" id="templateCreated" onChange={() => this.handleFilterChange('templateCreated', !this.state.filters.templateCreated)} />
              <Label for="templateCreated">Pokaż pytania z których został utworzony szablon</Label>
            </div>
            <InputGroup className="w-25">
              <Input placeholder="Szukaj..." onKeyUp={this.handleOnChangeSearch} />
              <InputGroupAddon addonType="append"><InputGroupText className="cursor-pointer"><i className="fa fa-search" /></InputGroupText></InputGroupAddon>
            </InputGroup>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <RemotePagination
            sortField={sortField}
            sortOrder={sortOrder}
            loading={ loading }
            data={ data }
            page={ page }
            sizePerPage={ sizePerPage }
            totalSize={ totalSize }
            onTableChange={ this.handleTableChange }
            fetchData={this.fetchData}
          />
        </Col>
      </Row>
    </React.Fragment>
  }
}

export default SurveyQuestionsBase;
