import React, {PureComponent, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import Email from "./DetailModal/Email";
import Panel from "./DetailModal/Panel";
import Tokens from "./DetailModal/Tokens";
import Tooltip from "../../../components/Tooltip";
import classnames from "classnames";
import api from "../../../api";
import axios from "axios";
import PanelSendEmailInfo from "./DetailModal/PanelSendEmailInfo";
import MobilePushSendModal from "./CollectorPanel/Tabs/MobilePushSendModal";
import {connect} from "react-redux";

const CancelToken = axios.CancelToken;

class PosibilityRange extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      range: false,
      loading: false,
      axiosToken: null,
    };

    this.count = this.count.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentWillUnmount() {
    const {axiosToken} = this.state;

    if(axiosToken){
      axiosToken.cancel();
    }
  }

  count(afterEndNext = false){
    const tok = CancelToken.source();

    this.setState({
      loading: true,
      axiosToken: tok
    });

    api.surveyCollector.panel.get.collectorPosibilityRange(this.props.collectorId, tok)
    .then(data => {
      this.setState({
        loading: false,
        range: data.count
      }, () => {
        if(afterEndNext){
          const target = document.querySelector('.collector-panel-posibility-range-count-trigger');
          if (target) {
            target.click();
          }
        }
      });
    })
  }

  onClick() {
    this.count();
  }

  render() {
    const {loading, range} = this.state;

    return <React.Fragment>
      <div className={classnames({
        'd-flex align-items-center justify-content-center': true,
      })}>
        {loading === true
          ? <i className="fas fa-spin fa-spinner" />
          : (range === false
              ? <React.Fragment>
                <span className="collector-panel-posibility-range-count-trigger" onClick={() => this.count(true)} />
                <i id={'collector-panel-'+this.props.collectorId+'-check'} onClick={this.onClick} className="fas fa-users pointer" />
                <Tooltip id={'collector-panel-'+this.props.collectorId+'-check'} msg="Kliknij aby sprawdzić szacowany zasięg kolektora" />

              </React.Fragment>
              : <React.Fragment>
                <span id={'collector-panel-'+this.props.collectorId+'-show'}>{range}</span>
                <Tooltip id={'collector-panel-'+this.props.collectorId+'-show'} msg="Szacowany zasięg" />
              </React.Fragment>
          )}
      </div>
    </React.Fragment>

  }
}

class DetailModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      detail: false,
      pushMobileOpen: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.getByCollectorType = this.getByCollectorType.bind(this);
  }

  getByCollectorType(){
    const {collector} = this.props;

    switch (collector.type) {
      case 'Email':
        return <Email collector={collector}/>;
      case 'Panel':
        return <Panel collector={collector} />;
      case 'Tokens':
        return <Tokens collector={collector} />;
      default:
        return <Alert color="warning" className="text-center">Brak dostępnych szczegółowych danych dla tego rodzaju kolektora</Alert>;
    }
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {modalOpen, pushMobileOpen} = this.state;
    const {collector, intl, plan} = this.props;

    if(['Email', 'Panel', 'Tokens'].indexOf(collector.type) < 0){
      return null;
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModal}>Szczegóły kolektora danych: <i>{collector.name ||  intl.formatMessage({ id: "_.surveyCollector." + collector.type + '.name' }) + ' ' + collector.position}</i></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        {this.getByCollectorType()}
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.close">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <div className="d-flex justify-content-around align-items-center">
        {
          collector.type === 'Panel'
          ? <PanelSendEmailInfo surveyId={this.props.surveyId} collector={this.props.collector} />
          : <span></span>
        }
        <Button color="" className="p-0 pr-1 mt-1" onClick={this.toggleModal}><i className="fas fa-info-circle" /></Button>
        {modal}
        {collector.type === 'Panel' && <PosibilityRange collectorId={this.props.collector.id} />}
        {collector.type === 'Panel' && plan.survey.isPanelMobilePushAllowed && <React.Fragment>
            <i id={'collector-panel-'+this.props.collectorId+'-check'} onClick={() => this.setState({pushMobileOpen: true})} className="fas fa-bell pointer" />
            <Tooltip id={'collector-panel-'+this.props.collectorId+'-check'} msg="Kliknij aby dokonać wysyłki push" />
          {pushMobileOpen && <MobilePushSendModal visible={pushMobileOpen} collector={this.props.collector} onCancel={() => this.setState({pushMobileOpen: false})}/>}
          </React.Fragment>}
      </div>
    );
  }
}

DetailModal.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));