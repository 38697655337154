import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import Select from 'react-select';
import {fetchSurvey} from "scenes/Survey/Structure/data/survey/actions";
import setTranslatableLangHeader from "utils/setTranslatableLangHeader";

class LanguageEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      lang: localStorage.translatableLang || props.defaultLang
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(inputValue) {
    const {survey} = this.props;

    this.setState({
      lang: inputValue.value
    });

    localStorage.translatableLang = inputValue.value;
    setTranslatableLangHeader(inputValue.value);

    this.props.fetchSurvey({
      id: survey.id
    });
  }

  render() {
    const {languages} = this.props;
    const {lang} = this.state;

    return (
        <Select
          className="w-100"
          size="xs"
          isClearable={false}
          value={{
            value: lang,
            key: lang,
            label: <span><img src={'/dashboard/img/icon/' + lang +'.png'} height="20" /> <FormattedMessage id={'_.lang.'+lang+'.text'}/>{lang === this.props.defaultLang && <FormattedMessage id={'languageEdit.defaultLang'}>{(msg) => <span> ({msg})</span>}</FormattedMessage>}</span>,
          }}
          options={languages.map(l => {
            return {
              value: l,
              label: <span><img src={'/dashboard/img/icon/' + l +'.png'} height="20" /> <FormattedMessage id={'_.lang.'+l+'.text'}/>{l === this.props.defaultLang && <FormattedMessage id={'languageEdit.defaultLang'}>{(msg) => <span> ({msg})</span>}</FormattedMessage>}</span>,
            }
          })}
          onChange={this.onChange}
          isSearchable={false}
          menuPlacement={this.props.placement}
        />
    )
  }
}

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

LanguageEdit.defaultProps = {
  placement: 'bottom'
};

LanguageEdit.propTypes = {
  defaultLang: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  placement: PropTypes.string.isRequired
};

export default connect(mapStateToProps, {fetchSurvey})(LanguageEdit);