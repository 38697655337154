import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {updateColumn} from "scenes/Survey/Structure/data/columns/actions";

class ColumnDescription extends Component {
  constructor(props){
    super(props);

    this.state = {
      saving: false,
      data: {
        description: props.column.description || ''
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      data: {
        ...this.state.data,
        description: nextProps.column.description || ''
      }
    })
  }

  onChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        description: e.target.value
      }
    });
  }

  onBlur(){
    const {data} = this.state;
    const {column} = this.props;

    this.setState({
      saving: true
    });

    this.props.updateColumn({
      id: column.id,
      column: data
    }).then(() => {
      this.setState({saving: false});
    }).catch(() => {
      this.setState({saving: false});
    })
  }

  render() {
    const {data, saving} = this.state;

    return (
      <FormattedMessage id="column.image.description.placeholder">{placeholder => <Input
        className={"mt-2"}
        disabled={saving}
        type="text"
        bsSize={"sm"}
        placeholder={placeholder}
        value={data.description}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />}</FormattedMessage>
    );
  }
}

ColumnDescription.propTypes = {
  columnId: PropTypes.number.isRequired,
  column: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
  }),
  updateColumn: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    column: Object.values(state.survey.structure.data.columns).find(column => column.id === props.columnId)
  }
}

export default connect(mapStateToProps, {updateColumn})(ColumnDescription);