import React from "react";
import PropTypes from "prop-types";
import ApplicationSelect from "components/Application/ApplicationSelect";
import api from "api";

function SwresearchCmsSelect(props){
  return <ApplicationSelect
    fetchById={api.swresearchCms.get}
    placeholder="Wybierz"
    searchData={api.swresearchCms.search}
    filters={{
      level: '0',
    }}
    value={props.swresearchCms ? props.swresearchCms.id : null}
    onChange={entity => props.onChange(entity)}
  />
}

SwresearchCmsSelect.propTypes = {
  swresearchCms: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};

export default SwresearchCmsSelect;