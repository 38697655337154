import * as React from 'react';
import PropTypes from "prop-types";
import {Alert, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import FiltersSerialGroupForm from "../../../../FiltersSerialGroup/FiltersSerialGroupForm";
import Button from "../../../../../../components/Button";
import {FormattedMessage} from "react-intl";
import Tooltip from "../../../../../../components/Tooltip";
import api from "../../../../../../api";

const DeleteFile = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [deleting, setDeleting] = React.useState(false);

  const deleteFile = () => {
    setDeleting(true);

    api.surveyResponse.questionResponse.removeFile(props.questionResponse.id)
    .then(res => {
      setDeleting(false);
      props.onRemoved();
      setModalOpen(false);
    })
    .catch(res => {
      setDeleting(false);
    })
  }

  return <div>
    <i id={`qr-${props.questionResponse.id}-file-remove-tooltip`} className="fas fa-times text-danger pointer p-2" onClick={toggleModalOpen} />
    <Tooltip id={`qr-${props.questionResponse.id}-file-remove-tooltip`} msg={'Usuń plik'} />
    {modalOpen && <Modal isOpen color="danger" toggle={toggleModalOpen} size="md">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Usuń plik</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <p>Czy na pewno usunąć wgrany plik?</p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <Button
          color="danger"
          loading={deleting}
          onClick={deleteFile}
        >Tak, usuń plik</Button>
      </ModalFooter>
    </Modal>}
  </div>
}

const UploadFile = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [uploading, setUploading] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const upload = () => {
    setUploading(true);

    const formData = new FormData();
    formData.append('file', file);

    api.surveyResponse.questionResponse.uploadFile(props.questionResponse.id, formData)
    .then(path => {
      setUploading(false);
      props.onUploaded(path);
      setModalOpen(false);
    })
    .catch(res => {
      setUploading(false);
    })
  }

  return <div>
    <i id={`qr-${props.questionResponse.id}-file-upload-tooltip`} className="fas fa-upload text-primary pointer p-2" onClick={toggleModalOpen} />
    <Tooltip id={`qr-${props.questionResponse.id}-file-upload-tooltip`} msg={'Wgraj plik'} />
    {modalOpen && <Modal isOpen color="danger" toggle={toggleModalOpen} size="md">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Wgraj plik</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <input type="file" onChange={e => setFile(e.target.files[0])} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <Button
          color="primary"
          loading={uploading}
          onClick={upload}
        >Wgraj</Button>
      </ModalFooter>
    </Modal>}
  </div>
}

function FileUpload(props) {
  const [filePath, setFilePath] = React.useState(props.questionResponse.filePath);

  return (
    <Row className="mb-2">
      <Col>
        <Alert color="info" className="d-flex align-items-center">
          <span className="mr-2">Plik respondenta:</span>
          {filePath && <div className="d-flex align-items-center">
            <Link to={`${props.filesystem_app_baseurl}/${props.questionResponse.filePath}`} target="_blank">Pobierz plik respondenta</Link>
            <DeleteFile
              questionResponse={props.questionResponse}
              onRemoved={() => setFilePath(undefined)}
            />
          </div>}
          {!filePath && <UploadFile
            questionResponse={props.questionResponse}
            onUploaded={filePath => setFilePath(filePath)}
          />}
        </Alert>
      </Col>
    </Row>
  );
}

FileUpload.propTypes = {
  questionResponse: PropTypes.shape({

  }).isRequired,
};

function mapStateToProps(state) {
  return {
    filesystem_app_baseurl: state.config.data.filesystem_app_baseurl
  }
}

export default connect(mapStateToProps)(FileUpload);