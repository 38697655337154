import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Elements from './components/Elements';
import GlobalTemplates from './components/GlobalTemplates';
import SearchInElements from './components/SearchInElements';
import {FormattedMessage} from "react-intl";
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import "./styles/style.scss"
import { connect } from 'react-redux';
import Favorite from "./components/Favorite";
import SurveyQuestionsBase from "../../../../../components/SurveyQuestionsBase";
import QuestionTemplates from "../../../../../components/QuestionTemplates";
import TemplatesModal from "./components/Templates/components/TemplatesModal";
import BlockTemplates from "../../../../../components/BlockTemplates";
import TemplatesAddModal from "../../../../../components/BlockTemplates/components/TemplatesAddModal";
import appRoutes from "../../../../../appRoutes";

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      option: 'Elements',
      tooltips: {
        favorite: false,
        elements: false,
        templates: false,
        globalTemplates: false
      },
      searchInElements: null,
      surveyQuestionBaseModal: false,
      questionTemplatesModal: false,
      questionTemplatesSelected: [],
      questionTemplatesAdd: false,
      blockTemplatesModal: false,
      blockTemplatesSelected: [],
      blockTemplatesAdd: false,
    };

    this.questionTemplates = React.createRef();

    this.keyboardMount = this.keyboardMount.bind(this);
    this.keyboardUnmount = this.keyboardUnmount.bind(this);
    this.switchOption = this.switchOption.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.setActiveStyle = this.setActiveStyle.bind(this);
    this.searchInElements = this.searchInElements.bind(this);
  }

  keyboardMount(){
    Mousetrap.bind("shift+x", () => {
      this.setState({
        questionTemplatesModal: true,
      })
    });
    Mousetrap.bind("shift+b", () => {
      this.setState({
        blockTemplatesModal: true,
      })
    });
  }

  keyboardUnmount(){
    Mousetrap.unbind("shift+x");
    Mousetrap.unbind("shift+b");
  }

  toggleTooltip(tooltipType) {
    this.setState({
      tooltips: {
        [tooltipType]:  !this.state.tooltips[tooltipType]
      }
    });
  }

  componentDidMount(){
    this.keyboardMount();

    const selfOffset = ReactDOM.findDOMNode(this).getBoundingClientRect();
    ReactDOM.findDOMNode(this).style.width = selfOffset.width+'px';

    window.onscroll = () => {
      if (window.pageYOffset >= selfOffset.top) {
        ReactDOM.findDOMNode(this).classList.add("sticky")
      } else {
        ReactDOM.findDOMNode(this).classList.remove("sticky");
      }
    };
  }

  componentWillUnmount(){
    this.keyboardUnmount();
    window.onscroll = false;
  }

  switchOption(option) {
    this.setState({option: option});
  }

  setActiveStyle(option) {
   return this.state.option === option ? 'active' : '';
  }

  searchInElements(text) {
    this.setState({searchInElements: text})
  }

  render() {
    const {questionTemplatesSelected, questionTemplatesAdd, blockTemplatesAdd, blockTemplatesSelected} = this.state;

    return (
      <aside className="d-none d-lg-block" id="structure-sidebar">
        <SearchInElements handleSearch={this.searchInElements}/>
        <div className="mt-2">
          <div className="accordion" id="accordionSidebar">

            <Button className="btn btn-link h3 mb-1 w-100 text-left pl-1" color="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
              <i className="fas fa-angle-down mr-2 active"/>
              <i className="fas fa-star mr-1"/>
              <FormattedMessage id="structure.sidebar.tooltip.favorite"/> ({this.props.user.favoriteQuestionTypes.length})
            </Button>

            <div id="collapseFour" className={"collapse pl-3 mb-2" + (this.props.user.favoriteQuestionTypes.length > 0 ? ' show' : '')} aria-labelledby="headingFavorite" data-parent="#accordionSidebar">
              <div className="mr-1">
                <Favorite searchText={this.state.searchInElements}/>
              </div>
            </div>

            <Button className="btn btn-link h3 mb-1 w-100 text-left pl-1" color="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <i className="fas fa-angle-down mr-2 active"/>
              <i className="fas fa-puzzle-piece active mr-1"/>
              <FormattedMessage id="structure.sidebar.tooltip.elements"/>
            </Button>

            <div id="collapseOne" className={"collapse pl-3 mb-2" + (this.props.user.favoriteQuestionTypes.length === 0 ? ' show' : '')} aria-labelledby="headingElements" data-parent="#accordionSidebar">
              <div className="mr-1">
                <Elements searchText={this.state.searchInElements}/>
              </div>
            </div>

            <Button className="btn btn-link h3 mb-1 w-100 text-left pl-1 collapsed" color="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
              <i className="fas fa-angle-down mr-2 active"/>
              <i className="icon-pytanie-personalizowane active mr-1"/>
              <FormattedMessage id="structure.sidebar.tooltip.templates"/>
            </Button>

            <div id="collapseTwo" className="collapse pl-3 mb-2" aria-labelledby="headingTemplates" data-parent="#accordionSidebar">
              <div className="mr-1">
                {this.props.user.userPlan.plan.survey.userQuestionTemplates
                  ? <Button size="sm" color="primary" onClick={() => this.setState({questionTemplatesModal: !this.state.questionTemplatesModal})}>Otwórz szablony pytań</Button>
                  : <Alert color="warning">Brak dostępu w Twoim planie</Alert>
                }

                {this.state.questionTemplatesModal && <Modal isOpen={true} toggle={() => this.setState({questionTemplatesModal: !this.state.questionTemplatesModal})} size="xxl">
                  <ModalHeader tag="h2" toggle={() => this.setState({questionTemplatesModal: !this.state.questionTemplatesModal})}>Szablony pytań</ModalHeader>
                  <hr className="my-0"/>
                  <ModalBody>
                    <QuestionTemplates ref={this.questionTemplates} onChange={selected => this.setState({questionTemplatesSelected: selected})} />
                  </ModalBody>
                  <hr className="my-0"/>
                  <ModalFooter>
                    <FormattedMessage id="_.button.close">{
                      (message) =>
                        <Button color="secondary" className="mb-0" onClick={() => this.setState({questionTemplatesModal: !this.state.questionTemplatesModal})}>{message}</Button>
                    }</FormattedMessage>
                    <Button color="primary" disabled={questionTemplatesSelected.length === 0} className="mb-0" onClick={() => this.setState({questionTemplatesAdd: !questionTemplatesAdd})}>Dodaj do ankiety {questionTemplatesSelected.length > 0 && <span>(Liczba pytań: {questionTemplatesSelected.length})</span>}</Button>
                  </ModalFooter>
                </Modal>}

                {questionTemplatesAdd && <TemplatesModal questionTemplates={questionTemplatesSelected} toggleModal={() => this.setState({questionTemplatesAdd: false, questionTemplatesModal: false})} />}
              </div>
            </div>

            <Button className="btn btn-link h3 mb-1 w-100 text-left pl-1 collapsed" color="" data-toggle="collapse" data-target="#collapseBlockTemplates" aria-expanded="true" aria-controls="collapseBlockTemplates">
              <i className="fas fa-angle-down mr-2 active"/>
              <i className="icon-pytanie-personalizowane active mr-1"/>
              <FormattedMessage id="structure.sidebar.tooltip.blockTemplates"/>
            </Button>

            <div id="collapseBlockTemplates" className="collapse pl-3 mb-2" aria-labelledby="headingTemplates" data-parent="#accordionSidebar">
              <div className="mr-1">
                {this.props.user.userPlan.plan.survey.userBlockTemplates
                  ? <Button size="sm" color="primary" onClick={() => this.setState({blockTemplatesModal: !this.state.blockTemplatesModal})}>Otwórz szablony bloków</Button>
                  : <Alert color="warning">Brak dostępu w Twoim planie</Alert>
                }

                {this.state.blockTemplatesModal && <Modal isOpen={true} toggle={() => this.setState({blockTemplatesModal: !this.state.blockTemplatesModal})} size="xxl">
                  <ModalHeader tag="h2" toggle={() => this.setState({blockTemplatesModal: !this.state.blockTemplatesModal})}>Szablony bloków</ModalHeader>
                  <hr className="my-0"/>
                  <ModalBody>
                    <BlockTemplates ref={this.blockTemplates} onChange={selected => this.setState({blockTemplatesSelected: selected})} />
                  </ModalBody>
                  <hr className="my-0"/>
                  <ModalFooter>
                    <FormattedMessage id="_.button.close">{
                      (message) =>
                        <Button color="secondary" className="mb-0" onClick={() => this.setState({blockTemplatesModal: !this.state.blockTemplatesModal})}>{message}</Button>
                    }</FormattedMessage>
                    <Button color="primary" disabled={blockTemplatesSelected.length === 0} className="mb-0" onClick={() => this.setState({blockTemplatesAdd: !blockTemplatesAdd})}>Dodaj do ankiety {blockTemplatesSelected.length > 0 && <span>(Liczba bloków: {blockTemplatesSelected.length})</span>}</Button>
                  </ModalFooter>
                </Modal>}

                {blockTemplatesAdd && <TemplatesAddModal blockTemplates={blockTemplatesSelected} toggleModal={() => this.setState({blockTemplatesAdd: false, blockTemplatesModal: false})} />}
              </div>
            </div>


            <Button className="btn btn-link h3 mb-1 w-100 text-left pl-1 collapsed" color="" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
              <i className="fas fa-angle-down mr-2 active"/>
              <i className="icon-pytanie active mr-1"/>
              <FormattedMessage id="structure.sidebar.tooltip.globalTemplates"/>
            </Button>

            <div id="collapseThree" className="collapse pl-3 mb-2" aria-labelledby="headingGlobalTemplates" data-parent="#accordionSidebar">
              <div className="mr-1">
                <GlobalTemplates searchText={this.state.searchInElements}/>
              </div>
            </div>

            {this.props.user.userPlan.plan.survey.ownQuestionsBase && <div>
              <Button className="btn btn-link h3 mb-1 w-100 text-left pl-1 collapsed" color="" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                <i className="fas fa-angle-down mr-2 active"/>
                <i className="icon-pytanie active mr-1"/>
                <FormattedMessage id="structure.sidebar.tooltip.questionBase"/>
              </Button>
              <div id="collapseFour" className="collapse pl-3 mb-2" aria-labelledby="headingQuestionsBase" data-parent="#accordionSidebar">
                <div className="mr-1">
                  <Button size="sm" color="primary" onClick={() => this.setState({surveyQuestionBaseModal: !this.state.surveyQuestionBaseModal})}>Otwórz bazę pytań</Button>

                  {this.state.surveyQuestionBaseModal && <Modal isOpen={true} toggle={() => this.setState({surveyQuestionBaseModal: !this.state.surveyQuestionBaseModal})} size="xxl">
                    <ModalHeader tag="h2" toggle={() => this.setState({surveyQuestionBaseModal: !this.state.surveyQuestionBaseModal})}>Baza pytań</ModalHeader>
                    <hr className="my-0"/>
                    <ModalBody>
                      <SurveyQuestionsBase />
                    </ModalBody>
                    <hr className="my-0"/>
                    <ModalFooter>
                      <FormattedMessage id="_.button.close">{
                        (message) =>
                          <Button color="secondary" className="mb-0" onClick={() => this.setState({surveyQuestionBaseModal: !this.state.surveyQuestionBaseModal})}>{message}</Button>
                      }</FormattedMessage>
                    </ModalFooter>
                  </Modal>}
                </div>
              </div>
            </div>}

          </div>
        </div>
      </aside>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(Sidebar);