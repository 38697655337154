import React, {Component} from 'react';
import api from 'api';
import {Col, Form, FormGroup, Input, Label} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import Switch from "../../../../../components/Switch";

class FormSlack extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      correct: !!props.user.slackHook ? true : null,
      data: {
        slackHook: props.user.slackHook,
        slackSendDailyStats: !!props.user.slackSendDailyStats,
        slackSendSurveyResponsesEnd: !!props.user.slackSendSurveyResponsesEnd,
        slackSendSurveyCreated: !!props.user.slackSendSurveyCreated,
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(key, value){
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      }
    })
  }

  onSubmit(e) {
    e.preventDefault();

    const {data} = this.state;

    this.setState({
      saving: true,
    });

    api.slackValidation(data.slackHook)
    .then(data => {
      if(data.valid){
        const formData = new FormData();
        formData.append('slackHook', this.state.data.slackHook);
        formData.append('slackSendDailyStats', this.state.data.slackSendDailyStats ? 1 : 0);
        formData.append('slackSendSurveyResponsesEnd', this.state.data.slackSendSurveyResponsesEnd ? 1 : 0);
        formData.append('slackSendSurveyCreated', this.state.data.slackSendSurveyCreated ? 1 : 0);

        api.user.post.userUpdate(formData)
        .then(data => {
          this.setState({
            correct: true,
            saving: false,
          });
        })
        .catch(res => {
          this.setState({
            saving: false,
            correct: false,
          });
        })
      }else{
        this.setState({
          saving: false,
          correct: false,
        });
      }
    })
    .catch(() => {
      this.setState({
        correct: false,
        saving: false,
      });
    });
  }

  render() {
    const {saving, data, correct} = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <FormGroup>
          <Input
            type="text"
            placeholder="Slack hook"
            value={data.slackHook}
            onChange={e => this.setState({
              data: {
                ...data,
                slackHook: e.target.value
              }
            })}
          />
        </FormGroup>
        <FormGroup className="d-flex justify-content-between align-items-center">
          <Label className="p-0" md={8}><small>Wysyłaj codzienne statystyki</small></Label>
          <Switch className="mb-0" onChange={slackSendDailyStats => this.onChange('slackSendDailyStats', slackSendDailyStats)} checked={data.slackSendDailyStats} />
        </FormGroup>
        <FormGroup className="d-flex justify-content-between align-items-center">
          <Label className="p-0" md={8}><small>Wysyłaj przy utworzeniu nowego projektu</small></Label>
          <Switch className="mb-0" onChange={slackSendSurveyCreated => this.onChange('slackSendSurveyCreated', slackSendSurveyCreated)} checked={data.slackSendSurveyCreated} />
        </FormGroup>
        <FormGroup className="d-flex justify-content-between align-items-center">
          <Label className="p-0" md={8}><small>Wysyłaj po osiągnięciu maksimum wywiadów</small></Label>
          <Switch className="mb-0" onChange={slackSendSurveyResponsesEnd => this.onChange('slackSendSurveyResponsesEnd', slackSendSurveyResponsesEnd)} checked={data.slackSendSurveyResponsesEnd} />
        </FormGroup>
        <FormGroup className="d-flex justify-content-between align-items-center mt-2">
          {saving && <i className="fas fa-spinner fa-spin" />}
          {!saving && correct === false && <i className="fas fa-times-circle text-danger" />}
          {!saving && correct === true && <i className="fas fa-check-circle text-success" />}
          <LaddaButton
            className="btn btn-primary btn-ladda d-block ml-auto"
            loading={saving}
            data-style={ZOOM_OUT}
          >Zapisz</LaddaButton>
        </FormGroup>
      </Form>
    );
  }
}

export default FormSlack;