import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {UncontrolledTooltip} from "reactstrap";
import _ from "lodash";

class QuestionContentTooltip extends PureComponent {
  render() {
    const {surveyQuestion} = this.props;
    const tooltipId = "questionContentTooltip-" + Math.random().toString(36).substring(7);

    if(!surveyQuestion){
      return <span><i>Brak wybranego pytania</i></span>;
    }

    return <React.Fragment>
      <span id={tooltipId} className="pointer">P.{surveyQuestion.positionInSurvey}</span>
      <UncontrolledTooltip target={tooltipId}>
        <span className="img-30" dangerouslySetInnerHTML={{__html: surveyQuestion.stripedContent}}/>
      </UncontrolledTooltip>
    </React.Fragment>
  }
}

QuestionContentTooltip.propTypes = {
  question: PropTypes.object.isRequired,
  surveyQuestion: PropTypes.object.isRequired
};

function mapStateToProps(state, props) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    surveyQuestion: props.question && questions.find(question => question.id === props.question.id)
  }
}

export default connect(mapStateToProps)(QuestionContentTooltip);