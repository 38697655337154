import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from 'reactstrap';
import ReportDeleteButton from "./ReportDeleteButton";
import ReportEditButton from "./ReportEditButton";
import ReactTable from 'react-table';
import Pagination from "components/Pagination";
import api from "api";
import striptags from "striptags";
import ReportCopyButton from "./ReportCopyButton";

class ReportsList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: [],
    };

    this.fetchData = this.fetchData.bind(this);
    this.actions = this.actions.bind(this);
  }

  fetchData(state) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.report.post.searchReports(data).then(res => {
      this.setState({searching: false, data: res.reports, count: res.count});
    });
  }

  actions(report){
    const handler = () => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});
    return (
      <ButtonGroup>
        <ReportEditButton report={report} handleChange={handler}/>
        <ReportCopyButton report={report} handleChange={handler}/>
        <ReportDeleteButton report={report} handleChange={handler}/>
      </ButtonGroup>
    )
  }

  static reduceAndStripLongText(text) {
    text = striptags(text);
    return text.length > 50 ? text.substr(0, 50) + '...' : text
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.title" />,
      accessor: 'title'
    }, {
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.slug" />,
      accessor: 'slug'
    }, {
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.image" />,
      accessor: 'image'
    }, {
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.price" />,
      accessor: 'price'
    }, {
      id: 'shortDescription',
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.shortDescription" />,
      accessor: report => ReportsList.reduceAndStripLongText(report.shortDescription)
    }, {
      id: 'description',
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.description" />,
      accessor: report => ReportsList.reduceAndStripLongText(report.description)
    }, {
      id: 'publishAt',
      Header: "data publikacji",
      accessor: report => report.publishAt
    }, {
      id: 'actions',
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.actions" />,
      accessor: report => this.actions(report),
      sortable: false
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.reportsList.listItems.table.noReports" />}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

export default ReportsList;