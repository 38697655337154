const convertArrayToChunks = (array, chunkSize = 10) => {
	const result = array.reduce((resultArray, item, index) => {
		const chunkIndex = Math.floor(index/chunkSize)

		if(!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = [] // start a new chunk
		}

		resultArray[chunkIndex].push(item)

		return resultArray
	}, [])

	return result;
}

export default convertArrayToChunks;