import * as React from 'react';
import PropTypes from "prop-types";
import Button from "../../../../components/Button";
import {Alert, Form, Col, Input, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";
import api from "../../../../api";
import {isEmpty, map} from "lodash";
import {toast} from "react-toastify";

const mappedKeys = {
  agemin: 'minAge',
  agemax: 'maxAge',
  f: 'sexF',
  m: 'sexM',
  popmin: 'cityLmFrom',
  popmax: 'cityLmTo',
  miasto: 'cityTown',
  'wieś': 'cityVillage',
  'wies': 'cityVillage',
  target: 'collectorResponses',
  region: 'cityProvinces',
  cashpointsfrom: 'cashPointsFrom',
  cashpointsto: 'cashPointsTo',
}

function CollectorPanelCreateTextarea(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [saving, setSaving] = React.useState(false);
  const [text, setText] = React.useState('');
  const [textErrors, setTextErrors] = React.useState({});

  const parseText = () => {
    let errors = {};
    const collectors = text.split(/\n/).filter(content => content !== '').map(content => {
      let collector = {
        type: 'Panel',
        paused: true,
        sexM: false,
        sexF: false,
      }
      const regexName = /<([\w\s0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\.,-_:;+\(\)]+)>/g;
      let matchAll;

      content.split(/\|/).map(con => {

        let nameMatch = regexName.exec(con);
        if(nameMatch){
          collector.name = nameMatch[1];
        }else{
          let split = con.split(':');
          let keyOrygin = split[0];
          let key = keyOrygin.toLowerCase();
          let value = 1 in split ? split[1] : null;
          if(value && value.includes(',')){
            value = value.split(',').map(v => v.trim());
          }

          if(key in mappedKeys){
            key = mappedKeys[key];
            collector[key] = value || true;
          }else{
            if(!Object.keys(errors).includes(collector.name)){
              errors[collector.name] = [];
            }

            errors[collector.name].push(keyOrygin);
          }
        }
      });

      return collector;
    });

    return {
      collectors,
      errors,
    };
  }

  const save = (e) => {
    setSaving(true);

    const {errors, collectors} = parseText();
    setTextErrors(errors);

    if(Object.keys(errors).length === 0){
      api.survey.post.collectorsBulk({
        survey: props.surveyId,
        collectors: collectors
      }).then(data => {
        console.log('data', data);
        setSaving(false);
        toast.success('Liczba utworzonych kolektorów: ' + data.collectors.length);

        props.onCreated();
      }).catch(data => {
        setSaving(false);
      })
    }else{
      setSaving(false);
    }
  }

  return (
    <React.Fragment>
      <Button className="px-2" onClick={toggleModalOpen}><i className="fas fa-keyboard" /></Button>
      {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="xl">
        <ModalHeader tag="h2" toggle={toggleModalOpen}>Utwórz kolektory Panel</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Col>
                <Input
                  autoFocus
                  type="textarea"
                  rows={10}
                  value={text}
                  onChange={e => setText(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col>
                {!isEmpty(textErrors) && <Alert color="warning">
                  <ul className="pl-0 mb-0">
                    {map(textErrors, (error, key) => <li><strong>{key}</strong> - Błędne nazwy parametrów: {error.join(', ')}</li>)}
                  </ul>
                </Alert>}
              </Col>
            </FormGroup>
          </Form>
          <p className="mt-2">Przykład: {`<nazwa1>|F|AgeMin:13|AgeMax:18|PopMin:0|PopMax:50000|Wieś|Target:15`}</p>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.create">{
            (message) => <Button
              loading={saving}
              onClick={save}
            >
              {message}
            </Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
}

CollectorPanelCreateTextarea.propTypes = {
  surveyId: PropTypes.any.isRequired,
  onCreated: PropTypes.func.isRequired,
};

export default CollectorPanelCreateTextarea;