import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Label} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Switch from "components/Switch";

class TabMaxDiff extends PureComponent {

  constructor(props, context) {
    super(props, context);

    this.onChange = this.onChange.bind(this);
  }

  onChange(key, value) {
    let data = this.props.data.survey.questionSettings.maxDiff;
    data[key] = value;

    this.props.onChangeQuestionSettings('maxDiff', data);
  }

  render() {
    const {errors, data} = this.props;

    return (
      <React.Fragment>
        <FormGroup>
          <Label for="rotateAnswers"><FormattedMessage id="_.question.maxDiff.rotateAnswers" /></Label>
          <Switch stickToProp checked={data.survey.questionSettings.maxDiff.rotateAnswers} onChange={value => this.onChange('rotateAnswers', value)} />
        </FormGroup>
      </React.Fragment>
    );
  }
}


TabMaxDiff.propTypes = {
  data: PropTypes.shape({
    survey: PropTypes.shape({
      questionSettings: PropTypes.shape({
        maxDiff: PropTypes.object.isRequired,
      }).isRequired
    }).isRequired,
  }),
  errors: PropTypes.object.isRequired,
  onChangeQuestionSettings: PropTypes.func.isRequired,
};

export default TabMaxDiff;