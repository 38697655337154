import * as React from 'react';
import PropTypes from "prop-types";
import Button from "components/Button";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import QuotasTable from "./QuotasTable";

function QuotasShare(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  return (
    <React.Fragment>
      <Button className="ml-2" onClick={toggleModalOpen}><i className="fas fa-share" /> Udostępnij</Button>
      {modalOpen && <Modal isOpen toggle={toggleModalOpen} className="height-100vh" size="xl">
        <ModalHeader toggle={toggleModalOpen}>Udostępnianie podglądu listy kwot</ModalHeader>
        <ModalBody>
          <QuotasTable
            surveyId={props.surveyId}
            filters={{
              survey: props.surveyId
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Zamknij</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
}

QuotasShare.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default QuotasShare;