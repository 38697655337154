import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Col, Row} from "reactstrap";
import Rotate from "./components/Rotate";
import RandBlocks from "./components/RandBlocks";
import RandAndRotateBlocksTable from "./components/RandAndRotateBlocksTable";
import Disabled from "./components/Disabled";
import {updateSet} from "scenes/Survey/Structure/data/sets/actions";

class Settings extends Component {

  constructor(props) {
    super(props);

    this.handledUpdateSet = this.handledUpdateSet.bind(this);
  }

  handledUpdateSet(set){
    this.props.updateSet({
      id: this.props.set.id,
      set
    });
  }

  render() {
    const { set } = this.props;
    const shouldRenderTable = set.rotateBlocks || set.randBlocks > 0;

    return (
      <Row>
        <Col className="p-4">
          <h5><FormattedMessage id="set.settings.header"/></h5>

          <Rotate set={set} handleUpdate={this.handledUpdateSet}/>
          <RandBlocks set={set} handleUpdate={this.handledUpdateSet}/>
          <Disabled set={set} handleUpdate={this.handledUpdateSet}/>

          {shouldRenderTable && <RandAndRotateBlocksTable set={set} />}
        </Col>
      </Row>
    )
  }
}

Settings.propTypes = {
  set: PropTypes.object.isRequired,
  updateSet: PropTypes.func.isRequired
};

export default connect(null, { updateSet })(Settings);