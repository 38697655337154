import React from 'react';
import PropTypes from 'prop-types';
import QuestionSummarySingle from "./QuestionSummary/QuestionSummarySingle";
import QuestionSummaryMulti from "./QuestionSummary/QuestionSummaryMulti";
import QuestionSummaryRanked from "./QuestionSummary/QuestionSummaryRanked";
import QuestionSummaryConjoint from "./QuestionSummary/QuestionSummaryConjoint";
import QuestionSummaryOpen from "./QuestionSummary/QuestionSummaryOpen";
import QuestionSummaryMultiOpen from "./QuestionSummary/QuestionSummaryMultiOpen";
import QuestionSummaryMatrix from "./QuestionSummary/QuestionSummaryMatrix";
import QuestionSummaryMatrixMulti from "./QuestionSummary/QuestionSummaryMatrixMulti";
import QuestionSummaryComment from "./QuestionSummary/QuestionSummaryComment";
import QuestionSummaryVideo from "./QuestionSummary/QuestionSummaryVideo";
import QuestionSummaryNumeric from "./QuestionSummary/QuestionSummaryNumeric";
import QuestionSummaryDifferential from "./QuestionSummary/QuestionSummaryDifferential";
import QuestionSummaryNps from "./QuestionSummary/QuestionSummaryNps";
import QuestionSummaryGMap from "./QuestionSummary/QuestionSummaryGMap";
import QuestionSummaryMaxDiff from "./QuestionSummary/QuestionSummaryMaxDiff";
import QuestionSummaryNumericMulti from "./QuestionSummary/QuestionSummaryNumericMulti";
import QuestionSummaryBasket from "./QuestionSummary/QuestionSummaryBasket";
import {Alert} from "reactstrap";

const QuestionSummaryByType = props => {
  const componentProps = {
    key: props.questionSummary.id,
    questionSummary: props.questionSummary,
    chart: props.chart,
    table: props.table,
  }

  switch (props.questionSummary.question.type) {
    case 'single':
      return <QuestionSummarySingle {...componentProps} />;
    case 'multi':
      return <QuestionSummaryMulti {...componentProps} />;
    case 'ranked':
      return <QuestionSummaryRanked {...componentProps} />;
    case 'conjoint':
      return <QuestionSummaryConjoint {...componentProps} />;
    case 'open':
      return <QuestionSummaryOpen {...componentProps} />;
    case 'multiOpen':
      return <QuestionSummaryMultiOpen {...componentProps} />;
    case 'matrix':
      return <QuestionSummaryMatrix {...componentProps} />;
    case 'matrixMulti':
      return <QuestionSummaryMatrixMulti {...componentProps} />;
    case 'comment':
      return <QuestionSummaryComment {...componentProps} />;
    case 'video':
      return <QuestionSummaryVideo {...componentProps} />;
    case 'numeric':
      return <QuestionSummaryNumeric {...componentProps} />;
    case 'differential':
      return <QuestionSummaryDifferential {...componentProps} />;
    case 'nps':
      return <QuestionSummaryNps {...componentProps} />;
    case 'gMap':
      return <QuestionSummaryGMap {...componentProps} />;
    case 'maxDiff':
      return <QuestionSummaryMaxDiff {...componentProps} />;
    case 'numericMulti':
      return <QuestionSummaryNumericMulti {...componentProps} />;
    case 'basket':
      return <QuestionSummaryBasket {...componentProps} />;
    case 'bpto':
    default:
      return <Alert color="warning" className="text-center">Brak wykresu dla tego typu pytania. Potrzebujesz wykresu do tego pytania? Skontaktuj się z nami.</Alert>
  }
};

QuestionSummaryByType.defaultProps = {
  chart: true,
  table: true,
};

QuestionSummaryByType.propTypes = {
  chart: PropTypes.bool,
  table: PropTypes.bool,
};

export default QuestionSummaryByType;
