import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";

class ButtonExcludedFromRand extends Component{
  constructor(props) {
    super(props);
    this.state = {
      excludedFromRand: props.column.excludedFromRand,
      tooltipIsOpen: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  onClick() {
    if (this.props.disabled) {
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());
      return;
    }
    this.setState({...this.state, excludedFromRand: !this.state.excludedFromRand});
    this.props.toggleOption('excludedFromRand');
  }

  render() {
    const {column, disabled} = this.props;
    const {tooltipIsOpen, excludedFromRand} = this.state;

    return (
      <a id={'excludedFromRand-' + column.id + '-column'} className={classnames({
        "fa-solid fa-eye ml-2": true,
        "text-primary": excludedFromRand,
        "not-active": disabled
      })} onClick={this.onClick}>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltipIsOpen}
          target={'excludedFromRand-' + column.id + '-column'}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="column.buttonExcludedFromRand.button.tooltip"/>
        </Tooltip>
      </a>
    )
  }
}

ButtonExcludedFromRand.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.number.isRequired,
    excludedFromRand: PropTypes.bool.isRequired,
  }).isRequired,
  toggleOption: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default connect(null, {setPlanRestrictionsModals})(ButtonExcludedFromRand);
