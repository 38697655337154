import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Card, CardBody, CardHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import GroupInvitationsAcceptButton from './GroupInvitationsAcceptButton';
import GroupInvitationsRejectButton from './GroupInvitationsRejectButton';
import ReactTable from "react-table";
import Pagination from "components/Pagination";

class GroupInvitationsList extends Component {

  static actions(group) {
    return (
      <ul className="float-right mb-0 p-0">
        <li className="float-left mr-2">
          <GroupInvitationsAcceptButton group={group}/>
        </li>
        <li className="float-left mr-2">
          <GroupInvitationsRejectButton group={group}/>
        </li>
      </ul>
    )
  }

  render() {
    const {invitations} = this.props;

    const columns = [{
      Header: <FormattedMessage id="groupInvitationsList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="groupInvitationsList.listItems.tableHeader.name" />,
      accessor: 'name'
    }, {
      id: 'actions',
      Header: <FormattedMessage id="groupInvitationsList.listItems.tableHeader.actions" />,
      accessor: group => GroupInvitationsList.actions(group)
    }];

    return (
      <Card>
        <CardHeader>
          <div className="direction-group">
            <div className="direction-left d-flex justify-content-start">
              <h2 className="float-left">
                <FormattedMessage id="groupInvitationsList.cardHeader" />
              </h2>
            </div>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <ReactTable
            minRows={0}
            className={'table-wrap mt-4'}
            data={Object.values(invitations)}
            columns={columns}
            showPagination={!_.isEmpty(invitations)}
            PaginationComponent={Pagination}
            defaultPageSize={10}
          />
        </CardBody>
      </Card>
    )
  }
}

GroupInvitationsList.propTypes = {
  invitations: PropTypes.object.isRequired
};

export default GroupInvitationsList;