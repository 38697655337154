import * as React from 'react';
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Button from "components/Button";
import SurveySwitcherCheckHistoryTable from "componentsNew/SurveySwitcherChecks/SurveySwitcherCheckHistoryTable";

function SurveySwitcherCheckHistoryModal(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  return (
    <React.Fragment>
      <div className="w-100 text-center">
        <span className="pointer" onClick={toggleModalOpen}>Sprawdź</span>
      </div>

      {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="xl">
        <ModalHeader tag="h2" toggle={toggleModalOpen}>Historia</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <SurveySwitcherCheckHistoryTable surveySwitcherCheck={props.surveySwitcherCheck} />
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.close">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
}

SurveySwitcherCheckHistoryModal.propTypes = {

};

export default SurveySwitcherCheckHistoryModal;