import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import UserAvatar from "../../../../components/UserAvatar";
import Select, { components } from 'react-select';
import api from "../../../../api";
import {toast} from "react-toastify";

class GroupUserRewriteSurveysModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      errors: {},
      user: null,
    };

    this.save = this.save.bind(this);
  }

  save(){
    this.setState({
      saving: true,
      errors: {}
    });
    api.user.rewriteSurveyShares(this.props.user.userId, this.state.user)
    .then(() => {
      this.setState({
        saving: false,
      });

      toast.success('Projekty zostały poprawnie przepisane');
      this.props.toggleModal();
    })
    .catch(() => {
      this.setState({
        saving: false,
      });
    });
  }

  render() {
    const { saving } = this.state;
    const { Option } = components;
    const avatarOption = (props) => {
      return <Option {...props}>
        <UserAvatar user={props.data.user} />
        <span className="ml-2">{props.data.label}</span>
      </Option>
    };

    let options = [];
    let users = [];

    _.each(this.props.groups[Object.keys(this.props.groups)[0]].users, user => {
      if (user.confirmed && parseInt(user.userId) !== this.props.userId) {
        users.push(user);
      }
    });

    _.each(users, user => {
      const content = (user.user.firstname && user.user.lastname) ? (user.user.firstname + ' ' + user.user.lastname) : user.user.email;

      options.push({user: user.user, label: content, value: user.user.id, avatar: user.user.avatar})
    });

    return (
      <Modal isOpen={true} toggle={this.props.toggleModal} size="lg">
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="groupUserEditModal.modalHeader" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <Select
            isClearable
            placeholder="Usuń z projektów lub przepisz projekty do wybranej osoby"
            onChange={option => {
              this.setState({
                user: option ? option.value : null
              })
            }}
            options={options}
            value={this.state.selected}
            components={{Option: avatarOption}}
            filterOption={(data, search) => {
              if (search.length === 0)
                return true;

              let user = _.find(users, user => parseInt(user.user.id) === data.value).user;
              let name = (user.firstname + ' ' + user.lastname).toLowerCase();
              search = search.toLowerCase();

              return user.email.includes(search) || name.includes(search);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={saving}
                  data-style={ZOOM_OUT}
                  onClick={e => this.save()}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggleModal}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

GroupUserRewriteSurveysModal.propTypes = {
  user: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    userId: state.user.id,
    groups: state.groups.data.groups,
  }
}

export default connect(mapStateToProps)(GroupUserRewriteSurveysModal);