import React, {Component} from 'react';
import {Row} from 'reactstrap';
import Widget from './components/Widget';
import PropTypes from "prop-types";
import widgetTypes from "../../../WidgetTypes/types";

class WidgetsAvailable extends Component {

  render() {
    let widgetsList = [];
    widgetTypes.map(widget => {
      widgetsList.push(<Widget key={widget.type} widget={widget} toggleModal={this.props.toggleModal}/>);
    });

    return (
      <Row>
        {widgetsList}
      </Row>
    )
  }
}

Widget.propTypes = {
  toggleModal: PropTypes.func.isRequired
};

export default WidgetsAvailable;