import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input} from "reactstrap";
import {injectIntl} from "react-intl";

const detailTypes = ['enteredText'];
const condTypes = ['equals', 'notEquals', 'greaterThenOrEquals', 'lesserThenOrEquals'];

class Open extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpenDropdownCondType: false,
    };

    this.toggleIsOpenDropdownCondType = this.toggleIsOpenDropdownCondType.bind(this);
  }

  componentWillMount() {
    const {condQuestion} = this.props;
    let condQuestionChanged = condQuestion;

    if(_.isUndefined(condQuestion.condType) || !condTypes.includes(condQuestion.condType)){ condQuestionChanged.condType = condTypes[0]; }
    if(_.isUndefined(condQuestion.detailType) || !detailTypes.includes(condQuestion.detailType)){ condQuestionChanged.detailType = detailTypes[0]; }
    if(_.isUndefined(condQuestion.value)){ condQuestionChanged.value = ''; }

    if(condQuestion !== condQuestionChanged){
      Open.propTypes.handleCondChanged(condQuestionChanged);
    }
  }

  toggleIsOpenDropdownCondType(){
    this.setState({
      isOpenDropdownCondType: !this.state.isOpenDropdownCondType,
    })
  }

  render() {
    const {isOpenDropdownCondType} = this.state;
    const {intl, condQuestion, changeable} = this.props;

    return (
      <React.Fragment>
        <span>wprowadzi wartość </span>

        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenDropdownCondType} toggle={this.toggleIsOpenDropdownCondType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Open.condType-' + condQuestion.condType})}</DropdownToggle>
          <DropdownMenu>
            {condTypes.map(condType => <DropdownItem tag="a" key={condType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              condType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.Open.condType-' + condType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condDetail.Open.condType-' + condQuestion.condType})} </span>}

        {changeable ? <Input
          className="w-25 d-inline ml-2"
          size="xs"
          value={condQuestion.value} onChange={e => this.props.handleCondChanged({
          ...condQuestion,
          value: e.target.value,
        })} /> : condQuestion.value}

      </React.Fragment>
    );
  }
}

Open.defaultProps = {
  changeable: true
};

Open.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number.isRequired,
    condType: PropTypes.oneOf(condTypes),
    detailType: PropTypes.oneOf(detailTypes),
    value: PropTypes.string.isRequired,
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(Open);