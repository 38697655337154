import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../components/Button";
import SurveySwitcherCheckForm from "./SurveySwitcherCheckForm";

class SurveySwitcherCheckEditModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      creating: false
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  submit(){
    this.formRef.current.edit()
  }

  render() {
    const {creating, modalOpen} = this.state;
    const {surveySwitcherCheck} = this.props;

    return <React.Fragment>
      <div>
        <i className="fas fa-edit p-1 ml-3 pointer"  onClick={this.toggleModal}/>
      </div>

      <Modal isOpen={modalOpen} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>Edytuj</ModalHeader>
        <ModalBody>
          <SurveySwitcherCheckForm
              onSaved={surveySwitcherCheck => {
                this.props.onAdded(surveySwitcherCheck);
                this.toggleModal()
              }}
              id={surveySwitcherCheck.id}
              data={{
                survey: surveySwitcherCheck.surveyId,
                followRedirects: surveySwitcherCheck.followRedirects,
                isCloseSurvey: surveySwitcherCheck.isCloseSurvey,
                isTextMatch: surveySwitcherCheck.isTextMatch,
                url: surveySwitcherCheck.url,
                matchText: surveySwitcherCheck.matchText
              }}
              ref={this.formRef}
              onSavingStart={() => this.setState({ creating: true })}
              onSavingStop={() => this.setState({ creating: false })}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={this.toggleModal}>Anuluj</Button>
          <Button
              loading={creating}
              onClick={this.submit}
          >Zapisz</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>;
  }
}

SurveySwitcherCheckEditModal.propTypes = {
  surveySwitcherCheck: PropTypes.object.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onAdded: PropTypes.func.isRequired,
};

export default SurveySwitcherCheckEditModal;
