import React, {Component} from 'react';
import {Nav, NavItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import appRoutes from "appRoutes";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import NavigationAdmin from './NavigationAdmin';
import {connect} from "react-redux";

class NavigationAdminSurveys extends Component {
  render() {
    const {user} = this.props;

    return (
      <Nav className="navbar-nav m-0 mx-lg-5 align-items-lg-baseline w-50 w-lg-100 float-right d-inline-block d-lg-flex">
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_SURVEYS')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.surveys.list} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminSurveys.surveys" /><span className="sr-only"><FormattedMessage id="navigation.adminSurveys.surveys" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_SURVEYS_SWPANEL')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.surveys.waitingAccept} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminSurveys.waitingAccept" /><span className="sr-only"><FormattedMessage id="navigation.adminSurveys.waitingAccept" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_SURVEYS_SWPANEL')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.surveys.accepted} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminSurveys.accepted" /><span className="sr-only"><FormattedMessage id="navigation.adminSurveys.accepted" /></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_SURVEYS_RESPONSES')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.surveys.responses} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminSurveys.surveyResponses" /><span className="sr-only"><FormattedMessage id="navigation.adminSurveys.surveyResponses" /></span></NavLink></NavItem>}
        {user.roles.includes('ROLE_SUPER_ADMIN') && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.surveys.templates} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminSurveys.surveyTemplates" /><span className="sr-only"><FormattedMessage id="navigation.adminSurveys.surveyTemplates" /></span></NavLink></NavItem>}
      </Nav>
    );
  }
}

NavigationAdminSurveys.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(NavigationAdminSurveys);
