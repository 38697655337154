import React, {Component} from 'react';
import {Alert, Card, CardBody, CardHeader} from "reactstrap";
import ProfileFormPasswordUpdate from "../Profile/components/ProfileFormPasswordUpdate";


class PasswordChangeRequired extends Component {
  render() {
    return <Card className="h-100 mb-0">
      <CardHeader><h2><i className="fas fa-user mr-2" /> Wymagana zmiana hasła</h2></CardHeader>
      <hr className="my-0" />
      <CardBody>
        <Alert color="warning"><span>Wymagana zmiana hasła</span></Alert>
        <div className="my-3">
          <ProfileFormPasswordUpdate />
        </div>
      </CardBody>
    </Card>;
  }
}

export default PasswordChangeRequired;