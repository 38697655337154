import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {addQuestion} from "scenes/Survey/Structure/data/questions/actions";
import {connect} from 'react-redux';
import LoadingSpinner from "components/LoadingSpinner";
import QuestionTypesModal from "components/QuestionTypesModal";

class ButtonCreate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      adding: false
    };
    this.toggle = this.toggle.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  toggle() {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  onClick(templates) {
    this.setState({ adding: true });


    this.props.addQuestion({
      page: this.props.page.id,
      questions: templates.map((template, key) => {
        return {
          type: template.type,
          position: this.props.position + key,
          ...template.options
        }
      })
  }).then(() => {
      this._ismounted && this.setState({ adding: false });
    });
  }

  render() {
    const {adding} = this.state;

    if(adding){
      return (
        <Button className="w-100 d-block mx-auto mb-1 text-center">
          <LoadingSpinner width={24} classNames="my-0"/>
        </Button>
      )
    }

    return (
      <Button className="w-100" onClick={this.toggle}>
        <FormattedMessage id="question.buttonCreate.dropdownToggle.add" />
        {this.state.modalOpen && <QuestionTypesModal isMulti={true} isOpen={this.state.modalOpen} toggle={this.toggle} onSelect={this.onClick}/>}
      </Button>
    )
  }
}

ButtonCreate.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  surveyPlan: PropTypes.shape({
    questionsAvailable: PropTypes.array.isRequired,
  }),
};

export default connect(null, {addQuestion})(ButtonCreate);