import React, {Component} from 'react';
import {connect} from "react-redux";
import {setShowQuestionsSettings} from "../../../../actions";
import classnames from "classnames";
import {FormattedMessage} from "react-intl";
import {Tooltip} from "reactstrap";


class QuestionsSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showQuestionsSettingsTooltip: false,
    };
  }

  render() {
    return <div id="show-questions-settings" className="pointer p-2 pr-0 d-flex align-items-center" onClick={() => {
      this.props.setShowQuestionsSettings(!this.props.showQuestionsSettings);
    }}>
      <i className={classnames({
      'fas fa-cogs': true,
      'text-primary': this.props.showQuestionsSettings
    })} />

      <Tooltip
        delay={0}
        placement="bottom"
        isOpen={this.state.showQuestionsSettingsTooltip}
        target={'show-questions-settings'}
        toggle={() => this.setState({showQuestionsSettingsTooltip: !this.state.showQuestionsSettingsTooltip})}
      >Pokaż/Ukryj ustawienia we wszystkich pytaniach</Tooltip>
    </div>
  }
}

function mapStateToProps(state) {
  return {
    showQuestionsSettings: state.survey.structure.showQuestionsSettings,
  }
}

export default connect(mapStateToProps, {setShowQuestionsSettings})(QuestionsSettings);