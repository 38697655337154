import React, {Component} from 'react';
import PropTypes from "prop-types";
import ReactTable from "react-table";
import api from "api";
import striptags from "striptags";
import {Alert, Button, Input, Table} from "reactstrap";
import SelectQuestions from "../../../../../Structure/components/SelectQuestions";
import LoadingSpinner from "../../../../../../../components/LoadingSpinner";
import StatusesSelect from "../../../../../../../components/Survey/Analysis/StatusesSelect";
import classnames from "classnames";
import reactHtmlParser from "react-html-parser";
import Pagination from "../../../../../../../components/Pagination";

import ColumnSingleThead from "./Columns/Single/ColumnThead";
import ColumnMultiThead from "./Columns/Multi/ColumnThead";
import ColumnNumericMultiThead from "./Columns/NumericMulti/ColumnThead";
import ColumnBasketThead from "./Columns/Basket/ColumnThead";
import ColumnDifferentialThead from "./Columns/Differential/ColumnThead";
import ColumnOpenThead from "./Columns/Open/ColumnThead";
import ColumnMaxDiffThead from "./Columns/MaxDiff/ColumnThead";
import ColumnNumericThead from "./Columns/Numeric/ColumnThead";
import ColumnNpsThead from "./Columns/Nps/ColumnThead";
import ColumnMultiOpenThead from "./Columns/MultiOpen/ColumnThead";
import ColumnGMapThead from "./Columns/GMap/ColumnThead";
import ColumnMatrixThead from "./Columns/Matrix/ColumnThead";
import ColumnMatrixMultiThead from "./Columns/MatrixMulti/ColumnThead";
import ColumnMatrixDropdownThead from "./Columns/MatrixDropdown/ColumnThead";

import ColumnSingleTbody from "./Columns/Single/ColumnTbody";
import ColumnMultiTbody from "./Columns/Multi/ColumnTbody";
import ColumnNumericMultiTbody from "./Columns/NumericMulti/ColumnTbody";
import ColumnBasketTbody from "./Columns/Basket/ColumnTbody";
import ColumnDifferentialTbody from "./Columns/Differential/ColumnTbody";
import ColumnOpenTbody from "./Columns/Open/ColumnTbody";
import ColumnMaxDiffTbody from "./Columns/MaxDiff/ColumnTbody";
import ColumnNumericTbody from "./Columns/Numeric/ColumnTbody";
import ColumnNpsTbody from "./Columns/Nps/ColumnTbody";
import ColumnMultiOpenTbody from "./Columns/MultiOpen/ColumnTbody";
import ColumnGMapTbody from "./Columns/GMap/ColumnTbody";
import ColumnMatrixTbody from "./Columns/Matrix/ColumnTbody";
import ColumnMatrixMultiTbody from "./Columns/MatrixMulti/ColumnTbody";
import ColumnMatrixDropdownTbody from "./Columns/MatrixDropdown/ColumnTbody";

import ColumnSingleTfoot from "./Columns/Single/ColumnTfoot";
import ColumnMultiTfoot from "./Columns/Multi/ColumnTfoot";
import ColumnNumericMultiTfoot from "./Columns/NumericMulti/ColumnTfoot";
import ColumnBasketTfoot from "./Columns/Basket/ColumnTfoot";
import ColumnDifferentialTfoot from "./Columns/Differential/ColumnTfoot";
import ColumnOpenTfoot from "./Columns/Open/ColumnTfoot";
import ColumnMaxDiffTfoot from "./Columns/MaxDiff/ColumnTfoot";
import ColumnNumericTfoot from "./Columns/Numeric/ColumnTfoot";
import ColumnNpsTfoot from "./Columns/Nps/ColumnTfoot";
import ColumnMultiOpenTfoot from "./Columns/MultiOpen/ColumnTfoot";
import ColumnGMapTfoot from "./Columns/GMap/ColumnTfoot";
import ColumnMatrixTfoot from "./Columns/Matrix/ColumnTfoot";
import ColumnMatrixMultiTfoot from "./Columns/MatrixMulti/ColumnTfoot";
import ColumnMatrixDropdownTfoot from "./Columns/MatrixDropdown/ColumnTfoot";

const ComponentsQuestion = {
  thead: {
    single: ColumnSingleThead,
    multi: ColumnMultiThead,
    numericMulti: ColumnNumericMultiThead,
    basket: ColumnBasketThead,
    differential: ColumnDifferentialThead,
    open: ColumnOpenThead,
    maxDiff: ColumnMaxDiffThead,
    numeric: ColumnNumericThead,
    nps: ColumnNpsThead,
    multiOpen: ColumnMultiOpenThead,
    gMap: ColumnGMapThead,
    matrix: ColumnMatrixThead,
    matrixMulti: ColumnMatrixMultiThead,
    matrixDropdown: ColumnMatrixDropdownThead,
  },
  tbody: {
    single: ColumnSingleTbody,
    multi: ColumnMultiTbody,
    numericMulti: ColumnNumericMultiTbody,
    basket: ColumnBasketTbody,
    differential: ColumnDifferentialTbody,
    open: ColumnOpenTbody,
    maxDiff: ColumnMaxDiffTbody,
    numeric: ColumnNumericTbody,
    nps: ColumnNpsTbody,
    multiOpen: ColumnMultiOpenTbody,
    gMap: ColumnGMapTbody,
    matrix: ColumnMatrixTbody,
    matrixMulti: ColumnMatrixMultiTbody,
    matrixDropdown: ColumnMatrixDropdownTbody,
  },
  tfoot: {
    single: ColumnSingleTfoot,
    multi: ColumnMultiTfoot,
    numericMulti: ColumnNumericMultiTfoot,
    basket: ColumnBasketTfoot,
    differential: ColumnDifferentialTfoot,
    open: ColumnOpenTfoot,
    maxDiff: ColumnMaxDiffTfoot,
    numeric: ColumnNumericTfoot,
    nps: ColumnNpsTfoot,
    multiOpen: ColumnMultiOpenTfoot,
    gMap: ColumnGMapTfoot,
    matrix: ColumnMatrixTfoot,
    matrixMulti: ColumnMatrixMultiTfoot,
    matrixDropdown: ColumnMatrixDropdownTfoot,
  },
};

class SurveyResponsesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filterVisible: true,
      searching: false,
      responsesSelected: [],
      questions: [],
      pageSize: 50,
      page: 0,
      filters: {
        statuses: [3],
      },
      filterQuestions: {},
      data: [],
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
    this.onChangeQuestions = this.onChangeQuestions.bind(this);
    this.onChangeFilterQuestions = this.onChangeFilterQuestions.bind(this);
    this.onSurveyResponseCheckedChange = this.onSurveyResponseCheckedChange.bind(this);
  }

  onChangeQuestions(questions){
    this.setState({
      questions
    });
  }

  onChangeFilterQuestions(key, filter){
    let {filterQuestions} = this.state;

    if(filter === false){
      delete filterQuestions[key];
    }else{
      filterQuestions[key] = filter;
    }

    this.setState({
      filterQuestions,
      page: 0,
    }, () => this.fetchData());
  }

  onSurveyResponseCheckedChange(id){
    let {responsesSelected} = this.state;

    if(responsesSelected.includes(id)){
      responsesSelected = _.remove(responsesSelected, v => v !== id);
    }else{
      responsesSelected.push(id);
    }

    this.setState({
      responsesSelected
    });
  }

  fetchData() {
    const {filters, filterQuestions, questions, page, pageSize} = this.state;
    const {surveyId} = this.props;

    this.setState({
      searching: true,
      responsesSelected: [],
    });

    const data = {
      page: page+1,
      pageSize,
      questions,
      filters,
      filterQuestions: filterQuestions,
    };

    api.survey.surveyResponses.post.search(surveyId, data).then(res => {
      this.setState({
        searching: false,
        data: res,
      });
    });
  }

  render() {
    const {filters, searching, data, questions, responsesSelected, pageSize, page, filterVisible} = this.state;

    return <div className="h-100 d-flex flex-column">
      <div className={classnames({
        'd-flex flex-column': true,
        'mb-4': filterVisible,
      })}>
        <div className={classnames({
          'd-flex mb-2': filterVisible,
          'd-none': !filterVisible
        })}>
          <SelectQuestions
            onChange={this.onChangeQuestions}
            enabledTypes={['single', 'multi', 'numericMulti', 'basket', 'differential', 'open', 'numeric', 'nps', 'multiOpen', 'gMap', 'matrix', 'matrixMulti', 'matrixDropdown', 'maxDiff']}
            className='w-75'
          />
          <StatusesSelect className="w-25 ml-3" value={filters.statuses} onChange={(statuses) => this.setState({filters: {...filters, statuses}})} />
          <Button color="primary" onClick={this.fetchData} className="ml-3">Pokaż</Button>
        </div>
        <p className="text-center pointer mb-3" onClick={() => this.setState({filterVisible: !filterVisible})}>
          {filterVisible && <span><i className="fas fa-arrow-up" /> Ukryj</span>}
          {!filterVisible && <span><i className="fas fa-arrow-down" /> Pokaż </span>}
        </p>
      </div>

      {(questions.length === 0 && _.isEmpty(data)) && <Alert color="info" className="text-center">Wybierz pytania lub kliknij pokaż</Alert>}

      {(searching && _.isEmpty(data)) && <LoadingSpinner />}

      {(!searching && data.count === 0) && <Alert color="info" className="text-center">Brak wywiadów</Alert>}

      {(!_.isEmpty(data)) && <div id="responses-table">
        <Table className="border table-hover">
          <thead>
            <tr className="thead-dark">
              <th colSpan={3} className="text-center">Wywiad</th>
              {data.questions.map((question, key) => {
                return <th key={key.toString()} colSpan={ComponentsQuestion.thead[question.type].getColSpan(question)} className="text-center border-left border-right">{reactHtmlParser(striptags(question.content))}</th>
              })}
            </tr>
            <tr className="thead-light">
              <th style={{width: '80px'}}>Id</th>
              <th style={{width: '140px'}}>Data wypełnienia</th>
              <th style={{width: '240px'}}>Użytkownik</th>
              {data.questions.map((question, key) => {
                return React.createElement(ComponentsQuestion.thead[question.type], {
                  key: key.toString(),
                  question,
                  onFilterChange: this.onChangeFilterQuestions
                });
              })}
            </tr>
          </thead>
          <tbody>
          {searching && <tr><td colSpan={999} className="text-center"><LoadingSpinner /></td></tr>}
          {(!searching && data.data.length > 0) && data.data.map((row, key) => <tr
            className={classnames({
              'pointer': true,
              'table-warning': responsesSelected.includes(row.surveyResponse.id)
            })}
            key={key.toString()}
            onClick={() => this.onSurveyResponseCheckedChange(row.surveyResponse.id)}
          >
            <td>{row.surveyResponse.id}</td>
            <td>{row.surveyResponse.createdAt}</td>
            <td>{row.surveyResponse.unknown ? row.surveyResponse.unknown.uniqueKey : (row.surveyResponse.userId ? row.surveyResponse.userId : <i>Brak</i>)}</td>
            {data.questions.map((question, key) => {
              return React.createElement(ComponentsQuestion.tbody[question.type], {
                key: key.toString(),
                question,
                questionResponse: row.questionResponses[question.id] || null,
              });
            })}
          </tr>)}
          {(!searching && data.data.length === 0) && <tr><td colSpan={9999}><div className="d-flex align-items-center justify-content-center text-center h-100">Brak danych</div></td></tr>}
          </tbody>
          {!searching && <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            {data.questions.map((question, key) => {
              return React.createElement(ComponentsQuestion.tfoot[question.type], {
                key: key.toString(),
                question,
                responsesSelected,
                onSaved: this.fetchData,
              });
            })}
          </tr>
          </tfoot>}
        </Table>
      </div>}

      {data.count > 0 && <div><Pagination
        pages={Math.ceil(data.count / pageSize)}
        pageSize={pageSize}
        page={page}
        rowsCount={data.count}
        data={data.data}
        onPageChange={(page) => this.setState({page}, () => this.fetchData())}
        onPageSizeChange={(pageSize) => this.setState({pageSize, page: 0}, () => this.fetchData())}
      /></div>}
    </div>
  }
}

SurveyResponsesList.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default SurveyResponsesList;