import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Badge, Col, Row, Table} from "reactstrap";
import LoadingSpinner from "../../../../../../../../../components/LoadingSpinner";
import striptags from "striptags";

class AdvancedFrequency extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };

    this.getData = this.getData.bind(this);
  }

  typeSingle(data){
    let percent = 0;
    return <Table size="sm" bordered>
      <thead className="thead-dark">
      <tr>
        <th>Odpowiedź</th>
        <th>N</th>
        <th>% ważnych</th>
        <th>% w całej próbie</th>
      </tr>
      </thead>
      <tbody>
      {data.data.answers.map(answer => {
        percent += (answer.value / data.data.n) * 100;

        return <tr>
          <td><div dangerouslySetInnerHTML={{__html: striptags(answer.content)}} /></td>
          <td>{answer.value}</td>
          <td>{((answer.value / data.data.n) * 100).toFixed(1)}</td>
          <td>{((answer.value / data.responsesCount) * 100).toFixed(1)}</td>
        </tr>
      })}
      </tbody>
      <tfoot className="bg-secondary text-white">
      <tr>
        <td>RAZEM</td>
        <td>{data.data.n}</td>
        <td>{percent.toFixed(1)}</td>
        <td></td>
      </tr>
      </tfoot>
    </Table>
  }

  typeOpen(data){
    let percent = 0;
    return <Table size="sm" bordered>
      <thead className="thead-dark">
      <tr>
        <th>Odpowiedź</th>
        <th>N</th>
        <th>% ważnych</th>
        <th>% w całej próbie</th>
      </tr>
      </thead>
      <tbody>
      {data.data.answers.map(answer => {
        percent += (answer.value / data.data.n) * 100;

        return <tr>
          <td><div dangerouslySetInnerHTML={{__html: striptags(answer.content)}} /></td>
          <td>{answer.value}</td>
          <td>{((answer.value / data.data.n) * 100).toFixed(1)}</td>
          <td>{((answer.value / data.responsesCount) * 100).toFixed(1)}</td>
        </tr>
      })}
      </tbody>
      <tfoot className="bg-secondary text-white">
      <tr>
        <td>RAZEM</td>
        <td>{data.data.n}</td>
        <td>{percent.toFixed(1)}</td>
        <td></td>
      </tr>
      </tfoot>
    </Table>
  }

  typeMatrix(data){
    return data.data.data.map(row => {
      let percent = 0;

      const n = data.question.columns.map(column => row['column_' + column.position]).reduce(function(a, b) { return parseInt(parseInt(a) + parseInt(b)) });

      return <Table bordered size="sm">
        <thead className="thead-dark">
        <tr>
          <th colSpan={4} className="text-center"><div dangerouslySetInnerHTML={{__html: striptags(row.content)}} /></th>
        </tr>
        <tr>
          <th>Odpowiedź</th>
          <th>N</th>
          <th>% ważnych</th>
          <th>% w całej próbie</th>
        </tr>
        </thead>
        <tbody>
        {data.question.columns.map((column) => {
          percent += (row['column_' + column.position] / n) * 100;

          return <tr key={column.position.toString()}>
            <td><div dangerouslySetInnerHTML={{__html: striptags(column.content)}} /></td>
            <td>{row['column_' + column.position]}</td>
            <td>{((row['column_' + column.position] / n) * 100).toFixed(1)}</td>
            <td>{((row['column_' + column.position] / data.responsesCount) * 100).toFixed(1)}</td>
          </tr>
        })}
        </tbody>
        <tfoot className="bg-secondary text-white">
        <tr>
          <td>RAZEM</td>
          <td>{n}</td>
          <td>{percent.toFixed(1)}</td>
          <td></td>
        </tr>
        </tfoot>
      </Table>
    });
  }

  getData(){
    const {advanced, data} = this.props;

    if(data === false){
      return <LoadingSpinner />
    }

    switch (advanced.question.type) {
      case 'single':
      case 'multi':
        return this.typeSingle(data);
      case 'open':
        return this.typeOpen(data);
      case 'matrix':
      case 'matrixMulti':
        return this.typeMatrix(data);
    }

    return <p>Brak danych</p>
  }

  render() {
    const {data} = this.props;

    return <React.Fragment>
      <div className="d-flex align-content-center justify-content-center mb-3">
        <Badge color="secondary" style={{fontSize: '1em'}}>N = {data.responsesCount}</Badge>
      </div>

      <Row>
        <Col>
          {this.getData()}
        </Col>
      </Row>
    </React.Fragment>;
  }
}

AdvancedFrequency.propTypes = {
  advanced: PropTypes.shape({
    question: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AdvancedFrequency;