import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Alert, Table} from "reactstrap";

class AdvancedStatistics extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };

    this.getData = this.getData.bind(this);
  }

  getData(){
    const {advanced, data} = this.props;

    switch (advanced.question.type) {
      case 'single':
      case 'numeric': {
        if(data === false){
          return <Alert className="text-center" color="warning">Brak danych</Alert>
        }
        return <div className="d-flex justify-content-between align-items-center w-100">
          <div className="w-100 text-center">
            <div className="d-flex flex-column p-4 m-4 border shadow">
              {data.avg === false ? <span>Brak danych</span> : parseFloat(data.avg).toFixed(2)}
              <strong>Średnia</strong>
            </div>
          </div>
          <div className="w-100 text-center">
            <div className="d-flex flex-column p-4 m-4 border shadow">
              {data.median === false ? <span>Brak danych</span> : parseFloat(data.median).toFixed(2)}
              <strong>Mediana</strong>
            </div>
          </div>
          <div className="w-100 text-center">
            <div className="d-flex flex-column p-4 m-4 border shadow">
              {data.variance === false ? <span>Brak danych</span> : parseFloat(data.variance).toFixed(2)}
              <strong>Wariancja</strong>
            </div>
          </div>
          <div className="w-100 text-center">
            <div className="d-flex flex-column p-4 m-4 border shadow">
              {data.standardDeviation === false ? <span>Brak danych</span> : parseFloat(data.standardDeviation).toFixed(2)}
              <strong>Odchylenie standardowe</strong>
            </div>
          </div>
        </div>
      }
      case 'ranked':
      case 'differential':
      case 'matrix': {
        if(data === false){
          return  <p>Brak danych</p>
        }
        return <div style={{overflowX: 'scroll'}}>
          <Table bordered>
            <thead>
            <tr>
              <td></td>
              <td>Średnia</td>
              <td>Mediana</td>
              <td>Wariancja</td>
              <td>Odchylenie standardowe</td>
            </tr>
            </thead>
            <tbody>
            {data.map((value, key) => {
              return <tr key={key.toString()}>
                <td><div dangerouslySetInnerHTML={{__html: value.content}} /></td>
                <td>{value.avg === undefined ? <span>Brak danych</span> : parseFloat(value.avg).toFixed(2)}</td>
                <td>{value.median === undefined ? <span>Brak danych</span> : parseFloat(value.median).toFixed(2)}</td>
                <td>{value.variance === undefined ? <span>Brak danych</span> : parseFloat(value.variance).toFixed(2)}</td>
                <td>{value.standardDeviation === undefined ? <span>Brak danych</span> : parseFloat(value.standardDeviation).toFixed(2)}</td>
              </tr>
            })}
            </tbody>
          </Table>
        </div>
      }
      case 'matrixDropdown': {
        if(data === false){
          return  <p>Brak danych</p>
        }

        return data.map(d => <div className="mt-3" style={{overflowX: 'scroll'}}>
          <Table bordered className="mb-0">
            <thead>
            <tr className="bg-dark text-white">
              <td colSpan={5}>
                <div className="d-flex align-content-center justify-content-center font-weight-bold">
                  {d.row}
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Średnia</td>
              <td>Mediana</td>
              <td>Wariancja</td>
              <td>Odchylenie standardowe</td>
            </tr>
            </thead>
            <tbody>
            {d.data.map((value, key) => {
              return <tr key={key.toString()}>
                <td><div dangerouslySetInnerHTML={{__html: value.content}} /></td>
                <td>{value.avg === undefined ? <span>Brak danych</span> : parseFloat(value.avg).toFixed(2)}</td>
                <td>{value.median === undefined ? <span>Brak danych</span> : parseFloat(value.median).toFixed(2)}</td>
                <td>{value.variance === undefined ? <span>Brak danych</span> : parseFloat(value.variance).toFixed(2)}</td>
                <td>{value.standardDeviation === undefined ? <span>Brak danych</span> : parseFloat(value.standardDeviation).toFixed(2)}</td>
              </tr>
            })}
            </tbody>
          </Table>
        </div>);
      }
      case 'numericMulti': {
        if(data === false){
          return  <p>Brak danych</p>
        }
        return <div style={{overflowX: 'scroll'}}>
          <Table bordered>
            <thead>
            <tr>
              <td></td>
              <td>Średnia</td>
              <td>Mediana</td>
              <td>Wariancja</td>
              <td>Odchylenie standardowe</td>
            </tr>
            </thead>
            <tbody>
            {data.avg.map((value, key) => {
              return <tr key={key.toString()}>
                <td><div dangerouslySetInnerHTML={{__html: value.content}} /></td>
                <td>{value.avg === undefined ? <span>Brak danych</span> : parseFloat(value.avg).toFixed(2)}</td>
                <td>{data['median'][key].median === false ? <span>Brak danych</span> : parseFloat(data['median'][key].median).toFixed(2)}</td>
                <td>{data['variance'][key].variance === false ? <span>Brak danych</span> : parseFloat(data['variance'][key].variance).toFixed(2)}</td>
                <td>{data['standardDeviation'][key].standardDeviation === false ? <span>Brak danych</span> : parseFloat(data['standardDeviation'][key].standardDeviation).toFixed(2)}</td>
              </tr>
            })}
            </tbody>
          </Table>
        </div>
      }
    }

    return <p>Brak</p>
  }

  render() {
    const {data} = this.props;

    return this.getData();
  }
}

AdvancedStatistics.propTypes = {
  advanced: PropTypes.shape({
    question: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AdvancedStatistics;