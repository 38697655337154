import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import RespondentBaseEditButton from './RespondentBaseEditButton';
import RespondentBaseDeleteButton from './RespondentBaseDeleteButton';
import appRoutes from "appRoutes";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import {Button} from "reactstrap";
import api from "api";

class RespondentBasesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      pages: null,
      page: 0,
      pageSize: 10,
      loading: false,
      data: [],
    };

    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state){
    if(!state){
      state = this.state;
    }
    const {pageSize, page} = state;

    this.setState({
      loading: true,
    });

    api.respondentBase.get({
      pageSize,
      page
    })
      .then(data => {
        this.setState({
          loading: false,
          data: data.respondentBases,
          pages: Math.ceil(data.count / pageSize),
        })
      })
  }

  static name(cell, row){
    return <Link to={appRoutes.respondentBase.addresses(row.id)}>{row.name}</Link>;
  }

  static createdAt(cell, row){
    return row.createdAt;
  }

  static actions(base){
    return (
      <ul className="float-right mb-0 p-0 d-flex flex-column flex-md-row justify-content-center justify-content-md-end w-100 text-center">
        <li className="float-left mr-2">
          <Link to={appRoutes.respondentBase.addresses(base.id)}>
            <Button color="" className="m-0 p-0"><i className="fas fa-users"/></Button>
          </Link>
        </li>
        <li className="float-left mr-2">
          <RespondentBaseEditButton respondentBase={base}/>
        </li>
        <li className="float-left mr-2">
          <RespondentBaseDeleteButton respondentBase={base}/>
        </li>
      </ul>
    )
  }

  render() {
    const {data, loading, pages, pageSize} = this.state;

    const columns = [{
      Header: <FormattedMessage id="respondentBasesList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="respondentBasesList.listItems.tableHeader.name" />,
      accessor: 'name'
    }, {
      Header: <FormattedMessage id="respondentBasesList.listItems.tableHeader.createdAt" />,
      accessor: 'createdAt'
    }, {
      Header: <FormattedMessage id="respondentBasesList.listItems.tableHeader.addresses" />,
      accessor: 'addressesCount'
    }, {
      id: 'actions',
      Header: <FormattedMessage id="respondentBasesList.listItems.tableHeader.actions" />,
      accessor: base => RespondentBasesList.actions(base)
    }];

    return (
      <ReactTable
        manual
        minRows={0}
        pages={pages}
        className={'table-wrap'}
        data={data}
        loading={loading}
        onFetchData={this.fetchData}
        columns={columns}
        showPagination={!_.isEmpty(data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="respondentBasesList.listItems.table.noRespondentBases" />}
        defaultPageSize={pageSize}
      />
    )
  }
}

export default RespondentBasesList;