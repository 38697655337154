import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from "react-checkbox-tree";
import striptags from "striptags";
import api from "api";
import LoadingSpinner from "../../LoadingSpinner";
import {injectIntl} from "react-intl";
import reactHtmlParser from "react-html-parser";

class QuestionsSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked || [],
      expanded: [],
      survey: false,
    };
  }

  componentDidMount() {
    const {survey} = this.props;
    api.survey.get.survey(survey)
      .then(data => {
        this.setState({
          survey: data
        })
      });
  }

  getNodes() {
    const {survey} = this.state;
    let nodes = [];

    let setPosition = 1;
    let blockPosition = 1;
    let pagePosition = 1;
    let questionPosition = 1;
    survey.setBlocks.map(setBlock => {
      let setNode = {label: 'Zestaw ' + setPosition, value: 'set ' + setBlock.id, children: []};

      setBlock.blockPages.map(block => {
        let blockNode = {label: 'Blok ' + blockPosition, value: 'block ' + block.id, children: []};

        block.pages.map(page => {

          page.questions.map(question => {
            let questionNode = {label: 'P' + questionPosition + '. ' + (question.content ? reactHtmlParser(striptags(question.content)) : this.props.intl.formatMessage({id: '_.question.content.empty'})), value: question.id, children: []};

            questionPosition++;
            blockNode.children.push(questionNode);
          });

          pagePosition++;
        });

        blockPosition++;
        blockNode.children.length > 0 && setNode.children.push(blockNode);
      });

      // if (setNode.children.length === 1) {
      //   setNode.children = setNode.children[0].children
      // }

      setPosition++;
      setNode.children.length > 0 && nodes.push(setNode);
    });

    if (nodes.length === 1) {
      nodes = nodes[0].children;
    }

    return nodes;
  }

  render() {
    const {survey} = this.state;

    if(!survey){
      return <LoadingSpinner/>
    }

    return (
      <CheckboxTree
        nodes={this.getNodes()}
        checked={this.state.checked}
        expanded={this.state.expanded}
        onCheck={checked => {
          if (!this.props.disabled) {
            this.setState({ checked });
            this.props.onChange(checked);
          }
        }}
        onExpand={expanded => this.setState({ expanded })}
        icons={{
          check: <i className="fas fa-check-circle" />,
          uncheck: <i className="fas fa-circle" />,
          halfCheck: <i className="fas fa-circle" />,
          expandClose: <i className="fas fa-arrow-right" />,
          expandOpen: <i className="fas fa-arrow-down" />,
          parentClose: <i className="fas fa-folder" />,
          parentOpen: <i className="fas fa-folder-open" />,
          leaf: <i className="fas fa-question" />,
        }}
      />
    );
  }
}

QuestionsSelect.defaultProps = {
  disabled: false
};

QuestionsSelect.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.array,
  disabled: PropTypes.bool.isRequired
};

export default injectIntl(QuestionsSelect);