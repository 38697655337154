import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Tooltip} from "reactstrap";
import classnames from "classnames";
import InputNumber from "components/InputNumber";

class RandQuestions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: {
        randQuestions: props.page.randQuestions,
      },
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(value){
    if(!value || value < 0){
      value = 0;
    }

    this.setState({
      page: {
        ...this.state.page,
        randQuestions: value
      }
    }, () => {
      this.props.handleUpdate(this.state.page)
    });
  }

  render() {
    let { page, tooltip } = this.state;

    return (
      <div
        id={"randQuestions-" + this.props.page.id}
        className={classnames({
          "min-w-0 float-left mr-1 mb-1": true,
          "w-auto": !page.randQuestions,
          "w-100 w-lg-auto input-group": page.randQuestions
        })}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": page.randQuestions > 0,
            })}
            color="secondary"
            onClick={() => {
              if(page.randQuestions === 0){
                this.onChange(1)
              }else{
                this.onChange(0)
              }
            }}
          >
            <i className="fa-solid fa-dice" />
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltip}
              target={"randQuestions-" + this.props.page.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="page.settings.randQuestions.button.tooltip"/>
            </Tooltip>
          </Button>
        </InputGroupAddon>
        {page.randQuestions > 0 && <InputNumber value={page.randQuestions} min={0} max={this.props.page.questions.length} onChange={(value) => this.onChange(value)}/>}
      </div>
    )
  }
}

RandQuestions.propTypes = {
  page: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default RandQuestions;
