import React, {Component} from 'react';
import {Modal, ModalFooter, ModalBody, ModalHeader, Button, Input, Form, Label, FormGroup, Col} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import api from 'api';
import LoadingSpinner from "components/LoadingSpinner";
import Select from 'react-select';
import _ from 'lodash';

class Settings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      widget: {
        survey: this.props.widget.survey ? this.props.widget.survey.id : '',
        collectors: this.props.widget.collectors || [],
        name: this.props.widget.name || ''
      },
      surveys: [],
      collectors: [],
      loading: true,
      loadingCollectors: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSurveyChange = this.onSurveyChange.bind(this);
    this.onCollectorsChange = this.onCollectorsChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    api.user.get.surveys().then(surveys => {
      let orderedSurveys = {};
      _.each(surveys, survey => orderedSurveys[survey.id] = survey);

      this.setState({...this.state, surveys: orderedSurveys, loading: false}, () => {
        let surveyId = this.state.widget.survey;

        if (surveyId === '' && Object.keys(orderedSurveys).length > 0) {
          surveyId = this.state.surveys[_(Object.keys(this.state.surveys)).first()].id;

          this.setState({
            ...this.state,
            widget: {
              ...this.state.widget,
              survey: surveyId
            }
          })
        }

        api.survey.get.collectors({id: surveyId}).then(collectors => {
          let orderedCollectors = {};
          _.each(collectors, collector => orderedCollectors[collector.id] = collector);
          this.setState({...this.state, collectors: orderedCollectors});
        });
      })
    });
  }

  onChange(e) {
    this.setState({
      ...this.state,
      widget: {
        ...this.state.widget,
        [e.target.name]: e.target.value,
      }
    })
  }

  onSurveyChange(e) {
    let surveyId = e.target.value;

    if (this.state.widget.survey !== surveyId) {
      this.setState({loadingCollectors: true});

      api.survey.get.collectors({id: surveyId}).then(collectors => {
        let orderedCollectors = {};
        _.each(collectors, collector => orderedCollectors[collector.id] = collector);

        this.setState({
          ...this.state,
          widget: {
            ...this.state.widget,
            survey: surveyId,
            collectors: []
          },
          collectors: orderedCollectors,
          loadingCollectors: false
        });
      });
    }
  }

  onCollectorsChange(e) {
    let collectors = [];
    e.map(collector => collectors.push(collector.value));

    this.setState({
      ...this.state,
      widget: {
        ...this.state.widget,
        collectors: collectors,
      }
    })
  }

  onSubmit() {
    if (this.state.loading)
      return;

    const data = {
      id: this.props.widget.id,
      widget: this.state.widget
    };

    if (data.widget.name.length === 0)
      delete data.widget.name;

    this.props.handleSettingsSet(data);
    this.props.toggle('settingsModal');
    this.props.widget.id && this.props.handleDataChange();
  }

  render() {
    let surveyList = [];
    let collectorsList = [];
    let defaultValues = [];

    if (!this.state.loading) {
      _.map(this.state.surveys, survey => {
        surveyList.push(<option value={survey.id} key={survey.id}>{survey.name}</option>);
      });

      _.map(this.state.collectors, collector => {
        collectorsList.push({value: collector.id, label: collector.name || collector.type + ' ' + collector.position});
      });

      if (Object.keys(this.state.collectors).length > 0) {
        _.map(this.state.widget.collectors, collector => {
          defaultValues.push({value: collector, label: this.state.collectors[collector].name || this.state.collectors[collector].type + ' ' + this.state.collectors[collector].position});
        });
      }
    }

    return (
      <Modal isOpen={this.props.modal} toggle={() => this.props.toggle('settingsModal')} size="lg"
             className={'modal-danger'}>
        <ModalHeader toggle={() => this.props.toggle('settingsModal')}><FormattedMessage id="widgetTypes.typeCollectors.settings.modalHeader"/></ModalHeader>
        <hr className="my-0" />
        {this.state.loading ? <LoadingSpinner classNames="my-0" width={38}/> :
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="widgetTypes.typeCollectors.settings.label.name"/></Label>
              <Col lg={9}>
                <FormattedMessage id="widgetTypes.typeCollectors.settings.placeholder.name">{
                  (message) =>
                    <Input type="text" name="name" placeholder={message} onChange={(e) => this.onChange(e)}
                           value={this.state.widget.name}/>
                }</FormattedMessage>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="widgetTypes.typeCollectors.settings.label.survey"/></Label>
              <Col lg={9}>
                <Input type="select" id="hf-category" name="survey" onChange={this.onSurveyChange}
                       value={this.state.widget.survey}>
                  {surveyList}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="widgetTypes.typeCollectors.settings.collectors.label"/></Label>
              <Col lg={9}>
                <Select
                  isMulti
                  value={defaultValues}
                  onChange={this.onCollectorsChange}
                  options={collectorsList}
                  isLoading={this.state.loadingCollectors}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>}
        <ModalFooter>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda"
              data-style={ZOOM_OUT}
              onClick={this.onSubmit}
              disabled={this.state.loading || this.state.widget.collectors.length === 0}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <Button color="secondary" onClick={() => this.props.toggle('settingsModal')}><FormattedMessage
            id="_.button.cancel"/></Button>
        </ModalFooter>
      </Modal>
    )
  }
}

Settings.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleSettingsSet: PropTypes.func.isRequired,
  handleDataChange: PropTypes.func,
  widget: PropTypes.shape({
    id: PropTypes.number,
    survey: PropTypes.shape({
      id: PropTypes.number
    })
  })
};

export default Settings;
