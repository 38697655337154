import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../components/Button";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import api from "../../api";
import {toast} from "react-toastify";
import classnames from "classnames";
import moment from "moment";

const RealizationAutomateSendEmailButton = props => {
  const {collector} = props;
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => {
    if (!disabled) {
      setModalOpen(!modalOpen);
    }
  }
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(collector.realizationAutomateLastManualShipment && moment().subtract(1, 'day').isBefore(collector.realizationAutomateLastManualShipment));

  const send = () => {
    setLoading(true);

    api.surveyCollector.panel.realizationAutomateSendEmail(props.collectorId)
      .then(() => {
        setDisabled(true)
        toast.success('Wysyłka maili została zaplanowana')
      })
      .catch(() => {
        toast.error('Wystąpił błąd podczas tworzenia wysyłki')
      })
      .finally(() => {
        setLoading(false);
        setModalOpen(false)
      })
  }

  return (
    <React.Fragment>
      <Button
          className={classnames({"disabled": disabled})}
          size={'sm'} onClick={toggleModalOpen}><i className="fa-solid fa-gauge-max mr-2" /> <span>Wyślij zaproszenia e-mail</span></Button>
      {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
        <ModalHeader toggle={toggleModalOpen}>Wyślij zaproszenia e-mail</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <p>Czy na pewno wykonać wysyłkę zaproszeń e-mail?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
          <Button
            color="primary"
            loading={loading}
            onClick={send}
          >Wyślij teraz</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

RealizationAutomateSendEmailButton.propTypes = {

};

export default RealizationAutomateSendEmailButton;
