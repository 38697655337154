import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input} from "reactstrap";
import ModalDelete from "../../../Rows/components/Row/components/ModalDelete";
import ButtonDrag from "../../../Rows/components/Row/components/ButtonDrag";
import LoadingSpinner from "components/LoadingSpinner";
import QuestionRowNew from "../RowNew";
import {deleteRow, updateRow} from "scenes/Survey/Structure/data/rows/actions";
import reactStringReplace from "react-string-replace";
import reactHtmlParser from "react-html-parser";
import HtmlEditor
  from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";
import {FormattedMessage} from "react-intl";
import Checkbox from "components/Checkbox";
import ButtonExcludedFromRotate from "../../../Rows/components/Row/components/ButtonExcludedFromRotate";
import getCKConfigByPlan from 'utils/ckeditor/cafeteryConfig';
import {isMobile, isTablet} from "react-device-detect";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "components/PlanRestrictionsModals/modals/MultimediaAvailable";

class QuestionRow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownSettingsOpen: false,
      checked: false,
      deleteButton: false,
      modalDelete: false,
      deleting: false,
      editingLeft: false,
      editingRight: false,
      leftSaving: false,
      RightSaving: false,
      addNewRowBelow: false,
      hovered: false,
      row: {
        leftContent: props.row.leftContent,
        rightContent: props.row.rightContent,
        excludedFromRotate: props.row.excludedFromRotate,
      },
    };

    this.deleteRow = this.deleteRow.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setHovered = this.setHovered.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      ...this.state,
      row: {
        ...this.state.row,
        leftContent: nextProps.row.leftContent,
        rightContent: nextProps.row.rightContent
      }
    })
  }

  onClick(property){
    this.setState({[property]: true});
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.updateRow()
  }

  onChangeContent(side, content){
    const saving = this.state[side + 'saving'];

    if(!saving) {
      this.setState({
        row: {
          ...this.state.row,
          [side + 'Content']: content,
          [side + 'saving']: saving
        }
      });
    }
  }

  toggleChecked(){
    const checked = !this.state.checked;

    this.setState({
      checked
    }, () => this.props.handlerChecked(checked));
  }

  updateRow(savingType){
    const {row} = this.state;

    this.setState({
      [savingType]: true,
      editingLeft: false,
      editingRight: false
    });

    const data = {
        id: this.props.row.id,
        row: {
            leftContent: row.leftContent,
            rightContent: row.rightContent
        }
    };

    this.props.updateRow(data).then(() => this.setState({[savingType]: false}));
  }

  deleteRow(){
    this.setState({
      deleting: true
    });

    if(!this.state.deleting){
      this.props.deleteRow({
        id: this.props.row.id
      })
    }
  }

  toggleOption(option) {
    this.setState({
      row: {
        ...this.state.row,
        [option]: !this.state.row[option]
      }
    }, () => {
      const data = {
        id: this.props.row.id,
        row: {
          [option]: this.state.row[option]
        }
      };

      this.props.updateRow(data);
    });
  }

  changeOption(option, value) {
    this.setState({
      row: {
        ...this.state.row,
        [option]: value
      }
    }, () => {
      const data = {
        id: this.props.row.id,
        row: {
          [option]: value
        }
      };

      this.props.updateRow(data);
    });
  }

  toggle(type, e = {}) {
    if (e.target.className.includes("value-button"))
      return;

    this.setState({
      [type]: !this.state[type]
    })
  }

  setHovered(hovered) {
    this.setState({hovered});
  }

  render() {
    const {position, question, searchInStructure, hideInStructure, plan} = this.props;
    const {row, checked, deleting, addNewRowBelow, editingLeft, editingRight, leftSaving, RightSaving, hovered} = this.state;
    const ctrlEnterKeyCode = 1114125;
    const gripStyle = hovered ? {opacity: 1} : {opacity: 0};
    let leftEditor;
    let rightEditor;

    if (isMobile || isTablet) {
      leftEditor = <Input value={row.leftContent} onChange={e => this.onChangeContent('left', e.target.value)} onBlur={this.updateRow} autoFocus={true} onKeyPress={this.handleKeyPress}/>;
      rightEditor = <Input value={row.rightContent} onChange={e => this.onChangeContent('reft', e.target.value)} onBlur={this.updateRow} autoFocus={true} onKeyPress={this.handleKeyPress}/>;
    } else {
      leftEditor = <HtmlEditor
        data={row.leftContent}
        config={getCKConfigByPlan(plan)}
        type="inline"
        onChange={event => this.onChangeContent('left', event)}
        onBlur={event => {
          this.updateRow('leftSaving');
          this.setState({editingLeft: false})
        }}
        onKey={event => {
          if (event.data.keyCode === ctrlEnterKeyCode) {
            event.cancel();
            this.updateRow('leftSaving');
            setTimeout(() => {
              event.editor.destroy();
              this.setState({editingLeft: false})
            }, 1);
          }
        }}
      />;
      rightEditor = <HtmlEditor
        data={row.rightContent}
        config={getCKConfigByPlan(plan)}
        type="inline"
        onChange={event => this.onChangeContent('right', event)}
        onBlur={event => {
          this.updateRow('rightSaving');
          this.setState({editingRight: false})
        }}
        onKey={event => {
          if (event.data.keyCode === ctrlEnterKeyCode) {
            event.cancel();
            this.updateRow('rightSaving');
            setTimeout(() => {
              event.editor.destroy();
              this.setState({editingRight: false})
            }, 1);
          }
        }}
      />;
    }

    let style = {
      position: 'relative'
    };

    let contentStyle = hideInStructure === 'minified' ? {
      width: '75%',
      display: 'list-item',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    } : {};

    if(deleting) style.opacity = 0.35;

    let rowLeftContent = row.leftContent ? row.leftContent : '<i class="alert-warning mx-5">Brak treści</i>';
    if (searchInStructure.length > 0) {
      rowLeftContent = reactStringReplace(rowLeftContent, searchInStructure, (match, i) => (
        <span className="bg-warning" key={i}>{match}</span>
      ));
    }

    let rowRightContent = row.rightContent ? row.rightContent : '<i class="alert-warning mx-5">Brak treści</i>';
    if (searchInStructure.length > 0) {
      rowRightContent = reactStringReplace(rowRightContent, searchInStructure, (match, i) => (
        <span className="bg-warning" key={i}>{match}</span>
      ));
    }

    return (
      <React.Fragment>
        <div className="sortable-draggable line-wrap" style={style} data-id={this.props.row.id} onMouseEnter={_ => this.setHovered(true)} onMouseLeave={_ => this.setHovered(false)}>
          <ButtonDrag id={this.props.row.id} gripStyle={gripStyle}/>

          <ul className="mb-0 d-flex pl-2">
            <li className="d-none d-lg-inline-block">
              <div className="form-group m-0 overflow-hidden">
                <Checkbox checked={checked} id={'row-' + this.props.row.id + '-check'} onClick={() => this.toggleChecked()} messageId={"checkbox.check.row"}/>
              </div>
            </li>
            <li>{position + 1}.</li>

            <li className="d-flex justify-content-between align-items-center flex-grow-1" style={contentStyle}>
              {question.rowsImage ?
                (
                  <React.Fragment>
                    <span className="d-inline-grid text-center w-50">
                      <img
                        className="mx-auto"
                        alt={row.leftContent}
                        style={{
                          maxHeight: '70px'
                        }}
                        src={row.leftContent || '/dashboard/img/no-image.png'}
                        onClick={() => {
                          if (!plan.multimedia.access) {
                            this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
                            return;
                          }
                          CKFinder.popup({
                            connectorInfo: 'auth=' + localStorage.jwt,
                            chooseFiles: true,
                            width: 1014,
                            height: 700,
                            onInit: finder => {
                              finder.on('files:choose', e => {
                                this.onChangeContent('left', e.data.files.first().getUrl());
                                this.updateRow('leftSaving');
                              });
                            }
                          })
                        }}
                      />
                    </span>
                    <i className="fas fa-arrows-alt-h d-inline-grid"/>
                    <span className="d-inline-grid text-center  w-50">
                      <img
                        className="mx-auto"
                        alt={row.rightContent}
                        style={{
                          maxHeight: '70px'
                        }}
                        src={row.rightContent || '/dashboard/img/no-image.png'}
                        onClick={() => {
                          if (!plan.multimedia.access) {
                            this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
                            return;
                          }
                          CKFinder.popup({
                            connectorInfo: 'auth=' + localStorage.jwt,
                            chooseFiles: true,
                            width: 1014,
                            height: 700,
                            onInit: finder => {
                              finder.on('files:choose', e => {
                                this.onChangeContent('right', e.data.files.first().getUrl());
                                this.updateRow('rightSaving');
                              });
                            }
                          })
                        }}
                      />
                    </span>
                  </React.Fragment>
                )
                :
                <React.Fragment>
                  <span onClick={() => this.onClick('editingLeft')} className="d-flex flex-nowrap justify-content-center align-items-center w-50">
                    {editingLeft
                      ? leftEditor
                      : leftSaving
                        ? <LoadingSpinner width={21} classNames="my-0"/>
                        : (searchInStructure.length > 0 ? rowLeftContent : reactHtmlParser(rowLeftContent))
                    }
                  </span>
                  <i className="fas fa-arrows-alt-h d-inline-grid"/>
                  <span onClick={() => this.onClick('editingRight')} className="d-flex flex-nowrap justify-content-center align-items-center w-50">
                    {editingRight
                      ? rightEditor
                      : RightSaving
                        ? <LoadingSpinner width={21} classNames="my-0"/>
                        : (searchInStructure.length > 0 ? rowRightContent : reactHtmlParser(rowRightContent))
                    }
                  </span>
                </React.Fragment>
              }
            </li>

            {question.rotateRows === true && <li className="mr-2 d-none d-lg-inline"><ButtonExcludedFromRotate row={this.props.row} toggleOption={this.toggleOption} excludedFromRotate={this.state.row.excludedFromRotate}/></li>}
            <li className="ml-2">
              <Dropdown isOpen={this.state.dropdownQuestionTypeOpen} toggle={e => this.toggle('dropdownQuestionTypeOpen', e)}>
                <DropdownToggle className="m-0 p-0" color="">
                  <span className="fas fa-ellipsis-v hoverable" />
                </DropdownToggle>
                {this.state.dropdownQuestionTypeOpen && <DropdownMenu>
                  {question.rotateRows === true && <DropdownItem tag="a" className={"d-lg-none" + (this.state.row.excludedFromRotate ? " text-primary" : "")} onClick={() => this.toggleOption('excludedFromRotate')}>
                    <p><span className={"fas fa-random" + (this.state.row.excludedFromRotate ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="rows.excludedFromRotate.button.tooltip" />
                  </DropdownItem>}

                  <DropdownItem tag="a" onClick={() => this.setState({addNewRowBelow: !this.state.addNewRowBelow})}>
                    <p><span className="fas fa-plus"/></p> <FormattedMessage tagName="h5" id="row.addNew.button" />
                  </DropdownItem>

                  <DropdownItem tag="a" onClick={(e) => this.toggle('modalDelete', e)}>
                    <p><span className="fas fa-trash"/></p> <FormattedMessage tagName="h5" id="row.modalDelete.button" />
                  </DropdownItem>
                </DropdownMenu>}
              </Dropdown>
            </li>
          </ul>

        </div>
        {addNewRowBelow && <QuestionRowNew autoFocus={true} handlerAdded={() => this.setState({addNewRowBelow: false})} position={position+1} question={question} />}
        {this.state.modalDelete && <ModalDelete handleDelete={this.deleteRow} id={this.props.row.id} isOpen={this.state.modalDelete} toggleModal={(e) => this.toggle('modalDelete', e)}/>}
      </React.Fragment>
    )
  }
}

QuestionRow.propTypes = {
  row: PropTypes.shape({
    content: PropTypes.string,
    description: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    excludeRequired: PropTypes.bool.isRequired,
  }).isRequired,
  position: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    rotateRows: PropTypes.bool.isRequired,
    rowsImage: PropTypes.bool.isRequired,
  }).isRequired,
  handlerChecked: PropTypes.func,
  searchInStructure: PropTypes.string.isRequired,
};

function mapStateToProps(state, props) {
  return {
    row: state.survey.structure.data.rows[props.row.id],
    hideInStructure: state.survey.structure.hideInStructure,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {deleteRow, updateRow, setPlanRestrictionsModals})(QuestionRow);