import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Card, CardHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {connect} from 'react-redux';
import HeaderActions from "./components/HeaderActions";
import Questions from "./components/Questions";
import Settings from "./components/Settings";
import {togglePageCard} from "scenes/Survey/Structure/actions"
import HelpModeIconModal from "components/HelpModeIconModal";

class Page extends Component {

  constructor(props) {
    super(props);
    this.state = {
      optionsOpen: false,
      headerActions: true,
      hovered: false,
    };
    this.headerActions = this.headerActions.bind(this);
    this.onCardHeaderClick = this.onCardHeaderClick.bind(this);
    this.setHovered = this.setHovered.bind(this);
  }

  toggleOptions() {
    this.setState({
      optionsOpen: !this.state.optionsOpen
    });
  }

  headerActions(bool){
    this.setState({
      headerActions: bool
    })
  }

  onCardHeaderClick(e) {
    if (e.target.getAttribute('data-toggleable') && this.props.hideInStructure !== 'none')
      this.props.togglePageCard(this.props.page.id);
  }

  setHovered(hovered) {
    this.setState({hovered});
  }

  render() {
    const {page, closedPageCards,  setsCount, blocksCount, pagesCount, questionsCount, hideInStructure} = this.props;
    const {headerActions, optionsOpen, hovered} = this.state;
    const showHeader = (questionsCount > 0 || pagesCount > 1 || blocksCount > 1 || setsCount > 1) && hideInStructure !== 'structure';
    const gripStyle = hovered ? {opacity: 1} : {opacity: 0};

    return (
      <React.Fragment>
        <Card data-id={page.id} data-type="pageCard" role="page" id={`page-${page.id}`} className="border-l blue">
          {showHeader && <CardHeader className="py-md-2" onClick={this.onCardHeaderClick} data-toggleable={true} onMouseEnter={() => this.setHovered(true)} onMouseLeave={() => this.setHovered(false)}>
            <div className="float-left p-0 pt-3 pt-md-1">
              <a className="handler fas fa-grip-vertical dragg float-left" style={gripStyle}/>
              <HelpModeIconModal className="float-left mx-2" modalHeader="Strony">
                <p>Strony wypełniane są pytaniami i wyświetlane w ankiecie</p>
              </HelpModeIconModal>
              <h2 className="float-left mb-0 mr-2 p-0" role="page-position-in-survey">Strona {this.props.positionInSurvey+1}</h2>
              <small className="float-left ml-2" data-toggleable={true}>(<FormattedMessage id="pages.page.cardHeader.countQuestion">{msg => <React.Fragment>{msg}: {page.questions.length}</React.Fragment>}</FormattedMessage>)</small>
            </div>

            {headerActions && <HeaderActions
              page={page}
              optionsOpen={optionsOpen}
              handleToggleOptions={() => this.toggleOptions()}
            />}
          </CardHeader>}

          {this.state.optionsOpen && <Settings page={page} />}
        </Card>

        {!closedPageCards.includes(page.id) && <Questions page={page}/>}
      </React.Fragment>
    )
  }
}

Page.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.number.isRequired,
    questions: PropTypes.array.isRequired,
    positionInSurvey: PropTypes.number.isRequired,
  }).isRequired,
  closedPageCards: PropTypes.array.isRequired,
};

function mapStateToProps(state, props) {
  return {
    closedPageCards: state.survey.structure.closedPageCards,
    setsCount: Object.keys(state.survey.structure.data.sets).length,
    blocksCount: Object.keys(state.survey.structure.data.blocks).length,
    pagesCount: Object.keys(state.survey.structure.data.pages).length,
    questionsCount: state.survey.structure.data.pages[props.page.id].questions.length,
    hideInStructure: state.survey.structure.hideInStructure
  }
}

export default connect(mapStateToProps, {togglePageCard})(Page);
