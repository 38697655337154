import api from "api";
import * as types from './actionTypes';

export function setReports(reports) {
  return {
    type: types.SET,
    reports
  }
}

export function reportDeleted(report) {
  return {
    type: types.DELETED,
    report
  }
}

export function reportAdded(report) {
  return {
    type: types.ADDED,
    report
  }
}

export function reportUpdated(report) {
  return {
    type: types.UPDATED,
    report
  }
}

export function deleteReport(report) {
  return dispatch => {
    return api.report.delete(report).then(() => {
      dispatch(reportDeleted(report));
    })
  }
}

export function fetchReports() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.report.get.reports().then(reports => {
      let orderedReports = {};
      _.map(reports, report => orderedReports[report.id] = report);

      dispatch(setReports(orderedReports));
      dispatch({type: types.SUCCESS});
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}

export function addReport(data) {
  return dispatch => {
    return api.report.post.report(data).then(report => {
      dispatch(reportAdded(report));
    })
  }
}

export function updateReport(data) {
  return dispatch => {
    return api.report.patch(data).then(report => {
      dispatch(reportUpdated(report));
    })
  }
}