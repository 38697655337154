import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Block from "../Block";
import {connect} from "react-redux";

class Blocks extends PureComponent {

  constructor(props) {
    super(props);

    this.handleBlockChanged = this.handleBlockChanged.bind(this);
  }

  deleteBlock(key){
    let {blocks} = this.props;

    blocks = blocks.filter((block, k) => k !== key);

    this.props.handleBlocksChanged(blocks);
  }

  handleBlockChanged(key, block){
    let {blocks} = this.props;

    blocks[key] = block;

    this.props.handleBlocksChanged(blocks);
  }

  render() {
    const {blocks} = this.props;

    return (
      <React.Fragment>
        {blocks.map((block, key) => block.parent ? null : <Block
          collectorPanelAccess={this.props.collectorPanelAccess}
          tokenFilter={this.props.tokenFilter}
          headerVisible={(block.children.length + block.condsQuestion.length) <= 1}
          requiredVisible={false}
          deleteable={false}
          key={key}
          block={!_.isEmpty(block) ? block : undefined}
          handleBlockChanged={(b) => this.handleBlockChanged(key, b)}
          handleBlockDelete={() => this.deleteBlock(key)}
        />)}
      </React.Fragment>
    );
  }
}

Blocks.defaultProps = {
  blocks: [{}],
};

Blocks.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape({
    children: PropTypes.array.isRequired,
    condsQuestion: PropTypes.array.isRequired,
  }),).isRequired,
  handleBlocksChanged: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    collectorPanelAccess: state.user.userPlan.plan.survey.collectorPanelAccess,
    tokenFilter: state.user.userPlan.plan.survey.tokenFilter,
  }
}

export default connect(mapStateToProps)(Blocks);
