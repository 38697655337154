import * as React from 'react';
import PropTypes from "prop-types";
import api from "api";

function CollectorPanelTemplatePrice(props) {
  const [price, setPrice] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);

    api.surveyCollectorPanelTemplate.checkPrice({
      survey: props.surveyId,
      template: props.template.id,
      n: props.n,
    }).then(data => {
      setPrice(data.price);
      setLoading(false);
    })
    }, [props.n]);

  if(loading){
    return <span><i className="fas fa-spin fa-spinner" /></span>
  }

  return (
    <span>{price.toFixed(2)} PLN</span>
  );
}

CollectorPanelTemplatePrice.propTypes = {
  surveyId: PropTypes.number.isRequired,
  template: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  n: PropTypes.number.isRequired,
};

export default CollectorPanelTemplatePrice;