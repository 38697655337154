import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button} from "reactstrap";
import Cond from "./Cond";

class Conds extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {

    };

    this.addCond = this.addCond.bind(this);
    this.removeCond = this.removeCond.bind(this);
    this.handlerCondChanged = this.handlerCondChanged.bind(this);
  }

  addCond(){
    let {conds} = this.props.quota;

    conds.push({});

    this.props.onCondsChanged(conds);
  }

  handlerCondChanged(key, cond){
    let {conds} = this.props.quota;

    conds[key] = cond;

    this.props.onCondsChanged(conds);
  }

  removeCond(condKey){
    let {conds} = this.props.quota;

    conds = conds.filter((cond, key) => key !== condKey);

    this.props.onCondsChanged(conds);
  }

  render() {
    const {quota, questions} = this.props;

    return (
      <React.Fragment>
        {quota.conds.map((cond, key) => {
          return <Cond
            key={key}
            cond={cond}
            deleteCond={() => this.removeCond(key)}
            onCondChange={(cond) => this.handlerCondChanged(key, cond)}
            questions={questions}
          />
        })}
        <Button color="primary" onClick={this.addCond}><i className="fa fa-plus" /> Dodaj warunek</Button>
      </React.Fragment>
    );
  }
}

Conds.propTypes = {
  quota: PropTypes.shape({
    conds: PropTypes.array.isRequired,
  }).isRequired,
  questions: PropTypes.array.isRequired,
  onCondsChanged: PropTypes.func.isRequired,
};

export default Conds;