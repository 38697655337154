import React, {Component} from 'react';
import PropTypes from "prop-types";

class Switch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      checked: nextProps.checked,
    })
  }

  onChange(){
    this.setState({
      checked: this.props.stickToProp ? !this.props.checked : !this.state.checked
    }, () => {
      this.props.onChange(this.state.checked);
    })
  }

  render() {
    const {checked} = this.state;

    return (
      <label id={this.props.id} className={"switch " + this.props.className}>
        <input type="checkbox" checked={this.props.stickToProp ? this.props.checked : checked} disabled={this.props.disabled} onChange={this.onChange} />
          <span className="slider round" />
      </label>
    );
  }
}

Switch.defaultProps = {
  disabled: false,
  stickToProp: false,
  id: '',
  className: ''
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  stickToProp: PropTypes.bool,
  id: PropTypes.string
};

export default Switch;