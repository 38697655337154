import React, {useEffect} from 'react';
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import api from "../../api";
import {isEmpty} from "lodash";
import {Link} from "react-router-dom";
import appRoutes from "../../appRoutes";

const SurveyCollectorsPanelEmailStats = props => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true)

    if(!isEmpty(props.survey)){
      api.survey.getCollectorsPanelEmailStats(props.survey.id)
        .then(res => setData(res.data.data))
        .finally(() => setLoading(false))
    }
  }, [props.survey])

  const statusCountPercent = (count, percent) => {
    return <div className="d-flex justify-content-end align-items-center">
      <span>{count}</span>
      <div className="text-secondary" style={{fontSize: '0.8em', width: '50px'}}>({percent.toFixed(2)}%)</div>
    </div>
  }

  const statusCountPercentFooter = (count, percent) => {
    return <div className="d-flex justify-content-end align-items-center">
      <span>{count}</span>
      <div className="text-white" style={{fontSize: '0.8em', width: '50px'}}>({percent.toFixed(2)}%)</div>
    </div>
  }

  const getDataSum = (key) => {
    return data.reduce((acc, item) => acc + parseInt(item[key]), 0)
  }

  return (
    <main>
      <Card>
        <CardHeader>
          <h2>Statystyki wysyłek</h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <Table size={'sm'} bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Nazwa kolektora</th>
                <th>Wielkość bazy respondentów</th>
                <th>Wysłanych wiadomości</th>
                <th>Otwartych wiadomości</th>
                <th>Wyświetleń wiadomości</th>
                <th>Zakończone</th>
                <th>Screenout + kwota</th>
                <th>Przerwane</th>
                <th>Brak kontaktu</th>
                <th>RR przed SO</th>
                <th>RR po SO</th>
                <th>Ustawienia</th>
              </tr>
            </thead>
            <tbody>
            {loading && <tr>
              <td colSpan={11} className="text-center">
                <i className="fas fa-spin fa-spinner" />
              </td>
            </tr>}
            {!loading && data.length === 0 && <tr>
              <td colSpan={11} className="text-center">
                <span>Brak danych</span>
              </td>
            </tr>}
            {!loading && data.map(row => <tr className="text-right">
              <td className="text-left">{row.id}</td>
              <td className="text-left">{row.name || 'Panel ' + row.position}</td>
              <td>{row.respondentsCount}</td>
              <td>{row.sentCount}</td>
              <td>
                {statusCountPercent(row.sentDisplayCount, row.respondentsCount > 0 ? (row.sentDisplayCount / row.respondentsCount * 100) : 0)}
              </td>
              <td>
                {row.sentSumDisplayCount}
              </td>
              <td>
                {statusCountPercent(row.srEndCount, row.respondentsCount > 0 ? (row.srEndCount / row.respondentsCount * 100) : 0)}
              </td>
              <td>
                {statusCountPercent(row.srScreenoutCount, row.respondentsCount > 0 ? (row.srScreenoutCount / row.respondentsCount * 100) : 0)}
              </td>
              <td>
                {statusCountPercent(row.srBreakCount, row.respondentsCount > 0 ? (row.srBreakCount / row.respondentsCount * 100) : 0)}
              </td>
              <td>
                {statusCountPercent(row.srWithoutCount, row.respondentsCount > 0 ? (row.srWithoutCount / row.respondentsCount * 100) : 0)}
              </td>
              <td>
                {statusCountPercent(row.srRrBeforeSoCount, row.respondentsCount > 0 ? (row.srRrBeforeSoCount / row.respondentsCount * 100) : 0)}
              </td>
              <td>
                {statusCountPercent(row.srRrAfterSoCount, row.respondentsCount > 0 ? (row.srRrAfterSoCount / row.respondentsCount * 100) : 0)}
              </td>
              <td className="text-center">
                <Link id={`c-${row.id}`} to={appRoutes.survey.collectors.collector.view(props.survey.id, row.id)}><i className="fas fa-cog" /></Link>
              </td>
            </tr>)}

            </tbody>
            {!loading && data.length > 0 && <tfoot>
            <tr className="bg-dark text-white font-weight-bold text-right">
              <td colSpan={2}>RAZEM</td>
              <td>{getDataSum('respondentsCount')}</td>
              <td>{getDataSum('sentCount')}</td>
              <td>{statusCountPercentFooter(getDataSum('sentDisplayCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('sentDisplayCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{getDataSum('sentSumDisplayCount')}</td>
              <td>{statusCountPercentFooter(getDataSum('srEndCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srEndCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{statusCountPercentFooter(getDataSum('srScreenoutCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srScreenoutCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{statusCountPercentFooter(getDataSum('srBreakCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srBreakCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{statusCountPercentFooter(getDataSum('srWithoutCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srWithoutCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{statusCountPercentFooter(getDataSum('srRrBeforeSoCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srRrBeforeSoCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{statusCountPercentFooter(getDataSum('srRrAfterSoCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srRrAfterSoCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td></td>
            </tr>
            </tfoot>}
          </Table>
        </CardBody>
      </Card>
    </main>
  );
};

SurveyCollectorsPanelEmailStats.propTypes = {

};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps)(SurveyCollectorsPanelEmailStats);