import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import PreviewVideoButton from "components/Buttons/PreviewVideoButton";

class Video extends Component {
  render() {
    const {details, question} = this.props;

    return (
      <React.Fragment>
        <PreviewVideoButton question={question} className="mb-2"/>
        {details.map((detail, i) => {
          return <p key={i}>{detail.watched ? <FormattedMessage id="analysis.browse.video.watched"/> : <FormattedMessage id="analysis.browse.video.notWatched"/>}</p>
        })}
      </React.Fragment>
    )
  }
}

Video.propTypes = {
  question: PropTypes.object.isRequired,
  details: PropTypes.arrayOf(PropTypes.shape({
    watched: PropTypes.bool.isRequired
  }),).isRequired,
};

export default Video;