import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import FormGroup from "reactstrap/es/FormGroup";
import api from "api";

import {Col, Form, Input, Label} from "reactstrap";
import InputNumber from "../../../InputNumber";
import Switch from "components/Switch";
import Conds from "./QuotaForm/Conds";
import _ from "lodash";
import LoadingSpinner from "../../../LoadingSpinner";
import HelpModeIconTooltip from "../../../HelpModeIconTooltip";

class QuotaForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loadingQuestions: true,
      quota: {
        name: props.quota ? props.quota.name : '',
        supplementCurrent: props.quota ? props.quota.supplementCurrent : 0,
        supplementMax: props.quota ? props.quota.supplementMax : 0,
        disabled: props.quota ? props.quota.disabled : false,
        conds: props.quota ? props.quota.conds : [],
      },
      questions: [],
    };


    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const {surveyId} = this.props;
    let questions = [];

    let positionInSurvey = 1;
    api.survey.get.survey({id: surveyId})
      .then(survey => {
        _.each(survey.setBlocks, set => {
          _.each(set.blockPages, block => {
            _.each(block.pages, page => {
              _.each(page.questions, question => {
                question.positionInSurvey = positionInSurvey;
                questions.push(question);

                positionInSurvey++;
              })
            });
          });
        });

        this.setState({
          questions,
          loadingQuestions: false
        });
      })
      .catch(res => {
        this.setState({
          loadingQuestions: false
        });
      })
  }

  onSubmit(){
    const {quota} = this.state;

    this.props.onSubmit(quota);
  }

  render() {
    const {quota, questions, loadingQuestions} = this.state;
    const {errors} = this.props;

    if(loadingQuestions){
      return <LoadingSpinner/>
    }

    return (
      <Form>
        <FormGroup row>
          <Label xs={12} lg={3} className="text-lg-right">Nazwa <HelpModeIconTooltip className="ml-2">Nazwa kwoty</HelpModeIconTooltip></Label>
          <Col xs={12} lg={9}>
            <Input name="name" onChange={e => this.setState({
              quota: {
                ...quota,
                name: e.target.value,
              }
            })} value={quota.name} invalid={!!errors.name}/>
            {errors.name && <div className="invalid-feedback">{errors.name.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={12} lg={3} className="text-lg-right">Aktualna liczba wypełnień <HelpModeIconTooltip className="ml-2">Aktualna liczba wypełnień ankiety spełniająca warunki tej kwoty. Jeśli kwota jest nowo ustawiona - zostaw wartość 0</HelpModeIconTooltip></Label>
          <Col xs={12} lg={9}>
            <InputNumber value={quota.supplementCurrent} onChange={(supplementCurrent) => this.setState({quota: {...quota, supplementCurrent}})}/>
            {errors.supplementCurrent && <div className="invalid-feedback">{errors.supplementCurrent.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={12} lg={3} className="text-lg-right">Maksimum wypełnień <HelpModeIconTooltip className="ml-2">Ile maksymalnie razy mogą zostać spełnione warunki kwoty</HelpModeIconTooltip></Label>
          <Col xs={12} lg={9}>
            <InputNumber value={quota.supplementMax} onChange={(supplementMax) => this.setState({quota: {...quota, supplementMax}})}/>
            {errors.supplementMax && <div className="invalid-feedback">{errors.supplementMax.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={12} lg={3} className="text-lg-right">Wyłącz <HelpModeIconTooltip className="ml-2">Wyłącza działanie kwoty</HelpModeIconTooltip></Label>
          <Col xs={12} lg={9} className="pt-2">
            <Switch checked={quota.disabled} onChange={(disabled) => this.setState({quota: {...quota, disabled}})}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={12} lg={3} className="text-lg-right">Warunki <HelpModeIconTooltip className="ml-2">Warunki, które muszą zostać spełnione. Jeśli więcej niż 1 warunek: operator logiczny między warunkami: wszystkie warunki muszą zostać spełnione [AND]</HelpModeIconTooltip></Label>
          <Col xs={12} lg={9} className="pt-2">
            <Conds
              quota={quota}
              questions={questions}
              onCondsChanged={conds => this.setState({quota: {...quota, conds}})}
            />
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

QuotaForm.propTypes = {
  surveyId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  quota: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    supplementCurrent: PropTypes.number.isRequired,
    supplementMax: PropTypes.number.isRequired,
    conds: PropTypes.array,
  }),
};

export default connect(null, null, null, {forwardRef: true})(QuotaForm);