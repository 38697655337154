import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import classnames from "classnames";
import ColorPickerModal from "../../../../../components/ColorPickerModal";


class BgColor extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.ownStyles[this.props.keyName] ? props.ownStyles[this.props.keyName] : false,
      modalOpen: false,
    };

    this.getDefaultValue = this.getDefaultValue.bind(this);
  }

  getDefaultValue(){
    return this.props.ownStylesDefault[this.props.keyName];
  }

  updateValue(value){
    this.setState({
      value
    });

    this.props.handlerUpdate({[this.props.keyName]: value});
  }

  render() {
    const {value, modalOpen} = this.state;

    const inactive = [false, ''].indexOf(value) >= 0;

    return (
      <Row className={classnames({
        'py-1 px-3 align-items-center': true,
        'inactive': inactive,
      })}>
        <label className="col col-4 my-0">{this.props.label} {!inactive && <i className="icon-clear-value fas fa-trash float-right" onClick={() => this.updateValue('')} />}</label>
        <Col className="col-8">
          <div className="color-picker-preview" onClick={() => this.setState({modalOpen: true})} style={{
            backgroundColor: value ? value : this.getDefaultValue(),
          }} />
          {modalOpen && <ColorPickerModal
            color={value ? value : this.getDefaultValue()}
            onClose={() => this.setState({modalOpen: false})}
            onSelect={value => {
              this.setState({modalOpen: false});
              this.updateValue(value);
            }}
          />}
        </Col>
      </Row>
    );
  }
}

BgColor.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  keyName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default BgColor;