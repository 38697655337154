import React, {Component} from 'react';
import PropTypes from "prop-types";

class MatrixMulti extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {details, question} = this.props;

    return (
      <ul>
        {details.map((detail, i) => {
          return <li key={i}>
            {question.rowsImage ? <img src={detail.row} className="img-100" /> : <span dangerouslySetInnerHTML={{__html: detail.row}} className="d-inline-block img-30"/>}
            {" - "}
            {question.columnsImage ? <img src={detail.column} className="img-100" /> : <span dangerouslySetInnerHTML={{__html: detail.column}} className="d-inline-block img-30"/>}
            </li>
        })}
      </ul>
    )
  }
}

MatrixMulti.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    row: PropTypes.string.isRequired,
    column: PropTypes.string.isRequired
  }),).isRequired,
  question: PropTypes.shape({
    rowsImage: PropTypes.bool.isRequired,
    columnsImage: PropTypes.bool.isRequired
  }).isRequired
};

export default MatrixMulti;