import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from "../components/Tooltip";
import uuid from "react-uuid";

const Icon = props => {
  const [id, setId] = React.useState('t' + uuid());

  return <React.Fragment>
    <i
      id={id}
      {...props}
    />
    {props.tooltip && <Tooltip msg={props.tooltip} id={id} />}
  </React.Fragment>;
};

Icon.propTypes = {
  className: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default Icon;
