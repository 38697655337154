import React, {Component} from 'react';
import {Alert, Col, Row} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";
import striptags from "striptags";

class QuestionSummaryNumericMulti extends Component {

  render() {
    const {questionSummary} = this.props;
    const dataExists = questionSummary.data.n > 0;

    if(!dataExists){
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <Chart id={questionSummary.question.id} type={questionSummary.chartType} n={questionSummary.data.n} data={questionSummary.data.data.map(data => {
        return {
          x: striptags(data.content),
          value: parseFloat(data.value).toFixed(2),
        }
      })}/>
    )
  }
}

export default QuestionSummaryNumericMulti;