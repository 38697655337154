import api from "api";
import * as questionsActions from "../questions/actions";

export const SET = 'Survey/data/columns/SET';
export const ADDED = 'Survey/data/columns/ADDED';
export const DELETED = 'Survey/data/columns/DELETED';
export const UPDATED = 'Survey/data/columns/UPDATED';

export function setColumns(columns) {
  return {
    type: SET,
    columns
  }
}
export function columnAdded(column) {
  return {
    type: ADDED,
    column
  }
}
export function columnDeleted(column) {
  return {
    type: DELETED,
    column
  }
}
export function columnUpdated(column) {
  return {
    type: UPDATED,
    column
  }
}





export function addColumn(data) {
  return dispatch => {
    return api.question.post.column(data).then(column => {
      dispatch(columnAdded(column));
      dispatch(questionsActions.columnAdded(column));

      return column;
    });
  }
}

export function deleteColumn(data) {
  return dispatch => {
    return api.column.delete.column(data).then(res => {
      dispatch(columnDeleted({
        id: data.id,
        question: {
          id: res.column.question.id
        }
      }))
    })
  }
}

export function deleteColumns(questionId, data) {
  return dispatch => {
    return api.column.delete.list(data).then(() => {
      _.map(data.ids, id => {
        dispatch(columnDeleted({
          id,
          question: {
            id: questionId
          }
        }))
      })
    })
  }
}

export function updateColumn(data) {
  return dispatch => {
    return api.column.patch(data).then(column => {
      dispatch(columnUpdated(column))
    })
  }
}