import React, {Component} from 'react';
import PropTypes from "prop-types";
import Select from "react-select";
import api from 'api';
import {FormattedMessage} from "react-intl";

class SelectPointsType extends Component {

  constructor(props) {
    super(props);

    this.state = {
      types: []
    }
  }

  componentDidMount() {
    api.points.getTypes().then(types => {
      this.setState({types});
    })
  }

  render() {

    const typesOptions = _.map(this.state.types, type => {
      return {
        label: <FormattedMessage id={'point.type-' + type} />,
        value: type
      }
    });

    return (
      <Select
        isClearable
        placeholder="Wybierz typ"
        options={typesOptions}
        onChange={e => this.props.onChange(e ? e.value : null)}
      />
    );
  }
}

SelectPointsType.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SelectPointsType;