import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Alert, Col, Nav, NavItem, NavLink, Row, TabContent} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {updateSurvey} from "scenes/Survey/Structure/data/survey/actions";
import _ from "lodash";
import Primary from "./Primary";
import Security from "./Security";
import Advanced from "./Advanced";
import Filters from "./Filters";
import QuotaNews from "./QuotaNews";
import Limit from "./Limit";
import {toast} from "react-toastify";
import IntegrationsAvailable from "../../../../components/SurveyIntegrations/components/IntegrationsAvailable";
import ButtonAddQuota from "../../../../components/Survey/Quotas/components/ButtonAddQuota";
import QuotasList from "../../../../components/Survey/Quotas/components/QuotasList";
import HelpModeIconModal from "../../../../components/HelpModeIconModal";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuotasAvailable from "components/PlanRestrictionsModals/modals/QuotasAvailable";
import FiltersAvailable from "components/PlanRestrictionsModals/modals/FiltersAvailable";
import LimitAvailable from "components/PlanRestrictionsModals/modals/LimitAvailable";
import HistoryChangeAvailable from "components/PlanRestrictionsModals/modals/HistoryChangeAvailable";
import SurveyIntegrationsAvailable from "components/PlanRestrictionsModals/modals/SurveyIntegrationsAvailable";
import axios from "axios";
import setTranslatableLangHeader from "utils/setTranslatableLangHeader";
import QuotasShare from "components/Survey/Quotas/components/QuotasShare/QuotasShare";
import SurveyChangeHistoryTable from "scenes/Survey/SurveyChangeHistoryTable";
import Webhook from "./Webhook";

class Settings extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      tabActive: 'primary',
      data: {},
      errors: {},
      saveButtonVisible: true,
    };

    this.updateSurvey = this.updateSurvey.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.handlePropertyChange = this.handlePropertyChange.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.tabs = this.tabs.bind(this);
  }

  tabs() {
    return [
      {
        type: 'primary',
        icon: 'fa-cog'
      },
      {
        type: 'security',
        icon: 'fa-shield-alt'
      },
      {
        type: 'advanced',
        icon: 'fa-cogs'
      },
      {
        type: 'integrations',
        icon: 'fa-puzzle-piece',
        disabled: !this.props.plan.survey.integrations,
        restrictionName: SurveyIntegrationsAvailable.getName()
      },
      {
        type: 'filters',
        icon: 'fa-filter',
        disabled: !this.props.plan.survey.filters,
        restrictionName: FiltersAvailable.getName()
      },
      {
        type: 'quotaNews',
        icon: 'fa-sort-amount-up',
        disabled: !this.props.plan.survey.quotas,
        restrictionName: QuotasAvailable.getName()
      },
      // {
      //   type: 'quotas',
      //   icon: 'fa-sort-amount-up',
      //   disabled: !this.props.plan.survey.quotas,
      //   restrictionName: QuotasAvailable.getName()
      // },
      {
        type: 'limit',
        icon: 'fa-lock',
        disabled: !this.props.plan.survey.limit,
        restrictionName: LimitAvailable.getName()
      },
      {
        type: 'historyChange',
        icon: 'fa-history',
        disabled: !this.props.plan.survey.historyChange,
        restrictionName: HistoryChangeAvailable.getName()
      },
      {
        type: 'webhook',
        icon: 'fa-webhook',
        hide: !this.props.plan.survey.webhook,
        disabled: !this.props.plan.survey.webhook,
        restrictionName: 'webhook'
      },
    ];
  }

  updateSurvey() {
    const {data} = this.state;
    const {survey} = this.props;

    this.setState({
      errors: {}
    }, () => {
      this.props.handleOnSavingUpdate(true);
    });

    this.props.updateSurvey({
      id: survey.id,
      survey: data
    }).then(survey => {
      if(!localStorage.getItem('translatableLang') || survey.languages.indexOf(axios.defaults.headers.common.translatableLang) < 0){
        localStorage.setItem('translatableLang', survey.defaultLang);
        setTranslatableLangHeader(survey.defaultLang);
      }
      if('defaultLang' in data || 'languages' in data){
        window.location.reload();
      }

      this.setState({
        data: {},
        errors: {},
      }, () => {
        this.props.handleOnSavingUpdate(false);
        this.props.handleButtonDisabledChanged(_.isEmpty(this.state.data));
      });

      // toast.success("Ustawienia ankiety zostały poprawnie zapisane");
    }).catch(res => {
      this.setState({
        errors: (res.response && res.response.data.form) ? res.response.data.form.errors : {},
      }, () => {
        this.props.handleOnSavingUpdate(false);
      });

      toast.error("Ustawienia ankiety nie zostały zapisane");
    });
  }

  changeTab(tab) {
    this.setState({
      tabActive: tab,
      saveButtonVisible: ['quotas', 'integrations', 'filters', 'quotaNews'].indexOf(tab) < 0
    }, () => {
      this.props.handleOnSaveButtonVisibleUpdate(this.state.saveButtonVisible);
    });
  }

  handlePropertyChange(data) {
    this.setState({
      data: _.assign({}, this.state.data, data)
    }, () => {
      this.props.handleButtonDisabledChanged(_.isEmpty(this.state.data))
    });
  }

  onTabChange(tab) {
    if (tab.disabled) {
      this.props.setPlanRestrictionsModals(tab.restrictionName);
    } else {
      this.changeTab(tab.type);
    }
  }

  render() {
    const {tabActive, data, errors} = this.state;
    const {survey} = this.props;

    return (
      <React.Fragment>
        <nav>
          <Nav tabs>
            {this.tabs().map(tab => <NavItem key={"tab-" + tab.type} onClick={() => this.onTabChange(tab)}>
              <NavLink active={tabActive === tab.type} className={tab.hide === true ? "d-none" : ""}>
                <i className={"fas " + tab.icon + (tab.disabled ? " text-black-25" : "")}/>
                <FormattedMessage id={'survey.settings.tabs.' + tab.type}>{msg => <span className={"d-none d-lg-inline ml-3" + (tab.disabled ? " text-black-25" : "")}>{msg}</span>}</FormattedMessage>
              </NavLink>
            </NavItem>)}
          </Nav>
        </nav>

        <TabContent className="tab-body pb-2" style={{
          boxShadow: 'none'
        }}>
          {tabActive === 'primary' && <Primary survey={_.merge(survey, data)} onPropertyChange={this.handlePropertyChange} errors={errors}/>}
          {tabActive === 'security' && <Security survey={_.merge(survey, data)} onPropertyChange={this.handlePropertyChange} errors={errors}/>}
          {tabActive === 'advanced' && <Advanced survey={_.merge(survey, data)} onPropertyChange={this.handlePropertyChange} errors={errors}/>}
          {tabActive === 'integrations' && <IntegrationsAvailable surveyId={this.props.survey.id}/>}
          {tabActive === 'filters' && <Filters surveyId={this.props.survey.id}/>}
          {tabActive === 'quotaNews' && <QuotaNews surveyId={this.props.survey.id}/>}
          {tabActive === 'webhook' && <Webhook surveyId={this.props.survey.id}/>}
          {tabActive === 'limit' && <Limit limit={_.merge(survey, data).limit} onPropertyChange={data => {
            this.handlePropertyChange({
              limit: data ? _.assign({}, this.state.data.limit, data) : false
            })
          }} errors={errors.limit || {}}/>}
          {tabActive === 'historyChange' && <SurveyChangeHistoryTable survey={survey} />}
        </TabContent>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {updateSurvey, setPlanRestrictionsModals}, null, {forwardRef: true})(Settings);
