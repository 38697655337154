import React, {Component} from 'react';
import PropTypes from "prop-types";
import CollectorCreate from "./CollectorCreate";
import {connect} from "react-redux";

class CollectorsAvailable extends Component {

  render() {
    const {surveyId, collectors, isUserRespondent, collectorPanelAccess, collectorSmsAccess, collectorWidgetAccess} = this.props;


    const colors = ['orange', 'turquoise', 'violet', 'green', 'blue', 'red', 'yellow', 'violet'];
    let collectorsArray = ['WebLink','BaseSurveys','Email','Test', 'Tokens', 'TopSurveys', 'Sms', 'Widget', 'Panel'].map((type, key) => {
      return {
        type: type,
        title: '_.surveyCollector.' + type + '.name',
        desc: '_.surveyCollector.' + type + '.desc',
        color: colors[key]
      }
    });

    let collectorsList = [];
    collectorsArray.map(collector => {
      if(collector.type === 'TopSurveys' && !isUserRespondent){
        return false;
      }
      if(collector.type === 'Panel' && !collectorPanelAccess){
        return false;
      }
      if(collector.type === 'Sms' && !collectorSmsAccess){
        return false;
      }
      if(collector.type === 'Widget' && !collectorWidgetAccess){
        return false;
      }

      const collectorsTypeCount = collectors.filter(({type}) => type === collector.type).length;

      if (collector.type === 'Panel') {
        if(!this.props.plan.survey.collectorPanelVerifyRequired){
          collectorsList.push(<CollectorCreate
            key={collector.type}
            subType={`${collector.type}-own`}
            collector={{
              ...collector,
              title: '_.surveyCollector.Panel-own.name',
              desc: '_.surveyCollector.Panel-own.desc',
            }}
            surveyId={surveyId}
            handleCreate={this.props.handleCreate}
            collectorsTotalCount={collectors.length}
            collectorsTypeCount={collectorsTypeCount}
            hasSurveyBaseCollector={this.props.hasSurveyBaseCollector}
            hasTopSurveysCollector={this.props.hasTopSurveysCollector}
          />);
        }

        collectorsList.push(<CollectorCreate
          key={collector.type}
          subType={`${collector.type}-template`}
          collector={{
            ...collector,
            title: '_.surveyCollector.Panel-template.name',
            desc: '_.surveyCollector.Panel-template.desc',
          }}
          surveyId={surveyId}
          handleCreate={this.props.handleCreate}
          collectorsTotalCount={collectors.length}
          collectorsTypeCount={collectorsTypeCount}
          hasSurveyBaseCollector={this.props.hasSurveyBaseCollector}
          hasTopSurveysCollector={this.props.hasTopSurveysCollector}
        />);
      }else{
        collectorsList.push(<CollectorCreate
          key={collector.type}
          subType={`${collector.type}`}
          collector={collector}
          surveyId={surveyId}
          handleCreate={this.props.handleCreate}
          collectorsTotalCount={collectors.length}
          collectorsTypeCount={collectorsTypeCount}
          hasSurveyBaseCollector={this.props.hasSurveyBaseCollector}
          hasTopSurveysCollector={this.props.hasTopSurveysCollector}
        />);
      }
    });

    return (
      <div className="mb-4 d-inline-block">
        {collectorsList}
      </div>
    )
  }
}

CollectorsAvailable.propTypes = {
  surveyId: PropTypes.string.isRequired,
  collectors: PropTypes.array.isRequired,
  handleCreate: PropTypes.func.isRequired,
  hasSurveyBaseCollector: PropTypes.bool.isRequired,
  hasTopSurveysCollector: PropTypes.bool.isRequired,
  plan: PropTypes.shape({
    survey: PropTypes.shape({
      collectorPanelVerifyRequired: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
    isUserRespondent: state.user.type === 'Respondent',
    collectorPanelAccess: state.user.userPlan.plan.survey.collectorPanelAccess,
    collectorSmsAccess: state.user.userPlan.plan.survey.collectorSmsAccess,
    collectorWidgetAccess: state.user.userPlan.plan.survey.collectorWidgetAccess,
  }
}

export default connect(mapStateToProps)(CollectorsAvailable);
