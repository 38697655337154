import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Tooltip} from "reactstrap";

class ButtonOptions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  render() {
    const {optionsOpen} = this.props;
    const {tooltip} = this.state;

    return (
      <a id={'question-' + this.props.id + '-options'} onClick={() => this.props.handleToggleOptions()}>
        <span className={"fas fa-cog" + (optionsOpen ? " text-primary" : " hoverable")}/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={tooltip}
          target={'question-' + this.props.id + '-options'}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="question.headerActions.buttonOptions.button.tooltip"/>
        </Tooltip>
      </a>
    )
  }
}

ButtonOptions.propTypes = {
  optionsOpen: PropTypes.bool.isRequired,
  handleToggleOptions: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

export default ButtonOptions;