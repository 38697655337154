import * as React from 'react';
import {Card, CardBody, CardHeader, Row, Col} from "reactstrap";
import SitemapForm from "./components/SitemapForm";

function SitemapsFiles(props) {
  return (
    <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader><h2 className="float-left mr-4">Sitemapy</h2></CardHeader>
          <hr className="my-0" />
          <CardBody>
            <Row>
              {['ankieteo', 'swpanel', 'swresearch'].map(name => <Col xs={12} md={4}>
                <SitemapForm name={name} />
              </Col>)}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

SitemapsFiles.propTypes = {

};

export default SitemapsFiles;