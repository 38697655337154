import React, {Component} from 'react';
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Button, Tooltip} from "reactstrap";
import {normalize} from "normalizr";
import schemaQuestions from "entities/question/schema";
import {answerUpdated} from "scenes/Survey/Structure/data/answers/actions";
import classnames from "classnames";


class AnswersImage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: {
        answersImage: props.question.answersImage,
      },
      tooltip: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleAnswersImage = this.toggleAnswersImage.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleAnswersImage() {
    this.setState({
      question: {
        answersImage: !this.state.question.answersImage
      }
    }, () => {
      this.props.handleUpdate(this.state.question).then(question => {
        const normalized = normalize(question, schemaQuestions);

        Object.values(normalized.entities.answers).map(answer => {
          this.props.dispatch(answerUpdated(answer));
        })
      })
    });
  }

  render() {
    let { question, tooltip } = this.state;
    return (
      <Button
        className={classnames({
          "float-left mr-1 btn-icon mb-1": true,
          "active": question.answersImage,
        })}
        color={'secondary'}
        id={"answersImage-" + this.props.question.id}
        onClick={this.toggleAnswersImage}
      >
        <i className="fa-solid fa-message-image" />

        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={"answersImage-" + this.props.question.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="question.settings.answersImage.button.tooltip"/>
        </Tooltip>
      </Button>
    )
  }
}

AnswersImage.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answersImage: PropTypes.bool.isRequired
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default connect()(AnswersImage);
