import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import InputNumber from "components/InputNumber";

class BasketAnswersMax extends Component{
  constructor(props) {
    super(props);
    this.state = {
      answersMax: props.basket.answersMax,
      tooltipIsOpen: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  onChange(value) {
    this.setState({...this.state, answersMax: value});
    this.props.changeOption('answersMax', value);
  }

  render() {
    const {answersMax, tooltipIsOpen} = this.state;

    return (
      <React.Fragment>
        <InputNumber value={answersMax} onChange={this.onChange} id={'basketAnswersMax-' + this.props.basket.id}/>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltipIsOpen}
          target={'basketAnswersMax-' + this.props.basket.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="basket.basketAnswersMax.button.tooltip"/>
        </Tooltip>
      </React.Fragment>
    )
  }
}

BasketAnswersMax.propTypes = {
  basket: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answersMax: PropTypes.number.isRequired,
  }).isRequired,
  changeOption: PropTypes.func.isRequired
};

export default BasketAnswersMax;
