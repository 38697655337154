import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown} from "reactstrap";
import DropdownToggle from "reactstrap/es/DropdownToggle";
import DropdownMenu from "reactstrap/es/DropdownMenu";
import DropdownItem from "reactstrap/es/DropdownItem";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import ActionTypeVisibility from "./ActionTypeVisibility";
import ActionTypeJump from "./ActionTypeJump";
import {injectIntl} from "react-intl";

class Action extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: {
        actionType: false,
      }
    };
  }

  componentWillMount() {
    const {action} = this.props;

    if(_.isUndefined(action)){
      this.props.handleActionChanged({
        actionType: 'actionVisibility',
        visibility: true,
        type: 'questions',
        questions: [],
      });
    }
  }

  toggleIsOpen(name){
    this.setState({
      isOpen: {
        ...this.state.isOpen,
        [name]: !this.state.isOpen[name]
      }
    })
  }

  render() {
    const {action, intl, changeable, grayedQuestionsToPositionInSurvey} = this.props;
    const {isOpen} = this.state;


    if(_.isUndefined(action)){
      return  null;
    }

    let actionTypeTrans = 'filter.action.actionType-' + action.actionType;
    if(action.actionType === 'actionVisibility'){
      actionTypeTrans += '.visibility-' + action.visibility;
    }

    if (!changeable) {
      return <React.Fragment>
        {intl.formatMessage({id: actionTypeTrans})} { }

        {action.actionType === 'actionVisibility' && <ActionTypeVisibility action={action} changeable={false} />}
        {action.actionType === 'actionJump' && <ActionTypeJump action={action} changeable={false} />}
      </React.Fragment>
    }

    return (
      <ListGroupItem className="action">

        <Dropdown className="d-inline mx-1 pointer" isOpen={isOpen.actionType} toggle={() => this.toggleIsOpen('actionType')}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: actionTypeTrans})}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              actionType: 'actionVisibility',
              type: 'questions',
              visibility: true,
            })}><h5>Pokaż</h5></DropdownItem>
            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              actionType: 'actionVisibility',
              type: 'questions',
              visibility: false,
            })}><h5>Ukryj</h5></DropdownItem>
            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              actionType: 'actionJump',
              type: 'question',
              visibility: null,
            })}><h5>Przeskocz do</h5></DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {action.actionType === 'actionVisibility' && <ActionTypeVisibility action={action} handleActionChanged={action => this.props.handleActionChanged(action)} grayedQuestionsToPositionInSurvey={grayedQuestionsToPositionInSurvey} />}
        {action.actionType === 'actionJump' && <ActionTypeJump action={action} handleActionChanged={action => this.props.handleActionChanged(action)} />}

        <Button
          className="float-right mb-0 mt-1 mr-1"
          size="xs"
          onClick={() => this.props.handleActionDelete()}
        ><i className="fas fa-trash" /></Button>
      </ListGroupItem>
    );
  }
}

Action.defaultProps = {
  changeable: true,
  grayedQuestionsToPositionInSurvey: null,
};

Action.propTypes = {
  action: PropTypes.shape({
    actionType: PropTypes.oneOf(['actionVisibility', 'actionJump']).isRequired,
  }),
  handleActionChanged: PropTypes.func,
  handleActionDelete: PropTypes.func,
  changeable: PropTypes.bool.isRequired,
  grayedQuestionsToPositionInSurvey: PropTypes.number,
};

export default injectIntl(Action);