import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import AdminForm from "./AdminForm";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import api from "../../../../api";

class AdminCreateButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      loading: false,
      errors: {},
    };

    this.toggle = this.toggle.bind(this);
    this.createUser = this.createUser.bind(this);
    this.userForm = React.createRef();
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  createUser(e, data) {
    e.preventDefault();
    this.setState({loading: true, errors: {}});

    api.user.post.admin(data)
      .then(() => {
        this.props.handleChange();
        this.setState({loading: false, modal: false});
      })
      .catch(e => {
        console.log('e', e);
        this.setState({
          loading: false,
          errors: _.has(e, 'response.data.form.errors') ? e.response.data.form.errors : {}
        })
      });
  }

  render() {
    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')} size="lg">
      <ModalHeader toggle={() => this.toggle('modal')} tag="h2">Utwórz nowego admina</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <AdminForm errors={this.state.errors} onSubmit={this.createUser} ref={this.userForm}/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggle('modal')}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={this.state.loading}
            data-style={ZOOM_OUT}
            onClick={e => this.createUser(e, this.userForm.current.state)}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>f
    </Modal>;

    return (
      <React.Fragment>
        <Button color="primary" disabled={this.props.loading} onClick={() => this.toggle('modal')} className="mb-2">Utwórz nowego admina</Button>
        {modal}
      </React.Fragment>
    )
  }
}

AdminCreateButton.defaultProps = {
  loading: false
};

AdminCreateButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default connect(null)(AdminCreateButton);