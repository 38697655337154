import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import CheckedDelete from "./components/CheckedDelete";
import ActionAdd from "./components/Add";
import ActionReplace from "./components/Replace";
import ActionCopy from "./components/Copy";
import ActionSwap from "./components/Swap";
import ActionReverse from "./components/Reverse";
import {Button, Col, Row} from "reactstrap";

class ColumnsActions extends Component {

  render() {
    const {question, checkedColumns, columns} = this.props;

    let columnsChecked =  _.map(checkedColumns, id => columns.find(column => column.id === id) || false);
    columnsChecked = columnsChecked.filter(column => column !== false);

    return (
      <Row className="mt-2">
        <Col xs={12}>
          {columnsChecked.length > 0 &&
          <div className="direction-group w-100 label-filtr-wrap mb-0">
            <ul className="direction-left mb-0 p-0">
              <li className="float-left">
                <CheckedDelete question={question} columnsChecked={columnsChecked}/>
              </li>
            </ul>
          </div>}

          <div className="direction-group w-100 label-filtr-wrap d-none d-lg-block">
            <ul className="direction-left mb-0 p-0">
              <li className="float-left"><Button color="secondary" onClick={this.props.onCheckAll}>{columnsChecked.length >= columns.length ? <i className="fas fa-check-square" /> : <i className="far fa-check-square" />}</Button></li>
              <li className="float-left"><ActionReplace question={question}/></li>
              <li className="float-left"><ActionAdd question={question}/></li>
              <li className="float-left"><ActionCopy question={question}/></li>
              <li className="float-left"><ActionSwap question={question}/></li>
              <li className="float-left"><ActionReverse question={question}/></li>
            </ul>
          </div>
        </Col>
      </Row>
    )
  }
}

ColumnsActions.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  checkedColumns: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onCheckAll: PropTypes.func.isRequired,
};

export default ColumnsActions;