import React, {Component, useState} from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from "../LoadingSpinner";
import api from "../../api";
import {Alert, Badge, Card, CardBody, CardFooter, CardHeader, Col, Input, Row, Tooltip} from "reactstrap";
import QuestionInfo from "../QuestionInfo";
import UsersGroupSelect from "../../UsersGroupSelect";
import Select from "react-select";
import DeleteQuestionTemplate from "./components/DeleteQuestionTemplate";
import {connect} from "react-redux";
import Button from "../../../../survey/src/components/Button";
import EditQuestionTemplate from "./components/EditQuestionTemplate";
import QuestionTagSelect from "../QuestionTagSelect";
import UserAvatar from "../UserAvatar";

const TooltipUsedCount = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return <React.Fragment>
    <Tooltip placement="bottom" isOpen={tooltipOpen} target={props.id} toggle={toggle}>Liczba wykorzystań szablonu</Tooltip>
  </React.Fragment>;
};

const InputSearch = (props) => {
  const [value, setValue] = useState('');

  return <Input
    placeholder="Szukaj pytania"
    className="mb-0"
    style={{maxWidth: '350px'}}
    value={value}
    onChange={e => setValue(e.target.value)}
    onBlur={e => props.onChange(value)}
    onKeyUp={e => {
      switch (e.keyCode) {
        case 13: {
          props.onChange(value);
        }
      }
    }}
  />
};

class QuestionTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templates: [],
      search: '',
      filters: {},
      total: 0,
      loading: false,
      limit: 1000,
      page: 1,
      selected: [],
      sortField: 'q.id',
      sortOrder: 'DESC',
    };

    this.getSelected = this.getSelected.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.getPosition = this.getPosition.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
  }

  componentDidMount() {
    this.fetchTemplates();
  }

  getSelected(){
    return this.state.selected;
  }

  isSelected(id){
    return this.getSelected().includes(id);
  }

  toggleSelected(id){
    const {selected} = this.state;
    if(this.isSelected(id)){
      this.setState({
        selected: selected.filter(selId => selId !== id)
      }, () => this.props.onChange(this.state.selected))
    }else{
      selected.push(id);
      this.setState({
        selected,
      }, () => this.props.onChange(this.state.selected))
    }
  }

  getPosition(id){
    return this.getSelected().indexOf(id);
  }

  changeFilter(key, value){
    const {filters} = this.state;

    this.setState({
      filters: {
        ...filters,
        [key]: value,
      }
    }, () => this.fetchTemplates())
  }

  fetchTemplates(){
    const {limit, page, search, filters, sortField, sortOrder} = this.state;

    this.setState({
      loading: true,
      selected: [],
    }, () => this.props.onChange([]));

    api.user.questionTemplates.search({
      limit,
      page,
      search,
      filters,
      sortField,
      sortOrder,
    })
    .then(({data, total}) => {
      this.setState({
        loading: false,
        templates: data,
        total,
      })
    })
    .catch(res => {

    })
  }


  render() {
    const {loading, templates, sortField} = this.state;
    const {user} = this.props;

    const sortOptions = [
      {
        label: 'Sortuj po najpopularniejszych',
        value: 'q.templateUsedCount'
      },
      {
        label: 'Sortuj po najnowszych',
        value: 'q.id'
      },
    ]

    return <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <InputSearch onChange={search => this.setState({search}, () => this.fetchTemplates())} />

        <div className="d-flex w-100 justify-content-end align-items-center">
          <div className="w-100" style={{maxWidth: '400px'}}>
            <QuestionTagSelect
              isCreateable={false}
              onChange={tags => this.changeFilter('tags', tags.map(tag => tag.id))}
            />
          </div>
          <div className="w-100 ml-2" style={{maxWidth: '400px'}}>
            <UsersGroupSelect
              onChange={users => this.changeFilter('users', users)}
            />
          </div>
          <div className="w-100 ml-2" style={{maxWidth: '400px'}}>
            <Select
              value={sortOptions.find(o => o.value === sortField)}
              options={sortOptions}
              onChange={option => this.setState({
                sortField: option.value
              }, () => this.fetchTemplates())}
            />
          </div>
        </div>
      </div>

      <div>
        {_.chunk(templates, 4).map((templatesList, key) => {
          return <Row className="mb-3" key={key.toString()}>{templatesList.map(template => {
            return <Col xs={12} sm={6} md={4} xl={3} key={'qt-' + template.id}>
              <Card className="mb-0 h-100">
                <CardHeader className="d-flex justify-content-between p-2">
                  <div className="d-flex flex-grow-1 overflow-hidden">
                    <QuestionInfo question={template} />
                    <div className="ml-2 text-ellipsis mr-4" dangerouslySetInnerHTML={{__html: template.stripedContent}} />
                  </div>

                  <div className="d-flex justify-content-end">
                    {template.ownerId === user.id && <EditQuestionTemplate questionTemplate={template} onSaved={templateUpdated => {
                      this.setState({
                        templates: templates.map(template => {
                          if(template.id === templateUpdated.id){
                            template.tags = templateUpdated.tags;
                            template.description = templateUpdated.description;
                          }

                          return template;
                        })
                      })
                    }} />}
                    {template.ownerId === user.id && <DeleteQuestionTemplate onDeleted={() => this.fetchTemplates()} questionTemplateId={template.id} />}
                  </div>
                </CardHeader>
                <hr className="my-0" />
                <CardBody className="d-flex justify-content-between">
                  <div className="d-flex flex-column">
                    <small>
                      {template.tags.map((tag, tagKey) => <Badge color="secondary"  className="mr-1 h2 text-white" key={tagKey.toString()}>{tag.name}</Badge>)}
                    </small>
                    <p>{template.description || <i>Brak opisu</i>}</p>
                  </div>
                  <div className="d-flex align-items-start">
                    <Badge className="h2 text-white" id={'tuc-' + template.id} color="success">{template.templateUsedCount}</Badge>
                    <TooltipUsedCount id={'tuc-' + template.id} />
                  </div>
                </CardBody>
                <CardFooter className="d-flex justify-content-between align-items-center">
                  <UserAvatar user={template.ownerInfo} />

                  <Button size="sm" color={this.isSelected(template.id) ? 'secondary' : 'primary'} onClick={() => this.toggleSelected(template.id)}>
                    {this.isSelected(template.id) ? 'Pozycja ' + (this.getPosition(template.id) + 1) : 'Wybierz'}
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          })}</Row>
        })}

      </div>

      {templates.length === 0 && !loading && <Alert color="info" className="text-center">Brak szablonów</Alert>}

      {loading && <LoadingSpinner />}
    </div>
  }
}

QuestionTemplates.propTypes = {
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(QuestionTemplates);