import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import api from "api";


class AddRespondents extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      respondents: 100,
      creating: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.generateRespondents = this.generateRespondents.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  generateRespondents(){
    const {respondents} = this.state;

    this.setState({creating: true});
    this.props.toggleLoading();

    api.surveyCollector.panel.post.sendEmail.post.respondents(this.props.collector.id, respondents).then(data => {
      this.props.handleAddRespondents(data.respondents);
      this.setState({
        creating: false,
        modalOpen: false,
      });
    }).catch(() => {
      this.setState({
        creating: false
      });
    });
  }

  render() {
    const {modalOpen, creating, respondents} = this.state;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="lg">
      <ModalHeader toggle={this.toggleModal}>Dodaj nowych respondentów</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label md={4}>Liczba respondentów:</Label>
            <Col md={8}>
              <Input type="number" value={respondents} onChange={e => this.setState({respondents: e.target.value})} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>Szacowany czas wysyłki:</Label>
            <Col md={8}>
              {Math.ceil(respondents / 50) * 5} min
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.add">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={creating}
            data-style={ZOOM_OUT}
            onClick={this.generateRespondents}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="primary" onClick={this.toggleModal}>
          Wygeneruj bazę dla tego targetu
        </Button>
        {modal}
      </React.Fragment>
    );
  }
}

AddRespondents.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  handleAddRespondents: PropTypes.func.isRequired,
  toggleLoading: PropTypes.func.isRequired,
};

export default AddRespondents;