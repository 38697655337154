import React, {Component} from 'react';
import PropTypes from 'prop-types';
import api from 'api';
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import Detail from "./components/Detail";

class CheckUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: false,
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  fetchData() {
    this.setState({loading: true});

    api.user.get.checkUser(this.props.id)
      .then((data) => {
        this.setState({
          loading: false,
          data
        })
      })
      .catch(() => this.setState({loading: false}));
  }

  render() {
    const {loading, data} = this.state;

    if(loading){
      return <LoadingSpinner />
    }

    return <Detail data={data.data} id={this.props.id} />
  }
}

CheckUser.propTypes = {
  id: PropTypes.number.isRequired
};

export default CheckUser;