import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import api from "api";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import Badge from "reactstrap/es/Badge";
import ButtonDeleteQuota from "./ButtonDeleteQuota";
import ButtonEditQuota from "./ButtonEditQuota";

class QuotasList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      quotas: [],
      loading: false,
    };

    this.fetchQuotas = this.fetchQuotas.bind(this);
    this.handlerOnQuotaDeleted = this.handlerOnQuotaDeleted.bind(this);
    this.handlerOnQuotaSaved = this.handlerOnQuotaSaved.bind(this);
  }

  componentDidMount() {
    this.fetchQuotas();
  }

  handlerOnQuotaDeleted(quota){
    this.fetchQuotas();
  }

  handlerOnQuotaSaved(quota){
    this.fetchQuotas();
  }

  fetchQuotas(){
    const id = this.props.surveyId;

    this.setState({
      loading: true,
    });

    api.survey.get.quotas({id})
      .then(quotas => {
        this.setState({
          quotas,
          loading: false,
        });
      })
      .catch(res => {
        this.setState({
          loading: false,
      });
      });
  }

  accessorActions(quota){
    const {surveyId} = this.props;

    return <React.Fragment>
      <ButtonEditQuota surveyId={surveyId} quota={quota} onSaved={this.handlerOnQuotaSaved} />
      <ButtonDeleteQuota quota={quota} onDeleted={this.handlerOnQuotaDeleted} />
    </React.Fragment>
  }

  render() {
    const {loading} = this.state;

    const columns = [
      { id: 'name', Header: 'Nazwa', accessor: quota => quota.name, }
    ];

    if(this.props.editable) {
      columns.push({id: 'disabled', Header: 'Status', accessor: quota => <Badge style={{fontSize: '1em'}} color={quota.disabled ? 'warning' : 'success'}>{quota.disabled ? 'Wyłączona' : 'Tak'}</Badge>,});
    }

    columns.push({ id: 'supplementInfo', Header: 'Liczba wypełnień', accessor: quota => [quota.supplementCurrent, quota.supplementMax].join(' / ') });

    if(this.props.editable){
      columns.push({ id: 'conds', Header: 'Liczba warunków', accessor: quota => quota.conds.length, });
      columns.push({ id: 'actions', Header: <FormattedMessage id="_.table.header.actions"/>, accessor: quota => this.accessorActions(quota) });
    }

    return (
      <ReactTable
        minRows = {0}
        className={'table-wrap pb-3 mb-0'}
        data={this.state.quotas}
        columns={columns}
        showPagination={!_.isEmpty(this.state.quotas)}
        PaginationComponent={Pagination}
        loading={loading}
        noDataText={<FormattedMessage id="quotas.listItems.table.noQuotas"/>}
      />
    );
  }
}

QuotasList.defaultProps = {
  editable: true,
};

QuotasList.propTypes = {
  surveyId: PropTypes.number.isRequired,
  editable: PropTypes.bool,
};

export default connect(null, null, null, {forwardRef: true})(QuotasList);