import * as React from 'react';
import PropTypes from "prop-types";
import Tooltip from "components/Tooltip";
import SelectedMaxModal from "./SelectedMaxModal";

function CafeterySelectedMaxButton(props) {
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <a
        id={'cafeterySelectedMax-' + props.answer.id}
        className={"fak fa-comments-check-max ml-2" + (props.answer.selectedMax > 0 ? " text-primary" : "")}
        onClick={() => setModalOpen(true)}
      >
        <Tooltip id={'cafeterySelectedMax-' + props.answer.id} msg="Maksymalna liczba wskazań tej odpowiedzi" />
      </a>
      {modalOpen && <SelectedMaxModal
        answer={props.answer}
        selectedMax={props.answer.selectedMax || 0}
        selectedCurrent={props.answer.selectedCurrent || 0}
        onModalClose={() => setModalOpen(false)}
      />}
    </React.Fragment>
  );
}

CafeterySelectedMaxButton.propTypes = {
  answer: PropTypes.shape({
    selectedMax: PropTypes.number,
  }).isRequired,
};

export default CafeterySelectedMaxButton;
