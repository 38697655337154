import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
	FormText,
	Row, InputGroup, InputGroupAddon, Input
} from "reactstrap";
import {addPiping, updatePiping} from "scenes/Survey/Structure/data/pipings/actions";
import SelectQuestions from "scenes/Survey/Structure/components/SelectQuestions";
import QuestionFrom from "./QuestionFrom";
import QuestionsTo from "./QuestionsTo";
import {TOKEN_FIELDS} from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/SelectTokenFields";
import Select from "react-select";

const PIPING_TYPES = [
	{label: "Pytanie", value: 'question'},
	{label: "Token", value: 'token'}
]

class PipingForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			piping: props.piping,
			errors: {},
			saving: false
		};

		this.onChange = this.onChange.bind(this);
		this.savePiping = this.savePiping.bind(this);
	}

	onChangeType(data) {
		this.onChange(data);
	}

	onChange(data) {
		this.setState({
			piping: {
				...this.state.piping,
				...data
			}
		})
	};

	onChangeQuestionFrom(data) {
		this.setState({
			piping: {
				...this.state.piping,
				questionFrom: {
					...this.state.piping.questionFrom,
					...data
				}
			}
		})
	};

	savePiping() {
		const {piping} = this.state;
		const {survey} = this.props;
		if (!piping.type) {
			this.setState({errors: {type: "Proszę wybrać typ"}});
			return;
		}

		this.setState({saving: true, errors: {}});

		const saveFunction = this.props.piping.hasOwnProperty('id') ? this.props.updatePiping : this.props.addPiping;

		saveFunction({piping, surveyId: survey.id})
			.then(() => {
				this.setState({...this.state, saving: false});
				this.props.toggleEdit(false);
			})
			.catch((e) => {
				this.setState({saving: false});

				if (e.response.data.form.errors) {
					this.setState({
						errors: e.response.data.form.errors,
					});
				}
			});
	}

	render() {
		const {config, questions} = this.props;
		const {piping, errors} = this.state;

		return (
			<div className="mb-3">
				<Form className="mb-3">
					<Row className="d-flex align-items-stretch">
						<Col xs={12}>
							<FormGroup row className="mb-1">
								<Label lg={3} className="text-right"><FormattedMessage
									id="pipingForm.label.type"
								/></Label>
								<Col lg={9}>
									{!piping.id && <Select
										placeholder="Wybierz typ"
										closeMenuOnSelect={false}
										isOpen={false}
										options={PIPING_TYPES}
										onChange={field => this.onChange({type: field.value})}
										value={PIPING_TYPES.find(field => field.value === piping.type)}
									/>}
									{piping.id && <div class="mt-2">{piping.type === 'question' ? 'Pytanie' : 'Token'}</div>}
									<FormText color="danger">{errors.type}</FormText>
								</Col>
							</FormGroup>

							{piping.type === 'question' && <FormGroup row className="mb-1">
								<Label lg={3} className="text-right"><FormattedMessage
									id="pipingForm.questionFrom.label.question"
								/></Label>
								<Col lg={9}>
									<SelectQuestions
										isMulti={false}
										enabledTypes={['single', 'multi', 'ranked', 'open', 'multiOpen']}
										value={piping.questionFrom.question || null}
										onChange={(question) => this.onChangeQuestionFrom({question, answers: []})}
									/>
									{_.has(errors, 'questionFrom.question') &&
									<FormText color="danger">{errors.questionFrom.question.join('; ')}</FormText>}
								</Col>
							</FormGroup>}

							{piping.type === 'question' && !!piping.questionFrom.question && <QuestionFrom
								errors={errors}
								question={questions[piping.questionFrom.question]}
								configQuestionFrom={config.questions[questions[piping.questionFrom.question].type].piping.questionFrom}
								questionFrom={piping.questionFrom}
								onChange={data => this.onChangeQuestionFrom(data)}
							/>}

							{piping.type === 'token' && <FormGroup row className="mb-1">
								<Label lg={3} className="text-right"><FormattedMessage
									id="pipingForm.label.token"
								/></Label>
								<Col lg={9}>
									<Select
										placeholder="Wybierz pole"
										closeMenuOnSelect={false}
										isOpen={false}
										options={TOKEN_FIELDS}
										onChange={field => this.onChange({tokenField: field.value})}
										value={TOKEN_FIELDS.find(field => field.value === piping.tokenField)}
									/>
								</Col>
							</FormGroup>}

							<FormGroup row>
								<Label lg={3} className="text-right">
									<FormattedMessage
										id="pipingForm.label.replacableText"
									>{msg => msg + '*'}</FormattedMessage>
									<p style={{lineHeight: '1.2em'}}>Wstaw [[twojanazwa]] w dowolnym miejscu w treści
										pytań lub odpowiedzi, do których ma zostać przepisana treść z wybranych
										odpowiedzi.</p>
								</Label>
								<Col lg={9}>
									<InputGroup className="mb-0">
										<InputGroupAddon addonType="prepend">[[</InputGroupAddon>
										<Input
											type="text" value={piping.replacableText} name="replacableText"
											onChange={(e) => this.onChange({replacableText: e.target.value})}
											invalid={errors && !!errors.replacableText}
										/>
										<InputGroupAddon addonType="append">]]</InputGroupAddon>
									</InputGroup>
									<FormText
										color="danger"
									>{_.has(errors, 'replacableText') ? errors.replacableText.join('; ') : false}</FormText>
								</Col>
							</FormGroup>

							<QuestionsTo piping={piping}/>
						</Col>
					</Row>
				</Form>

				<Row>
					<Col>
						<div className="direction-group">
							<div className="direction-right">
								<FormattedMessage id="_.button.save">{
									(message) => <LaddaButton
										loading={this.state.saving}
										className="btn btn-primary btn-ladda ml-2"
										data-style={ZOOM_OUT}
										onClick={this.savePiping}
									>
										<i className="fas fa-edit"/> {message}
									</LaddaButton>
								}</FormattedMessage>
								<Button color="secondary" className="ml-2" onClick={() => this.props.toggleEdit(false)}><i
									className="fas fa-times"
								/> <FormattedMessage id="_.button.cancel"/></Button>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

PipingForm.defaultProps = {
	piping: {
		questionFrom: {
			question: 0,
			fetchType: '',
		},
		replacableText: '',
	},
	questions: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		type: PropTypes.string.isRequired,
	}).isRequired,).isRequired,
};

PipingForm.propTypes = {
	piping: PropTypes.shape({
		id: PropTypes.number,
		questionFrom: PropTypes.shape({
			question: PropTypes.number,
			type: PropTypes.string,
			fetchType: PropTypes.string,
		}),
		replacableText: PropTypes.string,
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		config: state.config.data,
		survey: state.survey.structure.data.survey,
		questions: state.survey.structure.data.questions,
		answers: state.survey.structure.data.answers,
		rows: state.survey.structure.data.rows,
		columns: state.survey.structure.data.columns,
	}
}

export default connect(mapStateToProps, {addPiping, updatePiping})(PipingForm);