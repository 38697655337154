import {SET} from './actionTypes';

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.surveys;

    default:
      return state;
  }
};