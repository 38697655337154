import React from 'react';
import {Button, ButtonGroup, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import Tooltip from "../../../components/Tooltip";
import useAfterUpdateEffect from "../../../utils/useAfterUpdateEffect";

const translate = {
  AND: 'Oraz',
  OR: 'Lub',
}

const BlockCondsType = props => {
  const [type, setType] = React.useState(props.type);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  useAfterUpdateEffect(() => {
    setDropdownOpen(false)

    props.onChange(type)
  }, [type])

  return (
    <Dropdown className="mr-2 mb-1" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle className="btn-xs" color="secondary">
        <span id={'fg-ct-tooltip'}>{translate[type]}</span>

        <Tooltip id={'fg-ct-tooltip'} msg={translate[type]} />
      </DropdownToggle>
      <DropdownMenu className="p-3">
        <ButtonGroup className="w-100 mb-1">
          <Button color={type === 'AND' ? 'primary' : 'secondary'} onClick={() => setType('AND')}>Oraz</Button>
          <Button color={type === 'OR' ? 'primary' : 'secondary'} onClick={() => setType('OR')}>Lub</Button>
        </ButtonGroup>
      </DropdownMenu>
    </Dropdown>
  );
};


export default BlockCondsType;