import React, {Component} from 'react';
import PropTypes from "prop-types";

class Differential extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {details, question} = this.props;

    return <table className="table border">
      {details.map(detail => {

        return <tr>
          <td className="text-left">{detail.contentLeft}</td>
          <td className="text-center">{detail.value}</td>
          <td className="text-right">{detail.contentRight}</td>
        </tr>;
      })}
    </table>
  }
}

Differential.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    contentLeft: PropTypes.string.isRequired,
    contentRight: PropTypes.string.isRequired,
    value: PropTypes.number,
  }),).isRequired,
  question: PropTypes.shape({
    rowsImage: PropTypes.bool.isRequired,
    columnsImage: PropTypes.bool.isRequired
  }).isRequired
};

export default Differential;