import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Select from "react-select";

class SelectExcludings extends Component {
  constructor(props){
    super(props);

    this.select = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  onChange(e){
    const {isMulti} = this.props;

    if(isMulti){
      this.select.current.setState({isOpen: true, menuIsOpen: true});
    }

    if(isMulti){
      this.props.onChange(e.map(obj => obj.value));
    }else{
      this.props.onChange(e.value);
    }
  }

  render() {
    const {excludings, value, isMulti, className, placeholder} = this.props;

    return (
      <Select
        className={className}
        placeholder={placeholder ? placeholder : (isMulti ? "Wybierz odpowiedzi wykluczające" : "Wybierz odpowiedź wykluczającą")}
        ref={this.select}
        value={value && (!isMulti ?
            {
              label: <span className="img-20" dangerouslySetInnerHTML={{__html: excludings.find(a => a.id === value).stripedContent}}/>,
              value
            }
          :
          value.map(aId => {
            return {
              label: <span className="img-20" dangerouslySetInnerHTML={{__html: excludings.find(a => a.id === aId).stripedContent}}/>,
              value: aId,
            }
          })
        )}
        isOpen={false}
        isMulti={isMulti}
        onChange={this.onChange}
        options={excludings.map(excluding => {
          return {
            label: <span className="img-20" dangerouslySetInnerHTML={{__html: excluding.stripedContent}}/>,
            value: excluding.id,
          }
        })}
      />
    );
  }
}

SelectExcludings.defaultProps = {
  isMulti: true,
  placeholder: '',
  className: '',
};

SelectExcludings.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  questionId: PropTypes.number.isRequired,
  excludings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    stripedContent: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number,]),
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    excludings :Object.values(state.survey.structure.data.excludings).filter(a => state.survey.structure.data.questions[props.questionId].excludings.indexOf(a.id) > -1),
  }
}

export default connect(mapStateToProps)(SelectExcludings);