import React, {Component} from 'react';
import api from 'api';
import {Card, CardBody, Row, Col, Button, Modal, ModalBody, ModalHeader, Input} from "reactstrap";
import LoadingSpinner from "components/LoadingSpinner";
import {FormattedMessage} from "react-intl";
import qs from "query-string";
import {isBrowser} from 'react-device-detect';
import { connect } from 'react-redux';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import appRoutes from "appRoutes";

class PlanDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      discount: '',
      discountMsg: '',
      prize: null,
      userCashPoints: null,
      paySwpanel: false,
      period: props.isCompany ? (qs.parse(props.history.location.search).period || 'annual') : 'month',
      loading: true,
      loadingPayU: false,
      loadingPayPal: false,
      loadingBlueMedia: false,
      planInfo: null,
      error: null,
      successModal: false,
      errorModal: false
    };

    this.paySwPanel = this.paySwPanel.bind(this);
    this.onSubmitPayU = this.onSubmitPayU.bind(this);
    this.onSubmitPayPal = this.onSubmitPayPal.bind(this);
    // this.onSubmitBlueMedia = this.onSubmitBlueMedia.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.getDiscount = this.getDiscount.bind(this);
    this.getSum = this.getSum.bind(this);
    this.toggle = this.toggle.bind(this);
    this.fetchPlanInfo = this.fetchPlanInfo.bind(this);
  }

  componentDidMount() {
    this.fetchPlanInfo();
  }

  fetchPlanInfo(){
    api.plan.post.dataWithDiscount(this.props.match.params.plan, this.state.period, this.state.discount).then(data => {
      let planInfo = data.plan;

      this.setState({
        ...this.state,
        discountMsg: data.discountMsg,
        loading: false,
        planInfo,
        prize: data.prize,
        userCashPoints: data.cashPoints,
      });

      let period = qs.parse(this.props.history.location.search).period || 'annual';
      const funName = 'payUc' + this.props.match.params.plan + period;
      const script = document.createElement("script");
      script.setAttribute('pay-button', "#pay-button");
      script.setAttribute('success-callback', funName);

      for (let parameter in planInfo.payUWidgetData) {
        script.setAttribute(parameter, planInfo.payUWidgetData[parameter]);
      }

      const callback = document.createElement("script");
      callback.text =
        `function ` + funName + `(data) {
          let params = JSON.stringify({period: '` + period + `', token: data.value});
          let xhttp = new XMLHttpRequest();
          xhttp.open("POST", '/api/subscription/plan/payU/' + ` + this.props.match.params.plan + `, true);
          xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
          xhttp.setRequestHeader("authorization", "Bearer " + "` + localStorage.jwt + `");

          xhttp.onreadystatechange = () => {
            if (xhttp.readyState == XMLHttpRequest.DONE) {
              const res = JSON.parse(xhttp.response);
              
              if (xhttp.status === 200) {
                if (res.hasOwnProperty("redirectUrl") && !!res.redirectUrl) {
                  window.open(res.redirectUrl,"_self");
                } else {
                  document.getElementById('successModalButton').click();
                }
              } else {
                document.getElementById('errorModalButton').click();
              }
            }
          }

          xhttp.send(params);
        }
      `;

      document.getElementById('pay-button').appendChild(script);
      document.getElementById('pay-button').appendChild(callback);
    });
  }

  paySwPanel() {
    this.setState({...this.state, paySwpanel: true, error: null});
    api.user.post.buyPrizeAccount(this.state.prize.id)
      .then(data => {
        window.location = appRoutes.surveys.list
      })
      .catch(error => {
        this.setState({
          ...this.state,
          paySwpanel: false,
          error: error.response.data.message
        });
      });
  }

  onSubmitPayU() {
    this.setState({...this.state, loadingPayU: true, error: null});
    api.transaction.payU.post.order.plan(this.props.match.params.plan, {period: this.state.period, discount: this.state.discount})
      .then(data => window.open(data.redirectUrl,"_self"))
      .catch(error => {
        this.setState({
          ...this.state,
          loadingPayU: false,
          error: error.response.data.message
        });
      });
  }

  onSubmitPayPal() {
    this.setState({...this.state, loadingPayPal: true, error: null});
    api.transaction.payPal.post.order.plan(this.props.match.params.plan, {period: this.state.period})
      .then(data => window.open(data.redirectUrl,"_self"))
      .catch(error => {
        this.setState({
          ...this.state,
          loadingPayPal: false,
          error: error.response.data.message
        });
      });
  }

  // onSubmitBlueMedia() {
  //   this.setState({...this.state, loadingBlueMedia: true, error: null});
  //   api.transaction.blueMedia.post.order.plan(this.props.match.params.plan, {period: this.state.period})
  //     .then(data => window.open(data.redirectUrl,"_self"))
  //     .catch(error => {
  //       this.setState({
  //         ...this.state,
  //         loadingBlueMedia: false,
  //         error: error.response.data.message
  //       });
  //     });
  // }

  getPrice() {
    switch (this.state.period) {
      case 'month':
        return this.state.planInfo.price;
      case 'quarterly':
        return this.state.planInfo.quarterlyPrice;
      case 'annual':
        return this.state.planInfo.annualPrice;
    }
  }

  getDiscount() {
    switch (this.state.period) {
      case 'month':
        return this.state.planInfo.discount;
      case 'quarterly':
        return this.state.planInfo.quarterlyDiscount;
      case 'annual':
        return this.state.planInfo.annualDiscount;
    }
  }

  getSum() {
    switch (this.state.period) {
      case 'month':
        return this.state.planInfo.sum;
      case 'quarterly':
        return this.state.planInfo.quarterlySum;
      case 'annual':
        return this.state.planInfo.annualSum;
    }
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  render() {
    const {planInfo} = this.state;
    const isIosWebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
    const normalizedUserAgent = navigator.userAgent.toLowerCase();
    const isAndroid = /android/.test(normalizedUserAgent);
    const isAndroidWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent))

    const successModal = <React.Fragment>
      <Modal id="successModal" toggle={() => this.toggle('successModal')} isOpen={this.state.successModal}>
        <ModalHeader toggle={() => this.toggle('successModal')}>Status zamówienia</ModalHeader>
        <ModalBody>
          Zamówienie zostało przekazane do realizacji
        </ModalBody>
      </Modal>
      <Button id="successModalButton" className="d-none" onClick={() => this.toggle('successModal')} />
    </React.Fragment>;

    const errorModal = <React.Fragment>
      <Modal id="errorModal" toggle={() => this.toggle('errorModal')} isOpen={this.state.errorModal}>
        <ModalHeader toggle={() => this.toggle('errorModal')}>Status zamówienia</ModalHeader>
        <ModalBody>
          Wystąpił błąd, proszę spróbować później lub skontaktować się z administratorem.
        </ModalBody>
      </Modal>
      <Button id="errorModalButton" className="d-none" onClick={() => this.toggle('errorModal')} />
    </React.Fragment>;

    return this.state.loading ? <LoadingSpinner/> :
      <React.Fragment>
        <h1><FormattedMessage id="planDetails.info.summary"/></h1>
        <Row className="justify-content-md-center">
          {this.state.prize && <Col lg={3} md={6} xs={12} className="d-flex align-items-stretch">
            <Card className="w-100">
              <CardBody>
                <img src={'/dashboard/img/swpanel-logo.png'} className="d-block mx-auto py-3 mt-2" />
                  <div className="d-flex justify-content-between">
                    <div><strong><FormattedMessage id="planDetails.info.plan.name"/>:</strong></div>
                    <div className="color-blue" style={{fontSize: '2em'}}>{this.state.planInfo.name}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <dt><strong><FormattedMessage id="planDetails.info.period"/>:</strong></dt>
                    <dd className="color-blue" style={{fontSize: '2em'}}>
                      <FormattedMessage id={"planDetails.info." + this.state.period}/>
                    </dd>
                  </div>

                  <div className="d-flex justify-content-between mt-5 mb-3">
                    <dt><strong><FormattedMessage id="planDetails.info.plan.price"/>:</strong></dt>
                    <dd className="color-blue"><span className="mr-1" style={{fontSize: '1.6em'}}>{this.state.prize.cost}</span> <img src={'/dashboard/img/swpanel-cashPoints.png'} style={{width: '50px'}} /></dd>
                  </div>
                {/*<Row className="w-100 justify-content-end no-gutters">*/}
                {/*  <Col lg={4} md={5} xs={12}>*/}
                {/*    <Button disabled={this.state.loadingBlueMedia} onClick={this.onSubmitBlueMedia} className="btn btn-block btn-primary"><FormattedMessage id="planDetails.form.blueMedia.button"/> {this.state.loadingBlueMedia && <LoadingSpinner width={15} classNames="d-inline-block p-0 m-0"/>}</Button>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
                <Row className="w-100 justify-content-end no-gutters">
                  <Col xs={12}>
                    <p className="text-center" style={{color: '#32ace8', fontSize: '1em'}}>Na koncie SwPanel masz {this.state.userCashPoints} cashPoints</p>
                    <LaddaButton
                      className="btn btn-primary btn-ladda mx-auto d-flex align-items-center"
                      style={{borderRadius: '50px'}}
                      loading={this.state.paySwpanel}
                      disabled={this.state.userCashPoints < this.state.prize.cost}
                      data-style={ZOOM_OUT}
                      onClick={() => this.paySwPanel()}
                    >
                      <span className="mr-2" style={{fontSize: '1.5em'}}>Płacę punktami</span>
                      <img src={'/dashboard/img/swpanel-cashPoints.png'} style={{width: '60px'}} />
                    </LaddaButton>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>}
          {!isIosWebview && !isAndroidWebview && <Col lg={3} md={6} xs={12} className="d-flex align-items-stretch">
            <Card className="w-100">
              <CardBody>
                <img src={'/dashboard/img/payu-logo.png'} className="d-block mx-auto" style={{
                  height: '100px'
                }} />
                <dl>
                  <dt><strong><FormattedMessage id="planDetails.info.plan.name"/>:</strong></dt>
                  <dd className="color-blue" style={{fontSize: '2em'}}>{this.state.planInfo.name}</dd>
                  <dt><strong><FormattedMessage id="planDetails.info.period"/>:</strong></dt>
                  <dd className="color-blue" style={{fontSize: '2em'}}>
                    <FormattedMessage id={"planDetails.info." + this.state.period}/>
                  </dd>
                  <dt><strong><FormattedMessage id="planDetails.info.plan.price"/>:</strong></dt>
                  <dd className="color-blue">{this.getPrice()} <FormattedMessage id="planDetails.info.currency.pln"/> <FormattedMessage id="planDetails.info.net"/></dd>

                  {planInfo.discountValue ? <React.Fragment>
                    <dt><strong>Rabat</strong></dt>
                    <dd className="color-blue">-{this.getDiscount()} <FormattedMessage id="planDetails.info.currency.pln"/> <FormattedMessage id="planDetails.info.net"/></dd>
                  </React.Fragment> : null}

                  <dt />
                  <dd className="float-right">
                    <strong><FormattedMessage id="planDetails.info.toPay"/>:</strong> <br />
                    <span className="color-blue">{this.getSum()} <FormattedMessage id="planDetails.info.withTax"/></span>
                  </dd>
                </dl>
                <Row className="w-100 justify-content-end no-gutters mt-4">
                  <Col xs={12}>
                    <Button id={"payu-button"} onClick={this.onSubmitPayU} style={{
                      border: 0,
                      height: '50px',
                      width: '100%',
                      background: 'url("https://static.payu.com/pl/standard/partners/buttons/payu_account_button_long_03.png")',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: isBrowser ? 'contain' : '100%',
                      backgroundPosition: 'center',
                      cursor: 'pointer'
                    }}>{this.state.loadingPayU && <LoadingSpinner width={15} classNames="d-inline-block p-0 m-0"/>}</Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>}
        </Row>
        {successModal}
        {errorModal}
      </React.Fragment>
  }
}

function mapStateToProps(state) {
  return {
    isCompany: state.user.type === 'Company'
  }
}

export default connect(mapStateToProps)(PlanDetails);











