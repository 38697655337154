import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from "../../../../../survey/src/components/Tooltip";

const SurveySwitcherCheckWarning = props => {

  const getWarnings = () => {
    return props.surveySwitcherChecks.split(',').map(surveySwicherCheck => {
      surveySwicherCheck = surveySwicherCheck.split(';');

      return getStatus({
        httpStatus: parseInt(surveySwicherCheck[0]),
        matchTextResult: surveySwicherCheck[1] === '1' ? true : surveySwicherCheck[1] === '0' ? false : null,
        redirectUrl: surveySwicherCheck[2],
      });
    }).filter(status => !status.valid)
  }

  const getStatus = (surveySwitcherCheck) => {
    let ret = {
      valid: null,
      msg: 'Brak danych',
    }

    if(parseInt(surveySwitcherCheck.httpStatus) === 200){
      ret.valid = true;
      ret.msg = 'OK!';
    }else{
      ret.valid = false;

      if([301,302,307,308].indexOf(surveySwitcherCheck.httpStatus) >= 0){
        ret.msg = `Przekierowanie: ${surveySwitcherCheck.redirectUrl}`;
      }else{
        ret.msg = `Odpowiedź serwera: ${surveySwitcherCheck.httpStatus}`;
      }
    }

    return ret;
  }

  return (
    <div>
      {getWarnings().map((surveySwitcherCheckStatus, key) => {
        return <React.Fragment>
          <i id={`ssc-${props.surveyId}-${key}`} className="fas fa-exclamation-triangle text-danger ml-2" />
          <Tooltip id={`ssc-${props.surveyId}-${key}`} msg={surveySwitcherCheckStatus.msg} />
        </React.Fragment>;
      })}
    </div>
  );
};

SurveySwitcherCheckWarning.propTypes = {
  surveySwitcherChecks: PropTypes.string.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default SurveySwitcherCheckWarning;