import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Form,FormGroup,Label,Col,Input,FormFeedback} from "reactstrap";
import api from "../../../api";

class DictionaryWordTextareaForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      errors: {},
      data: {
        text: '',
        dictionary: props.dictionaryId,
      }
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  submit(e){
    e.preventDefault();
  }

  save(){
    const {data} = this.state;

    this.props.onSavingStart();

    this.setState({
      errors: {},
    })

    api.dictionaryWords.createFromText(data)
    .then(data => {
      this.props.onSavingStop();

      this.props.onSaved();
    })
    .catch(res => {
      this.props.onSavingStop();

      this.setState({
        errors: res.response.data
      })
    })
  }

  render() {
    const {data} = this.state;

    return <Form>
      <FormGroup row>
        <Label md={4}>Podaj listę słów i słów podobnych</Label>
        <Col md={8}>
          <Input
            type="textarea"
            rows={12}
            placeholder={"mbank\nembank\nbmank\nmbaank\n\nIdea Bank\nItea Bank\niidea Bankk"}
            onChange={e => this.setState({data: {...data, text: e.target.value}})}
            value={data.text}
          />
        </Col>
      </FormGroup>
    </Form>;
  }
}

DictionaryWordTextareaForm.propTypes = {
  dictionaryId: PropTypes.string.isRequired,
  onSaved: PropTypes.func,
  onSavingStart: PropTypes.func,
  onSavingStop: PropTypes.func,
};

export default DictionaryWordTextareaForm;
