import React, {Component} from 'react';
import LoadingSpinner from "../components/LoadingSpinner";
import api from "../api";
import {connect} from "react-redux";
import {profileUpdated} from "reducers/user/actions";
import setAuthorizationHeader from "utils/setAuthorizationHeader";
import appRoutes from "appRoutes";
import {toast} from "react-toastify";
class ChangeEmailCancel extends Component {
  componentDidMount() {
    const activationCode = this.props.match.params.activationCode;

    api.user.get.changeEmailCancelLimited(activationCode)
    .then(data => {
      toast.success('Proces zmiany adresu e-mail został poprawnie anulowany')

      setTimeout(() => {
        window.location = appRoutes.surveys.list
      }, 2000)
    })
  }

  render() {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
}

export default connect()(ChangeEmailCancel);
