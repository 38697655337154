import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Tooltip} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import PropTypes from 'prop-types';
import {omit} from "lodash";
import BlockTemplateForm from "components/BlockTemplates/components/BlockTemplateForm";

class ModalSaveTemplate extends Component {

  constructor(props) {
    super(props);

    this.blockTemplateFormRef = React.createRef();

    this.state = {
      saving: false,
    };

    this.onSaveStart = this.onSaveStart.bind(this);
    this.onSaved = this.onSaved.bind(this);
    this.save = this.save.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]});
  }

  onSaveStart(){
    this.setState({
      saving: true
    });
  }

  onError(){
    this.setState({
      saving: false,
    });
  }

  onSaved(){
    this.setState({
      saving: false,
    });

    this.props.toggleModal();
  }

  save(){
    this.setState({
      saving: true
    });

    this.blockTemplateFormRef.current.save();
  }

  render() {
    const {saving} = this.state;

    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
      <ModalHeader toggle={this.props.toggleModal} tag="h2">Zapisz jako szablon</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <BlockTemplateForm
          ref={this.blockTemplateFormRef}
          onSaveStart={this.onSaveStart}
          onSaved={this.onSaved}
          onError={this.onError}
          blockId={this.props.block.id}
          template={this.props.block}
        />
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <LaddaButton
              className="btn btn-primary btn-ladda mb-0 ml-3"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={this.save}
            >Zapisz jako szablon</LaddaButton>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggle}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  }
}

ModalSaveTemplate.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default ModalSaveTemplate;