import {SET, DELETED, UPDATED, ADDED} from './actionTypes';
import update from 'immutability-helper';

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.prizeCategories;

    case DELETED:
      if (state[action.prizeCategory.id].parent)
        state[state[action.prizeCategory.id].parent.id].children = _.filter(state[state[action.prizeCategory.id].parent.id].children, child => child.id !== action.prizeCategory.id);

      return _.filter(state, item => item.id !== action.prizeCategory.id);

    case UPDATED:
      return update(state, {
        [action.prizeCategory.id]: {
          $set: action.prizeCategory
        }
      });

    case ADDED:
      return {
        ...state,
        [action.prizeCategory.id]: action.prizeCategory
      };

    default:
      return state;
  }
};