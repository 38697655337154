import React from "react";
import {connect} from "react-redux";
import Tooltip from "components/Tooltip";
import PropTypes from 'prop-types';
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Button from "components/Button";
import {deleteAttributeLevel} from "../../../../../../../../../../../../../../../../../../../../data/attributes/levels/actions";

function AttributeLevelDelete(props){
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [deleting, setDeleting] = React.useState(false);

  const onDelete = () => {
    setDeleting(true);

    props.deleteAttributeLevel(props.level, props.attribute)
    .then(() => {
      setDeleting(false);
      toggleModalOpen();
    })
    .catch(() => {
      setDeleting(false);
      toggleModalOpen();
    })
  }

  return <React.Fragment>
    <i id={'delete-attribute-leevl-' + props.level.id} onClick={toggleModalOpen} className="fas fa-trash pointer p-2" />
    <Tooltip id={'delete-attribute-leevl-' + props.level.id} msg={'Usuń atrybut'} />
    {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="lg">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Potwierdź usunięcie wartości atrybutu</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        Czy na pewno usunąć wartość atrybutu?
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className={['mb-0']} onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.delete">{
          (message) => <Button
            color="danger"
            loading={deleting}
            onClick={onDelete}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
}

AttributeLevelDelete.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  attribute: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect(null, {deleteAttributeLevel})(AttributeLevelDelete);