import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "components/Button";
import SummaryForm from "./SummaryForm";

class SummaryAddModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      creating: false,
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  submit(){
    this.formRef.current.save()
  }

  render() {
    const {creating, modalOpen} = this.state;

    return <React.Fragment>
      <Button onClick={this.toggleModal}><i className="fas fa-plus" /> Dodaj</Button>

      <Modal isOpen={modalOpen} toggle={this.toggleModal} size="lg">
        <ModalHeader toggle={this.toggleModal}>Utwórz udostępnienie</ModalHeader>
        <ModalBody>
          <SummaryForm
            surveyId={this.props.surveyId}
            onSaved={dictionary => {
              this.props.onAdded(dictionary);
              this.toggleModal();
            }}
            ref={this.formRef}
            onSavingStart={() => this.setState({ creating: true })}
            onSavingStop={() => this.setState({ creating: false })}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={this.toggleModal}>Anuluj</Button>
          <Button
            loading={creating}
            onClick={this.submit}
          >Zapisz</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>;
  }
}

SummaryAddModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onAdded: PropTypes.func.isRequired,
};

export default SummaryAddModal;
