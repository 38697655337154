import React, {Component} from 'react';
import PropTypes from "prop-types";
import Select from "react-select";
import api from 'api';
import {FormattedMessage} from "react-intl";

class SelectPointsWhat extends Component {

  constructor(props) {
    super(props);

    this.state = {
      whats: []
    }
  }

  componentDidMount() {
    api.points.getWhats().then(whats => {
      this.setState({whats});
    })
  }

  render() {

    const whatsOptions = _.map(this.state.whats, what => {
      return {
        label: <FormattedMessage id={'point.what-' + what} />,
        value: what
      }
    });

    return (
      <Select
        isClearable
        placeholder="Wybierz sposób zdobycia"
        options={whatsOptions}
        onChange={e => this.props.onChange(e ? e.value : null)}
      />
    );
  }
}

SelectPointsWhat.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SelectPointsWhat;