import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import InputNumber from "components/InputNumber";

class AnswerStartValue extends Component{
  constructor(props) {
    super(props);
    this.state = {
      startValue: props.answer.startValue,
      tooltipIsOpen: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  onChange(value) {
    this.setState({...this.state, startValue: value});
    this.props.changeOption('startValue', value);
  }

  render() {
    const {startValue, tooltipIsOpen} = this.state;

    return (
      <React.Fragment>
        <InputNumber float value={startValue} onChange={this.onChange} id={'answerStartValue-' + this.props.answer.id}/>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltipIsOpen}
          target={'answerStartValue-' + this.props.answer.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="answer.answerStartValue.button.tooltip"/>
        </Tooltip>
      </React.Fragment>
    )
  }
}

AnswerStartValue.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    startValue: PropTypes.number.isRequired,
  }).isRequired,
  changeOption: PropTypes.func.isRequired
};

export default AnswerStartValue;
