import React, {Component} from 'react';
import {Form, Row, Label, Input, Col} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from 'react-redux';

class ClientsForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        name: props.client ? props.client.name : '',
        trade: props.client ? props.client.trade : Object.values(props.trades)[0].id
      }
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });
  }

  render() {
    const {errors, trades} = this.props;

    const options = _.map(trades, trade => {
      return {label: trade.name, value: trade.id}
    });

    let trade = null;
    if(this.state.data.trade){
      trade = _.find(trades, trade => trade.id === this.state.data.trade)
    }

    return (
      <Form onSubmit={e => this.props.onSubmit(e, this.state.data)}>
        <Row>
          <Col lg={3}>
            <Label for="name"><FormattedMessage id="clientsForm.label.name" /></Label>
          </Col>
          <Col lg={9}>
            <Input type="text" name="name" id="name" invalid={!!errors.name} value={this.state.data.name} onChange={this.onChange}/>
            {errors.name && <div className="invalid-feedback">{errors.name.join('; ')}</div>}
          </Col>
          <Col lg={3}>
            <Label for="name"><FormattedMessage id="clientsForm.label.trade" /></Label>
          </Col>
          <Col lg={9}>
            <Select
              value={trade ? {label: trade.name, value: this.state.data.trade} : null}
              onChange={e => this.onChange({target: {name: 'trade', value: e.value}})}
              options={options}
            />
            {errors.trade && <div className="small text-danger">{errors.trade.join('; ')}</div>}
          </Col>
        </Row>
      </Form>
    )
  }
}

ClientsForm.propTypes = {
  client: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    trades: state.config.data.trades
  }
}

export default connect(mapStateToProps, {}, null, {forwardRef: true})(ClientsForm);