import * as React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody, ModalFooter, TabPane, Form, FormGroup, Label, Col, Input} from "reactstrap";
import Select from "react-select";
import {map} from "lodash";
import Button from "../../../../../../components/Button";
import api from "../../../../../../api";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";

function ResponsesOperations(props) {
  const [statusesCount, setStatusesCount] = React.useState(false);
  const [statusesCounting, setStatusesCounting] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [option, setOption] = React.useState(null);
  const [saving, setSaving] = React.useState(false);
  const [text, setText] = React.useState('');

  const options = [
    {
      label: 'Oznacz jako wykluczone w tej analizie danych',
      value: 'exclude',
    },
    {
      label: 'Zmień status wywiadów na "Ekran zaproszenia"',
      value: 'changeStatusInvitation',
    },
    {
      label: 'Zmień status wywiadów na "Przerwany"',
      value: 'changeStatusBreak',
    },
    {
      label: 'Usuń bezpowrotnie wywiady z ankiety',
      value: 'remove',
    },
  ];

  const countStatuses = () => {
    setStatusesCounting(true);
    setStatusesCount(false);

    api.surveyResponse.get.countStatusesByIds(props.analysis.survey, text.split("\n"))
    .then(data => {
      setStatusesCount(data.data);
      setStatusesCounting(false);
      setModalOpen(true);

    }).catch(res => {
      setStatusesCounting(false);
    })
  }

  const save = () => {
    switch (option) {
      case 'exclude': {
        let excludedResponses = text.split("\n").concat(props.analysis.excludedResponses);

        setSaving(true);

        api.analysis.patch.analysis({
          id: props.analysis.id,
          analysis: {
            excludedResponses
          },
        }).then(() => {
          toast.success("Wywiady zostały poprawnie wykluczone");
          setSaving(false);
          setText('');
        }).catch(() => {
          setSaving(false);
          toast.warn("Coś poszło nie tak");
        })

        break;
      }
      case 'remove': {
        let ids = text.split("\n");

        setSaving(true);

        api.surveyResponse.delete.surveyResponsesBulk(props.analysis.survey, ids).then(() => {
          toast.success("Wywiady zostały usunięte");
          setSaving(false);
          setText('');
        }).catch(() => {
          setSaving(false);
        })

        break;
      }
      case 'changeStatusInvitation': {
        let ids = text.split("\n");

        setSaving(true);

        api.surveyResponse.post.changeStatusBulk(ids, 1).then(() => {
          toast.success("Status wywiadów został zmieniony");
          setSaving(false);
          setText('');
        }).catch(() => {
          setSaving(false);
          toast.warn("Coś poszło nie tak");
        })

        break;
      }
      case 'changeStatusBreak': {
        let ids = text.split("\n");

        setSaving(true);

        api.surveyResponse.post.changeStatusBulk(ids, 2).then(() => {
          toast.success("Status wywiadów został zmieniony");
          setSaving(false);
          setText('');
        }).catch(() => {
          setSaving(false);
          toast.warn("Coś poszło nie tak");
        })

        break;
      }
    }
  }

  return (
    <TabPane
      id="responses-operations"
      className="fade show p-0 p-lg-5 d-flex flex-column"
      style={{
        height: '100%',
        overflow: 'auto',
      }}
    >
      <Form onSubmit={e => e.preventDefault()}>
        <FormGroup row>
          <Label xs={12} sm={5} md={4} lg={3} xl={2}>Wybierz operacje:</Label>
          <Col xs={12} sm={7} md={8} lg={9} xl={10}>
            <Select
              placeholder="Wybierz..."
              options={options}
              onChange={option => setOption(option.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup className="mt-2" row>
          <Label xs={12} sm={5} md={4} lg={3} xl={2}>Podaj listę id wywiadów:</Label>
          <Col xs={12} sm={7} md={8} lg={9} xl={10}>
            <Input type="textarea" rows={10} placeholder="Podaj listę id" onChange={e => setText(e.target.value
                  .replace(',', "\n")
                  .replace(' ', "\n")
                  .replace(/[^0-9\,\n]/gi, '')
                  .replace(',,', ',')
                  .replace(',', "\n")
                  .replace("\n\n", "\n")
            )} value={text} />
          </Col>
        </FormGroup>
        <FormGroup>
          <Button
            onClick={countStatuses}
            loading={statusesCounting}
            disabled={!option}
            color={option === 'remove' ? 'danger' : 'warning'}
          >{(option) ? options.find(o => o.value === option).label : 'Wybierz opcję'}</Button>
        </FormGroup>
      </Form>

      {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="md">
        <ModalHeader tag="h2" toggle={toggleModalOpen}>Potwierdzenie operacji</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <span>Podana lista id wywiadów zawiera ponższe statusy wywiadów:</span>
          <ul className="pl-0 mt-3">
            {map(statusesCount, (status) => {
              return <li><FormattedMessage id={"_.surveyResponse.status." + status.status + ".label"} />: {status.count}</li>
            })}
          </ul>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
          }</FormattedMessage>
          <Button
            onClick={save}
            disabled={!option}
            color={option === 'remove' ? 'danger' : 'warning'}
            loading={saving}
          >{options.find(o => o.value === option).label}</Button>
        </ModalFooter>
      </Modal>}
    </TabPane>
  );
}

ResponsesOperations.propTypes = {

};

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
  }
}

export default connect(mapStateToProps)(ResponsesOperations);