import React, {Component} from 'react';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";
import {ButtonGroup} from "reactstrap";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import BaseSelect from "../../../../../components/RespondentSms/Base/BaseSelect";
import Button from "../../../../../components/Button";
import DeleteButton from "./TabRespondents/DeleteButton";
import {surveyLink} from "../../../../../services/surveyLink";

class TabRespondents extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: [],
      baseSelected: false,
      adding: false,
    };

    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    const {collector} = this.props;

    if(!state){
      state = this.state;
    }

    this.setState({
      searching: true,
      page: state.page,
      pageSize: state.pageSize
    });

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.surveyCollector.sms.respondents.search(collector.id, data)
    .then(res => {
      this.setState({
        searching: false,
        data: res.data,
        count: res.count,
        analysisId: res.analysisId,
      });
    })
    .catch(e => this.setState({
      searching: false,
    }));
  }

  actions(row){
    return <ButtonGroup>
      <DeleteButton
        handleDelete={() => this.fetchData()}
        respondent={row}
      />
    </ButtonGroup>
  }

  render() {
    const {surveyId, collector} = this.props;
    const {analysisId, baseSelected, adding} = this.state;
    const columns = [
      {
        Header: 'Id',
        accessor: 'id',
        width: 50,
      },
      {
        Header: 'Token',
        accessor: 'guid',
        width: 170,
      },
      {
        id: 'link',
        Header: 'Link',
        width: 170,
        accessor: row => surveyLink(`${collector.guid}/${row.guid}`)
      },
      {
        id: 'phone',
        Header: 'Telefon',
        accessor: row => row.respondentSms ? row.respondentSms.phone : <i>Brak danych</i>,
        width: 170,
      },
      {
        id: 'column_1',
        Header: 'Kolumna 1',
        accessor: row => (row.respondentSms && row.respondentSms.column_1) ? row.respondentSms.column_1 : <i>Brak danych</i>,
        width: 100,
      },
      {
        id: 'column_2',
        Header: 'Kolumna 2',
        accessor: row => (row.respondentSms && row.respondentSms.column_2) ? row.respondentSms.column_2 : <i>Brak danych</i>,
        width: 100,
      },
      {
        id: 'column_3',
        Header: 'Kolumna 3',
        accessor: row => (row.respondentSms && row.respondentSms.column_3) ? row.respondentSms.column_3 : <i>Brak danych</i>,
        width: 100,
      },
      {
        id: 'column_4',
        Header: 'Kolumna 4',
        accessor: row => (row.respondentSms && row.respondentSms.column_4) ? row.respondentSms.column_4 : <i>Brak danych</i>,
        width: 100,
      },
      {
        id: 'column_5',
        Header: 'Kolumna 5',
        accessor: row => (row.respondentSms && row.respondentSms.column_5) ? row.respondentSms.column_5 : <i>Brak danych</i>,
        width: 100,
      },
      {
        Header: 'Wysłanych wiadomości',
        accessor: 'sentsCount',
        width: 170,
      },
      {
        id: 'response',
        Header: 'Wywiad',
        width: 170,
        accessor: respondent => respondent.response ?
          <Link
            className={'btn btn-sm ' + (respondent.response.isEnded ? 'btn-success' : 'btn-warning')}
            to={appRoutes.survey.analysis.browseResponse(surveyId, analysisId, respondent.response.id)}
          >
            {respondent.response.id}
          </Link> : 'Brak odpowiedzi'
      },
      {
        id: 'actions',
        Header: 'Opcje',
        accessor: (row) => this.actions(row),
      },
    ];

    return <React.Fragment>
      <div className="d-flex align-items-center">
        <BaseSelect
          className="w-100"
          onChange={base => {
            this.setState({
              baseSelected: base.id
            })
          }}
        />
        <Button
          className={['ml-2']}
          loading={adding}
          onClick={() => {
            if(baseSelected){
              this.setState({
                adding: true
              });

              api.surveyCollector.sms.respondents.addFromBase(collector.id, baseSelected)
              .then(data => {
                this.fetchData();
                this.setState({
                  adding: false,
                })
              })
              .catch(res => {
                this.fetchData();
                this.setState({
                  adding: false,
                })
              })
              ;
            }
          }}
        >Dodaj respondentów</Button>
      </div>
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        style={{zIndex: 0}}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={parseInt(this.state.count)}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    </React.Fragment>  }
}

TabRespondents.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  surveyId: PropTypes.string.isRequired,
};

export default TabRespondents;
