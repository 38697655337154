import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import api from "api";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import PropTypes from 'prop-types';
import HelpModeIconTooltip from "components/HelpModeIconTooltip";

class TabSentHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      sentHistory: [],
    }
  }

  componentDidMount() {
    this.setState({
      loading: true
    });

    api.surveyCollector.sms.get.sent(this.props.collector).then(data => {
      this.setState({
        loading: false,
        sentHistory: data,
      })
    })
  }

  render() {
    const {loading, sentHistory} = this.state;

    const columns = [{
      id: 'respondent',
      Header: 'Respondent',
      accessor: row => row.respondent.respondentSms.phone
    }, {
      Header: 'Data wysyłki',
      accessor: 'createdAt'
    }];

    return (
      <React.Fragment>
        <HelpModeIconTooltip className="mb-2">Historia wysłanych zaproszeń do respondentów</HelpModeIconTooltip>
        <ReactTable
          minRows={0}
          className={'table-wrap'}
          data={sentHistory}
          columns={columns}
          showPagination={!_.isEmpty(sentHistory)}
          PaginationComponent={Pagination}
          loading={loading}
          defaultPageSize={10}
        />
      </React.Fragment>
    )
  }
}

TabSentHistory.propTypes = {
  collector: PropTypes.object.isRequired
};

export default TabSentHistory;