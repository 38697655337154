import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {InputGroup, InputGroupAddon, Tooltip, Button} from "reactstrap";
import Select from 'react-select';
import classnames from "classnames";

class PsmType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      question: {
        psmType: props.question.psmType
      }
    };
    this.onChange = this.onChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(e) {
    this.setState({
      ...this.state,
      question: {
        ...this.state.question,
        psmType: e.value
      }
    }, () => this.props.handleUpdate(this.state.question));
  };

  render() {
    const {tooltip, question} = this.state;

    const options = ['slider', 'input'].map(type => {
      return {
        label: <FormattedMessage id={"question.settings.psmType.type-" + type}/>,
        value: type,
      }
    });

    let value = null;
    if(question.psmType){
      value = options.find(option => option.value === question.psmType);
    }else{
      value = options[0];
    }

    return (
      <InputGroup className="mr-1 float-left w-100 w-lg-auto mb-1" id={"psmType-" + this.props.question.id}>
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon active": true,
            })}
            color="secondary"
          >
            <i className="fas fa-question"/>
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltip}
              target={"psmType-" + this.props.question.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="question.settings.psmType.title" />
            </Tooltip>
          </Button>
        </InputGroupAddon>
        <Select
          classNamePrefix="react-select"
          className="react-select float-left"
          isClearable={false}
          value={value}
          options={options}
          onChange={(e) => this.onChange(e)}
        />
      </InputGroup>
    );
  }
}

PsmType.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    psmType: PropTypes.string,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    questionSetting: state.user.userPlan.plan.survey.questionSettings[props.question.type]
  }
}

export default connect(mapStateToProps)(PsmType);
