import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import ShareCreateButton from "./ShareCreateButton";
import ShareList from "./ShareList";

class ShareModal extends Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="xl">
        <ModalHeader toggle={this.props.toggle} tag="h2"><FormattedMessage id="view.shareButton.modalHeader" /></ModalHeader>
        <hr className="m-0" />
        <ModalBody>
          <ShareCreateButton analysisId={this.props.analysisId}/>
          <ShareList />
        </ModalBody>
      </Modal>
    )
  }
}

ShareModal.propTypes = {
  analysisId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default ShareModal;
