import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {isMobile} from "react-device-detect";
import {get} from "lodash";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import appRoutes from "appRoutes";
import {userLogout} from "reducers/user/actions";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "../../../PlanRestrictionsModals/modals/MultimediaAvailable";
import classnames from "classnames";
import UserAvatar from "../../../UserAvatar";
import Notifications from "../Notifications/Notifications";
import isGroupManager from "../../../../utils/userGroupHelper";

class UserMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.logout = this.logout.bind(this);
  }

  logout(){
    this.props.userLogout();
  }

  render() {
    const {user} = this.props;
    const {isOpen} = this.state;
    const {userPlan} = user;
    const {plan} = userPlan;

    return <div className="float-right d-flex flex-row flex-nowrap align-items-center">
      {!isMobile && <Notifications />}
      <Dropdown className="float-right ml-3" isOpen={isOpen} toggle={() => this.setState({isOpen: !isOpen})}>
        <DropdownToggle tag="a" className="p-0">
          <UserAvatar user={user} size={45} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header><FormattedMessage id="header.myAcc"/></DropdownItem>
          <Link to={appRoutes.account.home} className="dropdown-item"><FormattedMessage tagName="h5" id="header.profile"/></Link>
          <Link
            to="#"
            className={classnames({
              "dropdown-item": true,
              // "not-active": !this.props.plan.multimedia.access
              "not-active": !get(this.props.plan, 'multimedia.access')
            })}
            onClick={(e) => {
              e.preventDefault();

              if (!this.props.plan.multimedia.access) {
                this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
                return;
              }

              CKFinder.popup({
                connectorInfo: 'auth=' + localStorage.jwt,
                width: 1014,
                height: 700,
              });
            }}
          ><h5>Multimedia</h5></Link>
          {/*<Link to={appRoutes.users.group} className="dropdown-item"><FormattedMessage tagName="h5" id="header.group"/></Link>*/}
          {/*<Link to={appRoutes.smtps.list} className="dropdown-item"><FormattedMessage tagName="h5" id="header.smtps"/></Link>*/}
          {plan && plan.isApiKeysAvailable && <Link to={appRoutes.users.apiKeys} className="dropdown-item"><h5>Klucze API</h5></Link>}
          <DropdownItem onClick={this.logout} tag="a"><FormattedMessage tagName="h5" id="header.logout"/></DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    avatar: state.user.avatar,
    plan: state.user.hasOwnProperty('id') && state.user.userPlan.plan ? state.user.userPlan.plan : {},
  }
}

export default connect(mapStateToProps, {userLogout, setPlanRestrictionsModals})(UserMenu);
