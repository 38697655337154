import {SET, DELETED, UPDATED, ADDED} from './actionTypes';
import update from 'immutability-helper';

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.prizes;

    case DELETED:
      return update(state, {
          $unset: [action.prize.id]
      });

    case UPDATED:
      return update(state, {
        [action.prize.id]: {
          $set: action.prize
        }
      });

    case ADDED:
      return {
        ...state,
        [action.prize.id]: action.prize
      };

    default:
      return state;
  }
};