import * as React from 'react';
import PropTypes from "prop-types";
import Button from "../../../../components/Button";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import BanForm from "../../Respondents/components/BanForm";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from "../../../../api";

function ChangeStatus(props) {
  const [changing, setChanging] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  const change = () => {
    setChanging(true);
    api.admin.userPrize.changeStatusFrom5To20ForValidatedUsers()
    .then(() => {
      setChanging(false);

      props.onChanged();
      setModalOpen(false);
    })
    .catch(() => {
      setChanging(false);
    })
  }

  return (
    <div>
      <Button onClick={toggleModalOpen}>Zmień statusy z "W realizacji" na "Przekazano do wypłaty"</Button>
      {modalOpen && <Modal isOpen toggle={toggleModalOpen} size="lg">
        <ModalHeader toggle={toggleModalOpen} tag="h2">Zmień status</ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <p>Zmień status z "W realizacji" na "Przekazano do wypłaty" dla zweryfikowanych użytkowników</p>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={toggleModalOpen}>{message}</Button>
          }</FormattedMessage>
          <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={changing}
            data-style={ZOOM_OUT}
            onClick={change}
          >Zmień</LaddaButton>
        </ModalFooter>
      </Modal>}
    </div>
  );
}

ChangeStatus.propTypes = {

};

export default ChangeStatus;