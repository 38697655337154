import React, {Component} from 'react';
import {Row, Col} from "reactstrap";
import WidgetContainer from '../../WidgetContainer';
import api from "api";
import QuestionSummaryCard from './components/QuestionSummaryCard';
import LoadingSpinner from "components/LoadingSpinner";

class QuestionSummariesFromAnalysis extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      questionSummaries: []
    };
    this.getQuestionSummaries = this.getQuestionSummaries.bind(this);
  }

  componentDidMount() {
    this.getQuestionSummaries();
  }

  getQuestionSummaries() {
    this.setState({questionSummaries: [], loaded: false});
    api.widget.get.widget(this.props.widget).then(questionSummaries => this.setState({questionSummaries: Object.values(questionSummaries), loaded: true}));
  }

  render() {
    return (
      <WidgetContainer widget={this.props.widget} handleDataChange={this.getQuestionSummaries}>
        <Row>
          <Col>
            {this.state.loaded ? _.map(this.state.questionSummaries, questionSummary => <QuestionSummaryCard key={questionSummary.id} questionSummary={questionSummary}/>) : <LoadingSpinner />}
          </Col>
        </Row>
      </WidgetContainer>
    )
  }
}

export default QuestionSummariesFromAnalysis;