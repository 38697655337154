import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PrizeCategoryForm from './PrizeCategoryForm';
import {fetchPrizeCategories, addPrizeCategory} from "../actions";
import {connect} from "react-redux";

class PrizeCategoryCreate extends Component {

  componentDidMount(){
    this.props.fetchPrizeCategories();
  }

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader><FormattedMessage id="admin.prizeCategoryCreate.cardHeader" /></CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Row>
                <Col>
                  <PrizeCategoryForm prizeCategories={this.props.prizeCategories} handleSave={this.props.addPrizeCategory}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    prizeCategories: state.admin.prizeCategories.data.prizeCategories
  }
}

export default connect(mapStateToProps, {fetchPrizeCategories, addPrizeCategory})(PrizeCategoryCreate);