import React from 'react';
import PropTypes from 'prop-types';
import {Alert, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import SurveyFilterTextarea from "./SurveyFilterTextarea";
import Block from "../../../Structure/components/Filter/Block";
import _ from "lodash";
import Action from "../../../Structure/components/Filter/Action";
import FilterSerialCond from "./components/FilterSerialCond";
import FilterSerialAction from "./components/FilterSerialAction";
import FilterIterationCond from "./components/FilterIterationCond";
import FilterIterationActions from "./components/FilterIterationActions";
import Button from "../../../../../components/Button";
import {connect} from "react-redux";
import {addFilter, updateFilter} from "../../../Structure/data/filters/actions";
import {addFilterSerial, updateFilterSerial} from "../../../Structure/data/filterSerial/actions";
import {addFilterIterative, updateFilterIterative} from "../../../Structure/data/filterIterative/actions";

FilterFromTextareaModal.propTypes = {
  filtersText: PropTypes.string,
  onClose: PropTypes.func,
};

FilterFromTextareaModal.defaultProps = {
  filtersText: '',
};

function InfoModal(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  return (
    <React.Fragment>
      <i className="fas fa-question-circle pointer" onClick={toggleModalOpen} />
      {modalOpen && <Modal isOpen size="lg" >
        <ModalHeader toggle={toggleModalOpen} tag="h2">Tworzenie filtrów z pola tekstowego</ModalHeader>
        <ModalBody>
          <a href="https://ankieteo.pl/files/Filtry-z-pliku-tekstowego.pdf" target="_blank" className="d-block mb-2">Instrukcja</a>
          <Table size="sm" bordered hover>
            <thead>
            <tr>
              <th colSpan={2} className="text-center bg-dark text-white">Warunki</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Q1</td>
              <td>Pytanie Q1 wyświetliło się</td>
            </tr>
            <tr>
              <td>Q1!</td>
              <td>Pytanie Q1 nie wyświetliło się</td>
            </tr>
            <tr>
              <td>Q1=1,2,3,4</td>
              <td>Wybierze odpowiedzi 1,2,3,4 (LUB)</td>
            </tr>
            <tr>
              <td>Q1!=1,2,3,4</td>
              <td>Nie wybierz odpowiedzi 1,2,3,4 (LUB)</td>
            </tr>
            <tr>
              <td>Q1&=1,2,3,4</td>
              <td>Wybierze odpowiedzi 1,2,3,4 (ORAZ)</td>
            </tr>
            <tr>
              <td>Q1&!=1,2,3,4</td>
              <td>Nie wybierze odpowiedzi 1,2,3,4 (ORAZ)</td>
            </tr>
            <tr>
              <td>Q1=1-1,1-2,1-3</td>
              <td>w wierszu 1 wybrał kolumny 1,2,3 (LUB)</td>
            </tr>
            <tr>
              <td>Q1E=1,2,3</td>
              <td>wybrał odp wykl 1,2,3</td>
            </tr>
            </tbody>

            <thead>
            <tr>
              <th colSpan={2} className="text-center bg-dark text-white">Kilka warunków w bloku</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Q1|Q2|Q3</td>
              <td>wyświetliło się Q1 oraz q2 oraz q3</td>
            </tr>
            <tr>
              <td>^Q1|Q2|Q3</td>
              <td>wyświetliło się Q1 lub q2 lub q3</td>
            </tr>
            <tr>
              <td>Q1=1,2|Q2!=2</td>
              <td>w q1 wybrał 1 lub 2 ORAZ w q2 nie wybrał 2</td>
            </tr>
            <tr>
              <td>^2-3 Q1|Q2|Q3|Q1|Q2</td>
              <td>min 2, max 3 warunki spełnione</td>
            </tr>
            </tbody>

            <thead>
            <tr>
              <th colSpan={2} className="text-center bg-dark text-white">Akcje</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Q=1,2,3</td>
              <td>pokaż pytania 1,2,3</td>
            </tr>
            <tr>
              <td>Q!=1,2,3</td>
              <td>ukryj pytania 1,2,3</td>
            </tr>
            <tr>
              <td>P=1,2,3</td>
              <td>pokaż strony 1,2,3</td>
            </tr>
            <tr>
              <td>P!=1,2,3</td>
              <td>pokaż strony 1,2,3</td>
            </tr>
            <tr>
              <td>B=1,2,3</td>
              <td>pokaż bloki 1,2,3</td>
            </tr>
            <tr>
              <td>B!=1,2,3</td>
              <td>pokaż bloki 1,2,3</td>
            </tr>
            <tr>
              <td>S=1,2,3</td>
              <td>pokaż zestawy 1,2,3</td>
            </tr>
            <tr>
              <td>S!=1,2,3</td>
              <td>pokaż zestawy 1,2,3</td>
            </tr>
            <tr>
              <td>Q1=1,2,3</td>
              <td>pokaż odpowiedzi 1,2,3 w Q1</td>
            </tr>
            <tr>
              <td>Q1!=1,2,3</td>
              <td>ukryj odpowiedzi 1,2,3 w Q1</td>
            </tr>
            <tr>
              <td>Q1R=1,2,3</td>
              <td>pokaż wiersze 1,2,3 w Q1</td>
            </tr>
            <tr>
              <td>Q1R!=1,2,3</td>
              <td>ukryj wiersze 1,2,3 w Q1</td>
            </tr>
            <tr>
              <td>Q1C=1,2,3</td>
              <td>pokaż kolumny 1,2,3 w Q1</td>
            </tr>
            <tr>
              <td>Q1C!=1,2,3</td>
              <td>ukryj kolumny 1,2,3 w Q1</td>
            </tr>
            <tr>
              <td>SO</td>
              <td>screenout</td>
            </tr>
            <tr>
              <td>END</td>
              <td>zakończenie ankiety</td>
            </tr>
            <tr>
              <td>JUMP=Q1</td>
              <td>Przeskocz do pytania 1</td>
            </tr>
            <tr>
              <td>JUMP=P1</td>
              <td>Przeskocz do strony 1</td>
            </tr>
            <tr>
              <td>JUMP=B1</td>
              <td>Przeskocz do blok 1</td>
            </tr>
            <tr>
              <td>JUMP=S1</td>
              <td>Przeskocz do zestaw 1</td>
            </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalOpen(false)}>Zamknij</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
}

function FilterFromTextareaModal(props) {
  const [saving, setSaving] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [filtersText, setFiltersText] = React.useState(props.filtersText);
  const [saved, setSaved] = React.useState(false);
  const [savedPositions, setSavedPositions] = React.useState([]);
  const [savedErrorsCount, setSavedErrorsCount] = React.useState(0);
  const [errors, setErrors] = React.useState([]);
  let errorsTmp = [];

  const saveFilters = async() => {
    errorsTmp = [];
    setErrors([]);
    setSaving(true);
    setSaved(false);
    let savedPositions = [];
    let savedErrorsCount = 0;

    const save = key => {
      if(!filters[key]){
        setTimeout(() => {
          setErrors(errorsTmp);
          setSaving(false);
          setSaved(true);
          setSavedPositions(savedPositions);
          setSavedErrorsCount(savedErrorsCount);

          let filtersTextNew = filtersText.split("\n");
          setFiltersText(filtersTextNew.filter((f, k) => savedPositions.indexOf(k) < 0 ).join("\n"));
          // props.onClose();
        }, 500)
        return;
      }

      const filter = filters[key];
      let apiFn;
      let data;
      if(filter.type === 'serial'){
        apiFn = filter.id ? props.updateFilterSerial : props.addFilterSerial;
        data = {
          surveyId: props.survey.id,
          filterSerial: filter,
        }
      }else if(filter.type === 'iterative'){
        apiFn = filter.id ? props.updateFilterIterative : props.addFilterIterative;
        data = {
          surveyId: props.survey.id,
          filterIterative: filter,
        }
      }else{
        apiFn = filter.id ? props.updateFilter : props.addFilter;
        data = {
          filter: {
            id: filter.id,
            name: filter.name,
            survey: props.survey.id,
            blocks: filter.blocks,
            actionsVisibility: filter.actions.filter(action => action.actionType === 'actionVisibility'),
            actionsJump: filter.actions.filter(action => action.actionType === 'actionJump')
          }
        };
      }

      apiFn(data)
        .then(() => {
          save(key+1);
          savedPositions.push(key);
        })
        .catch(e => {
          if(e.response.data.form){
            errorsTmp.push(e.response.data.form.errors);
          }else{
            errorsTmp.push([]);
          }
          save(key+1);
          savedErrorsCount++;
        });
    };

    save(0);
  }

  return (
    <Modal size="xxl" isOpen>
      <div className="modal-header">
        <h2 className="modal-title w-100 d-flex flex-row justify-content-between">
          <div className="d-flex flex-row align-center">
            <span className="mr-2">Utwórz filtry z pola tekstowego</span>
            <InfoModal />
          </div>

          {props.wizardChange && <Button color="secondary" onClick={() => {
            props.onClose(filters);
          }}><i className="fas fa-edit"/> Edytuj przez wizard</Button>}
        </h2>
        <button type="button" className="close" aria-label="Close" onClick={() => props.onClose()}><span aria-hidden="true">×</span></button>
      </div>

      <ModalBody>
        <div className="d-flex flex-column h-100">
          <div>
            <SurveyFilterTextarea
              blocked={saving}
              onChange={(filters, filtersText) => {
                setFilters(filters);
                setFiltersText(filtersText);
              }}
              filtersText={filtersText}
            />
          </div>
          {(saved && !saving) && <div className="mt-3">
            <Alert color="success">Liczba poprawnie zapisanych filtrów: {savedPositions.length}</Alert>
            <Alert color="danger">Liczba filtrów które nie zostały zapisane: {savedErrorsCount}</Alert>
          </div>}
          <div className="mt-3" style={{overflowY: 'auto'}}>
            {filters.map((filter, key) => {
              const e = errors[key] && _.map(errors[key], e => {
                if(_.isArray(e)){
                  return <React.Fragment>
                    {e.map(err => <span>{err}</span>)}
                  </React.Fragment>
                }
                return <span>{e}</span>;
              });

              switch (filter.type) {
                case 'conditional': {
                  return <div key={key} className="d-flex flex-column border shadow p-2 mb-3">
                    <h1 className="text-center mb-0">{filter.name ? filter.name : <i>Brak nazwy filtra</i>}</h1>
                    <hr />
                    {e && <Alert color="danger" className="d-flex flex-column">
                      {e}
                    </Alert>}
                    <div className="d-flex flex-row">
                      <div className="w-50">
                        <h2 className="text-center">Warunki</h2>

                        {filter.blocks.map((block, bKey) => <Block
                          changeable={false}
                          headerVisible={(block.children.length + block.condsQuestion.length) <= 1}
                          requiredVisible={false}
                          deleteable={false}
                          key={`b-${bKey}`}
                          block={!_.isEmpty(block) ? block : undefined}
                          handleBlockChanged={(b) => {}}
                          handleBlockDelete={() => {}}
                        />)}
                      </div>
                      <div className="w-50">
                        <h2 className="text-center">Akcje</h2>

                        <ListGroup>
                          {filter.actions.map(action => <ListGroupItem>
                            <Action action={action} changeable={false} />
                          </ListGroupItem>)}
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                }
                case 'serial': {
                  return <div className="d-flex flex-column border shadow p-2 mb-3">
                    <h1 className="text-center mb-0">{filter.name ? filter.name : <i>Brak nazwy filtra</i>}</h1>
                    <hr />
                    {e && <Alert color="danger" className="d-flex flex-column">
                      {e}
                    </Alert>}
                    <div className="d-flex flex-row">
                      <div className="w-50">
                        <h2 className="text-center">Warunki</h2>

                        <FilterSerialCond filter={filter} />
                      </div>
                      <div className="w-50">
                        <h2 className="text-center">Akcje</h2>

                        <FilterSerialAction filter={filter} />
                      </div>
                    </div>
                  </div>
                }
                case 'iterative': {
                  return <div className="d-flex flex-column border shadow p-2 mb-3">
                    <h1 className="text-center mb-0">{filter.name ? filter.name : <i>Brak nazwy filtra</i>}</h1>
                    <hr />
                    {e && <Alert color="danger" className="d-flex flex-column">
                      {e}
                    </Alert>}
                    <div className="d-flex flex-row">
                      <div className="w-50">
                        <h2 className="text-center">Warunki</h2>

                        <FilterIterationCond filter={filter} />
                      </div>
                      <div className="w-50">
                        <h2 className="text-center">Akcje</h2>

                        <FilterIterationActions filter={filter} />
                      </div>
                    </div>
                  </div>
                }
              }
            })}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={e => props.onClose()}>Zamknij</Button>
        <Button disabled={filters.length === 0} loading={saving} onClick={saveFilters}>Zapisz {filters.length > 0 && <span> ({filters.length})</span>}</Button>
      </ModalFooter>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps, {addFilter, updateFilter, addFilterSerial, updateFilterSerial, addFilterIterative, updateFilterIterative})(FilterFromTextareaModal);
