import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PrizeCategoriesList from './PrizeCategoriesList';
import {fetchPrizeCategories} from "../actions";
import {connect} from "react-redux";
import LoadingSpinner from "components/LoadingSpinner";
import appRoutes from 'appRoutes';
import {Link} from "react-router-dom";

class PrizeCategories extends Component {

  componentDidMount(){
    this.props.fetchPrizeCategories();
  }

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">
                    <FormattedMessage id="admin.prizeCategories.cardHeader"/>
                  </h2>
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Link to={appRoutes.admin.prizes.categories.create}>
                <Button className="mb-3" color="primary">
                  <i className="fas fa-plus" />
                  <FormattedMessage id="admin.prizeCategories.button.create" />
                </Button>
              </Link>
              <Row>
                <Col>
                  {this.props.prizeCategories.loading ? <LoadingSpinner width={20} classNames="my-0" /> : <PrizeCategoriesList prizeCategories={this.props.prizeCategories.data.prizeCategories} level={0}/>}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    prizeCategories: state.admin.prizeCategories
  }
}

export default connect(mapStateToProps, {fetchPrizeCategories})(PrizeCategories);