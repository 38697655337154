import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import RespondentForm from "./RespondentForm";
import { connect } from 'react-redux';
import {createRespondent} from '../actions';
import PropTypes from 'prop-types';

class RespondentCreateButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      loading: false,
      errors: {},
    };

    this.form = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.createUser = this.createUser.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  createUser(e, data) {
    e.preventDefault();
    this.setState({loading: true, errors: {}});

    this.props.createRespondent(data.user)
      .then(() => {
        this.props.handleChange();
        this.setState({loading: false, modal: false})
      })
      .catch(e => this.setState({loading: false, errors: e.response.data.form.errors}));
  }

  render() {
    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')} size="lg">
      <ModalHeader toggle={() => this.toggle('modal')} tag="h2"><FormattedMessage id="admin.respondents.respondentCreateButton.modalHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <RespondentForm errors={this.state.errors} onSubmit={this.createUser} ref={this.form}/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggle('modal')}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={this.state.loading}
            data-style={ZOOM_OUT}
            onClick={e => this.createUser(e, this.form.current.state)}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="primary" disabled={this.props.loading} onClick={() => this.toggle('modal')} className="mb-2"><FormattedMessage id="admin.respondents.respondentCreateButton.button" /></Button>
        {modal}
      </React.Fragment>
    )
  }
}

RespondentCreateButton.defaultProps = {
  loading: false
};

RespondentCreateButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default connect(null, {createRespondent})(RespondentCreateButton);