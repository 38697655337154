import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button, Alert} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import api from 'api';

class DeleteButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    }
  }

  addressDelete(){
    this.setState({
      deleting: true
    });

    api.surveyCollector.tokens.delete.token({
      id: this.props.token.id
    })
    .then(() => {
      this.props.handleDelete(this.props.token.id);
      this.setState({deleting: false, modal: false})
    })
    .catch(() => {
      this.setState({
        deleting: false
      });
    });
  }

  toggleModalDelete(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { deleting } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModalDelete()} size="lg">
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2" className="bg-danger"><span className="text-white"><FormattedMessage id="surveyCollector.typeTokens.token.deleteButton.modalHeader" /></span></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Alert color="danger">
          <div className="d-flex align-items-center">
            <i className="fas fa-exclamation-triangle fa-3x pr-3" />
            <div className="d-flex flex-column">
              <FormattedMessage id="surveyCollector.typeTokens.token.deleteButton.modalBody">{msg => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>
              <FormattedMessage id="surveyCollector.typeTokens.token.deleteButton.modalBody.hint">{msg => <span className="mt-2">{msg}</span>}</FormattedMessage>
            </div>
          </div>
        </Alert>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.modal.delete.confirmation">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={() => this.addressDelete()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModalDelete()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="m-0 p-0" onClick={() => this.toggleModalDelete()}><i className="fas fa-times"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

DeleteButton.propTypes = {
  token: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default DeleteButton;