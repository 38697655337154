import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from "react-intl";
import {updateSurvey} from "../../../../data/survey/actions";
import {Row, Col, Button, Input} from 'reactstrap';
import striptags from "striptags";
import HtmlEditor from "../../../../../../../componentsReusable/HtmlEditor";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import LoadingSpinner from "components/LoadingSpinner";
import {isMobile, isTablet} from "react-device-detect";
import getCKConfigByPlan from "utils/ckeditor/questionConfig";
import reactHtmlParser from "react-html-parser";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import SurveySettingAvailable from "components/PlanRestrictionsModals/modals/SurveySettingAvailable";

class InaccessibilityText extends Component{
  constructor(props) {
    super(props);
    this.state = {
      inaccessibilityText: props.inaccessibilityText,
      inaccessibilityToggle: props.inaccessibilityToggle,
      editing: false,
      saving: false,
    };
    this.toggleEditing = this.toggleEditing.bind(this);
    this.handleInaccessibilityToggle = this.handleInaccessibilityToggle.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  toggleEditing(type) {

    if (!this.props.surveyPlan.inaccessibilityText) {
      this.props.setPlanRestrictionsModals(SurveySettingAvailable.getName());
    } else {
      this.setState({
        [type]: !this.state[type]
      });
    }
  }

  onChange(value, type) {
    this.setState({
      [type]: value
    });
  }

  updateContent(){
    this.setState({
      saving: true
    });

    const data = {
      id: this.props.surveyId,
      survey: {
        inaccessibilityText: this.state.inaccessibilityText
      }
    };

    this.props.updateSurvey(data)
      .then((res) => {
        this.setState({
          saving: false,
          editing: false,
          inaccessibilityText: res.inaccessibilityText
        });
      })
      .catch(() => {
        this.setState({
          saving: false,
          editing: false
        });
      });
  }

  handleInaccessibilityToggle(){
    if (!this.props.surveyPlan.inaccessibilityText) {
      this.props.setPlanRestrictionsModals(SurveySettingAvailable.getName());
      return;
    }
    this.setState({
      inaccessibilityText: ''
    }, () => this.updateContent());
    this.props.handleInaccessibilityToggle();
  }


  render() {
    const {inaccessibilityText, editing, saving} = this.state;
    const {inaccessibilityToggle, plan} = this.props;

    const editor = <React.Fragment>
      <Row>
        <Col>
          {(isMobile || isTablet)
            ? <Input value={inaccessibilityText} onChange={e => this.onChange(e.target.value, 'inaccessibilityText')} onBlur={this.updateContent} autoFocus={true} />
            : <HtmlEditor
              onChange={e => this.onChange(e, 'inaccessibilityText')}
              data={inaccessibilityText}
              config={getCKConfigByPlan(plan)}
            />}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mr-2"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={() => this.updateContent()}
            >{message}</LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) => <Button onClick={() => this.toggleEditing('editing')}>{message}</Button>
          }</FormattedMessage>
        </Col>
      </Row>
    </React.Fragment>;

    return (
      <React.Fragment>
        {inaccessibilityToggle
          ?
            (
              <React.Fragment>
                <div className={this.props.surveyPlan.inaccessibilityText ? '' : 'not-active'}>
                  <h2 className="mt-4"><FormattedMessage id="surveyStructure.endingText.inaccessibilityText.content"/> <i className="fas fa-trash pointer" onClick={() => this.handleInaccessibilityToggle()}> </i></h2>
                  {editing ?
                    editor
                    :
                    inaccessibilityText ?
                      <span onClick={() => this.toggleEditing('editing')}>{reactHtmlParser(striptags(inaccessibilityText))}</span>
                      :
                      <i onClick={() => this.toggleEditing('editing')}><FormattedMessage id="surveyStructure.endingText.inaccessibilityText.isEmpty"/></i>
                  }
                </div>
              </React.Fragment>
            ) : saving ? <LoadingSpinner width={25} classNames="my-0"/> : ''
        }
      </React.Fragment>
    );
  }
}

InaccessibilityText.propTypes = {
  surveyId: PropTypes.string.isRequired,
  inaccessibilityToggle: PropTypes.bool.isRequired,
  inaccessibilityText: PropTypes.string,
  handleInaccessibilityToggle: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {updateSurvey, setPlanRestrictionsModals})(InaccessibilityText)
