import React from 'react';
import PropTypes from 'prop-types';
import AnyChart from 'anychart-react'
import StatusesSelect from "components/Survey/Analysis/StatusesSelect";
import moment from "moment";
import DatePicker from "react-datepicker";
import {Button} from "reactstrap";
import api from "../../../../api";
import striptags from "striptags";

const QuestionsResponsesChart = props => {
  const [data, setData] = React.useState([]);
  const [filters, setFilters] = React.useState({
    statuses: [3],
    dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
  });
  const [chartVisible, setChartVisible] = React.useState(false);

  React.useEffect(() => {
    if(chartVisible){
      fetchData();
    }
  }, [filters, chartVisible]);

  const fetchData = () => {
    api.survey.post.summaryQuestionsResponses(props.surveyId, filters)
      .then(data => {
        setData(data.data);
      })
  }

  const changeFilters = obj => {
    setFilters({
      ...filters,
      ...obj,
    });
  }

  const getChart = () => {
    let stage = anychart.graphics.create();
    let chart = anychart.line();
    chart.legend(false);
    chart.tooltip().positionMode('point');
    chart.yAxis().title('Liczba wypełnień pytania');
    chart.xAxis().labels().padding(5);



    chart.yScale().stackMode('value');
    chart.yScale().ticks().allowFractional(false);

    let dataSet = anychart.data.set(data.map(question => [
          `P${question.position}` + " " + striptags(question.content), question.value
        ]
      )
    );


    let firstSeriesData = dataSet.mapAs({ x: 0, value: 1 });

    let firstSeries = chart.line(firstSeriesData);
    firstSeries.name('Liczba wypełnień');
    firstSeries.hovered().markers().enabled(true).type('circle').size(4);
    firstSeries
      .tooltip()
      .position('right')
      .anchor('left-center')
      .offsetX(5)
      .offsetY(5);

    return (
      <AnyChart id="questions-chart" instance={stage} charts={[chart]} height={400}/>
    )
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row flex-nowrap align-items-center">
        <div style={{minWidth: '250px'}}>
          <StatusesSelect
            className="w-100"
            onChange={statuses => changeFilters({statuses})}
            value={filters.statuses}
          />
        </div>
        <div className="d-flex h-100 justify-content-center align-items-center ml-2">
          <DatePicker
            dropdownMode="select"
            className="form-control w-100 mb-0"
            isClearable={false}
            selected={moment(filters.dateFrom)}
            onChange={(date) => changeFilters({dateFrom: date.format('YYYY-MM-DD')})}
            dateFormat="Y-MM-DD"
            locale={localStorage.language}
          />
          <div className="px-2 d-flex align-items-center"> -</div>
          <DatePicker
            dropdownMode="select"
            className="form-control w-100 mb-0"
            isClearable={false}
            selected={moment(filters.dateTo)}
            onChange={(date) => changeFilters({dateTo: date.format('YYYY-MM-DD')})}
            dateFormat="Y-MM-DD"
            locale={localStorage.language}
          />
        </div>
      </div>

      {!chartVisible && <Button color="primary" className="mx-auto my-5" onClick={() => setChartVisible(true)}>Pokaż wykres</Button>}

      {chartVisible && getChart()}
    </div>
  );
};

QuestionsResponsesChart.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default QuestionsResponsesChart;