import React, {Component} from 'react';
import {connect} from "react-redux";
import {ReactSortable} from "react-sortablejs";
import {FormattedMessage} from "react-intl";
import {addSet} from "scenes/Survey/Structure/data/sets/actions";
import PropTypes from "prop-types";
import i18Config from "i18Config";
import OnMoving from "../../../OnMoving";
import OnAdding from "../../../OnAdding";
import {toggleSetCard} from "scenes/Survey/Structure/actions"
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import classnames from "classnames";
import SetsAvailable from "components/PlanRestrictionsModals/modals/SetsAvailable";

class SetElements extends Component {

  constructor(props) {
    super(props);

    this.state = {
      adding: false,
    };

    this.onEndBlock = this.onEndBlock.bind(this);
    this.shouldRenderElement = this.shouldRenderElement.bind(this);
  }

  onEndBlock(evt){
    if(evt.from === evt.to){
      return false;
    }
    evt.to.insertBefore(evt.item, evt.to.children[evt.newIndex]);

    this.setState({
      adding: true,
    });

    const {survey} = this.props;

    this.props.addSet({
      survey: survey.id,
      set: {
        position: evt.newIndex
      }
    }).then(set => {
      if (this.props.hideInStructure === 'sets' || this.props.hideInStructure === 'structure' ) {
        this.props.toggleSetCard(set.id);
      }
      evt.item.remove();
    })
  }

  shouldRenderElement() {
    if (typeof this.props.searchText !== 'string')
      return true;

    const searchingText = this.props.searchText.toLowerCase();
    const elementText = i18Config.messages['structure.sidebar.elements.sortable.set'].toLowerCase();
    const elementName = 'set';

    return elementText.includes(searchingText) || elementName.includes(searchingText);
  }

  render() {
    const {adding} = this.state;

    if(adding){
      this.setState({
        adding: false,
      });

      return null;
    }

    return this.shouldRenderElement() &&
      <ReactSortable
        list={[{}]}
        setList={() => {}}
        group={{
          name: 'sets',
          pull: 'clone',
          put: false,
          revertClone: true,
        }}
        sort={false}
        onEnd={this.onEndBlock}
        filter=".sortable-ignore"
      >
        <div
          className={classnames({
          "line-wrap py-1": true,
          'not-active sortable-ignore': !this.props.surveyPlan.sets
          })}
          role="set"
          onClick={() => !this.props.surveyPlan.sets && this.props.setPlanRestrictionsModals(SetsAvailable.getName())}
        >
          <a className="fas fa-grip-vertical dragg mr-3 mt-0"/>
          <a className="fas fa-bars mr-2" style={{fontSize: 12, cursor: 'default'}}/>
          <h5 className="mb-0 d-inline-block"><FormattedMessage id="structure.sidebar.elements.sortable.set"/></h5>
          <OnMoving/>
          <OnAdding/>
        </div>
      </ReactSortable>

  }
}

SetElements.propTypes = {
  searchText: PropTypes.string
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
    hideInStructure: state.survey.structure.hideInStructure,
    surveyPlan: state.user.userPlan.plan.survey,
  }
}

export default connect(mapStateToProps, {addSet, toggleSetCard, setPlanRestrictionsModals})(SetElements);