export const SET_NAME = 'PlanRestrictionsModals/SET_NAME';

export function setPlanRestrictionsModals(name) {
  return dispatch => {
    dispatch({
        type: SET_NAME,
        name
    });
  }
}
