import React, {Component} from 'react';
import AsyncSelect from 'react-select/lib/Async';
import PropTypes from "prop-types";
import api from "api";
import {Option} from "react-select";
import UserAvatar from "../components/UserAvatar";

class UsersGroupSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      search: '',
      options: [],
      value: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  fetchData(search, callback){
    api.user.group.users()
    .then(data => {
      let options = data.map(user => {
        const content = (user.firstname && user.lastname) ? (user.firstname + ' ' + user.lastname) : user.email;

        return {
          value: user.id,
          label: <div className="d-flex align-items-center">
            <UserAvatar user={user} />
            <span className="ml-2">{content}</span>
          </div>,
          avatar: user.avatar
        }
      });
      callback(options)
    });
  }

  handleInputChange(newValue){
    const search = newValue.replace(/\W/g, '');
    this.setState({ search });
    return search;
  }

  render() {
    return <AsyncSelect
      isMulti
      className="w-100"
      placeholder={this.props.placeholder}
      cacheOptions
      loadOptions={this.fetchData}
      defaultOptions
      onInputChange={this.handleInputChange}
      onChange={options => this.props.onChange(options.map(option => option.value))}
    />
  }
}

UsersGroupSelect.defaultProps = {
  placeholder: 'Wybierz użytkowników...',
};

UsersGroupSelect.propTypes = {
  onChange: PropTypes.func,
  onFetched: PropTypes.func,
  values: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
};

export default UsersGroupSelect;
