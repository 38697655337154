import update from 'immutability-helper';

import {
  ADDED,
  DELETED,
  SET,
  UPDATED,
  SET_PAGES,
  PAGE_ADDED
} from './actions';
import * as pagesActions from "../pages/actions";

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case pagesActions.DELETED:
      return update(state, {
        [action.page.block.id]: {
          pages: {
            $set: state[action.page.block.id].pages.filter(id => id !== action.page.id)
          }
        }
      });

    case PAGE_ADDED:
      let list = state[action.page.blockPages.id].pages;
      list.splice(action.page.position, 0, action.page.id);

      return update(state, {
        [action.page.blockPages.id]: {
          pages: {
            $set: list
          }
        }
      });

    case SET_PAGES:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          pages: action.data.pages
        }
      };

    case SET:
      return action.blocks;

    case ADDED:
      return {
        ...state,
        [action.block.id]: action.block
      };

    case DELETED:
      return update(state, {$unset: [action.block.id] });

    case UPDATED:
      return update(state, {
        [action.block.id]: {
          $set: action.block
        }
      });

    default:
      return state;
  }
};