import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Button, CardHeader, CardBody, Card} from "reactstrap";
import ListItems from './components/ListItems';
import PipingForm from "./components/PipingForm";
import HelpModeIconModal from "components/HelpModeIconModal";

class Pipings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editing: false
    };

    this.toggleEdit = this.toggleEdit.bind(this);
  }

  toggleEdit(edit) {
    this.setState({
      editing: edit
    });
  }

  render() {
    let props = {};

    if(this.state.editing && this.state.editing !== true){
      props.piping = this.state.editing;
    }

    return (
      <Card>
        <CardHeader>
          <FormattedMessage id="question.pipings.header">{msg => {
            return <h2>{msg} <HelpModeIconModal modalHeader="Piping">
              <p>Wskaż odpowiedzi, które po zaznaczeniu przez respondenta, chcesz przepisać do treści pytania lub odpowiedzi. Najpierw wybierz pytanie i element, który chcesz przepisać, następnie nazwij ten element. Po zapisaniu ustawienia wstaw tę nazwę w podwójnej klamrze [[twojanazwa]] do treści pytań lub odpowiedzi.</p>
            </HelpModeIconModal></h2>
          }}</FormattedMessage>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>

          {this.state.editing
            ? <PipingForm {...props} question={this.props.question} toggleEdit={this.toggleEdit}/>
            : <React.Fragment>
                <Button color="primary" className="mb-3" onClick={() => this.toggleEdit(true)}><i className="fas fa-plus" /> <FormattedMessage id="question.pipings.button.create" /></Button>
                <ListItems question={this.props.question} toggleEdit={this.toggleEdit}/>
            </React.Fragment>
          }
        </CardBody>
      </Card>
    )
  }
}

Pipings.propTypes = {
  question: PropTypes.object.isRequired,
};

export default Pipings;