import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Switch from "components/Switch";
import InputNumber from "components/InputNumber";
import {updateBlock} from 'scenes/Survey/Structure/data/blocks/actions';
import {updateSet} from "../../../../../../data/sets/actions";

class RandAndRotatePageOptions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        rotateInSurvey: props.set.randomInSurvey,
        randomInSurvey: props.set.randomInSurvey,
      }
    };
  }

  onChange(property, value) {
    this.setState({
      data: {
        ...this.state.data,
        [property]: value,
      }
    });

    this.props.updateSet({
      id: this.props.set.id,
      set: {
        [property]: value
      }
    });
  }

  render() {
    const {set, survey} = this.props;

    return (
      <tr>
        <td>
          <span>Zestaw {set.positionInSurvey + 1}{set.name && ': ' + set.name}</span>
        </td>
        {survey.rotateSets && <td>
          <Switch checked={set.rotateInSurvey} onChange={value => this.onChange('rotateInSurvey', value)}/>
        </td>}
        {survey.randSets > 0 && <td>
          <Switch checked={set.randomInSurvey} onChange={value => this.onChange('randomInSurvey', value)}/>
        </td>}
      </tr>
    )
  }
}

RandAndRotatePageOptions.propTypes = {
  set: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps, {updateSet})(RandAndRotatePageOptions);