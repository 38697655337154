import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {removePageBreaks} from "scenes/Survey/Structure/data/blocks/actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

class ModalRemovePageBreaks extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false
    };
  }

  updateBlock(){
    this.setState({saving: true});

    this.props.removePageBreaks({
      id: this.props.block.id
    }).then(() => {
      this.setState({saving: false});
      this.props.toggleModal()
    });
  }

  render() {
    const {saving} = this.state;

    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
      <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="block.headerActions.modalRemovePageBreaks.cardHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="block.headerActions.modalRemovePageBreaks.cardBody" /></p>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.yes">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={() => this.updateBlock()}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>

            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  }
}

ModalRemovePageBreaks.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default connect(null, {removePageBreaks})(ModalRemovePageBreaks);