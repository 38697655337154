import React, {Component} from 'react';
import Pagination from "../../../../../components/Pagination";
import ReactTable from "react-table";
import api from "../../../../../api";
import {Alert, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import SmtpForm from "./components/SmtpForm";
import Button from "../../../../../components/Button";

const ButtonDelete = (props) => {
  const [deleting, setDeleting] = React.useState();
  const [modal, setModal] = React.useState(false);
  const toggleModal = () => setModal(!modal);

  const remove = () => {
    setDeleting(true);

    api.smtp.delete(props.id)
    .then(() => {
      toggleModal();
      setDeleting(false);
      props.onDeleted();
    })
    .catch(() => {
      setDeleting(false);
    });
  };

  return <React.Fragment>
    <Button size={'sm'} color="danger" onClick={toggleModal}><i className="fas fa-times text-danger" /> Usuń</Button>
    {modal && <Modal isOpen={true} toggle={toggleModal} size="xl">
      <ModalHeader tag="h2" toggle={toggleModal}>Dodaj konto SMTP</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <p>Czy na pewno chcesz usunąć ustawienia konta SMTP?</p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.delete">{
          (message) => <Button
            loading={deleting}
            onClick={remove}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
};

const ButtonEdit = (props) => {
  const formRef = React.createRef();
  const [data, setData] = React.useState(props.data);
  const [errors, setErrors] = React.useState({});
  const [saving, setSaving] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  React.useEffect(() => setData(props.data), [props.data]);

  const save = () => {
    setSaving(true);
    setErrors({});

    api.smtp.patch(formRef.current.state.data)
    .then(data => {
      setSaving(false);
      toggle();
      props.onSaved();
    })
    .catch(res => {
      setSaving(false);
      setErrors(res.response.data.errors);
    });
  };

  return <React.Fragment>
    <Button size={'sm'} onClick={toggle}><i className="fas fa-edit" /> Edytuj</Button>
    {modalOpen && <Modal isOpen={true} toggle={toggle} size="xl">
      <ModalHeader tag="h2" toggle={toggle}>Edytuj konto SMTP</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        {errors.smtpInvalid && <Alert color="danger" className="text-center w-100">Podane dane nie są poprawne</Alert>}
        <SmtpForm ref={formRef} data={data} errors={errors.children || {}} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggle}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <Button
            loading={saving}
            onClick={save}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
};

const SmtpAddModal = (props) => {
  const formRef = React.createRef();
  const [data, setData] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const toggleErrors = () => setErrors(!errors);
  const toggleData = () => setData(!data);
  const [saving, setSaving] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggle = () => setModalOpen(!modalOpen);

  const save = () => {
    setSaving(true);
    setErrors({});

    api.smtp.post(formRef.current.state.data)
    .then(data => {
      setSaving(false);
      toggle();
      props.onAdded();
    })
    .catch(res => {
      setSaving(false);
      setErrors(res.response.data.errors.children);
    });
  };

  return <React.Fragment>
    <Button onClick={toggle}><i className="fas fa-plus" /> Dodaj konto SMTP</Button>
    {modalOpen && <Modal isOpen={true} toggle={toggle} size="xl">
      <ModalHeader tag="h2" toggle={toggle}>Dodaj konto SMTP</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <SmtpForm ref={formRef} errors={errors} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggle}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <Button
            loading={saving}
            onClick={save}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
};

class SmtpAccountsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      total: 0,
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData(){
    api.smtp.search({})
    .then(data => {
      this.setState({
        data: data.data,
        total: data.count,
      })
    })
    .catch(e => {

    });
  }

  render() {
    const {data} = this.state;

    const columns = [
      {
        accessor: 'name',
        Header: 'Nazwa',
      },{
        accessor: 'host',
        Header: 'Host',
      },{
        accessor: 'port',
        Header: 'Port',
      },{
        accessor: 'email',
        Header: 'E-mail',
      },{
        id: 'password',
        Header: 'Hasło',
        accessor: row => row.passwordHashed,
      },{
        id: 'ssl',
        Header: 'SSL',
        accessor: row => row.ssl ? <span>Tak</span> : <span>Nie</span>,
      },{
        id: 'actions',
        Header: 'Akcje',
        accessor: (row) => {
          return <ButtonGroup>
            <ButtonEdit data={row} onSaved={() => this.fetchData()} />
            <ButtonDelete id={row.id} onDeleted={() => this.fetchData()} />
          </ButtonGroup>
        },
      },
    ];

    return <div>
      <h1>Lista kont SMTP</h1>
      <SmtpAddModal onAdded={() => this.fetchData()} />
      <ReactTable
        minRows={0}
        className={'table-wrap mt-4'}
        data={data}
        columns={columns}
        PaginationComponent={Pagination}
        noDataText={'Brak danych'}
        defaultPageSize={10}
      />
    </div>;
  }
}

export default SmtpAccountsTable;