import React, {Component} from 'react';
import {Form, FormGroup, Label, Input, Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
import history from 'services/history';
import appRoutes from "appRoutes";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import Select from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {prizeImageLink} from 'services/prizeImageLink';
import { connect } from 'react-redux';
import {fetchPrizeCategories} from "../../PrizeCategories/actions";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Switch from "components/Switch";
import api from 'api';

class PrizeForm extends Component {

  constructor(props) {
    super(props);
    props.fetchPrizeCategories();
    let categories = [];
    if (props.prize)
      _.each(props.prize.categories, category => categories.push(category.id));

    this.state = {
      imageChanged: false,
      prize: {
        name: props.prize ? props.prize.name : '',
        type: props.prize ? {value: props.prize.type, label: props.prize.type} : '',
        description: props.prize ? props.prize.description : '',
        cost: props.prize ? props.prize.cost : 0,
        stock: props.prize ? props.prize.stock : 0,
        image: props.prize ? props.prize.image : '',
        active: props.prize ? props.prize.active : true,
        categories: categories,
        plan: (props.prize && props.prize.plan) ? {label: props.prize.plan.name, value: props.prize.plan.id} : {},
        extendByDays: props.prize ? props.prize.extendByDays : 0,
      },
      saving: false,
      categoriesSelectData: props.prize && _.map(props.prize.categories, category => {return {value: category.id, label: category.name}}),
      imagePath: props.prize ? prizeImageLink(props.prize.image) : '',
      plans: [],
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onPlanChange = this.onPlanChange.bind(this);
    this.onCategoriesChange = this.onCategoriesChange.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
    this.savePrize = this.savePrize.bind(this);
  }

  componentDidMount() {
    api.plan.get.plans(0, 1).then(plans => {
      this.setState({plans})
    })
  }

  onChange(e) {
    this.setState({
      ...this.state,
      prize: {
        ...this.state.prize,
        [e.target.name]: e.target.value
      }
    });
  }

  onImageUpload(e) {
    this.setState({
      ...this.state,
      imageChanged: true,
      imagePath: URL.createObjectURL(e.target.files[0]),
      prize: {
        ...this.state.prize,
        image: e.target.files[0],
      }
    });
  }

  onCategoriesChange(categories) {
    let newCategories = this.state.prize.categories;
    newCategories.push(categories.value);

    this.setState({
      ...this.state,
      categoriesSelectData: categories,
      prize: {
        ...this.state.prize,
        categories: _.map(categories, category => category.value),
      }
    });
  }

  onTypeChange(type) {
    this.setState({
      ...this.state,
      prize: {
        ...this.state.prize,
        type: type,
      }
    });
  }

  onPlanChange(plan) {
    this.setState({
      ...this.state,
      prize: {
        ...this.state.prize,
        plan: plan,
      }
    });
  }

  savePrize(e) {
    e.preventDefault();

    this.setState({
      ...this.state,
      saving: true
    });

    let form = new FormData();
    form.append('name', this.state.prize.name);
    form.append('active', this.state.prize.active);
    form.append('type', this.state.prize.type.value);
    form.append('description', this.state.prize.description);
    form.append('cost', this.state.prize.cost);
    form.append('stock', this.state.prize.stock);
    form.append('plan', this.state.prize.plan.value);
    form.append('extendByDays', this.state.prize.extendByDays);
    if(this.state.imageChanged){
      form.append('image', this.state.prize.image, this.state.prize.image.name);
    }
    form.append('categories', JSON.stringify(this.state.prize.categories));

    this.props.handleSave({
      id: this.props.prize ? this.props.prize.id : null,
      prize: form
    }).then(() => {
      toast.success(<FormattedMessage id="admin.prizeForm.toast.success" />);
      history.push(appRoutes.admin.prizes.list)
    })
      .catch(res => {
        this.setState({
          ...this.state,
          errors: res.response.data.form.errors,
          saving: false
        })
      })
  }

  render() {
    let {errors, prize, categoriesSelectData, imagePath, saving} = this.state;
    const types = [
      {value: 'account', label: 'account'},
      {value: 'cash', label: 'cash'},
      {value: 'charity', label: 'charity'},
      {value: 'coupon', label: 'coupon'},
      {value: 'material', label: 'material'},
      {value: 'shirt', label: 'shirt'}
    ];
    const plansOptions = _.map(this.state.plans, plan => {return {value: plan.id, label: plan.name}});

    return (
      <Form>
        <FormGroup>
          <Label for="name"><FormattedMessage id="admin.prizeForm.label.name" /></Label>
          <Input type="text" name="name" id="name" value={prize.name} invalid={!!errors.name} onChange={this.onChange}/>
          {errors.name && <p className="help-block text-danger">{errors.name}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="active"><FormattedMessage id="admin.prizeForm.label.active" /></Label>
          <Switch className="ml-4" onChange={active => this.onChange({target: {name: 'active', value: active}})} checked={prize.active} />
          {errors.active && <p className="help-block text-danger">{errors.active}</p>}
        </FormGroup>
        <FormGroup>
          <Label><FormattedMessage id="admin.prizeForm.label.type" /></Label>
          <Select
            value={prize.type}
            onChange={this.onTypeChange}
            options={types}
            isDisabled={this.props.prize}
          />
          {errors.categories && <p className="help-block text-danger">{errors.categories}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="description"><FormattedMessage id="admin.prizeForm.label.description" /></Label>
          <Input type="textarea" name="description" id="description" value={prize.description} invalid={!!errors.description} onChange={this.onChange}/>
          {errors.description && <p className="help-block text-danger">{errors.description}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="cost"><FormattedMessage id="admin.prizeForm.label.cost" /></Label>
          <Input type="number" name="cost" id="cost" value={prize.cost} invalid={!!errors.cost} onChange={this.onChange}/>
          {errors.cost && <p className="help-block text-danger">{errors.cost}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="stock"><FormattedMessage id="admin.prizeForm.label.stock" /></Label>
          <Input type="number" name="stock" id="stock" value={prize.stock} invalid={!!errors.stock} onChange={this.onChange}/>
          {errors.stock && <p className="help-block text-danger">{errors.stock}</p>}
        </FormGroup>
        {prize.type.value === 'account' && <FormGroup>
          <Label for="plan"><FormattedMessage id="admin.prizeForm.label.plan" /></Label>
          <Select
            value={prize.plan}
            onChange={this.onPlanChange}
            options={plansOptions}
          />
          {errors.plan && <p className="help-block text-danger">{errors.plan}</p>}
        </FormGroup>}
        {prize.type.value === 'account' && <FormGroup>
          <Label for="extendByDays"><FormattedMessage id="admin.prizeForm.label.extendByDays" /></Label>
          <Input type="number" name="extendByDays" id="extendByDays" value={prize.extendByDays} invalid={!!errors.extendByDays} onChange={this.onChange}/>
          {errors.extendByDays && <p className="help-block text-danger">{errors.extendByDays}</p>}
        </FormGroup>}
        <FormGroup>
          <Label><FormattedMessage id="admin.prizeForm.label.categories" /></Label>
          <Select
            isMulti
            value={categoriesSelectData}
            onChange={this.onCategoriesChange}
            options={_.map(this.props.prizeCategories, category => {
              return {
                value: category.id,
                label: category.name
              }
            })}
          />
          {errors.categories && <p className="help-block text-danger">{errors.categories}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="image"><FormattedMessage id="admin.prizeForm.label.image" /></Label><br />
          <img src={imagePath} />
          <Input type="file" name="image" id="image" onChange={this.onImageUpload} />
          {errors.image && <p className="help-block text-danger">{errors.image}</p>}
        </FormGroup>
        <Link to={appRoutes.admin.prizes.list}>
          <Button color="secondary" >
            <FormattedMessage id="_.button.back" />
          </Button>
        </Link>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda float-right"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.savePrize}
          >{message}</LaddaButton>
        }</FormattedMessage>
      </Form>
    )
  }
}

PrizeForm.propTypes = {
  handleSave: PropTypes.func.isRequired,
  prize: PropTypes.object
};

function mapStateToProps(state) {
  return {
    prizeCategories: state.admin.prizeCategories.data.prizeCategories
  }
}

export default connect(mapStateToProps, {fetchPrizeCategories})(PrizeForm);