import React, {Component} from 'react';
import AsyncSelect from 'react-select/lib/Async';
import api from "../../../api";
import PropTypes from "prop-types";

class BaseSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      search: '',
      options: [],
      value: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if(this.props.base){
      api.respondentSmsBases.get(this.props.base)
      .then(res => {
        this.setState({
          value: {
            value: this.props.base,
            label: res.data.name,
          }
        })

      });
    }
  }

  fetchData(search, callback){
    api.respondentSmsBases.search({
      page: 1,
      limit: 50,
      search,
    }, {
      sortField: 'rsb.id',
      sortOrder: 'desc',
    })
    .then(res => {
      let options = res.data.map(base => {
        return {
          value: base.id,
          label: base.name,
        }
      });

      this.setState({
        data: res.data,
      }, () => {
        callback(options);
      });
    });
  }

  handleInputChange(newValue){
    const search = newValue.replace(/\W/g, '');
    this.setState({ search });
    return search;
  }

  render() {
    const {value, data, modalAdd} = this.state;

    return <React.Fragment>
      <AsyncSelect
        className={this.props.className}
        placeholder={'Wybierz baze'}
        cacheOptions
        loadOptions={this.fetchData}
        defaultOptions
        onInputChange={this.handleInputChange}
        onChange={option => {
          const base = data.find(c => c.id === option.value);

          this.setState({
            value: {
              value: base.id,
              label: base.name,
            }
          });

          this.props.onChange(base);
        }}
        value={value}
      />
    </React.Fragment>
  }
}

BaseSelect.defaultProps = {
  className: '',
};

BaseSelect.propTypes = {
  onChange: PropTypes.func,
  base: PropTypes.number,
  className: PropTypes.string.isRequired,
};

export default BaseSelect;
