import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import ReportsList from "./components/ReportsList";
import {FormattedMessage} from "react-intl";
import HelpModeIconModal from "../../../components/HelpModeIconModal";

class Reports extends Component {
  render() {
    return <main>
      <Card>
        <CardHeader>
          <FormattedMessage id="reports.cardHeader">{msg => {
            return <h2>{msg} <HelpModeIconModal modalHeader="Błędy w ankiecie">
              <p>Lista ze zgłoszeniami od osób, które wypełniły ankietę</p>
            </HelpModeIconModal></h2>
          }}</FormattedMessage>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <Row>
            <Col>
              <ReportsList surveyId={parseInt(this.props.match.params.survey)} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </main>;
  }
}

export default Reports;