import api from "api";

export const SET = 'GlobalTemplates/SET';

export function setTemplates(templates) {
  return {
    type: SET,
    templates
  }
}

export function fetchTemplates() {
  return dispatch => {
    api.question.get.templates().then(templates => {
      let orderedTemplates = {};
      _.each(templates, template => orderedTemplates[template.id] = template);

      dispatch(setTemplates(orderedTemplates));
    });
  }
}
