import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Filter from "scenes/Survey/Structure/components/Filter";
import QuotaNew from "../../../../../../../../../../../../../../../../../../QuotaNew/QuotaNew";

class QuotaNewEditModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      colMd: 12,
      quotaNew: this.props.quotaNew ? JSON.parse(JSON.stringify(this.props.quotaNew)) : undefined,
    }

    this.quotaNew = React.createRef();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.quotaNew !== this.props.quotaNew){
      this.setState({
        quotaNew: nextProps.quotaNew ? JSON.parse(JSON.stringify(nextProps.quotaNew)) : undefined,
      })
    }
  }

  getQuotaNew() {
    return this.quotaNew && this.quotaNew.current.getQuotaNew();
  }

  render() {
    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="xl">
      <div className="modal-header">
        <h2 className="modal-title w-100 d-flex flex-row justify-content-between">
          <span>Kwota</span>
        </h2>
        <button type="button" className="close" aria-label="Close" onClick={this.props.toggleModal}><span aria-hidden="true">×</span></button>
      </div>
      <hr className="my-0" />
      <ModalBody>
        <QuotaNew ref={this.quotaNew} quotaNew={this.state.quotaNew}/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.props.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={this.props.saving}
            data-style={ZOOM_OUT}
            onClick={this.props.onSave}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>
  }
}

QuotaNewEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  quotaNew: PropTypes.object
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(QuotaNewEditModal);
