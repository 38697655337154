import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import HideInStructure from "./components/HideInStructure";
import SearchInStructure from "./components/SearchInStructure";
import LanguageEdit from "./components/LanguageEdit";
import AdvancedOptions from "./components/AdvancedOptions";
import {FormattedMessage} from "react-intl";
import {Tooltip} from "reactstrap";
import QuestionsSettings from "./components/QuestionsSettings";
import appRoutes from "../../../../../appRoutes";
import PrintStructure from "../PrintStructure";

class Topbar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showAdvanced: false
    };

    this.handleSideBarRendering = this.handleSideBarRendering.bind(this);
    this.toggleAdvanced = this.toggleAdvanced.bind(this);
    this.toggleAdvancedTooltip = this.toggleAdvancedTooltip.bind(this);
    this.toggleShowSideBarTooltip = this.toggleShowSideBarTooltip.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind("shift+w", () => { this.props.handleSideBarRendering() });
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+w");
  }

  handleSideBarRendering(){
    this.props.handleSideBarRendering();
  }

  toggleAdvanced() {
    this.setState({showAdvanced: !this.state.showAdvanced})
  }

  toggleAdvancedTooltip() {
    this.setState({advancedTooltip: !this.state.advancedTooltip})
  }

  toggleShowSideBarTooltip() {
    this.setState({showSideBarTooltip: !this.state.showSideBarTooltip})
  }

  render() {
    const {languages, defaultLang, mainRef, showSideBar, showLangsOnMobile, showFiltersOnMobile} = this.props;

    return (
      <div className={"d-grid pt-2 bg-color-body sticky-top" + (showFiltersOnMobile || showLangsOnMobile ? "" : " d-none d-lg-grid")}>
        <div className="direction-right d-flex justify-content-end w-100 pb-1 flex-column flex-lg-row">
          <a href="#" id="show-side-bar" className="active mt-2 mr-2 d-none d-lg-inline d-lg" onClick={this.handleSideBarRendering} data-toggle="collapse" data-target="#collapse-sidebar" >
            <span className={!showSideBar ? 'd-none' : ''}><i className="far fa-caret-square-left" /></span>
            <span className={showSideBar ? 'd-none' : ''}><i className="far fa-caret-square-right" /></span>
          </a>
          <Tooltip
            delay={0}
            placement="bottom"
            isOpen={this.state.showSideBarTooltip}
            target={'show-side-bar'}
            toggle={this.toggleShowSideBarTooltip}
          >
            <FormattedMessage id={showSideBar ? 'structure.topbar.tooltip.hideSideBar' : 'structure.topbar.tooltip.showSideBar'}/>
          </Tooltip>

          <a href="#" id="advanced-options-tooltip" className="mt-2 pr-1 d-none d-lg-inline" onClick={this.toggleAdvanced}><i className="fas fa-filter" /></a>
          <Tooltip
            delay={0}
            placement="bottom"
            isOpen={this.state.advancedTooltip}
            target={'advanced-options-tooltip'}
            toggle={this.toggleAdvancedTooltip}
          >
            <FormattedMessage id="structure.topbar.tooltip.advancedOptions"/>
          </Tooltip>

          <SearchInStructure/>
          <HideInStructure mainRef={mainRef}/>
          {(languages && languages.length > 1) && <div className={"w-50 d-none d-lg-block ml-lg-3"} ><LanguageEdit defaultLang={defaultLang} languages={languages} /></div>}
          <QuestionsSettings />
          {this.props.hideInStructure === 'print' && <PrintStructure survey={this.props.survey}/>}
        </div>
        {this.state.showAdvanced && <AdvancedOptions />}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    survey: state.survey,
    hideInStructure: state.survey.structure.hideInStructure,
    defaultLang: state.survey.structure.data.survey.defaultLang,
    languages: state.survey.structure.data.survey.languages,
  }
}

Topbar.propTypes = {
  handleSideBarRendering: PropTypes.func.isRequired,
  showSideBar: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(Topbar);
