import React, {Component} from 'react';
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";
import { connect } from 'react-redux';
import {FormattedMessage} from "react-intl";
import GroupCreateButton from './components/GroupCreateButton';
import GroupUsersList from './components/GroupUsersList';
import GroupUserCreateModal from './components/GroupUserCreateModal';
import GroupInvitationsList from './components/Invitations/GroupInvitationsList';
import {fetchGroups} from './actions';
import LoadingSpinner from "components/LoadingSpinner";
import HelpModeIconModal from "components/HelpModeIconModal";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import GroupsUsersMaxAvailable from "components/PlanRestrictionsModals/modals/GroupsUsersMaxAvailable";

class Group extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      userCreateModal: false
    }
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  componentDidMount() {
    document.title = 'Kolaboracja | ANKIETEO';
    document.querySelector('meta[name="description"]').setAttribute("content", 'Kolaboracja');
    document.querySelector('meta[name="keywords"]').setAttribute("content", 'Kolaboracja');
    this.props.fetchGroups();
  }

  render() {
    const {plan, privileges} = this.props;
    const groupId = Object.keys(this.props.groups.data.groups)[0];
    const group = this.props.groups.data.groups[groupId];
    const hasGroup = (Object.keys(this.props.groups.data.groups) > 0);
    const hasInvitations = (Object.keys(this.props.groups.data.invitations) > 0);
    const isGroupAdmin = hasGroup ? this.props.groups.data.groups[groupId].user.id === this.props.userId : false;
    const groupUser = hasGroup && _.find(this.props.groups.data.groups[groupId].users, user => user.user.id === this.props.userId);
    const isBlocked = !!groupUser && groupUser.blocked;
    const userPrivileges = hasGroup && _.find(privileges, privilege => privilege.id === (groupUser && groupUser.privilageSurveyShareDefault));
    const isAbleToEditUsers = (hasGroup && userPrivileges && userPrivileges.accessEditUsers) || isGroupAdmin;

    if(this.props.groups.loading){
      return <LoadingSpinner  />;
    }

    return (
        <Card className="h-100 mb-0">
          <CardHeader className="d-flex justify-content-between">
            <h2>
              <i className="fas fa-users mr-2" /> Użytkownicy
              <HelpModeIconModal className="ml-2" modalHeader="Grupy">
                <p>Grupy pozwalają udostępniać i nadawać prawa do ankiet między użytkownikami</p>
              </HelpModeIconModal></h2>

            <div className="d-flex align-items-center">
              {(group && plan.groupsUsersMax > 0) && <span>Liczba osób w grupie: {Object.values(group.users).length}/{plan.groupsUsersMax}</span>}

              <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle('dropdownOpen')} className="d-md-none">
                <DropdownToggle className="m-0 p-0" color="">
                  <span className="fas fa-ellipsis-v hoverable" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" onClick={() => this.toggle('userCreateModal')} >
                    <p><span className="fas fa-plus" /></p> <FormattedMessage tagName="h5" id="groupUserCreateButton.button"/>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              {(group) && <FormattedMessage id="groupUserCreateButton.button">{
                (message) =>
                  <Button color="primary" onClick={() => {
                    if(plan.groupsUsersMax == 0 || Object.values(group.users).length < plan.groupsUsersMax){
                      this.toggle('userCreateModal');
                    }else{
                      this.props.setPlanRestrictionsModals(GroupsUsersMaxAvailable.getName())
                    }
                  }} className="ml-3 d-none d-md-block"><i className="fas fa-plus" /> {message}</Button>
              }</FormattedMessage>}

            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
          {hasInvitations && !hasGroup && !isAbleToEditUsers && <GroupInvitationsList invitations={this.props.groups.data.invitations}/>}

          {!isAbleToEditUsers && hasGroup && <Alert color={isBlocked ? "danger" : "primary"}><FormattedMessage id="group.belongsToGroup" /> {this.props.userGroup} {isBlocked && <FormattedMessage id="group.blocked" />}</Alert>}

          {!isAbleToEditUsers && <Alert color='primary'><FormattedMessage id="group.noGroups" />
            <div className="d-block d-lg-inline mt-3 ml-lg-2"><GroupCreateButton /></div>
          </Alert>}

          {(isAbleToEditUsers || (!!groupUser && groupUser.role === 'admin')) && <GroupUsersList group={this.props.groups.data.groups[groupId]} />}

          <GroupUserCreateModal group={this.props.groups.data.groups[groupId]} isOpen={this.state.userCreateModal} toggle={() => this.toggle('userCreateModal')}/>
          </CardBody>
        </Card>
    )
  }
}

function mapStateToProps(props) {
  return {
    groups: props.groups,
    userGroup: props.user.userGroup,
    plan: props.user.userPlan.plan,
    userId: props.user.id,
    privileges: props.config.data.group.surveySharePrivilage
  }
}

export default connect(mapStateToProps, {fetchGroups, setPlanRestrictionsModals})(Group);