import React, {Component} from 'react';
import {CardBody, Col, Row} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import moment from "moment";
import "react-input-range/lib/css/index.css";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import 'react-datepicker/dist/react-datepicker.css';
import api from 'api';
import PropTypes from "prop-types";
import FormDefaultProperty from "../FormDefaultProperty";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import Switch from "components/Switch";
import InputNumber from "../../../../components/InputNumber";
import HelpModeIconTooltip from "../../../../components/HelpModeIconTooltip";
import {connect} from "react-redux";
import {setPlanRestrictionsModals} from "../../../../reducers/planRestrictionsModals/actions";

class CollectorBaseSurveys extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        guid: props.collector.guid,
        startAt: moment(props.collector.startAt).format('YYYY-MM-DD'),
        endAt: moment(props.collector.endAt).format('YYYY-MM-DD'),
        collectorResponses: props.collector.collectorResponses,
        enabledSurveyFilters: props.collector.enabledSurveyFilters,
        enabledValidations: props.collector.enabledValidations,
        enabledRating: props.collector.enabledRating,
        priorityPosition: props.collector.priorityPosition,
      },
      saving: false,
      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({...this.state, saving: true, errors: {}});
    const {data} = this.state;

    api.surveyCollector.patch({
      id: this.props.collector.id,
      surveyCollector: data,
    })
    .then(collector => {
      this.setState({...this.state, saving: false});
      this.props.handleUpdate(collector);
    })
    .catch(e => this.setState({
      errors: e.response.data.form.errors,
      saving: false
    }));
  }

  render() {
    const {data, errors} = this.state;
    const {plan} = this.props;

    return (
      <CardBody>
        <FormDefaultProperty
          errors={errors}
          collector={data}
          onPropertyChanged={(name, value) => {
            this.setState({
              data: {
                ...data,
                [name]: value,
              }
            })
          }}
        />

        <Row>
          <Col lg={6}>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="collectorBaseSurveys.label.enabledRating" /></Label>
              <Col lg={9} className="pt-2">
                <Switch checked={data.enabledRating} onChange={(enabledRating) => this.setState({data: {...data, enabledRating}})} />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        {plan.survey.collectorPriorityPosition && <Row>
          <Col lg={6}>
            <FormGroup row>
              <Label lg={3}>
                Pozycja priorytetowa
                <HelpModeIconTooltip className="ml-2">Priorytetowa pozycja ankiety w SWPANEL, która nadpisuje każde inne. Nie używać bez wyraźnej zgody szefa ;)</HelpModeIconTooltip>
              </Label>
              <Col lg={3} className="pt-2">
                <InputNumber value={data.priorityPosition} onChange={(priorityPosition) => this.setState({data: {...data, priorityPosition}})}/>
              </Col>
            </FormGroup>
          </Col>
        </Row>}


        <FormattedMessage id="_.button.save">{msg => <LaddaButton
          className="btn btn-primary btn-ladda mt-4 float-right"
          loading={this.state.saving}
          onClick={this.onSubmit}
          data-style={ZOOM_OUT}
        >
          {msg}
        </LaddaButton>}</FormattedMessage>
      </CardBody>
    )
  }
}

CollectorBaseSurveys.propTypes = {
  collector: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {})(CollectorBaseSurveys);