import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import Questions from "./Questions";
import Pages from "./Pages";
import Blocks from "./Blocks";
import Sets from "./Sets";
import {injectIntl} from "react-intl";
import QuestionDetails from "./QuestionDetails";
import ActionTypeJump from "../ActionTypeJump";
import EndingScreenFilter from "./EndingScreen/EndingScreenFilter";

class ActionTypeVisibility extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: {
        visibility: false,
      }
    };
  }

  toggleIsOpen(name){
    this.setState({
      isOpen: {
        ...this.state.isOpen,
        [name]: !this.state.isOpen[name]
      }
    })
  }

  render() {
    const {action, intl, changeable} = this.props;
    const {isOpen} = this.state;

    const defaultData = {
      questions: [],
      pages: [],
      blocks: [],
      sets: [],
      answers: [],
      baskets: [],
      rows: [],
      columns: [],
      question: null,
      endingScreen: null
    };

    return (
      <React.Fragment>
        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpen.type} toggle={() => this.toggleIsOpen('type')}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.' + action.type})}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({...action, ...defaultData, type: 'questionDetails',})}>
              <h5>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.questionDetails'})}</h5>
            </DropdownItem>

            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({...action, ...defaultData, type: 'questions'})}>
              <h5>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.questions'})}</h5>
            </DropdownItem>

            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action, ...defaultData, type: 'pages'})}>
              <h5>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.pages'})}</h5>
            </DropdownItem>

            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({...action, ...defaultData, type: 'blocks'})}>
              <h5>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.blocks'})}</h5>
            </DropdownItem>

            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({...action, ...defaultData, type: 'sets'})}>
              <h5>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.sets'})}</h5>
            </DropdownItem>

            <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({...action, ...defaultData, type: 'endingScreen'})}>
              <h5>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.endingScreen'})}</h5>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.' + action.type})} </span>}

        {['questionDetails', 'questionAnswers', 'questionRows', 'questionColumns', 'questionBaskets'].indexOf(action.type) >= 0 && <QuestionDetails action={action} handleActionChanged={action => this.props.handleActionChanged(action)} changeable={changeable}/>}
        {action.type === 'questions' && <Questions action={action} handleQuestionsChanged={questions => this.props.handleActionChanged({...action, questions})} changeable={changeable} grayedQuestionsToPositionInSurvey={this.props.grayedQuestionsToPositionInSurvey}/>}
        {action.type === 'pages' && <Pages action={action} handlePagesChanged={pages => this.props.handleActionChanged({...action, pages})} changeable={changeable}/>}
        {action.type === 'blocks' && <Blocks action={action} handleBlocksChanged={blocks => this.props.handleActionChanged({...action, blocks})} changeable={changeable}/>}
        {action.type === 'sets' && <Sets action={action} handleSetsChanged={sets => this.props.handleActionChanged({...action, sets})} changeable={changeable}/>}
        {action.type === 'endingScreen' && <EndingScreenFilter action={action} handleSetsChanged={endingScreen => this.props.handleActionChanged({...action, endingScreen})} changeable={changeable}/>}
      </React.Fragment>
    );
  }
}

ActionTypeVisibility.defaultProps = {
  changeable: true,
  grayedQuestionsToPositionInSurvey: null,
};

ActionTypeVisibility.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.oneOf(['questionDetails', 'questionAnswers', 'questionRows', 'questionColumns', 'questionBaskets', 'questions', 'pages', 'blocks', 'sets', 'endingScreen']).isRequired,
    visibility: PropTypes.bool,
  }).isRequired,
  handleActionChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired,
  grayedQuestionsToPositionInSurvey: PropTypes.number,
};

export default injectIntl(ActionTypeVisibility);