import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, Input, InputGroupAddon, Tooltip} from "reactstrap";
import classnames from "classnames";
import InputNumber from "components/InputNumber";

class OpenRexep extends Component {

  constructor(props) {
    super(props);

    this.state = {
      question: {
        openRegex: props.question.openRegex,
      },
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  toggleTooltip() {
    if(this.state.tooltip){
      setTimeout(() => {
        this.setState({
          tooltip: false
        });
      }, 1500);
    }else{
      this.setState({
        tooltip: true
      });
    }
  }

  onChange(value){
    this.setState({
      question: {
        ...this.state.question,
        openRegex: value
      }
    });
  }

  onBlur(){
    this.props.handleUpdate(this.state.question)
  }

  render() {
    let { question, tooltip } = this.state;

    return (
      <div
        id={"openRegex-" + this.props.question.id}
        className={classnames({
          "min-w-0 float-left mr-1 mb-1": true,
          "w-100 w-lg-auto input-group": true,
        })}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": !!question.openRegex,
            })}
            color="secondary"
          >
            <i className="fak fa-reg-square" />
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltip}
              target={"openRegex-" + this.props.question.id}
              toggle={() => this.toggleTooltip()}
            >
              Wyrażenie regularne w odpowiedzi wpisywanej<br />
              <a href="https://kursjs.pl/kurs/regular/regular.php" target="_blank">Przeczytaj instrukcję</a>
            </Tooltip>
          </Button>
        </InputGroupAddon>
        <Input placeholder="Przykład: ^[a-z]+$" value={question.openRegex} onChange={e => this.onChange(e.target.value)} onBlur={this.onBlur}/>
      </div>
    )
  }
}

OpenRexep.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    openRegex: PropTypes.string.isRequired
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default OpenRexep;
