import api from "api";
import * as types from './actionTypes';
import _ from 'lodash';

export function setPlans(plans) {
  return {
    type: types.SET,
    plans
  }
}

export function planDeleted(plan) {
  return {
    type: types.DELETED,
    plan
  }
}

export function planUpdated(plan) {
  return {
    type: types.UPDATED,
    plan
  }
}

export function deletePlan(plan) {
  return dispatch => {
    return api.plan.delete(plan).then(() => {
      dispatch(planDeleted(plan));
    })
  }
}

export function updatePlan(data) {
  return dispatch => {
    return api.plan.patch(data).then(plan => {
      dispatch(planUpdated(plan));
    })
  }
}

export function fetchPlans() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.plan.get.plans().then(plans => {
      let orderedPlans = {};
      _.map(plans, plan => orderedPlans[plan.id] = plan);

      dispatch(setPlans(orderedPlans));
      dispatch({type: types.SUCCESS});

      return plans;
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}
