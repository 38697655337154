import React, {Component, useEffect} from 'react';
import {Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, Label, Row} from "reactstrap";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import UserLoginsList from "./UserLoginsList";
import Switch from "../../../../components/Switch";
import moment from "moment";

function UserLogins(props) {
  const [success, setSuccess] = React.useState(null);
  const [dateFrom, setDateFrom] = React.useState("");
  const [dateTo, setDateTo] = React.useState("");
  const [search, setSearch] = React.useState("");

  return (
    <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader>
            <div className="direction-group">
              <div className="direction-left d-flex justify-content-start">
                <h2 className="float-left mr-4">Lista logowań</h2>
              </div>
              <div className="custom-search-input w-lg-100 float-right mt-1">
                <InputGroup className="p-0 mb-0">
                  <Input className="p-0" placeholder="Znajdź logowania..." onChange={(e) => setSearch(e.target.value)}/>
                  <span className="input-group-btn py-1">
                  <button className="btn" type="button"><i className="fas fa-search" /></button>
                </span>
                </InputGroup>
              </div>
              <div className="custom-search-input w-lg-100 float-right mt-1">
                <FormGroup className="d-flex flex-nowrap flex-row ml-2" style={{width: '200px'}}>
                  <Switch onChange={value => setSuccess(value)} checked={success} />
                  <Label className="ml-2">Pokaż udane logowania</Label>
                </FormGroup>
              </div>
              <div className="custom-search-input w-lg-100 float-right mt-1">
                <DatePicker
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                    dropdownMode="select"
                    placeholderText="Data logowania do"
                    selected={dateTo}
                    onChange={setDateTo}
                />
              </div>
              <div className="custom-search-input w-lg-100 float-right mt-1">
                <DatePicker
                  dateFormat="Y-MM-DD"
                  locale={localStorage.language}
                  dropdownMode="select"
                  placeholderText="Data logowania od"
                  selected={dateFrom}
                  onChange={setDateFrom}
                />
              </div>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <Row>
              <Col>
                <UserLoginsList search={search} dateFrom={dateFrom} dateTo={dateTo} success={success}/>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(UserLogins);