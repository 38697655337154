import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';

class DeleteUserAction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      modal: false,
      deleting: false,
    };

    this.deleteUser = this.deleteUser.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  deleteUser() {
    this.setState({deleting: true});

    api.admin.user.post.deleteUser([this.props.user.id])
      .then(() => {
        this.setState({
          deleting: false,
          modal: false
        });

        this.props.handleChange();
      })
      .catch(() => this.setState({deleting: false}));
  }

  render() {
    return (
      <React.Fragment>
        <span id={'deleteUser-' + this.props.user.id} className="fas fa-trash pointer ml-1" onClick={() => this.toggle('modal')}/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip}
          target={'deleteUser-' + this.props.user.id}
          toggle={() => this.toggle('tooltip')}
        >
          Usuń użytkownika
        </Tooltip>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')}>
          <ModalHeader toggle={() => this.toggle('modal')} tag="h2">Usuń użytkownika</ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            Usunięcie użytkownika spowoduje usunięcie danych osobowych z jego konta. Cała historia konta zostanie zachowana.
          </ModalBody>
          <ModalFooter>
            <div className="direction-group d-flex justify-content-end">
              <div className="direction-right">
                <FormattedMessage id="_.button.yes">{
                  (message) => <LaddaButton
                    className="btn btn-danger btn-ladda mb-0 ml-3"
                    data-style={ZOOM_OUT}
                    onClick={this.deleteUser}
                    loading={this.state.deleting}
                  >
                    {message}
                  </LaddaButton>
                }</FormattedMessage>
                <FormattedMessage id="_.button.cancel">{
                  (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggle('modal')}>{message}</Button>
                }</FormattedMessage>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

DeleteUserAction.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
};

export default DeleteUserAction;
