import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";

class ButtonOpenIsNotRequired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipIsOpen: false,
    };
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  render() {
    return (
      <a id={'openIsNotRequired-' + this.props.answer.id} className={"fas fa-exclamation-triangle ml-2" + (this.props.openIsNotRequired ? " text-primary" : "")} onClick={() => this.props.toggleOption('openIsNotRequired')}>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={this.state.tooltipIsOpen}
          target={'openIsNotRequired-' + this.props.answer.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="answer.buttonOpenIsNotRequired.button.tooltip"/>
        </Tooltip>
      </a>
    )
  }
}

ButtonOpenIsNotRequired.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  openIsNotRequired: PropTypes.bool.isRequired,
  toggleOption: PropTypes.func.isRequired
};

export default ButtonOpenIsNotRequired;