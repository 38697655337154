import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {isBrowser} from "react-device-detect";
import Switch from "../components/Switch";
import SurveyLogoMaxHeight from "./SurveyLogoMaxHeight";
import SliderValue from "../components/SliderValue";
import BgColor from "../components/BgColor";
import TextSetting from "../components/TextSetting";
import ProgressBarPlacement from "./ProgressBarPlacement";

class HeaderAndFooter extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(){
    if(!document.getElementById('color-picker')){
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  render() {
    const {isOpen} = this.state;

    const props = {
      ownStyles: this.props.ownStyles,
      ownStylesDefault: this.props.ownStylesDefault,
      handlerUpdate: this.props.handlerUpdate,
    };

    const showSurveyName = props.ownStyles['$survey-name-display'] ? props.ownStyles['$survey-name-display'] !== 'none' : false;

    return (
      <Dropdown toggle={this.toggle} isOpen={isOpen} inNavbar>
        <DropdownToggle className="py-3"><i className="fas fa-grip-lines" /></DropdownToggle>
        {isOpen && <DropdownMenu className="p-2" style={{
          height: isBrowser ? 'auto' : window.innerHeight - 100,
          width: isBrowser ? 650 : window.innerWidth,
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}>
          <div className="d-block">
            <h3 className="text-center">Nagłówek i stopka</h3>
            <Button color="" onClick={(e) => this.toggle(e)} className="ml-auto px-3" style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}><i className="fas fa-times mt-1"/> </Button>
          </div>
          <hr className="my-0 mb-3"/>
          <SliderValue
            {...props}
            keyName="$survey-header-borderRadius"
            label="Zaokrąglenie rogów w nagłówku"
            minValue={0}
            maxValue={25}
            stepValue={1}
          />
          <hr className="my-0"/>
          <Switch
            {...props}
            keyName="$survey-name-display"
            label="Pokaż nazwę ankiety"
            uncheckedValue={this.props.ownStylesDefault["$survey-name-display"]}
            checkedValue="block"
          />
          {showSurveyName && <React.Fragment>
            <hr className="my-0"/>
            <BgColor
              {...props}
              keyName="$survey-name-color"
              label="Kolor nazwy ankiety"
            /></React.Fragment>}
          <hr className="my-0"/>
          <SurveyLogoMaxHeight {...props}/>
          <hr className="my-0"/>
          <Switch
            {...props}
            keyName="$survey-progress-display"
            label="Pokaż pasek postępu"
            uncheckedValue="none"
            checkedValue={this.props.ownStylesDefault["$survey-progress-display"]}
          />
          <Switch
              {...props}
              keyName="$survey-progress-value-display"
              label="Pokaż postęp procentowy paska postępu"
              uncheckedValue={this.props.ownStylesDefault["$survey-progress-value-display"]}
              checkedValue="inline-block"
          />
          <hr className="my-0"/>
          <ProgressBarPlacement
            {...props}
          />
          <hr className="my-0"/>
          <BgColor
            {...props}
            keyName="$survey-progress-bgColor"
            label="Kolor paska postępu"
          />
          <hr className="my-0"/>
          <TextSetting
            handlerUpdate={props.handlerUpdate}
            label="Tekst w nagłówku"
            property='headerText'
          />
          <hr className="my-0"/>
          <TextSetting
            handlerUpdate={props.handlerUpdate}
            label="Tekst w stopce"
            property="footerText"
          />
        </DropdownMenu>}
      </Dropdown>
    );
  }
}

HeaderAndFooter.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  ownStyles: PropTypes.object,
  ownStylesDefault: PropTypes.object,
};

export default HeaderAndFooter;
