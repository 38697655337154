import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import api from "../../api";
import useAfterUpdateEffect from "../../utils/useAfterUpdateEffect";

const SurveySelectNew = props => {
  const [search, setSearch] = React.useState('');
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(props.isMulti ? [] : null);

  useAfterUpdateEffect(() => {
    props.onChange(value.map(v => v.value))
  }, [value])

  const fetchData = (s, callback) => {
    api.surveysCategories.surveys({
      ...props.filters,
      query: search,
      activity: "paused",
    })
      .then(data => {
        let options = data.map(survey => {
          return {
            value: survey.id,
            label: survey.nameWork || survey.name,
          }
        });

        setData(data)
        callback(options);
      })
  }

  const handleInputChange = (search) => setSearch(search)

  return (
    <AsyncSelect
      closeMenuOnSelect={!props.isMulti}
      isMulti={props.isMulti}
      isClearable={true}
      placeholder={props.placeholder}
      loadingMessage={() => <span>Trwa wyszukiwanie <i className="fas fa-spin fa-spinner ml-2" /></span>}
      noOptionsMessage={() => <span><span className="ml-2">Nie znaleziono</span></span>}
      value={value}
      loadOptions={fetchData}
      onInputChange={handleInputChange}
      onChange={option => {
        if(props.isMulti){
          setValue(option)
        }
      }}
    />
  );
};

SurveySelectNew.defaultProps = {
  isMulti: false,
  placeholder: 'Wybierz ankietę',
  filters: {},
};

SurveySelectNew.propTypes = {
  filters: PropTypes.object,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SurveySelectNew;