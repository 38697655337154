import api from "api";

export const SET = 'Tags/SET';

export function setTags(tags) {
  return {
    type: SET,
    tags
  }
}

export function fetchTags() {
  return dispatch => {
    return api.tag.get.tags().then(tags => dispatch(setTags(tags)));
  }
}
