import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Card, CardHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import HeaderActions from "./components/HeaderActions";
import Blocks from "./components/Blocks";
import {connect} from "react-redux";
import Settings from './components/Settings';
import {toggleSetCard} from "scenes/Survey/Structure/actions";
import HelpModeIconModal from "components/HelpModeIconModal";
import Name from "./components/Name";
import {isMobile} from "react-device-detect";

class Set extends Component {

  constructor(props) {
    super(props);
    this.state = {
      optionsOpen: false,
      headerActions: true,
      hovered: false,
    };
    this.headerActions = this.headerActions.bind(this);
    this.onCardHeaderClick = this.onCardHeaderClick.bind(this);
    this.setHovered = this.setHovered.bind(this);
  }

  toggleOptions() {
    this.setState({
      optionsOpen: !this.state.optionsOpen
    });
  }

  headerActions(bool){
    this.setState({
      headerActions: bool
    })
  }

  onCardHeaderClick(e) {
    if (e.target.getAttribute('data-toggleable') && this.props.hideInStructure !== 'none')
      this.props.toggleSetCard(this.props.set.id);
  }

  setHovered(hovered) {
    this.setState({hovered});
  }

  render() {
    const {set, closedSetCards, setsCount, blocksCount} = this.props;
    const {headerActions, optionsOpen, hovered} = this.state;
    const showHeader = blocksCount > 1 || setsCount > 1;
    const gripStyle = hovered ? {opacity: 1} : {opacity: 0};

    const setInfo = <React.Fragment>
      <HelpModeIconModal className="mx-2" modalHeader="Zestawy">
        <p>Ankieta składa się z zestawów, które wypełnione są blokami</p>
      </HelpModeIconModal>
    </React.Fragment>;

    return (
      <React.Fragment>
        <Card data-id={set.id} data-type="setCard" role="set" id={`set-${set.id}`} className="border-l orange">
          {showHeader && <CardHeader className="py-md-2" onClick={this.onCardHeaderClick} data-toggleable={true} onMouseEnter={() => this.setHovered(true)} onMouseLeave={() => this.setHovered(false)}>
            <div className="d-flex p-0">
              <div className="float-left p-0 pt-3 pt-md-1 d-flex flex-grow-1" data-toggleable={true} data-toggle="collapse" data-target="#collapse2">
                <a className="handler fas fa-grip-vertical dragg float-left" style={gripStyle}/>
                {setInfo}
                <Name positionInSurvey={set.positionInSurvey} set={set}/>
                {(!isMobile || !set.name) && <small className="ml-2" style={{minWidth: 'fit-content'}} data-toggleable={true}>(<FormattedMessage id="sets.set.cardHeader.countBlocks">{msg => <React.Fragment>{msg}: {set.blockPages.length}</React.Fragment>}</FormattedMessage>)</small>}
              </div>

              {headerActions && <HeaderActions
                set={set}
                optionsOpen={optionsOpen}
                handleToggleOptions={() => this.toggleOptions()}
              />}
            </div>

          </CardHeader>}
          {this.state.optionsOpen && <Settings set={set} />}
        </Card>
        {!closedSetCards.includes(set.id) && <Blocks set={set}/>}
      </React.Fragment>
    )
  }
}

Set.propTypes = {
  set: PropTypes.shape({
    id: PropTypes.number.isRequired,
    blockPages: PropTypes.array.isRequired,
    positionInSurvey: PropTypes.number.isRequired,
  }).isRequired,
  closedSetCards: PropTypes.array.isRequired,
};

function mapStateToProps(state, props) {
  return {
    hideInStructure: state.survey.structure.hideInStructure,
    closedSetCards: state.survey.structure.closedSetCards,
    setsCount: Object.keys(state.survey.structure.data.sets).length,
    blocksCount: state.survey.structure.data.sets[props.set.id].blockPages.length,
  }
}

export default connect(mapStateToProps, {toggleSetCard})(Set);