import React, {PureComponent} from 'react';
import PropTypes from "prop-types";
import AsyncSelect from 'react-select/lib/Async';
import api from "api";

class CityDistrictsSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mapping: false,
      loading: false,
      values: [],
      searchTimeout: null,
    };

    this.fetchData = this.fetchData.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(values){
    this.setState({
      values,
    }, () => {
      this.props.onChange(values);
    })
  }

  getLabel(city){
    return {
      value: city.id,
      label: <div className="w-100">
        <p className="mb-0">{city.powiat}</p>
        <small className="mb-0 text-muted">woj. {city.wojewodztwo}</small>
      </div>,
    }
  }

  componentDidMount() {
    if(!_.isEmpty(this.props.values)){
      this.setState({
        mapping: true
      });

      api.city.districts.post.search({
        search: '',
        page: 1,
        pageSize: 999999,
        filters: {
          cities: this.props.values.join(',')
        }
      })
      .then(data => {
        this.setState({
          mapping: false,
          values: data.cities.map(city => this.getLabel(city))
        })
      })
      .catch(() => {
        this.setState({
          mapping: false,
        });
      });
    }
  }

  fetchData(search, callback){
    clearTimeout(this.state.searchTimeout);

    this.setState({
      searchTimeout: setTimeout(() => {
        api.city.districts.post.search({
          search,
          page: 1,
          pageSize: 20,
        })
        .then(data => {
          callback(data.cities.map(city => this.getLabel(city)));
        });
      }, 1000)
    });
  }

  render() {
    const {loading, values, mapping} = this.state;

    return <AsyncSelect
      isMulti
      placeholder="Wybierz powiaty"
      isLoading={mapping || loading}
      cacheOptions
      loadOptions={this.fetchData}
      onChange={values => this.onChange(values)}
      value={values}
    />
  }
}

CityDistrictsSelect.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func,
};

export default CityDistrictsSelect;