import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {deleteFilterQuestion} from "../../../../reducer/filtersQuestion/actions";
import PropTypes from "prop-types";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import AnalysisFilteringAvailable from "components/PlanRestrictionsModals/modals/AnalysisFilteringAvailable";

class FiltersQuestionListOptions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (!this.props.analysisPlan.filtering) {
      this.props.setPlanRestrictionsModals(AnalysisFilteringAvailable.getName());
      return;
    }

    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  deleteFilter(id){
    this.props.onDeleting(id);
    this.props.deleteFilterQuestion({id})
      .then(() => {
        this.props.onDeleted();
      }).catch(() => {
      this.props.onDeleting(false);
    });
  }

  render() {
    const {dropdownOpen} = this.state;
    const {id} = this.props;

    return (
      <Dropdown toggle={this.toggle} isOpen={dropdownOpen} className="ml-auto mr-1">
        <DropdownToggle color="link" size="sm" className="px-0 pr-2 mb-0"><i className="fas fa-ellipsis-v" /></DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag="a" onClick={() => this.props.onEdit(id)}><p><i className="fas fa-edit"/></p> <FormattedMessage tagName="h5" id="_.button.edit"/></DropdownItem>
          <DropdownItem tag="a" onClick={() => this.deleteFilter(id)}><p><i className="fas fa-trash"/></p> <FormattedMessage tagName="h5" id="_.button.delete"/></DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

FiltersQuestionListOptions.propTypes = {
  id: PropTypes.number.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onDeleting: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    analysisPlan: state.user.userPlan.plan.analysis
  }
}

export default connect(mapStateToProps, {deleteFilterQuestion, setPlanRestrictionsModals})(FiltersQuestionListOptions);