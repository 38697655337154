import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import SelectBlocks from "../../../SelectBlocks";

class Block extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalBlock: props.block,
    };

    this.toggleModalBlock = this.toggleModalBlock.bind(this);
  }

  toggleModalBlock(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {block, blocks, changeable} = this.props;
    const {modalOpen, modalBlock} = this.state;
    const p = blocks.find(p => p.id === block);

    if (!changeable) {
      return p ? 'Blok ' + p.positionInSurvey : null;
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalBlock} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalBlock}>Wybierz blok</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <SelectBlocks isMulti={false} value={modalBlock} onChange={block => {
          this.setState({
            modalBlock: block,
            modalOpen: false,
          }, () => this.props.handleBlockChanged(block));
        }} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleBlockChanged(modalBlock);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={block ? 'secondary' : 'warning'} className="mx-1 pointer" onClick={this.toggleModalBlock}>{p ? ('Blok ' + p.positionInSurvey) : 'Wybierz blok...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

Block.defaultProps = {
  changeable: true
};

Block.propTypes = {
  block: PropTypes.number,
  handleBlockChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  let blocks = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      positionInSurvey++;

      let b = state.survey.structure.data.blocks[blockId];
      b.positionInSurvey = positionInSurvey;
      blocks.push(b);
    });
  });

  return {
    blocks
  }
}

export default connect(mapStateToProps)(Block);