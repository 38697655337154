import React, {Component} from 'react';
import api from "api";
import ApplicationTable from "../../components/Application/ApplicationTable";
import UserAvatar from "../../components/UserAvatar";
import moment from "moment";
import Tooltip from "../../componentsReusable/Tooltip";
import parseNotification from "../../utils/parseNotification";
import Icon from "../../componentsReusable/Icon";
import qs from "query-string";
import {NotificationAvatar} from "../../components/Header/components/Notifications/Notifications";

class NotificationsTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  render() {
    const columns = [
      {
        id: 'updated_at',
        Header: 'Data',
        width: 150,
        accessor: row => {
          return <React.Fragment>
            <span id={'id-' + row.id}>{moment(row.updated_at).fromNow()}</span>
            <Tooltip msg={row.updated_at} id={'id-' + row.id} />
          </React.Fragment>
        }
      },
      {
        id: 'createdBy',
        Header: 'Użytkownik',
        width: 100,
        accessor: row => <div className="notification-avatar"><NotificationAvatar notification={row} /></div>
      },
      {
        id: 'content',
        Header: <div style={{ textAlign: 'center' }}>Treść powiadomienia</div>,
        headerAlight: 'left',
        accessor: row => <div id={`nu-${row.id}`} style={{ textAlign: 'left' }}>
          {row.read === '0' && <Icon className={"fas fa-circle text-warning mr-1"} tooltip={"Nowe powiadomienie"}/>}
          <span className="notification-content">{parseNotification(row)}</span>
        </div>
      },
    ];

    return <React.Fragment>
      <ApplicationTable
        ref={this.tableRef}
        columns={columns}
        pageSize={250}
        getTrGroupProps={(state, rowInfo) => {
          const params = qs.parse(window.location.search);

          return {
            className: rowInfo.row._original.id == params.nid ? 'notification-selected' : ''
          };
        }}
        fetchData={api.notifications.search}
      />
    </React.Fragment>;
  }
}

export default NotificationsTable;
