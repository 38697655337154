import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Table} from "reactstrap";
import striptags from "striptags";
import {FormattedMessage} from "react-intl";


class AdvancedCrossTable extends Component {
  render() {
    const {advanced, data} = this.props;

    if(!data){
      return <p>Brak danych</p>
    }

    return data.tables.map(tableData => {
      let total = {};

      return <div style={{overflowX: 'scroll'}}>
        <Table bordered hover>
          <thead>
          <tr>
            <th></th>
            <th colSpan={Object.keys(tableData['data'][Object.keys(tableData['data'])[0]]['data']).length * 2} className="text-center">
              {advanced.crossType === 'question'
                ? <div dangerouslySetInnerHTML={{__html: striptags(advanced.questionCrossed.content)}} />
                : <div><FormattedMessage id={'analysis.advancedCrossTable.crossType.' + advanced.crossType} /></div>
              }
            </th>
            <th rowSpan={3} className="text-center align-middle">Razem</th>
          </tr>
          <tr>
            <th className="text-center" rowSpan={2}><div dangerouslySetInnerHTML={{__html: tableData.title ? striptags(tableData.title) : striptags(advanced.question.content)}} /></th>
            {_.map(tableData['data'][Object.keys(tableData['data'])[0]]['data'], (value, key) => {
              return <th className="text-center" colSpan={2} key={key.toString()}><div dangerouslySetInnerHTML={{__html: key}} /></th>
            })}
          </tr>
          <tr>
            {_.map(tableData['data'][Object.keys(tableData['data'])[0]]['data'], (value, key) => {
              return <React.Fragment>
                <th className="text-center" key={key.toString()}>Liczebność</th>
                <th className="text-center" key={key.toString()}>%</th>
              </React.Fragment>
            })}
          </tr>
          </thead>
          <tbody>
          {_.map(tableData['data'], (values, key) => {
            return <tr>
              <td><div dangerouslySetInnerHTML={{__html: key}} /></td>
              {_.map(values['data'], (value, columnKey) => {
                if(!total[columnKey]){
                  total[columnKey] = 0;
                }
                total[columnKey] += parseInt(value);
                return <React.Fragment>
                  <td key={columnKey.toString()} className="text-center">{value}</td>
                  <td key={columnKey.toString()} className="text-center">{value > 0 ? ((value / tableData['totalCrossed'][columnKey]) * 100).toFixed(1) : 0}%</td>
                </React.Fragment>
              })}
              <td className="text-center">{Object.values(values['data']).reduce((a, b) => parseInt(a) + parseInt(b), 0)}</td>
            </tr>
          })}
          </tbody>
          <tfoot>
          <tr className="bg-dark font-weight-bold text-white">
            <td>RAZEM</td>
            {_.map(total, (value, k) => {
              return <React.Fragment>
                <td className="text-center" key={k.toString()}>{value}</td>
                <td className="text-center" key={k.toString()}>{value > 0 ? ((value / Object.values(total).reduce((a, b) => parseInt(a) + parseInt(b), 0)) * 100).toFixed(1) : 0}%</td>
              </React.Fragment>
            })}
            <td className="text-center">{Object.values(total).reduce((a, b) => parseInt(a) + parseInt(b), 0)}</td>
          </tr>
          </tfoot>
        </Table>
      </div>
    });
  }
}

AdvancedCrossTable.propTypes = {
  advanced: PropTypes.shape({
    question: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }).isRequired,
    questionCrossed: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AdvancedCrossTable;