import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import Select from "react-select";
import _ from "lodash";

class SelectBlocks extends Component {
  constructor(props){
    super(props);

    this.select = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  onChange(e){
    const {isMulti} = this.props;

    if(isMulti){
      this.select.current.setState({isOpen: true, menuIsOpen: true});
    }

    if(isMulti){
      this.props.onChange(e.map(obj => obj.value))
    }else{
      this.props.onChange(e.value);
    }
  }

  render() {
    const {blocks, value, isMulti} = this.props;

    return (
      <Select
        placeholder={isMulti ? "Wybierz bloki" : "Wybierz blok"}
        ref={this.select}
        value={value && (!isMulti ?
            {
              label: 'Blok ' + (blocks.find(b => b.id === value).positionInSurvey+1),
              value
            }
          :
          value.map(bId => {
            return {
              label: 'Blok ' + (blocks.find(b => b.id === bId).positionInSurvey+1),
              value: bId,
            }
          })
        )}
        isOpen={false}
        isMulti={isMulti}
        onChange={this.onChange}
        options={blocks.map(block => {
          return {
            label: 'Blok ' + (block.positionInSurvey+1),
            value: block.id,
          }
        })}
      />
    );
  }
}

SelectBlocks.defaultProps = {
  isMulti: true,
};

SelectBlocks.propTypes = {
  isMulti: PropTypes.bool,
  blocks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    positionInSurvey: PropTypes.number.isRequired,
  }).isRequired,).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number,]),
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let blocks = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    let set = state.survey.structure.data.sets[setId];

    _.each(set.blockPages, blockId => {
      let block = state.survey.structure.data.blocks[blockId];
      block.positionInSurvey = positionInSurvey;
      blocks.push(block);
      positionInSurvey++;
    });
  });

  return {
    blocks,
  }
}

export default connect(mapStateToProps)(SelectBlocks);