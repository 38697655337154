import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Input, InputGroup, InputGroupAddon, Button, ButtonGroup, Col} from 'reactstrap';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {FormattedMessage} from "react-intl";
import api from 'api';
import HelpModeIconModal from "components/HelpModeIconModal";
import LoadingSpinner from "../../../../components/LoadingSpinner";

class CollectorName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      gettingName: false,
      errors: {},
      data: {
        name: this.props.collector.name || '',
      }
    };

    this.onChange = this.onChange.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.getCollectorName = this.getCollectorName.bind(this);
    this.getNameAutogenerated = this.getNameAutogenerated.bind(this);
  }

  getNameAutogenerated(){
    const {data} = this.state;

    this.setState({
      gettingName: true,
    });

    api.surveyCollector.panel.get.nameAutogenerated(this.props.collector.id, {})
    .then(data => {
      this.setState({
        gettingName: false,
        data: {
          ...data,
          name: data.name,
        }
      }, () => this.props.handleUpdate(this.state.data.name));
    })
    .catch(res => {
      this.setState({
        gettingName: false,
      });
    })
  }

  onChange(e) {
    this.setState({
      data: {
        name: e.target.value
      }
    });
  }

  getCollectorName() {
    const {gettingName} = this.state;

    if(gettingName){
      return <LoadingSpinner classNames="my-0" width={15} />
    }

    const {collector} = this.props;
    return this.state.data.name || <FormattedMessage tagName="h2" id={`_.surveyCollector.${collector.type}.name`}>
      {msg => msg + ' ' + collector.position}
    </FormattedMessage>;
  }

  toggleEditing(){
    this.setState({
      editing: !this.state.editing
    });
  }

  cancelEditing(){
    this.setState({
      editing: false,
      errors: {},
      data: {
        name: this.props.collector.name || ''
      }
    });
  }

  render() {
    const {editing, data, errors, gettingName} = this.state;
    const {showButtons} = this.props;
    const TagName = this.props.tagName;

    return (
      <div className="d-flex align-items-center w-100 mr-3">
        {editing ?
          <Input className="w-100" value={data.name} autoFocus={true} onChange={this.onChange} onBlur={() => this.setState({editing: false}, () => this.props.handleUpdate(this.state.data.name))}/>
          :
          <TagName className="d-inline-block mb-0 pointer" onClick={this.toggleEditing} >
            {this.getCollectorName()}
            {(!gettingName && showButtons) && <i className="fas fa-edit pointer ml-2" />}
          </TagName>
        }
        {(showButtons && !gettingName && !editing) && <i className="fas fa-download pointer" onClick={() => this.getNameAutogenerated()} />}
        {showButtons && errors.name && <p className="help-block text-danger">{errors.name}</p>}
        {showButtons && <HelpModeIconModal className="ml-auto" modalHeader={<FormattedMessage id={'_.surveyCollector.' + this.props.collector.type + '.name'} />}><FormattedMessage id={'_.surveyCollector.' + this.props.collector.type + '.desc'}/></HelpModeIconModal>}
      </div>
    );
  }
}

CollectorName.defaultProps = {
  showButtons: true,
  tagName: 'h2',
};

CollectorName.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  showButtons: PropTypes.bool.isRequired,
  tagName: PropTypes.string.isRequired,
};

export default CollectorName;
