import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import striptags from "striptags";
import {FormGroup, Table} from "reactstrap";
import {connect} from "react-redux";

class Matrix extends Component {

  constructor(props) {
    super(props);
    this.changeAnswers = this.changeAnswers.bind(this);
  }

  changeAnswers(rowId, columnId) {
    let answers = this.props.filter.answers.find(answer => answer.row === rowId && answer.column === columnId)
      ? this.props.filter.answers.filter((answer) => !(answer.row === rowId && answer.column === columnId))
      : this.props.filter.answers.concat([{row: rowId, column: columnId}]);
    this.props.handleChange(answers);
  }

  render() {
    const {questions, questionId, rows, columns} = this.props;
    const question = questions.find(q => q.id === questionId);

    return (
      <FormGroup style={{ overflowX: 'auto' }}>
        <Table bordered>
          <thead>
          <tr>
            <th/>
            {_.map(questions.find(q => q.id === questionId).columns, columnId => <th key={columnId} className="text-center">{striptags(question.columnsImage ? columns[columnId].description : columns[columnId].content)}</th>)}
          </tr>
          </thead>
          <tbody>
          {_.map(questions.find(q => q.id === questionId).rows, rowId =>
            <tr key={rowId}>
              <th>{striptags(question.rowsImage ? rows[rowId].description : rows[rowId].content)}</th>
              {_.map(questions.find(q => q.id === questionId).columns, columnId => <th className={this.props.filter.answers.find(answer => answer.row === rowId && answer.column === columnId) && "bg-info"} key={columnId} onClick={() => this.changeAnswers(rowId, columnId)}/>)}
            </tr>
          )}
          </tbody>
        </Table>
      </FormGroup>
    )
  }
}

Matrix.propTypes = {
  questionId: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  rows: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    rows: state.survey.analysis.data.rows,
    columns: state.survey.analysis.data.columns,
  }
}

export default connect(mapStateToProps)(Matrix);
