import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import SelectQuestions from "../../../SelectQuestions";

class Question extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalQuestion: props.question,
    };

    this.toggleModalQuestion = this.toggleModalQuestion.bind(this);
  }

  toggleModalQuestion(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {question, questions, changeable, enabledTypes} = this.props;
    const {modalOpen, modalQuestion} = this.state;
    const q = questions.find(q => q.id === question);

    if (!changeable) {
      const tooltipId = "questionTooltipId-" + Math.random().toString(36).substring(7);
      return q ? <React.Fragment>
        <span className="pointer" id={tooltipId}>P. {q.positionInSurvey} </span>
        <UncontrolledTooltip target={tooltipId}>
          <span className="img-30" dangerouslySetInnerHTML={{__html: q.stripedContent}}/>
        </UncontrolledTooltip>
      </React.Fragment> : null;
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalQuestion} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalQuestion}>Wybierz pytanie</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <SelectQuestions enabledTypes={enabledTypes} isMulti={false} value={modalQuestion} onChange={question => {
          this.setState({
            modalQuestion: question,
            modalOpen: false,
          }, () => this.props.handleQuestionChanged(question));
        }} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleQuestionChanged(modalQuestion);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={question ? 'secondary' : 'warning'} className="mx-1 d-inline-flex flex-wrap pointer" onClick={this.toggleModalQuestion}>
          <span className="question-badge ws-nowrap img-20 ch-50 d-inline-block" dangerouslySetInnerHTML={{__html: q ? ('P' + q.positionInSurvey + '. ' + q.stripedContent) : 'Wybierz pytanie...'}}/>
        </Badge>

        {modal}
      </React.Fragment>
    );
  }
}

Question.defaultProps = {
  changeable: true,
  enabledTypes: []
};

Question.propTypes = {
  question: PropTypes.number,
  handleQuestionChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    questions
  }
}

export default connect(mapStateToProps)(Question);