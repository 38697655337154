import update from 'immutability-helper';

import {SET, ADDED, UPDATED, DELETED, LEVEL_ADDED} from './actions';
import * as attributeLevelsActions from "../attributes/levels/actions";

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.attributes;

    case ADDED:
      return {
        ...state,
        [action.attribute.id]: action.attribute
      };

    case UPDATED:
      return update(state, {
        [action.attribute.id]: {
          $set: action.attribute
        }
      });

    case DELETED:
      return update(state, {
        $unset: [action.attribute.id]
      });

    case attributeLevelsActions.DELETED:
      return update(state, {
        [action.level.attribute.id]: {
          levels: {
            $set: state[action.level.attribute.id].levels.filter(id => id !== action.level.id)
          }
        }
      });

    case LEVEL_ADDED:
      console.log('action', action);
      let levels = state[action.level.attribute.id].levels;
      levels.push(action.level.id);
      return update(state, {
        [action.level.attribute.id]: {
          levels: {
            $set: levels,
          }
        }
      });

    default:
      return state;
  }
};