import { SET } from './actions';
import * as userActions from "reducers/user/reducer";

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.templates;

    case userActions.USER_LOGGED_OUT:
      return initialState;

    default:
      return state;
  }
};