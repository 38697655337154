import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button, InputGroupAddon} from "reactstrap";
import classnames from "classnames";
import InputNumber from "components/InputNumber";
import Tooltip from "../../../../../../../components/Tooltip";

class RandSets extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        randSets: props.randSets,
      },
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(value){
    if(!value || value < 0){
      value = 0;
    }

    this.setState({
      data: {
        ...this.state.data,
        randSets: value
      }
    }, () => {
      this.props.handleUpdate(this.state.data)
    });
  }

  render() {
    let { data } = this.state;

    return (
      <div
        id={"randSets"}
        className={classnames({
          "min-w-0 float-left mr-1 mb-1": true,
          "w-auto": !data.randSets,
          "w-100 w-lg-auto input-group": data.randSets
        })}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": data.randSets > 0,
            })}
            color="secondary"
            onClick={() => {
              if(data.randSets === 0){
                this.onChange(1)
              }else{
                this.onChange(0)
              }
            }}
          >
            <i className="fa-solid fa-dice" />
          </Button>
        </InputGroupAddon>
        {data.randSets > 0 && <InputNumber value={data.randSets} min={0} onChange={(value) => this.onChange(value)}/>}
        <Tooltip id={'randSets'} msg={'Liczba zestawów do wylosowania. 0 = wszystkie zestawy będą pokazane w ankiecie'} />
      </div>
    )
  }
}

RandSets.propTypes = {
  randSets: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default RandSets;
