import React, {Component} from 'react';
import {Form, FormGroup, Label, Input, Col} from "reactstrap";
import {find} from "lodash";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import Select from "react-select";

class GroupUserForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      firstname: props.user ? props.user.user.firstname : '',
      email: props.user ? props.user.email : '',
      password: '',
      privilageSurveyShareDefault: props.user ? props.user.privilageSurveyShareDefault : props.surveySharePrivilages[Object.keys(props.surveySharePrivilages)[0]].id
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    const {errors, surveySharePrivilages} = this.props;

    let privilageDefaultValue = {
      value: surveySharePrivilages[Object.keys(surveySharePrivilages)[0]].id,
      label: surveySharePrivilages[Object.keys(surveySharePrivilages)[0]].name
    };

    return (
      <Form onSubmit={e => this.props.onSubmit(e, this.state)}>
        <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right"><FormattedMessage id="groupUserForm.label.privilageSurveyShareDefault"/>:</Label>
          <Col lg={8}>
            <Select
              value={this.state.privilageSurveyShareDefault ? {
                value: this.state.privilageSurveyShareDefault,
                label: _.find(surveySharePrivilages, privilage => privilage.id === this.state.privilageSurveyShareDefault).name
              } : privilageDefaultValue}
              onChange={value => this.setState({privilageSurveyShareDefault: value.value})}
              options={_.map(surveySharePrivilages, privilage => {
                return {
                  value: privilage.id,
                  label: privilage.name,
                }
              })}
              className={errors.privilageSurveyShareDefault && "border border-danger"}
            />
          </Col>
        </FormGroup>
        {this.state.privilageSurveyShareDefault && <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right d-flex align-items-center justify-content-end">Opis uprawnień:</Label>
          <Col lg={8} className="d-flex align-items-center py-4">
            <span>{find(surveySharePrivilages, p => p.id === this.state.privilageSurveyShareDefault).description}</span>
          </Col>
        </FormGroup>}
        <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right"><FormattedMessage id="respondentBaseForm.label.firstname"/>:</Label>
          <Col lg={8}>
            <Input type="firstname" name="firstname" id="firstname" invalid={!!errors.firstname} value={this.state.firstname} onChange={this.onChange} disabled={!!this.props.user}/>
            {errors.firstname && <div className="invalid-feedback">{errors.firstname.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right"><FormattedMessage id="respondentBaseForm.label.email"/>:</Label>
          <Col lg={8}>
            <Input type="email" name="email" id="email" invalid={!!errors.email} value={this.state.email} onChange={this.onChange} disabled={!!this.props.user}/>
            {errors.email && <div className="invalid-feedback">{errors.email.join('; ')}</div>}
          </Col>
        </FormGroup>
        {!this.props.user && <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right"><FormattedMessage id="respondentBaseForm.label.password"/>:</Label>
          <Col lg={8}>
            <Input type="password" name="password" id="password" invalid={!!errors.password} value={this.state.password} onChange={this.onChange}/>
            {errors.password && <div className="invalid-feedback">{errors.password.join('; ')}</div>}
          </Col>
        </FormGroup>}
      </Form>
    )
  }
}

GroupUserForm.propTypes = {
  surveySharePrivilages: PropTypes.shape(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default GroupUserForm;