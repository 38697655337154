import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import GroupUserForm from './GroupUserForm';
import {updateUser} from '../actions';

class GroupUserEditModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      errors: {}
    };

    this.groupUserForm = React.createRef();
  }

  updateUser(e, data){
    this.setState({
      editing: true,
      errors: {}
    });

    this.props.updateUser({
      groupId: this.props.group.id,
      groupUserId: this.props.user.id,
      data: {
        user: this.props.user.userId,
        privilageSurveyShareDefault: data.privilageSurveyShareDefault,
        cint: data.cint,
      }
    })
    .then(() => {
      this.setState({
        editing: false,
      }, () => this.props.toggleModal());
    })
    .catch(e => {
      this.setState({
        editing: false,
        errors: e.response.data.form.errors.user || e.response.data.form.errors
      });
    });
  }

  render() {
    const { editing, errors } = this.state;
    const {surveySharePrivilages} = this.props;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="lg">
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="groupUserEditModal.modalHeader" /></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
        <GroupUserForm onSubmit={this.updateUser} errors={errors} user={this.props.user} ref={this.groupUserForm} surveySharePrivilages={surveySharePrivilages}/>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={editing}
                  data-style={ZOOM_OUT}
                  onClick={e => this.updateUser(e, this.groupUserForm.current.state)}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggleModal}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

GroupUserEditModal.propTypes = {
  user: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    surveySharePrivilages: state.config.data.group.surveySharePrivilage
  }
}

export default connect(mapStateToProps, { updateUser }, null, {forwardRef: true})(GroupUserEditModal);