import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Table} from "reactstrap";
import Checkbox from "components/Checkbox";

class TableDifferential extends PureComponent {
  toggleDetails(row, point){
    let {details} = this.props;
    const detail = details.find(detail => detail.row === row);

    if(detail){
      detail.scalePoints.includes(point) ? detail.scalePoints.splice(detail.scalePoints.indexOf(point), 1) : detail.scalePoints.push(point);
    }else{
      details.push({row, scalePoints: [point]});
    }

    this.props.handleDetailsChanged(details);
  }

  toggleDetailsRow(row){
    let {details, questionObject} = this.props;
    const scalePoints = [...Array(questionObject.scaleSize + 1).keys()].slice(1);
    const detail = details.find(detail => detail.row === row);

    if(detail){
      detail.scalePoints = detail.scalePoints.length === scalePoints.length ? [] : scalePoints;
    }else{
      details.push(row, scalePoints)
    }

    this.props.handleDetailsChanged(details)
  }

  toggleDetailsPoint(point){
    const {rows} = this.props;
    let {details} = this.props;

    _.each(rows, row => {
      const detail = details.find(detail => detail.row === row.id);

      if (detail) {
        detail.scalePoints.includes(point) ? detail.scalePoints.splice(detail.scalePoints.indexOf(point), 1) : detail.scalePoints.push(point);
      } else {
        details.push({row: row.id, scalePoints: [point]});
      }
    });

    this.props.handleDetailsChanged(details)
  }

  render() {
    const {rows, details, question, questionObject} = this.props;
    const scalePoints = [...Array(questionObject.scaleSize + 1).keys()].slice(1);

    return (
      <Table className="border mt-3">
        <thead>
          <tr>
            <th />
            {scalePoints.map(point => <th key={point} className="text-center" onClick={() => this.toggleDetailsPoint(point)}>{point}</th>)}
            <th />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rKey) => {
            return <tr key={rKey}>
              <td onClick={() => this.toggleDetailsRow(row.id)}>
                {row.position+1}. {questionObject.rowsImage ? <img src={row.leftContent} className="img-100"/> : <span className="question-badge img-30" dangerouslySetInnerHTML={{__html: row.stripedLeftContent}}/>}
              </td>

              {scalePoints.map(point => {
                const selected = details.filter(d => d.row === row.id && d.scalePoints.includes(point)).length > 0;

                return <td className="text-center" key={point} onClick={() => this.toggleDetails(row.id, point)}>
                  <Checkbox id={'checkbox-' + question + '-' + row.id + '-' + point} checked={selected} onClick={() => {}} tooltip={false}/>
                </td>
              })}

              <td onClick={() => this.toggleDetailsRow(row.id)}>
                {questionObject.rowsImage ? <img src={row.rightContent} className="img-100"/> : <span className="question-badge img-30" dangerouslySetInnerHTML={{__html: row.stripedRightContent}}/>}
              </td>
            </tr>
          })}
        </tbody>
      </Table>
    );
  }
}

TableDifferential.propTypes = {
  question: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    stripedLeftContent: PropTypes.string.isRequired,
    stripedRightContent: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  details: PropTypes.arrayOf(PropTypes.shape({
    row: PropTypes.number.isRequired,
    scalePoints: PropTypes.array.isRequired,
  })),
  handleDetailsChanged: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    rows: state.survey.structure.data.questions[props.question].rows.map(rowId => state.survey.structure.data.rows[rowId]),
    questionObject: state.survey.structure.data.questions[props.question],
    details: props.details.map(detail => {
      detail.scalePoints = detail.scalePoints.map(point => parseInt(point));
      return detail;
    })
  }
}

export default connect(mapStateToProps)(TableDifferential);