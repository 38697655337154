import api from "api";
import * as types from "./actionTypes";

export function setSmtps(smtps) {
  return {
    type: types.SET,
    smtps
  }
}

export function smtpAdded(smtp) {
  return {
    type: types.ADDED,
    smtp
  }
}

export function smtpDeleted(smtp) {
  return {
    type: types.DELETED,
    smtp
  }
}

export function smtpUpdated(smtp) {
  return {
    type: types.UPDATED,
    smtp
  }
}

export function addSmtp(data) {
  return dispatch => {
    return api.user.post.smtp(data).then(smtp => {
      dispatch(smtpAdded(smtp));
    })
  }
}

export function deleteSmtp(smtp) {
  return dispatch => {
    return api.smtp.delete(smtp).then(() => {
      dispatch(smtpDeleted(smtp));
    });
  }
}

export function updateSmtp(data) {
  return dispatch => {
    return api.smtp.patch(data).then(smtp => {
      dispatch(smtpUpdated(smtp));
    });
  }
}