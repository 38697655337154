import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {connect} from "react-redux";
import {updateJavascript} from "scenes/Survey/Structure/data/javascripts/actions";
import JavascriptForm from "./JavascriptForm";


class ActionEdit extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      saving: false,
      errors: {},
    };

    this.form = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.saveJavascript = this.saveJavascript.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  saveJavascript(){
    const {question, javascript} = this.props;
    const data = this.form.current.state.data;

    this.setState({
      saving: true,
      errors: {},
    });

    this.props.updateJavascript({
      id: javascript.id,
      javascript: data,
    }).then(data => {
      this.setState({
        saving: false,
        modalOpen: false,
      });
    }).catch(res => {
      this.setState({
        saving: false,
        errors: res.response.data.form.errors,
      });
    })

  }

  render() {
    const {modalOpen, saving, errors} = this.state;
    const {question, javascript} = this.props;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal}>Kod JavaScript</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <JavascriptForm ref={this.form} data={javascript} javascriptActions={question.javascriptActions} errors={errors} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.saveJavascript}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <i className="fas fa-edit mr-2 pointer" onClick={this.toggleModal} />
        {modal}
      </React.Fragment>
    );
  }
}

ActionEdit.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    javascriptActions: PropTypes.array.isRequired,
  }).isRequired,
  javascript: PropTypes.object.isRequired,
};

export default connect(null, {updateJavascript})(ActionEdit);