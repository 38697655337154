import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {connect} from "react-redux";
import {deleteUser} from "../../../../../reducers/user/actions";
import {userLoggedOut} from "../../../../../reducers/user/reducer";
import moment from "moment";
import setAuthorizationHeader from "../../../../../utils/setAuthorizationHeader";

class DeleteAccount extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalDelete: false,
      deleting: false,
    };

    this.deleteUser = this.deleteUser.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
  }

  deleteUser(){
    this.setState({
      deleting: true
    });

    this.props.deleteUser().then(user => {
      localStorage.removeItem('jwt');
      localStorage.setItem('deleteAt', moment(user.deletedDate).format('YYYY-MM-DD'));
      setAuthorizationHeader();
      this.props.userLoggedOut();
    }).catch(() => {
      this.setState({
        deleting: false
      });
    });
  }

  toggleModalDelete(){
    this.setState({
      modalDelete: !this.state.modalDelete
    })
  }

  render() {
    const {modalDelete, deleting} = this.state;

    const modal = <Modal isOpen={modalDelete} toggle={() => this.toggleModalDelete()} className={'modal-danger'}>
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2">Czy na pewno chcesz usunąć swoje konto?</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p>Czy jesteś pewien, że chcesz skasować konto? Jeśli potwierdzisz usunięcie konta, rozpocznie się 7-dniowy proces dezaktywacji, po którym Twoje dane osobowe zostaną bezpowrotnie skasowane, utracisz również dostęp do swoich projektów. Jeśli się rozmyślisz, w każdym momencie możesz przerwać ten proces.
        </p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModalDelete()}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.delete">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={this.deleteUser}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return <Button color="link" className="mt-4" style={{color: 'darkgrey'}} onClick={this.toggleModalDelete}>Usuń konto{modal}</Button>
  }
}

DeleteAccount.propTypes = {};

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, {deleteUser, userLoggedOut})(DeleteAccount);