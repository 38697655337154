import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {swapRowsAndColumns} from 'scenes/Survey/Structure/data/questions/actions'

class Swap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      saving: false,
    };

    this.swap = this.swap.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  swap(){
    this.setState({
      saving: true
    });

    this.props.swapRowsAndColumns(this.props.question.id);
  }

  toggleModal(){
    this.setState({ modal: !this.state.modal })
  }

  render() {
    const {saving} = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="columns.actions.swap.modalHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="columns.actions.swap.modalBody"/></p>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="columns.actions.swap.button.confirmation">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={() => this.swap()}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button onClick={this.toggleModal}><FormattedMessage id="columns.actions.swap.button"/></Button>
        {this.state.modal && modal}
      </React.Fragment>
    )
  }
}

Swap.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};


export default connect(null, {swapRowsAndColumns})(Swap);