import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from "react-intl";
import {updateSurvey} from "../../data/survey/actions";
import {Card, CardHeader, CardBody, Row, Col, Button, Input} from 'reactstrap';
import HtmlEditor from "../../../../../componentsReusable/HtmlEditor";
import LoadingSpinner from "components/LoadingSpinner";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import striptags from "striptags";
import {isMobile, isTablet} from "react-device-detect";
import getCKConfigByPlan from "utils/ckeditor/questionConfig";
import reactHtmlParser from "react-html-parser";
import {isEmpty} from "lodash";

class InvitationText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invitationText: props.invitationText,
      showOnMobile: false,
      editing: false,
      saving: false
    };
    this.toggleEditing = this.toggleEditing.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.toggleShowOnMobile = this.toggleShowOnMobile.bind(this);
  }

  toggleEditing() {
    this.setState({
      editing: !this.state.editing
    });
  }

  toggleShowOnMobile() {
    this.setState({
      showOnMobile: !this.state.showOnMobile
    });
  }

  onChange(invitationText) {
    this.setState({
      invitationText
    });
  }

  updateContent() {
    this.setState({
      saving: true
    });

    const data = {
      id: this.props.surveyId,
      survey: {
        invitationText: this.state.invitationText
      }
    };

    this.props.updateSurvey(data)
      .then(() => {
        this.setState({
          saving: false,
          editing: false,
        });
      })
      .catch(() => {
        this.setState({
          saving: false
        })
      });
  }

  render() {
    const {saving, invitationText, editing, showOnMobile} = this.state;
    const {plan} = this.props;

    const editor = <React.Fragment>
      <Row>
        <Col>
          {(isMobile || isTablet)
            ? <Input value={invitationText} onChange={e => this.onChange(e.target.value)} onBlur={this.updateContent} autoFocus={true} />
            : <HtmlEditor
              onChange={e => this.onChange(e)}
              data={invitationText}
              config={getCKConfigByPlan(plan)}
            />}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mr-2"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={() => this.updateContent()}
            >{message}</LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) => <Button onClick={() => this.toggleEditing()}>{message}</Button>
          }</FormattedMessage>
        </Col>
      </Row>
    </React.Fragment>;

    return (
      <Card className="border-l green">
        <CardHeader className="py-2 d-print-none">
          <div className="float-left" data-toggle="collapse" data-target="#collapse2">
            <h2 className="float-left mb-0 mr-3 p-0"><FormattedMessage id="surveyStructure.invitationText.cardHeader"/></h2>
            {showOnMobile
              ? <i className="fa fa-sort-up d-block d-lg-none float-right" onClick={this.toggleShowOnMobile}/>
              : <i className="fa fa-sort-down d-block d-lg-none float-right" onClick={this.toggleShowOnMobile}/>
            }
          </div>
        </CardHeader>
        <CardBody className={"d-print-block d-lg-block " + (showOnMobile ? "" : "d-none")}>
          {editing
            ? editor
            : saving ? <LoadingSpinner width={25} classNames="my-0"/> : <span class={"ck-content"} onClick={() => this.toggleEditing()} >{!isEmpty(this.props.invitationText) ? reactHtmlParser(this.props.invitationText) : <i><FormattedMessage id="surveyStructure.invitationText.noText"/></i>}</span>
          }
        </CardBody>
      </Card>
    );
  }
}

InvitationText.propTypes = {
  surveyId: PropTypes.string.isRequired,
  invitationText: PropTypes.string
};

function mapStatesToProps(state) {
  return {
    invitationText: state.survey.structure.data.survey.invitationText,
    plan: state.user.userPlan.plan
  };
}

export default connect(mapStatesToProps, { updateSurvey })(InvitationText);
