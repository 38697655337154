import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {setPlanRestrictionsModals} from "../../../reducers/planRestrictionsModals/actions";
import {Link} from "react-router-dom";
import appRoutes from "../../../appRoutes";


class QuestionAvailable extends PureComponent {


  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  static getName(){
    return 'QuestionAvailable';
  }

  closeModal() {
    this.props.setPlanRestrictionsModals('');
  }

  render() {
    const {modalOpen} = this.props;

    return <Modal isOpen={modalOpen} toggle={this.closeModal} size="xl">
      <ModalHeader tag={"h2"} toggle={this.closeModal}>Dostępne pytania w Twoim planie</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        Ten typ pytania nie jest dostępny w Twoim planie. Aby mieć dostęp do innych typów pytań <Link onClick={this.closeModal} to={appRoutes.users.plan}>zmień plan</Link>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.close">{
          (message) =>
            <Button color="warning" className="mb-0" onClick={this.closeModal}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;
  }
}

QuestionAvailable.defaultProps = {
  modalOpen: false,
};

QuestionAvailable.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    modalOpen: state.planRestrictionsModals.name === QuestionAvailable.getName(),
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(QuestionAvailable);