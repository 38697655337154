import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {map, first} from "lodash";
import useAfterUpdateEffect from "../../../../../../../utils/useAfterUpdateEffect";
import {FormattedMessage} from "react-intl";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import InputNumber from "../../../../../../../components/InputNumber";
import Switch from "../../../../../../../components/Switch";
import Select from "react-select";
import {cityProvinces} from "../../../../../../SurveyCollector/components/CollectorPanel/Tabs/CollectorPanelForm";
import useDidMountEffect from "../../../../../../../utils/useDidMountEffect";
import {TOKEN_FIELDS} from "../../../../../../../componentsReusable/SelectTokenFields";

const CondToken = props => {

  const [cond, setCond] = React.useState(props.condQuestion);
  const [selectedField, setSelectedField] = React.useState(cond.tokenField);
  const [t, setT] = React.useState('');

  useAfterUpdateEffect(() => {
    props.onChange(cond)
  }, [cond])

  useDidMountEffect(() => {
    if(!props.changeable){
      setTextFromCond(cond)
    }
  })

  useAfterUpdateEffect(() => {
    if(!props.changeable){
      setTextFromCond(props.condQuestion)
    }
  }, [props.condQuestion])

  const setTextFromCond = (cond) => {
    const text = [];

    if (cond.tokenField) {
      text.push('Pole' + cond.tokenField.slice(-1) + ' ' + cond.comparison + ' ' + cond.tokenValue)
    }

    setT(text.join(', '))
  }

  const selectField = (field) => {
    setCond({
      ...cond,
      tokenField: field
    })
    setSelectedField(field);
  }

  const updateField = (value) => {
    setCond({
      ...cond,
      tokenValue: value
    })
  }

  const comparisons = [
    "<",
    ">",
    "=",
    "!="
  ]

  if(!props.changeable){
    return <span>{t}</span>
  }

  return (
    <div className="w-100">
      <Alert color={'info'}>
        <span>Warunek dotyczący tokenu respondenta</span>
      </Alert>
      <FormGroup row>
        <Col lg={4}>
          <Select
              closeMenuOnSelect={false}
              isOpen={false}
              options={TOKEN_FIELDS}
              onChange={comparison => selectField(comparison.value)}
              value={TOKEN_FIELDS.find(field => field.value === selectedField)}
          />
        </Col>
        <Col lg={4}>
          <Select
              closeMenuOnSelect={false}
              isOpen={false}
              placeholder="="
              options={comparisons.map(comparison => {
                return {
                  label: comparison,
                  value: comparison,
                }
              })}
              onChange={comparison => setCond({
                ...cond,
                comparison: comparison.value
              })}
              value={cond.comparison ? {
                label: cond.comparison,
                value: cond.comparison
              } : {
                label: '=',
                value: '='
              }}
          />
        </Col>
        <Col lg={4} className="d-flex flex-row flex-nowrap">
          <InputGroup size={'sm'} className="mr-3 d-flex flex-row flex-nowrap">
            <Input onChange={(e) => updateField(e.target.value)} value={cond.tokenValue} />
          </InputGroup>
        </Col>
      </FormGroup>
    </div>
  );
};

CondToken.defaultProps = {
  changeable: true,
};

CondToken.propTypes = {
  changeable: PropTypes.bool,
  onChange: PropTypes.func,
  condQuestion: PropTypes.shape({

  }).isRequired,
};

export default CondToken;
