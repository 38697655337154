import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage, injectIntl} from "react-intl";
import _ from "lodash";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import reactHtmlParser from "react-html-parser";
import striptags from "striptags";

class QuestionSummaryComment extends Component {

  render() {
    const {intl} = this.props;
    const {questionSummary} = this.props;
    const data = _.concat([
      {
        x: intl.formatMessage({ id: 'analysis.questionSummary.comment.status.seen' }),
        value: questionSummary.data.data.seen
      },
      {
        x: intl.formatMessage({ id: 'analysis.questionSummary.comment.status.notSeen' }),
        value: questionSummary.data.data.notSeen
      },
    ], questionSummary.data.excludings.map(e => {
      return {
        x: reactHtmlParser(striptags(e.content)),
        value: parseInt(e.value)
      }
    }));
    const dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <React.Fragment>
        <Chart id={questionSummary.question.id} type={questionSummary.chartType} n={questionSummary.data.n} data={data}/>
        <BootstrapTable
          className={'table-wrap w-lg-50 mx-auto mt-3'}
          data={data}
          striped hover
        >
          <TableHeaderColumn dataField='x' isKey={true}><FormattedMessage id="analysis.questionSummary.comment.tableHeader.x" /></TableHeaderColumn>
          <TableHeaderColumn dataField='value'><FormattedMessage id="analysis.questionSummary.comment.tableHeader.value" /></TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    )
  }
}

export default injectIntl(QuestionSummaryComment);