import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Input, Alert, Form, Col, FormGroup, Label} from "reactstrap";
import SelectQuestions from "../Structure/components/SelectQuestions";
import SelectColumns from "../Structure/components/SelectColumns";
import Select from "react-select";
import SelectRows from "../Structure/components/SelectRows";
import SelectAnswers from "../Structure/components/SelectAnswers";
import SelectSets from "../Structure/components/SelectSets";
import {connect} from "react-redux";
import _ from "lodash";
import SelectBlocks from "../Structure/components/SelectBlocks";
import SelectPages from "../Structure/components/SelectPages";
import api from "../../../api";
import {filterGroupCreate} from "../Structure/data/survey/actions";
import FilterActionVisibilitySelect from "../FilterActionVisibilitySelect";
import {FormattedMessage} from "react-intl";
import CondForm from "scenes/Survey/FiltersGroup/CondForm";
import Button from "components/Button";
import BlockCondsType from "./BlockCondsType";

const FilterElementTypeSelect = (props) => {

  const options = [
    {
      value: 'questionCafeteries',
      label: 'Elementy pytania'
    },
    {
      value: 'questions',
      label: 'Pytania'
    },
    // {
    //   value: 'pages',
    //   label: 'Strony'
    // },
    // {
    //   value: 'blocks',
    //   label: 'Bloki pytań'
    // },
    // {
    //   value: 'sets',
    //   label: 'Zestawy'
    // },
  ];

  return <Select
    options={options}
    value={props.value ? options.find(o => o.value === props.value) : false}
    onChange={option => props.onChange(option.value)}
  />;
}
const CafeteryTypeSelect = (props) => {

  const optionsDefault = [
    {
      value: 'answers',
      label: 'Odpowiedzi'
    },
    {
      value: 'rows',
      label: 'Wiersze'
    },
    {
      value: 'columns',
      label: 'Kolumny'
    },
  ];

  const options = optionsDefault.filter(o => props.options[o.value]);

  return <Select
    options={options}
    value={props.value ? options.find(o => o.value === props.value) : false}
    onChange={option => props.onChange(option.value)}
  />
}

class FiltersGroupForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        prefix: '',
        from: [
          undefined,
        ],
        condsType: 'AND',
        visibility: true,
        to: {
          type: 'questionCafeteries', // sets/blocks/pages/questions/questionCafeteries
          questionCafeteryType: false, //answers/rows/columns
          sets: [],
          blocks: [],
          pages: [],
          questions: [],
          question: false,
          answers: false,
          rows: false,
          columns: false,
        },
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.save = this.save.bind(this);
  }

  onSubmit(e){
    e.preventDefault();

    this.save();
  }

  save(){
    const {surveyData} = this.props;
    const {data} = this.state;

    this.props.onSavingStart();

    this.props.filterGroupCreate(surveyData.survey.id, data)
    .then(data => {
      this.props.onSavingEnd();

      this.props.onSaved();
    })
    .catch(() => {
      this.props.onSavingEnd();
    })
  }

  render() {
    const {data} = this.state;
    const {surveyData} = this.props;

    return (
      <Form onSubmit={this.onSubmit}>
        <Alert color="info" className="mb-4 d-flex flex-column">
          <span><span className="font-weight-bold">Przykład 1</span> <i className="text-info">Pytanie matrycowe/karuzela</i> Gdy wskażesz kilka wierszy np. wiersz 1,2,3 i kolumny 1 lub 2 oraz określisz akcję:  pokaż pytania A,B,C, to pytanie A pojawi się, gdy respondent wybierze kolumnę 1 lub 2 w wierszu 1, pytanie B, gdy wybierze kolumnę 1 lub 2 w wierszy 2 itd (na zasadzie filtra seryjnego).</span>
          <span className="mt-2"><span className="font-weight-bold">Przykład 2</span> <i className="text-info">Pytanie jednokrotnego wyboru</i> Gdy wskażesz w warunku pytania kilka odpowiedzi np. 1,2,3 oraz  określisz akcję: pokaż pytania A,B,C, to pytanie A pojawi się, gdy respondent wybierze odpowiedź 1, pytanie B, gdy wybierze 2 itd (na zasadzie filtra iteracyjnego)</span>
        </Alert>
        <FormGroup row className="mb-2">
          <Label md={4}>Prefiks nazwy utworzonych filtrów</Label>
          <Col md={8}>
            <Input onChange={e => this.setState({data: {...data, prefix: e.target.value}})} value={data.prefix} />
          </Col>
        </FormGroup>

        <div className="border p-3">
          <div className="d-flex flex-row justify-content-between align-items-center mb-3">
            <div />
            <h1 className="text-center mb-0 text-center w-100">Konfiguracja warunków</h1>
            <BlockCondsType type={data.condsType} onChange={condsType => this.setState({
              data: {
                ...data,
                condsType,
              }
            })} />
          </div>
          {_.map(data.from, (from, key) => <div key={from ? from.key : ''}>
            <CondForm
              num={key+1}
              from={from}
              onChange={from => this.setState({
                data: {
                  ...data,
                  from: data.from.map((f, k) => {
                    if(k === key){
                      return from;
                    }

                    return f;
                  }),
                }
              })}
              handleOnDelete={() => this.setState({
                data: {
                  ...data,
                  from: data.from.map((f, k) => {
                    if(k === key){
                      return false;
                    }

                    return f;
                  }).filter(v => v !== false),
                }
              })}
            />
          </div>)}

          <Button className="mt-2 d-block" onClick={(e) => {
            e.preventDefault();
            const fromNew = data.from;
            fromNew.push(undefined);

            this.setState({
              data: {
                ...data,
                from: fromNew
              }
            })
          }}><i className="fas fa-plus" /> Dodaj warunek</Button>
        </div>


        {data.from.length > 0 && <div className="border p-3 mt-3">
          <h1 className="text-center pb-2 text-center w-100">Konfiguracja akcji</h1>

          {data.from.question && <FormGroup row className="mb-2">
            <Col md={4}>
              <FilterActionVisibilitySelect
                value={data.visibility}
                onChange={visibility => {
                  this.setState({
                    data: {
                      ...data,
                      visibility,
                    }
                  })
                }}
              />
            </Col>
            <Col md={8}>
              <FilterElementTypeSelect
                value={data.to.type}
                onChange={type => this.setState({
                  data: {
                    ...data,
                    to: {
                      ...data.to,
                      type,
                      questionCafeteryType: false,
                      question: false,
                      answers: false,
                      rows: false,
                      columns: false,
                    }
                  }
                })}
              />
            </Col>
          </FormGroup>}
          <FormGroup row className="mb-2">
            <Label md={4}>Rodzaj</Label>
            <Col md={8}>
              <FilterElementTypeSelect
                  value={data.to.type}
                  onChange={type => this.setState({
                    data: {
                      ...data,
                      to: {
                        ...data.to,
                        type,
                        questionCafeteryType: false,
                        question: false,
                        answers: false,
                        rows: false,
                        columns: false,
                      }
                    }
                  })}
              />
            </Col>
          </FormGroup>
          {(data.to.type === 'sets') && <FormGroup row className="mb-2">
            <Label md={4}>Zestawy</Label>
            <Col md={8}>
              <SelectSets
                onChange={sets => this.setState({
                  data: {
                    ...data,
                    to: {
                      ...data.to,
                      sets,
                    }
                  }
                })}
              />
            </Col>
          </FormGroup>}
          {(data.to.type === 'blocks') && <FormGroup row className="mb-2">
            <Label md={4}>Bloki</Label>
            <Col md={8}>
              <SelectBlocks
                onChange={blocks => this.setState({
                  data: {
                    ...data,
                    to: {
                      ...data.to,
                      blocks,
                    }
                  }
                })}
              />
            </Col>
          </FormGroup>}
          {(data.to.type === 'pages') && <FormGroup row className="mb-2">
            <Label md={4}>Strony</Label>
            <Col md={8}>
              <SelectPages
                onChange={pages => this.setState({
                  data: {
                    ...data,
                    to: {
                      ...data.to,
                      pages,
                    }
                  }
                })}
              />
            </Col>
          </FormGroup>}
          {(data.to.type === 'questions') && <FormGroup row className="mb-2">
            <Label md={4}>Pytania</Label>
            <Col md={8}>
              <SelectQuestions
                onChange={questions => this.setState({
                  data: {
                    ...data,
                    to: {
                      ...data.to,
                      questions,
                    }
                  }
                })}
              />
            </Col>
          </FormGroup>}

          {(data.to.type === 'questionCafeteries') && <FormGroup row className="mb-2">
            <Label md={4}>W pytaniu </Label>
            <Col md={8}>
              <SelectQuestions
                isMulti={false}
                onChange={question => {
                  let questionCafeteryType = false;
                  if (_.has(surveyData.questions[question], 'answers')) {
                    questionCafeteryType = 'answers';
                  }
                  if (_.has(surveyData.questions[question], 'rows')) {
                    questionCafeteryType = 'rows';
                  }

                  this.setState({
                    data: {
                      ...data,
                      to: {
                        ...data.to,
                        question,
                        questionCafeteryType,
                      }
                    }
                  })
                }}
              />
            </Col>
          </FormGroup>}
          {(data.to.type === 'questionCafeteries' && data.to.question) && <FormGroup row className="mb-2">
            <Label md={4}>Wybierz element pytania</Label>
            <Col md={8}>
              <CafeteryTypeSelect
                options={{
                  answers: (data.to.question && _.has(surveyData.questions[data.to.question], 'answers')),
                  rows: (data.to.question && _.has(surveyData.questions[data.to.question], 'rows')),
                  columns: (data.to.question && _.has(surveyData.questions[data.to.question], 'columns')),
                }}
                value={data.to.questionCafeteryType}
                onChange={questionCafeteryType => this.setState({
                  data: {
                    ...data,
                    to: {
                      ...data.to,
                      questionCafeteryType,
                      answers: [],
                      rows: [],
                      columns: [],
                    }
                  }
                })}
              />
            </Col>
          </FormGroup>}
          {data.to.questionCafeteryType === 'answers' && <FormGroup row className="mb-2">
            <Label md={4}>Wybierz odpowiedzi</Label>
            <Col md={8}>
              <SelectAnswers
                questionId={data.to.question}
                onChange={answers => this.setState({
                  data: {
                    ...data,
                    to: {
                      ...data.to,
                      answers,
                    }
                  }
                })}
              />
            </Col>
          </FormGroup>}
          {data.to.questionCafeteryType === 'rows' && <FormGroup row className="mb-2">
            <Label md={4}>Wybierz wiersze</Label>
            <Col md={8}>
              <SelectRows
                questionId={data.to.question}
                onChange={rows => this.setState({
                  data: {
                    ...data,
                    to: {
                      ...data.to,
                      rows,
                    }
                  }
                })}
              />
            </Col>
          </FormGroup>}
          {data.to.questionCafeteryType === 'columns' && <FormGroup row className="mb-2">
            <Label md={4}>Wybierz kolumny</Label>
            <Col md={8}>
              <SelectColumns
                questionId={data.to.question}
                onChange={columns => this.setState({
                  data: {
                    ...data,
                    to: {
                      ...data.to,
                      columns,
                    }
                  }
                })}
              />
            </Col>
          </FormGroup>}
        </div>}
      </Form>
    );
  }
}

FiltersGroupForm.propTypes = {
  onSavingStart: PropTypes.func,
  onSaved: PropTypes.func,
  onSavingEnd: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    surveyData: state.survey.structure.data,
  }
}

export default connect(mapStateToProps, {filterGroupCreate}, null, {forwardRef: true})(FiltersGroupForm);