import api from "api";
import * as types from "./actionTypes";

export function setClients(clients) {
  return {
    type: types.SET,
    clients
  }
}

export function clientAdded(client) {
  return {
    type: types.ADDED,
    client
  }
}

export function clientDeleted(client) {
  return {
    type: types.DELETED,
    client
  }
}

export function clientUpdated(client) {
  return {
    type: types.UPDATED,
    client
  }
}

export function addClient(data) {
  return dispatch => {
    return api.user.post.client(data).then(client => {
      dispatch(clientAdded(client));

      return client;
    })
  }
}

export function deleteClient(client) {
  return dispatch => {
    return api.client.delete(client).then(() => {
      dispatch(clientDeleted(client));
    });
  }
}

export function updateClient(data) {
  return dispatch => {
    return api.client.patch(data).then(client => {
      dispatch(clientUpdated(client));
    });
  }
}