import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {UncontrolledTooltip} from "reactstrap";
import { connect } from 'react-redux';

class SurveysFavoriteIcon extends Component {

  render() {
    const {survey, toggle, userId} = this.props;

    const icon = survey.favoriteBy.includes(userId)
      ? <i id={"favorite-star-survey-" + survey.id} className="fas fa-star pointer" onClick={toggle} style={{color: 'gold'}}/>
      : <i id={"favorite-star-survey-" + survey.id} className="far fa-star pointer non-favorite" onClick={toggle} style={{color: 'darkgrey'}}/>;

    return (
      <React.Fragment>
        <button className='btn p-0'>
        {icon}
        <UncontrolledTooltip
          target={"favorite-star-survey-" + survey.id}
          delay={0}>
          {survey.favoriteBy.includes(userId) ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'}
        </UncontrolledTooltip>
        </button>
      </React.Fragment>
    )
  }
}

SurveysFavoriteIcon.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    favoriteBy: PropTypes.array.isRequired,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userId: state.user.id
  }
}

export default connect(mapStateToProps)(SurveysFavoriteIcon);