import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Input, Tooltip, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import classnames from "classnames";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {toast} from "react-toastify";
import api from "api";

class LibraryValues extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      modalIsOpen: false,
      tooltip: false,
      saving: false,
      totalCount: 0,
    };

    this.modalToggle = this.modalToggle.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }

  modalToggle() {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    });
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  handleUploadFile(e) {
    this.setState({
      file: e.target.files[0]
    });
  }

  fileUpload() {
    const {file} = this.state;

    const formData = new FormData();
    formData.append('file', file);
    this.setState({ saving: true });

    api.question.post.libraryValues(this.props.question.id, formData)
    .then(data => {
      this.setState({
        saving: false,
        totalCount: data.totalCount
      });
    })
    .catch(e => {
      this.setState({ saving: false });
    });
  }

  render() {
    let { modalIsOpen, tooltip, saving, totalCount } = this.state;

    const modal = <Modal isOpen={modalIsOpen} toggle={this.modalToggle} size="lg">
      <ModalHeader tag="h2" toggle={this.modalToggle}>Wgraj Listę wartości</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        {totalCount > 0 && <p className="text-center">Wgrano {totalCount} rekordów</p>}
        {saving && !totalCount && <p className="text-center">Proszę czekać...</p>}
        {!saving && <Input
          name="file"
          type="file"
          placeholder="Wgraj plik"
          onChange={this.handleUploadFile}
        />}
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.modalToggle}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.fileUpload}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return <React.Fragment>
      <Button className="mr-1 btn-icon float-left" color="secondary" id={"libraryValues-" + this.props.question.id} onClick={this.modalToggle}>
        <i className="fas fa-tag" />
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={"libraryValues-" + this.props.question.id}
          toggle={() => this.toggleTooltip()}
        >Wgraj listę wartości</Tooltip>
      </Button>
      {modal}
    </React.Fragment>
  }
}

LibraryValues.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default LibraryValues;