import React, {Component} from 'react';
import {Row, Col, Table} from 'reactstrap';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

class RespondentsStatsData extends Component {
  render() {
    const {ages} = this.props.data;

    return (
      <Row>
        <Col xs={12}>
          <p><FormattedMessage id="admin.respondentsStatsData.new"/>: {this.props.data.allActive}</p>
          <p>Wszyscy aktywni: {this.props.data.new}</p>
          <p>Pobrana wtyczka: {this.props.data.browserPlugin}</p>
          <p><FormattedMessage id="admin.respondentsStatsData.facebook"/>: {this.props.data.facebook}</p>
          <p>Rejestracje google: {this.props.data.google}</p>
          <p><FormattedMessage id="admin.respondentsStatsData.notActive"/>: {this.props.data.notActive}</p>
          <p>Konta usunięte: {this.props.data.deleted}</p>
          <h3>Integracje</h3>
          <p><FormattedMessage id="admin.respondentsStatsData.iosLogin"/>: {this.props.data.iosLogin}</p>
          <p><FormattedMessage id="admin.respondentsStatsData.iosRegistration"/>: {this.props.data.iosRegistration}</p>
          <p><FormattedMessage id="admin.respondentsStatsData.androidLogin"/>: {this.props.data.androidLogin}</p>
          <p><FormattedMessage id="admin.respondentsStatsData.androidRegistration"/>: {this.props.data.androidRegistration}</p>
          <h3>Logowania:</h3>
          <p>Łącznie logowania: {this.props.data.logins.total}</p>
          <p>Udane logowania: {this.props.data.logins.success}</p>
          <p>Nieudane logowania: {this.props.data.logins.failed}</p>
        </Col>
        <Col xs={12} lg={6}>
          <Table>
            <thead>
            <tr>
              <th><FormattedMessage id="admin.respondentsStatsData.age"/></th>
              <th><FormattedMessage id="admin.respondentsStatsData.users"/></th>
            </tr>
            </thead>
            <tbody>
            {ages.map((ageData) => {
              return <tr>
                {ageData.from === ageData.to ?
                    <td>{ageData.from}</td> :
                    <td>{ageData.from}-{ageData.to !== 999 ? ageData.to : '100+'}</td>
                }
                <td>{ageData.value}</td>
              </tr>
            })}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} lg={6}>
          <Table>
            <thead>
            <tr>
              <th><FormattedMessage id="admin.respondentsStatsData.day"/></th>
              <th><FormattedMessage id="admin.respondentsStatsData.users"/></th>
            </tr>
            </thead>
            <tbody>
            {_.map(this.props.data.date, (val) => {
              return <tr>
                <td>{val.data}</td>
                <td>{val.count}</td>
              </tr>
            })}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} lg={6}>
          <h3>Logowania</h3>
          <Table>
            <thead>
            <tr>
              <th><FormattedMessage id="admin.respondentsStatsData.day"/></th>
              <th>Logowań</th>
              <th>Udane</th>
              <th>Nieudane</th>
            </tr>
            </thead>
            <tbody>
            {_.map(this.props.data.loginsDate, (val) => {
              return <tr>
                <td>{val.data}</td>
                <td>{val.count}</td>
                <td>{val.success}</td>
                <td>{val.failed}</td>
              </tr>
            })}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }
}

RespondentsStatsData.propTypes = {
  data: PropTypes.shape({
    new: PropTypes.string.isRequired,
    facebook: PropTypes.string.isRequired,
    notActive: PropTypes.string.isRequired,
    ages: PropTypes.string.isRequired,
    createdAtDay1: PropTypes.string.isRequired,
    createdAtDay2: PropTypes.string.isRequired,
    createdAtDay3: PropTypes.string.isRequired,
    createdAtDay4: PropTypes.string.isRequired,
    createdAtDay5: PropTypes.string.isRequired,
    createdAtDay6: PropTypes.string.isRequired,
    createdAtDay7: PropTypes.string.isRequired,
    createdAtDay8: PropTypes.string.isRequired,
    createdAtDay9: PropTypes.string.isRequired,
    createdAtDay10: PropTypes.string.isRequired,
    createdAtDay11: PropTypes.string.isRequired,
    createdAtDay12: PropTypes.string.isRequired,
    createdAtDay13: PropTypes.string.isRequired,
    createdAtDay14: PropTypes.string.isRequired,
    createdAtDay15: PropTypes.string.isRequired,
    createdAtDay16: PropTypes.string.isRequired,
    createdAtDay17: PropTypes.string.isRequired,
    createdAtDay18: PropTypes.string.isRequired,
    createdAtDay19: PropTypes.string.isRequired,
    createdAtDay20: PropTypes.string.isRequired,
    createdAtDay21: PropTypes.string.isRequired,
    createdAtDay22: PropTypes.string.isRequired,
    createdAtDay23: PropTypes.string.isRequired,
    createdAtDay24: PropTypes.string.isRequired,
    createdAtDay25: PropTypes.string.isRequired,
    createdAtDay26: PropTypes.string.isRequired,
    createdAtDay27: PropTypes.string.isRequired,
    createdAtDay28: PropTypes.string.isRequired,
    createdAtDay29: PropTypes.string.isRequired,
    createdAtDay30: PropTypes.string.isRequired,
    createdAtDay31: PropTypes.string.isRequired,
  }).isRequired
};

export default RespondentsStatsData;
