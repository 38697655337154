import React, {Component} from 'react';
import {Modal, ModalFooter, ModalBody, ModalHeader, Button, Form, Label, FormGroup, Col, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import Select from 'react-select';
import api from 'api';
import LoadingSpinner from "components/LoadingSpinner";
import _ from 'lodash';
import {fetchAnalysis} from "scenes/Survey/Analysis/reducer/analysis/actions";
import { connect } from 'react-redux';
import QuestionList from "./components/QuestionList";

class Settings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      widget: {
        name: props.widget.name || '',
        analysis: props.widget.analysis ? {label: props.widget.analysis.name, value: props.widget.analysis.id} : {},
        visibleQuestions: []
      },
      analyses: {},
      loading: true,
      loadingQuestions: false,
    };

    if (props.widget.analysis)
      this.props.fetchAnalysis({id: props.widget.analysis.id});

    this.onChange = this.onChange.bind(this);
    this.onAnalysisChange = this.onAnalysisChange.bind(this);
    this.onVisibleQuestionsChange = this.onVisibleQuestionsChange.bind(this);
  }

  componentDidMount() {
    api.user.get.analyses().then(analyses => this.setState({...this.state, analyses: analyses, loading: false}));
  }

  onChange(e){
    this.setState({
      widget: {
        ...this.state.widget,
        [e.target.name]: e.target.value,
      }
    });
  }

  onAnalysisChange(e) {
    this.setState({
      ...this.state,
      widget: {
        ...this.state.widget,
        analysis: e,
      },
      loadingQuestions: true
    });
    this.props.fetchAnalysis({id: e.value}).then(() => {
      this.setState({
        ...this.state,
        loadingQuestions: false
      })
    });
  }

  onVisibleQuestionsChange(questionList) {
    this.setState({
      ...this.state,
      widget: {
        ...this.state.widget,
        visibleQuestions: questionList
      }
    });
  }

  onSubmit(){
    const data = {
      id: this.props.widget.id,
      widget: this.state.widget
    };

    data.widget.analysis = data.widget.analysis.value;
    if (data.widget.name.length === 0)
      delete data.widget.name;

    this.props.handleSettingsSet(data);
    this.props.toggle('settingsModal');
    this.props.widget.id && this.props.handleDataChange();
  }

  render() {
    let showQuestionList = Object.keys(this.state.widget.analysis).length > 0 && Object.keys(this.state.analyses).length > 0;

    return (
      <Modal isOpen={this.props.modal} toggle={() => this.props.toggle('settingsModal')} size="lg" className={'modal-danger'}>
        <ModalHeader toggle={() => this.props.toggle('settingsModal')} tag="h2"><FormattedMessage id="widgetTypes.typeQuestionSummariesFromAnalysis.settings.modal.header" /></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
          {this.state.loading ? <LoadingSpinner/> :
          <Form>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="widgetTypes.typeQuestionSummariesFromAnalysis.settings.label.name" /></Label>
              <Col lg={9}>
                <FormattedMessage id="widgetTypes.typeQuestionSummariesFromAnalysis.settings.placeholder.name">{
                  (message) =>
                  <Input type="text" name="name" placeholder={message} onChange={this.onChange} value={this.state.widget.name} />
                }</FormattedMessage>
            </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="widgetTypes.typeQuestionSummariesFromAnalysis.settings.label.analysis" /></Label>
              <Col lg={9}>
                <Select
                  value={this.state.widget.analysis}
                  clearable={false}
                  onChange={this.onAnalysisChange}
                  options={_.map(this.state.analyses, analysis => {
                    return {
                      value: analysis.id,
                      label: <span>{analysis.surveyName} <i className="fas fa-arrow-right" /> {analysis.name}</span>,
                    }
                  })}
                />
              </Col>
            </FormGroup>
            {showQuestionList && (this.state.loadingQuestions ? <LoadingSpinner/> : <QuestionList handleCheck={this.onVisibleQuestionsChange} widget={this.props.widget}/>)}
          </Form>}
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda"
              data-style={ZOOM_OUT}
              onClick={(e) => this.onSubmit(e)}
              disabled={Object.keys(this.state.widget.analysis).length === 0}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <Button color="secondary" onClick={() => this.props.toggle('settingsModal')} ><FormattedMessage id="_.button.cancel" /></Button>
        </ModalFooter>
      </Modal>
    )
  }
}

Settings.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleSettingsSet: PropTypes.func.isRequired,
  handleDataChange: PropTypes.func,
  widget: PropTypes.shape({
    id: PropTypes.number,
    questions: PropTypes.object,
    analysis: PropTypes.shape({
      id: PropTypes.number
    })
  })
};

export default connect(null, {fetchAnalysis})(Settings);
