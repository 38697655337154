import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {ListGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../Button";
import LoadingSpinner from "../LoadingSpinner";
import api from "../../api";
import QuestionInfo from "../QuestionInfo";

const QuestionsListModal = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [loading, setLoading] = React.useState(false);
  const [survey, setSurvey] = React.useState(false);

  React.useEffect(() => {
    console.log('modalOpen', modalOpen);
    if(modalOpen){
      setLoading(true);

      api.survey.get.survey({
        id: props.surveyId,
      }).then(survey => {
        setSurvey(survey);
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [modalOpen]);

  const getQuestions = () => {
    let questions = [];

    if(survey){
      _.map(survey.setBlocks, setBlock => {
        _.map(setBlock.blockPages, block => {
          _.map(block.pages, page => {
            _.map(page.questions, question => {
              questions.push(question);
            });
          })
        })
      });
    }

    return questions;
  }
  
  return (
    <React.Fragment>
      <small className="pointer" onClick={toggleModalOpen}>Zobacz listę pytań</small>
      {modalOpen && <Modal isOpen toggle={toggleModalOpen} size="lg">
        <ModalHeader toggle={toggleModalOpen}>Lista pytań</ModalHeader>
        <hr className="my-0"/>
        <ModalBody id="questions-list">
          {loading && <LoadingSpinner />}
          {!loading && <ListGroup className="mt-2">
            {getQuestions().map(question => {
              return <li className="question">
                <div className="d-flex"><QuestionInfo question={question}/> <div className="ml-2" dangerouslySetInnerHTML={{__html: question.content}} /></div>
                {question.answers && <ul>
                  {question.answers.map(answer => <li>{answer.content}</li>)}
                </ul>}
                {question.rows && <ul>
                  {question.rows.map(row => <li>{row.content}</li>)}
                </ul>}
                {question.columns && <ul>
                  {question.columns.map(column => <li>{column.content}</li>)}
                </ul>}
              </li>
            })}
          </ListGroup>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Zamknij</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

QuestionsListModal.propTypes = {

};

export default QuestionsListModal;