import React, {Component} from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import _ from 'lodash';

class SmtpAccountSelect extends Component {
  constructor (props){
    super(props);
    this.state = {
      smtp: props.smtpId
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e){
    const smtp = e ? e.value : null;

    this.setState({
      smtp
    });

    this.props.handleSmtpChange(smtp);
  }



  render() {
    const {smtp} = this.state;
    const {smtps} = this.props;

    let value = smtp ? {label: smtps[smtp].name, value: smtp} : null;
    return (
      <Select
        placeholder="Wybierz swoje konto SMTP"
        value={value}
        isClearable
        onChange={this.onChange}
        options={_.map(smtps, smtp => {
          return {
            value: smtp.id,
            label: smtp.name
          }
        })}
      />
    );
  }
}

SmtpAccountSelect.propTypes = {
  smtpId: PropTypes.number,
  handleSmtpChange: PropTypes.func.isRequired,
  smtps: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })
};

function mapStateToProps(state) {
  return {
    smtps: state.smtps.data.smtps,
  }
}

export default connect(mapStateToProps)(SmtpAccountSelect);
