import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/lib/Async';
import api from "../api";
import useAfterUpdateEffect from "../utils/useAfterUpdateEffect";

const PlanSelect = props => {
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(props.value ? props.value.map(plan => ({
    value: plan.id,
    label: plan.name,
  })) : []);

  useAfterUpdateEffect(() => {
    if(props.isMulti){
      props.onChange(value.map(v => data.find(d => d.id == v.value)))
    }else{
      props.onChange(value ? data.find(d => d.id == value.value) : null)
    }
  }, [value])

  const fetchData = (search, callback) => {
    api.plan.get.plans()
      .then(plans => {
        setData(plans)
        callback(plans.map(plan => {
          return {
            value: plan.id,
            label: plan.name,
          }
        }));
      })
  }

  return (
    <AsyncSelect
      isMulti={props.isMulti}
      isClearable={true}
      loadOptions={fetchData}
      value={value}
      defaultOptions
      onChange={option => {
        if(props.isMulti){
          setValue(option.map(plan => ({
            value: plan.value,
            label: plan.label,
          })))
        }else{
          setValue(option ? {
            value: option.value,
            label: option.label,
          } : null)
        }
      }}
    />
  );
};

PlanSelect.defaultProps = {
  isMulti: true,
};

PlanSelect.propTypes = {
  isMulti: PropTypes.bool,
};

export default PlanSelect;
