import React from 'react';
import SurveysCategories from "../../../componentsNew/SurveysCategories/SurveysCategories";

const Surveys = props => {
  return (
    <SurveysCategories />
  );
};

Surveys.propTypes = {

};

export default Surveys;