import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Row, Col, Form, Input, Label} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {updateProfile} from "reducers/user/actions";
import Switch from "components/Switch";
import Select from "react-select";

class CompanyType extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {
        company: props.user.company || '',
        nip: props.user.nip || '',
        trade: props.user.trade || Object.values(props.trades)[0].id,
        city: props.user.city || '',
        street: props.user.street || '',
        postalCode: props.user.postalCode || '',
        avatar: props.user.avatar || '',
        helpMode: props.user.helpMode,
        invoice: props.user.invoice,
      },
      errors: {},
      avatarFile: null
    };

    this.fileUploader = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
  }

  onSubmit(e){
    e.preventDefault();
    const formData = new FormData();
    formData.append('company', this.state.data.company);
    formData.append('nip', this.state.data.nip);
    formData.append('trade', this.state.data.trade);
    formData.append('city', this.state.data.city);
    formData.append('street', this.state.data.street);
    formData.append('postalCode', this.state.data.postalCode);
    formData.append('helpMode', this.state.data.helpMode);
    formData.append('invoice', this.state.data.invoice);

    if (this.state.avatarFile)
      formData.append('avatar', this.state.avatarFile);

    this.setState({ loading: true });

    this.props.updateProfile(formData)
      .then(() => {
        this.setState({ loading: false, errors: {} })
      })
      .catch(error => {
        this.setState({ loading: false, errors: error.response.data.form.errors });
      });
  }

  onChange(e){
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  }

  fileChangedHandler(e) {
    this.setState({
      data: {
        ...this.state.data,
        avatar: URL.createObjectURL(e.target.files[0])
      },
      avatarFile: e.target.files[0]
    });
  }

  render() {
    const {data, errors, loading} = this.state;
    const {trades} = this.props;

    const tradeOptions = _.map(trades, trade => {
      return {label: trade.name, value: trade.id}
    });

    return (
      <Form onSubmit={this.onSubmit} className="needs-validation">
        <Row>
          <Col xs={12} lg={6}>
            <h1 className="text-center">Ogólne</h1>
            <Row className="mb-3">
              <Col md={3}>
                <Label data-role="label" for="input-type-1"><FormattedMessage id="profile.form.companyType.label.avatar" /></Label>
              </Col>
              <Col md={9}>
                {!data.avatar ? <span onClick={() => this.fileUploader.current.click()}>Kliknij aby ustawić</span> : <img src={data.avatar} alt={data.avatar} className="avatar rounded-img ml-3" onClick={() => this.fileUploader.current.click()}/>}
                <input type="file" onChange={this.fileChangedHandler} ref={this.fileUploader} hidden={true}/>
                {errors.avatar && <div className="invalid-feedback">{errors.avatar.join('; ')}</div>}
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label data-role="label" for="trade"><FormattedMessage id="profile.form.companyType.label.trade" /></Label>
              </Col>
              <Col md={9}>
                <Select
                  value={{label: _.find(trades, trade => trade.id === this.state.data.trade).name, value: this.state.data.trade}}
                  onChange={e => this.onChange({target: {name: 'trade', value: e.value}})}
                  options={tradeOptions}
                />
                {errors.trade && <div className="invalid-feedback">{errors.trade.join('; ')}</div>}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={3}>
                <Label data-role="label" for="helpMode"><FormattedMessage id="profile.form.companyType.label.helpMode" /></Label>
              </Col>
              <Col md={9}>
                <Switch checked={this.state.data.helpMode} onChange={value => this.setState({data: {...this.state.data, helpMode: value}})} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <h1 className="text-center">Dane do faktury</h1>
            <Row className="mt-2">
              <Col md={3}>
                <Label data-role="label" for="invoice">Chcę otrzymać fakturę na poniższe dane</Label>
              </Col>
              <Col md={9}>
                <Switch checked={this.state.data.invoice} onChange={value => this.setState({data: {...this.state.data, invoice: value}})} />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label data-role="label" for="company"><FormattedMessage id="profile.form.companyType.label.company" /></Label>
              </Col>
              <Col md={9}>
                <FormattedMessage id="profile.form.companyType.label.company">{
                  (message) =>
                    <Input type="text" id="company" name="company" className="custom" placeholder={message} value={data.company} invalid={!!errors.company} onChange={this.onChange}/>
                }</FormattedMessage>
                {errors.company && <div className="invalid-feedback">{errors.company.join('; ')}</div>}
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label data-role="label" for="nip"><FormattedMessage id="profile.form.companyType.label.nip" /></Label>
              </Col>
              <Col md={9}>
                <FormattedMessage id="profile.form.companyType.label.nip">{
                  (message) =>
                    <Input type="text" id="nip" name="nip" className="custom" placeholder={message} value={data.nip} invalid={!!errors.nip} onChange={this.onChange}/>
                }</FormattedMessage>
                {errors.nip && <div className="invalid-feedback">{errors.nip.join('; ')}</div>}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={3}>
                <Label data-role="label" for="city"><FormattedMessage id="profile.form.companyType.label.city" /></Label>
              </Col>
              <Col md={9}>
                <FormattedMessage id="profile.form.companyType.label.city">{
                  (message) =>
                    <Input type="text" id="city" name="city" className="custom" placeholder={message} value={data.city} invalid={!!errors.city} onChange={this.onChange}/>
                }</FormattedMessage>
                {errors.city && <div className="invalid-feedback">{errors.city.join('; ')}</div>}
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label data-role="label" for="street"><FormattedMessage id="profile.form.companyType.label.street" /></Label>
              </Col>
              <Col md={9}>
                <FormattedMessage id="profile.form.companyType.label.street">{
                  (message) =>
                    <Input type="text" id="street" name="street" className="custom" placeholder={message} value={data.street} invalid={!!errors.street} onChange={this.onChange}/>
                }</FormattedMessage>
                {errors.street && <div className="invalid-feedback">{errors.street.join('; ')}</div>}
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label data-role="label" for="postalCode"><FormattedMessage id="profile.form.companyType.label.postalCode" /></Label>
              </Col>
              <Col md={9}>
                <FormattedMessage id="profile.form.companyType.label.postalCode">{
                  (message) =>
                    <Input type="text" id="postalCode" name="postalCode" className="custom" placeholder={message} value={data.postalCode} invalid={!!errors.postalCode} onChange={this.onChange}/>
                }</FormattedMessage>
                {errors.postalCode && <div className="invalid-feedback">{errors.postalCode.join('; ')}</div>}
              </Col>
            </Row>
          </Col>
        </Row>

        <FormattedMessage id="_.button.save">
          {(message) => <LaddaButton
            className="btn btn-success mx-auto d-block px-5"
            loading={loading}
            data-style={ZOOM_OUT}
          >
            {message}
          </LaddaButton>}
        </FormattedMessage>
      </Form>
    )
  }
}

CompanyType.propTypes = {
  user: PropTypes.shape({
    company: PropTypes.string,
    nip: PropTypes.string,
    trade: PropTypes.string,
    avatar: PropTypes.string
  }).isRequired
};

function mapStateToProps(state) {
  return {
    trades: state.config.data.trades,
    user: state.user,
  }
}


export default connect(mapStateToProps, {updateProfile})(CompanyType);