import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input
} from "reactstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";

class Url extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalTarget: props.target,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {target, changeable} = this.props;
    const {modalOpen, modalTarget} = this.state;

    if (!changeable) {
      return target;
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModal}>Wprowadź adres URL</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <Input type="text" name="target" onChange={e => this.setState({modalTarget: e.target.value})} value={modalTarget}/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleTargetChanged(modalTarget);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;


    return (
      <React.Fragment>
        <Badge color={target ? 'secondary' : 'warning'} className="mx-1 pointer" onClick={this.toggleModal}>{target || 'Wprowadź adres...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

Url.defaultProps = {
  changeable: true
};

Url.propTypes = {
  target: PropTypes.string.isRequired,
  handleTargetChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(Url);