import React, {Component} from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {updateFilter} from "scenes/Survey/Structure/data/filters/actions";
import {updateFilterSerial} from "scenes/Survey/Structure/data/filterSerial/actions";
import {toast} from "react-toastify";
import Switch from "components/Switch";
import {UncontrolledTooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";

class ButtonPause extends Component {

  constructor(props) {
    super(props);
    this.changeStatus = this.changeStatus.bind(this);
  }

  changeStatus(active) {
    const action = this.props.isSerialFilter ? this.props.updateFilterSerial : this.props.updateFilter;

    const data = this.props.isSerialFilter ? {
      filterSerial: {
        id: this.props.filter.id,
        paused: !active
      }
    } : {
      filter: {
        id: this.props.filter.id,
        paused: !active
      }
    };

    action(data).catch(res => {
      if(res.response.data.form.errors.paused){
        res.response.data.form.errors.paused.map(error => {
          toast.error(error);
        });
      }
    });
  }

  render() {
    return <React.Fragment>
      <Switch id={"survey-filter-button-paused-" + this.props.filter.id + '-' + this.props.filter.type} className="mr-2" onChange={this.changeStatus} checked={!this.props.filter.paused}/>
      <UncontrolledTooltip target={"survey-filter-button-paused-" + this.props.filter.id + '-' + this.props.filter.type} placement="bottom" delay={0}>
        <FormattedMessage id="question.filters.paused.tooltip"/>
      </UncontrolledTooltip>
    </React.Fragment>
  }
}

ButtonPause.defaultProps = {
  tooltip: false,
};

ButtonPause.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.number.isRequired,
    paused: PropTypes.bool.isRequired
  }).isRequired,
  tooltip: PropTypes.bool,
  isSerialFilter: PropTypes.bool,
};

function mapStateToProps(state, props) {
  return {
    isSerialFilter: Object.values(state.survey.structure.data.filterSerial).includes(props.filter)
  }
}

export default connect(mapStateToProps, {updateFilter, updateFilterSerial})(ButtonPause);