import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import { Col, Form, FormGroup, Input, Label} from "reactstrap";
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from "react-select";
import { connect } from 'react-redux';
import Autocomplete from "react-async-autocomplete";
import api from 'api';

class SurveyForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      survey: {
        swPanel: {
          status: props.survey.swPanel ? props.survey.swPanel.status : '',
          msg: props.survey.swPanel ? props.survey.swPanel.msg : '',
        },
      }
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(name, value) {
    this.setState({
      survey: {
        ...this.state.survey,
        swPanel: {
          ...this.state.survey.swPanel,
          [name]: value
        }
      }
    });
  }

  render() {
    const {survey} = this.state;
    const {errors, onSubmit} = this.props;

    const statuses = [
      {
        label: 'Odrzucona',
        value: 2,
      },
      {
        label: 'Zaakceptowana',
        value: 3,
      },
    ];

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">Status</Label>
          <Col lg={9} className="pt-1">
            <Select
              value={_.find(statuses, option => option.value === survey.swPanel.status)}
              options={statuses}
              onChange={value => this.onChange('status', value.value)}
            />
            {_.has(errors, 'swPanel.status') && <p className="help-block text-danger">{errors.swPanel.status}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">Komunikat</Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="msg" id="msg" value={survey.swPanel.msg} invalid={_.has(errors, 'swPanel.msg') && !!errors.swPanel.msg} onChange={e => this.onChange('msg', e.target.value)}/>
            {_.has(errors, 'swPanel.msg') && <p className="help-block text-danger">{errors.swPanel.msg}</p>}
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

SurveyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  survey: PropTypes.shape({
    swPanel: PropTypes.shape({
      status: PropTypes.number.isRequired,
      msg: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default connect(null, {}, null, {forwardRef: true})(SurveyForm);