import api from "api";
import {normalize} from "normalizr";
import schemaAnalysisFilterQuestion from "entities/analysis/filterQuestion/schema";
import {REQUEST, SUCCESS} from "../analysis/actions";

export const SET = 'Analysis/data/filtersQuestion/SET';
export const ADDED = 'Analysis/data/filtersQuestion/ADDED';
export const DELETED = 'Analysis/data/filtersQuestion/DELETED';

export function setFiltersQuestion(filtersQuestion) {
  return {
    type: SET,
    filtersQuestion
  }
}

function filterQuestionAdded(filterQuestion){
  return {
    type: ADDED,
    filterQuestion
  }
}

function filterQuestionDeleted(id){
  return {
    type: DELETED,
    id
  }
}

export function saveFilterQuestion(filter) {
  return dispatch => {
    dispatch({type: REQUEST});

    let filterId = filter.id;

    const apiAction = filterId
      ? api.analysis.filterQuestion.patch(filter)
      : api.analysis.post.filterQuestion(filter);

    return apiAction.then((filterQuestionData) => {
      dispatch({type: SUCCESS});

      const normalized = normalize(filterQuestionData, schemaAnalysisFilterQuestion);
      const filterQuestion = normalized.entities.filtersQuestion[normalized.result];

      dispatch(filterQuestionAdded(filterQuestion));

      if (filter.id && filter.id !== filterQuestion.id)
        dispatch(filterQuestionDeleted(filter.id));

      return filterQuestion;
    });
  }
}

export function deleteFilterQuestion(data){
  return dispatch => {
    return api.analysis.filterQuestion.delete.filterQuestion(data).then(() => {
      dispatch(filterQuestionDeleted(data.id));
    });
  }
}