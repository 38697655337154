import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Select from "react-select";

class SelectColumns extends Component {
  constructor(props){
    super(props);

    this.select = React.createRef();

    this.onChange = this.onChange.bind(this);
    this.getLabel = this.getLabel.bind(this);
  }

  onChange(e){
    const {isMulti} = this.props;

    if(isMulti){
      this.select.current.setState({isOpen: true, menuIsOpen: true});
    }

    if(isMulti){
      this.props.onChange(e.map(obj => obj.value));
    }else{
      this.props.onChange(e.value);
    }
  }

  getLabel(column) {
    return this.props.question.columnsImage
      ? <span className="img-30">{column.position}. <img src={column.content} /></span>
      : <span className="img-20" dangerouslySetInnerHTML={{__html: column.position + '. ' + column.stripedContent}}/>
  }

  render() {
    const {columns, value, isMulti, placeholder} = this.props;

    return (
      <Select
        placeholder={placeholder ? placeholder : (isMulti ? "Wybierz kolumny" : "Wybierz kolumnę")}
        ref={this.select}
        value={value && (!isMulti ?
            {
              label: this.getLabel(columns.find(c => c.id === value)),
              value
            }
          :
          value.map(cId => {
            return {
              label: this.getLabel(columns.find(c => c.id === cId)),
              value: cId,
            }
          })
        )}
        isOpen={false}
        isMulti={isMulti}
        filterOption={(option, string) => {
          if (option.value.toString().toLowerCase().includes(string.toLowerCase())) return true;
          if (option.data.content.toLowerCase().includes(string.toLowerCase())) return true;
        }}
        onChange={this.onChange}
        options={columns.map(column => {
          return {
            label: this.getLabel(column),
            content: column.content || '',
            value: column.id,
          }
        })}
      />
    );
  }
}

SelectColumns.defaultProps = {
  isMulti: true,
};

SelectColumns.propTypes = {
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  questionId: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    stripedContent: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number,]),
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  let columns = state.survey.structure.data.questions[props.questionId].columns.map(cId => state.survey.structure.data.columns[cId]);

  let position = 0;
  columns = columns.map(column => {
    position++;
    return {
      ...column,
      position
    }
  });

  return {
    columns,
    question: state.survey.structure.data.questions[props.questionId]
  }
}

export default connect(mapStateToProps)(SelectColumns);