import * as React from 'react';
import PropTypes from "prop-types";
import {Card, CardFooter, CardBody, CardHeader, Row, Col, Form, FormGroup, Label, Input} from "reactstrap";
import Button from "../../../../components/Button";
import api from "../../../../api";
import {toast} from "react-toastify";


function SitemapForm(props) {
  const [file, setFile] = React.useState(null);
  const [saving, setSaving] = React.useState(false);

  const save = () => {
    setSaving(true);

    const formData = new FormData();
    formData.append('name', props.name);
    formData.append('file', file);

    api.sitemapsFile.addFile(formData)
    .then(data => {
      setSaving(false);
      toast.success('Plik został poprawnie wgrany');
    })
    .catch(() => {
      setSaving(false);
      toast.error('Wystąpił błąd');
    })
  }

  return (
    <Card>
      <CardHeader tag="h1" className="text-center pb-2">{props.name}</CardHeader>
      <hr className="my-0" />
      <CardBody>
        <Form onSubmit={(e) => { e.preventDefault(); save(); }}>
          <Input
            type="file"
            onChange={e => setFile(e.target.files[0])}
          />
        </Form>
      </CardBody>
      <CardFooter>
        <Button
          className="mx-auto d-block"
          loading={saving}
          onClick={() => save()}
        >Wgraj</Button>
      </CardFooter>
    </Card>
  );
}

SitemapForm.propTypes = {

};

export default SitemapForm;