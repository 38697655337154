import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, Button
} from 'reactstrap';
import QuestionInfo from "components/QuestionInfo";
import {FormattedMessage, injectIntl} from "react-intl";
import {getQuestionTemplateName} from "services/questionTypes";
import {changeQuestionType, updateQuestion} from "scenes/Survey/Structure/data/questions/actions";
import { connect } from 'react-redux';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionTypesModal from "components/QuestionTypesModal";

class ButtonType extends Component {

  constructor(props) {
    super(props);

    this.state = {
      typeModal: false,
      warningModal: false,
      tooltip: false,
      saving: false,
      chosenType: null
    };

    this.toggle = this.toggle.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  onTypeChange() {
    this.setState({saving: true});

    if (this.state.chosenType.type !== this.props.question.type) {
      this.props.changeQuestionType({id: this.props.question.id, data: {type: this.state.chosenType.type, ...this.state.chosenType.options}});
    } else {
      this.props.updateQuestion({id: this.props.question.id, question: {...this.state.chosenType.options}}).then(() => this.setState({saving: false, warningModal: false}))
    }
  }

  onSelect(chosenType) {
    this.setState({chosenType});
    this.toggle('warningModal');
    this.toggle('typeModal');
  }

  render() {
    const {question} = this.props;

    const warningModal = <Modal isOpen={this.state.warningModal} toggle={() => this.toggle('warningModal')}>
      <ModalHeader toggle={() => this.toggle('warningModal')} tag="h2"><FormattedMessage id="question.headerActions.buttonType.modal.modalHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="question.headerActions.buttonType.modal.modalBody"/></p>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.yes">{
              (message) => <LaddaButton
                className="btn btn-danger btn-ladda mb-0 ml-3"
                loading={this.state.saving}
                data-style={ZOOM_OUT}
                onClick={this.onTypeChange}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" onClick={() => this.toggle('warningModal')} className="mb-0 ml-3">{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <div className="dropdown-labels" onClick={() => this.toggle('typeModal')}>
        <span className="violet d-flex align-items-center" id={'question-type-tag-' + question.id}>
          <QuestionInfo question={question} />
          <Tooltip
            delay={0}
            placement="bottom"
            isOpen={this.state.tooltip}
            target={'question-type-tag-' + question.id}
            toggle={() => this.toggle('tooltip')}
          >
            <FormattedMessage id={`_.question.${getQuestionTemplateName(question)}.typeName`}/>
          </Tooltip>
        </span>
        {this.state.typeModal && <QuestionTypesModal isOpen={this.state.typeModal} toggle={() => this.toggle('typeModal')} onSelect={this.onSelect}/>}
        {warningModal}
      </div>
    )
  }
}

ButtonType.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(null, {changeQuestionType, updateQuestion, setPlanRestrictionsModals})(injectIntl(ButtonType));