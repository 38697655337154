import {schema} from 'normalizr';

import schemaSurvey from "../survey/schema";
import schemaFiltersQuestion from "../analysis/filterQuestion/schema";
import schemaFiltersCollector from "../analysis/filterCollector/schema";
import schemaQuestionSummaries from "../analysis/questionSummary/schema";
import schemaShares from "../analysis/shares/schema";

export default new schema.Entity('analysis', {
  survey: schemaSurvey,
  filtersQuestion: [schemaFiltersQuestion],
  questionSummaries: [schemaQuestionSummaries],
  filterCollectors: [schemaFiltersCollector],
  shares: [schemaShares],
});