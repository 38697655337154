import React, {Component} from 'react';
import {Switch, Route} from "react-router-dom";
import appRoutes from "../../../../appRoutes";
import NavigationAdmin from "./NavigationAdmin"
import NavigationSurvey from "./NavigationSurvey"
import NavigationDefault from "./NavigationDefault"
import PropTypes from "prop-types";

class Navigation extends Component {

  render() {
    const {location} = this.props;

    return (
      <React.Fragment>
        <div className="collapse navbar-collapse nav-wrap mainNav align-items-baseline" id="mainNav">
          <Switch>
            <Route location={location} path={appRoutes.admin.main} children={<NavigationAdmin />}/>
            <Route location={location} path={appRoutes.survey.main()} children={<NavigationSurvey />}/>
            <Route location={location} path={appRoutes.home} children={<NavigationDefault />}/>
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

Navigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default Navigation;
