import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LoadingSpinner from "components/LoadingSpinner";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import history from "services/history";
import appRoutes from "appRoutes";
import {Link} from "react-router-dom";
import api from "api";
import PlanForm from "./PlanForm";
import CardFooter from "reactstrap/es/CardFooter";


class PlanEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      errors: {},
    };

    this.savePlan = this.savePlan.bind(this);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.setState({
      loading: true
    });

    api.plan.get.plan(this.props.match.params.plan)
      .then(res => {
        this.setState({
          data: res.data.plan,
          loading: false,
        })
      })
  }

  savePlan(){
    const data = this.form.current.state.data;

    console.log('data', data);

    this.setState({
      saving: true,
    });

    api.plan.patch({
      id: this.props.match.params.plan,
      data,
    })
    .then(res => {
      this.setState({
        saving: false,
      });
      history.push(appRoutes.admin.settings.plans.list);
    })
    .catch(res => {
      this.setState({
        saving: false,
        errors: res.response.data.form.errors,
      });
    })
    ;
  }

  render() {
    const {errors, loading, saving, data} = this.state;

    if(loading){
      return <LoadingSpinner />
    }

    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader><h2><FormattedMessage id="admin.planEdit.cardHeader">{msg => msg + ': ' + data.name}</FormattedMessage></h2></CardHeader>
            <hr className="my-0" />
            <CardBody>
              <PlanForm
                ref={this.form}
                errors={errors}
                plan={data}
                handleSubmit={() => this.savePlan()}
              />
            </CardBody>
            <CardFooter>
              <Link to={appRoutes.admin.settings.plans.list}>
                <Button color="secondary">Wróć do listy planów</Button>
              </Link>
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda float-right"
                  loading={saving}
                  data-style={ZOOM_OUT}
                  onClick={this.savePlan}
                >{message}</LaddaButton>
              }</FormattedMessage>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default PlanEdit;