import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RedirectEditButton from './components/RedirectEditButton';
import RedirectDeleteButton from './components/RedirectDeleteButton';

class RedirectsListActions extends Component {

  render() {
    return (
      <React.Fragment>
        <RedirectEditButton redirect={this.props.redirect} onUpdate={this.props.onChange}/>
        <RedirectDeleteButton redirect={this.props.redirect} onDelete={this.props.onChange}/>
      </React.Fragment>
    );
  }
}

RedirectsListActions.propTypes = {
  redirect: PropTypes.shape({
    id: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    target: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default RedirectsListActions
