import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Row, Col, Card, CardHeader, CardBody, Button, Input} from 'reactstrap';
import Select from 'react-select';
import api from 'api';
import LoadingSpinner from "components/LoadingSpinner";
import RespondentsStatsData from './RespondentsStatsData';
import axios from "axios";
import moment from "moment";
import InputNumber from "../../../../components/InputNumber";


const CancelToken = axios.CancelToken;

class RespondentsStats extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			axiosSource: false,
			year: parseInt(moment().format('Y')),
			month: moment().format('MM'),
			ageFrom: 0,
			ageTo: 100,
			data: null
		};

		this.cancelAxiosToken = this.cancelAxiosToken.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.fetchData = this.fetchData.bind(this);
	}

	componentWillUnmount() {
		this.cancelAxiosToken();
	}

	cancelAxiosToken() {
		if (this.state.axiosSource !== false) {
			this.state.axiosSource.cancel();
		}
	}

	fetchData() {
		this.cancelAxiosToken();

		const data = {
			year: this.state.year,
			month: this.state.month,
			ageFrom: this.state.ageFrom,
			ageTo: this.state.ageTo
		};

		this.setState({
			loading: true,
			axiosSource: CancelToken.source(),
		}, () => {
			api.user.get.respondentsStats(data, this.state.axiosSource)
				.then(data => {
					this.setState({loading: false, data});
				})
				.catch(data => {
					this.setState({loading: false, data: null});
				});
		});
	}

	onFilterChange(e, type) {
		this.setState({[type]: e ? e.value : null})
	}

	onChangeAge(e, type) {
		this.setState({[type]: e})
	}

	render() {
		const months = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];
		const monthOptions = _.map(months, (month, val) => {
			if ((val + 1).toString().length === 1) {
				val = '0' + (val + 1);
			}

			return {label: month, value: val}
		});

		const yearsOptions = [];
		for (let i = new Date().getFullYear(); i >= 2011; i--) {
			yearsOptions.push({label: i, value: i});
		}

		return (
			<Row className="animated fadeIn">
				<Col>
					<Card>
						<CardHeader>
							<div className="direction-group">
								<div className="direction-left d-flex justify-content-start">
									<h2 className="float-left mr-4">
										<FormattedMessage id="admin.respondentsStats.header"/>
									</h2>
								</div>
								<div className="w-100 w-lg-25 float-right">
									<Select
										placeholder="Dowolny miesiąc"
										value={_.find(monthOptions, month => month.value === this.state.month)}
										onChange={(e) => this.onFilterChange(e, 'month')}
										options={monthOptions}
										isClearable
									/>
								</div>
								<div className="w-100 w-lg-25 float-right">
									<Select
										placeholder="Dowolny rok"
										value={_.find(yearsOptions, year => year.value === this.state.year)}
										onChange={(e) => this.onFilterChange(e, 'year')}
										options={yearsOptions}
										isClearable
									/>
								</div>
								<div className="w-100 w-lg-100 float-right">
									<div className="direction-left d-flex justify-content-start">
										<h2 className="float-left mr-4">
											<FormattedMessage id="admin.respondentsStats.age"/>
										</h2>
									</div>
									<div className="w-100 w-lg-25 float-right">
										<p className="mb-0">Wiek maksymalny</p>
										<InputNumber
											min={0} max={100} value={this.state.ageTo}
											onChange={(value) => this.onChangeAge(value, 'ageTo')}
										/>
									</div>
									<div className="w-100 w-lg-25 float-right">
										<p className="mb-0">Wiek minimalny</p>
										<InputNumber
											min={0} max={100} value={this.state.ageFrom}
											onChange={(value) => this.onChangeAge(value, 'ageFrom')}
										/>
									</div>
								</div>
							</div>
						</CardHeader>
						<Button
							color="primary" onClick={this.fetchData} className="mx-auto my-5 d-block"
						>Pokaż statystyki</Button>
						<hr className="my-0"/>
						<CardBody>
							<Row>
								<Col>
									{this.state.loading
										? <LoadingSpinner/>
										: this.state.data ? <RespondentsStatsData data={this.state.data}/> : <div/>
									}
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		)
	}
}

export default RespondentsStats;