import React, {Component} from 'react';
import {PropTypes} from "prop-types";
import {Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import api from "api";
import LoadingSpinner from "components/LoadingSpinner";

class TabSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      stats: false
    }
  }

  componentDidMount() {
    api.surveyCollector.panel.get.stats(this.props.collector).then(stats => {
      this.setState({
        stats
      })
    })
  }

  render() {
    const {collector} = this.props;
    const {stats} = this.state;

    if(stats === false){
      return <LoadingSpinner/>
    }

    return (
      <React.Fragment>
        <Row>
          <Col lg={4} className="d-flex align-items-md-stretch">
            <Card className="w-100 border-l orange">
              <CardHeader><h2>Respondenci</h2></CardHeader>
              <hr className="my-0" />
              <CardBody>
                <ListGroup>
                  <ListGroupItem>Liczba: {stats.respondentsCount}</ListGroupItem>
                  <ListGroupItem>Wysłanych zaproszeń: {stats.sentCount}</ListGroupItem>
                  <ListGroupItem>W trakcie wysyłki: {stats.inSendCount}</ListGroupItem>
                  <ListGroupItem>Otwartych wiadomości: {stats.displayedCount}</ListGroupItem>
                  <ListGroupItem>Łącznie wyświetleń wiadomości: {stats.displayedCountSum}</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} className="d-flex align-items-md-stretch">
            <Card className="w-100 border-l violet">
              <CardHeader><h2>Wypełnienia</h2></CardHeader>
              <hr className="my-0" />
              <CardBody>
                <ListGroup>
                  <ListGroupItem>Zakończone: {stats.finishedCount} ({(((stats.finishedCount / stats.respondentsCount) || 0) * 100).toFixed(2)} %)</ListGroupItem>
                  <ListGroupItem>Screenout: {stats.screenoutCount} ({(((stats.screenoutCount / stats.respondentsCount) || 0) * 100).toFixed(2)} %)</ListGroupItem>
                  <ListGroupItem>Przerwane: {stats.breakCount} ({(((stats.breakCount / stats.respondentsCount) || 0) * 100).toFixed(2)} %)</ListGroupItem>
                  <ListGroupItem>Brak kontaktu: {stats.notStartedCount} ({(((stats.notStartedCount / stats.respondentsCount) || 0) * 100).toFixed(2)} %)</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} className="d-flex align-items-md-stretch">
            <Card className="w-100 border-l blue">
              <CardHeader><h2>Ustawienia</h2></CardHeader>
              <hr className="my-0" />
              <CardBody>
                <ListGroup>
                  <ListGroupItem>Max reminderów: {collector.sendEmail.reminder.count}</ListGroupItem>
                  <ListGroupItem>Wyślij po upływie: {collector.sendEmail.reminder.sendAfter} godz.</ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

TabSummary.propTypes = {
  collector: PropTypes.shape({
    sendEmail: PropTypes.shape({
      reminder: PropTypes.shape({
        count: PropTypes.number.isRequired,
        sendAfter: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default TabSummary;