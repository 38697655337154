import React, { Component } from 'react';
import {Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import HelpModeIconModal from "components/HelpModeIconModal";
import RedirectsList from './components/RedirectsList';
import RedirectsCreateModal from "./components/RedirectsCreateModal";
import RedirectsUploadCsvModal from "./components/RedirectsUploadCsvModal";
import api from "api";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import SurveyRedirectsAvailable from "../../../../../components/PlanRestrictionsModals/modals/SurveyRedirectsAvailable";

class Redirects extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dropdown: false,
      redirects: [],
      createModal: false,
      uploadCsvModal: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    api.survey.get.redirects(this.props.surveyId).then(redirects => {
      this.setState({loading: false, redirects})
    })
  }

  handleChange() {
    this.setState({loading: true});

    api.survey.get.redirects(this.props.surveyId).then(redirects => {
      this.setState({loading: false, redirects})
    })
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]});
  }

  render() {
    return (
      <div className="d-print-none">
        <Card className="border-l">
          <CardHeader>
            <div className="float-left" data-toggle="collapse" data-target="#collapse2">
              <h4 className="float-left">
                <FormattedMessage id="surveyStructure.redirects.cardHeader"/>
                <HelpModeIconModal className="ml-2" modalHeader={<FormattedMessage id="surveyStructure.redirects.helpModeModal.modalHeader" />}><FormattedMessage id="surveyStructure.redirects.helpModeModal.modalBody" /></HelpModeIconModal>
              </h4>
            </div>
            <Dropdown
              className="float-right"
              isOpen={this.state.dropdown}
              toggle={() => this.toggle('dropdown')}
            >
              <DropdownToggle color="">
                <span className="fas fa-ellipsis-v pt-2 hoverable" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag="a" onClick={() => this.props.surveyPlan.redirects ? this.toggle('createModal') : this.props.setPlanRestrictionsModals(SurveyRedirectsAvailable.getName())}>
                  <p><span className='fas fa-plus align-middle'/></p> <small className="align-middle"><FormattedMessage tagName="h5" id="redirect.actions.create.button"/></small>
                </DropdownItem>
                <DropdownItem tag="a" onClick={() => this.props.surveyPlan.redirects ? this.toggle('uploadCsvModal') : this.props.setPlanRestrictionsModals(SurveyRedirectsAvailable.getName())}>
                  <p><span className='fas fa-plus align-middle'/></p> <small className="align-middle"><FormattedMessage tagName="h5" id="redirect.actions.uploadCsv.button"/></small>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <RedirectsList redirects={this.state.redirects} handleChange={this.handleChange} loading={this.state.loading}/>
          </CardBody>
        </Card>
        <RedirectsCreateModal surveyId={this.props.surveyId} handleCreate={this.handleChange} isOpen={this.state.createModal} toggleModal={() => this.toggle('createModal')}/>
        <RedirectsUploadCsvModal surveyId={this.props.surveyId} handleCreate={this.handleChange} isOpen={this.state.uploadCsvModal} toggleModal={() => this.toggle('uploadCsvModal')}/>
      </div>
    );
  }
}

Redirects.propTypes = {
  surveyId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(Redirects)
