import React, {Component} from "react";
import {Table} from "reactstrap"
import PropTypes from "prop-types"
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {updatePage} from "scenes/Survey/Structure/data/pages/actions";

class HideSurveyNavigationButtonsTable extends Component {
  constructor(props){
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(id, key, value) {
    this.props.updatePage({
      id: id,
      page: {
        [key]: !value
      }
    });
  }

  render() {
    const {pages} = this.props;

    return (
      <Table style={{'width': '192px'}}>
        <thead>
          <tr>
            <th><small><FormattedMessage id="block.settings.table.header.previous.name"/></small></th>
            <th><small><FormattedMessage id="block.settings.table.header.clear.name"/></small></th>
            <th><small><FormattedMessage id="block.settings.table.header.next.name"/></small></th>
            <th><small><FormattedMessage id="block.settings.table.header.page.name"/></small></th>
          </tr>
        </thead>
        <tbody>
          {
            pages.map(page => {
              let valuePrev = page.hideNavigationPreviousButton;
              let valueClear = page.hideNavigationClearButton;
              let valueNext = page.hideNavigationNextButton;

              return (
                <tr key={page.id}>
                  <td>
                    <input type="checkbox" checked={valuePrev} onChange={() => this.handleClick(page.id, 'hideNavigationPreviousButton', valuePrev)}/>
                  </td>
                  <td>
                    <input type="checkbox" checked={valueClear} onChange={() => this.handleClick(page.id, 'hideNavigationClearButton', valueClear)}/>
                  </td>
                  <td>
                    <input type="checkbox" checked={valueNext} onChange={() => this.handleClick(page.id, 'hideNavigationNextButton', valueNext)}/>
                  </td>
                  <td className="text-center"><small>{page.positionInSurvey+1}</small></td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    );
  }

}

HideSurveyNavigationButtonsTable.propTypes = {
  block: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      randQuestions: PropTypes.number.isRequired,
      timeMax: PropTypes.number.isRequired,
      timeMin: PropTypes.number.isRequired,
      rotateQuestions: PropTypes.bool.isRequired,
      hideNavigationClearButton: PropTypes.bool.isRequired,
      hideNavigationNextButton: PropTypes.bool.isRequired,
      hideNavigationPreviousButton: PropTypes.bool.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.number,
      ),
    })
  ).isRequired,
  updatePage: PropTypes.func.isRequired,
};

export default connect(null, {updatePage})(HideSurveyNavigationButtonsTable);
