import React, {Component} from 'react';
import api from 'api';
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import {Col, Form, FormGroup, Input, Label} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";

class FormCollectorPanelUsersExcluded extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      saving: false,
      editingValue: false,
      data: {
        collectorPanelUsersExcluded: []
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });

    api.user.get.collectorPanelUsersExcluded()
    .then(data => {
      this.setState({
        loading: false,
        data: {
          collectorPanelUsersExcluded: data.collectorPanelUsersExcluded,
        }
      });
    })
    .catch(res => {
      this.setState({
        loading: false,
      });
    })
  }

  onSubmit(e) {
    e.preventDefault();

    const {data} = this.state;

    this.setState({
      saving: true,
    });

    api.user.post.collectorPanelUsersExcluded(data)
    .then(data => {
      this.setState({
        saving: false,
        data: {
          collectorPanelUsersExcluded: data.collectorPanelUsersExcluded,
        }
      });
    })
    .catch(res => {
      this.setState({
        saving: false,
      });
    })
  }

  render() {
    const {saving, data, editingValue, loading} = this.state;

    if(loading){
      return <LoadingSpinner />
    }

    return (
      <Form onSubmit={this.onSubmit}>
        <FormGroup row>
          <Label md={4}>Lista zablokowanych panelistów ({data.collectorPanelUsersExcluded.length}/6000)</Label>
          <Col md={8}>
            <Input
              type="textarea"
              value={editingValue === false ? data.collectorPanelUsersExcluded.join(',') : editingValue}
              onFocus={() => this.setState({editingValue: data.collectorPanelUsersExcluded.join(',')})}
              onChange={e => this.setState({editingValue: e.target.value})}
              onBlur={() => this.setState({
                data: {
                  collectorPanelUsersExcluded: editingValue.split(','),
                },
                editingValue: false,
              })} />
          </Col>
        </FormGroup>
        <FormGroup>
          <LaddaButton
            className="btn btn-primary btn-ladda d-block ml-auto"
            loading={saving}
            data-style={ZOOM_OUT}
          >Zapisz</LaddaButton>
        </FormGroup>
      </Form>
    );
  }
}

export default FormCollectorPanelUsersExcluded;
