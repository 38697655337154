import React from 'react';
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import { connect } from 'react-redux';

function HelpModeIconModal(props) {
  if (!props.helpMode) {
    return null;
  }

  const [modal, setModal] = React.useState(false);

  return (
    <React.Fragment>
      <i className={"pointer text-info " + props.className + " " + props.icon} onClick={() => setModal(!modal)}/>

      <Modal isOpen={modal} toggle={() => setModal(!modal)} size={props.size}>
        <ModalHeader toggle={() => setModal(!modal)} tag="h2">{props.modalHeader}</ModalHeader>
        <hr className="my-0" />
        <ModalBody>{props.children}</ModalBody>
      </Modal>
    </React.Fragment>
  );
}

HelpModeIconModal.defaultProps = {
  icon: 'far fa-question-circle',
  modalHeader: 'Pomoc',
  size: 'md'
};

HelpModeIconModal.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  modalHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

function mapStateToProps(state) {
  return {
    helpMode: state.user.helpMode
  }
}

export default connect(mapStateToProps)(HelpModeIconModal);