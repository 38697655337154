import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";

class ButtonExcludedFromRotate extends Component{
  constructor(props) {
    super(props);
    this.state = {
      tooltipExcludedFromRotate: false,
      excludedFromRotate: props.column.excludedFromRotate
    };

    this.onClick = this.onClick.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipExcludedFromRotate: !this.state.tooltipExcludedFromRotate
    });
  }

  onClick() {
    this.setState({...this.state, excludedFromRotate: !this.state.excludedFromRotate});
    this.props.toggleOption('excludedFromRotate');
  }

  render() {
    const {column} = this.props;
    const {tooltipExcludedFromRotate, excludedFromRotate} = this.state;

    return (
      <a id={'excludedFromRotate-' + column.id + '-column'} className={"fas fa-random ml-2" + (excludedFromRotate ? " text-primary" : "")} onClick={this.onClick}>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltipExcludedFromRotate}
          target={'excludedFromRotate-' + column.id + '-column'}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="columns.excludedFromRotate.button.tooltip"/>
        </Tooltip>
      </a>
    )
  }
}

ButtonExcludedFromRotate.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.number.isRequired,
    excludedFromRotate: PropTypes.bool.isRequired,
  }).isRequired,
  toggleOption: PropTypes.func.isRequired
};

export default ButtonExcludedFromRotate;