import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Tooltip} from "reactstrap";
import classnames from "classnames";
import InputNumber from "components/InputNumber";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";

class RandAnswers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: {
        randAnswers: props.question.randAnswers,
      },
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(value){
    if (this.props.disabled) {
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());
      return;
    }

    if(!value || value < 0){
      value = 0;
    }

    this.setState({
      question: {
        ...this.state.question,
        randAnswers: value
      }
    },
      () => this.props.handleUpdate(this.state.question)
    );
  }

  render() {
    let { question, tooltip } = this.state;

    return (
      <div
        id={"randAnswers-" + this.props.question.id}
        className={classnames({
          "min-w-0 float-left mr-1 mb-1": true,
          "w-auto": !question.randAnswers,
          "w-100 w-lg-auto input-group": question.randAnswers
        })}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": question.randAnswers > 0,
              "disabled": this.props.disabled
            })}
            color="secondary"
            onClick={() => {
              if(question.randAnswers === 0){
                this.onChange(this.props.question.answers.length)
              }else{
                this.onChange(0)
              }
            }}
          >
            <i className="fa-solid fa-dice" />
          </Button>
        </InputGroupAddon>
        {question.randAnswers > 0 && <InputNumber value={question.randAnswers} min={0} max={this.props.question.answers.length} onChange={this.onChange} className={this.props.disabled ? "not-active" : ""}/>}
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={"randAnswers-" + this.props.question.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="question.settings.randAnswers.button.tooltip"/>
        </Tooltip>
      </div>
    )
  }
}

RandAnswers.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answers: PropTypes.array.isRequired,
    randAnswers: PropTypes.number.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default connect(null, {setPlanRestrictionsModals})(RandAnswers);
