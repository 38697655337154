import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import classnames from "classnames";
import Select from "react-select";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";

class ColumnThead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      filterExcluding: false,
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterExcludingChange = this.onFilterExcludingChange.bind(this);
  }

  componentWillUnmount() {
    const {question} = this.props;

    this.props.onFilterChange('q' + question.id, false);
    this.props.onFilterChange('q' + question.id + 'excl', false);
  }

  static getColSpan(question){
    return (question.rows.length * question.columns.length) + question.rows.filter(row => row.isOpen).length + question.excludings.length;
  }

  onFilterChange(rowId, columnId, option){
    let {filter} = this.state;
    const {question} = this.props;

    if(option === null){
      delete filter[[rowId, columnId].join('-')];
    }else{
      filter[[rowId, columnId].join('-')] = option.value;
    }

    this.setState({
      filter
    }, () => {
      const key = 'q' + question.id;

      this.props.onFilterChange(key, (this.state.filter === false || _.isEmpty(this.state.filter)) ? false : {
          question: question.id,
          answers: this.state.filter,
        }
      );
    });
  }

  onFilterExcludingChange(value){
    const {question} = this.props;

    if(value.length === 0){
      value = false;
    }

    this.setState({
      filterExcluding: value
    }, () => {
      const key = 'q' + question.id + 'excl';

      this.props.onFilterChange(key, this.state.filterExcluding === false ? false : {
          question: question.id,
          excludings: this.state.filterExcluding,
        }
      );
    });
  }

  render() {
    const {filter, filterExcluding} = this.state;
    const {question} = this.props;

    return <React.Fragment>
      {question.rows.map((row, key) => {
        return <React.Fragment key={key.toString()}>
          {question.columns.map((column, keyColumn) => <th
            key={keyColumn.toString()}
            className={classnames({
              'text-center pointer': true,
              'border-left': key === 0 && keyColumn === 0,
              'border-right': key === ((question.rows.length * question.columns.length)-1) && !row.isOpen,
            })}
            onClick={() => filter[[row.id, column.id].join('-')] === undefined && this.setState({filter: {...filter, [[row.id, column.id].join('-')]: 1}}) }
          >
            <div className={classnames({
              'd-flex h-100 text-center': true,
              'flex-column': filter[[row.id, column.id].join('-')] !== undefined,
              'align-items-center': filter[[row.id, column.id].join('-')] === undefined,
            })}>
              <div className="w-100">{striptags(row.content)} - {striptags(column.content)}</div>
              {filter[[row.id, column.id].join('-')] !== undefined && <Select
                isClearable={true}
                options={[
                  {
                    label: 'Wybrał',
                    value: 1,
                  },
                  {
                    label: 'Nie wybrał',
                    value: 0,
                  },
                ]}
                onChange={option => this.onFilterChange(row.id, column.id, option)}
              />}
            </div>
          </th>)}
          {/*{row.isOpen && <th className={classnames({*/}
          {/*  'text-center': true,*/}
          {/*  'border-left': key === 0,*/}
          {/*  'border-right': key === ((question.rows.length * question.columns.length)-1),*/}
          {/*})}>*/}
          {/*  <div className={classnames({*/}
          {/*    'd-flex h-100 text-center': true,*/}
          {/*  })}>{striptags(row.content)} - Odpowiedź wpisana</div>*/}
          {/*</th>}*/}
        </React.Fragment>
      })}
      {question.excludings.length > 0 && <th
        className="text-center border-left border-right pointer"
        onClick={() => filterExcluding === false && this.setState({filterExcluding: null})}
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filter !== false,
          'align-items-center': filter === false,
        })}>
          <div className="w-100">Wykluczająca</div>
          {filterExcluding !== false && <SelectExcludings
            isClearable={true}
            isMulti={true}
            value={filterExcluding}
            questionId={question.id}
            onChange={this.onFilterExcludingChange}
          />}
        </div>
      </th>}
    </React.Fragment>;
  }
}

ColumnThead.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['matrixMulti']),
    rows: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
    columns: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
    excludings: PropTypes.array,
  }).isRequired,
};

export default ColumnThead;