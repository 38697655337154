import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";

export const TOKEN_FIELDS = [
	{
		label: 'Pole1',
		value: 'field1'
	},
	{
		label: 'Pole2',
		value: 'field2'
	},
	{
		label: 'Pole3',
		value: 'field3'
	},
	{
		label: 'Pole4',
		value: 'field4'
	},
	{
		label: 'Pole5',
		value: 'field5'
	},
	{
		label: 'Pole6',
		value: 'field6'
	},
	{
		label: 'Pole7',
		value: 'field7'
	},
	{
		label: 'Pole8',
		value: 'field8'
	},
	{
		label: 'Pole9',
		value: 'field9'
	},
	{
		label: 'Pole10',
		value: 'field10'
	},
	{
		label: 'Pole11',
		value: 'field11'
	},
	{
		label: 'Pole12',
		value: 'field12'
	},
	{
		label: 'Pole13',
		value: 'field13'
	},
	{
		label: 'Pole14',
		value: 'field14'
	},
	{
		label: 'Pole15',
		value: 'field15'
	}
];

const SelectTokenFields = props => {
	const [value, setValue] = React.useState(props.field);

	const onChange = (field) => {
		setValue(field);
		props.onChange(field)
	}

	return (
		<Select
			placeholder="Wybierz pole"
			closeMenuOnSelect={false}
			isOpen={false}
			options={TOKEN_FIELDS}
			onChange={field => onChange(field.value)}
			value={TOKEN_FIELDS.find(field => field.value === value)}
		/>
	);
};

SelectTokenFields.propTypes = {
	onChange: PropTypes.func.isRequired,
	field: PropTypes.string
};

export default SelectTokenFields;
