import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import { connect } from 'react-redux';
import FilterSerial from "./components/FilterSerial";
import {addFilterSerial} from "scenes/Survey/Structure/data/filterSerial/actions";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import FiltersAvailable from "components/PlanRestrictionsModals/modals/FiltersAvailable";
import classnames from 'classnames';
import _ from "lodash";

class QuestionFrom extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      tooltip: false,
      adding: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleModal() {
    this.setState({modal: !this.state.modal})
  }

  addFilter() {
    this.setState({...this.state, adding: true});
    const filterByKeys = ['answers', 'rows', 'columns'];

    let data = {
      action: 1,
      name: 'Filtr seryjny',
      hideQuestionToCondsMin: 0,
      questionFrom: {
        question: this.props.question.id,
        answers: [],
        rows: [],
        columns: [],
        filterBy: this.props.filterBy || null
      },
      questionTo: null,
    };
    this.props.addFilterSerial({surveyId: this.props.survey.id, filterSerial: data})
      .then(() => this.setState({...this.state, adding: false}));
  }

  onClick() {
    this.props.plan.survey.filters
      ? this.toggleModal()
      : this.props.setPlanRestrictionsModals(FiltersAvailable.getName());
  }

  render() {
    const {tooltip, adding} = this.state;
    const {survey, questions, filterSerial, filterBy} = this.props;

    let filtersRender = _.map(filterSerial, (filter, i) => {
      return <FilterSerial filter={filter} surveyId={survey.id} questions={questions} key={filter.id} filterBy={filterBy} questionFrom={this.props.question} position={++i}/>
    });

    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="question.filterSerial.questionFrom.modalHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        {filtersRender.length ? filtersRender : <Alert color="warning" className="text-center">Brak filtrów</Alert>}
        {questions.length === 0 && <span className="small text-danger"><FormattedMessage id="question.filterSerial.questionFrom.noQuestionToAvailable"/></span>}
      </ModalBody>
      <hr className="my-0" />
      <ModalFooter>
        <div className="d-flex justify-content-between w-100">
          <FormattedMessage id="question.filterSerial.questionFrom.button.create">{
            (message) => <LaddaButton
              className="btn btn-success mr-auto btn-ladda mb-0"
              loading={adding}
              data-style={ZOOM_OUT}
              onClick={this.addFilter}
              disabled={questions.length === 0}
            >
              <i className="fas fa-plus" /> {message}
            </LaddaButton>}
          </FormattedMessage>
          <FormattedMessage id="_.button.close">{
            (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
          }</FormattedMessage>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <span id={'questionFrom-' + this.props.question.id + '-serialFilter-' + (filterBy ? filterBy : '')} onClick={this.onClick} className="d-none d-lg-inline">
          <i className={classnames({
            'fas fa-sign-out-alt pointer': true,
            'hoverable' : this.props.filterSerial.length === 0,
            'text-primary': this.props.filterSerial.length > 0
          })}/>
          <Tooltip
            delay={0}
            placement="bottom"
            isOpen={tooltip}
            target={'questionFrom-' + this.props.question.id + '-serialFilter-' + (filterBy ? filterBy : '')}
            toggle={this.toggleTooltip}
          >
            <FormattedMessage id="question.filterSerial.questionFrom.button.tooltip"/>
          </Tooltip>
        </span>
        {modal}
      </React.Fragment>
    )
  }
}

QuestionFrom.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    filterSerialQuestionFrom: PropTypes.array
  }).isRequired,
  filterSerial: PropTypes.array.isRequired,
  setPlanRestrictionsModals: PropTypes.func.isRequired,
  filterBy: PropTypes.string
};

function mapStateToProps(state) {
  let enableQuestionTypes = ['single', 'multi', 'matrix', 'ranked', 'matrixMulti', 'matrixDropdown', 'numericMulti'];
  let questionList = [];

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          let question = state.survey.structure.data.questions[questionId];

          if (enableQuestionTypes.includes(question.type))
            questionList.push(question)
        })
      });
    });
  });

  return {
    survey: state.survey.structure.data.survey,
    questions: questionList,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {addFilterSerial, setPlanRestrictionsModals})(QuestionFrom);
