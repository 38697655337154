import React, {Component} from 'react';
import PropTypes from "prop-types";

class UserAvatar extends Component {
  constructor(props) {
    super(props);

    this.getName = this.getName.bind(this);
  }

  getName(){
    const {user} = this.props;
    if(!user.firstname && !user.lastname){
      return user.email ? user.email.substr(0, 1) : '';
    }

    let name = [];
    if(user.firstname){
      name.push(user.firstname);
    }
    if(user.lastname){
      name.push(user.lastname);
    }

    return name.join(' ');
  }

  render() {
    const {size, user} = this.props;

    let style = {
      ...this.props.style,
      height: size+'px',
      width: size+'px',
    };

    return (
      <img
        src={user.avatar || 'https://eu.ui-avatars.com/api/?name=' + this.getName()}
        className="rounded-img"
        style={style}
      />
    );
  }
}

UserAvatar.defaultProps = {
  size: 30,
  style: {}
};

UserAvatar.propTypes = {
  style: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default UserAvatar;