import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from "../../../components/Tooltip";
import {connect} from "react-redux";

const SurveyResponsesCount = ({survey, plan}) => {
  let content = survey.endResponsesCount;

  if (survey.screenOutResponsesCount > 0 || survey.quotaResponsesCount > 0)
    content += ' (+' + (survey.screenOutResponsesCount + survey.quotaResponsesCount) + ' odrzuconych)';

  let displayWarningBreaksResponses = false;
  if(plan.surveysBreakWarning){
    displayWarningBreaksResponses = survey.responsesCount > 100 && (((survey.breakResponsesCount / survey.responsesCount) * 100) > 15);
  }

  return <div className="d-flex flex-row align-items-center justify-content-center">
    {displayWarningBreaksResponses && <React.Fragment>
      <i id={`s-${survey.id}-breaks-warning`} className="fas fa-exclamation-triangle text-danger mr-2" />
      <Tooltip id={`s-${survey.id}-breaks-warning`} msg="Uwaga - duża liczba przerwań ankiety!" />
    </React.Fragment>}
    <span><span className="d-inline-block d-md-none">Wywiady: </span> <span>{content}</span></span>
  </div>;
};

SurveyResponsesCount.propTypes = {
  survey: PropTypes.shape({

  }).isRequired,
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps)(SurveyResponsesCount);
