import React, {Component} from 'react';
import ChangePasswordAction from './Actions/ChangePasswordAction';
import PropTypes from 'prop-types';
import EditAction from "./Actions/EditAction";
import NoteAction from "./Actions/NoteAction";
import {FormattedMessage} from "react-intl";
import {UncontrolledTooltip} from "reactstrap";
import DeleteUserAction from "./Actions/DeleteUserAction";

class CompaniesActions extends Component {

  render() {
    return (
      <div>
        <NoteAction user={this.props.user} handleChange={this.props.handleChange}/>
        <EditAction user={this.props.user} handleChange={this.props.handleChange}/>
        <ChangePasswordAction user={this.props.user}/>
        {!this.props.user.isBanned && <React.Fragment>
          <i id={'banUser-' + this.props.user.id} className="ml-2 fas fa-lock pointer" onClick={() => this.props.toggleBan(this.props.user.id)}/>
          <UncontrolledTooltip target={'banUser-' + this.props.user.id} placement="bottom"><FormattedMessage id="admin.respondents.userBulkBanModal.ban" /></UncontrolledTooltip>
        </React.Fragment>}

        {this.props.user.isBanned && <React.Fragment>
          <i id={'unbanUser-' + this.props.user.id} className="ml-2 fas fa-lock-open pointer" onClick={() => this.props.toggleUnban(this.props.user.id)}/>
          <UncontrolledTooltip target={'unbanUser-' + this.props.user.id} placement="bottom"><FormattedMessage id="admin.respondents.userBulkUnbanModal.unban" /></UncontrolledTooltip>
        </React.Fragment>}
        <DeleteUserAction user={this.props.user} handleChange={this.props.handleChange} />
      </div>
    )
  }
}

CompaniesActions.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default CompaniesActions;