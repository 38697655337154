import React from 'react';
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import { connect } from 'react-redux';
import {cancelSubscription} from "reducers/user/actions";

const PlanSubscription = (props) => {
  const [modal, setModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const cancelSubscription = () => {
    setDeleting(true);

    props.cancelSubscription().then(() => {
      setDeleting(false);
      setModal(false);
    })
  };

  if (!props.userPlan.isSubscribed) {
    return null;
  }

  return (
    <Alert color="warning">
      <FormattedMessage id="planSubscription.info" />
      <Button color="danger" size="sm" className="ml-3" onClick={() => setModal(!modal)}><FormattedMessage id="planSubscription.modal.button" /></Button>

      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)} tag="h2"><FormattedMessage id="planSubscription.modal.header" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody><FormattedMessage id="planSubscription.modal.body" /></ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.modal.delete.confirmation">{
            (message) => <LaddaButton
              className="btn btn-danger btn-ladda"
              loading={deleting}
              data-style={ZOOM_OUT}
              onClick={() => cancelSubscription()}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={() => setModal(!modal)}>{message}</Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    </Alert>
  );
};

PlanSubscription.propTypes = {
  userPlan: PropTypes.shape({
    isSubscribed: PropTypes.bool.isRequired
  }).isRequired
};

export default connect(null, {cancelSubscription})(PlanSubscription);