import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, InputGroup, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PointsList from './PointsList';
import SelectPointsType from 'components/SelectPointsType';
import SelectPointsWhat from 'components/SelectPointsWhat';
import Select from "react-select";
import PointCreateButton from "./PointCreateButton";

class Points extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        users: "",
        survey: "",
        type: null,
        what: null,
        period: 7
      }
    };

    this.onChangeUserIds = this.onChangeUserIds.bind(this);
    this.onChangeSurveyId = this.onChangeSurveyId.bind(this);
  }

  onChangeUserIds(e) {
    let ids = e.target.value.replace(/\s/g, '').split(',').filter(Boolean);
    this.setState({filters: {...this.state.filters, users: ids}});
  }

  onChangeSurveyId(e) {
    let id = e.target.value.replace(/^\W/g, '');
    this.setState({filters: {...this.state.filters, survey: id}});
  }

  render() {
    const periodOptions = [
      {
        label: <FormattedMessage id="admin.points.period.7days" />,
        value: 7
      },
      {
        label: <FormattedMessage id="admin.points.period.30days" />,
        value: 30
      },
      {
        label: <FormattedMessage id="admin.points.period.null" />,
        value: null
      }
    ];

    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">
                    <FormattedMessage id="admin.points.cardHeader" />
                  </h2>
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <InputGroup className="p-0 mb-0">
                    <Input className="p-0" placeholder="Podaj id ankiety" onChange={this.onChangeSurveyId}/>
                    <span className="input-group-btn py-1">
                      <button className="btn" type="button"><i className="fas fa-search" /></button>
                    </span>
                  </InputGroup>
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1 mr-2">
                  <InputGroup className="p-0 mb-0">
                    <Input className="p-0" placeholder="Podaj id użytkowników" onChange={this.onChangeUserIds}/>
                    <span className="input-group-btn py-1">
                      <button className="btn" type="button"><i className="fas fa-search" /></button>
                    </span>
                  </InputGroup>
                </div>
                <div className="custom-search-input w-lg-100 float-right mr-2" style={{minWidth: 200}}>
                  <SelectPointsType onChange={type => this.setState({filters: {...this.state.filters, type}})}/>
                </div>
                <div className="custom-search-input w-lg-100 float-right mr-2" style={{minWidth: 200}}>
                  <SelectPointsWhat onChange={what => this.setState({filters: {...this.state.filters, what}})}/>
                </div>
                <div className="custom-search-input w-lg-100 float-right mr-2" style={{minWidth: 200}}>
                  <Select
                    options={periodOptions}
                    onChange={e => this.setState({filters: {...this.state.filters, period: e.value}})}
                    value={_.find(periodOptions, option => option.value === this.state.filters.period)}
                  />
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <PointCreateButton />
              <Row>
                <Col>
                  <PointsList filters={this.state.filters}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Points;