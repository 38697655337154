import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';

class MultiOpen extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {details} = this.props;

    return (
      <ul>
        {details.map((detail, i) => {
          return <li key={i}>{striptags(detail.content)}</li>
        })}
      </ul>
    )
  }
}

MultiOpen.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string.isRequired
  })).isRequired
};

export default MultiOpen;
