import React, {Component} from 'react';
import {CardBody} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import moment from "moment";
import "react-input-range/lib/css/index.css";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import api from 'api';
import FormDefaultProperty from "../FormDefaultProperty";

class CollectorTopSurveys extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        guid: props.collector.guid,
        startAt: moment(props.collector.startAt).format('YYYY-MM-DD'),
        endAt: moment(props.collector.endAt).format('YYYY-MM-DD'),
        collectorResponses: props.collector.collectorResponses,
      },
      saving: false,
      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({...this.state, saving: true, errors: {}});
    const {data} = this.state;

    api.surveyCollector.patch({
      id: this.props.collector.id,
      surveyCollector: data,
    })
    .then(collector => {
      this.setState({...this.state, saving: false});
      this.props.handleUpdate(collector);
    })
    .catch(e => this.setState({
      errors: e.response.data.form.errors,
      saving: false
    }));
  }

  render() {
    const {data, errors} = this.state;

    return (
      <CardBody>
        <FormDefaultProperty
          errors={errors}
          collector={data}
          onPropertyChanged={(name, value) => {
            this.setState({
              data: {
                ...data,
                [name]: value,
              }
            })
          }}
          disabled
        />

        <FormattedMessage id="_.button.save">{msg => <LaddaButton
          className="btn btn-primary btn-ladda mt-4 float-right"
          loading={this.state.saving}
          onClick={this.onSubmit}
          data-style={ZOOM_OUT}
        >
          {msg}
        </LaddaButton>}</FormattedMessage>
      </CardBody>
    )
  }
}

CollectorTopSurveys.propTypes = {
  collector: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default CollectorTopSurveys;