import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import { Col, Form, FormGroup, Input, Label} from "reactstrap";
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from "react-select";
import { connect } from 'react-redux';
import Autocomplete from "react-async-autocomplete";
import api from 'api';
import Switch from "../../../../components/Switch";

class SurveyForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      survey: {
        name: props.survey ? props.survey.name : '',
        isTemplate: props.survey ? props.survey.isTemplate : false,
        published: props.survey ? props.survey.published : false,
        user: (props.survey && props.survey.user) ? props.survey.user.id : '',
      }
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      survey: {
        ...this.state.survey,
        [e.target.name]: e.target.value
      }
    });
  }

  render() {
    const {survey} = this.state;
    const {errors, onSubmit} = this.props;

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">Nazwa</Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="name" id="name" value={survey.name} invalid={!!errors.name} onChange={this.onChange}/>
            {errors.name && <p className="help-block text-danger">{errors.name}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">User</Label>
          <Col lg={9} className="pt-1">
            <Input type="number" name="user" id="user" value={survey.user} invalid={!!errors.user} onChange={this.onChange}/>
            {errors.user && <p className="help-block text-danger">{errors.user}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">Szablon ankiety</Label>
          <Col lg={9} className="pt-1">
            <Switch checked={survey.isTemplate} onChange={(isTemplate) => {
              this.setState({
                survey: {
                  ...this.state.survey,
                  isTemplate: isTemplate
                }
              })
            }} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">Publikacja</Label>
          <Col lg={9} className="pt-1">
            <Switch checked={survey.published} onChange={(published) => {
              this.setState({
                survey: {
                  ...this.state.survey,
                  published: published
                }
              })
            }} />
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

SurveyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  survey: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isTemplate: PropTypes.bool,
    published: PropTypes.bool,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default connect(null, {}, null, {forwardRef: true})(SurveyForm);
