import React, {Component} from 'react';
import {Form, FormGroup, Label, Input, Col} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';

class ConfigurationForm extends Component {

  constructor(props) {
    super(props);

      this.state = {
        configuration: {
          key: props.configuration ? props.configuration.key : '',
          value: props.configuration ? props.configuration.value : '',
        },
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      ...this.state,
      configuration: {
        ...this.state.configuration,
        [e.target.name]: e.target.value
      }
    });
  }

  render() {
    let {configuration} = this.state;
    const {errors, onSubmit} = this.props;

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup row>
          <Label for="key" md={3} className="text-right"><FormattedMessage id="admin.configurationForm.label.key" /></Label>
          <Col md={9}>
            <Input type="text" name="key" id="key" value={configuration.key} invalid={!!errors.key} onChange={this.onChange}/>
            {errors.key && <p className="help-block text-danger">{errors.key}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="value" md={3} className="text-right"><FormattedMessage id="admin.configurationForm.label.value" /></Label>
          <Col md={9}>
            <Input type="text" name="value" id="value" value={configuration.value} invalid={!!errors.value} onChange={this.onChange}/>
            {errors.value && <p className="help-block text-danger">{errors.value}</p>}
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

ConfigurationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  configuration: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

export default ConfigurationForm;