import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import { connect } from 'react-redux';
import {updateSurvey} from "../../../Structure/data/survey/actions";
import {FormattedMessage} from "react-intl";

class ProgressBarPlacement extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectMenuOpen: false,
      value: props.survey.progressBar ? props.survey.progressBar.placement : 'header',
    };
  }

  updateValue(value){
    this.setState({
      value
    }, () => {
      const data = {
        id: this.props.survey.id,
        survey: {
          progressBar: {
            placement: value
          }
        }
      };

      this.props.updateSurvey(data).then(() => {
        this.props.handlerUpdate({});
      })
    });
  }

  render() {
    const {value, selectMenuOpen} = this.state;
    const inactive = value === 'header';

    const options = [
      "header",
      "footer",
    ];

    return (
      <Row className={classnames({
        'py-1 px-3 align-items-center': true,
        'inactive': inactive && !selectMenuOpen,
      })}>
        <label className="col col-4">Położenie paska postępu {!inactive && <i className="icon-clear-value fas fa-trash float-right" onClick={() => this.updateValue('header')} />}</label>
        <Col className="col-8">
          <Select
            onMenuOpen={() => this.setState({selectMenuOpen: true})}
            onMenuClose={() => this.setState({selectMenuOpen: false})}
            classNames="w-100"
            value={{
              label: <FormattedMessage id={"headerAndFooter.ProgressBarPlacement.option." + value} />,
              value: value,
            }}
            onChange={(e) => this.updateValue(e.value)}
            options={options.map(value => {
              return {
                label: <FormattedMessage id={"headerAndFooter.ProgressBarPlacement.option." + value} />,
                value: value
              }
            })}
          />
        </Col>
      </Row>
    );
  }
}

ProgressBarPlacement.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey
  }
}

export default connect(mapStateToProps, {updateSurvey})(ProgressBarPlacement);