import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import moment from "moment/moment";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import SurveysActions from './SurveysActions';
import PropTypes from "prop-types";
import api from "api";
import Tooltip from "../../../../components/Tooltip";
import Icon from "../../../../componentsReusable/Icon";
// import {Tooltip} from "reactstrap";

class SurveysList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      filters: props.filters,
      pointsTooltip: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const filtersWereChanged = this.props.filters !== nextProps.filters;

    if (filtersWereChanged) {
      clearTimeout(this.state.searchTimeout);
      this.setState({
        filters: nextProps.filters,
        searchTimeout: setTimeout(() => {
          this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});
        }, 1000)
      });
    }
  }

  toggleTooltip() {
    this.setState({pointsTooltip: !this.state.pointsTooltip})
  }

  fetchData(state, props) {
    this.setState({
      searching: true,
      checked: [],
      page: state.page,
      pageSize: state.pageSize,
    });

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        ...this.props.filters,
        sort: state.sorted
      }
    };

    api.user.post.searchSurveysAdmin(data).then(res => {
      this.setState({searching: false, data: res.surveys, count: res.count});
    });
  }

  static status(survey) {
    const type = survey.paused ? 'danger' : 'success';
    const text = survey.paused ? <FormattedMessage id="_.label.paused"/> : <FormattedMessage id="_.label.active"/>;

    return <label className={`label-status ${type} ml-2`}>{text}</label>
  }

  static swPanelStatus(survey) {
    if (survey.isBannedFromBaseSurveys) {
      return <FormattedMessage id={"admin.surveys.listItems.tableHeader.inspected.banned"} />;
    }

    if (!survey.swPanel || survey.swPanel.status === 1) {
      return <FormattedMessage id={"admin.surveys.listItems.tableHeader.inspected.false"} />
    }

    if (survey.swPanel.status === 2) {
      return <FormattedMessage id={"admin.surveys.listItems.tableHeader.inspected.rejected"} />
    }

    if (survey.swPanel.status === 3) {
      return <FormattedMessage id={"admin.surveys.listItems.tableHeader.inspected.true"} />
    }
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="admin.surveys.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="admin.surveys.listItems.tableHeader.title" />,
      accessor: 'name'
    }, {
      id: 'email',
      Header: <FormattedMessage id="admin.surveys.listItems.tableHeader.email" />,
      accessor: survey => {
        return <div className="d-flex flex-nowrap">
          <span>{survey.user.email}</span>
          {survey.surveyShares.length > 0 && <div id={`survey-${survey.id}-shares`} className="ml-2">
            <span className="pointer">+{survey.surveyShares.length}</span>
            <Tooltip
              id={`survey-${survey.id}-shares`}
              msg={<div className="d-flex flex-column">{survey.surveyShares.map(surveyShare => <div>{surveyShare.user.email}</div>)}</div>}
            />
          </div>}
        </div>
      }
    }, {
      id: 'startAt',
      Header: <FormattedMessage id="admin.surveys.listItems.tableHeader.startAt" />,
      accessor: survey => moment(survey.startAt).format('YYYY-MM-DD HH:mm')
    }, {
      id: 'endAt',
      Header: <FormattedMessage id="admin.surveys.listItems.tableHeader.endAt" />,
      accessor: survey => moment(survey.endAt).format('YYYY-MM-DD HH:mm')
    }, {
      id: 'paused',
      Header: <FormattedMessage id="admin.surveys.listItems.tableHeader.status"/>,
      accessor: survey => SurveysList.status(survey)
    }, {
      id: 'endResponsesCount',
      Header: <FormattedMessage id="admin.surveys.listItems.tableHeader.endResponses" />,
      accessor: survey => {
        let displayWarningBreaksResponses = survey.responsesCount > 100 && (((survey.breakResponsesCount / survey.responsesCount) * 100) > 15);

        return <span>
          {displayWarningBreaksResponses && <Icon className="fas fa-exclamation-triangle text-danger mr-2" tooltip="Uwaga - duża liczba przerwań ankiety!" />}
          <span>{survey.endResponsesCount}</span>
        </span>
      }
    }, {
      id: 'points',
      Header: <React.Fragment>
       <FormattedMessage id="admin.surveys.listItems.tableHeader.points" /> <i id="survey-points-header" className="fas fa-question-circle"/>
        {/*<Tooltip*/}
        {/*  delay={0}*/}
        {/*  placement="top"*/}
        {/*  isOpen={this.state.pointsTooltip}*/}
        {/*  target={'survey-points-header'}*/}
        {/*  toggle={this.toggleTooltip}*/}
        {/*>*/}
        {/*  <FormattedMessage id="admin.surveys.listItems.tableHeader.points.tooltip"/>*/}
        {/*</Tooltip>*/}
      </React.Fragment>,
      accessor: survey => <span>{survey.endCashPoints}/{survey.endSocialPoints}/{survey.screenOutCashPoints}/{survey.screenOutSocialPoints}</span>,
      sortable: false
    }, {
      id: 'inspected',
      Header: <FormattedMessage id="admin.surveys.listItems.tableHeader.inspected" />,
      accessor: SurveysList.swPanelStatus
    }, {
      id: 'actions',
      Header: <FormattedMessage id="_.table.header.actions"/>,
      accessor: survey => <SurveysActions survey={survey} handleChange={() => this.fetchData(this.state, this.props)}/>,
      sortable: false
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.surveys.listItems.table.noSurveys" />}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

SurveysList.propTypes = {
  filters: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default SurveysList;
