import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";
import striptags from "striptags";
import reactHtmlParser from "react-html-parser";

class QuestionSummaryMatrixMulti extends Component {

  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
    this.dataExists = this.dataExists.bind(this);
  }

  getData() {
    const {questionSummary} = this.props;

    switch (this.props.questionSummary.chartType) {
      case 'heat':
        let data = [];

        questionSummary.data.data.map(row => {
          row.data.map(column => {
            data.push({
              y: reactHtmlParser(striptags(questionSummary.question.rowsImage ? row.description : row.content))[0],
              x: reactHtmlParser(striptags(questionSummary.question.columnsImage ? column.description : column.content))[0],
              heat: column.count
            });
          });
        });


        return {
          data,
          rowsLength: questionSummary.data.data.length,
          columnsLength: questionSummary.data.data[0].data.length,
        };
      case 'stackedBar':
        let series = [];

        questionSummary.question.columns.map(column => {
          series.push(reactHtmlParser(striptags(questionSummary.question.columnsImage ? column.description : column.content)))
        });

        return {
          data: questionSummary.data.data.map(r => {
            let ret = [reactHtmlParser(striptags(questionSummary.question.rowsImage ? r.description : r.content))[0]];

            questionSummary.question.columns.map(column => {
              ret.push(r['column_' + column.position])
            });

            return ret;
          }),
          series: series
        };
    }
  }

  dataExists() {
    const {questionSummary} = this.props;
    let dataExists = false;

    questionSummary.data.data.map(r => {
      questionSummary.question.columns.map(column => {
        if(r['column_' + column.position] > 0){
          dataExists = true;
        }
      });
    });

    return dataExists;
  }

  render() {
    const {questionSummary} = this.props;

    if (!this.dataExists()) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <Chart id={'q' + questionSummary.question.id} type={questionSummary.chartType} data={this.getData()} n={questionSummary.data.n}/>
    )
  }
}

export default QuestionSummaryMatrixMulti;