import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import SelectColumns from "../../../../../SelectColumns";

class QuestionColumns extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalColumns: props.action.columns || [],
    };

    this.toggleModalColumns = this.toggleModalColumns.bind(this);
  }

  toggleModalColumns(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {action, columns, changeable, questionObject} = this.props;
    const {modalOpen, modalColumns} = this.state;

    if (!changeable) {
      return action.columns.map((id, i, arr) => {
        const tooltipId = "columnTooltipId-" + Math.random().toString(36).substring(7);
        let divider = i < arr.length - 1 && '| ';
        const column = columns.find(q => q.id === id);

        if(column){
          return <React.Fragment key={id}>
            <span className="pointer" id={tooltipId}>{column.position + 1}. </span>
            <UncontrolledTooltip target={tooltipId}>
              {questionObject.columnsImage ? <img src={column.content} className="img-100"/> : <span className="img-30" dangerouslySetInnerHTML={{__html: column.stripedContent}}/>}
            </UncontrolledTooltip>
            {divider}
          </React.Fragment>
        }else{
          return null;
        }
      })
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalColumns} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalColumns}>Wybierz kolumny</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-75 d-inline-block">
          <SelectColumns questionId={action.question} value={modalColumns} onChange={columns => {
            this.setState({
              modalColumns: columns,
            })
          }} />
        </div>
        <div className="w-25 d-inline-block pl-3">
          Wybranych kolumn: {modalColumns.length}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleColumnsChanged(modalColumns);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={!_.isEmpty(action.columns) ? 'secondary' : 'warning'} className="mx-1 pointer d-inline-flex flex-wrap" onClick={this.toggleModalColumns}>{!_.isEmpty(action.columns) ? action.columns.map((id, i, arr) => {
          let divider = i < arr.length - 1 && <span className="mx-1">|</span>;
          const column = columns.find(q => q.id === id);

          if (questionObject.columnsImage) {
            return <span key={id} className="img-20">{column.position + 1}. <img src={column.content}/>{divider}</span>
          }

          return <React.Fragment key={id}><span className="ch-25 img-20 question-badge ws-nowrap d-inline-block pointer" dangerouslySetInnerHTML={{__html: column.position+1 + '. ' + column.stripedContent}} /> {divider}</React.Fragment>;
        }) : 'Wybierz kolumny...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

QuestionColumns.defaultProps = {
  changeable: true
};

QuestionColumns.propTypes = {
  action: PropTypes.shape({
    question: PropTypes.number.isRequired,
    columns: PropTypes.array.isRequired,
  }).isRequired,
  handleColumnsChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state, props) {
  let columns = [];
  if(props.action.question){
    const q = state.survey.structure.data.questions[props.action.question]
    if(q){
      columns = q.columns.map(aId => state.survey.structure.data.columns[aId]);
      let position = 0;
      columns = columns.map(column => {
        const ret = {
          ...column,
          position
        };

        position++;
        return ret;
      });
    }
  }


  return {
    columns,
    questionObject: state.survey.structure.data.questions[props.action.question]
  }
}

export default connect(mapStateToProps)(QuestionColumns);
