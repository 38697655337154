import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";

class SelectValue extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.ownStyles[this.props.keyName] ? props.ownStyles[this.props.keyName] : false,
    };

    this.getDefaultValue = this.getDefaultValue.bind(this);
    this.getOptionByValue = this.getOptionByValue.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      value: nextProps.ownStyles[this.props.keyName] ? nextProps.ownStyles[this.props.keyName] : false,
    });
  }

  getDefaultValue(){
    return this.props.ownStylesDefault[this.props.keyName];
  }

  updateValue(value){
    this.setState({
      value
    });

    this.props.handlerUpdate({[this.props.keyName]: value});
  }

  getOptionByValue(value){
    const {options} = this.props;

    return options.find(option => option.value === value);
  }

  render() {
    const {options} = this.props;
    const {value} = this.state;

    const selectedOption = this.getOptionByValue(value || this.getDefaultValue());

    return (
      <React.Fragment>
        <Select
          placeholder={"Wybierz opcję..."}
          onMenuOpen={() => this.props.handleMenuOpen()}
          onMenuClose={() => this.props.handleMenuClose()}
          classNames="w-100"
          value={selectedOption && {
            label: selectedOption.label,
            value: selectedOption.value,
          }}
          onChange={(e) => this.updateValue(e.value)}
          options={options.map(option => {
            return {
              label: option.label,
              value: option.value,
            }
          })}
        />
      </React.Fragment>
    );
  }
}

SelectValue.propTypes = {
  handleMenuOpen: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  handlerUpdate: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),).isRequired,
  keyName: PropTypes.string.isRequired,
};

export default SelectValue;