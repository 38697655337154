import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button} from "reactstrap";
import LoadingSpinner from "components/LoadingSpinner";
import { connect } from 'react-redux';
import {addSet} from "scenes/Survey/Structure/data/sets/actions";

class ButtonCreateSet extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.addNewSet = this.addNewSet.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  addNewSet(){
    this.setState({loading: true});
    this.props.addSet({
      survey: this.props.survey.id,
      set: {
        position: this.props.position
      }
    }).then(() => this._ismounted && this.setState({loading: false}))
      .catch(() => this.setState({loading: false}));
  }

  render() {
    return (
      <Button onClick={this.addNewSet} size="sm" className="d-block mx-auto w-100 w-lg-25 mb-3 direction-right"> {this.state.loading ? <LoadingSpinner width={21} classNames="my-0"/> : <span><FormattedMessage id="sets.buttonCreateSet.button.create" /> <i className="fas fa-plus" /></span>}</Button>
    )
  }
}

export default connect(null, {addSet})(ButtonCreateSet);