import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import {FormattedMessage} from "react-intl";
import {Col, Row} from "reactstrap";
import moment from "moment";

class DateFromTo extends PureComponent {

  onChange(name, value){
    this.props.onChange(name, value);
  }

  render() {
    const {dateFrom, dateTo} = this.props;

    return (
      <Row>
        <Col sm={6}>
          <FormattedMessage id="filterDate.time">{msg => <DatePicker
            className="form-control"
            isClearable={true}
            placeholderText={"Data od"}
            selected={dateFrom && moment(dateFrom)}
            onChange={(date) => this.onChange('dateFrom', date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="Y-MM-DD HH:mm"
            timeCaption={msg}
            locale={localStorage.language}
          />}</FormattedMessage>
        </Col>
        <Col sm={6}>
          <FormattedMessage id="filterDate.time">{msg => <DatePicker
            className="form-control"
            isClearable={true}
            placeholderText={"Data do"}
            selected={dateTo && moment(dateTo)}
            onChange={(date) => this.onChange('dateTo', date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="Y-MM-DD HH:mm"
            timeCaption={msg}
            locale={localStorage.language}
          />}</FormattedMessage>
        </Col>
      </Row>
    );
  }
}

DateFromTo.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DateFromTo;