import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Tooltip} from "reactstrap";
import classnames from "classnames";
import InputNumber from "components/InputNumber";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";

class SettingNumber extends Component {

  constructor(props) {
    super(props);
    this.state = {
      typing: false,
      value: props.value,
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onBlur(){
    if(this.props.disabled) {
      return;
    }

    const {typing, value} = this.state;

    this.setState({
      typing: false,
      value: typing,
    }, () => this.props.handleUpdate({
        [this.props.name]: this.state.value
      })
    );
  }

  onChange(value){
    if (this.props.disabled) {
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());
      return;
    }

    if(this.props.float){
      value = parseFloat(value);
    }else{
      value = parseInt(value, 10);
    }
    if(!value || value < 0){
      value = 0;
    }

    if(this.state.typing === false){
      this.setState({
        value
      }, () => this.props.handleUpdate({
          [this.props.name]: this.state.value
        })
      );
    }else{
      this.setState({
        typing: value
      });
    }
  }

  render() {
    let { value, tooltip, typing} = this.state;
    const {min, max, step} = this.props;

    return (
      <div
        id={"value-" + this.props.name + '-' + this.props.question.id}
        className={classnames({
          "min-w-0 float-left mr-1 mb-1": true,
          "w-auto": value < this.props.activeFromValue,
          "w-100 w-lg-auto input-group": value >= this.props.activeFromValue
        })}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": value >= this.props.activeFromValue,
              "disabled": this.props.disabled
            })}
            color="secondary"
            onClick={() => {
              if(value === 0){
                this.onChange(1)
              }else{
                this.onChange(0)
              }
            }}
          >
            {this.props.icon}
          </Button>
        </InputGroupAddon>
        {value >= this.props.activeFromValue && <InputNumber
          value={typing === false ? value : typing}
          min={min}
          max={max}
          step={step}
          onFocus={() => {
            this.setState({
              typing: value,
            })
          }}
          onBlur={this.onBlur}
          onChange={this.onChange}
          float={this.props.float}
          className={this.props.disabled ? "not-active" : ""}
        />}
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={"value-" + this.props.name + '-' + this.props.question.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id={'_.question.' + this.props.question.type + '.' + this.props.name}/>
        </Tooltip>
      </div>
    )
  }
}

SettingNumber.defaultProps = {
  activeFromValue: 1,
  min: 0,
  max: 999999999,
  step: 1,
  float: false,
  disabled: false
};

SettingNumber.propTypes = {
  activeFromValue: PropTypes.number,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  float: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  icon: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default connect(null, {setPlanRestrictionsModals})(SettingNumber);