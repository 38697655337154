import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {SketchPicker} from "react-color";


class ColorPickerModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      color: props.color,
      modalOpen: true,
      saving: false,
    };
  }

  render() {
    const {modalOpen, saving, color} = this.state;

    return <Modal id="color-picker" isOpen={modalOpen} size="sm">
      <ModalHeader toggle={this.toggleModal}>Wybierz kolor</ModalHeader>
      <ModalBody>
        <SketchPicker
          className="mx-auto"
          color={color}
          onChangeComplete={color => this.setState({color: color.hex})}
        />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={() => this.props.onClose()}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={(e) => {
              this.props.onSelect(color);
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;
  }
}

ColorPickerModal.propTypes = {
  color: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ColorPickerModal;