import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {fetchSurveyShares} from "../actions";
import SurveyShareDeleteButton from './SurveyShareDeleteButton';
import _ from 'lodash';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import UserAvatar from "../../../components/UserAvatar";

class SurveyShareList extends Component {

  constructor(props){
    super(props);

    this.formatPrivilage = this.formatPrivilage.bind(this);
  }

  componentDidMount() {
    this.props.fetchSurveyShares(this.props.surveyId);
  }

  formatPrivilage(surveyShare){
    const {surveySharePrivilages} = this.props;

    return _.find(surveySharePrivilages, function (p) { return p.id === surveyShare.privilage }).name;
  }

  static actions(surveyShare){
    return (
      <ButtonGroup>
        <SurveyShareDeleteButton surveyShare={surveyShare} />
      </ButtonGroup>
    )
  }

  render() {
    const {surveyShares, user} = this.props;

    const columns = [{
      id: 'avatar',
      width: 50,
      accessor: surveyShare => <UserAvatar user={surveyShare.user} size={26} />
    }, {
      Header: <FormattedMessage id="surveyShareList.listItems.tableHeader.id" />,
      width: 100,
      accessor: 'id'
    }, {
      id: 'email',
      Header: <FormattedMessage id="surveyShareList.listItems.tableHeader.email" />,
      accessor: surveyShare => <span style={{wordBreak: 'break-word'}}>{surveyShare.user.email}</span>
    }, {
      id: 'privilage',
      Header: <FormattedMessage id="surveyShareList.listItems.tableHeader.privilage" />,
      accessor: surveyShare => this.formatPrivilage(surveyShare)
    }, {
      id: 'actions',
      width: 50,
      Header: <FormattedMessage id="surveyShareList.listItems.tableHeader.actions" />,
      accessor: surveyShare => SurveyShareList.actions(surveyShare)
    }];

    return (
      <ReactTable
        style={{zIndex: 0}}
        minRows={0}
        className={'table-wrap'}
        data={Object.values(surveyShares.data.surveyShares)}
        columns={columns}
        showPagination={!_.isEmpty(Object.values(surveyShares.data.surveyShares))}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="surveyShareList.listItems.table.noUsers" />}
        loading={surveyShares.loading}
        defaultPageSize={10}
      />
    )
  }
}

SurveyShareList.propTypes = {
  surveyId: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
    surveyShares: state.surveyShares,
    surveySharePrivilages: state.config.data.group.surveySharePrivilage,
  }
}

export default connect(mapStateToProps, {fetchSurveyShares})(SurveyShareList);