import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Card, CardHeader, CardBody, CardFooter} from "reactstrap";
import DictionaryWordsTable from "../componentsNew/Dictionaries/DictionaryWords/DictionaryWordsTable";
import appRoutes from "../appRoutes";
import api from "../api";

class DictionaryWordsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dictionary: false,
    };
  }

  componentDidMount() {
    document.title = 'Lista słów w słowniku | ANKIETEO';

    api.dictionaries.get(this.props.match.params.dictionaryId)
    .then(data => {
      this.setState({
        dictionary: data.data,
      })
    });
  }

  render() {
    const {dictionary} = this.state;

    return (
      <Card>
        <CardHeader className="p-0">
          <h2><strong>Lista słów w słowniku: {dictionary ? dictionary.name : <i className="fas fa-spin fa-spinner ml-2" />}</strong></h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <DictionaryWordsTable dictionaryId={this.props.match.params.dictionaryId} />
        </CardBody>
        <CardFooter>
          <Link className="btn btn-link" to={appRoutes.dictionaries.list}><i className="fas fa-arrow-left" /> Wróć do listy słowników</Link>
        </CardFooter>
      </Card>
    )
  }
}

export default DictionaryWordsView