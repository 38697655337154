import React, {Component} from 'react';
import {Label, FormGroup, Col} from "reactstrap";
import {FormattedMessage} from "react-intl";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from "lodash";
import QuestionsSelect from "components/Survey/Analysis/QuestionsSelect";

class QuestionList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      questions: {},
      checked: this.getChecked(),
      expanded: []
    };

    this.onCheck = this.onCheck.bind(this);
  }

  getChecked() {
    let checked = [];
    this.props.widget.visibleQuestions && (this.props.widget.visibleQuestions.length > 0 || Object.keys(this.props.widget.visibleQuestions).length > 0)
      ? _.each(this.props.widget.visibleQuestions, question => checked.push(question.id))
      : _.each(this.props.questions, question => checked.push(question.id));
    this.props.handleCheck(checked);
    return checked;
  }

  onCheck(checked) {
    this.setState({checked});
    this.props.handleCheck(checked);
  }

  render() {
    return (
      <FormGroup row>
        <Label lg={3}><FormattedMessage id="widgetTypes.typeQuestionSummariesFromAnalysis.settings.label.visibleQuestions" /></Label>
        <Col lg={9} className="pt-2">
          <QuestionsSelect survey={{id: this.props.analysis.survey}} onChange={this.onCheck}/>
        </Col>
      </FormGroup>
    )
  }
}

QuestionList.propTypes = {
  handleCheck: PropTypes.func.isRequired,
  widget: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
    sets: state.survey.analysis.data.sets,
    blocks: state.survey.analysis.data.blocks,
    pages: state.survey.analysis.data.pages,
    questions: state.survey.analysis.data.questions
  };
}

export default connect(mapStateToProps)(QuestionList);
