import * as types from './actionTypes';
import api from 'api';
import {groupInvitationAccepted} from 'reducers/user/actions';
import {setClients} from "scenes/Clients/actions";

function groupAdded(group) {
  return {
    type: types.ADDED,
    group
  }
}

function groupsSet(groups, invitations) {
  return {
    type: types.SET,
    groups,
    invitations
  }
}

function userDeleted(user) {
  return {
    type: types.USER_DELETED,
    user
  }
}

function userAdded(user) {
  return {
    type: types.USER_ADDED,
    user
  }
}

function userUpdated(user) {
  return {
    type: types.USER_UPDATED,
    user
  }
}

function invitationRejected(groupId) {
  return {
    type: types.INVITATION_REJECTED,
    groupId
  }
}

export function saveGroup(data) {
  return dispatch => {
    return api.user.post.group(data).then((group) => {
      dispatch(groupAdded(group));
    })
  }
}

export function fetchGroups() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.user.get.groups().then((data) => {
      let orderedGroups = {};
      let orderedInvitations = {};
      _.each(data.groups, group => {
        let orderedUsers = {};
        _.each(group.users, user => orderedUsers[user.id] = user);
        group.users = orderedUsers;
        orderedGroups[group.id] = group
      });
      _.each(data.invitations, invitation => {
        orderedInvitations[invitation.id] = invitation
      });

      dispatch(groupsSet(orderedGroups, orderedInvitations));
      dispatch({type: types.SUCCESS});
    })
  }
}

export function deleteUser(user) {
  return dispatch => {
    return api.group.user.delete(user).then(() => {
      dispatch(userDeleted(user));
    })
  }
}

export function saveUser(data) {
  return dispatch => {
    return api.group.user.post(data).then((response) => {
      if (response.user) {
        response.user.groupId = data.groupId;
        dispatch(userAdded(response.user));
      }
      return response;
    })
  }
}

export function inviteUser(data) {
  return dispatch => {
    return api.group.user.invite(data).then(user => {
      user.groupId = data.groupId;
      dispatch(userAdded(user));
    });
  }
}

export function acceptInvitation(groupId) {
  return dispatch => {
    return api.group.user.acceptInvitation(groupId).then(data => {
      let orderedGroups = {};
      let orderedInvitations = {};
      let orderedClients = {};
      let orderedQuestionTemplates = {};

      _.each(data.groups, group => {
        let orderedUsers = {};
        _.each(group.users, user => orderedUsers[user.id] = user);
        group.users = orderedUsers;
        orderedGroups[group.id] = group
      });

      _.each(data.invitations, invitation => {
        orderedInvitations[invitation.id] = invitation
      });

      _.each(data.clients, client => {
        orderedClients[client.id] = client
      });

      dispatch(groupsSet(orderedGroups, orderedInvitations));
      dispatch(groupInvitationAccepted(orderedGroups[groupId].name));

      dispatch(setClients(orderedClients));
      dispatch({type: types.SUCCESS});
    });
  }
}

export function rejectInvitation(groupId) {
  return dispatch => {
    return api.group.user.rejectInvitation(groupId).then(() => {
      dispatch(invitationRejected(groupId));
    });
  }
}

export function updateUser(data) {
  return dispatch => {
    return api.group.user.patch(data).then((user) => {
      user.groupId = data.groupId;
      dispatch(userUpdated(user));

      return user
    });
  }
}