import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import InputNumber from "components/InputNumber";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";

class AnswerRandPriority extends Component{
  constructor(props) {
    super(props);
    this.state = {
      randPriority: props.answer.randPriority,
      tooltipIsOpen: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  onChange(value) {
    if (this.props.disabled) {
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());
      return;
    }
    this.setState({...this.state, randPriority: value});
    this.props.changeOption('randPriority', value);
  }

  render() {
    const {randPriority, tooltipIsOpen} = this.state;

    return (
      <React.Fragment>
        <InputNumber value={randPriority} onChange={this.onChange} id={'answerRandPriority-' + this.props.answer.id}/>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltipIsOpen}
          target={'answerRandPriority-' + this.props.answer.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="answer.answerRandPriority.button.tooltip"/>
        </Tooltip>
      </React.Fragment>
    )
  }
}

AnswerRandPriority.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    randPriority: PropTypes.number.isRequired,
  }).isRequired,
  changeOption: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default connect(null, {setPlanRestrictionsModals})(AnswerRandPriority);
