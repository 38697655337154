import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Question from "../../../Modals/Question";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {injectIntl} from "react-intl";
import QuestionExcluding from "../../../Modals/QuestionExcluding";

class CondExcluding extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  toggleIsOpen(){
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const {intl, condQuestion, changeable} = this.props;
    const {isOpen} = this.state;

    return (
      <React.Fragment>
        <span>Jeżeli respondent </span>

        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpen} toggle={this.toggleIsOpen}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condExcluding.excludingType-' + condQuestion.excluding.excludingType})}</DropdownToggle>
          <DropdownMenu>
            {['selected', 'notSelected'].map(excludingType => <DropdownItem tag="a" key={excludingType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              excluding: {
                ...condQuestion.excluding,
                excludingType
              }
            })}><h5>{intl.formatMessage({id: 'filter.condExcluding.excludingType-' + excludingType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condExcluding.excludingType-' + condQuestion.excluding.excludingType})} </span>}

        <span>w pytaniu </span>
        <Question question={condQuestion.question} changeable={changeable} handleQuestionChanged={question => this.props.handleCondChanged({...condQuestion, question, excludings: []})} />

        {condQuestion.question && <span>{intl.formatMessage({id: 'filter.condExcluding.excludingType-' + condQuestion.excluding.excludingType + '-excluding'})} </span>}
        {condQuestion.question && <QuestionExcluding question={condQuestion.question} excludings={condQuestion.excluding.excludings} changeable={changeable} handleExcludingsChanged={excludings => this.props.handleCondChanged({
          ...condQuestion,
          excluding: {
            ...condQuestion.excluding,
            excludings
          }
        })} />}
      </React.Fragment>
    );
  }
}

CondExcluding.defaultProps = {
  changeable: true
};

CondExcluding.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number,
    excluding: PropTypes.shape({
      excludingType: PropTypes.oneOf(['selected']),
      excludings: PropTypes.array,
    }),
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(CondExcluding);