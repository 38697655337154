import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {fetchPlans} from "../../Plans/actions";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import AdminsActions from './AdminsActions';
import moment from "moment";
import api from "api";
import PropTypes from "prop-types";
import AdminForm from "./AdminForm";
import AdminCreateButton from "./AdminCreateButton";
import {CardBody} from "reactstrap";


class AdminsList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount(){
    this.props.fetchPlans();
  }

  componentWillReceiveProps(nextProps) {
    const filtersWereChanged = this.props.search !== nextProps.search || this.props.regBefore !== nextProps.regBefore || this.props.regAfter !== nextProps.regAfter || this.props.refUser !== nextProps.refUser;
    if (filtersWereChanged) {
      clearTimeout(this.state.searchTimeout);
      this.setState({
        searchTimeout: setTimeout(() => {
          this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, nextProps);
        }, 1000)
      });
    }
  }

  fetchData(state, props) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted,
        search: props.search,
        ref: props.refUser,
        regBefore: props.regBefore && moment(props.regBefore).format('YYYY-MM-DD 23:59:59'),
        regAfter: props.regAfter && moment(props.regAfter).format('YYYY-MM-DD 00:00:00'),
      }
    };

    api.user.post.searchAdmins(data).then(res => {
      this.setState({searching: false, data: res.admins, count: res.count});
    });
  }

  render() {
    const columns = [{
      Header: 'Id',
      accessor: 'id'
    }, {
      Header: 'Email',
      accessor: 'email'
    }, {
      Header: 'Telefon',
      accessor: 'phone'
    }, {
      id: 'roles',
      Header: 'Role',
      accessor: user => user.roles.map(roleValue => AdminForm.getAvailableRoles().find(r => r.value === roleValue).label).join(', '),
    }, {
      id: 'actions',
      Header: 'Akcje',
      accessor: user => <AdminsActions user={user} handleChange={() => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, this.props)}/>,
      sortable: false
    }];

    return <div>
      <AdminCreateButton loading={this.props.loadingPlans} handleChange={() => {
        this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, this.props)
      }}/>

      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        loading={this.state.searching}
        onFetchData={(state) => this.fetchData(state, this.props, state.sorted)}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    </div>;
  }
}

AdminsList.propTypes = {
  search: PropTypes.string.isRequired,
};

export default connect(null, {fetchPlans}, null, {forwardRef: true})(injectIntl(AdminsList));