import React, {Component} from 'react';
import {Card, CardHeader, CardBody, CardFooter} from "reactstrap";
import DictionaryWordSimilarsTable
  from "../componentsNew/Dictionaries/DictionaryWords/DictionaryWordSimilars/DictionaryWordSimilarsTable";
import {Link} from "react-router-dom";
import appRoutes from "../appRoutes";
import api from "../api";

class DictionaryWordSimilarsView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      word: false,
    };
  }

  componentDidMount() {
    document.title = 'Lista słów podobnych dla słowa | ANKIETEO';

    api.dictionaryWords.get(this.props.match.params.wordId)
    .then(data => {
      this.setState({
        word: data.data,
      })
    })
  }

  render() {
    const {word} = this.state;

    console.log('word', word);

    return (
      <Card>
        <CardHeader className="p-0">
          <h2><strong>Lista słów podobnych dla słowa: {word ? word.word : <i className="fas fa-spin fa-spinner ml-2" />}</strong></h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <DictionaryWordSimilarsTable wordId={this.props.match.params.wordId} />
        </CardBody>
        <CardFooter>
          <Link className="btn btn-link" to={appRoutes.dictionaries.dictionary.wordsList(this.props.match.params.dictionaryId)}><i className="fas fa-arrow-left" /> Wróć do listy kodowanych słów</Link>
        </CardFooter>
      </Card>
    )
  }
}

export default DictionaryWordSimilarsView