import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import SelectExcludings from "../../../SelectExcludings";

class QuestionExcluding extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      question: props.question,
      modalOpen: false,
      modalExcludings: props.excludings,
    };

    this.toggleModalExcluding = this.toggleModalExcluding.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return props.question !== state.question ? {modalExcludings: props.excludings, question: props.question} : null;
  }

  toggleModalExcluding(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {question, excludings, excludingsObject, changeable} = this.props;
    const {modalOpen, modalExcludings} = this.state;

    if (!changeable) {
      return excludings.map((id, i, arr) => {
        const tooltipId = "excludingTooltipId-" + Math.random().toString(36).substring(7);
        let divider = i < arr.length - 1 && '| ';
        const answer = excludingsObject.find(q => q.id === id);

        return <React.Fragment key={id}>
          <span className="pointer" id={tooltipId}>{answer.position + 1}. </span>
          <UncontrolledTooltip target={tooltipId}>
            <span className="img-30" dangerouslySetInnerHTML={{__html: answer.stripedContent}}/>
          </UncontrolledTooltip>
          {divider}
        </React.Fragment>
      })
    }

    if (!changeable) {
      return excludings.map((id, i, arr) => {
        const tooltipId = "excludingTooltipId-" + Math.random().toString(36).substring(7);
        let divider = i < arr.length - 1 && '| ';
        const answer = excludingsObject.find(q => q.id === id);

        return <React.Fragment key={id}>
          <span className="pointer" id={tooltipId}>{answer.position + 1}. </span>
          <UncontrolledTooltip target={tooltipId}>
            <span className="img-30" dangerouslySetInnerHTML={{__html: answer.stripedContent}}/>
          </UncontrolledTooltip>
          {divider}
        </React.Fragment>
      })
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalExcluding} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalExcluding}>Wybierz odpowiedzi</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-75 d-inline-block">
          <SelectExcludings questionId={question} value={modalExcludings} onChange={excludings => {
            this.setState({
              modalExcludings: excludings,
            })
          }} />
        </div>
        <div className="w-25 d-inline-block pl-3">
          Wybranych odpowiedzi: {modalExcludings.length}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleExcludingsChanged(modalExcludings);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={!_.isEmpty(excludings) ? 'secondary' : 'warning'} className="mx-1 pointer d-inline-flex flex-wrap" onClick={this.toggleModalExcluding}>{!_.isEmpty(excludings) ? excludings.map((id, i, arr) => {
          let divider = i < arr.length - 1 && <span className="mx-1">|</span>;
          const answer = excludingsObject.find(q => q.id === id);

          return <React.Fragment key={id}><span className="ch-25 img-20 question-badge ws-nowrap d-inline-block" dangerouslySetInnerHTML={{__html: answer.position+1 + '. ' + answer.stripedContent}} />{divider}</React.Fragment>;
        }) : 'Wybierz odpowiedzi...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

QuestionExcluding.defaultProps = {
  changeable: true
};

QuestionExcluding.propTypes = {
  question: PropTypes.number.isRequired,
  excludings: PropTypes.array.isRequired,
  handleExcludingsChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state, props) {
  let excludingsObject = [];
  if(props.question){
    excludingsObject = state.survey.structure.data.questions[props.question].excludings.map(aId => state.survey.structure.data.excludings[aId]);
    let position = 0;
    excludingsObject = excludingsObject.map(answer => {
      const ret = {
        ...answer,
        position
      };

      position++;
      return ret;
    });
  }


  return {
    excludingsObject,
  }
}

export default connect(mapStateToProps)(QuestionExcluding);