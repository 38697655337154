import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../components/Button";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from "../../../../api";

const AcceptMulti = props => {
  const [saving, setSaving] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  const accept = () => {
    setSaving(true);

    api.admin.survey.swPanelAcceptBulk(props.collectors.map(collector => collector.id))
      .then(() => {
        setSaving(false)
        toggleModalOpen();
        props.onSuccess()
      })
      .finally(() => setSaving(false))
  }

  if(props.collectors.length === 0){
    return null;
  }

  return (
    <React.Fragment>
      <Button className="text-nowrap" onClick={toggleModalOpen}>Akceptuj wszystkie ({props.collectors.length})</Button>
      {modalOpen && <Modal isOpen toggle={toggleModalOpen} size="md">
        <ModalHeader tag="h2" toggle={toggleModalOpen}>Zaakceptuj wszystkie</ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <p>Liczba ankiet do zaakceptowania: {props.collectors.length}</p>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.accept">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mb-0"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={accept}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

AcceptMulti.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  collectors: PropTypes.array.isRequired,
};

export default AcceptMulti;