import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Button, Tooltip} from "reactstrap";
import {updateQuestion} from "scenes/Survey/Structure/data/questions/actions";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "components/PlanRestrictionsModals/modals/MultimediaAvailable";

class VideoButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: {
        video: props.question.video,
      },
      tooltip: false
    };

    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(fileUrl) {
    this.setState({question: {video: fileUrl}}, () => this.handledUpdateQuestion(this.state.question));
  }

  handledUpdateQuestion(question){
    this.props.updateQuestion({
      id: this.props.question.id,
      question
    });
  }

  render() {
    let { question, tooltip } = this.state;
    const {plan} = this.props;

    return (
      <React.Fragment>
        <Button
          id={"video-" + this.props.question.id}
          color={!!question.video ? 'primary' : 'secondary'}
          onClick={() => {
            if (!plan.multimedia.access) {
              this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
              return;
            }
            CKFinder.popup({
              connectorInfo: 'auth=' + localStorage.jwt,
              chooseFiles: true,
              width: 1014,
              height: 700,
              onInit: function (finder) {
                finder.on('files:choose', function (e) {
                  this.onChange(e.data.files.first().getUrl());
                }.bind(this));
              }.bind(this)
            })
          }}
        >
          <i className="fas fa-film" />

          <Tooltip
            delay={0}
            placement="top"
            isOpen={tooltip}
            target={"video-" + this.props.question.id}
            toggle={() => this.toggleTooltip()}
          >
            <FormattedMessage id="video.videoButton.button.tooltip"/>
          </Tooltip>
        </Button>
        {question.video && <span className="ml-3">{question.video.slice(question.video.lastIndexOf("/") + 1, question.video.length)}</span>}
      </React.Fragment>
    )
  }
}

VideoButton.propTypes = {
  question: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {updateQuestion, setPlanRestrictionsModals})(VideoButton);