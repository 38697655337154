import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {ListGroup, ListGroupItem} from "reactstrap";
import Checkbox from "components/Checkbox";
import {FormattedMessage} from "react-intl";
import _ from "lodash";

class CheckAnswers extends Component {
  constructor(props){
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onToggleAll = this.onToggleAll.bind(this);
  }

  onChange(answer){
    let {checked} = this.props;

    checked = checked.includes(answer) ? checked.filter(a => a !== answer) : [...checked, answer];

    this.props.onChange(checked);
  }

  onToggleAll() {
    const allChecked = _.isEqual(_.map(this.props.answers, answer => answer.id).sort(), this.props.checked.sort());

    const checked = allChecked ? [] : _.map(this.props.answers, answer => answer.id);

    this.props.onChange(checked);
  }

  render() {
    const {checked, answers} = this.props;
    const allChecked = _.isEqual(_.map(answers, answer => answer.id).sort(), checked.sort());

    return (
      <ListGroup>
        <ListGroupItem className="py-0" onClick={this.onToggleAll}>
          <Checkbox
            text={<FormattedMessage id="checkAnswers.toggleAll"/>}
            id={'checkbox-answers-all'}
            checked={allChecked}
            tooltip={false}
          />
        </ListGroupItem>
        {Object.values(answers).map(answer => {
          return <ListGroupItem className="py-0" key={answer.id} onClick={() => this.onChange(answer.id)}>
            <Checkbox
              text={<div dangerouslySetInnerHTML={{__html: answer.content}} />}
              id={'checkbox-answer-' + answer.id}
              checked={checked.includes(answer.id)}
              tooltip={false}
            />
          </ListGroupItem>
        })}
      </ListGroup>
    );
  }
}

CheckAnswers.propTypes = {
  questionId: PropTypes.number.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  checked: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    answers: Object.values(_.map(state.survey.structure.data.questions[props.questionId].answers, id => state.survey.structure.data.answers[id])),
  }
}

export default connect(mapStateToProps)(CheckAnswers);