import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import {FormattedMessage} from "react-intl";

class JavascriptForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      errors: {},
    };
  }

  render() {
    const {data} = this.state;
    const {javascriptActions, errors} = this.props;

    return <Form>
      <FormGroup>
        <Label md={4}>Akcja</Label>
        <Col>
          <Select
            value={data.action && {
              value: data.action,
              label: <FormattedMessage id={"_.question.javascript.action-" + data.action} />,
            }}
            onChange={e => this.setState({
              data: {
                ...data,
                action: e.value,
              }
            })}
            options={javascriptActions.map(action => {
              return {
                value: action,
                label: <FormattedMessage id={"_.question.javascript.action-" + action} />,
              }
            })}
          />
          <FormText color="danger">{_.has(errors, 'action') ? errors.action.join('; ') : false}</FormText>
        </Col>
      </FormGroup>
      <FormGroup>
        <Label md={4}>Kod</Label>
        <Col>
          <Input type="textarea" invalid={errors && !!errors.code} rows={12} value={data.code} onChange={e => this.setState({
            data: {
              ...data,
              code: e.target.value
            }
          })} />
          <FormText color="danger">{_.has(errors, 'code') ? errors.code.join('; ') : false}</FormText>
        </Col>
      </FormGroup>
    </Form>;
  }
}

JavascriptForm.defaultProps = {
  data: {
    action: null,
    code: '',
  }
};

JavascriptForm.propTypes = {
  javascriptActions: PropTypes.array.isRequired,
  data: PropTypes.shape({
    action: PropTypes.string,
    code: PropTypes.string.isRequired,
  }),
  errors: PropTypes.object,
};

export default JavascriptForm;