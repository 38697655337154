import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";

class ButtonExcludeRequired extends Component{
  constructor(props) {
    super(props);
    this.state = {
      tooltipExcludeRequired: false,
      excludeRequired: props.row.excludeRequired
    };

    this.onClick = this.onClick.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipExcludeRequired: !this.state.tooltipExcludeRequired
    });
  }

  onClick() {
    this.setState({...this.state, excludeRequired: !this.state.excludeRequired});
    this.props.toggleOption('excludeRequired');
  }

  render() {
    const {row} = this.props;
    const {tooltipExcludeRequired, excludeRequired} = this.state;

    return (
      <a id={'excludeRequired-' + row.id + '-row'} className={"fa-solid fa-ban ml-2" + (excludeRequired ? " text-primary" : "")} onClick={this.onClick}>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltipExcludeRequired}
          target={'excludeRequired-' + row.id + '-row'}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="row.buttonExcludeRequired.button.tooltip"/>
        </Tooltip>
      </a>
    )
  }
}

ButtonExcludeRequired.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    excludeRequired: PropTypes.bool.isRequired,
  }).isRequired,
  toggleOption: PropTypes.func.isRequired
};

export default ButtonExcludeRequired;
