import React, {Component} from 'react';
import {Nav, NavItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import appRoutes from "appRoutes";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import NavigationAdmin from './NavigationAdmin';
import {connect} from "react-redux";

class NavigationAdminApplicationFiles extends Component {
  render() {
    const {user} = this.props;

    return (
      <Nav className="navbar-nav m-0 mx-lg-5 align-items-lg-baseline w-50 w-lg-100 float-right d-inline-block d-lg-flex">
        {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_FILES')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.files.files} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminFiles.files"/><span className="sr-only"><FormattedMessage id="navigation.adminFiles.files"/></span></NavLink></NavItem>}
        {(user.roles.includes('ROLE_SUPER_ADMIN')) && <NavItem className="py-2"><NavLink exact className="nav-link" to={appRoutes.admin.files.sitemaps} onClick={() => NavigationAdmin.toggleNavbar(true)}><FormattedMessage id="navigation.adminFiles.sitemaps"/><span className="sr-only"><FormattedMessage id="navigation.adminFiles.sitemaps"/></span></NavLink></NavItem>}
      </Nav>
    );
  }
}

NavigationAdminApplicationFiles.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(NavigationAdminApplicationFiles);