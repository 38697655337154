const convertSvgToPng64 = (selector) => {
	let svg = document.querySelector(selector)
	if (!svg) {
		return null;
	}
	const width = window.getComputedStyle(svg).width;
	const height = window.getComputedStyle(svg).height;

	svg.setAttribute("width", width)
	svg.setAttribute("height", height)

	const svgData = new XMLSerializer().serializeToString(svg);
	const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
	const svgUrl = URL.createObjectURL(svgBlob);

	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = svgUrl;
		img.onload = () => {
			const canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0);
			const canvasData = canvas.toDataURL("image/png");
			const base = canvasData.replace(/^data:image\/(png|jpg);base64,/, "");
			resolve({
				data: base,
				width: width.slice(0,-2),
				height: height.slice(0,-2)
			})
		}
	})
}

export default convertSvgToPng64;