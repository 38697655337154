import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import DeleteButton from "./TabAddresses/DeleteButton";
import AddRespondents from "./TabAddresses/AddRespondents";
import appRoutes from 'appRoutes';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from 'api';
import HelpModeIconTooltip from "components/HelpModeIconTooltip";
import {surveyLink} from "../../../../../services/surveyLink";

class TabAddresses extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      addresses: []
    };

    this.actions = this.actions.bind(this);
    this.responseFormat = this.responseFormat.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
    this.addAddresses = this.addAddresses.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true
    });
    api.surveyCollector.get.addresses({
      id: this.props.collector.id
    }).then(addresses => {
      this.setState({
        loading: false,
        addresses
      });
    }).catch(() => {
      this.setState({
        loading: false
      });
    })
  }

  addAddresses(addresses) {
    this.setState({addresses})
  }

  deleteAddress(addressId) {
    const addresses = this.state.addresses.filter(address => address.id !== addressId);
    this.setState({addresses});
  }

  responseFormat(address){
    const {surveyId} = this.props;
    const analysisId = 1;

    return address.response ?
      <Link
        className={'btn btn-sm ' + (address.response.isEnded ? 'btn-success' : 'btn-warning')}
        to={appRoutes.survey.analysis.browseResponse(surveyId, analysisId, address.response.id)}
      >
        <FormattedMessage id={'surveyCollector.typeEmail.addresses.table.response.link.' + (address.response.isEnded ? 'finish' : 'break')}/>
      </Link> : <FormattedMessage id="surveyCollector.typeEmail.addresses.table.noAnswer"/>;
  }

  actions(address){
    return (
      <ul className="float-right mb-0 p-0">
        <li className="float-left mr-2">
          <DeleteButton address={address} handleDelete={this.deleteAddress}/>
        </li>
      </ul>
    )
  }

  render() {
    const {loading, addresses} = this.state;
    const {collector, columnsCount, columns} = this.props;

    const tableColumns = [{
      Header: 'Id',
      accessor: 'id'
    }, {
      id: 'guid',
      Header: <FormattedMessage id="surveyCollector.typeEmail.addresses.table.guid"/>,
      accessor: row => {
        const link = surveyLink(collector.guid + '/' + row.guid);

        return link;
      }
    }, {
      id: 'respondentAddress',
      Header: <FormattedMessage id="surveyCollector.typeEmail.addresses.table.email"/>,
      accessor: row => {
        if(!row.respondentAddress){
          return <i className="text-secondary">Brak danych</i>
        }

        if(row.respondentAddress.unsubscribed){
          return <s className="text-secondary">{row.respondentAddress.email}</s>
        }

        return row.respondentAddress.email;
      }
    }, {
      Header: <FormattedMessage id="surveyCollector.typeEmail.addresses.table.sentCount"/>,
      accessor: 'sentCount'
    }];

    for (let i = 1; i <= columnsCount; i++) {
      let columnName = "column_" + i;
      let columnIndex = i - 1;

      tableColumns.push({
        id: 'columnName',
        Header: Object.values(columns)[columnIndex].name,
        accessor: address => address.respondentAddress ? address.respondentAddress[columnName] : <i className="text-secondary">Brak danych</i>
      });
    }

    tableColumns.push({
      id: 'response',
      Header: <FormattedMessage id="surveyCollector.typeEmail.addresses.table.response"/>,
      accessor: address => this.responseFormat(address)
    }, {
      id: 'actions',
      Header: <FormattedMessage id="surveyCollector.typeEmail.addresses.table.actions"/>,
      accessor: address => this.actions(address)
    });

    return (
      <React.Fragment>
        <HelpModeIconTooltip className="d-block mb-2">Lista respondentów do których wysyłane są zaproszenia mailowe do wypełnienia ankiety</HelpModeIconTooltip>
        <AddRespondents collector={this.props.collector} handleAddAddresses={this.addAddresses}/>
        <ReactTable
          minRows={0}
          className={'table-wrap'}
          data={addresses}
          columns={tableColumns}
          showPagination={!_.isEmpty(addresses)}
          PaginationComponent={Pagination}
          noDataText={<FormattedMessage id="surveyCollector.typeEmail.addresses.table.noData" />}
          loading={loading}
          defaultPageSize={10}
        />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    columnsCount: Object.keys(state.user.respondentBaseColumns).length,
    columns: state.user.respondentBaseColumns
  }
}

TabAddresses.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    addresses: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      respondentAddress: PropTypes.shape({
        email: PropTypes.string.isRequired
      }),
      response: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isEnded: PropTypes.bool.isRequired,
      }),
    }))
  }).isRequired,
  surveyId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(TabAddresses);
