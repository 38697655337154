import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import api from "api";
import {ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";
import {toast} from "react-toastify";

class ExportTokens extends Component {

  constructor(props) {
    super(props);

    this.state = {
      exporting: false,
    };

    this.export = this.export.bind(this);
  }

  export(){
    this.setState({exporting: true});
    let filename;

    fetch(api.surveyCollector.tokens.export(this.props.collector.id))
      .then(response => {
        filename = decodeURI(response.headers.get('content-disposition').split('filename=')[1]);
        return response.blob()
      })
      .then(blob => {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename.substring(1, filename.length-1);
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.setState({exporting: false});
      }).catch(() => {
      this.setState({exporting: false});
    });
  }

  render() {
    return <LaddaButton
        className="btn btn-primary btn-ladda mb-2 ml-2"
        loading={this.state.exporting}
        onClick={this.export}
        data-style={ZOOM_OUT}
      >
        Pobierz listę do Excela
      </LaddaButton>
  }
}

ExportTokens.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
};

export default ExportTokens;