import React, {Component} from 'react';
import PropTypes from "prop-types";
import MapComponent from "./components/MapComponent";

class ChartGMap extends Component {

  render() {
    const {data} = this.props;

    return (
      <MapComponent
        googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + process.env.GOOGLE_APIS_MAPS + "&v=3.exp&libraries=geometry,drawing,places"}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        markers={data.data}
        handleAddMarker={this.addDetail}
      />
    )
  }
}

ChartGMap.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      formattedAddress: PropTypes.string.isRequired,
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,).isRequired,
  }).isRequired,
};

export default ChartGMap;