import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {fetchPrizeCategories, updatePrizeCategory} from "../actions";
import {connect} from "react-redux";
import PrizeCategoryForm from './PrizeCategoryForm';
import LoadingSpinner from "components/LoadingSpinner";

class PrizeCategoryEdit extends Component {

  componentDidMount(){
    this.props.fetchPrizeCategories();
  }

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          {!this.props.prizeCategory
            ? <LoadingSpinner width={20} classNames="my-0" />
            : <Card>
            <CardHeader><FormattedMessage id="admin.prizeCategoryEdit.cardHeader" />: {this.props.prizeCategory.name}</CardHeader>
              <hr className="my-0" />
              <CardBody>
              <Row>
                <Col>
                   <PrizeCategoryForm prizeCategories={this.props.prizeCategories} handleSave={this.props.updatePrizeCategory} prizeCategory={this.props.prizeCategory} />
                </Col>
              </Row>
            </CardBody>
          </Card>}
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    prizeCategories: state.admin.prizeCategories.data.prizeCategories,
    prizeCategory: state.admin.prizeCategories.data.prizeCategories[props.match.params.prizeCategory]
  }
}

export default connect(mapStateToProps, {fetchPrizeCategories, updatePrizeCategory})(PrizeCategoryEdit);