import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import {connect} from 'react-redux';
import {deleteRespondentBaseColumn} from "reducers/user/actions";

class ColumnDeleteButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    };
    this.columnDelete = this.columnDelete.bind(this);
  }

  columnDelete(e) {
    e.preventDefault();
    this.setState({deleting: true, errors: {}});

    this.props.deleteRespondentBaseColumn(this.props.column.id)
      .then(() => this.setState({deleting: false, modal: false}))
      .catch(e => {
        this.setState({
          deleting: false,
          errors: e.response.data.form.errors
        });
      });
  }

  toggleDeleteModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { deleting } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleDeleteModal()}>
      <ModalHeader toggle={() => this.toggleDeleteModal()} tag="h2"><FormattedMessage id="columnDeleteButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="columnDeleteButton.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.modal.delete.confirmation">{
              (message) => <LaddaButton
                className="btn btn-danger btn-ladda mb-0 ml-3"
                loading={deleting}
                data-style={ZOOM_OUT}
                onClick={this.columnDelete}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggleDeleteModal()}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="m-0 p-0" onClick={() => this.toggleDeleteModal()}><i className="fas fa-times"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

ColumnDeleteButton.propTypes = {
  column: PropTypes.object.isRequired,
};

export default connect(null, {deleteRespondentBaseColumn})(ColumnDeleteButton);