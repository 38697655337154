import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Route, Redirect} from "react-router-dom"
import appRoutes from '../appRoutes';
import LoadingSpinner from "components/LoadingSpinner";
import DeletingAccount from "../scenes/Profile/DeletingAccount";

const UserRoute = ({ isAuthenticated, user, isBlocked, component: Component, ...rest}) => {

  let render = function (props) {
    if (isAuthenticated) {
      if(user.deletedDate){
        return <DeletingAccount/>
      }

      if (isBlocked && window.location.pathname !== appRoutes.users.group) {
        return <Redirect to={appRoutes.users.group}/>;
      }

      if (Array.isArray(user.roles)) {
        if ((user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN')) && window.location.pathname === appRoutes.home) {
          return <Redirect to={appRoutes.admin.main}/>;
        }

        if(_.isEmpty(user.userPlan) && window.location.pathname !== appRoutes.users.planDemo){
          return <Redirect to={appRoutes.users.planDemo}/>;
        }
        if((!user.roles.includes('ROLE_SUPER_ADMIN') && !user.roles.includes('ROLE_ADMIN')) && user.userPlan && (user.userPlan.isExpired || !user.userPlan.plan) && window.location.pathname.indexOf(appRoutes.users.plan) < 0){
          return <Redirect to={appRoutes.users.plan}/>;
        }

        return <Component {...props} />;
      } else {
        return <LoadingSpinner />
      }
    } else {
      return <Redirect to={appRoutes.users.login}/>;
    }
  };

  return (
      <Route {...rest} children={props => render(props)} />
  )
};

UserRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    userPlan: PropTypes.shape({
      expireDate: PropTypes.string.isRequired,
      isExpired: PropTypes.bool.isRequired,
    }),
  }),
};

function mapStateToProps(state) {
  const groupId = Object.keys(state.groups.data.groups)[0];
  const groupUser = groupId ? _.find(state.groups.data.groups[groupId].users, user => user.user.id === state.user.id) : false;

  return {
    isAuthenticated: Object.keys(state.user).length > 0,
    user: state.user,
    isBlocked: !!groupUser ? groupUser.blocked : false,
  }
}

export default connect(mapStateToProps)(UserRoute);