import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input} from "reactstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import TableMatrix from "./TableMatrix";
import {connect} from "react-redux";

const detailTypes = ['selected', 'notSelected'];
const condTypes = ['or', 'and', 'minMax'];

class Matrix extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpenDropdownCondType: false,
      isOpenDropdownDetailType: false,
    };

    this.toggleIsOpenDropdownCondType = this.toggleIsOpenDropdownCondType.bind(this);
    this.toggleIsOpenDropdownDetailType = this.toggleIsOpenDropdownDetailType.bind(this);
  }

  componentWillMount() {
    const {condQuestion} = this.props;
    let condQuestionChanged = condQuestion;

    if(_.isUndefined(condQuestion.condType) || !condTypes.includes(condQuestion.condType)){ condQuestionChanged.condType = condTypes[0]; }
    if(_.isUndefined(condQuestion.detailType) || !detailTypes.includes(condQuestion.detailType)){ condQuestionChanged.detailType = detailTypes[0]; }
    if(_.isUndefined(condQuestion.details)){ condQuestionChanged.details = []; }

    if(condQuestion !== condQuestionChanged){
      Matrix.propTypes.handleCondChanged(condQuestionChanged);
    }
  }

  toggleIsOpenDropdownCondType(){
    this.setState({
      isOpenDropdownCondType: !this.state.isOpenDropdownCondType,
    })
  }

  toggleIsOpenDropdownDetailType(){
    this.setState({
      isOpenDropdownDetailType: !this.state.isOpenDropdownDetailType,
    })
  }

  columnsByCount(count){
    switch (count) {
      case 1:
        return '1 kolumna';
      case 2:
      case 3:
      case 4:
        return count + ' kolumny';
    }

    return count + ' kolumn'
  }

  render() {
    const {isOpenDropdownDetailType, isOpenDropdownCondType} = this.state;
    const {intl, condQuestion, changeable, rows} = this.props;

    if (!changeable) {
      const rowsGrouped = {};
      condQuestion.details.map(detail => {
        if(rowsGrouped[detail['row']] === undefined){
          rowsGrouped[detail['row']] = [];
        }
        rowsGrouped[detail['row']].push(detail['column']);
      });

      return <React.Fragment>
        {intl.formatMessage({id: 'filter.condDetail.Matrix.detailType-' + condQuestion.detailType})} { }
        {intl.formatMessage({id: 'filter.condDetail.Matrix.condType-' + condQuestion.detailType + '-' + condQuestion.condType})} { }

        {condQuestion.condType === 'minMax' && <React.Fragment>
          <FormattedMessage id="condQuestion-minMax-min"/>
          <span className="mx-1">{condQuestion.min}</span>
          <FormattedMessage id="condQuestion-minMax-max"/>
          <span className="mx-1">{condQuestion.max}</span>
        </React.Fragment>}

        <div className="d-flex flex-column">
          {_.map(rowsGrouped, (columns, row) => {
            return <div className="d-flex">{rows[row].position+1}. {rows[row].stripedContent} - {this.columnsByCount(columns.length)}</div>
          })}
        </div>
      </React.Fragment>
    }

    return (
      <React.Fragment>
        <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenDropdownDetailType} toggle={this.toggleIsOpenDropdownDetailType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Matrix.detailType-' + condQuestion.detailType})}</DropdownToggle>
          <DropdownMenu>
            {detailTypes.map(detailType => <DropdownItem tag="a" key={detailType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              detailType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.Matrix.detailType-' + detailType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown>

        <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenDropdownCondType} toggle={this.toggleIsOpenDropdownCondType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Matrix.condType-' + condQuestion.detailType + '-' + condQuestion.condType})}</DropdownToggle>
          <DropdownMenu>
            {condTypes.map(condType => <DropdownItem tag="a" key={condType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              condType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.Matrix.condType-' + condQuestion.detailType + '-' + condType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown>

        {condQuestion.condType === 'minMax' && <React.Fragment>
          <FormattedMessage id="condQuestion-minMax-min"/>
          <Input
            type="number"
            className="d-inline mx-2"
            size="sm"
            style={{width: 50}}
            value={condQuestion.min} onChange={e => this.props.handleCondChanged({
            ...condQuestion,
            min: parseInt(e.target.value),
          })} />

          <FormattedMessage id="condQuestion-minMax-max"/>
          <Input
            type="number"
            className="d-inline ml-2"
            size="sm"
            style={{width: 50}}
            value={condQuestion.max} onChange={e => this.props.handleCondChanged({
            ...condQuestion,
            max: parseInt(e.target.value),
          })} />
        </React.Fragment>}

        <span>z poniższej tabeli </span>

        <TableMatrix question={condQuestion.question} details={condQuestion.details} handleDetailsChanged={details => {
          this.props.handleCondChanged({
            ...condQuestion,
            details,
          })
        }} />

      </React.Fragment>
    );
  }
}

Matrix.defaultProps = {
  changeable: true
};

Matrix.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number.isRequired,
    condType: PropTypes.oneOf(condTypes),
    detailType: PropTypes.oneOf(detailTypes),
    details: PropTypes.arrayOf(PropTypes.shape({
      row: PropTypes.number.isRequired,
      column: PropTypes.number.isRequired,
    })),
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state, props) {
  return {
    rows: state.survey.structure.data.rows,
    columns: state.survey.structure.data.columns,
    questionObject: state.survey.structure.data.questions[props.condQuestion.question],
  }
}

export default connect(mapStateToProps)(injectIntl(Matrix));