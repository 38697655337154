import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import AddressDeleteButton from './AddressDeleteButton';
import AddressEditButton from './AddressEditButton';
import AddressForm from "./AddressForm";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Pagination from "components/Pagination";
import ReactTable from "react-table";
import Checkbox from "../../../components/Checkbox";
import Button from "../../../components/Button";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import api from "../../../api";


const ButtonDeleteBulk = (props) => {
  const [modal, setModal] = React.useState(false);
  const toggleModal = () => setModal(!modal);
  const [deleting, setDeleting] = React.useState(false);

  const deleteAction = () => {
    setDeleting(true);

    api.respondentBase.address.deleteBulk(props.baseId, props.ids)
    .then(data => {
      setDeleting(false);
      toggleModal();
      props.onDeleted();
    })
    .catch(res => {
      setDeleting(false);
    })
  };

  return <React.Fragment>
    <Button color="danger" onClick={toggleModal}>Usuń zaznaczonych respondentów ({props.ids.length})</Button>
    {modal && <Modal isOpen={true} toggle={toggleModal} size="lg">
      <ModalHeader tag="h2" toggle={toggleModal}>Usuń respondentów</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        Czy na pewno chcesz usunąć zaznaczonych respondnetów?
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.delete">{
          (message) => <Button
            color="danger"
            loading={deleting}
            onClick={deleteAction}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
};

class AddressesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: [],
    };
  }

  static createdAt(cell, row){
    return row.createdAt;
  }

  static actions(address){
    return (
      <ul className="float-right mb-0 p-0 d-flex flex-column flex-md-row justify-content-center justify-content-md-end w-100 text-center">
        <li className="float-left mr-2">
          <AddressEditButton address={address}/>
        </li>
        <li className="float-left mr-2">
          <AddressDeleteButton address={address}/>
        </li>
      </ul>
    )
  }

  toggleChecked(id){
    const checked = _.xor(this.state.checked, [id]);
    this.setState({checked});
  };

  render() {
    const {addresses, columnsCount, columns} = this.props;

    let tableColumns = [
      {
        id: 'check',
        accessor: row => <Checkbox id="check" checked={this.state.checked.includes(row.id)} tooltip={false} onClick={() => this.toggleChecked(row.id)}/>,
        width: 20
      },
      {
      Header: <FormattedMessage id="addressesList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="addressesList.listItems.tableHeader.email" />,
      accessor: 'email'
    }];

    for (let i = 1; i <= columnsCount; i++) {
      let columnName = "column_" + i;
      let columnIndex = i - 1;

      tableColumns.push({
        Header: Object.values(columns)[columnIndex].name,
        accessor: columnName
      });
    }

    tableColumns.push(
      {
        id: 'unsubscribed',
        Header: 'Wypisany',
        accessor: (row) => {
          if(row.unsubscribed){
            return <span className="text-danger">Tak</span>
          }

          return <span className="text-success">Nie</span>
        }
      },
      {Header: <FormattedMessage id="addressesList.listItems.tableHeader.createdAt" />, accessor: 'createdAt'},
      {id: 'actions', Header: <FormattedMessage id="addressesList.listItems.tableHeader.actions" />, accessor: action => AddressesList.actions(action)}
    );

    return <React.Fragment>
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={addresses.loading ? [] : Object.values(addresses.data.addresses)}
        columns={tableColumns}
        showPagination={!_.isEmpty(addresses.data.addresses)}
        PaginationComponent={Pagination}
        loading={addresses.loading}
        noDataText={<FormattedMessage id="addressesList.listItems.table.noAddresses" />}
        defaultPageSize={10}
      />

      {this.state.checked.length > 0 && <ButtonDeleteBulk ids={this.state.checked} baseId={this.props.baseId} onDeleted={() => {
        this.setState({
          checked: []
        });

        if(this.props.onDeleted){
          this.props.onDeleted();
        }
      }} />}
    </React.Fragment>
  }
}

AddressForm.propTypes = {
  baseId: PropTypes.number.isRequired,
  addresses: PropTypes.object,
  onDeleted: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    columnsCount: Object.keys(state.user.respondentBaseColumns).length,
    columns: state.user.respondentBaseColumns
  }
}

export default connect(mapStateToProps)(AddressesList);
