import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Form, FormGroup, Label, Col, Input, FormFeedback, Alert} from "reactstrap";
import api from "../../api";
import CollectorsSelect from "../../components/Survey/Analysis/CollectorsSelect";
import SurveyCollectorsSelect from "../SurveyCollectorsSelect";

class SurveyResponseFakersForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        ...props.data,
        survey: props.surveyId,
      },
      saving: false,
      errors: {},
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  changeData(field, value){
    this.setState({
      data: {
        ...this.state.data,
        [field]: value,
      }
    })
  }

  submit(e){
    e.preventDefault();
  }

  save(){
    const {data} = this.state;

    this.props.onSavingStart();

    this.setState({
      errors: {},
    })

    api.surveyResponseFakers.post(data)
    .then(data => {
      this.props.onSavingStop();

      this.props.onSaved(data.data);
    })
    .catch(res => {
      this.props.onSavingStop();

      this.setState({
        errors: res.response.data
      })
    })
  }

  render() {
    const {data, errors} = this.state;

    return <Form>
      <Alert color="info">Można tylko wybrać kolektor typu Link do ankiety</Alert>
      <FormGroup row>
        <Label md={4}>Wybierz kolektor</Label>
        <Col md={8}>
          <SurveyCollectorsSelect
            onlyTypes={['WebLink']}
            survey={{
              id: this.props.surveyId,
            }}
            onChange={collector => this.changeData('collector', collector.id)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Liczba wypełnień:</Label>
        <Col md={8}>
          <Input type="number" max={2000} onChange={e => this.changeData('maxSupplement', e.target.value > 2000 ? 2000 : e.target.value)} value={data.maxSupplement} />
        </Col>
      </FormGroup>
    </Form>
  }
}

SurveyResponseFakersForm.defaultProps = {
  data: {
    collector: null,
    maxSupplement: 100,
  },
};

SurveyResponseFakersForm.propTypes = {
  data: PropTypes.object,
  onSaved: PropTypes.func,
  onSavingStart: PropTypes.func,
  onSavingStop: PropTypes.func,
  surveyId: PropTypes.number.isRequired,
};

export default SurveyResponseFakersForm;
