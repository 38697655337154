import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Filter from "scenes/Survey/Structure/components/Filter";
import FiltersEditTextarea
  from "../../../../../../../../../../../../../../../../../../../../../components/Settings/Filters/FiltersEditTextarea";
import {isEmpty} from "lodash";

class FilterEditModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      colMd: 12,
      filter: this.props.filter ? JSON.parse(JSON.stringify(this.props.filter)) : undefined,
      editFilterTextarea: false,
    }

    this.filter = React.createRef();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.filter !== this.props.filter){
      this.setState({
        filter: nextProps.filter ? JSON.parse(JSON.stringify(nextProps.filter)) : undefined,
      })
    }
  }

  getFilter() {
    return this.filter && this.filter.current.getFilter();
  }

  render() {
    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="xl">
      <div className="modal-header">
        <h2 className="modal-title w-100 d-flex flex-row justify-content-between">
          <span>Filtr</span>

          <div className="d-flex flex-row align-items-center">
            <div>
              {this.props.surveyPlan.filtersFromTextarea && <Button onClick={() => this.setState({editFilterTextarea: true})}><i className="fas fa-edit"/> Edytuj z
                pola tekstowego</Button>}
              {this.state.editFilterTextarea && <FiltersEditTextarea
                btn={false}
                filters={[
                  this.getFilter()
                ]}
                onClose={filters => {
                  let state = {
                    editFilterTextarea: false,
                  }
                  if(isEmpty(filters)){
                    this.props.toggleModal();
                  }else{
                    state.filter = filters[0];
                  }

                  this.setState(state);
                }}
              />}
            </div>
            <Button
              color="default"
              className="float-right mt-1"
              size="xs"
              onClick={() => this.setState({colMd: this.state.colMd === 12 ? 6 : 12})}
            ><i className={'fas fa-' + (this.state.colMd === 12 ? 'arrows-alt-v' : 'arrows-alt-h')} /></Button>
          </div>
        </h2>
        <button type="button" className="close" aria-label="Close" onClick={this.props.toggleModal}><span aria-hidden="true">×</span></button>
      </div>
      <hr className="my-0" />
      <ModalBody>
        {!this.state.editFilterTextarea && <Filter colMd={this.state.colMd} ref={this.filter} filter={this.state.filter}/>}
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.props.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={this.props.saving}
            data-style={ZOOM_OUT}
            onClick={this.props.onSave}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>
  }
}

FilterEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  filter: PropTypes.object
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(FilterEditModal);