import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import SelectAnswers from "../../../SelectAnswers";

class QuestionAnswers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      question: props.question,
      modalOpen: false,
      modalAnswers: props.answers || [],
    };

    this.toggleModalAnswers = this.toggleModalAnswers.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return props.question !== state.question ? {modalAnswers: props.answers, question: props.question} : null;
  }

  toggleModalAnswers(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {question, answers, answersObject, changeable, questionObject} = this.props;
    const {modalOpen, modalAnswers} = this.state;

    if (!changeable) {
      return answers.map((id, i, arr) => {
        const tooltipId = "answerTooltipId-" + Math.random().toString(36).substring(7);
        let divider = i < arr.length - 1 && '| ';
        const answer = answersObject.find(q => q.id === id);

        if(!answer){
          return <span className="text-muted"><i>Brak odpowiedzi</i></span>
        }

        return <React.Fragment key={id}>
          <span className="pointer" id={tooltipId}>{answer.position + 1}. </span>
          <UncontrolledTooltip target={tooltipId}>
            {questionObject.answersImage ? <img src={answer.content} className="img-100"/> : <span className="img-30" dangerouslySetInnerHTML={{__html: answer.stripedContent}}/>}
          </UncontrolledTooltip>
          {divider}
        </React.Fragment>
      })
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalAnswers} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalAnswers}>Wybierz odpowiedzi</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-75 d-inline-block">
          <SelectAnswers questionId={question} value={modalAnswers} onChange={answers => {
            this.setState({
              modalAnswers: answers,
            })
          }} />
        </div>
        <div className="w-25 d-inline-block pl-3">
          Wybranych odpowiedzi: {modalAnswers.length}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleAnswersChanged(modalAnswers);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={!_.isEmpty(answers) ? 'secondary' : 'warning'} className="mx-1 pointer d-inline-flex flex-wrap" onClick={this.toggleModalAnswers}>{!_.isEmpty(answers) ? answers.map((id, i, arr) => {
          let divider = i < arr.length - 1 && <span className="mx-1">|</span>;
          const answer = answersObject.find(q => q.id === id);

          if (questionObject.answersImage) {
            return <span key={id} className="img-20">{answer.position + 1}. <img src={answer.content}/>{divider}</span>
          }

          return <React.Fragment key={id}><span className="ch-25 img-20 question-badge ws-nowrap d-inline-block" dangerouslySetInnerHTML={{__html: answer.position+1 + '. ' + answer.stripedContent}} />{divider}</React.Fragment>;
        }) : 'Wybierz odpowiedzi...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

QuestionAnswers.defaultProps = {
  changeable: true
};

QuestionAnswers.propTypes = {
  question: PropTypes.number.isRequired,
  answers: PropTypes.array.isRequired,
  handleAnswersChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state, props) {
  let answersObject = [];
  if(props.question && 'answers' in state.survey.structure.data.questions[props.question]){
    answersObject = state.survey.structure.data.questions[props.question].answers.map(aId => state.survey.structure.data.answers[aId]);
    let position = 0;
    answersObject = answersObject.map(answer => {
      const ret = {
        ...answer,
        position
      };

      position++;
      return ret;
    });
  }


  return {
    answersObject,
    questionObject: state.survey.structure.data.questions[props.question]
  }
}

export default connect(mapStateToProps)(QuestionAnswers);