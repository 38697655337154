import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {Input} from "reactstrap";
import {connect} from "react-redux";
import {updateFilter} from "../../../Structure/data/filters/actions";
import {updateFilterSerial} from "../../../Structure/data/filterSerial/actions";
import {updateFilterIterative} from "../../../Structure/data/filterIterative/actions";

FilterNameColumn.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
};

function FilterNameColumn(props) {
  const [name, setName] = React.useState('');
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const save = () => {
    let apiFn;
    let data;
    if (Object.values(props.filters).includes(props.filter)) {
      apiFn = props.updateFilter;
      data = {
        filter: {
          ...props.filter,
          name,
        }
      }
    } else if (Object.values(props.serialFilters).includes(props.filter)) {
      apiFn = props.updateFilterSerial;
      data = {
        filterSerial: {
          ...props.filter,
          name,
        }
      }
    } else {
      apiFn = props.updateFilterIterative;
      data = {
        filterIterative: {
          ...props.filter,
          name,
        }
      }
    }

    setSaving(true);
    setEditing(false);
    apiFn(data).then(() => {
      setSaving(false);
    }).catch(() => {
      setSaving(false);
    })
  }

  if(saving){
    return <i className="fas fa-spinner fa-spin mx-auto d-block" />
  }

  if(editing){
    return <Input
      value={name}
      autoFocus
      className="mb-0"
      size="sm"
      onChange={e => setName(e.target.value)}
      onBlur={() => {
        save();
      }}
      onKeyDown={e => {
        switch (e.which) {
          case 13: {
            save();
            break;
          }
          case 27: {
            e.preventDefault();
            setEditing(false);
            break;
          }
        }
      }}
    />
  }

  return (
    <span className="pointer" onClick={() => {
      setName(props.filter.name);
      setEditing(true);
    }}>{props.filter.name || <i><FormattedMessage id="filters.listItems.tableHeader.name.empty"/></i>}</span>
  );
}

function mapStateToProps(state) {
  return {
    filters: state.survey.structure.data.filters,
    serialFilters: state.survey.structure.data.filterSerial,
    iterativeFilters: state.survey.structure.data.filterIterative,
  }
}

export default connect(mapStateToProps, {updateFilter, updateFilterSerial, updateFilterIterative})(FilterNameColumn);