import * as React from 'react';
import PropTypes from "prop-types";
import Button from "../../../../components/Button";
import {Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Col, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import api from "../../../../api";
import {toast} from "react-toastify";

function SmsTestSend(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [sending, setSending] = React.useState(false);
  const [phones, setPhones] = React.useState('');

  const onChangePhones = (value) => {
    let phones = value
    .replace(',', "\n")
    .replace(' ', "\n")
    .replace(',,', ',')
    .replace(',', "\n")
    .replace("\n\n", "\n")
    .split("\n");

    phones = phones.slice(0, 9);

    setPhones(phones.join("\n"));
  }

  const send = () => {
    setSending(true);

    api.surveyCollector.sendTestPhone(props.collector.id, phones)
    .then(data => {
      setSending(false);
      setModalOpen(false);
      setPhones('');

      toast.success('Wiadomości zostały wysłane');
    })
    .catch(res => {
      setSending(false);
    })
  }

  return (
    <React.Fragment>
      <Button onClick={toggleModalOpen}>Wyślij testowe wiadomości</Button>
      {modalOpen && <Modal isOpen={modalOpen} toggle={toggleModalOpen} size="lg">
        <ModalHeader tag="h2" toggle={toggleModalOpen}>Wyślij testowe wiadomości</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label xs={12} md={5} lg={4}>Podaj numery telefonów:</Label>
              <Col xs={12} md={7} lg={8}>
                <Input type="textarea" rows={15} placeholder="Numery telefonów" onChange={e => onChangePhones(e.target.value)} value={phones} />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
          }</FormattedMessage>
          <Button
            color={"primary"}
            disabled={!phones}
            loading={sending}
            onClick={send}
          >Wyślij</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
}

SmsTestSend.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default SmsTestSend;