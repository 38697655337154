import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import Select from "react-select";
import _ from "lodash";

class SelectPages extends Component {
  constructor(props){
    super(props);

    this.select = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  onChange(e){
    const {isMulti} = this.props;

    if(isMulti){
      this.select.current.setState({isOpen: true, menuIsOpen: true});
    }

    if(isMulti){
      this.props.onChange(e.map(obj => obj.value))
    }else{
      this.props.onChange(e.value);
    }
  }

  render() {
    const {pages, value, isMulti, className} = this.props;

    return (
      <Select
        placeholder={isMulti ? "Wybierz strony" : "Wybierz strone"}
        className={className}
        ref={this.select}
        value={value && (!isMulti ?
          {
            label: 'Strona ' + (pages.find(p => p.id === value).positionInSurvey+1),
            value
          }
          :
          value.map(pId => {
            return {
              label: 'Strona ' + (pages.find(p => p.id === pId).positionInSurvey+1),
              value: pId,
            }
          }))
        }
        isOpen={false}
        isMulti={isMulti}
        onChange={this.onChange}
        options={pages.map(page => {
          return {
            label: 'Strona ' + (page.positionInSurvey+1),
            value: page.id,
          }
        })}
      />
    );
  }
}

SelectPages.defaultProps = {
  isMulti: true,
  className: "",
};

SelectPages.propTypes = {
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  pages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    positionInSurvey: PropTypes.number.isRequired,
  }).isRequired,).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number,]),
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let pages = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    let set = state.survey.structure.data.sets[setId];

    _.each(set.blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        let page = state.survey.structure.data.pages[pageId];
        page.positionInSurvey = positionInSurvey;
        pages.push(page);
        positionInSurvey++;
      });
    });
  });

  return {
    pages,
  }
}

export default connect(mapStateToProps)(SelectPages);