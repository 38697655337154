import React, {PureComponent} from 'react';
import QuestionAvailable from "./modals/QuestionAvailable";
import SurveySettingsAvailable from "./modals/SurveySettingAvailable";
import QuestionSettingsAvailable from "./modals/QuestionSettingAvailable";
import PipingAvailable from "./modals/PipingAvailable";
import FiltersAvailable from "./modals/FiltersAvailable";
import QuotasAvailable from "./modals/QuotasAvailable";
import CollectorsAvailable from "./modals/CollectorsAvailable";
import GlobalQuestionTemplatesAvailable from "./modals/GlobalQuestionTemplatesAvailable";
import AnalysisFilteringAvailable from "./modals/AnalysisFilteringAvailable";
import ExportResponseTimeAvailable from "./modals/ExportResponseTimeAvailable";
import UserQuestionTemplatesAvailable from "./modals/UserQuestionTemplatesAvailable";
import MultimediaAvailable from "./modals/MultimediaAvailable";
import CollectorsMaxAvailable from "./modals/CollectorsMaxAvailable";
import GroupsAvailable from "./modals/GroupsAvailable";
import GroupsUsersMaxAvailable from "./modals/GroupsUsersMaxAvailable";
import SetsAvailable from "./modals/SetsAvailable";
import BlocksAvailable from "./modals/BlocksAvailable";
import PagesAvailable from "./modals/PagesAvailable";
import SurveyAccess from "./modals/SurveyAccess";
import SurveyRedirectsAvailable from "./modals/SurveyRedirectsAvailable";
import LimitAvailable from "./modals/LimitAvailable";
import ClientsAvailable from "./modals/ClientsAvailable";
import SurveyIntegrationsAvailable from "./modals/SurveyIntegrationsAvailable";
import ExcludingsAvailable from "./modals/ExcludingsAvailable";
import WidgetsAvailable from "./modals/WidgetsAvailable";
import ExportDemographicsAvailable from "./modals/ExportDemographicsAvailable";

class PlanRestrictionsModals extends PureComponent {
  render() {
    return (
      <React.Fragment>
        <QuestionAvailable />
        <SurveySettingsAvailable />
        <QuestionSettingsAvailable />
        <PipingAvailable />
        <FiltersAvailable />
        <QuotasAvailable />
        <CollectorsAvailable />
        <GlobalQuestionTemplatesAvailable />
        <UserQuestionTemplatesAvailable />
        <MultimediaAvailable />
        <CollectorsMaxAvailable />
        <GroupsAvailable />
        <GroupsUsersMaxAvailable />
        <ExportResponseTimeAvailable />
        <ExportDemographicsAvailable />
        <SetsAvailable />
        <BlocksAvailable />
        <PagesAvailable />
        <AnalysisFilteringAvailable />
        <SurveyAccess />
        <SurveyRedirectsAvailable />
        <LimitAvailable />
        <ClientsAvailable />
        <SurveyIntegrationsAvailable />
        <ExcludingsAvailable />
        <WidgetsAvailable />
        <PipingAvailable />
      </React.Fragment>
    );
  }
}

export default PlanRestrictionsModals;