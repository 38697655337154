import React, {Component} from "react";
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

class ButtonBlocking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipIsOpen: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  render() {
    return (
      <a id={'blocking-' + this.props.answer.id} className={"fa-solid fa-lock ml-2" + (this.props.blocking ? " text-primary" : "")} onClick={() => this.props.toggleOption('blocking')}>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={this.state.tooltipIsOpen}
          target={'blocking-' + this.props.answer.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="answers.blocking.button.tooltip"/>
        </Tooltip>
      </a>
    );
  }
}

ButtonBlocking.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  blocking: PropTypes.bool.isRequired,
  toggleOption: PropTypes.func.isRequired
};

export default ButtonBlocking;
