import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader, Col, Input, ListGroupItem, ListGroupItemHeading, Row} from "reactstrap";
import useDidMountEffect from "../../../utils/useDidMountEffect";
import api from "../../../api";
import {get} from "lodash";
import {ReactSortable} from "react-sortablejs";
import Switch from "../../../components/Switch";
import PlanSelect from "../../../components/PlanSelect";
import PlanSettingValue from "./PlanSettingValue";

const SettingInput = (props) => {
  const [editing, setEditing] = React.useState(false);
  const [value, setValue] = React.useState(props.value);

  if(editing){
    return <Input
      size={'sm'}
      autoFocus
      className="mb-0 py-0"
      style={{ width: '200px' }}
      onChange={e => setValue(e.target.value)}
      onKeyDown={e => {
        switch(e.key){
          case 'Enter': {
            props.onChanged(value)
            setEditing(false)
            break;
          }
          case 'Escape': {
            setValue(props.value)
            setEditing(false)
            break;
          }
        }
      }}
      onBlur={() => {
        props.onChanged(value)
        setEditing(false)
      }}
      value={value}
    />
  }

  return <span onClick={() => setEditing(true)}>{props.children}</span>
}

const PlanCategories = props => {
  const [data, setData] = React.useState([]);
  const [plan, setPlan] = React.useState(null);

  useDidMountEffect(() => {
    api.planCategory.list()
      .then(res => setData(res.data.planCategories))
  })

  const updateSetting = (id, obj) => {
    setData([
      ...data.map(planCategory => ({
        ...planCategory,
        settings: planCategory.settings.map(setting => {
          if(setting.id === id){
            setting = {
              ...setting,
              ...obj,
            }
          }

          return setting;
        })
      }))
    ])

    api.planCategory.planCategorySetting.patch({
      id,
      data: {
        ...obj
      }
    })
  }

  const handlerOnEnd = (evt) => {
    const {newIndex} = evt;
    const planCategorySettingId = parseInt(evt.item.attributes['data-id'].nodeValue);
    const planCategoryToId = evt.to.attributes['id'] ? parseInt(evt.to.attributes['id'].nodeValue) : null;

    setData([
      ...data.map(planCategory => ({
        ...planCategory,
        settings: planCategory.settings.map(setting => {
          if(setting.id === planCategorySettingId){
            setting.position = newIndex-1
          }

          return setting;
        }).sort((a,b) => a.position > b.position ? 1 : -1)
      }))
    ])

    api.planCategory.planCategorySetting.patch({
      id: planCategorySettingId,
      data: {
        position: newIndex-1,
        planCategory: planCategoryToId,
      }
    })
      .then(res => {
        setData(res.data.planCategories)
      })
  }

  return (
    <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader><h2>Wyświetlanie planów</h2></CardHeader>
          <hr className="my-0" />
          <CardBody>
            <div className="mb-2 ml-auto" style={{width: '250px'}}>
              <PlanSelect
                isMulti={false}
                onChange={plan => {
                  setPlan(null)
                  api.plan.get.plan(plan.id)
                    .then(res => setPlan(res.data.plan))
                }}
              />
            </div>

            {data.map(planCategory => <ReactSortable
              key={planCategory.id}
              group={{
                name: 'planCategory',
              }}
              id={planCategory.id}
              animation={200}
              className="list-group mb-3"
              list={[
                {},
                ...planCategory.settings,
              ]}
              setList={() => {}}
              onEnd={handlerOnEnd}
              handle={".sortable"}
            >
              <ListGroupItem className="bg-secondary text-white d-flex flex-row justify-content-between align-items-center">
                <span>{planCategory.name}</span>
                {plan && <span>Plan: {plan.name}</span>}
              </ListGroupItem>
              {planCategory.settings.map(setting => {
                return <ListGroupItem className="bg-on-hover d-flex flex-row align-items-center justify-content-between py-1" key={setting.id} title={setting.path}>
                  <div className="d-flex flex-row align-items-center">
                    <Switch className="mb-0 mr-1" checked={setting.enabled} onChange={enabled => updateSetting(setting.id, { enabled })} />
                    <span className="sortable p-1 mr-2" style={{ cursor: 'grab' }}><i className="fas fa-arrows-up-down" /></span>
                    <SettingInput
                      value={setting.label}
                      onChanged={label => {
                        if(setting.label !== label) {
                          updateSetting(setting.id, {label})
                        }
                      }}
                    ><span className="text-nowrap mr-2">{setting.label}</span></SettingInput>
                    <SettingInput
                      value={setting.tooltip}
                      onChanged={tooltip => {
                        if(setting.tooltip !== tooltip){
                          updateSetting(setting.id, {tooltip})
                        }
                      }}
                    ><small className="text-secondary ml-2">{setting.tooltip || <i className="">Brak tooltipa</i> }</small></SettingInput>
                  </div>

                  <div className="d-flex justify-content-end">
                    {plan && <PlanSettingValue plan={plan} path={setting.path} />}
                  </div>
                </ListGroupItem>
              })}
            </ReactSortable>)}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

PlanCategories.propTypes = {

};

export default PlanCategories;
