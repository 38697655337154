import React, {Component} from 'react';
import {Form, FormGroup, Label, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';

class SurveyTemplatesForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      template: {
        name: props.template ? props.template.name : '',
        survey: props.template ? props.template.survey : null,
      },
      imagePath: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
  }

  onChange(e) {
    this.setState({
      ...this.state,
      template: {
        ...this.state.template,
        [e.target.name]: e.target.value
      }
    });
  }

  onImageUpload(e) {
    this.setState({
      ...this.state,
      imagePath: URL.createObjectURL(e.target.files[0]),
      template: {
        ...this.state.template,
        image: e.target.files[0],
      }
    });
  }

  render() {
    let {template} = this.state;
    const {errors, onSubmit} = this.props;

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup>
          <Label for="name"><FormattedMessage id="admin.surveyTemplatesForm.label.name" /></Label>
          <Input type="text" name="name" id="name" value={template.name} invalid={!!errors.name} onChange={this.onChange}/>
          {errors.name && <p className="help-block text-danger">{errors.name}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="name"><FormattedMessage id="admin.surveyTemplatesForm.label.survey" /></Label>
          <Input type="text" name="survey" id="survey" value={template.survey} invalid={!!errors.survey} onChange={this.onChange}/>
          {errors.survey && <p className="help-block text-danger">{errors.survey}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="image"><FormattedMessage id="admin.surveyTemplatesForm.label.image" /></Label>
          <img src={this.state.imagePath} className="mw-100 mh-100"/>
          <Input type="file" name="image" id="image" onChange={this.onImageUpload} />
          {errors.image && <p className="help-block text-danger">{errors.image}</p>}
        </FormGroup>
      </Form>
    )
  }
}

SurveyTemplatesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  template: PropTypes.object
};

function mapStateToProps() {
  return {
  }
}

export default connect(mapStateToProps, {}, null, {forwardRef: true})(SurveyTemplatesForm);