import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';

class ChangePasswordAction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      modal: false,
      loading: false,
      newPassword: null
    };

    this.changePassword = this.changePassword.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  changePassword() {
    this.setState({loading: true});

    api.user.patch.password(this.props.user.id)
      .then(pass => {
        this.setState({
          loading: false,
          newPassword: pass
        })
      })
      .catch(() => this.setState({loading: false}));
  }

  render() {
    return (
      <React.Fragment>
        <span id={'changeUserPassword-' + this.props.user.id} className="fas fa-key" onClick={() => this.toggle('modal')}/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip}
          target={'changeUserPassword-' + this.props.user.id}
          toggle={() => this.toggle('tooltip')}
        >
          <FormattedMessage id="admin.companies.changePasswordAction.tooltip" />
        </Tooltip>

        <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')}>
          <ModalHeader toggle={() => this.toggle('modal')} tag="h2"><FormattedMessage id="admin.companies.changePasswordAction.modalHeader"/></ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            {!this.state.newPassword && <p><FormattedMessage id="admin.companies.changePasswordAction.modalBody"/></p>}
            {this.state.newPassword && <p><u><FormattedMessage id="admin.companies.changePasswordAction.newPassword"/>: {this.state.newPassword}</u></p>}
          </ModalBody>
          {!this.state.newPassword && <ModalFooter>
            <div className="direction-group d-flex justify-content-end">
              <div className="direction-right">
                <FormattedMessage id="admin.companies.changePasswordAction.button">{
                  (message) => <LaddaButton
                    className="btn btn-danger btn-ladda mb-0 ml-3"
                    data-style={ZOOM_OUT}
                    onClick={this.changePassword}
                    loading={this.state.loading}
                  >
                    {message}
                  </LaddaButton>
                }</FormattedMessage>
                <FormattedMessage id="_.button.cancel">{
                  (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggle('modal')}>{message}</Button>
                }</FormattedMessage>
              </div>
            </div>
          </ModalFooter>}
        </Modal>
      </React.Fragment>
    )
  }
}

ChangePasswordAction.propTypes = {
  user: PropTypes.object.isRequired
};

export default ChangePasswordAction;