import update from 'immutability-helper';

import {
  SET,
  SET_QUESTIONS,
  ADDED,
  UPDATED,
  DELETED,
  QUESTION_ADDED,
} from './actions';
import * as questionsActions from "../questions/actions";

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case questionsActions.DELETED:
      return {
        ...state,
        [action.question.page.id]: {
          ...state[action.question.page.id],
          questions: state[action.question.page.id].questions.filter(id => id !== action.question.id)
        }
      };

    case questionsActions.TYPE_UPDATED:
      let questions = state[action.question.page.id].questions.filter(id => id !== action.expiredId);
      questions.splice(action.question.position, 0, action.question.id);

      return update(state, {
        [action.question.page.id]: {
          questions: {
            $set: questions
          }
        }
      });

    case QUESTION_ADDED:
      let list = state[action.question.page.id].questions;
      list.splice(action.question.position, 0, action.question.id);

      return update(state, {
        [action.question.page.id]: {
          questions: {
            $set: list
          }
        }
      });

    case SET:
      return action.pages;

    case SET_QUESTIONS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          questions: action.data.questions
        }
      };

    case ADDED:
      return {
        ...state,
        [action.page.id]: action.page
      };

    case UPDATED:
      return update(state, {
        [action.page.id]: {
          $set: action.page
        }
      });

    case DELETED:
      return update(state, {$unset: [action.page.id] });

    default:
      return state;
  }
};