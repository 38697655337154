import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';

class ShowActivationLinkAction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      modal: false,
    };
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  render() {
    return (
      <React.Fragment>
        <span id={'showActivationLink-' + this.props.user.id} className="fas fa-user-check mr-2 pointer" onClick={() => this.toggle('modal')}/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip}
          target={'showActivationLink-' + this.props.user.id}
          toggle={() => this.toggle('tooltip')}
        >Pokaż link aktywacyjny</Tooltip>

        <Modal size="xl" isOpen={this.state.modal} toggle={() => this.toggle('modal')}>
          <ModalHeader toggle={() => this.toggle('modal')} tag="h2">Link aktywacyjny dla {this.props.user.email}</ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            <span>https://swpanel.pl/dokoncz-rejestracje/{this.props.user.userActivation.registerKey}</span>
          </ModalBody>
          <ModalFooter>
            <div className="direction-group d-flex justify-content-end">
              <FormattedMessage id="_.button.close">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggle('modal')}>{message}</Button>
              }</FormattedMessage>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

ShowActivationLinkAction.propTypes = {
  user: PropTypes.object.isRequired
};

export default ShowActivationLinkAction;