// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./phone-overlay.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#phone-overlay{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:528px;height:815px;background-size:contain;margin-left:auto;background-repeat:no-repeat;margin-right:auto;left:0;background-position:center;right:4px;text-align:center}", "",{"version":3,"sources":["webpack://./src/scenes/Survey/Preview/components/preview.scss"],"names":[],"mappings":"AAAA,eACE,wDAAA,CACA,WAAA,CACA,YAAA,CACA,uBAAA,CACA,gBAAA,CACA,2BAAA,CACA,iBAAA,CACA,MAAA,CACA,0BAAA,CACA,SAAA,CACA,iBAAA","sourcesContent":["#phone-overlay {\n  background-image: url(\"./phone-overlay.png\");\n  width: 528px;\n  height: 815px;\n  background-size: contain;\n  margin-left: auto;\n  background-repeat: no-repeat;\n  margin-right: auto;\n  left: 0;\n  background-position: center;\n  right: 4px;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
