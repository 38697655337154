import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {UncontrolledTooltip} from "reactstrap";

class ProgressBar extends Component {

  render() {
    const progress = this.props.current === 0 ? 0 : (this.props.current / this.props.max) * 100;
    const color = progress < 33 ? 'danger' : (progress > 66 ? 'success' : 'primary');

    return (
      <React.Fragment>
        <div className={'progress w-100'} id={"progress-bar-" + this.props.id}>
          <div className={'progress-bar bg-' + color}
               role="progressbar"
               style={{width: progress + '%'}}
               aria-valuenow={progress + '%'}
               aria-valuemin="0"
               aria-valuemax="100" />
        </div>
        <UncontrolledTooltip target={"progress-bar-" + this.props.id}>{this.props.current}/{this.props.max}</UncontrolledTooltip>
      </React.Fragment>
    )
  }
}

ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired
};

export default ProgressBar;