import React, {Component} from 'react';
import {Form, FormGroup, Label, Col, Input, FormFeedback} from "reactstrap";
import PropTypes from 'prop-types';
import Button from "../../../../components/Button";
import api from "../../../../api";
import AnkieteoCmsSelect from "./AnkieteoCmsSelect";
import HtmlEditor from "../../../../componentsReusable/HtmlEditor";
import appRoutes from "../../../../appRoutes";
import withParams from "../../../../utils/withParams";

class AnkieteoCmsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      htmlCkeditor: false,
      data: props.data,
      saving: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentDidMount() {
    if(this.props.params.id){
      api.ankieteoCms.get(this.props.params.id)
      .then(data => {
        this.setState({
          data: data.data
        })
      })
    }
  }

  onChange(key, value){
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      }
    })
  }

  handleOnSubmit(e){
    e.preventDefault();

    this.setState({
      saving: true,
      errors: {},
    });

    let apiFn = api.ankieteoCms.post;
    if(this.props.params.id){
      apiFn = api.ankieteoCms.patch;
    }

    apiFn(this.state.data)
    .then(data => {

      this.setState({
        saving: false,
        errors: {},
      });

      this.props.history.push(appRoutes.admin.cms.ankieteo.list);
    })
    .catch(res => {

      this.setState({
        saving: false,
        errors: res.response.data
      });
    })
  }

  render() {
    const {data, saving, errors} = this.state;

    return (
      <Form onSubmit={this.handleOnSubmit}>
        {(!data.id || (data.id && data.level > 0)) && <FormGroup row>
          <Label md={2}>Dział</Label>
          <Col md={10}>
            <AnkieteoCmsSelect
              ankieteoCms={data.parent}
              onChange={ankieteoCms => this.onChange('parent', ankieteoCms)}
            />
            {_.has(errors, 'children.parent.errors') &&
            <FormFeedback className="d-block">{errors.children.parent.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>}
        <FormGroup row className="mt-2">
          <Label md={2}>Nazwa</Label>
          <Col md={10}>
            <Input invalid={!!_.has(errors, 'children.name.errors')} onChange={e => this.onChange('name', e.target.value)} value={data.name} readOnly={(data.id && data.level === 0)} />
            {_.has(errors, 'children.name.errors') && <FormFeedback>{errors.children.name.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label md={2}>Meta title</Label>
          <Col md={10}>
            <Input invalid={!!_.has(errors, 'children.title.errors')} onChange={e => this.onChange('title', e.target.value)} value={data.title} />
            {_.has(errors, 'children.title.errors') && <FormFeedback>{errors.children.title.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label md={2}>Meta description</Label>
          <Col md={10}>
            <Input invalid={!!_.has(errors, 'children.description.errors')} onChange={e => this.onChange('description', e.target.value)} value={data.description} />
            {_.has(errors, 'children.description.errors') && <FormFeedback>{errors.children.description.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label md={2}>Meta keywords</Label>
          <Col md={10}>
            <Input invalid={!!_.has(errors, 'children.keywords.errors')} onChange={e => this.onChange('keywords', e.target.value)} value={data.keywords} />
            {_.has(errors, 'children.keywords.errors') && <FormFeedback>{errors.children.keywords.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label md={2}>Zawartość strony</Label>
          <Col md={10}>
            {
              this.state.htmlCkeditor
                  ? <HtmlEditor
                      onChange={e => this.onChange('html', e)}
                      data={data.html}
                  />
                  : <div className="d-flex flex-column">
                    <Input type="textarea" rows={15} onChange={e => this.onChange('html', e.target.value)} value={data.html} />
                    <Button size="sm" className="mb-3" onClick={() => this.setState({
                      htmlCkeditor: true,
                    })}>Pokaż CKEditor</Button>
                  </div>

            }
            {_.has(errors, 'children.html.errors') && <FormFeedback>{errors.children.html.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label md={2}>Lewe menu</Label>
          <Col md={10}>
            <Input type="textarea" rows={15} onChange={e => this.onChange('menuLeft', e.target.value)} value={data.menuLeft} />

          </Col>
        </FormGroup>
        <FormGroup className="mt-2 d-flex justify-content-end">
          <Button
            loading={saving}
          >Zapisz</Button>
        </FormGroup>
      </Form>
    );
  }
}

AnkieteoCmsForm.defaultProps = {
  data: {
    name: '',
    parent: null,
    title: '',
    description: '',
    keywords: '',
    html: '',
    menuLeft: '',
  },
};

export default withParams(AnkieteoCmsForm);