import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {ReactSortable} from "react-sortablejs";
import _ from 'lodash';

import {Alert, Col, Row} from "reactstrap";

import QuestionBasket from "./components/Basket";
import QuestionBasketNew from "./components/BasketNew";

import {updateBasket, basketUpdated} from "scenes/Survey/Structure/data/baskets/actions";
import {setBaskets} from "scenes/Survey/Structure/data/questions/actions";

import './style.scss'
import BasketsActions from "./components/Actions";
import QuestionFrom from "../FilterSerial/QuestionFrom";
import QuestionTo from "../FilterSerial/QuestionTo";
import ActionAdd from "./components/Actions/components/Add";

class Baskets extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hideBaskets: props.hideInStructure !== 'none' || props.baskets.length >= 5,
      checkedBaskets: []
    };

    this.onUpdate = this.onUpdate.bind(this);
  }

  static getDerivedStateFromProps(props) {
    if(props.searchInStructure.length > 0) {
      return {hideBaskets: false};
    }

    return {};
  }

  onUpdate(evt){
    let {baskets} = this.props.question;

    const id = parseInt(evt.item.attributes['data-id'].nodeValue);
    baskets.splice(evt.oldIndex, 1);
    baskets.splice(evt.newIndex, 0, id);
    this.props.setBaskets(this.props.question.id, baskets);

    let position = 0;
    this.props.baskets.map(basket => {
      basket.position = position;
      this.props.basketUpdated(basket);
      position++;
    });

    this.props.updateBasket({
      id,
      basket: {
        position: evt.newIndex
      }
    });
  }

  toggleChecked(basketId, checked){
    let {checkedBaskets} = this.state;

    if(checked){
      checkedBaskets.push(basketId);
    }else{
      checkedBaskets = checkedBaskets.filter(id => id !== basketId)
    }

    this.setState({
      checkedBaskets
    });
  }

  toggleBaskets(){
    this.setState({
      hideBaskets: !this.state.hideBaskets
    })
  }

  static isFilterSerialFromEnable(question) {
    const enableTypes = ['multi', 'ranked'];

    return enableTypes.includes(question.type);
  }

  static isFilterSerialToEnable(question) {
    const enableTypes = ['single', 'multi', 'ranked', 'conjoint'];

    return enableTypes.includes(question.type);
  }

  render() {
    const {question, searchInStructure, filterSerialFrom, filterSerialTo} = this.props;
    const {checkedBaskets} = this.state;
    let baskets = _.sortBy(this.props.baskets, basket => basket.position);

    return (
      <Row className="mt-3">
        <Col>
          <h4>
            <span onClick={()=> this.toggleBaskets()}><FormattedMessage id="question.baskets.listGroup.header" /> ({baskets.length})</span>
            <div className='d-inline mx-2' key={Math.random()} onClick={()=> this.toggleBaskets()}>
              <i className={this.state.hideBaskets ? 'fas fa-angle-right' : 'fas fa-angle-down'}/>
            </div>
            {Baskets.isFilterSerialFromEnable(question) &&
              <div className='d-inline mx-2'>
                <QuestionFrom question={question} filterSerial={filterSerialFrom}/>
              </div>}
            {Baskets.isFilterSerialToEnable(question) &&
              <div className='d-inline mx-2'>
                <QuestionTo question={question} filterSerial={filterSerialTo}/>
              </div>}
          </h4>

          {(!this.state.hideBaskets && baskets.length > 0) && <div className="ml-lg-4">
            <ReactSortable
              list={baskets}
              setList={() => {}}
              group={{
                name: 'baskets-' + question.id,
              }}
              tag="ul"
              onUpdate={this.onUpdate}
              animation={100}
              filter={".sortable-ignore"}
              draggable={".sortable-draggable"}
              handle={".handler-drag-position"}
              className="baskets-list list-group"
            >
              {baskets.map((basket, position) => {
                return (
                  <QuestionBasket
                    position={basket.position}
                    basket={basket}
                    question={question}
                    handlerChecked={(checked) => this.toggleChecked(basket.id, checked)}
                    searchInStructure={searchInStructure}
                  />
                )
              })}
            </ReactSortable>
            <QuestionBasketNew autoFocus={false} position={baskets.length} question={question} />
          </div>}

          {baskets.length === 0 && <Alert color="warning">
            <p>To pytanie nie zostanie wyświetlone, ponieważ nie ma żadnych koszyków</p>
            <ActionAdd question={question} />
          </Alert>}

          {(baskets.length > 0 && !this.state.hideBaskets) && <BasketsActions checkedBaskets={checkedBaskets} baskets={baskets} question={question}/>}

        </Col>
      </Row>
    )
  }
}

Baskets.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    baskets: PropTypes.array.isRequired,
  }).isRequired
};

function mapStateToProps(state, props) {
  return {
    baskets: state.survey.structure.data.baskets ? _.map(props.question.baskets, id => state.survey.structure.data.baskets[id]) : [],
    searchInStructure: state.survey.structure.searchInStructure,
    hideInStructure: state.survey.structure.hideInStructure,
    filterSerialFrom: _.filter(state.survey.structure.data.filterSerial, filter => filter.questionFrom && filter.questionFrom.question === props.question.id),
    filterSerialTo: _.filter(state.survey.structure.data.filterSerial, filter => filter.questionTo && filter.questionTo.question === props.question.id)
  }
}

export default connect(mapStateToProps, { updateBasket, setBaskets, basketUpdated })(Baskets);