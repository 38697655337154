import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Form, FormGroup, Label, Col, Input, FormFeedback, Alert} from "reactstrap";
import api from "../../api";
import SurveySelect from "componentsNew/Survey/SurveySelect";
import {FormattedMessage} from "react-intl";
import SettingSlider
  from "../../scenes/Survey/Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/Settings/components/SettingSlider";
import Switch from "../../components/Switch";

class SurveySwitcherCheckForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      saving: false,
      errors: {},
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  changeData(field, value){
    this.setState({
      data: {
        ...this.state.data,
        [field]: value,
      }
    })
  }

  submit(e){
    e.preventDefault();
  }

  save(){
    const {data} = this.state;

    this.props.onSavingStart();

    this.setState({
      errors: {},
    })

    api.surveySwitcherChecks.post(data)
        .then(data => {
          this.props.onSavingStop();

          this.props.onSaved(data.data);
        })
        .catch(res => {
          this.props.onSavingStop();

          this.setState({
            errors: res.response.data
          })
        })
  }

  edit(){
    const {data} = this.state;
    const {id} = this.props;

    this.props.onSavingStart();

    this.setState({
      errors: {},
    })

    api.surveySwitcherChecks.patch(id, data)
        .then(data => {
          this.props.onSavingStop();

          this.props.onSaved(data.data);
        })
        .catch(res => {
          this.props.onSavingStop();

          this.setState({
            errors: res.response.data
          })
        })
  }

  render() {
    const {data, errors} = this.state;
    const {id} = this.props;

    return <Form>
      <Alert color="warning">
        Dodać treść instrukcji:<br />
        Jak prawidłowo ustawić ping test?<br />
        1/ wybierz ankietę, której dotyczy pomiar<br />
        2/ wskaż adres URL. Jeśli w adresie należy podstawić ID panelisty:<br />
        a/ wpisz URL bez ID  - o ile wywoła on prawidłowy adres strony (status 200)<br />
        b/ wpisz sztywny ID usera (poniżej {`<50`}) - o ile można wielokrotnie wejść na ankietę z jednego ID<br />
        3/ W polu znajdź treść wpisz treść, która jest na stronie po wywołaniu źródła strony (CTRL+U).<br />
        <br />
        UWAGA: Jeśli w źródle strony nie ma tekstu, który widzisz na stronie www - wykorzystaj fragment kodu ze źródła strony  np. getElementById<br />
        <br />
        Nie korzystaj z funkcji: Zbadaj element.
        <br /><br />
        Jeśli chcesz automatycznie <b>zamknąć swoją ankietę</b>, to w sytuacji gdy:<br /><br />
        1/ Chcesz ją zamknąć, jeśli ping nie znajdzie tekstu, ustaw:<br />
        Tekst musi być?  = WYŁĄCZONY/Czerwony<br />
        Zamykanie ankiety = WŁĄCZONY/Zielony<br />
        <br />
        2/ Chcesz ją zamknąć, jeśli ping znajdzie określony tekst, ustaw:
        Tekst musi być? = WŁĄCZONY/Zielony<br />
        Zamykanie ankiety = WŁĄCZONY/Zielony<br />
      </Alert>
      <FormGroup row className="mb-2">
        <Label md={4}>Wybierz ankiete:</Label>
        <Col md={8}>
          <SurveySelect
              value={id ? data.survey : false}
              onChange={survey => this.changeData('survey', survey.id)}
          />
          {_.has(errors, 'children.survey.errors') && <FormFeedback className="d-block">{errors.children.survey.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row className="mb-2">
        <Label md={4}>Adres url:</Label>
        <Col md={8}>
          <Input type="text" className="mb-0" invalid={!!_.has(errors, 'children.url.errors')} onChange={e => this.changeData('url', e.target.value)} value={data.url} />
          {_.has(errors, 'children.url.errors') && <FormFeedback>{errors.children.url.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Znajdź treść:</Label>
        <Col md={8}>
          <Input type="text" invalid={!!_.has(errors, 'children.matchText.errors')} onChange={e => this.changeData('matchText', e.target.value)} value={data.matchText} />
          {_.has(errors, 'children.matchText.errors') && <FormFeedback>{errors.children.matchText.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Podążaj za przekierowaniami?</Label>
        <Col md={8}>
          <Switch className="mb-0 mr-1" checked={data.followRedirects} onChange={value => this.changeData('followRedirects', value)} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Tekst musi być?</Label>
        <Col md={8}>
          <Switch className="mb-0 mr-1" checked={data.isTextMatch} onChange={value => this.changeData('isTextMatch', value)} />
          {_.has(errors, 'children.isTextMatch.errors') && <FormFeedback>{errors.children.isTextMatch.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Zamykanie ankiety</Label>
        <Col md={8}>
          <Switch className="mb-0 mr-1" checked={data.isCloseSurvey} onChange={value => this.changeData('isCloseSurvey', value)} />
          {_.has(errors, 'children.isCloseSurvey.errors') && <FormFeedback>{errors.children.isCloseSurvey.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
    </Form>
  }
}

SurveySwitcherCheckForm.defaultProps = {
  data: {
    survey: null,
    isCloseSurvey: false,
    isTextMatch: false,
    followRedirects: false,
    url: '',
    matchText: ''
  },
};

SurveySwitcherCheckForm.propTypes = {
  id: PropTypes.number,
  data: PropTypes.object,
  onSaved: PropTypes.func,
  onSavingStart: PropTypes.func,
  onSavingStop: PropTypes.func,
};

export default SurveySwitcherCheckForm;
