import api from "api";
import * as types from './actionTypes';

export function setUserPrizes(userPrizes) {
  return {
    type: types.SET,
    userPrizes
  }
}

export function userPrizeUpdated(userPrize) {
  return {
    type: types.UPDATED,
    userPrize
  }
}

export function fetchUserPrizes() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.userPrize.get.userPrizes().then(userPrizes => {
      dispatch(setUserPrizes(userPrizes));
      dispatch({type: types.SUCCESS});
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}

export function updateUserPrize(data) {
  return dispatch => {
    return api.userPrize.patch(data).then(userPrize => {
      dispatch(userPrizeUpdated(userPrize));
    })
  }
}
