import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Form, Row, Col} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {connect} from "react-redux";
import {uploadAddressesFile} from "../actions";
import {toast} from "react-toastify";


class AddressesImportCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
      file: null,
      addressesResponse: false,
    };

    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }

  handleUploadFile(e) {
    this.setState({
      file: e.target.files[0]
    });
  }

  fileUpload(file) {
    const formData = new FormData();
    formData.append('file', file);
    this.setState({ creating: true});

    this.props.uploadAddressesFile(this.props.respondentBaseId, formData)
      .then(addresses => {
        let state = {
          creating: false,
          addressesResponse: addresses,
        };

        this.setState(state);
      })
      .catch((e) => {
        const errorMessage = e.response.data.form.errors.email ? e.response.data.form.errors.email[0] : e.response.data.form.errors[0];
        toast.error(errorMessage);
        this.setState({
          creating: false
        });
      });
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.fileUpload(this.state.file);
  }

  render() {
    const {creating, file, addressesResponse} = this.state;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle} tag="h2"><FormattedMessage id="addressesImportCSV.modalHeader"/></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <Form id="address-import-csv-form" onSubmit={(e) => this.onFormSubmit(e)} className="d-flex justify-content-center">
            <Input
              name="addresses-import-csv"
              type="file"
              placeholder="Import addresses"
              onChange={this.handleUploadFile}
            />
          </Form>

          {addressesResponse !== false && <Row>
            <Col>
              {addressesResponse.added.length > 0
                ? <div className="alert alert-success text-center">Adresy dodane: {addressesResponse.added.length}</div>
                : <div className="alert alert-warning text-center">Nie dodano żadnego nowego adresu</div>
              }
              {addressesResponse.rejected.length > 0 && <div className="alert alert-danger text-center">
                <span className="font-weight-bold">Adresy, które nie zostały dodane: {addressesResponse.rejected.length}</span>
                <ul>
                  {addressesResponse.rejected.map(address => {
                    return <li>{address.email}</li>
                  })}
                </ul>
              </div>}
            </Col>
          </Row>}
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.add">{
                (message) => <LaddaButton
                  type="submit"
                  form="address-import-csv-form"
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={creating}
                  data-style={ZOOM_OUT}
                  disabled={!file}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) =>
                  <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggle}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

AddressesImportCSV.propTypes = {
  respondentBaseId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default connect(null, { uploadAddressesFile })(AddressesImportCSV);
