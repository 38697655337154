import update from 'immutability-helper';

import { SET, ADDED, UPDATED, DELETED } from './actions';

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.levels;

    case ADDED:
      return {
        ...state,
        [action.level.id]: action.level
      };

    case UPDATED:
      return update(state, {
        [action.level.id]: {
          $set: action.level
        }
      });

    case DELETED:
      return update(state, {
        $unset: [action.level.id]
      });

    default:
      return state;
  }
};