import React, {Component} from 'react';
import {Form, FormGroup, Label, Col, Input, FormFeedback} from "reactstrap";
import Button from "../../../../components/Button";
import api from "../../../../api";
import appRoutes from "../../../../appRoutes";
import withParams from "../../../../utils/withParams";

class AnkieteoCmsNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      saving: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentDidMount() {
    api.ankieteoNav.get(this.props.params.id)
    .then(data => {
      this.setState({
        data: data.data
      })
    })
  }

  onChange(key, value){
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      }
    })
  }

  handleOnSubmit(e){
    e.preventDefault();

    this.setState({
      saving: true,
      errors: {},
    });

    api.ankieteoNav.patch(this.props.params.id, this.state.data)
    .then(data => {
      this.setState({
        saving: false,
        errors: {},
      });

      this.props.history.push(appRoutes.admin.cms.ankieteo.list);
    })
    .catch(res => {

      this.setState({
        saving: false,
        errors: res.response.data
      });
    })
  }

  render() {
    const {data, saving, errors} = this.state;

    return (
      <Form onSubmit={this.handleOnSubmit}>
        <FormGroup row>
          <Label md={2}>Zawartość nawigacji</Label>
          <Col md={10}>
            <Input type="textarea" rows={15} onChange={e => this.onChange('html', e.target.value)} value={data.html} />
          </Col>
        </FormGroup>
        <FormGroup className="mt-2 d-flex justify-content-end">
          <Button
            loading={saving}
          >Zapisz</Button>
        </FormGroup>
      </Form>
    );
  }
}

AnkieteoCmsNav.defaultProps = {
  data: {
    html: '',
  },
};

AnkieteoCmsNav.propTypes = {
};

export default withParams(AnkieteoCmsNav);