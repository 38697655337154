import api from "api";
import * as attributesActions from "../actions";

export const SET = 'Survey/data/attributes/levels/SET';
export const ADDED = 'Survey/data/attributes/levels/ADDED';
export const DELETED = 'Survey/data/attributes/levels/DELETED';
export const UPDATED = 'Survey/data/attributes/levels/UPDATED';

export function setAttributesLevels(levels) {
  return {
    type: SET,
    levels
  }
}
export function attributeLevelAdded(level) {
  return {
    type: ADDED,
    level
  }
}
export function attributeLevelDeleted(level) {
  return {
    type: DELETED,
    level
  }
}
export function attributeLevelUpdated(level) {
  return {
    type: UPDATED,
    level
  }
}





export function addAttributeLevel(data) {
  return dispatch => {
    return api.question.attributes.levels.post(data).then(data => {
      dispatch(attributesActions.levelAdded(data.data));
      dispatch(attributeLevelAdded(data.data));

      return data.data;
    });
  }
}

export function deleteAttributeLevel(level, attribute) {
  return dispatch => {
    return api.question.attributes.levels.delete(level.id).then(res => {
      dispatch(attributeLevelDeleted({
        id: level.id,
        attribute: {
          id: attribute.id
        }
      }))
    })
  }
}

export function updateAttributeLevel(id, data) {
  return dispatch => {
    return api.question.attributes.levels.patch(id, data).then(data => {
      dispatch(attributeLevelUpdated(data.data))
    })
  }
}