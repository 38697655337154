import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Form, FormGroup, Input, Label} from "reactstrap";
import {ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";
import PropTypes from "prop-types";
import api from 'api';
import HelpModeIconTooltip from "components/HelpModeIconTooltip";
import reactHTMLParser from "react-html-parser";
import ModalEmailVars from "../ModalEmailVars";
import striptags from "striptags";
import Switch from "../../../../../components/Switch";
import {connect} from "react-redux";
import HtmlEditor from "../../../../../componentsReusable/HtmlEditor";

class TabTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      mailFooter: props.collector.mailFooter,
      template: props.collector.template,
      editorContentOpen: false,
      editorContentReminderOpen: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onContentChange = this.onContentChange.bind(this);
  }

  onContentChange(contentType, content){
    this.setState({
      template: {
        ...this.state.template,
        [contentType]: content
      }
    });
  };

  onChange(e){
    this.setState({
      template: {
        ...this.state.template,
        [e.target.name]: e.target.value
      }
    });
  }

  onClick(editorType){
    this.setState({
      [editorType]: !this.state[editorType]
    });
  }

  onSubmit(e){
    e.preventDefault();

    const {collector} = this.props;
    const {template, mailFooter} = this.state;

    this.setState({saving: true});
    api.surveyCollector.patch({
      id: collector.id,
      surveyCollector: {
        mailFooter,
        template,
      }
    }).then(collector => {
      this.setState({saving: false});
      this.props.handleUpdate(collector);
    }).catch(() => {
      this.setState({saving: false});
    });
  }

  render() {
    const {saving, template, editorContentOpen, editorContentReminderOpen} = this.state;
    const {collector} = this.props;

    return (
      <React.Fragment>
        <HelpModeIconTooltip className="mb-2">Szablon wiadomości wysyłanych do respondentów tego kolektora</HelpModeIconTooltip>
        <ModalEmailVars collector={collector} />

        <Form onSubmit={this.onSubmit}>
          <FormGroup>
            <Label for="subject"><FormattedMessage id="surveyCollector.typeEmail.template.subject"/></Label>
            <Input
              id="subject"
              name="subject"
              onChange={this.onChange}
              value={template.subject || ""}
            />
          </FormGroup>
          <FormGroup className="mb-2">
            <Label for="content"><FormattedMessage id="surveyCollector.typeEmail.template.content"/></Label>
            {editorContentOpen ?
              <React.Fragment>
                <HtmlEditor
                  data={template.content}
                    onChange={(event) => this.onContentChange('content', event)}
                    onBlur={() => this.onClick('editorContentOpen')}
                />
                <small>Liczba znaków: {template.content ? striptags(template.content.replace(/&nbsp;/g, "")).length : 0}</small>
              </React.Fragment> :
              <div onClick={() => this.onClick('editorContentOpen')}>{template.content ? reactHTMLParser(template.content) : <i>Brak treści, kliknij aby edytować</i>}</div>
            }
          </FormGroup>
          <FormGroup>
            <Label for="subjectReminder"><FormattedMessage id="surveyCollector.typeEmail.template.subjectReminder"/></Label>
            <Input
              id="subjectReminder"
              name="subjectReminder"
              onChange={this.onChange}
              value={template.subjectReminder || ""}
            />
          </FormGroup>
          <FormGroup>
            <Label for="contentReminder"><FormattedMessage id="surveyCollector.typeEmail.template.contentReminder"/></Label>
            {editorContentReminderOpen ?
              <React.Fragment>
                <HtmlEditor
                  data={template.contentReminder}
                  onChange={(event) => this.onContentChange('contentReminder', event)}
                  onBlur={() => this.onClick('editorContentReminderOpen')}
                />
                <small>Liczba znaków: {template.contentReminder ? striptags(template.contentReminder.replace(/&nbsp;/g, "")).length : 0}</small>
              </React.Fragment> :
              <div onClick={() => this.onClick('editorContentReminderOpen')}>{template.contentReminder ? reactHTMLParser(template.contentReminder) : <i>Brak treści, kliknij aby edytować</i>}</div>
            }
          </FormGroup>
          {this.props.plan.survey.collectorEmailMailFooterEdit && <FormGroup className="mt-3">
            <Label for="mailFooter" className="mr-2">Dołączaj stopkę do wiadomości</Label>
            <Switch onChange={mailFooter => this.setState({
              mailFooter
            })} checked={this.state.mailFooter}/>
          </FormGroup>}

          <div className="direction-group">
            <div className="direction-right">
              <LaddaButton
                loading={saving}
                className="btn btn-primary btn-ladda mt-2"
                data-style={ZOOM_OUT}
              >
                <FormattedMessage id="_.button.save"/>
              </LaddaButton>
            </div>
          </div>
        </Form>
      </React.Fragment>
    )
  }
}

TabTemplate.propTypes = {
  collector: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps)(TabTemplate);