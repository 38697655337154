import React, {Component} from 'react';
import PropTypes from "prop-types";
import SettingBool from "../../../SettingBool";
import SettingNumber from "../../../SettingNumber";
import PresentationType from "../../../PresentationType";
import SettingText from "../../../SettingText";

class NumericSettings extends Component {

  render() {
    const {question} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'required'} value={question.required || false} icon={<i className="fa-solid fa-message-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} activeFromValue={0} name={'valueMin'} float={true} value={question.valueMin || 0} icon={<i className="fak fa-sliders-simple-left" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'valueMax'} float={true} value={question.valueMax || 0} icon={<i className="fak fa-sliders-simple-right" />} handleUpdate={this.props.handleUpdate}/>
        {['slider'].includes(question.presentationType) && <SettingBool question={question} name={'showSliderInputs'} value={question.showSliderInputs} icon={<i className="fa-solid fa-input-numeric" />} handleUpdate={this.props.handleUpdate}/>}
        <SettingNumber question={question} name={'decimals'} value={question.decimals || 0} icon={<i className="fak fa-zero-comma-zero" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'step'} value={question.step || 0} icon={<i className="fa-solid fa-distribute-spacing-horizontal" />} handleUpdate={this.props.handleUpdate}/>
        {!question.defaultValueMiddle && <SettingNumber question={question} name={'defaultValue'} minValue={0} value={question.defaultValue || 0} icon={<i className="fa-solid fa-scale-balanced"/>} handleUpdate={values => {
          values.defaultValueMiddle = false;

          this.props.handleUpdate(values);
        }}/>}
        <SettingBool question={question} name={'defaultValueMiddle'} value={question.defaultValueMiddle || false} icon={<i className="fa-solid fa-align-center" />} handleUpdate={values => {
          if(values.defaultValueMiddle){
            values.defaultValue = 0;
          }
          this.props.handleUpdate(values);
        }}/>
        <SettingText question={question} name={'valueText'} value={question.valueText || ''} icon={<i className="fa-solid fa-strikethrough" />} handleUpdate={this.props.handleUpdate}/>
        {['slider'].includes(question.presentationType) && <SettingBool question={question} name={'hideSelectedValue'} value={question.hideSelectedValue} icon={<i className="fas fa-strikethrough" />} handleUpdate={this.props.handleUpdate}/>}
        {['slider'].includes(question.presentationType) && <SettingBool question={question} name={'hideSelectingValue'} value={question.hideSelectingValue} icon={<i className="fa-solid fa-circle-info" />} handleUpdate={this.props.handleUpdate}/>}
        <PresentationType question={question} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'range'} value={question.range} icon={<i className="fa-solid fa-2" />} handleUpdate={this.props.handleUpdate}/>
      </React.Fragment>
    )
  }
}

NumericSettings.propTypes = {
  question: PropTypes.shape({
    valueMin: PropTypes.number.isRequired,
    valueMax: PropTypes.number.isRequired,
    decimals: PropTypes.number.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default NumericSettings;
