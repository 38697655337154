import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {UncontrolledTooltip} from "reactstrap";

class ButtonEdit extends Component {
  render() {
    return (
      <React.Fragment>
        <i id={"survey-filter-button-edit-" + this.props.filter.id + '-' + this.props.filter.type} className="fas fa-edit mr-2 pointer" onClick={this.props.onClick}/>
        <UncontrolledTooltip target={"survey-filter-button-edit-" + this.props.filter.id + '-' + this.props.filter.type} placement="bottom" delay={0}>
          <FormattedMessage id="_.button.edit"/>
        </UncontrolledTooltip>
      </React.Fragment>
    )
  }
}

ButtonEdit.propTypes = {
  onClick: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired
};

export default ButtonEdit;