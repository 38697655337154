import api from "api";
import * as questionsActions from "../questions/actions";


export const SET = 'Survey/data/baskets/SET';
export const ADDED = 'Survey/data/baskets/ADDED';
export const DELETED = 'Survey/data/baskets/DELETED';
export const UPDATED = 'Survey/data/baskets/UPDATED';

export function setBaskets(baskets) {
  return {
    type: SET,
    baskets
  }
}
export function basketAdded(basket) {
  return {
    type: ADDED,
    basket
  }
}
export function basketDeleted(basket) {
  return {
    type: DELETED,
    basket
  }
}
export function basketUpdated(basket) {
  return {
    type: UPDATED,
    basket
  }
}





export function addBasket(data) {
  return dispatch => {
    return api.question.post.basket(data).then(basket => {
      dispatch(basketAdded(basket));
      dispatch(questionsActions.basketAdded(basket));

      return basket;
    });
  }
}

export function deleteBasket(data) {
  return dispatch => {
    return api.basket.delete.basket(data).then(res => {
      dispatch(basketDeleted({
        id: data.id,
        question: res.basket.question
      }))
    })
  }
}
export function deleteBaskets(questionId, data) {
  return dispatch => {
    return api.basket.delete.list(data).then(() => {
      _.map(data.ids, id => {
        dispatch(basketDeleted({
          id,
          question: {
            id: questionId
          }
        }))
      })
    })
  }
}

export function updateBasket(data) {
  return dispatch => {
    return api.basket.patch(data).then(basket => {
      dispatch(basketUpdated(basket))
    })
  }
}