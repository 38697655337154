import React from 'react';
import PropTypes from "prop-types";
import Button from "../../Button";
import {Progress, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";

DeleteSelectedModal.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  deleteFunc: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default function DeleteSelectedModal(props) {
  const [deleted, setDeleted] = React.useState(0);
  const [modalOpen, setModalOpen] = React.useState();
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [deleting, setDeleting] = React.useState(false);

  const deleteSelected = async () => {
    setDeleting(true);

    const del = key => {
      if(!props.selected[key]){
        setDeleted(0);
        setDeleting(false);
        setModalOpen(false);
        props.onDeleted();
        return;
      }

      props.deleteFunc(props.selected[key])
      .then(() => {
        setDeleted(key+1);
        del(key+1);
      })
      .catch(() => {
        setDeleted(key+1);
        del(key+1);
      });
    };

    del(0);
  }

  return <React.Fragment>
    <Button color="danger" onClick={toggleModalOpen}>Usuń zaznaczone</Button>

    <Modal isOpen={modalOpen} toggle={toggleModalOpen} size="lg">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Usuń zaznaczone wiersze</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        {(!deleting && deleted === 0) && <span>Czy na pewno chcesz usunąć zaznaczone wiersze?</span>}
        {(!deleting && deleted > 0) && <span>Liczba usuniętych wierszy: {deleted}</span>}
        {deleting && <div className="d-flex flex-column">
          <Progress
            color="danger"
            value={deleted+1}
            max={props.selected.length}
            children={<span>{deleted+1}/{props.selected.length}</span>}
          />
        </div>}

      </ModalBody>
      <ModalFooter>
        {(!deleting && deleted > 0)
          ? <FormattedMessage id="_.button.close">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
          }</FormattedMessage>
          : <React.Fragment>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
            }</FormattedMessage>
            <FormattedMessage id="_.button.delete">{
              (message) => <Button
                color="danger"
                loading={deleting}
                onClick={deleteSelected}
              >
                {message}
              </Button>
            }</FormattedMessage>
          </React.Fragment>
        }
      </ModalFooter>
    </Modal>
  </React.Fragment>;
};