import React, {createRef, useRef} from 'react';
import PropTypes from 'prop-types';
import SelectReact from "react-select";
import useAfterUpdateEffect from "../utils/useAfterUpdateEffect";
import useDidMountEffect from "../utils/useDidMountEffect";
import {isNull} from "lodash";

const Select = props => {
  const selectRef = createRef();
  const [value, setValue] = React.useState(props.value);

  useDidMountEffect(() => {
    if(props.autoFocus) {
      selectRef.current.setState({menuIsOpen: true})
      selectRef.current.select.focus()
    }
  })

  useAfterUpdateEffect(() => {
    props.onChange(value)
  }, [value])

  const getStyles = () => {
    if(props.selectWidth){
      return {
        menu: menu => ({
          ...menu,
          width: props.selectWidth,
        })
      }
    }

    return undefined;
  }

  return (
    <SelectReact
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      ref={selectRef}
      menuPortalTarget={props.documentAsTarget ? document.body : undefined}
      styles={getStyles()}
      classNamePrefix="react-select"
      className="w-100"
      placeholder={props.placeholder}
      options={props.options}
      onChange={option => {
        if(isNull(option)){
          setValue(props.isMulti ? [] : null)
        }else{
          setValue(props.isMulti ? option.map(o => o.value) : option.value)
        }
      }}
      onBlur={() => props.onChange(value)}
      value={
        props.isMulti
        ? props.options.filter(o => value.includes(o.value))
        : props.options.find(o => o.value === value)
      }
    />
  );
};

Select.defaultProps = {
  isMulti: false,
  isClearable: false,
  autoFocus: false,
  selectWidth: false,
  documentAsTarget: false,
  placeholder: 'Wybierz...',
};

Select.propTypes = {
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  documentAsTarget: PropTypes.bool,
  selectWidth: PropTypes.string,
  autoFocus: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Select;
