import { reducer as dataReducer } from './data/reducer';
import {REQUEST, SUCCESS, ERROR} from "./data/widgets/actions";
import _ from 'lodash';

const loadingReducer = (state, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case SUCCESS:
    case ERROR:
      return false;
    default:
      return state;
  }
};


const initialState = { loading: false };
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      const rest = _.omit(state, Object.keys(initialState));
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: dataReducer(rest.data, action)
      };
  }
};