import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, Row, Col, ModalFooter, Button} from "reactstrap";
import {NavLink} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import appRoutes from "appRoutes";
import {connect} from "react-redux";
import Settings from "scenes/Survey/components/Settings";
import {isMobile, isTablet} from "react-device-detect";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {map, isEmpty} from "lodash";
import {fetchSurvey, reReportSurvey} from "scenes/Survey/Structure/data/survey/actions";
import UserAvatar from "../../../UserAvatar";
import Tooltip from "../../../Tooltip";
import Mousetrap from "mousetrap";
import SurveyShareButton from "../../../../scenes/SurveyShare/components/SurveyShareButton";
import SurveysModalShare from "../../../../scenes/Surveys/components/Modals/SurveysModalShare";
import {fetchGroups} from "../../../../scenes/Group/actions";
import api from "../../../../api";
import {fetchSurveyShares} from "../../../../scenes/SurveyShare/actions";
import checkIsGroupRole from "../../../../utils/checkIsGroupRole";
import withParams from "../../../../utils/withParams";

class SurveyShares extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      surveyShares: [],
    };

    this.toggleModalOpen = this.toggleModalOpen.bind(this);
    this.fetchSurveyShares = this.fetchSurveyShares.bind(this);
  }

  componentDidMount() {
    this.fetchSurveyShares();
    Mousetrap.bind("shift+u", () => { this.toggleModalOpen(); });
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+u");
  }

  fetchSurveyShares(){
    api.survey.get.surveyShares(this.props.survey.id)
    .then(surveyShares => {
      this.setState({
        surveyShares
      })
    })
  }

  toggleModalOpen(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    });

    if(this.state.modalOpen){
      this.fetchSurveyShares();
    }
  }

  render() {
    const {modalOpen, surveyShares} = this.state;

    return <div className="ml-2">
      <SurveyShareButton
        surveyId={this.props.survey.id}
        surveyShares={surveyShares}
        toggleModal={this.toggleModalOpen}
      />
      {modalOpen && <SurveysModalShare
        survey={this.props.survey}
        isOpen={true}
        toggleModal={this.toggleModalOpen}
        onChange={() => {}}
      />}
    </div>
  }
}

class NavigationSurvey extends Component {

  constructor(props) {
    super(props);

    this.socket = false;

    this.state = {
      activeUsers: {},
      showOthers: false,
      settingsModal: false,
      settingsSaveButtonVisible: true,
      settingsButtonDisabled: true,
      settingsSaving: false,
      reportModal: false,
      surveyBaseWarningModal: false,
      saving: false,
      onRemoveFromBase: null
    };

    this.settings = React.createRef();
    this.sockedSend = this.sockedSend.bind(this);
    this.toggle = this.toggle.bind(this);
    this.removeFromBase = this.removeFromBase.bind(this);
    this.onStructureNav = this.onStructureNav.bind(this);
  }

  translatePathname(pathname){

    if(pathname.indexOf(appRoutes.survey.summary(this.props.params.survey)) > -1){
      return 'Podsumowanie';
    }
    if(pathname.indexOf(appRoutes.survey.structure(this.props.params.survey)) > -1){
      return 'Struktura';
    }
    if(pathname.indexOf(appRoutes.survey.preview.main(this.props.params.survey)) > -1){
      return 'Podgląd';
    }
    if(pathname.indexOf(appRoutes.survey.collectors.list(this.props.params.survey)) > -1){
      return 'Kolektory';
    }
    if(pathname.indexOf(appRoutes.survey.analysis.questionSummaries(this.props.params.survey, '')) > -1){
      return 'Analiza';
    }
    if(pathname.indexOf(appRoutes.survey.collectorsPanelEmailStats(this.props.params.survey)) > -1){
      return 'Statystyki wysyłek';
    }
    if(pathname.indexOf(appRoutes.survey.reports(this.props.params.survey)) > -1){
      return 'Błędy';
    }
    if(pathname.indexOf(appRoutes.survey.surveyResponseFakers(this.props.params.survey)) > -1){
      return 'Dane testowe';
    }
    if(pathname.indexOf(appRoutes.survey.surveyValidation(this.props.params.survey)) > -1){
      return 'Test';
    }

    return 'Brak danych';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.location.pathname !== prevProps.location.pathname){
      this.updateSocket();
    }
  }

  updateSocket(expectsResponse = false){
    this.sockedSend(this.translatePathname(this.props.location.pathname), expectsResponse)
  }

  componentDidMount() {
    this.props.fetchGroups();
    Mousetrap.bind("shift+s", () => { this.props.history.push(appRoutes.survey.structure(this.props.params.survey)) });
    Mousetrap.bind("shift+d", () => { this.props.history.push(appRoutes.survey.preview.main(this.props.params.survey)) });
    Mousetrap.bind("shift+z", () => { this.setState({settingsModal: true}) });

    if (location.pathname !== appRoutes.survey.structure(this.props.params.survey)) {
      this.props.fetchSurvey({id: this.props.params.survey})
    }

    if(this.props.plan.groups){
      // this.socket = new WebSocket((location.protocol.indexOf('https') > -1 ? 'wss' : 'ws') + "://"+location.hostname+":3001");
      //
      // this.socket.addEventListener("message", (e) => {
      //   const data = JSON.parse(e.data);
      //
      //   if(data.sId === this.props.params.survey){
      //     let activeUsers = this.state.activeUsers;
      //
      //     if(data.tab === false){
      //       delete activeUsers[data.user.id];
      //     }else{
      //       activeUsers[data.user.id] = data;
      //     }
      //     this.setState({
      //       activeUsers,
      //     })
      //
      //     if(data.expectsResponse){
      //       this.updateSocket();
      //     }
      //   }
      // });
      //
      //
      // setTimeout(() => {
      //   this.sockedSend(this.translatePathname(this.props.location.pathname), true)
      // }, 1000)
    }
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+s");
    Mousetrap.unbind("shift+d");
    Mousetrap.unbind("shift+z");

    this.sockedSend(false);
    if(this.socket){
      this.socket.close();
    }
  }

  sockedSend(tab, expectsResponse = false){
    if(this.socket && this.socket.readyState === 1){
      this.socket.send(JSON.stringify({
        expectsResponse,
        sId: this.props.params.survey,
        tab,
        user: _.pick(this.props.user, ['id', 'avatar', 'firstname', 'lastname', 'email'])
      }))
    }  }

  toggle(property) {
    this.setState({[property]: !this.state[property]});
  }

  static toggleNavbar() {
    let elements = document.getElementsByClassName('mainNav');

    for (let i = 0; i < elements.length; i++) {
      let element = elements[i].classList;
      element.contains('show') ? element.remove('show') : element.add('show');
    }
  }

  onStructureNav(e) {
    NavigationSurvey.toggleNavbar();

    if (this.props.hasActiveSurveyBase && !this.props.swPanelDefaultAccepted) {
      e.preventDefault();
      this.toggle('surveyBaseWarningModal');
      this.setState({onRemoveFromBase: () => this.props.history.push(appRoutes.survey.structure(this.props.params.survey))})
    }
  }

  removeFromBase() {
    this.setState({saving: true});

    this.props.reReportSurvey(this.props.params.survey).then(() => {
      this.setState({saving: true, surveyBaseWarningModal: false});
      this.state.onRemoveFromBase();
    });
  }

  render() {
    const {settingsModal, showOthers, settingsSaveButtonVisible, settingsButtonDisabled, settingsSaving, activeUsers} = this.state;
    const {plan, survey, user, userGroup} = this.props;


    const reportModal = <Modal isOpen={this.state.reportModal} toggle={() => this.toggle('reportModal')}>
      <ModalHeader toggle={() => this.toggle('reportModal')} tag="h2"><FormattedMessage id="footer.reportModal.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="footer.reportModal.modalBody" values={{br: <br />, mail: <strong><a href="mailto:umniedziala@ankieteo.com">umniedziala@ankieteo.com</a></strong>}}/></p>
      </ModalBody>
    </Modal>;

    const surveyBaseModal = <Modal isOpen={this.state.surveyBaseWarningModal} toggle={() => this.toggle('surveyBaseWarningModal')}>
      <ModalHeader toggle={() => this.toggle('surveyBaseWarningModal')} tag="h2"><FormattedMessage id="navigation.survey.surveyBaseWarningModal.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="navigation.survey.surveyBaseWarningModal.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="navigation.survey.surveyBaseWarningModal.button">{
              (message) => <LaddaButton
                className="btn btn-danger btn-ladda mb-0 ml-3"
                loading={this.state.saving}
                data-style={ZOOM_OUT}
                onClick={this.removeFromBase}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>

            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggle('surveyBaseWarningModal')}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    const modal =
      <Modal size="xl" isOpen={settingsModal} toggle={() => this.toggle('settingsModal')}>
        <ModalHeader toggle={() => this.toggle('settingsModal')} tag="h2"><FormattedMessage id="survey.settings.modal.header"/></ModalHeader>
        <hr className="my-0" />
        <ModalBody className="px-0 pb-0">
          <Settings
            ref={this.settings}
            handleOnSavingUpdate={settingsSaving => this.setState({settingsSaving})}
            handleOnSaveButtonVisibleUpdate={settingsSaveButtonVisible => this.setState({settingsSaveButtonVisible})}
            handleButtonDisabledChanged={settingsButtonDisabled => this.setState({settingsButtonDisabled})} />
        </ModalBody>
        <ModalFooter>
          <div className="d-flex w-100">
            <h4 className="flex-grow-1 mb-0 mt-2">Data utworzenia: {survey.createdAt}</h4>
            {settingsSaveButtonVisible && <LaddaButton
              className="btn btn-primary btn-ladda"
              disabled={settingsButtonDisabled}
              loading={settingsSaving}
              data-style={ZOOM_OUT}
              onClick={() => this.settings.current.updateSurvey()}
            >Zapisz zmiany</LaddaButton>}
          </div>
        </ModalFooter>=
      </Modal>
    ;

    const navItems = <React.Fragment>
      {checkIsGroupRole('accessReport', user, userGroup) && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.survey.summary(this.props.params.survey)} onClick={NavigationSurvey.toggleNavbar}><FormattedMessage id="navigation.survey.summary" /><span className="sr-only"><FormattedMessage id="navigation.survey.summary" /></span></NavLink></NavItem>}
      {checkIsGroupRole('accessEdit', user, userGroup) && this.props.surveySettingsLoaded && <NavItem className={"py-2" + (this.props.hasActiveSurveyBase && !this.props.swPanelDefaultAccepted ? " not-active" : "")}><NavLink className="nav-link" to={appRoutes.survey.structure(this.props.params.survey)} onClick={this.onStructureNav}><FormattedMessage id="navigation.survey.structure" /><span className="sr-only"><FormattedMessage id="navigation.survey.structure" /></span></NavLink></NavItem>}
      <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.survey.preview.main(this.props.params.survey)} onClick={NavigationSurvey.toggleNavbar}><FormattedMessage id="navigation.survey.preview" /><span className="sr-only"><FormattedMessage id="navigation.survey.preview" /></span></NavLink></NavItem>
      {checkIsGroupRole('accessCollectors', user, userGroup) && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.survey.collectors.list(this.props.params.survey)} onClick={NavigationSurvey.toggleNavbar}><FormattedMessage id="navigation.survey.collectors" /><span className="sr-only"><FormattedMessage id="navigation.survey.collectors" /></span></NavLink></NavItem>}
      {checkIsGroupRole('accessReport', user, userGroup) && this.props.plan.survey.collectorPanelAccess && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.survey.collectorsPanelEmailStats(this.props.params.survey)} onClick={NavigationSurvey.toggleNavbar}><FormattedMessage id="navigation.survey.collectorsPanelEmailStats" /><span className="sr-only"><FormattedMessage id="navigation.survey.collectorsPanelEmailStats" /></span></NavLink></NavItem>}
      {checkIsGroupRole('accessPreviewAnalysisData', user, userGroup) && <NavItem className="py-2"><NavLink className="nav-link" target={isMobile ? '_blank' : ''} to={appRoutes.survey.analysis.questionSummaries(this.props.params.survey, '')} onClick={NavigationSurvey.toggleNavbar}><FormattedMessage id="navigation.survey.analysis" /><span className="sr-only"><FormattedMessage id="navigation.survey.analysis" /></span></NavLink></NavItem>}
      {checkIsGroupRole('accessReport', user, userGroup) && plan.survey.reportEnable && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.survey.reports(this.props.params.survey)} onClick={NavigationSurvey.toggleNavbar}><FormattedMessage id="navigation.survey.reports" /><span className="sr-only"><FormattedMessage id="navigation.survey.reports" /></span></NavLink></NavItem>}
      {checkIsGroupRole('accessReport', user, userGroup) && plan.survey.surveyResponseFakerEnable && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.survey.surveyResponseFakers(this.props.params.survey)} onClick={NavigationSurvey.toggleNavbar}><FormattedMessage id="navigation.survey.surveyResponseFakers" /><span className="sr-only"><FormattedMessage id="navigation.survey.surveyResponseFakers" /></span></NavLink></NavItem>}
      {checkIsGroupRole('accessReport', user, userGroup) && plan.survey.surveyValidation && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.survey.surveyValidation(this.props.params.survey)} onClick={NavigationSurvey.toggleNavbar}><FormattedMessage id={plan.survey.scoring ? "Scoring" : "navigation.survey.surveyValidation"} /><span className="sr-only"><FormattedMessage id={plan.name === "SW Research" ? "Scoring" : "navigation.survey.surveyValidation"} /></span></NavLink></NavItem>}

      {checkIsGroupRole('accessEdit', user, userGroup) && this.props.surveySettingsLoaded &&
      <React.Fragment>
        <NavItem className={"py-2" + (this.props.hasActiveSurveyBase && !this.props.swPanelDefaultAccepted ? " not-active" : "")}>
          <a id="settings" className="ml-3 nav-link" onClick={() => {
            this.toggle(this.props.hasActiveSurveyBase && !this.props.swPanelDefaultAccepted ? 'surveyBaseWarningModal' : 'settingsModal');
            this.setState({onRemoveFromBase: () => this.toggle('settingsModal')})
          }}><FormattedMessage id="navigation.survey.settings"/></a>
          {modal}
        </NavItem>
      </React.Fragment>
      }

      {(!isEmpty(this.props.survey) && !this.props.noGroup) && <SurveyShares
        survey={this.props.survey}
      />}

      <NavItem className="py-2 d-lg-none"><NavLink className=" ml-3 nav-link" to={appRoutes.surveys.list} onClick={NavigationSurvey.toggleNavbar}><FormattedMessage id="navigation.default.surveyList" /><span className="sr-only"><FormattedMessage id="navigation.default.surveyList" /></span></NavLink></NavItem>
      <NavItem className="py-2 d-lg-none" onClick={() => this.toggle('showOthers')}><a className="nav-link"><FormattedMessage id="navigation.default.others" /></a></NavItem>
      {surveyBaseModal}
    </React.Fragment>;

    const navOtherItems = showOthers && <React.Fragment>
      <NavItem className="py-2 d-lg-none"><a className="nav-link" href={this.props.config.routes.ankieteo.contact}><FormattedMessage id="footer.contact" /></a></NavItem>
      <NavItem className="py-2 d-lg-none"><a className="nav-link" href={this.props.config.routes.ankieteo.help}><FormattedMessage id="footer.help" /></a></NavItem>
      {/*<NavItem className="py-2 d-lg-none"><a className="nav-link" onClick={() => this.toggle('reportModal')}><FormattedMessage id="footer.reportBug" /></a></NavItem>*/}
      <NavItem className="py-2 d-lg-none"><a className="nav-link" href={this.props.config.routes.ankieteo.regulations}><FormattedMessage id="footer.regulations" /></a></NavItem>
      <NavItem className="py-2 d-lg-none"><a className="nav-link" href={this.props.config.routes.ankieteo.informationClause}><FormattedMessage id="footer.informationClause" /></a></NavItem>
      {reportModal}
    </React.Fragment>;

    const toRender = isMobile || isTablet
      ? <Row>
        <Col xs={showOthers ? 6 : 12}>
          {navItems}
        </Col>
        <Col xs={showOthers ? 6 : 12}>
          {navOtherItems}
        </Col>
      </Row>
      : <React.Fragment>{navItems}{navOtherItems}</React.Fragment>;

    return (
      <React.Fragment>
        <Nav className="navbar-nav ml-4 mr-4 ml-lg-5 align-items-lg-baseline">
          {toRender}
        </Nav>
        <div className="d-flex">
          {map(activeUsers, data => {
            let userLabel = [];
            if(!data.user.firstname && !data.user.lastname){
              userLabel.push(data.user.email)
            }else{
              if(data.user.firstname){
                userLabel.push(data.user.firstname)
              }
              if(data.user.lastname){
                userLabel.push(data.user.lastname)
              }
            }

            return <div>
              <div id={'active-user-' + data.user.id + '-tooltip'}><UserAvatar user={data.user} /></div>
              <Tooltip id={'active-user-' + data.user.id + '-tooltip'} msg={userLabel.join(' ') + " jest w zakładce " + data.tab} delay={0} />
            </div>
          })}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    noGroup: !Object.keys(state.groups.data.groups).length > 0 && !state.user.userGroup,
    userGroup: state.user.userGroups,
    user: state.user,
    plan: state.user.userPlan.plan,
    surveySettingsLoaded: Object.keys(state.survey.structure.data.survey).length > 0,
    config: state.config.data,
    hasActiveSurveyBase: state.survey.structure.data.survey.hasActiveSurveyBase,
    swPanelDefaultAccepted: 'userPlan' in state.user ? state.user.userPlan.plan.survey.swPanelDefaultAccepted : false,
    survey: state.survey.structure.data.survey,
  }
}

export default withParams(connect(mapStateToProps, {fetchSurvey, fetchGroups, reReportSurvey})(NavigationSurvey));
