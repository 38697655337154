import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import {Alert} from "reactstrap";

export const statusNumberToClass = (status) => {
  switch(status) {
    case null: return 'secondary'
    case 1: return 'success'
    case 2: return 'warning'
    case 3: return 'danger'
    default: return ''
  }
}

export const statusDetails = (status) => {
  let icon = 'fa-spin fa-spinner';
  let statusName = 'Weryfikacja...';

  switch(statusNumberToClass(status)) {
    case 'success': {
      icon = 'fa-check-circle';
      statusName = 'Poprawny';
      break;
    }
    case 'warning': {
      icon = 'fa-exclamation-triangle';
      statusName = 'Błędy';
      break;
    }
    case 'danger': {
      icon = 'fa-times';
      statusName = 'Krytyczne błędy';
      break;
    }
  }

  return {
    icon,
    statusName,
  }
}

const StageBox = ({stageData, onSelect, className, title}) => {

  const getStatusBox = (stageStatus) => {
    const stageStatusDetails = statusDetails(stageStatus);

    return <div className="d-flex flex-column text-center p-2">
      <i className={`fas ${stageStatusDetails.icon} fa-2x mb-2`} />

      <span>{stageStatusDetails.statusName}</span>
    </div>
  }

  return <Alert color={statusNumberToClass(stageData ? stageData.status : null)}
                className={classnames({
                  [className]: !!className,
                  '': true,
                  'pointer': !!onSelect,
                })}
                style={{width: '300px'}}
                onClick={() => onSelect && onSelect()}>
    <h1 className="text-dark border-bottom text-center p-2 border-bottom border-light">{title}</h1>

    {getStatusBox(stageData ? stageData.status : null)}
  </Alert>;
};

StageBox.defaultProps = {
  className: '',
};

StageBox.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  stageData: PropTypes.shape({
    status: PropTypes.number.isRequired,
  }),
};

export default StageBox;
