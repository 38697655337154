import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../components/Button";
import api from "../../../../api";

const UsersDeleteModal = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [saving, setSaving] = React.useState(false);

  const remove = () => {
    setSaving(true);

    api.admin.user.post.deleteUser(props.checked)
      .then(() => {
        setModalOpen(false);
      })
      .finally(() => {
        setSaving(false)
        props.onDeleted()
      })
  }

  return (
    <React.Fragment>
      <Button color="danger" className="ml-3" onClick={toggleModalOpen}>Usuń respondentów</Button>
      {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
        <ModalHeader toggle={toggleModalOpen}>Usuń użytkowników</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          Usunięcie użytkowników spowoduje usunięcie danych osobowych z ich kont. Cała historia konta zostanie zachowana.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
          <Button
            loading={saving}
            color="danger"
            onClick={remove}
          >Usuń respondentów</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

UsersDeleteModal.propTypes = {

};

export default UsersDeleteModal;
