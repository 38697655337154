import update from 'immutability-helper';
import {ADDED, DELETED, UPDATED} from "./actionTypes";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case ADDED:
      return {...state, [action.respondentBase.id]: action.respondentBase};

    case UPDATED:
      return update(state, {
        [action.respondentBase.id]: {
          $set: action.respondentBase
        }
      });

    case DELETED:
      return update(state, {$unset: [action.respondentBase.id]});

    default:
      return state;
  }
};