import React, {Component} from 'react';
import api from 'api';
import {Col, Form, FormGroup, Input, Label} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";

class FormSmsApiToken extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      correct: !!props.token ? true : null,
      data: {
        smsApiToken: props.token
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const {data} = this.state;

    this.setState({
      saving: true,
    });

    api.smsApiTokenValidation(data.smsApiToken)
    .then(data => {
      if(data.valid){
        const formData = new FormData();
        formData.append('smsApiToken', this.state.data.smsApiToken);

        api.user.post.userUpdate(formData)
        .then(data => {
          this.setState({
            correct: true,
            saving: false,
          });
        })
        .catch(res => {
          this.setState({
            saving: false,
            correct: false,
          });
        })
      }else{
        this.setState({
          saving: false,
          correct: false,
        });
      }
    })
    .catch(() => {
      this.setState({
        correct: false,
        saving: false,
      });
    });
  }

  render() {
    const {saving, data, correct} = this.state;

    return (
      <Form onSubmit={this.onSubmit} className="h-100">
        <FormGroup>
          <Input
            type="text"
            placeholder="Podaj token SMSAPI"
            value={data.smsApiToken}
            onChange={e => this.setState({
              data: {
                ...data,
                smsApiToken: e.target.value
              }
            })}
          />
        </FormGroup>
        <FormGroup className="d-flex justify-content-between align-items-center">
          {saving && <i className="fas fa-spinner fa-spin" />}
          {!saving && correct === false && <i className="fas fa-times-circle text-danger" />}
          {!saving && correct === true && <i className="fas fa-check-circle text-success" />}
          <LaddaButton
            className="btn btn-primary btn-ladda d-block ml-auto"
            loading={saving}
            data-style={ZOOM_OUT}
          >Zapisz</LaddaButton>
        </FormGroup>
      </Form>
    );
  }
}

export default FormSmsApiToken;