import React, {Component} from 'react';
import {Form, FormGroup, Label, Col, Input, FormFeedback} from "reactstrap";
import PropTypes from 'prop-types';
import Button from "components/Button";
import api from "api";
import appRoutes from "appRoutes";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";
import {FormattedMessage} from "react-intl";
import Select from "react-select";
import PlanSelect from "../../../../../components/PlanSelect";
import HtmlEditor from "../../../../../componentsReusable/HtmlEditor";
import withParams from "../../../../../utils/withParams";

class DashboardNotifyForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      htmlCkeditor: false,
      data: props.data,
      saving: false,
      loading: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentDidMount() {
    if(this.props.params.id){
      this.setState({
        loading: true
      })
      api.dashboardNotifies.get(this.props.params.id)
      .then(data => {
        this.setState({
          loading: false,
          data: data.data
        })
      })
    }
  }

  onChange(key, value){
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      }
    })
  }

  handleOnSubmit(e){
    e.preventDefault();

    this.setState({
      saving: true,
      errors: {},
    });

    let apiFn = api.dashboardNotifies.post;
    if(this.props.params.id){
      apiFn = api.dashboardNotifies.patch;
    }

    apiFn(this.state.data)
    .then(data => {

      this.setState({
        saving: false,
        errors: {},
      });

      this.props.history.push(appRoutes.admin.cms.dashboardNotifies.list);
    })
    .catch(res => {

      this.setState({
        saving: false,
        errors: res.response.data
      });
    })
  }

  render() {
    const {data, saving, errors, loading} = this.state;

    if(loading){
      return null
    }

    return (
      <Form onSubmit={this.handleOnSubmit}>
        <FormGroup row className="mt-2">
          <Label md={2}>Layout</Label>
          <Col md={10}>
            <Select
              placeholder={'Wybierz'}
              value={data.layout && {
                label: data.layout,
                value: data.layout,
              }
              }
              onChange={e => {
                this.onChange('layout', e.value)
              }}
              options={[
                'content',
                'functions',
                'offer',
                'important',
                'technical-works',
              ].map(layout => ({
                  label: layout,
                  value: layout,
                }
              ))}
            />
            {_.has(errors, 'children.title.errors') && <FormFeedback>{errors.children.title.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label md={2}>Dla planów</Label>
          <Col md={10}>
            <PlanSelect
              value={data.plans}
              onChange={plans => this.onChange('plans', plans)}
            />
            {_.has(errors, 'children.title.errors') && <FormFeedback>{errors.children.title.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label md={2}>Tytuł</Label>
          <Col md={10}>
            <Input invalid={!!_.has(errors, 'children.title.errors')} onChange={e => this.onChange('title', e.target.value)} value={data.title} readOnly={(data.id && data.level === 0)} />
            {_.has(errors, 'children.title.errors') && <FormFeedback>{errors.children.title.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label md={2}>Data od</Label>
          <Col md={10}>
            <DatePicker
              dateFormat="Y-MM-DD HH:mm"
              showTimeSelect
              locale={localStorage.language}
              dropdownMode="select"
              placeholderText="Data od"
              selected={data.dateFrom ? moment(data.dateFrom) : ''}
              onChange={date => this.onChange('dateFrom', date ? date.format('YYYY-MM-DD HH:mm') : null)}
            />
            {_.has(errors, 'children.title.errors') && <FormFeedback>{errors.children.title.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label md={2}>Data do</Label>
          <Col md={10}>
            <DatePicker
              dateFormat="Y-MM-DD HH:mm"
              showTimeSelect
              locale={localStorage.language}
              dropdownMode="select"
              placeholderText="Data do"
              selected={data.dateTo ? moment(data.dateTo) : ''}
              onChange={date => this.onChange('dateTo', date ? date.format('YYYY-MM-DD HH:mm') : null)}
            />
            {_.has(errors, 'children.title.errors') && <FormFeedback>{errors.children.title.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label md={2}>Treść</Label>
          <Col md={10}>
            {
              this.state.htmlCkeditor
              ? <HtmlEditor
                  onChange={e => this.onChange('content', e)}
                  data={data.content}
                />
              : <div className="d-flex flex-column">
                  <Input type="textarea" rows={15} onChange={e => this.onChange('content', e.target.value)} value={data.content} />
                <Button size="sm" className="mb-3" onClick={() => this.setState({
                  htmlCkeditor: true,
                })}>Pokaż CKEditor</Button>
                </div>

            }
            {_.has(errors, 'children.content.errors') && <FormFeedback className="d-block">{errors.children.content.errors.join('; ')}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup className="mt-2 d-flex justify-content-end">
          <Button
            loading={saving}
          >Zapisz</Button>
        </FormGroup>
      </Form>
    );
  }
}

DashboardNotifyForm.defaultProps = {
  data: {
    layout: '',
    title: '',
    content: '',
    dateFrom: null,
    dateTo: null,
    plans: [],
  },
};

export default withParams(DashboardNotifyForm);
