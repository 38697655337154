import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, InputGroup, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import SurveysList from './SurveysList';
import Select from "react-select";

const SwPanelStatusSelect = (props) => {
  const [value, setValue] = React.useState(3);

  React.useEffect(() => {
    props.onChange(value);
  }, [value]);

  const options = [
    {
      label: 'Odrzucone',
      value: 2,
    },
    {
      label: 'Zaakceptowane',
      value: 3,
    },
  ]

  return <div style={{width: '200px'}}>
    <Select
      isSearchable={false}
      className="w-100"
      options={options}
      value={options.find(o => o.value === value)}
      onChange={option => setValue(option.value)}
    />
  </div>
}

class Surveys extends Component {

  constructor(props) {
    super(props);

    this.state = {
      swPanelStatus: 3,
      filters: {
        search: "",
      }
    };

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(e) {
    this.setState({filters: {...this.state.filters, search: e.target.value}})
  }

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">Ankiety zaakceptowane</h2>
                </div>
                <div className="d-flex flex-nowrap flex-row">
                  <SwPanelStatusSelect
                    onChange={swPanelStatus => this.setState({swPanelStatus})}
                  />
                  <div className="custom-search-input w-lg-100 float-right mt-1">
                    <InputGroup className="p-0 mb-0">
                      <Input className="p-0" placeholder="Wyszukaj ankiety..." onChange={this.onSearch}/>
                      <span className="input-group-btn py-1">
                      <button className="btn" type="button"><i className="fas fa-search" /></button>
                    </span>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Row>
                <Col>
                  <SurveysList filters={this.state.filters} swPanelStatus={this.state.swPanelStatus} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Surveys;