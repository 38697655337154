import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import SelectBaskets from "../../../SelectBaskets";

class QuestionBasket extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalBasket: props.basket,
    };

    this.toggleModalBasket = this.toggleModalBasket.bind(this);
  }

  toggleModalBasket(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {question, basket, basketsObject, changeable} = this.props;
    const {modalOpen, modalBasket} = this.state;
    const basketObject = basketsObject.find(q => q.id === basket);

    if (!changeable) {
      const tooltipId = "basketTooltipId-" + Math.random().toString(36).substring(7);
      return basketObject ? <React.Fragment>
        <span className="pointer" id={tooltipId}>{basketObject.position + 1}. </span>
        <UncontrolledTooltip target={tooltipId}>
          <span className="img-30" dangerouslySetInnerHTML={{__html: basketObject.stripedContent}}/>
        </UncontrolledTooltip>
      </React.Fragment> : null;
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalBasket} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalBasket}>Wybierz koszyk</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <SelectBaskets questionId={question} isMulti={false} value={modalBasket} onChange={basket => {
          this.setState({
            modalBasket: basket,
            modalOpen: false,
          }, () => {
            this.props.handleBasketChanged(basket);
          });

        }} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleBasketChanged(modalBasket);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={basket ? 'secondary' : 'warning'} className="mx-1 question-badge ws-nowrap img-20 ch-50 pointer d-inline-flex flex-wrap" onClick={this.toggleModalBasket} dangerouslySetInnerHTML={{__html: basket ? (basketObject.position+1 + '. ' + basketObject.stripedContent) : 'Wybierz koszyk...'}} />
        {modal}
      </React.Fragment>
    );
  }
}

QuestionBasket.defaultProps = {
  changeable: true
};

QuestionBasket.propTypes = {
  question: PropTypes.number.isRequired,
  basket: PropTypes.number,
  handleBasketChanged: PropTypes.func,
  changeable: PropTypes.bool
};

function mapStateToProps(state, props) {
  let basketsObject = [];
  if(props.question){
    basketsObject = state.survey.structure.data.questions[props.question].baskets.map(bId => state.survey.structure.data.baskets[bId]);
    let position = 0;
    basketsObject = basketsObject.map(basket => {
      const ret = {
        ...basket,
        position
      };

      position++;
      return ret;
    });
  }


  return {
    basketsObject,
  }
}

export default connect(mapStateToProps)(QuestionBasket);