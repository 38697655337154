import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from "../../../components/Tooltip";
import api from "../../../api";

const SurveyFavorite = props => {
  const [favorite, setFavorite] = React.useState(props.survey.favorite === '1');
  const [saving, setSaving] = React.useState(false);

  const toggleFavorite = () => {
    if(saving){
      return;
    }
    setSaving(true);

    setFavorite(!favorite);
    api.user.patch.toggleFavoriteSurvey(props.survey.id)
      .then(() => {
        setSaving(false);
      })
  }

  return (
    <React.Fragment>
      <i
        id={`s-${props.survey.id}-favorite`}
        className={classnames({
          'fa-star pointer mr-2': true,
          'far': !favorite,
          'fas': favorite,
          'non-favorite': !favorite,
        })}
        style={{
          color: favorite ? 'gold' : 'darkgrey'
        }}
        onClick={toggleFavorite}
      />
      <Tooltip id={`s-${props.survey.id}-favorite`} msg={favorite ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'} />
    </React.Fragment>
  );
};

SurveyFavorite.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    favorite: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SurveyFavorite;