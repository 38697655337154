import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Tooltip} from "reactstrap";
import classnames from "classnames";
import ColorPickerModal from "../../../../components/ColorPickerModal";

const KEY_BG = '$survey-prevStatement-bgColor';
const KEY_COLOR = '$survey-prevStatement-color';

class PrevStatement extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        [KEY_BG]: props.ownStyles[KEY_BG] ? props.ownStyles[KEY_BG] : false,
        [KEY_COLOR]: props.ownStyles[KEY_COLOR] ? props.ownStyles[KEY_COLOR] : false,
      },
      modalOpen: {
        bg: false,
        color: false
      },
      tooltip: {
        bg: false,
        color: false
      }
    };

    this.getDefaultValue = this.getDefaultValue.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(key){
    this.setState({
      tooltip: {
        ...this.state.tooltip,
        [key]: !this.state.tooltip[key]
      }
    })
  }

  getDefaultValue(key){
    return this.props.ownStylesDefault[key];
  }

  clearValues(){
    this.setState({
      values: {
        [KEY_BG]: '',
        [KEY_COLOR]: '',
      }
    });

    this.props.handlerUpdate({[KEY_BG]: ''});
    this.props.handlerUpdate({[KEY_COLOR]: ''});
  }

  updateValue(key, value){
    this.setState({
      values: {
        ...this.state.values,
        [key]: value
      }

    });

    this.props.handlerUpdate({[key]: value});
  }

  render() {
    const {values, modalOpen} = this.state;
    const inactive =
      [false, ''].indexOf(values[KEY_BG]) >= 0
      && [false, ''].indexOf(values[KEY_COLOR]) >= 0
    ;

    return (
      <Row className={classnames({
        'py-1 px-3 align-items-center': true,
        'inactive': inactive,
      })}>
        <label className="col col-5 mb-0">Przycisk "Poprzednie stwierdzenie" {!inactive && <i className="icon-clear-value fas fa-trash float-right" onClick={() => this.clearValues()} />}</label>
        <Col className="col-7 d-flex">
          <div className="d-flex">
            <div className="d-flex">
              <div id="key-prevStatement-bg" className="mr-2">
                <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, bg: true}})} style={{
                  backgroundColor: values[KEY_BG] ? values[KEY_BG] : this.getDefaultValue(KEY_BG),
                }} />
                {modalOpen.bg && <ColorPickerModal
                  color={values[KEY_BG] ? values[KEY_BG] : this.getDefaultValue(KEY_BG)}
                  onClose={() => this.setState({modalOpen: false})}
                  onSelect={value => {
                    this.setState({modalOpen: {...modalOpen, bg: false}});
                    this.updateValue(KEY_BG, value);
                  }}
                />}
                <Tooltip
                  delay={0}
                  placement="top"
                  isOpen={this.state.tooltip.bg}
                  target={'key-prevStatement-bg'}
                  toggle={() => this.toggleTooltip('bg')}
                >
                  Domyślny kolor tła
                </Tooltip>
              </div>

              <div id="key-prevStatement-color" className="mr-2">
                <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, color: true}})} style={{
                  backgroundColor: values[KEY_COLOR] ? values[KEY_COLOR] : this.getDefaultValue(KEY_COLOR),
                }} />
                {modalOpen.color && <ColorPickerModal
                  color={values[KEY_COLOR] ? values[KEY_COLOR] : this.getDefaultValue(KEY_COLOR)}
                  onClose={() => this.setState({modalOpen: false})}
                  onSelect={value => {
                    this.setState({modalOpen: {...modalOpen, color: false}});
                    this.updateValue(KEY_COLOR, value);
                  }}
                />}
                <Tooltip
                  delay={0}
                  placement="top"
                  isOpen={this.state.tooltip.color}
                  target={'key-prevStatement-color'}
                  toggle={() => this.toggleTooltip('color')}
                >
                  Domyślny kolor tekstu
                </Tooltip>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

PrevStatement.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
};

export default PrevStatement;