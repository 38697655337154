import * as React from 'react';
import {connect} from "react-redux";
import api from "../../api";
import SurveysCategory from "../../componentsNew/SurveysCategories/SurveysCategory";
import classnames from "classnames";
import LoadingSpinner from "../../components/LoadingSpinner";

function LastSeenSurvey(props) {
	const [loadingSurveys, setLoadingSurveys] = React.useState(false);
	const [surveys, setSurveys] = React.useState([]);

	React.useEffect(() => {
		fetchSurveys()
	}, [])

	const fetchSurveys = () => {
		setLoadingSurveys(true)

		api.surveysCategories.surveys(props.filters)
			.then(data => {
				setSurveys(data.map(survey => {
					if (survey.surveyShares) {
						let unique = [];
						survey.surveyShares = survey.surveyShares.split(',').map(surveyShare => {
							surveyShare = surveyShare.split(';');
							if (unique.includes(surveyShare[0])) {
								return false;
							}
							unique.push(surveyShare[0])
							return {
								user: {
									email: surveyShare[0],
									firstname: surveyShare[1],
									lastname: surveyShare[2],
									avatar: surveyShare[3] !== '0' ? surveyShare[3] : null,
								},
							}
						}).filter(v => v);
					} else {
						survey.surveyShares = [];
					}

					if (survey.swPanelStatus) {
						survey.swPanel = {
							status: survey.swPanelStatus,
							msg: survey.swPanelMsg,
						}
					} else {
						survey.swPanel = undefined;
					}

					survey.hasTopSurveysCollector = survey.hasTopSurveysCollector === 1;
					survey.hasActiveSurveyBase = survey.hasActiveSurveyBase === 1;

					return survey;
				}));
			})
			.finally(() => setLoadingSurveys(false))
	}

	const getAllSurveys = () => {
		return surveys;
	}

	return (
		<React.Fragment>
			<div className="w-100 d-flex flex-column position-relative">
				<div
					className={classnames({
						'surveys-loading-layer align-items-center justify-content-center': true,
						'd-flex': loadingSurveys,
						'd-none': !loadingSurveys,
					})} style={{
					opacity: loadingSurveys ? 1 : 0
				}}
				>
					<LoadingSpinner/>
				</div>
				<div
					className="ReactTable table-wrap pt-3"
					style={{display: window.innerWidth <= 800 ? 'none' : 'block'}}
				>
					<div className="tr-tbody" style={{color: '#7b8089', fontSize: '0.8em'}}>
						<div className="rt-tr-group mr-4 pr-4">
							<div className="rt-tr text-center">
								<div className="rt-td td-first text-left pl-4">
								</div>
								<div className="rt-td td-name pointer ml-4 pl-4">
									<span>Projekt</span>
								</div>
								<div className="rt-td td-end">
									<span>Koniec badania</span>
								</div>
								<div className="rt-td td-status">
									<span>Status</span>
								</div>
								<div className="rt-td td-responsesCount">
									<span>Pełne wywiady</span>
								</div>
								<div className="rt-td td-progressbar">
									Postęp
								</div>
								{props.isUserRespondent && <div className="rt-td td-progressbar">
									Średnia ocena
								</div>}
								<div className="rt-td td-actions">
									Akcje
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					id={'surveys-categories-list'} style={{minHeight: "180px"}}
					className="flex-grow-1 overflow-auto"
				>
					<React.Fragment>
						<SurveysCategory
							collapsed
							sortableDisabled
							surveysCollapsed
							hideHeader
							surveys={getAllSurveys()}
							onEndSurveys={evt => {}}
							onChange={surveyId => {
								fetchSurveys()
							}}
						/>
					</React.Fragment>
				</div>
			</div>
		</React.Fragment>
	);
}

LastSeenSurvey.propTypes = {};

function mapStateToProps(state) {
	return {
		user: state.user,
		plan: state.user.userPlan.plan,
		isUserRespondent: state.user.type === 'Respondent',
	}
}

export default connect(mapStateToProps, {})(LastSeenSurvey);