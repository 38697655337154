import * as React from 'react';
import PropTypes from "prop-types";
import AnyChart from "anychart-react";
import classnames from "classnames";
import {map} from "lodash";
import {Alert, Row, Col, Card, CardHeader, CardBody} from "reactstrap";
import moment from "moment";
import api from "../../../api";
import Button from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import DatePicker from "react-datepicker";

function SurveysStats(props) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [filterDateFrom, setFilterDateFrom] = React.useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
  const [filterDateTo, setFilterDateTo] = React.useState(moment().format('YYYY-MM-DD'));
  const [filterInterval, setFilterInterval] = React.useState('day');
  const [filterBa, setFilterBa] = React.useState(false);

  const intervals = {
    day: 'Dzień',
    week: 'Tydzień',
    month: 'Miesiąc',
  };

  const fetchData = () => {
    setLoading(true);

    api.admin.stats.surveys({
      dateFrom: filterDateFrom,
      dateTo: filterDateTo,
      ba: filterBa,
      interval: filterInterval,
    })
    .then(data => {
      setLoading(false);
      setData(data)
    })
    .catch(e => {
      setLoading(false);
      setData({});
    })
  }

  const getChart = () => {
    if(_.isEmpty(data)){
      return <Alert color="warning" className="text-center">Brak danych</Alert>
    }

    let stage = anychart.graphics.create();
    var chart = anychart.column(map(data.data, d => {
      return [d.date, d.count]
    }));
    chart.yScale().minimum(0);
    chart.legend(false);
    chart.title('Liczba wywiadów');

    chart.yScale().stackMode('value');
    chart.yScale().ticks().allowFractional(false);
    chart.yAxis().title('Liczba wywiadów');

    chart.getSeries(0).name('Liczba ankiet');

    return <AnyChart instance={stage} charts={[chart]} height={550}/>
  }

  return (
    <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader><h2 className="float-left mr-4">Statystyki tworzenia ankiet</h2></CardHeader>
          <hr className="my-0" />
          <CardBody>
            <div className="d-flex justify-content-end mb-3">
              <div className="btn-group mr-3">
                <button
                  className={classnames({
                    'btn': true,
                    'btn-primary': filterBa,
                    'btn-secondary': !filterBa,
                  })}
                  onClick={() => setFilterBa(!filterBa)}
                >Tylko z bazy ankiet</button>
              </div>
              <div className="btn-group mr-3">
                {_.map(intervals, (name, key) => <button
                  className={classnames({
                    'btn': true,
                    'btn-primary': filterInterval === key,
                    'btn-secondary': filterInterval !== key,
                  })}
                  onClick={() => setFilterInterval(key)}
                >{name}</button>)}
              </div>

              <div>
                {filterDateFrom && filterDateTo && <div className="d-flex h-100 justify-content-center align-items-center">
                  <DatePicker
                    dropdownMode="select"
                    className="form-control w-100 mb-0"
                    isClearable={false}
                    selected={moment(filterDateFrom)}
                    onChange={(date) => setFilterDateFrom(date.format('YYYY-MM-DD'))}
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                  />
                  <div className="px-2 d-flex align-items-center"> - </div>
                  <DatePicker
                    dropdownMode="select"
                    className="form-control w-100 mb-0"
                    isClearable={false}
                    selected={moment(filterDateTo)}
                    onChange={(date) => setFilterDateTo(date.format('YYYY-MM-DD'))}
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                  />
                </div>}
              </div>
              <Button color="primary" className="ml-2" onClick={fetchData}>Pokaż</Button>
            </div>

            {loading ? <LoadingSpinner classNames="py-5 my-5" /> : getChart()}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

SurveysStats.propTypes = {

};

export default SurveysStats;