import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './style.scss'
import {FormattedMessage} from "react-intl";
import {Tooltip} from "reactstrap";
import LoadingSpinner from "../../../../../../components/LoadingSpinner";

class Stat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipIsOpen: false,
    };

    this.compareNumbers = this.compareNumbers.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen,
    })
  }

  compareNumbers(){
    const {numberCurrent, numberCompare} = this.props;
    let color = '';
    const diff = numberCurrent - numberCompare;

    if(diff < 0){
      color = 'danger';
    }else if(diff > 0){
      color = 'success';
    }

    return <span className={'compare text-' + color}>
      {diff > 0 && <span><i className="fas fa-arrow-up" /> {Math.abs(diff)}</span>}
      {diff < 0 && <span><i className="fas fa-arrow-down" /> {Math.abs(diff)}</span>}
      {diff === 0 && <span>Bez zmian</span>}
    </span>
  }

  render() {
    const {tooltipIsOpen} = this.state;
    const {tooltip, numberCurrent, title, numberCompare} = this.props;

    const tooltipTarget = 'tooltip-' + title;

    return (
      <div className="stat d-flex flex-column w-100">
        <span className="number">{!_.isUndefined(numberCurrent) ? numberCurrent : <LoadingSpinner width={25} classNames="my-1" />}</span>
        <span className="title">
          {title}
          {tooltip && <i className="fas fa-question-circle ml-1" id={tooltipTarget} />}
          {tooltip && <Tooltip
            delay={0}
            placement="top"
            isOpen={tooltipIsOpen}
            target={tooltipTarget}
            toggle={() => this.toggleTooltip()}
          >{tooltip}</Tooltip>}
        </span>
        {(!_.isUndefined(numberCurrent) && numberCompare) ? this.compareNumbers() : null}
      </div>
    );
  }
}

Stat.propTypes = {
  numberCurrent: PropTypes.number,
  numberCompare: PropTypes.number,
  title: PropTypes.string.isRequired,
  compared: PropTypes.string,
  tooltip: PropTypes.string,
};

export default Stat;