import * as React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, Col, FormGroup, Input, Label} from "reactstrap";
import HelpModeIconTooltip from "../../../../../components/HelpModeIconTooltip";
import Select from "react-select";
import api from "../../../../../api";
import {toast} from "react-toastify";
import ModalSimple from "../../../../../componentsReusable/ModalSimple";

function WebhookEdit(props) {
	const [endpoint, setEndpoint] = React.useState(props.webhook.endpoint);
	const [status, setStatus] = React.useState(props.webhook.status);
	const [error, setError] = React.useState({});
	const [isDeleted, setDeleted] = React.useState(false);
	const [isDeleteModalOpen, setDeleteModal] = React.useState(false);

	const getOptions = () => {
		return [
			{
				label: 'Wejście na stronę zaproszenia',
				value: 1,
			},
			{
				label: 'Przejście na stronę z pytaniami',
				value: 2
			},
			{
				label: 'Zakończenie ankiety -  pełne wypełnienie ankiety (complete)',
				value: 3
			},
			{
				label: 'Zakończenie ankiety -  brak kwalifikacji do ankiety (screenout)',
				value: 4
			}
		];
	}

	const save = () => {
		api.survey.patch.webhooks(props.surveyId, props.webhook.id, {
			data: {
				endpoint,
				status
			}
		}).then(webhook => {
			setEndpoint(webhook.endpoint);
			setError({});
			toast.success("Zmiana webhook się powiodła");
		}).catch(res => {
			if (res.response && res.response.data.form) {
				setError(res.response.data.form.errors);
			}

			toast.error("Zmiana webhook nie powiodła się");
		});
	}

	const deleteWebhook = () => {
		api.survey.delete.webhooks({id: props.surveyId, webhookId: props.webhook.id}).then(() => {
			setDeleted(true);
			toast.success("Zmiana webhook się powiodła");
		}).catch(res => {
			toast.error("Usunięcie webhook nie powiodło się");
		});
	}

	if (isDeleted) {
		return <div/>
	}

	return (
		<div>
			<ModalSimple onAccept={() => deleteWebhook()} onCancel={() => setDeleteModal(false)}
				modalOpen={isDeleteModalOpen} bodyText="Czy chesz usunąć webhook?"/>
			<FormGroup>
				<Label lg={12} className="text-lg-center">
					Webhook {props.index + 1}
				</Label>
			</FormGroup>
			<FormGroup row>
				<Label lg={3} className="text-lg-right">
					Endpoint
					<HelpModeIconTooltip className="ml-2">Adres url do którego ma być wywołany
						webhook za pomocą metody POST</HelpModeIconTooltip>
				</Label>
				<Col lg={9}>
					<Input onChange={(e) => setEndpoint(e.target.value)} value={endpoint}/>
					{error.endpoint && <div className="d-block invalid-feedback">{error.endpoint.join('; ')}</div>}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label lg={3} className={"text-lg-right"}>
					Status
					<HelpModeIconTooltip className="ml-2">Określa status kiedy zostanie wysłany webhook</HelpModeIconTooltip>
				</Label>
				<Col lg={9} className="mb-2">
					<Select
						className={"react-select"}
						options={getOptions()}
						placeholder="Wybierz status webhook"
						value={status ? getOptions().find(option => option.value === status) : null}
						onChange={option => {
							setStatus(option.value);
						}}
					/>
					{error.status && <div className="d-block invalid-feedback">{error.status.join('; ')}</div>}
				</Col>
			</FormGroup>
			<FormGroup row className="flex-row-reverse">
				<Button onClick={() => save()} className="ladda-button btn btn-primary btn-ladda">Edytuj</Button>
				<i onClick={() => setDeleteModal(true)} className="p-1 mr-1 fas fa-trash float-right pointer" />
			</FormGroup>
		</div>
	);
}

WebhookEdit.propTypes = {
	surveyId: PropTypes.number.isRequired,
	webhook: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired
};

function mapStateToProps() {
	return {}
}

export default connect(mapStateToProps)(WebhookEdit);