import React from 'react';
import PropTypes from 'prop-types';
import useDidMountEffect from "../../../../../../../utils/useDidMountEffect";
import api from "../../../../../../../api";
import ImportFile from "./ImportFile";
import RemoveButton from "./RemoveButton";
import {toast} from "react-toastify";

const EndingCodes = ({surveyId}) => {
  const [endingCodesCount, setEndingCodesCount] = React.useState(0);

  useDidMountEffect(() => {
    fetchEndingCodesCount()
  })

  const fetchEndingCodesCount = () => {
    api.survey.getEndingCodesCount(surveyId)
      .then(({count}) => setEndingCodesCount(count))
  }

  return (
    <div className="d-print-none">
      <h2 className="mt-4">Treści indywidualne / kody</h2>

      <div className="d-flex flex-column">
        <div>
          <span>Liczba wgranych pozycji: {endingCodesCount}</span>
          {endingCodesCount > 0 && <RemoveButton
            surveyId={surveyId}
            onRemoved={() => {
              fetchEndingCodesCount()
              toast.success('Wszystkie treści indywidualne / kody zostały poprawnie usunięte')
            }}
          />}
        </div>
        <div className="mt-1">
          <ImportFile
            surveyId={surveyId}
            onAdded={() => {
              fetchEndingCodesCount()
              toast.success('Pozycje zostały poprawnie wgrane')
            }}
          />
        </div>
      </div>
    </div>
  );
};

EndingCodes.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default EndingCodes;
