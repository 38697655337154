import api from "api";

export const UPDATED = 'Analysis/data/filterDate/UPDATED';

function filterDateUpdated(filter){
  return {
    type: UPDATED,
    filter
  }
}

export function updateFilterDate(data){
  return dispatch => {
    return api.analysis.patch.filterDate(data).then((filter) => {
      dispatch(filterDateUpdated(filter));
    });
  }
}