import React, {Component} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import TableList from "../../../components/RespondentSms/Respondent/TableList";
import ButtonAdd from "../../../components/RespondentSms/Respondent/ButtonAdd";
import ButtonAddTextarea from "./components/ButtonAddTextarea";

class Base extends Component {
  constructor(props) {
    super(props);

    this.tableList = React.createRef();
  }
  render() {
    return <Card>
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Baza respondentów SMS</h2>
          </div>

          <div>
            <ButtonAddTextarea baseId={parseInt(this.props.match.params.respondentSmsBase)} onSaved={() => this.tableList.current.fetchData()} />
            <ButtonAdd baseId={parseInt(this.props.match.params.respondentSmsBase)} onSaved={() => this.tableList.current.fetchData()} />
          </div>
        </div>
      </CardHeader>
      <hr className="my-0" />
      <CardBody>
        <TableList
          ref={this.tableList}
          baseId={parseInt(this.props.match.params.respondentSmsBase)} />
      </CardBody>
    </Card>;
  }
}

export default Base;