import {
	AlignmentType,
	Document,
	HeadingLevel,
	Packer,
	Paragraph,
	Table,
	TableCell,
	TableRow,
	TextRun,
	WidthType
} from "docx";
import stripTagsAndSpecialChars from "../converters/stripTagsAndSpecialChars";

export default class docxBuilder {
	constructor(defaultOptions = {}, defaultTextOptions = {}) {
		this.elements = [];
		this.defaultOptions = defaultOptions;
		this.defaultTextOptions = defaultTextOptions;
	}

	addText(text, textOptions = {}, paragraphOptions = {}) {
		this.elements.push(new Paragraph({
			children: [
				new TextRun({
					text: stripTagsAndSpecialChars(text),
					...this.getDefaultTextOptions(textOptions)
				})
			],
			...this.getDefaultOptions(paragraphOptions)
		}))
	}

	addHeading(text, level = 1, options = {}) {
		let heading = HeadingLevel.HEADING_1;
		if (level === 2) {
			heading = HeadingLevel.HEADING_2;
		}
		if (level === 3) {
			heading = HeadingLevel.HEADING_3;
		}
		if (level === 4) {
			heading = HeadingLevel.HEADING_4;
		}
		if (level === 5) {
			heading = HeadingLevel.HEADING_5;
		}
		if (level === 6) {
			heading = HeadingLevel.HEADING_6;
		}
		this.elements.push(new Paragraph({
			text: stripTagsAndSpecialChars(text),
			heading: heading,
			...this.getDefaultOptions(options)
		}))
	}

	createTableCell(text, bold = false) {
		return new TableCell({
			width: {
				size: 4505,
				type: WidthType.DXA,
			},
			children: [new Paragraph({
				spacing: {
					before: 50,
				},
				children: [
					new TextRun({
						text: "  " + stripTagsAndSpecialChars(text),
						bold: bold,
						size: 16,
					}),
				]
			})],
		})
	}

	addTable(matrix) {
		const rows = [];

		let i = 0;
		matrix.forEach((row) => {
			let columns = [];
			let j = 0;

			row.forEach((column) => {
				columns.push(this.createTableCell(column, i === 0))

				j++;
			})

			rows.push(new TableRow({
				height: {
					value: 300
				},
				children: columns
			}))
			i++;
		})


		const table = new Table({
			columnWidths: [4505, 4505],
			rows: rows
		});

		this.elements.push(table)
	}

	addElement(element) {
		this.elements.push(element);
	}

	getDefaultTextOptions(options) {
		if (Object.keys(options).length > 0) {
			return options;
		}

		return this.defaultTextOptions;
	}

	getDefaultOptions(options) {
		if (Object.keys(options).length > 0) {
			return options;
		}

		return this.defaultOptions;
	}

	createDocument() {
		return new Document({
			sections: [
				{
					children: this.elements
				}
			]
		});
	}

	createAndDownload(filename) {
		const document = this.createDocument();

		Packer.toBlob(document).then((blob) => {
			saveAs(blob, filename + ".docx");
		});
	}
}