import {normalize} from "normalizr";
import _ from 'lodash';
import api from "api";
import schemaAnalysis from "entities/analysis/schema";

import * as setsActions from "../sets/actions";
import * as excludingsActions from "../excludings/actions";
import * as answersActions from "../answers/actions";
import * as questionsActions from "../questions/actions";
import * as pagesActions from "../pages/actions";
import * as blocksActions from "../blocks/actions";
import * as collectorsActions from "../collectors/actions";
import * as surveyActions from "../survey/actions";
import * as sharesActions from "../shares/actions";
import * as rowsActions from "../rows/actions";
import * as columnsActions from "../columns/actions";

import * as filtersQuestionActions from "../filtersQuestion/actions";
import * as filtersCollectorActions from "../filtersCollector/actions";

export const REQUEST = 'Survey/data/analysis/REQUEST';
export const SUCCESS = 'Survey/data/analysis/SUCCESS';
export const ERROR = 'Survey/data/analysis/ERROR';

export const SET = 'Survey/data/analysis/SET';
export const UPDATED = 'Survey/data/analysis/UPDATED';

function setAnalysis(analysis) {
  return {
    type: SET,
    analysis
  }
}

function analysisUpdated(analysis) {
  return {
    type: UPDATED,
    analysis
  }
}

export function updateAnalysis(analysis) {
  return dispatch => {
    return api.analysis.patch.analysis(analysis)
      .then(analysis => {
        analysis.excludedResponses = _.map(analysis.excludedResponses, response => response.id);
        dispatch(analysisUpdated(analysis));

        return analysis;
      });
  }
}

export function fetchAnalysis(analysis) {
  return dispatch => {
    dispatch({type: REQUEST});

    dispatch(setAnalysis({}));
    dispatch(filtersQuestionActions.setFiltersQuestion({}));
    dispatch(filtersCollectorActions.setFiltersCollector({}));

    return api.analysis.get.analysis(analysis).then(analysisData => {
      const normalized = normalize(analysisData, schemaAnalysis);
      const analysis = normalized.entities.analysis[normalized.result];


      dispatch(setsActions.setSets(normalized.entities.sets));

      normalized.entities.questionExcludings && dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings));
      normalized.entities.answers && dispatch(answersActions.setAnswers(normalized.entities.answers));
      normalized.entities.rows && dispatch(rowsActions.setRows(normalized.entities.rows));
      normalized.entities.columns && dispatch(columnsActions.setColumns(normalized.entities.columns));
      normalized.entities.questions && dispatch(questionsActions.setQuestions(normalized.entities.questions));
      normalized.entities.pages && dispatch(pagesActions.setPages(normalized.entities.pages));
      normalized.entities.blocks && dispatch(blocksActions.setBlocks(normalized.entities.blocks));
      normalized.entities.collectors && dispatch(collectorsActions.setCollectors(normalized.entities.collectors));
      normalized.entities.filtersQuestion && dispatch(filtersQuestionActions.setFiltersQuestion(normalized.entities.filtersQuestion));
      normalized.entities.filtersCollector && dispatch(filtersCollectorActions.setFiltersCollector(normalized.entities.filtersCollector));
      normalized.entities.shares ? dispatch(sharesActions.setShares(normalized.entities.shares)) : dispatch(sharesActions.setShares([]));
      analysis.excludedResponses = _.map(analysis.excludedResponses, response => response.id);

      dispatch(setAnalysis(analysis));
      dispatch(surveyActions.setSurvey(normalized.entities.surveys[analysis.survey]));

      dispatch({type: SUCCESS});

      return analysis;
    }).catch(() => {
      dispatch({type: ERROR})
    });
  }
}