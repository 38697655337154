import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Pagination from "components/Pagination";
import {FormattedMessage} from "react-intl";
import ReactTable from "react-table";
import {forEach, isArray, xor, filter} from "lodash";
import {ButtonGroup, Row, Col} from "reactstrap";
import QuotaNewEditModal from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/QuotaNews/components/QuotaNewEditModal";
import {addQuotaNew, updateQuotaNew} from "../../../Structure/data/quotaNews/actions";
import Block from "../../../Structure/components/Filter/Block";
import _ from "lodash";
import {toast} from "react-toastify";
import Button from "../../../../../componentsReusable/Button";
import Icon from "../../../../../componentsReusable/Icon";
import classnames from "classnames";
import CreateCollectorPanel
  from "../../../Structure/components/Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/QuotaNews/components/CreateCollectorPanel";
import {Link} from "react-router-dom";
import api from "../../../../../api";
import DeleteSelectedModal from "./DeleteSelectedModal";
import SurveyQuotaNewFromTextarea from "./SurveyQuotaNewFromTextarea";

class QuotaNews extends Component {

  constructor(props) {
    super(props);
    this.actions = this.actions.bind(this);

    this.tableRef = React.createRef();

    this.state = {
      selected: [],
      editing: false,
      quotaNew: null,
      saving: false
    };

    this.quotaNew = React.createRef();
    this.getAllQuotaNews = this.getAllQuotaNews.bind(this);
    this.saveQuotaNew = this.saveQuotaNew.bind(this);
    this.toggleCreateQuotaNew = this.toggleCreateQuotaNew.bind(this);
    this.quotaNewConditions = this.quotaNewConditions.bind(this);
    this.getCurrentDataTable = this.getCurrentDataTable.bind(this);
    this.toggleCreateQuotaNew = this.toggleCreateQuotaNew.bind(this);
  }

  getAllQuotaNews(){
    return Object.values(filter(this.props.quotaNews, quotaNew => !quotaNew.deleted))
  }

  omitIdBlock(block){
    block = _.omit(block, ['id']);

    block.condsQuestion = block.condsQuestion.map(cond => _.omit(cond, ['id']));
    block.children = block.children.map(b => this.omitIdBlock(b));

    return block;
  }

  actions(quotaNew) {

    return (
      <ButtonGroup>
        <Icon className={classnames({
          'fas fa-power-off p-1 cursor-pointer': true,
          'text-danger': quotaNew.paused,
        })} tooltip={quotaNew.paused ? 'Aktywuj kwotę' : 'Wyłącz kwotę'} onClick={() => {
          this.props.updateQuotaNew({
            quotaNew: {
              id: quotaNew.id,
              paused: !quotaNew.paused,
            }
          })
        }} />
        <Icon className="fas fa-edit ml-1 p-1" tooltip={'Edytuj'} onClick={() => {
          let data = {editing: true, quotaNew};
          this.setState(data)
        }} />
        <Icon className="fas fa-copy ml-1 p-1" tooltip={'Kopiuj'} quotaNew={quotaNew} onClick={() => {
          let quotaNewCopy = _.clone(quotaNew);
          quotaNewCopy = _.omit(_.merge(quotaNewCopy, {name: quotaNewCopy.name + ' kopia'}), ['id']);
          quotaNewCopy.blocks = quotaNewCopy.blocks.filter(block => block.parent === false);

          quotaNewCopy.blocks = quotaNewCopy.blocks.map(b => this.omitIdBlock(b));

          let data = {editing: true, quotaNew: quotaNewCopy};
          this.setState(data)
        }}/>
      </ButtonGroup>
    )
  }

  toggleCreateQuotaNew() {
    this.setState({
      editing: true,
      quotaNew: {
        name: 'Kwota',
        limit: 0,
        blocks: [
          {
            type: 'AND',
            children: [],
            condsQuestion: []
          }
        ],
      }
    })
  }

  saveQuotaNew() {
    const {survey} = this.props;
    const quotaNew = this.quotaNew.current.getQuotaNew();

    this.setState({saving: true});

    let data = {
      quotaNew: {
        id: quotaNew.id,
        name: quotaNew.name,
        limit: quotaNew.limit,
        survey: survey.id,
        blocks: quotaNew.blocks,
      }
    };

    const action = quotaNew.hasOwnProperty('id') ? this.props.updateQuotaNew : this.props.addQuotaNew;

    action(data)
      .then(() => {
        this.setState({
          quotaNew: null,
          editing: false,
        })
      })
      .catch(error => {
        const errors = error.response.data.form.errors;
        forEach(errors, error => {
          if(isArray(error)){
            forEach(error, e => toast.error(e));
          }else{
            toast.error(error);
          }
        });

      })
      .finally(() => {
        this.setState({
          saving: false
        })
      })
  }

  quotaNewConditions(quotaNew) {
    return <ol className="m-0 p-0 text-left">{_.map(quotaNew.blocks, (block, key)=> !block.parent && <li key={key} style={{listStyleType: 'decimal'}}><Block block={block} changeable={false}/></li>)}</ol>
  }

  getCurrentDataTable(){
    const data = this.getAllQuotaNews();

    const {page,pageSize} = this.tableRef.current.state;
    const from = page * pageSize;
    const to = from + pageSize;

    return data.slice(from, to);
  }

  toggleSelected(id) {
    const selected = xor(this.state.selected, [id]);

    this.setState({
      selected,
    })
  }

  toggleSelectAll(){
    const {selected} = this.state;

    const data = this.getCurrentDataTable();

    if(selected.length === data.length){
      this.setState({
        selected: [],
      });
    }else{
      this.setState({
        selected: data.map(d => d.id),
      });
    }
  }

  render() {
    const data = this.getAllQuotaNews();

    const columns = [{
      id: 'name',
      Header: 'Nazwa',
      accessor: quotaNew => quotaNew.name
    }, {
      id: 'conditions',
      Header: 'Warunki',
      accessor: quotaNew => this.quotaNewConditions(quotaNew)
    }, {
      id: 'progress',
      Header: 'Postęp',
      accessor: quotaNew => <span>{quotaNew.current}/{quotaNew.limit}</span>
    }, {
      id: 'actions',
      show: !this.props.hiddenColumns.includes('actions'),
      Header: <FormattedMessage id="quotaNews.listItems.tableHeader.actions"/>,
      accessor: quotaNew => this.actions(quotaNew),
    }];

    if (this.props.selectable) {
      columns.unshift({
        id: "checkbox",
        accessor: "",
        Cell: ({original}) => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected.includes(original.id)}
              onChange={() => this.toggleSelected(original.id)}
            />
          );
        },
        Header: x => {
          return (
            <input
              type="checkbox"
              className="checkbox"
              checked={this.state.selected.length > 0 && this.state.selected.length === this.getCurrentDataTable().length}
              onChange={() => this.toggleSelectAll()}
            />
          );
        },
        sortable: false,
        width: 45
      })
    }

    return (
      <React.Fragment>
        <Row className="d-print-none">
          <Col className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              <Button color="primary" onClick={this.toggleCreateQuotaNew}><i className="fas fa-plus" /> <span>Utwórz kwotę</span></Button>
              {this.props.surveyPlan.filtersFromTextarea && <SurveyQuotaNewFromTextarea />}
            </div>

            <Link to={api.quotaNew.getCsv(this.props.survey.id)} target="_blank">Pobierz do csv</Link>
          </Col>
        </Row>
        <Row className="d-none d-print-block">
          <h1 className="text-center">Lista kwot</h1>
        </Row>
        <Row>
          <Col>
            <ReactTable
              ref={this.tableRef}
              minRows={0}
              className={'table-wrap'}
              data={data}
              getTrGroupProps={(state, rowInfo) => {
                return {
                  className: (
                    (!rowInfo.row._original.blocks)
                    || (rowInfo.row._original.blocks.length > 0 && !rowInfo.row._original.blocks[0].condsQuestion.length)
                  )
                    ? 'bg-warning'
                    : ''
                };
              }}
              columns={columns}
              showPagination={!_.isEmpty(this.props.quotaNews)}
              PaginationComponent={Pagination}
              noDataText={'Brak danych'}
              defaultPageSize={this.props.defaultPageSize}
              onSortedChange={() => this.setState({selected: []})}
              onPageChange={() => this.setState({selected: []})}
              onPageSizeChange={() => this.setState({selected: []})}
            />
            <div className="d-flex flex-row flex-nowrap align-items-center">
              <Button className="mr-2" color={'secondary'} onClick={() => this.toggleSelectAll()}>Zaznacz/Odznacz wszystko</Button>

              {this.state.selected.length > 0 && <DeleteSelectedModal
                selected={this.state.selected.map(fId => data.find(f => f.id === fId))}
                onDeleted={() => this.setState({selected: [],})}
              />}
              {(this.props.surveyPlan.collectorPanelAccess && this.state.selected.length > 0) && <CreateCollectorPanel
                surveyId={this.props.survey.id}
                selected={this.state.selected.map(qId => data.find(q => q.id === qId))}
                onFinish={() => this.setState({selected: [],})}
              />}
            </div>
            {(this.state.editing && !!this.state.quotaNew) && <QuotaNewEditModal
              isOpen
              saving={this.state.saving}
              quotaNew={this.state.quotaNew}
              toggleModal={() => this.setState({editing: false, quotaNew: null})}
              onSave={this.saveQuotaNew}
              ref={this.quotaNew}
            />
            }
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

QuotaNews.defaultProps = {
  defaultPageSize: 10,
  hiddenColumns: [],
  selectable: true,
};

QuotaNews.propTypes = {
  selectable: PropTypes.bool,
  hiddenColumns: PropTypes.array,
  defaultPageSize: PropTypes.number,
  quotaNews: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    surveyPlan: state.user.userPlan.plan.survey,
    survey: state.survey.structure.data.survey,
    quotaNews: state.survey.structure.data.quotaNews,
    serialQuotaNews: state.survey.structure.data.quotaNewSerial,
    iterativeQuotaNews: state.survey.structure.data.quotaNewIterative,
    questions: questions,
  }
}

export default connect(mapStateToProps, {addQuotaNew, updateQuotaNew})(QuotaNews);
