import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from 'reactstrap';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";

class TransactionsList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    this.setState({
      searching: true,
      page: state.page,
      pageSize: state.pageSize
    });

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.transaction.post.search(data)
    .then(res => {
      this.setState({
        searching: false,
        data: res.data,
        count: res.count
      });
    })
    .catch(e => this.setState({
      searching: false,
    }));
  }

  render() {
    const columns = [
      {
        Header: 'Id',
        accessor: 'transactionId',
        width: 150,
      }, {
        Header: 'Data',
        accessor: 'createdAt',
        width: 170,
      }, {
        id: 'status',
        Header: 'Status',
        accessor: transaction => {
          switch (parseInt(transaction.status)) {
            case 1: {
              return <div className="badge badge-warning">Oczekiwanie na płatność</div>;
            }
            case 2: {
              return <div className="badge badge-success">Zakończona</div>;
            }
            case 3: {
              return <div className="badge badge-danger">Odrzucona</div>;
            }
          }
        },
        width: 170,
      }, {
        id: 'user.email',
        Header: 'Użytkownik',
        width: 300,
        accessor: transaction => transaction.user.email + ' (' + transaction.user.type + ')',
      }, {
        id: 'invoiceData',
        Header: 'Dane do faktury',
        width: 300,
        accessor: transaction => {
          if(!transaction.user.invoice){
            return <i>Brak</i>;
          }

          return <div className="d-flex flex-column">
            <span>{transaction.user.company}</span>
            <span>NIP: {transaction.user.nip}</span>
            <span>{transaction.user.postalCode} {transaction.user.city}</span>
            <span>{transaction.user.street}</span>
          </div>
        },
      }, {
        Header: 'Plan',
        accessor: 'plan.name',
        width: 170,
      }, {
        Header: 'Okres',
        accessor: 'period',
        width: 170,
      },
      {
        id: 'planDiscount.id',
        Header: 'Zniżka',
        accessor: transaction => {
          if(!transaction.planDiscount){
            return <i>Brak zniżki</i>
          }

          return transaction.planDiscount.code
        },
        width: 170,
      },
      {
        id: 'price',
        Header: 'Cena',
        accessor: transaction => {
         return ((transaction.price/100) * 1.23).toFixed(2)
        },
        width: 170,
      },
    ];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

export default TransactionsList;