import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import SelectPages from "../../../../SelectPages";
import _ from "lodash";
import SelectSets from "../../../../SelectSets";

class Pages extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalPages: props.action.pages || [],
    };

    this.toggleModalPages = this.toggleModalPages.bind(this);
  }

  toggleModalPages(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {action, pages, changeable} = this.props;
    const {modalOpen, modalPages} = this.state;

    if (!changeable) {
      return action.pages.map(id => {
        const page = pages.find(q => q.id === id);

        return 'Strona ' + page.positionInSurvey;
      }).join('; ')
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalPages} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalPages}>Wybierz strony</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-75 d-inline-block">
          <SelectPages value={modalPages} onChange={pages => {
            this.setState({
              modalPages: pages,
            })
          }} />
        </div>
        <div className="w-25 d-inline-block pl-3">
          Wybranych stron: {modalPages.length}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handlePagesChanged(modalPages);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={!_.isEmpty(action.pages) ? 'secondary' : 'warning'} className="mx-1 pointer" onClick={this.toggleModalPages}>{!_.isEmpty(action.pages) ? action.pages.map(id => {
          const page = pages.find(q => q.id === id);

          return 'Strona ' + page.positionInSurvey;
        }).join('; ') : 'Wybierz strony...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

Pages.defaultProps = {
  changeable: true
};

Pages.propTypes = {
  action: PropTypes.shape({
    pages: PropTypes.array,
  }).isRequired,
  handlePagesChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  let pages = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        positionInSurvey++;

        let p = state.survey.structure.data.pages[pageId];
        p.positionInSurvey = positionInSurvey;
        pages.push(p)
      });
    });
  });

  return {
    pages
  }
}

export default connect(mapStateToProps)(Pages);