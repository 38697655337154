import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Badge, Row, Col, Label} from "reactstrap";
import FiltersQuestionFilter from "./FiltersQuestionFilter";
import FiltersQuestionList from "./FiltersQuestionList";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import AnalysisFilteringAvailable from "components/PlanRestrictionsModals/modals/AnalysisFilteringAvailable";

class FiltersQuestion extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filter: false,
    }
  }

  render() {
    const {filter} = this.state;
    const {filtersQuestion, analysis, questions, analysisPlan} = this.props;
    let editing = filter !== false;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Label>
              <FormattedMessage id="filtersQuestion.header">{msg => {
                return <span className="float-left">{msg} ({Object.keys(filtersQuestion).length})</span>
              }}</FormattedMessage>
              <Badge className="float-right ml-2" onClick={() => analysisPlan.filtering ? this.setState({filter: 0}) : this.props.setPlanRestrictionsModals(AnalysisFilteringAvailable.getName())}><i className="fa fa-plus" /></Badge>
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            {!editing
              ? <FiltersQuestionList
                onEdit={(id) => this.setState({filter: id})}
                onDeleted={() => this.props.onDeleted()}
              />
              :
              <FiltersQuestionFilter
                analysis={analysis}
                questions={questions}
                onSaved={() => this.props.onSaved()}
                onClosed={() => this.setState({filter: false})}
                filter={filter > 0 ? filtersQuestion[filter] : {}}
              />
            }
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

FiltersQuestion.propTypes = {
  onDeleted: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.analysis.data.sets, set => {
    _.each(set.blockPages, blockId => {
      _.each(state.survey.analysis.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.analysis.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.analysis.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    analysis: state.survey.analysis.data.analysis,
    questions: questions,
    filtersQuestion: state.survey.analysis.data.filtersQuestion,
    analysisPlan: state.user.userPlan.plan.analysis
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(FiltersQuestion);