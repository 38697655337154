import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "components/PlanRestrictionsModals/modals/MultimediaAvailable";


class Logo extends Component{
  constructor(props) {
    super(props);
    this.state = {
      image: props.image,
      isModalOpen: false,
      saving: false,
    };

    this.toggleModalRemove = this.toggleModalRemove.bind(this)
  }

  onChange(fileUrl) {
    this.props.onChange({
      image: fileUrl
    });

    this.setState({
      isModalOpen: false,
      image: fileUrl,
    });
  }

  toggleModalRemove() {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  renderLogo(image) {
    let onClickFunction = (event) => {
      event.preventDefault();
      if (!this.props.plan.multimedia.access) {
        this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
        return;
      }
      CKFinder.popup({
        connectorInfo: 'auth=' + localStorage.jwt,
        chooseFiles: true,
        width: 1014,
        height: 700,
        onInit: (finder) => {
          finder.on('files:choose', (e) => {
            this.onChange(e.data.files.first().getUrl());
          });
        }
      });
    };

    let input;
    if (!image) {
      input = <div className="custom-file">
        <input type="file" className="custom-file-input" id="customFile" onClick={onClickFunction} />
          <label className="custom-file-label" htmlFor="customFile" style={{zIndex: 0}}>Wybierz logo</label>
      </div>;
    } else {
      input =
        <div>
          <img
            alt=""
            src={image}
            style={{
              maxHeight: '100px',
              maxWidth: '100px',
            }}
            onClick={onClickFunction}
          />
          {this.props.plan.multimedia.access && <span onClick={() => this.toggleModalRemove()}>
            <i className="far fa-times-circle"/>
          </span>}
        </div>;
    }
    return input;
  }

  render() {
    const {image, saving, isModalOpen} = this.state;

    const modal =
      <Modal
        isOpen={isModalOpen}
        size="lg"
        toggle={() => this.toggleModalRemove()}
      >
        <ModalHeader toggle={() => this.toggleModalRemove()} tag="h2">Potwierdź usunięcie logo z ankiety</ModalHeader>
        <hr className="my-0" />
      <ModalBody>
          Czy na pewno chcesz usunąć logo z tej ankiety?
        </ModalBody>
        <ModalFooter>
          <LaddaButton
            className="btn btn-primary btn-ladda mr-2"
            onClick={() => this.toggleModalRemove()}
          >
            <FormattedMessage id='_.button.cancel'/>
          </LaddaButton>
          <LaddaButton
            className="btn btn-danger btn-ladda mr-2"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={() => this.onChange('')}
          >
            <FormattedMessage id='_.button.delete'/>
          </LaddaButton>
        </ModalFooter>
      </Modal>
    ;


    return (
         <React.Fragment>
           {this.renderLogo(image)}
           {modal}
         </React.Fragment>
    );
  }
}

Logo.propTypes = {
  image: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    image: state.survey.structure.data.survey.logo ? state.survey.structure.data.survey.logo.image : '',
    plan: state.user.userPlan.plan
  };
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(Logo);
