import React, {Component} from 'react';
import SurveyResponsesList from "./components/SurveyResponsesList";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Alert} from "reactstrap";
import appRoutes from "../../../../../../appRoutes";
import './style.scss'

class Modify extends Component {
  render() {
    const {plan} = this.props;
    const {survey} = this.props.match.params;

    return (
      <div className="m-3 h-100">
        {!plan.analysis.responseModify ? <Alert color="warning" className="text-center">
          <span>Edycja zbiorcza wywiadów nie jest dostępna w Twoim planie. <Link to={appRoutes.users.plan} style={{fontSize: '16px'}}>Kliknij, aby zmienić plan</Link></span>
        </Alert> : <SurveyResponsesList surveyId={parseInt(survey)} />}
      </div>
    );
  }
}

Modify.propTypes = {};


function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps)(Modify);