import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Col, Row} from "reactstrap";
import VideoButton from "./components/VideoButton";
import PreviewButton from "components/Buttons/PreviewVideoButton";

class Video extends Component {

  render() {

    return (
      <Row className="mt-3">
        <Col>
          <VideoButton question={this.props.question}/>
          <PreviewButton question={this.props.question} className="ml-3"/>
        </Col>
      </Row>
    )
  }
}

Video.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    video: PropTypes.string,
  }).isRequired
};

export default Video;