import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import api from "api";
import {injectIntl} from "react-intl";

class CollectorsSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      value: props.value,
      collectors: [],
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const {id} = this.props.survey;

    this.setState({
      loading: true,
    });

    api.survey.get.collectors({id})
      .then(collectors => {
        this.setState({
          loading: false,
          collectors,
        });
      })
      .catch(res => {
        this.setState({
          loading: false,
        });
      })
  }

  onChange(e, a){
    let value = e.map(v => v.value);

    this.setState({
      value
    });

    this.props.onChange(value);
  }

  render() {
    const {value, collectors} = this.state;
    const {excludedTypes} = this.props;

    let options = _.map(collectors, collector => {
      if(excludedTypes.indexOf(collector.type) >= 0){
        return;
      }

      return {
        label: collector.name || this.props.intl.formatMessage({ id: "_.surveyCollector." + collector.type + '.name' }) + ' ' + collector.position,
        value: collector.id,
      };
    }).filter(val => val);

    return (
      <Select
        isMulti
        isLoading={this.props.isLoading || this.state.loading}
        isDisabled={this.props.isDisabled}
        placeholder={this.props.placeholder}
        value={!_.isEmpty(collectors) && value ? value.map(collectorId => {
          const collector = collectors.find(collector => collector.id === collectorId);
          return {
            label: collector.name || this.props.intl.formatMessage({ id: "_.surveyCollector." + collector.type + '.name' }) + ' ' + collector.position,
            value: collectorId,
          }
        }) : false}
        options={options}
        onChange={this.onChange}
      />
    );
  }
}

CollectorsSelect.defaultProps = {
  isLoading: false,
  isDisabled: false,
  excludedTypes: [],
  placeholder: 'Wybierz kolektory'
};

CollectorsSelect.propTypes = {
  placeholder: PropTypes.string,
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  excludedTypes: PropTypes.array,
};

export default injectIntl(CollectorsSelect);