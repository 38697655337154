import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../../componentsReusable/Button";
import api from "../../../../../api";
import {quotaNewDeleted} from "../../../Structure/data/quotaNews/actions";
import {connect} from "react-redux";

const DeleteSelectedModal = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [saving, setSaving] = React.useState(false);

  const deleteSelected = () => {
    setSaving(true)

    api.quotaNew.remove(props.selected.map(quotaNew => quotaNew.id))
      .then(() => {
        props.selected.map(quotaNew => props.dispatch(quotaNewDeleted(quotaNew)))
      })
      .finally(() => {
        props.onDeleted()
        setSaving(false)
      })
  }

  return <React.Fragment>
    <Button color={'danger'} className="mr-2" onClick={toggleModalOpen}>Usuń zaznaczone</Button>
    {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
      <ModalHeader toggle={toggleModalOpen}>Usuń kwoty</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <span>Zaznaczone kwoty zostaną usunięte</span>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
        <Button
          color="danger"
          loading={saving}
          onClick={deleteSelected}
        >Usuń zaznaczone</Button>
      </ModalFooter>
    </Modal>
    }
  </React.Fragment>;
};

DeleteSelectedModal.propTypes = {
  selected: PropTypes.array.isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default connect()(DeleteSelectedModal);
