import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input
} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import striptags from "striptags";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "components/PlanRestrictionsModals/modals/MultimediaAvailable";

import {addAnswers} from "scenes/Survey/Structure/data/questions/actions";

class ActionAdd extends Component {

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      modal: this.props.isOpen,
      saving: false,
    };

    this.onChangeText = this.onChangeText.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    }, () => {
      if(this.props.onClose){
        this.props.onClose();
      }
    });
  }

  onChangeText(text){
    this.setState({
      text
    })
  }

  saveAnswers(){
    const {text, saving} = this.state;
    const {question} = this.props;

    if(_.isEmpty(text) || saving){
      return;
    }

    let answers = [];

    text.split("\n").map(t => {
      if(t.trim()) {
        answers.push({
          content: t
        });
      }
    });

    this.setState({
      saving: true
    });
    this.props.addAnswers(question.id, answers).then(() => {
      this.setState({
        text: '',
        saving: false,
        modal: false,
      }, () => {
        if(this.props.onClose){
          this.props.onClose();
        }
      });
    });
  }

  render() {
    const {question, plan} = this.props;
    const {text, saving} = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">
      <ModalHeader toggle={this.toggleModal} tag="h2" className="overflow-hidden">
        <div className="d-flex flex-nowrap text-nowrap">
          <FormattedMessage id="answers.actions.add.cardHeader"/>: <div className="ml-2 text-ellipsis" dangerouslySetInnerHTML={{__html: striptags(this.props.question.content)}} />
        </div>
      </ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="answers.actions.add.cardBody"/></p>
        <Form>
          <FormGroup>
            <Input
              type="textarea"
              disabled={saving}
              rows={10}
              onChange={(e) => this.onChangeText(e.target.value)}
              value={text}
              className="custom"
            />
          </FormGroup>
        </Form>

        {question.answersImage && <Button
          onClick={() => {
            if (!plan.multimedia.access) {
              this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
              return;
            }
            CKFinder.popup({
              connectorInfo: 'auth=' + localStorage.jwt,
              chooseFiles: true,
              width: 1014,
              height: 700,
              onInit: finder => {
                finder.on('files:choose', e => {

                  let content = [];
                  e.data.files.forEach( function( file ) {
                    content.push(file.get( 'url' ));
                  } );
                  this.onChangeText(content.join("\n"));
                  this.saveAnswers();
                });
              }
            })
          }}
        >Wybierz zdjęcia z biblioteki</Button>}
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="answers.actions.add.button.confirmation">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={() => this.saveAnswers()}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button onClick={this.toggleModal}><FormattedMessage id="answers.actions.add.button.add"/></Button>
        {this.state.modal && modal}
      </React.Fragment>
    )
  }
}

ActionAdd.defaultProps = {
  isOpen: false,
};

ActionAdd.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answersImage: PropTypes.bool,
  }).isRequired
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {addAnswers, setPlanRestrictionsModals})(ActionAdd);