import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button, Form, Label, Input, Col, Row} from "reactstrap";
import {saveAddresses} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import PropTypes from "prop-types";
import _ from 'lodash';
import {toast} from "react-toastify";

class AddressesCreateModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      data: [],
      text: '',
      addressesResponse: false,
    };

    this.onChange = this.onChange.bind(this);
    this.saveAddresses = this.saveAddresses.bind(this);
  }

  onChange(e) {
    let addresses = e.target.value.split(/\n{2,}/).filter((text) => text.trim() !== "");
    let data = [];

    _.each(addresses, item => {
      let arr = item.split("\n").filter((text) => text.trim() !== "");
      _.each(arr, text => data.push(text));
    });
    this.setState({ data: data, text: e.target.value });
  }

  saveAddresses(e) {
    e.preventDefault();

    this.setState({...this.state, creating: true});

    this.props.saveAddresses({
      respondentBaseId: this.props.respondentBaseId,
      data: {
        addresses: this.state.data
      }
    })
    .then((addresses) => {
      let state = {
        creating: false,
        addressesResponse: addresses,
      };

      this.setState(state);
    })
    .catch((e) => {
      e.response.data.form && toast.error(e.response.data.form.errors.email[0]);
      this.setState({
        ...this.state,
        creating: false
      })
    })
  }

  render() {
    const { creating, addressesResponse } = this.state;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle} tag="h2"><FormattedMessage id="addressesCreateModal.modalHeader" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <Label for="addresses"><FormattedMessage id="addressesCreateModal.modalBody" /></Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input type="textarea" name="addresses" id="addresses" rows={10} value={this.state.text} onChange={this.onChange}/>
              </Col>
            </Row>
          </Form>

          {addressesResponse !== false && <Row>
            <Col>
              {addressesResponse.added.length > 0
                ? <div className="alert alert-success text-center">Adresy dodane: {addressesResponse.added.length}</div>
                : <div className="alert alert-warning text-center">Nie dodano żadnego nowego adresu</div>
              }
              {addressesResponse.rejected.length > 0 && <div className="alert alert-danger text-center">
                <span className="font-weight-bold">Adresy, które nie zostały dodane: {addressesResponse.rejected.length}</span>
                <ul>
                  {addressesResponse.rejected.map(address => {
                    return <li>{address.email}</li>
                  })}
                </ul>
              </div>}
            </Col>
          </Row>}
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.add">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={creating}
                  data-style={ZOOM_OUT}
                  onClick={this.saveAddresses}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) =>
                  <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggle}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

AddressesCreateModal.propTypes = {
  respondentBaseId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default connect(null, { saveAddresses })(AddressesCreateModal);