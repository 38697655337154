import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import _ from "lodash";
import {ReactSortable} from "react-sortablejs";
import classnames from "classnames";
import {Row, Col, Alert} from "reactstrap";
import ButtonCreateSet from './components/ButtonCreateSet';
import Set from "./components/Set";
import {setSets} from "scenes/Survey/Structure/data/survey/actions";
import {updateSet} from "scenes/Survey/Structure/data/sets/actions";
import {filterSets} from "services/surveyStructureFilter";
import {addPage} from "../../data/pages/actions";
import KeyboardQuestionAddCafetery from "../KeyboardQuestionAddCafetery";
import KeyboardFilter from "../KeyboardFilter";
import Structure from "../Structure";
import KeyboardReplaceFunction from "../KeyboardReplaceFunction";
import Filters from "../../../components/Settings/Filters";

class Sets extends Component {

  constructor(props) {
    super(props);

    this.onEnd = this.onEnd.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind("shift+p", () => {
      if(this.props.lastBlock){
        this.props.addPage({
          block: this.props.lastBlock.id,
          page: {
            position: this.props.lastBlock.pages.length
          }
        }).then(page => {
          document.querySelector('#structure #page-' + page.id).scrollIntoView()
          document.querySelector('#structure').scrollTop -= 70;
        });
      }
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+p");
  }

  onEnd(evt){
    if(evt.to === evt.from && evt.oldIndex === evt.newIndex){
      return false;
    }

    const {survey} = this.props;
    let list = survey.setBlocks;

    const sId = parseInt(evt.item.attributes['data-id'].nodeValue);

    list.splice(evt.oldIndex, 1);
    list.splice(evt.newIndex, 0, sId);
    this.props.setSets(list);

    this.props.updateSet({
      id: sId,
      set: {
        position: evt.newIndex
      }
    });
  }

  render() {
    const {filteredSets, survey, allSets, hideInStructure, surveyPlan} = this.props;

    let setsList = [];

    if(Object.keys(allSets).length > 0){
      _.map(allSets, (set, k) => setsList.push(
        <div data-id={set.id} key={set.id} className={classnames({
          "card-wrap": true,
          "d-none": !filteredSets.includes(set),
          "direction-group": true,
        })}>
          <Set set={set} />
          {/*{hideInStructure !== 'structure' && <ButtonCreateSet position={set.position + 1} survey={survey} />}*/}
        </div>)
      )
    }

    return <React.Fragment>
      {setsList.length > 1 && <div className="accordion custom">
        <Structure />
      </div>}
      <ReactSortable
        list={setsList.length > 0 ? setsList : [{}]}
        setList={() => {}}
        group={{
          name: 'sets',
          put: ['sets'],
        }}
        handle=".handler"
        animation={100}
        onEnd={this.onEnd}
        className="accordion custom"
        data-id={survey.id}
        id="sortable-survey"
      >
        {setsList.length > 0 ? setsList
          :
          hideInStructure !== 'structure' && <Row className="animated fadeIn">
            <Col>
              <Alert className="text-center"><FormattedMessage id="structure.sets.alert.noSets"/></Alert>
              <ButtonCreateSet position={0} survey={survey} />
            </Col>
          </Row>
        }
      </ReactSortable>

      <KeyboardQuestionAddCafetery />
      <KeyboardFilter />
      <KeyboardReplaceFunction />

      <div className="mt-5 d-none d-print-block">
        <Filters
          selectable={false}
          defaultPageSize={1000}
          hiddenColumns={['actions']}
        />
      </div>
    </React.Fragment>
  }
}

function mapStateToProps(state) {
  let positionInSurvey = 0;

  let sets = [];
  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    let set = state.survey.structure.data.sets[setId];

    set.positionInSurvey = positionInSurvey;
    sets.push(set);

    positionInSurvey++;
  });


  let setsFiltered = filterSets(sets, state.survey.structure);

  let lastBlock = null;
  if (state.survey.structure.data.survey.setBlocks) {
    const lastSetId = state.survey.structure.data.survey.setBlocks[state.survey.structure.data.survey.setBlocks.length - 1];
    if (lastSetId) {
      const lastBlockId = state.survey.structure.data.sets[lastSetId].blockPages[state.survey.structure.data.sets[lastSetId].blockPages.length - 1];
      if (lastBlockId) {
        lastBlock = state.survey.structure.data.blocks[lastBlockId];
      }
    }
  }

  return {
    survey: state.survey.structure.data.survey,
    filteredSets: setsFiltered,
    allSets: sets,
    hideInStructure: state.survey.structure.hideInStructure,
    surveyPlan: state.user.userPlan.plan.survey,
    lastBlock,
  }
}

export default connect(mapStateToProps, {setSets, updateSet, addPage})(Sets);
