import update from "immutability-helper";

import {
ADDED,
DELETED,
SET,
} from './actions';

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case ADDED:
      return {
        ...state,
        [action.filterQuestion.id]: action.filterQuestion
      };

    case DELETED:
      return update(state, {$unset: [action.id] });

    case SET:
      return action.filtersQuestion;



    default:
      return state;
  }
};