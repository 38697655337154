import React from 'react';
import {Button} from "reactstrap";
import {connect} from "react-redux";

const PlanIndividual = (props) => {
  return (
    <div className="row mt-3">
      <div className="col-12 col-md-9 offset-md-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-8 col-12">
                <h3>Indywidualny</h3>
                <p className="mb-0">Skonfigurujemy plan specjalnie dla Ciebie</p>
              </div>
              <div className="col-md-4 col-12 text-right h-100 align-content-center">
                <a href={props.config.routes.ankieteo.contact} className="btn btn-primary mb-0">Zapytaj o cenę</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    config: state.config.data,
  }
}

export default connect(mapStateToProps)(PlanIndividual);