import React, {Component} from 'react';
import PropTypes from "prop-types";
import RandAnswers from "../../../RandAnswers";
import AnswersColumns from "../../../AnswersColumns";
import SettingBool from "../../../SettingBool";
import AnswersMinMax from "../../../AnswersMinMax";
import PresentationType from "../../../PresentationType";
import AnswersImage from "../../../AnswersImage";
import FetchCafeteryPositions from "../../../FetchCafeteryPositions";
import {connect} from "react-redux";
import _ from "lodash";
import OpenRexep from "../../../OpenRexep";

class MultiSettings extends Component {

  render() {
    const {question, questionSettingsPlan, answers} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'required'} value={question.required} icon={<i className="fa-solid fa-message-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'rotateAnswers'} value={question.rotateAnswers} disabled={!questionSettingsPlan.multi.rotateAnswers} icon={<i className="fas fa-random" />} handleUpdate={this.props.handleUpdate}/>
        <AnswersImage question={question} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'answerOpenAlwaysShow'} value={question.answerOpenAlwaysShow} icon={<i className="fa-solid fa-list-check"/>} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'reverseAnswers'} value={question.reverseAnswers} icon={<i className="fa-solid fa-retweet" />} handleUpdate={this.props.handleUpdate}/>
        <AnswersMinMax question={question} disabled={!questionSettingsPlan.minMaxCafetery} handleUpdate={this.props.handleUpdate}/>
        <PresentationType question={question} handleUpdate={this.props.handleUpdate}/>
        <AnswersColumns question={question}/>
        <RandAnswers question={question} disabled={!questionSettingsPlan.randCafetery} handleUpdate={this.props.handleUpdate}/>
        <FetchCafeteryPositions question={question} handleUpdate={this.props.handleUpdate}/>
        {(questionSettingsPlan.openRegex && answers.filter(answer => answer.isOpen).length > 0) && <OpenRexep question={question} handleUpdate={this.props.handleUpdate} />}
        {questionSettingsPlan.cafeterySupplementMax && <SettingBool question={question} name={'cafeterySelectedMax'} disabled={!questionSettingsPlan.cafeterySupplementMax} value={question.cafeterySelectedMax} icon={<i className="fak fa-comments-check-max" />} handleUpdate={this.props.handleUpdate}/>}
      </React.Fragment>
    )
  }
}

MultiSettings.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state, props) {
  return {
    questionSettingsPlan: state.user.userPlan.plan.survey.questionSettings,
    answers: _.map(props.question.answers, id => state.survey.structure.data.answers[id]),
  }
}

export default connect(mapStateToProps)(MultiSettings);
