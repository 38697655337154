import React, {Component} from 'react';
import PropTypes from "prop-types";
import AsyncSelect from 'react-select/lib/Async';
import api from "api";
import {isNull} from "lodash";

class DictionarySelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchTimeout: false,
      modalAdd: false,
      loading: false,
      search: '',
      options: [],
      value: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setDictionary = this.setDictionary.bind(this);
  }

  componentDidMount() {
    if(this.props.dictionary){
      this.setDictionary(this.props.dictionary);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.dictionary && this.props.dictionary !== prevProps.dictionary){
      this.setDictionary(this.props.dictionary);
    }
  }

  setDictionary(dictionaryId){
    api.dictionaries.get(dictionaryId)
    .then(data => {
      this.setState({
        value: {
          value: this.props.dictionary,
          label: data.data.name
        }
      })
    });
  }

  fetchData(search, callback){
    clearTimeout(this.state.searchTimeout);
    this.setState({
      searchTimeout: setTimeout(() => {
        api.dictionaries.search({
          page: 1,
          limit: 50,
          search,
        }, {
          sortField: 'd.id',
          sortOrder: 'desc',
        })
        .then(res => {
          let options = res.data.map(dictionary => {
            return {
              value: dictionary.id,
              label: dictionary.name,
            }
          });

          this.setState({
            data: res.data,
          }, () => {
            callback(options);
          });
        })
      }, 450)
    })
  }

  handleInputChange(search){
    this.setState({ search });
    return search;
  }

  render() {
    const {value, data} = this.state;

    return <React.Fragment>
      <AsyncSelect
        className={this.props.className}
        isClearable={true}
        placeholder={this.props.placeholder}
        loadingMessage={() => <span>Trwa wyszukiwanie <i className="fas fa-spin fa-spinner ml-2" /></span>}
        noOptionsMessage={() => <span><span className="ml-2">Nie znaleziono</span></span>}
        loadOptions={this.fetchData}
        onInputChange={this.handleInputChange}
        value={value}
        defaultOptions
        onChange={option => {
          if(isNull(option)){
            this.setState({
              value: null
            });

            this.props.onChange(null);
          }else{
            const dictionary = data.find(c => c.id === option.value);

            if(dictionary){
              this.setState({
                value: {
                  value: dictionary.id,
                  label: dictionary.name,
                }
              });

              this.props.onChange(dictionary);
            }else{
              this.setState({
                value: {
                  value: option.value,
                  label: <i className="text-info">{option.value}</i>,
                }
              });

              this.props.onChange(option.value);
            }
          }
        }}
      />
    </React.Fragment>
  }
}

DictionarySelect.defaultProps = {
  className: '',
  placeholder: 'Wyszukaj słownik...',
};

DictionarySelect.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  dictionary: PropTypes.number,
  className: PropTypes.string,
};

export default DictionarySelect;
