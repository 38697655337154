import React, {Component} from 'react';
import PropTypes from "prop-types";
import SettingBool from "../../../SettingBool";
import SettingText from "../../../SettingText";
import AnswersImage from "../../../AnswersImage";

class MaxDiffSettings extends Component {

  render() {
    const {question} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'required'} value={question.required} icon={<i className="fa-solid fa-message-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <AnswersImage question={question} handleUpdate={this.props.handleUpdate}/>
        <SettingText question={question} name={'leftText'} value={question.leftText || ''} icon={<i className="fa-solid fa-arrow-left" />} handleUpdate={this.props.handleUpdate}/>
        <SettingText question={question} name={'rightText'} value={question.rightText || ''} icon={<i className="fa-solid fa-arrow-right" />} handleUpdate={this.props.handleUpdate}/>
      </React.Fragment>
    )
  }
}

MaxDiffSettings.propTypes = {
  question: PropTypes.shape({
    required: PropTypes.bool.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default MaxDiffSettings;
