import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import _ from "lodash";
import {ReactSortable} from "react-sortablejs";
import {Row, Col, Alert} from "reactstrap";
import Page from "./components/Page";
import LoadingSpinner from "components/LoadingSpinner";
import {setPages} from "scenes/Survey/Structure/data/blocks/actions";
import {updatePage} from "scenes/Survey/Structure/data/pages/actions";
import ButtonCreatePage from "./components/ButtonCreatePage";
import "./style.scss"
import {filterPages} from "services/surveyStructureFilter";
import classnames from "classnames";

class Pages extends Component {

  constructor(props) {
    super(props);

    this.onEnd = this.onEnd.bind(this);
  }

  onEnd(evt){
    if(evt.to === evt.from && evt.oldIndex === evt.newIndex){
      return false;
    }

    if(evt.to === evt.from){
      const {block} = this.props;
      let list = block.pages;

      const pId = parseInt(evt.item.attributes['data-id'].nodeValue);

      list.splice(evt.oldIndex, 1);
      list.splice(evt.newIndex, 0, pId);
      this.props.setPages(block.id, list);

      this.props.updatePage({
        id: pId,
        page: {
          position: evt.newIndex
        }
      });
    }else{
      const pId = parseInt(evt.item.attributes['data-id'].nodeValue);
      const oldBlock = this.props.blocks[parseInt(evt.from.parentElement.attributes['data-id'].nodeValue)];
      const newBlock = this.props.blocks[parseInt(evt.to.parentElement.attributes['data-id'].nodeValue)];


      //add pId to new block
      let newList = newBlock.pages;
      newList.splice(evt.newIndex, 0, pId);
      this.props.setPages(newBlock.id, newList);

      //remove pId from old block
      evt.from.appendChild(evt.item);
      const oldList = oldBlock.pages.filter(id => id !== pId);
      this.props.setPages(oldBlock.id, oldList);

      //update block and position in page
      this.props.updatePage({
        id: pId,
        page: {
          blockPages: newBlock.id,
          position: evt.newIndex
        }
      });
    }
  }

  render() {
    const {filteredPages, loading, block, allPages, hideInStructure, surveyPlan} = this.props;

    if(loading){
      return <LoadingSpinner />
    }

    let pagesList = [];

    if(Object.keys(allPages).length > 0){
      _.map(allPages, (page) => pagesList.push(
        <div data-id={page.id} key={page.id} className={classnames({
          "card-wrap": true,
          "d-none": !filteredPages.includes(page),
          "direction-group": true,
        })}>
          <Page page={page} positionInSurvey={page.positionInSurvey} />
          {/*{hideInStructure !== 'structure' && <ButtonCreatePage position={page.position + 1} block={block} />}*/}
        </div>)
      )
    }

    return (
        <ReactSortable
          list={pagesList.length > 0 ? pagesList : [{}]}
          setList={() => {}}
          group={{
            name: 'pages',
            put: ['pages']
          }}
          handle=".handler"
          animation={100}
          onEnd={this.onEnd}
          className="child-wrap"
          data-id={block.id}
          id="sortable-block"
        >
          {pagesList.length > 0 ? pagesList
            :
            hideInStructure !== 'structure' && <Row className="animated fadeIn">
              <Col>
                <Alert className="text-center" color="warning"><FormattedMessage id="block.pages.alert.noPages"/></Alert>
                <ButtonCreatePage position={0} block={block} />
              </Col>
            </Row>
          }
        </ReactSortable>
    )
  }
}

function mapStateToProps(state, props) {
  let positionInSurvey = 0;

  let pages = [];
  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    let set = state.survey.structure.data.sets[setId];

    _.each(set.blockPages, blockId => {
      let block = state.survey.structure.data.blocks[blockId];

        _.each(block.pages, pageId => {
          let page = state.survey.structure.data.pages[pageId];

          if (blockId === props.block.id) {
            page.positionInSurvey = positionInSurvey;
            pages.push(page);
          }

          positionInSurvey++;
        });
    });
  });



  let pagesFiltered = filterPages(pages, state.survey.structure);

  return {
    loading: state.survey.structure.loading,
    block: state.survey.structure.data.blocks[props.block.id],
    blocks: state.survey.structure.data.blocks,
    filteredPages: pagesFiltered,
    allPages: pages,
    hideInStructure: state.survey.structure.hideInStructure,
    surveyPlan: state.user.userPlan.plan.survey
  }
}

export default connect(mapStateToProps, {setPages, updatePage})(Pages);