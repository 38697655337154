import * as React from 'react';
import {Badge, Card, CardBody, Col, Row, Tooltip} from "reactstrap";
import {connect} from "react-redux";
import LastSeenSurveys from "./lastSeenSurveys";

function LastSeenSurveysRoute(props) {
	return (
		<Card className="w-100" id={"last-seen-surveys"}>
			<CardBody>
				<h1 class={"color-text"}>Ostatnio przeglądane projekty</h1>
				<LastSeenSurveys filters={{visited: true}} />
			</CardBody>
		</Card>
	);
}

LastSeenSurveysRoute.propTypes = {};

function mapStateToProps(state) {
}

export default connect(mapStateToProps, {})(LastSeenSurveysRoute);