import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../../components/Button";
import FilterFromTextareaModal from "./FilterFromTextareaModal";
import _ from "lodash";
import {connect} from "react-redux";

FiltersEditTextarea.propTypes = {
  filters: PropTypes.array.isRequired,
  btn: PropTypes.bool,
  onCancel: PropTypes.func,
};

FiltersEditTextarea.defaultProps = {
  btn: true,
};

function FiltersEditTextarea(props) {
  const [filtersText, setFiltersText] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    if(!props.btn){
      edit();
    }
  }, []);

  const edit = () => {
    setFiltersText(parseFiltersToText());

    setModalOpen(true);
  }

  const parseFiltersToText = () => {
    return props.filters.map(filter => {
      console.log('filter', filter);
      try {
        switch (filter.type) {
          case 'conditional': {
            return parseFilterConditional(filter);
          }
          case 'serial': {
            return parseFilterSerial(filter);
          }
          case 'iterative': {
            return parseFilterIterative(filter);
          }
        }
      }catch(e){
        console.log('error', e);
        return false;
      }
    }).filter(f => f).join("\n");
  }

  const getQuestionPositionInSurvey = (qId) => {
    const q = props.questions.find(q => q.id == qId);
    if(q){
      return q.positionInSurvey;
    }

    return false;
  }

  const getQuestionCafeteryPosition = (question, cafeteryType, cId) => {
    let p = question[cafeteryType].indexOf(cId)

    return p >= 0 ? p+1 : false;
  }

  const parseFilterConditional = (filter) => {
    let filterText = [];
    filterText.push(`[${filter.id}]${filter.name || ''}`);

    filterText.push(parseFilterConditional_conds(filter));
    filterText.push(parseFilterConditional_actions(filter));

    return filterText.join(';');
  }

  const parseFilterConditional_conds = (filter) => {
    if(filter.blocks[0].children.length > 0){
      throw new Error('filtr zawiera kilka bloków');
    }

    const block = filter.blocks[0];
    let settings = block.type == 'OR' ? '^' : '';
    if(block.minCondsToMet > 1 || block.maxCondsToMet > 0){
      settings += `${block.minCondsToMet}-${block.maxCondsToMet} `;
    }

    const conds = filter.blocks[0].condsQuestion.map(cond => {
      if(cond.required){
        throw new Error('filtr zawiera warunek obowiązkowy');
      }
      if(cond.detailType && ['selected', 'notSelected'].indexOf(cond.detailType) < 0){
        throw new Error('filtr zawiera detailType = ' + cond.detailType);
      }

      switch (cond.check) {
        case 'detail': {
          if(cond.condType == 'minMax'){
            throw new Error('filtr zawiera warunek minMax');
          }
          let and = cond.condType == 'and' ? '&' : '';
          let selected = cond.detailType == 'notSelected' ? '!' : '';

          const q = props.questions.find(q => q.id == cond.question);
          let cafetery = '';
          if(cond.answers && cond.answers.length > 0){
            cafetery = cond.answers.map(a => getQuestionCafeteryPosition(q, 'answers', a.answer)).join(',');
          }else if(cond.details && cond.details.length > 0){
            cafetery = cond.details.map(detail => getQuestionCafeteryPosition(q, 'rows', detail.row)+'-'+getQuestionCafeteryPosition(q, 'columns', detail.column)).join(',');
          }

          if(q){
            return `Q${q.positionInSurvey}${and}${selected}=${cafetery}`
          }else{
            return false;
          }

          break;
        }
        case 'visibility': {
          let selected = !cond.visibility ? '!' : '';
          const q = props.questions.find(q => q.id == cond.question);

          if(q){
            return `Q${q.positionInSurvey}${selected}`
          }else{
            return false;
          }

          break;
        }
        case 'excluding': {
          const q = props.questions.find(q => q.id == cond.question);

          if(q){
            let cafetery = cond.excluding.excludings.map(eId => getQuestionCafeteryPosition(q, 'excludings', eId)).join(',');

            return `Q${q.positionInSurvey}E=${cafetery}`
          }else{
            return false;
          }

          break;
        }

      }

      throw new Error('filtrnie został edytowany');
    }).filter(f => f).join('|');

    return `${settings}${conds}`;
  }

  const parseFilterConditional_actions = (filter) => {
    return filter.actions.map(action => {

      switch (action.actionType) {
        case 'actionJump': {
          switch(action.type){
            case 'question': {
              const q = props.questions.find(q => q.id == action.question);
              return q ? `JUMP=Q${q.positionInSurvey}` : false;
            }
            case 'page': {
              const p = props.pages.find(p => p.id == action.page);
              return p ? `JUMP=P${p.positionInSurvey}` : false;
            }
            case 'block': {
              const b = props.blocks.find(b => b.id == action.block);
              return b ? `JUMP=B${b.positionInSurvey}` : false;
            }
            case 'set': {
              const s = props.sets.find(s => s.id == action.set);
              return s ? `JUMP=S${s.positionInSurvey}` : false;
            }
            case 'surveyEnd': {
              return action.status == 4 ? `SO` : 'END';
            }
            default: {
              throw new Error('Nie obsługiwany typ akcji');
            }
          }
          break;
        }
        case 'actionVisibility': {
          let show = action.visibility ? '' : '!';
          switch (action.type) {
            case 'questions': {
              let questions = action.questions.map(qId => props.questions.find(q => q.id == qId).positionInSurvey).join(',');
              return `Q${show}=${questions}`;
            }
            case 'pages': {
              let pages = action.pages.map(id => props.pages.find(p => p.id == id).positionInSurvey).join(',');
              return `P${show}=${pages}`;
            }
            case 'blocks': {
              let blocks = action.blocks.map(id => props.blocks.find(b => b.id == id).positionInSurvey).join(',');
              return `B${show}=${blocks}`;
            }
            case 'sets': {
              let sets = action.sets.map(id => props.sets.find(s => s.id == id).positionInSurvey).join(',');
              return `S${show}=${sets}`;
            }
            case 'questionAnswers': {
              let q = props.questions.find(q => q.id == action.question);
              let cafetery = action.answers.map(aId => getQuestionCafeteryPosition(q, 'answers', aId)).join(',');

              return `Q${q.positionInSurvey}${show}=${cafetery}`;
            }
            case 'questionRows': {
              let q = props.questions.find(q => q.id == action.question);
              let cafetery = action.rows.map(id => getQuestionCafeteryPosition(q, 'rows', id)).join(',');

              return `Q${q.positionInSurvey}${show}R=${cafetery}`;
            }
            case 'questionColumns': {
              let q = props.questions.find(q => q.id == action.question);
              let cafetery = action.columns.map(id => getQuestionCafeteryPosition(q, 'columns', id)).join(',');

              return `Q${q.positionInSurvey}${show}C=${cafetery}`;
            }
            default: {
              throw new Error('Nie obsługiwany typ akcji');
            }
          }
          break;
        }
        default: {
          throw new Error('Nie obsługiwany typ akcji');
        }
      }

      return false;
    }).filter(a => a).join('|');
  }

  const parseFilterSerial = (filter) => {
    let filterText = [];
    filterText.push(`[${filter.id}]{S}${filter.name || ''}`);

    let questionFrom = '';
    if(filter.questionFrom.question){
      const q = props.questions.find(q => q.id == filter.questionFrom.question);
      if(q){
        let hideQuestionToCondsMin = filter.hideQuestionToCondsMin > 0 ? `{${filter.hideQuestionToCondsMin}}` : '';
        let filterBy = '';
        if(filter.questionFrom.filterBy === 'rows'){
          filterBy = 'R';
        }else if(filter.questionFrom.filterBy === 'columns'){
          filterBy = 'C';
        }

        let cafetery = filter.questionFrom[filter.questionFrom.filterBy].map(cId => getQuestionCafeteryPosition(q, filter.questionFrom.filterBy, cId)).filter(c => c).join(',');

        questionFrom = `${hideQuestionToCondsMin}Q${q.positionInSurvey}${filterBy}=${cafetery}`;
      }
    }
    filterText.push(questionFrom);

    let questionTo = '';
    if(filter.questionTo.question){
      const q = props.questions.find(q => q.id == filter.questionTo.question);
      if(q){
        let filterBy = '';
        let action = filter.action === 2 ? '!' : '';

        if(filter.questionTo.filterBy === 'rows'){
          filterBy = 'R';
        }else if(filter.questionTo.filterBy === 'columns'){
          filterBy = 'C';
        }

        questionTo = `${action}Q${q.positionInSurvey}${filterBy}`;
      }
    }
    filterText.push(questionTo);

    return filterText.join(';');
  }

  const parseFilterIterative = (filter) => {
    let filterText = [];
    filterText.push(`[${filter.id}]{I}${filter.name || ''}`);

    if(filter.questionFrom.question){
      const qPosition = getQuestionPositionInSurvey(filter.questionFrom.question);
      if(qPosition !== false){
        filterText.push(`Q${qPosition}`)
      }
    }

    filterText.push(filter.questionsTo.map(questionTo => {
      const qPosition = getQuestionPositionInSurvey(questionTo.question);
      if(qPosition === false){
        return false;
      }
      let filterBy = '';
      if(questionTo.filterBy === 'rows'){
        filterBy = 'R';
      }else if(questionTo.filterBy === 'columns'){
        filterBy = 'C';
      }

      return `Q${qPosition}${filterBy}`;
    }).filter(f => f).join('|'));

    return filterText.join(';');
  }

  return (
    <React.Fragment>
      {props.btn && <Button className="ml-2" onClick={edit}><i className="fas fa-edit"/> Edytuj z pola tekstowego</Button>}
      {modalOpen && <FilterFromTextareaModal
        filtersText={filtersText}
        wizardChange={!props.btn}
        onClose={filters => {
          setModalOpen(false);
          props.onClose(filters);
        }}
      />}
    </React.Fragment>
  );
}


function mapStateToProps(state) {
  let sets = [];
  let blocks = [];
  let pages = [];
  let questions = [];
  let sPositionInSurvey = 0;
  let bPositionInSurvey = 0;
  let pPositionInSurvey = 0;
  let qPositionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    sPositionInSurvey++;
    _.each(state.survey.structure.data.sets[setId].blockPages, blockId => {
      bPositionInSurvey++;

      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        pPositionInSurvey++;

        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          qPositionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = qPositionInSurvey;
          questions.push(q)
        })

        let p = state.survey.structure.data.pages[pageId];
        p.positionInSurvey = pPositionInSurvey;
        pages.push(p)
      });

      let b = state.survey.structure.data.blocks[blockId];
      b.positionInSurvey = bPositionInSurvey;
      blocks.push(b);
    });

    let s = state.survey.structure.data.sets[setId];
    s.positionInSurvey = sPositionInSurvey;
    sets.push(s);
  });

  return {
    sets,
    blocks,
    pages,
    questions,
  }
}

export default connect(mapStateToProps)(FiltersEditTextarea);