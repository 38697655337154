import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import InputNumber from "components/InputNumber";

class AnswerMaxValue extends Component{
  constructor(props) {
    super(props);
    this.state = {
      maxValue: props.answer.maxValue,
      tooltipIsOpen: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  onChange(value) {
    this.setState({...this.state, maxValue: value});
    this.props.changeOption('maxValue', value);
  }

  render() {
    const {maxValue, tooltipIsOpen} = this.state;

    return (
      <React.Fragment>
        <InputNumber float value={maxValue} onChange={this.onChange} id={'answerMaxValue-' + this.props.answer.id} placeholder="max"/>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltipIsOpen}
          target={'answerMaxValue-' + this.props.answer.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="answer.answerMaxValue.button.tooltip"/>
        </Tooltip>
      </React.Fragment>
    )
  }
}

AnswerMaxValue.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    maxValue: PropTypes.number,
  }).isRequired,
  changeOption: PropTypes.func.isRequired
};

export default AnswerMaxValue;
