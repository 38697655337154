import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Card, CardBody, CardHeader, Col, InputGroup, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import InputNumber from "components/InputNumber";
import Switch from "../../../../components/Switch";

class Paused extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data
    };
  }

  onChange(property, value){
    this.setState({
      data: {
        ...this.state.data,
        [property]: value
      }
    }, () => {
      this.props.onChange(property, this.state.data[property])
    });
  }

  render() {
    const {errors} = this.props;
    const {data} = this.state;


    return (
      <Col className="d-flex align-items-md-stretch" xs={this.props.xs} lg={this.props.lg}>
        <Card className="w-100">
          <CardHeader className="text-center"><h2>Status kolektora</h2></CardHeader>
          <hr className="my-0"/>
          <CardBody className="d-flex align-items-center justify-content-center">
            {data.paused === undefined
              ? <span onClick={() => this.onChange('paused', false)}>Kliknij aby zmienić status</span>
              : <Switch
                  checked={!data.paused}
                  onChange={() => this.onChange('paused', !data.paused)}
                />
            }
          </CardBody>
        </Card>
      </Col>
    );
  }
}

Paused.defaultProps = {
  disabled: false,
  errors: {},
  xs: 12,
  lg: 4,
};

Paused.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    paused: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.object.isRequired,
  xs: PropTypes.oneOf([1,2,3,4,5,6,7,8,9,10,11,12]).isRequired,
  lg: PropTypes.oneOf([1,2,3,4,5,6,7,8,9,10,11,12]).isRequired,
};

export default Paused;