import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {connect} from "react-redux";
import SurveyForm from "../SurveyForm";
import api from "../../../../../api";

class EditAction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      modal: false,
      loading: false,
      errors: {}
    };

    this.surveyForm = React.createRef();
    this.update = this.update.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  update(e, data) {
    e.preventDefault();
    this.setState({loading: true});

    api.survey.patch.survey({
      id: this.props.survey.id,
      survey: data.survey
    }).then(() => {
      this.props.handleChange();
      this.setState({
        loading: false,
        modal: false
      })
    }).catch(e => {
      this.setState({
        loading: false,
        errors: e.response.data.form.errors
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <span id={'editSurvey-' + this.props.survey.id} className="fas fa-edit ml-2 pointer" onClick={() => this.toggle('modal')}/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip}
          target={'editSurvey-' + this.props.survey.id}
          toggle={() => this.toggle('tooltip')}
        >Edytuj ankiete</Tooltip>

        <Modal isOpen={this.state.modal} size="lg" toggle={() => this.toggle('modal')}>
          <ModalHeader toggle={() => this.toggle('modal')} tag="h2">Edycja ankiety</ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            <SurveyForm survey={this.props.survey} errors={this.state.errors} onSubmit={this.update} ref={this.surveyForm}/>
          </ModalBody>
          <ModalFooter>
            <div className="direction-group d-flex justify-content-end">
              <div className="direction-right">
                <FormattedMessage id="_.button.save">{
                  (message) => <LaddaButton
                    className="btn btn-success btn-ladda mb-0 ml-3"
                    data-style={ZOOM_OUT}
                    onClick={e => this.update(e, this.surveyForm.current.state)}
                    loading={this.state.loading}
                  >
                    {message}
                  </LaddaButton>
                }</FormattedMessage>
                <FormattedMessage id="_.button.cancel">{
                  (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggle('modal')}>{message}</Button>
                }</FormattedMessage>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

EditAction.propTypes = {
  survey: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default EditAction;