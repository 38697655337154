import React, {Component} from 'react';

import api from 'api';
import {messagesLangs} from "i18Config";
import { connect } from 'react-redux';
import './footer.scss';

class Footer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toogleFooter: false,
      shortcutsModalInfo: false,
    }
  }

  static onLanguageChange(e) {
    localStorage.language = e.value;
    api.language.post({locale: e.value});
    window.location.reload();
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  openFooter() {
    if (this.state.toogleFooter === false) {
      this.setState({toogleFooter: true});
    }
  }

  render() {
    const {toogleFooter} = this.state;
    let languagesList = [];

    _.map(messagesLangs, (language, lang) => languagesList.push({
      value: lang,
      label: <span><img src={'/dashboard/img/icon/' + lang +'.png'} height="20" /> {messagesLangs[localStorage.language]['lang.' + lang]}</span>
    }));

    return (
      <footer className={(toogleFooter === false ? "pointer " : "") + "mt-2 d-none d-lg-block"} onClick={() => this.openFooter()}>
        {toogleFooter && <i className="fas fa-x" onClick={() => this.setState({toogleFooter: false})}/>}
        <div className="pt-2 pb-2 black-container">
          <div className="row">
            <div className="col-sm">
              <h4>Ankieteo</h4>
              {toogleFooter && <div>
                <p>System ankietowy lidera
                  Badań online w Polsce</p>
                <h4 className="mt-2">Kontakt</h4>
                <p>Marcin Styczyński</p>
                <p>+48 739 970 388</p>
                <p>kontakt@ankieteo.pl</p>
              </div>}
            </div>
            <div className="col-sm text-center">
              <h4>Produkt</h4>
              {toogleFooter && <div>
                <p><a target="_blank" href={process.env.ANKIETEO_FRONTEND + '/program-do-ankiet/funkcje'}>Funkcje</a></p>
                <p><a target="_blank" href={process.env.ANKIETEO_FRONTEND + '/program-do-ankiet/respondenci'}>Respondenci</a></p>
                <p><a target="_blank" href={process.env.ANKIETEO_FRONTEND + '/faq'}>Pomoc</a></p>
              </div>}
            </div>
            <div className="col-sm text-right">
              <h4>Baza wiedzy</h4>
              {toogleFooter && <div>
                <p><a target="_blank" href={process.env.ANKIETEO_FRONTEND + '/instrukcja'}>Instrukcja</a></p>
                <p><a target="_blank" href={process.env.ANKIETEO_FRONTEND + '/baza-wiedzy/zbieranie-wyników'}>Zbieranie Wyników</a></p>
                <p><a target="_blank" href={process.env.ANKIETEO_FRONTEND + '/baza-wiedzy/analiza-wyników'}>Analiza wyników</a></p>
                <p><a target="_blank" href={process.env.ANKIETEO_FRONTEND + '/blog'}>Blog</a></p>
              </div>}
            </div>
          </div>
        </div>
        {toogleFooter && <div className="pt-2 white-container">
          <div className="row pt-1">
            <div className="col-sm">
              <p>SW Research sp. z.o.o. ul. J. Dąbrowskiego 64a, 02-561 Warszawa</p>
            </div>
            <div className="col-sm text-right">
              <p><a target="_blank" href={process.env.ANKIETEO_FRONTEND + '/benefity'}>Benefity</a> |
                <a target="_blank" href={process.env.ANKIETEO_FRONTEND + '/regulamin'}>Regulamin</a> |
                <a target="_blank" href={process.env.ANKIETEO_FRONTEND + '/klauzulainformacyjna'}>RODO</a></p>
            </div>
          </div>
        </div>}
      </footer>
    )
  }
}



function mapStateToProps(state) {
  return {
    config: state.config.data
  }
}

export default connect(mapStateToProps)(Footer)