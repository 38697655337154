import api from "api";
import * as questionsActions from "../questions/actions";


export const SET = 'Survey/data/answers/SET';
export const ADDED = 'Survey/data/answers/ADDED';
export const DELETED = 'Survey/data/answers/DELETED';
export const UPDATED = 'Survey/data/answers/UPDATED';

export function setAnswers(answers) {
  return {
    type: SET,
    answers
  }
}
export function answerAdded(answer) {
  return {
    type: ADDED,
    answer
  }
}
export function answerDeleted(answer) {
  return {
    type: DELETED,
    answer
  }
}
export function answerUpdated(answer) {
  return {
    type: UPDATED,
    answer
  }
}





export function addAnswer(data) {
  return dispatch => {
    return api.question.post.answer(data).then(d => {
      dispatch(answerAdded(d.answer));
      d.answers.map(a => dispatch(answerUpdated(a)))
      dispatch(questionsActions.answerAdded(d.answer));

      return d.answer;
    });
  }
}

export function deleteAnswer(data) {
  return dispatch => {
    return api.answer.delete.answer(data).then(res => {
      dispatch(answerDeleted({
        id: data.id,
        question: {
          id: res.answer.question.id
        }
      }))
    })
  }
}
export function deleteAnswers(questionId, data) {
  return dispatch => {
    return api.answer.delete.list(data).then(() => {
      _.map(data.ids, id => {
        dispatch(answerDeleted({
          id,
          question: {
            id: questionId
          }
        }))
      })
    })
  }
}

export function updateAnswer(data) {
  return dispatch => {
    return api.answer.patch(data).then(answer => {
      dispatch(answerUpdated(answer))
    })
  }
}