import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';
import HtmlEditor from "../../../../../componentsReusable/HtmlEditor";

class CollectorsModalInvitationText extends Component {

  constructor(props) {
    super(props);

    this.state = {
      invitationText: props.collector.invitationText,
      saving: false
    };

    this.onChange = this.onChange.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  onChange(e) {
    this.setState({
      invitationText: e
    });
  }

  updateContent() {
    this.setState({
      saving: true
    });

    const data = {
      id: this.props.collector.id,
      surveyCollector: {
        invitationText: this.state.invitationText
      }
    };

    api.surveyCollector.patch(data)
      .then(() => {
        this.setState({
          saving: false,
        });
        this.props.handleCollectorChange(this.props.collector.id, 'invitationText', data.surveyCollector.endingText);
        this.props.toggleModal()
      })
      .catch(() => {
        this.setState({
          saving: false
        })
      });
  }

  render() {
    const {saving, invitationText} = this.state;

    return (
      <Modal
        isOpen={this.props.isOpen}
        size="lg"
        toggle={this.props.toggleModal}
      >
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id='collectors.listItems.modalInvitationTextMessage.modal.modalHeader'/></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
          <HtmlEditor
              onChange={this.onChange}
            data={invitationText}
          />
          <LaddaButton
            className="btn btn-primary btn-ladda mr-2"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={() => this.updateContent()}
          >
            <FormattedMessage id='_.button.save'/>
          </LaddaButton>
        </ModalBody>
      </Modal>
    );
  }
}

CollectorsModalInvitationText.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    invitationText: PropTypes.string
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleCollectorChange: PropTypes.func.isRequired,
};

export default CollectorsModalInvitationText;
