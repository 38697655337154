import React, {Component} from 'react';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";
import {Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";

class ReportDownloadsList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    this.setState({
      searching: true,
      page: state.page,
      pageSize: state.pageSize
    });

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      },
      search: state.search,
    };

    api.reportDownloads.post.search(data)
    .then(res => {
      this.setState({
        searching: false,
        data: res.data,
        count: res.count
      });
    })
    .catch(e => this.setState({
      searching: false,
    }));
  }

  render() {
    const columns = [
      {
        Header: 'E-mail',
        accessor: 'email',
        width: 350,
      },
      {
        id: 'newsletter',
        Header: 'Newsletter',
        accessor: row => row.newsletter ? <span className="badge badge-success">Tak</span> : <span className="badge badge-danger">Nie</span>,
        width: 80,
      },
      {
        Header: 'Data',
        accessor: 'createdAt',
        width: 170,
      },
      {
        Header: 'Nazwa raportu',
        accessor: 'report.title',
      },
      {
        Header: 'Link do pobrania raportu',
        accessor: 'report.pdf',
      },
    ];

    return <React.Fragment>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <a className="mb-2" href={api.reportDownloads.download(this.state.search)}>Pobierz</a>
        </div>

        <div>
          <InputGroup size="sm">
            <Input onKeyUp={e => {
              if(e.keyCode === 13){
                this.fetchData(this.state)
              }
            }} onChange={e => this.setState({search: e.target.value})} value={this.state.search} />
            <InputGroupAddon className="pointer" onClick={() => this.fetchData(this.state)} addonType="append"><InputGroupText>Szukaj</InputGroupText></InputGroupAddon>
          </InputGroup>
        </div>
      </div>
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    </React.Fragment>
  }
}

export default ReportDownloadsList;