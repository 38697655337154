import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from "react-intl";
import {Col, Form, FormGroup, Label, Input} from 'reactstrap';
import {updateAnalysis} from "../../../reducer/analysis/actions";

class SettingsAnalysisName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      analysisId: props.analysis.id,
      analysisName: props.analysis.name
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange(e) {
      this.setState({
        analysisName: e.target.value
      });
  };

  static handleKeyPress(e) {
    if (e.charCode === 13) {
      e.preventDefault();
    }
  };

  onBlur() {
    this.props.updateAnalysis({
      id: this.props.analysis.id,
      analysis: {
        name: this.state.analysisName
      }
    });
  }

  render() {
    return (
      <Form>
        <FormGroup
          row
          className="mt-2"
        >
          <Label
            lg={4}
            className="text-right"
          >
            <FormattedMessage id="analysis.sidebar.settings.nav.analysisName"/>
          </Label>
          <Col
            lg={8}
          >
            <Input
              type="text"
              value={this.state.analysisName}
              onChange={this.onChange}
              onBlur={this.onBlur}
              onKeyPress={SettingsAnalysisName.handleKeyPress}
              required
            />
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

SettingsAnalysisName.propTypes = {
  analysis: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default connect(null, {updateAnalysis}) (SettingsAnalysisName);
