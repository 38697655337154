import api from "api";

export const REQUEST = 'Survey/data/quotaNews/REQUEST';
export const SUCCESS = 'Survey/data/quotaNews/SUCCESS';
export const ERROR = 'Survey/data/quotaNews/ERROR';

export const SET = 'Survey/data/quotaNews/SET';
export const DELETED = 'Survey/data/quotaNews/DELETED';
export const ADDED = 'Survey/data/quotaNews/ADDED';
export const UPDATED = 'Survey/data/quotaNews/UPDATED';

export function setQuotaNews(quotaNews) {
  return {
    type: SET,
    quotaNews
  }
}

export function quotaNewDeleted(quotaNew) {
  return {
    type: DELETED,
    quotaNew
  }
}
export function quotaNewAdded(quotaNew) {
  return {
    type: ADDED,
    quotaNew
  }
}

export function quotaNewUpdated(quotaNew, expiredId) {
  return {
    type: UPDATED,
    quotaNew,
    expiredId
  }
}

export function deleteQuotaNew(data) {
  return dispatch => {
    return api.quotaNew.delete(data).then(() => {
      dispatch(quotaNewDeleted({
        id: data.id,
      }))
    })
  }
}

export function addQuotaNew(data) {
  return dispatch => {
    return api.quotaNew.post(data).then((quotaNew) => {
      dispatch(quotaNewAdded(quotaNew))
    })
  }
}

export function updateQuotaNew(data) {
  return dispatch => {
    return api.quotaNew.patch(data).then(quotaNew => {
      dispatch(quotaNewUpdated(quotaNew, data.quotaNew.id));
    })
  }
}
