import React, {Component} from 'react';
import {CardBody, Col, Row} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import moment from "moment/moment";
import "react-input-range/lib/css/index.css";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import api from 'api';
import FormDefaultProperty from "../FormDefaultProperty";
import CodeGenerator from "./CodeGenerator";




class CollectorWidget extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        guid: props.collector.guid,
        startAt: moment(props.collector.startAt).format('YYYY-MM-DD'),
        endAt: moment(props.collector.endAt).format('YYYY-MM-DD'),
        collectorResponses: props.collector.collectorResponses,
        enabledSurveyFilters: props.collector.enabledSurveyFilters,
        enabledValidations: props.collector.enabledValidations,
        showInvitationText: props.collector.showInvitationText,
      },
      saving: false,
      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({...this.state, saving: true, errors: {}});
    const {data} = this.state;

    api.surveyCollector.patch({
      id: this.props.collector.id,
      surveyCollector: data,
    })
      .then(collector => {
        this.setState({...this.state, saving: false});
        this.props.handleUpdate(collector);
      })
      .catch(e => this.setState({
        errors: e.response.data.form.errors,
        saving: false
      }));
  }

  render() {
    const {data, errors} = this.state;

    return (
      <CardBody>
        <FormDefaultProperty
          errors={errors}
          copyLinkWithQuestion={true}
          collector={data}
          onPropertyChanged={(name, value) => {
            this.setState({
              data: {
                ...data,
                [name]: value,
              }
            })
          }}
        />

        <Row>
          <Col>
            <FormattedMessage id="_.button.save">{msg => <LaddaButton
              className="btn btn-primary btn-ladda mt-4 float-right"
              loading={this.state.saving}
              onClick={this.onSubmit}
              data-style={ZOOM_OUT}
            >
              {msg}
            </LaddaButton>}</FormattedMessage>
          </Col>
        </Row>

        <CodeGenerator collector={data} />
      </CardBody>
    )
  }
}

CollectorWidget.propTypes = {
  collector: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default CollectorWidget;