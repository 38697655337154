import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {InputGroupAddon, Tooltip, Button} from "reactstrap";
import InputNumber from "components/InputNumber";
import classnames from "classnames";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";

class AnswersMinMax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answersMinTooltip: false,
      answersMaxTooltip: false,
      question: {
        answersMin: props.question.answersMin,
        answersMax: props.question.answersMax,
      }
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip(type) {
    this.setState({
      ...this.state,
      [type]: !this.state[type]
    })
  }

  onChange(value, target) {
    if (this.props.disabled) {
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());
      return;
    }
    this.setState({
      ...this.state,
      question: {
        ...this.state.question,
        [target]: value
      }
    }, () => this.props.handleUpdate(this.state.question));
  }

  render() {
    const {question, answersMinTooltip, answersMaxTooltip} = this.state;
    const {id} = this.props.question;

    return (
      <React.Fragment>
        <div
          id={"answersMin" + id}
          className={classnames({
            "min-w-0 float-left mr-1 mb-1": true,
            "w-auto": question.answersMin === 0,
            "w-100 w-lg-auto input-group": question.answersMin > 0
          })}
        >
          <InputGroupAddon addonType="prepend">
            <Button
              className={classnames({
                "btn-icon": true,
                "active": question.answersMin > 0,
                "disabled": this.props.disabled
              })}
              color="secondary"
              onClick={() => {
                if(question.answersMin === 0){
                  this.onChange(1, 'answersMin')
                }else{
                  this.onChange(0, 'answersMin')
                }
              }}
            >
              <i className="fak fa-clipboard-list-check-max"/>
              <Tooltip
                delay={0}
                placement="top"
                isOpen={answersMinTooltip}
                target={"answersMin" + id}
                toggle={() => this.toggleTooltip('answersMinTooltip')}
              >
                <FormattedMessage id="question.settings.answersMinMax.buttonMin.tooltip"/>
              </Tooltip>
            </Button>
          </InputGroupAddon>
          {question.answersMin > 0 && <InputNumber value={question.answersMin} min={0} max={this.props.question.answers.length} onChange={(value) => this.onChange(value, 'answersMin')} className={this.props.disabled ? "not-active" : ""}/>}
        </div>
        <div
          id={"answersMax" + id}
          className={classnames({
            "min-w-0 float-left mr-1 mb-1": true,
            "w-auto": question.answersMax === 0,
            "w-100 w-lg-auto input-group": question.answersMax > 0
          })}
        >
          <InputGroupAddon addonType="prepend">
            <Button
              className={classnames({
                "btn-icon": true,
                "active": question.answersMax > 0,
                "disabled": this.props.disabled
              })}
              color="secondary"
              onClick={() => {
                if(question.answersMax === 0){
                  this.onChange(1, 'answersMax')
                }else{
                  this.onChange(0, 'answersMax')
                }
              }}
            >
              <i className="fak fa-clipboard-list-check-max"/>
              <Tooltip
                delay={0}
                placement="top"
                isOpen={answersMaxTooltip}
                target={"answersMax" + id}
                toggle={() => this.toggleTooltip('answersMaxTooltip')}
              >
                <FormattedMessage id="question.settings.answersMinMax.buttonMax.tooltip"/>
              </Tooltip>
            </Button>
          </InputGroupAddon>
          {question.answersMax > 0 && <InputNumber value={question.answersMax} min={0} max={this.props.question.answers.length} onChange={(value) => this.onChange(value, 'answersMax')} className={this.props.disabled ? "not-active" : ""}/>}
        </div>
      </React.Fragment>
    )
  }
}

AnswersMinMax.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answersMin: PropTypes.number.isRequired,
    answersMax: PropTypes.number.isRequired,
    answers: PropTypes.array.isRequired
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default connect(null, {setPlanRestrictionsModals})(AnswersMinMax);
