import React, {Component} from 'react';
import {connect} from "react-redux";
import {map} from "lodash";
import ActionAdd
  from "./Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/Answers/components/Actions/components/Add";

class KeyboardQuestionAddCafetery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addingAnswer: false,
      question: null,
    };
  }

  componentDidMount() {
    Mousetrap.bind("shift+r", () => {
      let question = null;

      map(document.querySelectorAll('#structure div[role="question"] div[role="answers"]'), (answers) => {
        let q = answers.closest('div[role="question"]');
        const top = q.getBoundingClientRect().top;
        const height = q.getBoundingClientRect().height;

        if(question === null && (top > 150 || (top + height) > 200)){
          if((top - height) < 300){
            question = this.props.questions[parseInt(q.attributes['data-id'].nodeValue)];
          }
        }
      });

      if(question){
        this.setState({
          question,
          addingAnswer: true,
        })
      }
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+r");
  }

  render() {
    const {question} = this.state;

    return <React.Fragment>
      {this.state.addingAnswer && <ActionAdd isOpen={true} question={question} onClose={() => this.setState({
        addingAnswer: false,
        question: null,
      })} />}
    </React.Fragment>;
  }
}

function mapStateToProps(state) {
  return {
    questions: state.survey.structure.data.questions,
  }
}

export default connect(mapStateToProps)(KeyboardQuestionAddCafetery);