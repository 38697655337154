import React, {Component} from 'react';
import {Form, Row, Label, Input, Col} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import Checkbox from "../../../components/Checkbox";

class SmtpsForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
        name: props.smtp ? props.smtp.name : '',
        host: props.smtp ? props.smtp.host : '',
        port: props.smtp ? props.smtp.port : 0,
        email: props.smtp ? props.smtp.email : '',
        password: props.smtp ? props.smtp.password: '',
        ssl: props.smtp ? props.smtp.ssl : false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSslChange = this.onSslChange.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSslChange() {
    this.setState({
      ssl: !this.state.ssl
    });
  }

  render() {
    const {errors} = this.props;

    return (
      <Form onSubmit={e => this.props.onSubmit(e, this.state)}>
        <Row>
          <Col lg={3}>
            <Label for="name"><FormattedMessage id="smtpsForm.label.name" /></Label>
          </Col>
          <Col lg={9}>
            <Input type="text" name="name" id="name" invalid={!!errors.name} value={this.state.name} className="custom" onChange={this.onChange}/>
            {errors.name && <div className="invalid-feedback">{errors.name.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label for="host"><FormattedMessage id="smtpsForm.label.host" /></Label>
          </Col>
          <Col lg={9}>
            <Input type="text" name="host" id="host" invalid={!!errors.host} value={this.state.host} className="custom" onChange={this.onChange}/>
            {errors.host && <div className="invalid-feedback">{errors.host.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label for="port"><FormattedMessage id="smtpsForm.label.port" /></Label>
          </Col>
          <Col lg={9}>
            <Input type="number" name="port" id="port" invalid={!!errors.port} value={this.state.port} className="custom" onChange={this.onChange}/>
            {errors.port && <div className="invalid-feedback">{errors.port.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label for="email"><FormattedMessage id="smtpsForm.label.email" /></Label>
          </Col>
          <Col lg={9}>
            <Input type="text" name="email" id="email" invalid={!!errors.email} value={this.state.email} className="custom" onChange={this.onChange}/>
            {errors.email && <div className="invalid-feedback">{errors.email.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label for="password"><FormattedMessage id="smtpsForm.label.password" /></Label>
          </Col>
          <Col lg={9}>
            <Input type="text" name="password" id="password" invalid={!!errors.password} value={this.state.password} className="custom" onChange={this.onChange}/>
            {errors.password && <div className="invalid-feedback">{errors.password.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label for="ssl"><FormattedMessage id="smtpsForm.label.ssl" /></Label>
          </Col>
          <Col lg={9}>
            <Checkbox id={'smtp-ssl'} checked={this.state.ssl} onClick={this.onSslChange} tooltip={false}/>
          </Col>
        </Row>
      </Form>
    )
  }
}

SmtpsForm.propTypes = {
  smtp: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};

export default SmtpsForm;