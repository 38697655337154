import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import PropTypes from 'prop-types';

class DeleteModal extends Component {

  render() {
    return <Modal isOpen toggle={() => this.props.toggleModal()} className={'modal-danger'}>
      <ModalHeader toggle={() => this.props.toggleModal()} tag="h2"><FormattedMessage id="browse.deleteModal.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="browse.deleteModal.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.modal.delete.confirmation">{
          (message) =>
            <Button color="danger" onClick={this.props.handleDelete}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.props.toggleModal()}>{message}</Button>
        }</FormattedMessage>

      </ModalFooter>
    </Modal>;
  }
}

DeleteModal.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default DeleteModal;