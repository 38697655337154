import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button} from "reactstrap";
import LoadingSpinner from "components/LoadingSpinner";
import { connect } from 'react-redux';
import {addPage} from "scenes/Survey/Structure/data/pages/actions";
import ButtonAddQuestionList from "../Page/components/Questions/components/ButtonAddQuestionList";

class ButtonCreatePage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.addNewPage = this.addNewPage.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  addNewPage(){
    this.setState({loading: true});
    this.props.addPage({
      block: this.props.block.id,
      page: {
        position: this.props.position
      }
    }).then(() => this._ismounted && this.setState({loading: false}));
  }

  render() {
    return (
      <div className="d-flex flex-row flex-nowrap justify-content-end mb-2">
        {/*<ButtonAddQuestionList pageId={null} position={0} classes="d-block mr-2" buttonColor='secondary'/>*/}
        <Button onClick={this.addNewPage} size="sm"> {this.state.loading ? <LoadingSpinner width={21} classNames="my-0"/> : <span><FormattedMessage id="pages.buttonCreatePage.button.create" /> <i className="fas fa-plus" /></span>}</Button>
      </div>
    )
  }
}

export default connect(null, {addPage})(ButtonCreatePage);
