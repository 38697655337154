import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../../../../../components/Button";
import AdvancedForm from "./AdvancedForm";

class AdvancedEditModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      saving: false,
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  submit(){
    this.formRef.current.save()
  }

  render() {
    const {saving, modalOpen} = this.state;

    return <React.Fragment>
      <Button size="sm" color="" onClick={this.toggleModal}><i className="fas fa-edit" /></Button>

      {modalOpen && <Modal isOpen={true} toggle={this.toggleModal} size="xl">
        <ModalHeader toggle={this.toggleModal}>Edycja</ModalHeader>
        <ModalBody>
          <AdvancedForm
            data={{
              ...this.props.advanced,
              question: this.props.advanced.question.id,
              questionCrossed: this.props.advanced.questionCrossed ? this.props.advanced.questionCrossed.id : false,
            }}
            onSaved={advanced => {
              this.toggleModal();
              this.props.onSaved(advanced)
            }}
            ref={this.formRef}
            onSavingStart={() => this.setState({ saving: true })}
            onSavingStop={() => this.setState({ saving: false })}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={this.toggleModal}>Anuluj</Button>
          <Button
            loading={saving}
            onClick={this.submit}
          >Zapisz</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>;
  }
}

AdvancedEditModal.propTypes = {
  onSaved: PropTypes.func.isRequired,
  advanced: PropTypes.shape({

  }).isRequired,
};

export default AdvancedEditModal;
