import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from "striptags";
import classnames from "classnames";

class ColumnTbody extends Component {
  getExcluding(excludingId){
    return this.props.question.excludings.find(excluding => excluding.id === parseInt(excludingId));
  }
  
  getAnswer(answerId){
    return this.props.question.answers.find(answer => answer.id === parseInt(answerId));
  }

  render() {
    const {question, questionResponse} = this.props;

    return <React.Fragment>
      {question.rows.map((row, key) => {
        return <React.Fragment>
          {question.columns.map((column, keyColumn) => {
            let detail = false;

            if(questionResponse){
              if(!questionResponse.excluding && questionResponse.details.find(detail => detail.row === row.id && detail.column === column.id)){
                detail = questionResponse.details.find(detail => detail.row === row.id && detail.column === column.id);
              }
            }

            return <React.Fragment key={key.toString() + keyColumn.toString()}>
              <td className={classnames({
                'text-center': true,
                'border-left': key === 0,
                'border-right': key === ((question.rows.length * question.columns.length)-1) && !row.isOpen,
              })}>
                {(detail && detail.answer) ? <div className="" dangerouslySetInnerHTML={{__html: striptags(this.getAnswer(detail.answer).content)}} /> : <i>Nie wybrał</i>}
              </td>
            </React.Fragment>
          })}

          {/*{row.isOpen && <td className={classnames({*/}
          {/*  'text-center': true,*/}
          {/*  'border-left': key === 0,*/}
          {/*  'border-right': key === ((question.rows.length * question.columns.length)-1),*/}
          {/*})}>*/}
          {/*  {detailRow ? detailRow.contentRow : ''}*/}
          {/*</td>}*/}
        </React.Fragment>
      })}

      {question.excludings.length > 0 && <td className="text-center border-left border-right">
        {(questionResponse && questionResponse.excluding) ? <div className="" dangerouslySetInnerHTML={{__html: striptags(this.getExcluding(questionResponse.excluding).content)}} /> : ''}
      </td>}
    </React.Fragment>;
  }
}

ColumnTbody.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['matrixMulti']),
    rows: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
    columns: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
    excludings: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
  }).isRequired,
  questionResponse: PropTypes.shape({
    details: PropTypes.arrayOf(PropTypes.shape({

    }).isRequired,),
  }),
};

export default ColumnTbody;