import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Table} from "reactstrap";
import { connect } from 'react-redux';
import {updateBlock} from 'scenes/Survey/Structure/data/blocks/actions';
import RandAndRotateBlockOptions from './components/RandAndRotateBlockOptions';

class RandAndRotateBlocksTable extends Component {

  render() {
    return (
      <Table>
        <thead>
          <tr>
            <th />
            {this.props.set.rotateBlocks && <th><small><FormattedMessage id="set.settings.table.header.rotate"/></small></th>}
            {this.props.set.randBlocks > 0 && <th><small><FormattedMessage id="set.settings.table.header.random"/></small></th>}
          </tr>
        </thead>
        <tbody>
        {this.props.set.blockPages.map(block => <RandAndRotateBlockOptions set={this.props.set} block={this.props.blocks[block]} key={block}/>)}
        </tbody>
      </Table>
    )
  }
}

RandAndRotateBlocksTable.propTypes = {
  set: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    blocks: state.survey.structure.data.blocks
  }
}

export default connect(mapStateToProps, {updateBlock})(RandAndRotateBlocksTable);