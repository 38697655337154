import React, {Component} from 'react';
import {Nav, NavItem} from "reactstrap";
import {NavLink, Route, Switch} from "react-router-dom";
import appRoutes from "appRoutes";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import NavigationAdminSettings from "./NavigationAdminSettings";
import NavigationAdminSurveys from "./NavigationAdminSurveys";
import NavigationAdminUsers from "./NavigationAdminUsers";
import NavigationAdminPrizes from "./NavigationAdminPrizes";
import NavigationAdminCms from "./NavigationAdminCms";
import NavigationAdminStats from "./NavigationAdminStats";
import NavigationAdminPlans from "./NavigationAdminPlans";
import NavigationAdminApplicationFiles from "./NavigationAdminApplicationFiles";
import NavigationAdminShipping from "./NavigationAdminShipping";
import {isMobile, isTablet} from "react-device-detect";
import {connect} from "react-redux";

class NavigationAdmin extends Component {

  static toggleNavbar(toggleOnMobile) {
    if (toggleOnMobile && (isMobile || isTablet)) {
      let element = document.getElementById('mainNav').classList;
      element.contains('show') ? element.remove('show') : element.add('show');
    }
  }

  render() {
    const {user, location} = this.props;

    return (
      <React.Fragment>
        <Nav className="navbar-nav m-0 mx-lg-5 align-items-lg-baseline w-50 w-lg-100 d-inline-block d-lg-flex">
          {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_USER_RESPONDENTS') || user.roles.includes('ROLE_ADMIN_USER_COMPANIES')) &&
          <NavItem className="py-2">
            <NavLink className="nav-link" to={appRoutes.admin.users.respondents} onClick={() => NavigationAdmin.toggleNavbar(false)}>
              <FormattedMessage id="navigation.admin.users"/>
              <span className="sr-only"><FormattedMessage id="navigation.admin.users"/></span>
            </NavLink>
          </NavItem>}
          {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_SURVEYS_SWPANEL') || user.roles.includes('ROLE_ADMIN_SURVEYS_RESPONSES') || user.roles.includes('ROLE_ADMIN_SURVEYS')) && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.admin.surveys.list} onClick={() => NavigationAdmin.toggleNavbar(false)}><FormattedMessage id="navigation.admin.surveys"/><span className="sr-only"><FormattedMessage id="navigation.admin.surveys"/></span></NavLink></NavItem>}
          {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_USER_PRIZE')) && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.admin.prizes.list} onClick={() => NavigationAdmin.toggleNavbar(false)}><FormattedMessage id="navigation.admin.prizes"/><span className="sr-only"><FormattedMessage id="navigation.admin.prizes"/></span></NavLink></NavItem>}
          {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_REPORTER') || user.roles.includes('ROLE_ADMIN_ANKIETEO_CMS') || user.roles.includes('ROLE_ADMIN_RANKINGS')) && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.admin.cms.reports} onClick={() => NavigationAdmin.toggleNavbar(false)}><FormattedMessage id="navigation.admin.cms"/><span className="sr-only"><FormattedMessage id="navigation.admin.cms"/></span></NavLink></NavItem>}
          {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_PLANS')) && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.admin.settings.codes} onClick={() => NavigationAdmin.toggleNavbar(false)}><FormattedMessage id="navigation.admin.settings" /><span className="sr-only"><FormattedMessage id="navigation.admin.settings" /></span></NavLink></NavItem>}
          {user.roles.includes('ROLE_SUPER_ADMIN') && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.admin.stats.respondents} onClick={() => NavigationAdmin.toggleNavbar(false)}><FormattedMessage id="navigation.admin.stats"/><span className="sr-only"><FormattedMessage id="navigation.admin.stats"/></span></NavLink></NavItem>}
          {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN')) && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.admin.plans.transactions} onClick={() => NavigationAdmin.toggleNavbar(false)}><FormattedMessage id="navigation.admin.plans"/><span className="sr-only"><FormattedMessage id="navigation.admin.plans"/></span></NavLink></NavItem>}
          {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_FILES')) && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.admin.files.files} onClick={() => NavigationAdmin.toggleNavbar(false)}><FormattedMessage id="navigation.admin.files"/><span className="sr-only"><FormattedMessage id="navigation.admin.files"/></span></NavLink></NavItem>}
          {(user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN_SURVEY_SHIPPING')) && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.admin.shipping.panel} onClick={() => NavigationAdmin.toggleNavbar(false)}><FormattedMessage id="navigation.admin.shipping"/><span className="sr-only"><FormattedMessage id="navigation.admin.shipping"/></span></NavLink></NavItem>}
        </Nav>

        <Switch>
          <Route location={location} path={appRoutes.admin.users.respondents} children={<NavigationAdminUsers />}/>
          <Route location={location} path={appRoutes.admin.surveys.list} children={<NavigationAdminSurveys />}/>
          <Route location={location} path={appRoutes.admin.prizes.list} children={<NavigationAdminPrizes />}/>
          <Route location={location} path={appRoutes.admin.cms.reports} children={<NavigationAdminCms />}/>
          <Route location={location} path={appRoutes.admin.settings.codes} children={<NavigationAdminSettings />}/>
          <Route location={location} path={appRoutes.admin.stats.respondents} children={<NavigationAdminStats />}/>
          <Route location={location} path={appRoutes.admin.plans.transactions} children={<NavigationAdminPlans />}/>
          <Route location={location} path={appRoutes.admin.files.files} children={<NavigationAdminApplicationFiles />}/>
          <Route location={location} path={appRoutes.admin.shipping.panel} children={<NavigationAdminShipping />}/>
        </Switch>
      </React.Fragment>
    );
  }
}

NavigationAdmin.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}
export default connect(mapStateToProps)(NavigationAdmin);
