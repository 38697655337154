import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, CardBody} from "reactstrap";
import SelectQuestions from "scenes/Survey/Structure/components/SelectQuestions";
import SelectAnswers from "scenes/Survey/Structure/components/SelectAnswers";

class Cond extends PureComponent {

  renderCondDetailsByQuestionType(){
    const {cond, questions} = this.props;
    const question = questions.find(q => q.id === cond.question);

    if(!question){
      return;
    }

    switch (question.type) {
      case 'single':
      case 'multi':
        return <SelectAnswers
          questionId={question.id}
          answers={question.answers}
          value={cond.answers || []}
          onChange={answers => this.props.onCondChange({
            ...cond,
            answers
          })}
        />;
    }
  }

  render() {
    const {questions, cond} = this.props;

    return (
      <Card className="border-l orange">
        <CardHeader>
          <div className="direction-group">
            <h4 className="direction-left">Warunek</h4>

            <div className="direction-right mr-2 py-2">
              <a onClick={this.props.deleteCond}><i className="fas fa-trash" /></a>
            </div>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <SelectQuestions
            value={cond.question}
            isMulti={false}
            questions={questions}
            onChange={question => {
              this.props.onCondChange({
                ...cond,
                question,
                answers: []
              });
            }}
          />
          {this.renderCondDetailsByQuestionType()}
        </CardBody>
      </Card>
    );
  }
}

Cond.propTypes = {
  cond: PropTypes.shape({
    question: PropTypes.number,
  }).isRequired,
  questions: PropTypes.array.isRequired,
  onCondChange: PropTypes.func.isRequired,
  deleteCond: PropTypes.func.isRequired,
};

export default Cond;