import api from "api";
import * as questionsActions from "../questions/actions";

export const SET = 'Survey/data/javascript/SET';
export const ADDED = 'Survey/data/javascript/ADDED';
export const DELETED = 'Survey/data/javascript/DELETED';
export const UPDATED = 'Survey/data/javascript/UPDATED';

export function setJavascripts(javascripts) {
  return {
    type: SET,
    javascripts
  }
}
export function javascriptAdded(javascript) {
  return {
    type: ADDED,
    javascript
  }
}
export function javascriptDeleted(javascript) {
  return {
    type: DELETED,
    javascript
  }
}
export function javascriptUpdated(javascript) {
  return {
    type: UPDATED,
    javascript
  }
}

export function addJavascript(data) {
  return dispatch => {
    return api.question.post.javascript(data).then(javascript => {
      dispatch(javascriptAdded(javascript));
      dispatch(questionsActions.javascriptAdded(javascript));

      return javascript;
    });
  }
}

export function deleteJavascript(question, javascript) {
  return dispatch => {
    return api.javascript.delete.javascript(javascript).then(res => {
      dispatch(javascriptDeleted({
        id: javascript.id,
        question
      }))
    })
  }
}

export function updateJavascript(data) {
  return dispatch => {
    return api.javascript.patch(data).then(javascript => {
      dispatch(javascriptUpdated(javascript))
    })
  }
}