import * as React from 'react';
import "../../../componentsNew/Survey/SurveyValidation/scoring.scss";
import ModalComponent from "../../../componentsReusable/ModalComponent";
import SurveyScoringTable from "./SurveyScoringTable";
import {UncontrolledTooltip} from "reactstrap";

function SurveyScoringIcon(props) {
	const {survey, scoring, allowModal} = props;
	const [openModal, setModalOpen] = React.useState(false);

	const onClick = () => {
		if (allowModal) {
			setModalOpen(true);
		}
	}

	let style = {};
	if (allowModal) {
		style = {cursor: "pointer"};
	}

	return (
		<React.Fragment>
			{allowModal &&
			<UncontrolledTooltip placement="right" target={'survey-scoring-' + survey.id} delay={0}>Pokazuje wynik
				jakościowy ankiety na skali 0-100. Kliknij na ikonę aby dowiedzieć się więcej.</UncontrolledTooltip>}
			<span id={allowModal ? 'survey-scoring-' + survey.id : ""} style={style} onClick={onClick}>
            {scoring < 75 && <span class={"span-container red"}>{scoring}</span>}
				{scoring >= 75 && scoring < 85 && <span class={"span-container yellow"}>{scoring}</span>}
				{scoring >= 85 && scoring < 94 && <span class={"span-container light-green"}>{scoring}</span>}
				{scoring >= 94 && <span class={"span-container green"}>{scoring}</span>}
          </span>
			<ModalComponent
				headerText={"Historia zmian scoringu (sprawdzana co 1h)"} modalOpen={openModal} onAccept={() => setModalOpen(false)}
				onCancel={() => setModalOpen(false)} component={
					<SurveyScoringTable survey={survey}/>
				}
			/>
		</React.Fragment>
	);
}

SurveyScoringIcon.propTypes = {};

export default SurveyScoringIcon;