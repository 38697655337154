import {ERROR, REQUEST, SUCCESS} from "./actionTypes";
import _ from 'lodash';
import {combineReducers} from "redux";

import {reducer as analysisReducer} from './reducer/analysis/reducer';
import {reducer as setsReducer} from "./reducer/sets/reducer";
import {reducer as excludingsReducer} from "./reducer/excludings/reducer";
import {reducer as answersReducer} from "./reducer/answers/reducer";
import {reducer as questionsReducer} from "./reducer/questions/reducer";
import {reducer as pagesReducer} from "./reducer/pages/reducer";
import {reducer as blocksReducer} from "./reducer/blocks/reducer";
import {reducer as collectorsReducer} from "./reducer/collectors/reducer";
import {reducer as filtersQuestionReducer} from "./reducer/filtersQuestion/reducer";
import {reducer as filtersCollectorReducer} from "./reducer/filtersCollector/reducer";
import {reducer as questionSummariesReducer} from "./reducer/questionSummaries/reducer";
import {reducer as surveyReducer} from "./reducer/survey/reducer";
import {reducer as sharesReducer} from "./reducer/shares/reducer";
import {reducer as rowsReducer} from "./reducer/rows/reducer";
import {reducer as columnsReducer} from "./reducer/columns/reducer";

export const dataReducer = combineReducers({
  analysis: analysisReducer,
  sets: setsReducer,
  excludings: excludingsReducer,
  answers: answersReducer,
  rows: rowsReducer,
  columns: columnsReducer,
  questions: questionsReducer,
  pages: pagesReducer,
  blocks: blocksReducer,
  collectors: collectorsReducer,
  filtersQuestion: filtersQuestionReducer,
  filtersCollector: filtersCollectorReducer,
  questionSummaries: questionSummariesReducer,
  survey: surveyReducer,
  shares: sharesReducer
});

const loadingReducer = (state, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case SUCCESS:
    case ERROR:
      return false;
    default:
      return state;
  }
};

const initialState = { loading: false };
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      const rest = _.omit(state, Object.keys(initialState));
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: dataReducer(rest.data, action)
      };
  }
};