import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import api from "api";

class ButtonAddTextarea extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      saving: false,
      respondents: '',
      added: [],
      rejected: [],
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.save = this.save.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  save(){
    this.setState({
      saving: true,
      rejected: [],
      added: [],
    });

    api.respondentSmsBases.respondents.addText(this.props.baseId, this.state.respondents)
    .then(data => {
      this.setState({
        saving: false,
        added: data.respondents.added,
        rejected: data.respondents.rejected,
      });

      this.props.onSaved()
    })
    .catch(res => {

      this.setState({
        saving: false,
      });
    })
    ;
  }

  render() {
    const {modalOpen, saving, respondents, added, rejected} = this.state;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal}>Dodaj wielu respondentów</ModalHeader>
      <ModalBody>
        <Input
          type={"textarea"}
          placeholder={"wklej listę - każdy rekord w oddzielnej linii; dane rozdzielaj średnikami; nie dodawaj  wiersza tytułowego; na pierwszej pozycji umieść 9-cyfrowy numer telefonu bez numeru kierunkowego 48 na początku"}
          rows={10}
          value={respondents}
          onChange={e => this.setState({respondents: e.target.value})}
        />
        {rejected.length > 0 && <Alert color="danger">
          <p className="text-center">Odrzuceni respondenci:</p>
          <div>
            {rejected.map(respondent => <div>{respondent.phone}</div>)}
          </div>
        </Alert>}
        {added.length > 0 && <Alert color="success">
          <p className="text-center">Dodani respondenci:</p>
          <div>
            {added.map(respondent => <div>{respondent.phone}</div>)}
          </div>
        </Alert>}
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.add">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.save}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button className="mr-2" color="primary" onClick={this.toggleModal}><i className="fas fa-plus" /> Dodaj wielu respondentów</Button>
        {modal}
      </React.Fragment>
    );
  }
}

ButtonAddTextarea.propTypes = {
  baseId: PropTypes.number.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default ButtonAddTextarea;