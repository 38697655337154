import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, InputGroup, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import ResponsesList from "./ResponsesList";
import api from "api";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";

class Responses extends Component {

  constructor(props) {
    super(props);

    this.state = {
      statuses: [],
      filters: {
        users: '',
        survey: '',
        search: '',
        period: 7,
        dateFrom: null,
        dateTo: null,
      }
    };

    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidMount(){
    api.surveyResponse.get.statusCodes().then(statuses => {
      this.setState({statuses});
    });
  }

  onFilterChange(name, value) {
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      }
    });
  }

  render() {
    const periodOptions = [
      {
        label: <FormattedMessage id="admin.points.period.7days" />,
        value: 7
      },
      {
        label: <FormattedMessage id="admin.points.period.30days" />,
        value: 30
      },
      {
        label: <FormattedMessage id="admin.points.period.null" />,
        value: null
      },
      {
        label: 'Zakres dat',
        value: 0
      },
    ];

    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <FormattedMessage tagName="h2" id="admin.responses.cardHeader" />

                <div className="d-flex">
                  <div className="custom-search-input w-lg-100 float-right mr-2" style={{minWidth: 200}}>
                    <Select
                      options={periodOptions}
                      onChange={e => this.onFilterChange('period', e.value)}
                      value={_.find(periodOptions, option => option.value === this.state.filters.period)}
                    />
                  </div>

                  {this.state.filters.period === 0 && <React.Fragment>
                    <div className="mt-1 mr-2">
                      <DatePicker
                        isClearable
                        dateFormat="Y-MM-DD"
                        locale={localStorage.language}
                        dropdownMode="select"
                        placeholderText="Data od"
                        selected={this.state.filters.dateFrom ? moment(this.state.filters.dateFrom) : null}
                        onChange={date => this.onFilterChange('dateFrom', date ? date.format('YYYY-MM-DD') : null)}
                      />
                    </div>
                    <div className="mt-1 mr-2">
                      <DatePicker
                        isClearable
                        dateFormat="Y-MM-DD"
                        locale={localStorage.language}
                        dropdownMode="select"
                        placeholderText="Data od"
                        selected={this.state.filters.dateTo ? moment(this.state.filters.dateTo) : null}
                        onChange={date => this.onFilterChange('dateTo', date ? date.format('YYYY-MM-DD') : null)}
                      />
                    </div>
                  </React.Fragment>}

                  <div className="custom-search-input w-lg-100 float-right mt-1">
                    <InputGroup className="p-0 mb-0">
                      <Input className="p-0" name="users" placeholder="Użytkownik (id, email, token)" onChange={e => this.onFilterChange(e.target.name, e.target.value)}/>
                      <span className="input-group-btn py-1">
                      <button className="btn" type="button"><i className="fas fa-search" /></button>
                    </span>
                    </InputGroup>
                  </div>

                  <div className="custom-search-input w-lg-100 float-right mt-1 ml-2">
                    <InputGroup className="p-0 mb-0">
                      <Input className="p-0" name="survey" placeholder="Id ankiety" onChange={e => this.onFilterChange(e.target.name, e.target.value)}/>
                      <span className="input-group-btn py-1">
                      <button className="btn" type="button"><i className="fas fa-search" /></button>
                    </span>
                    </InputGroup>
                  </div>

                  <div className="custom-search-input w-lg-100 float-right mt-1 ml-2">
                    <InputGroup className="p-0 mb-0">
                      <Input className="p-0" name="search" placeholder="Szukaj..." onChange={e => this.onFilterChange(e.target.name, e.target.value)}/>
                      <span className="input-group-btn py-1">
                      <button className="btn" type="button"><i className="fas fa-search" /></button>
                    </span>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Row>
                <Col>
                  <ResponsesList filters={this.state.filters} statuses={this.state.statuses}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Responses;
