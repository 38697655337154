import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from 'api';
import HelpModeIconTooltip from "components/HelpModeIconTooltip";
import DeleteButton from "./TabTokens/DeleteButton";
import AddTokens from "./TabTokens/AddTokens";
import ExportTokens from "./TabTokens/ExportTokens";
import {surveyLink} from "services/surveyLink";
import moment from "moment";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";
import ImportTokens from "./TabTokens/ImportTokens";
import ModalEmailVars from "../../CollectorEmail/ModalEmailVars";
import {Button} from "reactstrap";
import Select from "react-select";
import {TOKEN_FIELDS} from "../../../../../componentsReusable/SelectTokenFields";

class TabTokens extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tokens: [],
      analysis: false,
      pageSize: 10,
      page: 0,
      total: 0,
      showTokens: 5
    };

    this.actions = this.actions.bind(this);
    this.deleteToken = this.deleteToken.bind(this);
    this.addTokens = this.addTokens.bind(this);
    this.link = this.link.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.getColumns = this.getColumns.bind(this);
  }

  addTokens(tokens) {
    this.setState({tokens})
    this.fetchData()
  }

  deleteToken(tokenId) {
    const tokens = this.state.tokens.filter(token => token.id !== tokenId);
    this.setState({tokens});
    this.fetchData()
  }

  actions(token){
    return (
      <ul className="float-right mb-0 p-0">
        <li className="float-left mr-2">
          <DeleteButton token={token} handleDelete={this.deleteToken}/>
        </li>
      </ul>
    )
  }
  
  link(token){
    return <a href={surveyLink(this.props.collector.guid + '/' + token.guid)} target="_blank">{surveyLink(this.props.collector.guid + '/' + token.guid)}</a> ;
  }

  getColumns() {
    const {analysis, showTokens} = this.state;
    const {surveyId} = this.props;

    const tableColumns = [{
      Header: 'Token',
      accessor: 'guid'
    }, {
      id: 'link',
      Header: 'Link',
      accessor: this.link
    }, {
      id: 'responseCreatedAt',
      Header: 'Pierwszy kontakt',
      accessor: token => token.responseCreatedAt && moment(token.responseCreatedAt).format('YYYY-MM-DD HH:mm')
    }, {
      id: 'responseUpdatedAt',
      Header: 'Ostatni kontakt',
      accessor: token => token.responseUpdatedAt && moment(token.responseUpdatedAt).format('YYYY-MM-DD HH:mm')
    }, {
      Header: 'Status',
      id: 'responseId',
      accessor: token => {
        if(token.responseId){
          return <Link to={appRoutes.survey.analysis.browseResponse(surveyId, analysis.id, token.responseId)} target="_blank">{token.status}</Link>
        }

        return token.status;
      }
    }];

    if (showTokens) {
      for (let i = 1;i<(showTokens + 1);i++) {
        tableColumns.push({
          Header: 'Pole ' + i,
          accessor: 'field' + i
        });
      }
    }

    tableColumns.push({
      id: 'actions',
      Header: <FormattedMessage id="surveyCollector.typeTokens.tokens.table.actions"/>,
      accessor: token => this.actions(token)
    });

    return tableColumns;
  }

  fetchData(state) {
    if(!state){
      state = this.state;
    }

    this.setState({
      loading: true,
      page: state.page,
      pageSize: state.pageSize
    });
    api.surveyCollector.get.tokens({
      id: this.props.collector.id,
      page: state.page,
      pageSize: state.pageSize
    }).then(data => {
      this.setState({
        loading: false,
        tokens: data.tokens,
        analysis: data.analysis,
        total: data.totalTokens
      });
    }).catch(() => {
      this.setState({
        loading: false
      });
    })
  }

  render() {
    const {loading, tokens, pageSize, showTokens} = this.state;
    const {collector} = this.props;

    const selectTokenFields = [
      {
        label: 0,
        value: 0
      },
      {
        label: 5,
        value: 5
      },
      {
        label: 10,
        value: 10
      },
      {
        label: 15,
        value: 15
      }
    ];

    return (
      <React.Fragment>
        <HelpModeIconTooltip className="mr-3">Lista wygenerowanych tokenów</HelpModeIconTooltip>
        <ImportTokens collector={this.props.collector} handleAddTokens={this.addTokens}/>
        <AddTokens collector={this.props.collector} handleAddTokens={this.addTokens}/>
        <ExportTokens collector={this.props.collector}/>
        <div style={{width: '150px'}} className="float-right ml-3">
          <h2>Liczba widocznych pól</h2>
          <Select
              closeMenuOnSelect={false}
              isOpen={false}
              options={selectTokenFields}
              onChange={field => this.setState({showTokens: field.value})}
              value={selectTokenFields.find(field => field.value === showTokens)}
          />
        </div>
        <ModalEmailVars collector={collector} />
        <div style={{ maxWidth: '100%', overflowX: 'auto' , marginTop: '70px'}}>
          <ReactTable
            minRows={0}
            className={'table-wrap'}
            data={tokens}
            columns={this.getColumns()}
            showPagination={!_.isEmpty(tokens)}
            PaginationComponent={Pagination}
            noDataText={<FormattedMessage id="surveyCollector.typeTokens.tokens.table.noData" />}
            loading={loading}
            defaultPageSize={pageSize}
            onFetchData={this.fetchData}
            rowsCount={parseInt(this.state.total)}
            manual
          />
        </div>
      </React.Fragment>
    )
  }
}

TabTokens.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    guid: PropTypes.string.isRequired,
    tokens: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      guid: PropTypes.string,
      responseId: PropTypes.number,
    }))
  }).isRequired,
  surveyId: PropTypes.string.isRequired
};

export default TabTokens;