import React from 'react';
import PropTypes from 'prop-types';
import Icon from "../../../../../../../componentsReusable/Icon";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../../../../componentsReusable/Button";
import api from "../../../../../../../api";

const RemoveButton = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [loading, setLoading] = React.useState(false);

  const remove = () => {
    setLoading(true)

    api.survey.removeEndingCodes(props.surveyId)
      .then(() => {
        setLoading(false)
        props.onRemoved()
      })
  }

  return <React.Fragment>
    <Icon className="fas fa-times ml-2 pointer" tooltip="Usuń wgrane pozycje" onClick={toggleModalOpen} />
    {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
      <ModalHeader toggle={toggleModalOpen}>Potwierdź</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <p>Czy na pewno usunąć wszystkie wgrane pozycje treści indywidualnych / kodów?</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
        <Button
          color="danger"
          onClick={remove}
          loading={loading}
        >Tak, usuń</Button>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
};

RemoveButton.propTypes = {
  onRemoved: PropTypes.func.isRequired,
};

export default RemoveButton;
