import * as React from 'react';
import PropTypes from "prop-types";
import Button from "components/Button";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import SummaryTable from "scenes/Survey/Summary/components/SummaryTable";

function SummaryShare(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  return (
    <div className="ml-auto">
      <Button size="sm" className="m-1" onClick={toggleModalOpen}><i className="fas fa-share" /></Button>
      {modalOpen && <Modal isOpen toggle={toggleModalOpen} className="height-100vh" size="xl">
        <ModalHeader toggle={toggleModalOpen}>Udostępnianie podglądu podsumowania</ModalHeader>
        <ModalBody>
          <SummaryTable
            surveyId={props.surveyId}
            filters={{
              survey: props.surveyId
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Zamknij</Button>
        </ModalFooter>
      </Modal>}
    </div>
  );
}

SummaryShare.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default SummaryShare;