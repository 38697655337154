import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Tooltip} from "reactstrap";
import FiltersAvailable from "components/PlanRestrictionsModals/modals/FiltersAvailable";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";

class ButtonFilters extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onClick() {
    this.props.surveyPlan.filters
      ? this.props.handleToggleFilters()
      : this.props.setPlanRestrictionsModals(FiltersAvailable.getName())
  }

  render() {
    const {filtersOpen} = this.props;
    const {tooltip} = this.state;

    return (
      <a id={'question-' + this.props.id + '-filters'} onClick={this.onClick}>
        <span className={"fas fa-filter" + (filtersOpen ? " text-primary" : " hoverable")}/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={tooltip}
          target={'question-' + this.props.id + '-filters'}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="question.headerActions.buttonFilters.button.tooltip"/>
        </Tooltip>
      </a>
    )
  }
}

ButtonFilters.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  handleToggleFilters: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  surveyPlan: PropTypes.shape({
    filters: PropTypes.bool.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey
  }
}


export default connect(mapStateToProps, {setPlanRestrictionsModals})(ButtonFilters);