import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Form,FormGroup,Label,Col,Input,FormFeedback} from "reactstrap";
import api from "api";
import moment from "moment";
import {map} from "lodash";
import StatusesSelect from "components/Survey/Analysis/StatusesSelect";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Switch from "components/Switch";

class QuotasForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        ...props.data,
        survey: props.surveyId,
      },
      saving: false,
      errors: {},
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  changeData(field, value){
    this.setState({
      data: {
        ...this.state.data,
        [field]: value,
      }
    })
  }

  submit(e){
    e.preventDefault();
  }

  save(){
    const {data} = this.state;

    this.props.onSavingStart();

    this.setState({
      errors: {},
    })

    api.surveyShared.quotas.post(data)
    .then(data => {
      this.props.onSavingStop();

      this.props.onSaved(data.data);
    })
    .catch(res => {
      this.props.onSavingStop();

      this.setState({
        errors: res.response.data
      })
    })
  }

  render() {
    const {data, errors} = this.state;

    return <Form>
      <FormGroup className="mb-2" row>
        <Label md={4}>Nazwa:</Label>
        <Col md={8}>
          <Input type="text" className="mb-0" invalid={!!_.has(errors, 'children.name.errors')} onChange={e => this.changeData('name', e.target.value)} value={data.name} />
          {_.has(errors, 'children.name.errors') && <FormFeedback>{errors.children.name.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup className="mb-2" row>
        <Label md={4}>Hasło:</Label>
        <Col md={8}>
          <Input type="text" className="mb-0" invalid={!!_.has(errors, 'children.password.errors')} onChange={e => this.changeData('password', e.target.value)} value={data.password} />
          {_.has(errors, 'children.password.errors') && <FormFeedback>{errors.children.password.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
    </Form>
  }
}

QuotasForm.defaultProps = {
  data: {
    name: '',
    password: '',
  },
};

QuotasForm.propTypes = {
  surveyId: PropTypes.number.isRequired,
  data: PropTypes.object,
  onSaved: PropTypes.func,
  onSavingStart: PropTypes.func,
  onSavingStop: PropTypes.func,
};

export default QuotasForm;
