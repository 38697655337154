import React, {Component} from 'react';
import {Form, FormGroup, Label, Input, Col, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Select from 'react-select';
import PropTypes from 'prop-types';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';

class CodeForm extends Component {

  constructor(props) {
    super(props);
    let arrayPlaces = [];

    if (props.code) {
      for (let i = 0; i < props.code.places.length; i++)
        arrayPlaces.push({label: props.code.places[i], value: props.code.places[i]});
    }

    this.state = {
      code: {
        name: props.code ? props.code.name : '',
        cookieName: props.code ? props.code.cookieName : '',
        groupRandFrom: props.groupRandFrom ? props.code.groupRandFrom : '',
        groupRandTo: props.groupRandTo ? props.code.groupRandTo : '',
        code: props.code ? props.code.code : '',
        places: props.code ? props.code.places : [],
        onlyAuth: props.code ? props.code.onlyAuth : false,
      },
      placesSelectedData: arrayPlaces
    };

    this.onChange = this.onChange.bind(this);
    this.onRadioChange = this.onRadioChange.bind(this);
    this.onPlacesChange = this.onPlacesChange.bind(this);
  }

  onChange(e) {
    this.setState({
      ...this.state,
      code: {
        ...this.state.code,
        [e.target.name]: e.target.value
      }
    });
  }

  onRadioChange(e) {
    this.setState({
      ...this.state,
      code: {
        ...this.state.code,
        onlyAuth: e.target.value === 'true'
      }
    });
  }

  onPlacesChange(places) {
    let arrayPlaces = [];
    for (let i = 0; i < places.length; i++)
      arrayPlaces.push(places[i].value);

    this.setState({
      ...this.state,
      placesSelectedData: places,
      code: {
        ...this.state.code,
        places: arrayPlaces,
      }
    });
  }

  render() {
    let {code, placesSelectedData} = this.state;
    const {errors, onSubmit} = this.props;

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup>
          <Label for="name"><FormattedMessage id="admin.codeForm.label.name" /></Label>
          <Input type="text" name="name" id="name" value={code.name} invalid={!!errors.name} onChange={this.onChange}/>
          {errors.name && <p className="help-block text-danger">{errors.name}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="cookieName">Cookie name</Label>
          <Input type="text" name="cookieName" id="cookieName" value={code.cookieName} invalid={!!errors.cookieName} onChange={this.onChange}/>
          {errors.cookieName && <p className="help-block text-danger">{errors.cookieName}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="name"><FormattedMessage id="admin.codeForm.label.groupRand" /></Label>
          <Row>
            <Col md={6}>
              <Input type="number" placeholder="Od" name="groupRandFrom" id="groupRandFrom" value={code.groupRandFrom} invalid={!!errors.groupRandFrom} onChange={this.onChange}/>
            </Col>
            <Col md={6}>
              <Input type="number" placeholder="Do" name="groupRandTo" id="groupRandTo" value={code.groupRandTo} invalid={!!errors.groupRandTo} onChange={this.onChange}/>
            </Col>
          </Row>
          {errors.name && <p className="help-block text-danger">{errors.name}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="code"><FormattedMessage id="admin.codeForm.label.code" /></Label>
          <Editor
            value={code.code}
            onValueChange={code => this.setState({ code: {...this.state.code, code} })}
            highlight={code => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
            }}
          />
          {errors.code && <p className="help-block text-danger">{errors.code}</p>}
        </FormGroup>
        <FormGroup>
          <Label><FormattedMessage id="admin.codeForm.label.places" /></Label>
          <Select
            isMulti
            value={placesSelectedData}
            onChange={this.onPlacesChange}
            options={[
              {label: 'swpanel', value: 'swpanel'},
              {label: 'studentswatch', value: 'studentswatch'},
              {label: 'ankieteo', value: 'ankieteo'}
            ]}
          />
          {errors.places && <p className="help-block text-danger">{errors.places}</p>}
        </FormGroup>
        <Label for="onlyAuth"><FormattedMessage id="admin.codeForm.label.onlyAuth" /></Label>
        <FormGroup row>
          <Col className="offset-1" sm={3}>
            <Input type="radio" name="onlyAuth" id="onlyAuth" value={true} onChange={this.onRadioChange} checked={code.onlyAuth}/>
            <FormattedMessage id="admin.codeForm.label.onlyAuth.true" />
          </Col>
          <Col sm={3}>
            <Input type="radio" name="onlyAuth" id="onlyAuth" value={false} onChange={this.onRadioChange} checked={!code.onlyAuth}/>
            <FormattedMessage id="admin.codeForm.label.onlyAuth.false" />
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

CodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  code: PropTypes.object
};

export default CodeForm;
