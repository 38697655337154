import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import appRoutes from "appRoutes";
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import CollectorsModalDelete from './Modals/CollectorsModalDelete';
import CollectorsModalClear from './Modals/CollectorsModalClear';
import CollectorsModalInvitationText from './Modals/CollectorsModalInvitationText';
import CollectorsModalEndingText from './Modals/CollectorsModalEndingText';
import CollectorsModalCopy from './Modals/CollectorsModalCopy';

class CollectorsActions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      clearModal: false,
      deleteModal: false,
      invitationTextModal: false,
      endingTextModal: false,
      copyModal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(property) {
    this.setState({
      [property]: !this.state[property]
    })
  }

  render() {
    const {collector, surveyId, handleCollectorChange, handleCollectorDelete, clearResponsesBlockade, handleCollectorCopy} = this.props;

    return (
      <ul className="float-right mb-0 ml-0 pl-0">
        <li className="float-left mr-2">
          <Button color="" className="m-0 p-0"><Link to={appRoutes.survey.collectors.collector.view(surveyId, collector.id)}><span className="fas fa-edit text-dark" /></Link></Button>
        </li>
        <li className="float-left">
          <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle('dropdownOpen')} className={'dd-' + collector.id}>
            <DropdownToggle className="m-0 p-0" color="" data-toggle={'dd-' + collector.id}>
              <span className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.toggle('invitationTextModal')} tag={"a"}>
                <p><i className="fas fa-play-circle"/></p> <FormattedMessage tagName="h5" id="collectors.collectors.actions.label.invitationText"/>
              </DropdownItem>
              <DropdownItem onClick={() => this.toggle('endingTextModal')} tag={"a"}>
                <p><i className="fas fa-check"/></p> <FormattedMessage tagName="h5" id="collectors.collectors.actions.label.endingText"/>
              </DropdownItem>
              {!clearResponsesBlockade && <DropdownItem onClick={() => this.toggle('clearModal')} tag={"a"}>
                <p><i className="fa-solid fa-eraser" /></p> <FormattedMessage tagName="h5" id="_.button.clear" />
              </DropdownItem>}
              {collector.type !== 'BaseSurveys' && <DropdownItem onClick={() => this.toggle('copyModal')} tag={"a"}>
                <p><i className="fas fa-copy" /></p> <FormattedMessage tagName="h5" id="_.button.copy" />
              </DropdownItem>}
              <DropdownItem onClick={() => this.toggle('deleteModal')} tag={"a"}>
                <p><i className="fas fa-trash" /></p> <FormattedMessage tagName="h5" id="_.button.delete" />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </li>
        <CollectorsModalInvitationText collector={collector} isOpen={this.state.invitationTextModal} toggleModal={() => this.toggle('invitationTextModal')} handleCollectorChange={handleCollectorChange}/>
        <CollectorsModalEndingText collector={collector} isOpen={this.state.endingTextModal} toggleModal={() => this.toggle('endingTextModal')} handleCollectorChange={handleCollectorChange}/>
        {!clearResponsesBlockade && <CollectorsModalClear collector={collector} isOpen={this.state.clearModal} toggleModal={() => this.toggle('clearModal')} handleCollectorChange={handleCollectorChange}/>}
        <CollectorsModalDelete collector={collector} isOpen={this.state.deleteModal} toggleModal={() => this.toggle('deleteModal')} handleCollectorDelete={handleCollectorDelete}/>
        <CollectorsModalCopy collector={collector} isOpen={this.state.copyModal} toggleModal={() => this.toggle('copyModal')} handleCollectorCopy={handleCollectorCopy}/>
      </ul>
    )
  }
}

CollectorsActions.propTypes = {
  collector: PropTypes.object.isRequired,
  surveyId: PropTypes.number.isRequired,
  handleCollectorChange: PropTypes.func.isRequired,
  handleCollectorDelete: PropTypes.func.isRequired,
  handleCollectorCopy: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    noGroup: !Object.keys(state.groups.data.groups).length > 0 && !state.user.userGroup,
    clearResponsesBlockade: state.survey.structure.data.survey.clearResponsesBlockade
  }
}


export default connect(mapStateToProps)(CollectorsActions);
