import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {Button, Card, CardBody, CardFooter, Form, FormGroup} from "reactstrap";
import {saveFilterQuestion} from "../../../../reducer/filtersQuestion/actions";
import Single from './FilterQuestionAnswer/Single';
import Multi from './FilterQuestionAnswer/Multi';
import Matrix from './FilterQuestionAnswer/Matrix';
import Ranked from './FilterQuestionAnswer/Ranked';
import MatrixMulti from './FilterQuestionAnswer/MatrixMulti';
import SelectQuestions from "../../../../../Structure/components/SelectQuestions";

class FiltersQuestionFilter extends Component {
  constructor(props) {
    super(props);

    let filter = this.getClearFilter();

    if(props.filter){
      filter = props.filter;
    }

    this.state = {
      button: {
        saving: false,
      },
      question: props.filter.question || false,
      filter,
    };

    this.changeQuestion = this.changeQuestion.bind(this);
    this.saveFilter = this.saveFilter.bind(this);
    this.onAnswersChange = this.onAnswersChange.bind(this);
  }

  getClearFilter(){
    return {
      id: this.props.filter.id || 0,
      analysis: this.props.analysis.id,
      question: 0,
    };
  }

  changeQuestion(question){
    const state = this.state;

    let filter = this.getClearFilter();
    filter.question = question;
    filter.answers = [];

    this.setState({
      ...state,
      question,
      filter,
    });
  }

  onAnswersChange(answers) {
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        answers: answers
      }
    })
  }

  saveFilter(){
    const {question, filter} = this.state;

    if(!question || _.isEmpty(filter.answers)){
      return false;
    }

    this.setState({
      ...this.state,
      button: {
        ...this.state.button,
        saving: true
      }
    });

    this.props.saveFilterQuestion(filter)
      .then(() => {
        this.props.onClosed();
        this.props.onSaved();
      })
      .catch(() => {
        this.setState({
          ...this.state,
          button: {
            ...this.state.button,
            saving: false
          }
        });
      })
    ;
  }

  getFilterQuestionAnswer() {
    switch (this.props.questions.find(q => q.id === this.state.question).type) {
      case 'single':
        return <Single questions={this.props.questions} questionId={this.state.question} filter={this.state.filter} handleChange={this.onAnswersChange}/>;
      case 'multi':
        return <Multi questions={this.props.questions} questionId={this.state.question} filter={this.state.filter} handleChange={this.onAnswersChange}/>;
      case 'ranked':
        return <Ranked questions={this.props.questions} questionId={this.state.question} filter={this.state.filter} handleChange={this.onAnswersChange}/>;
      case 'matrix':
        return <Matrix questions={this.props.questions} questionId={this.state.question} filter={this.state.filter} handleChange={this.onAnswersChange}/>;
      case 'matrixMulti':
        return <MatrixMulti questions={this.props.questions} questionId={this.state.question} filter={this.state.filter} handleChange={this.onAnswersChange}/>;
      default:
        return;
    }
  }

  render() {
    const {button, question} = this.state;
    const {questions} = this.props;

    return (
      <Card>
        <CardBody className="p-0">
          <Form>
            <FormGroup className="mb-2">
              <SelectQuestions
                enabledTypes={['single','multi','matrix','ranked','matrixMulti']}
                isMulti={false}
                questions={questions}
                onChange={this.changeQuestion}
                value={question || null}
              />
            </FormGroup>

            {question && this.getFilterQuestionAnswer()}

          </Form>
        </CardBody>
        <CardFooter>
          <Button color="secondary" size="sm" className="w-50 float-left" onClick={this.props.onClosed}><FormattedMessage id="_.button.cancel"/></Button>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-sm btn-primary w-50 float-right btn-ladda z-0"
              data-style={ZOOM_OUT}
              loading={button.saving}
              onClick={this.saveFilter}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </CardFooter>
      </Card>
    )
  }
}

FiltersQuestionFilter.propTypes = {
  analysis: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  onClosed: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
};

export default connect(null, {saveFilterQuestion})(FiltersQuestionFilter);
