import * as React from 'react';
import PropTypes from "prop-types";
import Summary from "./components/Summary";
import SummaryShare from "./components/SummaryShare";

function SummaryView(props) {
  return <div className="d-flex flex-column w-100">
    <div className="d-flex pr-4">
      <SummaryShare surveyId={props.match.params.survey} />
    </div>

    <div>
      <Summary
        surveyId={props.match.params.survey}
      />
    </div>
  </div>
}

SummaryView.propTypes = {

};

export default SummaryView;