import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import countPercent from "../../../../../../../utils/countPercent";

const SLICE_VALUE = 5;

class QuestionSummaryTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tableSlice: true,
    };
  }

  render() {
    const {data, questionSummary, valueProps} = this.props;
    const {tableSlice} = this.state;

    const isExpandable = (row) => {
      return row.isOpen;
    }

    const expandOpenDetails = (row) => {
      return (
          <BootstrapTable
              className={'table-wrap'}
              data={row.openDetails}
              striped hover
          >
            <TableHeaderColumn dataField='content' isKey={true}><FormattedMessage id="analysis.questionSummary.single.tableHeader.x" /></TableHeaderColumn>
            <TableHeaderColumn dataField='count'><FormattedMessage id="analysis.questionSummary.single.tableHeader.value" /></TableHeaderColumn>
          </BootstrapTable>
      );
    }

    return (
      <React.Fragment>
        <BootstrapTable
            id={"table-" + questionSummary.id}
          expandableRow={ isExpandable }
          expandComponent={ expandOpenDetails }
          expandColumnOptions={ { expandColumnVisible: true } }
          className={'table-wrap w-lg-50 mx-auto mt-3'}
          data={tableSlice ? data.slice(0, SLICE_VALUE) : data}
          striped hover
        >
          <TableHeaderColumn dataField='content' isKey={true} dataFormat={(cell, d) => (questionSummary.question.answersImage && d.isAnswer) ? <img src={cell} className="img-100"/> : <span dangerouslySetInnerHTML={{__html: cell}} className="img-30"/>}><FormattedMessage id="analysis.questionSummary.single.tableHeader.x" /></TableHeaderColumn>
          <TableHeaderColumn dataField='value' {...valueProps}><FormattedMessage id="analysis.questionSummary.single.tableHeader.value" /></TableHeaderColumn>
          {questionSummary.question.type !== 'ranked' && <TableHeaderColumn dataFormat={(cell, d) => {
            return countPercent(d.value, questionSummary.data.n)
          }}><FormattedMessage id="analysis.questionSummary.percent" /></TableHeaderColumn>}
          {questionSummary.question.type !== 'ranked' && <TableHeaderColumn dataFormat={(cell, d) => {
            return countPercent(d.value, questionSummary.data.allResults)
          }}><FormattedMessage id="analysis.questionSummary.wholeResult" /></TableHeaderColumn>}
        </BootstrapTable>
        {(tableSlice && data.length > SLICE_VALUE) && <p className="text-center">
          <span className="text-muted py-2 d-block pointer" onClick={() => this.setState({tableSlice: false})}>Kliknij, aby pokazać wszystkie odpowiedzi w tabeli</span>
        </p>}
      </React.Fragment>
    )
  }
}

QuestionSummaryTable.defaultProps = {
  data: []
};

QuestionSummaryTable.propTypes = {
};

export default QuestionSummaryTable;
