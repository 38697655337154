import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PlansList from './PlansList';
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";

class Plans extends Component {

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader><FormattedMessage tagName="h2" id="admin.plans.cardHeader" /></CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Row>
                <Col>
                  <Link className="btn btn-primary" to={appRoutes.admin.settings.plans.create}><i className="fas fa-plus" /> Utwórz nowy plan</Link>
                  <PlansList />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Plans;