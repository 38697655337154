import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import {isMobile} from "react-device-detect";
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import SurveysCategorySelect from "../../SurveysCategorySelect";
import api from "api";
import Select from "react-select";
import useAfterUpdateEffect from "../../../utils/useAfterUpdateEffect";

const PlacementSelect = (props) => {
  const [value, setValue] = React.useState(props.value);

  useAfterUpdateEffect(() => {
    props.onChange(value);
  }, [value])

  const getOptions = () => {
    return [
      {
        label: 'Na początek',
        value: 'first',
      },
      {
        label: 'Na koniec',
        value: 'last',
      },
    ]
  }

  return <Select
    options={getOptions()}
    value={getOptions().find(o => o.value === value)}
    onChange={option => setValue(option.value)}
  />
}

const SurveySortable = props => {
  const [surveysCategory, setSurveysCategory] = React.useState(props.survey.surveysCategoryId);
  const [placement, setPlacement] = React.useState('first');
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [saving, setSaving] = React.useState(false);

  const save = () => {
    setSaving(true);

    api.surveysCategories.surveyPatch({
      surveyId: props.survey.id,
      surveysCategoryId: surveysCategory,
      placement,
    })
      .then(() => {
        setModalOpen(false);
        props.onChange();
      })
      .finally(() => {
        setSaving(false);
      })
  }
  
  return (
    <React.Fragment>
      <i
        className={classnames({
          'handler-sortable-survey fas fa-grip-vertical mr-2 visible-visibilitiable-1': true,
          'd-none': isMobile,
        })}
        style={{cursor: 'move'}}
        onClick={() => setModalOpen(true)}
      />
      {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
        <ModalHeader toggle={toggleModalOpen}>Zmień pozycję ankiety w sekcji</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <FormGroup row>
            <Label xs={5} md={4}>Wybierz sekcje</Label>
            <Col xs={7} md={8}>
              <SurveysCategorySelect
                onChange={surveysCategory => setSurveysCategory(surveysCategory)}
                value={surveysCategory}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label xs={5} md={4}>Wybierz miejsce</Label>
            <Col xs={7} md={8}>
              <PlacementSelect
                value={placement}
                onChange={placement => setPlacement(placement)}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
          <Button
            color="primary"
            onClick={save}
          >Zapisz</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

SurveySortable.propTypes = {

};

export default SurveySortable;