import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Col, FormGroup, Label, Row} from "reactstrap";
import {map, first} from "lodash";
import useAfterUpdateEffect from "../../../../../../../utils/useAfterUpdateEffect";
import {FormattedMessage} from "react-intl";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import InputNumber from "../../../../../../../components/InputNumber";
import Switch from "../../../../../../../components/Switch";
import Select from "react-select";
import {cityProvinces} from "../../../../../../SurveyCollector/components/CollectorPanel/Tabs/CollectorPanelForm";
import useDidMountEffect from "../../../../../../../utils/useDidMountEffect";

const CondUserProfile = props => {
  const [cond, setCond] = React.useState(props.condQuestion);
  const [t, setT] = React.useState('');

  useAfterUpdateEffect(() => {
    props.onChange(cond)
  }, [cond])

  useDidMountEffect(() => {
    if(!props.changeable){
      setTextFromCond(cond)
    }
  })

  useAfterUpdateEffect(() => {
    if(!props.changeable){
      setTextFromCond(props.condQuestion)
    }
  }, [props.condQuestion])

  const setTextFromCond = (cond) => {
    const text = [];
    cond.sexM && text.push('Mężczyzna')
    cond.sexF && text.push('Kobieta')

    if(cond.ageFrom && cond.ageTo){
      text.push(`Wiek ${cond.ageFrom}-${cond.ageTo} lat`)
    }else if(cond.ageFrom){
      text.push(`Wiek od ${cond.ageFrom} lat`)
    }else if(cond.ageTo){
      text.push(`Wiek do ${cond.ageTo} lat`)
    }

    cond.cityVillage && text.push('Wieś')
    cond.cityTown && text.push('Miasto')

    if(cond.cityLmFrom && cond.cityLmTo){
      text.push(`LM ${cond.cityLmFrom}-${cond.cityLmTo}`)
    }else if(cond.cityLmFrom){
      text.push(`LM od ${cond.cityLmFrom}`)
    }else if(cond.cityLmTo){
      text.push(`LM do ${cond.cityLmTo}`)
    }

    if(cond.cityProvinces.length > 0){
      if(cond.cityProvinces.length === 1){
        text.push(first(cond.cityProvinces))
      }else {
        text.push(`Województwa (${cond.cityProvinces.length})`)
      }
    }

    setT(text.join(', '))
  }

  if(!props.changeable){
    return <span>{t}</span>
  }

  return (
    <div>
      <Alert color={'info'}>
        <span>Warunek dotyczący profilu respondenta</span>
      </Alert>
      <Row className="w-100">
        <Col xs={12} md={6}>
          <FormGroup row>
            <Label xs={12} md={2} className="text-right">Płeć</Label>
            <Col xs={12} md={10}>
              <Button
                className="mr-2"
                color={cond.sexM ? 'primary' : 'secondary'}
                onClick={() => setCond({
                  ...cond,
                  sexM: !cond.sexM,
                })}
              ><i className="fas fa-male fa-2x" /></Button>
              <Button
                color={cond.sexF ? 'primary' : 'secondary'}
                onClick={() => setCond({
                  ...cond,
                  sexF: !cond.sexF,
                })}
              ><i className="fas fa-female fa-2x" /></Button>
            </Col>
          </FormGroup>
          <FormGroup row className="mt-2">
            <Label md={2} className="text-right">Wiek</Label>
            <Col md={10} className="d-flex flex-row flex-nowrap">
              <InputGroup size="sm" className="mr-3 d-flex flex-row flex-nowrap">
                <InputGroupAddon addonType={"prepend"}><InputGroupText>Od</InputGroupText></InputGroupAddon>
                <InputNumber onChange={(value) => setCond({
                  ...cond,
                  ageFrom: parseInt(value) || null,
                })} min={0} max={99} value={cond.ageFrom} />
              </InputGroup>
              <InputGroup size="sm" className="d-flex flex-row flex-nowrap">
                <InputGroupAddon addonType={"prepend"}><InputGroupText>Do</InputGroupText></InputGroupAddon>
                <InputNumber onChange={(value) => setCond({
                  ...cond,
                  ageTo: parseInt(value) || null,
                })} min={0} max={99} value={cond.ageTo} />
              </InputGroup>
            </Col>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup row>
            <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cityVillage" /></Label>
            <Col md={8}>
              <Switch onChange={() => setCond({
                ...cond,
                cityVillage: !cond.cityVillage,
              })} checked={!!cond.cityVillage} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cityTown" /></Label>
            <Col md={8}>
              <Switch onChange={() => setCond({
                ...cond,
                cityTown: !cond.cityTown,
              })} checked={!!cond.cityTown} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cityLmRange" /></Label>
            <Col md={8} className="d-flex flex-row flex-nowrap">
              <InputGroup size={'sm'} className="mr-3 d-flex flex-row flex-nowrap">
                <InputGroupAddon addonType={"prepend"}><InputGroupText>Od</InputGroupText></InputGroupAddon>
                <InputNumber onChange={(value) => setCond({
                  ...cond,
                  cityLmFrom: parseInt(value) || null,
                })} step={1000} min={0} max={10000000} value={cond.cityLmFrom || 0} />
              </InputGroup>
              <InputGroup size={'sm'} className="d-flex flex-row flex-nowrap">
                <InputGroupAddon addonType={"prepend"}><InputGroupText>Do</InputGroupText></InputGroupAddon>
                <InputNumber onChange={(value) => setCond({
                  ...cond,
                  cityLmTo: parseInt(value) || null,
                })} step={1000} min={0} max={10000000} value={cond.cityLmTo || 0} />
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4} className="text-right"><FormattedMessage tagName="h2" id="collectorPanel.label.cityProvinces" /></Label>
            <Col md={8}>
              <Select
                isMulti
                closeMenuOnSelect={false}
                isOpen={false}
                placeholder="Wybierz województwa"
                options={cityProvinces.map(province => {
                  return {
                    label: province,
                    value: province,
                  }
                })}
                onChange={provinces => setCond({
                  ...cond,
                  cityProvinces: provinces.map(province => province.value)
                })}
                value={cond.cityProvinces.map(province => ({
                    label: province,
                    value: province,
                  }
                ))}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

CondUserProfile.defaultProps = {
  changeable: true,
};

CondUserProfile.propTypes = {
  changeable: PropTypes.bool,
  onChange: PropTypes.func,
  condQuestion: PropTypes.shape({

  }).isRequired,
};

export default CondUserProfile;
