import React, {PureComponent} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import reactHtmlParser from "react-html-parser";
import striptags from "striptags";
import PropTypes from "prop-types";

class ModalSmsVars extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      loading: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {modalOpen} = this.state;
    const {firstQuestion, collector} = this.props;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModal}>Lista zmiennych do wykorzystania przy tworzeniu szablonu wiadomości</ModalHeader>
      <hr className="my-2" />
      <ModalBody>
        <Table bordered>
          <thead>
            <tr>
              <th>Zmienna</th>
              <th>Opis</th>
            </tr>
          </thead>
          <tbody>
            {['Sms'].includes(collector.type) && <tr>
              <td>{'{url}'}</td>
              <td>Adres url do wypełnienia ankiety</td>
            </tr>}
            {['Sms'].includes(collector.type) && <tr>
              <td>{'{cX}'}</td>
              <td>Kolumna X z bazy respondentów, gdzie zamiast X należy podać numer kolumny, np. {'{c2}'} oznacza kolumnę numer 2 z bazy respondentów</td>
            </tr>}
            {['Panel', 'Email'].includes(collector.type) && <tr>
              <td>{'{firstQuestion}'}</td>
              <td>Wstawia w treść maila klikalne linki do udzielenia odpowiedzi na pierwsze pytanie z ankiety</td>
            </tr>}
            {(firstQuestion && firstQuestion.type === 'single') && firstQuestion.answers.map((answerId, key) => <tr>
              <td>{'{url}?qr['+firstQuestion.id+']=' + key}</td>
              <td>Adres url zaznaczający odpowiedź numer {key+1} na pierwsze pytanie w ankiecie: {reactHtmlParser(striptags(firstQuestion.content))}</td>
            </tr>)}
            {(firstQuestion && firstQuestion.type === 'nps') && [0,1,2,3,4,5,6,7,8,9,10].map((value) => <tr>
              <td>{'{url}?qr['+firstQuestion.id+']=' + value}</td>
              <td>Adres url zaznaczający wartość {value} na pierwsze pytanie w ankiecie: {reactHtmlParser(striptags(firstQuestion.content))}</td>
            </tr>)}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.close">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="secondary" className="float-right" onClick={this.toggleModal}>
          <i className="fas fa-code" /> Pokaż zmienne
        </Button>
        {modal}
      </React.Fragment>
    );
  }
}

ModalSmsVars.propTypes = {
  collector: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  const firstSet = state.survey.structure.data.survey.setBlocks[0];
  const firstBlock = state.survey.structure.data.sets[firstSet].blockPages[0];
  const firstPage = state.survey.structure.data.blocks[firstBlock].pages[0];
  const firstQuestion = state.survey.structure.data.pages[firstPage].questions[0];

  return {
    firstQuestion: state.survey.structure.data.questions[firstQuestion],
  }
}

export default connect(mapStateToProps)(ModalSmsVars);