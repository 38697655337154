import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button} from "reactstrap";
import LoadingSpinner from "components/LoadingSpinner";
import { connect } from 'react-redux';
import {addBlock} from "scenes/Survey/Structure/data/blocks/actions";
import ButtonAddQuestionList
  from "../Block/components/Pages/components/Page/components/Questions/components/ButtonAddQuestionList";

class ButtonCreateBlock extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.addNewBlock = this.addNewBlock.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  addNewBlock(){
    this.setState({loading: true});
    this.props.addBlock({
      set: this.props.set.id,
      block: {
        position: this.props.position
      }
    }).then(() => this._ismounted && this.setState({loading: false}));
  }

  render() {
    return (
      <div className="d-flex flex-row flex-nowrap justify-content-end mb-2">
        <Button onClick={this.addNewBlock} size="sm"> {this.state.loading ? <LoadingSpinner width={21} classNames="my-0"/> : <span><FormattedMessage id="blocks.buttonCreateBlock.button.create" /> <i className="fas fa-plus" /></span>}</Button>
      </div>
    )
  }
}

export default connect(null, {addBlock})(ButtonCreateBlock);