import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Button from "../../../Button";
import api from "../../../../api";


class DeleteQuestionTemplate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      deleting: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.delete = this.delete.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  delete(){
    this.setState({
      deleting: true
    });

    api.user.questionTemplates.delete(this.props.questionTemplateId)
    .then(() => {
      this.setState({
        modalOpen: false,
        deleting: false
      });

      this.props.onDeleted();
    })
    .catch(() => {
      this.setState({
        deleting: false
      });
    });
  }

  render() {
    const {modalOpen, deleting} = this.state;

    return (
      <React.Fragment>
        <i className="fas fa-times text-danger pointer" onClick={this.toggleModal} />
        {modalOpen && <Modal isOpen={modalOpen} toggle={this.toggleModal} size="lg">
          <ModalHeader toggle={this.toggleModal}>Usuń szablon pytania</ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            Czy na pewno chcesz usunąć szablon pytania?
          </ModalBody>
          <ModalFooter>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
            <FormattedMessage id="_.button.delete">{
              (message) => <Button
                color="danger"
                loading={deleting}
                onClick={this.delete}
              >{message}</Button>
            }</FormattedMessage>
          </ModalFooter>
        </Modal>}
      </React.Fragment>
    );
  }
}

DeleteQuestionTemplate.propTypes = {
  questionTemplateId: PropTypes.number.isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default DeleteQuestionTemplate;