import React, {Component} from 'react';
import {Form, FormGroup, Label, Input, Col, Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import InputNumber from "components/InputNumber";
import StatusesSelect from "../../../../../../../components/Survey/Analysis/StatusesSelect";

class RedirectForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirect: {
        position: props.redirect ? props.redirect.position: 0,
        max: props.redirect ? props.redirect.max: 0,
        target: props.redirect ? props.redirect.target: "",
        status: props.redirect ? props.redirect.status: null,
      },
      positionTooltip: false,
      statusTooltip: false,
      maxTooltip: false,
      targetTooltip: false
    };

    this.onChange = this.onChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]});
  }

  onChange(e) {
    this.setState({
      redirect: {
        ...this.state.redirect,
        [e.target.name]: e.target.value
      }
    });
  }

  render() {
    let {redirect} = this.state;
    const {errors, onSubmit} = this.props;

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup row className="mb-2">
          <Label for="position" md={3} className="text-right">
            <span>Status ankiety</span>
            <i id="survey-redirect-status" className="ml-2 fas fa-question-circle"/>
            <Tooltip
              delay={0}
              placement="bottom"
              isOpen={this.state.statusTooltip}
              target={'survey-redirect-status'}
              toggle={() => this.toggle('statusTooltip')}
            >
              Określa po jakim statusie wywiadu zostanie wykonane przekierowanie
            </Tooltip>
          </Label>
          <Col md={9}>
            <StatusesSelect
              onChange={status => {
                this.setState({
                  redirect: {
                    ...redirect,
                    status,
                  }
                })
              }}
              value={redirect.status}
              isMulti={false}
              isClearable={true}
              statuses={[3,4,5]}
              placeholder={"Dowolny lub wybierz..."}
            />
            {errors.position && <p className="help-block text-danger">{errors.position}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="position" md={3} className="text-right">
            <FormattedMessage id="redirect.redirectForm.label.position" />
            <i id="survey-redirect-position" className="ml-2 fas fa-question-circle"/>
            <Tooltip
              delay={0}
              placement="bottom"
              isOpen={this.state.positionTooltip}
              target={'survey-redirect-position'}
              toggle={() => this.toggle('positionTooltip')}
            >
              <FormattedMessage id='redirect.redirectForm.tooltip.position'/>
            </Tooltip>
          </Label>
          <Col md={9}>
            <InputNumber onChange={value => this.onChange({target: {name: 'position', value}})} value={redirect.position} />
            {errors.position && <p className="help-block text-danger">{errors.position}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="max" md={3} className="text-right">
            <FormattedMessage id="redirect.redirectForm.label.max" />
            <i id="survey-redirect-max" className="ml-2 fas fa-question-circle"/>
            <Tooltip
              delay={0}
              placement="bottom"
              isOpen={this.state.maxTooltip}
              target={'survey-redirect-max'}
              toggle={() => this.toggle('maxTooltip')}
            >
              <FormattedMessage id='redirect.redirectForm.tooltip.max'/>
            </Tooltip>
          </Label>
          <Col md={9}>
            <InputNumber onChange={value => this.onChange({target: {name: 'max', value}})} value={redirect.max} />
            {errors.max && <p className="help-block text-danger">{errors.max}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="target" md={3} className="text-right">
            <FormattedMessage id="redirect.redirectForm.label.target" />
            <i id="survey-redirect-target" className="ml-2 fas fa-question-circle"/>
            <Tooltip
              delay={0}
              placement="bottom"
              isOpen={this.state.targetTooltip}
              target={'survey-redirect-target'}
              toggle={() => this.toggle('targetTooltip')}
            >
              <FormattedMessage id='redirect.redirectForm.tooltip.target'/>
            </Tooltip>
          </Label>
          <Col md={9}>
            <Input type="text" name="target" id="target" value={redirect.target} invalid={!!errors.target} onChange={this.onChange}/>
            {errors.target && <p className="help-block text-danger">{errors.target}</p>}
            <small>W adresie przekierowania można wstawić parametry które zostały przekazane w linku ankiety</small><br />
            <small><strong>Link do ankiety:</strong> https://app.ankieteo.pl/s/adres-do-ankiety?wiek=35&plec=f</small><br />
            <small><strong>Adres:</strong> https://example.com?wiek=[params.wiek]&plec=[params.plec]</small>
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

RedirectForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  redirect: PropTypes.shape({
    position: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    target: PropTypes.string.isRequired,
    status: PropTypes.number,
  }),
};

export default RedirectForm;