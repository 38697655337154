import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Tooltip} from "reactstrap";
import classnames from "classnames";
import ColorPickerModal from "../../../../../components/ColorPickerModal";

const KEY_BG = '$survey-nav-btnNext-bgColor';
const KEY_BG_HOVER = '$survey-nav-btnNext-bgColor-hover';
const KEY_COLOR = '$survey-nav-btnNext-color';
const KEY_COLOR_HOVER = '$survey-nav-btnNext-color-hover';

class ButtonNext extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        [KEY_BG]: props.ownStyles[KEY_BG] ? props.ownStyles[KEY_BG] : false,
        [KEY_BG_HOVER]: props.ownStyles[KEY_BG_HOVER] ? props.ownStyles[KEY_BG_HOVER] : false,
        [KEY_COLOR]: props.ownStyles[KEY_COLOR] ? props.ownStyles[KEY_COLOR] : false,
        [KEY_COLOR_HOVER]: props.ownStyles[KEY_COLOR_HOVER] ? props.ownStyles[KEY_COLOR_HOVER] : false,
      },
      modalOpen: {
        bg: false,
        bgHover: false,
        color: false,
        colorHover: false,
      },
      tooltip: {
        bg: false,
        bgHover: false,
        color: false,
        colorHover: false,
      }
    };

    this.getDefaultValue = this.getDefaultValue.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(key){
    this.setState({
      tooltip: {
        ...this.state.tooltip,
        [key]: !this.state.tooltip[key]
      }
    })
  }

  getDefaultValue(key){
    return this.props.ownStylesDefault[key];
  }

  clearValues(){
    this.setState({
      values: {
        [KEY_BG]: '',
        [KEY_BG_HOVER]: '',
        [KEY_COLOR]: '',
        [KEY_COLOR_HOVER]: '',
      }
    });

    this.props.handlerUpdate({[KEY_BG]: ''});
    this.props.handlerUpdate({[KEY_BG_HOVER]: ''});
    this.props.handlerUpdate({[KEY_COLOR]: ''});
    this.props.handlerUpdate({[KEY_COLOR_HOVER]: ''});
  }

  updateValue(key, value){
    this.setState({
      values: {
        ...this.state.values,
        [key]: value
      }
    });

    this.props.handlerUpdate({[key]: value});
  }

  render() {
    const {values, modalOpen} = this.state;
    const inactive =
      [false, ''].indexOf(values[KEY_BG]) >= 0
      && [false, ''].indexOf(values[KEY_BG_HOVER]) >= 0
      && [false, ''].indexOf(values[KEY_COLOR]) >= 0
      && [false, ''].indexOf(values[KEY_COLOR_HOVER]) >= 0
    ;

    return (
      <Row className={classnames({
        'py-1 px-3 align-items-center': true,
        'inactive': inactive,
      })}>
        <label className="col col-5 mb-0">Przycisk "Dalej" {!inactive && <i className="icon-clear-value fas fa-trash float-right" onClick={() => this.clearValues()} />}</label>
        <Col className="col-7 d-flex">
          <div className="d-flex">
            <div className="d-flex">
              <div id="key-next-bg" className="mr-2">
                <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, bg: true}})} style={{
                  backgroundColor: values[KEY_BG] ? values[KEY_BG] : this.getDefaultValue(KEY_BG),
                }} />
                {modalOpen.bg && <ColorPickerModal
                  color={values[KEY_BG] ? values[KEY_BG] : this.getDefaultValue(KEY_BG)}
                  onClose={() => this.setState({modalOpen: false})}
                  onSelect={value => {
                    this.setState({modalOpen: {...modalOpen, bg: false}});
                    this.updateValue(KEY_BG, value);
                  }}
                />}
                <Tooltip
                  delay={0}
                  placement="top"
                  isOpen={this.state.tooltip.bg}
                  target={'key-next-bg'}
                  toggle={() => this.toggleTooltip('bg')}
                >
                  Domyślny kolor tła
                </Tooltip>
              </div>

              <div id="key-next-color" className="mr-2">
                <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, color: true}})} style={{
                  backgroundColor: values[KEY_COLOR] ? values[KEY_COLOR] : this.getDefaultValue(KEY_COLOR),
                }} />
                {modalOpen.color && <ColorPickerModal
                  color={values[KEY_COLOR] ? values[KEY_COLOR] : this.getDefaultValue(KEY_COLOR)}
                  onClose={() => this.setState({modalOpen: false})}
                  onSelect={value => {
                    this.setState({modalOpen: {...modalOpen, color: false}});
                    this.updateValue(KEY_COLOR, value);
                  }}
                />}
                <Tooltip
                  delay={0}
                  placement="top"
                  isOpen={this.state.tooltip.color}
                  target={'key-next-color'}
                  toggle={() => this.toggleTooltip('color')}
                >
                  Domyślny kolor tekstu
                </Tooltip>
              </div>
            </div>

            <div className="d-flex ml-5">
              <div id="key-next-bgHover" className="mr-2">
                <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, bgHover: true}})} style={{
                  backgroundColor: values[KEY_BG_HOVER] ? values[KEY_BG_HOVER] : this.getDefaultValue(KEY_BG_HOVER),
                }} />
                {modalOpen.bgHover && <ColorPickerModal
                  color={values[KEY_BG_HOVER] ? values[KEY_BG_HOVER] : this.getDefaultValue(KEY_BG_HOVER)}
                  onClose={() => this.setState({modalOpen: false})}
                  onSelect={value => {
                    this.setState({modalOpen: {...modalOpen, bgHover: false}});
                    this.updateValue(KEY_BG_HOVER, value);
                  }}
                />}
                <Tooltip
                  delay={0}
                  placement="top"
                  isOpen={this.state.tooltip.bgHover}
                  target={'key-next-bgHover'}
                  toggle={() => this.toggleTooltip('bgHover')}
                >
                  Kolor tła po najechaniu myszką
                </Tooltip>
              </div>

              <div id="key-next-colorHover" className="mr-2">
                <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, colorHover: true}})} style={{
                  backgroundColor: values[KEY_COLOR_HOVER] ? values[KEY_COLOR_HOVER] : this.getDefaultValue(KEY_COLOR_HOVER),
                }} />
                {modalOpen.colorHover && <ColorPickerModal
                  color={values[KEY_COLOR_HOVER] ? values[KEY_COLOR_HOVER] : this.getDefaultValue(KEY_COLOR_HOVER)}
                  onClose={() => this.setState({modalOpen: false})}
                  onSelect={value => {
                    this.setState({modalOpen: {...modalOpen, colorHover: false}});
                    this.updateValue(KEY_COLOR_HOVER, value);
                  }}
                />}
                <Tooltip
                  delay={0}
                  placement="top"
                  isOpen={this.state.tooltip.colorHover}
                  target={'key-next-colorHover'}
                  toggle={() => this.toggleTooltip('colorHover')}
                >
                  Kolor tekstu po najechaniu myszką
                </Tooltip>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

ButtonNext.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
};

export default ButtonNext;