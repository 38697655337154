import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input} from "reactstrap";
import {injectIntl} from "react-intl";
import QuestionAnswers from "../../../../Modals/QuestionAnswers";
import QuestionAnswer from "../../../../Modals/QuestionAnswer";
import QuestionBaskets from "../../../../Modals/QuestionBaskets";
import QuestionBasket from "../../../../Modals/QuestionBasket";
import {FormattedMessage} from "react-intl";

const detailTypes = ['selected', 'notSelected', 'answerThrown', 'thrownToBasket'];
const condTypes = ['or', 'and', 'minMax'];

class Basket extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpenDropdownCondType: false,
      isOpenDropdownDetailType: false,
    };

    this.toggleIsOpenDropdownCondType = this.toggleIsOpenDropdownCondType.bind(this);
    this.toggleIsOpenDropdownDetailType = this.toggleIsOpenDropdownDetailType.bind(this);
  }

  componentWillMount() {
    const {condQuestion} = this.props;
    let condQuestionChanged = condQuestion;

    if(_.isUndefined(condQuestion.answers)){ condQuestionChanged.answers = []; }
    if(_.isUndefined(condQuestion.answerThrown)){ condQuestionChanged.answerThrown = {answer: null, baskets: []}; }
    if(_.isUndefined(condQuestion.thrownToBasket)){ condQuestionChanged.thrownToBasket = {basket: null, answers: []}; }
    if(_.isUndefined(condQuestion.condType) || !condTypes.includes(condQuestion.condType)){ condQuestionChanged.condType = condTypes[0]; }
    if(_.isUndefined(condQuestion.detailType) || !detailTypes.includes(condQuestion.detailType)){ condQuestionChanged.detailType = detailTypes[0]; }

    if(condQuestion !== condQuestionChanged){
      Basket.propTypes.handleCondChanged(condQuestionChanged);
    }
  }

  toggleIsOpenDropdownCondType(){
    this.setState({
      isOpenDropdownCondType: !this.state.isOpenDropdownCondType,
    })
  }

  toggleIsOpenDropdownDetailType(){
    this.setState({
      isOpenDropdownDetailType: !this.state.isOpenDropdownDetailType,
    })
  }

  render() {
    const {isOpenDropdownDetailType, isOpenDropdownCondType} = this.state;
    const {intl, condQuestion, changeable} = this.props;

    const condSelect = <React.Fragment>
      {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenDropdownCondType} toggle={this.toggleIsOpenDropdownCondType}>
      <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Basket.condType-' + condQuestion.detailType + '-' + condQuestion.condType})}</DropdownToggle>
      <DropdownMenu>
        {condTypes.map(condType => <DropdownItem tag="a" key={condType} onClick={() => this.props.handleCondChanged({
          ...condQuestion,
          condType,
        })}><h5>{intl.formatMessage({id: 'filter.condDetail.Basket.condType-' + condQuestion.detailType + '-' + condType})}</h5></DropdownItem>)}
      </DropdownMenu>
    </Dropdown> : <span>{intl.formatMessage({id: 'filter.condDetail.Basket.condType-' + condQuestion.detailType + '-' + condQuestion.condType})} </span>}

      {condQuestion.condType === 'minMax' && <React.Fragment>
        <FormattedMessage id="condQuestion-minMax-min"/>
        {changeable ? <Input
          type="number"
          className="d-inline mx-2"
          size="sm"
          style={{width: 50}}
          value={condQuestion.min} onChange={e => this.props.handleCondChanged({
          ...condQuestion,
          min: parseInt(e.target.value),
        })} /> : <span className="mx-1">{condQuestion.min}</span>}

        <FormattedMessage id="condQuestion-minMax-max"/>
        {changeable ? <Input
          type="number"
          className="d-inline ml-2"
          size="sm"
          style={{width: 50}}
          value={condQuestion.max} onChange={e => this.props.handleCondChanged({
          ...condQuestion,
          max: parseInt(e.target.value),
        })} /> : <span className="mx-1">{condQuestion.max}</span>}
      </React.Fragment>}

    </React.Fragment>;

    const questionAnswersSelect = <QuestionAnswers
      answers={condQuestion.answers.map(answer => answer.answer)}
      question={condQuestion.question}
      handleAnswersChanged={answersArray => {
        let answers = answersArray.map(answer => {
          return {answer}
        });
        this.props.handleCondChanged({...condQuestion, answers});
      }}
      changeable={changeable}
    />;

    const questionAnswersThrownToBasketSelect = <QuestionAnswers
      answers={condQuestion.thrownToBasket.answers || []}
      question={condQuestion.question}
      handleAnswersChanged={answers => {
        this.props.handleCondChanged({...condQuestion, thrownToBasket: {...condQuestion.thrownToBasket, answers}});
      }}
      changeable={changeable}
    />;

    const questionAnswerSelect = <QuestionAnswer
      answer={condQuestion.answerThrown.answer}
      question={condQuestion.question}
      handleAnswerChanged={answer => {
        this.props.handleCondChanged({...condQuestion, answerThrown: {...condQuestion.answerThrown, answer}});
      }}
      changeable={changeable}
    />;

    const questionBasketsSelect = <QuestionBaskets
      baskets={condQuestion.answerThrown.baskets || []}
      question={condQuestion.question}
      handleBasketsChanged={baskets => {
        this.props.handleCondChanged({...condQuestion, answerThrown: {...condQuestion.answerThrown, baskets}});
      }}
      changeable={changeable}
    />;

    const questionBasketSelect = <QuestionBasket
      basket={condQuestion.thrownToBasket.basket}
      question={condQuestion.question}
      handleBasketChanged={basket => {
        this.props.handleCondChanged({...condQuestion, thrownToBasket: {...condQuestion.thrownToBasket, basket}});
      }}
      changeable={changeable}
    />;

    return (
      <React.Fragment>
        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenDropdownDetailType} toggle={this.toggleIsOpenDropdownDetailType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Basket.detailType-' + condQuestion.detailType})}</DropdownToggle>
          <DropdownMenu>
            {detailTypes.map(detailType => <DropdownItem tag="a" key={detailType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              detailType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.Basket.detailType-' + detailType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condDetail.Basket.detailType-' + condQuestion.detailType})} </span>}

        {(condQuestion.detailType === 'selected' || condQuestion.detailType === 'notSelected') && <React.Fragment>
          {condSelect}
          {questionAnswersSelect}
        </React.Fragment>}

        {condQuestion.detailType === 'answerThrown' && <React.Fragment>
          {questionAnswerSelect}
          <FormattedMessage id="filter.condDetail.Basket.detailType-answerThrown-toBasket">{msg => <span>{msg} </span>}</FormattedMessage>
          {questionBasketsSelect}
        </React.Fragment>}

        {condQuestion.detailType === 'thrownToBasket' && <React.Fragment>
          {questionBasketSelect}
          {condSelect}
          {questionAnswersThrownToBasketSelect}
        </React.Fragment>}

      </React.Fragment>
    );
  }
}

Basket.defaultProps = {
  changeable: true
};

Basket.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number.isRequired,
    condType: PropTypes.oneOf(condTypes),
    detailType: PropTypes.oneOf(detailTypes),
    answers: PropTypes.arrayOf(PropTypes.shape({
      answer: PropTypes.number.isRequired,
    })),
    answerThrown: PropTypes.shape({
      answer: PropTypes.number,
      baskets: PropTypes.array,
    }),
    thrownToBasket: PropTypes.shape({
      basket: PropTypes.number,
      answers: PropTypes.array,
    }),
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(Basket);