import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from "reactstrap";
import {FormattedMessage} from "react-intl";
import SurveyTemplatesList from "./SurveyTemplatesList";
import SurveyTemplatesCreateButton from "./SurveyTemplatesCreateButton";

class SurveyTemplates extends Component {
  constructor(props) {
    super(props);

    this.list = React.createRef();
  }

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">
                    <FormattedMessage id="admin.surveyTemplates.cardHeader" />
                  </h2>
                </div>
                <div className="direction-right">
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <SurveyTemplatesCreateButton handleChange={() => this.list.current.fetchData({page: this.list.current.state.page, pageSize: this.list.current.state.pageSize, sorted: this.list.current.state.sorted})}/>
              <SurveyTemplatesList ref={this.list}/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default SurveyTemplates;