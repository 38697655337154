import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../../components/Button";
import {FormattedMessage} from "react-intl";
import api from "../../../api";

const WithReports = props => {
  const [loading, setLoading] = React.useState(false);
  const [reportsCount, setReportsCount] = React.useState(0);

  React.useEffect(() => {
    setLoading(true);

    api.user.get.reportsCount()
      .then(reportsCount => setReportsCount(reportsCount))
      .finally(() => setLoading(false))
  }, []);

  return (
    <Button
      className="ml-2"
      size="sm"
      color={props.withReports ? 'primary' : 'secondary'}
      onClick={() => {
        props.onChange(!props.withReports);
      }}
    >
      <i className="fas fa-exclamation-triangle mr-2" />
      <FormattedMessage id="surveys.button.reports"/>
      <span className="ml-1">({loading ? <i className="fas fa-spin fa-spinner" /> : reportsCount})</span>
    </Button>
  );
};

WithReports.propTypes = {
  onChange: PropTypes.func.isRequired,
  withReports: PropTypes.bool.isRequired
};

export default WithReports;