import axios from "axios";
import {toast} from "react-toastify";
import setAuthorizationHeader from "./setAuthorizationHeader";
import appRoutes from "../appRoutes";


axios.interceptors.response.use((response) => {

  if('messages' in response.data){
    response.data.messages.map((msg) => {
      let type = 'success';
      if('type' in msg)
        type = msg.type;

      switch (type){
        case 'success':
          toast.success(msg.text);
          break;
        case 'notify':
          toast.notify(msg.text);
          break;
        case 'error':
          toast.error(msg.text);
          break;
        case 'info':
          toast.info(msg.text);
          break;
        case 'warn':
          toast.warn(msg.text);
          break;
        case 'clear':
          toast.clear(msg.text);
          break;
      }
    })
  }

  return response;
}, function (error) {
  if('action' in error.response.data){
    switch (error.response.data.action) {
      case 'passwordChangeRequired': {
        if(
          window.location.pathname !== appRoutes.account.passwordChangeRequired
          && window.location.pathname !== appRoutes.users.passwordChangeRequired
        ){
          window.location = appRoutes.users.passwordChangeRequired;
        }

        break;
      }
    }
  }

  if('error' in error.response.data){
    toast.error(error.response.data.error.message)
  }

  if('errors' in error.response.data){
    if('children' in error.response.data.errors){

    }else{
      error.response.data.errors.map(error => {
        toast.error(error.m)
      })
    }
  }

  if('forceLogout' in error.response.data){
    localStorage.removeItem('jwt');
    setAuthorizationHeader();

    window.location = appRoutes.users.login;
  }

  return Promise.reject(error);
});
