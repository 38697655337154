import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";

class SurveyBgSize extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectMenuOpen: false,
      value: props.ownStyles[props.styleKey] ? props.ownStyles[props.styleKey] : false,
    };

    this.getDefaultValue = this.getDefaultValue.bind(this);
  }

  getDefaultValue(){
    return this.props.ownStylesDefault[this.props.styleKey];
  }

  updateValue(value){
    this.setState({
      value
    });

    this.props.handlerUpdate({[this.props.styleKey]: value});
  }

  render() {
    const {value, selectMenuOpen} = this.state;
    const inactive = [false, ''].indexOf(value) >= 0;

    const options = ['auto', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'];

    return (
      <Row className={classnames({
        'py-1 px-3 align-items-center': true,
        'inactive': inactive && !selectMenuOpen,
      })}>
        <label className="col col-4">{this.props.label} {!inactive && <i className="icon-clear-value fas fa-trash float-right" onClick={() => this.updateValue('')} />}</label>
        <Col className="col-8">
          <Select
            classNames="w-100"
            onMenuOpen={() => this.setState({selectMenuOpen: true})}
            onMenuClose={() => this.setState({selectMenuOpen: false})}
            value={{
              label: value || this.getDefaultValue(),
              value: value || this.getDefaultValue(),
            }}
            onChange={(e) => this.updateValue(e.value)}
            options={options.map(value => {
              return {
                label: value,
                value: value
              }
            })}
          />
        </Col>
      </Row>
    );
  }
}

SurveyBgSize.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  ownStyles: PropTypes.object.isRequired,
  ownStylesDefault: PropTypes.object.isRequired,
  styleKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default SurveyBgSize;