import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import { updateReport } from 'reducers/reports/actions';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import PropTypes from 'prop-types';

class SurveysReportsButtonFix extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false
    };
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  fixReport() {
    this.setState({...this.state, saving: true});

    let data = {
      id: this.props.id,
      data: {
        fixed: true
      }
    };

    this.props.updateReport(data);
  }

  render() {
    const { saving } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()}>
      <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id="surveysReports.surveysReportsButtonFix.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="surveysReports.surveysReportsButtonFix.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="surveysReports.surveysReportsButtonFix.fix">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={() => this.fixReport()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModal()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <FormattedMessage id="surveysReports.surveysReportsButtonFix.button">{
          (message) =>
            <Button color="primary" size="sm" onClick={() => this.toggleModal()}><i className="fas fa-times" /> {message}</Button>
        }</FormattedMessage>
        {modal}
      </React.Fragment>
    )
  }
}

SurveysReportsButtonFix.propTypes = {
  id: PropTypes.number.isRequired
};

export default connect(null, {updateReport})(SurveysReportsButtonFix);