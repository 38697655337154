import api from "api";

export const REQUEST = 'Survey/data/pipings/REQUEST';
export const SUCCESS = 'Survey/data/pipings/SUCCESS';
export const ERROR = 'Survey/data/pipings/ERROR';

export const SET = 'Survey/data/pipings/SET';
export const DELETED = 'Survey/data/pipings/DELETED';
export const ADDED = 'Survey/data/pipings/ADDED';
export const UPDATED = 'Survey/data/pipings/UPDATED';

export function setPipings(pipings) {
  return {
    type: SET,
    pipings
  }
}

export function pipingDeleted(piping) {
  return {
    type: DELETED,
    piping
  }
}
export function pipingAdded(piping) {
  return {
    type: ADDED,
    piping
  }
}

export function pipingUpdated(piping, expiredId) {
  return {
    type: UPDATED,
    piping,
    expiredId
  }
}

export function deletePiping(data) {
  return dispatch => {
    return api.piping.delete(data).then(() => {
      dispatch(pipingDeleted({
        id: data.id,
      }))
    })
  }
}

export function addPiping(data) {
  return dispatch => {
    return api.piping.post(data).then((piping) => {
      dispatch(pipingAdded(piping))
    })
  }
}

export function updatePiping(data) {
  return dispatch => {
    return api.piping.patch(data).then(piping => {
      dispatch(pipingDeleted({
        id: data.piping.id,
      }));
      dispatch(pipingAdded(piping))
    })
  }
}