import React from 'react';
import Tooltip from "../../../../components/Tooltip";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../components/Button";
import api from "../../../../api";

const PayPalPayout = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [sending, setSending] = React.useState(false);

  const payout = () => {
    setSending(true);

    api.admin.userPrize.payoutPayPal(props.userPrize.id)
      .then(res => {
        setModalOpen(false)
        props.onSuccess()
      })
      .finally(() => setSending(false))
  }

  return (
    <React.Fragment>
      <i id={`userPrize-${props.userPrize.id}-paypal-payout`} className="fab fa-paypal pointer" onClick={toggleModalOpen} />
      <Tooltip id={`userPrize-${props.userPrize.id}-paypal-payout`} msg={'Wypłać przez PayPal'} />
      {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
        <ModalHeader toggle={toggleModalOpen} tag="h2">Wypłać przez PayPal</ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          Czy na pewno wykonać przelew na podany adres e-mail?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModalOpen}>Anuluj</Button>
          <Button
            color="primary"
            loading={sending}
            onClick={payout}
          >Wypłać</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

PayPalPayout.propTypes = {

};

export default PayPalPayout;