import React, {Component} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import Pagination from "../../../components/Pagination";
import {FormattedMessage} from "react-intl";
import ReactTable from "react-table";
import api from "../../../api";


class Billing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      total: 0,
      page: 0,
      pageSize: 10
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    document.title = 'Ankieteo - Zamówienia';
    this.fetchData({page: this.state.page, pageSize: this.state.pageSize});
  }

  fetchData(state){
    api.user.get.transactions(state.page, state.pageSize)
    .then(data => {
      this.setState({
        data: data.transactions,
        total: data.count,
        page: state.page,
        pageSize: state.pageSize,
      })
    })
    .catch(e => {
      console.log('e', e);
    });
  }

  render() {
    const {data} = this.state;

    const columns = [
      {
        accessor: 'transactionId',
        Header: 'Id transakcji',
        width: 250,
      },
      {
        id: "price",
        Header: 'Cena',
        accessor: row => {
          return (row.price / 100).toString() + " PLN"
        },
      },
      {
        id: "type",
        Header: 'Typ zamówienia',
        accessor: row => {
          return row.type.charAt(0).toUpperCase() + row.type.slice(1);
        },
      },
      {
        id: "period",
        accessor: row => {
          switch(row.period){
            case 'month': {
              return 'Miesiąc';
            }
            case 'quarterly': {
              return 'Kwartał';
            }
            case 'annual': {
              return 'Rok';
            }
          }
        },
        Header: 'Okres planu',
      },
      {
        id: 'status',
        accessor: row => {
          switch(row.status){
            case '1': {
              return 'Rozpoczęta';
            }
            case '2': {
              return 'Zakończona';
            }
            case '3': {
              return 'Odrzucona';
            }
          }
        },
        Header: 'Status płatności',
      },
      {
        accessor: 'createdAt',
        Header: 'Data',
        width: 125,
      },
    ];

    return <Card className="h-100 mb-0">
      <CardHeader><h2><i className="fas fa-credit-card mr-2" /> Zamówienia</h2></CardHeader>
      <hr className="my-0" />
      <CardBody>
        <ReactTable
          minRows={0}
          className={'table-wrap mt-4'}
          data={data}
          columns={columns}
          PaginationComponent={Pagination}
          onFetchData={this.fetchData}
          rowsCount={this.state.total}
          noDataText={'Brak danych'}
          defaultPageSize={10}
          manual
        />
      </CardBody>
    </Card>;
  }
}

export default Billing;