import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EditAction from "./Actions/EditAction";

class AdminsActions extends Component {

  render() {
    return (
      <div>
        <EditAction user={this.props.user} handleChange={this.props.handleChange}/>
      </div>
    )
  }
}

AdminsActions.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default AdminsActions;