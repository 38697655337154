import React, {Component} from 'react';
import {connect} from "react-redux";
import SettingsLang from "./SettingsLang";
import PropTypes from "prop-types";
import SettingsAnalysisName from "./SettingsAnalysisName";
import SettingsDeleteButton from "./SettingsDeleteButton";

class Analyses extends Component {

  render() {
    const {analysis, analyses, languages} = this.props;

    return (
      <React.Fragment>
        <SettingsLang
          analysisId={analysis.id}
          lang={analysis.lang}
          availableLanguages={languages}
          onSaved={this.props.onChange}
        />

        <SettingsAnalysisName
          analysis={analysis}
        />
        <SettingsDeleteButton analysis={analysis} analyses={analyses}/>
      </React.Fragment>
    )
  }
}

Analyses.propTypes = {
  onChange: PropTypes.func.isRequired,
  analyses: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
    languages: state.survey.analysis.data.survey.languages,
  }
}

export default connect(mapStateToProps)(Analyses);