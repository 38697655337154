import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, FormGroup, FormText, Label} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Select from "react-select";

class Open extends PureComponent {

  componentDidMount() {
    const {questionFrom} = this.props;
    if(!questionFrom.fetchType){
      this.props.onChange({fetchType: this.props.configQuestionFrom.fetchTypes[0]});
    }
  }

  render() {
    const {errors, questionFrom, configQuestionFrom} = this.props;

    return (
      <FormGroup row>
        <Label lg={3} className="text-right"><FormattedMessage id="pipingForm.questionFrom.label.fetchType" /></Label>
        <Col lg={9}>
          <Select
            placeholder="Wybierz..."
            value={questionFrom.fetchType ? {value: questionFrom.fetchType, label: <FormattedMessage id={'pipingForm.questionFrom.open.fetchType.' + questionFrom.fetchType}>{(msg) => msg}</FormattedMessage>} : null}
            onChange={(e) => this.props.onChange({fetchType: e.value})}
            options={configQuestionFrom.fetchTypes.map(type => {
              return {
                value: type,
                label: <FormattedMessage id={'pipingForm.questionFrom.open.fetchType.' + type}>{(msg) => msg}</FormattedMessage>,
              }
            })}
          />
          <FormText color="danger">{_.has(errors, 'questionFrom.fetchType') ? errors.questionFrom.fetchType.join('; ') : false}</FormText>
        </Col>
      </FormGroup>
    );
  }
}

Open.propTypes = {
  configQuestionFrom: PropTypes.shape({
    fetchTypes: PropTypes.array.isRequired,
  }).isRequired,
  question: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  questionFrom: PropTypes.shape({
    fetchType: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Open;