import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import classnames from "classnames";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";
import {Col, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import SelectBaskets from "../../../../../../../Structure/components/SelectBaskets";

class ColumnThead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      filterExcluding: false,
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterExcludingChange = this.onFilterExcludingChange.bind(this);
  }

  componentWillUnmount() {
    const {question} = this.props;

    this.props.onFilterChange('q' + question.id, false);
    this.props.onFilterChange('q' + question.id + 'excl', false);
  }

  static getColSpan(question){
    return question.answers.length + question.excludings.length;
  }

  onFilterChange(answerId, basketId){
    let {filter} = this.state;
    const {question} = this.props;
    if(basketId === false){
      delete filter[answerId];
    }else{
      filter[answerId] = basketId;
    }

    this.setState({
      filter
    }, () => {
      const key = 'q' + question.id;

      this.props.onFilterChange(key, (this.state.filter === false || _.isEmpty(this.state.filter)) ? false : {
          question: question.id,
          answers: this.state.filter,
        }
      );
    });
  }

  onFilterExcludingChange(value){
    const {question} = this.props;

    if(value.length === 0){
      value = false;
    }

    this.setState({
      filterExcluding: value
    }, () => {
      const key = 'q' + question.id + 'excl';

      this.props.onFilterChange(key, this.state.filterExcluding === false ? false : {
          question: question.id,
          excludings: this.state.filterExcluding,
        }
      );
    });
  }

  render() {
    const {filter, filterExcluding} = this.state;
    const {question} = this.props;

    return <React.Fragment>
      {question.answers.map((answer, key) => {
        return <React.Fragment key={key.toString()}>
          <th
            className={classnames({
              'text-center pointer': true,
              'border-left': key === 0,
              'border-right': key === question.answers.length-1,
            })}
            onClick={() => filter[answer.id] === undefined && this.setState({filter: {...filter, [answer.id]: {}}}) }
          >
            <div className={classnames({
              'd-flex h-100 text-center': true,
              'flex-column': filter[answer.id] !== undefined,
              'align-items-center': filter[answer.id] === undefined,
            })}>
              <div className="w-100">{striptags(answer.content)}</div>
              {(filter[answer.id] !== undefined) && <div className="d-flex">
                <SelectBaskets
                  isClearable={true}
                  className="w-100"
                  isMulti={false}
                  questionId={question.id}
                  onChange={basketId => this.onFilterChange(answer.id, basketId)}
                />
              </div>}
            </div>
          </th>
        </React.Fragment>
      })}
      {question.excludings.length > 0 && <th
        className="text-center border-left border-right pointer"
        onClick={() => filterExcluding === false && this.setState({filterExcluding: null})}
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filter !== false,
          'align-items-center': filter === false,
        })}>
          <div className="w-100">Wykluczająca</div>
          {filterExcluding !== false && <SelectExcludings
            isClearable={true}
            isMulti={true}
            value={filterExcluding}
            questionId={question.id}
            onChange={this.onFilterExcludingChange}
          />}
        </div>
      </th>}
    </React.Fragment>;
  }
}

ColumnThead.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['basket']),
    answers: PropTypes.arrayOf(PropTypes.shape({

    }),),
    excludings: PropTypes.array,
  }).isRequired,
};

export default ColumnThead;