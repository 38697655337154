import React, {Component} from 'react';
import PropTypes from "prop-types";

import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Tooltip
} from "reactstrap";
import InputNumber from "components/InputNumber";

class BlockBadge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };

    this.badgeTexts = this.badgeTexts.bind(this);
  }

  badgeTexts(){
    const {block} = this.props;
    let texts = {
      badge: '',
      tooltip: '',
    };

    if(block.type === 'AND'){
      texts.badge = '&';
      texts.tooltip = 'Wszystkie warunki muszą być spełnione';
    }else if(block.type === 'OR'){
      texts.badge = '||';

      if(block.maxCondsToMet === 0){
        texts.tooltip = 'Liczba warunków do spełnienia: ' + block.minCondsToMet;
      }else{
        texts.tooltip = block.minCondsToMet + ' z ' + block.maxCondsToMet + ' warunków musi zostać spełnionych';
      }
    }

    return texts;
  }

  render() {
    const {block, index} = this.props;
    const {dropdownOpen, tooltipOpen} = this.state;

    const badgeTexts = this.badgeTexts();

    return (
        <Dropdown className="mr-2 mb-1" isOpen={dropdownOpen} toggle={() => this.setState({dropdownOpen: !dropdownOpen})}>
          <DropdownToggle id={'dropdown' + index} className="btn-xs" color="secondary">
            <span>{badgeTexts.badge}</span>

            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltipOpen}
              target={'dropdown' + index}
              toggle={() => this.setState({tooltipOpen: !tooltipOpen})}
            >{badgeTexts.tooltip}</Tooltip>
          </DropdownToggle>
          <DropdownMenu className="p-3">
            <ButtonGroup className="w-100 mb-1">
              <Button color={block.type === 'AND' ? 'primary' : 'secondary'} onClick={() => this.props.handleBlockChanged({...block, type: 'AND'})}>Oraz</Button>
              <Button color={block.type === 'OR' ? 'primary' : 'secondary'} onClick={() => this.props.handleBlockChanged({...block, type: 'OR'})}>Lub</Button>
            </ButtonGroup>

            {block.type === 'OR' && (
              <React.Fragment>
                <InputNumber className="mb-1" min={1} max={block.children.length + block.condsQuestion.length} onChange={(minCondsToMet) => this.props.handleBlockChanged({...block, minCondsToMet})} value={block.minCondsToMet}/>
                <InputNumber min={0} max={block.children.length + block.condsQuestion.length} onChange={(maxCondsToMet) => this.props.handleBlockChanged({...block, maxCondsToMet})} value={block.maxCondsToMet}/>
              </React.Fragment>
            )}
          </DropdownMenu>
        </Dropdown>
    );
  }
}

BlockBadge.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.oneOf(['AND', 'OR']).isRequired,
    minCondsToMet: PropTypes.number.isRequired,
    maxCondsToMet: PropTypes.number.isRequired,
  }).isRequired,
  handleBlockChanged: PropTypes.func.isRequired,
};

export default BlockBadge;