import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from "prop-types";
import {Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {updateAnswer} from "scenes/Survey/Structure/data/answers/actions";


class AnswerDescription extends Component {
  constructor(props){
    super(props);

    this.state = {
      saving: false,
      data: {
        description: props.answer.description || ''
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      data: {
        ...this.state.data,
        description: nextProps.answer.description || ''
      }
    })
  }

  onChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        description: e.target.value
      }
    });
  }

  onBlur(){
    const {data} = this.state;
    const {answer} = this.props;

    this.setState({
      saving: true
    });

    this.props.updateAnswer({
      id: answer.id,
      answer: data
    }).then(() => {
      this.setState({saving: false});
    }).catch(() => {
      this.setState({saving: false});
    })
  }

  render() {
    const {data, saving} = this.state;

    return (
      <FormattedMessage id="answer.image.description.placeholder">{placeholder => <Input
        className={"mt-2"}
        disabled={saving}
        type="text"
        bsSize={"sm"}
        placeholder={placeholder}
        value={data.description}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />}</FormattedMessage>
    );
  }
}

AnswerDescription.propTypes = {
  answerId: PropTypes.number.isRequired,
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
  }),
  updateAnswer: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    answer: Object.values(state.survey.structure.data.answers).find(answer => answer.id === props.answerId)
  }
}

export default connect(mapStateToProps, {updateAnswer})(AnswerDescription);