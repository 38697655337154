import React, {Component} from 'react';
import {Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {setSearchInStructure} from "../../../../actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class SearchInStructure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }
  render() {
    const {value} = this.state;

    return (
      <FormattedMessage id="topbar.searchInStructure.input.placeholder">{(msg) =>
        <Input tyle="text" className={"w-100 ml-lg-3 mr-3 mb-0" + (this.props.showOnMobile ? " d-block" : " d-none d-lg-block")} placeholder={msg} value={value} onChange={e => {
          // let value = e.target.value.replace(/[^a-zA-Z0-9\s+]+/gi, '').replace(/\s\s+/g, ' ')
          let value = e.target.value;

          this.setState({
            value,
          }, () => {
            this.props.setSearchInStructure(value);
          });
        }}/>}
      </FormattedMessage>
    )
  }
}

SearchInStructure.defaultProps = {
  showOnMobile: false
};

SearchInStructure.propTypes = {
  showOnMobile: PropTypes.bool.isRequired
};

export default connect(null, {setSearchInStructure})(SearchInStructure);