import { reducer as dataReducer } from './data/reducer';
import {REQUEST, SUCCESS, ERROR} from "scenes/Survey/Structure/data/survey/actions";
import {
  SET_HIDE_IN_STRUCTURE,
  SET_SEARCH_IN_STRUCTURE,
  SET_FILTER_CONDITION_QUESTIONS,
  CLEAR_CLOSED_CARDS,
  SET_FILTER_ACTION_QUESTIONS,
  SET_DISABLED_QUESTIONS,
  SET_SHOW_QUESTIONS_SETTINGS,
  SET_QUESTION_TYPES,
  TOGGLE_SET_CARD,
  TOGGLE_BLOCK_CARD,
  TOGGLE_PAGE_CARD,
  TOGGLE_QUESTION_CARD,
  SET_CLOSED_SETS_CARDS,
  SET_CLOSED_BLOCKS_CARDS,
  SET_CLOSED_PAGES_CARDS,
  SET_CLOSED_QUESTIONS_CARDS,
  SET_LOADING,
  UNSET_LOADING
} from "./actions";
import _ from 'lodash';

const loadingReducer = (state, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case SUCCESS:
    case ERROR:
      return false;
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  hideInStructure: 'none',
  searchInStructure: '',
  filterConditionQuestions: false,
  filterActionQuestions: false,
  disabledQuestions: false,
  questionTypes: [],
  closedSetCards: [],
  closedBlockCards: [],
  closedPageCards: [],
  closedQuestionCards: [],
  showQuestionsSettings: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case UNSET_LOADING:
      return {
        ...state,
        loading: false
      };
    case SET_HIDE_IN_STRUCTURE:
      return {
        ...state,
        hideInStructure: action.hideInStructure
      };
    case SET_SEARCH_IN_STRUCTURE:
      return {
        ...state,
        searchInStructure: action.text
      };
    case SET_FILTER_CONDITION_QUESTIONS:
      return {
        ...state,
        filterConditionQuestions: action.filterConditionQuestions
      };
    case SET_FILTER_ACTION_QUESTIONS:
      return {
        ...state,
        filterActionQuestions: action.filterActionQuestions
      };
    case SET_DISABLED_QUESTIONS:
      return {
        ...state,
        disabledQuestions: action.disabledQuestions
      };
    case SET_SHOW_QUESTIONS_SETTINGS:
      return {
        ...state,
        showQuestionsSettings: action.showQuestionsSettings
      };
    case SET_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: action.questionTypes
      };
    case TOGGLE_SET_CARD:
      const isSetCardClosed = state.closedSetCards.includes(action.setId);

      return {
        ...state,
        closedSetCards: isSetCardClosed ? _.filter(state.closedSetCards, card => card !== action.setId) : [...state.closedSetCards, action.setId]
      };
    case TOGGLE_BLOCK_CARD:
      const isBlockCardClosed = state.closedBlockCards.includes(action.blockId);

      return {
        ...state,
        closedBlockCards: isBlockCardClosed ? _.filter(state.closedBlockCards, card => card !== action.blockId) : [...state.closedBlockCards, action.blockId]
      };
    case TOGGLE_PAGE_CARD:
      const isPageCardClosed = state.closedPageCards.includes(action.pageId);

      return {
        ...state,
        closedPageCards: isPageCardClosed ? _.filter(state.closedPageCards, card => card !== action.pageId) : [...state.closedPageCards, action.pageId]
      };
    case TOGGLE_QUESTION_CARD:
      const isQuestionCardClosed = state.closedQuestionCards.includes(action.questionId);

      return {
        ...state,
        closedQuestionCards: isQuestionCardClosed ? _.filter(state.closedQuestionCards, card => card !== action.questionId) : [...state.closedQuestionCards, action.questionId]
      };
    case SET_CLOSED_SETS_CARDS:

      return {
        ...state,
        closedSetCards: action.setsIds,
        closedBlockCards: [],
        closedPageCards: [],
        closedQuestionCards: []
      };
    case SET_CLOSED_BLOCKS_CARDS:

      return {
        ...state,
        closedSetCards: [],
        closedBlockCards: action.blocksIds,
        closedPageCards: [],
        closedQuestionCards: []
      };
    case SET_CLOSED_PAGES_CARDS:

      return {
        ...state,
        closedSetCards: [],
        closedBlockCards: [],
        closedPageCards: action.pagesIds,
        closedQuestionCards: []
      };
    case SET_CLOSED_QUESTIONS_CARDS:

      return {
        ...state,
        closedSetCards: [],
        closedBlockCards: [],
        closedPageCards: [],
        closedQuestionCards: action.questionsIds
      };
    case CLEAR_CLOSED_CARDS:

      return {
        ...state,
        closedSetCards: [],
        closedBlockCards: [],
        closedPageCards: [],
        closedQuestionCards: []
      };

    default:
      const rest = _.omit(state, Object.keys(initialState));
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: dataReducer(rest.data, action)
      };
  }
};