import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {injectIntl} from "react-intl";
import QuestionAnswers from "../../../../Modals/QuestionAnswers";

const detailTypes = ['selected', 'notSelected'];

class Single extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpenDropdownDetailType: false,
      detailTypes: ['selected', 'notSelected']
    };

    this.toggleIsOpenDropdownDetailType = this.toggleIsOpenDropdownDetailType.bind(this);
  }

  componentWillMount() {
    const {condQuestion} = this.props;
    let condQuestionChanged = condQuestion;

    if(_.isUndefined(condQuestion.answers)){ condQuestionChanged.answers = []; }
    if(_.isUndefined(condQuestion.detailType) || !detailTypes.includes(condQuestion.detailType)){ condQuestionChanged.detailType = detailTypes[0]; }

    if(condQuestion !== condQuestionChanged){
      Single.propTypes.handleCondChanged(condQuestionChanged);
    }
  }

  toggleIsOpenDropdownDetailType(){
    this.setState({
      isOpenDropdownDetailType: !this.state.isOpenDropdownDetailType,
    })
  }

  render() {
    const {isOpenDropdownDetailType} = this.state;
    const {intl, condQuestion, changeable} = this.props;

    return (
      <React.Fragment>
        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenDropdownDetailType} toggle={this.toggleIsOpenDropdownDetailType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Single.detailType-' + condQuestion.detailType})}</DropdownToggle>
          <DropdownMenu>
            {detailTypes.map(detailType => <DropdownItem tag="a" key={detailType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              detailType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.Single.detailType-' + detailType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condDetail.Single.detailType-' + condQuestion.detailType})} </span>}

        <span>{intl.formatMessage({id: 'filter.condDetail.Single.detailType-' + condQuestion.detailType + '-text-answer'})} </span>

        <QuestionAnswers answers={condQuestion.answers.map(answer => answer.answer)} question={condQuestion.question} changeable={changeable} handleAnswersChanged={answersArray => {
          let answers = answersArray.map(answer => {
            return {
              answer
            }
          });

          this.props.handleCondChanged({...condQuestion, answers});
        }} />
      </React.Fragment>
    );
  }
}

Single.defaultProps = {
  changeable: true
};

Single.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number.isRequired,
    detailType: PropTypes.oneOf(detailTypes),
    answers: PropTypes.arrayOf(PropTypes.shape({
      answer: PropTypes.number.isRequired,
    })),
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(Single);