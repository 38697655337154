import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import ReportDownloadsList from "./components/ReportDownloadsList";
import Link from "react-router-dom";
import api from "../../../api";

class ReportDownloads extends Component {
  render() {
    return <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader><h2 className="float-left mr-4">Historia pobrań raportów</h2></CardHeader>
          <hr className="my-0" />
          <CardBody>
            <Row>
              <Col>
                <ReportDownloadsList />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>;
  }
}

export default ReportDownloads;