import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Row, Col, Label} from "reactstrap";
import {updateAnalysis} from "../../../../reducer/analysis/actions";
import StatusesSelect from "components/Survey/Analysis/StatusesSelect";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import AnalysisFilteringAvailable from "components/PlanRestrictionsModals/modals/AnalysisFilteringAvailable";

class FilterStatus extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
    };

    this.handlerOnChange = this.handlerOnChange.bind(this);
  }

  handlerOnChange(filterStatus){
    this.setState({saving: true});

    let data = {
      id: this.props.analysis.id,
      analysis: {
        filterStatus
      }
    };

    this.props.updateAnalysis(data)
      .then(() => {
        this.setState({saving: false});
        this.props.onSaved();
      })
      .catch(() => this.setState({saving: false}));
  }

  render() {
    const {saving} = this.state;

    return (
      <React.Fragment>
        <Row className="mt-4">
          <Col>
            <Label>
              <FormattedMessage id="filterStatus.header" />
            </Label>
          </Col>
        </Row>
        <Row onClick={() => this.props.analysisPlan.filtering ? null : this.props.setPlanRestrictionsModals(AnalysisFilteringAvailable.getName())}>
          <Col>
            <StatusesSelect
              isLoading={saving}
              isDisabled={saving || !this.props.analysisPlan.filtering}
              value={this.props.analysis.filterStatus}
              onChange={this.handlerOnChange}
            />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

FilterStatus.propTypes = {
  onSaved: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
    analysisPlan: state.user.userPlan.plan.analysis
  }
}

export default connect(mapStateToProps, {updateAnalysis, setPlanRestrictionsModals})(FilterStatus);