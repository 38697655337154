import React from "react";
import PropTypes from "prop-types";
import {Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import useAfterUpdateEffect from "../../../utils/useAfterUpdateEffect";

SearchTextInput.defaultProps = {
  className: '',
};

SearchTextInput.propTypes = {
  tableRef: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default function SearchTextInput(props) {
  const [value, setValue] = React.useState('');

  useAfterUpdateEffect(() => {
    if(props.onChange){
      props.onChange(value);
    }
  }, [value])

  const handleOnKeyUp = (e) => {
    let delay = 1000;
    let value = e.target.value;

    switch (e.keyCode) {
      case 13: {
        delay = 1;
        break;
      }
      case 27: {
        delay = 1;
        value = '';
        break;
      }
    }

    setValue(value);

    if(props.tableRef){
      props.tableRef.current.setSearch(value);
      props.tableRef.current.fetchDataDelay(delay);
    }
  };

  return <InputGroup className={'mb-0 ' + props.className}>
    <Input
      placeholder="Szukaj..."
      onKeyUp={handleOnKeyUp}
      onChange={e => setValue(e.target.value)}
      value={value}
    />
    <InputGroupAddon addonType="append" onClick={() => (props.tableRef && props.tableRef.current.fetchDataDelay(1))}><InputGroupText className="cursor-pointer"><i className="fa fa-search" /></InputGroupText></InputGroupAddon>
  </InputGroup>;
};
