import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Row, Col, Form, FormGroup, Input, Label} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {updateProfile} from "reducers/user/actions";
import Switch from "../../../components/Switch";

class RespondentType extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {
        firstname: props.user.firstname || '',
        avatar: props.user.avatar || '',
        helpMode: props.user.helpMode
      },
      errors: {},
      avatarFile: null
    };

    this.fileUploader = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
  }

  onSubmit(e){
    e.preventDefault();
    const formData = new FormData();
    formData.append('firstname', this.state.data.firstname);
    formData.append('helpMode', this.state.data.helpMode);

    if (this.state.avatarFile)
      formData.append('avatar', this.state.avatarFile);

    this.setState({ loading: true });

    this.props.updateProfile(formData)
      .then(() => {
        this.setState({ loading: false, errors: {} })
      })
      .catch(error => {
        this.setState({ loading: false, errors: error.response.data.form.errors });
      });
  }

  onChange(e){
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  }

  fileChangedHandler(e) {
    this.setState({
      data: {
        ...this.state.data,
        avatar: URL.createObjectURL(e.target.files[0])
      },
      avatarFile: e.target.files[0]
    });
  }

  render() {
    const {data, errors, loading} = this.state;

    return (
      <Form onSubmit={this.onSubmit} className="needs-validation">
        <Row className="mb-3">
          <Col md={3}>
            <Label data-role="label" for="input-type-1"><FormattedMessage id="profile.form.respondentType.label.avatar" /></Label>
          </Col>
          <Col md={9}>
            {!data.avatar ? <span onClick={() => this.fileUploader.current.click()}>Kliknij aby ustawić</span> : <img src={data.avatar} alt={data.avatar} className="avatar rounded-img ml-3" onClick={() => this.fileUploader.current.click()}/>}
            <input type="file" onChange={this.fileChangedHandler} ref={this.fileUploader} hidden={true}/>
            {errors.avatar && <div className="invalid-feedback">{errors.avatar.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Label data-role="label" for="firstname"><FormattedMessage id="profile.form.respondentType.label.firstname" /></Label>
          </Col>
          <Col md={9}>
            <FormattedMessage id="profile.form.respondentType.label.firstname">{
              (message) =>
                <Input type="text" id="firstname" name="firstname" className="custom" placeholder={message} value={data.firstname} invalid={!!errors.firstname} onChange={this.onChange}/>
            }</FormattedMessage>
            {errors.firstname && <div className="invalid-feedback">{errors.firstname.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Label data-role="label" for="helpMode"><FormattedMessage id="profile.form.respondentType.label.helpMode" /></Label>
          </Col>
          <Col md={9}>
            <Switch checked={this.state.data.helpMode} onChange={value => this.setState({data: {...this.state.data, helpMode: value}})} />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormattedMessage id="_.button.save">
              {(message) => <LaddaButton
                className="btn btn-success float-right"
                loading={loading}
                data-style={ZOOM_OUT}
              >
                {message}
              </LaddaButton>}
            </FormattedMessage>
          </Col>
        </Row>
      </Form>
    )
  }
}

RespondentType.propTypes = {
  user: PropTypes.shape({
    firstname: PropTypes.string,
    avatar: PropTypes.string
  }).isRequired
};

export default connect(null, {updateProfile})(RespondentType);