import React from 'react';
import PropTypes from 'prop-types';
import {isNull} from "lodash";
import useDidMountEffect from "../utils/useDidMountEffect";
import api from "../api";

const PanelCurrentActiveUsers = props => {
  const [users, setUsers] = React.useState(null);

  useDidMountEffect(() => {
    fetchData();

    setInterval(() => fetchData(), 1000 * 60)
  })

  const fetchData = () => {
    api.user.getActivePanelUsers()
      .then(res => setUsers(res.data.users))
  }

  return <div>
    <i className="fas fa-users mr-1" />
    <span>{isNull(users) ? <i className="fas fa-spin fa-spinner" /> : users}</span>
  </div>;
};

PanelCurrentActiveUsers.propTypes = {

};

export default PanelCurrentActiveUsers;
