import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import api from "api";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import classnames from "classnames";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../../../components/Button";

const ButtonSendPaused = props => {
  const [saving, setSaving] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [sendPaused, setSendPaused] = React.useState(props.collector.sendEmail.sendPaused);

  const toggleSendPaused = () => {
    setSaving(true);

    api.surveyCollector.patch({
      id: props.collector.id,
      surveyCollector: {
        sendEmail: {
          sendPaused: !props.collector.sendEmail.sendPaused
        }
      },
    })
      .then(collector => {
        setSaving(false);
        props.handleUpdate(collector);
      })
      .catch(e => {
        setSaving(false);
      });
  }

  return <React.Fragment>
    <LaddaButton
      className={classnames({
        'btn btn-ladda d-block ml-2': true,
        'btn-danger': sendPaused,
        'btn-success': !sendPaused,
      })}
      loading={saving}
      data-style={ZOOM_OUT}
      onClick={() => {
        if(sendPaused){
          setModalOpen(true);
        }else{
          toggleSendPaused();
        }
      }}
    >{sendPaused
      ? <span>Wysyłka zaproszeń jest wstrzymana, kliknij aby uruchomić</span>
      : <span>Wysyłka zaproszeń jest włączona</span>
    }</LaddaButton>

    {modalOpen && <Modal isOpen toggle={toggleModalOpen} size="lg">
      <ModalHeader toggle={toggleModalOpen}>Potwierdź</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        Czy wykonałe(a)ś test wyglądu wiadomości mailowej?
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => toggleSendPaused()}
        >Tak</Button>
        <Button
          color="warning"
          onClick={() => toggleSendPaused()}
        >Nie, mimo to zrób wysyłkę</Button>
        <Button
          color="secondary"
          onClick={toggleModalOpen}
        >Nie, najpierw wykonam test</Button>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
};

ButtonSendPaused.propTypes = {

};

export default ButtonSendPaused;