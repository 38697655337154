import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import Select from "react-select";
import { connect } from 'react-redux';

class Place extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalPlace: props.place,
    };

    this.toggleModalPlace = this.toggleModalPlace.bind(this);
  }

  toggleModalPlace(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {place, placesCount, changeable} = this.props;
    const {modalOpen, modalPlace} = this.state;
    const selectOptions = _.range(1, placesCount + 1).map(place => {
      return {
        label: place,
        value: place,
      }
    });

    if (!changeable) {
      return Number.isInteger(place) ? place : null;
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalPlace} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalPlace}>Wybierz miejsce</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <Select
          placeholder={"Wybierz miejsce"}
          ref={this.select}
          value={_.find(selectOptions, option => option.value === modalPlace)}
          isOpen={false}
          isMulti={false}
          onChange={data => {
            this.setState({modalPlace: data.value, modalOpen: false}, () => {
              this.props.handlePlaceChanged(data.value);
            });
          }}
          options={selectOptions}
        />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handlePlaceChanged(modalPlace);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={Number.isInteger(place) ? 'secondary' : 'warning'} className="mx-1 pointer" onClick={this.toggleModalPlace}>
          {Number.isInteger(place) ? place : 'Wybierz miejsce...'}
        </Badge>
        {modal}
      </React.Fragment>
    );
  }
}

Place.defaultProps = {
  changeable: true
};

Place.propTypes = {
  handlePlaceChanged: PropTypes.func,
  questionId: PropTypes.number.isRequired,
  place: PropTypes.number,
  changeable: PropTypes.bool
};

function mapStateToProps(state, props) {
  return {
    placesCount: state.survey.structure.data.questions[props.questionId].places
  }
}

export default connect(mapStateToProps)(Place);