import React, {Component} from 'react';
import ChangePasswordAction from './Actions/ChangePasswordAction';
import EditAction from './Actions/EditAction';
import BanEditAction from './Actions/BanEditAction';
import PropTypes from 'prop-types';
import ResetPasswordAction from "./Actions/ResetPasswordAction";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import {FormattedMessage} from "react-intl";
import CheckUserAction from "./Actions/CheckUserAction";
import ShowActivationLinkAction from "./Actions/ShowActivationLinkAction";
import DeleteUserAction from "./Actions/DeleteUserAction";

class RespondentsActions extends Component {

  render() {
    return (
      <div>
        <CheckUserAction user={this.props.user} />
        <EditAction user={this.props.user} handleChange={this.props.handleChange}/>
        {this.props.user.isBanned && <BanEditAction user={this.props.user} categories={this.props.categories} handleChange={this.props.handleChange}/>}
        <ChangePasswordAction user={this.props.user}/>
        {this.props.user.userActivation && <ShowActivationLinkAction user={this.props.user}/>}
        <ResetPasswordAction user={this.props.user} />

        {!this.props.user.isBanned && <React.Fragment>
          <i id={'banUser-' + this.props.user.id} className="ml-2 fas fa-lock pointer" onClick={() => this.props.toggleBan(this.props.user.id)}/>
          <UncontrolledTooltip target={'banUser-' + this.props.user.id} placement="bottom"><FormattedMessage id="admin.respondents.userBulkBanModal.ban" /></UncontrolledTooltip>
        </React.Fragment>}

        {this.props.user.isBanned && <React.Fragment>
          <i id={'unbanUser-' + this.props.user.id} className="ml-2 fas fa-lock-open pointer" onClick={() => this.props.toggleUnban(this.props.user.id)}/>
          <UncontrolledTooltip target={'unbanUser-' + this.props.user.id} placement="bottom"><FormattedMessage id="admin.respondents.userBulkUnbanModal.unban" /></UncontrolledTooltip>
        </React.Fragment>}
        <DeleteUserAction user={this.props.user} handleChange={this.props.handleChange} />
      </div>
    )
  }
}

RespondentsActions.propTypes = {
  user: PropTypes.object.isRequired,
  toggleBan: PropTypes.func.isRequired,
  toggleUnban: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default RespondentsActions;