import * as React from 'react';
import ReactTable from "react-table";
import Pagination from "../components/Pagination";

function DefaultTable(props) {
	const [data, setData] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [searching, setSearching] = React.useState(false);
	const [count, setCount] = React.useState(0);

	const fetchData = async (state) => {
		if (state) {
			setSearching(true);
			setPage(state.page)
			setPageSize(state.pageSize);
		}

		const res = await props.pageRequest(
			Number.isInteger(state.page) ? state.page : page,
			state.pageSize ? state.pageSize : pageSize);

		setData(res.data)
		setCount(res.count);
		setSearching(false)
	}

	return (
		<React.Fragment>
			<ReactTable
				minRows={0}
				className={'table-wrap'}
				data={data}
				columns={props.columns}
				defaultPageSize={pageSize}
				showPagination={true}
				PaginationComponent={Pagination}
				noDataText={"Brak wyników"}
				loading={searching}
				onFetchData={(state) => fetchData(state)}
				rowsCount={count}
				manual
			/>
		</React.Fragment>
	);

};

export default DefaultTable;