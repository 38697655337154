import {normalize} from "normalizr";
import _ from 'lodash';

import api from "api";
import schemaPages from "entities/page/schema";
import schemaBlock from "entities/blockPages/schema";
import schemaQuestion from "entities/question/schema";

import * as basketsActions from "../baskets/actions";
import * as answersActions from "../answers/actions";
import * as rowsActions from "../rows/actions";
import * as columnsActions from "../columns/actions";
import * as blocksActions from "../blocks/actions";
import * as pagesActions from "../pages/actions";
import * as questionsActions from "../questions/actions";
import * as answerActions from "../answers/actions";
import * as rowActions from "../rows/actions";
import * as columnActions from "../columns/actions";
import * as excludingsActions from "../excludings/actions";
import * as setsActions from "../sets/actions";
import * as surveyActions from "../survey/actions";
import * as filtersActions from "../filters/actions";
import * as pipingsActions from "../pipings/actions";
import * as filterSerialActions from "../filterSerial/actions";
import * as structureActions from "../../actions";
import schemaSurvey from "entities/survey/schema";
import * as filterIterativeActions from "../filterIterative/actions";
import * as scalePointsActions from "../scalePoints/actions";
import * as javascriptActions from "../javascripts/actions";
import {setBlocks} from "../blocks/actions";
import {setSurvey} from "../survey/actions";
import {closedQuestionsCardsSet, hideInStructureSet} from "../../actions";

export const SET = 'Survey/data/pages/SET';
export const SET_QUESTIONS = 'Survey/data/pages/SET_QUESTIONS';
export const ADDED = 'Survey/data/pages/ADDED';
export const UPDATED = 'Survey/data/pages/UPDATED';
export const DELETED = 'Survey/data/pages/DELETED';
export const QUESTION_ADDED = 'Survey/data/pages/QUESTION_ADDED';

export function setPages(pages) {
  return {
    type: SET,
    pages
  }
}
export function setQuestions(id, questions) {
  return {
    type: SET_QUESTIONS,
    data: {
      id,
      questions
    }
  }
}
export function pageAdded(page) {
  return {
    type: ADDED,
    page
  }
}
export function pageUpdated(page) {
  return {
    type: UPDATED,
    page
  }
}
export function pageDeleted(page) {
  return {
    type: DELETED,
    page
  }
}
export function questionAdded(question) {
  return {
    type: QUESTION_ADDED,
    question
  }
}





export function addPage(data) {
  return dispatch => {
    return api.blockPages.post.page(data).then((page) => {
      dispatch(pageAdded(page));
      dispatch(blocksActions.pageAdded(page));

      return page;
    })
  }
}

export function updatePage(data) {
  return dispatch => {
    return api.page.patch(data).then(page => {
      const normalized = normalize(page, schemaPages);
      dispatch(pageUpdated(normalized.entities.pages[normalized.result]));

      return page;
    })
  }
}

export function breakPage(data) {
  return dispatch => {
    return api.page.post.break(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(setPages(normalized.entities.pages || {}));
      dispatch(setsActions.setSets(normalized.entities.sets || {}));
      dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(surveyActions.setSurvey(survey));
    })
  }
}

export function deletePage(data) {
  return dispatch => {
    return api.page.delete(data).then((res) => {
      dispatch(pageDeleted({
        id: data.id,
        block: {
          id: res.page.blockPages.id
        }
      }));
    });
  }
}

export function addQuestions(id, data) {
  return dispatch => {
    data.page = id
    return api.page.post.questions(data).then(data => {
      const normalized = normalize(data.survey, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      if(normalized.entities.questions) {
        let questionIds = Object.keys(normalized.entities.questions).map(id => parseInt(id));
        if (questionIds.length > 2) {
          dispatch(hideInStructureSet('structure'))
          dispatch(closedQuestionsCardsSet(questionIds));
        }
      }

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(setPages(normalized.entities.pages || {}));
      dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
      dispatch(setsActions.setSets(normalized.entities.sets || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(filterIterativeActions.setFilterIterative(normalized.entities.filterIterative || {}));
      dispatch(surveyActions.setSurvey(survey));
    })
  }
}

export function copyPage(data) {
  return dispatch => {
    return api.page.post.copy(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(setPages(normalized.entities.pages || {}));
      dispatch(setsActions.setSets(normalized.entities.sets || {}));
      dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(filterIterativeActions.setFilterIterative(normalized.entities.filterIterative || {}));
      dispatch(surveyActions.setSurvey(survey));
    });
  }
}

export function autoPaginatePage(data) {
  return dispatch => {
    return api.page.patchAutoPaginate(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(setPages(normalized.entities.pages || {}));
      dispatch(setsActions.setSets(normalized.entities.sets || {}));
      dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(surveyActions.setSurvey(survey));
    })
  }
}