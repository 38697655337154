import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import striptags from 'striptags';
import reactHtmlParser from "react-html-parser";

const SLICE_VALUE = 5;

class QuestionSummaryMaxDiff extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tableSlice: true,
    };
  }

  render() {
    const {questionSummary} = this.props;
    const {tableSlice} = this.state;
    const dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <React.Fragment>
        <Chart id={questionSummary.question.id} type={questionSummary.chartType} n={questionSummary.data.n} data={{
          data: questionSummary.data.data.map(row => [reactHtmlParser(striptags(questionSummary.question.answersImage ? row.description : row.content))[0], row['value_1'] * -1, (row['value_2'])]),
          series1Name: questionSummary.question.leftText || 'Lewa strona',
          series2Name: questionSummary.question.rightText || 'Prawa strona',
        }} />

        {this.props.table && <React.Fragment>
          <BootstrapTable
            className={'table-wrap w-lg-50 mx-auto mt-3'}
            data={tableSlice ? questionSummary.data.data.slice(0, SLICE_VALUE) : questionSummary.data.data}
            striped hover
          >
            <TableHeaderColumn dataField='value1'
                               dataFormat={(value, data) => data['value_1']}>{questionSummary.question.leftText || 'Lewa strona'}</TableHeaderColumn>
            <TableHeaderColumn dataField='answer' isKey={true} dataFormat={(cell, row) => <div
              className="text-center">{questionSummary.question.answersImage ?
              <img src={row.content} className="img-100"/> :
              <span dangerouslySetInnerHTML={{__html: row.content}} className="img-30"/>}</div>}><FormattedMessage
              id="analysis.questionSummary.maxDiff.tableHeader.x"/></TableHeaderColumn>
            <TableHeaderColumn dataField='value2'
                               dataFormat={(value, data) => data['value_2']}>{questionSummary.question.rightText || 'Prawa strona'}</TableHeaderColumn>
          </BootstrapTable>
          {(tableSlice && questionSummary.data.data.length > SLICE_VALUE) &&
            <p className="text-center"><span className="text-muted py-2 d-block pointer"
                                             onClick={() => this.setState({tableSlice: false})}>Kliknij, aby pokazać wszystkie odpowiedzi w tabeli</span>
            </p>}
        </React.Fragment>}
      </React.Fragment>
    )
  }
}

QuestionSummaryMaxDiff.defaultProps = {
  chart: true,
  table: true,
};

QuestionSummaryMaxDiff.propTypes = {
  chart: PropTypes.bool,
  table: PropTypes.bool,
};

export default QuestionSummaryMaxDiff;
