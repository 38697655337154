import React from 'react';
import PropTypes from 'prop-types';
import api from "../../api";
import ApplicationTable from "../../components/Application/ApplicationTable";
import SearchTextInput from "../../components/Application/ApplicationTable/SearchTextInput";
import {Link} from "react-router-dom";
import appRoutes from "../../appRoutes";
import {isNull, isUndefined} from "lodash";
import CollectorName from "../../scenes/SurveyCollector/components/Collector/CollectorName";
import {FormattedMessage} from "react-intl";
import ProgressBar from "../../components/ProgressBar";
import Tooltip from "../../components/Tooltip";
import RealizationAutomateSendEmailButton from "./RealizationAutomateSendEmailButton";

const SurveyCollectorPanelRealizationAutomateTable = props => {
  const tableRef = React.createRef();
  console.log(props)

  const columns = [
    {
      id: 'name',
      Header: 'Nazwa kolektora',
      accessor: collector => <CollectorName key={collector.name} tagName="div" id={collector.id} collector={collector} handleUpdate={name => {
        api.surveyCollector.patch({
          id: collector.id,
          surveyCollector: {
            name: name
          }
        })
      }} showButtons={false}/>
    },
    {
      id: 'responsePercentage',
      Header: 'Postęp',
      accessor: collector => <ProgressBar responsePercentage={collector.responsePercentage} current={parseInt(collector.endResponsesCount)} max={parseInt(collector.collectorResponses)} id={collector.id}/>,
      width: 170,
    },
    {
      id: 'realizationAutomateCType',
      Header: 'Typ',
      width: 80,
      accessor: row => {
        if(row.realizationAutomateCType >= 1){
          let tooltip = '';
          let color = '';
          switch(row.realizationAutomateCType) {
            case 1: {
              color = 'danger';
              tooltip = 'Target b. trudny';
              break;
            }
            case 2: {
              color = 'warning';
              tooltip = 'Target trudny';
              break;
            }
            case 3: {
              color = 'primary';
              tooltip = 'Target umiarkowany';
              break;
            }
            case 4: {
              color = 'success';
              tooltip = 'Target łatwy';
              break;
            }
          }

          return <span>
            <span id={`c-id-rat-${row.id}`} className={`text-${color}`}>C{row.realizationAutomateCType}</span>
            <Tooltip id={`c-id-rat-${row.id}`} msg={tooltip} />
          </span>
        }

        return <i className="text-secondary">Brak</i>
      }
    },
    {
      id: 'realizationAutomateStage',
      Header: 'Ostatnio wykonany etap',
      width: 160,
      accessor: row => !isUndefined(row.realizationAutomateStage) ? row.realizationAutomateStage : <i className="text-secondary">Brak</i>
    },
    {
      id: 'realizationAutomateLastStageDateStart',
      Header: 'Data wykonania ostatniego etapu',
      width: 210,
      accessor: row => row.realizationAutomateLastStageDateStart || <i className="text-secondary">Brak</i>
    },
    {
      id: 'realizationAutomateLastStageMinutesToEnd',
      Header: 'Do końca etapu pozostało',
      sortable: false,
      width: 210,
      accessor: row => {
        if(isUndefined(row.realizationAutomateLastStageMinutesToEnd)){
          return <i className="text-secondary">Brak</i>
        }

        if(row.realizationAutomateLastStageMinutesToEnd <= 0){
          return <i className="text-warning">Koniec</i>
        }

        const hours = Math.floor(row.realizationAutomateLastStageMinutesToEnd / 60);
        let minutes = row.realizationAutomateLastStageMinutesToEnd - (hours * 60);
        if(minutes < 10){
          minutes = '0' + minutes;
        }

        return `${hours}h ${minutes}min`
      }
    },
    {
      id: 'realizationAutomateSendEmail',
      Header: 'Wysyła zaproszeń',
      sortable: false,
      width: 210,
      accessor: row => <RealizationAutomateSendEmailButton collector={row} collectorId={row.id} />
    },
  ];


  return <React.Fragment>
    <ApplicationTable
      ref={tableRef}
      columns={columns}
      fetchData={api.surveyCollector.search}
      filters={{
        survey: props.survey,
        realizationAutomate: true,
      }}
    />
  </React.Fragment>;
};

SurveyCollectorPanelRealizationAutomateTable.propTypes = {

};

export default SurveyCollectorPanelRealizationAutomateTable;
