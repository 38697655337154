import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardBody, CardHeader, CardFooter, ListGroup} from "reactstrap";
import Action from "../Action";
import { connect } from 'react-redux';

class Actions extends PureComponent {

  constructor(props) {
    super(props);

    this.addAction = this.addAction.bind(this);
    this.handleActionChanged = this.handleActionChanged.bind(this);
    this.getFirstFilterCondition = this.getFirstFilterCondition.bind(this);
    this.getQuestionIdForNewAction = this.getQuestionIdForNewAction.bind(this);
    this.getQuestionIdNextToFirstFilterCondition = this.getQuestionIdNextToFirstFilterCondition.bind(this);
    this.getQuestionIdNextToQuestion = this.getQuestionIdNextToQuestion.bind(this);
    this.getMaxPositionQuestionInBlock = this.getMaxPositionQuestionInBlock.bind(this);
  }

  addAction(Obj){
    let actions = this.props.filter.actions;

    if (_.isUndefined(Obj)) {
      Obj = {
        actionType: 'actionVisibility',
        visibility: true,
        type: 'questions',
        questions: [],
      };

      const actionQuestionId = this.getQuestionIdForNewAction();
      if (_.isInteger(actionQuestionId)) {
        Obj.questions = [actionQuestionId];
      }
    }

    actions.push(Obj);

    this.props.handleActionsChanged(actions);
  }

  getQuestionIdForNewAction() {
    let questionId;

    if (!_.isEmpty(this.props.filter.actions)) {
      questionId = this.getQuestionIdNextToLastAction();
    }

    if (!_.isInteger(questionId)) {
      questionId = this.getQuestionIdNextToFirstFilterCondition();
    }

    return questionId;
  }

  getQuestionIdNextToLastAction() {
    const actions = this.props.filter.actions;
    let action;

    for (let i = actions.length - 1; i >= 0; i--) {
      action = actions[i];

      if (action.hasOwnProperty('question')) {
        return this.getQuestionIdNextToQuestion(action.question)
      }

      if (action.hasOwnProperty('questions')) {
        return this.getQuestionIdNextToQuestion(action.questions[action.questions.length - 1]);
      }
    }
  }

  getQuestionIdNextToQuestion(questionId) {
    const firstCondQuestionSurveyPosition = _.find(this.props.questions, question => question.id === questionId).positionInSurvey;
    const nextQuestion = _.find(this.props.questions, question => question.positionInSurvey === (firstCondQuestionSurveyPosition + 1));

    return _.isUndefined(nextQuestion) ? questionId : nextQuestion.id;
  }

  getQuestionIdNextToFirstFilterCondition() {
    const firstFilterCondition = this.getFirstFilterCondition();

    if (_.isUndefined(firstFilterCondition)) {
      return;
    }

    return this.getQuestionIdNextToQuestion(firstFilterCondition.question);
  }

  getFirstFilterCondition() {
    let firstCond;

    const findCondition = (block) => {
      if (firstCond) {
        return false;
      }

      _.each(block.condsQuestion, cond => {
        if (cond.hasOwnProperty('question')) {
          firstCond = cond;
          return false;
        }
      });

      _.each(block.children, nestedBlock => {
        findCondition(nestedBlock);
      })
    };

    _.each(this.props.filter.blocks, block => {
      if (!firstCond) {
        findCondition(block);
      }
    });

    return firstCond;
  }

  deleteAction(key){
    let actions = this.props.filter.actions;

    actions = actions.filter((action, k) => k !== key);

    this.props.handleActionsChanged(actions);
  }

  handleActionChanged(key, action){
    let actions = this.props.filter.actions;

    actions[key] = action;

    this.props.handleActionsChanged(actions);
  }

  getMaxPositionQuestionInBlock(block){
    const {questions} = this.props;
    let maxPosition = 0;

    _.forEach(block.children, b => {
      let newMaxPosition = this.getMaxPositionQuestionInBlock(b);

      if(newMaxPosition > maxPosition){
        maxPosition = newMaxPosition;
      }
    });

    _.forEach(block.condsQuestion, condQuestion => {
      if('question' in condQuestion && condQuestion.question in questions){
        const positionInSurvey = questions[condQuestion.question]['positionInSurvey'];

        if(positionInSurvey > maxPosition){
          maxPosition = positionInSurvey;
        }
      }
    });

    return maxPosition;
  }

  render() {
    let actions = this.props.filter.actions;

    let maxPosition = 0;
    _.forEach(this.props.filter.blocks, block => {
      let newMaxPosition = this.getMaxPositionQuestionInBlock(block);

      if(newMaxPosition > maxPosition){
        maxPosition = newMaxPosition;
      }
    });

    const grayedQuestionsToPositionInSurvey = maxPosition;

    return (
      <Card>
        <CardHeader className="text-center"><h2>Jeśli warunki zostaną spełnione, to wykonaj poniższe akcje</h2></CardHeader>
        <hr className="my-0" />
        <CardBody>
          <ListGroup>
            {actions.map((action, key) => <Action
              grayedQuestionsToPositionInSurvey={grayedQuestionsToPositionInSurvey}
              key={key}
              action={action}
              handleActionChanged={(action) =>this.handleActionChanged(key, action)}
              handleActionDelete={() => this.deleteAction(key)}
            />)}
          </ListGroup>
        </CardBody>
        <CardFooter>
          <Button className="mr-1" onClick={() => this.addAction()}>Dodaj akcję</Button>
          <Button onClick={() => this.addAction({
            actionType: 'actionJump',
            type: 'surveyEnd',
            status: 4,
          })}>Dodaj screenout</Button>
        </CardFooter>
      </Card>
    );
  }
}

Actions.propTypes = {
  filter: PropTypes.shape({
    actions: PropTypes.array.isRequired,
    blocks: PropTypes.array.isRequired
  }).isRequired,
  handleActionsChanged: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    questions: state.survey.structure.data.questions
  }
}

export default connect(mapStateToProps)(Actions);