import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import ColumnForm from "./ColumnForm";
import {connect} from 'react-redux';
import {updateRespondentBaseColumn} from "reducers/user/actions";

class ColumnEditButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      editing: false,
      errors: {}
    };
    this.columnUpdate = this.columnUpdate.bind(this);
    this.columnForm = React.createRef();
  }

  columnUpdate(e, data) {
    e.preventDefault();
    this.setState({editing: true, errors: {}});

    this.props.updateRespondentBaseColumn({data: {
        id: this.props.column.id,
        name: data.name
      }})
      .then(() => {
        this.setState({
          modal: false,
          editing: false
        })
      })
      .catch(e => {
        this.setState({
          editing: false,
          errors: e.response.data.form.errors
        });
      });
  }

  toggleEditModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { editing, errors } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleEditModal()} size="lg">
      <ModalHeader toggle={() => this.toggleEditModal()} tag="h2"><FormattedMessage id="respondentBaseEditButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <ColumnForm onSubmit={this.columnUpdate} column={this.props.column} ref={this.columnForm} errors={errors}/>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.save">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={editing}
                data-style={ZOOM_OUT}
                onClick={e => this.columnUpdate(e, this.columnForm.current.state)}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggleEditModal()}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="m-0 p-0" onClick={() => this.toggleEditModal()}><i className="fas fa-edit"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

ColumnEditButton.propTypes = {
  column: PropTypes.object.isRequired,
};

export default connect(null, {updateRespondentBaseColumn}, null, {forwardRef: true})(ColumnEditButton);