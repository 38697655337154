const getCKConfigByPlan = (plan) => {
  const config = {
    extraPlugins: ['font', 'justify', 'iframe', 'smiley', 'newpage', 'colorbutton'],
    toolbar: [
      {items: ['Source', "NewPage"]},
      {items: ['Cut', "Copy", "Paste", "PasteText", "PasteFromWord", "Undo", "Redo"]},
      {items: ['Bold', "Italic", "Underline", "Strike"]},
      {items: ['NumberedList', "BulletedList", "Outdent", "Indent", "JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"]},
      {items: ['Link', "Unlink"]},
      {items: ['Image', "HorizontalRule", "Smiley", "SpecialChar", "Iframe"]},
      "/",
      {items: ['Styles', "Format", "Font", "FontSize", "TextColor", "BGColor"]},
    ],
    removeButtons: '',
    image_prefillDimensions: false,
    entities: false,
  };

  if (plan.multimedia.access) {
    config.filebrowserBrowseUrl = '/ckfinder';
  }

  return config;
};

export default getCKConfigByPlan;