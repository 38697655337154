import api from "api";
import * as questionsActions from "../questions/actions";

export const SET = 'Survey/data/excludings/SET';
export const ADDED = 'Survey/data/excludings/ADDED';
export const DELETED = 'Survey/data/excludings/DELETED';
export const UPDATED = 'Survey/data/excludings/UPDATED';

export function setExcludings(excludings) {
  return {
    type: SET,
    excludings
  }
}
export function excludingAdded(excluding) {
  return {
    type: ADDED,
    excluding
  }
}
export function excludingDeleted(excluding) {
  return {
    type: DELETED,
    excluding
  }
}
export function excludingUpdated(excluding) {
  return {
    type: UPDATED,
    excluding
  }
}





export function addExcluding(data) {
  return dispatch => {
    return api.question.post.excluding(data).then(excluding => {
      dispatch(excludingAdded(excluding));
      dispatch(questionsActions.excludingAdded(excluding));

      return excluding;
    });
  }
}

export function deleteExcluding(data) {
  return dispatch => {
    return api.excluding.delete.excluding(data).then(res => {
      dispatch(excludingDeleted({
        id: data.id,
        question: {
          id: res.excluding.question.id
        }
      }))
    })
  }
}

export function deleteExcludings(questionId, data) {
  return dispatch => {
    return api.excluding.delete.list(data).then(() => {
      _.map(data.ids, id => {
        dispatch(excludingDeleted({
          id,
          question: {
            id: questionId
          }
        }))
      })
    })
  }
}

export function updateExcluding(data) {
  return dispatch => {
    return api.excluding.patch(data).then(excluding => {
      dispatch(excludingUpdated(excluding))
    })
  }
}