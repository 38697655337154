import * as React from 'react';
import PropTypes from "prop-types";
import {
  Col,
  Form,
  FormGroup, Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {FormattedMessage} from "react-intl";
import api from "api";
import appRoutes from "appRoutes";
import Button from "components/Button";
import {connect} from "react-redux";
import InputNumber from "components/InputNumber";
import CollectorPanelTemplatePrice from "scenes/Survey/Collectors/components/CollectorPanelTemplatePrice";

function CollectorPanelFromTemplateModal(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [saving, setSaving] = React.useState(false);
  const [n, setN] = React.useState(1000);

  const save = () => {
    setSaving(true);

    api.survey.post.collectorPanelTemplate(props.surveyId, {
      template: props.template.id,
      n
    })
    .then(data => {
      props.history.push(appRoutes.survey.collectors.listType(props.surveyId, 'Panel'))
    })
    .catch(res => {
      setSaving(false);
    })
    ;
  }

  return <React.Fragment>
    <Button color="primary" size="lg" onClick={toggleModalOpen}>Wybierz</Button>
    {modalOpen && <Modal isOpen={modalOpen} toggle={toggleModalOpen} size="lg">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Utwórz kolektory z wybranego szablonu</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <h1 className="text-center mb-1">{props.template.name}</h1>
        <div dangerouslySetInnerHTML={{__html: props.template.description}} />
        <Form className="mt-4">
          <FormGroup row>
            <Label xs={12} md={4}>Liczba wywiadów:</Label>
            <Col xs={12} md={8}>
              <InputNumber
                min={1}
                max={props.plan.survey.collectorPanelVerifyRequired ? 2000 : 99999}
                step={100}
                value={n}
                onChange={v => setN(parseInt(v))}
              />
            </Col>
          </FormGroup>
          {props.plan.survey.collectorPanelVerifyRequired && <React.Fragment>
            <FormGroup row>
              <Label xs={12} md={4}>Szacunkowa cena*:</Label>
              <Col xs={12} md={8} className="d-flex align-items-center">
                <CollectorPanelTemplatePrice surveyId={props.surveyId} template={props.template} n={n} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs={12}>
                <p className="mt-2 mb-0" style={{
                  lineHeight: '11px',
                  fontSize: '11px',
                  color: '#adadad',
                }}>Przedstawiona oferta cenowa ma charakter informacyjny i nie stanowi oferty handlowej w rozumieniu Art.66 par.1 Kodeksu Cywilnego. Podana kwota jest kwotą netto i może różnić się od końcowej wyceny wykonanej przez pracownika Ankieteo.  Cena obejmuje dostarczenie respondentów do wypełnienia ankiety i nie zawiera: przygotowania bazy wyników, tabel, raportu, dopracowania ankiety. Jeśli potrzebujesz pomocy w tych obszarach - skontaktuj się z nami.</p>
                <p className="mt-0 mb-0" style={{
                  lineHeight: '11px',
                  fontSize: '11px',
                  color: '#adadad',
                }}>Kliknięcie w Utwórz nie wiąże się z żadnymi opłatami.  Po kliknięciu w utwórz pojawi się lista kolektorów składających się na wybraną strukturę populacji</p>
              </Col>
            </FormGroup>
          </React.Fragment>}
        </Form>

      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.create">{
          (message) => <Button
            color="primary"
            loading={saving}
            onClick={save}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
}

CollectorPanelFromTemplateModal.propTypes = {
  plan: PropTypes.shape({
    survey: PropTypes.shape({
      collectorPanelVerifyRequired: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps)(CollectorPanelFromTemplateModal);