import React, {Component} from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Switch from "components/Switch";
import InputNumber from "components/InputNumber";
import {updateQuestion} from 'scenes/Survey/Structure/data/questions/actions';
import striptags from "striptags";
import reactHtmlParser from "react-html-parser";


class RandAndRotateQuestionOptions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rotateInPage: props.question.rotateInPage,
      randomInPage: props.question.randomInPage,
      randPriority: props.question.randPriority,
    };
  }

  onChange(property, value) {
    this.setState({
      [property]: value
    });

    this.props.updateQuestion({
      id: this.props.question.id,
      question: {
        [property]: value
      }
    });
  }

  render() {
    const {page, question} = this.props;

    return (
      <tr>
        <td>
          {reactHtmlParser(striptags(question.content))}
        </td>
        {page.rotateQuestions && <td>
          <Switch checked={this.state.rotateInPage} onChange={value => this.onChange( 'rotateInPage', value)}/>
        </td>}
        {page.randQuestions > 0 && <td>
          <Switch checked={this.state.randomInPage} onChange={value => this.onChange( 'randomInPage', value)}/>
        </td>}
        {page.randQuestions > 0 && this.state.randomInPage && <td>
          <InputNumber value={this.state.randPriority} min={0} onChange={value => this.onChange('randPriority', value)}/>
        </td>}
      </tr>
    )
  }
}

RandAndRotateQuestionOptions.propTypes = {
  page: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired
};

export default connect(null, {updateQuestion})(RandAndRotateQuestionOptions);