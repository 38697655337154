import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import PropTypes from 'prop-types';
import api from "../../../../api";

class ReportsButtonFix extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false
    };
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  fixReport() {
    this.setState({
      saving: true
    });

    api.survey.reports.patchBulk([this.props.id], {fixed: true})
    .then(data => {
      this.setState({
        saving: false,
        modal: false,
      });

      this.props.onFixed();
    })
    .catch(res => {
      this.setState({
        saving: false
      });
    });
  }

  render() {
    const { saving } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()}>
      <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id="reports.reportButtonFix.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="reports.reportButtonFix.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="reports.reportButtonFix.fix">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={() => this.fixReport()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModal()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <FormattedMessage id="reports.reportButtonFix.button">{
          (message) =>
            <Button color="primary" size="sm" onClick={() => this.toggleModal()}>{message}</Button>
        }</FormattedMessage>
        {modal}
      </React.Fragment>
    )
  }
}

ReportsButtonFix.propTypes = {
  id: PropTypes.number.isRequired,
  onFixed: PropTypes.func.isRequired,
};

export default ReportsButtonFix;