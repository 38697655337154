import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {addNews} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import NewsForm from './NewsForm';
import PropTypes from "prop-types";

class NewsCreateButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      creating: false,
      errors: {}
    }
    this.newsForm = React.createRef();
  }

  newsCreate(e, data) {
    e.preventDefault();
    this.setState({creating: true});

    this.props.addNews({
      data: data.news
    }).then(() => {
      this.props.handleChange();
      this.setState({
        creating: false,
        modal: false
      })
    }).catch(e => {
      this.setState({
        creating: false,
        errors: e.response.data.form.errors
      });
    });
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { creating, errors } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} size="lg">
      <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id="admin.newsCreateButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <NewsForm errors={errors} onSubmit={this.newsCreate} ref={this.newsForm}/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={creating}
            data-style={ZOOM_OUT}
            onClick={e => this.newsCreate(e, this.newsForm.current.state)}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModal()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="primary" className="mb-2" onClick={() => this.toggleModal()}><i className="fas fa-plus" /> <FormattedMessage id="admin.newsCreateButton.button"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

NewsCreateButton.propTypes = {
  handleChange: PropTypes.func.isRequired
};

export default connect(null, { addNews }, null, {forwardRef: true})(NewsCreateButton);