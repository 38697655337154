import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

import Select from 'react-select';

class Languages extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: props.languages
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(inputValue, action) {
    const {defaultLang} = this.props;
    let value = this.state.value;

    switch (action.action) {
      case 'select-option':
        value = inputValue.map(v => v.value);
        break;
      case 'remove-value':
        if(action.removedValue.value === defaultLang){
          return;
        }

        value = value.filter(v => v !== action.removedValue.value);
        break;
    }

    this.props.onChange(value);

    this.setState({
      value
    })
  }

  render() {
    const {availableLanguages, defaultLang, disabled} = this.props;
    const {value, saving} = this.state;

    const styles = {
      multiValueLabel: (base, state) => {
        return state.data.isFixed ? { ...base, paddingRight: 6 } : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
      }
    };

    return (
      <Select
        isMulti
        styles={styles}
        isClearable={false}
        isLoading={saving}
        isDisabled={saving || disabled}
        value={value.map(l => {
          return {
            value: l,
            key: l,
            isFixed: l === defaultLang,
            label: <img src={'/dashboard/img/icon/' + l + '.png'} height="20" />,
          }
        })}
        options={availableLanguages.map(l => {
          return {
            value: l,
            label: <span><img src={'/dashboard/img/icon/'+ l + '.png'} height="20"/> <FormattedMessage
              id={'_.lang.' + l + '.text'}/></span>,
          }
        })}
        onChange={this.onChange}
      />
    )
  }
}

Languages.propTypes = {
  defaultLang: PropTypes.string.isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  languages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  disabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    languages: state.survey.structure.data.survey.languages,
    availableLanguages: state.config.data.survey.availableLanguages
  }
}

export default connect(mapStateToProps)(Languages);