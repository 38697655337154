import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Label, Col, Input, Form} from 'reactstrap';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import PropTypes from 'prop-types';
import api from 'api';

class SummaryShareForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      loading: false,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    })
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true
    });

    api.surveyShared.summary.check(
      this.props.guid,
      this.state.password,
    ).then(data => {
      this.setState({
        loading: false,
      });
      this.props.handle(data);
    }).catch(res => {
      this.setState({
        loading: false
      });
    });
  }

  render() {
    return (
      <Modal isOpen className={'modal-danger'}>
        <ModalHeader tag="h2">Podsumowanie ankiety</ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <p>Wprowadź hasło, a następnie kliknij załaduj, aby uzyskać dostęp.</p>
          <Form onSubmit={this.onSubmit}>
            <Row>
              <Label for="password" lg={3}>Hasło</Label>
              <Col lg={9}>
                <Input type="password" id="password" name="password" value={this.state.password} invalid={!!this.state.errors.password} onChange={this.onChange}/>
                {this.state.errors.password && <p className="help-block text-danger">{this.state.errors.password}</p>}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="analysisShare.analysisShareForm.button">{
            (message) => <LaddaButton
              className="btn btn-danger btn-ladda"
              loading={this.state.loading}
              data-style={ZOOM_OUT}
              onClick={this.onSubmit}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    )
  }
}

SummaryShareForm.propTypes = {
  handle: PropTypes.func.isRequired,
  guid: PropTypes.string.isRequired,
};

export default SummaryShareForm;