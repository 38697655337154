import React, {Component} from "react";
import PropTypes from "prop-types";
import {InputGroupAddon, Tooltip, Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";
import InputNumber from "components/InputNumber";

class RequiredCount extends Component {
  constructor(props){
    super(props);
    this.state = {
      tooltipOpen: false,
      question: {
        requiredCount: props.question.requiredCount
      }
    };

    this.onChange = this.onChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  onChange(value){
    this.setState({
        question: {
          requiredCount: value
        }
      },
      () => this.props.handleUpdate(this.state.question)
    );
  }

  render() {
    const {tooltipOpen} = this.state;
    const {question} = this.props;

    let options = [
      {
        label: <FormattedMessage id="question.settings.requiredCount.select.minValue.option"/>,
        value: 0
      }
    ];

    for (let i = 1; i <= question.answers.length; i++) {
       options.push({
         label: i,
         value: i
       });
    }

    return (
      <div
        id={"requiredCount-" + question.id}
        className={classnames({
          "min-w-0 float-left mr-1 mb-1": true,
          "w-auto": question.requiredCount === 0,
          "w-100 w-lg-auto input-group": question.requiredCount > 0
        })}
        >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": question.requiredCount > 0,
            })}
            color="secondary"
            onClick={() => {
              if(question.requiredCount === 0){
                this.onChange(1)
              }else{
                this.onChange(0)
              }
            }}
          >
            <i className="fa-solid fa-arrow-down-triangle-square"/>
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltipOpen}
              target={"requiredCount-" + question.id}
              toggle={this.toggleTooltip}
            >
              <FormattedMessage id="question.settings.requiredCount.tooltip"/>
            </Tooltip>
          </Button>
        </InputGroupAddon>
        {question.requiredCount > 0 && <InputNumber value={question.requiredCount} min={0} max={this.props.question.answers.length} onChange={(value) => this.onChange(value, 'requiredCount')}/>}
      </div>
    );
  }
}

RequiredCount.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    requiredCount: PropTypes.number.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default RequiredCount;
