import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, FormGroup, Label, Row, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import SelectQuestions from "../../../../../../Structure/components/SelectQuestions";
import Select from "react-select";
import api from "../../../../../../../../api";
import DemographyValuesTable from "./DemographyValuesTable";
import {connect} from "react-redux";
import Switch from "../../../../../../../../components/Switch";

export const typeOptions = [
  {
    value: 'frequency',
    label: 'Rozkłady częstości',
    description: 'Rozkłady częstości',
  },
  {
    value: 'statistics',
    label: 'Statystyki opisowe',
    description: 'Średnia, mediana, odchylenie standardowe, wariancja',
  },
  {
    value: 't2b',
    label: 'Top2Box',
    description: 'Suma odsetków dwóch skrajnie pozytywnych odpowiedzi',
  },
  {
    value: 't3b',
    label: 'Top3Box',
    description: 'Suma odsetków trzech skrajnie pozytywnych odpowiedzi',
  },
  {
    value: 'b2b',
    label: 'Bottom2Box',
    description: 'Suma odsetków dwóch skrajnie negatywnych odpowiedzi',
  },
  {
    value: 'b3b',
    label: 'Bottom3Box',
    description: 'Suma odsetków trzech skrajnie negatywnych odpowiedzi',
  },
  {
    value: 'crossTable',
    label: 'Tabele krzyżowe',
    description: 'Wyniki w podgrupach',
  },
];

export const CrossTypeSelect = (props) => {
  const [value, setValue] = React.useState(props.value);
  const {demographyAllow} = props;

  let v = value;

  if(value && value.indexOf('demogr') > -1){
    v = 'demography';
  }

  const options = [
    {
      value: 'question',
      label: 'Pytaniem z ankiety',
    },
    {
      value: 'demography',
      label: 'Demografia respondenta (Panel)',
      isDisabled: !demographyAllow,
    },
  ]

  return <Select
    options={options}
    onChange={option => {
      setValue(option.value);
      props.onChange(option.value);
    }}
    value={options.find(o => o.value === v)}
  />
}

export const DemographyTypeSelect = (props) => {

  const options = [
    {
      value: 'demography_age',
      label: 'Wiek respondenta',
    },
    {
      value: 'demography_sex',
      label: 'Płeć respondenta',
    },
    {
      value: 'demography_voivodeship',
      label: 'Województwo respondenta',
    },
    {
      value: 'demography_city_lm',
      label: 'Wielkość miejscowości respondenta',
    },
    {
      value: 'demography_education',
      label: 'Wykształcenie respondenta',
    },
    {
      value: 'demography_respondent_income',
      label: 'Dochód respondenta',
    },
  ]

  return <Select
    isMulti={true}
    options={options}
    onChange={options => props.onChange(options)}
  />
}

class ButtonCreate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      saving: false,
      data: {
        download: false,
        questions: null,
        type: typeOptions[0].value,
        crossType: false,
        questionCrossed: false,
        demographies: [],
        demographyValues: {
          demography_age: [],
          demography_city_lm: [],
        },
      }
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.save = this.save.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  save(){
    const {data} = this.state;

    this.setState({
      saving: true,
    });

    api.analysis.advanced.post({
      ...data,
      analysis: this.props.analysisId
    })
    .then(data => {
      this.setState({
        saving: false,
        data: {
          ...this.state.data,
          crossType: false,
          questionCrossed: false,
          demographies: [],
          demographyValues: {
            demography_age: [],
            demography_city_lm: [],
          },
        }
      });

      this.toggleModal();

      if('fileName' in data){
        window.location = data.fileName;
      }

      this.props.onAdded(data.data);
    })
    .catch(res => {
      this.setState({
        saving: false,
      })
    })
  }

  render() {
    const {modalOpen, saving, data} = this.state;

    const selected = typeOptions.find(type => type.value === data.type) || false;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModal}>Utwórz nowy widok</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <FormGroup row>
          <Label md={3}>Wybierz pytania:</Label>
          <Col md={9}>
            <SelectQuestions
              onChange={questions => this.setState({data: {...data, questions}})}
            />
          </Col>
        </FormGroup>
        <FormGroup className="mt-2" row>
          <Label md={3}>Zakres danych:</Label>
          <Col md={9}>
            <Select
              placeholder={"Wybierz zakres danych"}
              value={{
                label: <div className="d-flex flex-column flex-wrap">
                  <span>{selected.label}</span>
                  <small>{selected.description}</small>
                </div>,
                value: selected.value,
              }
              }
              options={typeOptions.map(type => {
                return {
                  label: <div className="d-flex flex-column flex-wrap">
                    <span>{type.label}</span>
                    <small>{type.description}</small>
                  </div>,
                  value: type.value,
                }
              })}
              onChange={option => this.setState({
                data: {
                  ...data,
                  type: option.value,
                  crossType: false,
                  questionCrossed: false,
                  demographyValues: {
                    demography_age: [],
                    demography_city_lm: [],
                  },
                }
              })}
            />
          </Col>
        </FormGroup>
        {data.type === 'crossTable' && <FormGroup className="mt-2" row>
          <Label md={3}>Skrzyżuj z:</Label>
          <Col md={9}>
            <CrossTypeSelect
              value={data.crossType}
              demographyAllow={this.props.collectorPanelAccess}
              onChange={crossType => {
                this.setState({
                  data: {
                    ...data,
                    questionCrossed: false,
                    crossType,
                  }
                })
              }}
            />
          </Col>
        </FormGroup>}
        {data.crossType === 'question' && <FormGroup className="mt-2" row>
          <Label md={3}>Skrzyżuj z pytaniem:</Label>
          <Col md={9}>
            <SelectQuestions
              isMulti={false}
              onChange={questionCrossed => this.setState({data: {...data, questionCrossed}})}
            />
          </Col>
        </FormGroup>}
        {data.crossType === 'demography' && <FormGroup className="mt-2" row>
          <Label md={3}>Wybierz demografie: </Label>
          <Col md={9}>
            <DemographyTypeSelect
              demographyAllow={this.props.collectorPanelAccess}
              onChange={crossTypes => {
                let demographies = [];
                let demography_age = [];
                let demography_city_lm = [];

                crossTypes.map(crossType => {
                  demographies.push(crossType.value);
                  switch (crossType.value) {
                    case 'demography_age': {
                        demography_age = [
                        {name: 'do 24 lat', valueFrom: 0, valueTo: 24},
                        {name: '25-34 lat', valueFrom: 25, valueTo: 34},
                        {name: '35-49 lat', valueFrom: 35, valueTo: 49},
                        {name: 'Powyżej 50 lat', valueFrom: 50, valueTo: 99},
                      ];

                      break;
                    }
                    case 'demography_city_lm': {
                      demography_city_lm = [
                        {name: 'wieś miasto do 20 tys.', valueFrom: 0, valueTo: 20000},
                        {name: 'miasto 20-99 tys.', valueFrom: 20001, valueTo: 99999},
                        {name: 'miasto 100-199 tys.', valueFrom: 100000, valueTo: 199999},
                        {name: 'miasto 200-499 tys.', valueFrom: 200000, valueTo: 499999},
                        {name: 'miasto powyżej 500tys.', valueFrom: 500000, valueTo: 9999999},
                      ];

                      break;
                    }
                  }
                });

                this.setState({
                  data: {
                    ...data,
                    questionCrossed: false,
                    demographies,
                    demographyValues: {
                      demography_age,
                      demography_city_lm,
                    },
                  }
                })
              }}
            />
          </Col>
        </FormGroup>}
        {data.demographies.includes('demography_age') && <FormGroup className="mt-2" row>
          <Col>
            <h1 className="text-center mt-3 w-100">Ustaw przedziały wieku</h1>

            <DemographyValuesTable
              values={data.demographyValues.demography_age}
              onChange={demography_age => this.setState({data: {...data, demographyValues: {
                    ...data.demographyValues,
                    demography_age
              }}})}
            />
          </Col>
        </FormGroup>}
        {data.demographies.includes('demography_city_lm') && <FormGroup className="mt-2" row>
          <Col>
            <h1 className="text-center mt-3 w-100">Ustaw przedziały wielkości miejscowości</h1>

            <DemographyValuesTable
              values={data.demographyValues.demography_city_lm}
              onChange={demography_city_lm => this.setState({data: {...data, demographyValues: {
                ...data.demographyValues,
                demography_city_lm
              }}})}
            />
          </Col>
        </FormGroup>}
        {data.type === 'crossTable' && <FormGroup className="mt-2" row>
          <Label md={3}>Pobierz dane do Excela: </Label>
          <Col md={9} className="d-flex align-items-center">
            <Switch onChange={download => this.setState({data: {...data, download}})} checked={data.download} />
          </Col>
        </FormGroup>}
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.create">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.save}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="primary" onClick={this.toggleModal} className="d-print-none">
          <i className="fas fa-plus" /> Utwórz
        </Button>
        {modal}
      </React.Fragment>
    );
  }
}

ButtonCreate.propTypes = {
  analysisId: PropTypes.number.isRequired,
  onAdded: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    collectorPanelAccess: state.user.userPlan.plan.survey.collectorPanelAccess,
  }
}

export default connect(mapStateToProps)(ButtonCreate);