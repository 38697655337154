import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import { connect } from 'react-redux';
import {updateFilterSerial, deleteFilterSerial} from "scenes/Survey/Structure/data/filterSerial/actions";
import {Card, CardHeader, CardBody, Input, CardFooter} from 'reactstrap';
import SelectQuestions from "scenes/Survey/Structure/components/SelectQuestions";
import CheckAnswers from "scenes/Survey/Structure/components/CheckAnswers";
import CheckRows from "scenes/Survey/Structure/components/CheckRows";
import CheckColumns from "scenes/Survey/Structure/components/CheckColumns";
import ButtonDeleteFilterSerial from "./components/ButtonDeleteFilterSerial";
import FilterBySelect from "../../../FilterBySelect";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import Select from "react-select";
import ButtonCopyFilterSerial from "./components/ButtonCopyFilterSerial";

class FilterSerial extends Component {

  constructor(props) {
    super(props);

    this.state = {
      edited: false,
      saving: false,
      editingName: false,
      filter: {
        id: props.filter.id,
        action: props.filter.action,
        hideQuestionToCondsMin: props.filter.hideQuestionToCondsMin,
        name: props.filter.name,
        questionFrom: {
          question: props.filter.questionFrom ? props.filter.questionFrom.question : null,
          answers: props.filter.questionFrom ? props.filter.questionFrom.answers : null,
          rows: props.filter.questionFrom ? props.filter.questionFrom.rows : null,
          columns: props.filter.questionFrom ? props.filter.questionFrom.columns : null,
          filterBy: props.filter.questionFrom ? props.filter.questionFrom.filterBy : null,
        },
        questionTo: {
          question: props.filter.questionTo.question,
          filterBy: props.filter.questionTo.filterBy,
        },
        answers: props.filter.answers || [],
        rows: props.filter.rows || [],
        columns: props.filter.columns || [],
      }
    };

    this.saveFilter = this.saveFilter.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onQuestionToFilterByChange = this.onQuestionToFilterByChange.bind(this);
    this.onQuestionFromFilterByChange = this.onQuestionFromFilterByChange.bind(this);
    this.onElementsChange = this.onElementsChange.bind(this);
    this.onAnswersChange = this.onAnswersChange.bind(this);
    this.onRowsChange = this.onRowsChange.bind(this);
    this.onColumnsChange = this.onColumnsChange.bind(this);
    this.renderCafeterySelect = this.renderCafeterySelect.bind(this);
  }

  saveFilter() {
    this.setState({...this.state, saving: true, editingName: false});

    this.props.updateFilterSerial({surveyId: this.props.surveyId, filterSerial: this.state.filter})
      .then(() => this.setState({...this.state, edited: false, saving: false}));
  }

  onChange(e) {
    const filterByKeys = ['answers', 'rows', 'columns'];
    let questionFrom = _.find(this.props.questions, question => question.id === e);
    const filterBy = _.find(filterByKeys, key => questionFrom.hasOwnProperty(key));

    this.setState({
      ...this.state,
      edited: true,
      filter: {
        ...this.state.filter,
        questionFrom: {
          filterBy,
          question: e,
          answers: [],
          rows: [],
          columns: []
        }
      }
    });
  }

  onQuestionToFilterByChange(filterBy) {
    this.setState({
      ...this.state,
      edited: true,
      filter: {
        ...this.state.filter,
        questionTo: {
          ...this.state.filter.questionTo,
          filterBy},
        questionFrom: {
          ...this.state.filter.questionFrom,
          answers: [],
          rows: [],
          columns: []
        }
      }
    });
  }
  onQuestionFromFilterByChange(filterBy) {
    this.setState({
      ...this.state,
      edited: true,
      filter: {
        ...this.state.filter,
        questionFrom: {
          ...this.state.filter.questionFrom,
          filterBy
        }
      }
      } );
  }

  onAnswersChange(answers) {
    this.onElementsChange(answers, 'answers')
  }

  onRowsChange(rows) {
    this.onElementsChange(rows, 'rows')
  }

  onColumnsChange(columns) {
    this.onElementsChange(columns, 'columns')
  }

  onElementsChange(elementsIds, elementsType) {
    this.setState({
      ...this.state,
      edited: true,
      filter: {
        ...this.state.filter,
        questionFrom: {
          ...this.state.filter.questionFrom,
          [elementsType]: elementsIds
        }
      }
    })
  }

  renderCafeterySelect(questionFrom) {
    switch (this.state.filter.questionFrom.filterBy) {
      case 'rows':
        return <CheckRows
          questionId={questionFrom.id}
          onChange={this.onRowsChange}
          checked={this.state.filter.questionFrom.rows || []}
        />;
      case 'columns':
        return <CheckColumns
          questionId={questionFrom.id}
          onChange={this.onColumnsChange}
          checked={this.state.filter.questionFrom.columns || []}
        />;
      case 'answers':
        return <CheckAnswers
          questionId={questionFrom.id}
          onChange={this.onAnswersChange}
          checked={this.state.filter.questionFrom.answers || []}
        />;
    }
  }

  render() {
    const {edited, saving} = this.state;

    const actionOptions = [
      {label: 'Pokaż', value: 1},
      {label: 'Ukryj', value: 2},
    ];

    let questionFrom = this.props.questionFrom || _.find(this.props.questions, question => question.id === this.state.filter.questionFrom.question);
    let editor = <Input value={this.state.filter.name} onChange={e => this.setState({edited: true, filter: {...this.state.filter, name: e.target.value}})} onBlur={() => this.setState({editingName: false})} autoFocus={true}/>;

    return (
      <Card className="border">
        <CardHeader className="border-bottom p-2 d-flex align-items-center">
          {this.state.editingName ? editor : <span onClick={() => this.setState({editingName: true})}>{this.state.filter.name || <i><FormattedMessage id="question.filterSerial.questionFrom.name.empty"/></i>}</span>}
          <div className="ml-auto pl-2 py-2">
            <ButtonDeleteFilterSerial filter={this.state.filter}/>
            <ButtonCopyFilterSerial filter={this.state.filter}/>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <FilterBySelect filterQuestion={this.state.filter.questionTo} onChange={this.onQuestionToFilterByChange}/>

          <FormattedMessage id="question.filterSerial.questionTo.questionFrom.label"/>
          <div className="d-flex mb-2">
            <SelectQuestions
              className="w-100"
              enabledTypes={['multi', 'matrix', 'ranked', 'conjoint', 'matrixMulti', 'matrixDropdown']}
              disabledFromPositionInSurvey={this.props.questions[this.state.filter.questionTo.question].positionInSurvey}
              isMulti={false}
              isLoading={this.state.saving}
              onChange={this.onChange}
              value={questionFrom ? questionFrom.id : null}
            />
          </div>

          <Select
            className="mb-2"
            options={actionOptions}
            value={actionOptions.find(option => option.value == this.state.filter.action)}
            onChange={option => {
              this.setState({
                edited: true,
                filter: {
                  ...this.state.filter,
                  action: option.value,
                }
              })
            }}
          />

          {this.state.filter.action == 1 && <div className="d-flex flex-row align-items-center">
            <span>Pokaż pytanie jeśli respondent wybierze co najmniej</span>
            <Input className="mx-2" size="sm" type="number" style={{width: '50px'}} onChange={e => {
              this.setState({
                edited: true,
                filter: {
                  ...this.state.filter,
                  hideQuestionToCondsMin: e.target.value,
                }
              })

            }} value={this.state.filter.hideQuestionToCondsMin}/>
            <span>zaznaczonych poniżej odpowiedzi</span>
          </div>}

          {questionFrom && this.renderCafeterySelect(questionFrom)}
          <FilterBySelect filterQuestion={this.state.filter.questionFrom} onChange={this.onQuestionFromFilterByChange}/>
        </CardBody>
        {edited && <CardFooter>
          <LaddaButton
            className="btn btn-primary btn-ladda d-block mx-auto"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.saveFilter}
          >
            <i className="fas fa-save" /> Zapisz ustawienia
          </LaddaButton>
        </CardFooter>}

      </Card>
    )
  }
}

FilterSerial.propTypes = {
  filter: PropTypes.object.isRequired,
  surveyId: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  position: PropTypes.number.isRequired,
  questionFrom: PropTypes.object,
  questionTo: PropTypes.object,
  filterBy: PropTypes.string
};

function mapStateToProps(state, props) {
  let questionFrom = null;

  if(props.filter.questionFrom){
    questionFrom = state.survey.structure.data.questions[props.filter.questionFrom.question];
  }

  return {
    questions: state.survey.structure.data.questions,
    answers: questionFrom ? _.filter(state.survey.structure.data.answers, element => element.question.id === questionFrom.id) : [],
    rows: questionFrom ? _.filter(state.survey.structure.data.rows, element => element.question.id === questionFrom.id) : [],
    columns: questionFrom ? _.filter(state.survey.structure.data.columns, element => element.question.id === questionFrom.id) : []
  }
}

export default connect(mapStateToProps, {updateFilterSerial, deleteFilterSerial})(FilterSerial);
