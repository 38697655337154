import React, {Component} from 'react';
import { connect } from 'react-redux';
import {FormattedMessage} from "react-intl";
import {setFilterConditionQuestions} from 'scenes/Survey/Structure/actions';
import {Button, Tooltip} from "reactstrap";

class ButtonFilterConditionQuestions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      filterConditionQuestions: props.filterConditionQuestions,
    };

    this.toggleFilterConditionQuestions = this.toggleFilterConditionQuestions.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleFilterConditionQuestions() {
    this.setState({filterConditionQuestions: !this.state.filterConditionQuestions}, () => {
      this.props.setFilterConditionQuestions(this.state.filterConditionQuestions);
    })
  }

  toggleTooltip() {
    this.setState({tooltip: !this.state.tooltip})
  }

  render() {
    return (
      <Button className="mb-0" id="buttonFilterConditionQuestions" color={this.state.filterConditionQuestions ? 'primary' : 'secondary'} onClick={this.toggleFilterConditionQuestions}>
        <i className="fas fa-question"/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip}
          target="buttonFilterConditionQuestions"
          toggle={this.toggleTooltip}
        >
          <FormattedMessage id="topbar.advancedOptions.filterConditionQuestions.label"/>
        </Tooltip>
      </Button>
    )
  }
}

function mapStateToProps(state) {
  return {
    filterConditionQuestions: state.survey.structure.filterConditionQuestions,
  }
}

export default connect(mapStateToProps, {setFilterConditionQuestions})(ButtonFilterConditionQuestions);
