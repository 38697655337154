import * as React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Button from "components/Button";
import {addAttributeLevel} from "../../../../../../../../../../../../../../../../../../../../data/attributes/levels/actions";

function CreateAttributeLevel(props) {
  const [creating, setCreating] = React.useState(false);

  const createAttributeLevel = () => {
    setCreating(true);
    props.addAttributeLevel({
      attribute: props.attribute.id,
    })
    .then(() => {
      setCreating(false)
    })
    .catch(() => {
      setCreating(false)
    })
  }

  return (
    <Button
      size="sm"
      color="secondary"
      onClick={createAttributeLevel}
      loading={creating}
    ><i className="fas fa-plus" /> Utwórz nową wartość</Button>
  );
}

CreateAttributeLevel.propTypes = {
  attribute: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect(null, {addAttributeLevel})(CreateAttributeLevel);