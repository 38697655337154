import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, Row, Col, CardImgOverlay, Input} from "reactstrap";
import striptags from "striptags";
import {FormattedMessage} from "react-intl";
import {updateBlock} from "scenes/Survey/Structure/data/blocks/actions";
import reactStringReplace from 'react-string-replace';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import LoadingSpinner from "components/LoadingSpinner";

class Name extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: props.block.name || '',
      editing: false,
      saving: false
    };

    this.onChange = this.onChange.bind(this);
    this.updateName = this.updateName.bind(this);
  }

  onChange(name){
    this.setState({
      name: name.substring(0, 50)
    })
  }

  updateName(){
    this.setState({
      saving: true,
    });

    const data = {
      id: this.props.block.id,
      block: {
        name: this.state.name
      }
    };

    this.toggleEditing();
    this.props.updateBlock(data).then(() => {
      this.setState({
        saving: false
      });
    }).catch(() => {
      this.setState({
        saving: false
      })
    })
  }

  toggleEditing(){
    this.setState({
      editing: !this.state.editing
    });
  }

  render() {
    const {block, searchInStructure, hideInStructure} = this.props;
    const {saving, editing, name} = this.state;
    let style = {};
    if(saving){
      style.opacity = 0.3
    }

    let nameStyle = hideInStructure === 'minified' ? {
      width: '65%',
      display: 'list-item',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    } : {display: 'contents'};

    const editor = <div className="animated fadeIn w-100">
      <Row>
        <Col>
          <Input value={name} bsSize="sm" onChange={e => this.onChange(e.target.value)} onBlur={this.updateName} autoFocus={true} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mr-2"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={() => this.updateName()}
            >{message}</LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) => <Button onClick={() => this.toggleEditing()}>{message}</Button>
          }</FormattedMessage>
        </Col>
      </Row>
    </div>;

    if (editing) {
      return editor;
    }

    let blockName = striptags(block.name);
    if (searchInStructure.length > 0) {
      blockName = reactStringReplace(blockName, searchInStructure, (match, i) => (
        <span className="bg-warning" key={i}>{match}</span>
      ));
    }

    return (
      <React.Fragment>
        <h2 className="float-left mb-0 p-0 mr-2" onClick={() => this.toggleEditing()} style={{minWidth: 'fit-content'}}>
          <FormattedMessage id="blocks.block.cardHeader.block">
            {msg => <React.Fragment>{msg} {this.props.positionInSurvey+1}{block.name && ':'}</React.Fragment>}
          </FormattedMessage>
        </h2>
        <h2 className="float-left mb-0 p-0" style={nameStyle} onClick={() => this.toggleEditing()}>
          {saving ? <span style={style}>{striptags(this.state.name)}</span> : <span style={{wordBreak: 'break-word'}}>{blockName}</span>}
          {saving && <CardImgOverlay>
            <LoadingSpinner width={25} absolute={true}/>
          </CardImgOverlay>}
        </h2>
      </React.Fragment>
    )
  }
}

Name.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string
  }).isRequired,
};

function mapStateToProps(state){
  return {
    hideInStructure: state.survey.structure.hideInStructure,
    searchInStructure: state.survey.structure.searchInStructure,
  };
}

export default connect(mapStateToProps, { updateBlock })(Name);