import React from 'react';
import PropTypes from 'prop-types';
import {isNull} from "lodash";
import Icon from "../../../../componentsReusable/Icon";
import api from "../../../../api";

const SurveyResponseIpAddressRepeatsCheck = props => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  const fetch = () => {
    setLoading(true);

    api.admin.survey.getSurveyResponsesCountRepeatedIp(props.surveyId)
      .then(({count}) => setData(count))
  }

  if(!isNull(data)){
    return <span>{data}</span>
  }

  if(loading){
    return <Icon tooltip="Trwa pobieranie" className="fas fa-spin fa-spinner" />
  }

  return <Icon className="fas fa-search" tooltip="Pokaż liczbę wywiadów ze zduplikowanym IP" onClick={fetch} />
};

SurveyResponseIpAddressRepeatsCheck.propTypes = {

};

export default SurveyResponseIpAddressRepeatsCheck;
