import React, {Component} from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardTitle,
  CardText
} from "reactstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import { connect } from 'react-redux';
import {addQuestion} from '../../../../data/questions/actions';
import {addQuestions} from '../../../../data/pages/actions';
import SelectQuestionTypes from "../../../SelectQuestionTypes";
import Select from "react-select";
import SelectQuestions from "../../../SelectQuestions";
import _ from "lodash";
import Switch from "components/Switch";
import {getQuestionTemplate} from "services/questionTypes";
import {addQuestionFromTemplate} from "scenes/Survey/Structure/data/questions/actions";
import striptags from 'striptags';
import reactHtmlParser from "react-html-parser";

class QuestionModal extends Component {

  constructor(props) {
    super(props);

    let all = document.querySelectorAll("[data-type='questionCard']");
    let questionId;

    for (let i = 0; i < all.length; i++) {
      if (!all[i + 1]) {
        questionId = parseInt(all[i].getAttribute('data-id'));
        break;
      }

      let nextRect = all[i + 1].getBoundingClientRect();

      if (nextRect.top - nextRect.height - 80 > 0) {
        questionId = parseInt(all[i].getAttribute('data-id'));
        break;
      }
    }

    this.state = {
      loading: false,
      type: 'single',
      shift: { value: 0, label: <FormattedMessage id="createElementButton.questionModal.label.shift.before"/>},
      question: Object.keys(props.questions).length > 0 ? questionId : null,
      template: Object.keys(props.templates).length > 0 ? props.templates[Object.keys(props.templates)[0]].id : null,
      questionList: false,
      questionTemplate: false,
      text: '',
      data: []
    };

    this.submit = this.submit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.addQuestionList = this.addQuestionList.bind(this);
  }

  onChange(e) {
    let questions = e.target.value.split(/\n{2,}/).filter((text) => text.trim() !== "");
    let data = [];

    _.each(questions, item => {
      let arr = item.split("\n").filter((text) => text.trim() !== "");
      let question = {};
      if(_.isEmpty(arr.slice(1))){
        question = {
          content: arr[0],
          type: 'open',
        };
      }else{
        question = {
          content: arr[0],
          type: 'single',
          answers: arr.slice(1)
        };
      }

      data.push(question);
    });
    this.setState({ data: data, text: e.target.value });
  }

  submit() {
    this.setState({loading: true});

    const page = this.state.question
      ? _.find(this.props.pages, page => page.questions.includes(this.state.question))
      : _.sortBy(this.props.pages, page => page.position)[0];

    const position = this.state.question
      ? _.find(this.props.questions, question => question.id === this.state.question).position + this.state.shift.value
      : 0;

    if (this.state.questionList) {
      this.addQuestionList(page, position)
    } else if (this.state.questionTemplate) {
      this.addQuestionFromTemplate(page, position)
    } else {
      this.addQuestion(page, position);
    }
  }

  addQuestion(page, position) {
    const questionType = getQuestionTemplate(this.state.type);

    let data = {
      page: page.id,
      questions: [
        {
          type: questionType.type,
          position: position,
          ...questionType.options
        }
      ]
    };

    this.props.addQuestion(data).then(() => {
      this.setState({loading: false});
      this.props.toggle();
    })
  }

  addQuestionList(page, position) {
    let questions = [];

    _.each(this.state.data, question => {
      let questionCopy = Object.assign({}, question);
      let answers = [];

      _.each(questionCopy.answers, answer => answers.push({content: answer}));
      questionCopy.answers = answers;
      questions.push(questionCopy);
    });

    this.props.addQuestions(page.id, {position: position, elements: questions}).then(() => {
      this.setState({loading: false});
      this.props.toggle();
    })
  }

  addQuestionFromTemplate(page, position) {
    this.props.addQuestionFromTemplate({
      templates: [this.state.template],
      page: page.id,
      position: position
    }).then(() => {
      this.setState({loading: false});
      this.props.toggle();
    })
  }

  render() {
    const hasPages = Object.keys(this.props.pages).length > 0;
    const hasTemplates = Object.keys(this.props.templates).length > 0;

    const questionList = [];

    _.each(this.state.data, (question, questionKey) => {
      const answerList = [];
      _.each(question.answers, (answer, answerKey) => {
        answerList.push(
          <span key={answerKey} className="d-block"><FormattedMessage id="questions.buttonAddQuestionList.answerList.label" /> {answerKey + 1}: {answer}</span>,
        );
      });

      questionList.push(
        <Card key={questionKey} className="bg-light border border-dark mb-2 p-2">
          <CardTitle>
            <p className="mb-0">{question.content}</p>
            <p className="mb-0 text-muted">{this.props.intl.formatMessage({id: '_.question.' + question.type + '.typeName'})}</p>
          </CardTitle>
          <CardText>
            {answerList}
          </CardText>
        </Card>
      );
    });

    return (
      <Modal size="xl" isOpen={true} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}><FormattedMessage id="createElementButton.questionModal.modalHeader" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          {!hasPages ? <p><FormattedMessage id="createElementButton.questionModal.modalBody.noPages" /></p> : <React.Fragment>

            {!this.state.questionTemplate && <Row>
              <Col lg={3}>
                <Label><FormattedMessage id="createElementButton.questionModal.label.list" /></Label>
              </Col>
              <Col lg={9}>
                <Switch checked={this.state.questionList} onChange={value => this.setState({questionList: value})} />
              </Col>
            </Row>}

            {!this.state.questionList && hasTemplates && <Row>
              <Col lg={3}>
                <Label><FormattedMessage id="createElementButton.questionModal.label.questionTemplate" /></Label>
              </Col>
              <Col lg={9}>
                <Switch checked={this.state.questionTemplate} onChange={value => this.setState({questionTemplate: value})} />
              </Col>
            </Row>}

            {this.state.questionList ?

              <Row>
                <Col lg={7}>
                  <FormattedMessage id="questions.buttonAddQuestionList.input.placeholder">{
                    (message) => <Input
                      type="textarea"
                      name="text"
                      onChange={this.onChange}
                      value={this.state.text}
                      placeholder={message}
                      rows={16}
                    />
                  }</FormattedMessage>
                </Col>
                <Col lg={5}>
                  <Card body style={{height: 400, overflowY: 'scroll'}}>
                    {questionList.length > 0 ? questionList : <FormattedMessage id="questions.buttonAddQuestionList.questionList.noQuestions" />}
                  </Card>
                </Col>
              </Row>

              :
              this.state.questionTemplate ? <Row>
                  <Col lg={3}>
                    <Label for="shift"><FormattedMessage id="createElementButton.questionModal.label.template" /></Label>
                  </Col>
                  <Col lg={9}>
                    <Select
                      ref={this.select}
                      value={this.state.template ? {label: reactHtmlParser(striptags(this.props.templates[this.state.template].content)), value: this.props.templates[this.state.template].id} : null}
                      isOpen={false}
                      isMulti={false}
                      onChange={data => this.setState({template: data.value})}
                      options={_.map(this.props.templates, template => {
                        return {
                          label: reactHtmlParser(striptags(template.content)),
                          value: template.id,
                        }
                      })}
                    />
                  </Col>
                </Row>
                : <Row>
                <Col lg={3}>
                  <Label for="shift"><FormattedMessage id="createElementButton.questionModal.label.type" /></Label>
                </Col>
                <Col lg={9}>
                  <SelectQuestionTypes isMulti={false} onChange={type => this.setState({type})} value={this.state.type}/>
                </Col>
              </Row>
            }

              {this.state.question && <React.Fragment>
                <Row className="mt-3">
                  <Col lg={3}>
                    <Label for="shift"><FormattedMessage id="createElementButton.questionModal.label.shift" /></Label>
                  </Col>
                  <Col lg={9}>
                    <Select
                      clearable={false}
                      value={this.state.shift}
                      options={[
                        { value: 0, label: <FormattedMessage id="createElementButton.questionModal.label.shift.before" />},
                        { value: 1, label: <FormattedMessage id="createElementButton.questionModal.label.shift.after" />},
                      ]}
                      onChange={shift => this.setState({shift: shift})}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={3}>
                    <Label for="shift"><FormattedMessage id="createElementButton.questionModal.label.question" /></Label>
                  </Col>
                  <Col lg={9}>
                    <SelectQuestions
                      isMulti={false}
                      onChange={question => this.setState({question})}
                      value={this.state.question}
                    />
                  </Col>
                </Row>
            </React.Fragment>}
          </React.Fragment>}
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.add">{
            (message) => <LaddaButton
              className="btn btn-success btn-ladda"
              data-style={ZOOM_OUT}
              loading={this.state.loading}
              onClick={this.submit}
              disabled={!hasPages || (this.state.questionList && questionList.length === 0)}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggle}>{message}</Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    )
  }
}

QuestionModal.propTypes = {
  toggle: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  let pages = [];
  let questions = [];

  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    _.each(state.survey.structure.data.sets[setId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        pages.push(state.survey.structure.data.pages[pageId]);

        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          questions.push(state.survey.structure.data.questions[questionId])
        })
      });
    });
  });

  return {
    pages,
    questions,
    templates: state.globalTemplates
  }
}

export default connect(mapStateToProps, {addQuestion, addQuestions, addQuestionFromTemplate})(injectIntl(QuestionModal));