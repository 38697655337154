import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PrizeForm from './PrizeForm';
import {fetchPrizes, addPrize} from "../actions";
import {connect} from "react-redux";

class PrizeCreate extends Component {

  componentDidMount(){
    this.props.fetchPrizes();
  }

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader><FormattedMessage id="admin.prizeCreate.cardHeader" /></CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Row>
                <Col>
                  <PrizeForm prizes={this.props.prizes} handleSave={this.props.addPrize}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    prizes: state.admin.prizes.data.prizes
  }
}

export default connect(mapStateToProps, {fetchPrizes, addPrize})(PrizeCreate);