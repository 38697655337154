import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import GroupUserEditModal from "./GroupUserEditModal";
import GroupUserBlockModal from "./GroupUserBlockModal";
import GroupUserDeleteModal from "./GroupUserDeleteModal";
import GroupUserRewriteSurveysModal from "./GroupUserRewriteSurveysModal";
import {connect} from "react-redux";
import {fetchGroups} from "../actions";
import {setPlanRestrictionsModals} from "../../../../reducers/planRestrictionsModals/actions";

class GroupUsersActions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      rewriteModal: false,
      editModal: false,
      blockModal: false,
      deleteModal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(property) {
    this.setState({
      [property]: !this.state[property]
    })
  }

  render() {
    const {user, group, ownUser} = this.props;
    const ownUserGroup = ownUser.userGroups.find(() => true);
    const isSameUser = user.user.id === ownUser.id;
    const isUserAdmin = user.role === 'admin';
    const isOwnUserAdmin = ownUserGroup && ownUserGroup.role === 'admin';
    const isSamePrivilegeGroup = user.privilageSurveyShareDefault === (ownUserGroup && ownUserGroup.privilageSurveyShareDefault.id);
    let isAbleToEdit = isOwnUserAdmin ? true : !isSamePrivilegeGroup;
    if (isSameUser || isUserAdmin) {
      isAbleToEdit = false;
    }

    if (isAbleToEdit === false) {
      return (<ul/>)
    }

    return (
      <ul className="float-right mb-0 p-0 d-flex flex-column flex-md-row justify-content-center justify-content-md-end w-100 text-center">
        <li className="float-left mr-2">
          <Button color="" className="m-0 p-0"> <a onClick={() => this.toggle('rewriteModal')}><i className="fas fa-retweet"/></a></Button>
        </li>
        <li className="float-left mr-2">
          <Button color="" className="m-0 p-0"> <a onClick={() => this.toggle('editModal')}><i className="fas fa-edit"/></a></Button>
        </li>
        <li className="float-left">
          <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle('dropdownOpen')} className={'dd-' + user.id}>
            <DropdownToggle className="m-0 p-0" color="" data-toggle={'dd-' + user.id}>
              <span className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.toggle('blockModal')} tag={"a"}>
                <p><i className={user.blocked ? 'fas fa-toggle-off' : 'fas fa-toggle-on'}/></p> {user.blocked ? <FormattedMessage tagName="h5" id="groupUsersActions.button.disable"/> : <FormattedMessage tagName="h5" id="groupUsersActions.button.enable"/>}
              </DropdownItem>
              <DropdownItem onClick={() => this.toggle('deleteModal')} tag={"a"}>
                <p><i className="fas fa-trash" /></p> <FormattedMessage tagName="h5" id="_.button.delete" />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </li>
        {this.state.rewriteModal && <GroupUserRewriteSurveysModal toggleModal={() => this.toggle('rewriteModal')} user={user} />}
        <GroupUserEditModal isOpen={this.state.editModal} toggleModal={() => this.toggle('editModal')} user={user} group={group}/>
        <GroupUserBlockModal isOpen={this.state.blockModal} toggleModal={() => this.toggle('blockModal')} user={user} group={group}/>
        <GroupUserDeleteModal isOpen={this.state.deleteModal} toggleModal={() => this.toggle('deleteModal')} user={user} group={group}/>
      </ul>
    )
  }
}

GroupUsersActions.propTypes = {
  user: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ownUser: state.user
  }
}

export default connect(mapStateToProps)(GroupUsersActions);