import React, {Component} from 'react';
import {Form, FormGroup, Label, Input, Col} from "reactstrap";
import {find} from "lodash";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import Select from "react-select";
import Switch from "../../../../components/Switch";
import {connect} from "react-redux";

class GroupUserForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      passwordGenerated: false,
      firstname: props.user ? props.user.user.firstname : '',
      email: props.user ? props.user.email : '',
      password: '',
      privilageSurveyShareDefault: props.user ? props.user.privilageSurveyShareDefault : props.surveySharePrivilages[Object.keys(props.surveySharePrivilages)[0]].id,
      cint: props.user ? props.user.cint : false,
      allowSurveyStart: props.user ? props.user.allowSurveyStart : true,
      allowSurveyCollectorPanelCreateSendEmail: props.user ? props.user.allowSurveyCollectorPanelCreateSendEmail : true,
    };
    this.passwordGenerate = this.passwordGenerate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  passwordGenerate(){
    var numberChars = "0123456789";
    var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lowerChars = "abcdefghijklmnopqrstuvwxyz";
    var specialChars = "~!#$%&*-+|";

    let pass = [
      numberChars[Math.floor(Math.random() * ((numberChars.length-1) - 0))],
      numberChars[Math.floor(Math.random() * ((numberChars.length-1) - 0))],
      upperChars[Math.floor(Math.random() * ((upperChars.length-1) - 0))],
      upperChars[Math.floor(Math.random() * ((upperChars.length-1) - 0))],
      lowerChars[Math.floor(Math.random() * ((lowerChars.length-1) - 0))],
      lowerChars[Math.floor(Math.random() * ((lowerChars.length-1) - 0))],
      specialChars[Math.floor(Math.random() * ((specialChars.length-1) - 0))],
      specialChars[Math.floor(Math.random() * ((specialChars.length-1) - 0))],
    ];

    pass = pass.sort(() => Math.random() - 0.5).join('');

    this.setState({
      passwordGenerated: pass,
      password: pass,
    })
  }

  onChange(e) {
    let data = {
      [e.target.name]: e.target.value
    }
    if(e.target.name === 'password'){
      data.passwordGenerated = false;
    }
    this.setState(data);
  }

  render() {
    const {errors, surveySharePrivilages} = this.props;

    let privilageDefaultValue = {
      value: surveySharePrivilages[Object.keys(surveySharePrivilages)[0]].id,
      label: surveySharePrivilages[Object.keys(surveySharePrivilages)[0]].name
    };

    return (
      <Form onSubmit={e => this.props.onSubmit(e, this.state)}>
        <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right"><FormattedMessage id="groupUserForm.label.privilageSurveyShareDefault"/>:</Label>
          <Col lg={8}>
            <Select
              value={this.state.privilageSurveyShareDefault ? {
                value: this.state.privilageSurveyShareDefault,
                label: _.find(surveySharePrivilages, privilage => privilage.id === this.state.privilageSurveyShareDefault).name
              } : privilageDefaultValue}
              onChange={value => this.setState({privilageSurveyShareDefault: value.value})}
              options={_.map(surveySharePrivilages, privilage => {
                return {
                  value: privilage.id,
                  label: privilage.name,
                }
              })}
              className={errors.privilageSurveyShareDefault && "border border-danger"}
            />
          </Col>
        </FormGroup>
        {this.state.privilageSurveyShareDefault && <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right d-flex align-items-center justify-content-end">Opis uprawnień:</Label>
          <Col lg={8} className="d-flex align-items-center py-4">
            <span>{find(surveySharePrivilages, p => p.id === this.state.privilageSurveyShareDefault).description}</span>
          </Col>
        </FormGroup>}
        <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right"><FormattedMessage id="respondentBaseForm.label.firstname"/>:</Label>
          <Col lg={8}>
            <Input type="firstname" name="firstname" id="firstname" invalid={!!errors.firstname} value={this.state.firstname} onChange={this.onChange} disabled={!!this.props.user}/>
            {errors.firstname && <div className="invalid-feedback">{errors.firstname.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right"><FormattedMessage id="respondentBaseForm.label.email"/>:</Label>
          <Col lg={8}>
            <Input type="email" name="email" id="email" invalid={!!errors.email} value={this.state.email} onChange={this.onChange} disabled={!!this.props.user}/>
            {errors.email && <div className="invalid-feedback">{errors.email.join('; ')}</div>}
          </Col>
        </FormGroup>
        {!this.props.user && <React.Fragment>
          <FormGroup row className="mt-2">
            <Label lg={4} className="text-lg-right"><FormattedMessage id="respondentBaseForm.label.password"/>:</Label>
            <Col lg={8}>
              <Input type="password" name="password" id="password" invalid={!!errors.password} value={this.state.password} onChange={this.onChange}/>
              {errors.password && <div className="invalid-feedback">{errors.password.join('; ')}</div>}
            </Col>
          </FormGroup>
          <FormGroup row className="mt-2">
            <Label lg={4} className="text-lg-right">Wygeneruj hasło:</Label>
            <Col lg={8} className="d-flex flex-column justify-content-start">
              <small className="pointer" onClick={this.passwordGenerate}>Kliknij aby wygenerować losowe hasło</small>
              {this.state.passwordGenerated && <span className="font-weight-bold">{this.state.passwordGenerated}</span>}
            </Col>
          </FormGroup>
        </React.Fragment>}
        <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right">Wł/Wył ankiet:</Label>
          <Col lg={8} className="d-flex align-items-center">
            <Switch onChange={allowSurveyStart => this.setState({allowSurveyStart})} checked={this.state.allowSurveyStart}/>
          </Col>
        </FormGroup>
        {this.props.plan.survey.collectorPanelAccess && <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right">Wysyłanie ankiet:</Label>
          <Col lg={8} className="d-flex align-items-center">
            <Switch onChange={allowSurveyCollectorPanelCreateSendEmail => this.setState({allowSurveyCollectorPanelCreateSendEmail})} checked={this.state.allowSurveyCollectorPanelCreateSendEmail}/>
          </Col>
        </FormGroup>}
        {this.props.plan.cint && <FormGroup row className="mt-2">
          <Label lg={4} className="text-lg-right">Cint:</Label>
          <Col lg={8} className="d-flex align-items-center">
            <Switch onChange={cint => this.setState({cint})} checked={this.state.cint} />
          </Col>
        </FormGroup>}
      </Form>
    )
  }
}

GroupUserForm.propTypes = {
  surveySharePrivilages: PropTypes.shape(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  user: PropTypes.object
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(GroupUserForm);