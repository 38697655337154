import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from "reactstrap";
import {FormattedMessage} from "react-intl";
import NewsList from "./NewsList";
import NewsCreateButton from "./NewsCreateButton";

class News extends Component {

  constructor(props) {
    super(props);

    this.list = React.createRef();
  }

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">
                    <FormattedMessage id="admin.news.cardHeader"/>
                  </h2>
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <NewsCreateButton handleChange={() => this.list.current.fetchData({page: this.list.current.state.page, pageSize: this.list.current.state.pageSize, sorted: this.list.current.state.sorted})}/>
              <Row>
                <Col>
                  <NewsList ref={this.list}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default News;