import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import {toast} from "react-toastify";
import api from "../../../../api";

class MediaInfoDeleteButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    }
  }

  reportDelete(){
    this.setState({
      deleting: true
    });

    api.mediaInfo.delete({
      id: this.props.report.id
    }).then(() => {
      this.props.handleChange();
      this.setState({deleting: false, modal: false});
      toast.success(<FormattedMessage id="admin.reportDeleteButton.toast.success" />);
    }).catch(() => {
      this.setState({
        deleting: false
      });
    });
  }

  toggleModalDelete(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { deleting } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModalDelete()}>
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2"><FormattedMessage id="admin.reportDeleteButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="admin.reportDeleteButton.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.modal.delete.confirmation">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={() => this.reportDelete()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModalDelete()}>{message}</Button>
        }</FormattedMessage>

      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <FormattedMessage id="_.button.delete">{
          (message) =>
            <Button color="danger" size="sm" onClick={() => this.toggleModalDelete()}><i className="fas fa-times" /> {message}</Button>
        }</FormattedMessage>
        {modal}
      </React.Fragment>
    )
  }
}

MediaInfoDeleteButton.propTypes = {
  report: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default MediaInfoDeleteButton;