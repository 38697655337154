import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Switch from "components/Switch";
import {FormGroup, Label} from "reactstrap";
import {FormattedMessage} from "react-intl";

class GlobalSwitcher extends PureComponent {

  constructor(props, context) {
    super(props, context);

    const notChecked = _.find(props.data.survey.questionSettings, questionType => {
      return questionType.hasOwnProperty(this.props.setting) && !questionType[this.props.setting];
    });

    this.state = {
      allChecked: typeof notChecked !== 'object'
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(value) {
    let data = this.props.data.survey.questionSettings;

    _.each(data, (questionType, typeName) => {
      if (questionType.hasOwnProperty(this.props.setting)) {
        data[typeName][this.props.setting] = value;
        this.props.onChangeQuestionSettings(typeName, data[typeName]);
      }
    })
  }

  render() {
    return (
      <FormGroup>
        <Label for="disabled">{this.props.children}</Label>
        <Switch onChange={this.toggle} checked={this.state.allChecked} />
      </FormGroup>
    );
  }
}

GlobalSwitcher.propTypes = {
  data: PropTypes.shape({
    survey: PropTypes.shape({
      questionSettings: PropTypes.shape({
        single: PropTypes.object.isRequired,
        multi: PropTypes.object.isRequired,
        ranked: PropTypes.object.isRequired,
        maxDiff: PropTypes.object.isRequired,
        numericMulti: PropTypes.object.isRequired,
        basket: PropTypes.object.isRequired,
        matrixDropdown: PropTypes.object.isRequired,
      }).isRequired
    }).isRequired,
  }),
  onChangeQuestionSettings: PropTypes.func.isRequired,
  setting: PropTypes.string.isRequired,
};

export default GlobalSwitcher;