import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "../../Button";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import RespondentForm from "./RespondentForm";

class ButtonEdit extends Component {
  constructor(props) {
    super(props);

    this.respondentForm = React.createRef();

    this.state = {
      modalOpen: false,
      saving: false,
    };

    this.save = this.save.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  save(){
    this.respondentForm.current.save();
  }

  toggleModal(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {saving, modalOpen} = this.state;

    return <React.Fragment>
      <Button color="" onClick={this.toggleModal}><i className="fas fa-edit" /></Button>
      <Modal isOpen={modalOpen} toggle={this.toggleModal} size="lg">
        <ModalHeader tag="h2" toggle={this.toggleModal}>Edytuj respondenta</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <RespondentForm
            data={this.props.respondent}
            ref={this.respondentForm}
            onSaved={() => {
              this.toggleModal();
              this.props.onSaved();
            }}
            onSavingStart={() => this.setState({saving: true})}
            onSavingStop={() => this.setState({saving: false})}
          />
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className={['mb-0']} onClick={this.toggleModal}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.save">{
            (message) => <Button
              color="primary"
              loading={saving}
              onClick={this.save}
            >
              {message}
            </Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    </React.Fragment>;
  }
}

ButtonEdit.propTypes = {
  respondent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    phone: PropTypes.string.isRequired,
    column_1: PropTypes.string,
  }).isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default ButtonEdit;