import api from "api";
import {normalize} from "normalizr";
import schemaAnalysisFilterCollector from "entities/analysis/filterCollector/schema";
import {REQUEST, SUCCESS} from "../analysis/actions";

export const SET = 'Analysis/data/filtersCollector/SET';
export const ADDED = 'Analysis/data/filtersCollector/ADDED';
export const DELETED = 'Analysis/data/filtersCollector/DELETED';

export function setFiltersCollector(filtersCollector) {
  return {
    type: SET,
    filtersCollector
  }
}

function filterCollectorAdded(filterCollector){
  return {
    type: ADDED,
    filterCollector
  }
}

function filterCollectorDeleted(id){
  return {
    type: DELETED,
    id
  }
}


export function saveFilterCollector(filter) {
  return dispatch => {
    dispatch({type: REQUEST});

    return api.analysis.post.filterCollector(filter).then(filterCollectorData => {
      dispatch({type: SUCCESS});

      const normalized = normalize(filterCollectorData, schemaAnalysisFilterCollector);
      const filterCollector = normalized.entities.filtersCollector[normalized.result];

      dispatch(filterCollectorAdded(filterCollector));

      return filterCollector;
    });
  }
}

export function deleteFilterCollector(data){
  return dispatch => {
    return api.analysis.filterCollector.delete.filterCollector(data).then(() => {
      dispatch(filterCollectorDeleted(data.id));
    });
  }
}