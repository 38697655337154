import React, {useEffect} from 'react';
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import {connect} from "react-redux";
import api from "../api";
import useDidMountEffect from "../utils/useDidMountEffect";
import {Link} from "react-router-dom";
import appRoutes from "../appRoutes";
import SurveySelectNew from "../componentsNew/Survey/SurveySelectNew";
import useAfterUpdateEffect from "../utils/useAfterUpdateEffect";

const SurveySendEmailStatsView = props => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [surveys, setSurveys] = React.useState([]);

  useDidMountEffect(() => {
    fetchData();
  }, [props.survey])

  useAfterUpdateEffect(() => {
    fetchData();
  }, [surveys])

  const fetchData = () => {
    setLoading(true)

    api.survey.getSurveyEmailStats({
      surveys
    })
      .then(res => setData(res.data.data))
      .finally(() => setLoading(false))
  }

  const statusCountPercent = (count, percent) => {
    return <div className="d-flex justify-content-end align-items-center">
      <span>{count}</span>
      <div className="text-secondary" style={{fontSize: '0.8em', width: '50px'}}>({percent.toFixed(2)}%)</div>
    </div>
  }

  const statusCountPercentFooter = (count, percent) => {
    return <div className="d-flex justify-content-end align-items-center">
      <span>{count}</span>
      <div className="text-white" style={{fontSize: '0.8em', width: '50px'}}>({percent.toFixed(2)}%)</div>
    </div>
  }

  const getDataSum = (key) => {
    return data.reduce((acc, item) => acc + parseInt(item[key]), 0)
  }

  return (
    <main>
      <Card>
        <CardHeader>
          <h2>Statystyki wysyłek</h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <div className="ml-auto mb-2" style={{width: '500px', maxWidth: '100%'}}>
            <SurveySelectNew
              filters={{
                collectorPanelSendEmailNotEmpty: 1,
              }}
              placeholder={'Znajdź ankietę...'}
              isMulti
              onChange={setSurveys}
            />
          </div>
          <Table size={'sm'} bordered hover>
            <thead>
            <tr>
              <th rowSpan={2}>Id</th>
              <th rowSpan={2}>Nazwa</th>
              <th rowSpan={2}>Wywiadów</th>
              <th rowSpan={2}>CP (link/email)</th>
              <th rowSpan={2}>Liczba kolektorów z wysyłką</th>
              <th rowSpan={2}>Wielkość bazy</th>
              <th colSpan={3} className="text-center">Wiadomości</th>
              <th rowSpan={2}>Zakończonych</th>
              <th rowSpan={2}>Screenout + kwota</th>
              <th rowSpan={2}>Nieukończonych</th>
              <th rowSpan={2}>Niezaczętych</th>
              <th colSpan={2} className="text-center">RR</th>
            </tr>
            <tr>
              <th>Wysłanych</th>
              <th>Otwartych</th>
              <th>Wyświetleń</th>
              <th>przed SO</th>
              <th>po SO</th>
            </tr>
            </thead>
            <tbody>
            {loading && <tr>
              <td colSpan={12} className="text-center">
                <i className="fas fa-spin fa-spinner" />
              </td>
            </tr>}
            {!loading && data.length === 0 && <tr>
              <td colSpan={12} className="text-center">
                <span>Brak danych</span>
              </td>
            </tr>}
            {!loading && data.map(row => <tr className="text-right">
              <td className="text-left">{row.id}</td>
              <td className="text-left"><Link to={appRoutes.survey.collectors.list(row.id)}>{row.name}</Link></td>
              <td>{row.end} {row.screenOut > 0 && <span>(+{row.screenOut} screenout)</span>}</td>
              <td>{row.endCashPoints}/{row.endCashPointsEmail}</td>
              <td>{row.sendEmailCount}</td>
              <td>{row.respondentsCount}</td>
              <td>{row.sentCount}</td>
              <td>{statusCountPercent(row.sentDisplayCount, row.respondentsCount > 0 ? (row.sentDisplayCount / row.respondentsCount * 100) : 0)}</td>
              <td>{row.sentSumDisplayCount}</td>
              <td>{statusCountPercent(row.srEndCount, row.respondentsCount > 0 ? (row.srEndCount / row.respondentsCount * 100) : 0)}</td>
              <td>{statusCountPercent(row.srScreenoutCount, row.respondentsCount > 0 ? (row.srScreenoutCount / row.respondentsCount * 100) : 0)}</td>
              <td>{statusCountPercent(row.srBreakCount, row.respondentsCount > 0 ? (row.srBreakCount / row.respondentsCount * 100) : 0)}</td>
              <td>{statusCountPercent(row.srWithoutCount, row.respondentsCount > 0 ? (row.srWithoutCount / row.respondentsCount * 100) : 0)}</td>
              <td>{statusCountPercent(row.srRrBeforeSoCount, row.respondentsCount > 0 ? (row.srRrBeforeSoCount / row.respondentsCount * 100) : 0)}</td>
              <td>{statusCountPercent(row.srRrAfterSoCount, row.respondentsCount > 0 ? (row.srRrAfterSoCount / row.respondentsCount * 100) : 0)}</td>
            </tr>)}

            </tbody>
            {!loading && data.length > 0 && <tfoot>
            <tr className="bg-dark text-white font-weight-bold text-right">
              <td colSpan={2}>RAZEM</td>
              <td>{getDataSum('end')} {getDataSum('screenOut') > 0 && <span>(+{getDataSum('screenOut')} screenout)</span>}</td>
              <td></td>
              <td>{getDataSum('sendEmailCount')}</td>
              <td>{getDataSum('respondentsCount')}</td>
              <td>{getDataSum('sentCount')}</td>
              <td>{statusCountPercentFooter(getDataSum('sentDisplayCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('sentDisplayCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{getDataSum('sentSumDisplayCount')}</td>
              <td>{statusCountPercentFooter(getDataSum('srEndCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srEndCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{statusCountPercentFooter(getDataSum('srScreenoutCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srScreenoutCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{statusCountPercentFooter(getDataSum('srBreakCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srBreakCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{statusCountPercentFooter(getDataSum('srWithoutCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srWithoutCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{statusCountPercentFooter(getDataSum('srRrBeforeSoCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srRrBeforeSoCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
              <td>{statusCountPercentFooter(getDataSum('srRrAfterSoCount'), getDataSum('respondentsCount') > 0 ? (getDataSum('srRrAfterSoCount') / getDataSum('respondentsCount') * 100) : 0)}</td>
            </tr>
            </tfoot>}
          </Table>
        </CardBody>
      </Card>
    </main>
  );
};

SurveySendEmailStatsView.propTypes = {

};

function mapStateToProps(state) {
  return {
    plan: state.user.plan,
  }
}

export default connect(mapStateToProps)(SurveySendEmailStatsView);