import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Input, Tooltip, Modal, ModalHeader, ModalBody, ModalFooter, Alert} from "reactstrap";
import classnames from "classnames";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {toast} from "react-toastify";
import api from "api";
import {connect} from "react-redux";

class PhoneValidationSms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      question: {
        id: props.question.id,
        phoneValidationSms: props.question.phoneValidationSms,
      },
      modalIsOpen: false,
      tooltip: false,
    };

    this.modalToggle = this.modalToggle.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.handlerOnChangeValue = this.handlerOnChangeValue.bind(this);
  }

  modalToggle() {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    });
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  handlerOnChangeValue(){
    const {question} = this.state;
    const {user} = this.props;

    let valid = !!user.smsApiToken;

    if(user.userGroups.length > 0){
      if(!!user.userGroups[0].group.user.smsApiToken){
        valid = true;
      }
    }

    if(!question.phoneValidationSms && !valid){
      this.modalToggle();
      return;
    }

    this.setState({
      question: {
        ...question,
        phoneValidationSms: !question.phoneValidationSms
      }
    }, () => {
      this.props.handleUpdate(this.state.question)
    })
  }

  render() {
    let { modalIsOpen, tooltip, question } = this.state;

    return <React.Fragment>
      <Button className={classnames({
        'mr-1 btn-icon float-left': true,
        'active': question.phoneValidationSms,
      })} id={"phoneValidationSms-" + this.props.question.id} onClick={this.handlerOnChangeValue}>
        <i className="fa-solid fa-comment-sms" />
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={"phoneValidationSms-" + this.props.question.id}
          toggle={() => this.toggleTooltip()}
        >Walidacja SMS</Tooltip>
      </Button>
      {modalIsOpen && <Modal isOpen={true} toggle={this.modalToggle} size="lg">
        <ModalHeader tag="h2" toggle={this.modalToggle}>Brak SmsApi Token</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <Alert color="info">
            Aby włączyć opcję walidacji numeru telefonu za pomocą wysyłki sms, musisz podać token do swojego konta w SmsApi.pl. Możesz to zrobić przechodząc do ustawień swojego konta.
          </Alert>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.close">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={this.modalToggle}>{message}</Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  }
}

PhoneValidationSms.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    phoneValidationSms: PropTypes.bool.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(PhoneValidationSms);
