import React, {Component} from 'react';
import PropTypes from 'prop-types';
import api from "../../../../../../../../api";
import LoadingSpinner from "../../../../../../../../components/LoadingSpinner";
import ButtonCreate from "../ButtonCreate";
import {Alert, TabPane} from "reactstrap";
import AdvancedView from "./type/AdvancedView";
import classnames from "classnames";
import {connect} from "react-redux";
import moment from "moment";
import {ReactSortable} from "react-sortablejs";
import './styles.scss';

class AdvancedList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      loading: false,
      data: [],
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      search: '',
      filters: {
        ...props.filters,
        analysis: props.analysis,
      },
      searchTimeout: null,
      sortField: 'a.position',
      sortOrder: 'asc',
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleOnDeleted = this.handleOnDeleted.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleOnDeleted(deletedId){
    const {data} = this.state;
    this.setState({
      data: data.filter(d => d.id !== deletedId)
    });
  }

  fetchData() {
    const {sizePerPage, page, search, filters, sortField, sortOrder} = this.state;

    this.setState({loading: true});

    api.analysis.advanced.search({
      page,
      limit: sizePerPage,
      search,
      filters,
    }, {
      sortField,
      sortOrder,
    })
    .then((res) => {
      this.setState({
        loading: false,
        data: res.data,
        totalSize: res.total,
      });
    })
    .catch(res => {
      this.setState({loading: false});
    });
  }

  render() {
    const {loading, data, dragging} = this.state;
    const {survey} = this.props;

    if(loading){
      return <LoadingSpinner />;
    }

    return <TabPane
      id="advanced"
      className="fade show p-0 pt-2 p-lg-3"
      style={{
        height: '100%',
        overflow: 'auto',
      }}
    >
      <div className="d-none d-print-flex justify-content-between mb-4">
        <small>{moment().format('YYYY-MM-DD')}</small>
        <small>{survey.name} | ANKIETEO</small>
      </div>
      <h2 className="d-none d-print-block w-100 text-center mb-4">Analiza statystyczna</h2>
      <div className={classnames({
        'd-flex flex-wrap flex-column': true,
        'dragging': dragging
      })}>
        {data.length === 0 && <Alert color="info" className="text-center">Brak utworzonych widoków</Alert>}
        {data.length > 0 && <ReactSortable
          list={data}
          setList={() => {}}
          draggable={'.sortable-draggable'}
          handle={'.handler-drag-position'}
          onStart={() => {
            this.setState({
              dragging: true,
            })
          }}
          onEnd={() => {
            this.setState({
              dragging: false,
            })
          }}
          onUpdate={(event) => {
            const id = event.item.attributes['data-id'].nodeValue;
            const position = event.newIndex;

            api.analysis.advanced.patch(id, {position})
          }}
          animation={100}
          tag="ul"
          className="answers-list list-group"
        >
          {data.map(advanced => <AdvancedView
            key={advanced.id.toString()}
            onDeleted={this.handleOnDeleted}
            advanced={advanced}
          />)}
        </ReactSortable>}
      </div>

      <p className="d-none d-print-block w-100 text-center">Raport wygenerowany ze strony ankieteo.pl</p>

      <div className="d-flex justify-content-center">
        <ButtonCreate analysisId={this.props.analysis} onAdded={added => {
          added.map(addedData => data.push(addedData));

          this.setState({data})
        }} />
      </div>
    </TabPane>;
  }
}

AdvancedList.defaultProps = {
  filters: {},
};

AdvancedList.propTypes = {
  analysis: PropTypes.number.isRequired,
  filters: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps)(AdvancedList);