import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {DropdownToggle, DropdownMenu, Dropdown, DropdownItem, Input} from "reactstrap";
import ButtonOpenAnswer from "./components/ButtonOpenAnswer";
import ButtonOpenIsNotRequired from "./components/ButtonOpenIsNotRequired";
import ButtonExcludedFromRand from "./components/ButtonExcludedFromRand";
import AnswerRandPriority from "./components/AnswerRandPriority";
import AnswerWeightValue from "./components/AnswerWeightValue";
import AnswerStartValue from "./components/AnswerStartValue";
import AnswerMinValue from "./components/AnswerMinValue";
import AnswerMaxValue from "./components/AnswerMaxValue";
import ModalDelete from "./components/ModalDelete";
import ButtonDrag from "./components/ButtonDrag";
import LoadingSpinner from "components/LoadingSpinner";
import QuestionAnswerNew from "../AnswerNew";
import ButtonBlocking from "./components/ButtonBlocking";
import {deleteAnswer, updateAnswer} from "scenes/Survey/Structure/data/answers/actions";
import reactStringReplace from "react-string-replace";
import ButtonExcludedFromRotate from "./components/ButtonExcludedFromRotate";
import AnswerDescription from "./components/AnswerDescription";
import {FormattedMessage} from "react-intl";
import Checkbox from "components/Checkbox";
import reactHtmlParser from "react-html-parser";
import {isMobile, isTablet} from "react-device-detect";
import HtmlEditor
  from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";
import getCKConfigByPlan from "utils/ckeditor/cafeteryConfig";
import InputNumber from "components/InputNumber";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "components/PlanRestrictionsModals/modals/MultimediaAvailable";
import classnames from "classnames";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";
import CafeteryEditModal from "../../../CafeteryEditModal";
import ButtonExcludeRequired from "./components/ButtonExcludeRequired";
import CafeterySelectedMaxButton from "./components/CafeterySelectedMaxButton";
import ButtonDisabled from "./components/ButtonDisabled";
import AnswerFilterScreenout from "./components/AnswerFilterScreenout";

class QuestionAnswer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownSettingsOpen: false,
      deleteButton: false,
      modalDelete: false,
      deleting: false,
      editing: false,
      saving: false,
      addNewAnswerBelow: false,
      hovered: false,
      answer: {
        content: props.answer.content,
        isOpen: props.answer.isOpen,
        openIsNotRequired: props.answer.openIsNotRequired,
        blocking: props.answer.blocking,
        excludedFromRand: props.answer.excludedFromRand,
        excludedFromRotate: props.answer.excludedFromRotate,
        randPriority: props.answer.randPriority,
        startValue: props.answer.startValue,
        minValue: props.answer.minValue,
        maxValue: props.answer.maxValue
      },
    };

    this.deleteAnswer = this.deleteAnswer.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setHovered = this.setHovered.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      ...this.state,
      answer: {
        ...this.state.answer,
        content: nextProps.answer.content
      }
    })
  }

  onClick(){
    this.setState({editing: true});
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.updateAnswer()
  }

  onChangeContent(content){
    const {saving} = this.state;

    if(!saving) {
      this.setState({
        answer: {
          ...this.state.answer,
          content
        }
      });
    }
  }

  updateAnswer(){
    const {answer} = this.state;

    if (answer.content.length === 0) {
      this.setState({answer: {...this.state.answer, content: this.props.answer.content}});
      return;
    }

    this.setState({
      saving: true,
      editing: false
    });

    if(answer.content.length === 0)
        return false;

    const data = {
        id: this.props.answer.id,
        answer: {
            content: answer.content
        }
    };

    this.props.updateAnswer(data).then(() => this.setState({saving: false}));
  }

  deleteAnswer(){
    this.setState({
      deleting: true
    });

    if(!this.state.deleting){
      this.props.deleteAnswer({
        id: this.props.answer.id
      })
        .then(() => {
          this.setState({
            modalDelete: false
          })
        })
    }
  }

  toggleOption(option) {
    this.setState({
      answer: {
        ...this.state.answer,
        [option]: !this.state.answer[option]
      }
    }, () => {
      const data = {
        id: this.props.answer.id,
        answer: {
          [option]: this.state.answer[option]
        }
      };

      this.props.updateAnswer(data);
    });
  }

  changeOption(option, value) {
    this.setState({
      answer: {
        ...this.state.answer,
        [option]: value
      }
    }, () => {
      const data = {
        id: this.props.answer.id,
        answer: {
          [option]: value
        }
      };

      this.props.updateAnswer(data);
    });
  }

  toggle(type, e = {}) {
    this.setState({
      [type]: !this.state[type]
    })
  }

  setHovered(hovered) {
    this.setState({hovered});
  }

  getAnswerContent(searchInStructure, content) {
    if (searchInStructure.length > 0) {
      return <span class={"ck-content"}>{content}</span>;
    }

    return <span class={"ck-content"}>{reactHtmlParser(content)}</span>;
  }

  render() {
    const {position, checked, question, searchInStructure, hideInStructure, plan} = this.props;
    const {answer, deleting, addNewAnswerBelow, editing, saving, hovered} = this.state;
    const ctrlEnterKeyCode = 1114125;
    const gripStyle = hovered ? {opacity: 1} : {opacity: 0};
    let editor;

    if (isMobile || isTablet) {
      editor = <Input value={answer.content} onChange={e => this.onChangeContent(e.target.value)} onBlur={this.updateAnswer} autoFocus={true} onKeyPress={this.handleKeyPress}/>
    } else {
      editor = <HtmlEditor
        data={answer.content}
        config={getCKConfigByPlan(plan)}
        type="inline"
        onChange={event => this.onChangeContent(event)}
        onBlur={event => {
          this.updateAnswer();
          this.setState({editing: false})
        }}
        onKey={event => {
          if (event.data.keyCode === ctrlEnterKeyCode) {
            event.cancel();
            this.updateAnswer();
            setTimeout(() => {
              event.editor.destroy();
              this.setState({editing: false})
            }, 1);
          }
        }}
      />
    }

    let style = {
      position: 'relative'
    };

    let contentStyle = hideInStructure === 'minified' ? {
      width: '75%',
      display: 'list-item',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    } : {wordBreak: 'break-all'};

    if(deleting) style.opacity = 0.35;

    let answerContent = answer.content;
    if (searchInStructure.length > 0) {
      answerContent = reactStringReplace(answerContent, searchInStructure, (match, i) => (
        <span className="bg-warning" key={i}>{match}</span>
      ));
    }

    return (
      <React.Fragment>
        <div className="sortable-draggable line-wrap" style={style} data-id={this.props.answer.id} onMouseEnter={() => this.setHovered(true)} onMouseLeave={() => this.setHovered(false)}>
          <ButtonDrag id={this.props.answer.id} gripStyle={gripStyle}/>

          <ul className="mb-0 d-flex pl-2">
            <li className="d-none d-lg-inline-block">
              <div className="form-group m-0 overflow-hidden">
                <Checkbox checked={checked} id={'answer-' + this.props.answer.id + '-check'} onClick={() => this.props.handlerChecked(!checked)} messageId={"checkbox.check.answer"}/>
              </div>
            </li>
            <li>{position + 1}.</li>

            <li className="flex-grow-1" style={contentStyle}>
              {question.answersImage ?
                (
                  <img
                    alt={answer.content}
                    style={{
                      maxHeight: '70px'
                    }}
                    src={answer.content || '/dashboard/img/no-image.png'}
                    onClick={() => {
                      if (!plan.multimedia.access) {
                        this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
                        return;
                      }
                      CKFinder.popup({
                        connectorInfo: 'auth=' + localStorage.jwt,
                        chooseFiles: true,
                        width: 1014,
                        height: 700,
                        onInit: finder => {
                          finder.on('files:choose', e => {
                            this.onChangeContent(e.data.files.first().getUrl());
                            this.updateAnswer();
                          });
                        }
                      })
                    }}
                  />
                )
                :
                <span onClick={this.onClick}>
                  {editing
                    ? editor
                    : saving
                      ? <LoadingSpinner width={21} classNames="my-0"/>
                      : this.getAnswerContent(searchInStructure, answerContent)
                  }
                </span>
              }
            </li>

            {this.props.surveyWeights && <React.Fragment>
              <li className="mr-2 d-none d-lg-inline"><AnswerWeightValue answer={this.props.answer} changeOption={this.changeOption}/></li>
            </React.Fragment>}

            {question.type === 'bpto' && <React.Fragment>
              <li className="mr-2 d-none d-lg-inline"><AnswerStartValue answer={this.props.answer} changeOption={this.changeOption}/></li>
              <li className="mr-2 d-none d-lg-inline"><AnswerMinValue answer={this.props.answer} changeOption={this.changeOption}/></li>
              <li className="mr-2 d-none d-lg-inline"><AnswerMaxValue answer={this.props.answer} changeOption={this.changeOption}/></li>
            </React.Fragment>}

            {question.type === 'numericMulti' && question.presentationType !== 'dragAndDrop' && <React.Fragment>
              <li className="mr-2 d-none d-lg-inline"><AnswerMinValue answer={this.props.answer} changeOption={this.changeOption}/></li>
              <li className="mr-2 d-none d-lg-inline"><AnswerMaxValue answer={this.props.answer} changeOption={this.changeOption}/></li>
            </React.Fragment>}

            {question.randAnswers > 0 && <li className={"mr-2 d-none d-lg-inline" + (plan.survey.questionSettings.randCafetery ? "" : " not-active")}><AnswerRandPriority disabled={!plan.survey.questionSettings.randCafetery} answer={this.props.answer} changeOption={this.changeOption}/></li>}
            {question.randAnswers > 0 && <li className="mr-2 d-none d-lg-inline"><ButtonExcludedFromRand answer={this.props.answer} disabled={!plan.survey.questionSettings.randCafetery} toggleOption={this.toggleOption} excludedFromRand={this.state.answer.excludedFromRand}/></li>}
            {question.rotateAnswers === true && <li className="mr-2 d-none d-lg-inline"><ButtonExcludedFromRotate answer={this.props.answer} toggleOption={this.toggleOption} excludedFromRotate={this.state.answer.excludedFromRotate}/></li>}
            {(question.required === true && ['numericMulti'].includes(question.type)) && <li className="mr-2 d-none d-lg-inline"><ButtonExcludeRequired answer={this.props.answer} toggleOption={this.toggleOption} excludeRequired={this.state.answer.excludeRequired}/></li>}
            {['multi', 'bpto'].includes(question.type) && <li className="mr-2 d-none d-lg-inline"><ButtonBlocking answer={this.props.answer} toggleOption={this.toggleOption} blocking={this.state.answer.blocking}/></li>}
            {this.state.answer.isOpen && <li className="mr-2 d-none d-lg-inline"><ButtonOpenIsNotRequired answer={this.props.answer} toggleOption={this.toggleOption} openIsNotRequired={this.state.answer.openIsNotRequired}/></li>}
            {!['matrixDropdown'].includes(question.type) && <li className="mr-2 d-none d-lg-inline"><ButtonOpenAnswer answer={this.props.answer} toggleOption={this.toggleOption} isOpen={this.state.answer.isOpen}/></li>}
            {!question.answersImage && <li className="ml-2 d-none d-lg-inline"><CafeteryEditModal type="answer" id={this.props.answer.id} content={this.props.answer.content} onSave={content => this.changeOption('content', content)} /></li>}

            {question.cafeterySelectedMax && <li className="mr-2 d-none d-lg-inline"><CafeterySelectedMaxButton answer={this.props.answer} /></li>}
            {plan.survey.cafeteryDisable && <li className="mr-2 d-none d-lg-inline"><ButtonDisabled answer={this.props.answer} toggleOption={this.toggleOption} disabled={this.props.answer.disabled}/></li>}

            {plan.survey.quickFilterScreenoutFromAnswer && <li className={"mx-2 d-none d-lg-inline"}><AnswerFilterScreenout answer={this.props.answer}/></li>}

            <li className="ml-2 d-print-none">
              <Dropdown isOpen={this.state.dropdownQuestionTypeOpen} toggle={e => this.toggle('dropdownQuestionTypeOpen', e)}>
                <DropdownToggle className="m-0 p-0" color="">
                  <span className="fas fa-ellipsis-v hoverable" />
                </DropdownToggle>
                {this.state.dropdownQuestionTypeOpen && <DropdownMenu>

                  {question.type === 'bpto' && <React.Fragment>
                    <DropdownItem tag="a" className="d-lg-none text-center">
                      <InputNumber float className="mb-0" value={this.state.answer.startValue} onChange={value => this.changeOption('startValue', value)} id={'answerStartValue-' + this.props.answer.id}/>
                      <FormattedMessage tagName="h5" id="answer.answerStartValue.button.tooltip" />
                    </DropdownItem>
                    <DropdownItem tag="a" className="d-lg-none text-center">
                      <InputNumber float className="mb-0" value={this.state.answer.minValue} onChange={value => this.changeOption('minValue', value)} id={'answerMinValue-' + this.props.answer.id}/>
                      <FormattedMessage tagName="h5" id="answer.answerMinValue.button.tooltip" />
                    </DropdownItem>
                    <DropdownItem tag="a" className="d-lg-none text-center">
                      <InputNumber float className="mb-0" value={this.state.answer.maxValue} onChange={value => this.changeOption('maxValue', value)} id={'answerMaxValue-' + this.props.answer.id}/>
                      <FormattedMessage tagName="h5" id="answer.answerMaxValue.button.tooltip" />
                    </DropdownItem>
                  </React.Fragment>}

                  {question.type === 'numericMulti' && question.presentationType !== 'dragAndDrop' && <React.Fragment>
                    <DropdownItem tag="a" className="d-lg-none text-center">
                      <InputNumber float className="mb-0" value={this.state.answer.minValue} onChange={value => this.changeOption('minValue', value)} id={'answerMinValue-' + this.props.answer.id}/>
                      <FormattedMessage tagName="h5" id="answer.answerMinValue.button.tooltip" />
                    </DropdownItem>
                    <DropdownItem tag="a" className="d-lg-none text-center">
                      <InputNumber float className="mb-0" value={this.state.answer.maxValue} onChange={value => this.changeOption('maxValue', value)} id={'answerMaxValue-' + this.props.answer.id}/>
                      <FormattedMessage tagName="h5" id="answer.answerMaxValue.button.tooltip" />
                    </DropdownItem>
                  </React.Fragment>}

                  {question.randAnswers > 0 && <DropdownItem tag="a" className="d-lg-none text-center">
                    <InputNumber className="mb-0" value={this.state.answer.randPriority} onChange={value => this.changeOption('randPriority', value)} id={'answerRandPriority-' + this.props.answer.id}/>
                    <FormattedMessage tagName="h5" id="answer.answerRandPriority.button.tooltip" />
                  </DropdownItem>}

                  {question.randAnswers > 0 && <DropdownItem tag="a" className={"d-lg-none" + (this.state.answer.excludedFromRand ? " text-primary" : "")} onClick={() => plan.survey.questionSettings.randCafetery ? this.toggleOption('excludedFromRand') : setPlanRestrictionsModals(QuestionSettingAvailable.getName())}>
                    <p><span className={classnames({
                      "fas fa-eye": true,
                      "text-primary": this.state.answer.excludedFromRand,
                      "not-active": !plan.survey.questionSettings.randCafetery
                    })}/></p> <FormattedMessage tagName="h5" id="answer.buttonExcludedFromRand.button.tooltip" />
                  </DropdownItem>}

                  {question.rotateAnswers === true && <DropdownItem tag="a" className={"d-lg-none" + (this.state.answer.excludedFromRotate ? " text-primary" : "")} onClick={() => this.toggleOption('excludedFromRotate')}>
                    <p><span className={"fas fa-random" + (this.state.answer.excludedFromRotate ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="answers.excludedFromRotate.button.tooltip" />
                  </DropdownItem>}


                  {['multi', 'bpto'].includes(question.type) && <DropdownItem tag="a" className={"d-lg-none" + (this.state.answer.blocking ? " text-primary" : "")} onClick={() => this.toggleOption('blocking')}>
                    <p><span className={"fa-solid fa-eraser" + (this.state.answer.blocking ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="answers.blocking.button.tooltip" />
                  </DropdownItem>}

                  {this.state.answer.isOpen && <DropdownItem tag="a" className={"d-lg-none" + (this.state.answer.openIsNotRequired ? " text-primary" : "")} onClick={() => this.toggleOption('openIsNotRequired')}>
                    <p><span className={"fas fa-exclamation-triangle" + (this.state.answer.openIsNotRequired ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="answer.buttonOpenIsNotRequired.button.tooltip" />
                  </DropdownItem>}

                  <DropdownItem tag="a" className={"d-lg-none" + (this.state.answer.isOpen ? " text-primary" : "")} onClick={() => this.toggleOption('isOpen')}>
                    <p><span className={"fa-solid fa-keyboard" + (this.state.answer.isOpen ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="answers.isOpen.button.tooltip" />
                  </DropdownItem>

                  <DropdownItem tag="a" onClick={() => this.setState({addNewAnswerBelow: !this.state.addNewAnswerBelow})}>
                    <p><span className="fas fa-plus"/></p> <FormattedMessage tagName="h5" id="answer.addNew.button" />
                  </DropdownItem>

                  <DropdownItem tag="a" onClick={e => this.toggle('modalDelete', e)}>
                    <p><span className="fas fa-trash"/></p> <FormattedMessage tagName="h5" id="answer.modalDelete.button" />
                  </DropdownItem>
                </DropdownMenu>}
              </Dropdown>
            </li>
          </ul>

          {question.answersImage && <AnswerDescription answerId={this.props.answer.id}/>}

          {/*{position !== 0 && <ButtonMovePosition answer={this.props.answer} question={question} position={position} move={1}/>}*/}
          {/*{position !== question.answers.length - 1 && <ButtonMovePosition answer={this.props.answer} question={question} position={position} move={-1}/>}*/}

        </div>
        {addNewAnswerBelow && <QuestionAnswerNew autoFocus={true} handlerAdded={() => this.setState({addNewAnswerBelow: false})} position={position+1} question={question} />}
        {this.state.modalDelete && <ModalDelete handleDelete={this.deleteAnswer} id={this.props.answer.id} isOpen={this.state.modalDelete} toggleModal={(e) => this.toggle('modalDelete', e)}/>}
      </React.Fragment>
    )
  }
}

QuestionAnswer.propTypes = {
  answer: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    openIsNotRequired: PropTypes.bool.isRequired,
    blocking: PropTypes.bool,
    excludedFromRand: PropTypes.bool.isRequired,
    excludedFromRotate: PropTypes.bool.isRequired,
  }).isRequired,
  position: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    answersImage: PropTypes.bool,
    rotateAnswers: PropTypes.bool,
  }).isRequired,
  handlerChecked: PropTypes.func,
  searchInStructure: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  return {
    surveyWeights: state.survey.structure.data.survey.weights,
    answer: state.survey.structure.data.answers[props.answer.id],
    hideInStructure: state.survey.structure.hideInStructure,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, { deleteAnswer, updateAnswer, setPlanRestrictionsModals })(QuestionAnswer);
