import * as React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../components/Button";
import PropTypes from "prop-types";

function ModalSimple(props) {

    const acceptModal = () => {
        props.onAccept();
    }

	const closeModal = () => {
		props.onCancel();
	}

	return (
		<Modal isOpen={props.modalOpen} toggle={closeModal} size="lg">
            {props.headerText && <ModalHeader toggle={closeModal}>{props.headerText}</ModalHeader>}
			<hr className="my-0"/>
            {props.bodyText && <ModalBody>{props.bodyText}</ModalBody>}
			<ModalFooter>
				<Button
					color="primary"
					onClick={() => acceptModal()}
				>{props.acceptText}</Button>
				<Button
					color="secondary"
					onClick={() => closeModal()}
				>{props.cancelText}</Button>
			</ModalFooter>
		</Modal>
	);
}

ModalSimple.defaultProps = {
    acceptText: "Tak",
    cancelText: "Nie",
    bodyText: "",
    headerText: ""
}

ModalSimple.propTypes = {
	modalOpen: PropTypes.bool.isRequired,
	onAccept: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	acceptText: PropTypes.string.isRequired,
	cancelText: PropTypes.string.isRequired,
	bodyText: PropTypes.string.isRequired,
	headerText: PropTypes.string.isRequired
};

export default ModalSimple;