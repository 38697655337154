import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import classnames from "classnames";
import Select from "react-select";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";

class ColumnThead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      filterExcluding: false,
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterExcludingChange = this.onFilterExcludingChange.bind(this);
  }

  componentWillUnmount() {
    const {question} = this.props;

    this.props.onFilterChange('q' + question.id, false);
    this.props.onFilterChange('q' + question.id + 'excl', false);
  }

  static getColSpan(question){
    return question.rows.length + question.excludings.length;
  }

  onFilterChange(rowId, value){
    let {filter} = this.state;
    const {question} = this.props;
    if(value === ''){
      delete filter[rowId];
    }else{
      filter[rowId] = value;
    }

    this.setState({
      filter
    }, () => {
      const key = 'q' + question.id;

      this.props.onFilterChange(key, (this.state.filter === false || _.isEmpty(this.state.filter)) ? false : {
          question: question.id,
          answers: this.state.filter,
        }
      );
    });
  }

  onFilterExcludingChange(value){
    const {question} = this.props;

    if(value.length === 0){
      value = false;
    }

    this.setState({
      filterExcluding: value
    }, () => {
      const key = 'q' + question.id + 'excl';

      this.props.onFilterChange(key, this.state.filterExcluding === false ? false : {
          question: question.id,
          excludings: this.state.filterExcluding,
        }
      );
    });
  }

  render() {
    const {filter, filterExcluding} = this.state;
    const {question} = this.props;

    let valueOptions = [];
    for (let i = 1; i <= question.scaleSize; i++) {
      valueOptions.push({
        label: i,
        value: i,
      });
    }

    return <React.Fragment>
      {question.rows.map((row, key) => {
        return <React.Fragment key={key.toString()}>
          <th
            className={classnames({
              'text-center pointer': true,
              'border-left': key === 0,
              'border-right': key === question.rows.length-1
            })}
            onClick={() => filter[row.id] === undefined && this.setState({filter: {...filter, [row.id]: {}}}) }
          >
            <div className={classnames({
              'd-flex h-100 text-center': true,
              'flex-column': filter[row.id] !== undefined,
              'align-items-center': filter[row.id] === undefined,
            })}>
              <div className="w-100">{striptags(row.leftContent)} - {striptags(row.rightContent)}</div>
              {(filter[row.id] !== undefined) && <div className="d-flex">
                <Select
                  isClearable
                  className={"w-100"}
                  options={valueOptions}
                  onChange={(option) => {
                    if(option){
                      this.onFilterChange(row.id, option.value)
                    }else{
                      this.onFilterChange(row.id, '')
                    }
                  }}
                />
              </div>}
            </div>
          </th>
        </React.Fragment>
      })}
      {question.excludings.length > 0 && <th
        className="text-center border-left border-right pointer"
        onClick={() => filterExcluding === false && this.setState({filterExcluding: null})}
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filter !== false,
          'align-items-center': filter === false,
        })}>
          <div className="w-100">Wykluczająca</div>
          {filterExcluding !== false && <SelectExcludings
            isClearable={true}
            isMulti={true}
            value={filterExcluding}
            questionId={question.id}
            onChange={this.onFilterExcludingChange}
          />}
        </div>
      </th>}
    </React.Fragment>;
  }
}

ColumnThead.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['differential']),
    rows: PropTypes.arrayOf(PropTypes.shape({
      leftContent: PropTypes.string.isRequired,
      rightContent: PropTypes.string.isRequired,
    }),),
    excludings: PropTypes.array,
  }).isRequired,
};

export default ColumnThead;