import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {FormGroup, Input, Label} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Switch from "components/Switch";
import InputGroup from "reactstrap/es/InputGroup";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import InputGroupText from "reactstrap/es/InputGroupText";
import Select from "react-select";

class TabGeneral extends PureComponent {
  render() {
    const {errors, config, data} = this.props;

    return (
      <React.Fragment>
        <FormGroup>
          <Label for="name"><FormattedMessage id="admin.planEdit.label.name" /></Label>
          <Input type="text" name="name" id="name" value={data.name} onChange={e => this.props.onChange(e.target.name, e.target.value)} invalid={!!errors.name}/>
          {errors.name && <p className="help-block text-danger">{errors.name}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="demo"><FormattedMessage id="admin.planEdit.label.demo" /></Label>
          <Switch checked={data.demo} onChange={value => this.props.onChange('demo', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="availableForRespondents"><FormattedMessage id="admin.planEdit.label.availableForRespondents" /></Label>
          <Switch checked={data.availableForRespondents} onChange={value => this.props.onChange('availableForRespondents', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="availableForCompanies"><FormattedMessage id="admin.planEdit.label.availableForCompanies" /></Label>
          <Switch checked={data.availableForCompanies} onChange={value => this.props.onChange('availableForCompanies', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="onSale"><FormattedMessage id="admin.planEdit.label.onSale" /></Label>
          <Switch checked={data.onSale} onChange={value => this.props.onChange('onSale', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="requiredPasswordChange"><FormattedMessage id="admin.planEdit.label.requiredPasswordChange" /></Label>
          <Switch checked={data.requiredPasswordChange} onChange={value => this.props.onChange('requiredPasswordChange', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="passwordStrong"><FormattedMessage id="admin.planEdit.label.passwordStrong" /></Label>
          <Switch checked={data.passwordStrong} onChange={value => this.props.onChange('passwordStrong', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="cint"><FormattedMessage id="admin.planEdit.label.cint" /></Label>
          <Switch checked={data.cint} onChange={value => this.props.onChange('cint', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="surveysAutoRefresh"><FormattedMessage id="admin.planEdit.label.surveysAutoRefresh" /></Label>
          <Switch checked={data.surveysAutoRefresh} onChange={value => this.props.onChange('surveysAutoRefresh', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="surveysBreakWarning"><FormattedMessage id="admin.planEdit.label.surveysBreakWarning" /></Label>
          <Switch checked={data.surveysBreakWarning} onChange={value => this.props.onChange('surveysBreakWarning', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="surveySwitcherCheck"><FormattedMessage id="admin.planEdit.label.surveySwitcherCheck" /></Label>
          <Switch checked={data.surveySwitcherCheck} onChange={value => this.props.onChange('surveySwitcherCheck', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="surveySendEmailStats"><FormattedMessage id="admin.planEdit.label.surveySendEmailStats" /></Label>
          <Switch checked={data.surveySendEmailStats} onChange={value => this.props.onChange('surveySendEmailStats', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="surveysCategories"><FormattedMessage id="admin.planEdit.label.surveysCategories" /></Label>
          <Switch checked={data.surveysCategories} onChange={value => this.props.onChange('surveysCategories', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="panelActiveUsers"><FormattedMessage id="admin.planEdit.label.panelActiveUsers" /></Label>
          <Switch checked={data.panelActiveUsers} onChange={value => this.props.onChange('panelActiveUsers', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="price"><FormattedMessage id="admin.planEdit.label.price" /></Label>
          <InputGroup className="p-0">
            <Input type="number" name="price" id="price" value={data.price} onChange={e => this.props.onChange(e.target.name, e.target.value)} invalid={!!errors.price}/>
            <InputGroupAddon addonType={"append"}><InputGroupText>{(data.price / 100).toFixed(2)} PLN netto</InputGroupText></InputGroupAddon>
          </InputGroup>
          {errors.price && <p className="help-block text-danger">{errors.price}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="quarterlyPrice"><FormattedMessage id="admin.planEdit.label.quarterlyPrice" /></Label>
          <InputGroup className="p-0">
            <Input type="number" name="quarterlyPrice" id="quarterlyPrice" value={data.quarterlyPrice} onChange={e => this.props.onChange(e.target.name, e.target.value)} invalid={!!errors.quarterlyPrice}/>
            <InputGroupAddon addonType={"append"}><InputGroupText>{(data.quarterlyPrice / 100).toFixed(2)} PLN netto</InputGroupText></InputGroupAddon>
          </InputGroup>
          {errors.quarterlyPrice && <p className="help-block text-danger">{errors.quarterlyPrice}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="annualPrice"><FormattedMessage id="admin.planEdit.label.annualPrice" /></Label>
          <InputGroup className="p-0">
            <Input type="number" name="annualPrice" id="annualPrice" value={data.annualPrice} onChange={e => this.props.onChange(e.target.name, e.target.value)} invalid={!!errors.annualPrice}/>
            <InputGroupAddon addonType={"append"}><InputGroupText>{(data.annualPrice / 100).toFixed(2)} PLN netto</InputGroupText></InputGroupAddon>
          </InputGroup>
          {errors.annualPrice && <p className="help-block text-danger">{errors.annualPrice}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="surveysMax"><FormattedMessage id="admin.planEdit.label.surveysMax" /></Label>
          <Input type="number" name="surveysMax" id="surveysMax" value={data.surveysMax} onChange={e => this.props.onChange(e.target.name, e.target.value)} invalid={errors && !!errors.surveysMax}/>
          {errors && errors.surveysMax && <p className="help-block text-danger">{errors.surveysMax}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="surveysActiveMax"><FormattedMessage id="admin.planEdit.label.surveysActiveMax" /></Label>
          <Input type="number" name="surveysActiveMax" id="surveysActiveMax" value={data.surveysActiveMax} onChange={e => this.props.onChange(e.target.name, e.target.value)} invalid={errors && !!errors.surveysActiveMax}/>
          {errors && errors.surveysActiveMax && <p className="help-block text-danger">{errors.surveysActiveMax}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="questionsAvailable"><FormattedMessage id="admin.planEdit.label.survey.questionsAvailable" /></Label>
          <Select
            isMulti
            value={_.map(data.survey.questionsAvailable, type => {
              return {
                label: <FormattedMessage id={'_.question.' + type + '.typeName'} />,
                value: type,
              }
            })}
            onChange={e => {
              this.props.onChangeNested('survey', 'questionsAvailable', e.map(val => val.value))
            }}
            options={_.map(config.data.questions, (data, type) => {
              return {
                label: <FormattedMessage id={'_.question.' + type + '.typeName'} />,
                value: type,
              }
            })}
          />
          {errors.survey && errors.survey.questionsAvailable && <p className="help-block text-danger">{errors.questionsAvailable}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="piping"><FormattedMessage id="admin.planEdit.label.survey.piping" /></Label>
          <Switch checked={data.survey.piping} onChange={value => this.props.onChangeNested('survey', 'piping', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="filters"><FormattedMessage id="admin.planEdit.label.survey.filters" /></Label>
          <Switch checked={data.survey.filters} onChange={value => this.props.onChangeNested('survey', 'filters', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="filtersFromTextarea"><FormattedMessage id="admin.planEdit.label.survey.filtersFromTextarea" /></Label>
          <Switch checked={data.survey.filtersFromTextarea} onChange={value => this.props.onChangeNested('survey', 'filtersFromTextarea', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="quickFilterScreenoutFromAnswer"><FormattedMessage id="admin.planEdit.label.survey.quickFilterScreenoutFromAnswer" /></Label>
          <Switch checked={data.survey.quickFilterScreenoutFromAnswer} onChange={value => this.props.onChangeNested('survey', 'quickFilterScreenoutFromAnswer', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="quotas"><FormattedMessage id="admin.planEdit.label.survey.quotas" /></Label>
          <Switch checked={data.survey.quotas} onChange={value => this.props.onChangeNested('survey', 'quotas', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="globalQuestionTemplates"><FormattedMessage id="admin.planEdit.label.survey.globalQuestionTemplates" /></Label>
          <Switch checked={data.survey.globalQuestionTemplates} onChange={value => this.props.onChangeNested('survey', 'globalQuestionTemplates', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="ownQuestionsBase"><FormattedMessage id="admin.planEdit.label.survey.ownQuestionsBase" /></Label>
          <Switch checked={data.survey.ownQuestionsBase} onChange={value => this.props.onChangeNested('survey', 'ownQuestionsBase', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="textsOverride"><FormattedMessage id="admin.planEdit.label.survey.textsOverride" /></Label>
          <Switch checked={data.survey.textsOverride} onChange={value => this.props.onChangeNested('survey', 'textsOverride', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="allowRespondentsToHideSurveyOwner"><FormattedMessage id="admin.planEdit.label.survey.allowRespondentsToHideSurveyOwner" /></Label>
          <Switch checked={data.survey.allowRespondentsToHideSurveyOwner} onChange={value => this.props.onChangeNested('survey', 'allowRespondentsToHideSurveyOwner', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="collectorEmailMailFooterEdit"><FormattedMessage id="admin.planEdit.label.survey.collectorEmailMailFooterEdit" /></Label>
          <Switch checked={data.survey.collectorEmailMailFooterEdit} onChange={value => this.props.onChangeNested('survey', 'collectorEmailMailFooterEdit', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="nameWork"><FormattedMessage id="admin.planEdit.label.survey.nameWork" /></Label>
          <Switch checked={data.survey.nameWork} onChange={value => this.props.onChangeNested('survey', 'nameWork', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="historyChange"><FormattedMessage id="admin.planEdit.label.survey.historyChange" /></Label>
          <Switch checked={data.survey.historyChange} onChange={value => this.props.onChangeNested('survey', 'historyChange', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="cafeteryDisable"><FormattedMessage id="admin.planEdit.label.survey.cafeteryDisable" /></Label>
          <Switch checked={data.survey.cafeteryDisable} onChange={value => this.props.onChangeNested('survey', 'cafeteryDisable', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="surveyTemplates"><FormattedMessage id="admin.planEdit.label.survey.surveyTemplates" /></Label>
          <Switch checked={data.survey.surveyTemplates} onChange={value => this.props.onChangeNested('survey', 'surveyTemplates', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="panelPromoteAvailable"><FormattedMessage id="admin.planEdit.label.survey.panelPromoteAvailable" /></Label>
          <Switch checked={data.survey.panelPromoteAvailable} onChange={value => this.props.onChangeNested('survey', 'panelPromoteAvailable', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="insidePanel"><FormattedMessage id="admin.planEdit.label.survey.insidePanel" /></Label>
          <Switch checked={data.survey.insidePanel} onChange={value => this.props.onChangeNested('survey', 'insidePanel', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="weights"><FormattedMessage id="admin.planEdit.label.survey.weights" /></Label>
          <Switch checked={data.survey.weights} onChange={value => this.props.onChangeNested('survey', 'weights', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="userQuestionTemplates"><FormattedMessage id="admin.planEdit.label.survey.userQuestionTemplates" /></Label>
          <Switch checked={data.survey.userQuestionTemplates} onChange={value => this.props.onChangeNested('survey', 'userQuestionTemplates', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="userBlockTemplates"><FormattedMessage id="admin.planEdit.label.survey.userBlockTemplates" /></Label>
          <Switch checked={data.survey.userBlockTemplates} onChange={value => this.props.onChangeNested('survey', 'userBlockTemplates', value)} />
        </FormGroup>

        <FormGroup>
          <Label for="collectorsMax"><FormattedMessage id="admin.planEdit.label.survey.collectorsMax" /></Label>
          <Input type="number" name="collectorsMax" id="collectorsMax" value={data.survey.collectorsMax} onChange={e => this.props.onChangeNested('survey', e.target.name, e.target.value)} invalid={errors.survey && !!errors.survey.collectorsMax}/>
          {errors.survey && errors.survey.collectorsMax && <p className="help-block text-danger">{errors.survey.collectorsMax}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="collectorsTokensMax"><FormattedMessage id="admin.planEdit.label.survey.collectorsTokensMax" /></Label>
          <Input type="number" name="collectorsTokensMax" id="collectorsTokensMax" value={data.survey.collectorsTokensMax} onChange={e => this.props.onChangeNested('survey', e.target.name, e.target.value)} invalid={errors.survey && !!errors.survey.collectorsTokensMax}/>
          {errors.survey && errors.survey.collectorsTokensMax && <p className="help-block text-danger">{errors.survey.collectorsTokensMax}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="analysesMax"><FormattedMessage id="admin.planEdit.label.survey.analysesMax" /></Label>
          <Input type="number" name="analysesMax" id="analysesMax" value={data.survey.analysesMax} onChange={e => this.props.onChangeNested('survey', e.target.name, e.target.value)} invalid={errors.survey && !!errors.survey.analysesMax}/>
          {errors.survey && errors.survey.analysesMax && <p className="help-block text-danger">{errors.survey.analysesMax}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="responsesMax"><FormattedMessage id="admin.planEdit.label.survey.responsesMax" /></Label>
          <Input type="number" name="responsesMax" id="responsesMax" value={data.survey.responsesMax} onChange={e => this.props.onChangeNested('survey', e.target.name, e.target.value)} invalid={errors.survey && !!errors.survey.responsesMax}/>
          {errors.survey && errors.survey.responsesMax && <p className="help-block text-danger">{errors.survey.responsesMax}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="dateEndMax"><FormattedMessage id="admin.planEdit.label.survey.dateEndMax" /></Label>
          <Input type="number" name="dateEndMax" id="dateEndMax" value={data.survey.dateEndMax} onChange={e => this.props.onChangeNested('survey', e.target.name, e.target.value)} invalid={errors.survey && !!errors.survey.dateEndMax}/>
          {errors.survey && errors.survey.dateEndMax && <p className="help-block text-danger">{errors.survey.dateEndMax}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="questionsMax"><FormattedMessage id="admin.planEdit.label.survey.questionsMax" /></Label>
          <Input type="number" name="questionsMax" id="questionsMax" value={data.survey.questionsMax} onChange={e => this.props.onChangeNested('survey', e.target.name, e.target.value)} invalid={errors.survey && !!errors.survey.questionsMax}/>
          {errors.survey && errors.survey.questionsMax && <p className="help-block text-danger">{errors.survey.questionsMax}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="cookieBlockade"><FormattedMessage id="admin.planEdit.label.survey.cookieBlockade" /></Label>
          <Switch checked={data.survey.cookieBlockade} onChange={value => this.props.onChangeNested('survey', 'cookieBlockade', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="ipBlockade"><FormattedMessage id="admin.planEdit.label.survey.ipBlockade" /></Label>
          <Switch checked={data.survey.ipBlockade} onChange={value => this.props.onChangeNested('survey', 'ipBlockade', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="languages"><FormattedMessage id="admin.planEdit.label.survey.languages" /></Label>
          <Switch checked={data.survey.languages} onChange={value => this.props.onChangeNested('survey', 'languages', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="baseSurveys"><FormattedMessage id="admin.planEdit.label.survey.baseSurveys" /></Label>
          <Switch checked={data.survey.baseSurveys} onChange={value => this.props.onChangeNested('survey', 'baseSurveys', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="topSurveys"><FormattedMessage id="admin.planEdit.label.survey.topSurveys" /></Label>
          <Switch checked={data.survey.topSurveys} onChange={value => this.props.onChangeNested('survey', 'topSurveys', value)} />
        </FormGroup>


        <FormGroup>
          <Label><FormattedMessage id="admin.planEdit.label.analysis.exportTypes" /></Label>
          <Select
            isMulti
            value={_.map(data.analysis.exportTypes, type => {
              return {
                label: <FormattedMessage id={'_.analysis.exportType.' + type} />,
                value: type,
              }
            })}
            onChange={e => {
              this.props.onChangeNested('analysis', 'exportTypes', e.map(val => val.value))
            }}
            options={_.map(config.data.analysis.exportTypes, (type) => {
              return {
                label: <FormattedMessage id={'_.analysis.exportType.' + type} />,
                value: type,
              }
            })}
          />
        </FormGroup>


        <FormGroup>
          <Label for="basesMax"><FormattedMessage id="admin.planEdit.label.respondentBase.basesMax" /></Label>
          <Input type="number" name="basesMax" id="basesMax" value={data.respondentBase.basesMax} onChange={e => this.props.onChangeNested('respondentBase', e.target.name, e.target.value)} invalid={errors.respondentBase && !!errors.respondentBase.basesMax}/>
          {errors.respondentBase && errors.respondentBase.basesMax && <p className="help-block text-danger">{errors.respondentBase.basesMax}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="baseAddressesMax"><FormattedMessage id="admin.planEdit.label.respondentBase.baseAddressesMax" /></Label>
          <Input type="number" name="baseAddressesMax" id="baseAddressesMax" value={data.respondentBase.baseAddressesMax} onChange={e => this.props.onChangeNested('respondentBase', e.target.name, e.target.value)} invalid={errors.respondentBase && !!errors.respondentBase.baseAddressesMax}/>
          {errors.respondentBase && errors.respondentBase.baseAddressesMax && <p className="help-block text-danger">{errors.respondentBase.baseAddressesMax}</p>}
        </FormGroup>


        <FormGroup>
          <Label for="socialMinPoints"><FormattedMessage id="admin.planEdit.label.points.socialMinPoints" /></Label>
          <Input type="number" name="socialMinPoints" id="socialMinPoints" value={data.survey.points.socialMinPoints} onChange={e => this.props.onChangePoints(e.target.name, e.target.value)} invalid={errors.survey && errors.survey.points && !!errors.survey.socialMinPoints}/>
          {errors.survey && errors.survey.points && errors.survey.socialMinPoints && <p className="help-block text-danger">{errors.survey.socialMinPoints}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="socialMaxPoints"><FormattedMessage id="admin.planEdit.label.points.socialMaxPoints" /></Label>
          <Input type="number" name="socialMaxPoints" id="socialMaxPoints" value={data.survey.points.socialMaxPoints} onChange={e => this.props.onChangePoints(e.target.name, e.target.value)} invalid={errors.survey && errors.survey.points && !!errors.survey.socialMaxPoints}/>
          {errors.survey && errors.survey.points && errors.survey.socialMaxPoints && <p className="help-block text-danger">{errors.survey.socialMaxPoints}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="cashMinPoints"><FormattedMessage id="admin.planEdit.label.points.cashMinPoints" /></Label>
          <Input type="number" name="cashMinPoints" id="cashMinPoints" value={data.survey.points.cashMinPoints} onChange={e => this.props.onChangePoints(e.target.name, e.target.value)} invalid={errors.survey && errors.survey.points && !!errors.survey.cashMinPoints}/>
          {errors.survey && errors.survey.points && errors.survey.cashMinPoints && <p className="help-block text-danger">{errors.survey.cashMinPoints}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="cashMaxPoints"><FormattedMessage id="admin.planEdit.label.points.cashMaxPoints" /></Label>
          <Input type="number" name="cashMaxPoints" id="cashMaxPoints" value={data.survey.points.cashMaxPoints} onChange={e => this.props.onChangePoints(e.target.name, e.target.value)} invalid={errors.survey && errors.survey.points && !!errors.survey.cashMaxPoints}/>
          {errors.survey && errors.survey.points && errors.survey.cashMaxPoints && <p className="help-block text-danger">{errors.survey.cashMaxPoints}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="screenOutSocialMinPoints"><FormattedMessage id="admin.planEdit.label.points.screenOutSocialMinPoints" /></Label>
          <Input type="number" name="screenOutSocialMinPoints" id="screenOutSocialMinPoints" value={data.survey.points.screenOutSocialMinPoints} onChange={e => this.props.onChangePoints(e.target.name, e.target.value)} invalid={errors.survey && errors.survey.points && !!errors.survey.screenOutSocialMinPoints}/>
          {errors.survey && errors.survey.points && errors.survey.screenOutSocialMinPoints && <p className="help-block text-danger">{errors.survey.screenOutSocialMinPoints}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="screenOutSocialMaxPoints"><FormattedMessage id="admin.planEdit.label.points.screenOutSocialMaxPoints" /></Label>
          <Input type="number" name="screenOutSocialMaxPoints" id="screenOutSocialMaxPoints" value={data.survey.points.screenOutSocialMaxPoints} onChange={e => this.props.onChangePoints(e.target.name, e.target.value)} invalid={errors.survey && errors.survey.points && !!errors.survey.screenOutSocialMaxPoints}/>
          {errors.survey && errors.survey.points && errors.survey.screenOutSocialMaxPoints && <p className="help-block text-danger">{errors.survey.screenOutSocialMaxPoints}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="screenOutCashMinPoints"><FormattedMessage id="admin.planEdit.label.points.screenOutCashMinPoints" /></Label>
          <Input type="number" name="screenOutCashMinPoints" id="screenOutCashMinPoints" value={data.survey.points.screenOutCashMinPoints} onChange={e => this.props.onChangePoints(e.target.name, e.target.value)} invalid={errors.survey && errors.survey.points && !!errors.survey.screenOutCashMinPoints}/>
          {errors.survey && errors.survey.points && errors.survey.screenOutCashMinPoints && <p className="help-block text-danger">{errors.survey.screenOutCashMinPoints}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="screenOutCashMaxPoints"><FormattedMessage id="admin.planEdit.label.points.screenOutCashMaxPoints" /></Label>
          <Input type="number" name="screenOutCashMaxPoints" id="screenOutCashMaxPoints" value={data.survey.points.screenOutCashMaxPoints} onChange={e => this.props.onChangePoints(e.target.name, e.target.value)} invalid={errors.survey && errors.survey.points && !!errors.survey.screenOutCashMaxPoints}/>
          {errors.survey && errors.survey.points && errors.survey.screenOutCashMaxPoints && <p className="help-block text-danger">{errors.survey.screenOutCashMaxPoints}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="allowedToChangeSocialPoints"><FormattedMessage id="admin.planEdit.label.points.allowedToChangeSocialPoints" /></Label>
          <Switch checked={data.survey.points.allowedToChangeSocialPoints} onChange={value => this.props.onChangePoints('allowedToChangeSocialPoints', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="allowedToChangeCashPoints"><FormattedMessage id="admin.planEdit.label.points.allowedToChangeCashPoints" /></Label>
          <Switch checked={data.survey.points.allowedToChangeCashPoints} onChange={value => this.props.onChangePoints('allowedToChangeCashPoints', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="allowedToChangeSocialScreenOutPoints"><FormattedMessage id="admin.planEdit.label.points.allowedToChangeSocialScreenOutPoints" /></Label>
          <Switch checked={data.survey.points.allowedToChangeSocialScreenOutPoints} onChange={value => this.props.onChangePoints('allowedToChangeSocialScreenOutPoints', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="allowedToChangeCashScreenOutPoints"><FormattedMessage id="admin.planEdit.label.points.allowedToChangeCashScreenOutPoints" /></Label>
          <Switch checked={data.survey.points.allowedToChangeCashScreenOutPoints} onChange={value => this.props.onChangePoints('allowedToChangeCashScreenOutPoints', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="inaccessibilityText"><FormattedMessage id="admin.planEdit.label.survey.inaccessibilityText" /></Label>
          <Switch checked={data.survey.inaccessibilityText} onChange={value => this.props.onChangeNested('survey', 'inaccessibilityText', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="groups"><FormattedMessage id="admin.planEdit.label.groups" /></Label>
          <Switch checked={data.groups} onChange={value => this.props.onChange('groups', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="groupsUsersMax"><FormattedMessage id="admin.planEdit.label.groupsUsersMax" /></Label>
          <Input type="number" name="groupsUsersMax" id="groupsUsersMax" value={data.groupsUsersMax} onChange={e => this.props.onChange(e.target.name, e.target.value)} invalid={errors && !!errors.groupsUsersMax}/>
          {errors && errors.groupsUsersMax && <p className="help-block text-danger">{errors.groupsUsersMax}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="access"><FormattedMessage id="admin.planEdit.label.multimedia.access" /></Label>
          <Switch checked={data.multimedia.access} onChange={value => this.props.onChangeNested('multimedia', 'access', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="maxTotalSizeKb"><FormattedMessage id="admin.planEdit.label.multimedia.maxTotalSizeKb" /></Label>
          <Input type="number" name="maxTotalSizeKb" id="maxTotalSizeKb" value={data.multimedia.maxTotalSizeKb} onChange={e => this.props.onChangeNested('multimedia', e.target.name, e.target.value)} invalid={errors.multimedia && !!errors.multimedia.maxTotalSizeKb}/>
          {errors.multimedia && errors.multimedia.maxTotalSizeKb && <p className="help-block text-danger">{errors.multimedia.maxTotalSizeKb}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="maxImageFileSizeKb"><FormattedMessage id="admin.planEdit.label.multimedia.maxImageFileSizeKb" /></Label>
          <Input type="number" name="maxImageFileSizeKb" id="maxImageFileSizeKb" value={data.multimedia.maxImageFileSizeKb} onChange={e => this.props.onChangeNested('multimedia', e.target.name, e.target.value)} invalid={errors.multimedia && !!errors.multimedia.maxImageFileSizeKb}/>
          {errors.multimedia && errors.multimedia.maxImageFileSizeKb && <p className="help-block text-danger">{errors.multimedia.maxImageFileSizeKb}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="maxAudioFileSizeKb"><FormattedMessage id="admin.planEdit.label.multimedia.maxAudioFileSizeKb" /></Label>
          <Input type="number" name="maxAudioFileSizeKb" id="maxAudioFileSizeKb" value={data.multimedia.maxAudioFileSizeKb} onChange={e => this.props.onChangeNested('multimedia', e.target.name, e.target.value)} invalid={errors.multimedia && !!errors.multimedia.maxAudioFileSizeKb}/>
          {errors.multimedia && errors.multimedia.maxAudioFileSizeKb && <p className="help-block text-danger">{errors.multimedia.maxAudioFileSizeKb}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="maxVideoFileSizeKb"><FormattedMessage id="admin.planEdit.label.multimedia.maxVideoFileSizeKb" /></Label>
          <Input type="number" name="maxVideoFileSizeKb" id="maxVideoFileSizeKb" value={data.multimedia.maxVideoFileSizeKb} onChange={e => this.props.onChangeNested('multimedia', e.target.name, e.target.value)} invalid={errors.multimedia && !!errors.multimedia.maxVideoFileSizeKb}/>
          {errors.multimedia && errors.multimedia.maxVideoFileSizeKb && <p className="help-block text-danger">{errors.multimedia.maxVideoFileSizeKb}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="exportResponseTime"><FormattedMessage id="admin.planEdit.label.analysis.exportResponseTime" /></Label>
          <Switch checked={data.analysis.exportResponseTime} onChange={value => this.props.onChangeNested('analysis', 'exportResponseTime', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="sets"><FormattedMessage id="admin.planEdit.label.survey.sets" /></Label>
          <Switch checked={data.survey.sets} onChange={value => this.props.onChangeNested('survey', 'sets', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="blocks"><FormattedMessage id="admin.planEdit.label.survey.blocks" /></Label>
          <Switch checked={data.survey.blocks} onChange={value => this.props.onChangeNested('survey', 'blocks', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="pages"><FormattedMessage id="admin.planEdit.label.survey.pages" /></Label>
          <Switch checked={data.survey.pages} onChange={value => this.props.onChangeNested('survey', 'pages', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="filtering"><FormattedMessage id="admin.planEdit.label.analysis.filtering" /></Label>
          <Switch checked={data.analysis.filtering} onChange={value => this.props.onChangeNested('analysis', 'filtering', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="demographics"><FormattedMessage id="admin.planEdit.label.analysis.demographics" /></Label>
          <Switch checked={data.analysis.demographics} onChange={value => this.props.onChangeNested('analysis', 'demographics', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="editableSurveyResponses"><FormattedMessage id="admin.planEdit.label.analysis.editableSurveyResponses" /></Label>
          <Switch checked={data.analysis.editableSurveyResponses} onChange={value => this.props.onChangeNested('analysis', 'editableSurveyResponses', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="deletableSurveyResponses"><FormattedMessage id="admin.planEdit.label.analysis.deletableSurveyResponses" /></Label>
          <Switch checked={data.analysis.deletableSurveyResponses} onChange={value => this.props.onChangeNested('analysis', 'deletableSurveyResponses', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="dataProcessing"><FormattedMessage id="admin.planEdit.label.analysis.dataProcessing" /></Label>
          <Switch checked={data.analysis.dataProcessing} onChange={value => this.props.onChangeNested('analysis', 'dataProcessing', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="responsesOperations"><FormattedMessage id="admin.planEdit.label.analysis.responsesOperations" /></Label>
          <Switch checked={data.analysis.responsesOperations} onChange={value => this.props.onChangeNested('analysis', 'responsesOperations', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="filterUserIds"><FormattedMessage id="admin.planEdit.label.analysis.filterUserIds" /></Label>
          <Switch checked={data.analysis.filterUserIds} onChange={value => this.props.onChangeNested('analysis', 'filterUserIds', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="summary"><FormattedMessage id="admin.planEdit.label.analysis.summary" /></Label>
          <Switch checked={data.analysis.summary} onChange={value => this.props.onChangeNested('analysis', 'summary', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="responsePreview"><FormattedMessage id="admin.planEdit.label.analysis.responsePreview" /></Label>
          <Switch checked={data.analysis.responsePreview} onChange={value => this.props.onChangeNested('analysis', 'responsePreview', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="responseModify"><FormattedMessage id="admin.planEdit.label.analysis.responseModify" /></Label>
          <Switch checked={data.analysis.responseModify} onChange={value => this.props.onChangeNested('analysis', 'responseModify', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="advanced"><FormattedMessage id="admin.planEdit.label.analysis.advanced" /></Label>
          <Switch checked={data.analysis.advanced} onChange={value => this.props.onChangeNested('analysis', 'advanced', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="dictionaries"><FormattedMessage id="admin.planEdit.label.analysis.dictionaries" /></Label>
          <Switch checked={data.analysis.dictionaries} onChange={value => this.props.onChangeNested('analysis', 'dictionaries', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="transposition"><FormattedMessage id="admin.planEdit.label.analysis.transposition" /></Label>
          <Switch checked={data.analysis.transposition} onChange={value => this.props.onChangeNested('analysis', 'transposition', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="accessDaysAfterCreation"><FormattedMessage id="admin.planEdit.label.survey.accessDaysAfterCreation" /></Label>
          <Input type="number" name="accessDaysAfterCreation" id="accessDaysAfterCreation" value={data.survey.accessDaysAfterCreation} onChange={e => this.props.onChangeNested('survey', e.target.name, e.target.value)} invalid={errors.survey && !!errors.survey.accessDaysAfterCreation}/>
          {errors.survey && errors.survey.accessDaysAfterCreation && <p className="help-block text-danger">{errors.survey.accessDaysAfterCreation}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="redirects"><FormattedMessage id="admin.planEdit.label.survey.redirects" /></Label>
          <Switch checked={data.survey.redirects} onChange={value => this.props.onChangeNested('survey', 'redirects', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="pause"><FormattedMessage id="admin.planEdit.label.survey.pause" /></Label>
          <Switch checked={data.survey.pause} onChange={value => this.props.onChangeNested('survey', 'pause', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="limit"><FormattedMessage id="admin.planEdit.label.survey.limit" /></Label>
          <Switch checked={data.survey.limit} onChange={value => this.props.onChangeNested('survey', 'limit', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="clients"><FormattedMessage id="admin.planEdit.label.clients" /></Label>
          <Switch checked={data.clients} onChange={value => this.props.onChange('clients', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="invitationRedirect"><FormattedMessage id="admin.planEdit.label.survey.invitationRedirect" /></Label>
          <Switch checked={data.survey.invitationRedirect} onChange={value => this.props.onChangeNested('survey', 'invitationRedirect', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="switcherGuid"><FormattedMessage id="admin.planEdit.label.survey.switcherGuid" /></Label>
          <Switch checked={data.survey.switcherGuid} onChange={value => this.props.onChangeNested('survey', 'switcherGuid', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="integrations"><FormattedMessage id="admin.planEdit.label.survey.integrations" /></Label>
          <Switch checked={data.survey.integrations} onChange={value => this.props.onChangeNested('survey', 'integrations', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="googleAdsense"><FormattedMessage id="admin.planEdit.label.survey.googleAdsense" /></Label>
          <Switch checked={data.survey.googleAdsense} onChange={value => this.props.onChangeNested('survey', 'googleAdsense', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="clientRequired"><FormattedMessage id="admin.planEdit.label.survey.clientRequired" /></Label>
          <Switch checked={data.survey.clientRequired} onChange={value => this.props.onChangeNested('survey', 'clientRequired', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="excludings"><FormattedMessage id="admin.planEdit.label.survey.excludings" /></Label>
          <Switch checked={data.survey.excludings} onChange={value => this.props.onChangeNested('survey', 'excludings', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="reportEnable"><FormattedMessage id="admin.planEdit.label.survey.reportEnable" /></Label>
          <Switch checked={data.survey.reportEnable} onChange={value => this.props.onChangeNested('survey', 'reportEnable', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="surveyResponseFakerEnable"><FormattedMessage id="admin.planEdit.label.survey.surveyResponseFakerEnable" /></Label>
          <Switch checked={data.survey.surveyResponseFakerEnable} onChange={value => this.props.onChangeNested('survey', 'surveyResponseFakerEnable', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="reportFromSystem"><FormattedMessage id="admin.planEdit.label.survey.reportFromSystem" /></Label>
          <Switch checked={data.survey.reportFromSystem} onChange={value => this.props.onChangeNested('survey', 'reportFromSystem', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="widgets"><FormattedMessage id="admin.planEdit.label.widgets" /></Label>
          <Switch checked={data.widgets} onChange={value => this.props.onChange('widgets', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="smtpManage"><FormattedMessage id="admin.planEdit.label.smtpManage" /></Label>
          <Switch checked={data.smtpManage} onChange={value => this.props.onChange('smtpManage', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="slackIntegration"><FormattedMessage id="admin.planEdit.label.slackIntegration" /></Label>
          <Switch checked={data.slackIntegration} onChange={value => this.props.onChange('slackIntegration', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="allQuestionsResult"><FormattedMessage id="admin.planEdit.label.allQuestionsResult" /></Label>
          <Switch checked={data.allQuestionsResult} onChange={value => this.props.onChange('allQuestionsResult', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="clearResponsesBlockadeDefault"><FormattedMessage id="admin.planEdit.label.clearResponsesBlockadeDefault" /></Label>
          <Switch checked={data.survey.clearResponsesBlockadeDefault} onChange={value => this.props.onChangeNested('survey', 'clearResponsesBlockadeDefault', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="questionJsCssAccess"><FormattedMessage id="admin.planEdit.label.questionJsCssAccess" /></Label>
          <Switch checked={data.survey.questionJsCssAccess} onChange={value => this.props.onChangeNested('survey', 'questionJsCssAccess', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="collectorPanelAccess"><FormattedMessage id="admin.planEdit.label.collectorPanelAccess" /></Label>
          <Switch checked={data.survey.collectorPanelAccess} onChange={value => this.props.onChangeNested('survey', 'collectorPanelAccess', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="collectorPanelTemplateCreate"><FormattedMessage id="admin.planEdit.label.collectorPanelTemplateCreate" /></Label>
          <Switch checked={data.survey.collectorPanelTemplateCreate} onChange={value => this.props.onChangeNested('survey', 'collectorPanelTemplateCreate', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="collectorPanelVerifyRequired"><FormattedMessage id="admin.planEdit.label.collectorPanelVerifyRequired" /></Label>
          <Switch checked={data.survey.collectorPanelVerifyRequired} onChange={value => this.props.onChangeNested('survey', 'collectorPanelVerifyRequired', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="collectorSmsAccess"><FormattedMessage id="admin.planEdit.label.collectorSmsAccess" /></Label>
          <Switch checked={data.survey.collectorSmsAccess} onChange={value => this.props.onChangeNested('survey', 'collectorSmsAccess', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="collectorWidgetAccess"><FormattedMessage id="admin.planEdit.label.collectorWidgetAccess" /></Label>
          <Switch checked={data.survey.collectorWidgetAccess} onChange={value => this.props.onChangeNested('survey', 'collectorWidgetAccess', value)} />
        </FormGroup>
      </React.Fragment>
    );
  }
}

TabGeneral.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    demo: PropTypes.bool.isRequired,
    availableForRespondents: PropTypes.bool.isRequired,
    availableForCompanies: PropTypes.bool.isRequired,
    onSale: PropTypes.bool.isRequired,
    discountable: PropTypes.bool.isRequired,
    requiredPasswordChange: PropTypes.bool.isRequired,
    passwordStrong: PropTypes.bool.isRequired,
    cint: PropTypes.bool.isRequired,
    surveysAutoRefresh: PropTypes.bool.isRequired,
    surveysBreakWarning: PropTypes.bool.isRequired,
    surveySwitcherCheck: PropTypes.bool.isRequired,
    surveySendEmailStats: PropTypes.bool.isRequired,
    surveysCategories: PropTypes.bool.isRequired,
    panelActiveUsers: PropTypes.bool.isRequired,
    clients: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    quarterlyPrice: PropTypes.number.isRequired,
    annualPrice: PropTypes.number.isRequired,
    surveysMax: PropTypes.number.isRequired,
    surveysActiveMax: PropTypes.number.isRequired,
    widgets: PropTypes.bool.isRequired,
    clearResponsesBlockadeDefault: PropTypes.bool.isRequired,
    questionJsCssAccess: PropTypes.bool.isRequired,
    collectorPanelAccess: PropTypes.bool.isRequired,
    collectorPanelTemplateCreate: PropTypes.bool.isRequired,
    collectorPanelVerifyRequired: PropTypes.bool.isRequired,
    collectorSmsAccess: PropTypes.bool.isRequired,
    collectorWidgetAccess: PropTypes.bool.isRequired,
    survey: PropTypes.shape({
      collectorsMax: PropTypes.number.isRequired,
      collectorsTokensMax: PropTypes.number.isRequired,
      analysesMax: PropTypes.number.isRequired,
      responsesMax: PropTypes.number.isRequired,
      dateEndMax: PropTypes.number.isRequired,
      questionsMax: PropTypes.number.isRequired,
      cookieBlockade: PropTypes.bool.isRequired,
      ipBlockade: PropTypes.bool.isRequired,
      languages: PropTypes.bool.isRequired,
      baseSurveys: PropTypes.bool.isRequired,
      topSurveys: PropTypes.bool.isRequired,
      piping: PropTypes.bool.isRequired,
      filters: PropTypes.bool.isRequired,
      filtersFromTextarea: PropTypes.bool.isRequired,
      quickFilterScreenoutFromAnswer: PropTypes.bool.isRequired,
      quotas: PropTypes.bool.isRequired,
      globalQuestionTemplates: PropTypes.bool.isRequired,
      ownQuestionsBase: PropTypes.bool.isRequired,
      textsOverride: PropTypes.bool.isRequired,
      allowRespondentsToHideSurveyOwner: PropTypes.bool.isRequired,
      collectorEmailMailFooterEdit: PropTypes.bool.isRequired,
      nameWork: PropTypes.bool.isRequired,
      historyChange: PropTypes.bool.isRequired,
      cafeteryDisable: PropTypes.bool.isRequired,
      surveyTemplates: PropTypes.bool.isRequired,
      panelPromoteAvailable: PropTypes.bool.isRequired,
      insidePanel: PropTypes.bool.isRequired,
      weights: PropTypes.bool.isRequired,
      inaccessibilityText: PropTypes.bool.isRequired,
      userQuestionTemplates: PropTypes.bool.isRequired,
      userBlockTemplates: PropTypes.bool.isRequired,
      redirects: PropTypes.bool.isRequired,
      pause: PropTypes.bool.isRequired,
      limit: PropTypes.bool.isRequired,
      invitationRedirect: PropTypes.bool.isRequired,
      switcherGuid: PropTypes.bool.isRequired,
      integrations: PropTypes.bool.isRequired,
      googleAdsense: PropTypes.bool.isRequired,
      clientRequired: PropTypes.bool.isRequired,
      excludings: PropTypes.bool.isRequired,
      reportEnable: PropTypes.bool.isRequired,
      surveyResponseFakerEnable: PropTypes.bool.isRequired,
      reportFromSystem: PropTypes.bool.isRequired,
      questionsAvailable: PropTypes.array.isRequired,
      points: PropTypes.shape({
        socialMinPoints: PropTypes.number.isRequired,
        socialMaxPoints: PropTypes.number.isRequired,
        cashMinPoints: PropTypes.number.isRequired,
        cashMaxPoints: PropTypes.number.isRequired,
        screenOutSocialMinPoints: PropTypes.number.isRequired,
        screenOutSocialMaxPoints: PropTypes.number.isRequired,
        screenOutCashMinPoints: PropTypes.number.isRequired,
        screenOutCashMaxPoints: PropTypes.number.isRequired,
        allowedToChangeSocialPoints: PropTypes.bool.isRequired,
        allowedToChangeCashPoints: PropTypes.bool.isRequired,
        allowedToChangeSocialScreenOutPoints: PropTypes.bool.isRequired,
        allowedToChangeCashScreenOutPoints: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
    analysis: PropTypes.shape({
      exportTypes: PropTypes.array.isRequired,
    }).isRequired,
    respondentBase: PropTypes.shape({
      basesMax:  PropTypes.number.isRequired,
      baseAddressesMax:  PropTypes.number.isRequired,
    }).isRequired,
  }),
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeNested: PropTypes.func.isRequired,
  onChangePoints: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    config: state.config,
  }
}

export default connect(mapStateToProps)(TabGeneral);
