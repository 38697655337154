import React, {Component} from 'react';
import {Form, FormGroup, Label, Input, Col} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Select from 'react-select';
import PropTypes from 'prop-types';
import Switch from "components/Switch";
import reactHtmlParser from "react-html-parser";
import moment from "moment";
import DatePicker from "react-datepicker";
import Button from "../../../../components/Button";
import HtmlEditor from "../../../../componentsReusable/HtmlEditor";

class NewsForm extends Component {

	constructor(props) {
		super(props);
		let arrayPlaces = [];

		if (props.news) {
			for (let i = 0; i < props.news.places.length; i++)
				arrayPlaces.push({label: props.news.places[i], value: props.news.places[i]});
		}

		this.state = {
			ckeditorShortDescription: false,
			ckeditorDescription: false,
			showCkEditorDescription: false,
			news: {
				title: props.news ? props.news.title : '',
				pageTitle: props.news ? props.news.pageTitle : '',
				publishAt: props.news ? moment(props.news.publishAt).format('YYYY-MM-DD HH:mm') : '',
				slug: props.news ? props.news.slug : '',
				image: props.news && props.news.image,
				shortDescription: props.news ? props.news.shortDescription : '',
				description: props.news ? props.news.description : '',
				metaDescription: props.news ? props.news.metaDescription : '',
				metaKeywords: props.news ? props.news.metaKeywords : '',
				places: props.news ? props.news.places : [],
				published: props.news ? props.news.published : false,
			},
			placesSelectedData: arrayPlaces
		};

		this.onChange = this.onChange.bind(this);
		this.onPlacesChange = this.onPlacesChange.bind(this);
	}

	onChange(e) {
		this.setState({
			...this.state,
			news: {
				...this.state.news,
				[e.target.name]: e.target.value
			}
		});
	}

	onPlacesChange(places) {
		let arrayPlaces = [];
		for (let i = 0; i < places.length; i++)
			arrayPlaces.push(places[i].value);

		this.setState({
			...this.state,
			placesSelectedData: places,
			news: {
				...this.state.news,
				places: arrayPlaces,
			}
		});
	}

	render() {
		let {
			news,
			placesSelectedData,
			ckeditorShortDescription,
			ckeditorDescription,
			showCkEditorDescription
		} = this.state;
		const {errors, onSubmit} = this.props;
		return (
			<Form onSubmit={e => onSubmit(e, this.state)}>
				<FormGroup row>
					<Label for="title" md={3} className="text-right"><FormattedMessage id="admin.newsForm.label.title"/></Label>
					<Col md={9}>
						<Input
							type="text" name="title" id="title" value={news.title} invalid={!!errors.title}
							onChange={this.onChange}
						/>
						{errors.title && <p className="help-block text-danger">{errors.title}</p>}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label  md={3}  className="text-right" for="title">Tytuł strony (max 60 znaków)</Label>
					<Col md={9}>
					<Input
						type="text" name="pageTitle" id="pageTitle" value={news.pageTitle} invalid={!!errors.pageTitle}
						onChange={this.onChange}
					/>
					{errors.pageTitle && <p className="help-block text-danger">{errors.pageTitle}</p>}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label for="publishAt" md={3} className="text-right"><FormattedMessage
						id="admin.reportForm.label.publishAt"
					/></Label>
					<Col md={9}>
						<DatePicker
							dateFormat="Y-MM-DD HH:mm"
							showTimeSelect
							locale={localStorage.language}
							dropdownMode="select"
							placeholderText="Data publikacji"
							selected={news.publishAt ? moment(news.publishAt) : ''}
							onChange={date => this.onChange({
									target: {
										name: 'publishAt',
										value: date.format('YYYY-MM-DD HH:mm')
									}
								}
							)}
						/>
						{errors.publishAt && <p className="help-block text-danger">{errors.publishAt}</p>}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label md={3} for="shortDescription" className="text-right"><FormattedMessage
						id="admin.newsForm.label.shortDescription"
					/></Label>
					<Col md={9} className="pt-2">
						{ckeditorShortDescription ?
							<HtmlEditor
								data={news.shortDescription}
								onChange={(event) => {
									this.setState({
										news: {
											...news,
											shortDescription: event
										},
									})
								}}
								onBlur={() => {
									this.setState({
										ckeditorShortDescription: false
									})
								}}
							/>

							: <div
								onClick={() => this.setState({ckeditorShortDescription: true})}
							>{news.shortDescription ? reactHtmlParser(news.shortDescription) :
								<i>Brak treści</i>}</div>}
						{errors.shortDescription && <p className="help-block text-danger">{errors.shortDescription}</p>}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label md={3} for="description" className="text-right"><FormattedMessage
						id="admin.newsForm.label.description"
					/></Label>
					<Col md={9} className="pt-2">
						{ckeditorDescription ?
							<div>
								{showCkEditorDescription === false &&
								<div>
									<div className="d-flex flex-column">
										<Input
											type="textarea" rows={15}
											onChange={e => this.onChange({
													target: {
														name: 'description',
														value: e.target.value
													}
												}
											)}
											value={news.description}
										/>
										<Button
											size="sm" className="mb-3" onClick={() => this.setState({
											showCkEditorDescription: true,
										})}
										>Pokaż CKEditor</Button>
									</div>
								</div>
								}
								{showCkEditorDescription === true && <div><HtmlEditor
									data={news.description}
									onChange={(event) => {
										this.setState({
											news: {
												...news,
												description: event
											},
										})
									}}
									onBlur={() => {
										this.setState({
											ckeditorDescription: false,
										})
									}}
								/>
									<Button
										size="sm" className="mb-3 w-100" onClick={() => this.setState({
										showCkEditorDescription: false,
									})}
									>Ukryj CKEditor</Button>
								</div>}
							</div>
							: <div
								onClick={() => this.setState({ckeditorDescription: true})}
							>{news.description ? reactHtmlParser(news.description) : <i>Brak treści</i>}</div>}
						{errors.description && <p className="help-block text-danger">{errors.description}</p>}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label md={3} className="text-right"><FormattedMessage id="admin.newsForm.label.places"/></Label>
					<Col md={9}>
						<Select
							isMulti
							value={placesSelectedData}
							onChange={this.onPlacesChange}
							options={[
								{label: 'swpanel', value: 'swpanel'},
								{label: 'swresearch', value: 'swresearch'},
								{label: 'ankieteo', value: 'ankieteo'},
							]}
						/>
						{errors.places && <p className="help-block text-danger">{errors.places}</p>}
					</Col>
				</FormGroup>
				<FormGroup
					row onClick={() => CKFinder.popup({
					connectorInfo: 'auth=' + localStorage.jwt,
					chooseFiles: true,
					width: 1014,
					height: 700,
					onInit: (finder) => {
						finder.on('files:choose', (e) => {
							let data = {
								target: {
									name: 'image',
									value: e.data.files.first().getUrl()
								}
							};
							this.onChange(data);
						});
					}
				})}
				>
					<Label md={3} for="image" className="text-right"><FormattedMessage id="admin.newsForm.label.image"/></Label>
					<Col md={9}>
						<div>
							{news.image ? <img
									alt=""
									src={news.image}
									style={{
										maxHeight: '100px',
										maxWidth: '100px',
									}}

								/>
								:
								<span className="d-block mt-2">Brak zdjęcia</span>}
						</div>
						{errors.image && <p className="help-block text-danger">{errors.image}</p>}
					</Col>
				</FormGroup>
				<FormGroup row>
					<Label md={3} className="text-right">Opublikuj</Label>
					<Col md={9} className="pt-2">
						<Switch
							checked={news.published} onChange={(published) => this.setState({
							news: {
								...news,
								published
							}
						})}
						/>
					</Col>
				</FormGroup>
				<FormGroup>
					<Label for="metaDescription">Meta description</Label>
					<Input
						type="text" name="metaDescription" id="metaDescription" value={news.metaDescription}
						invalid={!!errors.metaDescription} onChange={this.onChange}
					/>
					{errors.metaDescription && <p className="help-block text-danger">{errors.metaDescription}</p>}
				</FormGroup>
				<FormGroup>
					<Label for="metaKeywords">Meta keywords</Label>
					<Input
						type="text" name="metaKeywords" id="metaKeywords" value={news.metaKeywords}
						invalid={!!errors.metaKeywords} onChange={this.onChange}
					/>
					{errors.metaKeywords && <p className="help-block text-danger">{errors.metaKeywords}</p>}
				</FormGroup>
			</Form>
		)
	}
}

NewsForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	news: PropTypes.shape({
		title: PropTypes.string.isRequired,
		publishAt: PropTypes.string.isRequired,
		slug: PropTypes.string.isRequired,
		image: PropTypes.string.isRequired,
		shortDescription: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		places: PropTypes.array.isRequired,
		published: PropTypes.bool.isRequired,
	}),
};

export default NewsForm;