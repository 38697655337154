import React from 'react';

import Link from "../componentsReusable/Link";
import appRoutes from "../appRoutes";
import {FormattedMessage} from "react-intl";
import api from "../api";

export const TYPE_SURVEY_REMOVED = 'survey_removed';
export const TYPE_SURVEY_PAUSED_END_AT = 'survey_paused_end_at';
export const TYPE_SURVEY_PAUSED_RESPONSES_MAX = 'survey_paused_responses_max';
export const TYPE_SURVEY_REPORT_CREATED = 'survey_report_created';
export const TYPE_SURVEY_SHARE_CREATED = 'survey_share_created';
export const TYPE_SURVEY_BREAKS_WARNING = 'survey_break_warning';
export const TYPE_SURVEY_QUOTA_REACHED = 'survey_quota_reached';
export const TYPE_SURVEY_COLLECTOR_END_AT_REACHED = 'survey_collector_end_at_reached';
export const TYPE_SURVEY_COLLECTOR_RESPONSES_REACHED = 'survey_collector_responses_reached';
export const TYPE_SURVEY_COLLECTOR_SEND_ENABLE = 'survey_collector_send_enable';
export const TYPE_SURVEY_COLLECTOR_SEND_DISABLE = 'survey_collector_send_disable';
export const TYPE_SURVEY_COLLECTOR_REALIZATION_AUTOMATE_ENABLE = 'survey_collector_realization_automate_enable';
export const TYPE_ANALYSIS_EXPORT = 'analysis_export';

export default (notification) => {
  switch(notification.type) {
    case TYPE_SURVEY_REMOVED: {
      return <span>Ankieta <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link> ({notification.survey_detail_end_responses_count}) została usunięta</span>
    }
    case TYPE_SURVEY_PAUSED_END_AT: {
      return <span>Ankieta <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link> została zamknięta - przekroczona data zamknięcia ankiety</span>
    }
    case TYPE_SURVEY_PAUSED_RESPONSES_MAX: {
      return <span>Ankieta <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link> została zamknięta - osiągnięto limit wypełnień</span>
    }
    case TYPE_SURVEY_REPORT_CREATED: {
      return <span className="text-warning">Nowe zgłoszenie błędu w ankiecie <Link to={appRoutes.survey.reports(notification.survey_detail_id)}>{notification.survey_detail_name}</Link></span>
    }
    case TYPE_SURVEY_SHARE_CREATED: {
      return <span>Dodał(a) Ciebie do ankiety <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link></span>
    }
    case TYPE_SURVEY_BREAKS_WARNING: {
      return <span className="text-danger">Wysoka liczba przerwań w ankiecie <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link></span>
    }
    case TYPE_SURVEY_QUOTA_REACHED: {
      return <span>Kwota <i>{notification.quota_detail_name}</i> w ankiecie <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link> została zapełniona ({notification.quota_detail_count})</span>
    }
    case TYPE_SURVEY_COLLECTOR_END_AT_REACHED: {
      return <span>Data zakończenia w kolektorze <Link to={appRoutes.survey.collectors.collector.view(notification.survey_detail_id, notification.survey_collector_detail_id)}>{notification.survey_collector_detail_name || <FormattedMessage id={`_.surveyCollector.${notification.survey_collector_detail_type}.name`} />}</Link> w ankiecie <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link> została przekroczona</span>
    }
    case TYPE_SURVEY_COLLECTOR_RESPONSES_REACHED: {
      return <span>Limit wypełnień w kolektorze <Link to={appRoutes.survey.collectors.collector.view(notification.survey_detail_id, notification.survey_collector_detail_id)}>{notification.survey_collector_detail_name || <FormattedMessage id={`_.surveyCollector.${notification.survey_collector_detail_type}.name`} />}</Link> w ankiecie <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link> został osiągnięty</span>
    }
    case TYPE_SURVEY_COLLECTOR_SEND_ENABLE: {
      return <span>Wykonał(a) emisję zaproszeń do kolektora <Link to={appRoutes.survey.collectors.collector.view(notification.survey_detail_id, notification.survey_collector_detail_id)}>{notification.survey_collector_detail_name || <FormattedMessage id={`_.surveyCollector.${notification.survey_collector_detail_type}.name`} />}</Link> ({notification.respondents_count}) w ankiecie <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link></span>
    }
    case TYPE_SURVEY_COLLECTOR_SEND_DISABLE: {
      return <span>Zatrzymał(a) emisję zaproszeń do kolektora <Link to={appRoutes.survey.collectors.collector.view(notification.survey_detail_id, notification.survey_collector_detail_id)}>{notification.survey_collector_detail_name || <FormattedMessage id={`_.surveyCollector.${notification.survey_collector_detail_type}.name`} />}</Link> ({notification.respondents_count}) w ankiecie <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link></span>
    }
    case TYPE_SURVEY_COLLECTOR_REALIZATION_AUTOMATE_ENABLE: {
      return <span>Pinczos - łowca wywiadów - wyruszył w poszukiwaniu respondentów do ankiety <Link to={appRoutes.survey.collectors.listPanelRealizationAutomate(notification.survey_detail_id)}>{notification.survey_detail_name}</Link></span>
    }
    case TYPE_ANALYSIS_EXPORT: {
      return <span>Plik eksportu dla ankiety <Link to={appRoutes.survey.structure(notification.survey_detail_id)}>{notification.survey_detail_name}</Link> jest dostępny do pobrania: <Link to={api.analysis.getAnalysisExportFile(notification.analysis_export_detail_id)} onClick={e => e.stopPropagation()} target="_blank">pobierz</Link></span>
    }
    default: {
      return '';
    }
  }
}
