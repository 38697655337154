import React, {Component} from 'react';
import PropTypes from "prop-types";

class Bpto extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {details} = this.props;

    return (
      <ul>
        {details.map((detail, i) => {
          return <li key={i}>{detail.position+1}. <span dangerouslySetInnerHTML={{__html: detail.answer.content}} className="d-inline-block img-30"/></li>
        })}
      </ul>
    )
  }
}


Bpto.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }).isRequired,
    position: PropTypes.number.isRequired,
  }),).isRequired,
};

export default Bpto;