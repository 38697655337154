import React, {Component} from 'react';
import PropTypes from 'prop-types';

class GMap extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {details} = this.props;

    return (
      <ul>
        {details.map((detail, i) => {
          return <li key={i}>{i+1}. {detail.formattedAddress} ({detail.lat}; {detail.lng})</li>
        })}
      </ul>
    )
  }
}

GMap.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    formattedAddress: PropTypes.string.isRequired,
  })).isRequired
};

export default GMap;
