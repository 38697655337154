export const SET_HIDE_IN_STRUCTURE = 'Survey/Structure/SET_HIDE_IN_STRUCTURE';
export const SET_SEARCH_IN_STRUCTURE = 'Survey/Structure/SET_SEARCH_IN_STRUCTURE';
export const SET_FILTER_CONDITION_QUESTIONS = 'Survey/Structure/SET_FILTER_CONDITION_QUESTIONS';
export const SET_FILTER_ACTION_QUESTIONS = 'Survey/Structure/SET_FILTER_ACTION_QUESTIONS';
export const SET_DISABLED_QUESTIONS = 'Survey/Structure/SET_DISABLED_QUESTIONS';
export const SET_SHOW_QUESTIONS_SETTINGS = 'Survey/Structure/SET_SHOW_QUESTIONS_SETTINGS';
export const SET_QUESTION_TYPES = 'Survey/Structure/SET_QUESTION_TYPES';
export const TOGGLE_SET_CARD = 'Survey/Structure/TOGGLE_SET_CARD';
export const TOGGLE_BLOCK_CARD = 'Survey/Structure/TOGGLE_BLOCK_CARD';
export const TOGGLE_PAGE_CARD = 'Survey/Structure/TOGGLE_PAGE_CARD';
export const TOGGLE_QUESTION_CARD = 'Survey/Structure/TOGGLE_QUESTION_CARD';
export const SET_CLOSED_SETS_CARDS = 'Survey/Structure/SET_CLOSED_SETS_CARDS';
export const SET_CLOSED_BLOCKS_CARDS = 'Survey/Structure/SET_CLOSED_BLOCKS_CARDS';
export const SET_CLOSED_PAGES_CARDS = 'Survey/Structure/SET_CLOSED_PAGES_CARDS';
export const SET_CLOSED_QUESTIONS_CARDS = 'Survey/Structure/SET_CLOSED_QUESTIONS_CARDS';
export const CLEAR_CLOSED_CARDS = 'Survey/Structure/CLEAR_CLOSED_CARDS';
export const SET_LOADING = 'Survey/Structure/SET_LOADING';
export const UNSET_LOADING = 'Survey/Structure/UNSET_LOADING';

export function hideInStructureSet(hideInStructure) {
  return {
    type: SET_HIDE_IN_STRUCTURE,
    hideInStructure
  }
}

function searchInStructureSet(text) {
  return {
    type: SET_SEARCH_IN_STRUCTURE,
    text
  }
}

function filterConditionQuestionsSet(filterConditionQuestions) {
  return {
    type: SET_FILTER_CONDITION_QUESTIONS,
    filterConditionQuestions
  }
}

function filterActionQuestionsSet(filterActionQuestions) {
  return {
    type: SET_FILTER_ACTION_QUESTIONS,
    filterActionQuestions
  }
}

function disabledQuestionsSet(disabledQuestions) {
  return {
    type: SET_DISABLED_QUESTIONS,
    disabledQuestions
  }
}
function showQuestionsSettingsSet(showQuestionsSettings) {
  return {
    type: SET_SHOW_QUESTIONS_SETTINGS,
    showQuestionsSettings
  }
}

function questionTypesSet(questionTypes) {
  return {
    type: SET_QUESTION_TYPES,
    questionTypes
  }
}

function setCardToggled(setId) {
  return {
    type: TOGGLE_SET_CARD,
    setId
  }
}

function blockCardToggled(blockId) {
  return {
    type: TOGGLE_BLOCK_CARD,
    blockId
  }
}

function pageCardToggled(pageId) {
  return {
    type: TOGGLE_PAGE_CARD,
    pageId
  }
}

function questionCardToggled(questionId) {
  return {
    type: TOGGLE_QUESTION_CARD,
    questionId
  }
}

function closedSetsCardsSet(setsIds) {
  return {
    type: SET_CLOSED_SETS_CARDS,
    setsIds
  }
}

function closedBlocksCardsSet(blocksIds) {
  return {
    type: SET_CLOSED_BLOCKS_CARDS,
    blocksIds
  }
}

function closedPagesCardsSet(pagesIds) {
  return {
    type: SET_CLOSED_PAGES_CARDS,
    pagesIds
  }
}

export function closedQuestionsCardsSet(questionsIds) {
  return {
    type: SET_CLOSED_QUESTIONS_CARDS,
    questionsIds
  }
}

function closedCardsCleared() {
  return {
    type: CLEAR_CLOSED_CARDS,
  }
}


export function setLoading() {
  return {
    type: SET_LOADING,
  }
}

export function unsetLoading() {
  return {
    type: UNSET_LOADING,
  }
}

export function setHideInStructure(type) {
  return dispatch => {
    dispatch(hideInStructureSet(type));
  }
}

export function setSearchInStructure(text) {
  return dispatch => {
    dispatch(searchInStructureSet(text));
  }
}

export function setFilterConditionQuestions(filterConditionQuestions) {
  return dispatch => {
    dispatch(filterConditionQuestionsSet(filterConditionQuestions));
  }
}

export function setFilterActionQuestions(filterActionQuestions) {
  return dispatch => {
    dispatch(filterActionQuestionsSet(filterActionQuestions));
  }
}

export function setDisabledQuestions(disabledQuestions) {
  return dispatch => {
    dispatch(disabledQuestionsSet(disabledQuestions));
  }
}

export function setShowQuestionsSettings(showQuestionsSettings) {
  return dispatch => {
    dispatch(showQuestionsSettingsSet(showQuestionsSettings));
  }
}

export function setQuestionTypes(questionTypes) {
  return dispatch => {
    dispatch(questionTypesSet(questionTypes));
  }
}

export function toggleSetCard(setId) {
  return dispatch => {
    dispatch(setCardToggled(setId));
  }
}

export function toggleBlockCard(blockId) {
  return dispatch => {
    dispatch(blockCardToggled(blockId));
  }
}

export function togglePageCard(pageId) {
  return dispatch => {
    dispatch(pageCardToggled(pageId));
  }
}

export function toggleQuestionCard(questionId) {
  return dispatch => {
    dispatch(questionCardToggled(questionId));
  }
}

export function setClosedSetCards(setsIds) {
  return dispatch => {
    dispatch(closedSetsCardsSet(setsIds));
  }
}

export function setClosedBlockCards(blocksIds) {
  return dispatch => {
    dispatch(closedBlocksCardsSet(blocksIds));
  }
}

export function setClosedPageCards(pagesIds) {
  return dispatch => {
    dispatch(closedPagesCardsSet(pagesIds));
  }
}

export function setClosedQuestionCards(questionsIds) {
  return dispatch => {
    dispatch(closedQuestionsCardsSet(questionsIds));
  }
}

export function clearClosedCards() {
  return dispatch => {
    dispatch(closedCardsCleared());
  }
}