import React, {Component} from 'react';
import {Link, Route, Switch} from "react-router-dom";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import appRoutes from "../../../appRoutes";
import DashboardNotifiesTable from "./components/DashboardNotifiesTable";
import DashboardNotifyForm from "../Da/DashboardNotifiesCms/components/DashboardNotifyForm";

class DashboardNotifiesCms extends Component {
  render() {
    return <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="float-left mr-4">Powiadomienia ankieteo</h2>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <Row>
              <Col>
                <Switch>
                  <Route path={appRoutes.admin.cms.dashboardNotifies.list} children={<DashboardNotifiesTable />} />
                  <Route path={appRoutes.admin.cms.dashboardNotifies.add} children={<DashboardNotifyForm />} />
                  <Route path={appRoutes.admin.cms.dashboardNotifies.edit()} children={<DashboardNotifyForm />} />
                </Switch>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>;
  }
}

DashboardNotifiesCms.propTypes = {};

export default DashboardNotifiesCms;
