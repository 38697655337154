import api from 'api';

export const SET = 'Analysis/data/shares/SET';
export const DELETED = 'Analysis/data/shares/DELETED';
export const ADDED = 'Analysis/data/shares/ADDED';
export const UPDATED = 'Analysis/data/shares/UPDATED';

export function setShares(share) {
  return {
    type: SET,
    share
  }
}

function shareDeleted(id) {
  return {
    type: DELETED,
    id
  }
}

function shareAdded(share) {
  return {
    type: ADDED,
    share
  }
}

function shareUpdated(share) {
  return {
    type: UPDATED,
    share
  }
}

export function deleteShare(data) {
  return dispatch => {
    return api.analysis.delete.analysisShare(data).then(() => {
      dispatch(shareDeleted(data.id));
    });
  }
}

export function addShare(data) {
  return dispatch => {
    return api.analysis.post.analysisShare(data).then((share) => {
      dispatch(shareAdded(share));
      return share;
    });
  }
}

export function updateShare(data) {
  return dispatch => {
    return api.analysis.patch.analysisShare(data).then((share) => {
      dispatch(shareUpdated(share));
      return share;
    });
  }
}