import React, {Component} from 'react';
import {Table} from "reactstrap";
import { connect } from 'react-redux';
import RandAndRotateBlockOptions from './components/RandAndRotateBlockOptions';
import {updateSet} from "../../../../data/sets/actions";

class RandAndRotateSetsTable extends Component {

  render() {
    if(!this.props.survey.rotateSets && !this.props.survey.randSets){
      return null;
    }

    return (
      <Table>
        <thead>
          <tr>
            <th />
            {this.props.survey.rotateSets && <th><small>Rotowanie</small></th>}
            {this.props.survey.randSets > 0 && <th><small>Losowanie</small></th>}
          </tr>
        </thead>
        <tbody>
        {this.props.survey.setBlocks.map(setId => <RandAndRotateBlockOptions set={this.props.sets[setId]} key={setId}/>)}
        </tbody>
      </Table>
    )
  }
}

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
    sets: state.survey.structure.data.sets,
  }
}

export default connect(mapStateToProps, {updateSet})(RandAndRotateSetsTable);
