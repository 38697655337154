import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {deleteExcludings} from "scenes/Survey/Structure/data/excludings/actions";
import striptags from 'striptags';

class CheckedDelete extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      deleting: false,
    };

    this.deleteChecked = this.deleteChecked.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
  }

  deleteChecked(){
    this.setState({
      deleting: true
    });

    this.props.deleteExcludings(this.props.question.id, {
      ids: this.props.excludingsChecked.map(excluding => excluding.id)
    });
  }

  toggleModalDelete(){
    this.setState({ modal: !this.state.modal })
  }

  render() {
    const {excludingsChecked} = this.props;
    const {deleting} = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModalDelete()}>
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2"><FormattedMessage id="excludings.checkedDelete.modal.header"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="excludings.checkedDelete.modal.body"/>?</p>
        <ul>
          {excludingsChecked.map((excluding, key) => {
            return <li key={key}><h4>{striptags(excluding.content)}</h4></li>
          })}
        </ul>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="excludings.checkedDelete.modal.button.confirm">{
              (message) => <LaddaButton
                className="btn btn-danger btn-ladda mb-0 ml-3"
                loading={deleting}
                data-style={ZOOM_OUT}
                onClick={() => this.deleteChecked()}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggleModalDelete()}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Label className="label-filtr bg-danger text-white" color="danger" onClick={() => this.toggleModalDelete()}><i className="fas fa-trash" /> <FormattedMessage id="excludings.checkedDelete.button.deleteChecked" /> ({excludingsChecked.length})</Label>
        {modal}
      </React.Fragment>
    )
  }
}

CheckedDelete.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  excludingsChecked: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired).isRequired
};


export default connect(null, {deleteExcludings})(CheckedDelete);