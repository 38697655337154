import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";
import {connect} from "react-redux";
import {userLogout} from "../../../reducers/user/actions";
import isGroupManager from "../../../utils/userGroupHelper";

class Home extends Component {
  componentDidMount() {
    document.title = 'Ankieteo - Konto';
  }

  render() {
    const {user} = this.props;

    return <Card className="h-100 mb-0">
      <CardHeader><h2><i className="fas fa-home mr-2" /> Home</h2></CardHeader>
      <hr className="my-0" />
      <CardBody>
        <Row>
          {isGroupManager(user) && <Col xs={12} sm={6} md={4} lg={3}>
            <Link to={appRoutes.account.insights} className="border shadow d-block w-100 p-4 mb-3">
              <p className="text-center pb-0"><i className="fas fa-chart-area fa-2x" /></p>
              <p className="text-center" style={{fontSize: '1.5em'}}>Statystyki</p>
            </Link>
          </Col>}
          {isGroupManager(user) && <Col xs={12} sm={6} md={4} lg={3}>
            <Link to={appRoutes.account.group} className="border shadow d-block w-100 p-4 mb-3">
              <p className="text-center pb-0"><i className="fas fa-users fa-2x" /></p>
              <p className="text-center" style={{fontSize: '1.5em'}}>Użytkownicy</p>
            </Link>
          </Col>}
          <Col xs={12} sm={6} md={4} lg={3}>
            <Link to={appRoutes.account.billing} className="border shadow d-block w-100 p-4 mb-3">
              <p className="text-center pb-0"><i className="fas fa-credit-card fa-2x" /></p>
              <p className="text-center" style={{fontSize: '1.5em'}}>Zamówienia</p>
            </Link>
          </Col>
          {isGroupManager(user) && <Col xs={12} sm={6} md={4} lg={3}>
            <Link to={appRoutes.account.profile} className="border shadow d-block w-100 p-4 mb-3">
              <p className="text-center pb-0"><i className="fas fa-user fa-2x" /></p>
              <p className="text-center" style={{fontSize: '1.5em'}}>Dane firmy</p>
            </Link>
          </Col>}
          {!isGroupManager(user) && <Col xs={12} sm={6} md={4} lg={3}>
            <Link to={appRoutes.users.profile} className="border shadow d-block w-100 p-4 mb-3">
              <p className="text-center pb-0"><i className="fas fa-user fa-2x" /></p>
              <p className="text-center" style={{fontSize: '1.5em'}}>Profil</p>
            </Link>
          </Col>}
          {isGroupManager(user) && <Col xs={12} sm={6} md={4} lg={3}>
            <Link to={appRoutes.account.settings} className="border shadow d-block w-100 p-4 mb-3">
              <p className="text-center pb-0"><i className="fas fa-cog fa-2x" /></p>
              <p className="text-center" style={{fontSize: '1.5em'}}>Ustawienia</p>
            </Link>
          </Col>}
          {isGroupManager(user) && <Col xs={12} sm={6} md={4} lg={3}>
            <Link to={appRoutes.account.integration} className="border shadow d-block w-100 p-4 mb-3">
              <p className="text-center pb-0"><i className="fas fa-puzzle-piece fa-2x" /></p>
              <p className="text-center" style={{fontSize: '1.5em'}}>Integracje</p>
            </Link>
          </Col>}
        </Row>
      </CardBody>
    </Card>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Home);