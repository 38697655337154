import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Select from "react-select";

class SelectRows extends Component {
  constructor(props){
    super(props);

    this.select = React.createRef();

    this.onChange = this.onChange.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.getContent = this.getContent.bind(this);
  }

  onChange(e){
    const {isMulti} = this.props;

    if(isMulti){
      this.select.current.setState({isOpen: true, menuIsOpen: true});
    }

    if(isMulti){
      this.props.onChange(e.map(obj => obj.value));
    }else{
      this.props.onChange(e.value);
    }
  }

  getContent(row) {
    const isDifferential = row.type === 'differential';

    if (this.props.question.rowsImage) {
      return isDifferential ? <React.Fragment><img src={row.leftContent} /> - <img src={row.rightContent} /></React.Fragment> : <img src={row.content} />
    }

    return isDifferential ? (row.stripedLeftContent + ' | ' + row.stripedRightContent) : row.stripedContent
  }

  getLabel(row) {
    return this.props.question.rowsImage
      ? <span className="img-30">{row.position}. {this.getContent(row)}</span>
      : <span className="img-20" dangerouslySetInnerHTML={{__html: row.position + '. ' + this.getContent(row)}}/>
  }

  render() {
    const {rows, value, isMulti, placeholder} = this.props;

    return (
      <Select
        placeholder={placeholder ? placeholder : (isMulti ? "Wybierz wiersze" : "Wybierz wiersz")}
        ref={this.select}
        value={value && (!isMulti ?
            {
              label: this.getLabel(rows.find(r => r.id === value)),
              value
            }
          :
          value.map(rId => {
            return {
              label: this.getLabel(rows.find(r => r.id === rId)),
              value: rId,
            }
          })
        )}
        isOpen={false}
        isMulti={isMulti}
        filterOption={(option, string) => {
          if (option.value.toString().toLowerCase().includes(string.toLowerCase())) return true;
          if (option.data.content.toLowerCase().includes(string.toLowerCase())) return true;
        }}
        onChange={this.onChange}
        options={rows.map(row => {
          return {
            label: this.getLabel(row),
            content: row.content || '',
            value: row.id,
          }
        })}
      />
    );
  }
}

SelectRows.defaultProps = {
  isMulti: true,
};

SelectRows.propTypes = {
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  questionId: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    stripedContent: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number,]),
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  let rows = state.survey.structure.data.questions[props.questionId].rows.map(rId => state.survey.structure.data.rows[rId]);

  let position = 0;
  rows = rows.map(row => {
    position++;
    return {
      ...row,
      position
    }
  });

  return {
    rows,
    question: state.survey.structure.data.questions[props.questionId]
  }
}

export default connect(mapStateToProps)(SelectRows);