import React, {Component} from 'react';
import PropTypes from "prop-types";
import AnyChart from "anychart-react";

class ChartTagCloud extends Component {
  render() {
    const {id, data} = this.props;
    let sliceValue = data.length > 0 ? parseInt((data[0].value * 0.01), 10) : 0;
    const dataToDisplay = data.filter(d => d.value > sliceValue);

    return (
      <AnyChart
        className="w-50"
        id={'question-' + id}
        type="tagCloud"
        height={400}
        data={dataToDisplay}
      />
    )
  }
}

ChartTagCloud.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,).isRequired,
};

export default ChartTagCloud;