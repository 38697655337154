import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appRouter from "appRoutes";
import {Link} from "react-router-dom";
import EditAction from "./Actions/EditAction";
import SurveyResponseIpAddressRepeatsCheck from "./SurveyResponseIpAddressRepeatsCheck";
import DeleteAction from "./Actions/DeleteAction";
import {connect} from "react-redux";

class SurveysActions extends Component {

  render() {
    return (
      <div className="d-flex flex-row flex-nowrap align-items-center justify-content-end">
        <span className="mr-1"><SurveyResponseIpAddressRepeatsCheck surveyId={this.props.survey.id} /></span>
        <Link to={appRouter.survey.structure(this.props.survey.id)}><i className="fas fa-link" /></Link>
        <EditAction survey={this.props.survey} handleChange={this.props.handleChange} />
        {this.props.isSuperAdmin && <DeleteAction survey={this.props.survey} handleChange={this.props.handleChange}/>}
      </div>
    )
  }
}

SurveysActions.propTypes = {
  survey: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};


function mapStateToProps(state) {
  return {
    isSuperAdmin: state.user.roles && state.user.roles.includes('ROLE_SUPER_ADMIN'),
  }
}
export default connect(mapStateToProps)(SurveysActions);
