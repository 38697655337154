const convertSvgToBase64 = (selector) => {
	let svg = document.querySelector(selector)
	if (!svg) {
		return null;
	}
	const width = window.getComputedStyle(svg).width;
	const height = window.getComputedStyle(svg).height;

	svg.setAttribute("width", width)
	svg.setAttribute("height", height)

	const s = new XMLSerializer().serializeToString(svg)
	const encodedData = window.btoa(unescape(encodeURIComponent(s)));

	return {
		data: "data:image/svg+xml;base64," + encodedData,
		height: height.slice(0,-2),
		width: width.slice(0,-2)
	};
}

export default convertSvgToBase64;