import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from 'reactstrap';
import SurveyTemplatesDeleteButton from "./SurveyTemplatesDeleteButton";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import moment from "moment";
import api from "api";

class SurveyTemplatesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
    this.actions = this.actions.bind(this);
  }

  fetchData(state) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.survey.post.searchGlobalTemplates(data).then(res => {
      this.setState({searching: false, data: res.templates, count: res.count});
    });
  }

  actions(template){
    const handler = () => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});
    return (
      <ButtonGroup>
        <SurveyTemplatesDeleteButton template={template} handleChange={handler}/>
      </ButtonGroup>
    )
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="admin.surveyTemplatesList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="admin.surveyTemplatesList.listItems.tableHeader.name" />,
      accessor: 'name'
    }, {
      id: 'createdAt',
      Header: <FormattedMessage id="admin.surveyTemplatesList.listItems.tableHeader.createdAt" />,
      accessor: template => moment(template.createdAt).format('YYYY-MM-DD HH:mm')
    }, {
      id: 'image',
      Header: <FormattedMessage id="admin.surveyTemplatesList.listItems.tableHeader.image" />,
      accessor: template => <img src={'/surveyStyles/images/' + template.id + '.png'} className="mw-100 mh-100"/>
    }, {
      id: 'actions',
      Header: <FormattedMessage id="admin.surveyTemplatesList.listItems.tableHeader.actions" />,
      accessor: template => this.actions(template),
      sortable: false
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.surveyTemplatesList.listItems.table.noTemplates" />}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

export default SurveyTemplatesList;