import update from 'immutability-helper';
import {SET, ADDED, UPDATED, DELETED} from './actions';
import * as questionActions from "../questions/actions";
import _ from 'lodash';

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.filterSerial;

    case ADDED:
      return {
        ...state,
        [action.filterSerial.id]: action.filterSerial
      };

    case UPDATED:
      return update(state, {
        [action.filterSerial.id]: {
          $set: action.filterSerial
        }
      });

    case questionActions.DELETED:
      let filters = {};

      let filteredFilters = _.filter(state, filter => {
        return filter.questionFrom.question !== action.question.id && filter.questionTo.question !== action.question.id
      });
      _.each(filteredFilters, filter => filters[filter.id] = filter);

      return filters;

    case DELETED:
      return update(state, {
        $unset: [action.filterSerial.id]
      });

    default:
      return state;
  }
};