import React, {Component} from 'react';
import {
  Button,
  Card,
  CardFooter,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Tooltip
} from "reactstrap";
import CardHeader from "reactstrap/es/CardHeader";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import RespondentBaseCreateModal from "../RespondentBases/components/RespondentBaseCreateModal";
import CardBody from "reactstrap/es/CardBody";
import RespondentBasesList from "../RespondentBases/components/RespondentBasesList";
import appRoutes from "../../appRoutes";
import {connect} from "react-redux";

class RespondentBaseChoose extends Component {

  render() {
    return (
      <Card>
        <CardHeader>
          <h2>Wybierz rodzaj bazy respondentów</h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <Row className="d-flex justify-content-center">
            <Col xs={12} sm={6} md={5} lg={4} xl={3}>
              <Card className="h-100">
                <CardHeader><h2>Bazy respondentów e-mail</h2></CardHeader>
                <hr className="my-0" />
                <CardBody>
                  Zarządzaj bazami respondentów e-mail
                </CardBody>
                <CardFooter><Link className="btn btn-primary mx-auto d-flex justify-content-center align-items-center" to={appRoutes.respondentBase.list}>Zarządzaj</Link></CardFooter>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={5} lg={4} xl={3}>
              <Card className="h-100">
                <CardHeader><h2>Bazy respondentów SMS</h2></CardHeader>
                <hr className="my-0" />
                <CardBody>
                  Zarządzaj bazami respondentów SMS
                </CardBody>
                <CardFooter><Link className="btn btn-primary mx-auto d-flex justify-content-center align-items-center" to={appRoutes.respondentSmsBase.list}>Zarządzaj</Link></CardFooter>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    plan: 'userPlan' in state.user ? state.user.userPlan.plan : null
  }
}

export default connect(mapStateToProps)(RespondentBaseChoose);