import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {DropdownToggle, DropdownMenu, Dropdown, DropdownItem, Input} from "reactstrap";
import ModalDelete from "./components/ModalDelete";
import ButtonDrag from "./components/ButtonDrag";
import LoadingSpinner from "components/LoadingSpinner";
import QuestionColumnNew from "../ColumnNew";
import {deleteColumn, updateColumn} from "scenes/Survey/Structure/data/columns/actions";
import reactStringReplace from "react-string-replace";
import reactHtmlParser from "react-html-parser";
import HtmlEditor
  from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";
import {FormattedMessage} from "react-intl";
import Checkbox from "components/Checkbox";
import ButtonExcludedFromRotate from "./components/ButtonExcludedFromRotate";
import ButtonExcludedFromRand from "./components/ButtonExcludedFromRand";
import ButtonBlocking from "./components/ButtonBlocking";
import getCKConfigByPlan from 'utils/ckeditor/cafeteryConfig';
import ColumnDescription from "./components/ColumnDescription";
import ButtonOpenColumn from "./components/ButtonOpenColumn";
import ColumnRandPriority from "./components/ColumnRandPriority";
import {isMobile, isTablet} from "react-device-detect";
import ButtonExcludeRequired from "./components/ButtonExcludeRequired";
import InputNumber from "components/InputNumber";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "components/PlanRestrictionsModals/modals/MultimediaAvailable";
import classnames from "classnames";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";
import CafeteryEditModal from "../../../CafeteryEditModal";
import ButtonDisabled from "./components/ButtonDisabled";
import ButtonOpenIsRequired from "../../../Rows/components/Row/components/ButtonOpenIsRequired";

class QuestionColumn extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownSettingsOpen: false,
      deleteButton: false,
      modalDelete: false,
      deleting: false,
      editing: false,
      saving: false,
      addNewColumnBelow: false,
      hovered: false,
      column: {
        content: props.column.content,
        excludedFromRand: props.column.excludedFromRand,
        excludedFromRotate: props.column.excludedFromRotate,
        blocking: props.column.blocking,
        openIsRequired: props.column.openIsRequired,
        isOpen: props.column.isOpen,
        randPriority: props.column.randPriority
      },
    };

    this.deleteColumn = this.deleteColumn.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.updateColumn = this.updateColumn.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setHovered = this.setHovered.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      ...this.state,
      column: {
        ...this.state.column,
        content: nextProps.column.content
      }
    })
  }

  onClick(){
    this.setState({editing: true});
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.updateColumn()
  }

  onChangeContent(content){
    const {saving} = this.state;

    if(!saving) {
      this.setState({
        column: {
          ...this.state.column,
          content
        }
      });
    }
  }

  updateColumn(){
    const {column} = this.state;

    if (column.content.length === 0) {
      this.setState({column: {...this.state.column, content: this.props.column.content}});
      return;
    }

    this.setState({
      saving: true,
      editing: false
    });

    if(column.content.length === 0)
        return false;

    const data = {
        id: this.props.column.id,
        column: {
            content: column.content
        }
    };

    this.props.updateColumn(data).then(() => this.setState({saving: false}));
  }

  deleteColumn(){
    this.setState({
      deleting: true
    });

    if(!this.state.deleting){
      this.props.deleteColumn({
        id: this.props.column.id
      })
    }
  }

  toggleOption(option) {
    this.setState({
      column: {
        ...this.state.column,
        [option]: !this.state.column[option]
      }
    }, () => {
      const data = {
        id: this.props.column.id,
        column: {
          [option]: this.state.column[option]
        }
      };

      this.props.updateColumn(data);
    });
  }

  changeOption(option, value) {
    this.setState({
      column: {
        ...this.state.column,
        [option]: value
      }
    }, () => {
      const data = {
        id: this.props.column.id,
        column: {
          [option]: value
        }
      };

      this.props.updateColumn(data);
    });
  }

  toggle(type, e = {}) {
    if (e.target.className.includes("value-button"))
      return;

    this.setState({
      [type]: !this.state[type]
    })
  }

  setHovered(hovered) {
    this.setState({hovered});
  }

  render() {
    const {position, question, searchInStructure, hideInStructure, plan, checked} = this.props;
    const {column, deleting, addNewColumnBelow, editing, saving, hovered} = this.state;
    const ctrlEnterKeyCode = 1114125;
    const gripStyle = hovered ? {opacity: 1} : {opacity: 0};
    let editor;

    if (isMobile || isTablet) {
      editor = <Input value={column.content} onChange={e => this.onChangeContent(e.target.value)} onBlur={this.updateColumn} autoFocus={true} onKeyPress={this.handleKeyPress}/>
    } else {
      editor = <HtmlEditor
        data={column.content}
        config={getCKConfigByPlan(plan)}
        type="inline"
        onChange={event => this.onChangeContent(event)}
        onBlur={event => {
          this.updateColumn();
          this.setState({editing: false})
        }}
        onKey={event => {
          if (event.data.keyCode === ctrlEnterKeyCode) {
            event.cancel();
            this.updateColumn();
            setTimeout(() => {
              event.editor.destroy();
              this.setState({editing: false})
            }, 1);
          }
        }}
      />
    }

    let style = {
      position: 'relative'
    };

    let contentStyle = hideInStructure === 'minified' ? {
      width: '75%',
      display: 'list-item',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    } : {wordBreak: 'break-all'};

    if(deleting) style.opacity = 0.35;

    let columnContent = column.content;
    if (searchInStructure.length > 0) {
      columnContent = reactStringReplace(columnContent, searchInStructure, (match, i) => (
        <span className="bg-warning" key={i}>{match}</span>
      ));
    }

    return (
      <React.Fragment>
        <div className="sortable-draggable line-wrap" style={style} data-id={this.props.column.id} onMouseEnter={_ => this.setHovered(true)} onMouseLeave={_ => this.setHovered(false)}>
          <ButtonDrag id={this.props.column.id} gripStyle={gripStyle}/>

          <ul className="mb-0 d-flex pl-2">
            <li className="d-none d-lg-inline-block">
              <div className="form-group m-0 overflow-hidden">
                <Checkbox checked={checked} id={'column-' + this.props.column.id + '-check'} onClick={() => this.props.handlerChecked(!checked)} messageId={"checkbox.check.column"}/>
              </div>
            </li>
            <li>{position + 1}.</li>

            <li className="flex-grow-1" style={contentStyle}>
              {question.columnsImage ?
                (
                  <img
                    alt={column.content}
                    style={{
                      maxHeight: '70px'
                    }}
                    src={column.content || '/dashboard/img/no-image.png'}
                    onClick={() => {
                      if (!plan.multimedia.access) {
                        this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
                        return;
                      }
                      CKFinder.popup({
                        connectorInfo: 'auth=' + localStorage.jwt,
                        chooseFiles: true,
                        width: 1014,
                        height: 700,
                        onInit: finder => {
                          finder.on('files:choose', e => {
                            this.onChangeContent(e.data.files.first().getUrl());
                            this.updateColumn();
                          });
                        }
                      })
                    }}
                  />
                )
                :
                <span onClick={this.onClick}>
                  {editing
                    ? editor
                    : saving
                      ? <LoadingSpinner width={21} classNames="my-0"/>
                      : (searchInStructure.length > 0 ? columnContent : reactHtmlParser(columnContent))
                  }
                </span>
              }
            </li>

            {question.randColumns > 0 && <li className={"mr-2 d-none d-lg-inline" + (plan.survey.questionSettings.randCafetery ? "" : " not-active")}><ColumnRandPriority disabled={!plan.survey.questionSettings.randCafetery} column={this.props.column} changeOption={this.changeOption}/></li>}
            {question.randColumns > 0 && <li className="mr-2 d-none d-lg-inline"><ButtonExcludedFromRand disabled={!plan.survey.questionSettings.randCafetery} column={this.props.column} toggleOption={this.toggleOption} excludedFromRand={this.state.column.excludedFromRand}/></li>}
            {question.rotateColumns === true && <li className="mr-2 d-none d-lg-inline"><ButtonExcludedFromRotate column={this.props.column} toggleOption={this.toggleOption} excludedFromRotate={this.state.column.excludedFromRotate}/></li>}
            {question.requiredColumns === true && <li className="mr-2 d-none d-lg-inline"><ButtonExcludeRequired column={this.props.column} toggleOption={this.toggleOption} excludeRequired={this.state.column.excludeRequired}/></li>}
            {question.type === 'matrixMulti' && <li className="mr-2 d-none d-lg-inline"><ButtonBlocking column={this.props.column} toggleOption={this.toggleOption} blocking={this.state.column.blocking}/></li>}
            <li className="mr-2 d-none d-lg-inline"><ButtonOpenColumn column={this.props.column} toggleOption={this.toggleOption} isOpen={this.state.column.isOpen}/></li>
            {this.state.column.isOpen && <li className="mr-2 d-none d-lg-inline"><ButtonOpenIsRequired row={this.props.column} toggleOption={this.toggleOption} openIsRequired={this.state.column.openIsRequired}/></li>}
            {!question.columnsImage && <li className="ml-2 d-none d-lg-inline"><CafeteryEditModal type="column" id={this.props.column.id} content={this.props.column.content} onSave={content => this.changeOption('content', content)} /></li>}


            {plan.survey.cafeteryDisable && <li className="mr-2 d-none d-lg-inline"><ButtonDisabled column={this.props.column} toggleOption={this.toggleOption} disabled={this.props.column.disabled} /></li>}
            <li className="ml-2 d-print-none">
              <Dropdown isOpen={this.state.dropdownQuestionTypeOpen} toggle={e => this.toggle('dropdownQuestionTypeOpen', e)}>
                <DropdownToggle className="m-0 p-0" color="">
                  <span className="fas fa-ellipsis-v hoverable" />
                </DropdownToggle>
                {this.state.dropdownQuestionTypeOpen && <DropdownMenu>
                  {question.randColumns > 0 && <DropdownItem tag="a" className="d-lg-none text-center">
                    <InputNumber className="mb-0" value={this.state.column.randPriority} onChange={value => this.changeOption('randPriority', value)} id={'columnRandPriority-' + this.props.column.id}/>
                    <FormattedMessage tagName="h5" id="column.columnRandPriority.button.tooltip" />
                  </DropdownItem>}

                  {question.randColumns > 0 && <DropdownItem tag="a" className={"d-lg-none" + (this.state.column.excludedFromRand ? " text-primary" : "")}  onClick={() => plan.survey.questionSettings.randCafetery ? this.toggleOption('excludedFromRand') : setPlanRestrictionsModals(QuestionSettingAvailable.getName())}>
                    <p><span className={classnames({
                      "fas fa-eye": true,
                      "text-primary": this.state.column.excludedFromRand,
                      "not-active": !plan.survey.questionSettings.randCafetery
                    })}/></p> <FormattedMessage tagName="h5" id="column.buttonExcludedFromRand.button.tooltip" />
                  </DropdownItem>}

                  {question.rotateColumns === true && <DropdownItem tag="a" className={"d-lg-none" + (this.state.column.excludedFromRotate ? " text-primary" : "")} onClick={() => this.toggleOption('excludedFromRotate')}>
                    <p><span className={"fas fa-random" + (this.state.column.excludedFromRotate ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="columns.excludedFromRotate.button.tooltip" />
                  </DropdownItem>}

                  {question.type === 'matrixMulti' && <DropdownItem tag="a" className={"d-lg-none" + (this.state.column.blocking ? " text-primary" : "")} onClick={() => this.toggleOption('blocking')}>
                    <p><span className={"fa-solid fa-eraser" + (this.state.column.blocking ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="columns.blocking.button.tooltip" />
                  </DropdownItem>}

                  <DropdownItem tag="a" className={"d-lg-none" + (this.state.column.isOpen ? " text-primary" : "")} onClick={() => this.toggleOption('isOpen')}>
                    <p><span className={"fa-solid fa-keyboard" + (this.state.column.isOpen ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="columns.isOpen.button.tooltip" />
                  </DropdownItem>

                  <DropdownItem tag="a" onClick={() => this.setState({addNewColumnBelow: !this.state.addNewColumnBelow})}>
                    <p><span className="fas fa-plus"/></p> <FormattedMessage tagName="h5" id="column.addNew.button" />
                  </DropdownItem>

                  <DropdownItem tag="a" onClick={(e) => this.toggle('modalDelete', e)}>
                    <p><span className="fas fa-trash"/></p> <FormattedMessage tagName="h5" id="column.modalDelete.button" />
                  </DropdownItem>
                </DropdownMenu>}
              </Dropdown>
            </li>
          </ul>

          {question.columnsImage && <ColumnDescription columnId={this.props.column.id}/>}

          {/*{position !== 0 && <ButtonMovePosition column={this.props.column} question={question} position={position} move={1}/>}*/}
          {/*{position !== question.columns.length - 1 && <ButtonMovePosition column={this.props.column} question={question} position={position} move={-1}/>}*/}

        </div>
        {addNewColumnBelow && <QuestionColumnNew autoFocus={true} handlerAdded={() => this.setState({addNewColumnBelow: false})} position={position+1} question={question} />}
        {this.state.modalDelete && <ModalDelete handleDelete={this.deleteColumn} id={this.props.column.id} isOpen={this.state.modalDelete} toggleModal={(e) => this.toggle('modalDelete', e)}/>}
      </React.Fragment>
    )
  }
}

QuestionColumn.propTypes = {
  column: PropTypes.shape({
    content: PropTypes.string,
    description: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    excludeRequired: PropTypes.bool.isRequired,
  }).isRequired,
  position: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    randColumns: PropTypes.number.isRequired,
    rotateColumns: PropTypes.bool.isRequired,
    requiredColumns: PropTypes.bool.isRequired,
  }).isRequired,
  handlerChecked: PropTypes.func,
  searchInStructure: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  return {
    column: state.survey.structure.data.columns[props.column.id],
    hideInStructure: state.survey.structure.hideInStructure,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {deleteColumn, updateColumn, setPlanRestrictionsModals})(QuestionColumn);
