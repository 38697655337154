import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FilterEditModal from "../../../../Filters/components/FilterEditModal";
import _, {forEach, isArray} from "lodash";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {
  addFilter,
  deleteFilter
} from "../../../../../../../../../../../../../../../../../../../../../data/filters/actions";
import Tooltip from "../../../../../../../../../../../../../../../../../../../../../../../../components/Tooltip";
import {Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";

const AnswerFilterScreenout = props => {
  const filterRef = React.createRef();
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [modalRemoveFilterOpen, setModalRemoveFilterOpen] = React.useState(false);
  const toggleModalRemoveFilterOpen = () => setModalRemoveFilterOpen(!modalRemoveFilterOpen);
  const [saving, setSaving] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const getQuestionPositionInSurvey = () => {
    let positionInSurvey = 0;

    try {
      _.each(props.survey.setBlocks, setBlockId => {
        _.each(props.sets[setBlockId].blockPages, blockId => {
          _.each(props.blocks[blockId].pages, pageId => {
            _.each(props.pages[pageId].questions, questionId => {
              positionInSurvey++;

              if(props.answer.question.id === questionId) {
                throw 'Break';
              }
            })
          });
        });
      });
    } catch(e){ }

    return positionInSurvey;
  }

  const getFilterObject = () => {
    return {
      name: 'Screenout: jeśli w p'+getQuestionPositionInSurvey()+' wybierze jedną z odpowiedź/i: ' + (parseInt(props.answer.position)+1),
      blocks: [
        {
          children: [],
          condsQuestion: [
            {
              answers: [
                {
                  answer: props.answer.id,
                }
              ],
              baskets: [],
              check: "detail",
              columns: [],
              detailType: "selected",
              question: props.answer.question.id,
              rows: [],
            }
          ]
        }
      ],
      actions: [
        {
          actionType: 'actionJump',
          status: 4,
          type: 'surveyEnd',
        }
      ]
    }
  }

  const getExistingFilterId = () => {
    let filterId = false;

    try {
      _.each(props.filters, filter => {
        _.each(filter.blocks, block => {
          _.each(block.condsQuestion, condQuestion => {
            if(condQuestion.question === props.answer.question.id){
              if('answers' in condQuestion && condQuestion.answers.find(a => a.answer === props.answer.id)){
                filterId = filter.id;
                throw 'Break';
              }
            }
          })
        })
      })
    }catch(e) {}

    return filterId;
  }

  const saveFilter = () => {
    const {survey} = props;
    const filter = filterRef.current.getFilter();

    setSaving(true);

    let data = {
      filter: {
        id: filter.id,
        name: filter.name,
        survey: survey.id,
        blocks: filter.blocks,
        actionsVisibility: filter.actions.filter(action => action.actionType === 'actionVisibility'),
        actionsJump: filter.actions.filter(action => action.actionType === 'actionJump')
      }
    };

    props.addFilter(data)
      .then(() => {
        setSaving(false);
        setModalOpen(false)
      })
      .catch(error => {
        const errors = error.response.data.form.errors;
        forEach(errors, error => {
          if(isArray(error)){
            forEach(error, e => toast.error(e));
          }else{
            toast.error(error);
          }
        });

        setSaving(false)
      });
  }

  const filterDelete = () => {
    const filterId = getExistingFilterId();
    setDeleting(true);

    props.deleteFilter({
      id: filterId
    }).then(() => {
      setModalRemoveFilterOpen(false)
      setDeleting(false)
    });
  }

  return (
    <React.Fragment>
      <i id={`answer-filter-screenout-${props.answer.id}`} onClick={() => {
        if(getExistingFilterId() !== false){
          return toggleModalRemoveFilterOpen()
        }

        return toggleModalOpen();
      }} className={classnames({
        'fa-solid fa-user-large-slash pointer': true,
        'text-primary': getExistingFilterId() !== false,
      })} />
      <Tooltip id={`answer-filter-screenout-${props.answer.id}`} msg={'Filtr z akcją screenout od tej odpowiedzi'} />
      {modalOpen && <FilterEditModal
        isOpen
        saving={saving}
        filter={getFilterObject()}
        toggleModal={toggleModalOpen}
        onSave={saveFilter}
        ref={filterRef}
      />}
      {modalRemoveFilterOpen && <Modal isOpen className={'modal-danger'}>
        <ModalHeader tag="h2">Usuń filtr</ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <p>Czy na pewno usunąć filtr?</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModalRemoveFilterOpen}>Anuluj</Button>
          <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={() => filterDelete()}
          >Usuń filtr</LaddaButton>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

AnswerFilterScreenout.propTypes = {

};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
    sets: state.survey.structure.data.sets,
    blocks: state.survey.structure.data.blocks,
    pages: state.survey.structure.data.pages,
    filters: state.survey.structure.data.filters,
  }
}

export default connect(mapStateToProps, {addFilter, deleteFilter})(AnswerFilterScreenout);
