import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Alert} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "components/PlanRestrictionsModals/modals/MultimediaAvailable";

import {replaceAnswers} from "scenes/Survey/Structure/data/questions/actions";
import Switch from "../../../../../../../../../../../../../../../../../../../../../../../../../components/Switch";

class ActionReplace extends Component {

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      modal: false,
      saving: false,
      changeable: false,
    };

    this.onChangeText = this.onChangeText.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
    this.setState({ modal: !this.state.modal });
  }

  onChangeText(text){
    this.setState({
      text
    })
  }

  saveAnswers(){
    const {text, saving} = this.state;
    const {question} = this.props;

    if(_.isEmpty(text) || saving){
      return;
    }

    let answers = [];

    text.split("\n").map(t => {
      if(t.trim()) {
        answers.push({
          content: t
        });
      }
    });

    this.setState({
      saving: true
    });
    this.props.replaceAnswers(question.id, answers, this.state.changeable)
    .then(() => {
      this.setState({
        text: '',
        saving: false,
        modal: false,
      });
    })
    .catch(res => {
      this.setState({
        saving: false,
      })
    });
  }

  render() {
    const {question, plan} = this.props;
    const {text, saving, changeable} = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="answers.actions.replace.cardHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="answers.actions.replace.cardBody"/></p>
        <Form>
          <FormGroup>
            <Input
              type="textarea"
              rows={10}
              onChange={e => this.onChangeText(e.target.value)}
              value={text}
              className="custom"
            />
          </FormGroup>
        </Form>

        {question.answersImage && <Button
          onClick={() => {
            if (!plan.multimedia.access) {
              this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
              return;
            }
            CKFinder.popup({
              connectorInfo: 'auth=' + localStorage.jwt,
              chooseFiles: true,
              width: 1014,
              height: 700,
              onInit: finder => {
                finder.on('files:choose', e => {

                  let content = [];
                  e.data.files.forEach( function( file ) {
                    content.push(file.get( 'url' ));
                  } );
                  this.onChangeText(content.join("\n"));
                  this.saveAnswers();
                });
              }
            })
          }}
        >Wybierz zdjęcia z biblioteki</Button>}

        <FormGroup className="mt-2">
          <Switch checked={changeable} onChange={changeable => {
            this.setState({
              changeable,
            })
          }} />
          <span className="ml-2">Dodaj/usuń odpowiedzi</span>
        </FormGroup>
        {changeable && <Alert color="warning">
          <p>Dodaj/usuń odpowiedzi w przypadku, gdy nowa lista różni się od oryginału pod względem ilości odpowiedzi.</p>
          <p className="mb-0">UWAGA: Zmiana może wpłynąć na już zebrane odpowiedzi w tym pytaniu.</p>
        </Alert>}
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="answers.actions.replace.button.confirmation">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={() => this.saveAnswers()}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button onClick={this.toggleModal}><FormattedMessage id="answers.actions.replace.button.replace"/></Button>
        {this.state.modal && modal}
      </React.Fragment>
    )
  }
}

ActionReplace.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answersImage: PropTypes.bool,
  }).isRequired
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {replaceAnswers, setPlanRestrictionsModals})(ActionReplace);