import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {ReactSortable} from "react-sortablejs";
import _ from 'lodash';
import {Row, Col} from "reactstrap";
import ScalePointNew from './components/ScalePointNew';
import ScalePoint from './components/ScalePoint';
import ScalePointsActions from './components/Actions';
import {updateScalePoint, scalePointUpdated} from "scenes/Survey/Structure/data/scalePoints/actions";
import {setScalePoints} from "scenes/Survey/Structure/data/questions/actions";
import './style.scss'
import classnames from "classnames";

class ScalePoints extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hideScalePoints: false,
      checkedScalePoints: []
    };

    this.onUpdate = this.onUpdate.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
  }

  onUpdate(evt){
    let {scalePoints} = this.props.question;

    const id = parseInt(evt.item.attributes['data-id'].nodeValue);
    scalePoints.splice(evt.oldIndex, 1);
    scalePoints.splice(evt.newIndex, 0, id);
    this.props.setScalePoints(this.props.question.id, scalePoints);

    let position = 0;
    this.props.scalePoints.map(scalePoint => {
      scalePoint.position = position;
      this.props.scalePointUpdated(scalePoint);
      position++;
    });

    this.props.updateScalePoint({
        id,
        scalePoint: {
          position: evt.newIndex
        }
    });
  }

  toggleChecked(scalePointId, checked){
    let {checkedScalePoints} = this.state;

    if(checked){
      checkedScalePoints.push(scalePointId);
    }else{
      checkedScalePoints = checkedScalePoints.filter(id => id !== scalePointId)
    }

    this.setState({
      checkedScalePoints
    });
  }

  toggleScalePoints(){
    this.setState({
      hideScalePoints: !this.state.hideScalePoints
    })
  }

  render() {
    const {scalePoints, question} = this.props;
    const {hideScalePoints, checkedScalePoints} = this.state;

    return (
      <Row className="mt-3">
        <Col>
          <h4>
            <span onClick={()=> this.toggleScalePoints()}><FormattedMessage id="question.scalePoints.listGroup.header" /> ({ scalePoints.length })</span>
            <div className='d-inline mx-2' key={Math.random()} onClick={()=> this.toggleScalePoints()}>
              <i className={hideScalePoints ? 'fas fa-angle-right' : 'fas fa-angle-down'}/>
            </div>
          </h4>

          {!hideScalePoints && <div className="ml-lg-4">
            <ReactSortable
              list={scalePoints}
              setList={() => {}}
              group={{
                name: 'scalePoints-' + question.id,
              }}
              tag="ul"
              onUpdate={this.onUpdate}
              animation={100}
              filter={".sortable-ignore"}
              draggable={".sortable-draggable"}
              handle={".handler-drag-position"}
              className="scalePoints-list list-group"
            >
              {scalePoints.map((scalePoint, position)=> {
                return (
                  <ScalePoint
                    scalePoint={scalePoint}
                    handlerChecked={(checked) => this.toggleChecked(scalePoint.id, checked)}
                    position={scalePoint.position}
                  />
                )
              })}
            </ReactSortable>
            <ScalePointNew question={question} handlerAdding={this.addingInc} handlerAdded={this.addingDec} autoFocus={false}/>
          </div>}

          {!hideScalePoints && <ScalePointsActions checkedScalePoints={checkedScalePoints} scalePoints={scalePoints} question={question}/>}
        </Col>
      </Row>
    )
  }
}

ScalePoints.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    scalePoints: PropTypes.array.isRequired
  }).isRequired
};

function mapStateToProps(state, props) {
  return {
    scalePoints: state.survey.structure.data.scalePoints ? _.map(props.question.scalePoints, id => state.survey.structure.data.scalePoints[id]) : [],
  }
}

export default connect(mapStateToProps, {setScalePoints, updateScalePoint, scalePointUpdated})(ScalePoints);