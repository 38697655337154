import React, {Component} from 'react';
import PropTypes from "prop-types";
import Select from "react-select";
import SelectQuestions from 'scenes/Survey/Structure/components/SelectQuestions';
import {FormattedMessage} from "react-intl";

class QuestionFromSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionFrom: props.filter.questionFrom ? props.questions.find(question => question.id === props.filter.questionFrom.question) : null,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const question = this.props.questions.find(question => question.id === e.value);
    const availableFilterBys = ['answers', 'rows', 'columns'];
    let filterBy;

    _.each(availableFilterBys, key => {
      if (filterBy) {
        return false;
      }
      if (question.hasOwnProperty(key)) {
        filterBy = key;
      }
    });

    this.props.onChange({
      question: e.value,
      filterBy
    });

    this.setState({
      questionFrom: question
    })
  }

  render() {
    return (
      <Select
        isMulti={false}
        value={this.state.questionFrom ? {
          label: SelectQuestions.questionLabelStatic(this.state.questionFrom),
          value: this.state.questionFrom.id
        } : null}
        onChange={this.onChange}
        options={this.props.questions.filter(question => question.type === 'single').map(question => {
          return {
            label: SelectQuestions.questionLabelStatic(question),
            value: question.id,
          }
        })}
        className="mb-3"
      />
    )
  }
}

QuestionFromSelect.propTypes = {
  filter: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default QuestionFromSelect;
