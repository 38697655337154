import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input} from "reactstrap";
import ModalDelete from "./components/ModalDelete";
import ButtonDrag from "./components/ButtonDrag";
import LoadingSpinner from "components/LoadingSpinner";
import QuestionRowNew from "../RowNew";
import {deleteRow, updateRow} from "scenes/Survey/Structure/data/rows/actions";
import reactStringReplace from "react-string-replace";
import reactHtmlParser from "react-html-parser";
import {FormattedMessage} from "react-intl";
import Checkbox from "components/Checkbox";
import ButtonExcludedFromRotate from "./components/ButtonExcludedFromRotate";
import ButtonExcludedFromRand from "./components/ButtonExcludedFromRand";
import ButtonBlocking from "./components/ButtonBlocking";
import getCKConfigByPlan from 'utils/ckeditor/cafeteryConfig';
import RowDescription from "./components/RowDescription";
import ButtonOpenRow from "./components/ButtonOpenRow";
import RowRandPriority from "./components/RowRandPriority";
import {isMobile, isTablet} from "react-device-detect";
import ButtonExcludeRequired from "./components/ButtonExcludeRequired";
import InputNumber from "components/InputNumber";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "components/PlanRestrictionsModals/modals/MultimediaAvailable";
import classnames from "classnames";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";
import ButtonOpenIsRequired from "./components/ButtonOpenIsRequired";
import ButtonOpenIsNotRequired from "../../../Answers/components/Answer/components/ButtonOpenIsNotRequired";
import CafeteryEditModal from "../../../CafeteryEditModal";
import ButtonDisabled from "./components/ButtonDisabled";
import HtmlEditor
  from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";

class QuestionRow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownSettingsOpen: false,
      deleteButton: false,
      modalDelete: false,
      deleting: false,
      editing: false,
      saving: false,
      addNewRowBelow: false,
      hovered: false,
      row: {
        content: props.row.content,
        excludedFromRand: props.row.excludedFromRand,
        excludedFromRotate: props.row.excludedFromRotate,
        blocking: props.row.blocking,
        isOpen: props.row.isOpen,
        openIsRequired: props.row.openIsRequired,
        randPriority: props.row.randPriority
      },
    };

    this.deleteRow = this.deleteRow.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setHovered = this.setHovered.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      ...this.state,
      row: {
        ...this.state.row,
        content: nextProps.row.content
      }
    })
  }

  onClick(){
    this.setState({editing: true});
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.updateRow()
  }

  onChangeContent(content){
    const {saving} = this.state;

    if(!saving) {
      this.setState({
        row: {
          ...this.state.row,
          content
        }
      });
    }
  }

  updateRow(){
    const {row} = this.state;

    if (row.content.length === 0) {
      this.setState({row: {...this.state.row, content: this.props.row.content}});
      return;
    }

    this.setState({
      saving: true,
      editing: false
    });

    if(row.content.length === 0)
        return false;

    const data = {
        id: this.props.row.id,
        row: {
            content: row.content
        }
    };

    this.props.updateRow(data).then(() => this.setState({saving: false}));
  }

  deleteRow(){
    this.setState({
      deleting: true
    });

    if(!this.state.deleting){
      this.props.deleteRow({
        id: this.props.row.id
      })
    }
  }

  toggleOption(option) {
    this.setState({
      row: {
        ...this.state.row,
        [option]: !this.state.row[option]
      }
    }, () => {
      const data = {
        id: this.props.row.id,
        row: {
          [option]: this.state.row[option]
        }
      };

      this.props.updateRow(data);
    });
  }

  changeOption(option, value) {
    this.setState({
      row: {
        ...this.state.row,
        [option]: value
      }
    }, () => {
      const data = {
        id: this.props.row.id,
        row: {
          [option]: value
        }
      };

      this.props.updateRow(data);
    });
  }

  toggle(type, e = {}) {
    if (e.target.className.includes("value-button"))
      return;

    this.setState({
      [type]: !this.state[type]
    })
  }

  setHovered(hovered) {
    this.setState({hovered});
  }

  render() {
    const {position, question, searchInStructure, hideInStructure, plan, checked} = this.props;
    const {row, deleting, addNewRowBelow, editing, saving, hovered} = this.state;
    const ctrlEnterKeyCode = 1114125;
    const gripStyle = hovered ? {opacity: 1} : {opacity: 0};
    let editor;

    if (isMobile || isTablet) {
      editor = <Input value={row.content} onChange={e => this.onChangeContent(e.target.value)} onBlur={this.updateRow} autoFocus={true} onKeyPress={this.handleKeyPress}/>
    } else {
      editor = <HtmlEditor
        data={row.content}
        config={getCKConfigByPlan(plan)}
        type="inline"
        onChange={event => this.onChangeContent(event)}
        onBlur={event => {
          this.updateRow();
          this.setState({editing: false})
        }}
        onKey={event => {
          if (event.data.keyCode === ctrlEnterKeyCode) {
            event.cancel();
            this.updateRow();
            setTimeout(() => {
              event.editor.destroy();
              this.setState({editing: false})
            }, 1);
          }
        }}
      />
    }

    let style = {
      position: 'relative'
    };

    let contentStyle = hideInStructure === 'minified' ? {
      width: '75%',
      display: 'list-item',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    } : {wordBreak: 'break-all'};

    if(deleting) style.opacity = 0.35;

    let rowContent = row.content;
    if (searchInStructure.length > 0) {
      rowContent = reactStringReplace(rowContent, searchInStructure, (match, i) => (
        <span className="bg-warning" key={i}>{match}</span>
      ));
    }

    return (
      <React.Fragment>
        <div className="sortable-draggable line-wrap" style={style} data-id={this.props.row.id} onMouseEnter={_ => this.setHovered(true)} onMouseLeave={_ => this.setHovered(false)}>
          <ButtonDrag id={this.props.row.id} gripStyle={gripStyle}/>

          <ul className="mb-0 d-flex pl-2">
            <li className="d-none d-lg-inline-block">
              <div className="form-group m-0 overflow-hidden">
                <Checkbox checked={checked} id={'row-' + this.props.row.id + '-check'} onClick={() => this.props.handlerChecked(!checked)} messageId={"checkbox.check.row"}/>
              </div>
            </li>
            <li>{position + 1}.</li>

            <li className="flex-grow-1" style={contentStyle}>
              {question.rowsImage ?
                (
                  <img
                    alt={row.content}
                    style={{
                      maxHeight: '70px'
                    }}
                    src={row.content || '/dashboard/img/no-image.png'}
                    onClick={() => {
                      if (!plan.multimedia.access) {
                        this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
                        return;
                      }
                      CKFinder.popup({
                        connectorInfo: 'auth=' + localStorage.jwt,
                        chooseFiles: true,
                        width: 1014,
                        height: 700,
                        onInit: finder => {
                          finder.on('files:choose', e => {
                            this.onChangeContent(e.data.files.first().getUrl());
                            this.updateRow();
                          });
                        }
                      })
                    }}
                  />
                )
                :
                <span onClick={this.onClick}>
                  {editing
                    ? editor
                    : saving
                      ? <LoadingSpinner width={21} classNames="my-0"/>
                      : (searchInStructure.length > 0 ? rowContent : reactHtmlParser(rowContent))
                  }
                </span>
              }
            </li>

            {question.randRows > 0 && <li className={"mr-2 d-none d-lg-inline" + (plan.survey.questionSettings.randCafetery ? "" : " not-active")}><RowRandPriority disabled={!plan.survey.questionSettings.randCafetery} row={this.props.row} changeOption={this.changeOption}/></li>}
            {question.randRows > 0 && <li className="mr-2 d-none d-lg-inline"><ButtonExcludedFromRand disabled={!plan.survey.questionSettings.randCafetery} row={this.props.row} toggleOption={this.toggleOption} excludedFromRand={this.state.row.excludedFromRand}/></li>}
            {question.rotateRows === true && <li className="mr-2 d-none d-lg-inline"><ButtonExcludedFromRotate row={this.props.row} toggleOption={this.toggleOption} excludedFromRotate={this.state.row.excludedFromRotate}/></li>}
            {question.requiredRows === true && <li className="mr-2 d-none d-lg-inline"><ButtonExcludeRequired row={this.props.row} toggleOption={this.toggleOption} excludeRequired={this.state.row.excludeRequired}/></li>}
            {question.type === 'matrixMulti' && <li className="mr-2 d-none d-lg-inline"><ButtonBlocking row={this.props.row} toggleOption={this.toggleOption} blocking={this.state.row.blocking}/></li>}
            <li className="mr-2 d-none d-lg-inline"><ButtonOpenRow row={this.props.row} toggleOption={this.toggleOption} isOpen={this.state.row.isOpen}/></li>
            {this.state.row.isOpen && <li className="mr-2 d-none d-lg-inline"><ButtonOpenIsRequired row={this.props.row} toggleOption={this.toggleOption} openIsRequired={this.state.row.openIsRequired}/></li>}
            {!question.rowsImage && <li className="ml-2 d-none d-lg-inline"><CafeteryEditModal type="row" id={this.props.row.id} content={this.props.row.content} onSave={content => this.changeOption('content', content)} /></li>}


            {plan.survey.cafeteryDisable && <li className="mr-2 d-none d-lg-inline"><ButtonDisabled row={this.props.row} toggleOption={this.toggleOption} disabled={this.props.row.disabled}/></li>}
            <li className="ml-2 d-print-none">
              <Dropdown isOpen={this.state.dropdownQuestionTypeOpen} toggle={e => this.toggle('dropdownQuestionTypeOpen', e)}>
                <DropdownToggle className="m-0 p-0" color="">
                  <span className="fas fa-ellipsis-v hoverable" />
                </DropdownToggle>
                {this.state.dropdownQuestionTypeOpen && <DropdownMenu>
                  {question.randRows > 0 && <DropdownItem tag="a" className="d-lg-none text-center">
                    <InputNumber className="mb-0" value={this.state.row.randPriority} onChange={value => this.changeOption('randPriority', value)} id={'rowRandPriority-' + this.props.row.id}/>
                    <FormattedMessage tagName="h5" id="row.rowRandPriority.button.tooltip" />
                  </DropdownItem>}

                  {question.randRows > 0 && <DropdownItem tag="a" className={"d-lg-none" + (this.state.row.excludedFromRand ? " text-primary" : "")}  onClick={() => plan.survey.questionSettings.randCafetery ? this.toggleOption('excludedFromRand') : setPlanRestrictionsModals(QuestionSettingAvailable.getName())}>
                    <p><span className={classnames({
                      "fas fa-eye": true,
                      "text-primary": this.state.row.excludedFromRand,
                      "not-active": !plan.survey.questionSettings.randCafetery
                    })}/></p> <FormattedMessage tagName="h5" id="row.buttonExcludedFromRand.button.tooltip" />
                  </DropdownItem>}

                  {question.rotateRows === true && <DropdownItem tag="a" className={"d-lg-none" + (this.state.row.excludedFromRotate ? " text-primary" : "")} onClick={() => this.toggleOption('excludedFromRotate')}>
                    <p><span className={"fas fa-random" + (this.state.row.excludedFromRotate ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="rows.excludedFromRotate.button.tooltip" />
                  </DropdownItem>}

                  {question.type === 'matrixMulti' && <DropdownItem tag="a" className={"d-lg-none" + (this.state.row.blocking ? " text-primary" : "")} onClick={() => this.toggleOption('blocking')}>
                    <p><span className={"fa-solid fa-eraser" + (this.state.row.blocking ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="rows.blocking.button.tooltip" />
                  </DropdownItem>}

                  <DropdownItem tag="a" className={"d-lg-none" + (this.state.row.isOpen ? " text-primary" : "")} onClick={() => this.toggleOption('isOpen')}>
                    <p><span className={"fa-solid fa-keyboard" + (this.state.row.isOpen ? " text-primary" : "")}/></p> <FormattedMessage tagName="h5" id="rows.isOpen.button.tooltip" />
                  </DropdownItem>

                  <DropdownItem tag="a" onClick={() => this.setState({addNewRowBelow: !this.state.addNewRowBelow})}>
                    <p><span className="fas fa-plus"/></p> <FormattedMessage tagName="h5" id="row.addNew.button" />
                  </DropdownItem>

                  <DropdownItem tag="a" onClick={(e) => this.toggle('modalDelete', e)}>
                    <p><span className="fas fa-trash"/></p> <FormattedMessage tagName="h5" id="row.modalDelete.button" />
                  </DropdownItem>
                </DropdownMenu>}
              </Dropdown>
            </li>
          </ul>

          {question.rowsImage && <RowDescription rowId={this.props.row.id}/>}

          {/*{position !== 0 && <ButtonMovePosition row={this.props.row} question={question} position={position} move={1}/>}*/}
          {/*{position !== question.rows.length - 1 && <ButtonMovePosition row={this.props.row} question={question} position={position} move={-1}/>}*/}

        </div>
        {addNewRowBelow && <QuestionRowNew autoFocus={true} handlerAdded={() => this.setState({addNewRowBelow: false})} position={position+1} question={question} />}
        {this.state.modalDelete && <ModalDelete handleDelete={this.deleteRow} id={this.props.row.id} isOpen={this.state.modalDelete} toggleModal={(e) => this.toggle('modalDelete', e)}/>}
      </React.Fragment>
    )
  }
}

QuestionRow.propTypes = {
  row: PropTypes.shape({
    content: PropTypes.string,
    description: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    openIsRequired: PropTypes.bool.isRequired,
    excludeRequired: PropTypes.bool.isRequired,
  }).isRequired,
  position: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    randRows: PropTypes.number.isRequired,
    rotateRows: PropTypes.bool.isRequired,
    rowsImage: PropTypes.bool.isRequired,
    requiredRows: PropTypes.bool.isRequired,
  }).isRequired,
  handlerChecked: PropTypes.func,
  searchInStructure: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};


function mapStateToProps(state, props) {
  return {
    row: state.survey.structure.data.rows[props.row.id],
    hideInStructure: state.survey.structure.hideInStructure,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {deleteRow, updateRow, setPlanRestrictionsModals})(QuestionRow);
