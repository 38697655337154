import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup, Button} from 'reactstrap';
import PrizeDeleteButton from './PrizeDeleteButton';
import appRoutes from 'appRoutes';
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";

class PrizesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
    this.actions = this.actions.bind(this);
  }

  fetchData(state) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.prize.post.searchPrizes(data).then(res => {
      this.setState({searching: false, data: res.prizes, count: res.count});
    });
  }

  actions(prize){
    const handler = () => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});
    return (
      <ButtonGroup>
        <Link to={appRoutes.admin.prizes.edit(prize.id)}>
          <Button color="primary" size="sm">
            <i className="fas fa-edit" />
            <FormattedMessage id="_.button.edit" />
          </Button>
        </Link>
        <PrizeDeleteButton prize={prize} handleChange={handler}/>
      </ButtonGroup>
    )
  }

  status(prize) {
    const type = prize.active ? 'success' : 'danger';
    const text = prize.active ? <FormattedMessage id="_.label.active"/> : <FormattedMessage id="_.label.paused"/>;

    return <label className={`label-status ${type} ml-2`}>{text}</label>
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="admin.prizesList.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="admin.prizesList.tableHeader.name" />,
      accessor: 'name'
    }, {
      id: 'active',
      Header: <FormattedMessage id="admin.prizesList.tableHeader.active" />,
      accessor: prize => this.status(prize),
    }, {
      Header: <FormattedMessage id="admin.prizesList.tableHeader.type" />,
      accessor: 'type',
      sortable: false
    }, {
      Header: <FormattedMessage id="admin.prizesList.tableHeader.cost" />,
      accessor: 'cost'
    }, {
      Header: <FormattedMessage id="admin.prizesList.tableHeader.stock" />,
      accessor: 'stock'
    }, {
      id: 'actions',
      Header: <FormattedMessage id="admin.prizesList.tableHeader.actions" />,
      accessor: prize => this.actions(prize),
      sortable: false
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.prizesList.table.noPrizes" />}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

export default PrizesList;