import React, {Component} from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {updateSurvey} from "scenes/Survey/Structure/data/survey/actions";
import {Button, UncontrolledTooltip} from "reactstrap";
import {toast} from "react-toastify";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import SurveyAccess from "components/PlanRestrictionsModals/modals/SurveyAccess";
import moment from "moment";
import Switch from "../../../components/Switch";
import history from "../../../services/history";
import appRoutes from "../../../appRoutes";
import {isEmpty} from "lodash";

class SurveysButtonPaused extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      paused: props.paused,
    };

    this.isDisabled = this.isDisabled.bind(this);
    this.togglePaused = this.togglePaused.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      paused: nextProps.paused,
    })
  }

  isDisabled() {
    if(!this.props.hasAccess){
      return true;
    }

    if(!isEmpty(this.props.user.userGroups) && !this.props.user.userGroups[0].allowSurveyStart){
      return true;
    }

    return false;
  }

  togglePaused() {
    const {loading, paused} = this.state;

    if(loading){
      return;
    }

    this.setState({
      loading: true,
    });

    this.props.updateSurvey({
      id: this.props.survey.id,
      survey: {
        paused: !paused
      }
    }).then(survey => {
      this.setState({
        loading: false,
        paused: survey.paused,
      });

      if (this.props.handleSurveyChange)
        this.props.handleSurveyChange(survey.id, 'paused', survey.paused);
    }).catch(res => {
      this.setState({
        loading: false,
      });
      if(res.response.data.form.errors){
        _.map(res.response.data.form.errors, (errors) => {
          toast.error(errors.join(<br />));
        })
      }
    })
  }

  render() {
    const {survey} = this.props;
    const {loading, paused} = this.state;

    return <Switch
      disabled={this.isDisabled()}
      className="mb-0"
      checked={!paused}
      onChange={() => this.props.hasAccess ? this.togglePaused() : this.props.setPlanRestrictionsModals(SurveyAccess.getName())}
    />;
  }
}

SurveysButtonPaused.propTypes = {
  paused: PropTypes.bool.isRequired,
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    paused: PropTypes.bool.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  handleSurveyChange: PropTypes.func,
};

function mapStateToProps(state, props) {
  return {
    hasAccess: state.user.userPlan.plan.survey.accessDaysAfterCreation === 0 || moment().diff(moment(props.survey.createdAt), 'days') <= state.user.userPlan.plan.survey.accessDaysAfterCreation,
    user: state.user,
  }
}

export default connect(mapStateToProps, {updateSurvey, setPlanRestrictionsModals})(SurveysButtonPaused);