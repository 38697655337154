import React, {Component} from 'react';
import PropTypes from "prop-types";

class Basket extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {details} = this.props;

    return (
      <ul>
        {details.map((detail, i) => {
          return <li key={i}><span dangerouslySetInnerHTML={{__html: detail.answer.content}} className="d-inline-block img-30"/>: <span dangerouslySetInnerHTML={{__html: detail.basket.content}}/></li>
        })}
      </ul>
    )
  }
}

Basket.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    basket: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }).isRequired,
    answer: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }).isRequired,
  }),).isRequired,
};

export default Basket;