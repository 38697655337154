import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash";
import SurveysButtonPaused from "scenes/Surveys/components/SurveysButtonPaused";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import SurveysModalAnalysis from "scenes/Surveys/components/Modals/SurveysModalAnalysis";
import appRoutes from "../../../../../../appRoutes";
import checkIsGroupRole from "../../../../../../utils/checkIsGroupRole";

class SurveyWrap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdown: false,
      modalAnalysis: false,
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind("shift+e", () => { this.toggleModalAnalysis(); });
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+e");
  }

  toggleModalAnalysis(){
    this.setState({
      modalAnalysis: !this.state.modalAnalysis
    })
  }

  toggleDropdown() {
    this.setState({dropdown: !this.state.dropdown})
  }

  render() {
    const {survey, user, userGroup} = this.props;

    return (
      <React.Fragment>
        {!_.isEmpty(survey) && <div className="d-flex float-left justify-content-start">
          <p className="pt-1 m-0">Ankieta: <SurveysButtonPaused paused={!!survey.paused} survey={survey} tooltip={true}/></p>

          {checkIsGroupRole('accessExportAnalysis', user, userGroup) && <Dropdown isOpen={this.state.dropdown} toggle={this.toggleDropdown} className="pl-1">
            <DropdownToggle className="m-0 p-0" color="">
              <span className="text-primary">{survey.name}</span> <span className="fas fa-caret-down" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.toggleModalAnalysis()} tag={"a"}>
                <p><i className="fas fa-download" /></p>
                <h5>Eksportuj dane</h5>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>}

          <SurveysModalAnalysis survey={survey} isOpen={this.state.modalAnalysis} toggleModal={() => this.toggleModalAnalysis()}/>
        </div>}
      </React.Fragment>
    );
  }
}

SurveyWrap.defaultProps = {
  survey: false
};

SurveyWrap.propTypes = {
  survey: PropTypes.shape({
    name: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    userGroup: state.user.userGroups,
    user: state.user,
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps)(SurveyWrap);