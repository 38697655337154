import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTable from "react-table";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import Pagination from "components/Pagination";
import SelectQuestions from "scenes/Survey/Structure/components/SelectQuestions";
import { connect } from 'react-redux';
import Select from 'react-select';
import {Button} from 'reactstrap';
import {deleteQuestionTo, addQuestionTo, updateQuestionTo} from "scenes/Survey/Structure/data/filterIterative/actions";

const availableFilterBys = ['answers', 'rows', 'columns'];
class QuestionsToList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      questionsTo: props.filter.questionsTo,
      adding: false,
      deleting: false
    };

    this.onQuestionChange = this.onQuestionChange.bind(this);
    this.onFilterByChange = this.onFilterByChange.bind(this);
    this.removeQuestionTo = this.removeQuestionTo.bind(this);
    this.filterBy = this.filterBy.bind(this);
    this.addQuestionTo = this.addQuestionTo.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({questionsTo: nextProps.filter.questionsTo});
  }

  onQuestionChange(questionTo, question) {
    const questionsTo = this.props.filter.questionsTo;
    const surveyQuestion = this.props.surveyQuestions[question];

    questionTo = _.find(questionsTo, stateQuestionTo => stateQuestionTo === questionTo);
    questionTo.question = question;

    if (!surveyQuestion.hasOwnProperty(questionTo.filterBy)) {
      const filterBy = _.find(availableFilterBys, key => surveyQuestion.hasOwnProperty(key));
      _.find(questionsTo, stateQuestionTo => stateQuestionTo === questionTo).filterBy = filterBy;
    }

    this.setState({questionsTo});
    this.props.updateQuestionTo({data: questionTo, filterIterativeId: this.props.filter.id});
  }

  onFilterByChange(questionTo, filterBy) {
    const questionsTo = this.props.filter.questionsTo;

    questionTo = _.find(questionsTo, stateQuestionTo => stateQuestionTo === questionTo);
    questionTo.filterBy = filterBy;

    this.setState({questionsTo});
    this.props.updateQuestionTo({data: questionTo, filterIterativeId: this.props.filter.id});
  }

  removeQuestionTo(questionTo) {
    this.setState({deleting: true});
    this.props.deleteQuestionTo(questionTo).then(() => this.setState({deleting: false}));
  }

  addQuestionTo() {
    this.setState({adding: true});
    const filterByKeys = ['answers', 'rows', 'columns'];
    const filterBy = _.find(filterByKeys, key => _.first(this.props.questions).hasOwnProperty(key));

    const data = {
      filterIterativeId: this.props.filter.id,
      questionTo: {
        question: _.first(this.props.questions).id,
        filterBy
      }
    };

    this.props.addQuestionTo(data).then(() => this.setState({adding: false}));
  }

  filterBy(questionTo){
    const question = this.props.surveyQuestions[questionTo.question];
    const options = [];

    _.each(availableFilterBys, key => {
      if (question.hasOwnProperty(key)) {
        options.push({
          label: <FormattedMessage id={"question.filterIterative.questionsToList.tableHeader.filterBy." + key} />,
          value: key
        });
      }
    });

    return (
      <Select
        value={{
          label: <FormattedMessage id={"question.filterIterative.questionsToList.tableHeader.filterBy." + questionTo.filterBy} />,
          value: questionTo.filterBy
        }}
        isMulti={false}
        onChange={e => this.onFilterByChange(questionTo, e.value)}
        options={options}
      />
    )
  }

  actions(questionTo){
    return (
      <i className="text-danger fas fa-times pointer mr-2" onClick={() => this.removeQuestionTo(questionTo)}/>
    )
  }

  render() {
    const columns = [
      {
      id: 'question',
      Header: <FormattedMessage id="question.filterIterative.questionsToList.tableHeader.question"/>,
      accessor: questionTo => <SelectQuestions enabledTypes={['single','multi','ranked','conjoint','matrix','matrixMulti','matrixDropdown', 'numericMulti']} isMulti={false} value={questionTo.question} onChange={question => this.onQuestionChange(questionTo, question)}/>,
    },
      {
      id: 'filterBy',
      Header: <FormattedMessage id="question.filterIterative.questionsToList.tableHeader.filterBy"/>,
      accessor: questionTo => this.filterBy(questionTo)
    },
      {
      id: 'actions',
      Header: <FormattedMessage id="question.filterIterative.questionsToList.tableHeader.actions"/>,
      accessor: questionTo => this.actions(questionTo),
      width: 50
    }];

    return (
      <React.Fragment>
        <ReactTable
          minRows={0}
          className={'table-wrap'}
          data={this.state.questionsTo}
          columns={columns}
          showPagination={!_.isEmpty(this.state.questionsTo)}
          PaginationComponent={Pagination}
          noDataText={<FormattedMessage id="question.filterIterative.questionsToList.table.noQuestionsTo" />}
          defaultPageSize={5}
          loading={this.state.adding || this.state.deleting}
        />
        <Button size="sm" color="primary" onClick={this.addQuestionTo} disabled={this.state.adding}><FormattedMessage id="question.filterIterative.questionsToList.button.add" /></Button>
      </React.Fragment>
    )
  }
}

QuestionsToList.propTypes = {
  questions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    surveyQuestions: state.survey.structure.data.questions
  }
}

export default connect(mapStateToProps, {deleteQuestionTo, addQuestionTo, updateQuestionTo})(QuestionsToList);
