import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Tooltip} from "reactstrap";
import classnames from "classnames";
import ColorPickerModal from "../../../../../components/ColorPickerModal";

const KEY_BG = '$question-cafeteryWrap-bgColor';
const KEY_BG_ACTIVE = '$question-cafeteryWrap-bgColor-active';
const KEY_BG_HOVER = '$question-cafeteryWrap-bgColor-hover';
const KEY_COLOR = '$question-cafeteryWrap-color';
const KEY_COLOR_ACTIVE = '$question-cafeteryWrap-color-active';
const KEY_COLOR_HOVER = '$question-cafeteryWrap-color-hover';

class CafeteryWrap extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        [KEY_BG]: props.ownStyles[KEY_BG] ? props.ownStyles[KEY_BG] : false,
        [KEY_BG_ACTIVE]: props.ownStyles[KEY_BG_ACTIVE] ? props.ownStyles[KEY_BG_ACTIVE] : false,
        [KEY_BG_HOVER]: props.ownStyles[KEY_BG_HOVER] ? props.ownStyles[KEY_BG_HOVER] : false,
        [KEY_COLOR]: props.ownStyles[KEY_COLOR] ? props.ownStyles[KEY_COLOR] : false,
        [KEY_COLOR_ACTIVE]: props.ownStyles[KEY_COLOR_ACTIVE] ? props.ownStyles[KEY_COLOR_ACTIVE] : false,
        [KEY_COLOR_HOVER]: props.ownStyles[KEY_COLOR_HOVER] ? props.ownStyles[KEY_COLOR_HOVER] : false,
      },
      modalOpen: {
        bg: false,
        bgActive: false,
        bgHover: false,
        color: false,
        colorActive: false,
        colorHover: false,
      },
      tooltip: {
        bg: false,
        bgActive: false,
        bgHover: false,
        color: false,
        colorActive: false,
        colorHover: false,
      }
    };

    this.getDefaultValue = this.getDefaultValue.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(key){
    this.setState({
      tooltip: {
        ...this.state.tooltip,
        [key]: !this.state.tooltip[key]
      }
    })
  }

  getDefaultValue(key){
    return this.props.ownStylesDefault[key];
  }

  clearValues(){
    this.setState({
      values: {
        [KEY_BG]: '',
        [KEY_BG_ACTIVE]: '',
        [KEY_BG_HOVER]: '',
        [KEY_COLOR]: '',
        [KEY_COLOR_ACTIVE]: '',
        [KEY_COLOR_HOVER]: '',
      }
    });

    this.props.handlerUpdate({[KEY_BG]: ''});
    this.props.handlerUpdate({[KEY_BG_ACTIVE]: ''});
    this.props.handlerUpdate({[KEY_BG_HOVER]: ''});
    this.props.handlerUpdate({[KEY_COLOR]: ''});
    this.props.handlerUpdate({[KEY_COLOR_ACTIVE]: ''});
    this.props.handlerUpdate({[KEY_COLOR_HOVER]: ''});
  }

  updateValue(key, value){
    this.setState({
      values: {
        ...this.state.values,
        [key]: value
      }
    });

    this.props.handlerUpdate({[key]: value});
  }

  render() {
    const {values, modalOpen} = this.state;
    const inactive =
      [false, ''].indexOf(values[KEY_BG]) >= 0
      && [false, ''].indexOf(values[KEY_BG_ACTIVE]) >= 0
      && [false, ''].indexOf(values[KEY_BG_HOVER]) >= 0
      && [false, ''].indexOf(values[KEY_COLOR]) >= 0
      && [false, ''].indexOf(values[KEY_COLOR_ACTIVE]) >= 0
      && [false, ''].indexOf(values[KEY_COLOR_HOVER]) >= 0
    ;

    return (
      <Row className={classnames({
        'py-1 px-3 align-items-center': true,
        'inactive': inactive,
      })}>
        <label className="col col-4 mb-0">Kafeteria {!inactive && <i className="icon-clear-value fas fa-trash float-right" onClick={() => this.clearValues()} />}</label>
        <Col className="col-8 d-flex justify-content-between">
          <div className="d-flex">
            <div id="key-cafeteryWrap-bg" className="mr-2">
              <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, bg: true}})} style={{
                backgroundColor: values[KEY_BG] ? values[KEY_BG] : this.getDefaultValue(KEY_BG),
              }} />
              {modalOpen.bg && <ColorPickerModal
                color={values[KEY_BG] ? values[KEY_BG] : this.getDefaultValue(KEY_BG)}
                onClose={() => this.setState({modalOpen: false})}
                onSelect={value => {
                  this.setState({modalOpen: {...modalOpen, bg: false}});
                  this.updateValue(KEY_BG, value);
                }}
              />}
              <Tooltip
                delay={0}
                placement="top"
                isOpen={this.state.tooltip.bg}
                target={'key-cafeteryWrap-bg'}
                toggle={() => this.toggleTooltip('bg')}
              >
                Kolor tła gdy odpowiedź jest niezaznaczona
              </Tooltip>
            </div>
            <div id="key-cafeteryWrap-color" className="mr-2">
              <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, color: true}})} style={{
                backgroundColor: values[KEY_COLOR] ? values[KEY_COLOR] : this.getDefaultValue(KEY_COLOR),
              }} />
              {modalOpen.color && <ColorPickerModal
                color={values[KEY_COLOR] ? values[KEY_COLOR] : this.getDefaultValue(KEY_COLOR)}
                onClose={() => this.setState({modalOpen: false})}
                onSelect={value => {
                  this.setState({modalOpen: {...modalOpen, color: false}});
                  this.updateValue(KEY_COLOR, value);
                }}
              />}
              <Tooltip
                delay={0}
                placement="top"
                isOpen={this.state.tooltip.color}
                target={'key-cafeteryWrap-color'}
                toggle={() => this.toggleTooltip('color')}
              >
                Kolor tekstu gdy odpowiedź jest niezaznaczona
              </Tooltip>
            </div>
          </div>

          <div className="d-flex">
            <div id="key-cafeteryWrap-bgHover" className="mr-2">
              <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, bgHover: true}})} style={{
                backgroundColor: values[KEY_BG_HOVER] ? values[KEY_BG_HOVER] : this.getDefaultValue(KEY_BG_HOVER),
              }} />
              {modalOpen.bgHover && <ColorPickerModal
                color={values[KEY_BG_HOVER] ? values[KEY_BG_HOVER] : this.getDefaultValue(KEY_BG_HOVER)}
                onClose={() => this.setState({modalOpen: false})}
                onSelect={value => {
                  this.setState({modalOpen: {...modalOpen, bgHover: false}});
                  this.updateValue(KEY_BG_HOVER, value);
                }}
              />}
              <Tooltip
                delay={0}
                placement="top"
                isOpen={this.state.tooltip.bgHover}
                target={'key-cafeteryWrap-bgHover'}
                toggle={() => this.toggleTooltip('bgHover')}
              >
                Kolor tła gdy na odpowiedź respondent najeżdża myszką
              </Tooltip>
            </div>
            <div id="key-cafeteryWrap-colorHover" className="mr-2">
              <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, colorHover: true}})} style={{
                backgroundColor: values[KEY_COLOR_HOVER] ? values[KEY_COLOR_HOVER] : this.getDefaultValue(KEY_COLOR_HOVER),
              }} />
              {modalOpen.colorHover && <ColorPickerModal
                color={values[KEY_COLOR_HOVER] ? values[KEY_COLOR_HOVER] : this.getDefaultValue(KEY_COLOR_HOVER)}
                onClose={() => this.setState({modalOpen: false})}
                onSelect={value => {
                  this.setState({modalOpen: {...modalOpen, colorHover: false}});
                  this.updateValue(KEY_COLOR_HOVER, value);
                }}
              />}
              <Tooltip
                delay={0}
                placement="top"
                isOpen={this.state.tooltip.colorHover}
                target={'key-cafeteryWrap-colorHover'}
                toggle={() => this.toggleTooltip('colorHover')}
              >
                Kolor tekstu gdy na odpowiedź respondent najeżdża myszką
              </Tooltip>
            </div>
          </div>

          <div className="d-flex">
            <div id="key-cafeteryWrap-bgActive" className="mr-2">
              <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, bgActive: true}})} style={{
                backgroundColor: values[KEY_BG_ACTIVE] ? values[KEY_BG_ACTIVE] : this.getDefaultValue(KEY_BG_ACTIVE),
              }} />
              {modalOpen.bgActive && <ColorPickerModal
                color={values[KEY_BG_ACTIVE] ? values[KEY_BG_ACTIVE] : this.getDefaultValue(KEY_BG_ACTIVE)}
                onClose={() => this.setState({modalOpen: false})}
                onSelect={value => {
                  this.setState({modalOpen: {...modalOpen, bgActive: false}});
                  this.updateValue(KEY_BG_ACTIVE, value);
                }}
              />}
              <Tooltip
                delay={0}
                placement="top"
                isOpen={this.state.tooltip.bgActive}
                target={'key-cafeteryWrap-bgActive'}
                toggle={() => this.toggleTooltip('bgActive')}
              >
                Kolor tła gdy odpowiedź jest zaznaczona
              </Tooltip>
            </div>
            <div id="key-cafeteryWrap-colorActive" className="mr-2">
              <div className="color-picker-preview" onClick={() => this.setState({modalOpen: {...modalOpen, colorActive: true}})} style={{
                backgroundColor: values[KEY_COLOR_ACTIVE] ? values[KEY_COLOR_ACTIVE] : this.getDefaultValue(KEY_COLOR_ACTIVE),
              }} />
              {modalOpen.colorActive && <ColorPickerModal
                color={values[KEY_COLOR_ACTIVE] ? values[KEY_COLOR_ACTIVE] : this.getDefaultValue(KEY_COLOR_ACTIVE)}
                onClose={() => this.setState({modalOpen: false})}
                onSelect={value => {
                  this.setState({modalOpen: {...modalOpen, colorActive: false}});
                  this.updateValue(KEY_COLOR_ACTIVE, value);
                }}
              />}
              <Tooltip
                delay={0}
                placement="top"
                isOpen={this.state.tooltip.colorActive}
                target={'key-cafeteryWrap-colorActive'}
                toggle={() => this.toggleTooltip('colorActive')}
              >
                Kolor tekstu gdy odpowiedź jest zaznaczona
              </Tooltip>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

CafeteryWrap.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
};

export default CafeteryWrap;