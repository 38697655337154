import React, {Component} from 'react';
import {connect} from "react-redux";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import RotateSets from "./Survey/Settings/RotateSets";
import {updateSurvey} from "../data/survey/actions";
import RandSets from "./Survey/Settings/RandSets";
import RandAndRotateSetsTable from "./Survey/Settings/RandAndRotateSetsTable";

class Structure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bodyIsOpen: false,
      dropdownIsOpen: false,
    };

    this.update = this.update.bind(this);
  }

  update(survey){
    this.props.updateSurvey({
      id: this.props.survey.id,
      survey,
    })
  }

  render() {
    const {bodyIsOpen, dropdownIsOpen} = this.state;

    return <div className="card-wrap">
      <div className="border-l gray card">
        <div className="py-md-2 card-header d-flex justify-content-between">
          <h2 className="float-left mb-0 p-1 mr-2">Struktura</h2>

          <Dropdown className="py-0" isOpen={dropdownIsOpen} toggle={() => this.setState({dropdownIsOpen: !dropdownIsOpen})}>
            <DropdownToggle className="m-0 p-0" color="">
              <span className="fas fa-ellipsis-v hoverable" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag="a" onClick={() => this.setState({bodyIsOpen: !bodyIsOpen})}>
                <p><span className="fas fa-cog"/></p> <h5>Ustawienia</h5>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        {bodyIsOpen && <div className="card-body">
          <div className="d-flex">
            <RotateSets rotateSets={this.props.survey.rotateSets} handleUpdate={survey => this.update(survey)} />
            <RandSets randSets={this.props.survey.randSets} handleUpdate={survey => this.update(survey)} />
          </div>
          <RandAndRotateSetsTable />
        </div>}
      </div>
    </div>;
  }
}

Structure.propTypes = {};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps, {updateSurvey})(Structure);
