import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from "reactstrap";
import CondDetail from "./CondDetail";
import CondVisibility from "./CondVisibility";
import CondExcluding from "./CondExcluding";
import DropdownToggle from "reactstrap/es/DropdownToggle";
import DropdownMenu from "reactstrap/es/DropdownMenu";
import DropdownItem from "reactstrap/es/DropdownItem";
import uniqid from "uniqid";
import classnames from "classnames";
import CondUserProfile from "./CondUserProfile";
import CondToken from "./CondToken";

class CondQuestion extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      tooltipId: uniqid(),
      isOpenCondDropdown: false,
    };

    this.toggleIsOpenCondDropdown = this.toggleIsOpenCondDropdown.bind(this);
  }

  componentWillMount() {
    const {condQuestion} = this.props;

    if(_.isUndefined(condQuestion)){
      this.props.handleCondQuestionChanged({

      });
    }
  }

  toggleIsOpenCondDropdown(){
    this.setState({
      isOpenCondDropdown: !this.state.isOpenCondDropdown,
    });
  }

  render() {
    const {condQuestion, requiredVisible, changeable} = this.props;
    const {isOpenCondDropdown} = this.state;

    if (!changeable) {

      if (condQuestion.type === 'token') {
        return <CondToken condQuestion={condQuestion} changeable={false} />
      }

      return condQuestion.type === 'userProfile'
        ? <CondUserProfile condQuestion={condQuestion} changeable={false} />
        : <React.Fragment>
          {condQuestion.check === 'detail' && <CondDetail condQuestion={condQuestion} changeable={false} />}
          {condQuestion.check === 'visibility' && <CondVisibility condQuestion={condQuestion} changeable={false} />}
          {condQuestion.check === 'excluding' && <CondExcluding condQuestion={condQuestion} changeable={false} />}
        </React.Fragment>
    }

    return (
      <div className="d-flex">
        <div className="cond d-flex flex-wrap align-items-center flex-grow-1">
          {(condQuestion.type === 'userProfile' &&
              <CondUserProfile condQuestion={condQuestion} onChange={this.props.handleCondQuestionChanged} />)
          || (condQuestion.type === 'token' &&
              <CondToken condQuestion={condQuestion} onChange={this.props.handleCondQuestionChanged} />)
          ||
          <React.Fragment>
            {condQuestion.check === 'detail' && <CondDetail condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondQuestionChanged(condQuestion)} />}
            {condQuestion.check === 'visibility' && <CondVisibility condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondQuestionChanged(condQuestion)} />}
            {condQuestion.check === 'excluding' && <CondExcluding condQuestion={condQuestion} handleCondChanged={condQuestion => this.props.handleCondQuestionChanged(condQuestion)} />}
          </React.Fragment>
          }
        </div>
        <div>
          <Dropdown className="float-right mb-0 mt-0" isOpen={isOpenCondDropdown} toggle={this.toggleIsOpenCondDropdown}>
            <DropdownToggle tag="div" className="d-block m-0 py-1 px-2"><i className="fas fa-ellipsis-v fa-rotate-90" /></DropdownToggle>
            <DropdownMenu>
              {requiredVisible && <DropdownItem className={classnames({
                'bg-warning': condQuestion.required,
              })} tag="a" onClick={() => this.props.handleCondQuestionChanged({...condQuestion, required: !condQuestion.required})}><p><span className="fas fa-exclamation" /></p> <h5>Oznacz jako obowiązkowy</h5></DropdownItem>}
              <DropdownItem tag="a" onClick={() => this.props.handleCondQuestionDelete()}><p><span className="fas fa-trash" /></p> <h5>Usuń warunek</h5></DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }
}

CondQuestion.defaultProps = {
  changeable: true
};

CondQuestion.propTypes = {
  condQuestion: PropTypes.shape({
    check: PropTypes.oneOf(['visibility', 'excluding', 'detail']),
    required: PropTypes.bool,
  }),
  requiredVisible: PropTypes.bool,
  handleCondQuestionChanged: PropTypes.func,
  handleCondQuestionDelete: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default CondQuestion;
