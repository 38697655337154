import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';
import RedirectForm from '../../../../../RedirectForm';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import {connect} from "react-redux";
import SurveyRedirectsAvailable from "components/PlanRestrictionsModals/modals/SurveyRedirectsAvailable";

class RedirectsEditButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false,
      errors: {}
    };

    this.redirectForm = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.updateRedirect = this.updateRedirect.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]});
  }

  updateRedirect(e, data) {
    e.preventDefault();
    this.setState({saving: true, errors: {}});
    data.redirect.id = this.props.redirect.id;

    api.survey.patch.redirect(data.redirect)
      .then(() => {
        this.setState({saving: false, modal: false});
        this.props.onUpdate();
      })
      .catch(e => {
        this.setState({saving: false, errors: e.response.data.form.errors})
      });
  }

  render() {
    const modal =
      <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')} size="lg">
        <ModalHeader toggle={() => this.toggle('modal')} tag="div"><FormattedMessage tagName="h2" id="redirect.actions.edit.modalHeader"/></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <RedirectForm errors={this.state.errors} onSubmit={this.updateRedirect} ref={this.redirectForm}  redirect={this.props.redirect}/>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={this.state.saving}
                  data-style={ZOOM_OUT}
                  onClick={e => this.updateRedirect(e, this.redirectForm.current.state)}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggle('modal')}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    ;

    return (
      <React.Fragment>
        <a className="fas fa-edit mr-2" onClick={() => this.props.surveyPlan.redirects ? this.toggle('modal') : this.props.setPlanRestrictionsModals(SurveyRedirectsAvailable.getName())}/>
        {modal}
      </React.Fragment>
    );
  }
}

RedirectsEditButton.propTypes = {
  redirect: PropTypes.shape({
    id: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    target: PropTypes.string.isRequired,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(RedirectsEditButton)
