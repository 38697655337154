import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Button, Tooltip} from "reactstrap";
import classnames from "classnames";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";

class SettingBool extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      value: props.value,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleFieldValue = this.toggleFieldValue.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleFieldValue() {
    if (this.props.disabled) {
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());
      return;
    }

    this.setState({
      value: !this.state.value
    }, () => {
      this.props.handleUpdate({
        [this.props.name]: this.state.value
      })
    });
  }

  render() {
    let { tooltip, value } = this.state;

    return (
      <Button
        className={classnames({
          "float-left mr-1 btn-icon mb-1": true,
          "active text-white": value,
          "disabled": this.props.disabled
        })}
        color={'secondary'}
        id={'question-' + this.props.question.id + '-' + this.props.name}
        onClick={this.toggleFieldValue}
      >
        {this.props.icon}

        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={'question-' + this.props.question.id + '-' + this.props.name}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id={'_.question.' + this.props.question.type + '.' + this.props.name}/>
        </Tooltip>
      </Button>
    )
  }
}

SettingBool.defaultProps = {
  disabled: false
};

SettingBool.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  icon: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default connect(null, {setPlanRestrictionsModals})(SettingBool);