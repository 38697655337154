import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import GlobalTemplates from "./GlobalTemplates";
import UserTemplates from "./UserTemplates";
import SaveTemplate from "./SaveTemplate";
import {updateSurvey} from '../../Structure/data/survey/actions';
import { connect } from 'react-redux';
import {isBrowser} from "react-device-detect";

class Templates extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.onTemplateChoose = this.onTemplateChoose.bind(this);
  }

  toggle(){
    if(!document.getElementById('template-picker') &&
        !document.getElementById('saved-template-picker') &&
        !document.getElementById('save-template-modal')
    ){
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  onTemplateChoose(template) {
    const data = {
      id: this.props.surveyId,
      survey: {
        template
      }
    };
    this.props.updateSurvey(data)
      .then(() => this.props.handlerUpdate({}));
    this.setState({isOpen: false});
  }

  render() {
    const {isOpen} = this.state;

    return (
      <Dropdown toggle={this.toggle} isOpen={isOpen} inNavbar>
        <DropdownToggle className="py-3"><i className="fas fa-paste" /></DropdownToggle>
        {isOpen && <DropdownMenu className="p-2" style={{
          height: isBrowser ? 'auto' : window.innerHeight - 100,
          width: isBrowser ? 650 : window.innerWidth,
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}>
          <div className="d-block">
            <h3 className="text-center">Szablony</h3>

            <Button color="" onClick={(e) => this.toggle(e)} className="ml-auto px-3" style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}><i className="fas fa-times mt-1"/> </Button>
          </div>
          <hr className="my-0 mb-2"/>
          <GlobalTemplates handlerUpdate={this.onTemplateChoose}/>
          <hr className="my-2"/>
          <UserTemplates handlerUpdate={this.onTemplateChoose}/>
          <hr className="my-2"/>
          <SaveTemplate ownStyles={this.props.ownStyles} surveyId={this.props.surveyId}/>
        </DropdownMenu>}
      </Dropdown>
    );
  }
}

Templates.propTypes = {
  ownStyles: PropTypes.object.isRequired,
  surveyId: PropTypes.number.isRequired,
  handlerUpdate: PropTypes.func.isRequired,
};

export default connect(null, {updateSurvey})(Templates);
