import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {addExcluding} from "scenes/Survey/Structure/data/excludings/actions";
import {Input} from 'reactstrap';
import LoadingSpinner from "components/LoadingSpinner";
import {isMobile, isTablet} from "react-device-detect";
import HtmlEditor
  from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";
import getCKConfigByPlan from "utils/ckeditor/cafeteryConfig";

class ExcludingNew extends Component {

  constructor(props) {
    super(props);

    this.state = {
      autoFocus: props.autoFocus,
      saving: false,
      adding: props.autoFocus,
      excluding: {
        content: ''
      }
    };

    this.onChangeContent = this.onChangeContent.bind(this);
    this.saveExcluding = this.saveExcluding.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.saveExcluding()
  }

  onChangeContent(content){
    if(this.state.saving){
      return;
    }

    this.setState({
      excluding: {
        content
      }
    })
  }

  saveExcluding(){
    this.setState({adding: false});
    if(this.state.saving){
      return;
    }

    const {excluding} = this.state;

    if(excluding.content.length === 0)
      return false;

    this.setState({
      excluding: {
        ...this.state.excluding,
        content: ''
      },
      saving: true
    });

    this.props.addExcluding({
      question: this.props.question.id,
      excluding
    }).then(() => {
      this.setState({saving: false});
    });
  }

  render() {
    const {excluding, adding, saving, autoFocus} = this.state;
    const {plan} = this.props;

    const ctrlEnterKeyCode = 1114125;
    let editor;

    if (isMobile || isTablet) {
      editor = <Input value={excluding.content} onChange={e => this.onChangeContent(e.target.value)} onBlur={this.saveExcluding} autoFocus={autoFocus} onKeyPress={this.handleKeyPress}/>
    } else {
      editor = <HtmlEditor
        data={excluding.content}
        config={getCKConfigByPlan(plan)}
        type="inline"
        onChange={event => this.onChangeContent(event)}
        onBlur={event => {
          this.saveExcluding();
          this.setState({editing: false})
        }}
        onKey={event => {
          if (event.data.keyCode === ctrlEnterKeyCode) {
            event.cancel();
            this.saveExcluding();
            setTimeout(() => {
              event.editor.destroy();
              this.setState({editing: false})
            }, 1);
          }
        }}
      />
    }

    return (
      <div className="line-wrap d-print-none">
        <ul className="mb-0 ml-lg-5 d-flex">
          <li className="flex-grow-1">

            {adding
              ? editor
              : saving
                ? <LoadingSpinner width={21} classNames="my-0"/>
                : <small onClick={() => this.setState({adding: true, autoFocus: true})}><i><FormattedMessage id="excludings.excludingNew.input.placeholder" /></i></small>
            }

          </li>
        </ul>
      </div>
    );
  }
}

ExcludingNew.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  autoFocus: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {addExcluding})(ExcludingNew);