import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {InputGroupAddon, Tooltip, Button} from "reactstrap";
import classnames from "classnames";
import InputRange from "react-input-range";

class DivisionColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      question: {
        divisionColumn: props.question.divisionColumn,
      }
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    })
  }

  onChange(divisionColumn) {
    this.setState({
      ...this.state,
      question: {
        divisionColumn
      }
    }, () => this.props.handleUpdate(this.state.question));
  }

  render() {
    const {question, tooltip} = this.state;
    const {id} = this.props.question;

    return (
      <React.Fragment>
        <div
          id={"divisionColumn" + id}
          className={classnames({
            "min-w-0 float-left mr-1 mb-1 w-100 w-lg-auto input-group": true,
          })}
        >
          <InputGroupAddon addonType="prepend">
            <Button
              className={classnames({
                "btn-icon active": true,
              })}
              color="secondary"
            >
              <i className="fa-solid fa-ruler-triangle"/>
              <Tooltip
                delay={0}
                placement="top"
                isOpen={tooltip}
                target={"divisionColumn" + id}
                toggle={this.toggleTooltip}
              >
                <FormattedMessage id="question.settings.divisionColumn.tooltip">{msg => msg + ' (' + question.divisionColumn + '/12)'}</FormattedMessage>
              </Tooltip>
            </Button>
          </InputGroupAddon>
          <div className="input-number-wrap pt-3 pt-md-1 overflow-hidden" style={{flex: '1 1 auto', minWidth: 150}}>
            <InputRange minValue={1} maxValue={11} value={question.divisionColumn} onChange={divisionColumn => this.setState({question: {divisionColumn}})} onChangeComplete={this.onChange} formatLabel={() => ""}/>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

DivisionColumn.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    divisionColumn: PropTypes.number.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default DivisionColumn;
