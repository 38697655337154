import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import TabSummary from "../CollectorEmail/Tabs/TabSummary";

class Email extends PureComponent {
  render() {
    const {collector} = this.props;

    return <TabSummary collector={collector} />
  }
}

Email.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default Email;