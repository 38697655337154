import api from "api";
import * as types from './actionTypes';

export function setCodes(codes) {
  return {
    type: types.SET,
    codes
  }
}

export function codeDeleted(code) {
  return {
    type: types.DELETED,
    code
  }
}

export function codeAdded(code) {
  return {
    type: types.ADDED,
    code
  }
}

export function codeUpdated(code) {
  return {
    type: types.UPDATED,
    code
  }
}

export function deleteCode(code) {
  return dispatch => {
    return api.code.delete(code).then(() => {
      dispatch(codeDeleted(code));
    })
  }
}

export function fetchCodes() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.code.get.codes().then(codes => {
      let orderedCodes = {};
      _.map(codes, code => orderedCodes[code.id] = code);

      dispatch(setCodes(orderedCodes));
      dispatch({type: types.SUCCESS});
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}

export function addCode(data) {
  return dispatch => {
    return api.code.post.code(data).then((code) => {
      dispatch(codeAdded(code));
    })
  }
}

export function updateCode(data) {
  return dispatch => {
    return api.code.patch(data).then(code => {
      dispatch(codeUpdated(code));
    })
  }
}