import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import api from "api";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import PropTypes from 'prop-types';

class TabSentHistory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    this.setState({
      searching: true,
      page: state.page,
      pageSize: state.pageSize
    });

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.surveyCollector.panel.get.sendEmail.sent.search(this.props.collector.id, data)
    .then(res => {
      this.setState({
        searching: false,
        data: res.data,
        count: res.count
      });
    })
    .catch(e => this.setState({
      searching: false,
    }));
  }

  render() {
    const {loading, sentHistory} = this.state;

    const columns = [
      {
        id: 'respondent',
        Header: <FormattedMessage id="collectorPanel.sentHistory.table.email"/>,
        accessor: history => history.respondent.user.emailMasked
      },
      {
        Header: <FormattedMessage id="collectorPanel.sentHistory.table.createdAt"/>,
        accessor: 'createdAt'
      },
      {
        Header: 'Liczba wyświetleń',
        accessor: 'displayCount'
      },
    ];

    return (
      <ReactTable
        manual
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="collectorPanel.addresses.table.noData" />}
        defaultPageSize={10}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
      />
    )
  }
}

TabSentHistory.propTypes = {
  collector: PropTypes.object.isRequired
};

export default TabSentHistory;