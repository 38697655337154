import React, {Component} from 'react';
import PropTypes from "prop-types";
import striptags from "striptags";
import {Badge, Card, CardBody, CardHeader} from "reactstrap";
import QuestionSummarySingle from 'scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummarySingle';
import QuestionSummaryMulti from 'scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryMulti';
import QuestionSummaryMultiOpen from 'scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryMultiOpen';
import QuestionSummaryOpen from 'scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryOpen';
import QuestionSummaryVideo from 'scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryVideo';
import QuestionSummaryMatrix from 'scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryMatrix';
import QuestionSummaryRanked from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryRanked";
import QuestionSummaryConjoint from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryConjoint";
import QuestionSummaryMatrixMulti from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryMatrixMulti";

class AnalysisShareQuestionSummary extends Component {

  renderQuestionSummary() {
    switch (this.props.questionSummary.question.type) {
      case 'single':
        return <QuestionSummarySingle questionSummary={this.props.questionSummary}/>;
      case 'multi':
        return <QuestionSummaryMulti questionSummary={this.props.questionSummary}/>;
      case 'multiOpen':
        return <QuestionSummaryMultiOpen questionSummary={this.props.questionSummary}/>;
      case 'open':
        return <QuestionSummaryOpen questionSummary={this.props.questionSummary}/>;
      case 'video':
        return <QuestionSummaryVideo questionSummary={this.props.questionSummary}/>;
      case 'matrix':
        return <QuestionSummaryMatrix questionSummary={this.props.questionSummary}/>;
      case 'ranked':
        return <QuestionSummaryRanked questionSummary={this.props.questionSummary}/>;
      case 'conjoint':
        return <QuestionSummaryConjoint questionSummary={this.props.questionSummary}/>;
      case 'matrixMulti':
        return <QuestionSummaryMatrixMulti questionSummary={this.props.questionSummary}/>;
    }
  }

  render() {
    const {questionSummary} = this.props;

    return (
      <Card className="mb-4 w-100">
        <CardHeader className="text-center">
          <div dangerouslySetInnerHTML={{__html: striptags(questionSummary.question.content)}} />
        </CardHeader>
        <CardBody>
          <div className="d-flex align-content-center justify-content-center mb-3">
            <Badge color="secondary" style={{fontSize: '1em'}}>N = {questionSummary.data.n}</Badge>
          </div>
          {this.renderQuestionSummary()}
        </CardBody>
      </Card>
    )
  }
}

AnalysisShareQuestionSummary.propTypes = {
  questionSummary: PropTypes.object.isRequired
};

export default AnalysisShareQuestionSummary;