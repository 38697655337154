import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader, Tooltip, ModalFooter, Alert} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import api from "api";
import TopSurveysTermsCheckbox from "components/TopSurveysTermsCheckbox";
import {FormattedMessage} from "react-intl";
import moment from "moment";

const STATUS_REPORTED = 1;
const STATUS_REJECTED = 2;
const STATUS_ACCEPTED = 3;
const STATUS_WAITING = 4;

class SurveysTopSurveysStatusIcon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      modal: false,
      regulationsAccepted: false,
      saving: false,
      modalBaseSurveysTerms: false,
    };

    this.toggle = this.toggle.bind(this);
    this.getStatusColor = this.getStatusColor.bind(this);
    this.rereportSurvey = this.rereportSurvey.bind(this);
    this.createTopSurveysCollector = this.createTopSurveysCollector.bind(this);
    this.getStatusModalTitle = this.getStatusModalTitle.bind(this);
    this.getTooltipDescription = this.getTooltipDescription.bind(this);
  }

  rereportSurvey(){
    const {survey} = this.props;

    this.setState({
      saving: true,
    });

    api.survey.patch.swPanel.rereport(survey.id)
      .then(() => {
        this.setState({
          saving: false,
          modal: false,
        });

        this.props.onReReported();
      })
      .catch(() => {
        this.setState({
          saving: false
        });
      })
  }

  createTopSurveysCollector() {
    const {survey} = this.props;

    this.setState({
      saving: true,
    });

    const data = {
      survey: survey.id,
      collector: {
        type: 'TopSurveys'
      }
    };

    api.survey.post.collector(data)
      .then(() => {
        this.setState({
          saving: false,
          modal: false,
        });

        this.props.onReReported();
      })
      .catch(() => {
        this.setState({
          saving: false
        });
      })
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  getStatusColor() {
    const swPanel = this.props.survey.swPanel;

    if(!swPanel || !this.props.survey.hasTopSurveysCollector){
      return 'secondary';
    }

    switch (swPanel.status) {
      case STATUS_REPORTED:
      case STATUS_WAITING:
        return 'primary';
      case STATUS_REJECTED:
        return 'danger';
      case STATUS_ACCEPTED:
        return 'success';
    }
  }

  getStatusDescription() {
    const swPanel = this.props.survey.swPanel;

    if(!swPanel || !this.props.survey.hasTopSurveysCollector){
      return <React.Fragment>
        <Alert color="danger">
          <div className="d-flex align-items-center">
            <i className="fas fa-exclamation-triangle fa-3x pr-3" />
            <div className="d-flex flex-column">
              <FormattedMessage id="collectors.warningModal.modalBody">{msg => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>
              <FormattedMessage id="collectors.warningModal.modalBody.hint">{msg => <span className="mt-2">{msg}</span>}</FormattedMessage>
            </div>
          </div>
        </Alert>
        <FormattedMessage tagName="p" id="collectors.warningModal.modalBody.warning"/>
        <FormattedMessage tagName="p" id="collectors.warningModal.modalBody.warning.desc"/>
        <ol className="mb-5" style={{listStyleType: 'decimal'}}>
          <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a1"/> ({moment().endOf('month').format("YYYY-MM-DD")})</li>
          <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a2"/> {moment().format("YYYY-MM-DD")}</li>
          <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a3"/> {moment().endOf('month').format("YYYY-MM-DD")}</li>
          <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a4"/></li>
          <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a5"/></li>
          <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a6"/></li>
          <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a7"/></li>
          <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a8"/></li>
        </ol>
        <i className="fas fa-2x fa-trophy text-warning float-left mr-3" />
        <FormattedMessage id="collectors.warningModal.topSurveys.info"/>
      </React.Fragment>;
    }

    switch (swPanel.status) {
      case STATUS_REPORTED:
      case STATUS_WAITING:
        return 'Oczekuje na potwierdzenie';
      case STATUS_REJECTED:
        let msg = this.props.survey.isBannedFromBaseSurveys ? 'Ankieta została zablokowana przed dodaniem do top ankiet' : 'Ankieta została odrzucona';

        if(swPanel.msg){
          msg += ' - ' + swPanel.msg;
        }

        return msg;
      case STATUS_ACCEPTED:
        return 'Ankieta została zaakceptowana przez administratora';
    }
  }

  getTooltipDescription() {
    if (!this.props.survey.swPanel || !this.props.survey.hasTopSurveysCollector) {
      return 'Kliknij aby skierować ankietę do umieszczenia w top ankietach w swpanel.pl.';
    }

    return this.getStatusDescription();
  }

  getStatusModalTitle() {
    if (!this.props.survey.swPanel || !this.props.survey.hasTopSurveysCollector){
      return <span className="text-white"><FormattedMessage id="collectors.warningModal.modalHeader" /></span>;
    }

    return 'Status ankiety';
  }

  render() {
    const {saving, regulationsAccepted} = this.state;
    const hasTopSurveysSwPanel = this.props.survey.swPanel && this.props.survey.hasTopSurveysCollector;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')} size={!hasTopSurveysSwPanel ? "lg" : "md"}>
      <ModalHeader toggle={() => this.toggle('modal')} tag="h2" className={!hasTopSurveysSwPanel ? "bg-danger" : ""}>{this.getStatusModalTitle()}</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p>{this.getStatusDescription()}</p>
        {!hasTopSurveysSwPanel && <TopSurveysTermsCheckbox onChange={() => this.toggle('regulationsAccepted')} checked={regulationsAccepted} />}
      </ModalBody>
      {hasTopSurveysSwPanel && this.props.survey.swPanel.status === STATUS_REJECTED && !this.props.survey.isBannedFromBaseSurveys && <ModalFooter>
        <LaddaButton
          className="btn btn-primary btn-ladda"
          loading={saving}
          data-style={ZOOM_OUT}
          onClick={() => this.rereportSurvey()}
        >Zgłoś ponownie</LaddaButton>
      </ModalFooter>}
      {!hasTopSurveysSwPanel && <ModalFooter>
        <LaddaButton
          className="btn btn-danger btn-ladda"
          loading={saving}
          data-style={ZOOM_OUT}
          onClick={() => this.createTopSurveysCollector()}
          disabled={!regulationsAccepted}
        ><FormattedMessage id="collectors.warningModal.button" /></LaddaButton>
      </ModalFooter>}
    </Modal>;

    return (
      <React.Fragment>
        <button className='btn p-0'>
          <i id={this.props.type + "-top-surveys-status-" + this.props.survey.id} className={"fas fa-trophy pointer text-" + this.getStatusColor() + " " + this.props.className} onClick={() => this.toggle('modal')}/>
          <Tooltip
            delay={0}
            placement="bottom"
            isOpen={this.state.tooltip}
            target={this.props.type + '-top-surveys-status-' + this.props.survey.id}
            toggle={() => this.toggle('tooltip')}
          >
            {this.getTooltipDescription()}
          </Tooltip>
          {modal}
        </button>
      </React.Fragment>
    )
  }
}

SurveysTopSurveysStatusIcon.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isBannedFromBaseSurveys: PropTypes.bool.isRequired,
    swPanel: PropTypes.shape({
      status: PropTypes.number.isRequired,
      msg: PropTypes.string,
    }),
  }).isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  rejectMessage: PropTypes.string,
  onReReported: PropTypes.func.isRequired,
};

export default SurveysTopSurveysStatusIcon;