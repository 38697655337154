import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Card, CardHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import HeaderActions from "./components/HeaderActions";
import Pages from "./components/Pages";
import {connect} from "react-redux";
import Settings from "./components/Settings";
import {toggleBlockCard} from "scenes/Survey/Structure/actions"
import HelpModeIconModal from "components/HelpModeIconModal";
import Name from './components/Name';
import {isMobile} from "react-device-detect";

class Block extends Component {

  constructor(props) {
    super(props);
    this.state = {
      optionsOpen: false,
      headerActions: true,
      hovered: false,
    };
    this.headerActions = this.headerActions.bind(this);
    this.onCardHeaderClick = this.onCardHeaderClick.bind(this);
    this.setHovered = this.setHovered.bind(this);
  }

  toggleOptions() {
    this.setState({
      optionsOpen: !this.state.optionsOpen
    });
  }

  headerActions(bool){
    this.setState({
      headerActions: bool
    })
  }

  onCardHeaderClick(e) {
    if (e.target.getAttribute('data-toggleable') && this.props.hideInStructure !== 'none')
      this.props.toggleBlockCard(this.props.block.id);
  }

  setHovered(hovered) {
    this.setState({hovered});
  }

  render() {
    const {block, closedBlockCards, setsCount, blocksCount, pagesCount} = this.props;
    const {headerActions, optionsOpen, hovered} = this.state;
    const showHeader = pagesCount > 1 || blocksCount > 1 || setsCount > 1;
    const gripStyle = hovered ? {opacity: 1} : {opacity: 0};

    const blockInfo = <React.Fragment>
      <HelpModeIconModal className="mx-2" modalHeader="Bloki">
        <p>Bloki stanowią zbiór stron ankiety</p>
      </HelpModeIconModal>
    </React.Fragment>;

    return (
      <React.Fragment>
        <Card data-id={block.id} data-type="blockCard" role="block" id={`block-${block.id}`} className="border-l red">
          {showHeader && <CardHeader className="py-md-2" onClick={this.onCardHeaderClick} data-toggleable={true} onMouseEnter={() => this.setHovered(true)} onMouseLeave={() => this.setHovered(false)}>
            <div className="d-flex p-0">
              <div className="float-left p-0 pt-3 pt-md-1 d-flex flex-grow-1" data-toggleable={true}>
                <a className="handler fas fa-grip-vertical dragg float-left" style={gripStyle}/>
                {blockInfo}
                <Name block={block} positionInSurvey={this.props.positionInSurvey} />
                {(!isMobile || !block.name) && <small className="ml-2" style={{minWidth: 'fit-content'}} data-toggleable={true}>(<FormattedMessage id="blocks.block.cardHeader.countPages">{msg => <React.Fragment>{msg}: {block.pages.length}</React.Fragment>}</FormattedMessage>)</small>}
              </div>

              {headerActions && <HeaderActions
                block={block}
                optionsOpen={optionsOpen}
                handleToggleOptions={() => this.toggleOptions()}
              />}
            </div>

          </CardHeader>}
          {this.state.optionsOpen && <Settings block={block} />}
        </Card>

        {!closedBlockCards.includes(block.id) && <Pages block={block}/>}
      </React.Fragment>
    )
  }
}

Block.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pages: PropTypes.array.isRequired,
    positionInSurvey: PropTypes.number.isRequired
  }).isRequired,
  closedBlockCards: PropTypes.array.isRequired,
};

function mapStateToProps(state, props) {
  return {
    hideInStructure: state.survey.structure.hideInStructure,
    closedBlockCards: state.survey.structure.closedBlockCards,
    setsCount: Object.keys(state.survey.structure.data.sets).length,
    blocksCount: Object.keys(state.survey.structure.data.blocks).length,
    pagesCount: state.survey.structure.data.blocks[props.block.id].pages.length,
  }
}

export default connect(mapStateToProps, {toggleBlockCard})(Block);