import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {injectIntl} from "react-intl";

class SurveyEnd extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  toggleIsOpen(){
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const {isOpen} = this.state;
    const {intl, status, changeable} = this.props;

    return (
      <React.Fragment>
        <span>i ustaw status wywiadu jako </span>

        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpen} toggle={() => this.toggleIsOpen()}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-surveyEnd-' + status})}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a" onClick={() => this.props.handleStatusChanged(3)}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-surveyEnd-3'})}</h5></DropdownItem>
            <DropdownItem tag="a" onClick={() => this.props.handleStatusChanged(4)}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionJump.type-surveyEnd-4'})}</h5></DropdownItem>
          </DropdownMenu>
        </Dropdown> : intl.formatMessage({id: 'filter.action.actionType-actionJump.type-surveyEnd-' + status})}
      </React.Fragment>
    );
  }
}

SurveyEnd.defaultProps = {
  changeable: true
};

SurveyEnd.propTypes = {
  status: PropTypes.number.isRequired,
  handleStatusChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(SurveyEnd);