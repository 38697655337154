import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import api from 'api';

class DeleteButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    }
  }

  respondentDelete(){
    this.setState({
      deleting: true
    });

    api.surveyCollector.sms.respondents.delete(this.props.respondent.id)
    .then(() => {
      this.props.handleDelete(this.props.respondent.id);
      this.setState({deleting: false, modal: false})
    })
    .catch(() => {
      this.setState({
        deleting: false
      });
    });
  }

  toggleModalDelete(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { deleting } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModalDelete()} className={'modal-danger'}>
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2">Usuń respondenta</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p>Czy na pewno chcesz usunąć respondenta?</p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.modal.delete.confirmation">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={() => this.respondentDelete()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModalDelete()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="m-0 p-0" onClick={() => this.toggleModalDelete()}><i className="fas fa-times"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

DeleteButton.propTypes = {
  respondent: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default DeleteButton;