import React, {Component} from 'react';
import {connect} from "react-redux";
import {ReactSortable} from "react-sortablejs";
import {FormattedMessage} from "react-intl";
import {addBlock} from "scenes/Survey/Structure/data/blocks/actions";
import PropTypes from "prop-types";
import i18Config from "i18Config";
import OnMoving from "../../../OnMoving";
import OnAdding from "../../../OnAdding";
import {toggleBlockCard} from "scenes/Survey/Structure/actions"
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import classnames from "classnames";
import BlocksAvailable from "components/PlanRestrictionsModals/modals/BlocksAvailable";

class BlockElements extends Component {

  constructor(props) {
    super(props);

    this.state = {
      adding: false,
    };

    this.onEndBlock = this.onEndBlock.bind(this);
    this.shouldRenderElement = this.shouldRenderElement.bind(this);
  }

  onEndBlock(evt){
    if(evt.from === evt.to){
      return false;
    }
    evt.to.insertBefore(evt.item, evt.to.children[evt.newIndex]);

    this.setState({
      adding: true,
    });

    const {sets} = this.props;

    const set = sets[parseInt(evt.to.parentElement.attributes['data-id'].nodeValue)];

    this.props.addBlock({
      set: set.id,
      block: {
        position: evt.newIndex
      }
    }).then(block => {
      if (this.props.hideInStructure === 'blocks' || this.props.hideInStructure === 'structure' ) {
        this.props.toggleBlockCard(block.id);
      }
      evt.item.remove();
    })
  }

  shouldRenderElement() {
    if (typeof this.props.searchText !== 'string')
      return true;

    const searchingText = this.props.searchText.toLowerCase();
    const elementText = i18Config.messages['structure.sidebar.elements.sortable.block'].toLowerCase();
    const elementName = 'block';

    return elementText.includes(searchingText) || elementName.includes(searchingText);
  }

  render() {
    const {adding} = this.state;

    if(adding){
      this.setState({
        adding: false,
      });

      return null;
    }

    return this.shouldRenderElement() &&
      <ReactSortable
        list={[{}]}
        setList={() => {}}
        group={{
          name: 'blocks',
          pull: 'clone',
          put: false,
          revertClone: true,
        }}
        sort={false}
        onEnd={this.onEndBlock}
        filter=".sortable-ignore"
      >
        <div
          className={classnames({
            "line-wrap py-1": true,
            'not-active sortable-ignore': !this.props.surveyPlan.blocks
          })}
          role="block"
          onClick={() => !this.props.surveyPlan.blocks && this.props.setPlanRestrictionsModals(BlocksAvailable.getName())}
        >
          <a className="fas fa-grip-vertical dragg mr-3 mt-0"/>
          <a className="fas fa-th-large mr-2" style={{fontSize: 12, cursor: 'default'}}/>
          <h5 className="mb-0 d-inline-block"><FormattedMessage id="structure.sidebar.elements.sortable.block">{(msg) => msg}</FormattedMessage></h5>
          <OnMoving/>
          <OnAdding/>
        </div>
      </ReactSortable>
  }
}

BlockElements.propTypes = {
  searchText: PropTypes.string
};

function mapStateToProps(state) {
  return {
    sets: state.survey.structure.data.sets,
    hideInStructure: state.survey.structure.hideInStructure,
    surveyPlan: state.user.userPlan.plan.survey,
  }
}

export default connect(mapStateToProps, {addBlock, toggleBlockCard, setPlanRestrictionsModals})(BlockElements);