import React, {Component} from 'react';
import ReactDOM from "react-dom";
import {connect} from "react-redux";
import {ReactSortable} from "react-sortablejs";
import {FormattedMessage, IntlProvider} from "react-intl";
import {addPage, breakPage} from "scenes/Survey/Structure/data/pages/actions";
import i18Config from "i18Config";
import PropTypes from "prop-types";
import OnMoving from "../../../OnMoving";
import OnAdding from "../../../OnAdding";
import {togglePageCard} from "scenes/Survey/Structure/actions"
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import classnames from "classnames";
import PagesAvailable from "components/PlanRestrictionsModals/modals/PagesAvailable";

class PageElements extends Component {

  constructor(props) {
    super(props);

    this.state = {
      adding: false,
    };

    this.onEndPage = this.onEndPage.bind(this);
    this.shouldRenderElement = this.shouldRenderElement.bind(this);
  }

  onEndPage(evt){
    if(evt.from === evt.to){
      return false;
    }
    evt.to.insertBefore(evt.item, evt.to.children[evt.newIndex]);

    this.setState({
      adding: true,
    });

    switch(evt.to.attributes['id'].nodeValue){
      case 'sortable-page': {
        this.props.breakPage({
          id: evt.to.parentElement.firstChild.attributes['data-id'].nodeValue,
          page: {
            position: evt.newIndex,
          },
        }).then(page => {
          if (this.props.hideInStructure === 'pages') {
            this.props.togglePageCard(page.id);
          }
          evt.item.remove();
        });

        break;
      }
      case 'sortable-block': {
        this.props.addPage({
          block: evt.to.parentElement.firstChild.attributes['data-id'].nodeValue,
          page: {
            position: evt.newIndex
          },
        }).then(page => {
          if (this.props.hideInStructure === 'pages') {
            this.props.togglePageCard(page.id);
          }
          evt.item.remove();
        });

        break;
      }
    }
  }

  static onMovePage(evt){
    let alert = document.createElement('div');
    let message;

    switch(evt.to.attributes['id'].nodeValue){
      case 'sortable-page': {
        message = <FormattedMessage id="structure.sidebar.elements.alert.pageSplit">{(msg) => msg}</FormattedMessage>;
        break;
      }
      case 'sortable-block': {
        message = <FormattedMessage id="structure.sidebar.elements.alert.pageInsert">{(msg) => msg}</FormattedMessage>;
        break;
      }
    }
    ReactDOM.render(<IntlProvider locale={i18Config.locale} messages={i18Config.messages}><div className="alert alert-warning text-center">{message}</div></IntlProvider>, alert);
    evt.dragged.replaceChild(alert, evt.dragged.childNodes[0]);
  }

  shouldRenderElement() {
    if (typeof this.props.searchText !== 'string')
      return true;

    const searchingText = this.props.searchText.toLowerCase();
    const elementText = i18Config.messages['structure.sidebar.elements.sortable.page'].toLowerCase();
    const elementName = 'page';

    return elementText.includes(searchingText) || elementName.includes(searchingText);
  }

  render() {
    const {adding} = this.state;

    if(adding){
      this.setState({
        adding: false,
      });

      return null;
    }

    return this.shouldRenderElement() &&
      <ReactSortable
        list={[{}]}
        setList={() => {}}
        group={{
          name: 'page-new',
          pull: 'clone',
          put: false,
          revertClone: true,
        }}
        sort={false}
        onEnd={this.onEndPage}
        filter=".sortable-ignore"
      >
        <div
          className={classnames({
            "line-wrap py-1": true,
            'not-active sortable-ignore': !this.props.surveyPlan.pages
          })}
          role="page"
          onClick={() => !this.props.surveyPlan.pages && this.props.setPlanRestrictionsModals(PagesAvailable.getName())}
        >
          <a className="fas fa-grip-vertical dragg mr-3 mt-0"/>
          <a className="fas fa-file mr-2" style={{fontSize: 12, cursor: 'default'}}/>
          <h5 className="mb-0 d-inline-block"><FormattedMessage id="structure.sidebar.elements.sortable.page">{(msg) => msg}</FormattedMessage></h5>
          <OnMoving/>
          <OnAdding/>
        </div>
      </ReactSortable>
  }
}

PageElements.propTypes = {
  searchText: PropTypes.string
};

function mapStateToProps(state) {
  return {
    pages: state.survey.structure.data.pages,
    hideInStructure: state.survey.structure.hideInStructure,
    surveyPlan: state.user.userPlan.plan.survey,
  }
}

export default connect(mapStateToProps, {addPage, breakPage, togglePageCard, setPlanRestrictionsModals})(PageElements);