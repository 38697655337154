import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import PropTypes from 'prop-types';
import {deleteTemplate} from 'scenes/Survey/Structure/data/survey/actions';

class ButtonDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    }
  }

  templateDelete(){
    this.setState({
      deleting: true
    });

    this.props.deleteTemplate(this.props.template.id)
      .then(() => this.setState({deleting: false, modal: false}));
  }

  toggleModalDelete(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { deleting } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModalDelete()}>
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2"><FormattedMessage id="userTemplate.buttonDelete.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="userTemplate.buttonDelete.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.modal.delete.confirmation">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={() => this.templateDelete()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModalDelete()}>{message}</Button>
        }</FormattedMessage>

      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <i className="fas fa-trash pointer ml-2" onClick={() => this.toggleModalDelete()} />
        {modal}
      </React.Fragment>
    )
  }
}

ButtonDelete.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default connect(null, { deleteTemplate })(ButtonDelete);