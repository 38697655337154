import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {saveUser, inviteUser} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import GroupUserForm from './GroupUserForm';
import PropTypes from 'prop-types';

class GroupUserCreateModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      inviteModal: false,
      creating: false,
      inviting: false,
      errors: {},
      invitingErrors: []
    };

    this.form = React.createRef();
    this.saveUser = this.saveUser.bind(this);
  }

  saveUser(e, data){
    e.preventDefault();
    this.setState({creating: true, errors: {}});

    this.props.saveUser({
      groupId: this.props.group.id,
      privilageSurveyShareDefault: data.privilageSurveyShareDefault,
      user: data
    }).then(data => {
      if (data.userExists) {
        this.setState({inviteModal: true});
      } else {
        this.setState({
          creating: false
        });
        this.props.toggle();
      }
    })
    .catch((e) => {
      if(e.response.data.form.errors){
        this.setState({
          creating: false,
          errors: e.response.data.form.errors
        });
      }
    });
  }

  inviteUser(e, d) {
    e.preventDefault();
    this.setState({inviting: true});

    let data = {
      groupId: this.props.group.id,
      privilageSurveyShareDefault: d.privilageSurveyShareDefault,
      user: {
        email: d.email
      }
    };

    this.props.inviteUser(data).then(() => {
      this.setState({
        inviteModal: false,
        creating: false,
        inviting: false,
      });
      this.props.toggle();
    })

    .catch(e => {
      if(e.response.data.form.errors){
        this.setState({
          creating: false,
          inviting: false,
          invitingErrors: e.response.data.form.errors
        });
      }
    });
  }

  toggleConfirmModal(){
    this.setState({
      inviteModal: !this.state.inviteModal,
      creating: false
    })
  }

  render() {
    const { creating, inviting, errors, invitingErrors } = this.state;
    const {surveySharePrivilages} = this.props;

    const modal = <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
      <ModalHeader toggle={this.props.toggle} tag="h2"><FormattedMessage id="groupUserCreateButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <GroupUserForm errors={errors} surveySharePrivilages={surveySharePrivilages} onSubmit={this.saveUser} ref={this.form}/>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.save">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={creating}
                data-style={ZOOM_OUT}
                onClick={e => this.saveUser(e, this.form.current.state)}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" onClick={this.props.toggle}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    const inviteModal = <Modal isOpen={this.state.inviteModal} toggle={() => this.toggleConfirmModal()}>
      <ModalHeader toggle={() => this.toggleConfirmModal()} tag="h2"><FormattedMessage id="groupUserCreateButton.inviteModal.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <FormattedMessage id="groupUserCreateButton.inviteModal.modalBody" />
        {invitingErrors.user && <div className="text-danger mt-2">{invitingErrors.user.join('; ')}</div>}
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.yes">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={inviting}
            data-style={ZOOM_OUT}
            onClick={e => this.inviteUser(e, this.form.current.state)}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.no">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleConfirmModal()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        {modal}
        {inviteModal}
      </React.Fragment>
    )
  }
}

GroupUserCreateModal.propTypes = {
  group: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    surveySharePrivilages: state.config.data.group.surveySharePrivilage
  }
}

export default connect(mapStateToProps, { saveUser, inviteUser }, null, {forwardRef: true})(GroupUserCreateModal);
