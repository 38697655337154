import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Form,FormGroup,Label,Col,Input,FormFeedback} from "reactstrap";
import api from "../../../api";

class DictionaryWordImportForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      saving: false,
      errors: {},
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  submit(e){
    e.preventDefault();
  }

  onFileChange(e) {
    this.setState({
      file: e.target.files[0]
    });
  };

  save(){
    this.props.onSavingStart();

    this.setState({
      errors: {},
    })

    const formData = new FormData();
    formData.append('file', this.state.file);
    formData.append('dictionary', this.props.dictionaryId);

    api.dictionaryWords.importFile(formData)
    .then(data => {
      this.props.onSavingStop();

      this.props.onSaved();
    })
    .catch(res => {
      this.props.onSavingStop();

      this.setState({
        errors: res.response.data
      })
    })
  }

  render() {
    const {errors} = this.state;

    return <Form>
      <FormGroup row>
        <Label md={4}>Wybierz plik:</Label>
        <Col md={8}>
          <Input type="file" onChange={e => this.onFileChange(e)} />
        </Col>
      </FormGroup>
    </Form>
  }
}

DictionaryWordImportForm.propTypes = {
  dictionaryId: PropTypes.number.isRequired,
  onSaved: PropTypes.func,
  onSavingStart: PropTypes.func,
  onSavingStop: PropTypes.func,
};

export default DictionaryWordImportForm;
