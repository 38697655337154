import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";
import striptags from "striptags";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import reactHtmlParser from "react-html-parser";
import QuestionSummaryTable from "./QuestionSummaryTable";

class QuestionSummaryRanked extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tableSlice: true,
    };
  }

  placesDataFormat(row) {
    const {questionSummary} = this.props;
    let li = [];

    for(let place = 1; place <= questionSummary.question.places; place++){
      li.push(<li key={place}>Miejsce {place} - {row['place_' + place]}</li>);
    }

    return (
      <ul className="mb-0">
        {li}
      </ul>
    )
  }

  render() {
    const {questionSummary} = this.props;
    const dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    let series = [];
    for(let place = 1; place <= questionSummary.question.places; place++){
      series.push('Miejsce ' + place);
    }

    return (
      <React.Fragment>
        <Chart id={questionSummary.question.id} type={questionSummary.chartType} n={questionSummary.data.n} data={{
          data: questionSummary.data.answers.map(answer => {
            let ret = [reactHtmlParser(striptags(questionSummary.question.answersImage ? answer.description : answer.content))[0]];

            for(let place = 1; place <= questionSummary.question.places; place++){
              ret.push(answer['place_' + place]);
            }

            return ret;
          }),
          series: series
        }}/>
        {this.props.table && <React.Fragment>
          <QuestionSummaryTable data={questionSummary.data.answers} questionSummary={questionSummary}
            valueProps={{
              dataFormat: (cell, row) => this.placesDataFormat(row)
            }}
          />
        </React.Fragment>}
      </React.Fragment>
    )
  }
}

QuestionSummaryRanked.defaultProps = {
  chart: true,
  table: true,
};

QuestionSummaryRanked.propTypes = {
  chart: PropTypes.bool,
  table: PropTypes.bool,
};

export default QuestionSummaryRanked;
