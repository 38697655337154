import React, {Component} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {FormattedMessage} from "react-intl";
import SetModal from './components/SetModal';
import BlockModal from './components/BlockModal';
import PageModal from './components/PageModal';
import QuestionModal from './components/QuestionModal';
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import PagesAvailable from "components/PlanRestrictionsModals/modals/PagesAvailable";
import BlocksAvailable from "components/PlanRestrictionsModals/modals/BlocksAvailable";
import SetsAvailable from "components/PlanRestrictionsModals/modals/SetsAvailable";

class CreateElementButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdown: false,
      type: null
    };

    this.toggle = this.toggle.bind(this);
    this.onElementClick = this.onElementClick.bind(this);
    this.getModal = this.getModal.bind(this);
    this.clear = this.clear.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]});
  }

  clear() {
    this.setState({type: null})
  }

  onElementClick(type) {
    this.setState({
      modal: true,
      type: type
    });
  }

  getModal() {
    switch (this.state.type) {
      case 'set':
        return <SetModal toggle={this.clear}/>;
      case 'block':
        return <BlockModal toggle={this.clear}/>;
      case 'page':
        return <PageModal toggle={this.clear}/>;
      case 'question':
        return <QuestionModal toggle={this.clear}/>;
      default:
        return;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown isOpen={this.state.dropdown} toggle={() => this.toggle('dropdown')} className="d-inline">
          <DropdownToggle color="" className="mobile-bottom-nav-btn">
            <i className="fas fa-plus" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a" className="py-3" onClick={() => this.props.surveyPlan.sets ? this.onElementClick('set') : this.props.setPlanRestrictionsModals(SetsAvailable.getName())}><FormattedMessage tagName="h5" id="structure.createElementButton.dropdownItem.set" /></DropdownItem>
            <DropdownItem tag="a" className="py-3" onClick={() => this.props.surveyPlan.blocks ? this.onElementClick('block') : this.props.setPlanRestrictionsModals(BlocksAvailable.getName())}><FormattedMessage tagName="h5" id="structure.createElementButton.dropdownItem.block" /></DropdownItem>
            <DropdownItem tag="a" className="py-3" onClick={() => this.props.surveyPlan.pages ? this.onElementClick('page') : this.props.setPlanRestrictionsModals(PagesAvailable.getName())}><FormattedMessage tagName="h5" id="structure.createElementButton.dropdownItem.page" /></DropdownItem>
            <DropdownItem tag="a" className="py-3" onClick={() => this.onElementClick('question')}><FormattedMessage tagName="h5" id="structure.createElementButton.dropdownItem.question" /></DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {this.getModal()}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey,
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(CreateElementButton);