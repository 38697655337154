import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import SelectSets from "../../../SelectSets";

class Set extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalSet: props.set,
    };

    this.toggleModalSet = this.toggleModalSet.bind(this);
  }

  toggleModalSet(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {set, sets, changeable} = this.props;
    const {modalOpen, modalSet} = this.state;
    const s = sets.find(s => s.id === set);

    if (!changeable) {
      return s ? 'Zestaw ' + s.positionInSurvey : null;
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalSet} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalSet}>Wybierz zestaw</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <SelectSets isMulti={false} value={modalSet} onChange={set => {
          this.setState({
            modalSet: set,
            modalOpen: false,
          }, () => this.props.handleSetChanged(set));
        }} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleSetChanged(modalSet);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;


    return (
      <React.Fragment>
        <Badge color={set ? 'secondary' : 'warning'} className="mx-1 pointer" onClick={this.toggleModalSet}>{s ? ('Zestaw ' + s.positionInSurvey) : 'Wybierz zestaw...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

Set.defaultProps = {
  changeable: true
};

Set.propTypes = {
  set: PropTypes.number,
  handleSetChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  let sets = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    positionInSurvey++;

    let s = state.survey.structure.data.sets[setId];
    s.positionInSurvey = positionInSurvey;
    sets.push(s);
  });

  return {
    sets
  }
}

export default connect(mapStateToProps)(Set);