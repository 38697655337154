import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../../components/Button";
import SurveyFilterTextarea from "../Filters/SurveyFilterTextarea";
import _ from "lodash";
import Block from "../../../Structure/components/Filter/Block";
import {connect} from "react-redux";
import {addQuotaNew, updateQuotaNew} from "../../../Structure/data/quotaNews/actions";

const QuotaNewFromTextareaModal = props => {
  const [quotaNews, setQuotaNews] = React.useState([]);
  const [quotaNewsText, setQuotaNewsText] = React.useState(props.quotaNewsText);
  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [savedPositions, setSavedPositions] = React.useState([]);
  const [savedErrorsCount, setSavedErrorsCount] = React.useState(0);
  const [errors, setErrors] = React.useState([]);
  let errorsTmp = [];

  const saveQuotaNews = async() => {
    errorsTmp = [];
    setErrors([]);
    setSaving(true);
    setSaved(false);
    let savedPositions = [];
    let savedErrorsCount = 0;

    const save = key => {
      if(!quotaNews[key]){
        setTimeout(() => {
          setErrors(errorsTmp);
          setSaving(false);
          setSaved(true);
          setSavedPositions(savedPositions);
          setSavedErrorsCount(savedErrorsCount);

          let quotaNewsTextNew = quotaNewsText.split("\n");
          setQuotaNewsText(quotaNewsTextNew.filter((f, k) => savedPositions.indexOf(k) < 0 ).join("\n"));
          // props.onClose();
        }, 500)
        return;
      }

      const quotaNew = quotaNews[key];
      const apiFn = quotaNew.id ? props.updateQuotaNew : props.addQuotaNew;
      const data = {
        quotaNew: {
          id: quotaNew.id,
          name: quotaNew.name,
          limit: quotaNew.limit,
          survey: props.survey.id,
          blocks: quotaNew.blocks,
        }
      };

      apiFn(data)
        .then(() => {
          save(key+1);
          savedPositions.push(key);
        })
        .catch(e => {
          if(e.response.data.form){
            errorsTmp.push(e.response.data.form.errors);
          }else{
            errorsTmp.push([]);
          }
          save(key+1);
          savedErrorsCount++;
        });
    };

    save(0);
  }

  return <Modal size="xxl" isOpen>
    <ModalHeader>Utwórz kwoty z pola tekstowego</ModalHeader>
    <ModalBody>
      <div className="d-flex flex-column h-100">
        <div>
          <SurveyFilterTextarea
            blocked={saving}
            onChange={(quotaNews, quotaNewsText) => {
              setQuotaNews(quotaNews);
              setQuotaNewsText(quotaNewsText)
            }}
            filtersText={quotaNewsText}
          />
        </div>
        {(saved && !saving) && <div className="mt-3">
          <Alert color="success">Liczba poprawnie zapisanych filtrów: {savedPositions.length}</Alert>
          <Alert color="danger">Liczba filtrów które nie zostały zapisane: {savedErrorsCount}</Alert>
        </div>}
        <div className="mt-3" style={{overflowY: 'auto'}}>
          {quotaNews.map((quotaNew, key) => {
            const e = errors[key] && _.map(errors[key], e => {
              if(_.isArray(e)){
                return <React.Fragment>
                  {e.map(err => <span>{err}</span>)}
                </React.Fragment>
              }
              return <span>{e}</span>;
            });

            return <div key={key} className="d-flex flex-column border shadow p-2 mb-3">
              <h1 className="text-center mb-0">{quotaNew.name ? quotaNew.name : <i>Brak nazwy kwoty</i>} <span className="text-secondary">(Limit {quotaNew.limit})</span></h1>
              <hr />
              {e && <Alert color="danger" className="d-flex flex-column">
                {e}
              </Alert>}
              <div className="d-flex flex-row">
                <div className="w-50 mx-auto">
                  <h2 className="text-center">Warunki</h2>
                  {quotaNew.blocks.map((block, bKey) => <Block
                    changeable={false}
                    headerVisible={(block.children.length + block.condsQuestion.length) <= 1}
                    requiredVisible={false}
                    deleteable={false}
                    key={`b-${bKey}`}
                    block={!_.isEmpty(block) ? block : undefined}
                    handleBlockChanged={(b) => {}}
                    handleBlockDelete={() => {}}
                  />)}
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={e => props.onClose()}>Zamknij</Button>
      <Button disabled={quotaNews.length === 0} loading={saving} onClick={saveQuotaNews}>Zapisz {quotaNews.length > 0 && <span> ({quotaNews.length})</span>}</Button>
    </ModalFooter>
  </Modal>
};

QuotaNewFromTextareaModal.defaultProps = {
  quotaNewsText: '',
};

QuotaNewFromTextareaModal.propTypes = {
  quotaNewsText: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps, {addQuotaNew, updateQuotaNew})(QuotaNewFromTextareaModal);
