import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import PresentationType from "../../../PresentationType";
import SettingBool from "../../../SettingBool";
import SettingNumber from "../../../SettingNumber";
import LibraryValuePattern from "../../../../components/LibraryValuePattern";
import LibraryValues from "../../../LibraryValues";
import SendEmailModal from "../../../SendEmailModal";
import PhoneValidationSms from "../../../PhoneValidationSms";
import OpenRexep from "../../../OpenRexep";

class OpenSettings extends Component {

  render() {
    const {question, questionSettingsPlan} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'required'} value={question.required} icon={<i className="fa-solid fa-message-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'charsLengthMin'} value={question.charsLengthMin || 0} icon={<i className="fa-solid fa-greater-than-equal" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'charsLengthMax'} disabled={!questionSettingsPlan.open.charsLengthMax} value={question.charsLengthMax || 0} icon={<i className="fa-solid fa-less-than-equal" />} handleUpdate={this.props.handleUpdate}/>
        <PresentationType question={question} handleUpdate={this.props.handleUpdate}/>
        {question.presentationType === 'library' && <LibraryValuePattern question={question} handleUpdate={this.props.handleUpdate}/>}
        {question.presentationType === 'library' && <LibraryValues question={question} />}
        {question.presentationType === 'email' && <SendEmailModal question={question} handleUpdate={this.props.handleUpdate} />}
        {question.presentationType === 'email' && <SettingBool question={question} name={'emailVerify'} value={question.emailVerify} icon={<i className="fa-solid fa-envelope-circle-check" />} handleUpdate={this.props.handleUpdate}/>}
        {question.presentationType === 'phone' && <PhoneValidationSms question={question} handleUpdate={this.props.handleUpdate} />}
        {questionSettingsPlan.openRegex && <OpenRexep question={question} handleUpdate={this.props.handleUpdate} />}

      </React.Fragment>
    )
  }
}

OpenSettings.propTypes = {
  question: PropTypes.shape({
    charsLengthMin: PropTypes.number.isRequired,
    charsLengthMax: PropTypes.number.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    questionSettingsPlan: state.user.userPlan.plan.survey.questionSettings
  }
}


export default connect(mapStateToProps)(OpenSettings);
