import countPercent from "../countPercent";

const getContent = (answer) => {
	if (answer.content) {
		return answer.content;
	}

	if (answer.stripedContent) {
		return answer.stripedContent;
	}

	return "";
}

const questionAllowedForPercentAll = (question) => {
	return question.type === 'open' || question.type === 'single' || question.type === 'multi' || question.type === 'multiOpen' || question.type === 'nps'
}

const questionSummaryToStringRows = (summary) => {
	let rows = [];

	const answers = summary.question.type === 'nps' ? summary.data.data.answers : summary.data.answers;
	if (answers) {
		rows.push(["Odpowiedź", "Liczba wskazań"])
		if (questionAllowedForPercentAll(summary.question)) {
			rows[0].push("% ważnych")
			rows[0].push("% w całej próbie")
		}
		if (summary.question.type === 'ranked') {
			answers.forEach((answer) => {
				let rankedString = ""
				for (let place = 1; place <= summary.question.places; place++) {
					rankedString += "Miejsce " + place + " " + answer['place_1'] + "/n"
				}
				rows.push([answer.content, rankedString]);
			});
		} else {
			if (answers) {
				answers.forEach((answer) => {
					let row = [getContent(answer), answer.value.toString()]
					if (questionAllowedForPercentAll(summary.question)) {
						if (summary.question.type === 'multiOpen') {
							row.push(countPercent(answer.value, summary.data.allAnswers) + "%")
							row.push(countPercent(answer.value, summary.data.allValidAnswers) + "%")
						} else {
							row.push(countPercent(answer.value, summary.data.n) + "%")
							row.push(countPercent(answer.value, summary.data.allResults) + "%")
						}
					}
					rows.push(row);
				});
			}
		}

	} else {
		if (summary.question.type === 'maxDiff') {
			rows.push(["Lewa stron", "Odpowiedź", "Prawa strona"])
			summary.data.data.forEach((answer) => {
				rows.push([answer.value_1, answer.content, answer.value_2]);
			});
		}

		if (summary.question.type === 'video') {
			rows.push(["Odpowiedź", "Liczba wskazań"])
			rows.push(["Wyświetlono", summary.data.data.watched])
			rows.push(["Nie wyświetlono", summary.data.data.notWatched])
		}

		if (summary.question.type === 'comment') {
			rows.push(["Odpowiedź", "Liczba wskazań"])
			rows.push(["Obejrzane do końca", summary.data.data.seen])
			rows.push(["Nieobejrzane do końca", summary.data.data.notSeen])
		}
		if (summary.question.type === 'differential') {
			rows.push(["Wiersz", "Średnia"])
			summary.data.data.forEach((answer) => {
				rows.push([answer.leftContent + " - " + answer.rightContent, answer.avg])
			});
		}
	}

	return rows;
}

export default questionSummaryToStringRows;