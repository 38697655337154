import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import AddRespondents from "./TabRespondents/AddRespondents";
import api from "api";
import appRoutes from "appRoutes";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import {Button, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";

class TabRespondents extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: [],
      q: '',
      analyses: []
    };

    this.addRespondents = this.addRespondents.bind(this);
    this.responseFormat = this.responseFormat.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  fetchCsv() {
    api.surveyCollector.panel.get.sendEmail.respondents.searchCsv(this.props.collector.id)
  }

  fetchData(state) {
    this.setState({
      searching: true,
      page: state.page,
      pageSize: state.pageSize
    });

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        q: this.state.q,
        sort: state.sorted
      }
    };

    api.surveyCollector.panel.get.sendEmail.respondents.search(this.props.collector.id, data)
    .then(res => {
      this.setState({
        searching: false,
        data: res.data,
        count: res.count
      });
    })
    .catch(e => this.setState({
      searching: false,
    }));

    api.survey.get.analyses({id: this.props.surveyId})
      .then(res => {
        this.setState({
          analyses: res
        });
      });
  }

  addRespondents(respondents){
    this.setState({loading: false, respondents});
  }

  toggleLoading() {
    this.setState({loading: !this.state.loading})
  }

  responseFormat(response){
    const {surveyId} = this.props;
    const {analyses} = this.state;
    const analysisId = analyses.length > 0 ? analyses[0].id : null;
    const link = analysisId && response ? appRoutes.survey.analysis.browseResponse(surveyId, analysisId, response.id) :
        appRoutes.survey.analysis.main(surveyId);

    return response ?
      <Link
        className={'btn btn-sm ' + (response.status >= 3 ? 'btn-success' : 'btn-warning')}
        to={link}
      >
        <FormattedMessage id={'surveyCollector.typeEmail.addresses.table.response.link.' + (response.status)}/>
      </Link> : <FormattedMessage id="surveyCollector.typeEmail.addresses.table.noAnswer"/>;
  }

  render() {
    const {collector} = this.props;

    const tableColumns = [
      {
      id: 'r.id',
      Header: 'Id',
      accessor: 'id'
    }, {
      id: 'r.guid',
      Header: <FormattedMessage id="surveyCollector.typeEmail.addresses.table.guid"/>,
      accessor: 'guid'
    }, {
      id: 'email',
        sortable:false,
      Header: <FormattedMessage id="surveyCollector.typeEmail.addresses.table.email"/>,
      accessor: row => row.user.emailMasked
    }, {
      Header: <FormattedMessage id="surveyCollector.typeEmail.addresses.table.sentCount"/>,
      sortable:false,
      accessor: 'sentCount'
    }, {
      id: 'surveyResponse.status',
      Header: <FormattedMessage id="surveyCollector.typeEmail.addresses.table.response"/>,
      accessor: row => this.responseFormat(row.surveyResponse)
    }, {
      id: 'response.status',
      Header: <FormattedMessage id="surveyCollector.typeEmail.addresses.table.token"/>,
      accessor: address => this.responseFormat(address.response)
    }];

    return (
      <React.Fragment>
        <div className="d-flex justify-content-between align-items-center">
          <AddRespondents collector={collector} handleAddRespondents={this.addRespondents} toggleLoading={this.toggleLoading}/>

          <InputGroup className={'mb-0 ml-auto'} style={{width: '300px'}}>
            <Input
              placeholder="Szukaj..."
              onChange={e => this.setState({
                q: e.target.value
              })}
              value={this.state.q}
            />
            <InputGroupAddon addonType="append" onClick={() => this.fetchData(this.state)}><InputGroupText className="cursor-pointer"><i className="fa fa-search" /></InputGroupText></InputGroupAddon>
          </InputGroup>
        </div>
        {this.state.count > 0 && (<div className="mt-2">
          <Link color="primary" className="btn btn-primary" to={api.surveyCollector.panel.get.sendEmail.respondents.searchCsv(this.props.collector.id)} target="_blank">
            Export do csv
          </Link>
        </div>)}
        <ReactTable
          manual
          minRows={0}
          className={'table-wrap'}
          data={this.state.data}
          columns={tableColumns}
          showPagination={!_.isEmpty(this.state.data)}
          PaginationComponent={Pagination}
          noDataText={<FormattedMessage id="surveyCollector.typeEmail.addresses.table.noData" />}
          defaultPageSize={10}
          loading={this.state.searching}
          onFetchData={this.fetchData}
          rowsCount={this.state.count}
        />
      </React.Fragment>
    )
  }
}

TabRespondents.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sendEmail: PropTypes.shape({
      respondents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        user: PropTypes.shape({
          email: PropTypes.string.isRequired
        }).isRequired,
        response: PropTypes.shape({
          id: PropTypes.number.isRequired,
          isEnded: PropTypes.bool.isRequired,
        }),
      })).isRequired
    }).isRequired,
  }).isRequired,
  surveyId: PropTypes.string.isRequired
};

export default TabRespondents;