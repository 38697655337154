import React, {Component} from 'react';
import {Form, FormGroup, Label, Input, Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
import history from 'services/history';
import appRoutes from "appRoutes";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import Select from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {prizeCategoryImageLink} from 'services/prizeCategoryImageLink';
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

class PrizeCategoryForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      prizeCategory: {
        name: props.prizeCategory ? props.prizeCategory.name : '',
        description: props.prizeCategory ? props.prizeCategory.description : '',
        slug: props.prizeCategory ? props.prizeCategory.slug : '',
        parent: (props.prizeCategory && props.prizeCategory.parent) ? props.prizeCategory.parent.id : '',
        image: props.prizeCategory ? props.prizeCategory.image : '',
      },
      saving: false,
      parentSelectData: (props.prizeCategory && props.prizeCategory.parent) ? {value: props.prizeCategory.parent.id, label: props.prizeCategory.parent.name} : null,
      imagePath: props.prizeCategory ? prizeCategoryImageLink(props.prizeCategory.image) : '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onParentChange = this.onParentChange.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
    this.savePrizeCategory = this.savePrizeCategory.bind(this);
  }

  onChange(e) {
    this.setState({
      ...this.state,
      prizeCategory: {
        ...this.state.prizeCategory,
        [e.target.name]: e.target.value
      }
    });
  }

  onImageUpload(e) {
    this.setState({
      ...this.state,
      imagePath: URL.createObjectURL(e.target.files[0]),
      prizeCategory: {
        ...this.state.prizeCategory,
        image: e.target.files[0],
      }
    });
  }

  onParentChange(parent) {
    this.setState({
      ...this.state,
      parentSelectData: parent,
      prizeCategory: {
        ...this.state.prizeCategory,
        parent: parent.value,
      }
    });
  }

  savePrizeCategory(e) {
    e.preventDefault();

    this.setState({
      ...this.state,
      saving: true
    });

    this.props.handleSave({
      id: this.props.prizeCategory ? this.props.prizeCategory.id : null,
      prizeCategory: {
        name: this.state.prizeCategory.name,
        description: this.state.prizeCategory.description,
        slug: this.state.prizeCategory.slug,
        parent: this.state.prizeCategory.parent,
        image: this.state.prizeCategory.image
      }
    }).then(() => {
      toast.success(<FormattedMessage id="admin.prizeCategoryForm.toast.success" />);
      history.push(appRoutes.admin.prizes.categories.list)
    })
      .catch(res => {
        this.setState({
          ...this.state,
          errors: res.response.data.form.errors,
          saving: false
        })
      })
  }

  render() {
    let {errors, prizeCategory, parentSelectData, imagePath, saving} = this.state;
    return (
      <Form>
        <FormGroup>
          <Label for="name"><FormattedMessage id="admin.prizeCategoryForm.label.name" /></Label>
          <Input type="text" name="name" id="name" value={prizeCategory.name} invalid={!!errors.name} onChange={this.onChange}/>
          {errors.name && <p className="help-block text-danger">{errors.name}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="description"><FormattedMessage id="admin.prizeCategoryForm.label.description" /></Label>
          <Input type="textarea" name="description" id="description" value={prizeCategory.description} invalid={!!errors.description} onChange={this.onChange}/>
          {errors.description && <p className="help-block text-danger">{errors.description}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="slug"><FormattedMessage id="admin.prizeCategoryForm.label.slug" /></Label>
          <Input type="text" name="slug" id="slug" value={prizeCategory.slug} invalid={!!errors.slug} onChange={this.onChange}/>
          {errors.slug && <p className="help-block text-danger">{errors.slug}</p>}
        </FormGroup>
        <FormGroup>
          <Label><FormattedMessage id="admin.prizeCategoryForm.label.parent" /></Label>
          <Select
            value={parentSelectData}
            onChange={this.onParentChange}
            options={_.map(this.props.prizeCategories, prizeCategory => {
              return {
                value: prizeCategory.id,
                label: prizeCategory.name
              }
            })}
          />
          {errors.parent && <p className="help-block text-danger">{errors.parent}</p>}
        </FormGroup>
        <FormGroup>
          <Label for="slug"><FormattedMessage id="admin.prizeCategoryForm.label.image" /></Label>
          <Input type="text" name="image" id="image" value={prizeCategory.image} invalid={!!errors.image} onChange={this.onChange}/>
          {errors.image && <p className="help-block text-danger">{errors.image}</p>}
        </FormGroup>
        <Link to={appRoutes.admin.prizes.categories.list}>
          <Button color="secondary"><FormattedMessage id="_.button.back" /></Button>
        </Link>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda float-right"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.savePrizeCategory}
          >{message}</LaddaButton>
        }</FormattedMessage>
      </Form>
    )
  }
}

PrizeCategoryForm.propTypes = {
  handleSave: PropTypes.func.isRequired,
  prizeCategory: PropTypes.object
};

export default PrizeCategoryForm;