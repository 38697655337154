import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip} from "reactstrap";
import { connect } from 'react-redux';
import {FormattedMessage} from "react-intl";
import classnames from 'classnames';

class GlobalTemplates extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      selectedTemplate: null
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({modal: !this.state.modal})
  }

  updateValue(templateId){
    this.setState({modal: false});
    this.props.handlerUpdate(templateId);
  }

  onDoubleClick(template) {
    if (template.id !== this.props.currentTemplateId) {
      this.updateValue(template.id)
    }
  }

  onClick(template) {
    let selectedTemplate = template.id === this.props.currentTemplateId ? null : template;
    this.setState({selectedTemplate})
  }

  render() {
    const inactive = !_.find(this.props.globalTemplates, template => template.id === this.props.currentTemplateId);

    const modal = <Modal id="template-picker" isOpen={this.state.modal} toggle={this.toggleModal} size="xl" className="mw-100">
      <ModalHeader toggle={this.toggleModal} tag="h2">Wybierz gotowy szablon</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Row>
          {this.props.globalTemplates.map(template => {
            return <Col xl={3} md={6} sm={12} key={template.id} style={{borderRadius: 10}} className={classnames({
              "text-center py-3": true,
              "bg-primary": this.props.currentTemplateId === template.id,
              "bg-secondary": this.state.selectedTemplate === template
            })}>
              <img
                src={'/surveyStyles/images/' + template.id + '.png'}
                id={"globalTemplate-" + template.id}
                onClick={() => this.onClick(template)}
                onDoubleClick={() => this.onDoubleClick(template)}
                className="mw-100 mh-100 pointer"
              />
              <UncontrolledTooltip placement="top" target={"globalTemplate-" + template.id} delay={0}>
                {template.name}
              </UncontrolledTooltip>
            </Col>
          })}
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
             <Button
                className="btn btn-success mb-0 ml-3"
                onClick={() => this.updateValue(this.state.selectedTemplate.id)}
                disabled={!this.state.selectedTemplate}
              >
                <FormattedMessage id="_.button.choose" />
              </Button>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <Row className={classnames({
        'align-items-center': true,
        'inactive': inactive,
      })}>
        <label className="col col-4">Ogólnodostępne szablony {!inactive && <i className="icon-clear-value fas fa-trash float-right" onClick={() => this.updateValue(null)} />}</label>
        <Col className="col-8">
          <Button onClick={this.toggleModal} className="w-100">Przeglądaj</Button>
        </Col>
        {modal}
      </Row>
    );
  }
}

GlobalTemplates.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    globalTemplates: state.config.data.survey.globalTemplates,
    currentTemplateId: state.survey.structure.data.survey.templateId,
  }
}

export default connect(mapStateToProps)(GlobalTemplates);