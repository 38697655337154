import React, {Component} from 'react';
import { Col, Form, FormGroup, Input, Label} from "reactstrap";
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import { connect } from 'react-redux';

class AdminForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        firstname: props.user ? props.user.firstname : '',
        phone: props.user ? props.user.phone : '',
        roles: props.user ? props.user.roles : ['ROLE_ADMIN'],
      }
    };

    if (!props.user) {
      this.state.user.password = '';
      this.state.user.email = '';
    }

    this.onChange = this.onChange.bind(this);
    this.onChangeRoles = this.onChangeRoles.bind(this);
  }

  static getAvailableRoles(){
    return [
      {
        label: 'Admin',
        value: 'ROLE_ADMIN',
      },{
        label: 'Zarządzanie ankietami SwPanel',
        value: 'ROLE_ADMIN_SURVEYS_SWPANEL',
      },
      {
        label: 'Reporter',
        value: 'ROLE_ADMIN_REPORTER',
      },
      {
        label: 'Ankieteo CMS',
        value: 'ROLE_ADMIN_ANKIETEO_CMS',
      },
      {
        label: 'Swresearch CMS',
        value: 'ROLE_ADMIN_SWRESEARCH_CMS',
      },
      {
        label: 'Zarządzanie respondentami',
        value: 'ROLE_ADMIN_USER_RESPONDENTS',
      },
      {
        label: 'Zarządzanie firmami',
        value: 'ROLE_ADMIN_USER_COMPANIES',
      },
      {
        label: 'Zarządzanie wymianą nagród',
        value: 'ROLE_ADMIN_USER_PRIZE',
      },
      {
        label: 'Przegląd wywiadów',
        value: 'ROLE_ADMIN_SURVEYS_RESPONSES',
      },
      {
        label: 'Wgrywanie plików',
        value: 'ROLE_ADMIN_FILES',
      },
      {
        label: 'Rankingi',
        value: 'ROLE_ADMIN_RANKINGS',
      },
      {
        label: 'Zarządzanie planami',
        value: 'ROLE_ADMIN_PLANS',
      },
      {
        label: 'Przegląd ankiet',
        value: 'ROLE_ADMIN_SURVEYS',
      },
      {
        label: 'Przegląd wysyłek zaproszeń',
        value: 'ROLE_ADMIN_SURVEY_SHIPPING',
      },
    ];
  }

  onChange(e) {
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value
      }
    });
  }

  onChangeRoles(values) {
    let newRoles = values.map(r => r.value);

    if(newRoles.indexOf('ROLE_ADMIN') < 0){
      newRoles.unshift('ROLE_ADMIN');
    }

    this.setState({
      user: {
        ...this.state.user,
        roles: newRoles
      }
    })
  }

  render() {
    const {user} = this.state;
    const {errors, onSubmit} = this.props;

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">Imię</Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="firstname" id="firstname" value={user.firstname} invalid={!!errors.firstname} onChange={this.onChange}/>
            {errors.firstname && <p className="help-block text-danger">{errors.firstname}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">Numer telefonu</Label>
          <Col lg={9} className="pt-1">
            <Input type="text" placeholder={'500600700'} name="phone" id="phone" value={user.phone} invalid={!!errors.phone} onChange={this.onChange}/>
            {errors.phone && <p className="help-block text-danger">{errors.phone}</p>}
          </Col>
        </FormGroup>
        {!this.props.user && <FormGroup row>
          <Label lg={3} className="text-lg-right">Email</Label>
          <Col lg={9} className="pt-1">
            <Input type="email" name="email" id="email" value={user.email} invalid={!!errors.email} onChange={this.onChange}/>
            {errors.email && <p className="help-block text-danger">{errors.email}</p>}
          </Col>
        </FormGroup>}
        {!this.props.user && <FormGroup row>
          <Label lg={3} className="text-lg-right">Hasło</Label>
          <Col lg={9} className="pt-1">
            <Input type="password" name="password" id="password" value={user.password} invalid={!!errors.password} onChange={this.onChange}/>
            {errors.password && <p className="help-block text-danger">{errors.password}</p>}
          </Col>
        </FormGroup>}
        <FormGroup row>
          <Label lg={3} className="text-lg-right">Role</Label>
          <Col lg={9} className="pt-1">
            <Select
              isMulti
              options={AdminForm.getAvailableRoles()}
              value={user.roles.map(roleName => {
                let role = AdminForm.getAvailableRoles().find(r => r.value === roleName);

                return {
                  isDisabled: true,
                  label: role.label,
                  value: role.value,
                }
              })}
              onChange={this.onChangeRoles}
            />
            {errors.firstname && <p className="help-block text-danger">{errors.firstname}</p>}
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

AdminForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  user: PropTypes.object,
  plans: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    plans: state.admin.plans.data.plans,
    trades: state.config.data.trades
  }
}

export default connect(mapStateToProps, {}, null, {forwardRef: true})(AdminForm);
