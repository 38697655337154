import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {InputGroupAddon, Tooltip, Button} from "reactstrap";
import InputNumber from "components/InputNumber";
import classnames from "classnames";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";

class DateTimeIntervals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateTimeIntervalsTooltip: false,
      question: {
        dateTimeIntervals: parseInt(props.question.dateTimeIntervals),
      }
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip(type) {
    this.setState({
      ...this.state,
      [type]: !this.state[type]
    })
  }

  onChange(value, target) {
    if (this.props.disabled) {
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());
      return;
    }
    this.setState({
      ...this.state,
      question: {
        ...this.state.question,
        [target]: value
      }
    }, () => this.props.handleUpdate(this.state.question));
  }

  render() {
    const {question, dateTimeIntervalsTooltip} = this.state;
    const {id} = this.props.question;

    return (
      <React.Fragment>
        <div
          id={"dateTimeIntervals" + id}
          className={classnames({
            "min-w-0 float-left mr-1 mb-1": true,
            "w-auto": question.dateTimeIntervals === 0,
            "w-100 w-lg-auto input-group": question.dateTimeIntervals > 0
          })}
        >
          <InputGroupAddon addonType="prepend">
            <Button
              className={classnames({
                "btn-icon": true,
                "active": question.dateTimeIntervals > 0,
                "disabled": this.props.disabled
              })}
              color="secondary"
              onClick={() => {
                if(question.dateTimeIntervals === 0){
                  this.onChange(5, 'dateTimeIntervals')
                }else{
                  this.onChange(0, 'dateTimeIntervals')
                }
              }}
            >
              <i className="fak fa-clipboard-list-min"/>
              <Tooltip
                delay={0}
                placement="top"
                isOpen={dateTimeIntervalsTooltip}
                target={"dateTimeIntervals" + id}
                toggle={() => this.toggleTooltip('dateTimeIntervalsTooltip')}
              >Interwał co 15,30</Tooltip>
            </Button>
          </InputGroupAddon>
          {question.dateTimeIntervals > 0 && <InputNumber step={5} value={question.dateTimeIntervals} min={0} onChange={(value) => this.onChange(value, 'dateTimeIntervals')} className={this.props.disabled ? "not-active" : ""}/>}
        </div>
      </React.Fragment>
    )
  }
}

DateTimeIntervals.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    dateTimeIntervals: PropTypes.number.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default connect(null, {setPlanRestrictionsModals})(DateTimeIntervals);
