import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import _ from "lodash";
import DropdownToggle from "reactstrap/es/DropdownToggle";
import DropdownMenu from "reactstrap/es/DropdownMenu";
import DropdownItem from "reactstrap/es/DropdownItem";
import {Dropdown} from "reactstrap";
import QuestionAnswers from "../../../Modals/QuestionAnswers";
import QuestionRows from "./QuestionRows";
import QuestionColumns from "./QuestionColumns";
import QuestionBaskets from "./QuestionBaskets";
import Question from "../../../Modals/Question";
import {injectIntl} from "react-intl";

class QuestionDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpenActionTypeDropdown: false,
    };
  }

  render() {
    const {action, questions, intl, changeable} = this.props;
    const {isOpenActionTypeDropdown} = this.state;

    const question = questions.find(q => q.id === action.question);

    return (
      <React.Fragment>
        <Question
          enabledTypes={['single', 'multi', 'basket', 'matrix', 'matrixMulti', 'ranked', 'conjoint', 'numericMulti', 'differential', 'matrixDropdown']}
          question={action.question} changeable={changeable} handleQuestionChanged={qId => {
          const question = questions.find(q => q.id === qId);
          let type = null;
          if('answers' in question){
            type = 'questionAnswers';
          } else if('rows' in question){
            type = 'questionRows';
          }

          let actionUpdated = {
            ...action,
            question: qId,
            answers: [],
            rows: [],
            columns: [],
            baskets: [],
            type,
          };

          this.props.handleActionChanged(actionUpdated)
        }} />


        {action.question && (changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenActionTypeDropdown} toggle={() => this.setState({isOpenActionTypeDropdown: !isOpenActionTypeDropdown})}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.questionDetails.' + action.type})}</DropdownToggle>
          <DropdownMenu>
            {'answers' in question && <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              type: 'questionAnswers',
            })}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.questionDetails.questionAnswers'})}</h5></DropdownItem>}
            {'rows' in question && <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              type: 'questionRows',
            })}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.questionDetails.questionRows'})}</h5></DropdownItem>}
            {'columns' in question && <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              type: 'questionColumns',
            })}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.questionDetails.questionColumns'})}</h5></DropdownItem>}
            {'baskets' in question && <DropdownItem tag="a" onClick={() => this.props.handleActionChanged({
              ...action,
              type: 'questionBaskets',
            })}><h5>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.questionDetails.questionBaskets'})}</h5></DropdownItem>}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.action.actionType-actionVisibility.questionDetails.' + action.type})} </span>)}

        {action.type === 'questionAnswers' && <QuestionAnswers question={action.question} answers={action.answers} handleAnswersChanged={answers => this.props.handleActionChanged({...action, answers})} changeable={changeable}/>}
        {action.type === 'questionRows' && <QuestionRows action={action} handleRowsChanged={rows => this.props.handleActionChanged({...action, rows})} changeable={changeable}/>}
        {action.type === 'questionColumns' && <QuestionColumns action={action} handleColumnsChanged={columns => this.props.handleActionChanged({...action, columns})} changeable={changeable}/>}
        {action.type === 'questionBaskets' && <QuestionBaskets action={action} handleBasketsChanged={baskets => this.props.handleActionChanged({...action, baskets})} changeable={changeable}/>}
      </React.Fragment>
    );
  }
}

QuestionDetails.defaultProps = {
  changeable: true
};

QuestionDetails.propTypes = {
  action: PropTypes.shape({
    question: PropTypes.number,
  }).isRequired,
  handleActionChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    questions
  }
}

export default connect(mapStateToProps)(injectIntl(QuestionDetails));