import * as React from 'react';
import PropTypes from "prop-types";
import Select from "react-select";

function FilterActionVisibilitySelect(props) {
  const [value, setValue] = React.useState(props.value);

  const options = [
    {
      label: 'Pokaż',
      value: true,
    },
    {
      label: 'Ukryj',
      value: false,
    },
  ]

  return (
    <Select
      options={options}
      value={options.find(o => o.value === value)}
      onChange={option => {
        setValue(option.value);
        props.onChange(option.value);
      }}
    />
  );
}

FilterActionVisibilitySelect.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default FilterActionVisibilitySelect;