import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from "react-intl";
import {updateSurvey} from "../../data/survey/actions";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Input
} from 'reactstrap';
import ScreenOutText from "./components/ScreenOutText";
import InaccessibilityText from "./components/InaccessibilityText";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import SurveySettingAvailable from "components/PlanRestrictionsModals/modals/SurveySettingAvailable";
import EndingCodes from "./components/EndingCodes/EndingCodes";
import AnalysisSelect from "../../../../../components/Survey/Analysis/AnalysisSelect";
import EndingScreensList from "./components/EndingScreen/endingScreensList";

class EndingText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endingText: props.endingText,
      screenOutText: props.screenOutText,
      inaccessibilityText: props.inaccessibilityText,
      endingTextEditing: false,
      screenOutTextEditing: false,
      screenOutToggle: props.screenOutText !== undefined,
      inaccessibilityToggle: props.inaccessibilityText !== undefined,
      endingCodesToggle: props.endingCodesExists,
      analysisOnEndScreenToggle: !!props.analysisOnEndScreenId,
      savingEnding: false,
      dropdownSettingsOpen: false,
    };
    this.toggleEditing = this.toggleEditing.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
  }

  toggleEditing(type) {
    if (type === 'inaccessibilityToggle' && !this.props.surveyPlan.inaccessibilityText) {
      this.props.setPlanRestrictionsModals(SurveySettingAvailable.getName());
      return;
    }
    this.setState({
      [type]: !this.state[type]
    });
  }

  handleToggleDropdown() {
    this.setState({
      screenOutToggle: !this.state.screenOutToggle
    })
  }

  onChange(value, type) {
    this.setState({
      [type]: value
    });
  }

  updateContent(savingType, editingType) {
    this.setState({
      [savingType]: true
    });

    const data = {
      id: this.props.surveyId,
      survey: {
        screenOutText: this.state.screenOutText,
      }
    };

    this.props.updateSurvey(data)
      .then(() => {
        this.setState({
          [savingType]: false,
          [editingType]: false
        });
      })
      .catch(() => {
        this.setState({
          [savingType]: false
        })
      });
  }

  render() {
    const {savingEnding, endingText, endingTextEditing, dropdownSettingsOpen, screenOutToggle, screenOutText, inaccessibilityToggle, endingCodesToggle, analysisOnEndScreenToggle, inaccessibilityText} = this.state;

    return (
      <Card className="border-l yellow">
        <CardHeader className="d-print-none">
          <div className="float-left" data-toggle="collapse" data-target="#collapse2">
            <h4 className="float-left"><FormattedMessage id="surveyStructure.endingText.cardHeader"/></h4>
          </div>
          <Dropdown
            className="float-right"
            isOpen={dropdownSettingsOpen}
            toggle={() => this.toggleEditing('dropdownSettingsOpen')}
          >
            <DropdownToggle color="">
              <span className="fas fa-ellipsis-v pt-2 hoverable" />
            </DropdownToggle>
            <DropdownMenu>
              {!this.props.screenOutText && <DropdownItem tag="a" onClick={() => this.toggleEditing('screenOutToggle')}>
                <p><span className='fas fa-plus align-middle'/></p> <small className="align-middle"><FormattedMessage tagName="h5" id="surveyStructure.endingText.cardHeader.addScreenOut"/></small>
              </DropdownItem>}
              <DropdownItem tag="a" disabled={inaccessibilityToggle} className={this.props.surveyPlan.inaccessibilityText ? '' : 'not-active'} onClick={() => this.toggleEditing('inaccessibilityToggle')}>
                <p><span className='fas fa-plus align-middle'/></p> <small className="align-middle"><FormattedMessage tagName="h5" id="surveyStructure.endingText.cardHeader.addInaccessibility"/></small>
              </DropdownItem>
              {this.props.surveyPlan.endingCodes && <DropdownItem tag="a" onClick={() => this.toggleEditing('endingCodesToggle')}>
                <p><span className='fas fa-plus align-middle'/></p> <small className="align-middle"><h5>Dodaj treści indywidualne / kody</h5></small>
              </DropdownItem>}
              {this.props.surveyPlan.analysisChartsOnEndScreen && <DropdownItem tag="a" onClick={() => this.toggleEditing('analysisOnEndScreenToggle')}>
                <p><span className='fas fa-plus align-middle'/></p> <small className="align-middle"><h5>Wstaw wykresy z wynikami</h5></small>
              </DropdownItem>}
            </DropdownMenu>
          </Dropdown>

        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <EndingScreensList />

          <div className="d-print-none">
            {analysisOnEndScreenToggle && <div className="mt-3">
              <h2>Wstaw wykres z wynikami</h2>
              <AnalysisSelect
                surveyId={this.props.surveyId}
                value={this.props.analysisOnEndScreenId}
                onChange={analysisOnEndScreen => this.props.updateSurvey({
                  id: this.props.surveyId,
                  survey: {
                    analysisOnEndScreen,
                  }
                })}
              />
            </div>}

            <ScreenOutText
              surveyId={this.props.surveyId}
              screenOutToggle={screenOutToggle}
              screenOutText={screenOutText}
              handleScreenOutToggle={() => this.toggleEditing('screenOutToggle')}
            />

            <InaccessibilityText
              surveyId={this.props.surveyId}
              inaccessibilityToggle={inaccessibilityToggle}
              inaccessibilityText={inaccessibilityText}
              handleInaccessibilityToggle={() => this.toggleEditing('inaccessibilityToggle')}
            />

            {endingCodesToggle && <EndingCodes surveyId={this.props.surveyId} />}
          </div>
        </CardBody>
      </Card>
    );
  }
}

EndingText.propTypes = {
  surveyId: PropTypes.string.isRequired,
  endingText: PropTypes.string,
  screenOutText: PropTypes.string,
  inaccessibilityText: PropTypes.string
};

function mapStateToProps(state) {
  return {
    endingText: state.survey.structure.data.survey.endingText,
    screenOutText: state.survey.structure.data.survey.screenOutText,
    inaccessibilityText: state.survey.structure.data.survey.inaccessibilityText,
    endingCodesExists: state.survey.structure.data.survey.endingCodesExists,
    analysisOnEndScreenId: state.survey.structure.data.survey.analysisOnEndScreenId,
    surveyPlan: state.user.userPlan.plan.survey,
    plan: state.user.userPlan.plan
  };
}

export default connect(mapStateToProps, { updateSurvey, setPlanRestrictionsModals })(EndingText)
