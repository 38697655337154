import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Card, CardBody, CardHeader} from "reactstrap";
import SettingBool from "./components/SettingBool";
import SettingNumber from "./components/SettingNumber";
import Instruction from "./components/Instruction";
import {updateQuestion} from "scenes/Survey/Structure/data/questions/actions";
import SingleSettings from "./components/QuestionTypeSettings/components/SingleSettings";
import MultiSettings from "./components/QuestionTypeSettings/components/MultiSettings";
import MultiOpenSettings from "./components/QuestionTypeSettings/components/MultiOpenSettings";
import VideoSettings from "./components/QuestionTypeSettings/components/VideoSettings";
import MatrixSettings from "./components/QuestionTypeSettings/components/MatrixSettings";
import RankedSettings from "./components/QuestionTypeSettings/components/RankedSettings";
import ConjointSettings from "./components/QuestionTypeSettings/components/ConjointSettings";
import OpenSettings from "./components/QuestionTypeSettings/components/OpenSettings";
import MatrixMultiSettings from "./components/QuestionTypeSettings/components/MatrixMultiSettings";
import MatrixDropdownSettings from "./components/QuestionTypeSettings/components/MatrixDropdownSettings";
import NumericSettings from "./components/QuestionTypeSettings/components/NumericSettings";
import NumericMultiSettings from "./components/QuestionTypeSettings/components/NumericMultiSettings";
import BasketSettings from "./components/QuestionTypeSettings/components/BasketSettings";
import GmapSettings from "./components/QuestionTypeSettings/components/GmapSettings";
import NpsSettings from "./components/QuestionTypeSettings/components/NpsSettings";
import MaxDiffSettings from "./components/QuestionTypeSettings/components/MaxDiffSettings";
import DifferentialSettings from "./components/QuestionTypeSettings/components/DifferentialSettings";
import BptoSettings from "./components/QuestionTypeSettings/components/BptoSettings";
import CbcSettings from "./components/QuestionTypeSettings/components/CbcSettings";
import SupplementMax from "./components/SupplementMax";
import FileExtensions from "./components/FileExtensions";
import CssCode from "./components/CssCode";
import Javascripts from "./components/Javascripts";
import ValidationText from "./components/ValidationText";

class Settings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      fileUpload: props.question.fileUpload
    };

    this.handledUpdateQuestion = this.handledUpdateQuestion.bind(this);
  }

  handledUpdateQuestion(question){
    this.setState({errors: {}});

    return this.props.updateQuestion({
      id: this.props.question.id,
      question
    }).catch(e => this.setState({errors: e.response.data.form.errors}));
  }

  getQuestionTypeSettings(type) {
    switch (type) {
      case 'matrixMulti':
        return <MatrixMultiSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'matrix':
        return <MatrixSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'matrixDropdown':
        return <MatrixDropdownSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'multi':
        return <MultiSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'multiOpen':
        return <MultiOpenSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'open':
        return <OpenSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'ranked':
        return <RankedSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'conjoint':
        return <ConjointSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'numeric':
        return <NumericSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'numericMulti':
        return <NumericMultiSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'single':
        return <SingleSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'video':
        return <VideoSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion} errors={this.state.errors}/>;
      case 'basket':
        return <BasketSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'gMap':
        return <GmapSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'nps':
        return <NpsSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'maxDiff':
        return <MaxDiffSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'differential':
        return <DifferentialSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'bpto':
        return <BptoSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
      case 'cbc':
        return <CbcSettings question={this.props.question} handleUpdate={this.handledUpdateQuestion}/>;
    }
  }

  render() {
    const { question, questionSettingsPlan, planSurvey } = this.props;

    return (
      <Card>
        <CardHeader>
          <h2><FormattedMessage id="question.settings.header"/></h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody style={{zIndex: 0}}>
          <div className="d-flex">
            <div className="d-flex flex-wrap flex-grow-1">
              <Instruction question={question} handleUpdate={this.handledUpdateQuestion}/>
              <ValidationText question={question} handleUpdate={this.handledUpdateQuestion}/>

              {this.getQuestionTypeSettings(question.type)}

              <SupplementMax question={question} disabled={!questionSettingsPlan.supplementMax} handleUpdate={this.handledUpdateQuestion}/>
              <SettingBool question={question} name={'disabled'} disabled={!questionSettingsPlan.disabled} value={question.disabled} icon={<i className="fa-solid fa-power-off" />} handleUpdate={this.handledUpdateQuestion}/>
              <SettingBool question={question} name={'fileUpload'} disabled={!questionSettingsPlan.fileUpload} value={question.fileUpload} icon={<i className="fa-solid fa-file-arrow-up" />} handleUpdate={question => {this.setState({fileUpload: question.fileUpload}); this.handledUpdateQuestion(question)}}/>
              {this.state.fileUpload && <SettingNumber question={question} name={'fileMaxSize'} disabled={!questionSettingsPlan.fileUpload} step={1000} value={question.fileMaxSize} icon={<i className="fas fa-weight-hanging" />} handleUpdate={this.handledUpdateQuestion}/>}
              {this.state.fileUpload && <FileExtensions question={question} disabled={!questionSettingsPlan.fileUpload} handleUpdate={this.handledUpdateQuestion}/>}
            </div>

            <div className="d-flex ">
              <CssCode disabled={!planSurvey.questionJsCssAccess} question={question} handleUpdate={this.handledUpdateQuestion}/>
              <Javascripts disabled={!planSurvey.questionJsCssAccess} question={question} handleUpdate={this.handledUpdateQuestion}/>
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

Settings.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    planSurvey: state.user.userPlan.plan.survey,
    questionSettingsPlan: state.user.userPlan.plan.survey.questionSettings
  }
}

export default connect(mapStateToProps, { updateQuestion })(Settings);
