import * as React from 'react';
import PropTypes from "prop-types";
import InputNumber from "components/InputNumber";
import Button from "components/Button";
import {Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {updateAnswer} from "scenes/Survey/Structure/data/answers/actions";
import {connect} from "react-redux";

function SelectedMaxModal(props) {
  const [selectedMax, setSelectedMax] = React.useState(parseInt(props.selectedMax));
  const [selectedCurrent, setSelectedCurrent] = React.useState(parseInt(props.selectedCurrent));
  const [saving, setSaving] = React.useState(false);

  const save = () => {
    setSaving(true);

    const data = {
      id: props.answer.id,
      answer: {
        selectedMax,
        selectedCurrent,
      }
    };

    props.updateAnswer(data)
    .then(() => {
      setSaving(false);
      props.onModalClose();
    });
  }

  return <Modal isOpen toggle={props.onModalClose} className="height-100vh" size={"lg"}>
    <ModalHeader toggle={props.onModalClose}>Maksymalna liczba wskazań tej odpowiedzi</ModalHeader>
    <ModalBody>
      <Form>
        <FormGroup row>
          <Label xs={5}>Maksymalna liczba zaznaczeń:</Label>
          <Col xs={7}>
            <InputNumber
              onChange={selectedMax => setSelectedMax(selectedMax)}
              step={1}
              value={selectedMax}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label xs={5}>Aktualna liczba zaznaczeń:</Label>
          <Col xs={7}>
            <InputNumber
              onChange={selectedCurrent => setSelectedCurrent(selectedCurrent)}
              step={1}
              value={selectedCurrent}
            />
          </Col>
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" className="mb-0" onClick={props.onModalClose}>Anuluj</Button>
      <Button
        color="primary"
        className="mb-0"
        loading={saving}
        onClick={save}
      >Zapisz</Button>
    </ModalFooter>
  </Modal>
}

SelectedMaxModal.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  selectedMax: PropTypes.number,
  selectedCurrent: PropTypes.number,
  onModalClose: PropTypes.func.isRequired,
};

export default connect(null, {updateAnswer})(SelectedMaxModal);