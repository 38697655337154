import * as React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Alert, Input} from "reactstrap";
import {updateAttribute} from "../../../../../../../../../../../../../../../../../../../data/attributes/actions";

function AttributeName(props) {
  const [value, setValue] = React.useState(props.attribute.name);
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => setEditing(!editing);
  const [saving, setSaving] = React.useState(false);

  const {attribute} = props;

  const handlerOnKeyDown = (e) => {
    switch (e.keyCode) {
      case 13: {
        update();
        break;
      }
      case 27: {
        setValue(props.attribute.name);
        setEditing(false);

        break;
      }
    }
  }

  const update = () => {
    if(value !== props.attribute.name){
      setSaving(true);
      props.updateAttribute(props.attribute.id, {name: value})
      .then(() => {
        setSaving(false);
        setEditing(false);
      })
    }else{
      setEditing(false);
    }
  }

  if(editing){
    return <Input
      className="m-0"
      disabled={saving}
      value={value}
      placeholder="Nazwa atrybutu"
      onBlur={update}
      onChange={e => setValue(e.target.value)}
      onKeyDown={handlerOnKeyDown}
      autoFocus
      size="sm"
    />
  }

  return <span className="w-100" onClick={toggleEditing}><span>{attribute.name || <Alert color="warning" className="my-0 py-0"><small className="font-italic"><strong>Uzupełnij nazwę atrybutu</strong></small></Alert> }</span></span>
}

AttributeName.propTypes = {
  attribute: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  updateAttribute: PropTypes.func.isRequired,
};

export default connect(null, {updateAttribute})(AttributeName);