import { combineReducers } from 'redux'

import {reducer as configReducer} from "reducers/config/reducer";
import {reducer as planRestrictionsModalsReducer} from "reducers/planRestrictionsModals/reducer";
import {reducer as adminReducer} from "scenes/Admin/reducer";
import {reducer as userReducer} from "reducers/user/reducer";
import {reducer as tagsReducer} from "reducers/tags/reducer";
import {reducer as dashboardOptionsReducer} from "reducers/dashboardOptions/reducer";
import {reducer as surveyReducer} from "scenes/Survey/reducer";
import {reducer as widgetsReducer} from "scenes/Dashboard/scenes/Widgets/reducer";
import {reducer as globalTemplatesReducer} from "reducers/globalTemplates/reducer";
import {reducer as addressesReducer} from "scenes/Addresses/combineReducer";
import {reducer as respondentBasesReducer} from "scenes/RespondentBases/reducer";
import {reducer as groupsReducer} from "scenes/Group/combineReducer";
import {reducer as surveySharesReducer} from "scenes/SurveyShare/combineReducer";
import {reducer as reportsReducer} from "reducers/reports/reducer";
import {reducer as clientsReducer} from "scenes/Clients/combineReducer";
import {reducer as smtpsReducer} from "scenes/Smtps/combineReducer";

export default combineReducers({
  config: configReducer,
  planRestrictionsModals: planRestrictionsModalsReducer,
  admin: adminReducer,
  user: userReducer,
  dashboardOptions: dashboardOptionsReducer,
  survey: surveyReducer,
  tags: tagsReducer,
  widgets: widgetsReducer,
  globalTemplates: globalTemplatesReducer,
  respondentBases: respondentBasesReducer,
  addresses: addressesReducer,
  groups: groupsReducer,
  surveyShares: surveySharesReducer,
  reports: reportsReducer,
  clients: clientsReducer,
  smtps: smtpsReducer,
})
