import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import { connect } from 'react-redux';
import FilterIterative from "./components/FilterIterative";
import {addFilterIterative} from "scenes/Survey/Structure/data/filterIterative/actions";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import FiltersAvailable from "components/PlanRestrictionsModals/modals/FiltersAvailable";
import classnames from 'classnames';
import _ from "lodash";

class QuestionFrom extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      tooltip: false,
      adding: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleModal() {
    this.setState({modal: !this.state.modal})
  }

  addFilter() {
    this.setState({...this.state, adding: true});
    const filterByKeys = ['answers', 'rows', 'columns'];
    const filterBy = _.find(filterByKeys, key => this.props.question.hasOwnProperty(key));

    let data = {
      name: 'Filtr iteracyjny',
      questionFrom: {
        question: this.props.question.id,
        filterBy: this.props.filterBy || filterBy
      }
    };

    this.props.addFilterIterative({surveyId: this.props.survey.id, filterIterative: data})
      .then(() => this.setState({...this.state, adding: false}));
  }

  onClick() {
    this.props.plan.survey.filters
      ? this.toggleModal()
      : this.props.setPlanRestrictionsModals(FiltersAvailable.getName());
  }

  render() {
    const {tooltip, adding} = this.state;
    const {survey, questions, filterIterative, type} = this.props;

    let filtersRender = _.map(filterIterative, (filter, i) => {
      return <FilterIterative filter={filter} surveyId={survey.id} questions={questions} key={filter.id} type={type} questionFrom={this.props.question} position={++i}/>
    });

    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="lg">
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="question.filterIterative.questionFrom.modalHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        {filtersRender}
        {questions.length === 0 && <span className="small text-danger"><FormattedMessage id="question.filterIterative.questionFrom.noQuestionToAvailable"/></span>}
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="question.filterIterative.questionFrom.button.create">{
              (message) => <LaddaButton
                  className="btn btn-success mr-auto btn-ladda mb-0 ml-3"
                  loading={adding}
                  data-style={ZOOM_OUT}
                  onClick={this.addFilter}
                  disabled={questions.length === 0}
              >
                  {message}
                </LaddaButton>}
            </FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <span id={'questionFrom-' + this.props.question.id + '-iterativeFilter-' + (type ? type : '')} onClick={this.onClick} className="d-none d-lg-inline">
          <i className={classnames({
            'fas fa-step-forward pointer': true,
            'hoverable' : this.props.filterIterative.length === 0,
            'text-primary': this.props.filterIterative.length > 0
          })}/>
          <Tooltip
            delay={0}
            placement="bottom"
            isOpen={tooltip}
            target={'questionFrom-' + this.props.question.id + '-iterativeFilter-' + (type ? type : '')}
            toggle={this.toggleTooltip}
          >
            <FormattedMessage id="question.filterIterative.questionFrom.button.tooltip"/>
          </Tooltip>
        </span>
        {modal}
      </React.Fragment>
    )
  }
}

QuestionFrom.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  filterIterative: PropTypes.array.isRequired,
  setPlanRestrictionsModals: PropTypes.func.isRequired,
  type: PropTypes.string,
  filterBy: PropTypes.string
};

function mapStateToProps(state) {
  let enableQuestionTypes = ['single', 'multi', 'matrix', 'ranked', 'matrixMulti', 'matrixDropdown', 'numericMulti'];
  let questionList = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          let question = state.survey.structure.data.questions[questionId];
          positionInSurvey++;

          if (enableQuestionTypes.includes(question.type)) {
            question.positionInSurvey = positionInSurvey;
            questionList.push(question)
          }
        })
      });
    });
  });

  return {
    survey: state.survey.structure.data.survey,
    questions: questionList,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {addFilterIterative, setPlanRestrictionsModals})(QuestionFrom);
