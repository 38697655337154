import api from "api";
import * as types from './actionTypes';
import _ from 'lodash';

export function respondentBaseAdded(respondentBase) {
  return {
    type: types.ADDED,
    respondentBase
  }
}

export function respondentBaseDeleted(respondentBase) {
  return {
    type: types.DELETED,
    respondentBase
  }
}

export function respondentBaseUpdated(respondentBase) {
  return {
    type: types.UPDATED,
    respondentBase
  }
}

export function fetchRespondentBases() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.respondentBase.get()
      .then(data => {
        _.each(data.respondentBases, respondentBase => dispatch(respondentBaseAdded(respondentBase)));
        dispatch({type: types.SUCCESS});
    });
  }
}

export function deleteRespondentBase(respondentBase) {
  return dispatch => {
    return api.respondentBase.delete(respondentBase).then(() => {
      dispatch(respondentBaseDeleted(respondentBase));
    })
  }
}

export function saveRespondentBase(data) {
  return dispatch => {
    return api.user.post.respondentBase(data).then((respondentBase) => {
      dispatch(respondentBaseAdded(respondentBase));
    })
  }
}

export function updateRespondentBase(data) {
  return dispatch => {
    return api.respondentBase.patch(data).then((respondentBase) => {
      dispatch(respondentBaseUpdated(respondentBase));
    })
  }
}
