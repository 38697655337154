import {SET, DELETED, UPDATED, ADDED} from './actionTypes';
import update from "immutability-helper";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.clients;

    case ADDED:
      return {
        ...state,
        [action.client.id]: action.client
      };

    case UPDATED:
      return update(state, {
        [action.client.id]: {
          $set: action.client
        }
      });

    case DELETED:
      return update(state, {$unset: [action.client.id]});

    default:
      return state;
  }
};