import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Nav,
  NavItem,
  TabContent
} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import "react-input-range/lib/css/index.css";
import TabTemplate from "./TabSendEmail/Tabs/TabTemplate";
import TabSettings from "./TabSendEmail/Tabs/TabSettings";
import TabRespondents from "./TabSendEmail/Tabs/TabRespondents";
import TabSentHistory from "./TabSendEmail/Tabs/TabSentHistory";
import TabSummary from "./TabSendEmail/Tabs/TabSummary";
import {Route, Switch, NavLink} from "react-router-dom";
import appRoutes from "appRoutes";
import api from 'api';
import LoadingSpinner from "components/LoadingSpinner";
import ButtonSendPaused from "./TabSendEmail/ButtonSendPaused";
import EmailTestSend from "../../EmailTestSend";
import {isEmpty} from "lodash";
import {connect} from "react-redux";
import moment from "moment";

class TabSendEmail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creating: false
    };

    this.onCreate = this.onCreate.bind(this);
  }

  onCreate() {
    this.setState({creating: true});

    api.surveyCollector.panel.post.sendEmail.create(this.props.collector)
      .then(collector => {
        this.setState({creating: false});
        this.props.handleUpdate(collector);
      });
  }


  render() {
    const {collector} = this.props;
    const {params} = this.props.match;
    let bulkEdit = false;
    if (params.collector.split(',').length > 1) {
      bulkEdit = true;
    }

    if (bulkEdit === false) {
      if (_.isUndefined(collector.sendEmail)) {
        if (!isEmpty(this.props.user.userGroups) && !this.props.user.userGroups[0].allowSurveyStart) {
          return <Alert color="warning" className="text-center">
            <p className="mb-0">Niestety, nie ale nie masz uprawnień do utworzenia wysyłki. Skontaktuj się z
              administratorem konta.</p>
          </Alert>
        }

        return (
            <Alert color="warning" className="text-center">
              <p>Jeśli chcesz utworzyć wysyłkę maili do osób z targetu kliknij poniższy przycisk</p>
              <Button onClick={this.onCreate}>{this.state.creating ? <LoadingSpinner width={20} classNames="my-0"/> :
                  <React.Fragment><i className="fas fa-plus"/> Utwórz wysyłkę</React.Fragment>}</Button>
            </Alert>
        )
      }
    }

    return (
      <React.Fragment>
        {bulkEdit === false && <div className="d-flex justify-content-end">
          <EmailTestSend collector={this.props.collector} />
          <ButtonSendPaused collector={collector} handleUpdate={this.props.handleUpdate} />
        </div>}

        <Nav tabs>
          {bulkEdit === false && <NavItem>
            <NavLink className="nav-link" exact to={appRoutes.survey.collectors.collector.panel.summary(params.survey, params.collector)}>
              <i className="fas fa-poll" />
              <FormattedMessage id={'collectorPanel.sendEmail.tabs.summary'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>}
          <NavItem>
            <NavLink className="nav-link" exact to={bulkEdit ? appRoutes.survey.collectors.collector.panel.bulkSendEmail(params.survey, params.collector) : appRoutes.survey.collectors.collector.panel.sendEmail(params.survey, params.collector)}>
              <i className="fas fa-scroll" />
              <FormattedMessage id={'collectorPanel.sendEmail.tabs.template'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
          {bulkEdit === false && <NavItem>
            <NavLink className="nav-link" exact to={appRoutes.survey.collectors.collector.panel.respondents(params.survey, params.collector)}>
              <i className="fas fa-users" />
              <FormattedMessage id={'collectorPanel.sendEmail.tabs.respondents'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>}
          <NavItem>
            <NavLink className="nav-link" exact to={bulkEdit ? appRoutes.survey.collectors.collector.panel.bulkSettings(params.survey, params.collector) : appRoutes.survey.collectors.collector.panel.settings(params.survey, params.collector)}>
              <i className="fas fa-cog" />
              <FormattedMessage id={'collectorPanel.sendEmail.tabs.settings'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
          {bulkEdit === false && <NavItem>
            <NavLink className="nav-link" exact to={appRoutes.survey.collectors.collector.panel.sentHistory(params.survey, params.collector)}>
              <i className="fas fa-history" />
              <FormattedMessage id={'collectorPanel.sendEmail.tabs.sentHistory'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>}
        </Nav>
        <TabContent className="mt-2">
          <Switch>
            {bulkEdit === false && <Route path={appRoutes.survey.collectors.collector.panel.summary()} exact component={() => <TabSummary collector={this.props.collector} />} />}
            <Route path={bulkEdit ? appRoutes.survey.collectors.collector.panel.bulkSendEmail() : appRoutes.survey.collectors.collector.panel.sendEmail()} exact component={() => <TabTemplate bulkEdit={bulkEdit} collector={this.props.collector} handleUpdate={this.props.handleUpdate}/>} />
            {bulkEdit === false && <Route path={appRoutes.survey.collectors.collector.panel.respondents()} exact component={() => <TabRespondents collector={this.props.collector} surveyId={params.survey}/>} />}
            <Route path={bulkEdit ? appRoutes.survey.collectors.collector.panel.bulkSettings() : appRoutes.survey.collectors.collector.panel.settings()} exact component={() => <TabSettings bulkEdit={bulkEdit} collector={this.props.collector} handleUpdate={this.props.handleUpdate}/>} />
            {bulkEdit === false && <Route path={appRoutes.survey.collectors.collector.panel.sentHistory()} exact component={() => <TabSentHistory collector={this.props.collector}/>} />}
          </Switch>
        </TabContent>
      </React.Fragment>
    )
  }
}

TabSendEmail.defaultProps = {
  sendEmail: {}
};

TabSendEmail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      survey: PropTypes.string.isRequired,
      collector: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  collector: PropTypes.shape({
    sendEmail: PropTypes.shape({

    }),
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state, props) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(TabSendEmail);