import update from 'immutability-helper';
import moment from "moment";

import {
  SET,
  UPDATED,
  SET_SETS,
  SET_ADDED,
  TEMPLATE_ADDED,
  RE_REPORTED,
  TOP_SURVEYS_COLLECTOR_ADDED,
  TOP_SURVEYS_COLLECTOR_REMOVED,
} from './actions';

import * as setsActions from "../sets/actions";

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case setsActions.DELETED:
      return {
        ...state,
        setBlocks: state.setBlocks.filter(id => id !== action.set.id)
      };

    case SET_ADDED:
      let list = state.setBlocks;
      list.splice(action.set.position, 0, action.set.id);

      return update(state, {
        setBlocks: {
          $set: list
        }
      });

    case SET:
      return action.survey;

    case UPDATED:
      return update(state, {
        $merge: action.survey
      });

    case SET_SETS:
      return {
        ...state,
        setBlocks: action.sets
      };

    case TEMPLATE_ADDED:
      return {
        ...state,
        ownStyles: [],
        templateId: action.template.id
      };

    case RE_REPORTED:
      return {
        ...state,
        hasActiveSurveyBase: false
      };

    case TOP_SURVEYS_COLLECTOR_ADDED:
      return update(state, {
        hasTopSurveysCollector: {$set: true},
        endCashPoints: {$set: 0},
        endSocialPoints: {$set: 5},
        screenOutCashPoints: {$set: 0},
        screenOutSocialPoints: {$set: 0},
        responsesMax: {$set: 1000},
        ipBlockade: {$set: true},
        paused: {$set: false},
        startAt: {$set: moment().format('YYYY-MM-DD HH:mm:ss')},
        endAt: {$set: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')},
      });

    case TOP_SURVEYS_COLLECTOR_REMOVED:
      return update(state, {
        hasTopSurveysCollector: {$set: false},
      });

    default:
      return state;
  }
};