import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button, UncontrolledTooltip} from "reactstrap";
import {deleteFilter} from "scenes/Survey/Structure/data/filters/actions";
import {deleteFilterSerial} from "scenes/Survey/Structure/data/filterSerial/actions";
import {deleteFilterIterative} from "scenes/Survey/Structure/data/filterIterative/actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

class ButtonDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    }
  }

  filterDelete(){
    this.setState({
      deleting: true
    });

    const action = this.props.isFilterSerial ? this.props.deleteFilterSerial : this.props.isFilterIterative ? this.props.deleteFilterIterative : this.props.deleteFilter;

    action({
      id: this.props.filter.id
    }).then(() => this.setState({deleting: false, modal: false}));
  }

  toggleModalDelete(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { deleting } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModalDelete()}>
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2"><FormattedMessage id="filters.listItems.buttonDelete.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="filters.listItems.buttonDelete.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.modal.delete.confirmation">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={() => this.filterDelete()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModalDelete()}>{message}</Button>
        }</FormattedMessage>

      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <i id={"survey-filter-button-delete-" + this.props.filter.id + '-' + this.props.filter.type} className="fas fa-trash pointer" onClick={() => this.toggleModalDelete()} />
        <UncontrolledTooltip target={"survey-filter-button-delete-" + this.props.filter.id + '-' + this.props.filter.type} placement="bottom" delay={0}>
          <FormattedMessage id="_.button.delete"/>
        </UncontrolledTooltip>
        {modal}
      </React.Fragment>
    )
  }
}

ButtonDelete.defaultProps = {
  isFilterSerial: false,
  isFilterIterative: false
};

ButtonDelete.propTypes = {
  filter: PropTypes.object.isRequired,
  isFilterSerial: PropTypes.bool.isRequired,
  isFilterIterative: PropTypes.bool.isRequired
};

export default connect(null, { deleteFilter, deleteFilterSerial, deleteFilterIterative })(ButtonDelete);