import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {CardBody, Col, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";
import PropTypes from "prop-types";
import api from 'api';
import HelpModeIconTooltip from "components/HelpModeIconTooltip";
import Button from "../../../../../components/Button";
import ModalSmsVars from "../ModalSmsVars";
import striptags from "striptags";

class TabTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      errors: {},
      template: props.collector.template,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e){
    this.setState({
      template: e.target.value
    });
  }

  onSubmit(e){
    e.preventDefault();

    const {collector} = this.props;
    const {template} = this.state;

    this.setState({
      saving: true,
      errors: {},
    });
    api.surveyCollector.patch({
      id: collector.id,
      surveyCollector: {
        template
      }
    }).then(collector => {
      this.setState({saving: false});
      this.props.handleUpdate(collector);
    }).catch(res => {
      console.log('res.response.data', res.response.data);

      this.setState({
        saving: false,
        errors: _.has(res, 'response.data.form.errors') ? res.response.data.form.errors : {}
      });
    });
  }

  render() {
    const {saving, template, errors} = this.state;

    return (
      <React.Fragment>
        <ModalSmsVars collector={this.props.collector} />
        <Form onSubmit={this.onSubmit}>
          <FormGroup>
            <Label for="template">Szablon treści</Label>
            <Input
              invalid={!!errors.template}
              id="template"
              type="textarea"
              rows={7}
              name="template"
              onChange={this.onChange}
              value={template || ""}
            />
            <small>Liczba znaków: {template ? striptags(template.replace(/&nbsp;/g, "")).length : 0}</small>
            {errors.template && <FormFeedback>{errors.template.join('; ')}</FormFeedback>}

          </FormGroup>

          <div className="d-flex justify-content-end">
            <Button
              loading={saving}
              color="primary"
              className={['mt-2']}
            ><FormattedMessage id="_.button.save"/></Button>
          </div>
        </Form>
      </React.Fragment>
    )
  }
}

TabTemplate.propTypes = {
  collector: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default TabTemplate;
