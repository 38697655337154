import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Form,FormGroup,Label,Col,Input,FormFeedback} from "reactstrap";
import api from "api";
import moment from "moment";
import {map} from "lodash";
import StatusesSelect from "components/Survey/Analysis/StatusesSelect";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Switch from "components/Switch";

const IntervalSelect = (props) => {
  const [value, setValue] = React.useState(props.value);

  const intervals = {
    hour: 'Godzina',
    day: 'Dzień',
    month: 'Miesiąc',
  };

  const options = map(intervals, (name, key) => ({
    label: name,
    value: key,
  }))

  return <Select
    options={options}
    value={options.find(o => o.value === value)}
    onChange={option => setValue(option.value)}
  />
}

class SummaryForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        ...props.data,
        survey: props.surveyId,
      },
      saving: false,
      errors: {},
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  changeData(field, value){
    this.setState({
      data: {
        ...this.state.data,
        [field]: value,
      }
    })
  }

  submit(e){
    e.preventDefault();
  }

  save(){
    const {data} = this.state;

    this.props.onSavingStart();

    this.setState({
      errors: {},
    })

    api.surveyShared.summary.post(data)
    .then(data => {
      this.props.onSavingStop();

      this.props.onSaved(data.data);
    })
    .catch(res => {
      this.props.onSavingStop();

      this.setState({
        errors: res.response.data
      })
    })
  }

  render() {
    const {data, errors} = this.state;

    return <Form>
      <FormGroup row>
        <Label md={4}>Nazwa:</Label>
        <Col md={8}>
          <Input type="text" invalid={!!_.has(errors, 'children.name.errors')} onChange={e => this.changeData('name', e.target.value)} value={data.name} />
          {_.has(errors, 'children.name.errors') && <FormFeedback>{errors.children.name.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Hasło:</Label>
        <Col md={8}>
          <Input type="text" invalid={!!_.has(errors, 'children.password.errors')} onChange={e => this.changeData('password', e.target.value)} value={data.password} />
          {_.has(errors, 'children.password.errors') && <FormFeedback>{errors.children.password.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Wykres z liczbą wywiadów:</Label>
        <Col md={8} className="d-flex align-items-center">
          <Switch
            stickToProp
            checked={data.chartResponses}
            onChange={chartResponses => this.changeData('chartResponses', chartResponses)} />
          {_.has(errors, 'children.chartResponses.errors') && <FormFeedback>{errors.children.chartResponses.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Statusy:</Label>
        <Col md={8}>
          <StatusesSelect
            isMulti
            value={data.statuses}
            onChange={statuses => this.changeData('statuses', statuses)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Data od:</Label>
        <Col md={8}>
          <DatePicker
            dropdownMode="select"
            className="form-control w-100 mb-0"
            isClearable={false}
            selected={moment(data.dateFrom)}
            onChange={(date) => this.changeData('dateFrom', date.format('YYYY-MM-DD'))}
            dateFormat="Y-MM-DD"
            locale={localStorage.language}
          />
          {_.has(errors, 'children.dateFrom.errors') && <FormFeedback>{errors.children.dateFrom.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Data do:</Label>
        <Col md={8}>
          <DatePicker
            dropdownMode="select"
            className="form-control w-100 mb-0"
            isClearable={false}
            selected={moment(data.dateTo)}
            onChange={(date) => this.changeData('dateTo', date.format('YYYY-MM-DD'))}
            dateFormat="Y-MM-DD"
            locale={localStorage.language}
          />
          {_.has(errors, 'children.dateTo.errors') && <FormFeedback>{errors.children.dateTo.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Interwał:</Label>
        <Col md={8}>
          <IntervalSelect
            value={data.interval}
            onChange={interval => this.changeData('interval', interval)}
          />
          {_.has(errors, 'children.interval.errors') && <FormFeedback>{errors.children.interval.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Liczba przerwanych wywiadów: </Label>
        <Col md={8} className="d-flex align-items-center">
          <Switch
            stickToProp
            checked={data.statResponsesBreak}
            onChange={statResponsesBreak => this.changeData('statResponsesBreak', statResponsesBreak)} />
          {_.has(errors, 'children.statResponsesBreak.errors') && <FormFeedback>{errors.children.statResponsesBreak.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Liczba niezakwalifikowanych wywiadów: </Label>
        <Col md={8} className="d-flex align-items-center">
          <Switch
            stickToProp
            checked={data.statResponsesScreenout}
            onChange={statResponsesScreenout => this.changeData('statResponsesScreenout', statResponsesScreenout)} />
          {_.has(errors, 'children.statResponsesScreenout.errors') && <FormFeedback>{errors.children.statResponsesScreenout.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Liczba zakończonych wywiadów: </Label>
        <Col md={8} className="d-flex align-items-center">
          <Switch
            stickToProp
            checked={data.statResponsesEnd}
            onChange={statResponsesEnd => this.changeData('statResponsesEnd', statResponsesEnd)} />
          {_.has(errors, 'children.statResponsesEnd.errors') && <FormFeedback>{errors.children.statResponsesEnd.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>RR zakończonych wywiadów bez niezakwalifikowanych: </Label>
        <Col md={8} className="d-flex align-items-center">
          <Switch
            stickToProp
            checked={data.statResponsesEndWithoutScreen}
            onChange={statResponsesEndWithoutScreen => this.changeData('statResponsesEndWithoutScreen', statResponsesEndWithoutScreen)} />
          {_.has(errors, 'children.statResponsesEndWithoutScreen.errors') && <FormFeedback>{errors.children.statResponsesEndWithoutScreen.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>RR zakończonych wywiadów z niezakwalifikowanymi: </Label>
        <Col md={8} className="d-flex align-items-center">
          <Switch
            stickToProp
            checked={data.statResponsesEndWithScreen}
            onChange={statResponsesEndWithScreen => this.changeData('statResponsesEndWithScreen', statResponsesEndWithScreen)} />
          {_.has(errors, 'children.statResponsesEndWithScreen.errors') && <FormFeedback>{errors.children.statResponsesEndWithScreen.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Średni czas wypełniania ankiety:</Label>
        <Col md={8} className="d-flex align-items-center">
          <Switch
            stickToProp
            checked={data.statTimeAvg}
            onChange={statTimeAvg => this.changeData('statTimeAvg', statTimeAvg)} />
          {_.has(errors, 'children.statTimeAvg.errors') && <FormFeedback>{errors.children.statTimeAvg.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label md={4}>Mediana wypełniania ankiety:</Label>
        <Col md={8} className="d-flex align-items-center">
          <Switch
            stickToProp
            checked={data.statTimeMedian}
            onChange={statTimeMedian => this.changeData('statTimeMedian', statTimeMedian)} />
          {_.has(errors, 'children.statTimeMedian.errors') && <FormFeedback>{errors.children.statTimeMedian.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
    </Form>
  }
}

SummaryForm.defaultProps = {
  data: {
    name: '',
    password: '',
    statuses: [3],
    dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
    interval: 'day',
    chartResponses: true,
    statResponsesBreak: true,
    statResponsesScreenout: true,
    statResponsesEnd: true,
    statResponsesEndWithoutScreen: true,
    statResponsesEndWithScreen: true,
    statTimeAvg: true,
    statTimeMedian: true,
  },
};

SummaryForm.propTypes = {
  surveyId: PropTypes.number.isRequired,
  data: PropTypes.object,
  onSaved: PropTypes.func,
  onSavingStart: PropTypes.func,
  onSavingStop: PropTypes.func,
};

export default SummaryForm;
