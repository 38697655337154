import React, {Component} from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import {Navbar} from "reactstrap";
import appRoutes from "appRoutes";
import NavSettings from "./components/NavSettings";
import UserMenu from "./components/UserMenu";
import Navigation from "./components/Navigation";
import ButtonCreateSurvey from "../ButtonCreateSurvey";
import PropTypes from "prop-types";
import SurveyProgress from "./components/SurveyProgress";
import {isMobile, isTablet} from "react-device-detect";
import Notifications from "./components/Notifications/Notifications";
import {connect} from "react-redux";
import {fetchTags} from "../../reducers/tags/actions";
import checkIsGroupRole from "../../utils/checkIsGroupRole";

class Header extends Component {

  render() {
    const {location, user, userGroup} = this.props;

    return (
      <React.Fragment>
        <header style={{height: "auto"}} id="header" className="d-print-none">
          <NavSettings location={location}/>
          <Navbar className="navbar-expand-lg pt-0">
            <Link to={appRoutes.home} className="logo-wrap m-0 mx-lg-auto pt-1 pt-lg-3 order-0"><img src="/dashboard/img/logo.png" alt="" style={isMobile || isTablet ? {maxHeight: 38} : {}}/></Link>

            <div className="d-flex flex-row flex-nowrap">
              {isMobile && <div className="mt-2">
                <Notifications />
              </div>}
              <button id="navigationToggler" className="navbar-toggler pt-lg-3 mt-0 mt-md-2 pt-sm-1 order-1 mt-1"
                      type="button" data-toggle="collapse" data-target="#mainNav" aria-expanded="true"><i
                className="fas fa-bars"/></button>
            </div>
            <Navigation location={location} />
            {window.location.pathname.includes("superadmin") === false && <div id={"header-buttons-wrapper"}>
              {checkIsGroupRole('accessEdit', user, userGroup) && <span>
                <ButtonCreateSurvey displayText={false} classnames={"create-button"} />
              </span>}
              <Link to={appRoutes.surveys.lastSeen} class={"ml-2 last-seen button-link color-white background-magenta position-relative d-none d-lg-inline-block"}>
                <i class={"fas fa-paperclip"}></i> <span class={"d-none d-xxl-inline-block"}>Zobacz przeglądane projekty</span>
              </Link>
            </div>}

            <div className="user-wrap float-right h-100 py-2 pt-4 d-none d-lg-block">
              <UserMenu />
              <Switch>
                <Route location={location} path={appRoutes.survey.main()} children={<SurveyProgress />}/>
              </Switch>
            </div>
          </Navbar>
        </header>
      </React.Fragment>
    )
  }
}

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    userGroup: state.user.userGroups,
    user: state.user
  }
}

export default connect(mapStateToProps)(Header);
