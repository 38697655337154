import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {isArray, forEach} from "lodash";

import FilterEditModal
  from "./Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/Question/components/Filters/components/FilterEditModal";
import {toast} from "react-toastify";
import {addFilter} from "../data/filters/actions";

class KeyboardFilter extends Component {
  constructor(props) {
    super(props);

    this.filterRef = React.createRef();

    this.state = {
      saving: false,
      modalOpen: false,
      filter: null,
    };

    this.saveFilter = this.saveFilter.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind("shift+f", () => {
      this.setState({
        modalOpen: true,
        filter: {
          name: 'Filtr',
          blocks: [
            {
              children: [],
              condsQuestion: []
            }
          ],
          actions: []
        }
      })
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+f");
  }

  saveFilter() {
    const {survey} = this.props;
    const filter = this.filterRef.current.getFilter();

    this.setState({saving: true});

    let data = {
      filter: {
        id: filter.id,
        name: filter.name,
        survey: survey.id,
        blocks: filter.blocks,
        actionsVisibility: filter.actions.filter(action => action.actionType === 'actionVisibility'),
        actionsJump: filter.actions.filter(action => action.actionType === 'actionJump')
      }
    };

    this.props.addFilter(data)
    .then(() => {
      this.setState({
        saving: false,
        modalOpen: false,
        filter: null,
      })
    })
    .catch(error => {
      const errors = error.response.data.form.errors;
      forEach(errors, error => {
        if(isArray(error)){
          forEach(error, e => toast.error(e));
        }else{
          toast.error(error);
        }
      });

      this.setState({
        saving: false
      })
    });
  }

  render() {
    const {modalOpen} = this.state;

    return <React.Fragment>
      {modalOpen && <FilterEditModal
        isOpen={true}
        saving={this.state.saving}
        filter={this.state.filter}
        toggleModal={() => this.setState({modalOpen: false, filter: null})}
        onSave={this.saveFilter}
        ref={this.filterRef}
      />}
    </React.Fragment>;
  }
}

KeyboardFilter.propTypes = {
  survey: PropTypes.shape({

  }).isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps, {addFilter})(KeyboardFilter);