import React, {Component} from 'react';
import {Alert, Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import AnyChart from "anychart-react";
import api from "../../../api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import moment from "moment";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import axios from "axios";

const CancelToken = axios.CancelToken;

class SurveyResponsesStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      axiosSource: false,
      data: {},
      filters: {
        dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
        interval: 'day',
      },
    };

    this.cancelAxiosToken = this.cancelAxiosToken.bind(this);
    this.changeFilterValue = this.changeFilterValue.bind(this);
    this.getDataFormatted = this.getDataFormatted.bind(this);
    this.getChart = this.getChart.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillUnmount() {
    this.cancelAxiosToken();
  }

  cancelAxiosToken(){
    if(this.state.axiosSource !== false){
      this.state.axiosSource.cancel();
    }
  }

  changeFilterValue(name, value){
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      }
    })
  }

  getDataFormatted(){
    const {data} = this.state;

    if(_.isEmpty(data)){
      return [];
    }

    let rows = [];
    Object.keys(data[Object.keys(data)[0]]).forEach((keyName, keyValue) => {
      let row = [keyName];

      _.map(data, d => {
        row.push(d[keyName]);
      });

      rows.push(row);
    });

    return rows
  }

  fetchData(){
    this.cancelAxiosToken();

    const {filters} = this.state;

    this.setState({
      loading: true,
      axiosSource: CancelToken.source(),
    }, () => {
      api.admin.stats.surveyResponses(filters, this.state.axiosSource)
      .then(data => {
        this.setState({
          loading: false,
          data,
        });
      })
      .catch(e => {
        this.setState({
          loading: false,
          data: {}
        })
      })

    });
  }

  getChart(){
    const {data} = this.state;

    if(_.isEmpty(data)){
      return <Alert color="warning" className="text-center">Brak danych</Alert>
    }

    let stage = anychart.graphics.create();
    let chart = anychart.line();
    chart.legend(true);
    chart.title('Liczba wywiadów');

    chart.yScale().stackMode('value');
    chart.yScale().ticks().allowFractional(false);
    chart.yAxis().title('Liczba wywiadów');
    console.log('data', data);
    _.map(data, (values, keyName) => {
      let series = chart.area(_.map(values, (value, x) => {
        return {
          x,
          value,
        }
      }));
      series.name(keyName);
    });

    return <AnyChart instance={stage} charts={[chart]} height={550}/>
  }

  render() {
    const {loading, filters} = this.state;

    const interval = {
      day: 'Dzień',
      week: 'Tydzień',
      month: 'Miesiąc',
      year: 'Rok',
    };

    return <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader><h2 className="float-left mr-4">Statystyki wypełnień ankiet</h2></CardHeader>
          <hr className="my-0" />
          <CardBody>
            <div className="d-flex justify-content-end mb-3">
              <div className="btn-group mr-3">
                {_.map(interval, (name, key) => <button
                  className={classnames({
                    'btn': true,
                    'btn-primary': filters.interval === key,
                    'btn-secondary': filters.interval !== key,
                  })}
                  onClick={() => this.changeFilterValue('interval', key)}
                >{name}</button>)}
              </div>

              <div>
                {filters.dateFrom && filters.dateTo && <div className="d-flex h-100 justify-content-center align-items-center">
                  <DatePicker
                    dropdownMode="select"
                    className="form-control w-100 mb-0"
                    isClearable={false}
                    selected={moment(filters.dateFrom)}
                    onChange={(date) => this.changeFilterValue('dateFrom', date.format('YYYY-MM-DD'))}
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                  />
                  <div className="px-2 d-flex align-items-center"> - </div>
                  <DatePicker
                    dropdownMode="select"
                    className="form-control w-100 mb-0"
                    isClearable={false}
                    selected={moment(filters.dateTo)}
                    onChange={(date) => this.changeFilterValue('dateTo', date.format('YYYY-MM-DD'))}
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                  />
                </div>}
              </div>
              <Button color="primary" className="ml-2" onClick={() => this.fetchData()}>Pokaż</Button>
            </div>

            {loading ? <LoadingSpinner classNames="py-5 my-5" /> : this.getChart()}
          </CardBody>
        </Card>
      </Col>
    </Row>
  }
}

export default SurveyResponsesStats;