import React, {Component} from 'react';
import {Alert} from "reactstrap";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import api from 'api';
import LoadingSpinner from "components/LoadingSpinner";
import { connect } from 'react-redux';

class SurveyCollectorValidity extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      errors: []
    }
  }

  componentDidMount() {
    api.surveyCollector[this.props.collector.type.toLowerCase()].get.validity(this.props.collector)
      .then(data => this.setState({loading: false, errors: data.errors}))
      .catch(() => this.setState({loading: false}));
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.collector !== this.props.collector || nextProps.survey !== this.props.survey ) {
      api.surveyCollector[this.props.collector.type.toLowerCase()].get.validity(this.props.collector)
        .then(data => this.setState({errors: data.errors}));
    }
  }

  getErrorsFormattedData() {
    return <React.Fragment>
      <FormattedMessage id="surveyCollector.validity.invalid" />
      <ul className="m-0" style={{listStyle: 'disc'}}>
        {this.state.errors.map((error, i)=> <li key={i}>
          {error}
        </li>)}
      </ul>
    </React.Fragment>;
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner width={20}/>
    }

    const isValid = this.state.errors.length === 0;

    return (
      <Alert color={isValid ? 'success' : 'danger'}>{isValid ? <FormattedMessage id="surveyCollector.validity.valid" /> : this.getErrorsFormattedData()}</Alert>
    )
  }
}

SurveyCollectorValidity.propTypes = {
  collector: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps)(SurveyCollectorValidity);