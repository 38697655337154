import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';

class ResetPasswordAction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      modal: false,
      loading: false,
    };

    this.resetPassword = this.resetPassword.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  resetPassword() {
    this.setState({loading: true});

    api.user.post.resetPassword(this.props.user.id)
      .then(() => {
        this.setState({
          loading: false,
          modal: false
        })
      })
      .catch(() => this.setState({loading: false}));
  }

  render() {
    return (
      <React.Fragment>
        <span id={'resetPassword-' + this.props.user.id} className="fas fa-user-lock pointer" onClick={() => this.toggle('modal')}/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip}
          target={'resetPassword-' + this.props.user.id}
          toggle={() => this.toggle('tooltip')}
        >
          <FormattedMessage id="admin.respondents.resetPasswordAction.tooltip" />
        </Tooltip>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')}>
          <ModalHeader toggle={() => this.toggle('modal')} tag="h2"><FormattedMessage id="admin.respondents.resetPasswordAction.modalHeader"/></ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            <FormattedMessage id="admin.respondents.resetPasswordAction.modalBody"/>
          </ModalBody>
          <ModalFooter>
            <div className="direction-group d-flex justify-content-end">
              <div className="direction-right">
                <FormattedMessage id="_.button.yes">{
                  (message) => <LaddaButton
                    className="btn btn-danger btn-ladda mb-0 ml-3"
                    data-style={ZOOM_OUT}
                    onClick={this.resetPassword}
                    loading={this.state.loading}
                  >
                    {message}
                  </LaddaButton>
                }</FormattedMessage>
                <FormattedMessage id="_.button.cancel">{
                  (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggle('modal')}>{message}</Button>
                }</FormattedMessage>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

ResetPasswordAction.propTypes = {
  user: PropTypes.object.isRequired
};

export default ResetPasswordAction;