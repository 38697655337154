import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import striptags from "striptags";
import {ListGroupItem} from "reactstrap";
import FiltersQuestionListOptions from "./FiltersQuestionListOptions";
import LoadingSpinner from "components/LoadingSpinner";

class FiltersQuestionItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      deleting: false
    };
  }

  render() {
    const {filter, questions} = this.props;

    return (
      <ListGroupItem className="py-1 px-0 d-flex align-items-center" style={{
        opacity: this.state.deleting ? 0.4 : 1
      }}>
        <span className="pl-2">{questions[filter.question].content ? striptags(questions[filter.question].content) : <i><FormattedMessage id="_.question.content.empty" /></i>} ({Object.keys(filter.answers).length})</span>

        <FiltersQuestionListOptions
          id={filter.id}
          onEdit={(id) => this.props.onEdit(id)}
          onDeleted={() => this.props.onDeleted()}
          onDeleting={() => this.setState({deleting: true})}
        />

        {this.state.deleting && <LoadingSpinner absolute={true}/>}
      </ListGroupItem>
    )
  }
}

FiltersQuestionItem.propTypes = {
  onDeleted: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    questions: state.survey.analysis.data.questions,
  }
}

export default connect(mapStateToProps)(FiltersQuestionItem);