import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, ButtonGroup} from 'reactstrap';
import ReactTable from 'react-table';
import Pagination from "components/Pagination";
import moment from "moment";
import Checkbox from "components/Checkbox";
import ResponsesBulkStatusSelect from './ResponsesBulkStatusSelect';
import PropTypes from "prop-types";
import EditAction from "./Actions/EditAction";
import api from "api";

class ResponsesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      shiftPressed: false,
      searching: false,
      data: [],
      checked: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.toggleChecked = this.toggleChecked.bind(this);
    this.actions = this.actions.bind(this);
    this.handleBulkStatusUpdate = this.handleBulkStatusUpdate.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.table = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', e => {
      if (e.key === 'Shift') {
        this.setState({
          shiftPressed: true,
        })
      }
    });
    document.addEventListener('keyup', e => {
      if (e.key === 'Shift') {
        this.setState({
          shiftPressed: false,
        })
      }
    });
  }

  toggleSelectAll(){
    const {checked, data} = this.state;

    if(checked.length === data.length){
      this.setState({
        checked: [],
      });
    }else{
      this.setState({
        checked: data.map(d => d.id),
      });
    }
  }

  getCurrentRows() {

    const pageSize = this.table.current.state.pageSize;
    const page = this.table.current.state.page;

    const currentRows = this.state.data.slice(page * pageSize, page * pageSize + pageSize);

    return _.map(currentRows, row => row.id);
  }

  toggleChecked(id) {
    const {shiftPressed} = this.state;
    const tableRows = this.getCurrentRows();

    let checked = [];
    if(shiftPressed && this.state.checked.length > 0){
      const idStart = this.state.checked[this.state.checked.length-1];

      let adding = false;
      checked = _.merge(this.state.checked, tableRows.filter(_id => {
        if(_id === idStart){
          adding = true;
        }
        if(_id === id){
          adding = false;
        }


        return adding;
      }), [id]);
    }else{
      checked = _.xor(this.state.checked, [id]);
    }

    this.setState({checked});
  }

  componentWillReceiveProps(nextProps) {
    const filtersWereChanged = this.props.filters !== nextProps.filters;

    if (filtersWereChanged) {
      clearTimeout(this.state.searchTimeout);
      this.setState({
        searchTimeout: setTimeout(() => {
          this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, nextProps)
        }, 1000)
      });
    }
  }

  handleBulkStatusUpdate() {
    this.fetchData(this.state);
  }

  actions(response) {
    const handler = () => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});

    return (
      <ButtonGroup>
        <EditAction response={response} statuses={this.props.statuses} handleChange={handler}/>
      </ButtonGroup>
    )
  }

  fetchData(state, props) {
    this.setState({
      searching: true,
      checked: [],
      page: state.page,
      pageSize: state.pageSize,
    });

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        ...this.props.filters,
        sort: state.sorted
      }
    };

    api.admin.surveyResponse.post.search(data).then(res => {
      this.setState({
        searching: false,
        data: res.data,
        count: res.count,
      });
    });
  }

  render() {
    const columns = [{
      id: 'check',
      accessor: response => <Checkbox id="check" checked={this.state.checked.includes(response.id)} tooltip={false} onClick={() => this.toggleChecked(response.id)}/>,
      sortable: false
    }, {
      Header: <FormattedMessage id="admin.responsesList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      id: 'surveyName',
      Header: <FormattedMessage id="admin.responsesList.listItems.tableHeader.surveyName" />,
      accessor: response => response.surveyName
    }, {
      id: 'user',
      Header: <FormattedMessage id="admin.responsesList.listItems.tableHeader.user" />,
      accessor: response => response.userId,
    }, {
      id: 'status',
      Header: <FormattedMessage id="admin.responsesList.listItems.tableHeader.status" />,
      accessor: response => <FormattedMessage id={"admin.responses.status-" + response.status} />
    }, {
      id: 'createdAt',
      Header: <FormattedMessage id="admin.responsesList.listItems.tableHeader.createdAt" />,
      accessor: response => moment(response.createdAt).format('YYYY-MM-DD HH:mm')
    }, {
      id: 'actions',
      Header: <FormattedMessage id="admin.responsesList.listItems.tableHeader.actions" />,
      accessor: response => this.actions(response),
      sortable: false
    }];

    return (
      <React.Fragment>
        <ReactTable
          minRows={0}
          className={'table-wrap'}
          data={this.state.data}
          columns={columns}
          defaultPageSize={50}
          showPagination={!_.isEmpty(this.state.data)}
          PaginationComponent={Pagination}
          noDataText={<FormattedMessage id="admin.responsesList.listItems.table.noReports" />}
          loading={this.state.searching}
          onFetchData={this.fetchData}
          rowsCount={this.state.count}
          manual
          ref={this.table}
          onSortedChange={sorted => this.setState({sorted})}
        />
        <Button className="mr-2" color={'secondary'} onClick={() => this.toggleSelectAll()}>Zaznacz/Odznacz wszystko</Button>

        {this.state.checked.length > 0 && <ResponsesBulkStatusSelect statuses={this.props.statuses} checked={this.state.checked} onUpdate={this.handleBulkStatusUpdate}/>}
      </React.Fragment>
    )
  }
}

ResponsesList.propTypes = {
  statuses: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
};

export default ResponsesList;
