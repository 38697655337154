import React, {PureComponent} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import TopSurveysRanksList from "./components/TopSurveysRanksList";

class TopSurveysRanks extends PureComponent {
  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">Ranking top ankiety</h2>
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Row>
                <Col>
                  <TopSurveysRanksList />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

TopSurveysRanks.propTypes = {};

export default TopSurveysRanks;