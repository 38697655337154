import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader, Col, Input, InputGroup, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import FindShippingList from "./FindShippingList";
import moment from "moment/moment";
import DatePicker from "react-datepicker";

const FindShipping = props => {
  const [search, setSearch] = React.useState('');
  const [dateFrom, setDateFrom] = React.useState(moment().subtract(30, 'days'));

  return <Row className="animated fadeIn">
    <Col>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <h2>Znajdź</h2>

          <div className="d-flex flex-row flex-nowrap ml-auto align-items-center">
            <div className="mr-2">
              <DatePicker
                className="mr-2 mb-0"
                placeholderText={"Data od"}
                selected={dateFrom}
                onChange={setDateFrom}
                dateFormat={'Y-MM-DD'}
                locale={localStorage.language}
              />
            </div>
            <div className="custom-search-input w-lg-100 float-right mt-1">
              <InputGroup className="p-0 mb-0">
                <Input className="p-0" placeholder="Znajdź..." onChange={e => setSearch(e.target.value)}/>
                <span className="input-group-btn py-1">
                <button className="btn" type="button"><i className="fas fa-search" /></button>
              </span>
              </InputGroup>
            </div>
          </div>


        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <Row>
            <Col>
              <FindShippingList search={search} dateFrom={dateFrom ? dateFrom.format('YYYY-MM-DD') : null} history={props.history} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>;
};

FindShipping.propTypes = {

};

export default FindShipping;
