import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import SelectAnswers from "../../../../../SelectAnswers";

class AnswersSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      question: props.question,
      modalOpen: false,
      modalAnswers: props.selectedAnswers,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return props.question !== state.question ? {modalAnswers: props.answers, question: props.question} : null;
  }

  toggleModal(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {question, answers, selectedAnswers} = this.props;
    const {modalOpen, modalAnswers} = this.state;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModal}>Wybierz odpowiedzi</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-75 d-inline-block">
          <SelectAnswers questionId={question.id} value={modalAnswers} onChange={answers => {
            this.setState({
              modalAnswers: answers,
            })
          }} />
        </div>
        <div className="w-25 d-inline-block pl-3">
          Wybranych odpowiedzi: {modalAnswers.length}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleAnswersChanged(modalAnswers);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={!_.isEmpty(selectedAnswers) ? 'secondary' : 'warning'} className="mx-1 pointer d-inline-flex flex-wrap" style={{wordBreak: 'break-word'}} onClick={this.toggleModal}>{!_.isEmpty(selectedAnswers) ? selectedAnswers.map((id, i, arr) => {
          let divider = i < arr.length - 1 && <span className="mx-1">|</span>;
          const answer = answers.find(a => a.id === id);

          if (question.answersImage) {
            return <span key={id} className="img-20">{answer.position + 1}. <img src={answer.content}/>{divider}</span>
          }

          return <React.Fragment key={id}><span className="ch-25 img-20 question-badge ws-nowrap d-inline-block" dangerouslySetInnerHTML={{__html: answer.position+1 + '. ' + answer.stripedContent}} />{divider}</React.Fragment>;
        }) : 'Wybierz odpowiedzi...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

AnswersSelect.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  selectedAnswers: PropTypes.array.isRequired,
  handleAnswersChanged: PropTypes.func,
};

export default AnswersSelect;