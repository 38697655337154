import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import RespondentForm from "../RespondentForm";
import {connect} from "react-redux";
import {updateRespondent} from './../../actions';

class EditAction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      modal: false,
      loading: false,
      errors: {}
    };

    this.updateUser = this.updateUser.bind(this);
    this.groupUserForm = React.createRef();
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  updateUser(e, data) {
    e.preventDefault();
    this.setState({loading: true});

    this.props.updateRespondent({
      data:  {
        id: this.props.user.id,
        user: data.user
      },
    }).then(() => {
      this.props.handleChange();
      this.setState({
        loading: false,
        modal: false
      })
    }).catch(e => {
      this.setState({
        loading: false,
        errors: e.response.data.form.errors
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <span id={'editUser-' + this.props.user.id} className="fas fa-user-edit mr-2 pointer" onClick={() => this.toggle('modal')}/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip}
          target={'editUser-' + this.props.user.id}
          toggle={() => this.toggle('tooltip')}
        >
          <FormattedMessage id="admin.respondents.editAction.tooltip" />
        </Tooltip>

        <Modal isOpen={this.state.modal} size="lg" toggle={() => this.toggle('modal')}>
          <ModalHeader toggle={() => this.toggle('modal')} tag="h2"><FormattedMessage id="admin.respondents.editAction.modalHeader"/></ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            <RespondentForm user={this.props.user} errors={this.state.errors} onSubmit={this.updateUser} ref={this.groupUserForm}/>
          </ModalBody>
          <ModalFooter>
            <div className="direction-group d-flex justify-content-end">
              <div className="direction-right">
                <FormattedMessage id="_.button.save">{
                  (message) => <LaddaButton
                    className="btn btn-success btn-ladda mb-0 ml-3"
                    data-style={ZOOM_OUT}
                    onClick={e => this.updateUser(e, this.groupUserForm.current.state)}
                    loading={this.state.loading}
                  >
                    {message}
                  </LaddaButton>
                }</FormattedMessage>
                <FormattedMessage id="_.button.cancel">{
                  (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggle('modal')}>{message}</Button>
                }</FormattedMessage>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

EditAction.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default connect(null, { updateRespondent })(EditAction);