import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {ListGroup, ListGroupItem} from "reactstrap";
import Checkbox from "components/Checkbox";
import {FormattedMessage} from "react-intl";
import _ from "lodash";

class CheckColumns extends Component {
  constructor(props){
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onToggleAll = this.onToggleAll.bind(this);
  }

  onChange(column){
    let {checked} = this.props;

    checked = checked.includes(column) ? checked.filter(c => c !== column) : [...checked, column];

    this.props.onChange(checked);
  }

  onToggleAll() {
    const allChecked = _.isEqual(_.map(this.props.columns, column => column.id).sort(), this.props.checked.sort());

    const checked = allChecked ? [] : _.map(this.props.columns, column => column.id);

    this.props.onChange(checked);
  }

  render() {
    const {checked, columns} = this.props;
    const allChecked = _.isEqual(_.map(columns, column => column.id).sort(), checked.sort());

    return (
      <ListGroup>
        <ListGroupItem className="py-0" onClick={this.onToggleAll}>
          <Checkbox
            text={<FormattedMessage id="checkColumns.toggleAll"/>}
            id={'checkbox-columns-all'}
            checked={allChecked}
            tooltip={false}
          />
        </ListGroupItem>
        {Object.values(columns).map(column => {
          return <ListGroupItem className="py-0" key={column.id} onClick={() => this.onChange(column.id)}>
            <Checkbox
              text={<div dangerouslySetInnerHTML={{__html: column.content}} />}
              id={'checkbox-column-' + column.id}
              checked={checked.includes(column.id)}
              tooltip={false}
            />
          </ListGroupItem>
        })}
      </ListGroup>
    );
  }
}

CheckColumns.propTypes = {
  questionId: PropTypes.number.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  checked: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    columns: Object.values(_.map(state.survey.structure.data.questions[props.questionId].columns, id => state.survey.structure.data.columns[id])),
  }
}

export default connect(mapStateToProps)(CheckColumns);