import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

class Comment extends Component {
  render() {
    const {details} = this.props;

    return (
      <React.Fragment>
        {details.map((detail, i) => {
          return <p key={i}>{detail.microTime > 0 ? <FormattedMessage id="analysis.browse.comment.seen"/> : <FormattedMessage id="analysis.browse.comment.notSeen"/>}</p>
        })}
      </React.Fragment>
    )
  }
}

Comment.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    microTime: PropTypes.number.isRequired
  }),).isRequired,
};

export default Comment;