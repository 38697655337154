import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import PropTypes from "prop-types";
import CollectorsActions from "./CollectorsActions";
import {surveyLink} from "services/surveyLink";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import CollectorsButtonPaused from "./CollectorsButtonPaused";
import ProgressBar from "components/ProgressBar";
import moment from "moment";
import Checkbox from "components/Checkbox";
import {Badge, Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import appRoutes from 'appRoutes';
import DetailModal from "scenes/SurveyCollector/components/DetailModal";
import CollectorName from "../../../SurveyCollector/components/Collector/CollectorName";
import {connect} from "react-redux";
import CollectorsModalDeleteBulk from "./Modals/CollectorsModalDeleteBulk";
import api from "../../../../api";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import classnames from "classnames";
import CollectorPanelCreateTemplate from "./Modals/CollectorPanelCreateTemplate";
import CollectorsPanelVerify from "scenes/Survey/Collectors/components/CollectorsPanelVerify";
import {isUndefined} from "lodash";
import useAfterUpdateEffect from "../../../../utils/useAfterUpdateEffect";
import {Link} from "react-router-dom";

const CashPointsUpdate = (props) => {
  const [value, setValue] = React.useState(props.collector[props.propertyName] || '');
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [saving, setSaving] = React.useState(false);

  useAfterUpdateEffect(() => {
    if(!modalOpen){
      props.onModalClose()
    }
  }, [modalOpen])

  const save = () => {
    setSaving(true);

    api.surveyCollector.patch({
      id: props.collector.id,
      surveyCollector: {
        [props.propertyName]: value,
      }
    })
      .then(() => {
        toggleModalOpen()
      })
      .finally(() => setSaving(false))
  }

  return <React.Fragment>
    <span className="pointer" onClick={toggleModalOpen}>{!isUndefined(props.collector[props.propertyName]) ? props.collector[props.propertyName] : 0}</span>
    {modalOpen && <Modal isOpen toggle={toggleModalOpen} size="md">
      <ModalHeader toggle={toggleModalOpen} tag="h2">Zmiana wartości punktów</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Input type={'number'} placeholder={'Wartość pobrana z ustawień ankiety'} onChange={e => setValue(Math.min(parseInt(e.target.value), props.valueMax) || null)} value={value} />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>

        <Button color="primary" loading={saving} className="mb-0" onClick={save}>Zapisz</Button>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
}

const RealizationAutomate = (props) => {
  const [enabled, setEnabled] = React.useState(props.enabled);

  return <i
    className={classnames({
      "fa-solid fa-paw cursor-pointer": true,
      "text-secondary": !enabled,
      "text-success": enabled,
    })}
    onClick={() => {
      api.surveyCollector.patch({
        id: props.collector.id,
        surveyCollector: {
          realizationAutomate: !enabled,
        }
      })

      setEnabled(!enabled);
    }}
  />
}

class CollectorsList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      checked: [],
      collectors: props.collectors
    };

    this.toggleChecked = this.toggleChecked.bind(this);
    this.status = this.status.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
  }

  toggleChecked(id) {
    const checked = _.xor(this.state.checked, [id]);
    this.setState({checked});
  }

  type(collector){
    const {intl} = this.props;

    if(collector.templateName){
      return '[' + intl.formatMessage({ id: "_.surveyCollector." + collector.type + '.name' }) + '] - ' + collector.templateName;
    }

    return intl.formatMessage({ id: "_.surveyCollector." + collector.type + '.name' });
  }

  static endAt(collector){
    return moment(collector.endAt).format('YYYY-MM-DD');
  }

  static startAt(collector){
    const inFuture = moment(collector.startAt) > moment();

    return <span className={classnames({
      'bg-warning rounded p-1': inFuture,
    })}>{moment(collector.startAt).format('YYYY-MM-DD')}</span>;
  }

  static guid(collector){
    if (collector.type === 'Tokens') {
      return;
    }
    return <a href={surveyLink(collector.guid)} target="_blank">{surveyLink(collector.guid)}</a> ;
  }

  static responsesCountFormatter(collector){
    let content = collector.endResponsesCount;

    if (collector.screenOutResponsesCount > 0 || collector.quotaResponsesCount > 0)
      content += ' (+' + (collector.screenOutResponsesCount + collector.quotaResponsesCount) + ' odrzuconych)';

    content += ' z ' + collector.collectorResponses;

    return content;
  }

  status(collector) {
    const type = collector.paused ? 'danger' : 'success';
    const text = collector.paused ? <FormattedMessage id="_.label.paused"/> : <FormattedMessage id="_.label.active"/>;

    if(collector.type === 'Panel' && this.props.plan.survey.collectorPanelVerifyRequired && !collector.verified){
      return <span className="text-warning">Weryfikacja</span>;
    }

    return <div key={collector.paused} className="d-flex w-100 align-items-center justify-content-center">
      <CollectorsButtonPaused paused={collector.paused} collector={collector} handleCollectorChange={this.props.handleCollectorChange}/>
      <label className={`label-status ${type} ml-2 mb-0`}>{text}</label>
    </div>
  }

  onEditClick() {
    const editSingle = this.state.checked.length === 1;

    if (editSingle) {
      this.props.history.push(appRoutes.survey.collectors.collector.view(this.props.surveyId, this.state.checked[0]))
    } else {
      this.props.history.push(appRoutes.survey.collectors.collector.panel.edit(this.props.surveyId, this.state.checked))

    }
  }

  render() {
    const {intl, type, surveyId, history} = this.props;

    const collectorsPanelCount = this.props.collectors.filter(collector => collector.type === 'Panel').length;

    const columns = [
      {
      id: 'check',
      accessor: collector => (collector.type !== 'Panel' || (collector.type === 'Panel' && !this.props.plan.survey.collectorPanelVerifyRequired)) && <Checkbox id="check" checked={this.state.checked.includes(collector.id)} tooltip={false} onClick={() => this.toggleChecked(collector.id)}/>,
      width: 20
    }, {
      id: 'name',
      Header: <FormattedMessage id="collectors.listItems.tableHeader.name" />,
      // accessor: collector => collector.name ||  intl.formatMessage({ id: "_.surveyCollector." + collector.type + '.name' }) + ' ' + collector.position,
      accessor: collector => {
        return <CollectorName key={collector.name} tagName="div" id={collector.id} collector={collector} handleUpdate={name => {
          api.surveyCollector.patch({
            id: collector.id,
            surveyCollector: {
              name: name
            }
          })
        }} showButtons={false}/>;
      },
      sortMethod: (a, b, desc) => {
        a = a.key;
        b = b.key;

        // force null and undefined to the bottom
        a = a === null || a === undefined ? -Infinity : a;
        b = b === null || b === undefined ? -Infinity : b;
        // force any string values to lowercase
        a = typeof a === 'string' ? a.toLowerCase() : a;
        b = typeof b === 'string' ? b.toLowerCase() : b;
        // Return either 1 or -1 to indicate a sort priority
        if (a > b) {
          return 1
        }
        if (a < b) {
          return -1
        }
        // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
        return 0
      }
    }, {
      id: 'status',
      width: 150,
      Header: <FormattedMessage id="collectors.listItems.tableHeader.status" />,
      accessor: collector => this.status(collector),
      sortMethod: (a, b, desc) => {
        a = a.key;
        b = b.key;

        // force null and undefined to the bottom
        a = a === null || a === undefined ? -Infinity : a;
        b = b === null || b === undefined ? -Infinity : b;
        // force any string values to lowercase
        a = typeof a === 'string' ? a.toLowerCase() : a;
        b = typeof b === 'string' ? b.toLowerCase() : b;
        // Return either 1 or -1 to indicate a sort priority
        if (a > b) {
          return 1
        }
        if (a < b) {
          return -1
        }
        // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
        return 0
      }
    }, {
      id: 'startAt',
      Header: 'Start badania',
      accessor: collector => CollectorsList.startAt(collector),
      width: 88,
    }, {
      id: 'endAt',
      Header: <FormattedMessage id="collectors.listItems.tableHeader.endAt" />,
      accessor: collector => CollectorsList.endAt(collector),
      width: 80,
    }, {
      id: 'type',
      Header: <FormattedMessage id="collectors.listItems.tableHeader.type" />,
      accessor: collector => this.type(collector),
      width: 230,
    }];

    if(type !== 'Panel'){
      columns.push({
        id: 'guid',
        width: 220,
        Header: <FormattedMessage id="collectors.listItems.tableHeader.link" />,
        accessor: collector => CollectorsList.guid(collector),
      });
    }

    if(type === 'Panel'){
      columns.push({
        id: 'endCashPoints',
        width: 40,
        Header: <span>CP</span>,
        accessor: collector => <CashPointsUpdate
          valueMax={20}
          propertyName={'endCashPoints'}
          collector={collector}
          survey={this.props.survey}
          onModalClose={this.props.handleOnChange}
        />,
      });
      columns.push({
        id: 'cashPoints',
        width: 55,
        Header: <span>CP Email</span>,
        accessor: collector => <CashPointsUpdate
          valueMax={20}
          propertyName={'endCashPointsEmail'}
          collector={collector}
          survey={this.props.survey}
          onModalClose={this.props.handleOnChange}
        />,
      });
      columns.push({
        id: 'cashPointsTo',
        width: 55,
        Header: <span>Max CP</span>,
        accessor: collector => <CashPointsUpdate
          valueMax={999999}
          propertyName={'cashPointsTo'}
          collector={collector}
          survey={this.props.survey}
          onModalClose={this.props.handleOnChange}
        />,
      });
      columns.push({
        id: 'realizationAutomate',
        width: 55,
        Header: 'Autopanel',
        accessor: collector => <div key={`k-${collector.id}`}>
          <RealizationAutomate
            enabled={collector.realizationAutomate}
            collector={collector}
          />
        </div>,
      });
    }

    columns.push({
      id: 'responsesCount',
      Header: <FormattedMessage id="collectors.listItems.tableHeader.responses" />,
        accessor: collector => <span endResponsesCount={collector.endResponsesCount}>{CollectorsList.responsesCountFormatter(collector)}</span>,
      width: 110,
      sortMethod: (a, b) => {
          a = a.props.endResponsesCount;
          b = b.props.endResponsesCount;

          if (a > b) {
            return 1
          }
          if (a < b) {
            return -1
          }
          // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
          return 0
        }
    }, {
      id: 'responsePercentage',
      Header: <FormattedMessage id="collectors.listItems.tableHeader.progress" />,
      accessor: collector => <ProgressBar responsePercentage={collector.responsePercentage} current={parseInt(collector.endResponsesCount)} max={parseInt(collector.collectorResponses)} id={collector.id}/>,
      width: 170,
      sortMethod: (a, b, desc) => {
        a = a.props.responsePercentage;
        b = b.props.responsePercentage;

        if (a > b) {
          return 1
        }
        if (a < b) {
          return -1
        }
        // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
        return 0
      }
    }, {
      id: 'detail',
      Header: <FormattedMessage id="collectors.listItems.tableHeader.details" />,
      accessor: collector => {
        if(collector.type === 'Panel') {
          if (this.props.plan.survey.collectorPanelVerifyRequired) {
            return;
          }
        }

        return <DetailModal surveyId={this.props.surveyId} collector={collector}/>;
      },
      width: 90,
    },
    {
      id: 'actions',
      Header: <FormattedMessage id="collectors.listItems.tableHeader.actions" />,
      accessor: collector => {
        if(collector.type === 'Panel') {
          if (this.props.plan.survey.collectorPanelVerifyRequired) {
            return;
          }
        }

        return <CollectorsActions
          collector={collector}
          surveyId={parseInt(this.props.surveyId)}
          handleCollectorChange={this.props.handleCollectorChange}
          handleCollectorDelete={this.props.handleCollectorDelete}
          handleCollectorCopy={this.props.handleCollectorCopy}
        />;
      },
      width: 75,
    });

    return (
      <React.Fragment>
        <Row>
          <Col>
            {
              (this.props.plan.survey.collectorPanelVerifyRequired && Object.values(this.props.collectors).filter(collector => ['Panel'].includes(collector.type)).length > 0)
              && <CollectorsPanelVerify
                  surveyId={surveyId}
                  collectors={Object.values(this.props.collectors)}
                  onChange={() => this.props.handleOnChange()}
              />
            }
          </Col>
        </Row>
        <Row>
          <ReactTable
            minRows={0}
            defaultPageSize={50}
            className={'table-wrap pb-5'}
            data={Object.values(this.props.collectors)}
            columns={columns}
            showPagination={!_.isEmpty(this.props.collectors)}
            PaginationComponent={Pagination}
            noDataText={<FormattedMessage id="collectors.listItems.table.noCollectors" />}
            loading={this.props.loading}
          />
        </Row>
        <Row>
          <div className="d-flex btn-group">
            {this.state.checked.length > 0 && <CollectorsModalDeleteBulk collectors={this.state.checked} handleCollectorDelete={id => this.props.handleCollectorDelete(id)} />}
            {this.state.checked.length > 0 && <Button color="primary" onClick={this.onEditClick}><FormattedMessage id="_.button.edit"/></Button>}
            <Button color="primary" onClick={() => {
              let checked = this.state.checked;

              if(this.state.checked.length === collectorsPanelCount){
                checked = [];
              }else{
                this.props.collectors.map(collector => {
                  if(checked.indexOf(collector.id) < 0){
                    checked.push(collector.id);
                  }
                });
              }
              this.setState({ checked })
            }}>{this.state.checked.length === collectorsPanelCount ? 'Odznacz' : 'Zaznacz'} wszystkie</Button>
            {type === 'Panel' && <LaddaButton
              className="btn btn-primary btn-ladda mb-0"
              loading={this.state.creating}
              data-style={ZOOM_OUT}
              onClick={() => {
                this.setState({
                  creating: true
                });

                api.survey.post.collector({
                  survey: surveyId,
                  collector: {
                    type: 'Panel'
                  }
                }).then(data => {
                  history.push(appRoutes.survey.collectors.collector.view(surveyId, data.id))
                }).catch(() => {
                  this.setState({
                    creating: false
                  })
                });
              }}
            ><i className="fas fa-plus" /> Dodaj nowy</LaddaButton>}
            {(type === 'Panel' && this.state.checked.length > 1 && this.props.plan.survey.collectorPanelTemplateCreate) && <CollectorPanelCreateTemplate collectors={this.state.checked} surveyId={this.props.surveyId} />}
          </div>

          {(type === 'Panel' && this.props.plan.survey.collectorPanelRealizationAutomate) && <div className="ml-auto">
            <Link className="btn btn-primary" to={appRoutes.survey.collectors.listPanelRealizationAutomate(surveyId)}><i className="fas fa-dog-leashed mr-2" /> <span>Pokaż automatyczną realizacje</span></Link>
          </div>}
        </Row>
      </React.Fragment>
    )
  }
}

CollectorsList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  plan: PropTypes.shape({
    survey: PropTypes.shape({
      collectorPanelTemplateCreate: PropTypes.bool.isRequired,
      collectorPanelVerifyRequired: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  type: PropTypes.string,
  surveyId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  collectors: PropTypes.array.isRequired,
  handleCollectorChange: PropTypes.func.isRequired,
  handleCollectorDelete: PropTypes.func.isRequired,
  handleCollectorCopy: PropTypes.func.isRequired,
  isUserRespondent: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func,
};



function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
    isUserRespondent: state.user.type === 'Respondent',
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps)(injectIntl(CollectorsList));
