import React, {Component} from 'react';
import ReactDOM from "react-dom";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import Data from './Data/Data';
import Settings from './Settings/Settings';
import AnalysisCreateButton from "./AnalysisCreateButton";
import SelectAnalysis from "./SelectAnalysis";
import {Button} from "reactstrap";
import api from "api";
import { connect } from 'react-redux';
import checkIsGroupRole from "../../../../../utils/checkIsGroupRole";

class Sidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      analyses: [],
      tooltips: {
        data: false,
      },
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.getWidthByView = this.getWidthByView.bind(this);
  }

  toggleTooltip(tooltipType) {
    this.setState({
      tooltips: {
        [tooltipType]:  !this.state.tooltips[tooltipType]
      }
    });
  }

  componentDidMount(){
    const selfOffset = ReactDOM.findDOMNode(this).getBoundingClientRect();
    // ReactDOM.findDOMNode(this).style.width = selfOffset.width+'px';

    window.onscroll = () => {
      if (window.pageYOffset >= selfOffset.top) {
        ReactDOM.findDOMNode(this).classList.add("sticky")
      } else {
        ReactDOM.findDOMNode(this).classList.remove("sticky");
      }
    };

    api.survey.get.analyses({
      id: this.props.analysis.survey
    }).then(analyses => {
      this.setState({
        analyses
      })
    });
  }

  componentWillUnmount(){
    window.onscroll = false;
  }

  getWidthByView(){
    console.log('this.props.view', this.props.view);
    switch(this.props.view) {
      case 'hide': return '0px'
      case 'normal': return '300px'
      case 'huge': return '55vw'
    }
    return '300px'
  }

  render() {
    const {user, userGroup} = this.props;

    return (
      <aside className={"d-none " + (this.props.view !== 'hide' && "d-lg-block")} style={{ width: this.getWidthByView()}}>
        <div className="mt-2">
          <SelectAnalysis analyses={this.state.analyses} analysis={this.props.analysis}/>

          <div className="accordion" id="accordionAnalysisSidebar">

            <Button className="btn btn-link h3 mb-1 w-100 text-left pl-1" color="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <i className="fas fa-angle-down mr-2 active"/>
              <i className="fas fa-database mr-1"/>
              <FormattedMessage id='analysis.sidebar.tooltip.data'/>
            </Button>

            <div id="collapseOne" className="collapse pl-3 mb-2 show" aria-labelledby="headingData" data-parent="#accordionAnalysisSidebar">
              <div className="mr-1">
                <Data onChange={this.props.onChangedData}/>
              </div>
            </div>

            {checkIsGroupRole('accessEditAnalysisData', user, userGroup) && <Button className="btn btn-link h3 mb-1 w-100 text-left pl-1 collapsed" color="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
              <i className="fas fa-angle-down mr-2 active"/>
              <i className="fas fa-sliders-h mr-1"/>
              <FormattedMessage id='analysis.sidebar.tooltip.settings'/>
            </Button>}

            <div id="collapseTwo" className="collapse pl-3 mb-2" aria-labelledby="headingSettings" data-parent="#accordionAnalysisSidebar">
              <div className="mr-1">
                <Settings onChange={this.props.onChangedData} analyses={this.state.analyses}/>
              </div>
            </div>

          </div>
          {checkIsGroupRole('accessEditAnalysisData', user, userGroup) && <AnalysisCreateButton />}

        </div>
      </aside>
    )
  }
}

Sidebar.propTypes = {
  onChangedData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
    userGroup: state.user.userGroups,
    user: state.user,
  }
}

export default connect(mapStateToProps)(Sidebar);
