import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button, Alert} from "reactstrap";
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import api from 'api';
import { connect } from 'react-redux';
import {topSurveysCollectorRemoved} from '../../../Structure/data/survey/actions';

class CollectorsModalDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      deleting: false
    }
  }

  collectorDelete(){
    this.setState({
      deleting: true
    });

    api.surveyCollector.delete.collector({
      id: this.props.collector.id
    }).then(() => {
      if (this.props.collector.type === 'TopSurveys') {
        this.props.topSurveysCollectorRemoved();
      }
      this.setState({deleting: false});
      this.props.toggleModal();
      this.props.handleCollectorDelete(this.props.collector.id);
    }).catch(() => {
      this.setState({deleting: false});
    });
  }

  render() {
    const { deleting } = this.state;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="lg">
        <ModalHeader toggle={this.props.toggleModal} tag="h2" className="bg-danger"><span className="text-white"><FormattedMessage id="collectors.listItems.modalDelete.modalHeader" /></span> <span className="text-white">{this.props.collector.name || <FormattedMessage tagName="h2" id={`_.surveyCollector.${this.props.collector.type}.name`}>{msg => msg + ' ' + this.props.collector.position}</FormattedMessage>}</span></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
        <Alert color="danger">
          <div className="d-flex align-items-center">
            <i className="fas fa-exclamation-triangle fa-3x pr-3" />
            <div className="d-flex flex-column">
              <FormattedMessage id="collectors.listItems.modalDelete.modalBody">{msg => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>
              <FormattedMessage id="collectors.listItems.modalDelete.modalBody.hint">{msg => <span className="mt-2">{msg}</span>}</FormattedMessage>
            </div>
          </div>
        </Alert>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.modal.delete.confirmation">{
            (message) => <LaddaButton
              className="btn btn-danger btn-ladda"
              loading={deleting}
              data-style={ZOOM_OUT}
              onClick={() => this.collectorDelete()}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggleModal}>{message}</Button>
          }</FormattedMessage>

        </ModalFooter>
      </Modal>
    )
  }
}

CollectorsModalDelete.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleCollectorDelete: PropTypes.func.isRequired
};

export default connect(null, {topSurveysCollectorRemoved})(CollectorsModalDelete);
