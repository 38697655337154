import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import QuestionContentTooltip from "../../../../../../components/QuestionContentTooltip/QuestionContentTooltip";
import {connect} from "react-redux";

FilterSerialAction.propTypes = {

};

function FilterSerialAction(props) {
  const filter = props.filter;
  let cafeteryName = 'odpowiedzi';

  if(filter.questionTo){
    switch (filter.questionTo.filterBy) {
      case 'answers': {
        cafeteryName = 'odpowiedzi';
        break;
      }
      case 'rows': {
        cafeteryName = 'wiersze';
        break;
      }
      case 'columns': {
        cafeteryName = 'kolumny';
        break;
      }
    }
  }

  const questionTo = _.find(props.questions, question => filter.questionTo && question.id === filter.questionTo.question);
  return <React.Fragment>Zastąp {cafeteryName} pytania <QuestionContentTooltip question={questionTo}/></React.Fragment>;
}

function mapStateToProps(state) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    questions: questions,
  }
}

export default connect(mapStateToProps)(FilterSerialAction);