import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {map} from "lodash";
import Select from "react-select";
import PropTypes from "prop-types";
import api from "api";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../components/Button";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import useAfterUpdateEffect from "../../../../utils/useAfterUpdateEffect";

const UserPrizesBulkStatusSelect = props => {
  const [value, setValue] = React.useState();
  const [saving, setSaving] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  useAfterUpdateEffect(() => {
    if(!modalOpen){
      setValue(null)
    }
  }, [modalOpen])

  const onSelect = (e) => {
    setValue(e.value)
    setModalOpen(true)
  }

  const change = () => {
    setSaving(true)
    api.userPrize.patchStatuses({
      status: value,
      ids: props.checked
    }).then(() => {
      props.onUpdate();
      setModalOpen(false)
      setSaving(false)
    })
  }

  const statusOptions = map(props.statuses, status => {
    return {
      label: <FormattedMessage id={"admin.userPrizes.status-" + status} />,
      value: status
    }
  });

  return <React.Fragment>
    <Select
      options={statusOptions}
      placeholder="Zmień status zaznaczonych"
      className="w-100 w-lg-25 mt-3"
      loading={true}
      onChange={onSelect}
    />
    {modalOpen && <Modal isOpen>
      <ModalHeader tag="h2">Zmień status</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p>Potwierdź zmianę statusu</p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <LaddaButton
          className="btn btn-primary btn-ladda"
          loading={saving}
          data-style={ZOOM_OUT}
          onClick={change}
        >Zmień</LaddaButton>
      </ModalFooter>
    </Modal>}
  </React.Fragment>;
};

UserPrizesBulkStatusSelect.propTypes = {
  statuses: PropTypes.array.isRequired,
  checked: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default UserPrizesBulkStatusSelect;