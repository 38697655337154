import React, {Component} from 'react';
import EditAction from './Actions/EditAction';
import PropTypes from 'prop-types';
import CheckUserAction from "./Actions/CheckUserAction";
import PayPalPayout from "./PayPalPayout";
import UserPrizeVerificationResendModal from "./UserPrizeVerificationResendModal";

class UserPrizesList extends Component {
  render() {
    return (
      <div className="d-flex flex-row flex-nowrap justify-content-end">
        {this.props.userPrize.status === 3 && <UserPrizeVerificationResendModal userPrize={this.props.userPrize} />}
        <CheckUserAction user={this.props.userPrize.user} />
        <EditAction userPrize={this.props.userPrize} statuses={this.props.statuses} handleChange={this.props.handleChange}/>
        {(this.props.userPrize.transferType === 'PayPal' && [5,20].includes(this.props.userPrize.status)) && <PayPalPayout userPrize={this.props.userPrize} onSuccess={this.props.handleChange} />}
      </div>
    )
  }
}

UserPrizesList.propTypes = {
  userPrize: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired,
  handleChange: PropTypes.array.isRequired
};

export default UserPrizesList;
