import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Tooltip} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {copySet} from "scenes/Survey/Structure/data/sets/actions";
import SelectSets from "scenes/Survey/Structure/components/SelectSets";
import Checkbox from "components/Checkbox";

class ModalCopy extends Component {

  constructor(props) {
    super(props);

    this.state = {
      shift: { value: 0, label: <FormattedMessage id="set.headerActions.modalCopy.select.before"/>},
      reference: props.set.id,
      saving: false,
      copyFiltersTooltip: false,
      copyOptions: {
        filters: false
      }
    };

    this.onShiftChange = this.onShiftChange.bind(this);
    this.onReferenceChange = this.onReferenceChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]});
  }

  onShiftChange(select) {
    this.setState({
      shift: select
    });
  }

  onReferenceChange(setId) {
    this.setState({
      reference: setId
    });
  }

  onSubmit() {
    this.setState({saving: true});

    const data = {
      set: this.props.set.id,
      reference: this.state.reference,
      position: this.props.sets[this.state.reference].position + this.state.shift.value,
      copyOptions: this.state.copyOptions
    };

    this.props.copySet(data).then(() => {
      this.setState({saving: false});
      this.props.toggleModal();
    })
  }

  render() {
    const {shift, saving, reference} = this.state;

    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
      <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="set.headerActions.modalCopy.cardHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Row>
          <Col lg={3}>
            <Label for="shift"><FormattedMessage id="set.headerActions.modalCopy.label.shift"/></Label>
          </Col>
          <Col lg={9}>
            <Select
              clearable={false}
              value={shift}
              options={[
                { value: 0, label: <FormattedMessage id="set.headerActions.modalCopy.select.before"/>},
                { value: 1, label: <FormattedMessage id="set.headerActions.modalCopy.select.after"/>},
              ]}
              onChange={this.onShiftChange}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label for="reference"><FormattedMessage id="set.headerActions.modalCopy.label.reference"/></Label>
          </Col>
          <Col lg={9}>
            <SelectSets isMulti={false} value={reference} onChange={this.onReferenceChange}/>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Label for="filters">
              <FormattedMessage id="set.headerActions.modalCopy.label.copyOptions.filters"/>
              <i id="copy-filters-info" className="fas fa-question-circle ml-2"/>
              <Tooltip
                delay={0}
                placement="top"
                isOpen={this.state.copyFiltersTooltip}
                target={'copy-filters-info'}
                toggle={() => this.toggle('copyFiltersTooltip')}
              >
                <FormattedMessage id="set.headerActions.modalCopy.label.tooltip.copyFiltersInfo" />
              </Tooltip>
            </Label>
          </Col>
          <Col lg={9}>
            <Checkbox id="copyOptions-filters" tooltip={false} checked={this.state.copyOptions.filters} onClick={() => this.setState({copyOptions: {...this.state.copyOptions, filters: !this.state.copyOptions.filters}})} />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="set.headerActions.modalCopy.button.copy">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={this.onSubmit}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  }
}

ModalCopy.propTypes = {
  set: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  sets: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    sets: state.survey.structure.data.sets
  }
}

export default connect(mapStateToProps, {copySet})(ModalCopy);