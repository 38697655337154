import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input} from "reactstrap";
import ModalDelete from "./components/ModalDelete";
import LoadingSpinner from "components/LoadingSpinner";
import {deleteScalePoint, updateScalePoint} from "scenes/Survey/Structure/data/scalePoints/actions";
import ButtonDrag from "./components/ButtonDrag";
import {FormattedMessage} from "react-intl";
import reactStringReplace from "react-string-replace";
import {isMobile, isTablet} from "react-device-detect";
import HtmlEditor
  from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";
import getCKConfigByPlan from "utils/ckeditor/cafeteryConfig";
import reactHtmlParser from "react-html-parser";
import Checkbox from "components/Checkbox";

class ScalePoint extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownSettingsOpen: false,
      checked: false,
      deleteButton: false,
      modalDelete: false,
      deleting: false,
      editing: false,
      saving: false,
      hovered: false,
      scalePoint: {
        content: props.scalePoint.content
      }
    };

    this.deleteScalePoint = this.deleteScalePoint.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.updateScalePoint = this.updateScalePoint.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setHovered = this.setHovered.bind(this);
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.updateScalePoint()
  }

  onClick(){
    this.setState({
      editing: true
    });
  }

  onChangeContent(content){
    const {saving} = this.state;

    if(!saving) {
      this.setState({
        scalePoint: {
          content
        }
      });
    }
  }

  updateScalePoint(){
    const {scalePoint} = this.state;

    if (scalePoint.content.length === 0) {
      this.setState({scalePoint: {...this.state.scalePoint, content: this.props.scalePoint.content}});
      return;
    }

    this.setState({
      editing: false,
      saving: true,
    });

    if(scalePoint.content.length === 0)
      return false;

    const data = {
      id: this.props.scalePoint.id,
      scalePoint: {
        content: scalePoint.content
      }
    };

    this.props.updateScalePoint(data).then(() => this.setState({saving: false}));
  }

  deleteScalePoint(){
    this.setState({
      deleting: true
    });

    if(!this.state.deleting){
      this.props.deleteScalePoint({
        id: this.props.scalePoint.id
      })
    }
  }

  toggleChecked(){
    const checked = !this.state.checked;

    this.setState({
      ...this.state,
      checked
    }, () => this.props.handlerChecked(checked));
  }

  toggle(type) {
    this.setState({
      [type]: !this.state[type]
    })
  }

  setHovered(hovered) {
    this.setState({hovered});
  }

  render() {
    const {position, scalePoint, searchInStructure, hideInStructure, plan} = this.props;
    const {deleting, editing, saving, checked, hovered} = this.state;
    const ctrlEnterKeyCode = 1114125;
    const gripStyle = hovered ? {opacity: 1} : {opacity: 0};
    let editor;

    if (isMobile || isTablet) {
      editor = <Input value={this.state.scalePoint.content} onChange={e => this.onChangeContent(e.target.value)} onBlur={this.updateScalePoint} autoFocus={true} onKeyPress={this.handleKeyPress}/>
    } else {
      editor = <HtmlEditor
        data={scalePoint.content}
        config={getCKConfigByPlan(plan)}
        type="inline"
        onChange={event => this.onChangeContent(event)}
        onBlur={event => {
          this.updateScalePoint();
          this.setState({editing: false})
        }}
        onKey={event => {
          if (event.data.keyCode === ctrlEnterKeyCode) {
            event.cancel();
            this.updateScalePoint();
            setTimeout(() => {
              event.editor.destroy();
              this.setState({editing: false})
            }, 1);
          }
        }}
      />
    }

    let style = {
      position: 'relative'
    };

    let contentStyle = hideInStructure === 'minified' ? {
      width: '75%',
      display: 'list-item',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    } : {wordBreak: 'break-all'};

    if(deleting) style.opacity = 0.35;

    let scalePointContent = scalePoint.content;
    if (searchInStructure.length > 0) {
      scalePointContent = reactStringReplace(scalePointContent, searchInStructure, (match, i) => (
        <span className="bg-warning" key={i}>{match}</span>
      ));
    }

    return (
      <div className="sortable-draggable line-wrap" style={style} data-id={this.props.scalePoint.id} onMouseEnter={_ => this.setHovered(true)} onMouseLeave={_ => this.setHovered(false)}>
        <ButtonDrag id={this.props.scalePoint.id} gripStyle={gripStyle}/>

        <ul className="mb-0 d-flex pl-2">
          <Checkbox checked={checked} id={'scalePoint-' + scalePoint.id + '-check'} onClick={() => this.toggleChecked()} messageId={"checkbox.check.answer"}/>
          <li>{position + 1}.</li>

          <li className="flex-grow-1" style={contentStyle} onClick={this.onClick}>
              {editing
                ? editor
                : saving
                  ? <LoadingSpinner width={21} classNames="my-0"/>
                  : (searchInStructure.length > 0 ? scalePointContent : reactHtmlParser(scalePointContent))
              }
          </li>

          <li className="ml-2">
            <Dropdown isOpen={this.state.dropdownQuestionTypeOpen} toggle={() => this.toggle('dropdownQuestionTypeOpen')}>
              <DropdownToggle className="m-0 p-0" color="">
                <span className="fas fa-ellipsis-v hoverable" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem tag="a" onClick={() => this.toggle('modalDelete')}>
                  <p><span className="fas fa-trash"/></p> <FormattedMessage tagName="h5" id="scalePoint.modalDelete.button" />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>

        {this.state.modalDelete && <ModalDelete handleDelete={this.deleteScalePoint} id={this.props.scalePoint.id} isOpen={this.state.modalDelete} toggleModal={() => this.toggle('modalDelete')}/>}
      </div>
    )
  }
}

ScalePoint.propTypes = {
  scalePoint: PropTypes.object.isRequired,
  handlerChecked: PropTypes.func,
  position: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  return {
    searchInStructure: state.survey.structure.searchInStructure,
    hideInStructure: state.survey.structure.hideInStructure,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, { deleteScalePoint, updateScalePoint })(ScalePoint);