import React from 'react';
import PropTypes from 'prop-types';
import {Input, ListGroupItem} from "reactstrap";
import api from "../../api";

const SurveysCategoryAdd = props => {
  const [inputVisible, setInputVisible] = React.useState(false);
  const [name, setName] = React.useState('');
  const [adding, setAdding] = React.useState(false);

  const add = () => {
    setAdding(true);

    api.surveysCategories.post({
      name,
    })
      .then(data => {
        setName('');
        setInputVisible(false);
        props.onAdded(data.data);
      })
      .finally(() => {
        setAdding(false);
      })
  }

  return (
    <ListGroupItem className="border-0">
      {inputVisible && <Input
        autoFocus
        size="sm"
        readOnly={adding}
        placeholder="Wpisz nazwę..."
        onChange={e => setName(e.target.value)}
        onBlur={() => {
          setName('');
          setInputVisible(false);
        }}
        onKeyDown={e => {
          switch(e.key){
            case 'Enter': {
              add();
              break;
            }
            case 'Escape': {
              setName('');
              setInputVisible(false);
              break;
            }
          }
        }}
        value={name}
      />}
      {!inputVisible && <span className="pointer" onClick={() => setInputVisible(true)}><i className="fas fa-plus" /> Dodaj</span>}
    </ListGroupItem>
  );
};

SurveysCategoryAdd.propTypes = {
  onAdded: PropTypes.func.isRequired,
};

export default SurveysCategoryAdd;