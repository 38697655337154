import React, {Component} from 'react';
import moment from "moment/moment";
import {Card, CardBody, CardHeader, Col, Input, InputGroup, Row} from "reactstrap";
import DatePicker from "react-datepicker";
import FindShippingList from "../../FindShipping/FindShippingList";
import Pagination from "../../../../components/Pagination";
import {FormattedMessage} from "react-intl";
import ReactTable from "react-table";
import api from "../../../../api";

function CollectorRespondents(props) {
  const [email, setEmail] = React.useState('');
  const [dateFrom, setDateFrom] = React.useState(moment().subtract(7, 'days'));
  const [dateTo, setDateTo] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [loading, setLoading] = React.useState(false)

  const columns = [
    {
      id: 'surveyId',
      Header: 'ID ankiety',
      sortable: false,
      accessor: row => row.surveyId
    },
    {
      id: 'surveyName',
      Header: 'Nazwa ankiety',
      sortable: false,
      accessor: row => row.surveyName
    },
    {
      id: 'surveyPaused',
      Header: 'Stan ankiety',
      sortable: true,
      accessor: row => row.surveyPaused ? "Nieaktywny" : "Aktywny"
    },
    {
      id: 'id',
      Header: 'ID Kolektora',
      sortable: false,
      accessor: row => row.id
    },
    {
      id: 'name',
      Header: 'Nazwa kolektora',
      sortable: false,
      accessor: row => row.name
    },
    {
      id: 'paused',
      Header: 'Stan kolektora',
      sortable: true,
      accessor: row => row.paused ? "Nieaktywny" : "Aktywny"
    },
    {
      id: 'startAt',
      Header: 'Data startu',
      sortable: false,
      accessor: row => row.startAt
    },
    {
      id: 'endAt',
      Header: 'Data końca',
      sortable: false,
      accessor: row => row.endAt
    },
    {
      id: 'status',
      Header: 'Status',
      sortable: false,
      accessor: row => row.status
    },
    {
      id: 'guid',
      Header: 'Link',
      sortable: false,
      accessor: row => <a target={"_blank"} href={process.env.ANKIETEO_HOST + "/s/" + row.guid}>{row.guid}</a>
    },
  ];

 const fetchData = (state) => {
    if(!email){
      setLoading(false)
      setPage(0)
      setPageSize(10)
      setData([])
      setCount(0)

      return;
    }

    setLoading(true);
    setPage(state.page);
    setPageSize(state.pageSize)

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted,
        email: email,
        dateFrom: dateFrom,
        dateTo: dateTo
      }
    };

    api.user.getCollectorsRespondents(data).then(res => {
      setData(res.data);
      setCount(res.count);
      setLoading(false)
    });
  }

  return <Row className="animated fadeIn">
    <Col>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <h2>Znajdź</h2>

          <div className="d-flex flex-row flex-nowrap ml-auto align-items-center">
            <div className="mr-2">
              <DatePicker
                  className="mr-2 mb-0"
                  placeholderText={"Data od"}
                  selected={dateFrom}
                  onChange={setDateFrom}
                  dateFormat={'Y-MM-DD'}
                  locale={localStorage.language}
              />
            </div>
            <div className="mr-2">
              <DatePicker
                  className="mr-2 mb-0"
                  placeholderText={"Data do"}
                  selected={dateTo}
                  onChange={setDateTo}
                  dateFormat={'Y-MM-DD'}
                  locale={localStorage.language}
              />
            </div>
            <div className="custom-search-input w-lg-100 float-right mt-1">
              <InputGroup className="p-0 mb-0">
                <Input className="p-0" placeholder="email respondenta" onChange={e => setEmail(e.target.value)}/>
                <span className="input-group-btn py-1">
                <button className="btn" type="button"><i className="fas fa-search" onClick={() => {fetchData({page: page,pageSize: pageSize})}}/></button>
              </span>
              </InputGroup>
            </div>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <Row>
            <Col>
              <ReactTable
                  minRows={0}
                  className={'table-wrap'}
                  data={data}
                  columns={columns}
                  defaultPageSize={10}
                  showPagination={!_.isEmpty(data)}
                  PaginationComponent={Pagination}
                  noDataText={"Wpisz email aby zobaczyć kolektory respondenta"}
                  loading={loading}
                  onFetchData={fetchData}
                  rowsCount={count}
                  manual
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>;
}

export default CollectorRespondents;
