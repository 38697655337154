import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import history from "services/history";
import appRoutes from "appRoutes";
import {Link} from "react-router-dom";
import api from "api";
import PlanForm from "./PlanForm";
import CardFooter from "reactstrap/es/CardFooter";


class PlanAdd extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      errors: {},
    };

    this.form = React.createRef();
    this.createPlan = this.createPlan.bind(this);
  }

  createPlan(){
    const data = this.form.current.state.data;

    this.setState({
      saving: true,
    });

    api.plan.post.plan(data)
      .then(res => {
        this.setState({
          saving: false,
        });
        history.push(appRoutes.admin.settings.plans.list);
      })
      .catch(res => {
        this.setState({
          saving: false,
          errors: res.response.data.form.errors,
        });
      })
    ;
  }

  render() {
    const {errors, saving} = this.state;

    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader><FormattedMessage tagName="h2" id="admin.planAdd.cardHeader" /></CardHeader>
            <hr className="my-0" />
            <CardBody>
              <PlanForm
                ref={this.form}
                errors={errors}
                handleSubmit={() => this.createPlan()}
              />
            </CardBody>
            <CardFooter>
              <Link to={appRoutes.admin.settings.plans.list}>
                <Button color="secondary">Wróć do listy planów</Button>
              </Link>
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda float-right"
                  loading={saving}
                  data-style={ZOOM_OUT}
                  onClick={this.createPlan}
                >{message}</LaddaButton>
              }</FormattedMessage>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default PlanAdd;