import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import api from "../api";
import useAfterUpdateEffect from "../utils/useAfterUpdateEffect";
import {Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const SurveysCategorySelect = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [nameValue, setNameValue] = React.useState('');
  const [saving, setSaving] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [value, setValue] = React.useState(props.value || null);
  const [surveyCategories, setSurveyCategories] = React.useState([]);

  React.useEffect(() => {
    getSurveysCategories();
  }, []);

  useAfterUpdateEffect(() => {
    props.onChange(value);
  }, [value]);

  const getSurveysCategories = () => {
    api.surveysCategories.list().then(data => setSurveyCategories(data))
  }

  const save = () => {
    setSaving(true);

    api.surveysCategories.post({
      name: nameValue,
    })
      .then(data => {
        setNameValue('');
        setModalOpen(false);

        getSurveysCategories();
        setValue(data.data.id);
      })
      .finally(() => {
        setSaving(false);
      })
  }

  const getOptions = () => {
    let options = surveyCategories.map(surveyCategory => ({
      label: surveyCategory.name,
      value: surveyCategory.id,
    }));

    options.unshift({
      label: '--Utwórz nową sekcję--',
      value: 0
    });

    return options;
  }

  return (
    <React.Fragment>
      <Select
        className={"react-select"}
        options={getOptions()}
        placeholder="Wybierz sekcję"
        value={getOptions().find(option => option.value === value)}
        onChange={option => {
          if(option.value === 0){
            setModalOpen(true);
          }else{
            setModalOpen(false);
            setValue(option.value)
          }
        }}
      />
      {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
        <ModalHeader toggle={toggleModalOpen}>Dodaj nową sekcję</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <FormGroup>
            <Input placeholder={'Podaj nazwę sekcji'} onChange={e => setNameValue(e.target.value)} value={nameValue} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
          <Button
            color="primary"
            disabled={!nameValue}
            onClick={save}
          >Utwórz</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

SurveysCategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default SurveysCategorySelect;