import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";


class InputNumber extends Component {
  constructor(props){
    super(props);

    this.state = {
      value: this.props.value
    };

    this.onChange = this.onChange.bind(this);
    this.decrementValue = this.decrementValue.bind(this);
    this.incrementValue = this.incrementValue.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if(nextProps.value !== this.state.value){
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setState({
      value: this.props.value
    })
  }

  onChange(e){
    if (this.props.disabled) {
      return;
    }

    const {min, max, float} = this.props;
    const value = float ? e.target.value : parseInt(e.target.value || 0);


    if(
      (_.isUndefined(min) || (!_.isUndefined(min) && value >= min))
      && (_.isNull(max) || (!_.isNull(max) && value <= max))
    ){
      this.props.onChange(value);
    }
  }

  decrementValue(){
    if (this.props.disabled) {
      return;
    }

    const {min, step} = this.props;
    const value = (this.props.value || 0)-step;

    if(!_.isUndefined(min) && value >= min){
      this.props.onChange(value);
    }
  }

  incrementValue(){
    if (this.props.disabled) {
      return;
    }

    const {max, step} = this.props;
    const value = (this.props.value || 0)+step;

    if(_.isNull(max) || (!_.isNull(max) && value <= max)){
      this.props.onChange(value);
    }
  }

  render() {
    const {step, className} = this.props;

    return (
      <div className="input-number-wrap" id={this.props.id} style={{flex: '1 1 auto'}}>
        <div onClick={this.decrementValue} className="value-button decrease m-0 h-100" />
        <input
          className={"number mb-0  " + className}
          type="number"
          step={step}
          value={this.state.value}
          onChange={this.onChange}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
        />
        <div onClick={this.incrementValue} className="value-button increase m-0 h-100" />
      </div>
    );
  }
}

InputNumber.defaultProps = {
  step: 1,
  min: 0,
  max: null,
  className: "",
  float: false,
  placeholder: "",
  onFocus: null,
  onBlur: null,
  disabled: false,
};

InputNumber.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  id: PropTypes.string,
  className: PropTypes.string,
  float: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export default InputNumber;