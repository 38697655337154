import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Nps extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {details} = this.props;
    const detail = details && details[0];

    return detail ? <span>Wskazana wartość: {detail.value}</span> : <span>Brak odpowiedzi</span>;
  }
}

Nps.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
  })).isRequired
};

export default Nps;
