import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage, injectIntl} from "react-intl";
import {Col, CardBody, Form, FormGroup, Label, ButtonGroup, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import Select from "react-select";
import DictionarySelect from "../../../../../../componentsNew/Dictionaries/DictionarySelect";

class QuestionSummarySettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      questionSummary: props.questionSummary
    };
  }

  changeChartType(chartType){
    this.setState({
      ...this.state,
      questionSummary: {
        ...this.state.questionSummary,
        chartType
      }
    }, () => {
      this.props.onChange(this.state.questionSummary);
    });
  }

  changeDictionary(dictionaryId){
    this.setState({
      ...this.state,
      questionSummary: {
        ...this.state.questionSummary,
        dictionary: dictionaryId
      }
    }, () => {
      this.props.onChange(this.state.questionSummary);
    });
  }

  render() {
    const {config, saving, intl} = this.props;
    const {questionSummary} = this.state;

    return (
      <CardBody>
        <Form>
          <FormGroup row>
            <Label xs={4} md={3}>Wybierz typ wykresu</Label>

            <Col xs={8} md={9}>
              <Select
                options={config.chartTypes.map(type => {
                  return {
                    label: intl.formatMessage({ id: '_.analysis.chartType.' + type }),
                    value: type
                  }
                })}
                value={{
                  label: intl.formatMessage({ id: '_.analysis.chartType.' + questionSummary.chartType }),
                  value: questionSummary.chartType,
                }}
                onChange={(e) => this.changeChartType(e.value)}
              />
            </Col>
          </FormGroup>
          {(this.props.analysisPlan.dictionaries && ['open', 'multiOpen'].indexOf(this.props.questionSummary.question.type) >= 0) && <FormGroup row>
            <Label xs={4} md={3}>Wybierz słownik kodowania</Label>

            <Col xs={8} md={9}>
              <DictionarySelect
                dictionary={questionSummary.dictionary}
                onChange={dictionary => this.changeDictionary(dictionary ? dictionary.id : null)}
              />
            </Col>
          </FormGroup>}

          <ButtonGroup className="float-right mt-2">
            <Button color="secondary" onClick={() => this.props.onCancel()}>Anuluj</Button>
            <FormattedMessage id="_.button.save">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda z-0"
                data-style={ZOOM_OUT}
                loading={saving}
                onClick={() => this.props.onSave()}
              >
                {message}
              </LaddaButton>
            }
            </FormattedMessage>
          </ButtonGroup>
        </Form>
      </CardBody>
    )
  }
}

QuestionSummarySettings.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    chartTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  questionSummary: PropTypes.shape({
    chartType: PropTypes.string.isRequired,
    dictionary: PropTypes.number,
    question: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    analysisPlan: state.user.userPlan.plan.analysis
  }
}

export default connect(mapStateToProps)(injectIntl(QuestionSummarySettings));
