import { SET, UPDATED } from './actions';
import update from "immutability-helper";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.reports;

    case UPDATED:
      return update(state, {
        [action.report.id]: {
          $set: action.report
        }
      });

    default:
      return state;
  }
};