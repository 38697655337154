import React, {Component} from 'react';
import PropTypes from "prop-types";
import api from 'api';
import {Button, UncontrolledTooltip} from 'reactstrap';
import {toast} from "react-toastify";
import Switch from "../../../../components/Switch";
import SurveyAccess from "../../../../components/PlanRestrictionsModals/modals/SurveyAccess";

class CollectorsButtonPaused extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      paused: props.paused,
    };

    this.togglePaused = this.togglePaused.bind(this);
  }

  togglePaused() {
    const {loading, paused} = this.state;

    if(loading){
      return;
    }

    this.setState({
      loading: true,
    });

    api.surveyCollector.patch({
      id: this.props.collector.id,
      surveyCollector: {
        paused: !paused,
      }
    }).then(collector => {
      this.setState({
        loading: false,
        paused: collector.paused,
      });

      this.props.handleCollectorChange(collector.id, 'paused', collector.paused);
    }).catch(res => {
      this.setState({
        loading: false,
      });

      if(res.response.data.form.errors){
        _.map(res.response.data.form.errors, (errors) => {
          toast.error(errors.join(<br />));
        })
      }
    })
  }

  render() {
    const {collector, className} = this.props;
    const {loading, paused} = this.state;

    return <Switch
      className={className}
      checked={!paused}
      onChange={this.togglePaused}
    />
  }
}

CollectorsButtonPaused.defaultProps = {
  className: "p-0 m-0"
};

CollectorsButtonPaused.propTypes = {
  paused: PropTypes.bool.isRequired,
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    paused: PropTypes.bool.isRequired
  }).isRequired,
  handleCollectorChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default CollectorsButtonPaused;