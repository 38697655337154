import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "reactstrap";
import api from 'api';
import history from "services/history";
import appRoutes from "appRoutes";
import reactHtmlParser from "react-html-parser";
import striptags from "striptags";
import { connect } from 'react-redux';
import moment from "moment";

class SurveysQuestionsListIcon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      questions: [],
      loading: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]}, () => {
      if (this.state.tooltip && this.props.hasAccess && this.state.questions.length === 0) {
        this.setState({loading: true});

        api.survey.get.questionsList(this.props.survey.id).then(questions => {
          this.setState({questions, loading: false})
        })
      }
    })
  }

  render() {
    const data = this.props.hasAccess ? _.map(this.state.questions, (question, i) => {
      return <span onClick={e => {
        history.push(appRoutes.survey.preview.question(this.props.survey.id, question.id));
      }} className="d-block text-left pointer" key={i}>P{i +1}. {reactHtmlParser(striptags(question.content))}</span>
    }) : 'Dostęp do tej ankiety zakończył się. Aby uzyskać dostęp zmień plan';

    return (
      <React.Fragment>
        <i id={"survey-questions-list-" + this.props.survey.id} className={"fas fa-list-ul pointer " + this.props.className}/>
        <Tooltip
          autohide={false}
          delay={{show: 1000, hide: 100}}
          placement="bottom"
          isOpen={this.state.tooltip}
          target={"survey-questions-list-" + this.props.survey.id}
          toggle={() => this.toggle('tooltip')}
          innerClassName="scroll-tooltip"
        >
          {this.state.loading ? 'Ładowanie...' : data}
        </Tooltip>
      </React.Fragment>
    )
  }
}

SurveysQuestionsListIcon.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string
};

function mapStateToProps(state, props) {
  return {
    hasAccess: state.user.userPlan.plan.survey.accessDaysAfterCreation === 0 || moment().diff(moment(props.survey.createdAt), 'days') <= state.user.userPlan.plan.survey.accessDaysAfterCreation
  }
}

export default connect(mapStateToProps)(SurveysQuestionsListIcon);