import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, Row, Col, CardImgOverlay, Alert, Input} from "reactstrap";
import HtmlEditor from "../../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";
import striptags from "striptags";
import {FormattedMessage} from "react-intl";
import {updateQuestion} from "scenes/Survey/Structure/data/questions/actions";
import reactStringReplace from 'react-string-replace';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import LoadingSpinner from "components/LoadingSpinner";
import reactHtmlParser from "react-html-parser";
import getCKConfigByPlan from 'utils/ckeditor/questionConfig';
import _ from "lodash";
import {isMobile, isTablet} from "react-device-detect";
import {getQuestionTemplateName} from "../../../../../../../../../../../../../../../../../../../../../services/questionTypes";

class Content extends Component {

  constructor(props) {
    super(props);

    this.state = {
      content: props.question.content,
      editing: false,
      saving: false
    };

    this.onChange = this.onChange.bind(this);
    this.updateContent = this.updateContent.bind(this);
  }

  onChange(content){
    this.setState({
      content
    })
  }

  updateContent(){
    this.setState({
      saving: true,
    });

    const data = {
      id: this.props.question.id,
      question: {
        content: this.state.content
      }
    };

    this.toggleEditing();
    this.props.updateQuestion(data).then(() => {
      this.setState({
        saving: false
      });
    }).catch(() => {
      this.setState({
        saving: false,
      })
    })
  }

  toggleEditing(){
    this.props.handleEditing(this.state.editing);

    let obj = {
      editing: !this.state.editing,
    }

    if(!this.state.editing){
      obj.content = this.props.question.content
    }

    this.setState(obj);
  }

  render() {
    const {question, searchInStructure, hideInStructure, plan, positionInSurvey} = this.props;
    const {saving, editing, content} = this.state;
    let style = {};
    if(saving){
      style.opacity = 0.3
    }

    let contentStyle = hideInStructure === 'minified' ? {
      width: '65%',
      display: 'list-item',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    } : {display: 'contents'};

    const editor = <div className="animated fadeIn w-100">
      <Row>
        <Col>
          {(isMobile || isTablet)
            ? <Input value={content} onChange={e => this.onChange(e.target.value)} onBlur={this.updateContent} autoFocus={true} />
            : <React.Fragment>
              <HtmlEditor
                onChange={e => this.onChange(e)}
                data={content}
                config={getCKConfigByPlan(plan)}
              />
              <small>Liczba znaków: {content ? striptags(content.replace(/&nbsp;/g, "")).length : 0}</small>
            </React.Fragment>}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mr-2"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={() => this.updateContent()}
            >{message}</LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) => <Button onClick={() => this.toggleEditing()}>{message}</Button>
          }</FormattedMessage>
        </Col>
      </Row>
    </div>;

    if(editing){
      return editor;
    }

    let questionContent = (hideInStructure === 'questions') ? reactHtmlParser(striptags(question.content)) : reactHtmlParser(question.content);

    if (searchInStructure.length > 0) {
      questionContent = reactStringReplace(questionContent, searchInStructure, (match, i) => (
        <span className="bg-warning" key={i}>{match}</span>
      ));
    }

    return (
      <React.Fragment>
        <div className="d-flex flex-column w-100">
          <p className="w-100 mb-0 d-none d-print-block font-italic"><FormattedMessage id={`_.question.${getQuestionTemplateName(question)}.typeName`}/></p>
          <div>
            <h2 className="float-left p-0 mr-2" role="question-position-in-survey">P{positionInSurvey}. </h2>
            <h2 className="float-left mb-0 mr-3 p-0 img-30" style={contentStyle} onClick={() => this.toggleEditing()}>{saving ? <span style={style}>{this.state.content}</span> : (!_.isEmpty(questionContent) ? <span className={"ck-content"}>{questionContent}</span> : <Alert color="warning" className="my-0 py-0"><i><FormattedMessage id="question.content.noContent"/></i></Alert>)}
              {saving && <CardImgOverlay>
                <LoadingSpinner width={25} absolute={true}/>
              </CardImgOverlay>}
            </h2>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Content.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string
  }).isRequired,
  handleEditing: PropTypes.func.isRequired,
  searchInStructure: PropTypes.string.isRequired,
  hideInStructure: PropTypes.string,
  positionInSurvey: PropTypes.number.isRequired,
};

function mapStateToProps(state, props){
  let positionInSurvey = 1;
  let positionFound = false;

  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    const set = state.survey.structure.data.sets[setId];
    if(positionFound) return false;
    _.each(set.blockPages, blockId => {
      if(positionFound) return false;
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        if(positionFound) return false;
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          if(questionId === props.question.id){
            positionFound = true;
            return false;
          }
          positionInSurvey++;
        })
      });
    });
  });

  return {
    hideInStructure: state.survey.structure.hideInStructure,
    positionInSurvey,
    plan: state.user.userPlan.plan,
  };
}

export default connect(mapStateToProps, { updateQuestion })(Content);