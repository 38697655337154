import React, {Component} from 'react';
import SummarySharedForm from './SummarySharedForm';
import {Alert, Row, Container} from 'reactstrap';
import Summary from "scenes/Survey/Summary/components/Summary";
import withParams from "../../utils/withParams";

class SummaryShared extends Component {

  constructor(props) {
    super(props);

    this.state = {
      surveyId: null,
      surveyName: '',
      loading: true,
    };
  }

  render() {
    const {surveyName, surveyId, summary} = this.state;

    return (
      <main className="app align-items-center">
        <Row className="justify-content-center">
          <React.Fragment>
            {!surveyId && <SummarySharedForm handle={data => {
              this.setState({
                surveyId: data.surveyId,
                surveyName: data.surveyName,
                summary: data.summary,
              })
              document.title = `Ankieteo - ${data.surveyName}`;
            }} guid={this.props.params.guid}/>}
            {surveyId && <div className="d-flex flex-column w-100">
              <h1 className="text-center">{surveyName}</h1>
              <Summary
                surveyId={surveyId}
                hideFilters={true}
                summary={summary}
                filters={{
                  statuses: summary.statuses,
                  dateFrom: summary.dateFrom,
                  dateTo: summary.dateTo,
                  interval: summary.interval,
                }}
              />
            </div>}
          </React.Fragment>
        </Row>
      </main>
    )
  }
}

export default withParams(SummaryShared);