import React, {Component} from 'react';
import PropTypes from "prop-types";
import striptags from 'striptags';

class NumericMulti extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {details} = this.props;

    return (
      <ol>
        {details.map((detail, i) => {
          return <li key={i}>{detail.value1}</li>
        })}
      </ol>
    )
  }
}

NumericMulti.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    value1: PropTypes.number.isRequired,
  }),).isRequired,
};

export default NumericMulti;