import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button} from "reactstrap";
import classnames from "classnames";
import Tooltip from "../../../../../../../components/Tooltip";

class RotateSets extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        rotateSets: props.rotateSets,
      },
    };

    this.toggleRotate = this.toggleRotate.bind(this);
  }

  toggleRotate() {
    this.setState({
      data: {
        rotateSets: !this.state.data.rotateSets
      }
    }, () => {
      this.props.handleUpdate(this.state.data)
    });
  }

  render() {
    return (
      <Button
        className={classnames({
          "float-left mr-1 btn-icon mb-1": true,
          "active": this.state.data.rotateSets
        })}
        color={'secondary'}
        id="rotate-sets"
        onClick={this.toggleRotate}
      >
        <i className="fas fa-random" />

        <Tooltip id={'rotate-sets'} msg={'Rotuj kolejność zestawów'} />
      </Button>
    )
  }
}

RotateSets.propTypes = {
  rotateSets: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default RotateSets;