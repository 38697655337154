import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from 'reactstrap';
import ReactTable from 'react-table';
import Pagination from "components/Pagination";
import api from "api";
import striptags from "striptags";
import CaseStudyEditButton from "./components/CaseStudyEditButton";
import CaseStudyCreateButton from "./components/CaseStudyCreateButton";
import CaseStudyDeleteButton from "./components/CaseStudyDeleteButton";

class CaseStudiesList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searching: false,
			data: [],
			page: 0,
			pageSize: 50,
			count: 0,
			sorted: [],
		};

		this.fetchData = this.fetchData.bind(this);
		this.actions = this.actions.bind(this);
	}

	fetchData(state) {
		this.setState({searching: true, page: state.page, pageSize: state.pageSize});

		api.caseStudy.get().then(res => {
			this.setState({searching: false, data: res, count: res.length});
		});
	}

	actions(report){
		const handler = () => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});
		return (
			<ButtonGroup>
				<CaseStudyEditButton report={report} handleChange={handler}/>
				{/*<ReportCopyButton report={report} handleChange={handler}/>*/}
				<CaseStudyDeleteButton report={report} handleChange={handler}/>
			</ButtonGroup>
		)
	}

	static reduceAndStripLongText(text) {
		text = striptags(text);
		return text.length > 50 ? text.substr(0, 50) + '...' : text
	}

	render() {
		const columns = [{
			Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.id" />,
			accessor: 'id'
		}, {
			Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.title" />,
			accessor: 'title'
		}, {
			Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.slug" />,
			accessor: 'slug'
		}, {
			Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.image" />,
			accessor: 'image'
		}, {
			id: 'shortDescription',
			Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.shortDescription" />,
			accessor: report => CaseStudiesList.reduceAndStripLongText(report.shortDescription)
		}, {
			id: 'description',
			Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.description" />,
			accessor: report => CaseStudiesList.reduceAndStripLongText(report.description)
		}, {
			id: 'publishAt',
			Header: "data publikacji",
			accessor: report => report.publishAt
		}, {
			id: 'actions',
			Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.actions" />,
			accessor: report => this.actions(report),
			sortable: false
		}];

		return (
			<div>
				<CaseStudyCreateButton handleChange={() => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted})}/>
				<ReactTable
					minRows={0}
					className={'table-wrap'}
					data={this.state.data}
					columns={columns}
					defaultPageSize={50}
					showPagination={false}
					PaginationComponent={Pagination}
					noDataText={<FormattedMessage id="admin.reportsList.listItems.table.noReports" />}
					loading={this.state.searching}
					onFetchData={this.fetchData}
					rowsCount={this.state.count}
					manual
					onSortedChange={sorted => this.setState({sorted})}
				/>
			</div>
		)
	}
}

export default CaseStudiesList;