import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, FormGroup, Input, Label} from "reactstrap";
import striptags from "striptags";
import api from "api";
import {omit} from "lodash";


class BlockTemplateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: props.template.id,
        name: props.template.name,
        description: props.template.description,
      }
    };
  }

  save(){
    const {data} = this.state;

    this.props.onSaveStart();

    if(this.props.blockId){
      api.user.blockTemplates.post(this.props.blockId, omit(data, ['id']))
      .then(data => {
        this.props.onSaved(data.block);
      })
      .catch(res => {
        this.props.onError();
      });
    }else{
      api.user.blockTemplates.patch(this.props.template.id, omit(data, ['id']))
      .then(data => {
        this.props.onSaved(data.block);
      })
      .catch(res => {
        this.props.onError();
      });
    }
  }

  render() {
    const {data} = this.state;

    return (
      <div>
        <FormGroup row>
          <Label md={4}>Nazwa</Label>
          <Col md={8}>
            <Input value={data.name} onChange={e => this.setState({data: {...data, name: e.target.value}})} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={4}>Opis</Label>
          <Col md={8}>
            <Input type="textarea" value={data.description} onChange={e => this.setState({data: {...data, description: e.target.value}})} />
          </Col>
        </FormGroup>
      </div>
    );
  }
}

BlockTemplateForm.defaultProps = {
  template: {
    description: '',
    name: '',
  }
};

BlockTemplateForm.propTypes = {
  onSaveStart: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  blockId: PropTypes.number,
  template: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default BlockTemplateForm;