import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {ButtonGroup, CardBody, CardHeader, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import Autorefresh from "./SurveysCategoriesFilters/Autorefresh";
import {connect} from "react-redux";
import Button from "../../components/Button";
import {FormattedMessage} from "react-intl";
import Select from "react-select";
import WithReports from "./SurveysCategoriesFilters/WithReports";
import MobileView from "./SurveysCategoriesFilters/MobileView";
import classnames from 'classnames';
import useDidMountEffect from "../../utils/useDidMountEffect";
import qs from "query-string";
import moment from "moment";
import DatePicker from "react-datepicker";
import {useCallback} from "react";
import SurveyTags from "../../components/Survey/SurveyTags";

const TAGS_PREFIXES = {
  dateFrom: "Data od:",
  dateTo: "Data do:",
  reports: "Zgłoszenia błędów",
  active: "Aktywne",
  inactive: "Nieaktywne",
  favorite: "Ulubione",
  client: "Klient:",
  assigned: "Przydzielono do:",
  query: "Szukasz",
  surveyTags: "Tag:"
}

const SurveysCategoriesFilters = props => {
  let f = false;
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [client, setClient] = React.useState(null);
  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateTo, setDateTo] = React.useState(null);
  const [assignedTo, setAssignedTo] = React.useState([]);
  const [favorite, setFavorite] = React.useState(false);
  const [activity, setActivity] = React.useState(false);
  const [withReports, setWithReports] = React.useState(false);
  const [surveyTags, setSurveyTags] = React.useState([]);
  const [tags, setTags] = React.useState([]);

  useDidMountEffect(() => {
    const params = qs.parse(location.search);

    if(params.client){
      setClient(params.client)
    }
  })

  const isInitialMount = useRef(true);

  useEffect(() => {
    f = favorite;
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
    props.onChange(getFilters())
  }, [query, client, assignedTo, favorite, activity, withReports, dateFrom, dateTo, surveyTags]);

  React.useEffect(() => {
    Mousetrap.bind("shift+f", () => {
      document.querySelector('.favorite-btn:not(.selected)').click();
    });
    Mousetrap.bind("shift+a", () => {
      if(document.querySelector('#activity-btn-group .activity-btn-active.selected')){
        document.querySelector('#activity-btn-group .activity-btn-false').click();
      }else{
        document.querySelector('#activity-btn-group .activity-btn-active').click();
      }
    });

    return () => {
      Mousetrap.unbind("shift+f");
      Mousetrap.unbind("shift+a");
    }
  }, []);

  const getFilters = () => {
    return {
      query,
      client,
      assignedTo,
      favorite,
      activity,
      withReports,
      dateFrom,
      dateTo,
      tags: surveyTags
    }
  }

  const deleteTags = (startsWith) => {
    tags.map((entry, index) => {
      if (entry.startsWith(startsWith)) {
        return tags.splice(index, 1)
      }
    })

    return tags;
  }

  const deleteTag = (value) => {
    let manipulateTags = tags;
    if (value.startsWith(TAGS_PREFIXES.dateFrom)) {
      manipulateTags = deleteTags(TAGS_PREFIXES.dateFrom);
      setDateFrom(null);
    }

    if (value.startsWith(TAGS_PREFIXES.dateTo)) {
      manipulateTags = deleteTags(TAGS_PREFIXES.dateTo);
      setDateTo(null);
    }

    if (value.startsWith(TAGS_PREFIXES.reports)) {
      manipulateTags = deleteTags(TAGS_PREFIXES.reports);
      setWithReports(false);
    }

    if (value.startsWith(TAGS_PREFIXES.favorite)) {
      manipulateTags = deleteTags(TAGS_PREFIXES.favorite);
      setFavorite(false);
    }

    if (value.startsWith(TAGS_PREFIXES.active)) {
      manipulateTags = deleteTags(TAGS_PREFIXES.active);
      setActivity(false)
    }

    if (value.startsWith(TAGS_PREFIXES.inactive)) {
      manipulateTags = deleteTags(TAGS_PREFIXES.inactive);
      setActivity(false)
    }
    
    if (value.startsWith(TAGS_PREFIXES.client)) {
      manipulateTags = deleteTags(TAGS_PREFIXES.client);
      setClient(null);
    }

    if (value.startsWith(TAGS_PREFIXES.query)) {
      manipulateTags = deleteTags(TAGS_PREFIXES.query);
      setQuery('');
    }

    if (value.startsWith(TAGS_PREFIXES.assigned)) {
      manipulateTags = deleteTags(value);
      const email = value.split(' ').pop();
      const user = _.find(props.groupsUsers, user => user.email === email);
      if (user) {
        const manipulatedAssigned = assignedTo.filter(assigned => parseInt(user.userId) !== assigned);
        setAssignedTo(manipulatedAssigned);
      }
    }

    if (value.startsWith(TAGS_PREFIXES.surveyTags)) {
      manipulateTags = deleteTags(value);
      const label = value.split(' ').pop();
      if (label) {
        const manipulatedTags = surveyTags.filter(tags => label !== tags);
        const index = surveyTags.indexOf(label);
        surveyTags.splice(index, 1);
        setSurveyTags(manipulatedTags);
      }
    }

    setTags(manipulateTags);
  }

  const onChangeClient = useCallback((client) => {
    const label = client.label;
    if (label) {
      addTags(TAGS_PREFIXES.client, label)
    } else {
      deleteTags(TAGS_PREFIXES.client);
    }
    setClient(client.value);
  }, [])

  const addTags = (startsWith, query, isOnlyAdd) => {
    const manipulateTags = isOnlyAdd ? tags : deleteTags(startsWith);
    manipulateTags.push(startsWith + ' ' + query)
    setTags(manipulateTags);
  }

  const onChangeDateFrom = useCallback((date) => {
    if (date) {
      setDateFrom(date.format('YYYY-MM-DD'));
      addTags(TAGS_PREFIXES.dateFrom, date.format('YYYY-MM-DD'))
    } else {
      setDateFrom(null);
      deleteTags(TAGS_PREFIXES.dateFrom)
    }
  }, [])

  const onChangeReports = useCallback((withReports) => {
    if (withReports) {
      addTags(TAGS_PREFIXES.reports, "")
    } else {
      deleteTags(TAGS_PREFIXES.reports)
    }

    setWithReports(withReports)
  }, [])

  const onChangeFavorite = useCallback((favorite) => {
    if (favorite) {
      addTags(TAGS_PREFIXES.favorite, "")
    } else {
      deleteTags(TAGS_PREFIXES.favorite)
    }

    setFavorite(favorite)
  }, [])

  const onChangeActivity = useCallback((activity) => {
    if (activity === 'paused') {
      deleteTags(TAGS_PREFIXES.active)
      addTags(TAGS_PREFIXES.inactive, "")
    } else if (activity === 'active') {
      deleteTags(TAGS_PREFIXES.inactive)
      addTags(TAGS_PREFIXES.active, "")
    } else {
      deleteTags(TAGS_PREFIXES.inactive);
      deleteTags(TAGS_PREFIXES.active);
    }

    setActivity(activity)
  }, [])

  const onChangeDateTo = useCallback((date) => {
    if (date) {
      setDateTo(date.format('YYYY-MM-DD'));
      addTags(TAGS_PREFIXES.dateTo, date.format('YYYY-MM-DD'))
    } else {
      setDateTo(null);
      deleteTags(TAGS_PREFIXES.dateTo)
    }
  }, [])

  const onChangeQuery = useCallback((query) => {
    if (query) {
      addTags(TAGS_PREFIXES.query, query)
    } else {
      deleteTags(TAGS_PREFIXES.query);
    }
    setQuery(query);
  }, [])

  const onChangeAssignedTo = useCallback((options) => {
    const values = options.map(option => option.value)
    const labels = options.map(option => option.label);

    const clientTags = tags.filter(tag => tag.startsWith(TAGS_PREFIXES.assigned));
    const clientEmails = clientTags.map(tag => tag.split(' ').pop());
    const clientsToAdd = labels.filter(tag => !clientEmails.includes(tag))
    clientsToAdd.map((clientEmail) => {
      addTags(TAGS_PREFIXES.assigned, clientEmail, true)
    });

    const clientsToRemove = clientEmails.filter(tag => !labels.includes(tag))
    clientsToRemove.map((clientEmail) => {
      deleteTags(TAGS_PREFIXES.assigned + " " + clientEmail);
    });

    setAssignedTo(values);
  }, [])

  const onChangeSurveyTags = useCallback((options) => {
    const clientTags = tags.filter(tag => tag.startsWith(TAGS_PREFIXES.surveyTags));
    const tagLabel = clientTags.map(tag => tag.split(' ').pop());
    const tagsToAdd = options.filter(tag => !tagLabel.includes(tag))
    tagsToAdd.map((tagLabel) => {
      addTags(TAGS_PREFIXES.surveyTags, tagLabel, true)
    });

    const tagsToRemove = tagLabel.filter(tag => !options.includes(tag))
    tagsToRemove.map((tagLabel) => {
      deleteTags(TAGS_PREFIXES.surveyTags + " " + tagLabel);
    });

    setSurveyTags(options);
  }, [])

  return <React.Fragment>
    <div className="filter-tags m-lg-2">
      {tags.map(tag => {
        return <Button color="secondary mr-2 mb-2">{tag}<span className="fa fa-x" onClick={() => deleteTag(tag)}/></Button>
      })}
    </div>
    <MobileView
      dateFrom={dateFrom}
      dateTo={dateTo}
      favorite={favorite}
      query={query}
      activity={activity}
      withReports={withReports}
      client={client}
      assignedTo={assignedTo}
      surveyTags={surveyTags}
      onChangeQuery={query => onChangeQuery(query)}
      onChangeClient={client => onChangeClient(client)}
      onChangeDateFrom={dateFrom => onChangeDateFrom(dateFrom)}
      onChangeDateTo={dateTo => onChangeDateTo(dateTo)}
      onChangeAssignedTo={assignedTo => onChangeAssignedTo(assignedTo)}
      onChangeFavorite={favorite => onChangeFavorite(favorite)}
      onChangeActivity={activity => onChangeActivity(activity)}
      onChangeWithReports={withReports => onChangeReports(withReports)}
      onChangeSurveyTags={surveyTags => onChangeSurveyTags(surveyTags)}
      onRefresh={() => props.onChange(getFilters())}
      onChangeShowEmpty={(showEmpty) => props.onChangeShowEmpty(showEmpty)}
      onChangeShowSurveysCategories={(showSurveysCategories) => props.onChangeShowSurveysCategories(showSurveysCategories)}
    />
    <CardHeader className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
      <h2>{props.showHeaderTitle ? 'Lista ankiet' : ''}</h2>

      <div className="d-none d-md-flex flex-row flex-nowrap align-items-center justify-content-end mb-2">
        <InputGroup className="mb-0" style={{width: '170px'}}>
          <Input
            placeholder="Wyszukaj..."
            onChange={e => onChangeQuery(e.target.value)}
            value={query}
          />
          <InputGroupAddon addonType={'append'}><InputGroupText><i className="fas fa-search" /></InputGroupText></InputGroupAddon>
        </InputGroup>

        <WithReports
          withReports={withReports}
          onChange={onChangeReports}
        />

        <Button size="sm" className="ml-2" color={filtersOpen ? 'primary' : 'secondary'} onClick={() => setFiltersOpen(!filtersOpen)}><i className="fas fa-filter" /> Pokaż filtry</Button>

        {props.plan.surveysAutoRefresh && <Autorefresh
          onRefresh={() => props.onChange(getFilters())}
        />}

      </div>
    </CardHeader>
    <hr className="my-0" />

    <CardBody className={classnames({
      'd-none': !filtersOpen,
    })}><div className="d-flex flex-column mb-2">
      <div className="d-flex flex-row flex-nowrap w-100">
        {Object.keys(props.clients).length > 0 && <div className="flex-grow-1">
          <Select
            className="w-100 react-select"
            value={client && {label: props.clients[client].name, value: client}}
            options={_.map(props.clients, client => {
              return {label: client.name, value: client.id}
            })}
            onChange={option => {
              onChangeClient(option);
            }}
            isClearable
            placeholder="Wybierz klienta"
          />
        </div>}

        <div className="ml-2 flex-grow-1">
          {Object.keys(props.groups.data.groups) > 0 && <Select
            isMulti={true}
            value={_.map(assignedTo, assigned => {
              const user = _.find(props.groupsUsers, user => parseInt(user.userId) === assigned);
              return {
                label: user.email,
                value: assigned
              }
            })}
            options={_.map(props.groupsUsers, user => {
              return {label: user.email, value: parseInt(user.userId)}
            })}
            onChange={options => onChangeAssignedTo(options ? options.map(option => option) : [])}
            placeholder="Wybierz osoby"
          />}
        </div>
      </div>
      <div className="row mt-2 mb-1 tags">
        <SurveyTags isUser={true} onChange={onChangeSurveyTags} />
      </div>
      <div className="row mt-2 mb-1">
        <div className="col pr-0">
          <DatePicker
              dropdownMode="select"
              className="form-control w-100 mb-0"
              isClearable={true}
              selected={dateFrom ? moment(dateFrom) : null}
              onChange={onChangeDateFrom}
              dateFormat="Y-MM-DD"
              placeholderText="Data stworzenia od"
          />
        </div>
        <div className="col pl-0 ml-2">
          <DatePicker
              dropdownMode="select"
              className="form-control w-100 mb-0"
              isClearable={true}
              selected={dateTo ? moment(dateTo) : null}
              onChange={onChangeDateTo}
              dateFormat="Y-MM-DD"
              placeholderText="Data stworzenia do"
          />
        </div>
      </div>
      <div className="d-flex flex-row mt-2">
        <ButtonGroup>
          <Button color={favorite ? "secondary" : "primary"} className={classnames({'ml-0 favorite-btn': true, 'selected': !favorite})} onClick={() => onChangeFavorite(false)}><FormattedMessage
            id="surveys.button.filter.favorite.false"/></Button>
          <Button color={favorite ? "primary" : "secondary"} className={classnames({'ml-0 favorite-btn': true, 'selected': favorite})} onClick={() => onChangeFavorite(true)}><FormattedMessage
            id="surveys.button.filter.favorite.true"/></Button>
        </ButtonGroup>

        <ButtonGroup id="activity-btn-group" className="ml-3">
          <Button color={!activity ? "primary" : "secondary"} className={classnames({'ml-0 activity-btn-false': true, 'selected': favorite === false})} onClick={() => onChangeActivity(false)}><FormattedMessage
            id="surveys.button.filter.activity.all"/></Button>
          <Button color={activity === 'active' ? "primary" : "secondary"} className={classnames({'ml-0 activity-btn-active': true, 'selected': activity === 'active'})} onClick={() => onChangeActivity('active')}><FormattedMessage
            id="surveys.button.filter.activity.active"/></Button>
          <Button color={activity === 'paused' ? "primary" : "secondary"} className={classnames({'ml-0 activity-btn-paused': true, 'selected': activity === 'paused'})} onClick={() => onChangeActivity('paused')}><FormattedMessage
            id="surveys.button.filter.activity.paused"/></Button>
        </ButtonGroup>
      </div>
    </div></CardBody>

  </React.Fragment>
};

SurveysCategoriesFilters.defaultProps = {
  showHeaderTitle: true,
};

SurveysCategoriesFilters.propTypes = {
  showHeaderTitle: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onChangeShowEmpty: PropTypes.func.isRequired,
  onChangeShowSurveysCategories: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let groupsUsers = [];

  _.each(state.groups.data.groups, group => {
    group.user.userId = group.user.id;
    groupsUsers.push(group.user);
    _.each(group.users, user => {
      groupsUsers.push(user);
    })
  });

  return {
    plan: state.user.userPlan.plan,
    groups: state.groups,
    clients: state.clients.data.clients,
    groupsUsers: groupsUsers,
  }
}

export default connect(mapStateToProps)(SurveysCategoriesFilters);