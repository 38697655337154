import React, {Component} from 'react';
import WidgetContainer from '../../WidgetContainer';
import {fetchGroups} from "scenes/Group/actions";
import { connect } from 'react-redux';
import SurveysCategories from "../../../../../../../componentsNew/SurveysCategories/SurveysCategories";

class SurveyTable extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <WidgetContainer widget={this.props.widget} handleDataChange={() => {}}>
        <SurveysCategories
          showHeaderTitle={false}
          styleHeight={''}
        />
      </WidgetContainer>
    )
  }
}

export default connect(null, {fetchGroups})(SurveyTable);