import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {connect} from "react-redux";
import SurveyForm from "../SurveyForm";
import api from "../../../../../api";

class DeleteAction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      loading: false,
    };

    this.delete = this.delete.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  delete(e, data) {
    e.preventDefault();
    this.setState({loading: true});

    api.survey.delete.survey({
      id: this.props.survey.id,
    }).then(() => {
      this.props.handleChange();
      this.setState({
        loading: false,
        modal: false
      })
    }).catch(e => {
      this.setState({
        loading: false,
        errors: e.response.data.form.errors
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <span id={'editSurvey-' + this.props.survey.id} className="fas fa-trash ml-2 pointer" onClick={() => this.toggle('modal')}/>
        <Modal isOpen={this.state.modal} size="lg" toggle={() => this.toggle('modal')}>
          <ModalHeader toggle={() => this.toggle('modal')} tag="h2">Usuń ankietę</ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            <Alert color={'danger'}>Czy na pewno usunąć ankietę?</Alert>
          </ModalBody>
          <ModalFooter>
            <div className="direction-group d-flex justify-content-end">
              <div className="direction-right">
                <LaddaButton
                  className="btn btn-danger btn-ladda mb-0 ml-3"
                  data-style={ZOOM_OUT}
                  onClick={e => this.delete(e)}
                  loading={this.state.loading}
                >Usuń ankietę</LaddaButton>
                <FormattedMessage id="_.button.cancel">{
                  (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggle('modal')}>{message}</Button>
                }</FormattedMessage>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

DeleteAction.propTypes = {
  survey: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default DeleteAction;
