import {schema} from 'normalizr';

import schemaSetBlocks from "../setBlocks/schema";
import schemaCollectors from "../collector/schema";
import schemaFilters from "../filter/schema";
import schemaQuotaNews from "../quotaNew/schema";
import schemaPipings from "../piping/schema";
import schemaFilterSerial from "../filterSerial/schema";
import schemaFilterIterative from "../filterIterative/schema";

export default new schema.Entity('surveys', {
  setBlocks: [schemaSetBlocks],
  collectors: [schemaCollectors],
  filters: [schemaFilters],
  quotaNews: [schemaQuotaNews],
  pipings: [schemaPipings],
  serialFilters: [schemaFilterSerial],
  iterativeFilters: [schemaFilterIterative],
});
