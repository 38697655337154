import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input, Alert
} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';


import {replaceColumns} from "scenes/Survey/Structure/data/questions/actions";
import Switch from "../../../../../../../../../../../../../../../../../../../../../../../../../components/Switch";

class ActionReplace extends Component {

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      modal: false,
      saving: false,
      changeable: false,
    };

    this.onChangeText = this.onChangeText.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
    this.setState({ modal: !this.state.modal });
  }

  onChangeText(text){
    this.setState({
      text
    })
  }

  saveColumns(){
    const {text, saving} = this.state;
    const {question} = this.props;

    if(_.isEmpty(text) || saving){
      return;
    }

    let columns = [];

    text.split("\n").map(t => {
      if (t.trim()) {
        columns.push({
          content: t
        });
      }
    });

    this.setState({
      saving: true
    });
    this.props.replaceColumns(question.id, columns, this.state.changeable)
    .then(() => {
      this.setState({
        text: '',
        saving: false,
        modal: false,
      });
    })
    .catch(res => {
      this.setState({
        saving: false,
      })
    });
  }

  render() {
    const {text, saving, changeable} = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="columns.actions.replace.cardHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="columns.actions.replace.cardBody"/></p>
        <Form>
          <FormGroup>
            <Input
              type="textarea"
              rows={10}
              onChange={e => this.onChangeText(e.target.value)}
              value={text}
              className="custom"
            />
          </FormGroup>
          <FormGroup className="mt-2">
            <Switch checked={changeable} onChange={changeable => {
              this.setState({
                changeable,
              })
            }} />
            <span className="ml-2">Dodaj/usuń kolumny</span>
          </FormGroup>
          {changeable && <Alert color="warning">
            <p>Dodaj/usuń kolumny w przypadku, gdy nowa lista odpowiedzi różni się od oryginału pod względem ilości kolumny.</p>
            <p className="mb-0">UWAGA: Zmiana może wpłynąć na już zebrane odpowiedzi w tym pytaniu.</p>
          </Alert>}
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="columns.actions.replace.button.confirmation">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={() => this.saveColumns()}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button onClick={this.toggleModal}><FormattedMessage id="columns.actions.replace.button.tooltip"/></Button>
        {this.state.modal && modal}
      </React.Fragment>
    )
  }
}

ActionReplace.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired
};


export default connect(null, {replaceColumns})(ActionReplace);