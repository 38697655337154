import React, {Component} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import BulkEditForm from './BulkEditForm';

class BulkEdit extends Component {

  render() {
    return (
      <main>
      <Card>
        <CardHeader><FormattedMessage tagName="h2" id="collectors.bulkEdit.cardHeader" /></CardHeader>
        <hr className="my-0" />
        <CardBody>
          <BulkEditForm collectorsIds={this.props.match.params.collectors.split(',')} history={this.props.history} surveyId={this.props.match.params.survey}/>
        </CardBody>
      </Card>
      </main>
    )
  }
}

export default BulkEdit;