import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {updateSurvey} from "scenes/Survey/Structure/data/survey/actions";
import Select from 'react-select';

class DefaultLang extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultLang
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(inputValue) {
    this.setState({
      value: inputValue.value
    });

    this.props.onChange(inputValue.value);
  }

  render() {
    const {availableLanguages, disabled} = this.props;
    const {value} = this.state;

    return (
      <Select
        isClearable={false}
        isDisabled={disabled}
        value={{
          value: value,
          key: value,
          label: <span><img src={'/dashboard/img/icon/' + value +'.png'} height="20" /> <FormattedMessage id={'_.lang.'+value+'.text'}/></span>,
        }}
        options={availableLanguages.map(l => {
          return {
            value: l,
            label: <span><img src={'/dashboard/img/icon/' + l +'.png'} height="20" /> <FormattedMessage id={'_.lang.'+l+'.text'}/></span>,
          }
        })}
        onChange={this.onChange}
      />
    )
  }
}

DefaultLang.propTypes = {
  defaultLang: PropTypes.string.isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    defaultLang: state.survey.structure.data.survey.defaultLang,
  }
}

export default connect(mapStateToProps, {updateSurvey})(DefaultLang);