import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

class Button extends Component {
  render() {
    let classes = ['btn btn-ladda'];
    classes.push('btn-' + this.props.color);
    if(this.props.className){
      classes.push(this.props.className);
    }

    classes.push('btn-' + this.props.size);

    return <LaddaButton
      disabled={this.props.disabled}
      className={classes.join(' ')}
      loading={this.props.loading}
      data-style={ZOOM_OUT}
      onClick={this.props.onClick}
    >{this.props.children}</LaddaButton>;
  }
}

Button.defaultProps = {
  size: 'md',
  loading: false,
  disabled: false,
  color: 'primary',
  onClick: null,
  className: [],
};

Button.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.array,
  size: PropTypes.arrayOf(['sm', 'md', 'lg']).isRequired,
};

export default Button;
