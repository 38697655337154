import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {ReactSortable} from "react-sortablejs";
import _ from 'lodash';
import classnames from "classnames";

import {Alert, Col, Row} from "reactstrap";

import QuestionRow from "./components/Row";
import QuestionRowNew from "./components/RowNew";

import {updateRow, rowUpdated} from "scenes/Survey/Structure/data/rows/actions";
import {setRows} from "scenes/Survey/Structure/data/questions/actions";

import '../Rows/style.scss'
import RowsActions from "./components/Actions";

import ActionAdd from "./components/Actions/components/Add";

class DifferentialRows extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hideRows: props.hideInStructure !== 'none' || props.rows.length >= 5,
      checkedRows: []
    };

    this.onUpdate = this.onUpdate.bind(this);
  }

  static getDerivedStateFromProps(props) {
    if(props.searchInStructure.length > 0) {
      return {hideRows: false};
    }

    return {};
  }

  onUpdate(evt){
    let {rows} = this.props.question;

    const id = parseInt(evt.item.attributes['data-id'].nodeValue);
    rows.splice(evt.oldIndex, 1);
    rows.splice(evt.newIndex, 0, id);
    this.props.setRows(this.props.question.id, rows);

    let position = 0;
    this.props.rows.map(row => {
      row.position = position;
      this.props.rowUpdated(row);
      position++;
    });

    this.props.updateRow({
      id,
      row: {
        position: evt.newIndex
      }
    });
  }

  toggleChecked(rowId, checked){
    let {checkedRows} = this.state;

    if(checked){
      checkedRows.push(rowId);
    }else{
      checkedRows = checkedRows.filter(id => id !== rowId)
    }

    this.setState({
      checkedRows
    });
  }

  toggleRows(){
    this.setState({
      hideRows: !this.state.hideRows
    })
  }

  render() {
    const {rows, question, searchInStructure} = this.props;
    const {checkedRows} = this.state;

    return (
      <Row className="mt-3" role="rows">
        <Col>
          <h4 className="cafetery-header">
            <span onClick={()=> this.toggleRows()}><FormattedMessage id="question.rows.listGroup.header" /> ({rows.length})</span>
            <div className='d-inline mx-2' key={Math.random()} onClick={()=> this.toggleRows()}>
              <i className={this.state.hideRows ? 'fas fa-angle-right' : 'fas fa-angle-down'}/>
            </div>
          </h4>

          {(!this.state.hideRows && rows.length > 0) && <div className="ml-lg-4">
            <ReactSortable
              list={rows}
              setList={() => {}}
              group={{
                name: 'rows-' + question.id,
              }}
              tag="ul"
              onUpdate={this.onUpdate}
              animation={100}
              filter={".sortable-ignore"}
              draggable={".sortable-draggable"}
              handle={".handler-drag-position"}
              className={classnames({
                'rows-list d-print-block list-group': true,
                'd-none': this.state.hideRows,
              })}
            >
              {rows.map((row, position) => {
                return (
                  <QuestionRow
                    position={row.position}
                    row={row}
                    question={question}
                    handlerChecked={(checked) => this.toggleChecked(row.id, checked)}
                    searchInStructure={searchInStructure}
                  />
                )
              })}
            </ReactSortable>
            <QuestionRowNew autoFocus={false} position={rows.length} question={question} />
          </div>}

          {rows.length === 0 && <Alert color="warning">
            <p>To pytanie nie zostanie wyświetlone, ponieważ nie ma żadnego wiersza</p>
            <ActionAdd question={question} />
          </Alert>}

          {(rows.length > 0 && !this.state.hideRows) && <RowsActions checkedRows={checkedRows} rows={rows} question={question}/>}

        </Col>
      </Row>
    )
  }
}

DifferentialRows.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rows: PropTypes.array.isRequired,
  }).isRequired
};

function mapStateToProps(state, props) {
  return {
    rows: state.survey.structure.data.rows ? _.map(props.question.rows, id => state.survey.structure.data.rows[id]) : [],
    searchInStructure: state.survey.structure.searchInStructure,
    hideInStructure: state.survey.structure.hideInStructure,
  }
}

export default connect(mapStateToProps, {setRows, updateRow, rowUpdated})(DifferentialRows);