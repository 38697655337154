import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";
import striptags from "striptags";
import reactHtmlParser from "react-html-parser";

class QuestionSummaryBasket extends Component {

  constructor(props) {
    super(props);

    this.getData = this.getData.bind(this);
  }

  getData() {
    const {questionSummary} = this.props;

    switch (this.props.questionSummary.chartType) {
      case 'heat':
        let data = [];

        questionSummary.data.data.map(basket => {
          questionSummary.question.answers.map(answer => {
            data.push({
              y: reactHtmlParser(striptags(questionSummary.question.answersImage ? answer.description : answer.content))[0],
              x: reactHtmlParser(striptags(basket.content))[0],
              heat: basket['answer_' + answer.position]
            });
          });
        });

        return {
          data,
          rowsLength: questionSummary.data.data.length,
          columnsLength: questionSummary.question.answers.length,
        };
      case 'stackedBar':
        let series = [];
        questionSummary.question.answers.map(answer => {
          series.push(reactHtmlParser(striptags(questionSummary.question.answersImage ? answer.description : answer.content)));
        });

        return {
          data: questionSummary.data.data.map(basket => {
            let ret = [reactHtmlParser(striptags(basket.content))[0]];

            questionSummary.question.answers.map(answer => {
              ret.push(basket['answer_' + answer.position])
            });
            return ret;
          }),
          series: series,
        };
    }
  }

  render() {
    const {questionSummary} = this.props;
    const dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <Chart id={'q' + questionSummary.question.id} type={questionSummary.chartType} data={this.getData()} n={questionSummary.data.n}/>
    )
  }
}

export default QuestionSummaryBasket;