import {SET, ADDED, UPDATED} from './actionTypes';
import _ from "lodash";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.users;

    case ADDED:
      state.push(action.user);
      return state;

    case UPDATED:
      let company = _.find(state, user => user.id === action.user.id);
      state[state.indexOf(company)] = {...company, ...action.user};
      return state;

    default:
      return state;
  }
};