import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';
import BanForm from "./BanForm";

class UserBulkBanModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: {}
    };

    this.banForm = React.createRef();
    this.ban = this.ban.bind(this);
  }

  ban() {
    this.setState({loading: true});

    api.ban.post.bulk({data: {...this.banForm.current.state.data, users: this.props.checked}})
      .then(() => {
        this.props.onUpdate();
        this.setState({loading: false});
        this.props.toggle();

      })
      .catch(e => {
        this.setState({loading: false, errors: e.response.data.form.errors});
      });
  }

  render() {

    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
      <ModalHeader toggle={this.props.toggle} tag="h2"><FormattedMessage id="admin.respondents.userBulkBanModal.modalHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <BanForm categories={this.props.categories} errors={this.state.errors} ref={this.banForm}/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={this.props.toggle}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="admin.respondents.userBulkBanModal.ban">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={this.state.loading}
            data-style={ZOOM_OUT}
            onClick={this.ban}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;
  }
}

UserBulkBanModal.propTypes = {
  checked: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default UserBulkBanModal;