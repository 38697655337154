import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {addBasket} from "scenes/Survey/Structure/data/baskets/actions";
import LoadingSpinner from "components/LoadingSpinner";
import {Input} from "reactstrap";
import HtmlEditor
  from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";
import getCKConfigByPlan from 'utils/ckeditor/cafeteryConfig';
import {isMobile, isTablet} from "react-device-detect";

class QuestionBasketNew extends Component {

  constructor(props) {
    super(props);

    this.state = {
      autoFocus: props.autoFocus,
      saving: false,
      adding: props.autoFocus,
      basket: {
        content: '',
        position: props.position,
      }
    };

    this.onChangeContent = this.onChangeContent.bind(this);
    this.saveBasket = this.saveBasket.bind(this);
    this.closeIfEmpty = this.closeIfEmpty.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.saveBasket()
  }

  onChangeContent(content){
    if(this.state.saving){
      return;
    }

    this.setState({
      basket: {
        ...this.state.basket,
        content
      }
    })
  }

  saveBasket(){
    this.setState({adding: false});
    if(this.state.saving){
      return;
    }

    const basket = {
      ...this.state.basket,
      position: this.props.position
    };

    if ((basket.content.trim()).length === 0)
      return false;

    this.setState({
      saving: true
    });
    
    this.props.addBasket({
      question: this.props.question.id,
      basket
    }).then(() => {
      this.setState({
        saving: false,
        basket: {
          ...this.state.basket,
          content: ''
        }
      });

      this.props.handlerAdded && this.props.handlerAdded();
    });
  }

  closeIfEmpty() {
    if ((this.state.basket.content.trim()).length === 0) {
      this.props.handlerAdded && this.props.handlerAdded();
    }
  }

  render() {
    const {question, plan} = this.props;
    const {basket, saving, adding, autoFocus} = this.state;
    const ctrlEnterKeyCode = 1114125;
    let editor;


    if (isMobile || isTablet) {
      editor = <Input value={basket.content} onChange={e => this.onChangeContent(e.target.value)} onBlur={this.saveBasket} autoFocus={autoFocus} onKeyPress={this.handleKeyPress}/>
    } else {
      editor = <HtmlEditor
        data={basket.content}
        config={getCKConfigByPlan(plan)}
        type="inline"
        onChange={event => this.onChangeContent(event)}
        onBlur={event => {
          this.saveBasket();
          this.setState({editing: false})
        }}
        onKey={event => {
          if (event.data.keyCode === ctrlEnterKeyCode) {
            event.cancel();
            this.saveBasket();
            setTimeout(() => {
              event.editor.destroy();
              this.setState({editing: false})
            }, 1);
          }
        }}
      />
    }

    return (
      <div className="line-wrap">
        <ul className="mb-0 ml-lg-5 d-flex">
          <li className="flex-grow-1">

            {adding
              ? editor
              : saving
                ? <LoadingSpinner width={21} classNames="my-0"/>
                : <small onClick={() => this.setState({adding: true, autoFocus: true})}><i><FormattedMessage id="baskets.basketNew.input.placeholder" /></i></small>
            }

          </li>
        </ul>
      </div>
    )
  }
}

QuestionBasketNew.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  position: PropTypes.number.isRequired,
  handlerAdded: PropTypes.func,
  autoFocus: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {addBasket})(QuestionBasketNew);