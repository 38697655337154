import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup} from "reactstrap";
import { connect } from 'react-redux';
import {FormattedMessage} from "react-intl";
import classnames from 'classnames';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import moment from "moment";
import ButtonDelete from './components/ButtonDelete';
import _ from "lodash";

class UserTemplates extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.actions = this.actions.bind(this);
  }

  toggleModal() {
    this.setState({modal: !this.state.modal})
  }

  updateValue(templateId){
    this.setState({modal: false});
    this.props.handlerUpdate(templateId);
  }

  actions(template){
    return (
      <ButtonGroup>
        <i className="fas fa-check pointer" onClick={() => this.updateValue(template.id)} />
        {template.userId === this.props.userId && <ButtonDelete template={template}/>}
      </ButtonGroup>
    )
  }

  render() {
    const inactive = !_.find(this.props.templates, template => template.id === this.props.currentTemplateId);

    let myTemplates = _.filter(this.props.templates, template => template.owner === this.props.username);
    let groupUsersTemplates = {};

    _.each(this.props.templates, template => {
      if (!myTemplates.includes(template)) {
        if (!groupUsersTemplates.hasOwnProperty(template.owner))
          groupUsersTemplates[template.owner] = [];

        groupUsersTemplates[template.owner].push(template);
      }
    });

    _.each(groupUsersTemplates, templates => {
      myTemplates.push({
        name: templates[0].owner,
        templates: templates
      })
    });

    const expand = {
      Header: 'Pokaż',
      expander: true,
      Expander: ({ isExpanded, ...rest }) => {
        if (!!rest.original.id) {
          return null;
        }

        return <div>
          {isExpanded
            ? <i className="fas fa-minus" />
            : <i className="fas fa-plus" />}
        </div>;
      }
    };

    const columns = [{
      Header: 'Id',
      accessor: 'id'
    }, {
      id: 'name',
      Header: 'Nazwa',
      accessor: template => template.id ? template.name : 'Szablony użytkownika ' + template.templates[0].owner
    }, {
      id: 'createdAt',
      Header: 'Data utworzenia',
      accessor: template => template.id ? moment(template.createdAt).format('YYYY-MM-DD HH:mm') : null
    }, {
      Header: 'Utworzono w ankiecie',
      accessor: 'survey'
    }, {
      Header: 'Utworzony dla klienta',
      accessor: 'client'
    }, {
      id: 'actions',
      Header: 'Akcje',
      accessor: template => template.id ? this.actions(template) : null
    }];

    const modal = <Modal id="saved-template-picker" isOpen={this.state.modal} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal} tag="h2">Wybierz zapisany szablon</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <ReactTable
          minRows={0}
          className={'table-wrap'}
          data={myTemplates}
          columns={[expand, ...columns]}
          showPagination={!_.isEmpty(this.props.templates)}
          PaginationComponent={Pagination}
          noDataText="Brak zapisanych szablonów"
          defaultPageSize={10}
          getTrGroupProps={(state, rowInfo) => {
            return {className: rowInfo.original.id === this.props.currentTemplateId ? 'bg-primary' : ''};
          }}
          SubComponent={row => <ReactTable
            minRows={0}
            className={'table-wrap'}
            data={row.original.templates}
            columns={columns}
            PaginationComponent={Pagination}
            defaultPageSize={10}
            getTrGroupProps={(state, rowInfo) => {
              return {className: rowInfo.original.id === this.props.currentTemplateId ? 'bg-primary' : ''};
            }}
          />}
        />
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <Row className={classnames({
        'align-items-center': true,
        'inactive': inactive,
      })}>
        <label className="col col-4">Zapisane szablony {!inactive && <i className="icon-clear-value fas fa-trash float-right" onClick={() => this.updateValue(null)} />}</label>
        <Col className="col-8">
          <Button onClick={this.toggleModal} className="w-100">Przeglądaj</Button>
        </Col>
        {modal}
      </Row>
    );
  }
}

UserTemplates.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    templates: state.user.surveyTemplates,
    username: state.user.username,
    userId: state.user.id,
    currentTemplateId: state.survey.structure.data.survey.templateId,
  }
}

export default connect(mapStateToProps)(UserTemplates);