import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import RespondentsList from './RespondentsList';
import {Row, Col, Card, CardHeader, CardBody, Input, InputGroup} from 'reactstrap';
import DatePicker from "react-datepicker";
import RespondentCreateButton from "./RespondentCreateButton";
import { connect } from 'react-redux';
import qs from "query-string";
import Button from "../../../../components/Button";
import BanCategorySelect from "../../../../componentsNew/BanCategorySelect";

class Respondents extends Component {
  constructor(props) {
    super(props);

    const params = qs.parse(location.search);

    this.state = {
      search: "",
      regAfter: null,
      regBefore: null,
      ref: 'ref' in params ?  params.ref : null,
      onlyBanned: false,
      banCategory: null,
    };

    this.list = React.createRef();
    this.onSearch = this.onSearch.bind(this);
    this.onRef = this.onRef.bind(this);
    this.onRegDateChange = this.onRegDateChange.bind(this);
  }

  onSearch(e) {
    this.setState({search: e.target.value})
  }

  onRef(e) {
    this.setState({ref: e.target.value})
  }

  onRegDateChange(date, property) {
    this.setState({
      [property]: date
    })
  }

  render() {
    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">
                    <FormattedMessage id="admin.respondents.header" />
                  </h2>
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <InputGroup className="p-0 mb-0">
                    <Input className="p-0" placeholder="Znajdź użytkowników..." onChange={this.onSearch}/>
                    <span className="input-group-btn py-1">
                    <button className="btn" type="button"><i className="fas fa-search" /></button>
                  </span>
                  </InputGroup>
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <DatePicker
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                    dropdownMode="select"
                    placeholderText="Data rejestracji do"
                    selected={this.state.regBefore}
                    onChange={date => this.onRegDateChange(date, 'regBefore')}
                  />
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <DatePicker
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                    dropdownMode="select"
                    placeholderText="Data rejestracji od"
                    selected={this.state.regAfter}
                    onChange={date => this.onRegDateChange(date, 'regAfter')}
                  />
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <InputGroup className="p-0 mb-0">
                    <Input className="p-0" placeholder="Id użytkownika polecającego" onChange={this.onRef} value={this.state.ref}/>
                    <span className="input-group-btn py-1">
                    <button className="btn" type="button"><i className="fas fa-search" /></button>
                  </span>
                  </InputGroup>
                </div>
                {this.state.onlyBanned && <div className="custom-search-input float-right mt-1 mr-1" style={{ width: '220px' }}>
                  <BanCategorySelect
                    onChange={banCategory => this.setState({ banCategory })}
                  />
                </div>}
                <div className="custom-search-input float-right mt-1 mr-1">
                  <Button size={'sm'} color={this.state.onlyBanned ? 'primary' : 'secondary'} onClick={() => this.setState({ onlyBanned: !this.state.onlyBanned })}><i className="fas fa-ban" /></Button>
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              {this.state.banCategory}
              <RespondentCreateButton loading={this.props.loadingPlans} handleChange={() => this.list.current.refresh()}/>
              <Row>
                <Col>
                  <RespondentsList ref={this.list} search={this.state.search} regBefore={this.state.regBefore} regAfter={this.state.regAfter} refUser={this.state.ref} onlyBanned={this.state.onlyBanned} banCategory={this.state.banCategory} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    loadingPlans: state.admin.plans.loading
  }
}

export default connect(mapStateToProps )(Respondents);
