import React, {Component} from 'react';
import {Card, CardHeader, CardBody, Table} from "reactstrap";
import {connect} from "react-redux";
import {isEmpty} from "lodash";
import history from "services/history";
import appRoutes from "../appRoutes";
import api from "../api";
import LoadingSpinner from "../components/LoadingSpinner";
import Pagination from "../components/Pagination";
import ReactTable from "react-table";
import Button from "components/Button";
import {Link} from "react-router-dom";
import Switch from "../components/Switch";
import {updateProfile} from "../reducers/user/actions";

const ButtonCreate = (props) => {
  const [creating, setCreating] = React.useState(false);
  const create = () => {
    setCreating(true);

    api.cint.createSurvey(props.cint.project_id)
    .finally(data => {
      setCreating(false);
      props.onCreated();
    })
  }

  return <Button loading={creating} onClick={create}>Utwórz</Button>
}

class CintView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      cintEnabled: props.user.cintEnabled,
      data: [],
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    const {user} = this.props;

    let plan = user.userPlan.plan;
    if(!isEmpty(user.userGroups)){
      plan = user.userGroups[0].group.user.userPlan.plan;

      if(!user.userGroups[0].cint){
        return history.push(appRoutes.surveys.list);
      }
    }

    if(!plan.cint){
      return history.push(appRoutes.surveys.list)
    }

    this.fetchData();
  }

  fetchData(){
    this.setState({
      loading: true,
    });

    api.cint.list()
    .then(data => {
      this.setState({
        loading: false,
        data: data.data.surveys
      });
    })
    .catch(data => {
      this.setState({
        loading: false,
      });
    })
  }

  render() {
    const columns = [
      {
        Header: <div className="d-flex flex-column justify-content-center text-center">
          <span>Id</span>
          <small>project_id</small>
        </div>,
        className: 'text-center',
        accessor: 'project_id',
      },
      // {
      //   Header: <div className="d-flex flex-column justify-content-center text-center">
      //     <span>Nazwa</span>
      //     <small>name</small>
      //   </div>,
      //   className: 'text-center',
      //   accessor: 'name',
      // },
      {
        Header: <div className="d-flex flex-column justify-content-center text-center">
          <span>Typ</span>
          <small>study_type</small>
        </div>,
        className: 'text-center',
        accessor: 'study_type',
      },
      {
        Header: <div className="d-flex flex-column justify-content-center text-center">
          <span>Stawka</span>
          <small>cpi</small>
        </div>,
        className: 'text-center',
        accessor: 'cpi',
      },
      {
        Header: <div className="d-flex flex-column justify-content-center text-center">
          <span>Pozostało wywiadów</span>
          <small>remaining_completes</small>
        </div>,
        className: 'text-center',
        accessor: 'remaining_completes',
      },
      {
        Header: <div className="d-flex flex-column justify-content-center text-center">
          <span>Response rate</span>
          <small>conversion_rate</small>
        </div>,
        className: 'text-center',
        accessor: 'conversion_rate',
      },
      {
        Header: <div className="d-flex flex-column justify-content-center text-center">
          <span>loi</span>
        </div>,
        className: 'text-center',
        accessor: 'loi',
      },
      {
        id: 'survey_groups_ids',
        Header: <div className="d-flex flex-column justify-content-center text-center">
          <span>Grupa ankiet</span>
          <small>survey_groups_ids</small>
        </div>,
        className: 'text-center',
        accessor: row => row.survey_groups_ids ? row.survey_groups_ids.join(', ') : '',
      },
      {
        id: 'platform_types',
        Header: <div className="d-flex flex-column justify-content-center text-center">
          <span>Urządzenia</span>
          <small>platform_types</small>
        </div>,
        className: 'text-center',
        accessor: row => row.platform_types ? row.platform_types.join(', ') : '',
      },
      // {
      //   id: 'match_to_qualify',
      //   Header: <div className="d-flex flex-column justify-content-center text-center">
      //     <span>Kwoty</span>
      //     <small>match_to_qualify</small>
      //   </div>,
      //   className: 'text-center',
      //   accessor: row => row.match_to_qualify ? 'Tak' : 'Nie',
      // },
      // {
      //   id: 'delay_crediting',
      //   Header: <div className="d-flex flex-column justify-content-center text-center">
      //     <span>delay_crediting</span>
      //     <small>delay_crediting</small>
      //   </div>,
      //   className: 'text-center',
      //   accessor: row => row.delay_crediting ? 'Tak' : 'Nie',
      // },
      // {
      //   id: 'order.loi',
      //   Header: <div className="d-flex flex-column justify-content-center text-center">
      //     <span>order.loi</span>
      //     <small>order.loi</small>
      //   </div>,
      //   className: 'text-center',
      //   accessor: row => row.order.loi ? 'Tak' : 'Nie',
      // },
      // {
      //   id: 'order.ir',
      //   Header: <div className="d-flex flex-column justify-content-center text-center">
      //     <span>order.ir</span>
      //     <small>order.ir</small>
      //   </div>,
      //   className: 'text-center',
      //   accessor: row => row.order.ir ? 'Tak' : 'Nie',
      // },
      {
        id: 'is_pmp',
        Header: <div className="d-flex flex-column justify-content-center text-center">
          <span>is_pmp</span>
          <small>is_pmp</small>
        </div>,
        className: 'text-center',
        accessor: row => row.is_pmp ? 'Tak' : 'Nie',
      },
      {
        Header: <div className="d-flex flex-column justify-content-center text-center">
          <span>Link</span>
          <small>entry_link</small>
        </div>,
        className: 'text-center',
        accessor: 'entry_link',
      },
      {
        id: 'action',
        Header: 'Info',
        className: 'text-center',
        accessor: row => {

          if(row.surveyId){
            return <div className="d-flex flex-column">
              <Link to={appRoutes.survey.collectors.list(row.surveyId)} target="_blank">Ankieta</Link>
              <a href={row.exampleLink} target="_blank">Link do testów</a>
            </div>
          }

          return <ButtonCreate cint={row} onCreated={() => this.fetchData()} />
        },
      },
    ];

    return <Card>
      <CardHeader className="p-0 d-flex justify-content-between">
        <h2>Cint</h2>

        <div className="d-flex flex-row align-items-center">
          <Switch onChange={cintEnabled => {
            this.setState({cintEnabled}, () => {
              const formData = new FormData();
              formData.append('cintEnabled', this.state.cintEnabled);

              this.props.updateProfile(formData);

              if(!this.state.cintEnabled){
                api.survey.pauseCintSurveys()
              }
            })
          }} checked={this.state.cintEnabled} />
          <Link className="p-2 m-2 ml-5" to={api.cint.listCsv}>Pobierz CSV</Link>
        </div>
      </CardHeader>
      <hr className="my-0" />
      <CardBody>
        <ReactTable
          minRows={0}
          className={'table-wrap'}
          data={this.state.data}
          loading={this.state.loading}
          columns={columns}
          showPagination
          PaginationComponent={Pagination}
          noDataText={<span>Brak danych</span>}
          defaultPageSize={1000}
        />
      </CardBody>
    </Card>

  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps, {updateProfile})(CintView)