import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Col, Row} from "reactstrap";
import Rotate from "./components/Rotate";
import RandQuestions from "./components/RandQuestions";
import TimeMinMax from "./components/TimeMinMax";
import Disabled from "./components/Disabled";
import RandAndRotateQuestionsTable from "./components/RandAndRotateQuestionsTable";
import {updatePage} from "scenes/Survey/Structure/data/pages/actions";
import BooleanButton from "./components/BooleanButton";

class Settings extends Component {

  constructor(props) {
    super(props);

    this.handledUpdateQuestion = this.handledUpdateQuestion.bind(this);
  }

  handledUpdateQuestion(page){
    this.props.updatePage({
      id: this.props.page.id,
      page
    });
  }

  render() {
    const { page } = this.props;
    const shouldRenderTable = page.rotateQuestions || page.randQuestions > 0;

    return (
      <Row>
        <Col className="p-4">
          <h5><FormattedMessage id="page.settings.header"/></h5>

          <Rotate page={page} handleUpdate={this.handledUpdateQuestion}/>
          <RandQuestions page={page} handleUpdate={this.handledUpdateQuestion}/>
          <TimeMinMax page={page} handleUpdate={this.handledUpdateQuestion}/>
          <BooleanButton id={'Previous'} enabled={page.hideNavigationPreviousButton} handleChange={() => this.handledUpdateQuestion({ hideNavigationPreviousButton: !page.hideNavigationPreviousButton })} />
          <BooleanButton id={'Clear'} enabled={page.hideNavigationClearButton} handleChange={() => this.handledUpdateQuestion({ hideNavigationClearButton: !page.hideNavigationClearButton })} />
          <BooleanButton id={'Next'} enabled={page.hideNavigationNextButton} handleChange={() => this.handledUpdateQuestion({ hideNavigationNextButton: !page.hideNavigationNextButton })} />
          <Disabled page={page} handleUpdate={this.handledUpdateQuestion}/>

          {shouldRenderTable && <RandAndRotateQuestionsTable page={page} />}
        </Col>
      </Row>
    )
  }
}

Settings.propTypes = {
  page: PropTypes.object.isRequired,
  updatePage: PropTypes.func.isRequired
};

export default connect(null, { updatePage })(Settings);
