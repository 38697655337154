import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Tooltip} from "reactstrap";
import classnames from "classnames";
import InputNumber from "components/InputNumber";

class RandBlocks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      set: {
        randBlocks: props.set.randBlocks,
      },
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(value){
    if(!value || value < 0){
      value = 0;
    }

    this.setState({
      set: {
        ...this.state.set,
        randBlocks: value
      }
    }, () => {
      this.props.handleUpdate(this.state.set)
    });
  }

  render() {
    let { set, tooltip } = this.state;

    return (
      <div
        id={"randBlocks-" + this.props.set.id}
        className={classnames({
          "min-w-0 float-left mr-1 mb-1": true,
          "w-auto": !set.randBlocks,
          "w-100 w-lg-auto input-group": set.randBlocks
        })}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": set.randBlocks > 0,
            })}
            color="secondary"
            onClick={() => {
              if(set.randBlocks === 0){
                this.onChange(1)
              }else{
                this.onChange(0)
              }
            }}
          >
            <i className="fa-solid fa-dice" />
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltip}
              target={"randBlocks-" + this.props.set.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="set.settings.randBlocks.button.tooltip"/>
            </Tooltip>
          </Button>
        </InputGroupAddon>
        {set.randBlocks > 0 && <InputNumber value={set.randBlocks} min={0} max={this.props.set.blockPages.length} onChange={(value) => this.onChange(value)}/>}
      </div>
    )
  }
}

RandBlocks.propTypes = {
  set: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default RandBlocks;
