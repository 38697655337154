import React from 'react';
import PropTypes from 'prop-types';
import Button from "../components/Button";
import api from "../api";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';
import {toast} from "react-toastify";

const QuestionContentCopy = props => {
  const [loading, setLoading] = React.useState(false);

  const copyAction = () => {
    setLoading(true);

    api.question.get.question({
      id: props.questionId
    })
      .then(question => {
        let toCopy = [
          question.content,
        ]
        if('answers' in question && question.answers.length > 0){
          toCopy = [
            ...toCopy,
            ...question.answers.map(a => a.content),
          ]
        }
        if('rows' in question && question.rows.length > 0){
          toCopy = [
            ...toCopy,
            ...question.rows.map(r => r.content),
          ]
        }
        if('columns' in question && question.columns.length > 0){
          toCopy = [
            ...toCopy,
            '{coulmns}',
            ...question.columns.map(c => c.content),
          ]
        }
        copy(toCopy.join("\n"))
        toast.success('Treść została skopiowana do schowka')
      })
      .finally(() => setLoading(false))
  }

  return (
    <Button
      color="secondary"
      loading={loading}
      onClick={copyAction}
    >Kopiuj treści</Button>
  );
};

QuestionContentCopy.propTypes = {
  questionId: PropTypes.number.isRequired,
};

export default QuestionContentCopy;