import React from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import SurveyResponseFakersTable from "../../componentsNew/SurveyResponseFakers/SurveyResponseFakersTable";
import {connect} from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner";

const SurveyResponseFakers = props => {
  return (
    <main>
      <Card>
        <CardHeader>
          <h2>Dane testowe</h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          {!props.survey.id ? <LoadingSpinner /> : <Row>
            <Col>
              <SurveyResponseFakersTable
                surveyId={props.survey.id}
              />

              <div className="d-flex flex-row flex-nowrap align-items-center">
                <img src="/dashboard/img/herm.jpg" style={{width: '200px'}} />
                <span className="ml-3">Szybko i profesjonalnie przeklikam Twoją ankietę. Za błędy nie odpowiadam. Pamiętaj, że: mogę klikać tylko jedną ankietę i naciskam tylko kolektor typu Link do ankiety. Kot Herman.</span>
              </div>
            </Col>
          </Row>}
        </CardBody>
      </Card>
    </main>
  );
};

SurveyResponseFakers.propTypes = {

};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps)(SurveyResponseFakers);