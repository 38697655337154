import React, {Component} from 'react';
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";
import { connect } from 'react-redux';
import {FormattedMessage} from "react-intl";
import GroupCreateButton from './GroupCreateButton';
import GroupUsersList from './GroupUsersList';
import GroupUserCreateModal from './GroupUserCreateModal';
import GroupInvitationsList from './Invitations/GroupInvitationsList';
import {fetchGroups} from '../actions';
import LoadingSpinner from "components/LoadingSpinner";
import HelpModeIconModal from "components/HelpModeIconModal";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import GroupsUsersMaxAvailable from "components/PlanRestrictionsModals/modals/GroupsUsersMaxAvailable";

class Group extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      userCreateModal: false
    }
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  componentDidMount() {
    document.title = 'Kolaboracja | ANKIETEO';
    document.querySelector('meta[name="description"]').setAttribute("content", 'Kolaboracja');
    document.querySelector('meta[name="keywords"]').setAttribute("content", 'Kolaboracja');
    this.props.fetchGroups();
  }

  render() {
    const {plan} = this.props;
    const groupId = Object.keys(this.props.groups.data.groups)[0];
    const group = this.props.groups.data.groups[groupId];
    const hasGroup = (Object.keys(this.props.groups.data.groups) > 0);
    const hasInvitations = (Object.keys(this.props.groups.data.invitations) > 0);
    const isGroupOwner = hasGroup ? this.props.groups.data.groups[groupId].user.id === this.props.userId : false;
    const groupUser = hasGroup && _.find(this.props.groups.data.groups[groupId].users, user => user.user.id === this.props.userId);
    const isBlocked = !!groupUser && groupUser.blocked;

    return (
      this.props.groups.loading ? <LoadingSpinner  /> :
      <React.Fragment>
        {hasInvitations && !hasGroup && !isGroupOwner && <GroupInvitationsList invitations={this.props.groups.data.invitations}/>}

        {!isGroupOwner && hasGroup && <Alert color={isBlocked ? "danger" : "primary"}><FormattedMessage id="group.belongsToGroup" /> {this.props.userGroup} {isBlocked && <FormattedMessage id="group.blocked" />}</Alert>}

        {!hasGroup && <Alert color='primary'><FormattedMessage id="group.noGroups" />
          <div className="d-block d-lg-inline mt-3 ml-lg-2"><GroupCreateButton /></div>
        </Alert>}

        {isGroupOwner &&
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <h2 className="float-left">{this.props.groups.data.groups[groupId].name}
                  <HelpModeIconModal className="ml-2" modalHeader="Grupy">
                    <p>Grupy pozwalają udostępniać i nadawać prawa do ankiet między użytkownikami</p>
                  </HelpModeIconModal>
                </h2>
              </div>
              <div className="d-flex align-items-center">
                {(group && plan.groupsUsersMax > 0) && <span>Liczba osób w grupie: {Object.values(group.users).length}/{plan.groupsUsersMax}</span>}

                <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle('dropdownOpen')} className="d-md-none">
                  <DropdownToggle className="m-0 p-0" color="">
                    <span className="fas fa-ellipsis-v hoverable" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag="a" onClick={() => this.toggle('userCreateModal')} >
                      <p><span className="fas fa-plus" /></p> <FormattedMessage tagName="h5" id="groupUserCreateButton.button"/>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>

                {(group) && <FormattedMessage id="groupUserCreateButton.button">{
                  (message) =>
                    <Button color="primary" onClick={() => {
                      if(plan.groupsUsersMax == 0 || Object.values(group.users).length < plan.groupsUsersMax){
                        this.toggle('userCreateModal');
                      }else{
                        this.props.setPlanRestrictionsModals(GroupsUsersMaxAvailable.getName())
                      }
                    }} className="ml-3 d-none d-md-block"><i className="fas fa-plus" /> {message}</Button>
                }</FormattedMessage>}

              </div>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <GroupUsersList group={this.props.groups.data.groups[groupId]} />
          </CardBody>
        </Card>}

        <GroupUserCreateModal group={this.props.groups.data.groups[groupId]} isOpen={this.state.userCreateModal} toggle={() => this.toggle('userCreateModal')}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(props) {
  return {
    groups: props.groups,
    userGroup: props.user.userGroup,
    plan: props.user.userPlan.plan,
    userId: props.user.id,
  }
}

export default connect(mapStateToProps, {fetchGroups, setPlanRestrictionsModals})(Group);