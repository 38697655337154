import React from 'react';
import PropTypes from 'prop-types';
import Button from "../Button";
import api from "../../api";
import {toast} from "react-toastify";
import appRoutes from "../../appRoutes";
import {profileUpdated} from "../../reducers/user/actions";
import {connect} from "react-redux";

const ChangeEmailCancelButton = props => {
  const [loading, setLoading] = React.useState(false);

  const onClick = (e) => {
    e.preventDefault();

    setLoading(true)

    api.user.get.changeEmailCancel()
      .then(data => {
        props.dispatch(profileUpdated(data.user));
        toast.success('Proces zmiany adresu e-mail został poprawnie anulowany')
      })
  }

  return (
    <Button
      className="ml-2"
      loading={loading}
      size={'sm'}
      color={'warning'}
      onClick={onClick}
    >Anuluj</Button>
  );
};

ChangeEmailCancelButton.propTypes = {

};

export default connect()(ChangeEmailCancelButton);
