import * as React from 'react';
import {connect} from "react-redux";
import {Alert, Input} from "reactstrap";
import {updateAttributeLevel} from "../../../../../../../../../../../../../../../../../../../../data/attributes/levels/actions";

function AttributeLevelName(props) {
  const [value, setValue] = React.useState(props.level.name);
  const [editing, setEditing] = React.useState(false);
  const toggleEditing = () => setEditing(!editing);
  const [saving, setSaving] = React.useState(false);

  const {level} = props;

  const handlerOnKeyDown = (e) => {
    switch (e.keyCode) {
      case 13: {
        update();
        break;
      }
      case 27: {
        setValue(props.level.name);
        setEditing(false);

        break;
      }
    }
  }

  const update = () => {
    if(value !== props.level.name){
      setSaving(true);
      props.updateAttributeLevel(props.level.id, {name: value})
      .then(() => {
        setSaving(false);
        setEditing(false);
      })
    }else{
      setEditing(false);
    }
  }

  if(editing){
    return <Input
      className="m-0"
      disabled={saving}
      value={value}
      placeholder="Nazwa wartości"
      onBlur={update}
      onChange={e => setValue(e.target.value)}
      onKeyDown={handlerOnKeyDown}
      autoFocus
      size="sm"
    />
  }

  return <span className="w-100 ml-2" onClick={toggleEditing}>
    <span>
      {level.name
      || <Alert color="warning" className="my-0 py-0">
          <small className="font-italic"><strong>Uzupełnij nazwę wartości</strong></small>
        </Alert>
      }
    </span>
  </span>;
}

export default connect(null, {updateAttributeLevel})(AttributeLevelName);