import React from "react";
import PropTypes from "prop-types";
import ApplicationSelect from "components/Application/ApplicationSelect";
import api from "api";

function AnkieteoCmsSelect(props){
  return <ApplicationSelect
    fetchById={api.ankieteoCms.get}
    placeholder="Wybierz"
    searchData={api.ankieteoCms.search}
    filters={{
      level: '0',
    }}
    value={props.ankieteoCms ? props.ankieteoCms.id : null}
    onChange={entity => props.onChange(entity)}
  />
}

AnkieteoCmsSelect.propTypes = {
  ankieteoCms: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};

export default AnkieteoCmsSelect;