import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {FormGroup, Label, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import TabSingle from "./TabsQuestions/TabSingle";
import TabMulti from "./TabsQuestions/TabMulti";
import TabRanked from "./TabsQuestions/TabRanked";
import TabMaxDiff from "./TabsQuestions/TabMaxDiff";
import TabNumericMulti from "./TabsQuestions/TabNumericMulti";
import TabBasket from "./TabsQuestions/TabBasket";
import TabMatrixDropdown from "./TabsQuestions/TabMatrixDropdown";
import TabDifferential from "./TabsQuestions/TabDifferential";
import TabMatrix from "./TabsQuestions/TabMatrix";
import TabMatrixMulti from "./TabsQuestions/TabMatrixMulti";
import TabNumeric from "./TabsQuestions/TabNumeric";
import TabOpen from "./TabsQuestions/TabOpen";
import QuestionInfo from "components/QuestionInfo";
import {FormattedMessage} from "react-intl";
import GlobalSwitcher from "./GlobalSwitcher";
import Switch from "components/Switch";
import TabMultiOpen from "./TabsQuestions/TabMultiOpen";

class TabQuestionSettings extends PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeTab: 'single'
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    this.setState({activeTab: tab})
  }

  render() {
    const {errors, config, data} = this.props;
    const questionTypes = Object.keys(config.data.questions);

    return (
      <React.Fragment>
        <FormGroup>
          <Label for="disabled"><FormattedMessage id="admin.planEdit.label.survey.questionSettings.disabled" /></Label>
          <Switch checked={data.survey.questionSettings.disabled} onChange={value => this.props.onChangeQuestionSettings('disabled', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="fileUpload"><FormattedMessage id="admin.planEdit.label.survey.questionSettings.fileUpload" /></Label>
          <Switch checked={data.survey.questionSettings.fileUpload} onChange={value => this.props.onChangeQuestionSettings('fileUpload', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="supplementMax"><FormattedMessage id="admin.planEdit.label.survey.questionSettings.supplementMax" /></Label>
          <Switch checked={data.survey.questionSettings.supplementMax} onChange={value => this.props.onChangeQuestionSettings('supplementMax', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="cafeterySupplementMax"><FormattedMessage id="admin.planEdit.label.survey.questionSettings.cafeterySupplementMax" /></Label>
          <Switch checked={data.survey.questionSettings.cafeterySupplementMax} onChange={value => this.props.onChangeQuestionSettings('cafeterySupplementMax', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="randCafetery"><FormattedMessage id="admin.planEdit.label.survey.questionSettings.randCafetery" /></Label>
          <Switch checked={data.survey.questionSettings.randCafetery} onChange={value => this.props.onChangeQuestionSettings('randCafetery', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="minMaxCafetery"><FormattedMessage id="admin.planEdit.label.survey.questionSettings.minMaxCafetery" /></Label>
          <Switch checked={data.survey.questionSettings.minMaxCafetery} onChange={value => this.props.onChangeQuestionSettings('minMaxCafetery', value)} />
        </FormGroup>
        <FormGroup>
          <Label for="openRegex"><FormattedMessage id="admin.planEdit.label.survey.questionSettings.openRegex" /></Label>
          <Switch checked={data.survey.questionSettings.openRegex} onChange={value => this.props.onChangeQuestionSettings('openRegex', value)} />
        </FormGroup>

        <h2 className="mt-4"><FormattedMessage id="admin.planEdit.label.survey.questionSettings.globalSwitchers" /></h2>
        <GlobalSwitcher data={data} onChangeQuestionSettings={this.props.onChangeQuestionSettings} setting="rotateAnswers"><FormattedMessage id="_.question.single.rotateAnswers" /></GlobalSwitcher>

        <Nav tabs className="mt-4">
          <NavItem>
            {_.map(questionTypes, type => <NavLink key={type} className="nav-link float-left" active={this.state.activeTab === type} onClick={() => { this.toggle(type); }}>
              <QuestionInfo question={{type}}/>
              <span className="d-none d-lg-inline ml-2"><FormattedMessage id={'_.question.' + type + '.typeName'}/></span>
            </NavLink>)}
          </NavItem>
        </Nav>

        <TabContent className="tab-body" activeTab={this.state.activeTab}>
          <TabPane tabId="single">
            <TabSingle data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="multi">
            <TabMulti data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="ranked">
            <TabRanked data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="maxDiff">
            <TabMaxDiff data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="numericMulti">
            <TabNumericMulti data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="basket">
            <TabBasket data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="matrixDropdown">
            <TabMatrixDropdown data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="differential">
            <TabDifferential data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="matrix">
            <TabMatrix data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="matrixMulti">
            <TabMatrixMulti data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="numeric">
            <TabNumeric data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="open">
            <TabOpen data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
          <TabPane tabId="open">
            <TabMultiOpen data={data} errors={errors} onChangeQuestionSettings={this.props.onChangeQuestionSettings}/>
          </TabPane>
        </TabContent>
      </React.Fragment>
    );
  }
}


TabQuestionSettings.propTypes = {
  data: PropTypes.shape({
    survey: PropTypes.shape({
      questionSettings: PropTypes.shape({
        disabled: PropTypes.bool.isRequired,
        fileUpload: PropTypes.bool.isRequired,
        supplementMax: PropTypes.bool.isRequired,
        randCafetery: PropTypes.bool.isRequired,
        minMaxCafetery: PropTypes.bool.isRequired,
        openRegex: PropTypes.bool.isRequired,
        single: PropTypes.object.isRequired,
        multi: PropTypes.object.isRequired,
        ranked: PropTypes.object.isRequired,
        maxDiff: PropTypes.object.isRequired,
        numericMulti: PropTypes.object.isRequired,
        basket: PropTypes.object.isRequired,
        matrixDropdown: PropTypes.object.isRequired,
        differential: PropTypes.object.isRequired,
        matrix: PropTypes.object.isRequired,
        matrixMulti: PropTypes.object.isRequired,
        numeric: PropTypes.object.isRequired,
        open: PropTypes.object.isRequired,
      }).isRequired
    }).isRequired,
  }),
  errors: PropTypes.object.isRequired,
  onChangeQuestionSettings: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    config: state.config,
  }
}

export default connect(mapStateToProps)(TabQuestionSettings);
