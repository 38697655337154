import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Question from "../../../Modals/Question";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {injectIntl} from "react-intl";


class CondVisibility extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  toggleIsOpen(){
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const {intl, condQuestion, changeable} = this.props;
    const {isOpen} = this.state;

    return (
      <React.Fragment>
        <span>Jeżeli respondentowi </span>

        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpen} toggle={this.toggleIsOpen}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condVisibility.visibility-' + condQuestion.visibility})}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a" onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              visibility: true,
            })}><h5>{intl.formatMessage({id: 'filter.condVisibility.visibility-true'})}</h5></DropdownItem>
            <DropdownItem tag="a" onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              visibility: false,
            })}><h5>{intl.formatMessage({id: 'filter.condVisibility.visibility-false'})}</h5></DropdownItem>
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condVisibility.visibility-' + condQuestion.visibility})} </span>}

        <span>pytanie </span>
        <Question question={condQuestion.question} handleQuestionChanged={question => {
          let condUpdated = {
            ...condQuestion,
            question,
            answers: [],
            rows: [],
            columns: [],
            baskets: [],
          };

          this.props.handleCondChanged(condUpdated)
        }} changeable={changeable}/>
      </React.Fragment>
    );
  }
}

CondVisibility.defaultProps = {
  changeable: true
};

CondVisibility.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number,
    visibility: PropTypes.bool.isRequired,
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(CondVisibility);