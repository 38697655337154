import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Input, Alert, Form, Col, FormGroup, Label} from "reactstrap";
import SelectQuestions from "../Structure/components/SelectQuestions";
import SelectColumns from "../Structure/components/SelectColumns";
import Select from "react-select";
import SelectRows from "../Structure/components/SelectRows";
import SelectAnswers from "../Structure/components/SelectAnswers";
import SelectSets from "../Structure/components/SelectSets";
import {connect} from "react-redux";
import _ from "lodash";
import SelectBlocks from "../Structure/components/SelectBlocks";
import SelectPages from "../Structure/components/SelectPages";
import api from "../../../api";
import {filterSerialGroupCreate} from "../Structure/data/survey/actions";
import FilterActionVisibilitySelect from "../FilterActionVisibilitySelect";

const FilterSerialGroupTypeSelect = (props) => {
  const options = [
    {
      value: 'multi',
      label: 'Od wielu do wielu'
    },
    {
      value: 'single',
      label: 'Od jednego do wielu'
    },
  ];

  return <Select
    options={options}
    value={props.value ? options.find(o => o.value === props.value) : false}
    onChange={option => props.onChange(option.value)}
  />;
}

class FiltersSerialGroupForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        type: 'multi',
        prefix: '',
        from: [],
        answers: [],
        visibility: true,
        to: [],
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.save = this.save.bind(this);
  }

  onSubmit(e){
    e.preventDefault();

    this.save();
  }

  save(){
    const {surveyData} = this.props;
    const {data} = this.state;

    this.props.onSavingStart();

    this.props.filterSerialGroupCreate(surveyData.survey.id, data)
    .then(data => {
      this.props.onSavingEnd();

      this.props.onSaved();
    })
    .catch(() => {
      this.props.onSavingEnd();
    })
  }

  render() {
    const {data} = this.state;
    const {surveyData} = this.props;

    const isTypeMulti = data.type === 'multi';

    return (
      <Form onSubmit={this.onSubmit}>
        <FormGroup row className="mb-2">
          <Label md={4}>Nazwa grupy</Label>
          <Col md={8}>
            <Input onChange={e => this.setState({data: {...data, prefix: e.target.value}})} value={data.prefix} />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label md={4}>Rodzaj grupy</Label>
          <Col md={8}>
            <FilterSerialGroupTypeSelect
              value={data.type}
              onChange={type => this.setState({
                data: {
                  ...data,
                  type,
                }
              })}
            />
          </Col>
        </FormGroup>

        <div className="border p-3">
          <h1 className="text-center pb-2 text-center w-100">{isTypeMulti ? 'Od pytań' : 'Od pytania'}</h1>
          <FormGroup row className="mb-2">
            <Label md={4}>{isTypeMulti ? 'Wybierz pytania' : 'Wybierz pytanie'}</Label>
            <Col md={8}>
              <SelectQuestions
                isMulti={isTypeMulti}
                enabledTypes={['multi', 'ranked']}
                value={isTypeMulti ? data.from : (data.from.length > 0 ? data.from[0] : null)}
                onChange={questions => this.setState({
                  data: {
                    ...data,
                    from: isTypeMulti ? questions : [questions],
                  }
                })}
              />
            </Col>
          </FormGroup>
          {data.from.length > 0 && <FormGroup row className="mb-2">
            <Label md={4}>Wybierz odpowiedzi</Label>
            <Col md={8}>
              <SelectAnswers
                questionId={data.from[0]}
                isClearable
                onChange={answers => this.setState({
                  data: {
                    ...data,
                    answers: answers ? answers.map(answerId => this.props.answers[answerId].position) : [],
                  },
                })}
              />
            </Col>
          </FormGroup>}
        </div>


        <div className="border p-3 mt-3">
          <h1 className="text-center pb-2 text-center w-100">Do pytań</h1>

          <FormGroup row className="mb-2">
            <Label md={4}>Wybierz pytania</Label>
            <Col md={8}>
              <SelectQuestions
                enabledTypes={['single', 'multi', 'ranked', 'numericMulti']}
                onChange={questions => this.setState({
                  data: {
                    ...data,
                    to: questions,
                  }
                })}
              />
            </Col>
          </FormGroup>
        </div>
      </Form>
    );
  }
}

FiltersSerialGroupForm.propTypes = {
  onSavingStart: PropTypes.func,
  onSaved: PropTypes.func,
  onSavingEnd: PropTypes.func,
  answers: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    surveyData: state.survey.structure.data,
    answers: state.survey.structure.data.answers,
  }
}

export default connect(mapStateToProps, {filterSerialGroupCreate}, null, {forwardRef: true})(FiltersSerialGroupForm);