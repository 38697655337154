import update from 'immutability-helper';
import {DELETED, SET, ADDED, UPDATED} from './actions';
import * as questionsActions from "../questions/actions";

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.pipings;

    case DELETED:
      return update(state, {
        $unset: [action.piping.id]
      });

    case ADDED:
      return {
        ...state,
        [action.piping.id]: action.piping
      };

    case UPDATED:
      return update(state, {
        [action.piping.id]: {
          $set: action.piping
        }
      });

    case questionsActions.TYPE_UPDATED:
      return _.map(state, piping => {
        if (piping.questionFrom.question === action.expiredId) {
          piping.questionFrom.question = action.question.id;
        }

        return piping;
      });

    default:
      return state;
  }
};