import React, {Component} from 'react';
import PropTypes from "prop-types";
import AnyChart from "anychart-react";
import anychart from 'anychart'

class ChartStackedBarNegValues extends Component {


  render() {
    const {data, n} = this.props;
    let height = 130;

    height += (this.props.data.data.length * 65);

    let stage = anychart.graphics.create();

    var dataSet = anychart.data.set(data.data);

    var seriesData_1 = dataSet.mapAs({'x': 0, 'value': 1});
    var seriesData_2 = dataSet.mapAs({'x': 0, 'value': 2});

    var chart = anychart.bar();
    chart.yScale().stackMode('value');
    chart.yAxis().labels().format(function () {
      return Math.abs(this.value).toLocaleString();
    });

    chart.yAxis(0).title('Liczba wskazań');
    chart.yScale().minimum(n * -1);
    chart.yScale().maximum(n);
    chart.labels().background('#ffffff');
    chart.labels().fontColor("#000000");

    chart.xAxis(0).overlapMode('allow-overlap');

    var xLabels0 = chart.xAxis(0).labels();
    xLabels0.wordWrap("break-word");
    xLabels0.wordBreak("break-all");
    xLabels0.width('20%');
    xLabels0.hAlign("right");

    var xLabels1 = chart.xAxis(1).labels();
    xLabels1.wordWrap("break-word");
    xLabels1.wordBreak("break-all");
    xLabels1.width('20%');
    xLabels1.hAlign("left");

    var yAxis2 = chart.yAxis(1);
    yAxis2.orientation("top");
    yAxis2.labels().format(row => Math.abs(Math.round(row.tickValue / n * 1000) / 10) + '%');

    chart.xAxis(0).overlapMode('allow-overlap');
    chart.xAxis(1).overlapMode('allow-overlap');

    chart.xAxis(1)
    .enabled(true)
    .orientation('right')
    .overlapMode('allow-overlap');

    chart.title('');

    chart.interactivity().hoverMode('by-x');

    chart.tooltip(false);

    var series;

    series = chart.bar(seriesData_2);
    series.name(data.series2Name);
    series.labels(true);
    series.labels().format(row => Math.abs(row.value) + " (" + Math.abs(Math.round(row.value / n * 1000) / 10) + '%)');

    series = chart.bar(seriesData_1);
    series.name(data.series1Name);
    series.labels(true);
    series.labels().format(row => Math.abs(row.value) + " (" + Math.abs(Math.round(row.value / n * 1000) / 10) + '%)');


    // turn on legend
    chart.legend()
    .enabled(true)
    .inverted(true)
    .fontSize(13)
    .padding([0, 0, 20, 0]);

    return <AnyChart
        id={'question-' + this.props.id}
        instance={stage}
        height={height}
        charts={[chart]}
      />;
  }
}

ChartStackedBarNegValues.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    series1Name: PropTypes.string.isRequired,
    series2Name: PropTypes.string.isRequired,
  }).isRequired,
  n: PropTypes.number.isRequired
};

export default ChartStackedBarNegValues;