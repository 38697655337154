import React, {Component} from 'react';
import PlanIndividual from "./PlanIndividual";
import {FormattedMessage} from "react-intl";
import {Alert, Card} from "reactstrap";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import api from "api";
import LoadingSpinner from "components/LoadingSpinner";
import appRoutes from "appRoutes";
import history from "services/history";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import PlanSubscription from "./PlanSubscription";
import PlansTableNew from "./PlansTableNew";

class Plan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: [],
      prizes: [],
    };

    this.handleChoosePlan = this.handleChoosePlan.bind(this);
  }

  componentDidMount() {
    document.title = 'Zmiana planu | ANKIETEO';
    document.querySelector('meta[name="description"]').setAttribute("content", 'Zmiana planu');
    document.querySelector('meta[name="keywords"]').setAttribute("content", 'Zmiana planu');
    api.plan.get.userPlans().then(data => {
      this.setState({
        plans: data.plans,
        prizes: data.prizesAccount,
      });
    });
  }

  handleChoosePlan(plan, period) {
    if (plan.name === 'Enterprise') {
      return;
    }

    if (plan.price === 0) {
      history.push(appRoutes.users.freePlanDetails(plan.id));
    } else {
      history.push(appRoutes.users.planDetails(plan.id, period));
    }
  }

  render() {
    const {plans, prizes} = this.state;
    const {user} = this.props;
    const isCompany = user.type === 'Company';

    if(_.isEmpty(plans)){
      return <LoadingSpinner />
    }

    return (
      <Card>
        <CardHeader><FormattedMessage tagName="h2" id="plan.cardHeader"/></CardHeader>
        <hr className="my-0" />
        <CardBody>
          {isCompany === true && user.userPlan.isExpired && <Alert color="warning">Ważność Twojego planu zakończyła się. Wybierz jeden z dostępnych planów Ankieteo.</Alert>}
          {isCompany === false && <Alert color="warning">
            <p style={{"fontSize": "16px"}}>Cześć {user.firstname},</p>
            <p style={{"fontSize": "16px"}}>Wybierz jeden z dostepnych planów Ankieteo.</p>
            <p style={{"fontSize": "16px"}}>Możesz wybrać specjalny, darmowy plan swpanel, dostępny tylko dla panelistów swpanel.pl</p>
            <p className="mb-0" style={{"fontSize": "16px"}}>Jeśli potrzebujesz bardziej zaawansowanych opcji - sprawdź wszystkie nasze dostępne wersje Ankieteo na stronie: <a style={{"fontSize": "16px"}} href="https://ankieteo.pl/abonament" target="_blank">https://ankieteo.pl/abonament</a></p>
          </Alert>}
          <PlanSubscription userPlan={user.userPlan}/>
          <PlansTableNew plans={plans} prizes={prizes} bestseller={plans[1].id} onChoosePlan={this.handleChoosePlan} isCompany={isCompany}/>
          {isCompany && <PlanIndividual />}
        </CardBody>
      </Card>
    )
  }
}

Plan.propTypes = {
  user: PropTypes.shape({
    userPlan: PropTypes.shape({
      isExpired: PropTypes.bool.isRequired,
      plan: PropTypes.shape({
        demo: PropTypes.bool.isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(Plan);
