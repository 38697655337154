import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Card, Col, Row, CardHeader, CardBody, Input, Label} from "reactstrap";
import Blocks from "./Blocks";
import Actions from "./Actions";
import "./styles/style.scss"

class Filter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filter: props.filter || {
        name: 'Filtr',
        actions: []
      }
    };
  }

  getFilter(){
    const {filter} = this.state;

    return  filter;
  }

  render() {
    const {colMd} = this.props;
    const {filter} = this.state;

    return (
      <Row id="filter">
        <Label md={3}>Nazwa:</Label>
        <Col md={9}>
          <Input type="text" name="name" value={filter.name} onChange={e => this.setState({filter: {...filter, name: e.target.value}})}/>
        </Col>
        <Col md={colMd}>
          <Card>
            <CardHeader className="text-center"><h2>Warunki</h2></CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Blocks blocks={filter.blocks || undefined} handleBlocksChanged={(blocks) => {
                this.setState({
                  filter: {
                    ...filter,
                    blocks
                  }
                })
              }} />
            </CardBody>
          </Card>
        </Col>
        <Col md={colMd}>
          <Actions
            filter={filter}
            handleActionsChanged={actions => {
              this.setState({
                filter: {
                  ...filter,
                  actions
                }
              })
            }}
          />
        </Col>
      </Row>
    );
  }
}

Filter.defaultProps = {
  colMd: 12,
};

Filter.propTypes = {
  colMd: PropTypes.number,
  filter: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default connect(null, null, null, { forwardRef: true })(Filter);