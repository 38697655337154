import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import InputNumber from "components/InputNumber";

class AnswerWeightValue extends Component{
  constructor(props) {
    super(props);
    this.state = {
      weight: props.answer.weight,
      tooltipIsOpen: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  onChange(value) {
    this.setState({...this.state, weight: value === '' ? '' : parseInt(value)});
    this.props.changeOption('weight', value);
  }

  render() {
    const {weight, tooltipIsOpen} = this.state;

    return (
      <React.Fragment>
        <InputNumber
          float
          value={weight}
          onChange={this.onChange}
          id={'answerWeightValue-' + this.props.answer.id}
          min={-100}
          max={100}
        />
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltipIsOpen}
          target={'answerWeightValue-' + this.props.answer.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="answer.answerWeightValue.button.tooltip"/>
        </Tooltip>
      </React.Fragment>
    )
  }
}

AnswerWeightValue.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
  }).isRequired,
  changeOption: PropTypes.func.isRequired
};

export default AnswerWeightValue;
