import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Row, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import striptags from "striptags";
import api from "../../../../../api";
import HtmlEditor from "../../../../../componentsReusable/HtmlEditor";
import SearchTextInput from "../../../../../components/Application/ApplicationTable/SearchTextInput";
import ApplicationTable from "../../../../../components/Application/ApplicationTable";

const TemplatesTable = (props) => {
  const [openTable, setOpenTable] = React.useState(false);
  const toggleOpenTable = () => setOpenTable(!openTable);
  const tableRef = React.createRef();

  const columns = [
    {
      id: 'name',
      Header: 'Nazwa',
      width: 300,
      accessor: row => row.name
    },
    {
      id: 'description',
      Header: 'Opisz szablonu',
      accessor: row => <div dangerouslySetInnerHTML={{__html: striptags(row.description)}} />
    },
  ];


  return <Row>
    <Col>
      <h1 className="text-center mt-3">{openTable ? <span>Lista dostępnych szablonów</span> : <span className="pointer" onClick={toggleOpenTable}>Pokaż listę dostępnych szablonów</span>}</h1>

      {openTable && <div>
        <div className="d-flex justify-content-end mb-2">
          <div className="ml-auto">
            <SearchTextInput
              tableRef={tableRef}
            />
          </div>
        </div>

        <ApplicationTable
          ref={tableRef}
          columns={columns}
          fetchData={api.surveyCollectorPanelTemplate.search}
        /></div>}
    </Col>
  </Row>
}

class CollectorPanelCreateTemplate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      saving: false,
      errors: {},
      data: {
        name: '',
        description: '',
      }
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  saveTemplate(){
    const {data} = this.state;

    this.setState({
      saving: true,
    });

    api.user.post.surveyCollectorPanelTemplate(data, this.props.collectors)
    .then(data => {
      this.setState({
        saving: false,
        modalOpen: false,
      });
    })
    .catch(res => {
      this.setState({
        saving: false,
        errors: res.response.data.errors.children
      });
    });

  }

  render() {
    const {modalOpen, saving, data, errors} = this.state;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal}>Utwórz szablon kolektorów</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label md={3}>Nazwa</Label>
            <Col md={9}>
              <Input invalid={_.has(errors, 'name.errors')} onChange={e => this.setState({data: {...data, 'name': e.target.value}})} value={data.name} />
              {_.has(errors, 'name.errors') && <p className="help-block text-danger">{errors.name.errors.join('; ')}</p>}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3}>Opis</Label>
            <Col md={9}>
              <HtmlEditor
                className="w-100"
                config={{
                  height: '80px'
                }}
                data={data.description}
                onChange={event => this.setState({data: {...data, 'description': event}})}
              />
              {_.has(errors, 'description.errors') && <p className="help-block text-danger">{errors.description.errors.join('; ')}</p>}
            </Col>
          </FormGroup>

          <TemplatesTable surveyId={this.props.surveyId} />
        </Form>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.saveTemplate}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return <React.Fragment>
      <Button color="primary" onClick={this.toggleModal}>
        Utwórz szablon kolektorów
      </Button>
      {modal}
    </React.Fragment>;
  }
}

CollectorPanelCreateTemplate.propTypes = {
  collectors: PropTypes.arrayOf(PropTypes.number).isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default CollectorPanelCreateTemplate;