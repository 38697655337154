import React, {Component} from 'react';
import { connect } from 'react-redux';
import {setQuestionTypes} from 'scenes/Survey/Structure/actions';
import SelectQuestionTypes from '../../../SelectQuestionTypes';
import ButtonDisabledQuestions from "./components/ButtonDisabledQuestions";
import ButtonFilterActionQuestions from "./components/ButtonFilterActionQuestions";
import ButtonFilterConditionQuestions from "./components/ButtonFilterConditionQuestions";
import {questionTypes} from "services/questionTypes";

class AdvancedOptions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      questionTypes: props.questionTypes
    };
    this.onTypesChange = this.onTypesChange.bind(this);
  }

  onTypesChange(questionTypes) {
    this.setState({questionTypes: questionTypes}, () => {
      this.props.setQuestionTypes(questionTypes);
    })
  }

  render() {
    return (
      <div className="direction-group px-3 py-2">
        <div className="direction-right d-flex w-100">
          <SelectQuestionTypes className="mr-4 w-25" isMulti={true} onChange={this.onTypesChange} value={this.state.questionTypes} questionTypes={this.props.templateTypesInUse}/>
          <ButtonFilterActionQuestions/>
          <ButtonFilterConditionQuestions/>
          <ButtonDisabledQuestions/>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const types = [];

  _.each(state.survey.structure.data.questions, question => {
    _.each(questionTypes, templates => {
      _.each(templates, template => {
        if (template.type === question.type) {
          if (template.hasOwnProperty('options')) {
            let options = 0;

            _.each(Object.keys(template.options), option => {
              if (question[option] === template.options[option]) {
                options++
              }
            });

            if (options === Object.keys(template.options).length) {
              if (!types.includes(template.name)) {
                types.push(template.name)
              }
            }
          } else {
            if (!types.includes(template.name)) {
              types.push(template.name)
            }
          }
        }
      })
    })
  });

  return {
    questionTypes: state.survey.structure.questionTypes,
    templateTypesInUse: types,
  }
}

export default connect(mapStateToProps, {setQuestionTypes})(AdvancedOptions);
