import React, {Component} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardHeader, CardBody, ButtonGroup, Row, Col, Label, InputGroup, Input, InputGroupAddon, InputGroupText} from "reactstrap";
import {connect} from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import QuestionInfo from "../components/QuestionInfo";
import {getQuestionTemplateName} from "../services/questionTypes";
import moment from "moment";
import overlayFactory from "react-bootstrap-table2-overlay";
import cellEditFactory from "react-bootstrap-table2-editor";
import {FormattedMessage} from "react-intl";
import Select from "react-select";
import api from "../api";
import history from "services/history";
import QuestionSummaryCard from "../scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummaryCard";
import appRoutes from "../appRoutes";
import QuestionContentCopy from "./QuestionContentCopy";

const searchInOptions = [
  {
    value: 'questions',
    label: 'Treściach pytań',
  },
  {
    value: 'cafeteries',
    label: 'Treściach kafeterii',
  },
]

const ModalResults = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  return <React.Fragment>
    <Button size="sm" color="primary" onClick={toggleModalOpen}>Pokaż wyniki</Button>
    {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="xl">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Wyniki </ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <QuestionSummaryCard
          analysis={{
            id: props.analysisId,
          }}
          type={props.questionType}
          settings={false}
          questionSummary={{
          id: props.questionSummaryId,
          question: {
            id: props.questionId
          }
        }} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.close">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
}

const RemotePagination = ({ loading, data, page, sizePerPage, onTableChange, totalSize, sortField, sortOrder, fetchData }) => {
  const columns = [
    {
      dataField: 'content',
      text: 'Treść pytania',
      sort: false,
      editable: false,
      formatter: (cell, row) => {
        return <div dangerouslySetInnerHTML={{__html: row.content}} />;
      }
    },
    {
      dataField: 'survey',
      text: 'Ankieta',
      sort: false,
      editable: false,
      headerStyle: {
        width: '350px'
      },
      formatter: (cell, row) => {
        return row.surveyName;
      }
    },
    {
      dataField: 'surveyId',
      text: 'id ankiety',
      sort: false,
      editable: false,
      headerStyle: {
        width: '75px'
      },
      formatter: (cell, row) => {
        return row.surveyId;
      }
    },
    {
      dataField: 'endResponsesCount',
      text: 'Wypełnień',
      sort: false,
      editable: false,
      headerStyle: {
        width: '80px'
      },
      formatter: (cell, row) => {
        return row.surveyEndResponsesCount;
      }
    },
    {
      dataField: 'client',
      text: 'Klient',
      sort: false,
      editable: false,
      headerStyle: {
        width: '150px'
      },
      formatter: (cell, row) => {
        return row.clientName;
      }
    },
    {
      dataField: 'type',
      text: 'Rodzaj pytania',
      sort: false,
      editable: false,
      headerStyle: {
        width: '210px'
      },
      formatter: (cell, row) => {
        return <span><QuestionInfo question={row} /> <FormattedMessage id={`_.question.${getQuestionTemplateName(row)}.typeName`}/></span>;
      }
    },
    {
      dataField: 'createdAt',
      text: 'Data',
      sort: false,
      editable: false,
      headerStyle: {
        width: '80px'
      },
      formatter: (cell, row) => {
        return row.surveyCreatedAt ? moment(row.surveyCreatedAt).format('YYYY-MM-DD') : <i>Brak daty</i>;
      }
    },
    {
      dataField: 'actions',
      text: 'Opcje',
      editable: false,
      headerStyle: {
        width: '160px'
      },
      formatter: (cell, row) => {
        return <div className="d-flex flex-column">
          {row.questionSummaryId && <ModalResults
            analysisId={row.analysisId}
            questionId={row.questionId}
            questionSummaryId={row.questionSummaryId}
            questionType={row.type}
          />}
          <QuestionContentCopy questionId={row.id} />
        </div>
      }
    }
  ];

  return <BootstrapTable
    classes="table-sm"
    striped
    hover
    loading={loading}
    remote
    sort={{
      dataField: sortField,
      order: sortOrder,
    }}
    keyField="id"
    data={ data }
    columns={ columns }
    pagination={ paginationFactory({ page, sizePerPage, totalSize }) }
    onTableChange={(type, values) => onTableChange(type, values) }
    overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(0,0,0,0.3)'}) } }) }
    noDataIndication={() => !loading ? 'Brak danych' : 'Pobieram dane...'}
    cellEdit={ cellEditFactory({
      mode: 'click',
      blurToSave: true,
    }) }
  />
};


class QuestionsResultView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      search: '',
      filters: {
        searchIn: 'questions', //questions,cafeteries
      },
      searchTimeout: null,
      sortField: 'id',
      sortOrder: 'desc',
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    const {plan, user} = this.props;
    document.title = 'Przeglądarka wyników | ANKIETEO';
    if(plan){
      if(!plan.allQuestionsResult){
        history.push(appRoutes.home);
      }else{
        if((user.userGroups.length > 0 && user.userGroups[0].privilageSurveyShareDefault && !user.userGroups[0].privilageSurveyShareDefault.accessQuestionsSurveysResults)){
          history.push(appRoutes.home);
        }
      }
    }
  }

  handleFilterChange(key, value){
    this.setState({
      filters: {
        ...this.state.filters,
        [key]: value
      }
    }, () => this.fetchData());
  }

  handleOnChangeSearch(e){
    clearTimeout(this.state.searchTimeout);
    const search = e.target.value;

    if(e.keyCode === 13){
      this.setState({
        search,
        searchTimeout: null,
      }, () => {
        this.fetchData();
      });
    }else{
      this.setState({
        search,
        searchTimeout: setTimeout(() => {
          this.fetchData();
        }, 1000)
      });
    }
  }

  handleTableChange(type, values){
    switch (type) {
      case 'pagination': {
        this.setState({
          sizePerPage: values.sizePerPage,
          page: values.page,
        }, () => {
          this.fetchData()
        });
        break;
      }
      case 'sort': {
        this.setState({
          sizePerPage: values.sizePerPage,
          page: values.page,
          sortField: values.sortField,
          sortOrder: values.sortOrder,
        }, () => {
          this.fetchData()
        });
        break;
      }
      default: {
        return;
      }
    }
  }

  fetchData(){
    const {sizePerPage, page, search, filters, sortField, sortOrder} = this.state;

    this.setState({loading: true});

    api.user.findQuestions({
      page,
      limit: sizePerPage,
      search,
      filters,
    }, {
      sortField,
      sortOrder,
    })
    .then((res) => {
      this.setState({
        loading: false,
        data: res.data,
        totalSize: res.total,
      });
    })
    .catch(res => {
      this.setState({loading: false});
    })
  };


  render() {
    const {loading, data, page, sizePerPage, totalSize, sortField, sortOrder, filters} = this.state;

    return (
      <Card>
        <CardHeader className="p-0">
          <h2><strong>Przeglądarka wyników</strong></h2>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <Row className="mb-2 d-flex justify-content-end">
            <Col>
              <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex align-items-center mr-3" style={{width: '250px'}}>
                  <span className="mr-2 text-nowrap">Szukaj w: </span>
                  <Select
                    className="w-100"
                    options={searchInOptions}
                    value={searchInOptions.find(o => o.value === filters.searchIn)}
                    onChange={option => this.handleFilterChange('searchIn', option.value)}
                  />
                </div>
                <InputGroup className="w-25 mb-0">
                  <Input placeholder="Szukaj..." onKeyUp={this.handleOnChangeSearch} />
                  <InputGroupAddon addonType="append"><InputGroupText className="cursor-pointer"><i className="fa fa-search" /></InputGroupText></InputGroupAddon>
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <RemotePagination
                sortField={sortField}
                sortOrder={sortOrder}
                loading={ loading }
                data={ data }
                page={ page }
                sizePerPage={ sizePerPage }
                totalSize={ totalSize }
                onTableChange={ this.handleTableChange }
                fetchData={this.fetchData}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    plan: 'userPlan' in state.user ? state.user.userPlan.plan : null
  }
}

export default connect(mapStateToProps)(QuestionsResultView)
