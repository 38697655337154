import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import ReactTable from 'react-table';
import Pagination from "components/Pagination";
import api from 'api';
import moment from "moment";
import appRoutes from "appRoutes";
import PropTypes from "prop-types";

class FindShippingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const filtersWereChanged = this.props.search !== nextProps.search || this.props.dateFrom !== nextProps.dateFrom;
    if (filtersWereChanged) {
      clearTimeout(this.state.searchTimeout);
      this.setState({
        searchTimeout: setTimeout(() => {
          this.fetchData({
            page: this.state.page,
            pageSize: this.state.pageSize,
            sorted: this.state.sorted
          }, nextProps);
        }, 1000)
      });
    }
  }

  fetchData(state, props) {
    if(!props.search){
      this.setState({
        searching: false,
        page: 0,
        pageSize: 50,
        data: [],
        count: 0,
      });

      return;
    }

    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted,
        search: props.search,
        dateFrom: props.dateFrom,
      }
    };

    api.admin.shippingFind(data).then(res => {
      this.setState({searching: false, data: res.rows, count: res.count});
    });
  }

  render() {
    const columns = [
      {
        id: 'user.id',
        Header: 'User',
        sortable: false,
        accessor: row => `(${row.userId}) ${row.userEmail}`
      },
      {
        id: 's.id',
        Header: 'Ankieta',
        sortable: false,
        accessor: row => `(${row.surveyId}) ${row.surveyName}`
      },
      {
        id: 'se.sendDateStart',
        Header: 'Data kolektora',
        sortable: false,
        accessor: row => row.date
      },
      {
        id: 'sentEmail.createdAt',
        Header: 'Data wysyłki',
        sortable: false,
        accessor: row => row.sentEmailDate
      },
      {
        id: 'sc.id',
        Header: 'Kolektor',
        sortable: false,
        accessor: row => `(${row.collectorId}) ${(row.collectorName ? row.collectorName : '')}`
      },
      {
        id: 'email.status',
        Header: 'Status ankiety',
        sortable: false,
        accessor: row => row.realSurveyResponseStatus ? <FormattedMessage id={`admin.responses.status-${row.realSurveyResponseStatus}`}/> : <i className="text-secondary">Brak</i>
      },
      {
        id: 'sr.status',
        Header: 'Status tokenu',
        sortable: false,
        accessor: row => row.surveyResponseStatus ? <FormattedMessage id={`admin.responses.status-${row.surveyResponseStatus}`}/> : <i className="text-secondary">Brak</i>
      },
      {
        id: 'ser.guid',
        Header: 'Guid',
        sortable: false,
        accessor: row => row.guid
      },
    ];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={20}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.panelShippingList.listItems.table.noShipping" />}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

FindShippingList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
};

export default FindShippingList;
