import React from 'react';
import PropTypes from 'prop-types';
import useDidMountEffect from "../../../utils/useDidMountEffect";
import api from "../../../api";
import Select from "react-select";
import useAfterUpdateEffect from "../../../utils/useAfterUpdateEffect";

const AnalysisSelect = props => {
  const [value, setValue] = React.useState(props.value);
  console.log('valuexxxx', value);
  const [analysis, setAnalysis] = React.useState([]);

  useAfterUpdateEffect(() => {
    props.onChange(value)
  }, [value])

  useDidMountEffect(() => {
    api.survey.get.analyses({
      id: props.surveyId
    }).then(setAnalysis);
  })

  const getOptions = () => {
    return analysis.map(analysis => ({
      label: analysis.name,
      value: analysis.id,
    }))
  }

  const getValue = () => {
    if(getOptions().length === 0){
      return null
    }

    return getOptions().find(option => option.value === value)
  }

  return <Select
    isClearable
    placeholder={"Wybierz analizę..."}
    options={getOptions()}
    onChange={option => setValue(option ? option.value : null)}
    value={getValue()}
  />;
};

AnalysisSelect.propTypes = {
  surveyId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AnalysisSelect;
