import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button} from "reactstrap";
import api from "api";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import classnames from "classnames";


class ButtonSendPaused extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
    };

    this.toggleSendPaused = this.toggleSendPaused.bind(this);
  }

  toggleSendPaused(){
    const {collector} = this.props;

    this.setState({
      saving: true
    });

    api.surveyCollector.patch({
      id: collector.id,
      surveyCollector: {
        sendPaused: !collector.sendPaused
      },
    })
    .then(collector => {
      this.setState({
        saving: false,
      });
      this.props.handleUpdate(collector);
    })
    .catch(e => {
      this.setState({
        saving: false
      })
    });
  }

  render() {
    const {saving} = this.state;
    const {collector} = this.props;

    return <LaddaButton
      className={classnames({
        'btn btn-ladda d-block ml-2': true,
        'btn-danger': collector.sendPaused,
        'btn-success': !collector.sendPaused,
      })}
      loading={saving}
      data-style={ZOOM_OUT}
      onClick={this.toggleSendPaused}
    >{collector.sendPaused
      ? <span>Wysyłka zaproszeń jest wstrzymana, kliknij aby uruchomić</span>
      : <span>Wysyłka zaproszeń jest włączona</span>
    }</LaddaButton>
  }
}

ButtonSendPaused.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sendPaused: PropTypes.bool.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default ButtonSendPaused;