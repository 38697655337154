import React, {Component} from 'react';
import PropTypes from "prop-types";
import AnyChart from "anychart-react";

class ChartHistogram extends Component {

  render() {
    const {id, data} = this.props;
    let stage = anychart.graphics.create();
    var chart = anychart.bar(data);
    chart.labels().enabled(true);

    var tooltip = chart.getSeries(0).tooltip();
    tooltip.format(row => 'Średnia: ' + row.value);

    let height = 130;
    height += (data.length * 65);

    var xLabels = chart.xAxis().labels();
    xLabels.wordWrap("break-word");
    xLabels.wordBreak("break-all");
    xLabels.width('20%');
    xLabels.hAlign("right");

    chart.labels().enabled(true).format(row => row.value);
    chart.labels().background('#ffffff');
    chart.labels().fontColor("#000000");
    chart.labels().position("center");

    return (
      <AnyChart
        barGroupsPadding={0}
        id={'question-' + id}
        type="column"
        height={height}
        instance={stage}
        charts={[chart]}
        yAxis={{
          overlapMode: 'allowOverlap',
          labels: {
            wordWrap: 'break-word',
            wordBreak: 'break-all',
            hAlign: 'right'
          }
        }}
        xAxis={{
          overlapMode: 'allowOverlap',
          labels: {
            wordWrap: 'break-word',
            wordBreak: 'break-all',
          }
        }}
      />
    )
  }
}

ChartHistogram.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,).isRequired,
  n: PropTypes.number.isRequired
};

export default ChartHistogram;