import React, {Component} from 'react';
import {Form, FormGroup, Label, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import reactHtmlParser from "react-html-parser";
import DatePicker from "react-datepicker";
import moment from "moment";
import Button from "../../../../components/Button";
import HtmlEditor from "../../../../componentsReusable/HtmlEditor";

class CaseStudyForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			ckeditorShortDescription: false,
			ckeditorDescription: false,
			showCkEditorDescription: false,
			report: {
				title: props.report ? props.report.title : '',
				publishAt: props.report ? moment(props.report.publishAt).format('YYYY-MM-DD HH:mm') : '',
				slug: props.report ? props.report.slug : '',
				image: props.report && props.report.image,
				shortDescription: props.report ? props.report.shortDescription : '',
				description: props.report ? props.report.description : '',
				metaDescription: props.report ? props.report.metaDescription : '',
				metaKeywords: props.report ? props.report.metaKeywords : ''
			}
		};

		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.setState({
			...this.state,
			report: {
				...this.state.report,
				[e.target.name]: e.target.value
			}
		});
	}

	render() {
		let {report, ckeditorShortDescription, ckeditorDescription, showCkEditorDescription} = this.state;
		const {errors, onSubmit} = this.props;

		return (
			<Form onSubmit={e => onSubmit(e, this.state)}>
				<FormGroup>
					<Label for="title"><FormattedMessage id="admin.reportForm.label.title"/></Label>
					<Input
						type="text" name="title" id="title" value={report.title} invalid={!!errors.title}
						onChange={this.onChange}
					/>
					{errors.title && <p className="help-block text-danger">{errors.title}</p>}
				</FormGroup>
				<FormGroup>
					<Label for="title">Tytuł strony (max 60 znaków)</Label>
					<Input
						type="text" name="pageTitle" id="pageTitle" value={report.pageTitle} invalid={!!errors.pageTitle}
						onChange={this.onChange}
					/>
					{errors.pageTitle && <p className="help-block text-danger">{errors.pageTitle}</p>}
				</FormGroup>
				<FormGroup>
					<Label for="publishAt"><FormattedMessage id="admin.reportForm.label.publishAt"/></Label>
					<DatePicker
						dateFormat="Y-MM-DD HH:mm"
						showTimeSelect
						locale={localStorage.language}
						dropdownMode="select"
						placeholderText="Data publikacji"
						selected={report.publishAt ? moment(report.publishAt) : ''}
						onChange={date => this.onChange({
								target: {
									name: 'publishAt',
									value: date.format('YYYY-MM-DD HH:mm')
								}
							}
						)}
					/>
					{errors.publishAt && <p className="help-block text-danger">{errors.publishAt}</p>}
				</FormGroup>
				<FormGroup>
					<Label for="shortDescription"><FormattedMessage
						id="admin.reportForm.label.shortDescription"
					/></Label>
					{ckeditorShortDescription ? <HtmlEditor
						data={report.shortDescription}
						onChange={(event) => {
							this.setState({
								report: {
									...report,
									shortDescription: event
								},
							})
						}}
						onBlur={() => {
							this.setState({
								ckeditorShortDescription: false
							})
						}}
					/> : <div
						onClick={() => this.setState({ckeditorShortDescription: true})}
					>{report.shortDescription ? reactHtmlParser(report.shortDescription) : <i>Brak treści</i>}</div>}
					{errors.shortDescription && <p className="help-block text-danger">{errors.shortDescription}</p>}
				</FormGroup>
				<FormGroup>
					<Label for="description"><FormattedMessage id="admin.reportForm.label.description"/></Label>
					{ckeditorDescription ?
						<div>
							{showCkEditorDescription === false &&
							<div>
								<div className="d-flex flex-column">
									<Input
										type="textarea" rows={15}
										onChange={e => this.onChange({
												target: {
													name: 'description',
													value: e.target.value
												}
											}
										)}
										value={report.description}
									/>
									<Button
										size="sm" className="mb-3" onClick={() => this.setState({
										showCkEditorDescription: true,
									})}
									>Pokaż CKEditor</Button>
								</div>
							</div>
							}
							{showCkEditorDescription === true && <div><HtmlEditor
								data={report.description}
								onChange={(event) => {
									this.setState({
										report: {
											...report,
											description: event
										},
									})
								}}
								onBlur={() => {
									this.setState({
										ckeditorDescription: false,
									})
								}}
							/>
								<Button
									size="sm" className="mb-3 w-100" onClick={() => this.setState({
									showCkEditorDescription: false,
								})}
								>Ukryj CKEditor</Button>
							</div>}
						</div>
						: <div
							onClick={() => this.setState({ckeditorDescription: true})}
						>{report.description ? reactHtmlParser(report.description) : <i>Brak treści</i>}</div>}
					{errors.description && <p className="help-block text-danger">{errors.description}</p>}
				</FormGroup>
				<FormGroup>
					<Label for="metaDescription">Meta description</Label>
					<Input
						type="text" name="metaDescription" id="metaDescription" value={report.metaDescription}
						invalid={!!errors.metaDescription} onChange={this.onChange}
					/>
					{errors.metaDescription && <p className="help-block text-danger">{errors.metaDescription}</p>}
				</FormGroup>
				<FormGroup>
					<Label for="metaKeywords">Meta keywords</Label>
					<Input
						type="text" name="metaKeywords" id="metaKeywords" value={report.metaKeywords}
						invalid={!!errors.metaKeywords} onChange={this.onChange}
					/>
					{errors.metaKeywords && <p className="help-block text-danger">{errors.metaKeywords}</p>}
				</FormGroup>
				<FormGroup
					onClick={() => CKFinder.popup({
						connectorInfo: 'auth=' + localStorage.jwt,
						chooseFiles: true,
						width: 1014,
						height: 700,
						onInit: (finder) => {
							finder.on('files:choose', (e) => {
								let data = {
									target: {
										name: 'image',
										value: e.data.files.first().getUrl()
									}
								};
								this.onChange(data);
							});
						}
					})}
				>
					<Label for="image"><FormattedMessage id="admin.reportForm.label.image"/></Label>
					<div>
						<img
							alt=""
							src={report.image}
							style={{
								maxHeight: '100px',
								maxWidth: '100px',
							}}

						/>
					</div>
					{errors.image && <p className="help-block text-danger">{errors.image}</p>}
				</FormGroup>
			</Form>
		)
	}
}

CaseStudyForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	report: PropTypes.object
};

export default CaseStudyForm;