import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../../componentsReusable/Button";
import api from "../../../../api";

const SecretariatSendButton = props => {
  const [sending, setSending] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  const send = () => {
    setSending(true);

    api.report.sendToSecretariat(props.id)
      .then(() => props.onSuccess())
      .finally(() => setSending(false))
  }

  return <React.Fragment>
    <i className="far fa-envelope pointer p-2" onClick={toggleModalOpen} />
    {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
      <ModalHeader toggle={toggleModalOpen}>Potwierdź</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <p>Potwierdź wysłanie danych zgłoszenia do sekretariatu</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
        <Button
          color="success"
          onClick={send}
          loading={sending}
        >Wyślij do sekretariatu</Button>
      </ModalFooter>
    </Modal>}
  </React.Fragment>;
};

SecretariatSendButton.propTypes = {

};

export default SecretariatSendButton;
