import React, {Component} from 'react';
import {Row, Col, Card, CardBody} from "reactstrap";
import { connect } from 'react-redux';
import {acceptInvitation} from '../actions';
import LoadingSpinner from "components/LoadingSpinner";

class GroupInvitationAccept extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      errors: {}
    }
  }


  componentDidMount() {
    this.props.acceptInvitation(this.props.match.params.group)
      .then(() => this.setState({loading: false}))
      .catch(e => this.setState({loading: false, errors: e.response.data.errors}));
  }

  render() {

    if (this.state.loading) {
      return <LoadingSpinner />
    }

    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardBody>
              {this.state.errors ? <p>{this.state.errors[0]}</p> : <p>Zaproszenie do grupy zostało zaakceptowane</p>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default connect(null, {acceptInvitation})(GroupInvitationAccept);