import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip
} from "reactstrap";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import { connect } from 'react-redux';
import {updateSurvey} from "scenes/Survey/Structure/data/survey/actions";

class Integration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false,
      integration: this.props.integration || '',
      tooltip: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.updateIntegration = this.updateIntegration.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleModal() {
    this.setState({modal: !this.state.modal})
  }

  toggleTooltip() {
    this.setState({tooltip: !this.state.tooltip})
  }

  onChange(e) {
    this.setState({integration: e.target.value})
  }

  updateIntegration() {
    this.setState({saving: true});
    const integrationPropertyName = this.props.type + 'Id';

    this.props.updateSurvey({
      id: this.props.surveyId,
      survey: {
        [integrationPropertyName]: this.state.integration
      }
    }).then(() => {
      this.setState({saving: false, modal: false});
    });
  }

  render() {
    const {type} = this.props;

    const modal = <Modal isOpen={this.state.modal} size="lg" toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2">
        <FormattedMessage id={"integrations.integration.modalHeader." + type} />
      </ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <FormattedMessage id={"integrations.integration.modal.description." + type} />
        <Form className="mt-4">
          <FormGroup row className="mt-2" >
            <Label sx={12} lg={4} className="text-lg-right">
              <FormattedMessage id={"integrations.integration.modal.input.label." + type} />
            </Label>
            <Col sx={12} lg={8}>
              <Input type="text" value={this.state.integration} onChange={this.onChange} required/>
              <i>
                <FormattedMessage id={"integrations.integration.modal.detailedDescription." + type} /> {type === 'hotjar' && <a href="https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-your-Hotjar-Tracking-Code">https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-your-Hotjar-Tracking-Code</a>}
              </i>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.save">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                data-style={ZOOM_OUT}
                onClick={this.updateIntegration}
                loading={this.state.saving}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <Col sm={12} md={6} lg={4} xl={4} className="d-flex align-items-md-stretch">
        <Card className="border-l orange w-100">
          <CardHeader>
            <FormattedMessage id={"integrations.integration.cardHeader." + type}>
              {msg => <h4>{msg}
                {!!this.props.integration && <React.Fragment>
                  <i className="fas fa-check text-success ml-2" id={"integration-" + type}/>
                  <Tooltip
                    delay={0}
                    placement="bottom"
                    isOpen={this.state.tooltip}
                    target={"integration-" + type}
                    toggle={this.toggleTooltip}
                  >
                    <FormattedMessage id="integrations.integration.tooltip" />
                  </Tooltip>
                </React.Fragment>}
              </h4>}
            </FormattedMessage>
          </CardHeader>
          <hr className="m-0" />
          <CardBody>
            <p><FormattedMessage id={"integrations.integration.cardBody." + type} /></p>
          </CardBody>
          <CardFooter>
            <Button className="w-100 mb-0" color="primary" onClick={this.toggleModal}><i className="fas fa-edit" /> <FormattedMessage id="_.button.edit" /></Button>
          </CardFooter>
        </Card>
        {modal}
      </Col>
    );
  }
}

Integration.propTypes = {
  type: PropTypes.string.isRequired,
  surveyId: PropTypes.number.isRequired,
};

function mapStateToProps(state, props) {
  const integrationPropertyName = props.type + 'Id';

  return {
    integration: state.survey.structure.data.survey[integrationPropertyName]
  }
}

export default connect(mapStateToProps, {updateSurvey})(Integration);
