import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Button, Tooltip} from "reactstrap";
import {normalize} from "normalizr";
import schemaQuestions from "entities/question/schema";
import {columnUpdated} from "scenes/Survey/Structure/data/columns/actions";
import classnames from "classnames";

class ColumnsImage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: {
        columnsImage: props.question.columnsImage,
      },
      tooltip: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleColumnsImage = this.toggleColumnsImage.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleColumnsImage() {
    this.setState({
      question: {
        columnsImage: !this.state.question.columnsImage
      }
    }, () => {
      this.props.handleUpdate(this.state.question).then(question => {
        const normalized = normalize(question, schemaQuestions);

        Object.values(normalized.entities.columns).map(column => {
          this.props.dispatch(columnUpdated(column));
        })
      })
    });
  }

  render() {
    let { question, tooltip } = this.state;
    return (
      <Button
        className={classnames({
          "float-left mr-1 btn-icon mb-1": true,
          "active": question.columnsImage
        })}
        color={'secondary'}
        id={"columnsImage-" + this.props.question.id}
        onClick={this.toggleColumnsImage}
      >
        <i className="fa-solid fa-image" />

        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={"columnsImage-" + this.props.question.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="question.settings.columnsImage.button.tooltip"/>
        </Tooltip>
      </Button>
    )
  }
}

ColumnsImage.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    columnsImage: PropTypes.bool.isRequired
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default connect()(ColumnsImage);
