import * as React from 'react';
import {Tooltip} from "reactstrap";
import Switch from "../components/Switch";
import {updateProfile} from "../reducers/user/actions";
import {connect} from "react-redux";

function DarkMode(props) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toggleTooltipOpen = () => setTooltipOpen(!tooltipOpen);
  const [value, setValue] = React.useState(props.appDarkMode);

  React.useEffect(() => {
    localStorage.appDarkMode = props.appDarkMode ? 1 : 0;
    if(value){
      enableDarkMode();
    }
  }, [])
  
  const enableDarkMode = () => {
    document.body.classList.add('dark-mode');
  }
  
  const disableDarkMode = () => {
    document.body.classList.remove('dark-mode');
  }

  return (
    <div id="darkModeSwitchTooltip" className="d-flex justify-content-center align-items-start mr-5">
      <Switch stickToProp checked={value} onChange={checked => {
        const formData = new FormData();
        formData.append('appDarkMode', checked ? 1 : 0);
        props.updateProfile(formData);

        setValue(checked);
        localStorage.appDarkMode = checked ? 1 : 0;
        if(checked){
          enableDarkMode();
        }else{
          disableDarkMode();
        }
      }} />
      <span className="pl-2">Dark mode</span>
      <Tooltip
        target={"darkModeSwitchTooltip"}
        isOpen={tooltipOpen}
        toggle={toggleTooltipOpen}
      >Tryb ciemny / Dark mode</Tooltip>
    </div>
  );
}

DarkMode.propTypes = {

};

function mapStateToProps(state) {
  return {
    appDarkMode: state.user.appDarkMode
  }
}

export default connect(mapStateToProps, {updateProfile})(DarkMode);