import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import { connect } from 'react-redux';
import {updateFilterIterative} from "scenes/Survey/Structure/data/filterIterative/actions";
import {Card, CardBody, CardHeader, Input} from "reactstrap";
import ButtonDeleteFilterIterative from "../../../ButtonDeleteFilterIterative";
import QuestionsToList from "../../../QuestionsToList";
import QuestionFromSelect from "./components/QuestionFromSelect";
import QuestionFromFilterBySelect from "../../../QuestionFromFilterBySelect";
import ButtonCopyFilterIterative from "../../../ButtonCopyFilterIterative";

class FilterIterative extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      editingName: false,
      deleting: false,
      filter: {
        id: props.filter.id,
        questionFrom: props.filter.questionFrom,
        questionsTo: props.filter.questionsTo,
        name: props.filter.name || ''
      }
    };

    this.saveFilter = this.saveFilter.bind(this);
    this.onQuestionsToChange = this.onQuestionsToChange.bind(this);
    this.onQuestionFromChange = this.onQuestionFromChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  saveFilter() {
    this.setState({...this.state, saving: true, editingName: false});

    this.props.updateFilterIterative({surveyId: this.props.surveyId, filterIterative: this.state.filter})
      .then(() => this.setState({...this.state, saving: false}));
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.saveFilter()
  }

  onQuestionsToChange(questionsTo) {
    this.setState({filter: {
        ...this.state.filter,
        questionsTo
      }}, () => {
      this.saveFilter();
    })
  }

  onQuestionFromChange(questionFrom) {
    this.setState({filter: {
        ...this.state.filter,
        questionFrom
      }}, () => {
      this.saveFilter();
    })
  }

  render() {
    let editor = <Input value={this.state.filter.name} onChange={e => this.setState({filter: {...this.state.filter, name: e.target.value}})} onBlur={this.saveFilter} autoFocus={true} onKeyPress={this.handleKeyPress}/>;

    return (
      <Card>
        <CardHeader className="border-bottom p-3">
          {this.state.editingName ? editor : <span onClick={() => this.setState({editingName: true})}>{this.state.filter.name || <i><FormattedMessage id="question.filterIterative.questionTo.name.empty"/></i>}</span>}
          <ButtonDeleteFilterIterative filter={this.state.filter}/>
          <ButtonCopyFilterIterative filter={this.state.filter}/>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <FormattedMessage id="question.filterIterative.questionTo.questionFrom.label" />
          <QuestionFromSelect filter={this.props.filter} questions={this.props.questions} onChange={this.onQuestionFromChange}/>
          <QuestionFromFilterBySelect questionFrom={this.state.filter.questionFrom} onChange={filterBy => this.onQuestionFromFilterByChange({...this.state.filter.questionFrom, filterBy})}/>
          <FormattedMessage id="question.filterIterative.questionTo.questionsTo.label" />
          <QuestionsToList questions={this.props.questions} onChange={this.onQuestionsToChange} filter={this.props.filter}/>
        </CardBody>
      </Card>
    )
  }
}

FilterIterative.propTypes = {
  filter: PropTypes.object.isRequired,
  surveyId: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  type: PropTypes.string
};

export default connect(null, {updateFilterIterative})(FilterIterative);
