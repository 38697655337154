import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import appRoutes from "appRoutes";
import history from "services/history";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import FormSurvey from "components/ButtonCreateSurvey/components/FormSurvey";
import api from "api";
import LoadingSpinner from "../../../../components/LoadingSpinner";

class SurveysModalCopy extends Component {

  constructor(props) {
    super(props);

    this.state = {
      copying: false,
      errors: {},
      survey: false,
      loading: false,
    };

    this.form = React.createRef();
    this.copySurvey = this.copySurvey.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });

    api.survey.get.survey({
      id: this.props.survey.id,
    }).then(survey => {
      this.setState({
        loading: false,
        survey,
      })
    });
  }

  copySurvey(data) {
    this.setState({copying: true});

    api.survey.post.copy({survey: this.props.survey.id, data: data})
      .then((survey) => {
        this.setState({copying: false});
        history.push(appRoutes.survey.structure(survey.id));
      })
      .catch(e => {
        this.setState({
          copying: false
        });

        this.setState({
          errors: e.response.data.form.errors,
        });
      });
  }

  render() {
    const {survey, loading} = this.state;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} size="lg">
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="surveys.listItems.modalCopy.modalHeader" /> {survey ? `"${survey.name}"` : ''}</ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          {loading ? <LoadingSpinner /> : <FormSurvey errors={this.state.errors} submit={this.copySurvey} survey={survey} ref={this.form}/>}
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggleModal}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.copy">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda"
              loading={this.state.copying}
              data-style={ZOOM_OUT}
              onClick={(e) => this.form.current.onSubmit(e)}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    )
  }
}

SurveysModalCopy.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default connect(null, null, null, {forwardRef: true})(SurveysModalCopy);