import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Row, Col, Form, Input, Label} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import api from "api";
import {connect} from "react-redux";
import FormCollectorPanelUsersExcluded from "../../Account/Settings/components/FormCollectorPanelUsersExcluded";

class ProfileFormPasswordUpdate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {
        password: '',
        newPassword: {
          first: '',
          second: ''
        },
      },
      errors: {},
      avatarFile: null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onNewPasswordChange = this.onNewPasswordChange.bind(this);
  }

  onSubmit(e){
    e.preventDefault();
    this.setState({ loading: true });

    api.user.patch.userPassword(this.state.data)
      .then(() => {
        this.setState({ loading: false, errors: {} })
      })
      .catch(error => {
        this.setState({ loading: false, errors: error.response.data.form.errors });
      });
  }

  onChange(e){
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  }

  onNewPasswordChange(e){
    this.setState({
      data: {
        ...this.state.data,
        newPassword: {
          ...this.state.data.newPassword,
          [e.target.name]: e.target.value
        }
      }
    });
  }

  render() {
    const {data, errors, loading} = this.state;

    return <React.Fragment>
      <Form onSubmit={this.onSubmit} className="needs-validation">

        <Row>
          <Col md={3}>
            <Label data-role="label" for="password"><FormattedMessage id="profile.form.passwordUpdate.label.password" /></Label>
          </Col>
          <Col md={9}>
            <FormattedMessage id="profile.form.passwordUpdate.label.password">{
              (message) =>
                <Input type="password" id="password" name="password" className="custom" placeholder={message} value={data.password} invalid={!!errors.password} onChange={this.onChange}/>
            }</FormattedMessage>
            {errors.password && <div className="invalid-feedback">{errors.password.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Label data-role="label" for="newPassword.first"><FormattedMessage id="profile.form.passwordUpdate.label.newPassword.first" /></Label>
          </Col>
          <Col md={9}>
            <FormattedMessage id="profile.form.passwordUpdate.label.newPassword.first">{
              (message) =>
                <Input type="password" id="first" name="first" className="custom" placeholder={message} invalid={!!errors.newPassword} value={data.newPassword.first} onChange={this.onNewPasswordChange}/>
            }</FormattedMessage>
            {errors.newPassword && errors.newPassword.first && <div className="invalid-feedback">{errors.newPassword.first.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Label data-role="label" for="newPassword.second"><FormattedMessage id="profile.form.passwordUpdate.label.newPassword.second" /></Label>
          </Col>
          <Col md={9}>
            <FormattedMessage id="profile.form.passwordUpdate.label.newPassword.second">{
              (message) =>
                <Input type="password" id="second" name="second" className="custom" placeholder={message} invalid={!!errors.newPassword} value={data.newPassword.second} onChange={this.onNewPasswordChange}/>
            }</FormattedMessage>
            {errors.newPassword && errors.newPassword.second && <div className="invalid-feedback">{errors.newPassword.second.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormattedMessage id="_.button.save">
              {(message) => <LaddaButton
                className="btn btn-success btn-ladda float-right mb-0"
                loading={loading}
                data-style={ZOOM_OUT}
              >
                {message}
              </LaddaButton>}
            </FormattedMessage>
          </Col>
        </Row>
      </Form>
      {this.props.collectorPanelAccess && <div className="mt-3"><FormCollectorPanelUsersExcluded /></div>}
    </React.Fragment>
  }
}

function mapStateToProps(state) {
  return {
    collectorPanelAccess: state.user.userPlan.plan.survey.collectorPanelAccess,
  }
}

export default connect(mapStateToProps)(ProfileFormPasswordUpdate);