import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {
  Card,
  CardHeader,
  CardBody,
  CardImgOverlay,
  CardFooter,
  Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Alert
} from "reactstrap";
import LoadingSpinner from 'components/LoadingSpinner';
import api from 'api';
import HelpModeIconModal from "components/HelpModeIconModal";
import { connect } from 'react-redux';
import classnames from "classnames";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import {topSurveysCollectorAdded} from '../../Structure/data/survey/actions';
import CollectorsAvailable from "components/PlanRestrictionsModals/modals/CollectorsAvailable";
import CollectorsMaxAvailable from "components/PlanRestrictionsModals/modals/CollectorsMaxAvailable";
import SurveysBaseTermsCheckbox from "components/SurveysBaseTermsCheckbox";
import TopSurveysTermsCheckbox from "components/TopSurveysTermsCheckbox";
import appRoutes from "appRoutes";
import {Link} from "react-router-dom";
import moment from 'moment';
import { withRouter } from 'react-router-dom'
import CollectorPanelCreateFromTemplateButton from "./CollectorPanelCreateFromTemplateButton";
import history from "services/history";
import CollectorPanelCreateTextarea from "scenes/Survey/Collectors/components/CollectorPanelCreateTextarea";

class CollectorCreate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      regulationsAccepted: false,
      warningModal: false
    };

    this.isDisabled = this.isDisabled.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.createCollector = this.createCollector.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
    this.onClick = this.onClick.bind(this);
    this.toggleWarningModal = this.toggleWarningModal.bind(this);
  }

  isDisabled(){
    const {collector} = this.props;

    return (collector.type === 'BaseSurveys' || collector.type === 'TopSurveys') && !this.state.regulationsAccepted || (collector.type === 'BaseSurveys' && this.props.hasSurveyBaseCollector) || this.props.hasTopSurveysCollector;
  }

  renderButtons(){
    const {collector, collectorsTypeCount, surveyId} = this.props;

    let buttons = [];
    switch (collector.type) {
      case 'Panel': {
        if(this.props.surveyPlan.collectorPanelContactRequired){
          buttons.push(<a className="btn btn-primary btn-lg" href={'https://swresearch.pl/panel'} target={'_blank'}>Dowiedz się więcej i zamów</a>);

          return buttons
        }else{
          if(this.props.subType === 'Panel-own'){
            buttons.push(<Button color="primary" size="xs" onClick={this.onClick} disabled={this.isDisabled()}><i className="fas fa-plus" /></Button>);
            buttons.push(<CollectorPanelCreateTextarea
              surveyId={this.props.surveyId}
              onCreated={() => {
                history.push(appRoutes.survey.collectors.listType(this.props.surveyId, 'Panel'))
              }}
            />);
          }

          if(this.props.subType === 'Panel-template'){
            buttons.push(<CollectorPanelCreateFromTemplateButton surveyId={parseInt(surveyId)} />);
          }
        }
        break;
      }
      default: {
        buttons.push(<Button color="primary" size="xs" onClick={this.onClick} disabled={this.isDisabled()}><i className="fas fa-plus" /></Button>);
        break;
      }
    }

    buttons.push(<Link to={collectorsTypeCount === 0 ? '#' : appRoutes.survey.collectors.listType(this.props.surveyId, collector.type)} disabled className={classnames({
      'btn btn-primary w-75 pt-2': true,
      'disabled': collectorsTypeCount === 0
    })}>
      <i className="fas fa-search" /> Przeglądaj ({collectorsTypeCount})
    </Link>)

    return buttons;
  }

  createCollector(){
    const {surveyId, collector, history} = this.props;

    this.setState({
      creating: true
    });
    api.survey.post.collector({
      survey: surveyId,
      collector: {
        type: collector.type
      }
    }).then(data => {
      this.props.handleCreate();
      this.setState({creating: false});

      if (collector.type === 'TopSurveys') {
        this.props.topSurveysCollectorAdded();
      }

      history.push(appRoutes.survey.collectors.collector.view(surveyId, data.id))
    }).catch(() => {
      this.setState({
        creating: false
      })
    });
  }

  toggleChecked() {
    this.setState({
      regulationsAccepted: !this.state.regulationsAccepted
    })
  }

  toggleWarningModal() {
    this.setState({warningModal: !this.state.warningModal});
  }

  onClick() {
    const {surveyPlan, collectorsTotalCount} = this.props;
    const maxCollectorsReached = surveyPlan.collectorsMax > 0 && collectorsTotalCount >= surveyPlan.collectorsMax;

    if (maxCollectorsReached) {
      this.props.setPlanRestrictionsModals(CollectorsMaxAvailable.getName());
      return;
    }

    if ((this.props.collector.type === 'BaseSurveys' && !this.props.surveyPlan.baseSurveys) || (this.props.collector.type === 'TopSurveys' && !this.props.surveyPlan.topSurveys)) {
      this.props.setPlanRestrictionsModals(CollectorsAvailable.getName());
    } else {
      if (this.props.collector.type === 'TopSurveys') {
        this.toggleWarningModal();
      } else {
        this.createCollector();
      }
    }
  }

  render() {
    const {creating, regulationsAccepted} = this.state;
    const {collector, surveyPlan, collectorsTotalCount, collectorsTypeCount, surveyId} = this.props;
    const maxCollectorsReached = surveyPlan.collectorsMax > 0 && collectorsTotalCount >= surveyPlan.collectorsMax;
    let modal;

    if (collector.type === 'TopSurveys') {
      modal = <Modal toggle={this.toggleWarningModal} isOpen={this.state.warningModal} size="lg">
        <ModalHeader tag="h2" className="bg-danger" toggle={this.toggleWarningModal}><span className="text-white"><FormattedMessage id="collectors.warningModal.modalHeader" /></span></ModalHeader>
        <ModalBody>
          <p>
            <Alert color="danger">
              <div className="d-flex align-items-center">
                <i className="fas fa-exclamation-triangle fa-3x pr-3" />
                <div className="d-flex flex-column">
                  <FormattedMessage id="collectors.warningModal.modalBody">{msg => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>
                  <FormattedMessage id="collectors.warningModal.modalBody.hint">{msg => <span className="mt-2">{msg}</span>}</FormattedMessage>
                </div>
              </div>
            </Alert>
            <FormattedMessage tagName="p" id="collectors.warningModal.modalBody.warning"/>
            <FormattedMessage tagName="p" id="collectors.warningModal.modalBody.warning.desc"/>
            <ol className="mb-5" style={{listStyleType: 'decimal'}}>
              <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a1"/> ({moment().endOf('month').format("YYYY-MM-DD")})</li>
              <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a2"/> {moment().format("YYYY-MM-DD")}</li>
              <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a3"/> {moment().endOf('month').format("YYYY-MM-DD")}</li>
              <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a4"/></li>
              <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a5"/></li>
              <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a6"/></li>
              <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a7"/></li>
              <li><FormattedMessage id="collectors.warningModal.modalBody.warning.a8"/></li>
            </ol>
            <i className="fas fa-2x fa-trophy text-warning float-left mr-3" />
            <FormattedMessage id="collectors.warningModal.topSurveys.info"/>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => {this.toggleWarningModal(); this.createCollector()}}><FormattedMessage id="collectors.warningModal.button" /></Button>
          <Button color="secondary" onClick={this.toggleWarningModal}><FormattedMessage id="_.button.cancel" /></Button>
        </ModalFooter>
      </Modal>
    }

    return (
      <div className="float-left p-3 w-100 collectorCard" style={{height:275}}>
        <Card className={`h-100 border-l ${collector.color} w-100` + (maxCollectorsReached ? ' not-active' : '')}>
          <CardHeader>
            <h4>
              <FormattedMessage id={collector.title} />
              <HelpModeIconModal className="ml-2" modalHeader={<FormattedMessage id={collector.title} />}>
                <p><FormattedMessage id={collector.desc} /></p>
              </HelpModeIconModal>
            </h4>
          </CardHeader>
          <hr className="m-0"/>
          <CardBody>
            <p className="m-0"><FormattedMessage id={collector.desc} /></p>
          </CardBody>
          <CardFooter>
            {collector.type === 'BaseSurveys' && <SurveysBaseTermsCheckbox onChange={() => this.toggleChecked()} checked={regulationsAccepted} />}
            {collector.type === 'TopSurveys' && <TopSurveysTermsCheckbox onChange={() => this.toggleChecked()} checked={regulationsAccepted} />}

            <ButtonGroup className="w-100 d-flex">
              {this.renderButtons()}
            </ButtonGroup>
          </CardFooter>
          {creating
            ? <CardImgOverlay className="overlayer animated fadeIn"><LoadingSpinner absolute={true}/></CardImgOverlay>
            : ''
          }
        </Card>
        {modal}
      </div>
    )
  }
}

CollectorCreate.propTypes = {
  collector: PropTypes.shape({
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
  }).isRequired,
  surveyId: PropTypes.string.isRequired,
  handleCreate: PropTypes.func.isRequired,
  collectorsTotalCount: PropTypes.number.isRequired,
  collectorsTypeCount: PropTypes.number.isRequired,
  setPlanRestrictionsModals: PropTypes.func.isRequired,
  hasSurveyBaseCollector: PropTypes.bool.isRequired,
  hasTopSurveysCollector: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey,
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals, topSurveysCollectorAdded})(withRouter(CollectorCreate));
