import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Col, Input, FormGroup, Label, Tooltip} from "reactstrap";
import InputNumber from "../../../../../components/InputNumber";
import SurveyClientSelect from 'components/SurveyClientSelect';
import Logo from "./components/Logo";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import './FilterDate.css';
import Switch from "components/Switch";
import HelpModeIconTooltip from "components/HelpModeIconTooltip";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import SurveySettingAvailable from "components/PlanRestrictionsModals/modals/SurveySettingAvailable";
import SurveyTags from "../../../../../components/Survey/SurveyTags";
import SurveysCategorySelect from "../../../../../componentsNew/SurveysCategorySelect";
import {isEmpty} from "lodash";
import {loiToMinCPConverter} from "../../../../../services/loiConverter";
import api from "../../../../../api";

class Primary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      survey: {
        ...props.survey,
        name: props.survey.nameOryginal,
      },
      pauseEnableTooltip: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(property) {
    this.setState({
      [property]: !this.state[property]
    })
  }

  componentDidMount() {
    api.survey.get.loi(this.props.survey.id).then(data => {
      this.setState({
        lengthOfInterview: data.lengthOfInterview,
        loading: false,
      });
    });
  }


  onChangeProperty(name, value){
    let changes = {
      [name]: value,
    }
    if(name === 'endCashPoints'){
      changes.endCashPointsEmail = value;
    }
    if(name === 'endSocialPoints'){
      changes.endSocialPointsEmail = value;
    }

    this.setState({
      survey: {
        ...this.state.survey,
        ...changes
      }
    });

    this.props.onPropertyChange({
      ...changes
    });
  }

  renderCPHintText(loi) {
    const minCP = loiToMinCPConverter(loi);
    if (minCP === 1) {
      return "Sugerowana liczba CP to 1";
    }

    const maxCP = minCP + 1;

    return "Sugerowana liczba CP od " + minCP + " do " + maxCP;
  }

  render() {
    const {survey, lengthOfInterview} = this.state;
    const {errors, pointsPlan, isRespondent} = this.props;

    return (
      <React.Fragment>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Nazwa ankiety
            <HelpModeIconTooltip className="ml-2">Nazwa, która może być widoczna dla respondentów (w ankiecie, bazie ankiet etc).</HelpModeIconTooltip>
          </Label>
          <Col lg={9}>
            <Input invalid={!!errors.name} onChange={(e) => this.onChangeProperty('name', e.target.value)} value={survey.name} />
            {errors.name && <div className="invalid-feedback">{errors.name.join('; ')}</div>}
          </Col>
        </FormGroup>
        {this.props.surveyPlan.nameWork && <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Nazwa robocza ankiety
            <HelpModeIconTooltip className="ml-2">Nazwa wyświetlana na liście projektów, wizardzie etc. Nie jest widoczna dla respondentów.</HelpModeIconTooltip>
          </Label>
          <Col lg={9}>
            <Input invalid={!!errors.nameWork} onChange={(e) => this.onChangeProperty('nameWork', e.target.value)}
                   value={survey.nameWork}/>
            {errors.nameWork && <div className="invalid-feedback">{errors.nameWork.join('; ')}</div>}
          </Col>
        </FormGroup>}
        {!(!isEmpty(this.props.user.userGroups) && !this.props.user.userGroups[0].allowSurveyStart) && <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Aktywna
            <HelpModeIconTooltip className="ml-2">Określa czy ankietę można wypełniać</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch checked={!survey.paused} onChange={(paused) => {
              this.onChangeProperty('paused', !paused)
            }}/>
            {errors.paused && <div className="invalid-feedback d-block mb-2">{errors.paused.join('; ')}</div>}
          </Col>
        </FormGroup>}
        <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
            Maksymalna liczba wypełnień
            <HelpModeIconTooltip className="ml-2">Maksymalna liczba ile razy ankieta można być wypełniona</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-1">
            <InputNumber disabled={survey.hasTopSurveysCollector} min={1} max={999999} value={survey.responsesMax} onChange={(value) => this.onChangeProperty('responsesMax', value)}/>
            {errors.responsesMax && <div className="invalid-feedback d-block mb-2">{errors.responsesMax.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
            Tagi
            <HelpModeIconTooltip className="ml-2">Lista tagów ankiety</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-1 mb-2">
            <SurveyTags
              value={survey.tags.map(tag => tag.name)}
              onChange={tags => this.onChangeProperty('tags', tags)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (!this.props.plan.clients ? ' text-black-50' : '') }>
            Klient
            <HelpModeIconTooltip className="ml-2">Określa klienta do którego przypisana jest ankieta</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="mb-2">
            <SurveyClientSelect clientId={survey.clientId} changeHandler={(value) => this.onChangeProperty('client', value)}/>
            {errors.client && <div className="invalid-feedback">{errors.client.join('; ')}</div>}
          </Col>
        </FormGroup>
        {this.props.plan.surveysCategories && <FormGroup row>
          <Label lg={3} className={"text-lg-right"}>
            Sekcja
            <HelpModeIconTooltip className="ml-2">Określa sekcję, do której przypisana będzie ankieta. Ankietę można przypisać do jednej sekcji.</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="mb-2">
            <SurveysCategorySelect
              value={survey.surveysCategory ? survey.surveysCategory.id : null}
              onChange={surveysCategory => this.onChangeProperty('surveysCategory', {id: surveysCategory})}
            />
          </Col>
        </FormGroup>}
        {pointsPlan.allowedToChangeCashPoints && <React.Fragment>
          <FormGroup row>
            <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
              CashPoints
              <HelpModeIconTooltip className="ml-2">Liczba cash points jaką otrzyma respondent za wypełnienie ankiety</HelpModeIconTooltip>
            </Label>
            <Col lg={9} className="pt-1">
              <InputNumber disabled={survey.hasTopSurveysCollector} min={pointsPlan.cashMinPoints} max={pointsPlan.cashMaxPoints} value={survey.endCashPoints} onChange={(value) => this.onChangeProperty('endCashPoints', value)}/>
              {errors.endCashPoints && <div className="invalid-feedback">{errors.endCashPoints.join('; ')}</div>}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
              CashPoints e-mail
              <HelpModeIconTooltip className="ml-2">Liczba cash points jaką otrzyma respondent za wypełnienie ankiety z zaproszenia e-mail</HelpModeIconTooltip>
            </Label>
            <Col lg={9} className="pt-1">
              <InputNumber disabled={survey.hasTopSurveysCollector} min={pointsPlan.cashMinPoints} max={pointsPlan.cashMaxPoints} value={survey.endCashPointsEmail} onChange={(value) => this.onChangeProperty('endCashPointsEmail', value)}/>
              {errors.endCashPointsEmail && <div className="invalid-feedback">{errors.endCashPointsEmail.join('; ')}</div>}
            </Col>
          </FormGroup>
          {this.props.surveyPlan.allowCashPointsMax && <FormGroup row>
            <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
              CashPoints MAX
              <HelpModeIconTooltip className="ml-2">Maksymalna liczba cash points (Tylko wyświetlane)</HelpModeIconTooltip>
            </Label>
            <Col lg={9} className="pt-1">
              <InputNumber min={pointsPlan.cashMinPoints} max={999} value={survey.endCashPointsMax} onChange={(value) => this.onChangeProperty('endCashPointsMax', value)}/>
              {errors.endCashPointsMax && <div className="invalid-feedback">{errors.endCashPointsMax.join('; ')}</div>}
            </Col>
          </FormGroup>}
          <FormGroup row>
            <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
              LOI (min:sek)
              <HelpModeIconTooltip className="ml-2">W jaki sposób obliczamy długość ankiety w minutach (LOI)? Każdy rodzaj pytania ma uśrednioną długość wypełniania (od 20 do 60 sekund). Nie bierzemy pod uwagę długości kafeterii ani video. Nie uwzględniamy filtrów i losowań. Jeśli Twoja ankieta zawiera filtry lub losowania - przy określaniu  LOI i liczby CP stosuj odrębne obliczenia.</HelpModeIconTooltip>
            </Label>
            <Col lg={9} className="pt-1">
              <Input disabled={true} value={Math.floor(lengthOfInterview / 60)+':'+Math.floor(lengthOfInterview % 60)}/>
            </Col>
          </FormGroup>
          {survey.lengthOfInterview > 0 && <Label lg={{span: 9, offset: 3}} className={"text-lg-left text-red"}>
            {this.renderCPHintText(survey.lengthOfInterview)}
          </Label>}
        </React.Fragment>}
        {pointsPlan.allowedToChangeSocialPoints && <FormGroup row>
            <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
              SocialPoints
              <HelpModeIconTooltip className="ml-2">Liczba social points jaką otrzyma respondent za wypełnienie ankiety</HelpModeIconTooltip>
            </Label>
            <Col lg={9} className="pt-1">
              <InputNumber disabled={survey.hasTopSurveysCollector} min={pointsPlan.socialMinPoints} max={pointsPlan.socialMaxPoints} value={survey.endSocialPoints} onChange={(value) => this.onChangeProperty('endSocialPoints', value)}/>
              {errors.endSocialPoints && <div className="invalid-feedback">{errors.endSocialPoints.join('; ')}</div>}
            </Col>
          </FormGroup>}
          {pointsPlan.allowedToChangeCashPoints && <FormGroup row>
            <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
              SocialPoints e-mail
              <HelpModeIconTooltip className="ml-2">Liczba social points jaką otrzyma respondent za wypełnienie ankiety z zaproszenia e-mail</HelpModeIconTooltip>
            </Label>
            <Col lg={9} className="pt-1">
              <InputNumber disabled={survey.hasTopSurveysCollector} min={pointsPlan.socialMinPoints} max={pointsPlan.socialMaxPoints} value={survey.endSocialPointsEmail} onChange={(value) => this.onChangeProperty('endSocialPointsEmail', value)}/>
              {errors.endSocialPointsEmail && <div className="invalid-feedback">{errors.endSocialPointsEmail.join('; ')}</div>}
            </Col>
          </FormGroup>}
        {(pointsPlan.allowedToChangeCashScreenOutPoints && this.props.isGroupAdmin) && <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
            Screenout CashPoints
            <HelpModeIconTooltip className="ml-2">Liczba cash points jaką otrzyma respondent za wypełnienie ankiety w przypadku jej przerwania screenoutem</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-1">
            <InputNumber disabled={survey.hasTopSurveysCollector} min={pointsPlan.screenOutCashMinPoints} max={pointsPlan.screenOutCashMaxPoints} value={survey.screenOutCashPoints} onChange={(value) => this.onChangeProperty('screenOutCashPoints', value)}/>
            {errors.screenOutCashPoints && <div className="invalid-feedback">{errors.screenOutCashPoints.join('; ')}</div>}
          </Col>
        </FormGroup>}
        {pointsPlan.allowedToChangeSocialScreenOutPoints && <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
            Screenout SocialPoints
            <HelpModeIconTooltip className="ml-2">Liczba social points jaką otrzyma respondent za wypełnienie ankiety w przypadku jej przerwania screenoutem</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-1">
            <InputNumber disabled={survey.hasTopSurveysCollector} min={pointsPlan.screenOutSocialMinPoints} max={pointsPlan.screenOutSocialMaxPoints} value={survey.screenOutSocialPoints} onChange={(value) => this.onChangeProperty('screenOutSocialPoints', value)}/>
            {errors.screenOutSocialPoints && <div className="invalid-feedback">{errors.screenOutSocialPoints.join('; ')}</div>}
          </Col>
        </FormGroup>}
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Logo
            <HelpModeIconTooltip className="ml-2">Logo będzie wyświetlone podczas wypełniania ankiety</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-1">
            <Logo onChange={(logo) => this.onChangeProperty('logo', logo)} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
            Data rozpoczęcia
            <HelpModeIconTooltip className="ml-2">Data od której rozpoczyna się możliwość wypełnienia ankiety</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-1">
            <DatePicker
              selected={moment(survey.startAt)}
              onChange={(date) => this.onChangeProperty('startAt', date.format('YYYY-MM-DD'))}
              dateFormat="Y-MM-DD"
              locale={localStorage.language}
              dropdownMode="select"
              disabled={survey.hasTopSurveysCollector}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (survey.hasTopSurveysCollector ? " text-black-50" : "")}>
            Data zakończenia
            <HelpModeIconTooltip className="ml-2">Data do której trwa możliwość wypełnienia ankiety</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-1">
            <DatePicker
              selected={moment(survey.endAt)}
              onChange={(date) => this.onChangeProperty('endAt', date.format('YYYY-MM-DD'))}
              dateFormat="Y-MM-DD"
              locale={localStorage.language}
              dropdownMode="select"
              disabled={survey.hasTopSurveysCollector}
            />
            {errors.endAt && <div className="invalid-feedback d-block mb-2">{errors.endAt.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className={"text-lg-right" + (!this.props.surveyPlan.pause ? ' text-black-50' : '') }>
            Przerywanie ankiety
            <HelpModeIconTooltip className="ml-2">Niezalogowani użytkownicy mogą powrócić do wcześniej wypełnianej ankiety</HelpModeIconTooltip>
            <i id="survey-pauseEnable" className="far fa-question-circle ml-2"/>
            <Tooltip
              delay={0}
              placement="bottom"
              isOpen={this.state.pauseEnableTooltip}
              target={'survey-pauseEnable'}
              toggle={_ => this.toggle('pauseEnableTooltip')}
            >
              Ikona w nawigacji tylko dla niezalogowanych respondentów
            </Tooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch stickToProp checked={survey.pauseEnable} onChange={(pauseEnable) => {
              this.props.surveyPlan.pause ? this.onChangeProperty('pauseEnable', pauseEnable) : this.props.setPlanRestrictionsModals(SurveySettingAvailable.getName())
            }} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Kontakt z twórcą
            <HelpModeIconTooltip className="ml-2">Daje respondentowi możliwość wysłania maila do twórcy ankiety</HelpModeIconTooltip>
          </Label>
          <Col lg={9}  className="pt-2">
            <Switch checked={survey.contact} onChange={contact => {
              this.onChangeProperty('contact', contact)
            }} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Publikacja
            <HelpModeIconTooltip className="ml-2">Wyniki ankiety będą opublikowane na stronie swpanel.pl</HelpModeIconTooltip>
          </Label>
          <Col lg={9}  className="pt-2">
            <Switch checked={survey.published} onChange={published => {
              this.onChangeProperty('published', published)
            }} />
          </Col>
        </FormGroup>
        {isRespondent && <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Polecanie
            <HelpModeIconTooltip className="ml-2">Wyświetlaj ankietę do polecenia w profilu użytkowników swpanel.pl</HelpModeIconTooltip>
          </Label>
          <Col lg={9}  className="pt-2">
            <Switch checked={survey.recommending} onChange={recommending => {
              this.onChangeProperty('recommending', recommending)
            }} />
          </Col>
        </FormGroup>}
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Opis ankiety
            <HelpModeIconTooltip className="ml-2">Opis Twojej ankiety jest widoczny tylko dla Ciebie</HelpModeIconTooltip>
          </Label>
          <Col lg={9}>
            <Input type="textarea" rows={3} invalid={!!errors.description} onChange={(e) => this.onChangeProperty('description', e.target.value)} value={survey.description} />
            {errors.description && <div className="invalid-feedback">{errors.description.join('; ')}</div>}
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

Primary.propTypes = {
  onPropertyChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  survey: PropTypes.shape({
    name: PropTypes.string.isRequired,
    nameWork: PropTypes.string,
    responsesMax: PropTypes.number.isRequired,
    clientId: PropTypes.number,
    endCashPoints: PropTypes.number,
    endCashPointsEmail: PropTypes.number,
    endSocialPoints: PropTypes.number,
    endSocialPointsEmail: PropTypes.number,
    screenOutCashPoints: PropTypes.number,
    screenOutSocialPoints: PropTypes.number,
    lengthOfInterview: PropTypes.number,
    pauseEnable: PropTypes.bool,
    contact: PropTypes.bool,
    hasTopSurveysCollector: PropTypes.bool.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    pointsPlan: state.user.userPlan.plan.survey.points,
    surveyPlan: state.user.userPlan.plan.survey,
    plan: state.user.userPlan.plan,
    isRespondent: state.user.type === 'Respondent',
    isGroupAdmin: state.user.groups.length > 0 && parseInt(state.user.groups[0].groupOwner) === parseInt(state.user.id),
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(Primary);