import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import Select from "react-select";

class Places extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalPlaces: props.places,
    };

    this.select = React.createRef();
    this.toggleModalPlaces = this.toggleModalPlaces.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleModalPlaces(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  onChange(data) {
    this.select.current.setState({isOpen: true, menuIsOpen: true});

    const newPlaces = _.map(data, item => item.value);
    this.setState({modalPlaces: newPlaces}, () => {
      this.props.handlePlacesChanged(newPlaces);
    });
  }

  render() {
    const {places, placesCount, changeable} = this.props;
    const {modalOpen, modalPlaces} = this.state;
    const selectOptions = _.range(1, placesCount + 1).map(place => {
      return {
        label: place,
        value: place,
      }
    });

    if (!changeable) {
      return places.map(place => place).join(' | ')
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalPlaces} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalPlaces}>Wybierz miejsca</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-75 d-inline-block">
          <Select
            placeholder={"Wybierz miejsca"}
            ref={this.select}
            value={places.map(place => {
                return {
                  label: place,
                  value: place,
                }
              })
            }
            isOpen={false}
            isMulti={true}
            onChange={this.onChange}
            options={selectOptions}
          />
        </div>
        <div className="w-25 d-inline-block pl-3">
          Wybranych miejsc: {modalPlaces.length}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handlePlacesChanged(modalPlaces);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={!_.isEmpty(places) ? 'secondary' : 'warning'} className="mx-1 pointer" onClick={this.toggleModalPlaces}>{!_.isEmpty(places) ? places.map(place => place).join(' | ') : 'Wybierz miejsca...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

Places.defaultProps = {
  changeable: true
};

Places.propTypes = {
  questionId: PropTypes.number.isRequired,
  places: PropTypes.array.isRequired,
  handlePlacesChanged: PropTypes.func,
  changeable: PropTypes.bool
};

function mapStateToProps(state, props) {
  return {
    placesCount: state.survey.structure.data.questions[props.questionId].places
  }
}

export default connect(mapStateToProps)(Places);