import update from 'immutability-helper';

import { SET, ADDED, UPDATED, DELETED } from './actions';
import * as questionsActions from "../questions/actions";

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.columns;

    case ADDED:
      return {
        ...state,
        [action.column.id]: action.column
      };

    case UPDATED:
      return update(state, {
        [action.column.id]: {
          $set: action.column
        }
      });
    case DELETED:
      return update(state, {
        $unset: [action.column.id]
      });

    case questionsActions.TYPE_UPDATED:
      const ids = [];

      _.each(state, column => {
        if (column.question.id === action.expiredId)
          ids.push(column.id);
      });

      return update(state, {
        $unset: ids
      });

    default:
      return state;
  }
};