import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import api from "../../../../../../api";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";

class SurveyResponseStatus extends Component {

  constructor(props) {
    super(props);

    this.state = {
      status: props.surveyResponse.status,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  getOptions(){
    return [
      {
        value: 1,
        label: 'Ekran zaproszenia'
      },
      {
        value: 2,
        label: 'Przerwana'
      },
      {
        value: 3,
        label: 'Zakończona'
      },
      {
        value: 4,
        label: 'Screenout'
      },
      {
        value: 5,
        label: 'Kwota'
      },
    ];
  }

  handleChange(option){
    const {surveyResponse} = this.props;

    this.setState({
      status: option.value
    }, () => {
      api.surveyResponse.put.status(surveyResponse.id, this.state.status)
      .then(data => {
        toast.success("Status został zmieniony");
      })
      .catch(res => {});
    })
  }

  render() {
    const {status} = this.state;

    const options = this.getOptions();

    const value = options.find(option => option.value === status);

    return <Select
        className="w-100 ml-2"
        options={options}
        value={value}
        onChange={this.handleChange}
      />
  }
}

SurveyResponseStatus.propTypes = {
  surveyResponse: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
};

export default SurveyResponseStatus;