import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import InputRange from "react-input-range";
import classnames from "classnames";


class SliderValue extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.ownStyles[this.props.keyName] ? (props.percentage ? props.ownStyles[this.props.keyName] * 100 : props.ownStyles[this.props.keyName]) : false,
      valueNew: false,
    };

    this.getDefaultValue = this.getDefaultValue.bind(this);
  }

  getDefaultValue(){
    return parseInt(this.props.percentage ? this.props.ownStylesDefault[this.props.keyName] * 100 : this.props.ownStylesDefault[this.props.keyName]);
  }

  updateValue(value){
    this.setState({
      valueNew: false,
      value
    });

    this.props.handlerUpdate({[this.props.keyName]: this.props.percentage ? parseInt(value) / 100 : value});
  }

  render() {
    const {valueNew, value} = this.state;

    const inactive = !valueNew && [false, ''].indexOf(value) >= 0;

    return (
      <Row className={classnames({
        'py-1 px-3 mb-2 align-items-center': true,
        'inactive': inactive,
      })}>
        <label className="col col-4 d-flex align-items-center"><span>{this.props.label}</span> {!inactive && <i className="icon-clear-value fas fa-trash ml-2" onClick={() => this.updateValue('')} />}</label>
        <Col className="col-8">
          <InputRange
            minValue={this.props.minValue}
            maxValue={this.props.maxValue}
            step={this.props.stepValue}
            formatLabel={value => value + this.props.measure}
            value={Number.isInteger(valueNew) ? valueNew : (value ? parseInt(value) : this.getDefaultValue())}
            onChangeStart={valueNew => this.setState({ valueNew })}
            onChange={valueNew => this.setState({ valueNew })}
            onChangeComplete={value => {
              this.updateValue(value + this.props.measure)
            }}
          />
        </Col>
      </Row>
    );
  }
}

SliderValue.defaultProps = {
  measure: 'px',
  percentage: false
};

SliderValue.propTypes = {
  measure: PropTypes.string.isRequired,
  handlerUpdate: PropTypes.func.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  stepValue: PropTypes.number.isRequired,
  keyName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  percentage: PropTypes.bool.isRequired,
};

export default SliderValue;