import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, FormGroup, Input, Label} from "reactstrap";
import striptags from "striptags";
import QuestionTagSelect from "../../../QuestionTagSelect";
import api from "api";
import {omit} from "lodash";


class QuestionTemplateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        id: props.template.id,
        description: props.template.description,
        tags: props.template.tags,
      }
    };
  }

  save(){
    const {data} = this.state;

    this.props.onSaveStart();

    if(this.props.questionId){
      api.user.questionTemplates.post(this.props.questionId, omit(data, ['id']))
      .then(data => {
        this.props.onSaved(data.question);
      })
      .catch(res => {

      });
    }else{
      api.user.questionTemplates.patch(this.props.template.id, omit(data, ['id']))
      .then(data => {
        this.props.onSaved(data.question);
      })
      .catch(res => {

      });
    }


  }

  render() {
    const {data} = this.state;

    return (
      <div>
        <div className="pb-5" dangerouslySetInnerHTML={{__html: striptags(this.props.template.content)}} />
        <FormGroup row>
          <Label md={4}>Opis</Label>
          <Col md={8}>
            <Input type="textarea" value={data.description} onChange={e => this.setState({data: {...data, description: e.target.value}})} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={4}>Tagi</Label>
          <Col md={8}>
            <QuestionTagSelect
              value={data.tags}
              onChange={tags => this.setState({data: {...data, tags: tags.map(tag => tag.value)}})}
            />
          </Col>
        </FormGroup>
      </div>
    );
  }
}

QuestionTemplateForm.defaultProps = {
  template: {
    description: '',
    tags: [],
  }
};

QuestionTemplateForm.propTypes = {
  onSaveStart: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  questionId: PropTypes.number,
  template: PropTypes.shape({
    id: PropTypes.number,
    content: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default QuestionTemplateForm;