import React from 'react';
import PropTypes from 'prop-types';
import SurveysButtonPaused from "../../../scenes/Surveys/components/SurveysButtonPaused";
import {FormattedMessage} from "react-intl";

const SurveyStatus = ({survey}) => {
  const [paused, setPaused] = React.useState(survey.paused);

  return (
    <div className="d-flex w-100 align-items-center justify-content-center">
      <SurveysButtonPaused paused={!!paused} survey={survey} handleSurveyChange={(surveyId, prop, value) => setPaused(value)}/>
      <label className={`label-status ${paused ? 'danger' : 'success'} ml-2 mb-0`}>
        {paused ? <FormattedMessage id="_.label.paused"/> : <FormattedMessage id="_.label.active"/>}
      </label>
    </div>
  );
};

SurveyStatus.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default SurveyStatus;