import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap';
import Select from 'react-select';
import api from 'api';
import LoadingSpinner from "components/LoadingSpinner";
import CompaniesStatsData from './CompaniesStatsData';

class CompaniesStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      year: null,
      month: null,
      data: null
    };

    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidMount() {
    api.user.get.companiesStats(this.state.year, this.state.month).then(data => {
      this.setState({loading: false, data});
    });
  }

  onFilterChange(e, type) {
    this.setState({loading: true, [type]: e ? e.value : null}, () => {
      api.user.get.companiesStats(this.state.year, this.state.month).then(data => {
        this.setState({loading: false, data});
      });
    })
  }

  render() {
    const months = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];
    const monthOptions = _.map(months, (month, i)=> { return {label: month, value: ++i}});

    const yearsOptions= [];
    for (let i = new Date().getFullYear(); i >= 2011; i--) {
      yearsOptions.push({label: i, value: i});
    }

    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">
                    <FormattedMessage id="admin.companiesStats.header" />
                  </h2>
                </div>
                <div className="w-100 w-lg-25 float-right">
                  <Select
                    placeholder="Dowolny miesiąc"
                    value={_.find(monthOptions, month => month.value === this.state.month)}
                    onChange={(e) => this.onFilterChange(e, 'month')}
                    options={monthOptions}
                    isClearable
                  />
                </div>
                <div className="w-100 w-lg-25 float-right">
                  <Select
                    placeholder="Dowolny rok"
                    value={_.find(yearsOptions, year => year.value === this.state.year)}
                    onChange={(e) => this.onFilterChange(e, 'year')}
                    options={yearsOptions}
                    isClearable
                  />
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Row>
                <Col>
                  {this.state.loading
                    ? <LoadingSpinner />
                    : <CompaniesStatsData data={this.state.data} />
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default CompaniesStats;