import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from "reactstrap";
import Checkbox from 'components/Checkbox';
import {FormattedMessage} from "react-intl";

class SurveysBaseTermsCheckbox extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  render() {
    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')} size="xl">
      <ModalHeader toggle={() => this.toggle('modal')} tag="h2">Zasady publikacji ankiet w serwisach swpanel.pl</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <ol className="mb-5" style={{listStyleType: 'upper-roman'}}>
          <li className="mb-3">Ogólnie o publikowaniu i umieszczaniu ankiet w Bazach Ankiet swpanel.pl</li>

          <span> Bazy ankiet to miejsca, w którym możesz umieścić utworzoną w systemie Ankieteo ankietę i realizować wywiady na zarejestrowanych panelistach, którzy z niej korzystają.<br /><br />
            Umieszczanie ankiet w bazie ankiet jest bezpłatne.<br /><br />
            Liczba możliwych zgłoszonych ankiet zależy od posiadanego przez Ciebie planu i odpowiada maksymalnej liczbie aktywnych projektów.<br /><br />
            Publikowane ankiety są dostępne wszystkim zarejestrowanym panelistom, którzy logują się do bazy ankiet. Nie można wybrać zawężonej grupy, do której skierujemy ankietę. Możliwość określania targetu jest dodatkowo płatna. W przypadku zainteresowania wyślij zapytanie na adres: sw@swresearch.pl<br />
          </span>
          <li className="my-3">Zgłaszanie ankiet do bazy ankiet</li>
          <ol style={{listStyleType: 'decimal'}}>
            <li>Nowo utworzona ankieta <b>nie jest domyślnie</b> ustawiona ze statusem umieszczenia jej w bazie ankiet. </li>
            <li>Aby umieścić uprzednio przygotowaną ankietę w bazie ankiet należy skorzystać z kolektora <b>Baza ankiet</b> (widok poniżej) lub skorzystać z szybkiego tworzenia z poziomu listy projektów (niebieska ikona z lewej strony przy projekcie)</li>

            <img className="my-3" src="/dashboard/img/surveysBaseterms/4.png" style={{maxWidth: '90%'}}/>

            <li>W ramach edycji tego kolektora, można określić maksymalną liczbę wywiadów pozyskaną z bazy ankiet</li>
            <li>Alternatywnie można skorzystać z szybkiej opcji umieszczania ankiety w bazie ankiet, znajdującej się na liście projektów:</li>

            <img className="my-3" src="/dashboard/img/surveysBaseterms/3.png" style={{maxWidth: '90%'}}/>

            <li>W obu przypadkach wymagana jest akceptacja regulaminu umieszczania ankiet w bazie ankiet.</li>

            <img className="my-3" src="/dashboard/img/surveysBaseterms/2.png" style={{maxWidth: '90%'}}/>

            <li>Musi również zostać spełniony dodatkowy warunek: Ankieta musi mieć nadany status: „Aktywny” i datę zakończenia w przyszłości.</li>
            <li>Ankieta z wymienionymi wyżej ustawieniami jest przekazywana do moderacji. W innym wypadku, ankieta nie jest przekazana do moderacji</li>
          </ol>
          <li className="my-3">Moderacja ankiet</li>
          Każda ankieta przeznaczona do moderacji musi spełniać poniższe warunki:
          <ol style={{listStyleType: 'decimal'}}>
            <li>Poprawne ustawienie statusu ankiety: Projekt musi być aktywny (status zielony). Jeśli nie Wiesz czy ankieta jest aktywna - kliknij na jej link i wypełnij ją bez logowania. Ankieta musi mieć aktywny kolektor: Baza Ankiet. Administrator sprawdza TYLKO ankiety, które są przeznaczone przez twórcę ankiety do umieszczenia w bazie ankiet.</li>
            <li>Ankieta musi być możliwa do wypełnienia: ustawienia pytań, filtrów muszą umożliwiać jej wypełnienie w całości, aż do ekranu podziękowania za jej wypełnienie. Przykład: odrzucona zostanie ankieta, która wymusza wybranie wszystkich odpowiedzi w pytaniu wielokrotnego wyboru.</li>
            <li>Ankieta nie może zawierać wyraźnych wizualnych błędów (wynikających bądź z błędu systemu bądź z błędu ustawień). W przypadku błędów systemu moderator może skorygować ustawienia wizualne ankiety bez konsultacji z twórcą ankiety lub odrzucić ankietę z informacją o przyczynie odrzucenia</li>
            <li>Ankieta nie może zawierać screenoutu lub innych warunków technicznie nie kwalifikujących respondentów do ankiety. W treści lub w tytule ankiety można zawrzeć informację, do kogo jest kierowana ankieta. </li>
            <li>Ankieta nie może zawierać rażących błędów ortograficznych lub składniowych oraz pustych pytań, szczególnie: Uzupełnij treść pytania, Uzupełnij treść odpowiedzi, Dodaj odpowiedź</li>
            <li>Nazwa ankiety nie może składać się wyłącznie ze słów: Ankieta, test, temat ankiety, temat badania, badanie, itp. Powinna nawiązywać do tematu ankiety np. Kwiaty polskie. </li>
            <li>Ankieta nie może naruszać praw osób trzecich, w tym naruszać praw autorskich i dobrych obyczajów. Dotyczy to szczególnie wykorzystywanych w ankietach multimediów. </li>
            <li>Ankieta nie może dotyczyć tematyki serwisów konkurencyjnych tj. podobnych do swpanel.pl, ankieteo.pl </li>
            <li>Ankieta nie może zawierać widocznych lub niewidocznych np. ukrytych pod grafiką linków kierujących do serwisów konkurencyjnych tj. podobnych do swpanel.pl, ankieteo.pl</li>
            <li>Ankieta nie może bezpośrednio reklamować produkty lub usługi i/lub zachęcać do kupna lub sprzedaży</li>
            <li>Ankieta nie może przekierowywać respondentów na zewnętrzne strony URL</li>
            <li>Ankieta musi zawierać co najmniej jedno pytanie nie będące pytaniem metryczkowym/ pytaniem o socjodemografię tj. pytania o wiek, płeć, dochód, miejsce zamieszkania, województwo, liczbę dzieci. </li>
            <li>Dopuszcza się umieszczenie maksymalnie 3 (trzech) identycznych kopii jednej ankiety z zachowaniem minimum 30 dniowej karencji, liczonej od dnia zakończenia ankiety. Czyli, jeśli chcesz skopiować swoją ankietę, możesz ją umieścić w bazie ankiet dopiero po 30 dniach. Tę czynność możesz powtórzyć tylko dwukrotnie. </li>
            <li>Ankieta nie może zawierać pytań naprowadzających na odpowiedzi, sugerujących właściwą odpowiedź zarówno w treści, jak i w liście odpowiedzi np. Czy Psy są fajniejsze od kotów: Tak,  NIE, oczywiście, że NIE są</li>
            <li>Ankieta nie powinna zawierać pytań zbytnio nacechowanych emocjonalnie np. Czy wiesz,  że partia X oszukuje Polaków? Tak, Nie, Nie wiem.  Prosimy o unikanie takich sformułowań.  </li>
            <li>Ankieta nie może zawierać pytań, które nakłaniają do przekazania jakichkolwiek danych osobowych np. email, adres, nr telefonu.</li>
            <li className="mb-4">Ankieta nie może służyć do rekrutacji do badania rynku z wyjątkiem badań realizowanych przez  agencję SW Research sp. z o.o. lub podmiot od niej zależny.</li>
            <small className="text-muted">Prosimy o <u>testowanie ankiet</u> przed przekazaniem ich do moderacji,<br />
              Moderacja trwa <span className="text-dark">do ok 6 h roboczych</span>.</small>
          </ol>
          <li className="my-3">Odrzucenie, poprawianie ankiety i ponowna moderacja</li>
          <ol style={{listStyleType: 'decimal'}}>
            <li>W przypadku odrzucenia ankiety, twórca ankiety jest informowany o tym fakcie za pomocą ikony (IKONA), która znajduje się przy projekcie na liście projektów.</li>
            <li>Ankietę można poprawić i ponownie zgłosić ją do moderacji </li>
          </ol>
          <li className="my-3">Banowanie ankiet</li>
          Moderator może zabanować ankietę/uniemożliwić ponowną próbę publikacji ankiety, która:
          <ol className="mt-2" style={{listStyleType: 'decimal'}}>
            <li>Nie zawiera ani jednego poprawnie sformułowanego pytania</li>
            <li>Atakuje, grozi, narusza dobre imię moderatora, Ankieteo, swpanel lub innych użytkowników </li>
            <li>Zawiera liczne wulgaryzmy </li>
            <li>Pomimo <b>dwukrotnej prośby</b>, twórca nie poprawił ankiety względem sugestii moderatora </li>
          </ol>
          <li className="my-3">Ocenianie ankiet (dotyczy kont powiązanych z kontami w swpanel.pl)</li>
          <ol className="mt-2" style={{listStyleType: 'decimal'}}>
            <li>Można przeznaczyć swoją ankietę do oceny przez panelistów. Ocenianie ankiety odbywa się na ekranie podziękowania po jej zakończeniu.</li>
            <li>Każdy panelista może ocenić ankietę tylko jeden raz. </li>
            <li>Ocena odbywa się na skali 1-10, gdzie 1 to najgorsza a 10 najlepsza ocena Twojej ankiety.</li>
            <li>Dopisanie punktów za średnią ocenę ankiety od respondentów następuje, gdy min. 50 respondentów ją oceni. </li>
          </ol>
          <li className="my-3">Dodatkowy punkt za maksymalne wypełnienie Twojej ankiety (dotyczy kont powiązanych z kontami w swpanel.pl)</li>
          <ol className="mt-2" style={{listStyleType: 'decimal'}}>
            <li>Jeśli Twoją ankietę wypełni maksymalna liczba respondentów, otrzymasz dodatkowy SocialPoint.</li>
            <li>Maksymalna liczba wywiadów zależy od Twojego planu. Informację o tym ile osób musi maksymalnie wypełnić Twoją ankietę znajdziesz w Liście Projektów.</li>
          </ol>
          <li className="my-3">Otrzymywanie punktów za wypełnienie ankiet z bazy ankiet przez respondentów</li>
          <small>
            Ankiety umieszczone w bazie ankiet są punktowane dla respondentów zależnie od posiadanego przez twórcę ankiety planu.<br />
            <span className="text-muted">Jeśli posiadasz pakiet Free - Twoi respondenci otrzymają 1 SocialPoint; Basic = 3 SP, Premium = 5 SP, Biznes = 7 SP, Enterprise (plan indywidualny) = 10 SP. Punkty te są wyświetlane przy każdym projekcie umieszczonym w bazie ankiet i w profilu respondenta. Pamiętaj – im więcej punktów za Twoją ankietę otrzyma respondent, tym chętniej ją wypełni. </span>
          </small>
          <li className="my-3">Zalety korzystania z bazy ankiet:</li>
          <ol className="my-2" style={{listStyleType: 'decimal'}}>
            <li>Wysoka dostępność panelistów (marzec 2019 – ok 230 tys. sesji miesięcznie):</li>

            <img className="my-3" src="/dashboard/img/surveysBaseterms/1.png" style={{maxWidth: '90%'}}/>

            <li>wywiady są wykonywane na panelistach, a nie nieokreślonych internautach, jak w przypadku badań online typu Real Time Sampling (RTS)</li>
            <li>można publikować wyniki z badań i dzielić się nimi z innymi użytkownikami</li>
            <li>nie trzeba zadawać pytań metryczkowych, gdyż dane z panelu można połączyć z danymi z badania</li>
          </ol>
          Zobacz jak wygląda baza ankiet: <a href="http://www.swpanel.pl/baza-ankiet" target="_blank">http://www.swpanel.pl/baza-ankiet</a>
          <li className="my-3">Wyłączenie odpowiedzialności</li>
          <ol className="my-2" style={{listStyleType: 'decimal'}}>
            <li>Serwis nie ponosi odpowiedzialności za jakość odpowiedzi udzielanych przez respondentów</li>
            <li>Serwis nie ponosi odpowiedzialności za wszelkie ewentualne roszczenia dotyczące praw autorskich do materiałów zamieszczonych w ankiecie. Wszelkie roszczenia od osób trzecich będą bezpośrednio kierowane do twórcy ankiety</li>
            <li>Serwis nie ponosi odpowiedzialności za treść ankiet. Staramy się nie dopuszczać ankiet nie spełniających kryteriów opisanych w rozdziale III. Moderacja ankiet</li>
            <li>Serwis nie ponosi odpowiedzialności za decyzje podjęte na podstawie wyników badań</li>
          </ol>
        </ol>

        Zasady aktualne na dzień: 16.04.2020
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.close">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggle('modal')}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Checkbox
          checked={this.props.checked}
          id={'collector-regulations-check'}
          onClick={this.props.onChange}
          text={<span>Akceptuję <a href="#" className={this.props.checked ? "" : "text-danger"} onClick={() => this.toggle('modal')}>regulamin</a> umieszczania ankiet w bazie</span>}
          tooltip={false}
        />
        {modal}
      </React.Fragment>
    )
  }
}

SurveysBaseTermsCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};

export default SurveysBaseTermsCheckbox;
