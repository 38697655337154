import React from 'react';

const QuestionInfo = ({question, style}) => {

  const renderIcon = (question) => {
    switch (question.type) {
      case 'single':
        if (question.presentationType === 'scale' || (question.hasOwnProperty('options') && question.options.presentationType === 'scale')) {
          return <i style={style} className="fas fa-ellipsis-h" />;
        }
        return <i style={style} className="fas fa-circle" />;
      case 'multi':
        if (question.presentationType === 'heatMap' || (question.hasOwnProperty('options') && question.options.presentationType === 'heatMap')) {
          return <i style={style} className="fas fa-marker" />;
        }
        return <i style={style} className="fas fa-check" />;
      case 'ranked':
        return <i style={style} className="fas fa-signal" />;
      case 'open':
        if (question.presentationType === 'email' || (question.hasOwnProperty('options') && question.options.presentationType === 'email')) {
          return <i style={style} className="fas fa-at " />;
        }
        if (question.presentationType === 'phone' || (question.hasOwnProperty('options') && question.options.presentationType === 'phone')) {
          return <i style={style} className="fas fa-phone" />;
        }
        if (question.presentationType === 'library' || (question.hasOwnProperty('options') && question.options.presentationType === 'library')) {
          return <i style={style} className="fas fa-search-location " />;
        }

        return <i style={style} className="fas fa-keyboard" />;
      case 'multiOpen':
        if (question.presentationType === 'datetime' || (question.hasOwnProperty('options') && question.options.presentationType === 'datetime')) {
          return <i style={style} className="fas fa-calendar-days" />;
        }

        return <i style={style} className="fas fa-bars" />;
      case 'numeric':
        if (question.presentationType === 'input' || (question.hasOwnProperty('options') && question.options.presentationType === 'input')) {
          return <i style={style} className="fas fa-sort-numeric-up " />;
        }
        if (question.presentationType === 'slider' || (question.hasOwnProperty('options') && question.options.presentationType === 'slider')) {
          return <i style={style} className="fas fa-minus" />;
        }
        return <i style={style} className="fas fa-sort-numeric-up " />;
      case 'numericMulti':
        if (question.presentationType === 'input' || (question.hasOwnProperty('options') && question.options.presentationType === 'input')) {
          return <i style={style} className="fas fa-sort-numeric-down " />;
        }
        if (question.presentationType === 'slider' || (question.hasOwnProperty('options') && question.options.presentationType === 'slider')) {
          return <i style={style} className="fas fa-sliders-h" />;
        }
        if (question.presentationType === 'dragAndDrop' || (question.hasOwnProperty('options') && question.options.presentationType === 'dragAndDrop')) {
          return <i style={style} className="fas fa-object-group" />;
        }
        if (question.presentationType === 'psm' || (question.hasOwnProperty('options') && question.options.presentationType === 'psm')) {
          return <i style={style} className="fas fa-money-bill-alt" />;
        }
        return <i style={style} className="fas fa-sort-numeric-down " />;
      case 'matrix':
        if (question.presentationType === 'table' || (question.hasOwnProperty('options') && question.options.presentationType === 'table')) {
          return <i style={style} className="fas fa-table" />;
        }
        if (question.presentationType === 'carousel' || (question.hasOwnProperty('options') && question.options.presentationType === 'carousel')) {
          return <i style={style} className="fas fa-check" />;
        }
        return <i style={style} className="fas fa-table" />;
      case 'matrixMulti':
        if (question.presentationType === 'table' || (question.hasOwnProperty('options') && question.options.presentationType === 'table')) {
          return <i style={style} className="fas fa-th" />;
        }
        if (question.presentationType === 'carousel' || (question.hasOwnProperty('options') && question.options.presentationType === 'carousel')) {
          return <i style={style} className="fas fa-retweet" />;
        }
        return <i style={style} className="fas fa-th" />;
      case 'matrixDropdown':
        return <i style={style} className="fas fa-caret-square-down" />;
      case 'video':
        return <i style={style} className="fas fa-film" />;
      case 'basket':
        return <i style={style} className="fas fa-shopping-basket" />;
      case 'gMap':
        return <i style={style} className="fas fa-map-marked-alt" />;
      case 'nps':
        return <i style={style} className="fas fa-smile" />;
      case 'maxDiff':
        return <i style={style} className="fas fa-dice-six" />;
      case 'differential':
        return <i style={style} className="fas fa-tasks" />;
      case 'comment':
        return <i style={style} className="fas fa-comment" />;
      case 'bpto':
        return <i style={style} className="fas fa-credit-card" />;
      case 'cbc':
        return <i style={style} className="fas fa-credit-card" />;
      case 'conjoint':
        return <i style={style} className="fas fa-credit-card" />;
      default:
        console.log(`There is no specified icon for this ${question.type} question type. Please add proper font-awesome icon inside QuestionInfo component.`);
        return '';
    }
  };

  return renderIcon(question);
};

export default QuestionInfo;
