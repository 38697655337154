import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import classnames from "classnames";
import Select from "react-select";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";


class ColumnThead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: false,
      filterExcluding: false,
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterExcludingChange = this.onFilterExcludingChange.bind(this);
  }

  static getColSpan(question){
    return 1 + (question.excludings.length > 0 ? 1 : 0);
  }

  componentWillUnmount() {
    const {question} = this.props;

    this.props.onFilterChange('q' + question.id, false);
    this.props.onFilterChange('q' + question.id + 'excl', false);
  }

  onFilterChange(option){
    const {question} = this.props;

    this.setState({
      filter: option === null ? false : option.value,
    }, () => {
      const key = 'q' + question.id;

      this.props.onFilterChange(key, this.state.filter === false ? false : {
          question: question.id,
          answers: this.state.filter,
        }
      );
    });
  }

  onFilterExcludingChange(value){
    const {question} = this.props;

    if(value.length === 0){
      value = false;
    }

    this.setState({
      filterExcluding: value
    }, () => {
      const key = 'q' + question.id + 'excl';

      this.props.onFilterChange(key, this.state.filterExcluding === false ? false : {
          question: question.id,
          excludings: this.state.filterExcluding,
        }
      );
    });
  }

  render() {
    const {filter, filterExcluding} = this.state;
    const {question} = this.props;

    const options = [
      {
        label: 'Uzupełnił',
        value: 1,
      },
      {
        label: 'Nie uzupełnił',
        value: 0,
      },
    ];

    return <React.Fragment>
      <th
        className="text-center pointer border-left border-right"
        onClick={() => filter === false && this.setState({filter: null}) }
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filter !== false,
          'align-items-center': filter === false,
        })}>
          <div className="w-100">Wpisana odpowiedź</div>
          {filter !== false && <Select
            value={options.find(option => option.value === filter)}
            isClearable={true}
            options={options}
            onChange={option => this.onFilterChange(option)}
          />}
        </div>
      </th>
      {question.excludings.length > 0 && <th
        className="text-center border-left border-right pointer"
        onClick={() => filterExcluding === false && this.setState({filterExcluding: null})}
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filterExcluding !== false,
          'align-items-center': filterExcluding === false,
        })}>
          <div className="w-100">Wykluczająca</div>
          {filterExcluding !== false && <SelectExcludings
            isClearable={true}
            isMulti={true}
            value={filterExcluding}
            questionId={question.id}
            onChange={this.onFilterExcludingChange}
          />}
        </div>
      </th>}
    </React.Fragment>;
  }
}

ColumnThead.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['open']),
    excludings: PropTypes.array,
  }).isRequired,
};

export default ColumnThead;