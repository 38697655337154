import React, {Component} from 'react';
import PropTypes from "prop-types";
import striptags from 'striptags';

class Ranked extends Component {

  render() {
    const {details, question} = this.props;

    return (
      <ol>
        {details.map((detail, i) => {
          return <li key={i}>
            {question.answersImage ? <img src={detail.answer} className="img-100"/> : <span dangerouslySetInnerHTML={{__html: detail.answer}} className="d-inline-block img-30"/>}
            {'content' in detail && ' - ' + striptags(detail.content)}: Miejsce {detail.place}
          </li>
        })}
      </ol>
    )
  }
}

Ranked.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.string.isRequired,
    place: PropTypes.number.isRequired,
    content: PropTypes.string,
  }),).isRequired,
  question: PropTypes.shape({
    answersImage: PropTypes.bool.isRequired
  }).isRequired
};

export default Ranked;