import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import api from "api";
import {ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";
import {toast} from "react-toastify";

class AddTokens extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      saving: false,
      number: 0
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e){
    this.setState({
      number: e.target.value
    });
  }

  onSubmit(e){
    e.preventDefault();
    const {number} = this.state;

    this.setState({
      saving: true
    });

    api.surveyCollector.tokens.post.tokens({
      id: this.props.collector.id,
      data: {
        number,
      },
    }).then(tokens => {
      this.setState({saving: false, isOpen: false});
      this.props.handleAddTokens(tokens);
    }).catch((error) => {
      _.map(error.response.data.errors, err => {
        toast.error(err.message);
      });

      this.setState({
        saving: false
      });
    });
  }

  render() {
    const {isOpen, saving, number} = this.state;

    const modal = <Modal isOpen={isOpen} toggle={() => this.setState({isOpen: false})}>
      <ModalHeader toggle={() => this.setState({isOpen: false})} tag="h2"><FormattedMessage id="surveyCollector.typeTokens.token.addTokens.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Form className="my-3" onSubmit={this.onSubmit}>
          <FormGroup row>
            <Label lg={3} className="text-lg-right"><FormattedMessage id="surveyCollector.typeTokens.token.addTokens.form.number" /></Label>
            <Col lg={9} className="pt-1">
              <Input type="number" name="number" id="number" value={number} size="sm" onChange={this.onChange}/>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
       <LaddaButton
        loading={saving}
        className="btn btn-primary btn-ladda"
        data-style={ZOOM_OUT}
        disabled={number < 1}
        onClick={this.onSubmit}
       >
        <i className="fas fa-plus" /> <FormattedMessage id="_.button.add"/>
      </LaddaButton>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.setState({isOpen: false})}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return <React.Fragment>
      <Button color="primary" className="mb-2" onClick={() => this.setState({isOpen: true})}>Dodaj tokeny</Button>
      {modal}
    </React.Fragment>

  }
}

AddTokens.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  handleAddTokens: PropTypes.func.isRequired
};

export default AddTokens;