import React, {Component} from 'react';
import PropTypes from "prop-types";
import AnyChart from "anychart-react";
import anychart from 'anychart'

class ChartStackedBarPercent extends Component {

  render() {
    let stage = anychart.graphics.create();
    let height = 130;

    height += (this.props.data.data.length * 65);

    var dataSet = anychart.data.set(this.props.data.data);

    var chart = anychart.bar();
    chart.yScale().minimum(0);
    chart.labels().background('#ffffff');
    chart.labels().fontColor("#000000");
    chart.labels().position("center");
    chart.labels().enabled(true).format(row => Math.round(row.value / this.props.n * 1000) / 10 + '%');

    chart.yScale().stackMode('percent');
    chart.yAxis(0).labels().format('{%Value}%');
    chart.yAxis().title('Procent wskazań');

    var xLabels = chart.xAxis().labels();
    xLabels.wordWrap("break-word");
    xLabels.wordBreak("break-all");
    xLabels.width('20%');
    xLabels.hAlign("right");

    chart.xAxis(0).overlapMode('allow-overlap');

    let setupSeries = function(series, name) {
      series.name(name);
      series.stroke('3 #fff 1');
      series.hovered().stroke('3 #fff 1');
    };

    var series;
    var seriesData;

    this.props.data.series.map((serieName, key) => {
      seriesData = dataSet.mapAs({
        'x': 0,
        'value': key+1
      });

      series = chart.bar(seriesData);
      setupSeries(series, serieName);
    });

    chart.legend()
    .enabled(true)
    .fontSize(13)
    .padding([0, 0, 20, 0]);

    chart.interactivity().hoverMode('by-x');
    chart.tooltip()
    .displayMode('union');

    return <AnyChart
        id={'question-' + this.props.id}
        instance={stage}
        height={height}
        charts={[chart]}
      />;
  }
}

ChartStackedBarPercent.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    series: PropTypes.array.isRequired,
  }).isRequired,
  n: PropTypes.number.isRequired
};

export default ChartStackedBarPercent;