import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, InputGroup, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import UserPrizesList from './UserPrizesList';
import api from "api";
import Select from "react-select";
import {Link} from "react-router-dom";
import ChangeStatus from "./ChangeStatus";

class UserPrizes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      statuses: [],
      filters: {
        search: "",
        status: null,
        transferType: null,
      }
    };

    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount(){
    api.userPrize.get.statusCodes().then(statuses => {
      this.setState({statuses});
    });
  }

  onSearch(e) {
    this.setState({filters: {...this.state.filters, search: e.target.value}})
  }

  render() {
    const statusOptions = _.map(this.state.statuses, status => {
      return {
        label: <FormattedMessage id={"admin.userPrizes.status-" + status} />,
        value: status
      }
    });
    const transferTypesOptions = _.map(['Bank', 'PayPal', 'Inne'], value => {
      return {
        label: value,
        value: value,
      }
    });

    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">
                    <FormattedMessage id="admin.userPrizes.cardHeader" />
                  </h2>
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <InputGroup className="p-0 mb-0">
                    <Input className="p-0" placeholder="Szukaj..." onChange={this.onSearch}/>
                    <span className="input-group-btn py-1">
                      <button className="btn" type="button"><i className="fas fa-search" /></button>
                    </span>
                  </InputGroup>
                </div>
                <div className="w-lg-100 float-right mr-2" style={{minWidth: 200}}>
                  <Select
                    isClearable
                    className="react-select"
                    value={_.find(statusOptions, option => option.value === this.state.filters.status)}
                    options={statusOptions}
                    onChange={e => this.setState({filters: {...this.state.filters, status: e ? e.value : null}})}
                    placeholder="Filtruj po statusie"
                  />
                </div>
                <div className="w-lg-100 float-right mr-2" style={{minWidth: 200}}>
                  <Select
                    isClearable
                    value={_.find(transferTypesOptions, option => option.value === this.state.filters.transferType)}
                    options={transferTypesOptions}
                    onChange={e => this.setState({filters: {...this.state.filters, transferType: e ? e.value : null}})}
                    placeholder="Rodzaj przelewu"
                  />
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <Link to={api.admin.userPrize.get.deliveredForPayment('Bank')} target="_blank">Pobierz plik do przelewów bankowych</Link>
                  <Link to={api.admin.userPrize.get.deliveredForPayment('PayPal')} target="_blank">Pobierz plik do przelewów PayPal</Link>
                </div>
              </div>
              <Row>
                <Col>
                  <UserPrizesList filters={this.state.filters} statuses={this.state.statuses}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default UserPrizes;
