import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Col, Label, FormGroup, Input} from "reactstrap";
import Switch from "components/Switch";
import HelpModeIconTooltip from "components/HelpModeIconTooltip";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import SurveySettingAvailable from "components/PlanRestrictionsModals/modals/SurveySettingAvailable";
import PasswordConfirmationModal from "components/PasswordConfirmationModal";

class Security extends Component {
  constructor(props) {
    super(props);

    this.state = {
      survey: props.survey,
      passwordModal: false,
      onPasswordConfirmation: null
    };

    this.showPlanRestrictionsModalIfNeeded = this.showPlanRestrictionsModalIfNeeded.bind(this);
    this.showPasswordModal = this.showPasswordModal.bind(this);
  }

  onChangeProperty(name, value){
    this.setState({
      survey: {
        ...this.state.survey,
        [name]: value
      }
    });

    this.props.onPropertyChange({
      [name]: value
    });
  }

  showPlanRestrictionsModalIfNeeded(property) {
    if (!this.props.surveyPlan[property]) {
      this.props.setPlanRestrictionsModals(SurveySettingAvailable.getName());
    }
  }

  showPasswordModal(propertyToChange, value) {
    this.setState({
      passwordModal: true,
      onPasswordConfirmation: () => {
        this.setState({passwordModal: false, onPasswordConfirmation: null});
        this.onChangeProperty(propertyToChange, value);
      }
    });
  }

  render() {
    const {survey} = this.state;
    const {errors, surveyPlan} = this.props;

    return (
      <React.Fragment>
        <FormGroup row onClick={() => this.showPlanRestrictionsModalIfNeeded('cookieBlockade')}>
          <Label lg={3} className={"text-lg-right" + (!surveyPlan.cookieBlockade ? ' text-black-50' : '') }>
            Blokada cookies
            <HelpModeIconTooltip className="ml-2">Podczas wypełniania ankiety tworzy ciasteczko blokując możliwość ponownego wypełnienia z tej samej przeglądarki na tym komputerze</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="pt-2">
            <Switch checked={survey.cookieBlockade} disabled={!surveyPlan.cookieBlockade} onChange={(cookieBlockade) => {
              this.onChangeProperty('cookieBlockade', cookieBlockade)
            }} />
          </Col>
        </FormGroup>
        <FormGroup row onClick={() => this.showPlanRestrictionsModalIfNeeded('ipBlockade')}>
          <Label lg={3} className={"text-lg-right" + (!surveyPlan.ipBlockade || survey.hasTopSurveysCollector ? ' text-black-50' : '') }>
            Blokada IP
            <HelpModeIconTooltip className="ml-2">Blokuje możliwość ponownego wypełnienia ankiety z tego samego adresu IP</HelpModeIconTooltip>
          </Label>
          <Col lg={9}  className="pt-2">
            <Switch checked={survey.ipBlockade} disabled={!surveyPlan.ipBlockade || survey.hasTopSurveysCollector} onChange={(ipBlockade) => {
              this.onChangeProperty('ipBlockade', ipBlockade)
            }} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">
            Zabezpieczenie hasłem
            <HelpModeIconTooltip className="ml-2">Przed przystąpieniem do wypełnienia ankiety należy podać określone hasło</HelpModeIconTooltip>
          </Label>
          <Col lg={9} className="d-inline-flex">
            <Switch className="mt-2 mr-3" checked={survey.passwordBlockade} onChange={(passwordBlockade) => {
              this.onChangeProperty('passwordBlockade', passwordBlockade)
            }} />
            {survey.passwordBlockade && <React.Fragment>
              <Input invalid={!!errors.password} onChange={(e) => this.onChangeProperty('password', e.target.value)} value={survey.password || ''} placeholder="hasło"/>
              {errors.password && <div className="invalid-feedback">{errors.password.join('; ')}</div>}
            </React.Fragment>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className={"text-lg-right"}>
            Blokada usuwania wywiadów
            <HelpModeIconTooltip className="ml-2">Blokuje możliwość usuwania zebranych wywiadów dla tej ankiety</HelpModeIconTooltip>
          </Label>
          <Col lg={9}  className="pt-2">
            <Switch stickToProp checked={survey.clearResponsesBlockade} onChange={clearResponsesBlockade => {
              if (clearResponsesBlockade === false && this.props.survey.clearResponsesBlockade) {
                this.showPasswordModal('clearResponsesBlockade', false)
              } else {
                this.onChangeProperty('clearResponsesBlockade', clearResponsesBlockade)
              }
            }} />
          </Col>
        </FormGroup>
        <PasswordConfirmationModal
          isOpen={this.state.passwordModal}
          onSuccess={this.state.onPasswordConfirmation}
          toggleModal={() => this.setState({passwordModal: false, onPasswordConfirmation: null})}
        />
      </React.Fragment>
    );
  }
}

Security.propTypes = {
  setPlanRestrictionsModals: PropTypes.func.isRequired,
  onPropertyChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  survey: PropTypes.shape({
    cookieBlockade: PropTypes.bool.isRequired,
    ipBlockade: PropTypes.bool.isRequired,
    passwordBlockade: PropTypes.bool.isRequired,
    clearResponsesBlockade: PropTypes.bool.isRequired,
    password: PropTypes.string,
    hasTopSurveysCollector: PropTypes.bool.isRequired,
  }).isRequired,
  surveyPlan: PropTypes.shape({
    cookieBlockade: PropTypes.bool.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    // survey: state.survey.structure.data.survey,
    surveyPlan: state.user.userPlan.plan.survey
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(Security);