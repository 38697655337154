import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import ConfigurationForm from './ConfigurationForm';
import PropTypes from 'prop-types';
import {toast} from "react-toastify";
import api from 'api';

class ConfigurationEditButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      updating: false,
      errors: {}
    }

    this.form = React.createRef();
  }

  configurationUpdate(e, data) {
    e.preventDefault();
    this.setState({updating: true});

    api.configuration.patch({
      data: data.configuration,
      id: this.props.configuration.id
    }).then(() => {
      this.props.handleChange();
      toast.success(<FormattedMessage id="admin.configurationEditButton.toast.success" />);
      this.setState({
        updating: false,
        modal: false
      })
    }).catch(e => {
      this.setState({
        updating: false,
        errors: e.response.data.form.errors
      });
    });
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { updating, errors } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} size="lg">
      <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id="admin.configurationEditButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <ConfigurationForm errors={errors} onSubmit={this.configurationUpdate} ref={this.form} configuration={this.props.configuration}/>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={updating}
            data-style={ZOOM_OUT}
            onClick={e => this.configurationUpdate(e, this.form.current.state)}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModal()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <span className="fas fa-edit mr-2 pointer" onClick={() => this.toggleModal()}/>
        {modal}
      </React.Fragment>
    )
  }
}

ConfigurationEditButton.propTypes = {
  configuration: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default connect(null, null, null, {forwardRef: true})(ConfigurationEditButton);