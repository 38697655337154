import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import CreatableSelect from 'react-select/lib/Creatable';
import {Col, Form, FormGroup, Label, Input} from "reactstrap";
import _ from "lodash";
import SurveyClientSelect from 'components/SurveyClientSelect';
import SurveyTags from "../../../Survey/SurveyTags";
import HelpModeIconTooltip from "../../../HelpModeIconTooltip";
import SurveysCategorySelect from "../../../../componentsNew/SurveysCategorySelect";

class FormSurvey extends Component {

  constructor(props) {
    super(props);

    this.state = {
      survey: {
        name: props.survey ? props.survey.nameOryginal : '',
        nameWork: props.survey ? props.survey.nameWork : '',
        tags: props.survey ? props.survey.tags.map(tag => tag.name ? tag.name : tag) : [],
        client: props.survey ? props.survey.clientId : null
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onClientChange = this.onClientChange.bind(this);
  }

  onChange(name, value){
    this.setState({
      ...this.state,
      survey: {
        ...this.state.survey,
        [name]: value,
      }
    })
  }

  onClientChange(client) {
    this.setState({
      survey: {
        ...this.state.survey,
        client: client
      }
    })
  }

  onSubmit(e){
    e.preventDefault();
    let data = Object.assign({}, this.state.survey);

    this.props.submit(data);
  }

  render() {
    const {survey} = this.state;
    const {errors} = this.props;

    return (
      <Form className="form-horizontal" onSubmit={(e) => this.onSubmit(e)}>
        <FormGroup row className="mb-2">
          <Col lg={3}>
            <Label for="hf-name"><FormattedMessage id="buttonCreateSurvey.formSurvey.label.name" /></Label>
          </Col>
          <Col lg={9}>
            <Input className="mb-0" id="hf-name" name="name" valid={errors.name ? false : null} value={survey.name} invalid={!!errors.name} onChange={(e) => this.onChange(e.target.name, e.target.value)}/>
            {errors.name && <div className="invalid-feedback">{errors.name.join('; ')}</div>}
          </Col>
        </FormGroup>
        {this.props.surveyPlan.nameWork && <FormGroup row className="mb-2">
          <Col lg={3}>
            <Label for="hf-name">
              Nazwa robocza ankiety
              <HelpModeIconTooltip className="ml-2">Nazwa wyświetlana na liście projektów, wizardzie etc. Nie jest widoczna dla respondentów.</HelpModeIconTooltip>
            </Label>
          </Col>
          <Col lg={9}>
            <Input className="mb-0" id="hf-nameWork" name="nameWork" valid={errors.nameWork ? false : null} value={survey.nameWork} invalid={!!errors.nameWork} onChange={(e) => this.onChange(e.target.name, e.target.value)}/>
            {errors.nameWork && <div className="invalid-feedback">{errors.nameWork.join('; ')}</div>}
          </Col>
        </FormGroup>}
        {this.props.plan.surveysCategories && <FormGroup row className="mb-2">
          <Col lg={3}>
            <Label for="hf-name">
              Sekcja
              <HelpModeIconTooltip className="ml-2">Określa sekcję, do której przypisana będzie ankieta. Ankietę można przypisać do jednej sekcji.</HelpModeIconTooltip>
            </Label>
          </Col>
          <Col lg={9}>
            <SurveysCategorySelect
              value={survey.surveysCategory ? survey.surveysCategory.id : null}
              onChange={surveysCategory => this.onChange('surveysCategory', {id: surveysCategory})}
            />
          </Col>
        </FormGroup>}
        <FormGroup row className="mb-2">
          <Col lg={3}>
            <Label htmlFor="hf-tags"><FormattedMessage id="buttonCreateSurvey.formSurvey.label.tags" /></Label>
          </Col>
          <Col lg={9}>
            <SurveyTags
              value={survey.tags}
              onChange={tags => this.onChange('tags', tags)}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Col lg={3}>
            <Label htmlFor="hf-tags" className={!this.props.plan.clients ? ' text-black-50' : ''}>
              <FormattedMessage id="buttonCreateSurvey.formSurvey.label.client" />
            </Label>
          </Col>
          <Col lg={9}>
            <SurveyClientSelect changeHandler={this.onClientChange} clientId={survey.client}/>
            {errors.client && <div className="invalid-feedback d-block">{errors.client.join('; ')}</div>}
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

FormSurvey.propTypes = {
  submit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  survey: PropTypes.object
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
    surveyPlan: state.user.userPlan.plan.survey,
  }
}

export default connect(mapStateToProps, null, null, {forwardRef: true})(FormSurvey);