import React, {Component} from 'react';
import ClientsList from './ClientsList';
import ClientsCreateModal from "./ClientsCreateModal";
import { connect } from 'react-redux';
import {Card, CardHeader, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import ClientsAvailable from "../../../components/PlanRestrictionsModals/modals/ClientsAvailable";

class Clients extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      createModal: false
    }
  }

  componentDidMount() {
    document.title = 'Moi klienci | ANKIETEO';
    document.querySelector('meta[name="description"]').setAttribute("content", 'Moi klienci');
    document.querySelector('meta[name="keywords"]').setAttribute("content", 'Moi klienci');
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <div className="direction-group">
            <div className="direction-left d-flex justify-content-start">
              <h2 className="float-left mr-4">Klienci</h2>
            </div>

            <div className="direction-right d-md-flex justify-content-md-end mt-1 mr-1">

              {!this.props.isGroupMember && <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle('dropdownOpen')} className="d-md-none">
                <DropdownToggle className="m-0 p-0" color="">
                  <span className="fas fa-ellipsis-v hoverable" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" onClick={() => this.props.plan.clients ? this.toggle('createModal') : this.props.setPlanRestrictionsModals(ClientsAvailable.getName())} >
                    <p><span className="fas fa-plus" /></p> <FormattedMessage tagName="h5" id="clientsButtonCreate.button"/>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>}

              {!this.props.isGroupMember && <FormattedMessage id="clientsButtonCreate.button">{
                (message) =>
                  <Button color="primary" onClick={() => this.props.plan.clients ? this.toggle('createModal') : this.props.setPlanRestrictionsModals(ClientsAvailable.getName())} className="ml-3 d-none d-md-block"><i className="fas fa-plus" /> {message}</Button>
              }</FormattedMessage>}

              <ClientsCreateModal isOpen={this.state.createModal} toggle={() => this.props.plan.clients ? this.toggle('createModal') : this.props.setPlanRestrictionsModals(ClientsAvailable.getName())}/>

            </div>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <ClientsList isGroupMember={this.props.isGroupMember}/>
        </CardBody>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    isGroupMember: typeof state.user.userGroup === 'string',
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(Clients);