import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../Button";
import SurveyTemplatesCreate from "../SurveyTemplatesCreate/SurveyTemplatesCreate";

const SurveyTemplatesCreateModal = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  return (
    <React.Fragment>
      <Button color="warning" className="d-block mx-auto mt-3" onClick={toggleModalOpen}>Wybierz z szablonów</Button>
      {modalOpen && <Modal isOpen toggle={toggleModalOpen} size="xxl">
        <ModalHeader toggle={toggleModalOpen}>Wybierz szablon ankiety</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <SurveyTemplatesCreate
            onCreated={props.onCreated}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Zamknij</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

SurveyTemplatesCreateModal.propTypes = {
  onCreated: PropTypes.func.isRequired,
};

export default SurveyTemplatesCreateModal;