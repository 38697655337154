import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Select from "react-select";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import QuestionInfo from "components/QuestionInfo";
import {questionTypes, getQuestionTemplate} from "services/questionTypes";

class SelectQuestionTypes extends Component
{
  constructor(props){
    super(props);

    this.select = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  onChange(e){
    const {isMulti} = this.props;

    if(isMulti){
      this.select.current.setState({isOpen: true, menuIsOpen: true});
    }

    if(isMulti){
      this.props.onChange(e.map(obj => obj.value));
    }else{
      this.props.onChange(e.value);
    }
  }

  render() {
    const {value, isMulti, isLoading, className, plan} = this.props;

    return (
      <Select
        placeholder={isMulti ? "Wybierz typy pytań" : "Wybierz typ pytania"}
        className={className}
        ref={this.select}
        value={value && (!isMulti ?
            {
              label: <React.Fragment><QuestionInfo question={getQuestionTemplate(value)} /> <FormattedMessage id={"_.question." + value + ".typeName"}/></React.Fragment>,
              value
            }
          :
          value.map(type => {
            return {
              label: <React.Fragment><QuestionInfo question={getQuestionTemplate(type)} /> <FormattedMessage id={"_.question." + type + ".typeName"}/></React.Fragment>,
              value: type,
            }
          })
        )}
        isOpen={false}
        isMulti={isMulti}
        isLoading={isLoading}
        onChange={this.onChange}
        options={_.map(this.props.availableTypes, (types, index) => {
          let options = _.map(types, template => {
            return {
              label: <React.Fragment><QuestionInfo question={template} /> <FormattedMessage id={"_.question." + template.name + ".typeName"}/></React.Fragment>,
              value: template.name,
              isDisabled: !plan.survey.questionsAvailable.includes(template.type)
            }
          });

          return {
            label: <FormattedMessage id={"_.question." + index + ".typeName"}/>,
            options
          }
        })}
      />
    );
  }
}

SelectQuestionTypes.defaultProps = {
  isMulti: true,
  isLoading: false,
  className: ""
};

SelectQuestionTypes.propTypes = {
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  questionTypes: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  let availableTypes = {};

  if (props.questionTypes) {
    _.each(questionTypes, (types, index) => {
      availableTypes[index] = _.filter(types, type => props.questionTypes.includes(type.name))
    });
  }

  return {
    availableTypes: props.questionTypes ? availableTypes : questionTypes,
    plan: state.user.userPlan.plan
  }
}


export default connect(mapStateToProps)(SelectQuestionTypes);