import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Tooltip} from "reactstrap";
import PipingAvailable from "components/PlanRestrictionsModals/modals/PipingAvailable";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";

class ButtonPipings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onClick() {
    this.props.surveyPlan.piping
      ? this.props.handleTogglePipings()
      : this.props.setPlanRestrictionsModals(PipingAvailable.getName())
  }

  render() {
    const {pipingsOpen} = this.props;
    const {tooltip} = this.state;

    return (
      <a id={'question-' + this.props.id + '-pipings'} onClick={this.onClick}>
        <span className={"fas fa-share-alt" + (pipingsOpen ? " text-primary" : " hoverable")}/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={tooltip}
          target={'question-' + this.props.id + '-pipings'}
          toggle={() => this.toggleTooltip()}
        >Dynamiczne przepisywanie treści</Tooltip>
      </a>
    )
  }
}

ButtonPipings.propTypes = {
  pipingsOpen: PropTypes.bool.isRequired,
  handleTogglePipings: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  surveyPlan: PropTypes.shape({
    pipings: PropTypes.bool.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey
  }
}


export default connect(mapStateToProps, {setPlanRestrictionsModals})(ButtonPipings);