import React, {Component} from 'react';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";
import {ButtonGroup} from "reactstrap";
import {Link} from "react-router-dom";
import appRoutes from "../../../appRoutes";
import ButtonDelete from "./ButtonDelete";
import Button from "../../Button";

class TableList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
  }

  fetchData(state) {
    if(!state){
      state = this.state;
    }

    this.setState({
      searching: true,
      page: state.page,
      pageSize: state.pageSize
    });

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.respondentSmsBases.search(data)
    .then(res => {
      this.setState({
        searching: false,
        data: res.data,
        count: res.count
      });
    })
    .catch(e => this.setState({
      searching: false,
    }));
  }

  actions(row){
    return <ButtonGroup>
      <Link className="btn text-white px-2 d-flex align-items-center" to={appRoutes.respondentSmsBase.base(row.id)}><i className="fas fa-users" /></Link>
      <ButtonDelete baseId={row.id} onDeleted={this.fetchData}><Button color="" className="px-2"><i className="fas fa-trash" /></Button></ButtonDelete>
    </ButtonGroup>
  }

  render() {
    const columns = [
      {
        Header: 'Id',
        accessor: 'id',
        width: 50,
      }, {
        Header: 'Data utworzenia',
        accessor: 'createdAt',
        width: 170,
      }, {
        Header: 'Nazwa',
        accessor: 'name',
        width: 170,
      }, {
        id: 'actions',
        Header: 'Opcje',
        accessor: (row) => this.actions(row),
      },
    ];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={parseInt(this.state.count)}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

export default TableList;