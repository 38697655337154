import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Button} from "reactstrap";
import ClientsButtonDelete from './ClientsButtonDelete';
import ClientsButtonEdit from './ClientsButtonEdit';
import PropTypes from 'prop-types';
import Pagination from "components/Pagination";
import ReactTable from "react-table";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";

class ClientsList extends Component {

  static actions(client){
    return (
      <ul className="float-right mb-0 p-0 d-flex flex-column flex-md-row justify-content-center justify-content-md-end w-100 text-center">
        <li className="float-left mr-2">
          <Link to={appRoutes.surveys.filteredList(client.id)}>
            <Button color="" className="m-0 p-0"><i className="fas fa-list"/></Button>
          </Link>
        </li>
        <li className="float-left mr-2">
          <ClientsButtonEdit client={client}/>
        </li>
        <li className="float-left mr-2">
          <ClientsButtonDelete client={client}/>
        </li>
      </ul>
    )
  }

  render() {
    const {clients, isGroupMember, trades} = this.props;

    const columns = [{
      Header: <FormattedMessage id="clientsList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="clientsList.listItems.tableHeader.name" />,
      accessor: 'name'
    }, {
      id: 'trade',
      Header: <FormattedMessage id="clientsList.listItems.tableHeader.trade" />,
      accessor: client => {
        let trade = _.find(trades, trade => trade.id === client.trade);

        return trade ? trade.name : <i>Brak</i>
      }
    }, {
      Header: <FormattedMessage id="clientsList.listItems.tableHeader.surveysCount" />,
      accessor: 'surveysCount'
    }];

    !isGroupMember && columns.push({
      id: 'actions',
      Header: <FormattedMessage id="clientsList.listItems.tableHeader.actions" />,
      accessor: client => ClientsList.actions(client)
    });

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={Object.values(clients)}
        columns={columns}
        showPagination={!_.isEmpty(clients)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="clientsList.listItems.table.noClients" />}
        defaultPageSize={10}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    clients: state.clients.data.clients,
    trades: state.config.data.trades
  }
}

ClientsList.propTypes = {
  isGroupMember: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ClientsList);