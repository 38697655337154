import React, {Component} from 'react';
import api from 'api';
import {Card, CardBody, Row, Col, Button} from "reactstrap";
import LoadingSpinner from "components/LoadingSpinner";
import {FormattedMessage} from "react-intl";
import { connect } from 'react-redux';
import {chooseFreePlan} from 'reducers/user/actions';
import history from "services/history";
import appRoutes from "appRoutes";

class PlanFreeDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      planInfo: null,
      error: null,
    };

    this.toggle = this.toggle.bind(this);
    this.choosePlan = this.choosePlan.bind(this);
  }

  componentDidMount() {
    api.plan.get.planData(this.props.match.params.plan, 'month').then(data => {
      let planInfo = data.plan;

      if (parseInt(planInfo.price) !== 0) {
        history.push(appRoutes.users.planDetails(this.props.match.params.plan, 'month'));
      }

      this.setState({
        ...this.state,
        loading: false,
        planInfo
      }, () => this.choosePlan());
    });
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  choosePlan() {
    this.setState({saving: true});
    this.props.chooseFreePlan(this.props.match.params.plan)
      .then(() => {
        this.setState({saving: false});

        window.location = appRoutes.surveys.list
      })
  }

  render() {
    return <LoadingSpinner/>;

    return this.state.loading ? <LoadingSpinner/> :
      <React.Fragment>
        <h1><FormattedMessage id="planDetails.info.summary"/></h1>
        <Row className="justify-content-md-center">
          <Col lg={6} md={8} xs={12} className="d-flex align-items-stretch">
            <Card className="w-100">
              <CardBody>
                <dl>
                  <dt><strong><FormattedMessage id="planDetails.info.plan.name"/>:</strong></dt>
                  <dd className="color-blue" style={{fontSize: '2em'}}>{this.state.planInfo.name}</dd>
                  <dt><strong><FormattedMessage id="planDetails.info.plan.price"/>:</strong></dt>
                  <dd className="color-blue">0 <FormattedMessage id="planDetails.info.currency.pln"/></dd>

                  <dt><strong><FormattedMessage id="planDetails.info.sum"/>:</strong></dt>
                  <dd className="color-blue">0 <FormattedMessage id="planDetails.info.currency.pln"/> <FormattedMessage id="planDetails.info.net"/></dd>
                  <dt/>
                  <dd className="float-right">
                    <strong><FormattedMessage id="planDetails.info.toPay"/>:</strong> <br />
                    <span className="color-blue">0 <FormattedMessage id="planDetails.info.withTax"/></span>
                  </dd>
                </dl>
                <Button color="primary" className="float-right" onClick={this.choosePlan} disabled={this.state.saving}>{this.state.saving ? <LoadingSpinner width={20} classNames="my-0"/> : 'Wybieram'}</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
  }
}

export default connect(null, {chooseFreePlan})(PlanFreeDetails);
