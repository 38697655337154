import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Switch from "components/Switch";
import InputNumber from "components/InputNumber";
import {updatePage} from 'scenes/Survey/Structure/data/pages/actions';

class RandAndRotatePageOptions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rotateInBlock: props.page.rotateInBlock,
      randomInBlock: props.page.randomInBlock,
      randPriority: props.page.randPriority,
    };
  }

  onChange(property, value) {
    this.setState({
      [property]: value
    });

    this.props.updatePage({
      id: this.props.page.id,
      page: {
        [property]: value
      }
    });
  }

  render() {
    const {block, page} = this.props;

    return (
      <tr>
        <td>
          <FormattedMessage id="block.settings.page"/> {page.positionInSurvey + 1}
        </td>
        {block.rotatePages && <td>
          <Switch checked={this.state.rotateInBlock} onChange={value => this.onChange('rotateInBlock', value)}/>
        </td>}
        {block.randPages > 0 && <td>
          <Switch checked={this.state.randomInBlock} onChange={value => this.onChange('randomInBlock', value)}/>
        </td>}
        {block.randPages > 0 && this.state.randomInBlock && <td>
          <InputNumber value={this.state.randPriority} min={0} onChange={value => this.onChange('randPriority', value)}/>
        </td>}
      </tr>
    )
  }
}

RandAndRotatePageOptions.propTypes = {
  page: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired
};

export default connect(null, {updatePage})(RandAndRotatePageOptions);