import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {DropdownToggle, DropdownMenu, Dropdown, DropdownItem, Input} from "reactstrap";
import ModalDelete from "./components/ModalDelete";
import ButtonDrag from "./components/ButtonDrag";
import LoadingSpinner from "components/LoadingSpinner";
import QuestionBasketNew from "../BasketNew";
import {deleteBasket, updateBasket} from "scenes/Survey/Structure/data/baskets/actions";
import reactStringReplace from "react-string-replace";
import {FormattedMessage} from "react-intl";
import Checkbox from "components/Checkbox";
import reactHtmlParser from "react-html-parser";
import {isMobile, isTablet} from "react-device-detect";
import HtmlEditor
  from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/HtmlEditor";
import getCKConfigByPlan from "utils/ckeditor/cafeteryConfig";
import BasketAnswersMax from "./components/BasketAnswersMax";

class QuestionBasket extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownSettingsOpen: false,
      checked: false,
      deleteButton: false,
      modalDelete: false,
      deleting: false,
      editing: false,
      saving: false,
      addNewBasketBelow: false,
      hovered: false,
      basket: {
        content: props.basket.content,
      },
    };

    this.deleteBasket = this.deleteBasket.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.updateBasket = this.updateBasket.bind(this);
    this.toggleOption = this.toggleOption.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setHovered = this.setHovered.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      ...this.state,
      basket: {
        ...this.state.basket,
        content: nextProps.basket.content
      }
    })
  }

  onClick(){
    this.setState({editing: true});
  }

  handleKeyPress(e) {
    if (e.charCode === 13)
      this.updateBasket()
  }

  onChangeContent(content){
    const {saving} = this.state;

    if(!saving) {
      this.setState({
        basket: {
          ...this.state.basket,
          content
        }
      });
    }
  }

  toggleChecked(){
    const checked = !this.state.checked;

    this.setState({
      ...this.state,
      checked
    }, () => this.props.handlerChecked(checked));
  }

  updateBasket(){
    const {basket} = this.state;

    if (basket.content.length === 0) {
      this.setState({basket: {...this.state.basket, content: this.props.basket.content}});
      return;
    }

    this.setState({
      saving: true,
      editing: false
    });

    if(basket.content.length === 0)
        return false;

    const data = {
        id: this.props.basket.id,
        basket: {
            content: basket.content
        }
    };

    this.props.updateBasket(data).then(() => this.setState({saving: false}));
  }

  deleteBasket(){
    this.setState({
      deleting: true
    });

    if(!this.state.deleting){
      this.props.deleteBasket({
        id: this.props.basket.id
      })
    }
  }

  toggleOption(option) {
    this.setState({
      basket: {
        ...this.state.basket,
        [option]: !this.state.basket[option]
      }
    }, () => {
      const data = {
        id: this.props.basket.id,
        basket: {
          [option]: this.state.basket[option]
        }
      };

      this.props.updateBasket(data);
    });
  }

  changeOption(option, value) {
    this.setState({
      basket: {
        ...this.state.basket,
        [option]: value
      }
    }, () => {
      const data = {
        id: this.props.basket.id,
        basket: {
          [option]: value
        }
      };

      this.props.updateBasket(data);
    });
  }

  toggle(type, e = {}) {
    // if (e.target.className.includes("value-button"))
    //   return;

    this.setState({
      [type]: !this.state[type]
    })
  }

  setHovered(hovered) {
    this.setState({hovered});
  }

  render() {
    const {position, question, searchInStructure, hideInStructure, plan} = this.props;
    const {basket, checked, deleting, addNewBasketBelow, editing, saving, hovered} = this.state;
    const ctrlEnterKeyCode = 1114125;
    const gripStyle = hovered ? {opacity: 1} : {opacity: 0};
    let editor;

    if (isMobile || isTablet) {
      editor = <Input value={basket.content} onChange={e => this.onChangeContent(e.target.value)} onBlur={this.updateBasket} autoFocus={true} onKeyPress={this.handleKeyPress}/>
    } else {
      editor = <HtmlEditor
        data={basket.content}
        config={getCKConfigByPlan(plan)}
        type="inline"
        onChange={event => this.onChangeContent(event)}
        onBlur={event => {
          this.updateBasket();
          this.setState({editing: false})
        }}
        onKey={event => {
          if (event.data.keyCode === ctrlEnterKeyCode) {
            event.cancel();
            this.updateBasket();
            setTimeout(() => {
              event.editor.destroy();
              this.setState({editing: false})
            }, 1);
          }
        }}
      />
    }

    let style = {
      position: 'relative'
    };

    let contentStyle = hideInStructure === 'minified' ? {
      width: '75%',
      display: 'list-item',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    } : {wordBreak: 'break-all'};

    if(deleting) style.opacity = 0.35;

    let basketContent = basket.content;
    if (searchInStructure.length > 0) {
      basketContent = reactStringReplace(basketContent, searchInStructure, (match, i) => (
        <span className="bg-warning" key={i}>{match}</span>
      ));
    }

    return (
      <React.Fragment>
        <div className="sortable-draggable line-wrap" style={style} data-id={this.props.basket.id} onMouseEnter={_ => this.setHovered(true)} onMouseLeave={_ => this.setHovered(false)}>
          <ButtonDrag id={this.props.basket.id} gripStyle={gripStyle}/>

          <ul className="mb-0 d-flex pl-2">
            <li className="d-none d-lg-inline-block">
              <div className="form-group m-0 overflow-hidden">
                <Checkbox checked={checked} id={'basket-' + this.props.basket.id + '-check'} onClick={() => this.toggleChecked()} messageId={"checkbox.check.basket"}/>
              </div>
            </li>
            <li>{position + 1}.</li>

            <li className="flex-grow-1" style={contentStyle}>
              <span onClick={this.onClick}>
                {editing
                  ? editor
                  : saving
                    ? <LoadingSpinner width={21} classNames="my-0"/>
                    : (searchInStructure.length > 0 ? basketContent : reactHtmlParser(basketContent))
                }
              </span>
            </li>

            <li className="mr-2 d-none d-lg-inline"><BasketAnswersMax basket={this.props.basket} changeOption={this.changeOption}/></li>

            <li className="ml-2">
              <Dropdown isOpen={this.state.dropdownQuestionTypeOpen} toggle={e => this.toggle('dropdownQuestionTypeOpen', e)}>
                <DropdownToggle className="m-0 p-0" color="">
                  <span className="fas fa-ellipsis-v hoverable" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" onClick={() => this.setState({addNewBasketBelow: !this.state.addNewBasketBelow})}>
                    <p><span className="fas fa-plus"/></p> <FormattedMessage tagName="h5" id="basket.addNew.button" />
                  </DropdownItem>

                  <DropdownItem tag="a" onClick={() => this.toggle('modalDelete')}>
                    <p><span className="fas fa-trash"/></p> <FormattedMessage tagName="h5" id="basket.modalDelete.button" />
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
        </div>
        {addNewBasketBelow && <QuestionBasketNew autoFocus={true} handlerAdded={() => this.setState({addNewBasketBelow: false})} position={position+1} question={question} />}
        {this.state.modalDelete && <ModalDelete handleDelete={this.deleteBasket} id={this.props.basket.id} isOpen={this.state.modalDelete} toggleModal={() => this.toggle('modalDelete')}/>}
      </React.Fragment>
    )
  }
}

QuestionBasket.propTypes = {
  basket: PropTypes.shape({
    content: PropTypes.string,
  }).isRequired,
  position: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  handlerChecked: PropTypes.func,
  searchInStructure: PropTypes.string.isRequired
};

function mapStateToProps(state, props) {
  return {
    basket: state.survey.structure.data.baskets[props.basket.id],
    hideInStructure: state.survey.structure.hideInStructure,
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, { deleteBasket, updateBasket })(QuestionBasket);