import api from "api";
import * as types from './actionTypes';

export function setUsers(users) {
  return {
    type: types.SET,
    users
  }
}

function userCreated(user) {
  return {
    type: types.ADDED,
    user
  }
}

function userUpdated(user) {
  return {
    type: types.UPDATED,
    user
  }
}

export function fetchCompanies() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.user.get.companies().then(users => {
      dispatch(setUsers(users));
      dispatch({type: types.SUCCESS});
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}

export function createCompany(data) {
  return dispatch => {
    return api.user.post.company(data)
      .then(user => {
        dispatch(userCreated(user));

        return user;
      });
  }
}

export function updateCompany(data) {
  return dispatch => {
    return api.user.patch.companyAdmin(data)
      .then(user => {
        dispatch(userUpdated(user));

        return user;
      });
  }
}