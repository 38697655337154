import React, {Component} from 'react';
import PropTypes from 'prop-types';
import HideSurveyNavigationButton from './components/HideSurveyNavigationButton';
import HideSurveyNavigationButtonsTable from './components/HideSurveyNavigationButtonsTable';

class NavigationButtons extends Component {
  constructor(props) {
    super(props);

    this.shouldRenderTable = this.shouldRenderTable.bind(this);
  }

  shouldRenderTable(pages) {
    let result = false;

    pages.map(page=> {
      let valuePrev = page.hideNavigationPreviousButton;
      let valueClear = page.hideNavigationClearButton;
      let valueNext = page.hideNavigationNextButton;

      if (valuePrev || valueClear || valueNext){
        result = true;
      }
    });

    return result;
  }


  render() {
    const { block, pages } = this.props;

    return (
      <React.Fragment>
        <HideSurveyNavigationButton block={block} button="Previous" />
        <HideSurveyNavigationButton block={block} button="Clear"/>
        <HideSurveyNavigationButton block={block} button="Next"/>

        {this.shouldRenderTable(pages) && <HideSurveyNavigationButtonsTable pages={pages} block={block}/>}
      </React.Fragment>
    );
  }
}

NavigationButtons.propTypes = {
  block: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      randQuestions: PropTypes.number.isRequired,
      timeMax: PropTypes.number.isRequired,
      timeMin: PropTypes.number.isRequired,
      rotateQuestions: PropTypes.bool.isRequired,
      hideNavigationClearButton: PropTypes.bool.isRequired,
      hideNavigationNextButton: PropTypes.bool.isRequired,
      hideNavigationPreviousButton: PropTypes.bool.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.number,
      ),
    })
  ).isRequired,
};

export default NavigationButtons;