import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import AnyChart from "anychart-react";
import api from "../../../api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import moment from "moment";
import classnames from "classnames";
import DatePicker from "react-datepicker";

class PrizesStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {},
      filters: {
        dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
        interval: 'day',
      },
    };

    this.changeFilterValue = this.changeFilterValue.bind(this);
    this.getDataFormatted = this.getDataFormatted.bind(this);
    this.getChart = this.getChart.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  changeFilterValue(name, value){
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      }
    }, () => {
      this.fetchData();
    })
  }

  getDataFormatted(){
    const {data} = this.state;

    if(_.isEmpty(data)){
      return [];
    }

    let rows = [];
    Object.keys(data[Object.keys(data)[0]]).forEach((keyName, keyValue) => {
      let row = [keyName];

      _.map(data, d => {
        row.push(d[keyName]);
      });

      rows.push(row);
    });

    return rows
  }

  fetchData(){
    const {filters} = this.state;

    this.setState({
      loading: true,
    });

    api.admin.stats.prizes(filters)
    .then(data => {
      this.setState({
        loading: false,
        data,
      });
    })
    .catch(e => {
      this.setState({
        loading: false,
        data: {}
      })
    })
  }

  getChart(){
    const {data} = this.state;

    let stage = anychart.graphics.create();

    // create data set on our data
    var dataSet = anychart.data.set(this.getDataFormatted());

    // create line chart
    var chart = anychart.line();

    let i = 1;
    _.map(data, (values, keyName) => {
      let seriesData = dataSet.mapAs({ x: 0, value: i });

      var series = chart.line(seriesData);
      series.name(keyName);
      series.hovered().markers().enabled(true).type('circle').size(4);
      series
      .tooltip()
      .position('right')
      .anchor('left-center')
      .offsetX(0)
      .offsetY(0);

      i++;
    });

    // turn on chart animation
    chart.animation(true);

    // set chart padding
    chart.padding([10, 0, 5, 0]);

    // turn on the crosshair
    chart.crosshair().enabled(true).yLabel(false).yStroke(null);

    // set tooltip mode to point
    chart.tooltip().positionMode('point');

    // set chart title text settings
    chart.title('Wymiana punktów na nagrody');

    // set yAxis title
    chart.yAxis().title('Wartość w PLN');
    chart.xAxis().labels().padding(5);


    // turn the legend on
    chart.legend().enabled(true).fontSize(13).padding([0, 0, 10, 0]);

    return <AnyChart instance={stage} charts={[chart]} height={550}/>
  }

  render() {
    const {loading, filters} = this.state;

    const interval = {
      day: 'Dzień',
      week: 'Tydzień',
      month: 'Miesiąc',
      year: 'Rok',
    };

    return <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader><h2 className="float-left mr-4">Statystyki wymiany nagród</h2></CardHeader>
          <hr className="my-0" />
          <CardBody>
            <div className="d-flex justify-content-end mb-3">
              <div className="btn-group mr-3">
                {_.map(interval, (name, key) => <button
                  className={classnames({
                    'btn': true,
                    'btn-primary': filters.interval === key,
                    'btn-secondary': filters.interval !== key,
                  })}
                  onClick={() => this.changeFilterValue('interval', key)}
                >{name}</button>)}
              </div>

              <div>
                {filters.dateFrom && filters.dateTo && <div className="d-flex h-100 justify-content-center align-items-center">
                  <DatePicker
                    dropdownMode="select"
                    className="form-control w-100 mb-0"
                    isClearable={false}
                    selected={moment(filters.dateFrom)}
                    onChange={(date) => this.changeFilterValue('dateFrom', date.format('YYYY-MM-DD'))}
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                  />
                  <div className="px-2 d-flex align-items-center"> - </div>
                  <DatePicker
                    dropdownMode="select"
                    className="form-control w-100 mb-0"
                    isClearable={false}
                    selected={moment(filters.dateTo)}
                    onChange={(date) => this.changeFilterValue('dateTo', date.format('YYYY-MM-DD'))}
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                  />
                </div>}
              </div>
            </div>

            {loading ? <LoadingSpinner classNames="py-5 my-5" /> : this.getChart()}
          </CardBody>
        </Card>
      </Col>
    </Row>
  }
}

export default PrizesStats;