const routes = {
  home: '/',
  admin: {
    main: '/superadmin',
    users: {
      logins: '/superadmin/users/logins',
      respondents: '/superadmin/users',
      companies: '/superadmin/users/companies',
      admins: '/superadmin/users/admins',
      points: '/superadmin/users/points',
      respondentsCollectors: '/superadmin/users/collectors',
    },
    surveys: {
      list: '/superadmin/surveys',
      accepted: '/superadmin/surveys/zaakceptowane',
      waitingAccept: '/superadmin/surveys/oczekujace-na-akceptacje',
      responses: '/superadmin/surveys/responses',
      templates: '/superadmin/surveys/templates',
    },
    prizes: {
      list: '/superadmin/prizes',
      create: '/superadmin/prizes/create',
      edit: (id) => '/admin/prizes/' + (id || ':prize') + '/edit',
      categories: {
        list: '/superadmin/prizes/categories',
        create: '/superadmin/prizes/categories/create',
        edit: (id) => '/admin/prizes/categories/' + (id || ':prizeCategory') + '/edit',
      },
      userPrizes: '/superadmin/prizes/userPrizes',
    },
    cms: {
      reports: '/superadmin/cms',
      reportDownloads: '/superadmin/cms/report-downloads',
      caseStudies: '/superadmin/caseStudies',
      mediaInfos: '/superadmin/mediaInfos',
      newses: '/superadmin/cms/newses',
      topSurveysRanks: '/superadmin/cms/top-surveys-ranking',
      socialRanks: '/superadmin/cms/social-ranking',
      ankieteo: {
        main: '/superadmin/cms/ankieteo',
        navEdit: (id) => '/superadmin/cms/ankieteo/edycja-nawigacji/' + (id || ':id'),
        list: '/superadmin/cms/ankieteo/lista',
        add: '/superadmin/cms/ankieteo/dodaj',
        edit: id => '/superadmin/cms/ankieteo/' + (id || ':id'),
      },
      swresearch: {
        main: '/superadmin/cms/swresearch',
        navEdit: (id) => '/superadmin/cms/swresearch/edycja-nawigacji/' + (id || ':id'),
        list: '/superadmin/cms/swresearch/lista',
        add: '/superadmin/cms/swresearch/dodaj',
        edit: id => '/superadmin/cms/swresearch/' + (id || ':id'),
      },
      dashboardNotifies: {
        main: '/superadmin/cms/ankieteo-dash',
        list: '/superadmin/cms/ankieteo-dash/lista',
        add: '/superadmin/cms/ankieteo-dash/dodaj',
        edit: id => '/superadmin/cms/ankieteo-dash/' + (id || ':id'),
      },
    },
    settings: {
      codes: '/superadmin/settings',
      plans: {
        list: '/superadmin/settings/plans',
        create: '/superadmin/settings/plans/add',
        edit: (id) => '/superadmin/settings/plans/' + (id || ':plan') + '/edit',
      },
      planCategories: {
        list: '/superadmin/settings/plans-display',
      },
      configurations: '/superadmin/settings/configurations',
      userEmailDomainBlacklist: '/superadmin/settings/zablokowane-domeny',
    },
    stats: {
      respondents: '/superadmin/stats',
      companies: '/superadmin/stats/companies',
      prizes: '/superadmin/stats/wymiana-nagrod',
      surveyResponses: '/superadmin/stats/wypelnienia-ankiet',
      surveys: '/superadmin/stats/utworzone-ankiety',
      surveyPoints: '/superadmin/stats/ankiety-punkty',
    },
    shipping: {
      panel: '/superadmin/shipping',
      email: '/superadmin/shipping/email',
      find: '/superadmin/shipping/find'
    },
    plans: {
      transactions: '/superadmin/plans/transactions',
    },
    files: {
      files: '/superadmin/files',
      sitemaps: '/superadmin/files/sitemaps',
    },
  },

  respondentBaseChoose: '/bazy-respondentow-wybierz',

  respondentBase: {
    list: '/bazy-respondentow',
    settings: '/bazy-respondentow/ustawienia',
    addresses: (id) => '/bazy-respondentow/' + (id || ':respondentBase'),
  },

  respondentSmsBase: {
    list: '/bazy-respondentow-sms',
    base: (id) => '/bazy-respondentow-sms/' + (id || ':respondentSmsBase'),
  },

  users: {
    deletingAccount: '/usuwanie-konta',
    register: '/rejestracja',
    login: '/logowanie',
    apiKeys: '/klucze-api',
    profile: '/account/konto',
    passwordChangeRequired: '/zmiana-hasla',
    plan: '/plan',
    planDetails: (id, period) => {
      let path = '/plan/' + (id || ':plan');
      path += period ? '?period=' + period : '';
      return path;
    },
    freePlanDetails: (id) => '/plan/free/' + (id || ':plan'),
    planDemo: '/plan-demo',
    planDemoDetails: (id) => '/plan-demo/' + (id || ':plan'),
    planPaymentEnd: '/plan-platnosc-zakonczona',
    group: '/grupa',
    groupInvitationAccept: (id) => '/grupa/' + (id || ':group') + '/accept',
    forgotPassword: '/przypomnij-haslo',
    passwordReset: (resetKey) => '/resetuj-haslo/' + (resetKey || ':resetKey'),
  },
  surveys: {
    filteredList: (clientId) =>  '/lista-projektow?client=' + (clientId || ':client'),
    list: '/lista-projektow',
    listByCategory: '/lista-projektow-katalogi',
    reports: '/zgloszenia',
    lastSeen: '/ostatnio-widziane'
  },
  survey: {
    main: (id) => '/survey/' + (id || ':survey'),
    structure: (id) => '/survey/' + (id || ':survey') + '/structure',
    preview: {
      main: (id) => '/survey/' + (id || ':survey') + '/preview',
      question: (surveyId, questionId) => '/survey/' + (surveyId || ':survey') + '/preview/question/' + (questionId || ':question'),
    },
    collectors: {
      list: (surveyId) => '/survey/' + (surveyId || ':survey') + '/collectors',
      listType: (surveyId, type) => '/survey/' + (surveyId || ':survey') + '/collectors/type/' + (type || ':type'),
      listPanelRealizationAutomate: (surveyId) => '/survey/' + (surveyId || ':survey') + '/collectors/autopanel',
      collector: {
        view: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/',
        panel: {
          bulkSendEmail: (surveyId, collectorsIds) => '/survey/' + (surveyId || ':survey') + '/collectors/edit/' + (collectorsIds || ':collectors') + '/send-email',
          bulkSettings: (surveyId, collectorsIds) => '/survey/' + (surveyId || ':survey') + '/collectors/edit/' + (collectorsIds || ':collectors') + '/settings',
          edit: (surveyId, collectorsIds) => '/survey/' + (surveyId || ':survey') + '/collectors/edit/' + (collectorsIds || ':collectors'),
          send: (collectorId) => '/api/survey-collectors/panel/' + (collectorId || ':collector') + '/emails-send',
          sendEmail: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/send-email',
          mobilePush: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/mobile-push',
          summary: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/send-email/summary',
          respondents: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/send-email/respondents',
          settings: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/send-email/settings',
          sentHistory: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/send-email/sentHistory',
        },
        email: {
          send: (collectorId) => '/api/survey-collectors/email/' + (collectorId || ':collector') + '/emails-send',
          sendEmail: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/email',
          summary: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/email/summary',
          respondents: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/email/respondents',
          settings: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/email/settings',
          sentHistory: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/email/sentHistory',
        },
        sms: {
          send: (collectorId) => '/api/survey-collectors/sms/' + (collectorId || ':collector') + '/sms-send',
          sendSms: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/sms',
          summary: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/sms/summary',
          respondents: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/sms/respondents',
          settings: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/sms/settings',
          sentHistory: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/sms/sentHistory',
        },
        tokens: {
          tokens: (surveyId, collectorId) => '/survey/' + (surveyId || ':survey') + '/collectors/' + (collectorId || ':collector') + '/view/tokens',
          export: (collectorId, password) => '/api/survey-collectors/tokens/' + (collectorId || ':collector') + '/export?password=' + password,
        }
      }
    },
    analysis: {
      main: (surveyId) => '/survey/' + (surveyId || ':survey') + '/analysis',
      questionSummaries: (surveyId, analysisId) => '/survey/' + (surveyId || ':survey') + '/analysis/' + (_.isUndefined(analysisId) ? ':analysis' : analysisId),
      browse: (surveyId, analysisId) => '/survey/' + (surveyId || ':survey') + '/analysis/' + (analysisId || ':analysis') + '/browse',
      browseResponse: (surveyId, analysisId, responseId) => '/survey/' + (surveyId || ':survey') + '/analysis/' + (analysisId || ':analysis') + '/browse/' + (responseId || ':response'),
      modify: (surveyId, analysisId) => '/survey/' + (surveyId || ':survey') + '/analysis/' + (analysisId || ':analysis') + '/modify',
      advanced: (surveyId, analysisId) => '/survey/' + (surveyId || ':survey') + '/analysis/' + (analysisId || ':analysis') + '/advanced',
      dataProcessing: (surveyId, analysisId) => '/survey/' + (surveyId || ':survey') + '/analysis/' + (analysisId || ':analysis') + '/data-processing',
      responsesOperations: (surveyId, analysisId) => '/survey/' + (surveyId || ':survey') + '/analysis/' + (analysisId || ':analysis') + '/responses-operations',
    },
    summary: (id) => '/survey/' + (id || ':survey') + '/summary',
    reports: (id) => '/survey/' + (id || ':survey') + '/reports',
    collectorsPanelEmailStats: (id) => '/survey/' + (id || ':survey') + '/statystyki-wysylek',
    surveyResponseFakers: (id) => '/survey/' + (id || ':survey') + '/dane-testowe',
    surveyValidation: (id) => '/survey/' + (id || ':survey') + '/test',
  },
  analysis: {
    shared: (guid) => '/analysis/' + (guid || ':guid'),
    chartsByUid: (uid = ':uid') => `/analiza/${uid}/wykresy`,
  },
  surveyShared: {
    summary: guid =>  '/survey-shared/summary/' + (guid || ':guid'),
    quotas: guid =>  '/survey-shared/quotas/' + (guid || ':guid'),
  },
  clients: {
    list: '/klienci',
  },
  smtps: {
    list: '/poczty-smtp',
  },

  changeEmail: {
    cancel: (activationCode) => '/zmiana-maila/anuluj/' + (activationCode || ':activationCode'),
    activation: (activationCode) => '/zmiana-maila/' + (activationCode || ':activationCode'),
  },

  account: {
    home: '/account',
    insights: '/account/insights',
    group: '/account/members',
    billing: '/account/billing',
    profile: '/account/profile',
    passwordChangeRequired: '/account/password-change',
    settings: '/account/settings',
    integration: '/account/integrations',
    integrationSmtp: '/account/integrations/smtp',
  },

  dictionaries: {
    list: '/slowniki-kodowania',
    dictionary: {
      wordsList: (dictionaryId) => '/slowniki-kodowania/' + (dictionaryId || ':dictionaryId') + '/kodowane-slowa',
      word: {
        similarList: (dictionaryId, wordId) => '/slowniki-kodowania/' + (dictionaryId || ':dictionaryId') + '/kodowane-slowa/' + (wordId || ':wordId') + '/lista-slow',
      }
    }
  },

  notifications: '/powiadomienia',

  cint: '/cint',

  surveySendEmailStats: '/statystyki-wysylek',
  questionsResult: {
    list: '/przeglad-wynikow',
  },

  surveySwitcherChecks: {
    list: '/ping',
  },
};

export default routes;
