import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {FormattedMessage} from "react-intl";
import api from "api";
import appRoutes from "appRoutes";
import {withRouter} from "react-router-dom";
import SearchTextInput from "../../../../components/Application/ApplicationTable/SearchTextInput";
import ApplicationTable from "../../../../components/Application/ApplicationTable";
import striptags from "striptags";
import Button from "../../../../components/Button";
import CollectorPanelCreateTextarea from "./CollectorPanelCreateTextarea";
import history from "../../../../services/history";
import CollectorPanelFromTemplateModal from "scenes/Survey/Collectors/components/CollectorPanelFromTemplateModal";

class CollectorPanelCreateFromTemplateButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };

    this.tableRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {modalOpen} = this.state;

    const columns = [
      {
        id: 'name',
        Header: 'Nazwa',
        width: 300,
        accessor: row => row.name
      },
      {
        id: 'description',
        Header: 'Opis',
        accessor: row => <div dangerouslySetInnerHTML={{__html: striptags(row.description)}} />
      },
      {
        id: 'action',
        Header: '',
        accessor: row => <div>
          <CollectorPanelFromTemplateModal template={row} surveyId={this.props.surveyId} history={this.props.history} />
        </div>
      },
    ];

    return (
      <React.Fragment>
        <Button color="primary" size="xs" onClick={this.toggleModal}><i className="fas fa-plus" /></Button>
        {modalOpen && <Modal isOpen={true} toggle={this.toggleModal} size="xl">
          <ModalHeader toggle={this.toggleModal}>Wybierz strukturę badanej populacji</ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            <div>
              <div className="d-flex justify-content-end mb-2">
                <div className="ml-auto">
                  <SearchTextInput
                    tableRef={this.tableRef}
                  />
                </div>
              </div>

              <ApplicationTable
                ref={this.tableRef}
                columns={columns}
                fetchData={api.surveyCollectorPanelTemplate.search}
              /></div>
          </ModalBody>
          <ModalFooter>
            <FormattedMessage id="_.button.close">{
              (message) =>
                <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </ModalFooter>
        </Modal>}
      </React.Fragment>
    );
  }
}

CollectorPanelCreateFromTemplateButton.propTypes = {
  surveyId: PropTypes.any.isRequired,
};

export default withRouter(CollectorPanelCreateFromTemplateButton);