import React, {Component} from 'react';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import {FormattedMessage} from "react-intl";
import { connect } from 'react-redux';
import appRoutes from "appRoutes";
import ShareDeleteButton from "./ShareDeleteButton";
import ShareEditButton from "./ShareEditButton";

class ShareList extends Component {

  actions(share) {
    return <React.Fragment>
      <ShareEditButton share={share}/>
      <ShareDeleteButton share={share}/>
    </React.Fragment>
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="analysisShare.shareList.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="analysisShare.shareList.tableHeader.name" />,
      accessor: 'name'
    }, {
      Header: <FormattedMessage id="analysisShare.shareList.tableHeader.password" />,
      accessor: 'password'
    }, {
      id: 'link',
      Header: <FormattedMessage id="analysisShare.shareList.tableHeader.link" />,
      accessor: share => <a href={document.location.origin + appRoutes.analysis.shared(share.guid)}>{document.location.origin + appRoutes.analysis.shared(share.guid)}</a>
    }, {
      id: 'actions',
      Header: <FormattedMessage id="analysisShare.shareList.tableHeader.actions" />,
      accessor: this.actions
    }];

    return <ReactTable
      data={this.props.shares}
      minRows={0}
      className={'table-wrap'}
      columns={columns}
      PaginationComponent={Pagination}
      noDataText={<FormattedMessage id="analysisShare.shareList.table.noData" />}
      defaultPageSize={10}
    />
  }
}

function mapStateToProps(state) {
  return {
    shares: Object.values(state.survey.analysis.data.shares)
  }
}

export default connect(mapStateToProps)(ShareList);
