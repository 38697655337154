import React, {Component} from 'react';
import PropTypes from 'prop-types';
import api from "api";
import ApplicationTable from "../../components/Application/ApplicationTable";
import SearchTextInput from "../../components/Application/ApplicationTable/SearchTextInput";
import SurveyResponseFakersAddModal from "./SurveyResponseFakersAddModal";
import {injectIntl} from "react-intl";
import {Progress} from "reactstrap";

class SurveyResponseFakersTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  render() {
    const columns = [
      {
        id: 'collectorName',
        Header: 'Kolektor',
        width: 300,
        accessor: row => row.collectorName ? row.collectorName : (this.props.intl.formatMessage({ id: "_.surveyCollector." + row.collectorType + '.name' }) + ' ' + row.collectorPosition),
      },
      {
        id: 'progress',
        Header: 'Liczba wypełnień',
        width: 200,
        accessor: row => <Progress className="h-100" color={parseInt((row.currentSupplement/ row.maxSupplement)*100) < 100 ? 'warning' : 'success'} value={parseInt((row.currentSupplement/ row.maxSupplement)*100)}><span className="py-2 text-dark">{row.currentSupplement}/{row.maxSupplement}</span></Progress>
      },
    ];
    
    return <React.Fragment>
      <div className="d-flex justify-content-between mb-2">
        <SurveyResponseFakersAddModal surveyId={this.props.surveyId} onModalClose={() => {}} onAdded={dictionary => this.tableRef.current.fetchDataDelay(0)} />
        <div className="ml-auto">
          {/*<SearchTextInput*/}
          {/*  tableRef={this.tableRef}*/}
          {/*/>*/}
        </div>
      </div>
      <ApplicationTable
        ref={this.tableRef}
        columns={columns}
        fetchData={api.surveyResponseFakers.search}
        deleteData={api.surveyResponseFakers.delete}
        filters={{
          ...this.props.filters,
          survey: this.props.surveyId,
        }}
      />
    </React.Fragment>;
  }
}

SurveyResponseFakersTable.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default injectIntl(SurveyResponseFakersTable);
