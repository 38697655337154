import {SET, DELETED, ADDED, UPDATED} from './actionTypes';
import update from "immutability-helper";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.codes;

    case DELETED:
      return update(state, {
        $unset: [action.code.id]
      });

    case ADDED:
      return {
        ...state,
        [action.code.id]: action.code
      };

    case UPDATED:
      return update(state, {
        [action.code.id]: {
          $set: action.code
        }
      });

    default:
      return state;
  }
};