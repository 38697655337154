import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import _ from "lodash";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import AddJavascriptButton from "./components/AddJavascriptButton";
import ActionDelete from "./components/ActionDelete";
import {deleteJavascript} from "scenes/Survey/Structure/data/javascripts/actions";
import ActionEdit from "./components/ActionEdit";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";


class Javascripts extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      modalOpen: false,
    };

    this.actions = this.actions.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleModal() {
    if(this.props.disabled){
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());

      return;
    }
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  actions(javascript){
    const {question} = this.props;

    return <div className="d-flex justify-content-end">
      <ActionEdit javascript={javascript} question={question} onConfirmed={() => this.props.deleteJavascript(question, javascript)} />
      <ActionDelete javascript={javascript} onConfirmed={() => this.props.deleteJavascript(question, javascript)} />
    </div>
  }

  render() {
    const {modalOpen, tooltip} = this.state;
    const {question, javascripts} = this.props;

    const columns = [{
      Header: <FormattedMessage id="javascripts.listItems.tableHeader.id"/>,
      accessor: 'id',
      width: 60,
    }, {
      id: 'action',
      Header: <FormattedMessage id="javascripts.listItems.tableHeader.action"/>,
      accessor: row => <FormattedMessage id={"_.question.javascript.action-" + row.action} />,
    }, {
      Header: <FormattedMessage id="javascripts.listItems.tableHeader.code"/>,
      accessor: 'code',
    }, {
      id: 'actions',
      Header: <FormattedMessage id="_.tableHeader.actions"/>,
      accessor: javascript => this.actions(javascript),
      width: 100,
    }];

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal}>Lista kodów JavaScript</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <AddJavascriptButton question={question} />
        <ReactTable
          minRows={0}
          className={'table-wrap'}
          data={javascripts}
          columns={columns}
          showPagination={!_.isEmpty(javascripts)}
          PaginationComponent={Pagination}
          defaultPageSize={25}
        />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.close">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button id={'question-' + question.id + '-js'} className="ml-1" color={_.isEmpty(javascripts) ? 'secondary' : 'primary'} onClick={this.toggleModal}>
          <i className="fa-brands fa-js-square" />

          <Tooltip
            delay={0}
            placement="top"
            isOpen={tooltip}
            target={'question-' + question.id + '-js'}
            toggle={() => this.toggleTooltip()}
          >
            <FormattedMessage id={'_.question.js'}/>
          </Tooltip>
        </Button>
        {modal}
      </React.Fragment>
    );
  }
}

Javascripts.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};

function mapStateToProps(state, props) {
  return {
    javascripts: state.survey.structure.data.questions[props.question.id].javascripts.map(id => {
      return id in state.survey.structure.data.javascripts ? state.survey.structure.data.javascripts[id] : false;
    }).filter(value => !!value),
  }
}

export default connect(mapStateToProps, {deleteJavascript, setPlanRestrictionsModals})(Javascripts);
