import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import PropTypes from 'prop-types';
import {acceptInvitation} from '../../actions';

class GroupInvitationsAcceptButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false,
    };

    this.acceptInvitation = this.acceptInvitation.bind(this);
  }

  acceptInvitation(e){
    e.preventDefault();

    this.setState({saving: true});
    this.props.acceptInvitation(this.props.group.id);
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { saving } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()}>
      <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id="groupInvitationsAcceptButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <FormattedMessage id="groupInvitationsAcceptButton.modalBody" />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="groupInvitationsAcceptButton.button.accept">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.acceptInvitation}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModal()}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="m-0 p-0" onClick={() => this.toggleModal()}><i className="fas fa-check" /></Button>
        {modal}
      </React.Fragment>
    )
  }
}

GroupInvitationsAcceptButton.propTypes = {
  group: PropTypes.object.isRequired
};

export default connect(null, {acceptInvitation})(GroupInvitationsAcceptButton);