import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroup, InputGroupAddon, Tooltip} from "reactstrap";
import classnames from "classnames";
import InputNumber from "components/InputNumber";

class RandPages extends Component {

  constructor(props) {
    super(props);
    this.state = {
      block: {
        randPages: props.block.randPages,
      },
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(value){
    if(!value || value < 0){
      value = 0;
    }

    this.setState({
      block: {
        ...this.state.block,
        randPages: value
      }
    }, () => {
      this.props.handleUpdate(this.state.block)
    });
  }

  render() {
    let { block, tooltip } = this.state;

    return (
      <div
        id={"randPages-" + this.props.block.id}
        className={classnames({
          "min-w-0 float-left mr-1 mb-1": true,
          "w-auto": !block.randPages,
          "w-100 w-lg-auto input-group": block.randPages
        })}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": block.randPages > 0,
            })}
            color="secondary"
            onClick={() => {
              if(block.randPages === 0){
                this.onChange(1)
              }else{
                this.onChange(0)
              }
            }}
          >
            <i className="fa-solid fa-dice" />
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltip}
              target={"randPages-" + this.props.block.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="block.settings.randPages.button.tooltip"/>
            </Tooltip>
          </Button>
        </InputGroupAddon>
        {block.randPages > 0 && <InputNumber value={block.randPages} min={0} max={this.props.block.pages.length} onChange={(value) => this.onChange(value)}/>}
      </div>
    )
  }
}

RandPages.propTypes = {
  block: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default RandPages;
