import React from 'react';
import PropTypes from "prop-types";
import {Button, UncontrolledTooltip} from "reactstrap";
import {isMobile, isTablet} from "react-device-detect";
import UserAvatar from "../../../components/UserAvatar";

const SurveyShareButton = props => {
  const {surveyShares} = props;
  const avatarSize = (isMobile || isTablet) ? 28 : 20;

  return (
    <React.Fragment>
      <Button color="" id={'survey-shares-' + props.surveyId} className="m-0 p-0" onClick={() => props.toggleModal()}>
        {surveyShares.length === 0 ?
          <React.Fragment>
            <i className="fas fa-share-alt"/>
            <UncontrolledTooltip target={'survey-shares-' + props.surveyId} delay={0}>Udostępnianie ankiety</UncontrolledTooltip>
          </React.Fragment>
          :
          <span className="d-flex flex-nowrap align-items-center">
              {surveyShares.map((surveyShare, key) => {
                if(key > 2){
                  return null;
                }
                return <UserAvatar
                  key={key}
                  user={surveyShare.user}
                  size={avatarSize}
                  style={{
                    marginLeft: key > 0 ? '-7px' : ''
                  }}
                />
              })}
            {surveyShares.length > 3 && ('+' + (surveyShares.length - 3))}
            <UncontrolledTooltip delay={0} target={'survey-shares-' + props.surveyId}>
                {surveyShares.map((surveyShare, key) => {
                  return <div key={key}>{surveyShare.user.firstname && surveyShare.user.firstname} {surveyShare.user.lastname && surveyShare.user.lastname}</div>
                })}
              </UncontrolledTooltip>
            </span>
        }
      </Button>
    </React.Fragment>
  )};

SurveyShareButton.propTypes = {
  surveyId: PropTypes.number.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default SurveyShareButton;