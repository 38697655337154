import api from "api";
import * as questionsActions from "../questions/actions";

export const SET = 'Survey/data/scalePoint/SET';
export const ADDED = 'Survey/data/scalePoint/ADDED';
export const DELETED = 'Survey/data/scalePoint/DELETED';
export const UPDATED = 'Survey/data/scalePoint/UPDATED';

export function setScalePoints(scalePoints) {
  return {
    type: SET,
    scalePoints
  }
}
export function scalePointAdded(scalePoint) {
  return {
    type: ADDED,
    scalePoint
  }
}
export function scalePointDeleted(scalePoint) {
  return {
    type: DELETED,
    scalePoint
  }
}
export function scalePointUpdated(scalePoint) {
  return {
    type: UPDATED,
    scalePoint
  }
}

export function addScalePoint(data) {
  return dispatch => {
    return api.question.post.scalePoint(data).then(scalePoint => {
      dispatch(scalePointAdded(scalePoint));
      dispatch(questionsActions.scalePointAdded(scalePoint));

      return scalePoint;
    });
  }
}

export function deleteScalePoint(data) {
  return dispatch => {
    return api.scalePoint.delete.scalePoint(data).then(res => {
      dispatch(scalePointDeleted({
        id: data.id,
        question: {
          id: res.scalePoint.question.id
        }
      }))
    })
  }
}

export function deleteScalePoints(questionId, data) {
  return dispatch => {
    return api.scalePoint.delete.list(data).then(() => {
      _.map(data.ids, id => {
        dispatch(scalePointDeleted({
          id,
          question: {
            id: questionId
          }
        }))
      })
    })
  }
}

export function updateScalePoint(data) {
  return dispatch => {
    return api.scalePoint.patch(data).then(scalePoint => {
      dispatch(scalePointUpdated(scalePoint))
    })
  }
}