import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import ReactTable from 'react-table';
import Pagination from "components/Pagination";
import api from "../../../../api";
import TopSurveysRankEditButton from "./TopSurveysRankEditButton";
import moment from "moment";
import TopSurveysRankDeleteButton from "./TopSurveysRankDeleteButton";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {toast} from "react-toastify";
import SurveyResponseIpAddressRepeatsCheck from "../../Surveys/components/SurveyResponseIpAddressRepeatsCheck";

class TopSurveysRanksList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      month: moment().subtract('1', 'month').format('YYYY-MM'),
      searching: false,
      data: [],
      pageSize: 50,
      modalIsOpen: false,
      accepting: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.actions = this.actions.bind(this);
    this.toogleModal = this.toogleModal.bind(this);
    this.acceptRank = this.acceptRank.bind(this);
  }

  acceptRank(){
    this.setState({
      accepting: true
    });

    const data = {
      filters: {
        month: this.state.month,
      },
    };

    api.topSurveysRank.post.accept(data).then(() => {
      this.setState({
        accepting: false,
        modalIsOpen: false,
      });
      toast.success('Nagrody przydzielono pomyślnie');
    }).catch(e => {
      this.setState({
        accepting: false,
      });
      console.log('szczegóły błędu', e);
      toast.error('Wystąpił błąd, info masz w konsoli w zakładce "Console", nie zamykaj tej karty!')
    })
  }

  fetchData() {
    this.setState({searching: true});

    const data = {
      filters: {
        month: this.state.month,
      },
      page: 1,
      pageSize: this.state.pageSize,
    };

    api.topSurveysRank.post.search(data).then(res => {
      this.setState({
        searching: false,
        data: res.data
      });
    });
  }

  toogleModal(){
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  actions(data){
    const handler = () => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});
    return (
      <ButtonGroup>
        <span className="mr-1"><SurveyResponseIpAddressRepeatsCheck surveyId={data.surveyId} /></span>
        <TopSurveysRankEditButton data={data} handleChange={handler}/>
        <TopSurveysRankDeleteButton id={data.id} handleChange={handler}/>
      </ButtonGroup>
    )
  }

  render() {
    const {modalIsOpen, accepting} = this.state;

    const columns = [{
      Header: 'Ankieta',
      accessor: 'surveyName'
    }, {
      Header: 'Użytkownik',
      accessor: 'userName'
    }, {
      Header: 'Średnia ocena',
      accessor: 'rateAvg'
    }, {
      Header: 'Liczba ocen',
      accessor: 'rateCount'
    }, {
      id: 'actions',
      Header: <FormattedMessage id="admin.reportsList.listItems.tableHeader.actions" />,
      accessor: report => this.actions(report),
      sortable: false
    }];

    const modal = <Modal isOpen={modalIsOpen} toggle={this.toogleModal} size="lg">
      <ModalHeader tag="h2" toggle={this.toogleModal}>Przydziel nagrody</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        Kliknij przycisk poniżej aby zaakceptować ranking i przydzielić nagrody
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toogleModal}>{message}</Button>
        }</FormattedMessage>
        <LaddaButton
          className="btn btn-primary btn-ladda mb-0"
          loading={accepting}
          data-style={ZOOM_OUT}
          onClick={this.acceptRank}
        >Przydziel nagrody</LaddaButton>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <FormGroup row>
          <Label md={1}>Miesiąc</Label>
          <Col md={11}>
            <Input value={this.state.month} onChange={e => {
              this.setState({
                month: e.target.value
              }, () => {
                this.fetchData()
              });
            }} />
          </Col>
        </FormGroup>
        <Row>
          <ReactTable
            minRows={0}
            className={'table-wrap'}
            data={this.state.data}
            columns={columns}
            defaultPageSize={50}
            showPagination={!_.isEmpty(this.state.data)}
            PaginationComponent={Pagination}
            loading={this.state.searching}
            onFetchData={this.fetchData}
            rowsCount={this.state.count}
            manual
          />
        </Row>
        <Row>
          <Button onClick={this.toogleModal}>Przydziel nagrody</Button>
          {modal}
        </Row>
      </React.Fragment>
    )
  }
}

export default TopSurveysRanksList;
