import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {connect} from "react-redux";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";

class CssCode extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      modalOpen: false,
      saving: false,
      css: null,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleModal() {
    if(this.props.disabled){
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());

      return;
    }

    this.setState({
      modalOpen: !this.state.modalOpen,
      css: this.props.question.css,
    })
  }

  render() {
    const {modalOpen, saving, tooltip, css} = this.state;
    const {question} = this.props;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal}>Kod CSS</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Input
          type="textarea"
          rows={15}
          placeholder="Wpisz style CSS..."
          onChange={e => this.setState({css: e.target.value})}
          value={css}
        />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleUpdate({
                css: this.state.css
              });
              this.toggleModal();
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button id={'question-' + question.id + '-css'} color={_.isEmpty(question.css) ? 'secondary' : 'primary'} onClick={this.toggleModal}>
          <i className="fa-brands fa-css3" />

          <Tooltip
            delay={0}
            placement="top"
            isOpen={tooltip}
            target={'question-' + question.id + '-css'}
            toggle={() => this.toggleTooltip()}
          >
            <FormattedMessage id={'_.question.css'}/>
          </Tooltip>
        </Button>
        {modal}
      </React.Fragment>
    );
  }
}

CssCode.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    css: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
};

export default connect(null, {setPlanRestrictionsModals})(CssCode);
