import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";
import {ButtonGroup} from "reactstrap";
import Checkbox from "../../../../components/Checkbox";
import Button from "../../../../components/Button";
import ReportsButtonFix from "./ReportsButtonFix";
import ReportSendMailButton from "./ReportSendMailButton";
import SearchTextInput from "../../../../components/Application/ApplicationTable/SearchTextInput";
import SecretariatSendButton from "./SecretariatSendButton";
import Icon from "../../../../componentsReusable/Icon";
import {connect} from "react-redux";

class ReportsList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetchDataDelay: null,
      updating: false,
      searching: false,
      search: '',
      checked: [],
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchDataDelay = this.fetchDataDelay.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.checkedSetFixed = this.checkedSetFixed.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
    this.toggleCheckedAll = this.toggleCheckedAll.bind(this);
  }

  fetchDataDelay(delay = 1000){
    clearTimeout(this.state.fetchDataDelay);

    this.setState({
      fetchDataDelay: setTimeout(() => {
        this.fetchData(this.state);
      }, delay)
    });
  }

  fetchData(state) {
    this.setState({
      searching: true,
      page: state.page,
      pageSize: state.pageSize,
      checked: [],
    });

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted,
        q: state.search,
      },
      survey: this.props.surveyId,
    };

    api.survey.reports.search(data)
    .then(res => {
      this.setState({
        searching: false,
        data: res.data,
        count: res.count,
      });
    })
    .catch(e => this.setState({
      searching: false,
    }));
  }

  toggleChecked(id) {
    const checked = _.xor(this.state.checked, [id]);
    this.setState({checked});
  }

  toggleCheckedAll(){
    const {checked, data} = this.state;

    let checkedNew = [];
    if(checked.length < data.length){
      checkedNew = data.map(d => d.id);
    }

    this.setState({
      checked: checkedNew,
    });
  }

  checkedSetFixed(){
    const {checked} = this.state;

    this.setState({
      updating: true,
    });

    api.survey.reports.patchBulk(checked, {fixed: true})
    .then(data => {
      this.setState({
        updating: false,
      });

      this.fetchData(this.state);
    })
    .catch(res => {
      this.setState({
        updating: false,
      });
    });
  }

  static fixed(fixed) {
    return fixed
      ? <FormattedMessage id="reports.tableHeader.fixed.true" />
      : <FormattedMessage id="reports.tableHeader.fixed.false" />;
  }

  render() {
    const {checked, updating} = this.state;

    const columns = [
      {
        id: 'check',
        accessor: response => <Checkbox id="check" checked={this.state.checked.includes(response.id)} tooltip={false} onClick={() => this.toggleChecked(response.id)}/>,
        sortable: false
      },
      {
        id: 'u.id',
        Header: <FormattedMessage id="reports.tableHeader.user" />,
        accessor: report => report.user ? report.user.id : <i>Brak usera</i>
      },
      {
        id: 's.name',
        Header: 'Ankieta',
        show: !this.props.surveyId,
        accessor: report => report.survey.name
      },
      {
        id: 's.id',
        Header: 'Id ankiety',
        show: !this.props.surveyId,
        accessor: report => report.survey.id
      },
      {
        Header: <FormattedMessage id="reports.tableHeader.createdAt" />,
        accessor: 'createdAt'
      },
      {
        id: 'sr.id',
        Header: 'Id wywiadu',
        accessor: report => report.surveyResponse ? report.surveyResponse.id : <i>Brak danych</i>
      },
      {
        id: 'sr.status',
        Header: 'Status wywiadu',
        accessor: report => report.surveyResponse ? <FormattedMessage id={"_.surveyResponse.status." + report.surveyResponse.status + ".label"} /> : <i>Brak danych</i>
      },
      {
        id: 'p.id',
        Header: 'Strona',
        accessor: report => report.page ? (report.page.positionInSurvey+1) : <i>Brak danych</i>
      },
      {
        id: 'content',
        Header: <FormattedMessage id="reports.tableHeader.content" />,
        accessor: report => report.content.substr(0, 300)
      },
      {
        id: 'imageFilename',
        Header: <FormattedMessage id="reports.tableHeader.imageFilename" />,
        accessor: report => report.imageFilename ? <a href={this.props.filesystem_app_baseurl + "/" + report.imageFilename} target={"_blank"}>Link</a> : 'Brak'
      },
      {
        id: 'fixed',
        Header: <FormattedMessage id="reports.tableHeader.fixed" />,
        accessor: report => ReportsList.fixed(report.fixed)
      },
      {
        id: 'mailSent',
        Header: <FormattedMessage id="reports.tableHeader.mailSent" />,
        accessor: report => report.mailSent ? 'Tak' : 'Nie'
      },
      {
        id: 'actions',
        sortable: false,
        Header: <FormattedMessage id="reports.tableHeader.actions" />,
        accessor: report => {
          return !report.fixed ? <ReportsButtonFix id={report.id} onFixed={() => this.fetchData(this.state)}/> : null
        }
      },
    ];

    if(this.props.plan.survey.reportSendToSecretariat){
      columns.push({
        id: 'secretariatSend',
        sortable: false,
        Header: 'Wyślij do sekretariatu',
        accessor: report => {
          return !report.secretariatSend ? <SecretariatSendButton id={report.id} onSuccess={() => this.fetchData(this.state)}/> : <Icon className="fas fa-check-square text-success" tooltip={'Wysłano'} />
        }
      })
    }

    return (
      <React.Fragment>
        <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
          <div />
          <div>
            <SearchTextInput
              onChange={search => {
                this.setState({
                  search,
                }, () => {
                  this.fetchDataDelay(1000)
                })
              }}
            />
          </div>
        </div>
        <ReactTable
          ref={this.tableRef}
          minRows={0}
          className={'table-wrap'}
          data={this.state.data}
          columns={columns}
          showPagination={!_.isEmpty(this.state.data)}
          PaginationComponent={Pagination}
          loading={this.state.searching}
          onFetchData={this.fetchData}
          rowsCount={parseInt(this.state.count)}
          manual
          onSortedChange={sorted => this.setState({sorted})}
        />
        {!_.isEmpty(this.state.data) && <ButtonGroup>
          <Button color="secondary" onClick={this.toggleCheckedAll}>{checked.length === this.state.data.length ? 'Odznacz wszystkie' : 'Zaznacz wszystkie'}</Button>
          {checked.length > 0 && <Button color="primary" loading={updating} onClick={this.checkedSetFixed}>Oznacz jako rozwiązane ({checked.length})</Button>}
          {checked.length > 0 && <ReportSendMailButton reportIds={checked} />}
        </ButtonGroup>}
      </React.Fragment>
    )
  }
}

ReportsList.propTypes = {
  surveyId: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
    filesystem_app_baseurl: state.config.data.filesystem_app_baseurl
  }
}

export default connect(mapStateToProps)(ReportsList);
