import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Col, Input, Form} from "reactstrap";
import {connect} from 'react-redux';
import {FormattedMessage} from "react-intl";
import classnames from 'classnames';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {addTemplate} from 'scenes/Survey/Structure/data/survey/actions';

class SaveTemplate extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false,
      errors: {},
      name: ''
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
  }

  toggleModal() {
    this.setState({modal: !this.state.modal})
  }

  saveTemplate() {
    this.setState({saving: true, errors: {}});

    const data = {
      name: this.state.name,
      styles: {...this.props.currentTemplateStyles, ...this.props.ownStyles}
    };

    this.props.addTemplate(data, this.props.surveyId)
      .then(() => this.setState({saving: false, modal: false}))
      .catch(error => this.setState({ saving: false, errors: error.response.data.form.errors }));
  }

  render() {
    const {errors} = this.state;
    const inactive = Object.keys(this.props.ownStyles).length === 0;

    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal} backdrop="static" id="save-template-modal">
      <ModalHeader toggle={this.toggleModal} tag="h2">Zapis nowego szablonu</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <Form onSubmit={this.saveTemplate}>
          <FormGroup row>
            <Label lg={4} className="text-right">Nazwa</Label>
            <Col lg={8}>
              <Input
                type="text"
                value={this.state.name}
                onChange={e => this.setState({name: e.target.value})}
                invalid={!!errors.name}
                required
              />
              {errors.name && <div className="invalid-feedback">{errors.name.join('; ')}</div>}
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <LaddaButton
              className="btn btn-primary btn-ladda mb-0 ml-3"
              loading={this.state.saving}
              data-style={ZOOM_OUT}
              onClick={this.saveTemplate}
            >
              <FormattedMessage id="_.button.save" />
            </LaddaButton>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <div className={classnames({
        'align-items-center': true,
        'inactive': inactive,
      })}>
        <Button onClick={this.toggleModal} disabled={inactive} className="w-100">Zapisz zmiany jako nowy szablon</Button>
        {modal}
      </div>
    );
  }
}

SaveTemplate.propTypes = {
  ownStyles: PropTypes.object.isRequired,
  handlerUpdate: PropTypes.func.isRequired,
  surveyId: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  const templateId = state.survey.structure.data.survey.templateId;
  let template = _.find(state.config.data.survey.globalTemplates, template => template.id === templateId) || _.find(state.user.surveyTemplates, template => template.id === templateId);

  return {
    currentTemplateStyles: template ? template.styles : {}
  }
}

export default connect(mapStateToProps, {addTemplate})(SaveTemplate);
