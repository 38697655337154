import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";
import striptags from "striptags";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import reactHtmlParser from "react-html-parser";
import countPercent from "../../../../../../../utils/countPercent";

const SLICE_VALUE = 5;

class QuestionSummaryMultiOpen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tableSlice: true,
    };

    this.getDataForChart = this.getDataForChart.bind(this);
  }

  placesDataFormat(row) {
    const {questionSummary} = this.props;
    let li = [];

    for(let place = 1; place <= questionSummary.question.answersCount; place++){
      li.push(<li key={place}>Miejsce {place} - {row['place_' + place]}</li>);
    }

    return (
      <ul className="mb-0">
        {li}
      </ul>
    )
  }

  getDataForChart() {
    const {questionSummary} = this.props;

    if (questionSummary.chartType === 'tagCloud') {
      return questionSummary.data.answers.map(answer => {
        return {
          x: answer.content,
          value: parseInt(answer.value)
        }
      })
    } else if (questionSummary.chartType === 'stackedBar') {
      let series = [];
      for(let place = 1; place <= questionSummary.question.answersCount; place++){
        series.push('Miejsce ' + place);
      }
      return {
        data: questionSummary.data.answers.map(answer => {
          let ret = [reactHtmlParser(striptags(answer.content))[0]];

          for(let place = 1; place <= questionSummary.question.answersCount; place++){
            ret.push(answer['place_' + place]);
          }

          return ret;
        }),
        series: series,
      }
    }
  }

  render() {
    const {questionSummary} = this.props;
    const {tableSlice} = this.state;
    const dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <React.Fragment>
        <Chart
          id={questionSummary.question.id}
          type={questionSummary.chartType}
          data={this.getDataForChart()}
          n={questionSummary.data.n}
        />

        {this.props.table && <React.Fragment>
          <BootstrapTable
            className={'table-wrap w-lg-50 mx-auto mt-3'}
            data={tableSlice ? questionSummary.data.answers.slice(0, SLICE_VALUE) : questionSummary.data.answers}
            striped hover
          >
            {questionSummary.chartType === 'tagCloud' &&
              <TableHeaderColumn dataField='content' isKey={true}><FormattedMessage
                id="analysis.questionSummary.multiOpen.tableHeader.x"/></TableHeaderColumn>}
            {questionSummary.chartType === 'tagCloud' && <TableHeaderColumn dataField='value'><FormattedMessage
              id="analysis.questionSummary.multiOpen.tableHeader.value"/></TableHeaderColumn>}

            {questionSummary.chartType === 'stackedBar' && <TableHeaderColumn dataField='answer' isKey={true}
                                                                              dataFormat={(cell, row) => row.content}><FormattedMessage
              id="analysis.questionSummary.multiOpen.tableHeader.x"/></TableHeaderColumn>}
            {questionSummary.chartType === 'stackedBar' && <TableHeaderColumn dataField='places'
                                                                              dataFormat={(cell, row) => this.placesDataFormat(row)}><FormattedMessage
              id="analysis.questionSummary.multiOpen.tableHeader.value"/></TableHeaderColumn>}
            <TableHeaderColumn dataField='percent' dataFormat={(cell, d) => {
              return countPercent(d.value, questionSummary.data.allAnswers) + "%"
            }}><FormattedMessage id="analysis.questionSummary.percent" /></TableHeaderColumn>
            <TableHeaderColumn dataField='percent' dataFormat={(cell, d) => {
              return countPercent(d.value, questionSummary.data.allValidAnswers) + "%"
            }}><FormattedMessage id="analysis.questionSummary.wholeResult" /></TableHeaderColumn>

          </BootstrapTable>
          {(tableSlice && questionSummary.data.answers.length > SLICE_VALUE) &&
            <p className="text-center"><span className="text-muted py-2 d-block pointer"
                                             onClick={() => this.setState({tableSlice: false})}>Kliknij, aby pokazać wszystkie odpowiedzi w tabeli</span>
            </p>}
        </React.Fragment>}
      </React.Fragment>
    )
  }
}

QuestionSummaryMultiOpen.defaultProps = {
  chart: true,
  table: true,
};

QuestionSummaryMultiOpen.propTypes = {
  chart: PropTypes.bool,
  table: PropTypes.bool,
};

export default QuestionSummaryMultiOpen;
