import * as React from 'react';
import api from "api";
import ApplicationTable from "components/Application/ApplicationTable";
import striptags from 'striptags';
import moment from "moment";

function SurveyChangeHistoryTable(props) {

  const columns = [
    {
      id: 'createdAt',
      Header: 'Data',
      width: 140,
      accessor: row => moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      id: 'userName',
      Header: 'Użytkownik',
      width: 200,
      accessor: row => row.userName
    },
    {
      id: 'msg',
      Header: 'Akcja',
      accessor: row => <div dangerouslySetInnerHTML={{__html: striptags(row.msg)}} />
    },
  ];


  return (
    <ApplicationTable
      columns={columns}
      fetchData={api.surveyChangeHistory.search}
      filters={{
        ...props.filters,
        survey: props.survey.id,
      }}
    />
  );
}

SurveyChangeHistoryTable.propTypes = {

};

export default SurveyChangeHistoryTable;