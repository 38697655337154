import React, {Component} from 'react';
import LoadingSpinner from "../../components/LoadingSpinner";
import api from "../../api";
import {connect} from "react-redux";
import {profileUpdated} from "reducers/user/actions";
import setAuthorizationHeader from "utils/setAuthorizationHeader";
import appRoutes from "appRoutes";
class ChangeEmailActivation extends Component {
  componentDidMount() {
    const activationCode = this.props.match.params.activationCode;

    api.user.get.changeEmailActivation(activationCode)
    .then(data => {
      this.props.dispatch(profileUpdated(data.user));

      localStorage.jwt = data.token;
      setAuthorizationHeader(localStorage.jwt);

      setTimeout(() => {
        window.location = appRoutes.surveys.list
      }, 800)
    });
  }

  render() {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
}

export default connect()(ChangeEmailActivation);
