import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {deleteUser} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';

class GroupUserDeleteModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      deleting: false
    }
  }

  userDelete(){
    this.setState({
      deleting: true
    });

    this.props.deleteUser({
      groupId: this.props.group.id,
      id: this.props.user.id
    }).then(() => {
      this.setState({deleting: false});
      this.props.toggleModal();
    })
      .catch(() => {
      this.setState({
        deleting: false
      });
    });
  }

  render() {
    const { deleting } = this.state;
    const {user} = this.props;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.props.toggleModal} tag="h2">Usuń użytkownika z grupy</ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <p>Czy na pewno chcesz usunąć użytkownika <strong>{user.email}</strong> ze swojej grupy?</p>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.modal.delete.confirmation">{
                (message) => <LaddaButton
                  className="btn btn-danger btn-ladda mb-0 ml-3"
                  loading={deleting}
                  data-style={ZOOM_OUT}
                  onClick={() => this.userDelete()}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" onClick={this.props.toggleModal}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

GroupUserDeleteModal.propTypes = {
  user: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default connect(null, { deleteUser })(GroupUserDeleteModal);