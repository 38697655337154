import * as React from 'react';
import PropTypes from "prop-types";
import {Alert, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "components/Button";
import {FormattedMessage} from "react-intl";
import api from "api";

const SendToVerifyButton = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [sending, setSending] = React.useState(false);

  const send = () => {
    setSending(true);

    api.survey.collectorPanelSendVerify(props.surveyId)
    .then(data => {
      setSending(false);
      props.onSent();

    })
    .catch(() => {
      setSending(false);

    })
  }

  return <React.Fragment>
    <Button color="primary" className="ml-2" onClick={toggleModalOpen}>Tak, chcę poznać wycenę poniższej grupy respondentów.</Button>
    <Modal isOpen={modalOpen} toggle={toggleModalOpen} size="md">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Zgłoszenie prośby o wycenę realizacji wywiadów</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        Wyceny wykonujemy  na podstawie potencjalnej długości wywiadu (LOI), rodzaju struktury/charakterystyki targetu i liczby wywiadów.  W ciągu 1 dnia roboczego na swojego maila otrzymasz propozycję cenową od jednego z naszych pracowników.  Za zgłoszenie wyceny nie poniesiesz żadnych kosztów.
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <Button
          color={'primary'}
          loading={sending}
          onClick={send}
        >Zgłoś</Button>
      </ModalFooter>
    </Modal>
  </React.Fragment>
}

function CollectorsPanelVerify(props) {

  const isPendingVeification = () => {
    return props.collectors.filter(collector => ['Panel'].includes(collector.type) && !collector.verified && !collector.verifySent).length > 0;
  }

  const isVerified = () => {
    return props.collectors.filter(collector => ['Panel'].includes(collector.type) && !collector.verified).length === 0;
  }

  return (
    <Alert color={isPendingVeification() ? 'danger' : isVerified() ? 'success' : 'warning'}>
      {
        isPendingVeification()
          ? <div>
            <span>Utworzone kolektory nie zostały jeszcze zgłoszone do wyceny i realizacji.</span>
            <SendToVerifyButton surveyId={props.surveyId} onSent={() => props.onChange()} />
          </div>
          : isVerified()
            ? <span>Grupy respondentów zostały zweryfikowane i trwa realizacja wywiadów. W trakcie zbierania wywiadów nie możesz usuwać ani edytować ustawień kolektorów.</span>
            : <span>Twoje zapytanie jest w trakcie weryfikacji. Odpowiemy na nie na maila przypisanego do konta.</span>
      }
    </Alert>
  );
}

CollectorsPanelVerify.propTypes = {
  collectors: PropTypes.arrayOf(PropTypes.shape({
    verifySent: PropTypes.bool.isRequired,
  }).isRequired,).isRequired,
  surveyId: PropTypes.number,
};

export default CollectorsPanelVerify;