import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {Col, Row, Label, Input, Tooltip} from "reactstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import './FilterDate.css';
import {updateFilterDate} from 'scenes/Survey/Analysis/reducer/filterDate/actions';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import AnalysisFilteringAvailable from "components/PlanRestrictionsModals/modals/AnalysisFilteringAvailable";

class FilterDate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: props.filterDate.startDate ? moment(props.filterDate.startDate) : null,
      endDate: props.filterDate.endDate ? moment(props.filterDate.endDate) : null,
      startTooltip: false,
      endTooltip: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date, field) {
    this.setState({
      startTooltip: false,
      endTooltip: false,
      [field]: date
    });

    this.props.updateFilterDate({
      id: this.props.filterDate.id,
      filter: {
        [field]: date ? date.format('YYYY-MM-DD HH:mm') : null,
      }
    }).then(() => this.props.onSaved());
  }

  render() {
    return (
      <React.Fragment>
        <Row className="mt-4">
          <Col>
            <Label>
              <FormattedMessage id="filterDate.header">{msg => <span className="float-left">{msg}</span>}</FormattedMessage>
            </Label>
          </Col>
        </Row>
        <Row onClick={() => this.props.analysisPlan.filtering ? null : this.props.setPlanRestrictionsModals(AnalysisFilteringAvailable.getName())}>
          <Col lg={6} className="px-1">
            <Input id="filter-date-start" disabled={!this.props.analysisPlan.filtering} className="fs-12" value={this.state.startDate ? moment(this.state.startDate).format('YYYY-MM-DD HH:mm') : ""} placeholder="Data od" onClick={() => this.props.analysisPlan.filtering ? this.setState({startTooltip: !this.state.startTooltip, endTooltip: false}) : this.props.setPlanRestrictionsModals(AnalysisFilteringAvailable.getName())} onChange={() => {}}/>
            {this.state.startDate && <i className="fas fa-times-circle text-danger fs-12 position-absolute" onClick={() => this.handleChange(null, 'startDate')} style={{top: 10, right: 10}}/>}
            <Tooltip
              delay={0}
              placement="top"
              isOpen={this.state.startTooltip}
              target={'filter-date-start'}
              arrowClassName="d-none"
              innerClassName="bg-color-inherit"
            >
              <FormattedMessage id="filterDate.time">{msg => <DatePicker
                inline
                isClearable={true}
                selected={this.state.startDate}
                onChange={(date) => this.handleChange(date, 'startDate')}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption={msg}
                locale={localStorage.language}
              />}</FormattedMessage>
            </Tooltip>
          </Col>
          <Col lg={6} className="px-1">
            <Input id="filter-date-end" disabled={!this.props.analysisPlan.filtering} className="fs-12" value={this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD HH:mm') : ""} placeholder="Data do" onClick={() => this.props.analysisPlan.filtering ? this.setState({endTooltip: !this.state.endTooltip, startTooltip: false}) : this.props.setPlanRestrictionsModals(AnalysisFilteringAvailable.getName())} onChange={() => {}}/>
            {this.state.endDate && <i className="fas fa-times-circle text-danger fs-12 position-absolute" onClick={() => this.handleChange(null, 'endDate')} style={{top: 10, right: 10}}/>}
            <Tooltip
              delay={0}
              placement="top"
              isOpen={this.state.endTooltip}
              target={'filter-date-end'}
              arrowClassName="d-none"
              innerClassName="bg-color-inherit"
            >
              <FormattedMessage id="filterDate.time">{msg => <DatePicker
                inline
                isClearable={true}
                selected={this.state.endDate}
                onChange={(date) => this.handleChange(date, 'endDate')}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption={msg}
                locale={localStorage.language}
              />}</FormattedMessage>
            </Tooltip>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

FilterDate.propTypes = {
  onSaved: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    filterDate: state.survey.analysis.data.analysis.filterDate,
    analysisPlan: state.user.userPlan.plan.analysis
  }
}

export default connect(mapStateToProps, {updateFilterDate, setPlanRestrictionsModals})(FilterDate);