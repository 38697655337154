import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import {connect} from "react-redux";
import SurveyRedirectsAvailable from "components/PlanRestrictionsModals/modals/SurveyRedirectsAvailable";

class RedirectsDeleteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    };

    this.toggle = this.toggle.bind(this);
    this.deleteRedirect = this.deleteRedirect.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]});
  }

  deleteRedirect() {
    this.setState({deleting: true});

    api.survey.delete.redirect(this.props.redirect.id).then(() => {
      this.setState({loading: false, modal: false});
      this.props.onDelete();
    });
  }

  render() {
    const modal =
      <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')}>
        <ModalHeader toggle={() => this.toggle('modal')} tag="div"><FormattedMessage tagName="h2" id="redirect.actions.delete.modalHeader"/></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <FormattedMessage tagName="p" id="redirect.actions.delete.modalBody"/>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.delete">{
                (message) => <LaddaButton
                  className="btn btn-danger btn-ladda mb-0 ml-3"
                  loading={this.state.deleting}
                  data-style={ZOOM_OUT}
                  onClick={this.deleteRedirect}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggle('modal')}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    ;

    return (
      <React.Fragment>
        <a className="fas fa-trash" onClick={() => this.props.surveyPlan.redirects ? this.toggle('modal') : this.props.setPlanRestrictionsModals(SurveyRedirectsAvailable.getName())}/>
        {modal}
      </React.Fragment>
    );
  }
}

RedirectsDeleteButton.propTypes = {
  redirect: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(RedirectsDeleteButton)
