import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import striptags from "striptags";
import {FormGroup, Button, Input} from "reactstrap";
import {connect} from "react-redux";
import Select from "react-select";
import {FormattedMessage} from "react-intl";

class Ranked extends Component {

  constructor(props) {
    super(props);
    this.changeAnswers = this.changeAnswers.bind(this);
    this.addAnswer = this.addAnswer.bind(this);
    this.removeAnswer = this.removeAnswer.bind(this);
    this.onPlaceChange = this.onPlaceChange.bind(this);
  }

  changeAnswers(key, name, value) {
    let answers = this.props.filter.answers;
    answers[key][name] = value;

    this.props.handleChange(answers);
  }

  onPlaceChange(key, value) {
    const question = this.props.questions.find(q => q.id === this.props.questionId);
    let answers = this.props.filter.answers;

    value = parseInt(value || 0);

    if (value > question.places) {
      value = question.places
    }

    if (value < 0) {
      value = 0
    }

    answers[key].place = value;
    this.props.handleChange(answers);
  }

  addAnswer() {
    const {filter, questions, questionId, handleChange} = this.props;
    const question = questions.find(q => q.id === questionId);

    if (question.answers.length > 0) {
      let firstAnswerId = question.answers[0];
      let answers = filter.answers;
      answers.push({answer: firstAnswerId, place: 0});

      handleChange(answers);
    }
  }

  removeAnswer(index) {
    let answers = this.props.filter.answers;
    answers.splice(index, 1);
    this.props.handleChange(answers);
  }

  render() {
    const {questionId, questions, answers, filter} = this.props;
    const question = questions.find(q => q.id === questionId);
    let options = [];

    _.each(question.answers, answerId => options.push({label: striptags(question.answersImage ? answers[answerId].description : answers[answerId].content), value: answerId}));

    return (
      <FormGroup>
        <div>
          <span className="w-50 d-inline-block text-center" style={{fontSize: '0.85em'}}><FormattedMessage id="filtersQuestionAnswer.ranked.label.answer"/></span>
          <span className="w-50 d-inline-block text-center" style={{fontSize: '0.85em'}}><FormattedMessage id="filtersQuestionAnswer.ranked.label.place"/></span>
        </div>
        {_.map(filter.answers, (answer, key) => {
          return <div key={key} className="mb-2 d-flex align-items-center">
            <Select
              value={{label: striptags(question.answersImage ? answers[answer.answer].description : answers[answer.answer].content), value: answer.answer}}
              onChange={e => this.changeAnswers(key, 'answer', e.value)}
              options={options}
              className="w-50 d-inline-block mr-1"
            />
            <Input type="number" className="w-50 d-inline-block mb-0" name="place" min={0} max={question.places} onChange={e => this.onPlaceChange(key, e.target.value)} value={answer.place}/>
            <i className="fa fa-times mx-1" onClick={() => this.removeAnswer(key)}/>
          </div>
        })}
        {question.answers.length > 0 ? <Button className="w-100 text-center mt-3" size="sm" color="primary" onClick={this.addAnswer}><i className="fa fa-plus"/> Dodaj</Button> : <span className="text-danger small text-center d-block"><FormattedMessage id="filtersQuestionAnswer.ranked.answers.empty"/></span>}
      </FormGroup>
    )
  }
}

Ranked.propTypes = {
  questionId: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  answers: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    answers: state.survey.analysis.data.answers,
  }
}

export default connect(mapStateToProps)(Ranked);