import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input} from "reactstrap";
import {injectIntl} from "react-intl";
import { v4 as uuidv4 } from 'uuid';
import Tooltip from "../../../../../../../../../components/Tooltip";

const detailTypes = ['equals', 'notEquals', 'greaterThenOrEquals', 'lesserThenOrEquals'];

class Numeric extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isNumericDropdownDetailType: false,
      inputTooltipId: 'x' + uuidv4(),
    };

    this.toggleIsNumericDropdownDetailType = this.toggleIsNumericDropdownDetailType.bind(this);
  }

  componentWillMount() {
    const {condQuestion} = this.props;
    let condQuestionChanged = condQuestion;

    if(_.isUndefined(condQuestion.detailType) || !detailTypes.includes(condQuestion.detailType)){ condQuestionChanged.detailType = detailTypes[0]; }
    if(_.isUndefined(condQuestion.value)){ condQuestionChanged.value = ''; }

    if(condQuestion !== condQuestionChanged){
      Numeric.propTypes.handleCondChanged(condQuestionChanged);
    }
  }

  toggleIsNumericDropdownDetailType(){
    this.setState({
      isNumericDropdownDetailType: !this.state.isNumericDropdownDetailType,
    })
  }

  render() {
    const {isNumericDropdownDetailType} = this.state;
    const {intl, condQuestion, changeable} = this.props;

    return (
      <React.Fragment>
        <span>wprowadzi wartość </span>

        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isNumericDropdownDetailType} toggle={this.toggleIsNumericDropdownDetailType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Numeric.detailType-' + condQuestion.detailType})}</DropdownToggle>
          <DropdownMenu>
            {detailTypes.map(detailType => <DropdownItem tag="a" key={detailType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              detailType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.Numeric.detailType-' + detailType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condDetail.Numeric.detailType-' + condQuestion.detailType})} </span>}

        {changeable ? <React.Fragment>
          <i id={this.state.inputTooltipId} className="fas fa-info-circle ml-1 text-warning" />
          <Tooltip msg={'Uwaga. Jeśli pytanie numeryczne lub z suwakiem zawiera miejsca po przecinku, aby ustawić filtr uwzględniający miejsca po przecinku wymień wszystkie cyfry. Np. 12,34 - ustaw 1234,   12345,1 - ustaw 123451'} id={this.state.inputTooltipId} />
          <Input
            className="w-25 d-inline ml-2 mb-0"
            size="xs"
            value={condQuestion.value}
            onChange={e => this.props.handleCondChanged({
              ...condQuestion,
              value: e.target.value,
            })}
          />
        </React.Fragment> : condQuestion.value}

      </React.Fragment>
    );
  }
}

Numeric.defaultProps = {
  changeable: true
};

Numeric.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number.isRequired,
    detailType: PropTypes.oneOf(detailTypes),
    value: PropTypes.string.isRequired,
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(Numeric);
