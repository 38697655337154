import React, {Component} from 'react';
import PropTypes from "prop-types";
import SettingBool from "../../../SettingBool";
import SettingText from "../../../SettingText";
import SettingNumber from "../../../SettingNumber";
import BptoChangesType from "../../../BptoChangesType";

class Bpto extends Component {

  render() {
    const {question} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'required'} value={question.required} icon={<i className="fa-solid fa-message-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <SettingText question={question} name={'currency'} value={question.currency} icon={<i className="fa-solid fa-dollar-sign" />} handleUpdate={this.props.handleUpdate}/>
        <BptoChangesType question={question} updateQuestion={this.props.handleUpdate} />
        <SettingNumber float question={question} name={'incrementValue'} value={question.incrementValue} icon={<i className="fa-solid fa-arrow-up-long" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber float question={question} name={'decrementValue'} value={question.decrementValue} icon={<i className="fa-solid fa-arrow-down-long" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber float question={question} name={'maxSteps'} value={question.maxSteps} icon={<i className="fak fa-max-square" />} handleUpdate={this.props.handleUpdate}/>
      </React.Fragment>
    )
  }
}

Bpto.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default Bpto;
