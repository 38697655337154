import React, {PureComponent} from 'react';
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import AsyncSelect from 'react-select/lib/Async';
import api from "api";
import LoadingSpinner from "components/LoadingSpinner";


class CitiesSelectModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mapping: false,
      modalOpen: false,
      loading: false,
      values: [],
      searchTimeout: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.fetchCities = this.fetchCities.bind(this);
  }

  getLabel(city){
    return {
      value: city.id,
      label: <div className="w-100">
        <p className="mb-0">{city.name}</p>
        <small className="mb-0 text-muted">lm {city.lm}, gm. {city.gmina}, pow. {city.powiat}, woj. {city.wojewodztwo}</small>
      </div>,
    }
  }

  mapPropsValuesToState(){
    if(_.isEmpty(this.props.values)){
      this.setState({
        values: []
      });
    }else{
      this.setState({
        mapping: true
      });

      api.city.post.search({
        search: '',
        page: 1,
        pageSize: 999999,
        filters: {
          cities: this.props.values.join(',')
        }
      })
      .then(data => {
        this.setState({
          mapping: false,
          values: data.cities.map(city => this.getLabel(city))
        })
      })
      .catch(() => {
        this.setState({
          mapping: false,
        });
      });
    }
  }

  fetchCities(search, callback){
    clearTimeout(this.state.searchTimeout);

    this.setState({
      searchTimeout: setTimeout(() => {
        api.city.post.search({
          search,
          page: 1,
          pageSize: 20,
        })
        .then(data => {
          callback(data.cities.map(city => this.getLabel(city)));
        });
      }, 1000)
    });
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    }, () => {
      if(this.state.modalOpen){
        setTimeout(() => {
          this.mapPropsValuesToState()
        }, 1)
      }
    })
  }

  onSubmit(){
    if(this.props.onAccept){
      this.props.onAccept(this.state.values.map(value => value.value));
    }

    this.toggleModal();
  }

  render() {
    const {modalOpen, loading, values, mapping} = this.state;

    return <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal}>Lista miast</ModalHeader>
      <ModalBody>
        {mapping
          ? <LoadingSpinner width={20} classNames="my-0" />
          : <AsyncSelect
              isMulti
              cacheOptions
              loadOptions={this.fetchCities}
              onChange={values => this.setState({values})}
              value={values}
            />
        }
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={loading}
            data-style={ZOOM_OUT}
            onClick={this.onSubmit}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;
  }
}

CitiesSelectModal.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  onAccept: PropTypes.func,
};

export default CitiesSelectModal;