export function surveyLink(guid, absolute = true, appendJwt = false, surveyResponseId = -1, questionId = null, params = {}, subdomain = null) {
  let url;

  url = '/s/' + guid;

  if (subdomain) {
    url = subdomain + url;
  } else {
    url = process.env.ANKIETEO_FRONTEND + url;
  }

  if(appendJwt && localStorage.jwt){
    url += '?auth=' + localStorage.jwt;
  }

  if (questionId) {
    url += '&question=' + questionId;
  }

  if (surveyResponseId > 0) {
    url += '&id=' + surveyResponseId;
  }

  for (const [key, value] of Object.entries(params)) {
    url += "&" + key + "=" + value;
  }

  return url;
}
