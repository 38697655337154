import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from 'reactstrap';
import ConfigurationDeleteButton from "./ConfigurationDeleteButton";
import ConfigurationEditButton from "./ConfigurationEditButton";
import ConfigurationCopyButton from "./ConfigurationCopyButton";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";

class ConfigurationList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
    this.actions = this.actions.bind(this);
  }

  fetchData(state) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.configuration.post.searchConfigurations(data).then(res => {
      this.setState({searching: false, data: res.configurations, count: res.count});
    });
  }

  actions(configuration){
    const handler = () => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});
    return (
      <ButtonGroup>
        <ConfigurationEditButton configuration={configuration} handleChange={handler}/>
        <ConfigurationCopyButton configuration={_.omit(configuration, ['id'])} handleChange={handler}/>
        <ConfigurationDeleteButton configuration={configuration} handleChange={handler}/>
      </ButtonGroup>
    )
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="admin.configurationList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="admin.configurationList.listItems.tableHeader.key" />,
      accessor: 'key'
    }, {
      Header: <FormattedMessage id="admin.configurationList.listItems.tableHeader.value" />,
      accessor: 'value'
    }, {
      id: 'actions',
      Header: <FormattedMessage id="admin.configurationList.listItems.tableHeader.actions" />,
      accessor: configuration => this.actions(configuration),
      sortable: false
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.configurationList.listItems.table.noConfigurations" />}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

export default ConfigurationList;