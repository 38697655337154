import React, {PureComponent} from 'react';
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import api from "../../../api";
import Select from "react-select";


class ExcludedUsersSelectModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      optionValue: 'user',
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  onSubmit(){
    const {optionValue} = this.state;

    this.setState({
      loading: true,
    });

    switch (optionValue) {
      case 'user': {
        api.user.get.collectorPanelUsersExcluded()
        .then(data => {
          this.props.onAccept(data.collectorPanelUsersExcluded);
          this.setState({
            loading: false,
          });
          this.toggleModal();
        });

        break;
      }
      case 'group': {
        api.group.get.usersCollectorPanelUsersExcluded()
        .then(data => {
          this.props.onAccept(data.collectorPanelUsersExcluded);
          this.setState({
            loading: false,
          });
          this.toggleModal();
        });

        break;
      }
      default: {
        this.toggleModal();
      }
    }
  }

  render() {
    const {modalOpen, loading, optionValue} = this.state;
    const {userInGroup} = this.props;

    let options = [];
    options.push({
      label: 'Moja lista zablokowanych panelistów',
      value: 'user'
    });
    if(userInGroup){
      options.push({
        label: 'Listy zablokowanych panelistów od wszystkich użytkowników w grupie',
        value: 'group'
      });
    }

    return <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal}>Pobierz listę wykluczonych panelistów</ModalHeader>
      <ModalBody>
        <Select
          options={options}
          value={options.find(option => option.value === optionValue)}
          onChange={option => this.setState({
            optionValue: option.value
          })}
        />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.download">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={loading}
            data-style={ZOOM_OUT}
            onClick={this.onSubmit}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;
  }
}

ExcludedUsersSelectModal.propTypes = {
  onAccept: PropTypes.func,
  userInGroup: PropTypes.bool.isRequired,
};

export default ExcludedUsersSelectModal;