import * as React from 'react';
import PropTypes from "prop-types";
import {omit} from "lodash";
import {Link as LinkRouter} from "react-router-dom";
import uuid from "react-uuid";
import Tooltip from "../components/Tooltip";

function Link(props) {
  const tId = 't-' + uuid();
  let linkProps = omit(props, ['disableRedirect'])

  return <React.Fragment>
    <LinkRouter
      id={tId}
      className={props.className}
      target={props.target}
      {...linkProps}
      onClick={e => {
        if(props.onClick){
          props.onClick(e);
        }else{
          if(props.disableRedirect){
            e.preventDefault();
          }
        }
      }}
    >{props.children}</LinkRouter>
    {props.tooltip && <Tooltip msg={props.tooltip} id={tId} />}
  </React.Fragment>;
}

Link.defaultProps = {
  disableRedirect: false,
  className: '',
};

Link.propTypes = {
  tooltip: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disableRedirect: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

export default Link;
