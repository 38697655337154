import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader, Tooltip, ModalFooter, Col, Input} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {has} from "lodash";
import api from "api";
import SurveysBaseTermsCheckbox from "components/SurveysBaseTermsCheckbox";
import Switch from "../../../../components/Switch";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import {FormattedMessage} from "react-intl";
import HelpModeIconTooltip from "components/HelpModeIconTooltip";

const STATUS_REPORTED = 1;
const STATUS_REJECTED = 2;
const STATUS_ACCEPTED = 3;
const STATUS_WAITING = 4;

class SurveysBaseStatusIcon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: '',
      tooltip: false,
      modal: false,
      regulationsAccepted: false,
      saving: false,
      modalBaseSurveysTerms: false,
      enabledRating: true,
      msg: "",
    };

    this.toggle = this.toggle.bind(this);
    this.getStatusColor = this.getStatusColor.bind(this);
    this.rereportSurvey = this.rereportSurvey.bind(this);
    this.createSurveyBaseCollector = this.createSurveyBaseCollector.bind(this);
    this.getStatusModalTitle = this.getStatusModalTitle.bind(this);
    this.getTooltipDescription = this.getTooltipDescription.bind(this);
  }

  rereportSurvey(){
    const {survey} = this.props;
    const {msg} = this.state;

    this.setState({
      saving: true,
    });

    api.survey.patch.swPanel.rereport(survey.id, {msg: msg})
      .then(() => {
        this.setState({
          saving: false,
          modal: false,
        });

        this.props.onReReported();
      })
      .catch(() => {
        this.setState({
          saving: false
        });
      })
  }

  createSurveyBaseCollector() {
    const {survey} = this.props;

    this.setState({
      saving: true,
      error: '',
    });

    const data = {
      survey: survey.id,
      collector: {
        type: 'BaseSurveys',
        enabledRating: this.state.enabledRating
      }
    };

    api.survey.post.collector(data)
      .then(() => {
        this.setState({
          saving: false,
          modal: false,
        });

        this.props.onReReported();
      })
      .catch(res => {
        this.setState({
          saving: false,
          error: has(res, 'response.data.errors[0].m') ? res.response.data.errors[0].m : ''
        });
      })
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  getStatusColor() {
    const swPanel = this.props.survey.swPanel;

    if(!swPanel || this.props.survey.hasTopSurveysCollector){
      return 'secondary';
    }

    switch (swPanel.status) {
      case STATUS_REPORTED:
      case STATUS_WAITING:
        return 'primary';
      case STATUS_REJECTED:
        return 'danger';
      case STATUS_ACCEPTED:
        return 'success';
    }
  }

  getStatusDescription() {
    const swPanel = this.props.survey.swPanel;

    if(!swPanel){
      return 'Kliknij Dodaj do bazy ankiet aby umieścić ankietę w bazie ankiet i umożliwić jej wypełnianie przez tysiące potencjalnych respondentów. Wszystkie zgłoszone ankiety podlegają moderacji. Czas oczekiwania wynosi do 6h roboczych.';
    }

    if (this.props.survey.hasTopSurveysCollector) {
      return 'Ta ankieta posiada aktywny kolektor top ankiet. Jeżeli chcesz ją umieścić w bazie musisz najpierw usunąć ją z rankingu top ankiet.';
    }

    switch (swPanel.status) {
      case STATUS_REPORTED:
      case STATUS_WAITING:
        return 'Oczekuje na potwierdzenie';
      case STATUS_REJECTED:
        let msg = this.props.survey.isBannedFromBaseSurveys ? 'Ankieta została zablokowana przed dodaniem do bazy ankiet' : 'Ankieta została odrzucona';

        if(swPanel.msg){
          msg += ' - ' + swPanel.msg;
        }

        return msg;
      case STATUS_ACCEPTED:
        let m = ['Ankieta została zaakceptowana przez administratora'];
        if(swPanel.msg){
          m.push(swPanel.msg);
        }
        return m.join(' - ');
    }
  }

  getTooltipDescription() {
    if (!this.props.survey.swPanel || this.props.survey.hasTopSurveysCollector) {
      return 'Kliknij aby skierować ankietę do umieszczenia w bazie ankiet w swpanel.pl.';
    }

    return this.getStatusDescription();
  }

  getStatusModalTitle() {
    if (!this.props.survey.swPanel || this.props.survey.hasTopSurveysCollector){
      return 'Umieszczanie ankiety w bazie ankiet swpanel.pl';
    }

    return 'Status ankiety';
  }

  render() {
    const {saving, regulationsAccepted, error, msg} = this.state;
    const hasSurveysBaseSwPanel = this.props.survey.swPanel && !this.props.survey.hasTopSurveysCollector;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')} >
      <ModalHeader toggle={() => this.toggle('modal')} tag="h2">{this.getStatusModalTitle()}</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p>{this.getStatusDescription()}</p>
        {!hasSurveysBaseSwPanel && !this.props.survey.hasTopSurveysCollector && <React.Fragment>
          <FormGroup row className="my-4">
            <Label lg={6}>
              <FormattedMessage tagName="small" id="collectorBaseSurveys.label.enabledRating" />
              <HelpModeIconTooltip className="ml-2" autohide={false}><FormattedMessage id="surveysBaseStatusIcon.tooltip" /> LINK</HelpModeIconTooltip>
            </Label>
            <Col lg={6} className="pt-2">
              <Switch checked={this.state.enabledRating} onChange={enabledRating => this.setState({enabledRating})} />
            </Col>
          </FormGroup>

          <SurveysBaseTermsCheckbox onChange={() => this.toggle('regulationsAccepted')} checked={regulationsAccepted} />

          {error && <p className="mt-3 text-danger">{error}</p>}
        </React.Fragment>}
        {hasSurveysBaseSwPanel && this.props.survey.swPanel.status === STATUS_REJECTED && !this.props.survey.isBannedFromBaseSurveys && <FormGroup row>
          <Label md={12}>Wpisz wiadomość dla administratora:</Label>
          <Col md={12}>
            <Input type="textarea" onChange={e => this.setState({msg: e.target.value})} value={msg} />
          </Col>
        </FormGroup>}
      </ModalBody>
      {hasSurveysBaseSwPanel && this.props.survey.swPanel.status === STATUS_REJECTED && !this.props.survey.isBannedFromBaseSurveys && <ModalFooter>
        <LaddaButton
          className="btn btn-primary btn-ladda"
          loading={saving}
          data-style={ZOOM_OUT}
          onClick={() => this.rereportSurvey()}
        >Zgłoś ponownie</LaddaButton>
      </ModalFooter>}
      {!this.props.survey.swPanel && !this.props.survey.hasTopSurveysCollector && <ModalFooter>
        <LaddaButton
          className="btn btn-primary btn-ladda"
          loading={saving}
          data-style={ZOOM_OUT}
          onClick={() => this.createSurveyBaseCollector()}
          disabled={!regulationsAccepted}
        >Dodaj do bazy ankiet</LaddaButton>
      </ModalFooter>}
    </Modal>;

    return (
      <React.Fragment>
        <button className='btn p-0'>
          <i id={this.props.type + "-survey-base-status-" + this.props.survey.id} className={"fas fa-circle pointer text-" + this.getStatusColor() + " " + this.props.className} onClick={() => this.toggle('modal')}/>
          <Tooltip
            delay={0}
            placement="bottom"
            isOpen={this.state.tooltip}
            target={this.props.type + '-survey-base-status-' + this.props.survey.id}
            toggle={() => this.toggle('tooltip')}
          >
            {this.getTooltipDescription()}
          </Tooltip>
          {modal}
        </button>
      </React.Fragment>
    )
  }
}

SurveysBaseStatusIcon.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isBannedFromBaseSurveys: PropTypes.bool.isRequired,
    swPanel: PropTypes.shape({
      status: PropTypes.number.isRequired,
      msg: PropTypes.string,
    }),
  }).isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  rejectMessage: PropTypes.string,
  onReReported: PropTypes.func.isRequired,
};

export default SurveysBaseStatusIcon;