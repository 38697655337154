import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Route, Redirect} from "react-router-dom";
import appRoutes from '../appRoutes';

const GuestRoute = ({ isAuthenticated, isAdmin, component: Component, ...rest}) => {

  return (
      <Route
        {...rest}
        children={props => !isAuthenticated ? <Component {...props} /> : (isAdmin ? <Redirect to={appRoutes.admin.main} /> : <Redirect to={appRoutes.surveys.list} />)}
      />
  )
};

GuestRoute.defaultProps = {
  isAuthenticated: false,
  isAdmin: false,
};

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: Object.keys(state.user).length > 0,
    isAdmin: state.user.roles && (state.user.roles.includes('ROLE_SUPER_ADMIN') || state.user.roles.includes('ROLE_ADMIN')),
  }
}

export default connect(mapStateToProps)(GuestRoute);