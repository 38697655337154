export const isWebview = () => {
	if (typeof window === 'undefined') { return false };

	const navigator = window.navigator;

	const standalone = navigator.standalone;
	const userAgent = navigator.userAgent.toLowerCase();
	const safari = /safari/.test(userAgent);
	const ios = /iphone|ipod|ipad/.test(userAgent);

	return ios ? !standalone && !safari : /\bwv\b/.test(userAgent);
}