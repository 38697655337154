import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../../components/Button";
import Tooltip from "../../../components/Tooltip";

const Autorefresh = props => {
  const refreshRef = React.useRef();
  const [enabled, setEnabled] = React.useState(false);

  return (
    <React.Fragment>
      <Button
        id={'surveys-autorefresh'}
        size="sm"
        className="ml-2"
        color={enabled ? 'primary' : 'secondary'}
        onClick={() => {
          setEnabled(!enabled);

          clearInterval(refreshRef.current);
          if(!enabled){
            props.onRefresh();
            refreshRef.current = setInterval(() => {
              props.onRefresh();
            }, 1000 * 60);
          }else{
            refreshRef.current = null;
          }
        }}
      ><i className="fas fa-sync-alt" /></Button>
      <Tooltip id={'surveys-autorefresh'} msg="Automatyczne odświeżanie listy projektów" />
    </React.Fragment>
  );
};

Autorefresh.propTypes = {
  onRefresh: PropTypes.func.isRequired,
};

export default Autorefresh;
