import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import SelectAnswers from "../../../SelectAnswers";

class QuestionAnswer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalAnswer: props.answer,
    };

    this.toggleModalAnswer = this.toggleModalAnswer.bind(this);
  }

  toggleModalAnswer(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {question, answer, answersObject, changeable, questionObject} = this.props;
    const {modalOpen, modalAnswer} = this.state;
    const answerObject = answersObject.find(q => q.id === answer);

    if (!changeable) {
      const tooltipId = "answerTooltipId-" + Math.random().toString(36).substring(7);
      return answerObject ? <React.Fragment>
        <span className="pointer" id={tooltipId}>{answerObject.position + 1}. </span>
        <UncontrolledTooltip target={tooltipId}>
          {questionObject.answersImage ? <img src={answerObject.content} className="img-100"/> : <span className="img-30" dangerouslySetInnerHTML={{__html: answerObject.stripedContent}}/>}
        </UncontrolledTooltip>
      </React.Fragment> : null;
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalAnswer} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalAnswer}>Wybierz odpowiedź</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <SelectAnswers questionId={question} value={modalAnswer}  isMulti={false} onChange={answer => {
          this.setState({
            modalAnswer: answer,
            modalOpen: false,
          }, () => {
            this.props.handleAnswerChanged(answer);
          });
        }} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleAnswerChanged(modalAnswer);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={answer ? 'secondary' : 'warning'} className="mr-1 question-badge ws-nowrap img-20 ch-50 pointer d-inline-flex flex-wrap" onClick={this.toggleModalAnswer}>
          {questionObject.answersImage
            ? <span className="img-20">{answerObject.position + 1}. <img src={answerObject.content}/></span>
            : <span dangerouslySetInnerHTML={{__html: answer ? (answerObject.position+1 + '. ' + answerObject.stripedContent) : 'Wybierz odpowiedź...'}}/>
          }
        </Badge>
        {modal}
      </React.Fragment>
    );
  }
}

QuestionAnswer.defaultProps = {
  changeable: true
};

QuestionAnswer.propTypes = {
  question: PropTypes.number.isRequired,
  answer: PropTypes.number,
  handleAnswerChanged: PropTypes.func,
  changeable: PropTypes.bool
};

function mapStateToProps(state, props) {
  let answersObject = [];
  if(props.question && 'answers' in state.survey.structure.data.questions[props.question]){
    answersObject = state.survey.structure.data.questions[props.question].answers.map(aId => state.survey.structure.data.answers[aId]);
    let position = 0;
    answersObject = answersObject.map(answer => {
      const ret = {
        ...answer,
        position
      };

      position++;
      return ret;
    });
  }


  return {
    answersObject,
    questionObject: state.survey.structure.data.questions[props.question]
  }
}

export default connect(mapStateToProps)(QuestionAnswer);