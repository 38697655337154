import React, {Component} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import FormCollectorPanelUsersExcluded from "./components/FormCollectorPanelUsersExcluded";
import {connect} from "react-redux";

class SettingsGeneral extends Component {
  componentDidMount() {
    document.title = 'Ankieteo - Ustawienia';
  }
  render() {
    const {collectorPanelUsersExcluded} = this.props;

    return <Card className="h-100 mb-0">
      <CardHeader><h2><i className="fas fa-cog mr-2" /> Ustawienia</h2></CardHeader>
      <hr className="my-0" />
      <CardBody>
        {collectorPanelUsersExcluded && <FormCollectorPanelUsersExcluded />}
      </CardBody>
    </Card>;
  }
}

function mapStateToProps(state) {
  return {
    collectorPanelUsersExcluded: state.user.userPlan.plan.collectorPanelUsersExcluded
  }
}

export default connect(mapStateToProps)(SettingsGeneral);