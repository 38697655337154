import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert, Card, Col, Progress, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import QuestionSummaryTable from "./QuestionSummaryTable";

class QuestionSummaryNps extends Component {

  render() {
    const {questionSummary} = this.props;
    const {data} = questionSummary;

    let dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <React.Fragment>
        <Row>
          <Col md={3}>
            <Card>
              <CardHeader className="text-center"><h2>Wynik NPS</h2></CardHeader>
              <hr className="my-0" />
              <CardBody className="text-center"><span style={{fontSize: '2em'}}>{questionSummary.data.data.nps.toFixed(2)}</span></CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <CardHeader className="text-center"><h2 className="text-danger">Krytycy</h2></CardHeader>
              <hr className="my-0" />
              <CardBody className="text-center"><span className="text-danger" style={{fontSize: '2em'}}>{questionSummary.data.data.criticsPercent.toFixed(2)}% ({questionSummary.data.data.criticsCount})</span></CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <CardHeader className="text-center"><h2 className="text-warning">Neutralni</h2></CardHeader>
              <hr className="my-0" />
              <CardBody className="text-center"><span className="text-warning" style={{fontSize: '2em'}}>{questionSummary.data.data.neutralsPercent.toFixed(2)}% ({questionSummary.data.data.neutralsCount})</span></CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <CardHeader className="text-center"><h2 className="text-success">Promotorzy</h2></CardHeader>
              <hr className="my-0" />
              <CardBody className="text-center"><span className="text-success" style={{fontSize: '2em'}}>{questionSummary.data.data.promotorsPercent.toFixed(2)}% ({questionSummary.data.data.promotorsCount})</span></CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Progress multi style={{height: '30px'}}>
              <Progress bar value={questionSummary.data.data.criticsPercent} color="danger">Krytycy</Progress>
              <Progress bar value={questionSummary.data.data.neutralsPercent} color="warning">Neutralni</Progress>
              <Progress bar value={questionSummary.data.data.promotorsPercent} color="success">Promotorzy</Progress>
            </Progress>
          </Col>
        </Row>
        {dataExists && <div class={"mt-4"}>
          <QuestionSummaryTable data={data.data.answers} questionSummary={questionSummary} />
        </div>}
      </React.Fragment>
    )
  }
}

QuestionSummaryNps.propTypes = {
  questionSummary: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.shape({
        criticsCount: PropTypes.number.isRequired,
        criticsPercent: PropTypes.number.isRequired,
        neutralsCount: PropTypes.number.isRequired,
        neutralsPercent: PropTypes.number.isRequired,
        promotorsCount: PropTypes.number.isRequired,
        promotorsPercent: PropTypes.number.isRequired,
        nps: PropTypes.number.isRequired,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.oneOf([0,1,2,3,4,5,6,7,8,9,10]).isRequired,
            count: PropTypes.number.isRequired,
          }).isRequired,
        ).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

export default QuestionSummaryNps;