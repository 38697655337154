import React, {PureComponent} from 'react';
import PropTypes from "prop-types";
import api from "api";
import {connect} from "react-redux";
import withParams from "../../../../utils/withParams";

class SurveyProgress extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      survey: null,
    };
  }

  componentDidMount() {
    api.survey.getResponsesCount(this.props.params.survey)
    .then(data => {
      this.setState({
        survey: data.survey,
      })
    })
  }

  render() {
    const endResponsesCount = this.state.survey ? this.state.survey.endResponsesCount : 0;
    const screenOutResponsesCount = this.state.survey ? this.state.survey.screenOutResponsesCount : 0;
    const quotaResponsesCount = this.state.survey ? this.state.survey.quotaResponsesCount : 0;
    const responsesMax = this.state.survey ? this.state.survey.responsesMax : 0;

    return (
      <div className="float-right sub-nav  d-none d-xl-block">
        <p>Wypełnionych ankiet:</p>
        <label className="m-0">
          <span>{endResponsesCount}{(screenOutResponsesCount > 0 || quotaResponsesCount > 0) && <small> (+ {screenOutResponsesCount + quotaResponsesCount})</small>}</span>/{responsesMax}
        </label>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default withParams(connect(mapStateToProps)(SurveyProgress));