import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {updateQuestion} from "scenes/Survey/Structure/data/questions/actions";
import {addAnswer, updateAnswer} from "scenes/Survey/Structure/data/answers/actions";
import {Rnd} from "react-rnd";
import striptags from "striptags";
import reactHtmlParser from "react-html-parser";

import "./style.scss";

class HeatMap extends Component {
  render() {
    const {question, answers} = this.props;

    return (
      <div>
        <h3 className="text-center my-4">Ustawienia pozycji odpowiedzi {question.heatMapBg && <i
          className="fas fa-image pointer ml-4"
          onClick={() => CKFinder.popup({
            connectorInfo: 'auth=' + localStorage.jwt,
            chooseFiles: true,
            width: 1014,
            height: 700,
            onInit: finder => {
              finder.on('files:choose', e => {
                this.props.updateQuestion({
                  id: this.props.question.id,
                  question: {
                    heatMapBg: e.data.files.first().getUrl()
                  }
                });
              });
            }
          })}
        />}</h3>
        {question.heatMapBg ? <div className="d-flex">
          <div className="mx-auto position-relative shadow border">
            <img src={question.heatMapBg} />
            <div className="position-absolute" id="heat-map-area" style={{
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}>
              {answers.map(answer => {
                return <Rnd
                  className="answer-heat-map d-flex justify-content-center align-items-center"
                  default={{
                    x: answer.heatMapX || 0,
                    y: answer.heatMapY || 0,
                    width: answer.heatMapWidth || 100,
                    height: answer.heatMapHeight || 100,
                  }}
                  onDragStop={(e, d) => {
                    this.props.updateAnswer({
                      id: answer.id,
                      answer: {
                        heatMapWidth: d.node.offsetWidth,
                        heatMapHeight: d.node.offsetHeight,
                        heatMapX: d.x,
                        heatMapY: d.y,
                      }
                    });
                  }}
                  onResizeStop={(e, direction, ref, delta, position) => {
                    this.props.updateAnswer({
                      id: answer.id,
                      answer: {
                        heatMapX: position.x,
                        heatMapY: position.y,
                        heatMapWidth: ref.offsetWidth,
                        heatMapHeight: ref.offsetHeight,
                      }
                    });
                  }}
                >
                  <span className="d-block w-75 text-dark text-nowrap overflow-hidden" style={{fontSize: '0.7em', textOverflow: 'ellipsis', background: '#ffffffb8'}}>{reactHtmlParser(striptags(answer.content))}</span>
                </Rnd>
              })}
            </div>
          </div>
        </div> : <p
                    className="text-center py-4 pointer"
                    onClick={() => CKFinder.popup({
                      connectorInfo: 'auth=' + localStorage.jwt,
                      chooseFiles: true,
                      width: 1014,
                      height: 700,
                      onInit: finder => {
                        finder.on('files:choose', e => {
                          this.props.updateQuestion({
                            id: this.props.question.id,
                            question: {
                              heatMapBg: e.data.files.first().getUrl()
                            }
                          });
                        });
                      }
                    })}
                  >Brak zdjęcia, kliknij tutaj aby ustawić</p>}
      </div>
    );
  }
}

HeatMap.propTypes = {
  question: PropTypes.shape({
    heatMapBg: PropTypes.string,
  }).isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string,
  }),).isRequired,
};

function mapStateToProps(state) {
  return {

  }
}

export default connect(mapStateToProps, {updateAnswer, updateQuestion})(HeatMap);