import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {deleteSmtp} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

class SmtpsButtonDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    }
  }

  smtpDelete() {
    this.setState({
      deleting: true
    });

    this.props.deleteSmtp({
      id: this.props.smtp.id
    }).then(() => this.setState({deleting: false, modal: false}));
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { deleting } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()}>
      <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id="smtpsButtonDelete.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="smtpsButtonDelete.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.modal.delete.confirmation">{
              (message) => <LaddaButton
                className="btn btn-danger btn-ladda mb-0 ml-3"
                loading={deleting}
                data-style={ZOOM_OUT}
                onClick={() => this.smtpDelete()}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggleModal()}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="m-0 p-0" onClick={() => this.toggleModal()}><i className="fas fa-times"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

SmtpsButtonDelete.propTypes = {
  smtp: PropTypes.object.isRequired,
};

export default connect(null, { deleteSmtp })(SmtpsButtonDelete);