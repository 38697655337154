import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {
  Alert,
  Row,
  Col,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  TabPane, InputGroup, Input, InputGroupAddon
} from "reactstrap";
import {updateAnalysis} from '../../../reducer/analysis/actions';
import LoadingSpinner from "components/LoadingSpinner";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {toast} from "react-toastify";
import BrowseDeleteModal from "./BrowseDeleteModal";
import Single from "./Types/Single";
import Multi from "./Types/Multi";
import MultiOpen from "./Types/MultiOpen";
import Video from "./Types/Video";
import Matrix from "./Types/Matrix";
import Ranked from "./Types/Ranked";
import Conjoint from "./Types/Conjoint";
import MatrixMulti from "./Types/MatrixMulti";
import MatrixDropdown from "./Types/MatrixDropdown";
import NumericMulti from "./Types/NumericMulti";
import Numeric from "./Types/Numeric";
import api from "api";
import Open from "./Types/Open";
import HelpModeIconModal from "components/HelpModeIconModal";
import Basket from "./Types/Basket";
import GMap from "./Types/GMap";
import Nps from "./Types/Nps";
import MaxDiff from "./Types/MaxDiff";
import Comment from "./Types/Comment";
import Bpto from "./Types/Bpto";
import Differential from "./Types/Differential";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";
import EditSurveyResponseButton from "./EditSurveyResponseButton";
import SurveyResponseStatus from "./SurveyResponseStatus";
import Button from "../../../../../../components/Button";
import FileUpload from "./FileUpload";
import {getQueryParam} from "../../../../../../services/getQueryParam";

const ExcludeResponsesBulkModal = (props) => {
  const [saving, setSaving] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [text, setText] = React.useState('');

  const save = () => {
    setSaving(true);

    let excludedResponses = text.split(',').join("\n").split("\n").filter(t => !!t).map(t => parseInt(t)).concat(props.analysis.excludedResponses);

    api.analysis.patch.analysis({
      id: props.analysis.id,
      analysis: {
        excludedResponses
      },
    }).then(() => {
      toast.success("Poprawnie zapisano wykluczone wywiady");
      setSaving(false);
      setText('');
      toggleModalOpen();
      props.onSaved();
    }).catch(() => {
      setSaving(false);
    })
  }

  return <React.Fragment>
    <Button color="secondary" onClick={toggleModalOpen}><i className="fas fa-warning" /> Wyklucz listę wywiadów</Button>
    {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="lg">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Wyklucz listę wywiadów</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <Form>
          <Input type="textarea" rows={10} placeholder="Podaj listę id wywiadów, które chcesz wykluczyć" onChange={e => setText(e.target.value.replace(/[^0-9\,\n]/gi, '').replace(',,', ',').replace("\n\n", "\n"))} value={text} />
        </Form>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <Button
            color={"primary"}
            loading={saving}
            onClick={save}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
}

class Browse extends Component {

  constructor(props) {
    super(props);

    let search = '';
    if (props.match.params.response) {
      search = props.match.params.response;
    }

    if (getQueryParam('search')) {
      search = getQueryParam('search');
    }

    this.state = {
      search: search,
      page: 1,
      total: 0,
      loading: false,
      deleting: false,
      changingExcludeStatus: false,
      deleteModal: false,
      response: null,
    }
  }

  componentDidMount(){
    this.getResponse();
  }

  getRespondentInfo(response){
    if('unknown' in response){
      return response.unknown.uniqueKey;
    }

    if('user' in response){
      return response.user.id;
    }

    return <i>Brak danych</i>
  }

  getResponse() {
    const {analysis} = this.props;
    this.setState({loading: true});

    let page = this.state.page;
    if(this.state.search){
      this.setState({page: 1});
      page = 1;
    }

    api.analysis.post.searchResponse({
      id: analysis.id
    }, analysis.lang, page, this.state.search).then(data => {
      this.setState({
        response: data.response,
        total: data.total,
        loading: false,
      });
    })
  }

  changeResponse(value){
    let newPage = this.state.page + value;

    if(newPage < 1){
      newPage = 1;
    }
    if(newPage > this.state.total){
      newPage = this.state.total;
    }

    this.setState({
      page: newPage
    }, () => this.getResponse());
  }

  deleteResponse(){
    const id = this.state.response.id;

    this.setState({
      deleting: true,
      deleteModal: false
    });
    
    api.surveyResponse.delete.surveyResponse({
      id
    }).then(() => {
      this.changeResponse(-1);
      this.setState({deleting: false});
    }).catch(res => {
      this.setState({deleting: false});
    });
  }

  updateExcludeStatus(response) {
    this.setState({
      ...this.state,
      changingExcludeStatus: true
    });

    let data = {
      id: this.props.analysis.id,
      analysis: {
        excludedResponses: this.props.analysis.excludedResponses
      }
    };

    let excludedResponses = data.analysis.excludedResponses;
    let message;

    if (excludedResponses.includes(response.id)) {
      excludedResponses.splice(excludedResponses.indexOf(response.id));
      message = <FormattedMessage id="analysis.browse.toast.include"/>
    } else {
      excludedResponses.push(response.id);
      message = <FormattedMessage id="analysis.browse.toast.exclude"/>
    }

    data.analysis.excludedResponses = excludedResponses;

    this.props.updateAnalysis(data).then(() => {
      this.setState({
        ...this.state,
        changingExcludeStatus: false
      });
      toast.success(message);
    });
  }

  static questionResponseByType(question, details){
    const props = {
      question,
      details
    };

    switch (question.type) {
      case 'matrix':
        return <Matrix {...props}/>;
      case 'multi':
        return <Multi {...props}/>;
      case 'multiOpen':
        return <MultiOpen details={details}/>;
      case 'open':
        return <Open details={details}/>;
      case 'ranked':
        return <Ranked {...props}/>;
      case 'conjoint':
        return <Conjoint {...props}/>;
      case 'single':
        return <Single {...props}/>;
      case 'video':
        return <Video {...props}/>;
      case 'matrixMulti':
        return <MatrixMulti {...props}/>;
      case 'matrixDropdown':
        return <MatrixDropdown {...props}/>;
      case 'numeric':
        return <Numeric details={details}/>;
      case 'numericMulti':
        return <NumericMulti {...props}/>;
      case 'basket':
        return <Basket details={details}/>;
      case 'gMap':
        return <GMap details={details}/>;
      case 'nps':
        return <Nps details={details}/>;
      case 'maxDiff':
        return <MaxDiff {...props}/>;
      case 'comment':
        return <Comment {...props}/>;
      case 'bpto':
        return <Bpto {...props}/>;
      case 'differential':
        return <Differential {...props}/>;
    }
  }

  render() {
    const {loading, deleting, response, page, total, changingExcludeStatus, deleteModal} = this.state;
    const {plan} = this.props;

    if(!plan.analysis.responsePreview){
      return <Alert color="warning" className="text-center">
        <span>Przegląd wywiadów nie jest dostępny w Twoim planie. <Link to={appRoutes.users.plan} style={{fontSize: '16px'}}>Kliknij, aby zmienić plan</Link></span>
      </Alert>
    }

    if(loading){
      return <LoadingSpinner/>
    }

    if(total == 0 || !response){
      return <Alert color="warning" className="text-center"><FormattedMessage id="analysis.browse.alert.noData"/></Alert>
    }

    const params = _.map(response.params, (value, key) => { return key + ': ' +value});

    return (
      <TabPane
        className="fade show p-0 d-flex flex-column flex-grow pb-sm-5 pb-lg-1"
        style={{
          height: '100%',
          margin: '0'
        }}
      >
        <div className="p-0 py-2 px-lg-5 d-flex justify-content-between">
          <ExcludeResponsesBulkModal analysis={this.props.analysis} />
        </div>
        <div className="p-0 py-2 px-lg-5 d-flex justify-content-between">
          <Button
            onClick={() => this.changeResponse(-1)}
            disabled={page <= 1}
          >
            <i className="fas fa-chevron-left"/>
            <FormattedMessage id="analysis.browse.nav.prevResponse">{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
          </Button>
          <InputGroup className="w-25 mb-0">
            <Input placeholder="Wyszukaj wywiad..." onChange={e => this.setState({search: e.target.value})} value={this.state.search}/>
            <InputGroupAddon addonType={'append'}><Button color="primary" onClick={() => this.getResponse()}><i className="fa fa-search" /></Button></InputGroupAddon>
          </InputGroup>
          <Button
            onClick={() => this.changeResponse(1)}
            disabled={page >= total}
          >
            <FormattedMessage id="analysis.browse.nav.nextResponse">{msg => <span className="d-none d-lg-inline mr-2">{msg}</span>}</FormattedMessage>
            <i className="fas fa-chevron-right"/>
          </Button>
        </div>

        <div className="p-0 py-2 px-lg-5 h-100" style={{
          'overflow': 'scroll'
        }}>
          <Card className="border-l violet m-0 p-0">
            <CardHeader className="p-3">
              <FormattedMessage id="analysis.browse.nav.response">{msg => <h2 className="float-left mb-0 mr-3 p-0">{msg}</h2>}</FormattedMessage>
              <small className="float-left mb-0 mr-3 p-0">{page}/{total}</small>
              <HelpModeIconModal modalHeader="Wywiady">
                <p>W wywiadach masz możliwość dokładnego przeanalizowania każdego wypełnienia Twojej ankiety. Znajdziesz tu zarówno wporowadzone odpowiedzi jak i informacje o samym wypełnieniu (jego status, urządzenie itp.)</p>
              </HelpModeIconModal>
            </CardHeader>
            <hr className="m-0" />
            <CardBody>
              <Row>
                <Col md={6}>
                  <ListGroup>
                    <ListGroupItemHeading className="text-center"><FormattedMessage id="analysis.browse.infoBox.response.header"/></ListGroupItemHeading>
                    <ListGroupItem className="d-flex align-items-center"><FormattedMessage id="analysis.browse.infoBox.response.status">{(msg) => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>: <SurveyResponseStatus surveyResponse={response} /></ListGroupItem>
                    <ListGroupItem><FormattedMessage id="analysis.browse.infoBox.response.collector">{(msg) => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>: {response.collectorName}</ListGroupItem>
                    <ListGroupItem><FormattedMessage id="analysis.browse.infoBox.response.createdAt">{(msg) => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>: {response.createdAt}</ListGroupItem>
                    <ListGroupItem><FormattedMessage id="analysis.browse.infoBox.response.dateEnd">{(msg) => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>: {response.dateEnd}</ListGroupItem>
                    {!_.isEmpty(response.params) && <ListGroupItem><FormattedMessage id="analysis.browse.infoBox.response.params">{(msg) => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>: <br />{params.map(param => <p className="mb-0">{param}</p>)}</ListGroupItem>}

                    <ListGroupItem>
                      <Row>
                        <Col xs={12} className="mb-1">
                          {this.props.analysis.excludedResponses.includes(response.id) ?
                            <FormattedMessage id="analysis.browse.button.include">{
                              (message) => <LaddaButton
                                className="btn btn-sm btn-primary btn-ladda w-100 d-block"
                                loading={changingExcludeStatus}
                                data-style={ZOOM_OUT}
                                onClick={() => this.updateExcludeStatus(response)}
                              ><i className="fas fa-exclamation" /> {message}</LaddaButton>
                            }</FormattedMessage>
                            :
                            <FormattedMessage id="analysis.browse.button.exclude">{
                              (message) => <LaddaButton
                                className="btn btn-sm btn-warning btn-ladda w-100 d-block"
                                loading={changingExcludeStatus}
                                data-style={ZOOM_OUT}
                                onClick={() => this.updateExcludeStatus(response)}
                              ><i className="fas fa-exclamation" /> {message}</LaddaButton>
                            }</FormattedMessage>
                          }
                        </Col>
                        {plan.analysis.editableSurveyResponses && <Col xs={12} xl={6} className="mb-1 mb-xl-0">
                          <EditSurveyResponseButton surveyResponse={response} onModalClosed={() => this.getResponse()} />
                        </Col>}
                        {plan.analysis.deletableSurveyResponses && <Col xs={12} xl={6} className="mb-1 mb-xl-0">
                          <FormattedMessage id="analysis.browse.button.delete">{
                            (message) => <LaddaButton
                              className="btn btn-sm btn-danger btn-ladda w-100 d-block"
                              loading={deleting}
                              data-style={ZOOM_OUT}
                              onClick={() => this.setState({...this.state, deleteModal: true})}
                            ><i className="fas fa-trash" /> {message}</LaddaButton>
                          }</FormattedMessage>

                          {deleteModal && <BrowseDeleteModal handleDelete={() => this.deleteResponse()} toggleModal={() => this.setState({...this.state, deleteModal: false})}/>}
                        </Col>}
                      </Row>

                    </ListGroupItem>
                  </ListGroup>
                </Col>
                <Col md={6}>
                  <ListGroup>
                    <ListGroupItemHeading className="text-center"><FormattedMessage id="analysis.browse.infoBox.respondent.header"/></ListGroupItemHeading>
                    <ListGroupItem><FormattedMessage id="analysis.browse.infoBox.respondent.id">{msg => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>: {this.getRespondentInfo(response)}</ListGroupItem>
                    <ListGroupItem><FormattedMessage id="analysis.browse.infoBox.respondent.browser">{msg => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>: {response.browserName} {response.browserVersion}</ListGroupItem>
                    <ListGroupItem><FormattedMessage id="analysis.browse.infoBox.respondent.os">{msg => <span className="font-weight-bold">{msg}</span>}</FormattedMessage>: {response.osName} {response.osVersion}</ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>

            </CardBody>
          </Card>

          {response.questionResponses.map(qResponse => {
            console.log('qResponse', qResponse);
            return (
              <Card className="mt-4 border-l orange" key={qResponse.id}>
                <CardHeader><h2 dangerouslySetInnerHTML={{__html: qResponse.question.content}} className="img-30"/></CardHeader>
                <hr className="my-0" />
                <CardBody>
                  {qResponse.question.fileUpload && <FileUpload questionResponse={qResponse} />}
                  {qResponse.excluding ? <span dangerouslySetInnerHTML={{__html: qResponse.excluding}}/> : Browse.questionResponseByType(qResponse.question, qResponse.details)}
                </CardBody>
              </Card>
            )
          })}
        </div>
      </TabPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps, {updateAnalysis})(Browse);
