import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody} from "reactstrap";

const NotFound = props => {
  return (
    <Card>
      <CardBody className=" mx-auto my-2 py-2 my-md-5 py-md-5">
        <div className="d-flex flex-column flex-md-row flex-nowrap align-items-center">
          <span className="mr-md-4" style={{fontSize: '9em', color: '#e0e9ee', marginBottom: '22px'}}>404</span>
          <div className="d-flex flex-column font-weight-bold text-center text-md-left" style={{fontSize: '4em', color: '#23a9e2', lineHeight: '0.9em'}}>
            <span>PAGE</span>
            <span>NOT FOUND</span>
            <a href="//ankieteo.pl/kontakt" className="text-secondary" target="_blank" style={{ marginTop: '20px', lineHeight: '10px', textDecoration: 'underline' }}>Zgłoś błąd</a>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

NotFound.propTypes = {

};

export default NotFound;
