import React, {Component} from 'react';
import PropTypes from "prop-types";
import SettingNumber from "../../../SettingNumber";
import DefaultMap from "./components/DefaultMap";

class GmapSettings extends Component {

  render() {
    const {question} = this.props;

    return (
      <React.Fragment>
        <SettingNumber question={question} name={'answersMin'} value={question.answersMin || 3} icon={<i className="fa-solid fa-location-minus" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'answersCount'} value={question.answersCount || 3} icon={<i className="fa-solid fa-location-plus" />} handleUpdate={this.props.handleUpdate}/>
        <DefaultMap question={question} handleUpdate={this.props.handleUpdate} />
      </React.Fragment>
    )
  }
}

GmapSettings.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default GmapSettings;
