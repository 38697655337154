import {schema} from 'normalizr';

import schemaAnswers from "../answer/schema";
import schemaAttributes from "../attributes/schema";
import schemaBaskets from "../basket/schema";
import schemaRows from "../row/schema";
import schemaColumns from "../column/schema";
import schemaScalePoints from "../scalePoint/schema";
import schemaQuestionExcludings from "../questionExcluding/schema";
import schemaJavascripts from "../javascript/schema";

export default new schema.Entity('questions', {
  answers: [schemaAnswers],
  attributes: [schemaAttributes],
  baskets: [schemaBaskets],
  scalePoints: [schemaScalePoints],
  rows: [schemaRows],
  columns: [schemaColumns],
  excludings: [schemaQuestionExcludings],
  javascripts: [schemaJavascripts],
});