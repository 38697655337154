import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';
import api from "api";

class QuestionTagSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tagList: [],
      value: props.value || [],
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true
    });

    api.tagsQuestion.get()
    .then(tags => {
      this.setState({
        tagList: tags,
        loading: false,
      })
    })
  }

  onChange(options){
    const value = options.map(o => o.value);

    this.setState({
      value,
    });
    this.props.onChange(options);
  }

  render() {
    const {loading, value, tagList} = this.state;

    const SelectComponent = this.props.isCreateable ? CreatableSelect : Select;

    return (
      <SelectComponent
        isMulti
        placeholder={"Wybierz tagi..."}
        isLoading={loading}
        value={value.map(tag => {
          let t = tagList.find(t => t.name === tag);

          if(!t){
            return {
              id: null,
              label: <span>{tag}</span>,
              value: tag,
            }
          }

          return {
            id: t.id,
            label: <span>{t.name} ({t.questionsCount})</span>,
            value: t.name
          }

        })}
        onInputChange={value => value.replace(/[^a-zA-Z0-9\s+]+/gi, '').replace(/\s\s+/g, ' ')}
        onChange={e => { this.onChange(e) }}
        options={tagList.map(tag => {
          return {
            id: tag.id,
            label: <span>{tag.name} ({tag.questionsCount})</span>,
            value: tag.name
          }
        })}
      />
    );
  }
}

QuestionTagSelect.defaultProps = {
  isCreateable: true,
};

QuestionTagSelect.propTypes = {
  isCreateable: PropTypes.bool.isRequired,
  value: PropTypes.arrayOf(PropTypes.string,),
  onChange: PropTypes.func.isRequired,
};

export default QuestionTagSelect;