import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroup, InputGroupAddon, Tooltip} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";

class AnswersType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: {
        answersType: props.question.answersType,
      },
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(value){
    this.setState({
      question: {
        ...this.state.question,
        answersType: value.value
      }
    },
      () => this.props.handleUpdate(this.state.question)
    );
  }

  render() {
    const {questionConfig} = this.props;
    let { question, tooltip } = this.state;

    return (
      <InputGroup className="mr-1 w-100 w-lg-auto float-left mb-1" id={"answersType-" + this.props.question.id}>
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon active": true,
            })}
            color="secondary"
          >
            <i className="fa-solid fa-keyboard" />
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltip}
              target={"answersType-" + this.props.question.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="question.settings.answersType.button.tooltip"/>
            </Tooltip>
          </Button>
        </InputGroupAddon>

        <Select
          classNamePrefix="react-select"
          className="react-select float-left"
          value={{
            value: question.answersType,
            label: question.answersType
          }}
          onChange={this.onChange}
          options={questionConfig.answersTypes.map(type => {
            return {
              value: type,
              label: type,
            }
          })}
        />

      </InputGroup>
    )
  }
}

AnswersType.propTypes = {
  questionConfig: PropTypes.shape({
    answersTypes: PropTypes.array.isRequired,
  }).isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answersType: PropTypes.oneOf(['input', 'textarea']).isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default connect((state) => {
  return {
    questionConfig: state.config.data.questions.multiOpen
  }
})(AnswersType);
