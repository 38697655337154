import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';

class MultiOpen extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {details} = this.props;

    return <ul>
      <li>{details[0] ? striptags(details[0].content) : <i>Brak odpowiedzi</i>}</li>
    </ul>;
  }
}

MultiOpen.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string.isRequired
  })).isRequired
};

export default MultiOpen;
