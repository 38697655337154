import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import QuestionContentTooltip from "../../../../../../components/QuestionContentTooltip/QuestionContentTooltip";
import {connect} from "react-redux";

FilterIterationActions.propTypes = {

};

function FilterIterationActions(props) {
  const {filter} = props;

  return <ol className="p-0 m-0">Pokaż iteracyjnie: {_.map(filter.questionsTo, questionTo => {
    let filterQuestionTo = _.find(props.questions, question => question.id === questionTo.question);
    return <li key={questionTo.id}>- <FormattedMessage id={"_.question.cafetery." + questionTo.filterBy} /> w pytaniu <QuestionContentTooltip question={filterQuestionTo}/></li>;
  })}</ol>
}

function mapStateToProps(state) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    questions: questions,
  }
}

export default connect(mapStateToProps)(FilterIterationActions);