import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import api from "api";


class ButtonDeleteQuota extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      deleting: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.deleteQuota = this.deleteQuota.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  deleteQuota(){
    const {quota} = this.props;

    this.setState({
      deleting: true
    });

    api.quota.delete(quota)
      .then(res => {
        this.setState({
          modalOpen: false,
          deleting: false,
        });

        this.props.onDeleted(quota);
      })
      .catch(res => {
        this.setState({
          deleting: false,
        });
      })
    ;
  }

  render() {
    const {modalOpen, deleting} = this.state;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal} tag="h2">Potwierdź usunięcie kwoty</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        Czy na pewno chcesz usunąć tą kwotę?
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.delete">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda mb-0"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={this.deleteQuota}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <a className="ml-2" onClick={this.toggleModal}>
          <i className="fas fa-trash"/>
        </a>
        {modal}
      </React.Fragment>
    );
  }
}

ButtonDeleteQuota.propTypes = {
  quota: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onDeleted: PropTypes.func.isRequired,
};

export default ButtonDeleteQuota;