import api from "api";
import schemaSets from "entities/setBlocks/schema";
import {normalize} from "normalizr";

import * as pagesActions from "../pages/actions";
import * as questionsActions from "../questions/actions";
import * as answerActions from "../answers/actions";
import * as rowActions from "../rows/actions";
import * as columnActions from "../columns/actions";
import * as excludingsActions from "../excludings/actions";
import * as blocksActions from "../blocks/actions";
import * as surveyActions from "../survey/actions";
import * as filtersActions from "../filters/actions";
import * as pipingsActions from "../pipings/actions";
import * as filterSerialActions from "../filterSerial/actions";
import schemaSurvey from "entities/survey/schema";
import * as basketActions from "../baskets/actions";
import * as scalePointsActions from "../scalePoints/actions";
import * as javascriptActions from "../javascripts/actions";
import * as filterIterativeActions from "../filterIterative/actions";
import {setSurvey} from "../survey/actions";

export const SET = 'Survey/data/sets/SET';
export const ADDED = 'Survey/data/sets/ADDED';
export const DELETED = 'Survey/data/sets/DELETED';
export const UPDATED = 'Survey/data/sets/UPDATED';
export const SET_BLOCKS = 'Survey/data/sets/SET_BLOCKS';
export const BLOCK_ADDED = 'Survey/data/sets/BLOCK_ADDED';

export function setBlocks(id, blocks) {
  return {
    type: SET_BLOCKS,
    data: {
      id,
      blocks
    }
  }
}

export function setSets(sets) {
  return {
    type: SET,
    sets
  }
}
export function setAdded(set) {
  return {
    type: ADDED,
    set
  }
}
export function setDeleted(set) {
  return {
    type: DELETED,
    set
  }
}
export function setUpdated(set) {
  return {
    type: UPDATED,
    set
  }
}
export function blockAdded(block) {
  return {
    type: BLOCK_ADDED,
    block
  }
}

export function addSet(data) {
  return dispatch => {
    return api.survey.post.set(data).then((set) => {
      dispatch(setAdded(set));
      dispatch(surveyActions.setAdded(set));

      return set;
    })
  }
}

export function deleteSet(data) {
  return dispatch => {
    return api.setBlocks.delete(data).then(() => {
      dispatch(setDeleted(data));
    });
  }
}

export function updateSet(data) {
  return dispatch => {
    return api.setBlocks.patch(data).then(set => {
      const normalized = normalize(set, schemaSets);
      dispatch(setUpdated(normalized.entities.sets[normalized.result]));

      return set;
    })
  }
}

export function copySet(data) {
  return dispatch => {
    return api.setBlocks.post.copy(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(scalePointsActions.setScalePoints(normalized.entities.scalePoints || {}));
      dispatch(javascriptActions.setJavascripts(normalized.entities.javascripts || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(pagesActions.setPages(normalized.entities.pages || {}));
      dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
      dispatch(setSets(normalized.entities.sets || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(filterIterativeActions.setFilterIterative(normalized.entities.filterIterative || {}));
      dispatch(setSurvey(survey));
    });
  }
}

export function autoPaginateSet(data) {
  return dispatch => {
    return api.setBlocks.patchAutoPaginate(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(pagesActions.setPages(normalized.entities.pages || {}));
      dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
      dispatch(setSets(normalized.entities.sets || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(surveyActions.setSurvey(survey));
    })
  }
}

export function removePageBreaks(data) {
  return dispatch => {
    return api.setBlocks.removePageBreaks(data).then(surveyData => {
      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(surveyActions.setSurvey(survey));
      dispatch(setSets(normalized.entities.sets || {}));
      dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
      dispatch(pagesActions.setPages(normalized.entities.pages || {}));
    })
  }
}