import { combineReducers } from 'redux'

import {reducer as respondentsReducer} from "./Respondents/combineReducer";
import {reducer as companiesReducer} from "./Companies/combineReducer";
import {reducer as surveysReducer} from "./Surveys/combineReducer";
import {reducer as pointsReducer} from "./Points/combineReducer";
import {reducer as plansReducer} from "./Plans/combineReducer";
import {reducer as prizesReducer} from "./Prizes/combineReducer";
import {reducer as prizeCategoriesReducer} from "./PrizeCategories/combineReducer";
import {reducer as codesReducer} from "./Codes/combineReducer";
import {reducer as userPrizesReducer} from "./UserPrizes/combineReducer";
import {reducer as reportsReducer} from "./Reports/combineReducer";
import {reducer as newsReducer} from "./News/combineReducer";
import {reducer as responsesReducer} from "./SurveyResponses/combineReducer";
import {reducer as surveyTemplatesReducer} from "./SurveyTemplates/combineReducer";

export const reducer = combineReducers({
  respondents: respondentsReducer,
  companies: companiesReducer,
  surveys: surveysReducer,
  points: pointsReducer,
  plans: plansReducer,
  prizes: prizesReducer,
  prizeCategories: prizeCategoriesReducer,
  codes: codesReducer,
  userPrizes: userPrizesReducer,
  reports: reportsReducer,
  news: newsReducer,
  surveyResponses: responsesReducer,
  surveyTemplates: surveyTemplatesReducer,
});

