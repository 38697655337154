import React, {Component} from 'react';
import {connect} from "react-redux";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from "api";
import appRoutes from "appRoutes";
import history from "services/history";

class AnalysisCreateButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creating: false,
    };

    this.createAnalysis = this.createAnalysis.bind(this);
  }

  createAnalysis(){
    const {surveyId} = this.props;

    this.setState({
      creating: true
    });

    api.survey.post.analysis({
      survey: surveyId
    }).then(analysis => {
      history.push(appRoutes.survey.analysis.questionSummaries(surveyId, analysis.id));
      this.setState({
        creating: false
      });
    }).catch(() => {
      this.setState({
        creating: false
      });
    });
  }

  render() {
    const {creating} = this.state;

    return (
        <LaddaButton
          loading={creating}
          className="btn btn-sm btn-primary btn-ladda w-100 mt-3"
          data-style={ZOOM_OUT}
          onClick={this.createAnalysis}
        >
          <i className="fas fa-plus" /> Utwórz nową analizę
        </LaddaButton>
    )
  }
}

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
    surveyId: state.survey.analysis.data.analysis.survey,
  }
}

export default connect(mapStateToProps)(AnalysisCreateButton);