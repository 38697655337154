import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../../components/Button";
import QuotaNewFromTextareaModal from "./QuotaNewFromTextareaModal";

const SurveyQuotaNewFromTextarea = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  return (
    <React.Fragment>
      <Button className="ml-2" onClick={() => setModalOpen(!modalOpen)}><i className="fas fa-plus" /> Utwórz z pola tekstowego</Button>
      {modalOpen && <QuotaNewFromTextareaModal
        onClose={toggleModalOpen}
      />}
    </React.Fragment>
  );
};

SurveyQuotaNewFromTextarea.propTypes = {

};

export default SurveyQuotaNewFromTextarea;
