import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import SelectQuestions from "../../../../SelectQuestions";
import _ from "lodash";

class Questions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalQuestions: props.action.questions || [],
    };

    this.toggleModalQuestions = this.toggleModalQuestions.bind(this);
  }

  toggleModalQuestions(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {action, questions, changeable, grayedQuestionsToPositionInSurvey} = this.props;
    const {modalOpen, modalQuestions} = this.state;

    if (!changeable) {
      return action.questions.map((id, i, arr) => {
        const tooltipId = "questionTooltipId-" + Math.random().toString(36).substring(7);
        let divider = i < arr.length - 1 && '| ';
        const question = questions.find(q => q.id === id);

        if(question){
          return <React.Fragment key={id}>
            <span id={tooltipId} className="pointer">P. {question.positionInSurvey} </span>
            <UncontrolledTooltip target={tooltipId}>
              <span className="img-30" dangerouslySetInnerHTML={{__html: question.stripedContent}}/>
            </UncontrolledTooltip>
            {divider}
          </React.Fragment>
        }else{
          return null;
        }
      })
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalQuestions} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalQuestions}>Wybierz pytania</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-75 d-inline-block">
          <SelectQuestions grayedToPositionInSurvey={grayedQuestionsToPositionInSurvey} value={modalQuestions} onChange={questions => {
            this.setState({
              modalQuestions: questions,
            })
          }} />
        </div>
        <div className="w-25 d-inline-block pl-3">
          Wybranych pytań: {modalQuestions.length}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleQuestionsChanged(modalQuestions);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={!_.isEmpty(action.questions) ? 'secondary' : 'warning'} className="mx-1 pointer d-inline-flex flex-wrap" onClick={this.toggleModalQuestions}>{!_.isEmpty(action.questions) ? action.questions.map((id, i, arr) => {
          let divider = i < arr.length - 1 && <span className="mx-1">|</span>;
          const question = questions.find(q => q.id === id);

          return <React.Fragment key={id}><span className="ch-50 img-20 question-badge ws-nowrap d-inline-block" dangerouslySetInnerHTML={{__html: 'P' + question.positionInSurvey + '. ' + question.stripedContent}} />{divider}</React.Fragment>;
        }) : 'Wybierz pytania...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

Questions.defaultProps = {
  changeable: true,
  grayedQuestionsToPositionInSurvey: null,
};

Questions.propTypes = {
  action: PropTypes.shape({
    questions: PropTypes.array.isRequired,
  }).isRequired,
  handleQuestionsChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired,
  grayedQuestionsToPositionInSurvey: PropTypes.number,
};

function mapStateToProps(state) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    questions
  }
}

export default connect(mapStateToProps)(Questions);
