import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Badge, Card, CardBody, CardFooter, CardHeader, Col, Input, Row} from "reactstrap";
import api from "../../api";
import LoadingSpinner from "../LoadingSpinner";
import Button from "../Button";
import QuestionsListModal from "./QuestionsListModal";

const SurveyTemplatesCreate = props => {
  const fetchTimeout = React.useRef();
  const [surveys, setSurveys] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [copying, setCopying] = React.useState(false);

  React.useEffect(() => {
    fetchSurveys(0);
  }, []);

  const fetchSurveys = (delay = 500, query = '') => {
    clearTimeout(fetchTimeout.current);

    fetchTimeout.current = setTimeout(() => {
      setLoading(true);

      api.survey.templates({
        query,
      }).then(({data}) => {
        setSurveys(data.data);
      }).finally(() => {
        setLoading(false);
      })
    }, delay);
  }

  const onQueryChange = (query) => {
    setQuery(query);

    fetchSurveys(500, query);
  }

  const copySurvey = (survey) => {
    setCopying(survey.id);

    api.survey.post.copy({
      survey: survey.id,
      data: {
        name: survey.name,
        tags: survey.tags ? survey.tags.split(',') : [],
        client: null,
      }
    })
      .then((survey) => {
        props.onCreated(survey);
      })
      .catch(e => {
      });
  }
  
  return (
    <div className="d-flex flex-column">
      <div>
        <Input
          className="w-100 w-md-50 w-lg-25 mx-auto d-block"
          placeholder="Wyszukaj szablon..."
          onChange={e => onQueryChange(e.target.value)}
          value={query}
        />
      </div>

      <div className="mt-3 d-flex flex-wrap">
        {loading && <LoadingSpinner />}
        {!loading && surveys.length === 0 && <Alert color="warning" className="text-center w-100">Brak szablonów ankiet</Alert>}
        {!loading && surveys.length > 0 && <Row className="w-100">
          {surveys.map(survey => <Col className="mb-3" key={`s-${survey.id}`} xs={12} sm={6} lg={4} xl={3}>
            <Card className="h-100">
              <CardHeader><span className="d-block py-2">{survey.name}</span></CardHeader>
              <hr className="my-0" />
              <CardBody className="pb-0 d-flex flex-column">
                <p>{survey.description || <small className="text-dark">Brak opisu</small>}</p>

                <div className="d-flex justify-content-between align-items-center mt-auto">
                  <QuestionsListModal surveyId={survey.id} />
                  <small>Liczba użyć: {parseInt(survey.templateUsed || 0)}</small>
                </div>
              </CardBody>
              <CardFooter className="d-flex flex-row justify-content-between align-items-center" style={{height: '55px'}}>
                <div>
                  <Badge>{survey.tags}</Badge>
                </div>
                <Button loading={copying === survey.id} onClick={() => copySurvey(survey)}>Utwórz ankietę</Button>
              </CardFooter>
            </Card>
          </Col>)}
        </Row>}
      </div>
    </div>
  );
};

SurveyTemplatesCreate.propTypes = {
  onCreated: PropTypes.func.isRequired,
};

export default SurveyTemplatesCreate;