import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Input, Tooltip} from "reactstrap";
import classnames from "classnames";


class Instruction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: {
        instruction: props.question.instruction,
        instructionText: props.question.instructionText || '',
        instructionTextMobile: props.question.instructionTextMobile || '',
      },
      instructionTooltip: false,
      instructionDefaultTooltip: false,
    };

    this.toggleInstructionTooltip = this.toggleInstructionTooltip.bind(this);
    this.toggleInstructionDefaultTooltip = this.toggleInstructionDefaultTooltip.bind(this);
    this.toggleInstruction = this.toggleInstruction.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  toggleInstructionTooltip() {
    this.setState({
      instructionTooltip: !this.state.instructionTooltip
    });
  }
  toggleInstructionDefaultTooltip() {
    this.setState({
      instructionDefaultTooltip: !this.state.instructionDefaultTooltip
    });
  }

  onChange(e){
    this.setState({
      question: { ...this.state.question, [e.target.name]: e.target.value }
    });
  }

  handleKeyPress(e){
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  toggleInstruction() {
    this.setState({
      question: {
        ...this.state.question,
        instruction: !this.state.question.instruction,
      }
    }, () => {
      this.props.handleUpdate(this.state.question)
    });
  }

  render() {
    let { question, instructionTooltip, instructionDefaultTooltip } = this.state;

    if(!question.instruction){
      return <Button className="mr-1 btn-icon float-left" color="secondary" id={"instruction-" + this.props.question.id} onClick={this.toggleInstruction}>
        <i className="fa-solid fa-message-plus" />
        <Tooltip
          delay={0}
          placement="top"
          isOpen={instructionTooltip}
          target={"instruction-" + this.props.question.id}
          toggle={() => this.toggleInstructionTooltip()}
        >
          <FormattedMessage id="question.settings.instruction.button.tooltip"/>
        </Tooltip>
      </Button>
    }

    return (
      <div
        className={classnames({
          "mr-1 float-left w-100 p-0": true,
          "input-group": question.instruction,
        })}
      >
        <InputGroupAddon addonType="prepend" className="m-0">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": question.instruction,
            })}
            color="secondary"
            onClick={this.toggleInstruction}
            id={"instruction-" + this.props.question.id}
          >
            <i className="fa-solid fa-message-plus" />
            <Tooltip
              delay={0}
              placement="top"
              isOpen={instructionTooltip}
              target={"instruction-" + this.props.question.id}
              toggle={() => this.toggleInstructionTooltip()}
            >
              <FormattedMessage id="question.settings.instruction.button.tooltip"/>
            </Tooltip>
          </Button>
        </InputGroupAddon>
        {question.instruction && <div className="d-flex flex-row flex-nowrap flex-grow-1">
          <Input placeholder={'Treść instrukcji (Desktop)'} className="mb-0 border-right mr-2" name="instructionText" value={question.instructionText} onChange={this.onChange} onBlur={() => this.props.handleUpdate(this.state.question)} onKeyPress={this.handleKeyPress}/>
          <Input placeholder={'Treść instrukcji (Mobile)'} className="mb-0" name="instructionTextMobile" value={question.instructionTextMobile} onChange={this.onChange} onBlur={() => this.props.handleUpdate(this.state.question)} onKeyPress={this.handleKeyPress}/>
        </div>}
        {question.instruction && <InputGroupAddon className="my-0" id={"instruction-default-" + this.props.question.id} addonType="append">
          <Button color="default" className="btn-icon">
            <i className="fas fa-question-circle" />
            <Tooltip
              delay={0}
              placement="top"
              isOpen={instructionDefaultTooltip}
              target={"instruction-default-" + this.props.question.id}
              toggle={() => this.toggleInstructionDefaultTooltip()}
            >Podaj własną treść instrukcji lub pozostaw pole puste w celu ustawienia domyślnej treści instrukcji</Tooltip>
          </Button>
        </InputGroupAddon>}
      </div>
    )
  }
}

Instruction.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    instruction: PropTypes.bool.isRequired,
    instructionText: PropTypes.string,
    instructionTextMobile: PropTypes.string,
    instructionDefault: PropTypes.string,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default Instruction;
