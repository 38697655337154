import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import QuestionContentTooltip from "../../../../../../components/QuestionContentTooltip/QuestionContentTooltip";
import {connect} from "react-redux";

FilterSerialCond.propTypes = {

};

function FilterSerialCond(props) {
  const filter = props.filter;
  const questionFrom = _.find(props.questions, question => filter.questionFrom && question.id === filter.questionFrom.question);
  const answersLength = (filter.questionFrom && filter.questionFrom.filterBy === 'answers') ? filter.questionFrom.answers.length : 0;
  const rowsLength = (filter.questionFrom && filter.questionFrom.filterBy === 'rows') ? filter.questionFrom.rows.length : 0;
  const columnsLength = (filter.questionFrom && filter.questionFrom.filterBy === 'columns') ? filter.questionFrom.columns.length : 0;


  const cafeteryCount = answersLength + rowsLength + columnsLength;
  return <React.Fragment>Jeżeli w pytaniu <QuestionContentTooltip question={questionFrom}/> zaznaczy spośród kafeterii ({cafeteryCount})</React.Fragment>;
}

function mapStateToProps(state) {
  let questions = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          positionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = positionInSurvey;
          questions.push(q)
        })
      });
    });
  });

  return {
    questions: questions,
  }
}

export default connect(mapStateToProps)(FilterSerialCond);