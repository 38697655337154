import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Row, Col, Modal, ModalBody, ModalHeader, Card, CardBody, CardHeader, ModalFooter, Alert} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {questionTypes} from 'services/questionTypes';
import QuestionInfo from "../QuestionInfo";
import { connect } from 'react-redux';
import classnames from "classnames";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionAvailable from "components/PlanRestrictionsModals/modals/QuestionAvailable";
import "./styles/style.scss";
import Button from "../Button";


class QuestionTypesModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: props.isMulti ? [] : false,
    };

    this.renderOption = this.renderOption.bind(this);
  }

  renderOption(questionType) {
    const {selected} = this.state;
    const {surveyPlan, isMulti} = this.props;
    const templateAvailableInPlan =
      surveyPlan.questionsAvailable.indexOf(questionType.type) >= 0
      && (
        !_.has(questionType, 'options.presentationType')
        || (
          _.has(questionType, 'options.presentationType')
          && (
            surveyPlan.questionSettings[questionType.type].presentationTypes.length === 0
            || surveyPlan.questionSettings[questionType.type].presentationTypes.indexOf(questionType.options.presentationType) >= 0
          )
        )
      );

    const isSelected = isMulti ? selected.filter(s => s.name === questionType.name).length > 0 : selected === questionType.name;

    return <Col lg={4} xl={3} key={questionType.name} className="align-self-stretch d-flex">
      <Card
        className={classnames({
          'question-type w-100': true,
          'disabled': !templateAvailableInPlan,
          'selected': isSelected,
        })}
        onClick={() => {
          if(!templateAvailableInPlan){
            this.props.setPlanRestrictionsModals(QuestionAvailable.getName())
          }else {
            if(isMulti){
              selected.push(questionType);
              this.setState({selected})
            }else{
              if(isSelected){
                this.props.onSelect(questionType);
              }else{
                this.setState({selected: questionType.name})
              }
            }
          }
        }}
      >
        <CardBody className="pointer">
          <div className="d-flex align-self-stretch">
            <span className="align-self-center" style={{fontSize: '1.7em'}}><QuestionInfo question={questionType}/></span>
            <div className="d-flex flex-column pl-3">
              <FormattedMessage id={'_.question.' + questionType.name + '.typeName'}>{msg => <h2 className={classnames({
                'mb-1': true,
              })} style={{fontSize: '1em'}}>{msg}</h2>}</FormattedMessage>
              <FormattedMessage id={'elements.questionElements.' + questionType.type + '.helpTooltip'}>{msg => <p className={classnames({
                'small mb-0': true,
              })}
              >{msg}</p>}</FormattedMessage>
            </div>
          </div>
          <div className={classnames({
            'overlay-select': true,
          })}>
            <div className="bg bg-info" />
            <div className="d-flex box-text align-items-center justify-content-center">
              <p className="text text-white text-center mb-0">{isMulti ? selected.map((s, key) => s.name === questionType.name ? (key+1) : false).filter(s => !!s).join(', ') : 'Kliknij aby wybrać'}</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  }

  render() {
    const {isOpen, toggle, user, isMulti} = this.props;
    const {selected} = this.state;

    if(!isOpen && selected !== false){
      this.setState({
        selected: false
      })
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="xxl" id="question-types">
        <ModalHeader toggle={toggle} tag="h2">{isMulti ? 'Wybierz typy pytań' : 'Wybierz typ pytania'}</ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <Alert color="info" className="mb-5 text-center">Kliknij na typy pytań w kolejności ich umieszczenia w ankiecie. Możesz również kilka razy wybrać ten sam rodzaj pytania.</Alert>
          {user.favoriteQuestionTypes.length > 0 && <Card>
            <CardHeader className="bg-info p-0"><FormattedMessage id="questionTypesModal.cardHeader.favorite">{msg => <h2 className="text-white">{msg}</h2>}</FormattedMessage></CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Row className="d-flex">
                {_.map(user.favoriteQuestionTypes, questionType => this.renderOption(questionType))}
              </Row>
            </CardBody>
          </Card>}
          {_.map(questionTypes, (questions, categoryName) => {
            return <Card key={categoryName}>
              <CardHeader className="bg-info p-0"><FormattedMessage id={"_.question." + categoryName + ".typeName"}>{msg => <h2 className="text-white">{msg}</h2>}</FormattedMessage></CardHeader>
              <hr className="my-0" />
              <CardBody>
                <Row className="d-flex">
                  {_.map(questions, questionType => this.renderOption(questionType))}
                </Row>
              </CardBody>
            </Card>;
          })}
        </ModalBody>
        {isMulti && <ModalFooter>
          <Button onClick={() => this.props.onSelect(selected)}>Utwórz {selected.length > 0 && '(Liczba pytań: '+ selected.length +')'}</Button>
        </ModalFooter>}
      </Modal>
    )
  }
}

QuestionTypesModal.defaultProps = {
  isMulti: false,
};

QuestionTypesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isMulti: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey,
    user: state.user
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(QuestionTypesModal);