import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Tooltip, Input, Label} from "reactstrap";

class Checkbox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  render() {
    const {checked, id, messageId, onClick, text} = this.props;
    const {tooltip} = this.state;

    return (
      <div className="custom-control custom-checkbox">
        <Input type="checkbox" name="check" className="custom-control-input" checked={checked} readOnly/>
        <Label for="check" id={id} className="custom-control-label" onClick={onClick}>
          {this.props.tooltip && <Tooltip
            delay={0}
            placement="bottom"
            isOpen={tooltip}
            target={id}
            toggle={() => this.toggleTooltip()}
          >
            <FormattedMessage id={messageId}/>
          </Tooltip>}
          {text}
        </Label>
      </div>
    )
  }
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  messageId: PropTypes.string,
  tooltip: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
  tooltip: true,
};

export default Checkbox;