import React, {Component} from 'react';
import {Tooltip} from "reactstrap";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

class ButtonDrag extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  render() {
    const {tooltip} = this.state;

    return (
      <a id={'scalePoint-' + this.props.id + '-drag'} className="fas fa-grip-vertical dragg float-left mr-2 handler-drag-position" style={this.props.gripStyle}>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={tooltip}
          target={'scalePoint-' + this.props.id + '-drag'}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="scalePoint.buttonDrag.button.tooltip"/>
        </Tooltip>
      </a>
    )
  }
}

ButtonDrag.propTypes = {
  id: PropTypes.number.isRequired,
  gripStyle: PropTypes.object.isRequired
};

export default ButtonDrag;