import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Table} from "reactstrap";
import Checkbox from "components/Checkbox";

class TableMatrix extends PureComponent {
  toggleDetails(row, column){
    let {details} = this.props;
    const detail = {row, column};

    if(details.filter(d => d.row === row && d.column === column).length > 0){
      details = details.filter(d => !(d.row === row && d.column === column))
    }else{
      details.push(detail);
    }

    this.props.handleDetailsChanged(details);
  }

  toggleDetailsRow(row){
    const {columns} = this.props;
    let {details} = this.props;

    const filtered = details.filter(d => d.row === row).length;

    if(filtered > 0){
      if(filtered >= columns.length){
        details = details.filter(d => d.row !== row)
      }else{
        columns.map(column => {
          if(details.filter(d => d.column === column.id && d.row === row).length === 0){
            details.push({column: column.id, row})
          }
        });
      }
    }else{
      columns.map(column => {
        details.push({column: column.id, row})
      });
    }
    this.props.handleDetailsChanged(details)
  }

  toggleDetailsColumn(column){
    const {rows} = this.props;
    let {details} = this.props;

    const filtered = details.filter(d => d.column === column).length;

    if(filtered > 0){
      if(filtered >= rows.length){
        details = details.filter(d => d.column !== column)
      }else{
        rows.map(row => {
          if(details.filter(d => d.row === row.id && d.column === column).length === 0){
            details.push({row: row.id, column})
          }
        });
      }
    }else{
      rows.map(row => {
        details.push({row: row.id, column})
      });
    }
    this.props.handleDetailsChanged(details)
  }

  render() {
    const {rows, columns, details, question, questionObject} = this.props;

    return (
      <Table className="border mt-3">
        <thead>
          <tr>
            <th />
            {columns.map((column, cKey) => <th key={cKey} className="text-center" onClick={() => this.toggleDetailsColumn(column.id)}>
              {column.position+1}. {questionObject.columnsImage ? <img src={column.content} className="img-100"/> : <span className="question-badge img-30" dangerouslySetInnerHTML={{__html: column.stripedContent}}/>}
            </th>)}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rKey) => {
            return <tr key={rKey}>
              <td onClick={() => this.toggleDetailsRow(row.id)}>
                {row.position+1}. {questionObject.rowsImage ? <img src={row.content} className="img-100"/> : <span className="question-badge img-30" dangerouslySetInnerHTML={{__html: row.stripedContent}}/>}
              </td>
              {columns.map((column, cKey) => {
                const selected = details.filter(d => d.row === row.id && d.column === column.id).length > 0;

                return (
                  <td className="text-center" key={cKey} onClick={() => this.toggleDetails(row.id, column.id)}>
                    <Checkbox id={'checkbox-' + question + '-' + row.id + '-' + column.id} checked={selected} onClick={() => {}} tooltip={false}/>
                  </td>
                )
              })}
            </tr>
          })}
        </tbody>
      </Table>
    );
  }
}

TableMatrix.propTypes = {
  question: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    stripedContent: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    stripedContent: PropTypes.string.isRequired,
  }).isRequired,).isRequired,
  details: PropTypes.arrayOf(PropTypes.shape({
    row: PropTypes.number.isRequired,
    column: PropTypes.number.isRequired,
  })),
  handleDetailsChanged: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    rows: state.survey.structure.data.questions[props.question].rows.map(rowId => state.survey.structure.data.rows[rowId]),
    columns: state.survey.structure.data.questions[props.question].columns.map(colId => state.survey.structure.data.columns[colId]),
    questionObject: state.survey.structure.data.questions[props.question]
  }
}

export default connect(mapStateToProps)(TableMatrix);