import api from "api";

export const SET = 'Config/SET';

export function setConfig(config) {
  return {
    type: SET,
    config
  }
}

export function fetchConfig() {
  return api.config.get().then(config => {
    let orderedPrivileges = {};
    _.each(config.data.group.surveySharePrivilage, privelege => orderedPrivileges[privelege.id] = privelege);

    config.data.group.surveySharePrivilage = orderedPrivileges;
    return config;
  });
}
