import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from "reactstrap";
import ButtonDelete from "./components/ButtonDelete";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactTable from "react-table";
import Pagination from "components/Pagination";

class ListItems extends Component
{
  constructor(props) {
    super(props);

    this.actions = this.actions.bind(this);
    this.questionFromFormat = this.questionFromFormat.bind(this);
  }

  questionFromFormat(piping) {
    const {questions} = this.props;
    if (piping.type === 'question') {
      return 'Pytanie: ' + questions.find(question => question.id === piping.questionFrom.question).stripedContent;
    }

    if (piping.type === 'token') {
      return <FormattedMessage id={'piping.token.' + piping.tokenField} />
    }

    return "";
  }

  static replacableTextFormat(piping) {
    return '[[' + piping.replacableText + ']]';
  }

  static questionFromTypeFormat(piping) {
    return <FormattedMessage id={'piping.questionFromType.' + piping.questionFrom.type}>{(msg) => msg}</FormattedMessage>
  }

  actions(piping){
    return (
      <ButtonGroup>
        <i className="fas fa-edit mr-2" onClick={() => this.props.toggleEdit(piping)} />
        <i className="fas fa-copy mr-2" onClick={() => this.props.toggleEdit(_.omit(piping, ['id']))} />
        <ButtonDelete piping={piping}/>
      </ButtonGroup>
    )
  }

  render() {
    const columns = [{
      id: 'questionFrom',
      Header: <FormattedMessage id="pipings.listItems.tableHeader.questionFrom" />,
      accessor: piping => this.questionFromFormat(piping),
    }, {
      id: 'replacableText',
      Header: <FormattedMessage id="pipings.listItems.tableHeader.replacableText" />,
      accessor: piping => ListItems.replacableTextFormat(piping),
    }, {
      id: 'actions',
      Header: <FormattedMessage id="pipings.listItems.tableHeader.actions" />,
      accessor: piping => this.actions(piping),
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={Object.values(this.props.pipings)}
        columns={columns}
        showPagination={!_.isEmpty(this.props.pipings)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="pipings.listItems.table.noPipings" />}
        defaultPageSize={5}
      />
    )
  }
}

ListItems.propTypes = {
  question: PropTypes.object.isRequired,
  toggleEdit: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  let pipings = _.filter(state.survey.structure.data.pipings, piping => {
    return (piping.type === 'question' && piping.questionFrom.question === props.question.id) || piping.type === 'token';
  });

  let questionList = [];

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => questionList.push(state.survey.structure.data.questions[questionId]))
      });
    });
  });

  return {
    pipings: _.uniqBy(pipings, 'id'),
    questions: questionList,
  }
}

export default connect(mapStateToProps)(ListItems);