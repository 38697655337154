import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {surveyLink} from "../../../../../../services/surveyLink";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";

class EditSurveyResponseButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.getUrl = this.getUrl.bind(this);
  }

  toggleModal(){
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
    }, () => {
      if(!this.state.modalIsOpen){
        this.props.onModalClosed();
      }
    });
  }

  getUrl(){
    const {surveyResponse} = this.props;
    return surveyLink(surveyResponse.collectorName, true, true, surveyResponse.id) + '&begin-from-start=1&edit=1';
  }

  render() {
    const {modalIsOpen} = this.state;

    return <React.Fragment>
      <Button size="sm" color="primary" className="w-100" onClick={this.toggleModal}><FormattedMessage id="analysis.browse.button.edit">{(message) => <span><i className="fas fa-edit" /> {message}</span>}</FormattedMessage></Button>
      {modalIsOpen && <Modal isOpen={true} toggle={this.toggleModal} size="xxl">
        <ModalHeader tag="h2" toggle={this.toggleModal}>Edycja wywiadu</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <iframe src={this.getUrl()} style={{width: '100%', height: '100%', border: 'none'}} />
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.close">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  }
}

EditSurveyResponseButton.propTypes = {
  surveyResponse: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onModalClosed: PropTypes.func,
};

export default EditSurveyResponseButton;