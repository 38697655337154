import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import {ReactSortable} from "react-sortablejs";
import _ from 'lodash';
import {Row, Col} from "reactstrap";
import ExcludingNew from './components/ExcludingNew';
import Excluding from './components/Excluding';
import ExcludingsActions from './components/Actions';
import {updateExcluding} from "scenes/Survey/Structure/data/excludings/actions";
import {setExcludings} from "scenes/Survey/Structure/data/questions/actions";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import './style.scss'
import ExcludingsAvailable from "components/PlanRestrictionsModals/modals/ExcludingsAvailable";

class Excludings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hideExcludings: true,
      checkedExcludings: []
    };

    this.onUpdate = this.onUpdate.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
  }

  onUpdate(evt){
    let {excludings} = this.props.question;

    const id = parseInt(evt.item.attributes['data-id'].nodeValue);

    excludings.splice(evt.oldIndex, 1);
    excludings.splice(evt.newIndex, 0, id);

    this.props.setExcludings(this.props.question.id, excludings);

    this.props.updateExcluding({
        id,
        questionExcluding: {
          position: evt.newIndex
        }
    });
  }

  toggleChecked(excludingId, checked){
    let {checkedExcludings} = this.state;

    if(checked){
      checkedExcludings.push(excludingId);
    }else{
      checkedExcludings = checkedExcludings.filter(id => id !== excludingId)
    }

    this.setState({
      checkedExcludings
    });
  }

  toggleExcludings(){
    if (!this.props.surveyPlan.excludings) {
      this.props.setPlanRestrictionsModals(ExcludingsAvailable.getName());
    } else {
      this.setState({
        hideExcludings: !this.state.hideExcludings
      })
    }
  }

  render() {
    const {excludings, question, surveyPlan} = this.props;
    const {hideExcludings, checkedExcludings} = this.state;

    return (
      <Row className="mt-3" role="excludings">
        <Col>
          <h4
            className={classnames({
              'cafetery-header': true,
              'd-print-none': question.excludings.length === 0,
              'text-black-50': !surveyPlan.excludings,
            })}
          >
            <span className="pointer" onClick={()=> this.toggleExcludings()}><FormattedMessage id="question.excludings.listGroup.header" /> <span className="d-print-none">({ excludings.length })</span></span>
            <div className="d-inline mx-2 pointer d-print-none" key={Math.random()} onClick={()=> this.toggleExcludings()}>
              <i className={hideExcludings ? 'fas fa-angle-right' : 'fas fa-angle-down'}/>
            </div>
          </h4>

          <div className={classnames({
            'ml-lg-4 d-print-block': true,
            'd-none': hideExcludings,
          })}>
            <ReactSortable
              list={excludings}
              setList={() => {}}
              group={{
                name: 'excludings-' + question.id,
              }}
              tag="ul"
              onUpdate={this.onUpdate}
              animation={100}
              filter={".sortable-ignore"}
              draggable={".sortable-draggable"}
              handle={".handler-drag-position"}
              className="excludings-list list-group"
            >
              {question.excludings.length > 0 && excludings.map((excluding, position) => {
                return (
                  <Excluding
                    excluding={excluding}
                    handlerChecked={(checked) => this.toggleChecked(excluding.id, checked)}
                    position={excluding.position}
                  />
                )
              })}

            </ReactSortable>
            <ExcludingNew question={question} handlerAdding={this.addingInc} handlerAdded={this.addingDec} autoFocus={false}/>

          </div>

          {!hideExcludings && <ExcludingsActions checkedExcludings={checkedExcludings} excludings={excludings} question={question}/>}
        </Col>
      </Row>
    )
  }
}

Excludings.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    excludings: PropTypes.array.isRequired
  }).isRequired
};

function mapStateToProps(state, props) {
  return {
    excludings: state.survey.structure.data.excludings ? _.map(props.question.excludings, id => state.survey.structure.data.excludings[id]) : [],
    surveyPlan: state.user.userPlan.plan.survey
  }
}

export default connect(mapStateToProps, {setExcludings, updateExcluding, setPlanRestrictionsModals})(Excludings);