import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../../../../../../../../../../../../../../../../../../../componentsReusable/Button";
import api from "api";
import {toast} from "react-toastify";

const CreateCollectorPanel = props => {
  const [saving, setSaving] = React.useState(false);

  const createCollectors = () => {
    setSaving(true)

    api.survey.post.collectorsBulk({
      survey: props.surveyId,
      collectors: props.selected.map(quota => parseQuotaNewToCollectorPanelData(quota))
    })
      .then(() => {
        toast.success('Kolektory zostały utworzone')
      })
      .catch(() => {
        toast.error('Wystąpił błąd podczas tworzenia kolektorów')
      })
      .finally(() => {
        setSaving(false)
        props.onFinish()
      })
  }

  const parseQuotaNewToCollectorPanelData = (quotaNew) => {
    let data = {
      type: 'Panel',
      name: quotaNew.name,
    }
    let conds = [];

    quotaNew.blocks.map(block => {
      conds = [
        ...conds,
        ...getCondsFromBlock(block)
      ]
    })

    conds.forEach(cond => {
      if(cond.ageFrom){ data.minAge = cond.ageFrom }
      if(cond.ageTo){ data.maxAge = cond.ageTo }
      if(cond.sexF){ data.sexF = cond.sexF }
      if(cond.sexM){ data.sexM = cond.sexM }
      if(cond.cityTown){ data.cityTown = cond.cityTown }
      if(cond.cityVillage){ data.cityVillage = cond.cityVillage }
      if(cond.cityProvinces.length > 0){ data.cityProvinces = cond.cityProvinces }
    })

    return data
  }

  const getCondsFromBlock = (block) => {
    let conds = [];

    block.condsQuestion.forEach(cond => {
      if(cond.type === 'userProfile'){
        conds.push(cond)
      }
    })

    block.children.forEach(blockChild => {
      conds = [
        ...conds,
        ...getCondsFromBlock(blockChild)
      ]
    })

    return conds
  }

  return (
    <Button
      onClick={createCollectors}
      loading={saving}
    >
      <span>Utwórz kolektory Panel</span>
    </Button>
  );
};

CreateCollectorPanel.propTypes = {
  selected: PropTypes.array.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default CreateCollectorPanel;
