import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip} from "reactstrap";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from 'api';
import CheckUser from "../../../components/User/CheckUser/CheckUser";

class CheckUserAction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
      modal: false,
    };
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  render() {
    const {user} = this.props;

    console.log('user', user);

    return (
      <React.Fragment>
        <span id={'checkUser-' + this.props.user.id} className="fas fa-info pointer mr-2" onClick={() => this.toggle('modal')}/>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip}
          target={'checkUser-' + this.props.user.id}
          toggle={() => this.toggle('tooltip')}
        >
          <FormattedMessage id="admin.respondents.checkUserAction.tooltip" />
        </Tooltip>
        <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')}>
          <ModalHeader toggle={() => this.toggle('modal')} tag="h2"><FormattedMessage id="admin.respondents.checkUserAction.modalHeader"/></ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            <CheckUser id={user.id} />
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between align-items-center">
            {!this.props.user.verified ? <Button color="success" className="mb-0" onClick={() => {
              api.user.patch.respondentAdmin({
                data: {
                  id: user.id,
                  user: {
                    verified: 1,
                  }
                }
              })

              this.toggle('modal');
            }}>Oznacz jako zweryfikowany</Button> : <div />}

            <FormattedMessage id="_.button.close">{
              (message) => <Button color="secondary" className="mb-0" onClick={() => this.toggle('modal')}>{message}</Button>
            }</FormattedMessage>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

CheckUserAction.propTypes = {
  user: PropTypes.object.isRequired
};

export default CheckUserAction;