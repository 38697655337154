import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {fetchPlans} from "../../Plans/actions";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import CompaniesActions from './CompaniesActions';
import moment from "moment";
import api from "api";
import PropTypes from "prop-types";
import RespondentsActions from "../../Respondents/components/RespondentsActions";
import UserBulkBanModal from "../../Respondents/components/UserBulkBanModal";
import UserBulkUnbanModal from "../../Respondents/components/UserBulkUnbanModal";

class CompaniesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      banCategories: [],
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: [],
      banUser: null,
      unbanUser: null,
      banModal: false,
      unbanModal: false,
    };

    this.refresh = this.refresh.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleBan = this.toggleBan.bind(this);
    this.toggleUnban = this.toggleUnban.bind(this);
  }

  componentDidMount(){
    this.props.fetchPlans();
    api.ban.get.categories().then(categories => {
      this.setState({banCategories: categories});
    })
  }

  componentWillReceiveProps(nextProps) {
    const filtersWereChanged =
      this.props.search !== nextProps.search
      || this.props.regBefore !== nextProps.regBefore
      || this.props.regAfter !== nextProps.regAfter
      || this.props.refUser !== nextProps.refUser
      || this.props.plan !== nextProps.plan
      || this.props.expireDate !== nextProps.expireDate
    ;
    if (filtersWereChanged) {
      clearTimeout(this.state.searchTimeout);
      this.setState({
        searchTimeout: setTimeout(() => {
          this.fetchData({
            page: this.state.page,
            pageSize: this.state.pageSize,
            sorted: this.state.sorted,
            plan: this.state.plan,
            expireDate: this.state.expireDate
          }, nextProps);
        }, 1000)
      });
    }
  }

  toggle(property) {
    if (this.state[property]) {
      this.setState({[property]: !this.state[property], banUser: null, unbanUser: null});

    } else {
      this.setState({[property]: !this.state[property]});
    }
  }

  toggleBan(userId) {
    this.setState({banModal: true, banUser: [userId]})
  }

  toggleUnban(userId) {
    this.setState({unbanModal: true, unbanUser: [userId]})
  }

  refresh() {
    this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, this.props)
  }

  fetchData(state, props) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted,
        search: props.search,
        ref: props.refUser,
        plan: props.plan,
        expireDate: props.expireDate ? moment().add(props.expireDate, 'days') : null,
        regBefore: props.regBefore && moment(props.regBefore).format('YYYY-MM-DD 23:59:59'),
        regAfter: props.regAfter && moment(props.regAfter).format('YYYY-MM-DD 00:00:00'),
      }
    };

    api.user.post.searchCompanies(data).then(res => {
      this.setState({searching: false, data: res.companies, count: res.count});
    });
  }

  render() {
    const columns = [
      {
        Header: <FormattedMessage id="admin.companies.listItems.tableHeader.id" />,
        accessor: 'id'
      },
      {
        Header: 'IP',
        accessor: 'ip'
      },
      {
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.username" />,
      accessor: 'username'
    }, {
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.email" />,
      accessor: 'email'
    }, {
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.company" />,
      accessor: 'company'
    }, {
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.nip" />,
      accessor: 'nip'
    }, {
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.surveys" />,
      accessor: 'surveysCount'
    }, {
      id: 'group',
      width: '200px',
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.group" />,
      accessor: user => {
        if (user.group) {
          return user.group + this.props.intl.formatMessage({id: "admin.companies.listItems.tableHeader.group.owner"})
        } else if (user.userGroup && user.userGroups && user.userGroups[0]) {
         return user.userGroup + ' ('+user.userGroups[0].privilageSurveyShareDefault.name+')'
        } else if (user.userGroup) {
         return user.userGroup + ' ('+user.userGroups[0].group.user.email+')' + this.props.intl.formatMessage({id: "admin.companies.listItems.tableHeader.group.member"})
        }
      },
      sortable: false
    }, {
      id: 'plan',
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.plan" />,
      accessor: user => (user.userPlan && user.userPlan.plan) ? user.userPlan.plan.name : <FormattedMessage id="admin.companies.listItems.plan.noPlan" />,
      sortable: false
    }, {
      id: 'demo',
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.demo" />,
      accessor: user => (user.userPlan && user.userPlan.plan) ? (user.userPlan.plan && user.userPlan.plan.demo) && <i className="fas fa-check text-success" /> : null
    }, {
      id: 'expireDate',
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.planExpireDate" />,
      accessor: user => (user.userPlan && user.userPlan.plan) ? moment(user.userPlan.expireDate).format('YYYY-MM-DD HH:mm') : null
    }, {
      id: 'createdAt',
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.createdAt" />,
      accessor: user => moment(user.createdAt).format('YYYY-MM-DD HH:mm')
    }, {
      id: 'lastActivityAt',
      Header: <FormattedMessage id="admin.companies.listItems.tableHeader.lastActivityAt" />,
      accessor: user => user.lastActivityAt && moment(user.lastActivityAt).format('YYYY-MM-DD HH:mm')
    },  {
      id: 'actions',
      Header: <FormattedMessage id="_.table.header.actions"/>,
      accessor: user => <CompaniesActions user={user} toggleBan={this.toggleBan} categories={this.state.banCategories} toggleUnban={this.toggleUnban} handleChange={() => this.fetchData(this.state, this.props)}/>,
      sortable: false
    }];

    return <React.Fragment>
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.companies.listItems.table.noUsers" />}
        loading={this.state.searching}
        onFetchData={(state) => this.fetchData(state, this.props, state.sorted)}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
        getTrGroupProps={(state, rowInfo) => {
          return {className: rowInfo.original.isBanned && "bg-danger"};
        }}
      />

      <UserBulkBanModal isOpen={this.state.banModal} toggle={() => this.toggle('banModal')} checked={this.state.banUser || this.state.checked} onUpdate={this.refresh} categories={this.state.banCategories}/>
      <UserBulkUnbanModal isOpen={this.state.unbanModal} toggle={() => this.toggle('unbanModal')} checked={this.state.unbanUser || this.state.checked} onUpdate={this.refresh} />
    </React.Fragment>
  }
}

CompaniesList.propTypes = {
  search: PropTypes.string.isRequired,
  expireDate: PropTypes.number.isRequired
};

export default connect(null, {fetchPlans})(injectIntl(CompaniesList));
