import React, {Component} from 'react';
import PropTypes from "prop-types";
import SettingBool from "../../../SettingBool";
import RowsImage from "../../../RowsImage";
import SettingNumber from "../../../SettingNumber";
import PresentationType from "../../../PresentationType";

class DifferentialSettings extends Component {

  render() {
    const {question} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'required'} value={question.required} icon={<i className="fa-solid fa-message-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'rotateRows'} value={question.rotateRows} icon={<i className="fas fa-random" />} handleUpdate={this.props.handleUpdate}/>
        <RowsImage question={question} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'scaleSize'} value={question.scaleSize} min={0} max={question.presentationType === 'sliders' ? 9999 : 11} icon={<i className="fa-solid fa-ellipsis" />} handleUpdate={this.props.handleUpdate}/>
        <PresentationType
          question={question}
          handleUpdate={this.props.handleUpdate}
        />
        {question.presentationType === 'sliders' && <SettingBool question={question} name={'defaultValueMiddle'} value={question.defaultValueMiddle || false} icon={<i className="fas fa-align-center"/>} handleUpdate={this.props.handleUpdate}/>}
      </React.Fragment>
    )
  }
}

DifferentialSettings.propTypes = {
  question: PropTypes.shape({
    required: PropTypes.bool.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default DifferentialSettings;
