import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import FormGroup from "reactstrap/es/FormGroup";
import {Col, Form, Input, Label} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from "react-ladda";


class RegisterForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: '',
        password: '',
      }
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e){
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      }
    })
  }

  onSubmit(e){
    const {data} = this.state;

    e.preventDefault();

    this.props.onSubmit(data);
  }

  render() {
    const {data} = this.state;
    const {loading, errors} = this.props;

    return (
      <Form>
        <FormGroup className="mb-2">
          <Label>E-mail</Label>
          <Input className="mb-0" name="email" placeholder="Adres e-mail" onChange={this.onChange} value={data.email} invalid={!!errors.email} />
          {errors.email && <div className="invalid-feedback d-block mt-0">{errors.email}</div>}
        </FormGroup>
        <FormGroup className="mb-2">
          <Label>Hasło</Label>
          <Input className="mb-0" type="password" name="password" placeholder="Hasło" onChange={this.onChange} value={data.password} invalid={!!errors.password} />
          {errors.password && <div className="invalid-feedback d-block mt-0">{errors.password}</div>}
        </FormGroup>

        <FormGroup>
          <LaddaButton
            className="btn btn-primary btn-ladda d-block w-100"
            loading={loading}
            data-style={ZOOM_OUT}
            onClick={this.onSubmit}
          >Utwórz konto</LaddaButton>
        </FormGroup>

        <p className="w-100 text-center py-4 mb-0" style={{fontSize: '1.1em'}}>LUB</p>

        <a className="btn btn-secondary text-center w-100" href={process.env.ANKIETEO_HOST + "/api/connect/google"}><i className="fab fa-google"> </i> Załóż darmowe konto przez Google</a>
      </Form>
    );
  }
}

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
};

export default RegisterForm;