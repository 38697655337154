import {
	AlignmentType,
	ImageRun,
	Paragraph,
} from "docx";
import moment from "moment";
import convertSvgToPng64 from "./convertSvgToPng64";
import docxBuilder from "../builders/docxBuilder";
import questionSummaryToStringRows from "./questionSummaryToStringRows";

const questionsSummaryToDocxConverter = async (questionsSummaries, survey) => {
	const builder = new docxBuilder({alignment: AlignmentType.CENTER});
	builder.addHeading("Wyniki ankiety: " + survey.name);
	builder.addText("Wyniki wygenerowane za pomocą Ankieteo.pl");
	builder.addHeading("");
	builder.addText(moment().locale("pl").format("LLL"));
	builder.addText("");

	for (const summary of questionsSummaries) {
		const i = questionsSummaries.indexOf(summary);
		const image = await convertSvgToPng64("#container-question-" + summary.question.id + " svg")
		builder.addText(summary.question.content ? summary.question.content : "Brak treści pytania",
			{},
			{pageBreakBefore: i !== 0, alignment: AlignmentType.CENTER}
		);
		builder.addHeading("");
		builder.addHeading( "N = " + summary.data.n);
		builder.addHeading("");
		if (image) {
			const imageWidth = 600;
			let percent = (100 * image.height) / image.width;
			let percentage = (imageWidth/100) * percent;
			builder.addElement(new Paragraph({
				children: [
					new ImageRun({
						data: image.data,
						transformation: {
							width: 600,
							height: percentage,
						}
					}),
				],
			}))
		}
		builder.addHeading("");

		const tableElement = document.querySelector("#container-question-" + summary.question.id + " table");
		if (!tableElement) {
			continue;
		}

		builder.addTable(questionSummaryToStringRows(summary))
	}
	builder.addHeading("");
	builder.addText("Wyniki wygenerowane za pomocą Ankieteo.pl")
	builder.createAndDownload(survey.name)
}

export default questionsSummaryToDocxConverter;