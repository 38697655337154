import React, {Component} from 'react';
import AsyncSelect from 'react-select/lib/Async';
import PropTypes from "prop-types";
import {isNull} from 'lodash';

class ApplicationSelect extends Component {

  constructor(props) {
    super(props);

    this.selectRef = React.createRef();

    this.state = {
      searchTimeout: false,
      loading: false,
      search: '',
      options: [],
      value: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.setById = this.setById.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if(this.props.menuIsOpen){
      this.selectRef.current.focus();
    }
    if(this.props.value){
      this.setById(this.props.value);
    }
  }

  setById(id){
    this.props.fetchById(id)
    .then(res => {
      this.setState({
        value: {
          value: this.props.value,
          label: this.getLabelSelected(res.data)
        }
      })
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.value !== this.props.value){
      if(this.props.value){
        this.setById(this.props.value);
      }else{
        this.setState({
          value: false
        })

      }
    }
  }

  fetchData(search, callback){
    const {filters} = this.props;

    clearTimeout(this.state.searchTimeout);

    this.setState({
      searchTimeout: setTimeout(() => {
        this.props.searchData({
          page: 1,
          limit: 50,
          search,
          filters,
        }, {
          sortField: this.props.sortField,
          sortOrder: this.props.sortOrder,
        })
        .then(res => {
          let options = res.data.map(entity => {
            return {
              value: entity.id,
              label: this.getLabel(entity),
            }
          });

          if(this.props.addNewOption && res.data.length === 0){
            options.push({
              value: null,
              label: this.props.addNewOption
            });
          }

          this.setState({
            data: res.data,
          }, () => {
            callback(options);
          });
        });
      }, 450)
    })
  }

  getLabel(entity){
    if(this.props.label){
      return this.props.label(entity);
    }

    return entity.name;
  }

  getLabelSelected(entity){
    if(this.props.labelSelected){
      return this.props.labelSelected(entity);
    }

    return entity.name;
  }

  handleInputChange(search){
    this.setState({ search });
    return search;
  }

  render() {
    const {value, data} = this.state;
    const {menuIsOpen} = this.props;

    let selectProps = {
      className: 'react-select ' + this.props.className,
      ref: this.selectRef,
      isClearable: true,
      menuIsOpen: menuIsOpen || undefined,
      placeholder: this.props.placeholder,
      onBlur: this.props.onBlur,
      loadingMessage: () => <span>Trwa wyszukiwanie <i className="fas fa-spin fa-spinner ml-2" /></span>,
      noOptionsMessage: () => <span>Nic nie znaleziono</span>,
      loadOptions: this.fetchData,
      defaultOptions: true,
      onInputChange: this.handleInputChange,
      onChange: option => {
        if(isNull(option)){
          this.setState({
            value: null
          });

          this.props.onChange(null);
        }else{
          const entity = data.find(e => e.id === option.value);

          this.setState({
            value: {
              value: entity.id,
              label: this.getLabelSelected(entity),
            }
          });

          this.props.onChange(entity);
        }
      },
      value: value,
    }

    let SelectComponent = AsyncSelect;

    return <React.Fragment>
      <SelectComponent
        {...selectProps}
      />
    </React.Fragment>
  }
}

ApplicationSelect.defaultProps = {
  className: '',
  placeholder: 'Wybierz',
  menuIsOpen: false,
  sortField: 'id',
  sortOrder: 'desc',
  addNewOption: null,
};

ApplicationSelect.propTypes = {
  label: PropTypes.object,
  labelSelected: PropTypes.object,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  menuIsOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  fetchById: PropTypes.func.isRequired,
  searchData: PropTypes.func.isRequired,
  addNewOption: PropTypes.object,
  filters: PropTypes.object,
};

export default ApplicationSelect;
