import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import classnames from "classnames";
import PatternsModal from "./PatternsModal";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import MultimediaAvailable from "components/PlanRestrictionsModals/modals/MultimediaAvailable";


export const KEY = '$survey-bgImg';

class SurveyBgImg extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      value: props.ownStyles[KEY] ? props.ownStyles[KEY] : false,
    };

    this.getDefaultValue = this.getDefaultValue.bind(this);
  }

  getDefaultValue(){
    return this.props.ownStylesDefault[KEY];
  }

  updateValue(value){
    this.setState({
      value
    });

    this.props.handlerUpdate({[KEY]: value});
  }

  render() {
    const {value, modalOpen} = this.state;
    const {plan} = this.props;
    const inactive = [false, ''].indexOf(value) >= 0;

    return (
      <Row className={classnames({
        'py-1 px-3 align-items-center': true,
        'inactive': inactive,
      })}>
        <label className="col col-4">Obraz w tle {!inactive && <i className="icon-clear-value fas fa-trash float-right" onClick={() => this.updateValue('')} />}</label>
        <Col className="col-8 d-flex align-items-center">
          <div
            className="color-picker-preview d-flex align-items-center text-center"
            style={{
              width: '100px',
              height: '100px',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition:'50% 50%',
              backgroundImage: value,
            }}
          >
            {(!value || value === 'none') && <i>Brak wybranego zdjęcia</i>}
          </div>

          <div className="d-flex ml-2 flex-column align-items-center">
            <span
              className="text-center w-100"
              style={{fontSize: '0.8em', cursor: 'pointer'}}
              onClick={() => this.setState({modalOpen: true})}
            >Wybierz z gotowych wzorów</span>
            {modalOpen && <PatternsModal onClose={() => this.setState({modalOpen: false})} onSelect={url => {
              this.setState({modalOpen: false});
              this.updateValue('url(' + url + ')');
            }} />}

            <span
              className="text-center mt-3 w-100"
              style={{fontSize: '0.8em', cursor: 'pointer'}}
              onClick={() => {
                if (!plan.multimedia.access) {
                  this.props.setPlanRestrictionsModals(MultimediaAvailable.getName());
                  return;
                }
                CKFinder.popup({
                  connectorInfo: 'auth=' + localStorage.jwt,
                  chooseFiles: true,
                  width: 1014,
                  height: 700,
                  onInit: (finder) => {
                    finder.on('files:choose', (e) => {
                      const url = e.data.files.first().getUrl();

                      this.updateValue('url(' + url + ')')
                    });
                  }
                })
              }}
            >Wybierz z biblioteki</span>
          </div>

          {!inactive && <i className="icon-clear-value fas fa-trash ml-auto mr-3" onClick={() => this.updateValue('none')} />}
        </Col>
      </Row>
    );
  }
}

SurveyBgImg.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  ownStyles: PropTypes.shape({
    [KEY]: PropTypes.string,
  }),
  ownStylesDefault: PropTypes.shape({
    [KEY]: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(SurveyBgImg);