import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Col, FormGroup, Label, Row, Input, Alert} from "reactstrap";
import Select from "react-select";
import _ from "lodash";
import Switch from "components/Switch";
import api from "api";
import {FormattedMessage} from "react-intl";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import ExportResponseTimeAvailable from "../../PlanRestrictionsModals/modals/ExportResponseTimeAvailable";
import ExportDemographicsAvailable from "../../PlanRestrictionsModals/modals/ExportDemographicsAvailable";
import SelectQuestions from "../../../scenes/Survey/Structure/components/SelectQuestions";
import Icon from "../../../componentsReusable/Icon";
import {toast} from "react-toastify";

class ExportForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      format: props.exportTypesAvailable[0],
      usersIdsText: '',
      options: {
        inBackground: false,
        demographics: false,
        respondentInfo: true,
        positions: false,
        positionsCafetery: false,
        questionDisplayMicrotimes: false,
        questionResponses: true,
        questionResponsesMicrotimes: false,
        transposition: false,
        transpositionQuestions: [],
        usersIds: [],
      }
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onUsersIdsChange = this.onUsersIdsChange.bind(this);
    this.getExportTypes = this.getExportTypes.bind(this);
  }

  onSubmit(){
    const {analysis, deleteAfterExport} = this.props;
    const {format, options, usersIds} = this.state;
    let filename;

    if(this.state.options.inBackground){
      fetch(api.analysis.export(analysis.id, format, analysis.lang, options, deleteAfterExport, usersIds))
        .then(() => {
          toast.info('Link do pliku eksportu pojawi się z powiadomieniu')

          if(this.props.onEnd){
            this.props.onEnd()
          }
        })
    }else{
      const href = api.analysis.export(analysis.id, format, analysis.lang, options, deleteAfterExport, usersIds);
      let a = document.createElement('a');
      a.href = href;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }



    // this.props.setLoading(true);

    // fetch(api.analysis.export(analysis.id, format, analysis.lang, options, deleteAfterExport, usersIds))
    //   .then(response => {
    //     filename = decodeURI(response.headers.get('content-disposition').split('filename=')[1]);
    //     return response.blob()
    //   })
    //   .then(blob => {
    //     let a = document.createElement('a');
    //     a.href = window.URL.createObjectURL(blob);
    //     a.download = filename.substring(1, filename.length-1);
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     this.props.setLoading(false);
    //   }).catch(() => {
    //     this.props.setLoading(false);
    //   });
  }

  onUsersIdsChange(e) {
    const {options} = this.state;

    const text = e.target.value;
    const usersIds = [];

    _.each(text.split(','), id => {
      id = parseInt(id);

      if (Number.isInteger(id)) {
        usersIds.push(id)
      }
    });

    this.setState({
      usersIdsText: text,
      options: {
        ...options,
        usersIds
      },
    });
  }

  getExportTypes(){
    return this.props.exportTypesAll
  }

  render() {
    const {exportTypesAll, exportTypesAvailable, analysisPlan} = this.props;
    const {format, options} = this.state;

    return <React.Fragment>
      {['spss'].includes(format) && <Alert color="info" className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <strong>997</strong>
          <p className="mb-0 ml-2">element nie został wyświetlony z powodu zastosowania jednego z filtrów w skrypcie (pokaż/ukryj, przeskocz, seryjny, iteracyjny, screen-out, quota full).</p>
        </div>
        <div className="d-flex align-items-center mt-3">
          <strong>998</strong>
          <p className="mb-0 ml-2">element nie został wyświetlony z powodu zastosowania w skrypcie mechanizmu losującego. Zastosowana w skrypcie logika (filtry) zakładała wyświetlenie elementu, ale nie został on wylosowany dla danego respondenta.</p>
        </div>
      </Alert>}
      <Row>
        <Col lg={6}>
          <FormGroup row>
            <Label lg={4}>Format eksportu</Label>
            <Col lg={8}>
              <Select
                value={{
                  label: <FormattedMessage id={'_.analysis.exportType.' + format} />,
                  value: format,
                }}
                options={this.getExportTypes().map(format => {
                  return {
                    label: <FormattedMessage id={'_.analysis.exportType.' + format} />,
                    value: format,
                    isDisabled: !_.includes(exportTypesAvailable, format),
                  }
                })}
                onChange={(e) => {
                  this.setState({
                    format: e.value
                  })
                }}
              />
            </Col>
          </FormGroup>
          {analysisPlan.filterUserIds && <FormGroup row className="mt-2">
            <Label lg={4}>Id użytkowników</Label>
            <Col lg={8}>
              <Input type="text" value={this.state.usersIdsText} onChange={this.onUsersIdsChange}/>
            </Col>
          </FormGroup>}
          {(format === 'spss' && this.props.plan.notifications) && <FormGroup row className="mt-2">
            <Label lg={4}>Pobieranie w Tle <Icon className="fas fa-info-circle ml-2" tooltip="Tworzy plik eksportu w tle, umożliwiając pobranie pliku z eksportem po kliknięciu w link. Link pojawi się w Powiadomieniu/Notyfikacji." /></Label>
            <Col lg={8}>
              <Switch checked={this.state.options.inBackground} onChange={inBackground => this.setState({options: {...options, inBackground}})} />
            </Col>
          </FormGroup>}
        </Col>
        <Col lg={6}>
          {['spss'].includes(format) && <React.Fragment>
            <h1 className="w-100 text-center">Pobierz poniższe dane</h1>
            <FormGroup row>
              <Label lg={6}>Informacje o respondencie</Label>
              <Col lg={6} className="pt-2">
                <Switch checked={options.respondentInfo} onChange={respondentInfo => this.setState({options: {...options, respondentInfo}})}/>
              </Col>
            </FormGroup>
            <FormGroup row className={analysisPlan.demographics ? '' : 'not-active'} onClick={() => analysisPlan.demographics ? null : this.props.setPlanRestrictionsModals(ExportDemographicsAvailable.getName())}>
              <Label lg={6}>Demografia</Label>
              <Col lg={6} className="pt-2">
                <Switch checked={options.demographics} disabled={!analysisPlan.demographics} onChange={demographics => this.setState({options: {...options, demographics}})}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={6}>Pozycje elementów</Label>
              <Col lg={6} className="pt-2">
                <Switch checked={options.positions} onChange={positions => this.setState({options: {...options, positions}})}/>
              </Col>
            </FormGroup>
            {options.positions && <FormGroup row>
              <Label lg={6}>Dołącz również pozycje kafeterii</Label>
              <Col lg={6} className="pt-2">
                <Switch checked={options.positionsCafetery} onChange={positionsCafetery => this.setState({options: {...options, positionsCafetery}})}/>
              </Col>
            </FormGroup>}
            <FormGroup row className={analysisPlan.exportResponseTime ? '' : 'not-active'} onClick={() => analysisPlan.exportResponseTime ? null : this.props.setPlanRestrictionsModals(ExportResponseTimeAvailable.getName())}>
              <Label lg={6}>Czasy wyświetlenia pytań</Label>
              <Col lg={6} className="pt-2">
                <Switch checked={options.questionDisplayMicrotimes} disabled={!analysisPlan.exportResponseTime} onChange={questionDisplayMicrotimes => this.setState({options: {...options, questionDisplayMicrotimes}})}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={6}>Odpowiedzi respondentów</Label>
              <Col lg={6} className="pt-2">
                <Switch checked={options.questionResponses} onChange={questionResponses => this.setState({options: {...options, questionResponses}})}/>
              </Col>
            </FormGroup>
            <FormGroup row className={analysisPlan.exportResponseTime ? '' : 'not-active'} onClick={() => analysisPlan.exportResponseTime ? null : this.props.setPlanRestrictionsModals(ExportResponseTimeAvailable.getName())}>
              <Label lg={6}>Czasy odpowiedzi respondentów</Label>
              <Col lg={6} className="pt-2">
                <Switch checked={options.questionResponsesMicrotimes} disabled={!analysisPlan.exportResponseTime} onChange={questionResponsesMicrotimes => this.setState({options: {...options, questionResponsesMicrotimes}})}/>
              </Col>
            </FormGroup>
            {analysisPlan.transposition && <FormGroup row>
              <Label lg={6}>Transpozycja pytań</Label>
              <Col lg={6} className="pt-2">
                <Switch checked={options.transposition} onChange={transposition => this.setState({
                  options: {
                    ...options,
                    transposition,
                    transpositionQuestions: []
                  }
                })}/>
              </Col>
            </FormGroup>}
          </React.Fragment>}
        </Col>
      </Row>
      {options.transposition && <Row className="mt-3">
        <Col>
          <h1 className="text-center">Wybierz pytania do transpozycji</h1>
          <SelectQuestions
            onChange={transpositionQuestions => this.setState({options: {...options, transpositionQuestions,}})}
            enabledTypes={['matrix']}
          />
        </Col>
      </Row>}
    </React.Fragment>;
  }
}

ExportForm.defaultProps = {
  deleteAfterExport: false,
};

ExportForm.propTypes = {
  setLoading: PropTypes.func.isRequired,
  deleteAfterExport: PropTypes.bool,
  exportTypesAll: PropTypes.array.isRequired,
  exportTypesAvailable: PropTypes.array.isRequired,
  analysis: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lang: PropTypes.string.isRequired,
  }),
  onEnd: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    exportTypesAll: state.config.data.analysis.exportTypes,
    exportTypesAvailable: state.user.userPlan.plan.analysis.exportTypes,
    plan: state.user.userPlan.plan,
    analysisPlan: state.user.userPlan.plan.analysis,
    planName: state.user.userPlan.plan.name,
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals}, null, {forwardRef: true})(ExportForm);
