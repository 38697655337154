import React, {Component} from 'react';
import striptags from "striptags";
import {Badge, Card, CardBody, CardHeader} from "reactstrap";
import PropTypes from 'prop-types';
import QuestionSummarySingle from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummarySingle";
import QuestionSummaryMulti from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryMulti";
import QuestionSummaryMultiOpen from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryMultiOpen";
import QuestionSummaryVideo from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryVideo";
import QuestionSummaryMatrix from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryMatrix";
import QuestionSummaryRanked from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryRanked";
import QuestionSummaryOpen from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryOpen";
import QuestionSummaryMatrixMulti from "scenes/Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryMatrixMulti";
import QuestionSummaryConjoint
  from "../../../../../../../../Survey/Analysis/components/View/QuestionSummaries/QuestionSummary/QuestionSummaryConjoint";

class QuestionSummaryCard extends Component {

  renderQuestionSummary() {
    switch (this.props.questionSummary.question.type) {
      case 'single':
        return <QuestionSummarySingle questionSummary={this.props.questionSummary}/>;
      case 'multi':
        return <QuestionSummaryMulti questionSummary={this.props.questionSummary}/>;
      case 'multi-open':
        return <QuestionSummaryMultiOpen questionSummary={this.props.questionSummary}/>;
      case 'video':
        return <QuestionSummaryVideo questionSummary={this.props.questionSummary}/>;
      case 'matrix':
        return <QuestionSummaryMatrix questionSummary={this.props.questionSummary}/>;
      case 'ranked':
        return <QuestionSummaryRanked questionSummary={this.props.questionSummary}/>;
      case 'conjoint':
        return <QuestionSummaryConjoint questionSummary={this.props.questionSummary}/>;
      case 'open':
        return <QuestionSummaryOpen questionSummary={this.props.questionSummary}/>;
      case 'matrixMulti':
        return <QuestionSummaryMatrixMulti questionSummary={this.props.questionSummary}/>;
    }
  }

  render() {
    const {questionSummary} = this.props;

    return (
      <Card>
        <CardHeader>
          <div className="direction-group">
            <div className="direction-left d-flex justify-content-start">
              <h2 className="float-left mr-4"><div dangerouslySetInnerHTML={{__html: striptags(questionSummary.question.content)}} /></h2>
            </div>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <div className="d-flex align-content-center justify-content-center mb-3">
            <Badge color="secondary" style={{fontSize: '1em'}}>N = {questionSummary.data.n}</Badge>
          </div>
          {this.renderQuestionSummary()}
        </CardBody>
      </Card>
    )
  }
}

QuestionSummaryCard.propTypes = {
  questionSummary: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question: PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired
};

export default QuestionSummaryCard;