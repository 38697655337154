import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from 'reactstrap';
import PlanDeleteButton from './PlanDeleteButton';
import appRoutes from "appRoutes";
import {Link} from "react-router-dom";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import api from "api";
import PlanCopyModal from "./PlanCopyModal";

class PlansList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
    this.actions = this.actions.bind(this);
  }

  fetchData(state) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted
      }
    };

    api.plan.post.searchPlans(data).then(res => {
      this.setState({searching: false, data: res.plans, count: res.count});
    });
  }

  actions(plan){
    const handler = () => this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted});
    return (
      <ButtonGroup>
        <Link to={appRoutes.admin.settings.plans.edit(plan.id)} className="mr-2"><i className="fas fa-edit" /></Link>
        <PlanCopyModal plan={plan} onCopied={() => this.fetchData(this.state)} />
        <PlanDeleteButton plan={plan} handleChange={handler}/>
      </ButtonGroup>
    )
  }

  static accessorFormatter(field){
    return field ? 'Tak' : 'Nie'
  }

  render() {
    const columns = [
      {
        Header: <FormattedMessage id="admin.plansList.listItems.tableHeader.id" />,
        accessor: 'id'
      },
      {
        id: 'demo',
        Header: <FormattedMessage id="admin.plansList.listItems.tableHeader.demo" />,
        accessor: plan => PlansList.accessorFormatter(plan.demo)
      },
      {
        id: 'availableForRespondents',
        Header: <FormattedMessage id="admin.plansList.listItems.tableHeader.availableForRespondents" />,
        accessor: plan => PlansList.accessorFormatter(plan.availableForRespondents)
      },
      {
        id: 'availableForCompanies',
        Header: <FormattedMessage id="admin.plansList.listItems.tableHeader.availableForCompanies" />,
        accessor: plan => PlansList.accessorFormatter(plan.availableForCompanies)
      },
      {
        Header: <FormattedMessage id="admin.plansList.listItems.tableHeader.name" />,
        accessor: 'name'
      },
      {
        id: 'actions',
        Header: <FormattedMessage id="admin.plansList.listItems.tableHeader.actions" />,
        accessor: plan => this.actions(plan)
      }
    ];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={Object.values(this.state.data)}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.plansList.listItems.table.noPlans" />}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

export default PlansList;