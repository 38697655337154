import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input} from "reactstrap";
import {injectIntl} from "react-intl";
import QuestionAnswers from "../../../../Modals/QuestionAnswers";
import QuestionAnswer from "../../../../Modals/QuestionAnswer";
import Place from "../../../../Modals/Place";
import Places from "../../../../Modals/Places";
import {FormattedMessage} from "react-intl";

const detailTypes = ['selected', 'notSelected', 'chooseAnswerOnPlaces', 'chooseAnswersOnPlace'];
const condTypes = ['or', 'and', 'minMax'];

class Conjoint extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpenDropdownCondType: false,
      isOpenDropdownDetailType: false,
    };

    this.toggleIsOpenDropdownCondType = this.toggleIsOpenDropdownCondType.bind(this);
    this.toggleIsOpenDropdownDetailType = this.toggleIsOpenDropdownDetailType.bind(this);
  }

  componentWillMount() {
    const {condQuestion} = this.props;
    let condQuestionChanged = condQuestion;

    if(_.isUndefined(condQuestion.answers)){ condQuestionChanged.answers = []; }
    if(_.isUndefined(condQuestion.answerPlaces)){ condQuestionChanged.answerPlaces = {answer: null, places: []}; }
    if(_.isUndefined(condQuestion.answersPlace)){ condQuestionChanged.answersPlace = {answers: [], place: null}; }
    if(_.isUndefined(condQuestion.condType) || !condTypes.includes(condQuestion.condType)){ condQuestionChanged.condType = condTypes[0]; }
    if(_.isUndefined(condQuestion.detailType) || !detailTypes.includes(condQuestion.detailType)){ condQuestionChanged.detailType = detailTypes[0]; }

    if(condQuestion !== condQuestionChanged){
      Conjoint.propTypes.handleCondChanged(condQuestionChanged);
    }
  }

  toggleIsOpenDropdownCondType(){
    this.setState({
      isOpenDropdownCondType: !this.state.isOpenDropdownCondType,
    })
  }

  toggleIsOpenDropdownDetailType(){
    this.setState({
      isOpenDropdownDetailType: !this.state.isOpenDropdownDetailType,
    })
  }

  render() {
    const {isOpenDropdownDetailType, isOpenDropdownCondType} = this.state;
    const {intl, condQuestion, changeable} = this.props;
    const selectAnswers = condQuestion.detailType === 'chooseAnswersOnPlace'
      ? condQuestion.answersPlace.answers
      : condQuestion.answers.map(answer => answer.answer);

    const questionAnswersSelect = <QuestionAnswers
      answers={selectAnswers}
      question={condQuestion.question}
      changeable={changeable}
      handleAnswersChanged={answersArray => {
        let answers = answersArray.map(answer => {return {answer}});

        let data = condQuestion;
        if (condQuestion.detailType === 'chooseAnswersOnPlace') {
          data.answersPlace.answers = answersArray;
        } else {
          data.answers = answers;
        }
        this.props.handleCondChanged(data);
    }} />;

    const questionAnswerSelect = <QuestionAnswer
      answer={condQuestion.answerPlaces.answer}
      question={condQuestion.question}
      changeable={changeable}
      handleAnswerChanged={answer => {
      this.props.handleCondChanged({...condQuestion, answerPlaces: {...condQuestion.answerPlaces, answer}});
    }} />;

    return (
      <React.Fragment>
        {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenDropdownDetailType} toggle={this.toggleIsOpenDropdownDetailType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Conjoint.detailType-' + condQuestion.detailType})}</DropdownToggle>
          <DropdownMenu>
            {detailTypes.map(detailType => <DropdownItem tag="a" key={detailType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              detailType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.Conjoint.detailType-' + detailType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condDetail.Conjoint.detailType-' + condQuestion.detailType})} </span>}

        {(condQuestion.detailType === 'selected' || condQuestion.detailType === 'notSelected') && <React.Fragment>
          {changeable ? <Dropdown className="d-inline mx-1 pointer" isOpen={isOpenDropdownCondType} toggle={this.toggleIsOpenDropdownCondType}>
          <DropdownToggle tag="span" className="badge badge-secondary">{intl.formatMessage({id: 'filter.condDetail.Conjoint.condType-' + condQuestion.detailType + '-' + condQuestion.condType})}</DropdownToggle>
          <DropdownMenu>
            {condTypes.map(condType => <DropdownItem tag="a" key={condType} onClick={() => this.props.handleCondChanged({
              ...condQuestion,
              condType,
            })}><h5>{intl.formatMessage({id: 'filter.condDetail.Conjoint.condType-' + condQuestion.detailType + '-' + condType})}</h5></DropdownItem>)}
          </DropdownMenu>
        </Dropdown> : <span>{intl.formatMessage({id: 'filter.condDetail.Conjoint.condType-' + condQuestion.detailType + '-' + condQuestion.condType})} </span>}

          {condQuestion.condType === 'minMax' && <React.Fragment>
            <FormattedMessage id="condQuestion-minMax-min"/>
            {changeable ? <Input
              type="number"
              className="d-inline mx-2"
              size="sm"
              style={{width: 50}}
              value={condQuestion.min} onChange={e => this.props.handleCondChanged({
              ...condQuestion,
              min: parseInt(e.target.value),
            })} /> : <span className="mx-1">{condQuestion.min}</span>}

            <FormattedMessage id="condQuestion-minMax-max"/>
            {changeable ? <Input
              type="number"
              className="d-inline ml-2"
              size="sm"
              style={{width: 50}}
              value={condQuestion.max} onChange={e => this.props.handleCondChanged({
              ...condQuestion,
              max: parseInt(e.target.value),
            })} /> : <span className="mx-1">{condQuestion.max}</span>}
          </React.Fragment>}

        </React.Fragment>}

        {['selected', 'notSelected', 'chooseAnswersOnPlace'].includes(condQuestion.detailType) && questionAnswersSelect}

        {condQuestion.detailType === 'chooseAnswersOnPlace' && <React.Fragment>
          <FormattedMessage id="filter.condDetail.Conjoint.detailType-chooseAnswersOnPlace.place"/> { }
          <Place place={condQuestion.answersPlace.place} questionId={condQuestion.question} handlePlaceChanged={place => this.props.handleCondChanged({...condQuestion, answersPlace: {...condQuestion.answersPlace, place}})} />
        </React.Fragment>}

        {condQuestion.detailType === 'chooseAnswerOnPlaces' && <React.Fragment>
          <Places places={condQuestion.answerPlaces.places} questionId={condQuestion.question} handlePlacesChanged={places => this.props.handleCondChanged({...condQuestion, answerPlaces: {...condQuestion.answerPlaces, places}})} />
          <FormattedMessage id="filter.condDetail.Conjoint.detailType-chooseAnswerOnPlaces.answer"/> {questionAnswerSelect}
        </React.Fragment>}
      </React.Fragment>
    );
  }
}

Conjoint.defaultProps = {
  changeable: true
};

Conjoint.propTypes = {
  condQuestion: PropTypes.shape({
    question: PropTypes.number.isRequired,
    condType: PropTypes.oneOf(condTypes),
    detailType: PropTypes.oneOf(detailTypes),
    answers: PropTypes.arrayOf(PropTypes.shape({
      answer: PropTypes.number.isRequired,
    })),
    answerPlaces: PropTypes.shape({
      answer: PropTypes.number,
      places: PropTypes.array,
    }),
    answersPlace: PropTypes.shape({
      answers: PropTypes.array,
      place: PropTypes.number,
    }),
  }).isRequired,
  handleCondChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

export default injectIntl(Conjoint);