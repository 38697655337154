import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip} from 'reactstrap';
import ModalDelete from "./components/ModalDelete";
import ButtonOptions from "./components/ButtonOptions";
import ButtonFilters from "./components/ButtonFilters";
import ModalCopy from "./components/ModalCopy";
import ButtonPreview from "./components/ButtonPreview";
import ModalSaveTemplate from "./components/ModalSaveTemplate";
import {FormattedMessage} from "react-intl";
import ButtonType from "./components/ButtonType";
import ModalComment from "./components/ModalComment";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";
import {connect} from "react-redux";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import classnames from "classnames";
import FiltersAvailable from "components/PlanRestrictionsModals/modals/FiltersAvailable";
import PipingAvailable from "components/PlanRestrictionsModals/modals/PipingAvailable";
import _ from "lodash";
import UserQuestionTemplatesAvailable from "components/PlanRestrictionsModals/modals/UserQuestionTemplatesAvailable";
import ButtonPipings from "./components/ButtonPipings";
import ClearContentInQuestions from "scenes/Survey/Structure/components/ClearContentInQuestions";

class HeaderActions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      commentTooltip: false,
      dropdownSettingsOpen: false,
      dropdownQuestionTypeOpen: false,
      questionTypeOpen: false,

      modalDelete: false,
      modalClearContentInQuestion: false,
      modalSaveTemplate: false,
      modalCopy: false,
      modalComment: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(type) {
    this.setState({
      [type]: !this.state[type]
    })
  }

  render() {
    const {question, optionsOpen, pipingsOpen, filtersOpen, survey, surveyPlan, hasFilters, hasPipings} = this.props;

    return (
      <div className="d-flex align-self-start py-0 d-print-none">
        <ul className="d-flex align-content-start ml-auto mb-0 p-0 pl-2">
          <li className="d-lg-flex align-items-center mr-2 d-none">
            <i id={'question-comment-' + question.id} className={"fas fa-comment" + (question.comment ? ' text-primary' : '')} onClick={() => this.toggle('modalComment')}/>
            {question.comment && <Tooltip
              delay={1100}
              placement="bottom"
              isOpen={this.state.commentTooltip}
              target={'question-comment-' + question.id}
              toggle={() => this.toggle('commentTooltip')}
            >
              {question.comment}
            </Tooltip>}
          </li>
          <li className="d-none d-lg-flex align-items-center mr-2"><ButtonType question={question}/></li>
          <li className="d-none d-lg-flex align-items-center mr-2"><ButtonPreview surveyId={survey.id} id={question.id} /></li>
          <li className="d-none d-lg-flex align-items-center mr-2"><ButtonOptions id={question.id} optionsOpen={optionsOpen} handleToggleOptions={this.props.handleToggleOptions}/></li>
          {hasFilters && <li className="d-none d-lg-flex align-items-center mr-2"><ButtonFilters id={question.id} filtersOpen={filtersOpen} handleToggleFilters={this.props.handleToggleFilters}/></li>}
          {hasPipings && <li className="d-none d-lg-flex align-items-center mr-2"><ButtonPipings id={question.id} pipingsOpen={pipingsOpen} handleTogglePipings={this.props.handleTogglePipings}/></li>}
          <li className="d-flex align-items-center">
            <Dropdown isOpen={this.state.dropdownSettingsOpen} toggle={() => this.toggle('dropdownSettingsOpen')}>
              <DropdownToggle className="m-0 p-0" color="">
                <span className="fas fa-ellipsis-v hoverable" />
              </DropdownToggle>
              {this.state.dropdownSettingsOpen && <DropdownMenu>
                {!hasFilters && <DropdownItem tag="a" onClick={() => surveyPlan.filters ? this.props.handleToggleFilters() : this.props.setPlanRestrictionsModals(FiltersAvailable.getName())} className={classnames({
                  'd-none d-lg-block': true,
                  'text-primary': filtersOpen,
                  'bg-light': !surveyPlan.filters
                })}>
                  <p><span className="fas fa-filter" style={filtersOpen ? {filter: 'none'} : {}}/></p> <FormattedMessage tagName="h5" id="question.headerActions.dropdownItem.filter"/>
                </DropdownItem>}

                {!hasPipings && <DropdownItem tag="a" onClick={() => surveyPlan.piping ? this.props.handleTogglePipings() : this.props.setPlanRestrictionsModals(PipingAvailable.getName())} className={classnames({
                  'd-none d-lg-block': true,
                  'text-primary': pipingsOpen,
                  'bg-light': !surveyPlan.piping
                })}>
                  <p><span className="fas fa-share-alt" style={pipingsOpen ? {filter: 'none'} : {}}/></p> <FormattedMessage tagName="h5" id="question.headerActions.dropdownItem.piping"/>
                </DropdownItem>}

                <Link to={appRoutes.survey.preview.question(survey.id, question.id)} id={'question-' + question.id + '-preview'} className="dropdown-item d-lg-none">
                  <p><span className="fas fa-search"/></p> <span className="text-dark"><FormattedMessage tagName="h5" id="question.headerActions.buttonPreview.button.tooltip"/></span>
                </Link>

                <DropdownItem tag="a" onClick={this.props.handleToggleOptions} className={'d-lg-none ' + (optionsOpen ? 'text-primary' : '')}>
                  <p><span className="fas fa-cog" style={optionsOpen ? {filter: 'none'} : {}}/></p> <FormattedMessage tagName="h5" id="question.headerActions.buttonOptions.button.tooltip"/>
                </DropdownItem>

                <DropdownItem tag="a" onClick={() => this.toggle('modalComment')} className='d-lg-none'>
                  <p><span className="fas fa-comment" /></p> <FormattedMessage tagName="h5" id="question.headerActions.modalComment.button"/>
                </DropdownItem>

                <DropdownItem tag="a" onClick={() => this.toggle('modalClearContentInQuestion')}>
                  <p><span className="fa-solid fa-eraser" /></p> <h5>Usuń znaczniki w treści</h5>
                </DropdownItem>

                <DropdownItem tag="a" onClick={() => surveyPlan.userQuestionTemplates ? this.toggle('modalSaveTemplate') : this.props.setPlanRestrictionsModals(UserQuestionTemplatesAvailable.getName())}>
                  <p><span className="fas fa-file"/></p> <FormattedMessage tagName="h5" id="question.headerActions.dropdownItem.saveTemplate"/>
                </DropdownItem>

                <DropdownItem tag="a" onClick={() => this.toggle('modalCopy')}>
                  <p><span className="fas fa-copy"/></p> <FormattedMessage tagName="h5" id="question.headerActions.dropdownItem.copy" />
                </DropdownItem>

                <DropdownItem tag="a" onClick={() => this.toggle('modalDelete')}>
                  <p><span className="fas fa-trash"/></p> <FormattedMessage tagName="h5" id="question.headerActions.dropdownItem.delete" />
                </DropdownItem>
              </DropdownMenu>}
            </Dropdown>
          </li>
          {this.state.modalSaveTemplate && <ModalSaveTemplate question={question} isOpen={this.state.modalSaveTemplate} toggleModal={() => this.toggle('modalSaveTemplate')}/>}
          {this.state.modalCopy && <ModalCopy question={question} isOpen={this.state.modalCopy} toggleModal={() => this.toggle('modalCopy')}/>}
          {this.state.modalDelete && <ModalDelete question={question} isOpen={this.state.modalDelete} toggleModal={() => this.toggle('modalDelete')}/>}
          {this.state.modalComment && <ModalComment question={question} isOpen={this.state.modalComment} toggleModal={() => this.toggle('modalComment')}/>}
          {this.state.modalClearContentInQuestion && <ClearContentInQuestions questionId={question.id} toggleModal={() => this.toggle('modalClearContentInQuestion')}/>}
        </ul>
      </div>
    )
  }
}

HeaderActions.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  handleTogglePipings: PropTypes.func.isRequired,
  handleToggleFilters: PropTypes.func.isRequired,
  handleToggleOptions: PropTypes.func.isRequired,
  optionsOpen: PropTypes.bool.isRequired,
  pipingsOpen: PropTypes.bool.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  setPlanRestrictionsModals: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  let filters = [];

  _.each(state.survey.structure.data.filters, filter => {
    _.each(filter.actions, action => {

      if (action.question === props.question.id || (action.hasOwnProperty('questions') && action.questions.includes(props.question.id))) {
        filters.push(filter);
        return false;
      }
    });

    _.each(filter.blocks, block => {
      _.each(block.condsQuestion, condition => {
        if (condition.question === props.question.id) {
          filters.push(filter);
          return false;
        }
      });
    });
  });

  return {
    survey: state.survey.structure.data.survey,
    surveyPlan: state.user.userPlan.plan.survey,
    hasFilters: _.uniqBy(filters, 'id').length > 0,
    hasPipings: Object.values(state.survey.structure.data.pipings).filter(piping => piping && piping.questionFrom && piping.questionFrom.question == props.question.id).length > 0,
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(HeaderActions);
