import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from "striptags";


class ColumnTbody extends Component {
  getAnswer(answerId){
    return this.props.question.answers.find(answer => answer.id === parseInt(answerId));
  }

  getExcluding(excludingId){
    return this.props.question.excludings.find(excluding => excluding.id === parseInt(excludingId));
  }

  render() {
    const {questionResponse, question} = this.props;
    let detail = false;

    if(questionResponse && questionResponse.details){
      detail = questionResponse.details[0];
    }

    return <React.Fragment>
      <td className="text-center border-left border-right">
        {detail ? <div dangerouslySetInnerHTML={{__html: striptags(this.getAnswer(detail.answer).content)}} /> : <i>Brak odpowiedzi</i>}
      </td>
      {question.answers.find(answer => answer.isOpen) && <td className="text-center border-left border-right">
        {detail ? detail.content : ''}
      </td>}
      {question.excludings.length > 0 && <td className="text-center border-left border-right">
        {(questionResponse && questionResponse.excluding) ? <div dangerouslySetInnerHTML={{__html: striptags(this.getExcluding(questionResponse.excluding).content)}} /> : ''}
      </td>}
    </React.Fragment>;
  }
}

ColumnTbody.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['single']),
    answers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
  }).isRequired,
  questionResponse: PropTypes.shape({
    details: PropTypes.arrayOf(PropTypes.shape({

    }).isRequired,),
  }),
};

export default ColumnTbody;