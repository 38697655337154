import React, {Component} from 'react';
import PropTypes from "prop-types";
import AnswersImage from "../../../AnswersImage";
import {connect} from "react-redux";
import SettingBool from "../../../SettingBool";

class RankedSettings extends Component {

  render() {
    const {question, questionSettingsPlan} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'rotateAnswers'} value={question.rotateAnswers} disabled={!questionSettingsPlan.ranked.rotateAnswers} icon={<i className="fas fa-random" />} handleUpdate={this.props.handleUpdate}/>
        <AnswersImage question={question} handleUpdate={this.props.handleUpdate}/>
      </React.Fragment>
    )
  }
}

RankedSettings.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    questionSettingsPlan: state.user.userPlan.plan.survey.questionSettings
  }
}

export default connect(mapStateToProps)(RankedSettings);