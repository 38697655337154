import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import CheckedDelete from "./components/CheckedDelete";
import {Col, Row} from "reactstrap";

class BasketsActions extends Component {

  render() {
    const {question, checkedBaskets, baskets} = this.props;

    let basketsChecked =  _.map(checkedBaskets, id => baskets.find(basket => basket.id === id) || false);
    basketsChecked = basketsChecked.filter(basket => basket !== false);

    return (
      <Row className="mt-2">
        <Col xs={12}>
          {basketsChecked.length > 0 &&
          <div className="direction-group w-100 label-filtr-wrap mb-0">
            <ul className="direction-left mb-0 p-0">
              <li className="float-left">
                <CheckedDelete question={question} basketsChecked={basketsChecked}/>
              </li>
            </ul>
          </div>}
        </Col>
      </Row>
    )
  }
}

BasketsActions.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  checkedBaskets: PropTypes.array.isRequired,
  baskets: PropTypes.array.isRequired,
};

export default BasketsActions;