import React, {Component} from 'react';
import api from "api";
import ApplicationTable from "../../components/Application/ApplicationTable";
import {Link} from "react-router-dom";
import appRoutes from "../../appRoutes";
import SurveySwitcherCheckAddModal from "./SurveySwitcherCheckAddModal";
import history from "services/history";
import SearchTextInput from "../../components/Application/ApplicationTable/SearchTextInput";
import {Badge} from "reactstrap";
import SurveySwitcherCheckHistoryModal from "componentsNew/SurveySwitcherChecks/SurveySwitcherCheckHistoryModal";

class SurveySwitcherCheckHistoryTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  render() {
    const columns = [
      {
        id: 'createdAt',
        Header: 'Data',
        width: 200,
        accessor: row => row.createdAt
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: row => {
          let color = 'warning';

          if(row.status.valid === false){
            color = 'danger';
          }else if(row.status.valid === true){
            color = 'success';
          }

          return <Badge color={color} style={{fontSize: '100%'}}>{row.status.msg}</Badge>
        }
      },
    ];

    return <React.Fragment>
      <ApplicationTable
        ref={this.tableRef}
        columns={columns}
        fetchData={api.surveySwitcherChecks.history.search}
        deleteData={api.surveySwitcherChecks.history.delete}
        filters={{
          ...this.props.filters,
          surveySwitcherCheck: this.props.surveySwitcherCheck.id,
        }}
      />
    </React.Fragment>;
  }
}

export default SurveySwitcherCheckHistoryTable;
