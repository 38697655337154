import React, {Component} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import DeleteAccount from "./components/DeleteAccount";
import ProfileFormEmailChange from "./components/ProfileFormEmailChange";
import ProfileFormPasswordUpdate from "./components/ProfileFormPasswordUpdate";
import ProfileFormCompany from "./components/ProfileFormCompany";

class ProfileCompany extends Component {
  componentDidMount() {
    document.title = 'Ankieteo - Dane firmy';
  }
  render() {
    return <Card className="h-100 mb-0">
      <CardHeader><h2><i className="fas fa-user mr-2" /> Dane firmy</h2></CardHeader>
      <hr className="my-0" />
      <CardBody>
        <div className="my-3">
          <ProfileFormCompany />
        </div>
        <div className="my-3">
          <ProfileFormEmailChange />
        </div>
        <div className="my-3">
          <ProfileFormPasswordUpdate />
        </div>

        <DeleteAccount />
      </CardBody>
    </Card>;
  }
}

export default ProfileCompany;