import React, {Component} from 'react';
import api from "api";
import ApplicationTable from "components/Application/ApplicationTable";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";
import history from "services/history";
import UserEmailDomainBlacklistAddModal from "./UserEmailDomainBlacklistAddModal";

class UserEmailDomainBlacklist extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  render() {
    const columns = [
      {
        id: 'id',
        Header: 'Id',
        width: 80,
        accessor: row => row.id
      },
      {
        accessor: 'domain',
        Header: 'Domena',
      },
    ];

    return <React.Fragment>
      <div className="d-flex justify-content-between mb-2">
        <UserEmailDomainBlacklistAddModal onModalClose={() => {}} onAdded={dictionary => {
          this.tableRef.current.fetchDataDelay(1)
        }} />

        <div className="ml-auto">
          {/*<SearchTextInput*/}
          {/*  tableRef={this.tableRef}*/}
          {/*/>*/}
        </div>
      </div>
      <ApplicationTable
        ref={this.tableRef}
        columns={columns}
        fetchData={api.userEmailDomainBlacklists.search}
        deleteData={api.userEmailDomainBlacklists.delete}
        // filters={this.props.filters}
      />
    </React.Fragment>;
  }
}

export default UserEmailDomainBlacklist;
