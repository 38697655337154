import * as React from 'react';
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import api from "../../../../../api";
import LoadingSpinner from "../../../../../components/LoadingSpinner";

function MobilePushSendModal(props) {
	const {visible, onCancel, collector} = props;
	const [error, setError] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);
	const [range, setRange] = React.useState(0);
	const [pushRange, setPushRange] = React.useState(0);
	const [lastSending, setLastSending] = React.useState(null);

	React.useEffect(() => {
		api.surveyCollector.panel.post.mobilePush.posibilityRange(collector).then((res) => {
			setRange(res.range);
			setLastSending(res.lastSending);
			setPushRange(res.pushRange);
		})
	}, [])

	const sendPushToRespondents = () => {
		setIsLoading(true);
		setError("");
		api.surveyCollector.panel.post.mobilePush.send(collector).then((res) => {
			setIsLoading(false);
			if (res.error) {
				setError(res.error);
			} else {
				onCancel()
			}
		})
	}

	return (
		<Modal isOpen={visible} toggle={onCancel} size="md">
			<ModalHeader tag={"h2"} toggle={onCancel}>Wysyłka push do respondentów</ModalHeader>
			<hr className="my-0"/>
			<ModalBody>
				{range > 0 && <p>Zasięg ogólny kolektora: (użytkownicy) {range}</p>}
				{pushRange > 0 && <p>Zasięg wysyłek push kolektora: (urządzenia użytkowników) {pushRange}</p>}
				{lastSending && <p>Ostatnia data wysyłki push {lastSending}</p>}
				{error && <p style={{color: "red"}}>{error}</p>}
			</ModalBody>
			<ModalFooter>
				<Button color="primary" className="mb-0" onClick={sendPushToRespondents}>{isLoading && <LoadingSpinner width={20} classNames="my-0"/>}Wyślij push do respondentów</Button>
				<Button color="warning" className="mb-0" onClick={onCancel}>Zamknij</Button>
			</ModalFooter>
		</Modal>
	)
}

MobilePushSendModal.propTypes = {
	visible: false,
	onCancel: null,
	collector: null
};

function mapStateToProps(state) {
	return {}
}

export default connect(mapStateToProps, {})(MobilePushSendModal);