import React from 'react';
import PropTypes from 'prop-types';
import {ListGroup, ListGroupItem, Table} from "reactstrap";
import {statusDetails, statusNumberToClass} from "./StageBox";
import Icon from "../../../componentsReusable/Icon";

const DetailRow = ({detail}) => {
  const [subDetailsShow, setSubDetailsShow] = React.useState(false);
  const detailStatusDetail = statusDetails(detail.status)

  return <tr>
    <td className={`text-${statusNumberToClass(detail.status)}`}>{detail.label}</td>
    <td>
      <div className="d-flex flex-column">
        <div className={`d-flex flex-row align-items-center text-${statusNumberToClass(detail.status)}`}>
          <div className="text-center" style={{width: 22}}>
            <i className={`fas ${detailStatusDetail.icon}`} style={{ fontSize: '1rem' }} />
          </div>
          <span className="ml-2" dangerouslySetInnerHTML={{ __html: detail.msg}} />
          {detail.details.length > 0 && <span onClick={() => setSubDetailsShow(!subDetailsShow)}>
            <Icon className={'pointer fas fa-caret-down ml-1 px-2 text-secondary'} tooltip={'Pokaż szczegóły'} />
          </span>}
        </div>

        {subDetailsShow && <ListGroup className="mt-2">
          {detail.details.map(subDetail => <ListGroupItem className="py-1">
            <div dangerouslySetInnerHTML={{__html: subDetail}}/>
          </ListGroupItem>)}
        </ListGroup>}
      </div>
    </td>
  </tr>
}

const StageDetailsTable = ({stageData}) => {
  return (
    <Table bordered>
      <thead>
      <tr>
        <th style={{width: 350}}>Element</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      {stageData.details.map(detail => {
        return <DetailRow detail={detail} />
      })}
      </tbody>
    </Table>
  );
};

StageDetailsTable.propTypes = {

};

export default StageDetailsTable;
