import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import CheckedDelete from "./components/CheckedDelete";
import {Col, Row} from "reactstrap";

class ExcludingsActions extends Component {

  render() {
    const {question, checkedExcludings, excludings} = this.props;

    let excludingsChecked =  _.map(checkedExcludings, id => excludings.find(excluding => excluding.id === id) || false);
    excludingsChecked = excludingsChecked.filter(excluding => excluding !== false);

    return (
      <Row className="mt-2">
        <Col xs={12}>
          {excludingsChecked.length > 0 &&
          <div className="direction-group w-100 label-filtr-wrap mb-0">
            <ul className="direction-left mb-0 p-0">
              <li className="float-left">
                <CheckedDelete question={question} excludingsChecked={excludingsChecked}/>
              </li>
            </ul>
          </div>}
        </Col>
      </Row>
    )
  }
}

ExcludingsActions.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  checkedExcludings: PropTypes.array.isRequired,
  excludings: PropTypes.array.isRequired,
};

export default ExcludingsActions;