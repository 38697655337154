export const SET = 'Analysis/data/survey/SET';
export const UPDATED = 'Analysis/data/survey/UPDATED';

export function setSurvey(survey) {
  return {
    type: SET,
    survey
  }
}

export function updatedSurvey(surveyToMerge) {
  return {
    type: UPDATED,
    survey: surveyToMerge
  }
}