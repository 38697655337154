import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from "striptags";
import api from "../../../../../../../../../api";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";
import SelectBaskets from "../../../../../../../Structure/components/SelectBaskets";

class ColumnTfoot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      answer: false,
      excluding: false,
      basket: '',
      modalIsOpen: false,
    };

    this.getAnswer = this.getAnswer.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.save = this.save.bind(this);
  }

  getAnswer(answerId){
    return this.props.question.answers.find(answer => answer.id === answerId);
  }

  modalToggle(answer = false){
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
      answer,
      excluding: false,
      basket: '',
    });
  }

  save(){
    const {answer, basket, excluding} = this.state;
    const {question, responsesSelected} = this.props;

    this.setState({
      saving: true,
    });

    let data = {
      responses: responsesSelected,
      answer,
      excluding,
      basket,
    };

    api.questionResponse.question.patchDetails(question.id, data)
    .then(data => {
      this.setState({
        saving: false,
        answer: false,
        basket: '',
        excluding: false,
        modalIsOpen: false,
      });

      this.props.onSaved();
    })
    .catch(res => {
      this.setState({
        saving: false,
      });
    });
  }

  render() {
    const {modalIsOpen, answer, saving} = this.state;
    const {responsesSelected, question} = this.props;

    return <React.Fragment>
      {question.answers.map((answer, key) => {
        return <React.Fragment key={key.toString()}>
          <td>
            <Button
              onClick={() => this.modalToggle(answer.id)}
              disabled={responsesSelected.length === 0}
              size="sm"
              color="secondary"
              className="w-100 text-center"
            >Zmień</Button>
          </td>
        </React.Fragment>
      })}

      {question.excludings.length > 0 && <td>
        <Button
          onClick={() => this.modalToggle(false)}
          disabled={responsesSelected.length === 0}
          size="sm"
          color="secondary"
          className="w-100 text-center"
        >Zmień</Button>
      </td>}

      {modalIsOpen && <Modal isOpen={true} toggle={this.modalToggle} size="xl">
        <ModalHeader tag="h2" toggle={this.modalToggle}>Zmień odpowiedź dla wybranych wywiadów</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label md={3}>Liczba zaznaczonych wywiadów:</Label>
              <Col md={9} className="d-flex align-items-center">{responsesSelected.length}</Col>
            </FormGroup>
            {answer && <FormGroup row>
              <Label md={3}>Zmiana dotyczy odpowiedzi:</Label>
              <Col md={9} className="d-flex align-items-center">{striptags(this.getAnswer(answer).content)}</Col>
            </FormGroup>}
            {answer && <FormGroup row>
              <Label md={3}>W zaznaczonych wywiadach zmień odpowiedź na:</Label>
              <Col md={9} className="d-flex align-items-center">
                <SelectBaskets
                  className="w-100"
                  isMulti={false}
                  questionId={question.id}
                  onChange={basketId => this.setState({basket: basketId})}
                />
              </Col>
            </FormGroup>}
            {!answer && <FormGroup row>
              <Label md={3}>W zaznaczonych wywiadach zmień na odpowiedź wykluczającą:</Label>
              <Col md={9} className="d-flex align-items-center">
                <SelectExcludings
                  questionId={question.id}
                  className="w-100"
                  onChange={excluding => this.setState({excluding: excluding || false})}
                />
              </Col>
            </FormGroup>}
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={this.modalToggle}>{message}</Button>
          }</FormattedMessage>
          <FormattedMessage id="_.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mb-0"
              loading={saving}
              data-style={ZOOM_OUT}
              onClick={() => this.save()}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>}
    </React.Fragment>;
  }
}

ColumnTfoot.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['basket']),
    answers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
  }).isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default ColumnTfoot;