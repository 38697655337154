import React, {Component} from "react";
import Button from "./Button";
import {Modal, ModalHeader, Badge, ModalBody, Row, Col, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";

class KeyboardShortcutsInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };

    this.toggleModalOpen = this.toggleModalOpen.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind("shift+/", () => { this.setState({modalOpen: !this.state.modalOpen,}) });
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+/");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.modalOpen !== this.props.modalOpen){
      this.setState({
        modalOpen: this.props.modalOpen
      })
    }
  }

  toggleModalOpen(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {modalOpen} = this.state;

    return <React.Fragment>
      {modalOpen && <Modal isOpen={true} toggle={this.toggleModalOpen} size="lg">
        <ModalHeader tag="h2" toggle={this.toggleModalOpen}>Skróty klawiszowe <Badge color="secondary" className="ml-2">Shift + /</Badge></ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          <Row>
            <Col xs={12} lg={6}>
              <div className="d-flex flex-column align-content-center w-100 p-2">
                <div className="mb-2"><strong style={{fontSize: '1em'}}>Projekt</strong></div>
                {[
                  {name: 'Zakładka struktura', shortcut: 'Shift + s'},
                  {name: 'Zakładka podgląd', shortcut: 'Shift + d'},
                  {name: 'Widoczność lewej nawigacji', shortcut: 'Shift + w'},
                  {name: 'Udostępnianie projektu', shortcut: 'Shift + u'},
                  {name: 'Eksport', shortcut: 'Shift + e'},
                  {name: 'Ustawienia', shortcut: 'Shift + z'},
                  {name: 'Szablony bloków', shortcut: 'Shift + b'},
                  {name: 'Szablony pytań', shortcut: 'Shift + x'},
                  {name: 'Nowy filtr', shortcut: 'Shift + f'},
                  {name: 'Wstaw stronę', shortcut: 'Shift + p'},
                  {name: 'Dodaj odpowiedzi z listy', shortcut: 'Shift + r'},
                  {name: 'Dodaj pytania z listy', shortcut: 'Shift + q'},
                  {name: 'Podmiana treści w ankiecie', shortcut: 'Shift + h'},
                ].map(row => <div className="d-flex justify-content-between align-content-center mb-1"><span>{row.name}</span><Badge color="secondary">{row.shortcut}</Badge></div>)}
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="d-flex flex-column align-content-center w-100 p-2">
                <div className="mb-2"><strong style={{fontSize: '1em'}}>Lista projektów</strong></div>
                {[
                  {name: 'Tylko aktywne', shortcut: 'Shift + a'},
                  {name: 'Ulubione', shortcut: 'Shift + f'},
                ].map(row => <div className="d-flex justify-content-between align-content-center mb-1"><span>{row.name}</span><Badge color="secondary">{row.shortcut}</Badge></div>)}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.close">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={this.toggleModalOpen}>{message}</Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  }
}

export default KeyboardShortcutsInfo;