import React from 'react';
import PropTypes from "prop-types";
import {Tooltip, Collapse, Card, CardBody} from "reactstrap";
import { connect } from 'react-redux';

function HelpModeIconTooltip(props) {
  if (!props.helpMode) {
    return null;
  }

  const [tooltip, setTooltip] = React.useState(false);
  const [collapse, setCollapse] = React.useState(false);
  const [randomId] = React.useState("helpIcon-" + Math.random().toString(36).substring(7));

  return (
    <React.Fragment>
      <i id={randomId} className={"text-info " + props.className + " " + props.icon} onClick={() => setCollapse(!collapse)}/>
      <Tooltip
        placement="bottom"
        isOpen={tooltip}
        target={randomId}
        toggle={() => setTooltip(!tooltip)}
        autohide={props.autohide}
        delay={{show: 0, hide: props.autohide ? 0 : 100}}
      >
        {props.children}
      </Tooltip>
      <Collapse isOpen={collapse} className="position-absolute d-lg-none" style={{zIndex: 2}}>
        <Card>
          <CardBody>
            {props.children}
          </CardBody>
        </Card>
      </Collapse>
    </React.Fragment>
  );
}

HelpModeIconTooltip.defaultProps = {
  icon: 'far fa-question-circle',
  autohide: true
};

HelpModeIconTooltip.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  autohide: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    helpMode: state.user.helpMode
  }
}

export default connect(mapStateToProps)(HelpModeIconTooltip);