import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import SmtpsButtonDelete from './SmtpsButtonDelete';
import SmtpsButtonEdit from './SmtpsButtonEdit';
import ReactTable from "react-table";
import Pagination from "components/Pagination";

class SmtpsList extends Component {

  static actions(smtp){
    return (
      <ul className="float-right mb-0 p-0">
        <li className="float-left mr-2">
          <SmtpsButtonEdit smtp={smtp}/>
        </li>
        <li className="float-left mr-2">
          <SmtpsButtonDelete smtp={smtp}/>
        </li>
      </ul>
    )
  }

  static ssl(ssl){
    return ssl ? <FormattedMessage id="smtpsList.table.ssl.true" /> : <FormattedMessage id="smtpsList.table.ssl.false" />;
  }

  render() {
    const {smtps} = this.props;

    const columns = [{
      Header: <FormattedMessage id="smtpsList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="smtpsList.listItems.tableHeader.name" />,
      accessor: 'name'
    }, {
      Header: <FormattedMessage id="smtpsList.listItems.tableHeader.host" />,
      accessor: 'host'
    }, {
      Header: <FormattedMessage id="smtpsList.listItems.tableHeader.port" />,
      accessor: 'port'
    }, {
      Header: <FormattedMessage id="smtpsList.listItems.tableHeader.email" />,
      accessor: 'email'
    }, {
      Header: <FormattedMessage id="smtpsList.listItems.tableHeader.password" />,
      accessor: 'password'
    }, {
      id: 'ssl',
      Header: <FormattedMessage id="smtpsList.listItems.tableHeader.ssl" />,
      accessor: smtp => SmtpsList.ssl(smtp.ssl)
    }, {
      id: 'actions',
      Header: <FormattedMessage id="smtpsList.listItems.tableHeader.actions" />,
      accessor: smtp => SmtpsList.actions(smtp)
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={Object.values(smtps)}
        columns={columns}
        showPagination={!_.isEmpty(smtps)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="smtpsList.listItems.table.noSmtps" />}
        defaultPageSize={10}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    smtps: state.smtps.data.smtps,
  }
}

export default connect(mapStateToProps)(SmtpsList);