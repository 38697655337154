import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import QuotaForm from "./QuotaForm";
import api from "api";


class ButtonAddQuota extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      saving: false,
      errors: {},
    };

    this.form = React.createRef();
    this.toggleModal = this.toggleModal.bind(this);
    this.handlerOnSubmit = this.handlerOnSubmit.bind(this);
  }

  toggleModal(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  handlerOnSubmit(data){
    const {surveyId} = this.props;

    this.setState({
      saving: true,
      errors: {}
    });

    api.survey.post.quota(surveyId, data)
      .then(quota => {
        this.setState({
          saving: false,
          modalOpen: false,
        });

        this.props.onAdded(quota);
      })
      .catch(res => {
        this.setState({
          saving: false,
          errors: res.response.data.form.errors,
        });

      });
  }

  render() {
    const {modalOpen, saving, errors} = this.state;
    const {surveyId} = this.props;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal} tag="h2">Utwórz kwotę</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <QuotaForm
          surveyId={surveyId}
          onSubmit={this.handlerOnSubmit}
          errors={errors}
          ref={this.form}
        />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={() => this.form.current.onSubmit()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="primary" onClick={this.toggleModal}>
          {this.props.children}
        </Button>
        {modal}
      </React.Fragment>
    );
  }
}

ButtonAddQuota.propTypes = {
  surveyId: PropTypes.number.isRequired,
  onAdded: PropTypes.func.isRequired,
};

export default ButtonAddQuota;