import React from 'react';
import PropTypes from 'prop-types';
import useDidMountEffect from "../utils/useDidMountEffect";
import api from "../api";
import {Badge, Card, CardBody, CardHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import QuestionSummaryByType from "./Survey/Analysis/components/View/QuestionSummaries/QuestionSummaryByType";
import withParams from "../utils/withParams";

const AnalysisCharts = props => {
  const [questionSummaries, setQuestionSummaries] = React.useState([]);

  useDidMountEffect(() => {
    api.analysis.get.questionSummariesByAnalysisUid(props.params.uid)
      .then(setQuestionSummaries)
  })

  return <div className="mx-auto">
    {questionSummaries.map(questionSummary => <Card>
      <CardHeader className="d-flex align-items-center justify-content-between">
        {questionSummary.question.content ? <h2 dangerouslySetInnerHTML={{__html: questionSummary.question.content}} className="img-30" /> : <h2><i><FormattedMessage id="_.question.content.empty" /></i></h2>}
      </CardHeader>
      <CardBody>
        <div className="d-flex align-content-center justify-content-center mb-3">
          <Badge color="secondary" style={{fontSize: '1em'}}>N = {questionSummary.data.n}</Badge>
        </div>
        <QuestionSummaryByType questionSummary={questionSummary} table={false} />
      </CardBody>
    </Card>)}
  </div>;
};

AnalysisCharts.propTypes = {

};

export default withParams(AnalysisCharts);
