import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Switch from "../../../../../../components/Switch";


class ModalAccept extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      data: {
        msg: '',
        email: false,
      }
    };

    this.cancel = this.cancel.bind(this);
    this.accept = this.accept.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
    this.cancel();
  }

  cancel(){
    this.props.onCancel();
  }

  accept(){
    this.props.onAccept(this.state.data);
  }

  render() {
    const {data} = this.state;

    return <Modal isOpen={true} toggle={this.toggleModal} size="md">
      <ModalHeader tag="h2" toggle={this.toggleModal}>Zaakceptuj ankietę</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Input type={"textarea"} onChange={(e) => this.setState({data: {...data, msg: e.target.value}})} value={data.msg}/>
        <Switch onChange={value => this.setState({data: {...data, email: value}})} checked={data.email} /> Wyślij maila
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.cancel}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.accept">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => this.accept()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;
  }
}

ModalAccept.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ModalAccept;
