import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Card, Col, Row, CardHeader, CardBody, Input, Label} from "reactstrap";
import Blocks from "../Filter/Blocks";
import "../Filter/styles/style.scss"
import InputNumber from "../../../../../components/InputNumber";

class QuotaNew extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      quotaNew: props.quotaNew || {
        name: 'Kwota',
        limit: 0,
        actions: []
      }
    };
  }

  getQuotaNew(){
    const {quotaNew} = this.state;

    return quotaNew;
  }

  render() {
    const {quotaNew} = this.state;

    return (
      <Row id="quotaNew">
        <Label md={3}>Nazwa:</Label>
        <Col md={9}>
          <Input type="text" name="name" value={quotaNew.name} onChange={e => this.setState({quotaNew: {...quotaNew, name: e.target.value}})}/>
        </Col>
        <Label md={3}>Limit:</Label>
        <Col md={9}>
          <InputNumber min={0} max={100000} value={quotaNew.limit} onChange={(limit) => this.setState({quotaNew: {...quotaNew, limit,}})}/>
        </Col>
        <Col xs={12}>
          <Card>
            <CardHeader className="text-center"><h2>Warunki</h2></CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Blocks blocks={quotaNew.blocks || undefined} handleBlocksChanged={(blocks) => {
                this.setState({
                  quotaNew: {
                    ...quotaNew,
                    blocks
                  }
                })
              }} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

QuotaNew.propTypes = {
  quotaNew: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default connect(null, null, null, { forwardRef: true })(QuotaNew);
