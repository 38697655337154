import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Button from "../../../components/Button";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";
import FiltersGroupForm from "./FiltersGroupForm";

export default function FiltersGroup(){
  const formRef = useRef(null)
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [saving, setSaving] = React.useState(false);

  const save = () => {
    formRef.current.save()
  }

  return <React.Fragment>
    <Button className={['ml-2']} onClick={() => toggleModalOpen()}><i className="fas fa-plus" /> Utwórz grupę filtrów</Button>
    {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="xl">
      <ModalHeader tag="h2" toggle={toggleModalOpen}>Utwórz grupę filtrów</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <FiltersGroupForm
          ref={formRef}
          onSavingStart={() => setSaving(true)}
          onSaved={() => setModalOpen(false)}
          onSavingEnd={() => setSaving(false)}
        />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.create">{
          (message) => <Button
            loading={saving}
            onClick={save}
          >
            {message}
          </Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>}
  </React.Fragment>
}