import api from "api";
import * as questionsActions from "../questions/actions";

export const SET = 'Survey/data/rows/SET';
export const ADDED = 'Survey/data/rows/ADDED';
export const DELETED = 'Survey/data/rows/DELETED';
export const UPDATED = 'Survey/data/rows/UPDATED';

export function setRows(rows) {
  return {
    type: SET,
    rows
  }
}
export function rowAdded(row) {
  return {
    type: ADDED,
    row
  }
}
export function rowDeleted(row) {
  return {
    type: DELETED,
    row
  }
}
export function rowUpdated(row) {
  return {
    type: UPDATED,
    row
  }
}





export function addRow(data) {
  return dispatch => {
    return api.question.post.row(data).then(row => {
      dispatch(rowAdded(row));
      dispatch(questionsActions.rowAdded(row));

      return row;
    });
  }
}

export function deleteRow(data) {
  return dispatch => {
    return api.row.delete.row(data).then(res => {
      dispatch(rowDeleted({
        id: data.id,
        question: {
          id: res.row.question.id
        }
      }))
    })
  }
}
export function deleteRows(questionId, data) {
  return dispatch => {
    return api.row.delete.list(data).then(() => {
      _.map(data.ids, id => {
        dispatch(rowDeleted({
          id,
          question: {
            id: questionId
          }
        }))
      })
    })
  }
}

export function updateRow(data) {
  return dispatch => {
    return api.row.patch(data).then(row => {
      dispatch(rowUpdated(row))
    })
  }
}