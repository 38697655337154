import React, {Component} from 'react';
import {connect} from "react-redux";
import {ReactSortable} from "react-sortablejs";
import {FormattedMessage} from "react-intl";
import i18Config from "i18Config";
import {map} from "lodash";
import PropTypes from "prop-types";
import OnMoving from "../../../OnMoving";
import OnAdding from "../../../OnAdding";
import QuestionListModal from "../../../../../Sets/components/Set/components/Blocks/components/Block/components/Pages/components/Page/components/Questions/components/ButtonAddQuestionList/components/QuestionListModal";

class QuestionListElements extends Component {

  constructor(props) {
    super(props);

    this.state = {
      adding: props.adding,
      pageId: null,
      position: null,
      question: null,
      after: 0,
      item: null
    };

    this.onEndQuestionList = this.onEndQuestionList.bind(this);
    this.shouldRenderElement = this.shouldRenderElement.bind(this);
    this.clearState = this.clearState.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind("shift+q", () => {
      let question = null;

      map(document.querySelectorAll('#structure div[role="question"]'), (q) => {
        const top = q.getBoundingClientRect().top;
        const height = q.getBoundingClientRect().height;

        if((top+height) > 230 && question === null){
          question = parseInt(q.attributes['data-id'].nodeValue);
          return false;
        }
      });

      this.setState({
        question,
        adding: true,
        after: 0,
      })
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+q");
  }

  onEndQuestionList(evt){
    if(evt.from === evt.to){
      return false;
    }
    evt.to.insertBefore(evt.item, evt.to.children[evt.newIndex]);

    const pageId = evt.to.parentElement.firstChild.attributes['data-id'].nodeValue;
    const questions = this.props.pages[pageId].questions;
    let question = null;
    let after = 0;

    if(questions.length > 0){
      if(evt.newIndex >= questions.length){
        question = questions[evt.newIndex-1];
        after = 1;
      }else{
        question = questions[evt.newIndex];
      }
    }

    this.setState({
      adding: true,
      pageId,
      position: evt.newIndex,
      item: evt.item,
      question,
      after,
    });
  }

  clearState() {
    if(this.state.item){
      this.state.item.remove();
    }

    this.setState({
      adding: false,
      pageId: null,
      position: null,
      question: null,
      after: null,
      item: null
    });
  }

  shouldRenderElement() {
    if (typeof this.props.searchText !== 'string')
      return true;

    const searchingText = this.props.searchText.toLowerCase();
    const elementText = i18Config.messages['structure.sidebar.elements.sortable.questionList'].toLowerCase();
    const elementName = 'question list';

    return elementText.includes(searchingText) || elementName.includes(searchingText);
  }

  render() {
    return this.shouldRenderElement() &&
      <React.Fragment>
        <ReactSortable
          list={[{}]}
          setList={() => {}}
          group={{
            name: 'questions',
            pull: 'clone',
            put: false,
            revertClone: true,
          }}
          sort={false}
          onEnd={this.onEndQuestionList}
        >
          <div className="line-wrap py-1" role="question">
            <a className="fas fa-grip-vertical dragg mr-3 mt-0"/>
            <a className="fa-solid fa-list-ol mr-2" style={{fontSize: 12, cursor: 'default'}}/>
            <h5 className="mb-0 d-inline-block"><FormattedMessage id="structure.sidebar.elements.sortable.questionList" /></h5>
            <OnMoving/>
            <OnAdding/>
          </div>
        </ReactSortable>
        {this.state.adding && <QuestionListModal
          isOpen={true}
          toggle={this.clearState}
          pageId={this.state.pageId ? parseInt(this.state.pageId, 10) : null}
          position={this.state.position ? parseInt(this.state.position, 10) : null}
          question={this.state.question}
          after={this.state.after}
        />}
      </React.Fragment>
  }
}

QuestionListElements.defaultProps = {
  adding: false,
};

QuestionListElements.propTypes = {
  adding: PropTypes.bool,
  searchText: PropTypes.string
};

function mapStateToProps(state) {
  return {
    questions: state.survey.structure.data.questions,
    pages: state.survey.structure.data.pages,
  }
}

export default connect(mapStateToProps)(QuestionListElements);
