import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import {FormattedMessage} from "react-intl";
import RedirectsListActions from './components/RedirectsListActions';

class RedirectsList extends Component {

  render() {
    const columns = [{
      Header: <FormattedMessage id="redirects.redirectsList.tableHeader.id" />,
      accessor: 'id'
    }, {
      Header: <FormattedMessage id="redirects.redirectsList.tableHeader.target" />,
      accessor: 'target'
    }, {
      Header: <FormattedMessage id="redirects.redirectsList.tableHeader.position" />,
      accessor: 'position'
    }, {
      id: 'status',
      Header: <FormattedMessage id="redirects.redirectsList.tableHeader.status" />,
      accessor: redirect => redirect.status ? <FormattedMessage id={"_.surveyResponse.status." + redirect.status + ".label"}/> : <i className="text-muted">Dowolny status</i>
    }, {
      Header: <FormattedMessage id="redirects.redirectsList.tableHeader.current" />,
      accessor: 'current'
    }, {
      Header: <FormattedMessage id="redirects.redirectsList.tableHeader.max" />,
      accessor: 'max'
    }, {
      id: 'actions',
      Header: <FormattedMessage id="redirects.redirectsList.tableHeader.actions" />,
      accessor: redirect => <RedirectsListActions redirect={redirect} onChange={this.props.handleChange}/>
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.props.redirects}
        columns={columns}
        showPagination={!_.isEmpty(this.props.redirects)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="redirects.redirectsList.table.noRedirects" />}
        defaultPageSize={5}
        loading={this.props.loading}
      />
    );
  }
}

RedirectsList.propTypes = {
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  redirects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    target: PropTypes.string.isRequired,
  })).isRequired
};

export default RedirectsList
