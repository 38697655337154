import React, {Component} from 'react';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {FormattedMessage} from "react-intl";
import Select, { components } from 'react-select';
import { connect } from 'react-redux';
import {saveSurveyShare} from '../actions';
import PropTypes from 'prop-types';
import _ from 'lodash';
import UserAvatar from "../../../components/UserAvatar";

class SurveyShareForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      saving: false,
      selected: null
    };

    this.onChange = this.onChange.bind(this);
    this.saveSurveyShare = this.saveSurveyShare.bind(this);
  }

  onChange(e) {
    this.setState({
      ...this.state,
      userId: e.value,
      selected: e
    })
  }

  saveSurveyShare() {
    this.setState({
      ...this.state,
      saving: true
    });

    this.props.saveSurveyShare({
      survey: this.props.surveyId,
      surveyShare: {
        user: this.state.userId,
        group: Object.keys(this.props.groups)[0]
      }
    }).then(() => {
      this.setState({
        userId: null,
        saving: false,
        selected: null
      });
    }).catch(() => {
      this.setState({
        saving: false
      });
    });
  }

  render() {
    const groupId = Object.keys(this.props.groups)[0];
    const { Option } = components;
    const avatarOption = (props) => {
      return <Option {...props}>
        <UserAvatar user={props.data.user} />
        <span className="ml-2">{props.data.label}</span>
      </Option>
    };

    let sharedUsersIds = [];
    _.each(this.props.surveyShares, surveyShare => sharedUsersIds.push(surveyShare.user.id));

    let options = [];
    let users = [];

    _.each(this.props.groups[groupId].users, user => {
      if (user.confirmed && parseInt(user.userId) !== this.props.userId) {
        users.push(user);
      }
    });

    _.each(users, user => {
      const content = (user.user.firstname && user.user.lastname) ? (user.user.firstname + ' ' + user.user.lastname) : user.user.email;

      const isAlreadyShared = sharedUsersIds.includes(parseInt(user.userId));
      !isAlreadyShared && options.push({user: user.user, label: content, value: user.user.id, avatar: user.user.avatar})
    });

    return (
      <div className="mb-5 d-flex align-items-center">
        <div className="w-100 w-sm-75 pr-3">
          <Select
            placeholder="Wybierz użytkownika..."
            onChange={this.onChange}
            options={options}
            value={this.state.selected}
            components={{Option: avatarOption}}
            filterOption={(data, search) => {
              if (search.length === 0)
                return true;

              let user = _.find(users, user => parseInt(user.user.id) === data.value).user;
              let name = (user.firstname + ' ' + user.lastname).toLowerCase();
              search = search.toLowerCase();

              return user.email.includes(search) || name.includes(search);
            }}
          />
        </div>
        <div className="w-100 w-sm-25">
          <FormattedMessage id="surveyShareForm.button">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda mb-0 w-100"
              loading={this.state.saving}
              data-style={ZOOM_OUT}
              onClick={this.saveSurveyShare}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userId: state.user.id,
    groups: state.groups.data.groups,
    surveyShares: state.surveyShares.data.surveyShares
  }
}

SurveyShareForm.propTypes = {
 surveyId: PropTypes.number.isRequired
};

export default connect(mapStateToProps, {saveSurveyShare})(SurveyShareForm);