import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import SettingBool from "../../../SettingBool";
import AnswersCount from "../../../AnswersCount";
import AnswersStart from "../../../AnswersStart";
import AnswersAddButtonCount from "../../../AnswersAddButtonCount";
import AnswersType from "../../../AnswersType";
import AnswersColumns from "../../../AnswersColumns";
import OpenRexep from "../../../OpenRexep";
import _ from "lodash";
import AnswersMin from "../../../AnswersMin";
import PresentationType from "../../../PresentationType";
import DateTimeIntervals from "../../../DateTimeIntervals";
import DateTimeMinDate from "../../../DateTimeMinDate";
import DateTimeMaxDate from "../../../DateTimeMaxDate";
import DateTimeMinTime from "../../../DateTimeMinTime";
import DateTimeMaxTime from "../../../DateTimeMaxTime";

class MultiOpenSettings extends Component {

  render() {
    const {question, questionSettingsPlan} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'required'} value={question.required} icon={<i className="fa-solid fa-message-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <AnswersMin question={question} disabled={!questionSettingsPlan.minMaxCafetery} handleUpdate={this.props.handleUpdate}/>
        <AnswersStart question={question} handleUpdate={this.props.handleUpdate}/>
        <AnswersCount question={question} handleUpdate={this.props.handleUpdate}/>
        <AnswersAddButtonCount question={question} handleUpdate={this.props.handleUpdate}/>
        <AnswersColumns question={question} handleUpdate={this.props.handleUpdate}/>
        <PresentationType question={question} handleUpdate={this.props.handleUpdate}/>
        {/*{question.presentationType === 'datetime' && <DateTimeIntervals question={question} handleUpdate={this.props.handleUpdate} />}*/}
        {question.presentationType === 'datetime' && <DateTimeMinDate question={question} handleUpdate={this.props.handleUpdate} />}
        {question.presentationType === 'datetime' && <DateTimeMaxDate question={question} handleUpdate={this.props.handleUpdate} />}
        {question.presentationType === 'datetime' && <SettingBool question={question} name={'dateTimeSelect'} value={question.dateTimeSelect} icon={<i className="fa-solid fa-calendar-clock"/>} handleUpdate={this.props.handleUpdate}/>}
        {(question.presentationType === 'datetime' && question.dateTimeSelect) && <DateTimeMinTime question={question} handleUpdate={this.props.handleUpdate} />}
        {(question.presentationType === 'datetime' && question.dateTimeSelect) && <DateTimeMaxTime question={question} handleUpdate={this.props.handleUpdate} />}

        {questionSettingsPlan.openRegex && <OpenRexep question={question} handleUpdate={this.props.handleUpdate} />}
      </React.Fragment>
    )
  }
}

MultiOpenSettings.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state, props) {
  return {
    questionSettingsPlan: state.user.userPlan.plan.survey.questionSettings,
    answers: _.map(props.question.answers, id => state.survey.structure.data.answers[id]),
  }
}

export default connect(mapStateToProps)(MultiOpenSettings);
