import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {addSmtp} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import SmtpsForm from './SmtpsForm';
import PropTypes from "prop-types";

class SmtpsCreateModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      errors: []
    };

    this.saveSmtp = this.saveSmtp.bind(this);
    this.smtpsForm = React.createRef();
  }

  saveSmtp(e, data){
    e.preventDefault();
    this.setState({saving: true, errors: []});

    this.props.addSmtp({
      data: data
    }).then(() => {
      this.setState({
        saving: false
      });
      this.props.toggle();
    })
      .catch((e) => {
        if(e.response.data.form.errors){
          this.setState({
            saving: false,
            errors: e.response.data.form.errors,
          });
        }
      });
  }

  render() {
    const { saving, errors } = this.state;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle} tag="h2"><FormattedMessage id="smtpsButtonCreate.modalHeader" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <SmtpsForm errors={errors} onSubmit={this.saveSmtp} ref={this.smtpsForm}/>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={saving}
                  data-style={ZOOM_OUT}
                  onClick={e => this.saveSmtp(e, this.smtpsForm.current.state)}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) =>
                  <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggle}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

SmtpsCreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default connect(null, { addSmtp }, null, {forwardRef: true})(SmtpsCreateModal);