import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Alert, Table} from "reactstrap";
import striptags from "striptags";

import LoadingSpinner from "../../../../../../../../../components/LoadingSpinner";

class AdvancedBoxes extends Component {
  getText(type){
    switch (type) {
      case 't2b': return 'Suma odsetków dwóch skrajnie pozytywnych odpowiedzi - top 2 box';
      case 't3b': return 'Suma odsetków trzech skrajnie pozytywnych odpowiedzi - top 3 box';
      case 'b2b': return 'Suma odsetków dwóch skrajnie negatywnych odpowiedzi - bottom 2 box';
      case 'b3b': return 'Suma odsetków trzech skrajnie negatywnych odpowiedzi - bottom 3 box';
    }
  }

  getByQuestionType(qType){
    const {data, advanced} = this.props;

    switch (qType) {
      case 'single': {
        return <div className="d-flex flex-column text-center">
          <span>{this.getText(advanced.type)}</span>
          <span className="mt-2">{data === 0 ? <i>Brak danych</i> : data.toFixed(1) + '%'}</span>
        </div>;
      }
      case 'differential':
      case 'ranked':
      case 'conjoint':
      case 'matrix': {
        return <div style={{overflowX: 'scroll'}}>
          <span className="d-block w-100 text-center mb-3">{this.getText(advanced.type)}</span>

          <Table bordered>
            <thead>
            <tr>
              <td></td>
              <td>Wartość</td>
            </tr>
            </thead>
            <tbody>
            {data.map((d, key) => {
              return <tr key={key.toString()}>
                <td><div dangerouslySetInnerHTML={{__html: striptags(d.row)}} /></td>
                <td>{d.value.toFixed(1)}%</td>
              </tr>
            })}
            </tbody>
          </Table>
        </div>;
      }
    }
  }

  render() {
    const {data, advanced} = this.props;

    if(data === false){
      return null;
    }

    return this.getByQuestionType(advanced.question.type);
  }
}

AdvancedBoxes.propTypes = {
  advanced: PropTypes.shape({
    question: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AdvancedBoxes;