import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button, Row, Col, Label} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import { connect } from 'react-redux';
import {addSet} from '../../../../data/sets/actions';
import Select from "react-select";
import SelectSets from "../../../SelectSets";
import _ from "lodash";

class SetModal extends Component {

  constructor(props) {
    super(props);

    let all = document.querySelectorAll("[data-type='setCard']");
    let setId;

    for (let i = 0; i < all.length; i++) {
      if (!all[i + 1]) {
        setId = parseInt(all[i].getAttribute('data-id'));
        break;
      }

      let nextRect = all[i + 1].getBoundingClientRect();

      if (nextRect.top - nextRect.height - 80 > 0) {
        setId = parseInt(all[i].getAttribute('data-id'));
        break;
      }
    }

    if (all.length === 0 && Object.keys(props.sets).length > 0) {
      setId = _.sortBy(props.sets, set => set.position)[0].id;
    }

    this.state = {
      loading: false,
      set: Object.keys(props.sets).length > 0 ? setId : null,
      shift: { value: 0, label: <FormattedMessage id="createElementButton.setModal.label.shift.before"/>}
    };

    this.addSet = this.addSet.bind(this);
  }

  addSet() {
    this.setState({loading: true});

    const position = this.state.block
      ? this.props.sets[this.state.set].position + this.state.shift.value
      : 0;

    const data = {
      survey: this.props.survey.id,
      set: {
        position: position
      }
    };

    this.props.addSet(data).then(() => {
      this.setState({loading: false});
      this.props.toggle();
    })
  }

  render() {
    return (
      <Modal size="xl" isOpen={true} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}><FormattedMessage id="createElementButton.setModal.modalHeader" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          {!this.state.set ? <p><FormattedMessage id="createElementButton.setModal.modalBody.firstSet"/></p> : <React.Fragment>
            <Row>
              <Col lg={3}>
                <Label for="shift"><FormattedMessage id="createElementButton.setModal.label.shift" /></Label>
              </Col>
              <Col lg={9}>
                <Select
                  clearable={false}
                  value={this.state.shift}
                  options={[
                    { value: 0, label: <FormattedMessage id="createElementButton.setModal.label.shift.before" />},
                    { value: 1, label: <FormattedMessage id="createElementButton.setModal.label.shift.after" />},
                  ]}
                  onChange={shift => this.setState({shift: shift})}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={3}>
                <Label for="shift"><FormattedMessage id="createElementButton.setModal.label.set" /></Label>
              </Col>
              <Col lg={9}>
                <SelectSets
                  isMulti={false}
                  onChange={set => this.setState({set})}
                  value={this.state.set}
                />
              </Col>
            </Row>
          </React.Fragment>}
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.add">{
            (message) => <LaddaButton
              className="btn btn-success btn-ladda"
              data-style={ZOOM_OUT}
              loading={this.state.loading}
              onClick={this.addSet}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" onClick={this.props.toggle}>{message}</Button>
          }</FormattedMessage>
        </ModalFooter>
      </Modal>
    )
  }
}

SetModal.propTypes = {
  toggle: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
    sets: state.survey.structure.data.sets
  }
}

export default connect(mapStateToProps, {addSet})(SetModal);