import React, {Component} from 'react';
import ReactDOM from "react-dom";
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { connect } from 'react-redux';
import history from "services/history";
import classnames from "classnames";


import {updateWidgets} from './data/widgets/actions';
import './grid.css';

import SurveyTable from "scenes/Dashboard/scenes/Widgets/components/WidgetTypes/TypeSurveyTable";
import Collectors from "scenes/Dashboard/scenes/Widgets/components/WidgetTypes/TypeCollectors";
import QuestionSummariesFromAnalysis from "scenes/Dashboard/scenes/Widgets/components/WidgetTypes/TypeQuestionSummariesFromAnalysis";
import {Button, Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import CreateModal from "./components/CreateModal";
import appRoutes from "appRoutes";
import ButtonCreateSurvey from "../../../../components/ButtonCreateSurvey";
import {isMobile, isTablet, isBrowser} from "react-device-detect";
import {FormattedMessage} from "react-intl";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import WidgetsAvailable from "components/PlanRestrictionsModals/modals/WidgetsAvailable";
import LastSeenSurveysWidget from "../../../LastSeenSurveys/lastSeenSurveysWidget";

class Widgets extends Component {

  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      createModal: false
    };

    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  componentDidMount(){
    this.setState({
      width: ReactDOM.findDOMNode(this).getBoundingClientRect().width
    });
  }

  onLayoutChange(layout) {
    let data = [];
    layout.map(item => {
      data.push({id: item.i, width: item.w, height: item.h, placementX: item.x, placementY: item.y})
    });
    this.props.updateWidgets(data);
  }

  static transformWidgetIntoComponent(widget) {
    switch (widget.type) {
      case 'SurveyTable':
        return <SurveyTable widget={widget}/>;
      case 'LastSeenSurveys':
        return <LastSeenSurveysWidget widget={widget}/>;
      case 'Collectors':
        return <Collectors widget={widget}/>;
      case 'QuestionSummariesFromAnalysis':
        return <QuestionSummariesFromAnalysis widget={widget}/>;
    }
  }

  render() {
    const {width} = this.state;
    const {plan} = this.props;

    let elements = [];
    this.props.widgets.map(widget => {
      let grid = {i: String(widget.id), x: widget.placementX, y: widget.placementY, w: widget.width, h: widget.height};
      let component = Widgets.transformWidgetIntoComponent(widget);
      elements.push(<div key={widget.id} className={"p-1" + ((isMobile || isTablet) ? " mobileGrid" : "")}data-grid={grid}>{component}</div>);
    });

    if(elements.length === 0){
      return (
        <Row>
          <Col sm={12} lg={4} className="d-flex align-items-md-stretch">
            <Card className="border-l green w-100">
              <CardBody>
                <small className="d-block w-100 mb-3">Utwórz nowy projekt</small>
              </CardBody>
              <CardFooter>
                <ButtonCreateSurvey classnames="mb-0 w-100" color="success" />
              </CardFooter>
            </Card>
          </Col>
          <Col sm={12} lg={4} className="d-flex align-items-md-stretch mt-3 mt-lg-0">
            <Card className="border-l blue w-100">
              <CardBody>
                <small className="d-block w-100 mb-3">Przejdź na listę projektów</small>
              </CardBody>
              <CardFooter>
                <Button onClick={() => history.push(appRoutes.surveys.list)} className="w-100 mb-0" color="primary">Przejdź na listę projektów</Button>
              </CardFooter>
            </Card>
          </Col>
          <Col sm={12} lg={4} className="d-flex align-items-md-stretch mt-3 mt-lg-0">
            <Card className="border-l orange w-100">
              <CardBody>
                <small className="d-block w-100 mb-3">Dodaj widgety, aby wyświetlać informacje, które potrzebujesz</small>
              </CardBody>
              <CardFooter>
                <Button className={classnames({
                  "mb-0 w-100": true,
                  'disabled': !plan.widgets,
                })} color="warning" onClick={() => plan.widgets ? this.toggle('createModal') : this.props.setPlanRestrictionsModals(WidgetsAvailable.getName())}><i className="fas fa-plus" /> <FormattedMessage id="dashboard.buttonCreate.button" /></Button>
                <CreateModal color="warning" toggle={() => this.toggle('createModal')} isOpen={this.state.createModal}/>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      )
    }

    return (
      <GridLayout
        cols={16}
        rowHeight={25}
        width={width}
        margin={[0,0]}
        onLayoutChange={this.onLayoutChange}
        draggableHandle=".draggableWidget"
        isDraggable={isBrowser}
        isResizable={isBrowser}
      >
        {elements}
      </GridLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
  }
}


export default connect(mapStateToProps, {updateWidgets, setPlanRestrictionsModals})(Widgets);