import React, {Component} from 'react';

class Instruction extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="d-flex align-items-center mb-3">
          <div>
            <p className="mb-0"><strong>{"<zestaw>"}</strong></p>
            <p className="mb-0"><strong>{"<zestaw:Nazwa zestawu>"}</strong></p>
          </div>
          <p className="mb-0 ml-4">Rozpoczyna nowy zestaw</p>
        </div>
        <div className="d-flex align-items-center mb-3">
          <div>
            <p className="mb-0"><strong>{"<blok>"}</strong></p>
            <p className="mb-0"><strong>{"<blok:Nazwa bloku>"}</strong></p>
          </div>
          <p className="mb-0 ml-4">Rozpoczyna nowy blok</p>
        </div>
        <div className="mb-3">
          <p className="mb-0"><strong>Ustawienia bloku</strong></p>
          <p className="mb-0"><strong>{"<r>"}</strong> - rotowanie stron</p>
          <p className="mb-0"><strong>{"<l:2>"}</strong> - losowanie liczby stron: 2</p>
        </div>
      <div className="d-flex align-items-center">
          <div>
              <p className="mb-0"><strong>{"<page>"}</strong></p>
          </div>
      </div>
        <div className="d-flex align-items-center mb-3">
          <div>
            <p className="mb-0">Dodaje podział stron. Gdy wstawisz &lt;page&gt; wszystkie pytania pod tym znacznikiem znajdą się na tej stronie.  Uwaga: opcja nie zadziała, jeśli będzie włączona opcja “Włącz autostronicowanie pytań”</p>
          </div>
        </div>

        <p className="mb-0"><strong>Przykład:</strong></p>
        <p>
          <span>{"<blok:Nazwa bloku><r><i>"}</span><br />
        </p>

        <p><strong>{"<multi>Które marki są Ci znane?"}</strong> - określ typ pytania poprzedzając jego treść nazwą typu w ostrych nawiasach. Dostępne typy: "single", "multi", "ranked", "open", "multiOpen", "numeric", "numericMulti", "matrix", "matrixMulti", "matrixDropdown", "differential", "nps", "basket", "maxDiff", "gMap", "bpto", ""cbc, "video", "comment"</p>
        <div className="d-flex align-items-center mb-3">
          <div>
            <p className="mb-0"><strong>{"<numeric:slider>Suwak"}</strong></p>
            <p className="mb-0"><strong>{"<matrix:carousel>Karuzela"}</strong></p>
          </div>
          <p className="mb-0 ml-4">zmień typ prezentacji dodając po dwukropku jego nazwę</p>
        </div>
        <div>
          <p className="mb-0"><strong>Ustawienia pytania</strong></p>
          <p className="mb-0"><strong>{"<i>"}</strong> - instrukcja</p>
          <p className="mb-0"><strong>{"<r>"}</strong> - rotowanie odpowiedzi</p>
          <p className="mb-0"><strong>{"<rr>"}</strong> - rotowanie wierszy</p>
          <p className="mb-0"><strong>{"<rc>"}</strong> - rotowanie kolumn</p>
        </div>
        <div className="mt-3">
          <p className="mb-0"><strong>Ustawienia kafeterii</strong></p>
          <p className="mb-0"><strong>{"<o>"}</strong> - Odpowiedź otwarta</p>
          <p className="mb-0"><strong>{"<d>"}</strong> - Nie rotuj</p>
          <p className="mb-0"><strong>{"<b>"}</strong> - Odpowiedź blokująca</p>
        </div>

        <p className="mb-0 mt-3"><strong>Przykład:</strong></p>
        <p>
          <span>{"<multi><r><i>Jakie kolory lubisz?"}</span><br />
          <span>{"biały"}</span><br />
          <span>{"czarny"}</span><br />
          <span>{"zielony"}</span><br />
          <span>{"niebieski"}</span><br />
          <span>{"czerwony"}</span><br />
          <span>{"<o><d>inny, jaki?"}</span><br />
          <span>{"<b><d>żaden z wymienionych"}</span>
        </p>

        <p className="mt-4">Możesz również zmienić typ pytania ręcznie. Kliknij na ikonę typu pytania w lewym górnym rogu pytania w oknie podglądu</p>
        <p className="d-inline-flex align-items-center"><span className="d-inline-block"><strong>{"{rows}"}<br />Wiersz 1<br />Wiersz 2</strong></span> - określ rodzaj wpisywanej kafeterii poprzedzając ją nazwą rodzaju w nawiasach klamrowych. Dostępne rodzaje w zależności od typu pytania: "answers", "rows", "columns", "baskets", "excludings"</p>
        <p><strong>{"lewa|prawa"}</strong> - w przypadku wierszy pytania typu Dyferencjał znakiem "|" oddzielisz lewą część odpowiedzi od prawej</p>
      </React.Fragment>
    )
  }
}

export default Instruction;