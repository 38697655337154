import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {ListGroup, ListGroupItem} from "reactstrap";
import Checkbox from "components/Checkbox";

class Single extends Component {

  constructor(props) {
    super(props);

    this.onClickAnswer = this.onClickAnswer.bind(this);
  }

  onClickAnswer(answer){
    let {answers} = this.props.filter;

    if(answers.find(a => a.answer === answer)){
      answers = answers.filter(a => a.answer !== answer).map(a => a);
    }else{
      answers.push({
        answer
      })
    }

    this.props.handleChange(answers);
  }

  render() {
    const {answers, filter, questions, questionId} = this.props;
    const question = questions.find(q => q.id === questionId);

    return (
      <ListGroup>
        {Object.values(answers).map(answer => {
          return <ListGroupItem className="py-0" key={answer.id} onClick={() => this.onClickAnswer(answer.id)}>
            <Checkbox
              text={question.answersImage ? answer.description : answer.content}
              id={'answer-' + answer.id}
              checked={filter.answers.filter(a => a.answer === answer.id).length > 0}
              tooltip={false}
            />
          </ListGroupItem>
        })}
      </ListGroup>
    )
  }
}

Single.propTypes = {
  questionId: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,),
  filter: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    answers: Object.values(state.survey.analysis.data.answers).filter(answer => state.survey.analysis.data.questions[props.questionId].answers.indexOf(answer.id) > -1),
  }
}

export default connect(mapStateToProps)(Single);