import { combineReducers } from 'redux';

import { reducer as surveyReducer } from './survey/reducer';
import { reducer as setReducer } from './sets/reducer';
import { reducer as blockReducer } from './blocks/reducer';
import { reducer as pagesReducer } from './pages/reducer';
import { reducer as questionsReducer } from './questions/reducer';
import { reducer as answersReducer } from './answers/reducer';
import { reducer as attributesReducer } from './attributes/reducer';
import { reducer as attributeLevelsReducer } from './attributes/levels/reducer';
import { reducer as basketsReducer } from './baskets/reducer';
import { reducer as excludingsReducer } from './excludings/reducer';
import { reducer as filtersReducer } from './filters/reducer';
import { reducer as quotaNewsReducer } from './quotaNews/reducer';
import { reducer as pipingsReducer } from './pipings/reducer';
import { reducer as filterSerialReducer } from './filterSerial/reducer';
import { reducer as filterIterativeReducer } from './filterIterative/reducer';
import { reducer as rowsReducer } from './rows/reducer';
import { reducer as columnsReducer } from './columns/reducer';
import { reducer as scalePointsReducer } from './scalePoints/reducer';
import { reducer as javascriptsReducer } from './javascripts/reducer';

export const reducer = combineReducers({
  survey: surveyReducer,
  sets: setReducer,
  blocks: blockReducer,
  pages: pagesReducer,
  questions: questionsReducer,
  answers: answersReducer,
  attributes: attributesReducer,
  attributeLevels: attributeLevelsReducer,
  baskets: basketsReducer,
  excludings: excludingsReducer,
  filters: filtersReducer,
  filterSerial: filterSerialReducer,
  filterIterative: filterIterativeReducer,
  quotaNews: quotaNewsReducer,
  pipings: pipingsReducer,
  rows: rowsReducer,
  columns: columnsReducer,
  scalePoints: scalePointsReducer,
  javascripts: javascriptsReducer,
});
