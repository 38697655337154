import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, Form, FormGroup, Input, Label} from "reactstrap";
import api from "../../../api";

class BaseForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data
    };

    this.startSaving = this.startSaving.bind(this);
    this.stopSaving = this.stopSaving.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.save = this.save.bind(this);
  }

  startSaving(){
    this.setState({
      saving: true,
    });

    this.props.onSavingStart();
  }

  stopSaving(){
    this.setState({
      saving: false,
    });

    this.props.onSavingStop();
  }

  onSubmit(e) {
    e.preventDefault();

    this.save();
  }

  save() {
    const {data} = this.state;

    this.startSaving();

    api.respondentSmsBases.add(data)
    .then(data => {
      this.stopSaving();
      this.props.onAdded(data.respondentSmsBase);
    })
    .catch(res => {
      this.stopSaving();
    });
  }

  render() {
    const {data} = this.state;

    return <Form onSubmit={this.onSubmit}>
      <FormGroup row>
        <Label md={4}>Nazwa bazy</Label>
        <Col md={8}>
          <Input type="text" name="name" onChange={e => this.setState({data: {...data, name: e.target.value}})} value={data.value} />
        </Col>
      </FormGroup>
    </Form>;
  }
}

BaseForm.defaultProps = {
  data: {
    name: '',
  },
};

BaseForm.propTypes = {
  onAdded: PropTypes.func.isRequired,
  onSavingStart: PropTypes.func.isRequired,
  onSavingStop: PropTypes.func.isRequired,
};

export default BaseForm;