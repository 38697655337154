import striptags from "striptags";

const stripTagsAndSpecialChars = (string) => {
	string = striptags(string);
	let test = string.replace(/&nbsp;/g, '');

	return test;
}

export default stripTagsAndSpecialChars;
