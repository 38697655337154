import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Button from "../../../Button";
import api from "../../../../api";
import BlockTemplateForm from "../BlockTemplateForm";


class EditBlockTemplate extends PureComponent {
  constructor(props) {
    super(props);

    this.blockTemplateFormRef = React.createRef();

    this.state = {
      modalOpen: false,
      saving: false,
    };

    this.onSaveStart = this.onSaveStart.bind(this);
    this.onSaved = this.onSaved.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.save = this.save.bind(this);
  }

  onSaveStart(){
    this.setState({
      saving: true
    });
  }

  onSaved(template){
    this.setState({
      modalOpen: false,
      saving: false,
    });

    this.props.onSaved(template);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  save(){
    this.setState({
      saving: true
    });

    this.blockTemplateFormRef.current.save();
  }

  render() {
    const {modalOpen, saving} = this.state;

    return (
      <React.Fragment>
        <i className="fas fa-edit text-primary pointer mr-2" onClick={this.toggleModal} />
        {modalOpen && <Modal isOpen={modalOpen} toggle={this.toggleModal} size="lg">
          <ModalHeader toggle={this.toggleModal}>Edytuj szablon bloku</ModalHeader>
          <hr className="my-0" />
          <ModalBody>
            <BlockTemplateForm
              ref={this.blockTemplateFormRef}
              onSaveStart={this.onSaveStart}
              onSaved={this.onSaved}
              template={{
                ...this.props.blockTemplate,
              }}
            />
          </ModalBody>
          <ModalFooter>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
            <FormattedMessage id="_.button.save">{
              (message) => <Button
                color="primary"
                loading={saving}
                onClick={this.save}
              >{message}</Button>
            }</FormattedMessage>
          </ModalFooter>
        </Modal>}
      </React.Fragment>
    );
  }
}

EditBlockTemplate.propTypes = {
  onSaved: PropTypes.func.isRequired,
  blockTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default EditBlockTemplate;