import React, {Component} from 'react';
import {Row} from "reactstrap";
import PropTypes from 'prop-types';
import Integration from './Integration';

class IntegrationsAvailable extends Component {
  render() {
    return (
      <Row>
        <Integration surveyId={this.props.surveyId} type="hotjar"/>
        <Integration surveyId={this.props.surveyId} type="analytics"/>
      </Row>
    );
  }
}

IntegrationsAvailable.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default IntegrationsAvailable;
