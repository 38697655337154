import * as React from 'react';
import {Badge, Card, CardBody, Col, Row, Tooltip} from "reactstrap";
import {connect} from "react-redux";
import LastSeenSurveys from "./lastSeenSurveys";
import {Link} from "react-router-dom";
import appRoutes from "../../appRoutes";
import Button from "../../components/Button";
import WidgetContainer from "../Dashboard/scenes/Widgets/components/WidgetContainer";
import SurveysCategories from "../../componentsNew/SurveysCategories/SurveysCategories";

function LastSeenSurveysWidget(props) {
	const {widget} = props;

	if (!widget) {
		return (
			<Col sm={12} lg={12} className="d-flex align-items-md-stretch mt-5 mt-lg-0 pt-3" id={"last-seen-surveys"}>
				<Card className="border-l magenta w-100">
					<CardBody>
						<h1 class={"color-text"}>Ostatnio przeglądane projekty</h1>
						<LastSeenSurveys filters={{visited: true, maxResults: 10}} />
						<div class={"ml-3 mr-3 mt-5"}>
							<Link to={appRoutes.surveys.lastSeen} class={"button-link color-white background-magenta"}>
								<i class={"fas fa-paperclip"}></i> Zobacz wszystkie przeglądane projekty
							</Link>
						</div>
					</CardBody>
				</Card>
			</Col>
		);
	}

	return (
		<WidgetContainer widget={widget} handleDataChange={() => {}}>
			<LastSeenSurveys filters={{visited: true, maxResults: widget.tableRows}} />
			<div class={"ml-3 mr-3 mt-5"}>
				<Link to={appRoutes.surveys.lastSeen} class={"button-link color-white background-magenta"} style={{fontSize: "16px"}}>
					<i class={"fas fa-paperclip"}></i> Zobacz wszystkie przeglądane projekty
				</Link>
			</div>
		</WidgetContainer>
	)
}

LastSeenSurveysWidget.propTypes = {};

function mapStateToProps(state) {
}

export default connect(mapStateToProps, {})(LastSeenSurveysWidget);