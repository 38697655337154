import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input
} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {copyAnswers} from "scenes/Survey/Structure/data/questions/actions";
import SelectQuestions from "scenes/Survey/Structure/components/SelectQuestions";
import Switch from "../../../../../../../../../../../../../../../../../../../../../../../../../components/Switch";

class ActionCopy extends Component {

  constructor(props) {
    super(props);

    this.state = {
      reference: null,
      copyFromType: 'rows',
      copyToType: 'rows',
      replace: false,
      modal: false,
      saving: false,
    };

    this.onChangeReference = this.onChangeReference.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleModal(){
    this.setState({ modal: !this.state.modal });
  }

  onChangeReference(questionId){
    this.setState({ reference: questionId });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  copyAnswers(){
    const {reference, saving, copyFromType, copyToType} = this.state;
    const {question} = this.props;

    if(saving) return;
    this.setState({ saving: true });

    let data = {
      replace: this.state.replace,
      reference: reference,
      copyFromType: copyFromType,
      copyToType: copyToType
    };

    this.props.copyAnswers(question.id, data).then(() => {
      this.setState({
        saving: false,
        modal: false,
      });
    });
  }

  render() {
    const {reference, saving, copyFromType, replace} = this.state;
    const enabledTypes = ['single', 'multi', 'ranked', 'conjoint', 'matrix', 'matrixMulti'];

    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="rows.actions.copy.cardHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <div className="d-flex flex-row flex-nowrap align-items-center pb-3">
          <span className="mr-3">Zamień listę wierszy:</span>
          <Switch className="mb-0" checked={replace} onChange={replace => this.setState({ replace })} />
        </div>
        <p><FormattedMessage id="rows.actions.copy.cardBody"/></p>
        <Form>
          <FormGroup>
            <SelectQuestions isMulti={false} onChange={this.onChangeReference} value={reference} enabledTypes={enabledTypes}/>
          </FormGroup>
        </Form>

        {reference && ['matrix', 'matrixMulti'].includes(this.props.questions[reference].type) && <Input type="select" name="copyFromType" id="copyFromType" value={copyFromType} onChange={this.onChange}>
            <FormattedMessage id="rows.actions.copy.select.rows">{(message) => <option value='rows'>{message}</option>}</FormattedMessage>
            <FormattedMessage id="rows.actions.copy.select.columns">{(message) => <option value='columns'>{message}</option>}</FormattedMessage>
        </Input>}

      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="rows.actions.copy.button.confirmation">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={() => this.copyAnswers()}
                disabled={!this.state.reference}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button onClick={this.toggleModal}><FormattedMessage id="rows.actions.copy.button.copy"/></Button>
        {this.state.modal && modal}
      </React.Fragment>
    )
  }
}

ActionCopy.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    questions: state.survey.structure.data.questions
  }
}

export default connect(mapStateToProps, {copyAnswers})(ActionCopy);
