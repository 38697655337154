import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {Form, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Col, Input} from "reactstrap";
import Button from "../../../../components/Button";
import {FormattedMessage} from "react-intl";
import Switch from "../../../../components/Switch";
import api from "../../../../api";
import {replaceTexts} from "../data/survey/actions";
import SelectQuestions from "./SelectQuestions";

class KeyboardReplaceFunction extends Component {
  constructor(props) {
    super(props);

    this.filterRef = React.createRef();

    this.state = {
      inputSearch: '',
      inputReplace: '',
      cafeteryContent: true,
      questionContent: true,
      questions: [],
      checked: false,
      checking: false,
      replacing: false,
      modalOpen: false,
    };

    this.check = this.check.bind(this);
    this.replace = this.replace.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind("shift+h", () => {
      this.setState({
        modalOpen: true,
      })
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind("shift+h");
  }

  check() {
    const {survey} = this.props;
    const {inputSearch, questions, inputReplace, cafeteryContent, questionContent} = this.state;

    this.setState({
      checked: false,
      checking: true,
    });

    api.survey.checkReplaces(survey.id, {
      inputSearch,
      questions,
      inputReplace,
      cafeteryContent,
      questionContent,
    }).then(data => {
      this.setState({
        checking: false,
        checked: data,
      });
    }).catch(res => {
      this.setState({
        checking: false,
      });
    })
  }

  replace() {
    const {survey} = this.props;
    const {inputSearch, questions, inputReplace, cafeteryContent, questionContent} = this.state;

    this.setState({
      replacing: true
    });

    this.props.replaceTexts(survey.id, {
      inputSearch,
      questions,
      inputReplace,
      cafeteryContent,
      questionContent,
    }).then(data => {
      toast.success("Treści zostały poprawnie podmienione");

      this.setState({
        replacing: false,
        checked: false,
        modalOpen: false
      });
    }).catch(res => {
      this.setState({
        replacing: false,
      });
    })

  }

  render() {
    const {inputSearch, inputReplace, questions, modalOpen, replacing, checking, checked, questionContent, cafeteryContent} = this.state;

    return <React.Fragment>
      {modalOpen && <Modal isOpen={true} toggle={() => this.setState({modalOpen: !this.state.modalOpen})} size="lg">
        <ModalHeader tag="h2" toggle={() => this.setState({modalOpen: !this.state.modalOpen})}>Podmień treść</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          {checked !== false
            ? <div className="d-flex flex-column">
              <span className="mb-4">Znajdź <i className="text-dark font-weight-bold">{inputSearch}</i> i zamień na <i className="text-dark font-weight-bold">{inputReplace}</i></span>
              {questionContent && <span className="mb-2">Liczba znalezionych pytań: {checked.inQuestion}</span>}
              {cafeteryContent && <span>Liczba znalezionych kafeterii: {checked.inCafetery}</span>}
            </div>
            : <Form onSubmit={e => e.preventDefault()}>
              <FormGroup row>
                <Label xs={12} sm={4} md={3}>Znajdź:</Label>
                <Col xs={12} sm={8} md={9}>
                  <Input placeholder="Wpisz treść..." value={inputSearch} onChange={e => this.setState({inputSearch: e.target.value})} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xs={12} sm={4} md={3}>Zamień na:</Label>
                <Col xs={12} sm={8} md={9}>
                  <Input placeholder="Wpisz treść..." value={inputReplace} onChange={e => this.setState({inputReplace: e.target.value})} />
                </Col>
              </FormGroup>
              <FormGroup className="d-flex align-items-center" row>
                <Label xs={12} sm={4} md={3}>Podmień treści pytań</Label>
                <Col xs={12} sm={8} md={9}>
                  <Switch onChange={questionContent => this.setState({questionContent})} checked={questionContent} />
                </Col>
              </FormGroup>
              <FormGroup className="d-flex align-items-center" row>
                <Label xs={12} sm={4} md={3}>Podmień treści kafeterii</Label>
                <Col xs={12} sm={8} md={9}>
                  <Switch onChange={cafeteryContent => this.setState({cafeteryContent})} checked={cafeteryContent} />
                </Col>
              </FormGroup>
              <FormGroup className="d-flex align-items-center" row>
                <Label xs={12} sm={4} md={3}>Wybierz pytania</Label>
                <Col xs={12} sm={8} md={9}>
                  <SelectQuestions
                    value={questions}
                    placeholder="Wszystkie pytania lub wybierz"
                    onChange={questions => this.setState({questions})}
                  />
                </Col>
              </FormGroup>
            </Form>
          }
        </ModalBody>
        <ModalFooter>
          {checked !== false && <FormattedMessage id="_.button.back">{
            (message) => <Button color="secondary" className="mr-auto" onClick={() => this.setState({checked: false})}>{message}</Button>
          }</FormattedMessage>}
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={() => this.setState({modalOpen: !this.state.modalOpen})}>{message}</Button>
          }</FormattedMessage>
          {checked !== false
            ? <Button
              color={"primary"}
              loading={replacing}
              onClick={this.replace}
            >Zamień</Button>
            : <Button
              color={"primary"}
              loading={checking}
              onClick={this.check}
            >Sprawdź</Button>
          }
        </ModalFooter>
      </Modal>}
    </React.Fragment>;
  }
}

KeyboardReplaceFunction.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps, {replaceTexts})(KeyboardReplaceFunction);