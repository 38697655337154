import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {updateBlockPagesButton} from "scenes/Survey/Structure/data/blocks/actions";
import _ from 'lodash';
import classnames from "classnames";

class HideSurveyNavigationButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleButton(){
    this.props.updateBlockPagesButton({
      id: this.props.block.id,
      button: this.props.button
    });
  }

  static handleIconType(button){
    switch (button) {
      case 'Previous':
        return "fas fa-arrow-left";
      case 'Clear':
        return "fa-solid fa-eraser";
      case 'Next':
        return "fas fa-arrow-right";
      default:
        console.log('There is no icon for this particular button type!');
    }
  }


  render() {
    let {tooltip} = this.state;
    const {button, pagesWithHiddenButton} = this.props;

    return (
      <Button
        className={classnames({
          "float-left mr-1 btn-icon mb-1": true,
          "active": pagesWithHiddenButton.length > 0
        })}
        color={'secondary'}
        id={`hide-${button}-btn`}
        onClick={this.toggleButton}
      >
        <i className={HideSurveyNavigationButton.handleIconType(button)} />

        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target={`hide-${button}-btn`}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id={`block.settings.navigation.button.${button}.tooltip`}/>
        </Tooltip>
      </Button>
    );
  }
}

HideSurveyNavigationButton.propTypes = {
  block: PropTypes.object.isRequired,
  updateBlockPagesButton: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  let buttonTypeName = `hideNavigation${props.button}Button`;

  return {
    pagesWithHiddenButton: _.filter(state.survey.structure.data.pages, function (page) {
      return page[buttonTypeName];
    })
  }
}

export default connect(mapStateToProps, {updateBlockPagesButton})(HideSurveyNavigationButton);
