import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {ListGroup, Alert, Card, CardBody, CardHeader, CardFooter} from "reactstrap";
import DeleteAttribute from "./DeleteAttribute";
import AttributeName from "./AttributeName";
import CreateAttributeLevel from "./AttributeLevel/CreateAttributeLevel";
import AttributeLevel from "./AttributeLevel/AttributeLevel";

class Attribute extends Component {
  render() {
    const {attribute} = this.props;

    return <Card className="attribute border">
      <CardHeader className="d-flex justify-content-between align-items-center py-2">
        <AttributeName
          attribute={attribute}
        />

        <DeleteAttribute
          attribute={attribute}
        />
      </CardHeader>
      <CardBody>
        {attribute.levels.length === 0
          ? <Alert className="text-center" color="warning">Atrybut nie posiada żadnych wartości</Alert>
          : <ListGroup className="attribute-levels">
              {attribute.levels.map(levelId => <AttributeLevel key={levelId} level={this.props.attributeLevels[levelId]} attribute={attribute}/>)}
            </ListGroup>
        }
      </CardBody>
      <CardFooter>
        <CreateAttributeLevel attribute={attribute} />
      </CardFooter>
    </Card>;
  }
}

Attribute.propTypes = {
  attribute: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    levels: PropTypes.array.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    attributeLevels: state.survey.structure.data.attributeLevels,
  }
}

export default connect(mapStateToProps)(Attribute);