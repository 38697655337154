import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from "reactstrap";
import ButtonDelete from "./components/ButtonDelete";
import ButtonEdit from "./components/ButtonEdit";
import ButtonCopy from "./components/ButtonCopy";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import Action from "scenes/Survey/Structure/components/Filter/Action";
import Block from "scenes/Survey/Structure/components/Filter/Block";
import ButtonPause from "./components/ButtonPause";
import FilterNameColumn
  from "../../../../../../../../../../../../../../../../../../../../../components/Settings/Filters/FilterNameColumn";

class ListItems extends Component
{
  constructor(props) {
    super(props);
    this.actions = this.actions.bind(this);
  }

  omitIdBlock(block){
    block = _.omit(block, ['id']);

    block.condsQuestion = block.condsQuestion.map(cond => _.omit(cond, ['id']));
    block.children = block.children.map(b => this.omitIdBlock(b));

    return block;
  }

  actions(filter){
    return (
      <ButtonGroup>
        <ButtonPause filter={filter}/>
        <ButtonEdit filter={filter} onClick={() => this.props.toggleEdit(filter)}/>
        <ButtonCopy filter={filter} onClick={() => {
          let filterCopy = _.clone(filter);
          filterCopy = _.omit(_.merge(filterCopy, {name: filterCopy.name + ' kopia'}), ['id']);
          filterCopy.blocks = filterCopy.blocks.filter(block => block.parent === false);

          filterCopy.actions = filterCopy.actions.map(action => _.omit(action, ['id']));
          filterCopy.blocks = filterCopy.blocks.map(b => this.omitIdBlock(b));

          this.props.toggleEdit(filterCopy)
        }}/>
        <ButtonDelete filter={filter}/>
      </ButtonGroup>
    )
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="filters.listItems.tableHeader.id"/>,
      accessor: 'id',
      width: 40
    }, {
      id: 'name',
      Header: <FormattedMessage id="filters.listItems.tableHeader.name"/>,
      // accessor: filter => filter.name || <i><FormattedMessage id="filters.listItems.tableHeader.name.empty"/></i>
      accessor: filter => <FilterNameColumn filter={filter} />
    }, {
      id: 'conditions',
      Header: <FormattedMessage id="filters.listItems.tableHeader.conditions"/>,
      accessor: filter => <ol className="m-0 p-0 text-left">{_.map(filter.blocks, (block, key)=> !block.parent && <li key={key} style={{listStyleType: 'decimal'}}><Block block={block} changeable={false}/></li>)}</ol>

    }, {
      id: 'filterActions',
      Header: <FormattedMessage id="filters.listItems.tableHeader.filterActions"/>,
      accessor: filter => <ol className="m-0 p-0 text-left">{_.map(filter.actions, (action, key)=> <li key={key} style={{listStyleType: 'decimal'}}><Action action={action} changeable={false}/></li>)}</ol>
    }, {
      id: 'actions',
      Header: <FormattedMessage id="filters.listItems.tableHeader.actions"/>,
      accessor: filter => this.actions(filter),
      width: 100
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={Object.values(this.props.filters)}
        columns={columns}
        getTrGroupProps={(state, rowInfo) => {
          return {
            className: (
              rowInfo.row._original.type === 'conditional' && (
                (!rowInfo.row._original.actions || !rowInfo.row._original.actions.length)
                || (!rowInfo.row._original.blocks)
                || (rowInfo.row._original.blocks.length > 0 && !rowInfo.row._original.blocks[0].condsQuestion.length)
              )
              || rowInfo.row._original.type === 'serial' && (
                (!rowInfo.row._original.questionFrom || !rowInfo.row._original.questionTo)
              )
              || rowInfo.row._original.type === 'iterative' && (
                (!rowInfo.row._original.questionFrom || !rowInfo.row._original.questionTo.length === 0)
              )
            )
              ? 'bg-warning'
              : ''
          };
        }}
        showPagination={!_.isEmpty(this.props.filters)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="filters.listItems.table.noFilters" />}
        defaultPageSize={5}
      />
    )
  }
}

ListItems.propTypes = {
  question: PropTypes.object.isRequired,
  toggleEdit: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  let filters = [];

  _.each(state.survey.structure.data.filters, filter => {
    _.each(filter.actions, action => {

      if (action.question === props.question.id || (action.hasOwnProperty('questions') && action.questions.includes(props.question.id))) {
        filters.push(filter);
        return false;
      }
    });

    _.each(filter.blocks, block => {
      _.each(block.condsQuestion, condition => {
        if (condition.question === props.question.id) {
          filters.push(filter);
          return false;
        }
      });
    });
  });

  return {
    filters: _.uniqBy(filters, 'id')
  }
}

export default connect(mapStateToProps)(ListItems);
