import React, {PureComponent} from 'react';
import {Button, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import PropTypes from "prop-types";
import SocialMediaShareSwitch from "./components/SocialMediaShareSwitch";
import { connect } from 'react-redux';
import {isBrowser} from 'react-device-detect';

class Sharing extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(e){
    if(_.filter(e.path, path => path['id'] === 'sharing').length === 0){
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  render() {
    const {isOpen} = this.state;

    return (
      <Dropdown toggle={this.toggle} isOpen={isOpen} inNavbar>
        <DropdownToggle className="py-3"><i className="fas fa-share-alt" /></DropdownToggle>
        {isOpen && <DropdownMenu className="p-2" style={{
          height: isBrowser ? 'auto' : window.innerHeight - 100,
          width: isBrowser ? 650 : window.innerWidth,
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}>
          <div className="d-block">
            <h3 className="text-center">Udostępnianie ankiety</h3>
            <Button color="" onClick={(e) => this.toggle(e)} className="ml-auto px-3" style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}><i className="fas fa-times mt-1"/> </Button>
          </div>
          <hr className="my-0 mb-2"/>
          <SocialMediaShareSwitch handlerUpdate={this.props.handlerUpdate} type="facebook" survey={this.props.survey}/>
          <hr className="my-0 mb-2"/>
          <SocialMediaShareSwitch handlerUpdate={this.props.handlerUpdate} type="linkedIn" survey={this.props.survey}/>
          <hr className="my-0 mb-2"/>
          <SocialMediaShareSwitch handlerUpdate={this.props.handlerUpdate} type="twitter" survey={this.props.survey}/>
        </DropdownMenu>}
      </Dropdown>
    );
  }
}

Sharing.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps)(Sharing);
