import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import General from "./General";
import api from "api";
import "./styles/style.scss"
import Navigation from "./Navigation";
import Question from "./Question";
import Templates from './Templates';
import Sharing from "./Sharing";
import HeaderAndFooter from "./HeaderAndFooter";
import LoadingSpinner from "../../../components/LoadingSpinner";
import UnsetOwnStyles from "./UnsetOwnStyles";

class SurveyOwnStyles extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      ownStyles: {},
    };

    this.updateVariableValue = this.updateVariableValue.bind(this);
  }

  componentDidMount() {
    api.survey.get.ownStyles(this.props.surveyId).then(data => {
      this.setState({
        ownStyles: data.ownStyles,
        loading: false,
      });
    });
  }

  updateVariableValue(variables){
    this.setState({
      saving: true,
    });

    api.survey.patch.ownStyles(this.props.surveyId, variables).then(data => {
      this.setState({
        ownStyles: data.ownStyles,
        saving: false,
      });

      this.props.handlerOnUpdated();
    });
  }

  render() {
    const {loading} = this.state;

    if(loading){
      return <LoadingSpinner />
    }

    return (
      <div className="w-100 mt-0 d-flex" id="survey-own-styles">
        <General
          ownStyles={this.state.ownStyles}
          ownStylesDefault={this.props.ownStylesDefault}
          handlerUpdate={this.updateVariableValue}
        />
        <Question
          ownStyles={this.state.ownStyles}
          ownStylesDefault={this.props.ownStylesDefault}
          handlerUpdate={this.updateVariableValue}
        />
        <HeaderAndFooter
          ownStyles={this.state.ownStyles}
          ownStylesDefault={this.props.ownStylesDefault}
          handlerUpdate={this.updateVariableValue}
        />
        <Navigation
          ownStyles={this.state.ownStyles}
          ownStylesDefault={this.props.ownStylesDefault}
          handlerUpdate={this.updateVariableValue}
        />
        <Templates
          ownStyles={this.state.ownStyles}
          handlerUpdate={this.updateVariableValue}
          surveyId={this.props.surveyId}
        />
        <Sharing
          handlerUpdate={this.props.handlerOnUpdated}
        />
        <UnsetOwnStyles
          handlerUpdate={() => {
            this.setState({
              ownStyles: {},
            })
            this.props.handlerOnUpdated()
          }}
          surveyId={this.props.surveyId}
        />
      </div>
    );
  }
}

SurveyOwnStyles.propTypes = {
  surveyId: PropTypes.number.isRequired,
  handlerOnUpdated: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let defaultStyles = state.config.data.survey.stylesVariablesDefault;
  const templateId = state.survey.structure.data.survey.templateId;

  if (Number.isInteger(templateId)) {
    let template = _.find(state.config.data.survey.globalTemplates, template => template.id === templateId) || _.find(state.user.surveyTemplates, template => template.id === templateId);
    if(template){
      defaultStyles = {...defaultStyles, ...template.styles};
    }
  }

  return {
    ownStylesDefault: defaultStyles,
  }
}

export default connect(mapStateToProps)(SurveyOwnStyles);
