import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import SelectBlocks from "../../../../SelectBlocks";
import _ from "lodash";

class Blocks extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalBlocks: props.action.blocks || [],
    };

    this.toggleModalBlocks = this.toggleModalBlocks.bind(this);
  }

  toggleModalBlocks(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {action, blocks, changeable} = this.props;
    const {modalOpen, modalBlocks} = this.state;

    if (!changeable) {
      return action.blocks.map(id => {
        const block = blocks.find(b => b.id === id);
        let text = 'Blok ' + block.positionInSurvey;

        if(block.name){
          text += ' ' + block.name;
        }

        return text
      }).join('; ')
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalBlocks} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalBlocks}>Wybierz bloki</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-75 d-inline-block">
          <SelectBlocks value={modalBlocks} onChange={blocks => {
            this.setState({
              modalBlocks: blocks,
            })
          }} />
        </div>
        <div className="w-25 d-inline-block pl-3">
          Wybranych bloków: {modalBlocks.length}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleBlocksChanged(modalBlocks);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={!_.isEmpty(action.blocks) ? 'secondary' : 'warning'} className="mx-1 pointer" onClick={this.toggleModalBlocks}>{!_.isEmpty(action.blocks) ? action.blocks.map(id => {
          const block = blocks.find(b => b.id === id);
          let text = 'Blok ' + block.positionInSurvey;

          if(block.name){
            text += ' ' + block.name;
          }

          return text
        }).join('; ') : 'Wybierz bloki...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

Blocks.defaultProps = {
  changeable: true
};

Blocks.propTypes = {
  action: PropTypes.shape({
    blocks: PropTypes.array,
  }).isRequired,
  handleBlocksChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  let blocks = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      positionInSurvey++;

      let b = state.survey.structure.data.blocks[blockId];
      b.positionInSurvey = positionInSurvey;
      blocks.push(b)
    });
  });

  return {
    blocks
  }
}

export default connect(mapStateToProps)(Blocks);