import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";

class ButtonOpenIsRequired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipIsOpen: false,
    };
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  render() {
    return (
      <a id={'openIsRequired-' + this.props.row.id} className={"fas fa-exclamation-triangle ml-2" + (this.props.openIsRequired ? " text-primary" : "")} onClick={() => this.props.toggleOption('openIsRequired')}>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={this.state.tooltipIsOpen}
          target={'openIsRequired-' + this.props.row.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="row.buttonOpenIsRequired.button.tooltip"/>
        </Tooltip>
      </a>
    )
  }
}

ButtonOpenIsRequired.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  openIsRequired: PropTypes.bool.isRequired,
  toggleOption: PropTypes.func.isRequired
};

export default ButtonOpenIsRequired;