import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";

class QuestionSummaryNumeric extends Component {

  render() {
    const {questionSummary} = this.props;
    const dataExists = questionSummary.data.n > 0;

    if(!dataExists){
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>;
    }

    return (
      <Chart id={questionSummary.question.id} type={questionSummary.chartType} n={questionSummary.data.n} data={questionSummary.data.data}/>
    )
  }
}

export default QuestionSummaryNumeric;