import React, {Component} from 'react';
import {Button, Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import RespondentBasesList from './RespondentBasesList';
import RespondentBaseCreateModal from './RespondentBaseCreateModal';
import appRoutes from 'appRoutes';
import {Link} from "react-router-dom";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import HelpModeIconModal from "components/HelpModeIconModal";

class RespondentBases extends Component {

  constructor(props) {
    super(props);

    this.state = {
      settingsTooltip: false,
      dropdownSettingsOpen: false,
      createModal: false
    }
  }

  componentDidMount() {
    document.title = 'Bazy respondentów | ANKIETEO';
    document.querySelector('meta[name="description"]').setAttribute("content", 'Bazy respondentów');
    document.querySelector('meta[name="keywords"]').setAttribute("content", 'Bazy respondentów');
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <div className="direction-group">
            <div className="direction-left d-flex justify-content-start">
              <h2>Bazy respondentów e-mail <HelpModeIconModal modalHeader="Bazy respondentów">
                <p>Lista baz respondentów do zapraszania do wypełniania ankiet</p>
              </HelpModeIconModal></h2>
            </div>
            <div className="direction-group d-flex justify-content-end mt-1 mr-1">
              <div className="direction-right">

                <Dropdown isOpen={this.state.dropdownSettingsOpen} toggle={() => this.toggle('dropdownSettingsOpen')} className="d-md-none">
                  <DropdownToggle className="m-0 p-0" color="">
                    <span className="fas fa-ellipsis-v hoverable" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag="a" onClick={() => this.toggle('createModal')} >
                      <p><span className="fas fa-plus" /></p> <FormattedMessage tagName="h5" id="respondentBaseCreateModal.button"/>
                    </DropdownItem>
                    <Link to={appRoutes.respondentBase.settings} className="dropdown-item">
                      <p><span className="fas fa-cog"/></p> <span className="text-dark"><FormattedMessage tagName="h5" id="respondentBases.button.tooltip.settings"/></span>
                    </Link>
                  </DropdownMenu>
                </Dropdown>

                <Link to={appRoutes.respondentBase.settings}>
                  <Button id="respondentBase-settings" color="primary" className="ml-3 d-none d-md-block" size="sm">
                    <i className="fas fa-cogs"/>
                    <Tooltip
                      delay={0}
                      placement="bottom"
                      isOpen={this.state.settingsTooltip}
                      target={'respondentBase-settings'}
                      toggle={() => this.toggle('settingsTooltip')}
                    >
                      <FormattedMessage id="respondentBases.button.tooltip.settings"/>
                    </Tooltip>
                  </Button>
                </Link>
                <FormattedMessage id="respondentBaseCreateModal.button">{
                  (message) =>
                    <Button color="primary" onClick={() => this.toggle('createModal')} className="ml-3 d-none d-md-block"><i className="fas fa-plus" /> {message}</Button>
                }</FormattedMessage>
                <RespondentBaseCreateModal isOpen={this.state.createModal} toggle={() => this.toggle('createModal')}/>

              </div>
            </div>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <RespondentBasesList />
        </CardBody>
      </Card>
    )
  }
}

export default RespondentBases;