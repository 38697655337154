import * as types from './actionTypes';
import api from "api";
import _ from 'lodash';

export function setAddresses(addresses) {
  return {
    type: types.SET,
    addresses
  }
}

export function addressDeleted(address) {
  return {
    type: types.DELETED,
    address
  }
}

export function addressAdded(address) {
  return {
    type: types.ADDED,
    address
  }
}

export function addressUpdated(address) {
  return {
    type: types.UPDATED,
    address
  }
}

export function deleteAddress(address) {
  return dispatch => {
    return api.respondentBase.address.delete(address).then(() => {
      dispatch(addressDeleted(address));
    })
  }
}

export function createAddress(respondentBaseId, data) {
  return dispatch => {
    return api.respondentBase.address.post.post(respondentBaseId, data).then((address) => {
      dispatch(addressAdded(address));

      return address;
    })
  }
}

export function saveAddresses(data) {
  return dispatch => {
    return api.respondentBase.address.post.text(data).then((addresses) => {
      _.each(addresses.added, address => {
        dispatch(addressAdded(address))
      });

      return addresses;
    })
  }
}

export function updateAddress(data) {
  return dispatch => {
    return api.respondentBase.address.patch(data).then((address) => {
      dispatch(addressUpdated(address));
    })
  }
}

export function fetchAddresses(respondentBaseId) {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.respondentBase.address.get(respondentBaseId).then((addresses) => {
      let orderedAddresses = {};
      _.each(addresses, address => orderedAddresses[address.id] = address);
      dispatch(setAddresses(orderedAddresses));
      dispatch({type: types.SUCCESS});
    })
  }
}

export function uploadAddressesFile(respondentBaseId, formData) {
  return dispatch => {
    return api.respondentBase.address.post.file(respondentBaseId, formData).then((addresses) => {
      _.each(addresses.added, address => {
        dispatch(addressAdded(address))
      });
      dispatch({type: types.SUCCESS});

      return addresses;
    });
  }
}
