import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader} from "reactstrap";
import striptags from "striptags";
import AdvancedStatistics from "./AdvancedStatistics";
import AdvancedCrossTable from "./AdvancedCrossTable";
import api from "../../../../../../../../../api";
import LoadingSpinner from "../../../../../../../../../components/LoadingSpinner";
import AdvancedBoxes from "./AdvancedBoxes";
import AdvancedEditModal from "../AdvancedEditModal";
import AdvancedFrequency from "./AdvancedFrequency";

const DeleteButton = (props) => {
  const [deleting, setDeleting] = React.useState(false);
  const toggleDeleting = () => setDeleting(!deleting);

  const deleteAction = () => {
    toggleDeleting();

    api.analysis.advanced.delete(props.id)
    .then(() => {
      props.onDeleted(props.id)
    })
    .catch(() => {
      toggleDeleting();
    });
  };

  return <React.Fragment>
    <div onClick={deleteAction} className="p-2 pointer d-print-none">
      {deleting ? <i className="fas fa-spin fa-spinner text-danger" /> : <i className="fas fa-times text-danger" />}
    </div>
  </React.Fragment>
};

class AdvancedView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      advanced: props.advanced,
      loading: false,
      data: false,
    };

    this.fetchData = this.fetchData.bind(this);
    this.getAdvancedBody = this.getAdvancedBody.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchAdvanced(){
    const {advanced} = this.state;

    api.analysis.advanced.get(advanced.id)
    .then((data) => {
      this.setState({
        loading: false,
        advanced: data.data,
      }, () => this.fetchData());
    })
  }

  fetchData(){
    const {advanced} = this.state;

    this.setState({
      loading: true,
    });

    api.analysis.advanced.getData(advanced.id)
    .then(({data}) => {
      this.setState({
        loading: false,
        data,
      });
    })
    .catch(res => {

    });
  }

  getAdvancedBody(advanced){
    const {data} = this.state;

    switch (advanced.type) {
      case 'statistics':
        return <AdvancedStatistics advanced={advanced} data={data} />;
      case 'crossTable':
        return <AdvancedCrossTable advanced={advanced} data={data} />;
      case 't2b':
      case 't3b':
      case 'b2b':
      case 'b3b':
        return <AdvancedBoxes advanced={advanced} data={data} />;
      case 'frequency':
        return <AdvancedFrequency advanced={advanced} data={data} />;
    }

    return <p>{advanced.type}</p>
  }

  render() {
    const {loading, advanced} = this.state;

    return <Card key={advanced.id.toString()} className="sortable-draggable border mb-4" data-id={advanced.id}>
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="handler-drag-position ml-2"><i className="fas fa-grip-vertical" /></span>
            <h2>{<div dangerouslySetInnerHTML={{__html: advanced.question.content}} />}</h2>
          </div>

          <div className="d-flex">
            <AdvancedEditModal advanced={advanced} onSaved={() => {
              this.fetchAdvanced();
            }} />
            <DeleteButton id={advanced.id} onDeleted={this.props.onDeleted} />
          </div>
        </div>
      </CardHeader>
      <hr className="my-0" />
      <CardBody>
        {loading ? <LoadingSpinner /> : this.getAdvancedBody(advanced)}
      </CardBody>
    </Card>;
  }
}

AdvancedView.propTypes = {
  onDeleted: PropTypes.func.isRequired,
  advanced: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    question: PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AdvancedView;