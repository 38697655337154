import React, {Component} from 'react';
import {Modal, ModalFooter, ModalBody, ModalHeader, Button, Form, Label, FormGroup, Col, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from 'prop-types';
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import Select from 'react-select';
import Switch from "../../../../../../../../../components/Switch";

class Settings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      widget: {
        tableRows: this.props.widget.tableRows || 10,
        name: this.props.widget.name || '',
        activity: this.props.widget.activity || null,
        favorite: this.props.widget.favorite || false,
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChange(e){
    this.setState({
      widget: {
        ...this.state.widget,
        [e.target.name]: e.target.value,
      }
    });
  }

  onChangeValue(name, value) {
    this.setState({
      widget: {
        ...this.state.widget,
        [name]: value
      }
    });
  }

  onSubmit(){
    const data = {
      id: this.props.widget.id,
      widget: this.state.widget
    };

    if (data.widget.name.length === 0)
      delete data.widget.name;

    this.props.handleSettingsSet(data);
    this.props.toggle('settingsModal');
    this.props.widget.id && this.props.handleDataChange();
  }

  render() {

    const optionsActivity = [
      {
        label: 'Wszystkie',
        value: null,
      },
      {
        label: 'Aktywne',
        value: 'active',
      },
      {
        label: 'Wstrzymane',
        value: 'paused',
      },
    ];

    return (
      <Modal isOpen={this.props.modal} toggle={() => this.props.toggle('settingsModal')} size="lg" className={'modal-danger'}>
        <ModalHeader toggle={() => this.props.toggle('settingsModal')} tag="h2"><FormattedMessage id="widget.surveyTable.modal.header" /></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
          <Form>
            <FormGroup row>
              <Label lg={3}><FormattedMessage id="widgetTypes.typeSurveyTable.settings.label.name" /></Label>
              <Col lg={9}>
                <FormattedMessage id="widgetTypes.typeSurveyTable.settings.placeholder.name">{
                  (message) =>
                  <Input type="text" name="name" placeholder={message} onChange={this.onChange} value={this.state.widget.name} />
                }</FormattedMessage>
            </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={8}>Filtruj</Label>
              <Col lg={4}>
                <Select
                  value={optionsActivity.find(option => option.value === this.state.widget.activity)}
                  clearable={false}
                  onChange={option => this.onChangeValue('activity', option.value)}
                  options={optionsActivity}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={8}><FormattedMessage id="widget.surveyTable.modal.body.label.tableRows" /></Label>
              <Col lg={4}>
                <Select
                  value={{ value: this.state.widget.tableRows, label: this.state.widget.tableRows }}
                  clearable={false}
                  onChange={option => this.onChangeValue('tableRows', option.value)}
                  options={[
                    { value: 5, label: '5' },
                    { value: 10, label: '10' },
                    { value: 20, label: '20' },
                    { value: 50, label: '50' },
                    { value: 0, label: 'Wszystkie' },
                  ]}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg={8}>Tylko ulubione</Label>
              <Col lg={4}>
                <Switch className="mt-2 mr-3" checked={this.state.widget.favorite} onChange={(favorite) => this.onChangeValue('favorite', favorite)} />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="widget.surveyTable.modal.button.save">{
            (message) => <LaddaButton
              className="btn btn-primary btn-ladda"
              data-style={ZOOM_OUT}
              onClick={(e) => this.onSubmit(e)}
            >
              {message}
            </LaddaButton>
          }</FormattedMessage>
          <Button color="secondary" onClick={() => this.props.toggle('settingsModal')}><FormattedMessage id="_.button.cancel" /></Button>
        </ModalFooter>
      </Modal>
    )
  }
}

Settings.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleSettingsSet: PropTypes.func.isRequired,
  handleDataChange: PropTypes.func,
  widget: PropTypes.shape({
    id: PropTypes.number,
    tableRows: PropTypes.number,
    favorite: PropTypes.bool.isRequired,
    survey: PropTypes.shape({
      id: PropTypes.number
    })
  })
};

export default Settings;



