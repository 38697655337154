import React from 'react';
import PropTypes from 'prop-types';
import Icon from "../../../../../../../componentsReusable/Icon";
import api from "../../../../../../../api";
import {Input} from "reactstrap";
import useAfterUpdateEffect from "../../../../../../../utils/useAfterUpdateEffect";

const ImportFile = props => {
  const [file, setFile] = React.useState(null);
  const [saving, setSaving] = React.useState(false);

  useAfterUpdateEffect(() => {
    if(file){
      save()
    }
  }, [file])

  const save = () => {
    setSaving(true);

    const formData = new FormData();
    formData.append('file', file);

    api.survey.importEndingCodes(props.surveyId, formData)
      .then(data => {
        setSaving(false);
        props.onAdded();
      })
      .catch(() => {
        setSaving(false);
      })
  }

  return <React.Fragment>
    <Icon className="fas fa-download pointer" tooltip="Wgraj plik csv z pozycjami" onClick={() => document.getElementById('survey-ending-codes-input-file').click()} />
    <Input
      className="d-none"
      id="survey-ending-codes-input-file"
      type="file"
      onChange={e => setFile(e.target.files[0])}
    />
  </React.Fragment>;
};

ImportFile.propTypes = {
  onAdded: PropTypes.func.isRequired,
};

export default ImportFile;
