import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import Select from 'react-select';
import { connect } from 'react-redux';

class FilterBySelect extends Component {
  render() {
    const {filterQuestion, question} = this.props;
    const availableFilterBys = ['answers', 'rows', 'columns'];
    const options = [];

    if (!filterQuestion || !question) {
      return null;
    }

    _.each(availableFilterBys, key => {
      if (question.hasOwnProperty(key)) {
        options.push({
          label: <FormattedMessage id={"question.filterSerial.select.filterBy." + key} />,
          value: key
        });
      }
    });

    if (options.length < 2) {
      return null;
    }

    return (
      <div className="my-2">
        {this.props.showLabel && <React.Fragment><FormattedMessage id="question.filterSerial.label.filterBy" />:</React.Fragment>}
        <Select
          value={{
            label: <FormattedMessage id={"question.filterSerial.select.filterBy." + filterQuestion.filterBy} />,
            value: filterQuestion.filterBy
          }}
          isMulti={false}
          onChange={e => this.props.onChange(e.value)}
          options={options}
        />
      </div>
    )
  }
}

FilterBySelect.defaultProps = {
  showLabel: true
};

FilterBySelect.propTypes = {
  filterQuestion: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showLabel: PropTypes.bool
};

function mapStateToProps(state, props) {
  return {
    question: props.filterQuestion ? state.survey.structure.data.questions[props.filterQuestion.question] : null
  }
}

export default connect(mapStateToProps)(FilterBySelect);
