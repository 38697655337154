import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import _ from "lodash";
import {ReactSortable} from "react-sortablejs";
import {Row, Col, Alert} from "reactstrap";
import ButtonCreateBlock from './components/ButtonCreateBlock';
import Block from "./components/Block";
import LoadingSpinner from "components/LoadingSpinner";
import {setBlocks} from "scenes/Survey/Structure/data/sets/actions";
import {updateBlock} from "scenes/Survey/Structure/data/blocks/actions";
import {filterBlocks} from "services/surveyStructureFilter";
import classnames from "classnames";

class Blocks extends Component {

  constructor(props) {
    super(props);

    this.onEnd = this.onEnd.bind(this);
  }

  onEnd(evt){
    if(evt.to === evt.from && evt.oldIndex === evt.newIndex){
      return false;
    }

    if(evt.to === evt.from){
      const {set} = this.props;
      let list = set.blockPages;

      const bId = parseInt(evt.item.attributes['data-id'].nodeValue);

      list.splice(evt.oldIndex, 1);
      list.splice(evt.newIndex, 0, bId);
      this.props.setBlocks(set.id, list);

      this.props.updateBlock({
        id: bId,
        block: {
          position: evt.newIndex
        }
      });
    }else{
      const bId = parseInt(evt.item.attributes['data-id'].nodeValue);
      const oldSet = this.props.sets[parseInt(evt.from.parentElement.attributes['data-id'].nodeValue)];
      const newSet = this.props.sets[parseInt(evt.to.parentElement.attributes['data-id'].nodeValue)];


      //add bId to new set
      let newList = newSet.blockPages;
      newList.splice(evt.newIndex, 0, bId);
      this.props.setBlocks(newSet.id, newList);

      //remove bId from old set
      evt.from.appendChild(evt.item);

      const oldList = oldSet.blockPages.filter(id => id !== bId);
      this.props.setBlocks(oldSet.id, oldList);

      //update set and position in block
      this.props.updateBlock({
        id: bId,
        block: {
          setBlocks: newSet.id,
          position: evt.newIndex
        }
      });
    }
  }

  render() {
    const {filteredBlocks, loading, set, allBlocks, hideInStructure, surveyPlan} = this.props;

    if(loading){
      return <LoadingSpinner />
    }

    let blocksList = [];

    if(Object.keys(allBlocks).length > 0){
      _.map(allBlocks, (block) => blocksList.push(
        <div data-id={block.id} key={block.id} className={classnames({
          "card-wrap": true,
          "d-none": !filteredBlocks.includes(block),
          "direction-group": true,
        })}>
          <Block block={block} positionInSurvey={block.positionInSurvey} />
          {/*{hideInStructure !== 'structure' && <ButtonCreateBlock position={block.position + 1} set={set} />}*/}
        </div>)
      )
    }

    return (
      <ReactSortable
        list={blocksList.length > 0 ? blocksList : [{}]}
        setList={() => {}}
        group={{
          name: 'blocks',
          put: ['blocks']
        }}
        handle=".handler"
        animation={100}
        onEnd={this.onEnd}
        className="child-wrap"
        data-id={set.id}
        id="sortable-set"
      >
        {blocksList.length > 0 ? blocksList
          :
          hideInStructure !== 'structure' && <Row className="animated fadeIn">
            <Col>
              <Alert className="text-center" color="warning"><FormattedMessage id="set.blocks.alert.noBlocks"/></Alert>
              <ButtonCreateBlock position={0} set={set} />
            </Col>
          </Row>
        }
      </ReactSortable>
    )
  }
}

function mapStateToProps(state, props) {
  let positionInSurvey = 0;

  let blocks = [];
  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    let set = state.survey.structure.data.sets[setId];

    _.each(set.blockPages, blockId => {
      let block = state.survey.structure.data.blocks[blockId];

      if (setId === props.set.id) {
        block.positionInSurvey = positionInSurvey;
        blocks.push(block);
      }

      positionInSurvey++;
    });
  });

  let blocksFiltered = filterBlocks(blocks, state.survey.structure);

  return {
    loading: state.survey.structure.loading,
    set: state.survey.structure.data.sets[props.set.id],
    sets: state.survey.structure.data.sets,
    filteredBlocks: blocksFiltered,
    allBlocks: blocks,
    hideInStructure: state.survey.structure.hideInStructure,
    surveyPlan: state.user.userPlan.plan.survey
  }
}

export default connect(mapStateToProps, {setBlocks, updateBlock})(Blocks);
