import React, {Component} from 'react';
import {Input} from 'reactstrap';
import PropTypes from 'prop-types';

class SearchInElements extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({search: e.target.value}, () => {
      if (this.state.search.length >= 3) {
        this.props.handleSearch(this.state.search)
      } else {
        this.props.handleSearch(null)
      }
    })
  }

  render() {
    return (
      <div className="sticky sticky-top bg-white">
        <div className="custom-search-input ml-0">
          <Input type="text" className="py-0" placeholder="Wyszukaj elementy" onChange={this.onChange} value={this.state.search}/>
        </div>
      </div>
    )
  }
}

SearchInElements.propTypes = {
  handleSearch: PropTypes.func.isRequired
};

export default SearchInElements;

