import React from 'react';
import PropTypes from 'prop-types';
import api from "../../../api";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../../componentsReusable/Button";

const UnsetOwnStyles = props => {
  const [saving, setSaving] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  const unset = () => {
    setSaving(true)
    api.survey.patch.unsetOwnStyles(props.surveyId)
      .then(props.handlerUpdate)
      .finally(() => {
        setSaving(false)
        setModalOpen(false)
      })
  }

  return <React.Fragment>
    <span className="btn btn-secondary py-3 pointer" onClick={toggleModalOpen}>
      <i className="fas fa-times" />
    </span>
    {modalOpen && <Modal isOpen toggle={toggleModalOpen}>
      <ModalHeader toggle={toggleModalOpen}>Wyczyść wprowadzone ustawienia</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <p>Czy na pewno wyczyścić wszystkie wprowadzone ustawienia wyglądu ankiety w aktualnym szablonie?</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
        <Button
          color="primary"
          onClick={unset}
          loading={saving}
        >Wyczyść</Button>
      </ModalFooter>
    </Modal>}
  </React.Fragment>;
};

UnsetOwnStyles.propTypes = {

};

export default UnsetOwnStyles;
