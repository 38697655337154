import update from 'immutability-helper';
import {ADDED, DELETED, UPDATED, SET} from "./actionTypes";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.addresses;

    case ADDED:
      return {...state, [action.address.id]: action.address};

    case DELETED:
      return update(state, {$unset: [action.address.id]});

    case UPDATED:
      return update(state, {
        [action.address.id]: {
          $set: action.address
        }
      });

    default:
      return state;
  }
};