import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Table} from "reactstrap";
import { connect } from 'react-redux';
import RandAndRotatePageOptions from './components/RandAndRotatePageOptions';

class RandAndRotatePagesTable extends Component {

  render() {
    return (
      <Table>
        <thead>
          <tr>
            <th />
            {this.props.block.rotatePages && <th><small><FormattedMessage id="block.settings.table.header.rotate"/></small></th>}
            {this.props.block.randPages > 0 && <th><small><FormattedMessage id="block.settings.table.header.random"/></small></th>}
            {this.props.block.randPages > 0 && <th><small><FormattedMessage id="block.settings.table.header.randPriority"/></small></th>}
          </tr>
        </thead>
        <tbody>
        {this.props.block.pages.map(page => <RandAndRotatePageOptions block={this.props.block} page={this.props.pages[page]} key={page}/>)}
        </tbody>
      </Table>
    )
  }
}

RandAndRotatePagesTable.propTypes = {
  block: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    pages: state.survey.structure.data.pages
  }
}

export default connect(mapStateToProps)(RandAndRotatePagesTable);