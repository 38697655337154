import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Col, FormGroup, Input, Label} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import Select from "react-select";

class BanForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        category: props.ban ? props.ban.category : null,
        comment: props.ban ? props.ban.comment : ""
      }
    };

    this.onCommentChange = this.onCommentChange.bind(this);
  }

  onCommentChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        comment: e.target.value
      }
    })
  }

  render() {
    const categoriesOptions = _.map(this.props.categories, category => {
      return {
        label: <FormattedMessage id={"admin.respondents.ban.category-" + category} />,
        value: category
      }
    });

    return <React.Fragment>
      <FormGroup row className="mb-2">
        <Label lg={3} className="text-lg-right">
          <FormattedMessage id="admin.respondents.userBulkBanModal.label.category"/>
        </Label>
        <Col lg={9}>
          <Select
            options={categoriesOptions}
            placeholder="Wybierz powód zablokowania"
            onChange={e => this.setState({data: {...this.state.data, category: e.value}})}
            value={categoriesOptions.find(category => category.value === this.state.data.category)}
          />
          {this.props.errors.category && <div className="text-danger small mt-2">{this.props.errors.category.join('; ')}</div>}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label lg={3} className="text-lg-right">
          <FormattedMessage id="admin.respondents.userBulkBanModal.label.comment"/>
        </Label>
        <Col lg={9}>
          <Input type="textarea" rows={5} invalid={!!this.props.errors.comment} onChange={this.onCommentChange} value={this.state.data.comment} />
          {this.props.errors.comment && <div className="invalid-feedback">{this.props.errors.comment.join('; ')}</div>}
        </Col>
      </FormGroup>
    </React.Fragment>
  }
}

BanForm.propTypes = {
  categories: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  ban: PropTypes.object
};

export default BanForm;