import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {InputGroupAddon, Tooltip, Button} from "reactstrap";
import InputNumber from "components/InputNumber";
import classnames from "classnames";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import QuestionSettingAvailable from "components/PlanRestrictionsModals/modals/QuestionSettingAvailable";
import moment from "moment";
import DatePicker from "react-datepicker";

class DateTimeMaxTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      question: {
        dateTimeMaxTime: props.question.dateTimeMaxTime,
      }
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleTooltip(type) {
    this.setState({
      ...this.state,
      [type]: !this.state[type]
    })
  }

  onChange(value, target) {
    if (this.props.disabled) {
      this.props.setPlanRestrictionsModals(QuestionSettingAvailable.getName());
      return;
    }
    this.setState({
      ...this.state,
      question: {
        ...this.state.question,
        [target]: value
      }
    }, () => this.props.handleUpdate(this.state.question));
  }

  render() {
    const {question, tooltip} = this.state;
    const {id} = this.props.question;

    return (
      <React.Fragment>
        <div
          id={"dateTimeMaxTime" + id}
          className={classnames({
            "min-w-0 float-left mr-1 mb-1 d-flex flex-nowrap input-group": true,
          })}
          style={{width: '100px'}}
        >
          <InputGroupAddon addonType="prepend">
            <Button
              className={classnames({
                "btn-icon": true,
                "active": !!question.dateTimeMaxTime,
                "disabled": this.props.disabled
              })}
              color="secondary"
            >
              <i className="fas fa-dial-max"/>
              <Tooltip
                delay={0}
                placement="top"
                isOpen={tooltip}
                target={"dateTimeMaxTime" + id}
                toggle={() => this.toggleTooltip('tooltip')}
              >Godziny do</Tooltip>
            </Button>
          </InputGroupAddon>
          <DatePicker
            className="mb-0 mt-1"
            isClearable={true}
            placeholderText={"Godziny do"}
            showTimeSelect
            showTimeSelectOnly
            selected={question.dateTimeMaxTime ? moment('2022-01-01 ' + question.dateTimeMaxTime) : null}
            onChange={(date) => {
              this.onChange(date ? date.format('HH:mm') : null, 'dateTimeMaxTime')
            }}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Godzina"
            locale={localStorage.language}
          />
        </div>
      </React.Fragment>
    )
  }
}

DateTimeMaxTime.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    dateTimeMaxTime: PropTypes.string,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default connect(null, {setPlanRestrictionsModals})(DateTimeMaxTime);
