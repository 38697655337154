import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Input, Tooltip} from "reactstrap";
import classnames from "classnames";
import Icon from "../../../../../../../../../../../../../../../../../../../../../../../componentsReusable/Icon";

class ValidationText extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: {
        validationText: props.question.validationText || '',
        validationTextMobile: props.question.validationTextMobile || '',
      },
      isOpen: !!props.question.validationText || !!props.question.validationTextMobile,
      validationTooltip: false,
      validationDefaultTooltip: false,
    };

    this.toggleValidationTextTooltip = this.toggleValidationTextTooltip.bind(this);
    this.toggleValidationTextDefaultTooltip = this.toggleValidationTextDefaultTooltip.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  toggleValidationTextTooltip() {
    this.setState({
      validationTooltip: !this.state.validationTooltip
    });
  }

  toggleValidationTextDefaultTooltip() {
    this.setState({
      validationDefaultTooltip: !this.state.validationDefaultTooltip
    });
  }

  onChange(obj){
    this.setState({
      question: {
        ...this.state.question,
        ...obj,
      }
    });
  }

  handleKeyPress(e){
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  toggleIsOpen() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    let { isOpen, question, validationTooltip } = this.state;

    if(!isOpen){
      return <Button className="mr-1 btn-icon float-left" color="secondary" id={"validation-" + this.props.question.id} onClick={this.toggleIsOpen}>
        <i className="fa-solid fa-message-pen" />
        <Tooltip
          delay={0}
          placement="top"
          isOpen={validationTooltip}
          target={"validation-" + this.props.question.id}
          toggle={() => this.toggleValidationTextTooltip()}
        >Zmień treść walidacji</Tooltip>
      </Button>
    }

    return (
      <div
        className={classnames({
          "mr-1 float-left w-75 p-0 mb-1": true,
          "input-group d-flex d-flex flex-row flex-nowrap": isOpen,
        })}
      >
        <InputGroupAddon addonType="prepend" className="m-0">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": isOpen,
            })}
            color="secondary"
            onClick={() => {
              this.setState({
                isOpen: false,
                question: {
                  ...this.state.question,
                  validationText: '',
                  validationTextMobile: '',
                }
              }, () => {
                this.props.handleUpdate(this.state.question)
              });
            }}
            id={"validation-" + this.props.question.id}
          >
            <i className="fa-solid fa-message-pen" />
            <Tooltip
              delay={0}
              placement="top"
              isOpen={validationTooltip}
              target={"validation-" + this.props.question.id}
              toggle={() => this.toggleValidationTextTooltip()}
            >Zmień treść walidacji</Tooltip>
          </Button>
        </InputGroupAddon>
        {isOpen && <div className="d-flex flex-row flex-nowrap flex-grow-1">
          <Input placeholder="Treść walidacji (Desktop)" className="mb-0 mr-2 border-right" name="validationText" value={question.validationText} onChange={e => this.onChange({validationText: e.target.value})} onBlur={() => this.props.handleUpdate(this.state.question)} onKeyPress={this.handleKeyPress}/>
          <Input placeholder="Treść walidacji (Mobile)" className="mb-0 " name="validationTextMobile" value={question.validationTextMobile} onChange={e => this.onChange({validationTextMobile: e.target.value})} onBlur={() => this.props.handleUpdate(this.state.question)} onKeyPress={this.handleKeyPress}/>
        </div>}
        <InputGroupAddon className="my-0" id={"instruction-default-" + this.props.question.id} addonType="append">
          <Button color="default" className="btn-icon">
            <Icon className="fas fa-info-circle" tooltip="Podaj własną treść walidacji lub pozostaw pole puste w celu ustawienia domyślnej treści walidacji" />
          </Button>
        </InputGroupAddon>
      </div>
    )
  }
}

ValidationText.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    validationText: PropTypes.string,
    validationTextMobile: PropTypes.string,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default ValidationText;
