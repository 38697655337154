import api from "api";
import * as types from './actionTypes';
import _ from 'lodash';

export function setPrizes(prizes) {
  return {
    type: types.SET,
    prizes
  }
}

export function prizeDeleted(prize) {
  return {
    type: types.DELETED,
    prize
  }
}

export function prizeUpdated(prize) {
  return {
    type: types.UPDATED,
    prize
  }
}

export function prizeAdded(prize) {
  return {
    type: types.ADDED,
    prize
  }
}

export function fetchPrizes() {
  return dispatch => {
    dispatch({type: types.REQUEST});
    return api.prize.get().then(prizes => {
      let orderedPrizes = {};
      _.map(prizes, prize => orderedPrizes[prize.id] = prize);

      dispatch(setPrizes(orderedPrizes));
      dispatch({type: types.SUCCESS});
    }).catch(() => {
      dispatch({type: types.ERROR});
    });
  }
}

export function deletePrize(prize) {
  return dispatch => {
    return api.prize.delete(prize).then(() => {
      dispatch(prizeDeleted(prize));
    })
  }
}

export function updatePrize(data) {
  return dispatch => {
    return api.prize.patch(data).then(prize => {
      dispatch(prizeUpdated(prize));
    })
  }
}

export function addPrize(data) {
  return dispatch => {
    return api.prize.post.prize(data).then(prize => {
      dispatch(prizeAdded(prize));
    })
  }
}