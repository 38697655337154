import striptags from "striptags";
import {getQuestionTemplateName} from "./questionTypes";

export function filterSets(setsList, structure) {
  if (shouldFilter(structure)) {
    return setsList.filter(set => {
      let blocks = set.blockPages.map(id => structure.data.blocks[id]);

      const regExp = new RegExp(structure.searchInStructure.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), "i");
      const nameMatchSearch = set.name && structure.searchInStructure.length > 0 && set.name.match(regExp);

      return filterBlocks(blocks, structure).length > 0 || nameMatchSearch;
    });
  }

  return setsList;
}

export function filterBlocks(blocksList, structure) {
  if (shouldFilter(structure)) {
    return blocksList.filter(block => {
      let pages = block.pages.map(id => structure.data.pages[id]);

      const regExp = new RegExp(structure.searchInStructure.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), "i");
      const nameMatchSearch = block.name && structure.searchInStructure.length > 0 && block.name.match(regExp);

      return filterPages(pages, structure).length > 0 || nameMatchSearch;
    });
  }

  return blocksList;
}

export function filterPages(pagesList, structure) {
  if (shouldFilter(structure)) {
    return pagesList.filter(page => {
      let questions = page.questions.map(id => structure.data.questions[id]);
      return filterQuestions(questions, structure).length > 0;
    });
  }

  return pagesList
}

export function filterQuestions(questionList, structure) {
  questionList = searchInStructureFilter(questionList, structure);
  questionList = questionTypeFilter(questionList, structure.questionTypes);
  questionList = questionConditionFilter(questionList, structure.filterConditionQuestions, structure.data.filters);
  questionList = questionActionFilter(questionList, structure.filterActionQuestions, structure.data.filters);
  questionList = questionDisabledFilter(questionList, structure.disabledQuestions);

  return questionList;
}

function searchInStructureFilter(questionList, structure) {
  if (structure.searchInStructure.length > 0) {
    return questionList.filter(question => {
      let elements = [];

      elements = elements.concat(question.excludings.map(id => structure.data.excludings[id]));

      if (question.hasOwnProperty('answers'))
        elements = elements.concat(question.answers.map(id => structure.data.answers[id]));

      if (question.hasOwnProperty('rows'))
        elements = elements.concat(question.rows.map(id => structure.data.rows[id]));

      if (question.hasOwnProperty('columns'))
        elements = elements.concat(question.columns.map(id => structure.data.columns[id]));

      const regExp = new RegExp(structure.searchInStructure.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), "i");

      elements = elements.filter(element => {
        if(element.hasOwnProperty('leftContent') || element.hasOwnProperty('rightContent')){
          return striptags(element.leftContent).match(regExp) || striptags(element.rightContent).match(regExp)
        }

        return striptags(element.content).match(regExp)
      });
      return striptags(question.content).match(regExp) || elements.length > 0 || question.id == structure.searchInStructure;
    });
  }

  return questionList;
}

function questionTypeFilter(questionList, questionTypes) {
  if (questionTypes.length > 0) {
    return _.filter(questionList, question => questionTypes.includes(getQuestionTemplateName(question)));
  }

  return questionList;
}

function questionConditionFilter(questionList, filterConditionQuestions, filters) {
  if (filterConditionQuestions) {
    let conditionsQuestionsIds = [];

    _.each(filters, filter => {
      _.each(filter.blocks, block => {
        _.each(block.condsQuestion, condition => {
          conditionsQuestionsIds = [...conditionsQuestionsIds, condition.question]
        })
      })
    });

    return _.filter(questionList, question => conditionsQuestionsIds.includes(question.id));
  }

  return questionList;
}

function questionActionFilter(questionList, filterActionQuestions, filters) {
  if (filterActionQuestions) {
    let actionsQuestionsIds = [];

    _.each(filters, filter => {
      _.each(filter.actions, action => {
        actionsQuestionsIds = [...actionsQuestionsIds, ...action.questions]
      })
    });

    return _.filter(questionList, question => actionsQuestionsIds.includes(question.id));
  }

  return questionList;
}

function questionDisabledFilter(questionList, disabledQuestions) {
  if (disabledQuestions) {
    return _.filter(questionList, question => question.disabled);
  }

  return questionList;
}

function shouldFilter(structure) {
  return structure.searchInStructure.length > 0 ||
    structure.questionTypes.length > 0 ||
    structure.filterConditionQuestions ||
    structure.filterActionQuestions ||
    structure.disabledQuestions;
}