import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Form,FormGroup,Label,Col,Input,FormFeedback} from "reactstrap";
import api from "api";

class UserEmailDomainBlacklistForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      saving: false,
      errors: {},
    };

    this.formRef = React.createRef();

    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  changeData(field, value){
    this.setState({
      data: {
        ...this.state.data,
        [field]: value,
      }
    })
  }

  submit(e){
    e.preventDefault();
  }

  save(){
    const {data} = this.state;

    this.props.onSavingStart();

    this.setState({
      errors: {},
    })

    api.userEmailDomainBlacklists.post(data)
    .then(data => {
      this.props.onSavingStop();

      this.props.onSaved(data.data);
    })
    .catch(res => {
      this.props.onSavingStop();

      this.setState({
        errors: res.response.data
      })
    })
  }

  render() {
    const {data, errors} = this.state;

    return <Form>
      <FormGroup row>
        <Label md={4}>Domena:</Label>
        <Col md={8}>
          <Input type="text" invalid={!!_.has(errors, 'children.domain.errors')} onChange={e => this.changeData('domain', e.target.value)} value={data.domain} />
          {_.has(errors, 'children.domain.errors') && <FormFeedback>{errors.children.domain.errors.join('; ')}</FormFeedback>}
        </Col>
      </FormGroup>
    </Form>
  }
}

UserEmailDomainBlacklistForm.defaultProps = {
  data: {
    domain: ''
  },
};

UserEmailDomainBlacklistForm.propTypes = {
  data: PropTypes.object,
  onSaved: PropTypes.func,
  onSavingStart: PropTypes.func,
  onSavingStop: PropTypes.func,
};

export default UserEmailDomainBlacklistForm;
