import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "reactstrap";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";
import { connect } from 'react-redux';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";

class ButtonDisabled extends Component{
  constructor(props) {
    super(props);
    this.state = {
      tooltipIsOpen: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  toggleTooltip(){
    this.setState({
      tooltipIsOpen: !this.state.tooltipIsOpen
    });
  }

  onClick() {
    this.setState({...this.state, disabled: !this.props.disabled});
    this.props.toggleOption('disabled');
  }

  render() {
    const {answer, disabled} = this.props;
    const {tooltipIsOpen} = this.state;

    return (
      <a id={'disabled-' + answer.id} className={classnames({
        "fa-solid fa-eye-slash ml-2": true,
        "text-primary": disabled,
      })} onClick={this.onClick}>
        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltipIsOpen}
          target={'disabled-' + answer.id}
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="answer.buttonDisabled.button.tooltip"/>
        </Tooltip>
      </a>
    )
  }
}

ButtonDisabled.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleOption: PropTypes.func.isRequired,
};

export default connect(null, {setPlanRestrictionsModals})(ButtonDisabled);
