import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {saveRespondentBase} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import RespondentBaseForm from './RespondentBaseForm';
import PropTypes from 'prop-types';
import {toast} from "react-toastify";

class RespondentBaseCreateModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      errors: {}
    };

    this.form = React.createRef();
    this.saveRespondentBase = this.saveRespondentBase.bind(this);
  }

  saveRespondentBase(e, data){
    e.preventDefault();
    this.setState({creating: true, errors: {}});

    this.props.saveRespondentBase({
      respondentBase: {
        name: data.name
      }
    }).then(() => {
      this.setState({
        creating: false
      }, () => this.props.toggle())
    }).catch(e => {
        this.setState({creating: false});

        if (e.response.data.hasOwnProperty('errors')) {
          toast.error(e.response.data.errors[0].message)
        } else {
          this.setState({
            errors: e.response.data.form.errors
          });
        }
    });
  }

  render() {
    const { creating, errors } = this.state;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle} tag="h2"><FormattedMessage id="respondentBaseCreateModal.modalHeader" /></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <RespondentBaseForm onSubmit={this.saveRespondentBase} ref={this.form} errors={errors}/>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.save">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={creating}
                  data-style={ZOOM_OUT}
                  onClick={e => this.saveRespondentBase(e, this.form.current.state)}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) =>
                  <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggle}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

RespondentBaseCreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default connect(null, { saveRespondentBase }, null, {forwardRef: true})(RespondentBaseCreateModal);