import React, { useEffect, useRef } from 'react';

const useAfterUpdateEffect = (func, props) => {
  const initial = useRef(true);

  useEffect(() => {
    if (initial.current) {
      initial.current = false;

      return;
    }

    func();
  }, props);
}

export default useAfterUpdateEffect;