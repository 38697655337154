import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import SelectPages from "../../../SelectPages";

class Page extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalPage: props.page,
    };

    this.toggleModalPage = this.toggleModalPage.bind(this);
  }

  toggleModalPage(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {page, pages, changeable} = this.props;
    const {modalOpen, modalPage} = this.state;
    const p = pages.find(p => p.id === page);

    if (!changeable) {
      return p ? 'Strona ' + p.positionInSurvey : null;
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalPage} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalPage}>Wybierz stronę</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <SelectPages isMulti={false} value={modalPage} onChange={page => {
          this.setState({
            modalPage: page,
            modalOpen: false,
          }, () => this.props.handlePageChanged(page));
        }} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handlePageChanged(modalPage);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={page ? 'secondary' : 'warning'} className="mx-1 pointer" onClick={this.toggleModalPage}>{p ? ('Strona ' + p.positionInSurvey) : 'Wybierz stronę...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

Page.defaultProps = {
  changeable: true
};

Page.propTypes = {
  page: PropTypes.number,
  handlePageChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  let pages = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        positionInSurvey++;

        let p = state.survey.structure.data.pages[pageId];
        p.positionInSurvey = positionInSurvey;
        pages.push(p);
      });
    });
  });

  return {
    pages
  }
}

export default connect(mapStateToProps)(Page);