import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {updateClient} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import PropTypes from 'prop-types';
import ClientsForm from './ClientsForm';
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import ClientsAvailable from "components/PlanRestrictionsModals/modals/ClientsAvailable";

class ClientsButtonEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false,
      errors: []
    };

    this.form = React.createRef();
    this.saveClient = this.saveClient.bind(this);
  }

  saveClient(e, data){
    e.preventDefault();
    this.setState({saving: true});

    this.props.updateClient({
      id: this.props.client.id,
      data: data
    })
      .then(() => {
      this.setState({
        modal: false,
        saving: false
      })
    })
      .catch((e) => {
        if(e.response.data.form.errors){
          this.setState({
            saving: false,
            errors: e.response.data.form.errors,
          });
        }
      });
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { saving, errors } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()} size="lg">
      <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id="clientsButtonEdit.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <ClientsForm errors={errors} onSubmit={this.saveClient} ref={this.form} client={this.props.client}/>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.save">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={e => this.saveClient(e, this.form.current.state.data)}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggleModal()}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="m-0 p-0" onClick={() => this.props.plan.clients ? this.toggleModal() : this.props.setPlanRestrictionsModals(ClientsAvailable.getName())}><i className="fas fa-edit"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

ClientsButtonEdit.propTypes = {
  client: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan
  }
}

export default connect(mapStateToProps, { updateClient, setPlanRestrictionsModals }, null, {forwardRef: true})(ClientsButtonEdit);