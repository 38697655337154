import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardFooter, CardHeader, Col, Row} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {surveyLink} from "../../../../../services/surveyLink";
import ListGroup from "reactstrap/es/ListGroup";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import classnames from "classnames";
import ModalAccept from "./ModalAccept";
import ModalWaiting from "./ModalWaiting";
import ModalReject from "./ModalReject";
import api from "api";
import QuestionInfo from "../../../../../components/QuestionInfo";
import './style.scss'
import {isNull} from "lodash";
import Link from "../../../../../componentsReusable/Link";
import appRoutes from "../../../../../appRoutes";

const SurveyCopyNameItem = ({survey}) => {
  const [checking, setChecking] = React.useState(false);
  const [copy, setCopy] = React.useState(null);

  const check = () => {
    setChecking(true)

    api.admin.survey.checkCopyName(survey.id)
      .then(data => setCopy(data.data))
      .finally(() => setChecking(false))
  }

  return <ListGroupItem className={classnames({
    'bg-warning': !isNull(copy) && copy !== false,
    'bg-success text-white': !isNull(copy) && copy === false,
  })}>
    <span>Kopia nazwy: </span>
    {
      checking
        ? <i className="fas fa-spinner fa-spin" />
        : isNull(copy)
            ? <span className="pointer" onClick={check}>Sprawdź</span>
            : copy ? <span><Link to={appRoutes.survey.structure(copy.id)} target="_blank">Przejdź ({copy.createdAt})</Link></span> : <span>Brak kopi</span>
    }
  </ListGroupItem>
}

class CollectorsToAccept extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      modalAccept: false,
      modalWaiting: false,
      modalReject: false,
      questionsShow: false,
      messagesShow: false
    };

    this.handlerOnAccept = this.handlerOnAccept.bind(this);
    this.handlerOnWaiting = this.handlerOnWaiting.bind(this);
    this.handlerOnReject = this.handlerOnReject.bind(this);
  }

  handlerOnReject(data){
    const collector = this.props.collectors.find(collector => collector.id === this.state.modalReject);
    const survey = collector.survey;

    this.setState({
      modalReject: false,
      saving: collector.id,
    });

    api.admin.survey.patch.swPanel(survey.id, {...data, status: 2})
    .then(() => {
      this.setState({
        saving: false
      });

      this.onDone(collector.id);
    });
  }

  handlerOnAccept(data){
    const collector = this.props.collectors.find(collector => collector.id === this.state.modalAccept);
    const survey = collector.survey;

    this.setState({
      modalAccept: false,
      saving: collector.id,
    });

    api.admin.survey.patch.swPanel(survey.id, {...data, status: 3})
      .then(() => {
        this.setState({
          saving: false
        });

        this.onDone(collector.id);
      });
  }

  handlerOnWaiting(data){
    const collector = this.props.collectors.find(collector => collector.id === this.state.modalWaiting);
    const survey = collector.survey;

    this.setState({
      modalWaiting: false,
      saving: collector.id,
    });

    api.admin.survey.patch.swPanel(survey.id, {...data, status: 4})
      .then(() => {
        this.setState({
          saving: false
        });

        this.onDone(collector.id);
      });
  }

  onDone(id){
    this.setState({
      saving: false,
    });

    this.props.onDone(id);
  }

  statusToString(string) {
    let toReturn = "";
    switch (string) {
      case 1:
        toReturn = "Zaraportowano";
        break;
      case 2:
        toReturn = "Odrzucone";
        break;
      case 3:
        toReturn = "Zaakceptowane";
        break;
      case 4:
        toReturn = "W poczekalni";
        break;
    }

    return toReturn;
  }

  render() {
    const {saving, modalAccept, modalWaiting, modalReject, questionsShow, messagesShow} = this.state;
    const {collectors} = this.props;

    return (
      <Row>
        {collectors.map(collector => {
          const {survey} = collector;

          let pagesCount = 0;
          let questions = [];
          _.map(survey.setBlocks, setBlock => {
            _.map(setBlock.blockPages, block => {
              _.map(block.pages, page => {
                pagesCount++;
                _.map(page.questions, question => {
                  questions.push(question);
                });
              })
            })
          });

          return <Col key={collector.id} xs={12} md={4} xl={3}>
            <Card id={"questions-list"} style={{
              opacity: saving === collector.id ? 0.5 : 1
            }}>
              <CardHeader><h2 className="p-0"><a className="d-block w-100 p-3" href={surveyLink(collector.survey.id, true, true)} target="_blank">{collector.type === 'TopSurveys' && <i className="fas fa-trophy text-warning mr-2" />} {collector.survey.name}</a></h2></CardHeader>
              <hr className="my-0" />
              <CardBody>
                <ListGroup className="mt-2">
                  {survey.swPanel && survey.swPanel.rejectedByEmail && <ListGroupItem>Odrzucona przez: {survey.swPanel.rejectedByEmail}</ListGroupItem>}
                  <ListGroupItem>Id ankiety: {survey.id}</ListGroupItem>
                  <ListGroupItem>User: {survey.user.email}</ListGroupItem>
                  <ListGroupItem>Punkty (endSt/soSt/endSp/soSp): {survey.endCashPoints} / {survey.screenOutCashPoints} / {survey.endSocialPoints} / {survey.screenOutSocialPoints}</ListGroupItem>
                  <ListGroupItem>Liczba stron / pytań: {pagesCount} / {questions.length}</ListGroupItem>
                  <ListGroupItem className={classnames({
                    'bg-warning': [survey.copyFromSurveyId, survey.copyFromName, survey.copyFromEndAt].filter(v => !!v).length > 0
                  })}>Kopia: {survey.copyFromSurveyId ? <span>{survey.copyFromSurveyId}: {survey.copyFromName + ' (' + survey.copyFromEndAt + ')'}</span> : 'Nie'}</ListGroupItem>
                  <SurveyCopyNameItem survey={survey} />
                  <ListGroupItem onClick={() => this.setState({questionsShow: questionsShow ? false : collector.id})}>
                    <span>Pytania</span>
                    {questionsShow === collector.id ? <ul className="pl-0">
                      <li className="mb-4">
                        <div dangerouslySetInnerHTML={{__html: survey.invitationText}} />
                      </li>
                      {_.map(questions, question => {
                        return <li className="question">
                          <div className="d-flex"><QuestionInfo question={question}/> <div className="ml-2" dangerouslySetInnerHTML={{__html: question.content}} /></div>
                          {question.answers && <ul>
                            {question.answers.map(answer => <li>{answer.content}</li>)}
                          </ul>}
                          {question.rows && <ul>
                            {question.rows.map(row => <li>{row.content}</li>)}
                          </ul>}
                          {question.columns && <ul>
                            {question.columns.map(column => <li>{column.content}</li>)}
                          </ul>}
                        </li>
                      })}
                      <li className="mt-4">
                        <div dangerouslySetInnerHTML={{__html: survey.endingText}} />
                      </li>
                    </ul> : <span> - Kliknij aby rozwinąć</span>}
                  </ListGroupItem>
                  <ListGroupItem onClick={() => this.setState({messagesShow: messagesShow ? false : collector.id})}>
                    <span>Historia wiadomości ({survey.swPanel.messages.length}) </span>
                    {messagesShow === collector.id ? <ul className="pl-0">
                      {_.map(survey.swPanel.messages, message => {
                        return <ul class={"mt-4 pl-0"}>
                          <li>Email: {message.email}</li>
                          <li>Data: {message.createdAt}</li>
                          {message.status && <li>Status: {this.statusToString(message.status)}</li>}
                          {message.content && <li>Wiadomość: {message.content}</li>}
                        </ul>
                      })}
                    </ul> : <span> - Kliknij aby rozwinąć</span>}
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col xs={12} md={4}>
                    <LaddaButton
                      className="btn w-100 btn-danger btn-ladda float-left"
                      loading={false}
                      data-style={ZOOM_OUT}
                      onClick={() => this.setState({
                        modalReject: collector.id,
                      })}
                    >Odrzuć {survey.swPanel && survey.swPanel.rejectedTimes > 0 && <span>({survey.swPanel.rejectedTimes})</span>}</LaddaButton>

                    {modalReject === collector.id && <ModalReject
                      onCancel={() => this.setState({modalReject: false})}
                      onReject={data => this.handlerOnReject(data)}
                    />}
                  </Col>
                  <Col xs={12} md={4} className="mt-2 mt-md-0">
                    <LaddaButton
                      className="btn w-100 btn-warning btn-ladda float-right"
                      loading={false}
                      disabled={collector.survey.swPanel.status === 4}
                      data-style={ZOOM_OUT}
                      onClick={() => this.setState({
                        modalWaiting: collector.id,
                      })}
                    >Poczekalnia</LaddaButton>
                    {modalWaiting === collector.id && <ModalWaiting
                      onCancel={() => this.setState({modalWaiting: false})}
                      onWaiting={data => this.handlerOnWaiting(data)}
                    />}
                  </Col>
                  <Col xs={12} md={4} className="mt-2 mt-md-0">
                    <LaddaButton
                      className="btn w-100 btn-success btn-ladda float-right"
                      loading={false}
                      data-style={ZOOM_OUT}
                      onClick={() => this.setState({
                        modalAccept: collector.id,
                      })}
                    >Akceptuj</LaddaButton>

                    {modalAccept === collector.id && <ModalAccept
                      onCancel={() => this.setState({modalAccept: false})}
                      onAccept={data => this.handlerOnAccept(data)}
                    />}
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        })}
      </Row>
    );
  }
}

CollectorsToAccept.propTypes = {
  onDone: PropTypes.func.isRequired,
  collectors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    guid: PropTypes.string.isRequired,
    survey: PropTypes.shape({
      name: PropTypes.string.isRequired,
      responsesCount: PropTypes.number.isRequired,
      paused: PropTypes.bool.isRequired,
      copyFrom: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      swPanel: PropTypes.shape({
        status: PropTypes.number.isRequired,
        rejectedTimes: PropTypes.string.isRequired,
        msg: PropTypes.string,
      }),
    }).isRequired,
  }),).isRequired,
};

export default CollectorsToAccept;
