import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Settings from "scenes/Survey/components/Settings";
import { connect } from 'react-redux';
import {fetchSurvey} from "scenes/Survey/Structure/data/survey/actions";
import LoadingSpinner from 'components/LoadingSpinner';
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import classnames from "classnames";


class SurveysModalSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      saved: false,
      saveButtonVisible: true,
      buttonDisabled: true,
    };

      this.settings = React.createRef();
  }

  componentDidMount() {
    // if (this.props.isOpen && !this.state.isLoaded) {
      this.props.fetchSurvey({
        id: this.props.survey.id
      });
    // }
  }

  render() {
    const {isLoaded, survey} = this.props;
    const {saving, saved, saveButtonVisible, buttonDisabled} = this.state;

    return (
      <Modal size="xl" isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="survey.settings.modal.header"/></ModalHeader>
        <hr className="my-0" />
        <ModalBody className="px-0 pb-0">
          {isLoaded ? <Settings
            ref={this.settings}
            handleOnSavingUpdate={saving => {
              this.setState({
                saving,
              }, () => {
                if(!saving){
                  this.setState({
                    saved: true,
                  }, () => {
                    setTimeout(() => {
                      this.setState({saved: false})
                    }, 3000)
                  })
                }
              })
            }}
            handleOnSaveButtonVisibleUpdate={saveButtonVisible => this.setState({saveButtonVisible})}
            handleButtonDisabledChanged={buttonDisabled => this.setState({buttonDisabled})}
          /> : <LoadingSpinner />}
        </ModalBody>
        <ModalFooter>
            <div className="d-flex w-100">
              <h4 className="flex-grow-1 mb-0 mt-2">Data utworzenia: {survey.createdAt}</h4>
              {saveButtonVisible && <LaddaButton
                className={classnames({
                  'btn btn-ladda': true,
                  'btn-primary': !saved,
                  'btn-success': saved,
                })}
                disabled={buttonDisabled}
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={() => this.settings.current.updateSurvey()}
              >{saved ? <span><i className="fas fa-check" /> Zapisano</span> : 'Zapisz zmiany'}</LaddaButton>}
            </div>
        </ModalFooter>
      </Modal>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    isLoaded: Number.isInteger(state.survey.structure.data.survey.id) && state.survey.structure.data.survey.id === props.survey.id,
  }
}

export default connect(mapStateToProps, {fetchSurvey})(SurveysModalSettings);