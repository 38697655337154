import React, {Component} from 'react';
import PropTypes from "prop-types";
import SettingBool from "../../../SettingBool";
import AnswersImage from "../../../AnswersImage";
import RandAnswers from "../../../RandAnswers";
import SettingNumber from "../../../SettingNumber";
import PresentationType from "../../../PresentationType";
import FetchCafeteryPositions from "../../../FetchCafeteryPositions";
import SettingText from "../../../SettingText";
import DivisionColumn from "../../../DivisionColumn";
import {connect} from "react-redux";
import PsmType from "../../../PsmType";
import AnswersColumns from "../../../AnswersColumns";

class NumericMultiSettings extends Component {

  render() {
    const {question, questionSettingsPlan} = this.props;

    return (
      <React.Fragment>
        <SettingBool question={question} name={'required'} value={question.required || false} icon={<i className="fa-solid fa-message-exclamation" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'rotateAnswers'} value={question.rotateAnswers} disabled={!questionSettingsPlan.numericMulti.rotateAnswers} icon={<i className="fas fa-random" />} handleUpdate={this.props.handleUpdate}/>
        <AnswersImage question={question} handleUpdate={this.props.handleUpdate}/>
        {!question.range && question.presentationType !== 'dragAndDrop' && <SettingBool question={question} name={'summaryVisibility'} value={question.summaryVisibility || false} icon={<i className="fas fa-eye" />} handleUpdate={this.props.handleUpdate}/>}
        {['slider'].includes(question.presentationType) && <SettingBool question={question} name={'showSliderInputs'} value={question.showSliderInputs} icon={<i className="fa-solid fa-input-numeric" />} handleUpdate={this.props.handleUpdate}/>}
        <SettingBool question={question} name={'reverseAnswers'} value={question.reverseAnswers} icon={<i className="fa-solid fa-retweet" />} handleUpdate={this.props.handleUpdate}/>
        {!question.range && question.presentationType !== 'dragAndDrop' && <SettingNumber question={question} name={'summaryMin'} value={question.summaryMin || 0} icon={<i className="fak fa-sliders-simple-left" />} handleUpdate={this.props.handleUpdate}/>}
        {!question.range && question.presentationType !== 'dragAndDrop' && <SettingNumber question={question} name={'summaryMax'} value={question.summaryMax || 0} icon={<i className="fak fa-sliders-simple-right" />} handleUpdate={this.props.handleUpdate}/>}
        <SettingNumber question={question} name={'valueMin'} float={true} value={question.valueMin || 0} icon={<i className="fak fa-sliders-simple-left" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'valueMax'} float={true} value={question.valueMax || 0} icon={<i className="fak fa-sliders-simple-right" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'decimals'} value={question.decimals || 0} icon={<i className="fak fa-zero-comma-zero" />} handleUpdate={this.props.handleUpdate}/>
        <SettingNumber question={question} name={'step'} value={question.step || 0} icon={<i className="fa-solid fa-distribute-spacing-horizontal" />} handleUpdate={this.props.handleUpdate}/>
        {!question.defaultValueMiddle && <SettingNumber question={question} name={'defaultValue'} minValue={0} value={question.defaultValue || 0} icon={<i className="fa-solid fa-scale-balanced"/>} handleUpdate={({defaultValue}) => {
          this.props.handleUpdate({defaultValue});
        }}/>}
        <SettingBool question={question} name={'defaultValueMiddle'} value={question.defaultValueMiddle || false} icon={<i className="fa-solid fa-align-center" />} handleUpdate={this.props.handleUpdate}/>
        <SettingBool question={question} name={'stackedAnswers'} value={question.stackedAnswers} icon={<i className="fa-solid fa-layer-group" />} handleUpdate={this.props.handleUpdate}/>
        <AnswersColumns question={question}/>
        {!['dragAndDrop'].includes(question.presentationType) && <SettingText question={question} name={'valueText'} value={question.valueText || ''} icon={<i className="fa-solid fa-strikethrough" />} handleUpdate={this.props.handleUpdate}/>}
        {['psm', 'slider'].includes(question.presentationType) && <SettingBool question={question} name={'hideSelectedValue'} value={question.hideSelectedValue} icon={<i className="fas fa-strikethrough" />} handleUpdate={this.props.handleUpdate}/>}
        {['psm', 'slider'].includes(question.presentationType) && <SettingBool question={question} name={'hideSelectingValue'} value={question.hideSelectingValue} icon={<i className="fa-solid fa-circle-info" />} handleUpdate={this.props.handleUpdate}/>}
        <PresentationType question={question} handleUpdate={this.props.handleUpdate}/>
        {['psm'].includes(question.presentationType) && <PsmType question={question} handleUpdate={this.props.handleUpdate}/>}
        {['psm', 'slider'].includes(question.presentationType) && <SettingBool question={question} name={'sliderVertical'} value={question.sliderVertical} icon={<i className="fak fa-sliders-arrow" />} handleUpdate={this.props.handleUpdate}/>}
        <RandAnswers question={question} disabled={!questionSettingsPlan.randCafetery} handleUpdate={this.props.handleUpdate}/>
        {!['dragAndDrop', 'psm'].includes(question.presentationType) && <DivisionColumn question={question} handleUpdate={this.props.handleUpdate}/>}
        <FetchCafeteryPositions question={question} handleUpdate={this.props.handleUpdate}/>
        {!['dragAndDrop', 'psm'].includes(question.presentationType) && <SettingBool question={question} name={'range'} value={question.range} icon={<i className="fa-solid fa-2" />} handleUpdate={this.props.handleUpdate}/>}
      </React.Fragment>
    )
  }
}

NumericMultiSettings.propTypes = {
  question: PropTypes.shape({
    valueMin: PropTypes.number.isRequired,
    valueMax: PropTypes.number.isRequired,
    decimals: PropTypes.number.isRequired,
    summaryMax: PropTypes.number.isRequired,
    summaryVisibility: PropTypes.bool.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    questionSettingsPlan: state.user.userPlan.plan.survey.questionSettings
  }
}

export default connect(mapStateToProps)(NumericMultiSettings);
