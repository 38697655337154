import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardBody, CardHeader, FormGroup, Input, InputGroup, InputGroupAddon, Label} from "reactstrap";
import api from "api";
import CollectorsToAccept from "./components/CollectorsToAccept";
import LoadingSpinner from "components/LoadingSpinner";
import Switch from "../../../components/Switch";
import AcceptMulti from "./components/AcceptMulti";


class SurveyCollectorWaitingAccept extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      status: 3,
      loading: false,
      collectors: [],
      q: '',
    };

    this.fetchCollectors = this.fetchCollectors.bind(this);
  }

  componentDidMount() {
    this.fetchCollectors();
  }

  fetchCollectors(){
    this.setState({
      loading: true
    });

    let apiFn;
    if(this.state.status === 3){
      apiFn = api.admin.surveyCollector.get.reportedAccept;
    }else if(this.state.status === 4){
      apiFn = api.admin.surveyCollector.get.waitingAccept;
    }

    apiFn({
      q: this.state.q,
    })
    .then(data => {
      this.setState({
        loading: false,
        collectors: data.collectors,
      })
    })
    ;
  }

  handlerOnDone(id){
    const {collectors} = this.state;

    this.setState({
      collectors: collectors.filter(collector => collector.id !== id)
    }, () => {
      if(this.state.collectors.length === 0){
        this.fetchCollectors();
      }
    })
  }

  render() {
    const {collectors, loading, status} = this.state;

    return (
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <h2>Ankiety oczekujące na akceptacje ({loading ? <span>...</span> : collectors.length})</h2>

            <div className="d-flex flex-row align-items-center flex-nowrap">
              <AcceptMulti collectors={collectors} onSuccess={() => this.fetchCollectors()} />
              <FormGroup className="d-flex flex-nowrap flex-row ml-2" style={{width: '200px'}}>
                <Switch onChange={value => this.setState({status: value ? 4 : 3}, () => this.fetchCollectors())} checked={status === 4} />
                <Label className="ml-2">Pokaż ankiety z poczekalni</Label>
              </FormGroup>
              <InputGroup style={{width: '180px'}}>
                <Input placeholder={"Szukaj..."} onChange={e => this.setState({q: e.target.value})} value={this.state.q} />
                <InputGroupAddon addonType={'append'}>
                  <Button onClick={() => this.fetchCollectors()}><i className="fas fa-search" /></Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          {loading ? <LoadingSpinner /> : <CollectorsToAccept
            collectors={collectors}
            onDone={id => this.handlerOnDone(id)}
          />}

          {!loading && collectors.length === 0 && <React.Fragment><p className="w-100 text-center">Brak ankiet oczekujących na akceptacje</p><Button onClick={() => this.fetchCollectors()} color="warning" className="mx-auto d-block">Odśwież</Button></React.Fragment>}
        </CardBody>
      </Card>
    );
  }
}

SurveyCollectorWaitingAccept.propTypes = {};

export default SurveyCollectorWaitingAccept;