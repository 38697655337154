import {SET, UPDATED, ADDED, BAN_UPDATED} from './actionTypes';
import _ from "lodash";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.users;

    case UPDATED:
      let user = _.find(state, user => user.id === action.user.id);
      state[state.indexOf(user)] = {...user, ...action.user};
      return state;

    case BAN_UPDATED:
      let banUser = _.find(state, user => user.ban && user.ban.id === action.ban.id);
      state[state.indexOf(banUser)].ban = action.ban;
      return state;

    case ADDED:
      state.push(action.user);
      return state;

    default:
      return state;
  }
};