import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import api from "api";
import {ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";
import {toast} from "react-toastify";

class ImportTokens extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      saving: false,
      file: null,
    };

    this.fileUpload = this.fileUpload.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
  }

  handleUploadFile(e) {
    this.setState({
      file: e.target.files[0]
    });
  }

  fileUpload() {
    const {file} = this.state;
    const formData = new FormData();
    formData.append('file', file);
    this.setState({saving: true});

    api.surveyCollector.tokens.post.tokensWithFields(this.props.collector.id, formData)
    .then((data) => {
      this.setState({saving: false, isOpen: false});
      this.props.handleAddTokens(data.tokens);
    })
    .catch((e) => {
      this.setState({
        saving: false
      });

      _.map(error.response.data.errors, err => {
        toast.error(err.message);
      });
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.fileUpload();
  }

  render() {
    const {isOpen, saving, number} = this.state;

    const modal = <Modal isOpen={isOpen} toggle={() => this.setState({isOpen: false})}>
      <ModalHeader toggle={() => this.setState({isOpen: false})} tag="h2">Wgraj tokeny z danymi</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Alert color="info">Wgraj plik csv oddzielony średnikami w których kolumny będą zawierały dane które chcesz przypisać. Dla każdego wiersza zostanie utworzony indywidualny token.</Alert>
        <Form id="redirects-import-csv-form" onSubmit={(e) => this.onFormSubmit(e)} className="d-flex justify-content-center">
          <Input
            name="csv"
            type="file"
            placeholder="Wgraj plik csv"
            onChange={this.handleUploadFile}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
       <LaddaButton
        loading={saving}
        className="btn btn-primary btn-ladda"
        data-style={ZOOM_OUT}
        disabled={number < 1}
        onClick={this.fileUpload}
       >
        <i className="fas fa-plus" /> <FormattedMessage id="_.button.add"/>
      </LaddaButton>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.setState({isOpen: false})}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return <React.Fragment>
      <Button color="primary" className="mb-2 mr-2" onClick={() => this.setState({isOpen: true})}>Wgraj z danymi</Button>
      {modal}
    </React.Fragment>

  }
}

ImportTokens.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  handleAddTokens: PropTypes.func.isRequired
};

export default ImportTokens;