import React, {Component, useEffect} from 'react';
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import moment from "moment";
import api from "api";
import PropTypes from "prop-types";


function UserLoginsList(props) {
  const {search, success, dateFrom, dateTo} = props;

  const [searching, setSearching] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(250);
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    fetchData();
  }, [search, success, dateTo, dateFrom])

  const fetchData = (fetchPage) => {
    setSearching(true);

    const data = {
      page: typeof fetchPage !== "undefined" ? (fetchPage + 1) : (page + 1),
      pageSize: pageSize,
      filters: {
        search: search,
        success, success,
        dateTo: dateTo && moment(dateTo).format('YYYY-MM-DD 23:59:59'),
        dateFrom: dateFrom && moment(dateFrom).format('YYYY-MM-DD 00:00:00'),
      }
    };

    api.user.post.searchWebApplicationLogin(data).then(res => {
      setSearching(false);
      setData(res.data);
      setCount(res.count);
    });
  }

  const columns = [{
    Header: 'Email',
    accessor: 'username'
  }, {
    Header: 'IP',
    accessor: 'ip'
  }, {
    Header: 'Data',
    accessor: 'createdAt',
  }, {
    Header: 'Poprawne',
    id: 'valid',
    accessor: row => row.isSuccessfull ? <span className="badge badge-success">Tak</span> : <span className="badge badge-danger">Nie</span>,
  }, {
    Header: 'Strona',
    accessor: 'site',
  }];

  return <div>
    <ReactTable
      minRows={0}
      className={'table-wrap'}
      data={data}
      columns={columns}
      defaultPageSize={250}
      showPagination={!_.isEmpty(data)}
      PaginationComponent={Pagination}
      loading={searching}
      onFetchData={(data) => {
        setPage(data.page);
        setPageSize(data.pageSize);
        fetchData(data.page)
      }}
      rowsCount={count}
      manual
    />
  </div>;
}

UserLoginsList.propTypes = {
  search: PropTypes.string.isRequired,
};

export default UserLoginsList;