import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import moment from "moment/moment";
import {surveyLink} from "services/surveyLink";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import CollectorsActions from "scenes/Survey/Collectors/components/CollectorsActions";
import CollectorsButtonPaused from "scenes/Survey/Collectors/components/CollectorsButtonPaused";
import ProgressBar from "components/ProgressBar";
import PropTypes from "prop-types";

class ListItems extends Component {

  constructor(props) {
    super(props);

    this.status = this.status.bind(this);
  }

  static guid(collector){
    return <a href={surveyLink(collector.guid)} target="_blank">{collector.guid}</a> ;
  }

  static endAt(collector){
    return moment(collector.endAt).format('YYYY-MM-DD');
  }

  static createdAt(collector){
    return moment(collector.createdAt).format('YYYY-MM-DD HH:mm');
  }

  static responsesCountFormatter(collector){
    let content = collector.endResponsesCount;

    if (collector.screenOutResponsesCount > 0 || collector.quotaResponsesCount > 0)
      content += ' (+' + (collector.screenOutResponsesCount + collector.quotaResponsesCount) + ' odrzuconych)';

    return content;
  }

  status(collector) {
    const type = collector.paused ? 'danger' : 'success';
    const text = collector.paused ? <FormattedMessage id="_.label.paused"/> : <FormattedMessage id="_.label.active"/>;

    return <React.Fragment>
      <CollectorsButtonPaused paused={collector.paused} collector={collector} handleCollectorChange={this.props.handleCollectorChange}/>
      <label className={`label-status ${type} ml-2`}>{text}</label>
    </React.Fragment>
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="widgets.collectors.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      id: 'status',
      Header: <FormattedMessage id="widgets.collectors.listItems.tableHeader.status" />,
      accessor: collector => this.status(collector)
    }, {
      id: 'endAt',
      Header: <FormattedMessage id="widgets.collectors.listItems.tableHeader.endAt" />,
      accessor: collector => ListItems.endAt(collector)
    }, {
      Header: <FormattedMessage id="widgets.collectors.listItems.tableHeader.type" />,
      accessor: 'type'
    }, {
      id: 'guid',
      Header: <FormattedMessage id="widgets.collectors.listItems.tableHeader.link" />,
      accessor: collector => ListItems.guid(collector)
    }, {
      id: 'name',
      Header: <FormattedMessage id="widgets.collectors.listItems.tableHeader.name" />,
      accessor: collector => collector.name ||  <FormattedMessage id={"_.surveyCollector." + collector.type + '.name'}>{msg => <span>{msg} {collector.position}</span>}</FormattedMessage>,
    }, {
      id: 'responsesCount',
      Header: <FormattedMessage id="widgets.collectors.listItems.tableHeader.responses" />,
      accessor: collector => ListItems.responsesCountFormatter(collector)
    }, {
      id: 'progress',
      Header: <FormattedMessage id="widgets.collectors.listItems.tableHeader.progress" />,
      accessor: collector => {
        console.log('collector', collector);
        return <ProgressBar current={parseInt(collector.endResponsesCount)} max={parseInt(collector.collectorResponses)} id={collector.id}/>
      }
    }, {
      id: 'createdAt',
      Header: <FormattedMessage id="widgets.collectors.listItems.tableHeader.createdAt" />,
      accessor: collector => ListItems.createdAt(collector)
    }, {
      id: 'actions',
      Header: <FormattedMessage id="widgets.collectors.listItems.tableHeader.actions" />,
      accessor: collector => <CollectorsActions
        collector={collector}
        surveyId={parseInt(collector.survey.id)}
        handleCollectorChange={this.props.handleCollectorChange}
        handleCollectorDelete={this.props.handleCollectorDelete}
        handleCollectorCopy={this.props.handleCollectorCopy}
      />
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.props.collectors}
        columns={columns}
        showPagination={!_.isEmpty(this.props.collectors)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="widgets.collectors.listItems.table.noCollectors" />}
        loading={this.props.loading}
      />
    )
  }
}

ListItems.propTypes = {
  loading: PropTypes.bool.isRequired,
  collectors: PropTypes.array.isRequired,
  handleCollectorChange: PropTypes.func.isRequired,
  handleCollectorDelete: PropTypes.func.isRequired,
  handleCollectorCopy: PropTypes.func.isRequired,
};


export default ListItems;