import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import CompaniesList from './CompaniesList';
import CompanyCreateButton from './CompanyCreateButton';
import {Card, CardBody, CardHeader, Col, Input, InputGroup, Row} from "reactstrap";
import {connect} from "react-redux";
import qs from "query-string";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Link from "react-router-dom";
import api from "../../../../api";

class Companies extends Component {

  constructor(props) {
    super(props);

    const params = qs.parse(location.search);

    this.state = {
      search: "",
      expireDate: null,
      regAfter: null,
      regBefore: null,
      ref: 'ref' in params ?  params.ref : null,
      plan: undefined,
    };
    this.list = React.createRef();
    this.onSearch = this.onSearch.bind(this);
    this.onRef = this.onRef.bind(this);
    this.onExpireDate = this.onExpireDate.bind(this);
    this.onRegDateChange = this.onRegDateChange.bind(this);
  }

  onSearch(e) {
    this.setState({search: e.target.value})
  }

  onExpireDate(e) {
    this.setState({expireDate: e.target.value})
  }

  onRef(e) {
    this.setState({ref: e.target.value})
  }

  onRegDateChange(date, property) {
    this.setState({
      [property]: date
    })
  }

  render() {

    const planOptions = _.map(this.props.plans, plan => {
      let name = plan.name;
      if (plan.demo) {
        name += ' - demo';
      }
      return {label: name, value: plan.id}
    });

    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">
                    <FormattedMessage id="admin.companies.header" />
                  </h2>
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <InputGroup className="p-0 mb-0">
                    <Input className="p-0" placeholder="Znajdź użytkowników..." onChange={this.onSearch}/>
                    <span className="input-group-btn py-1">
                    <button className="btn" type="button"><i className="fas fa-search" /></button>
                  </span>
                  </InputGroup>
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <DatePicker
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                    dropdownMode="select"
                    placeholderText="Data rejestracji do"
                    selected={this.state.regBefore}
                    onChange={date => this.onRegDateChange(date, 'regBefore')}
                  />
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <DatePicker
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                    dropdownMode="select"
                    placeholderText="Data rejestracji od"
                    selected={this.state.regAfter}
                    onChange={date => this.onRegDateChange(date, 'regAfter')}
                  />
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <InputGroup className="p-0 mb-0">
                    <Input className="p-0" placeholder="Id użytkownika polecającego" onChange={this.onRef} value={this.state.ref}/>
                    <span className="input-group-btn py-1">
                    <button className="btn" type="button"><i className="fas fa-search" /></button>
                  </span>
                  </InputGroup>
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1">
                  <InputGroup className="p-0 mb-0">
                    <Input className="p-0" placeholder="Liczba dni aktywnego planu" onChange={this.onExpireDate} value={this.state.expireDate}/>
                    <span className="input-group-btn py-1">
                    <button className="btn" type="button"><i className="fas fa-search" /></button>
                  </span>
                  </InputGroup>
                </div>
                <div className="custom-search-input w-lg-100 float-right mt-1" style={{width: '250px'}}>
                  <Select
                    className="w-100"
                    placeholder="Wybierz plan"
                    isClearable={false}
                    options={planOptions}
                    onChange={option => {
                      this.setState({
                        plan: option ? option.value : undefined
                      })
                    }}
                  />
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <div className="d-flex justify-content-between">
                <CompanyCreateButton loading={this.props.loadingPlans} handleChange={() => this.list.current.fetchData({page: this.list.current.state.page, pageSize: this.list.current.state.pageSize, sorted: this.list.current.state.sorted})}/>

                <a href={api.user.post.getCsvCompanies({
                  plan: this.state.plan,
                  search: this.state.search,
                  regBefore: this.state.regBefore,
                  regAfter: this.state.regAfter,
                  refUser: this.state.ref,
                })}>Pobierz listę</a>
              </div>
              <Row>
                <Col>
                  <CompaniesList ref={this.list} expireDate={this.state.expireDate} plan={this.state.plan} search={this.state.search} regBefore={this.state.regBefore} regAfter={this.state.regAfter} refUser={this.state.ref}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    loadingPlans: state.admin.plans.loading,
    plans: state.admin.plans.data.plans,
  }
}

export default connect(mapStateToProps)(Companies);