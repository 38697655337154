import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

import api from "api";
import history from "services/history";
import appRoutes from "appRoutes";

class SettingsDeleteButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      deleting: false
    };

    this.deleteAnalysis = this.deleteAnalysis.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({modal: !this.state.modal})
  }


  deleteAnalysis(){
    this.setState({deleting: true});
    const analyses = this.props.analyses.filter(analysis => analysis.id !== this.props.analysis.id);

    api.analysis.delete.analysis(this.props.analysis.id).then(() => {
      this.setState({deleting: false, modal: false});
      history.push(appRoutes.survey.analysis.questionSummaries(this.props.analysis.survey, analyses[0].id));
    }).catch(() => this.setState({deleting: false}));
  }

  render() {
    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="sidebar.settings.deleteButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="sidebar.settings.deleteButton.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.modal.delete.confirmation">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            data-style={ZOOM_OUT}
            onClick={this.deleteAnalysis}
            loading={this.state.deleting}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>

      </ModalFooter>
    </Modal>;

    return <React.Fragment>
      <Button color="danger" size="sm" className="float-right" onClick={this.toggleModal}><FormattedMessage id="_.button.delete"/></Button>
      {modal}
    </React.Fragment>
  }
}

SettingsDeleteButton.propTypes = {
  analysis: PropTypes.object.isRequired,
  analyses: PropTypes.array.isRequired
};

export default SettingsDeleteButton;