import api from "api";

export const REQUEST = 'Survey/data/filterSerial/REQUEST';
export const SUCCESS = 'Survey/data/filterSerial/SUCCESS';
export const ERROR = 'Survey/data/filterSerial/ERROR';

export const SET = 'Survey/data/filterSerial/SET';
export const ADDED = 'Survey/data/filterSerial/ADDED';
export const UPDATED = 'Survey/data/filterSerial/UPDATED';
export const DELETED = 'Survey/data/filterSerial/DELETED';

export function setFilterSerial(filterSerial) {
  return {
    type: SET,
    filterSerial
  }
}

export function filterSerialAdded(filterSerial) {
  return {
    type: ADDED,
    filterSerial
  }
}

export function filterSerialUpdated(filterSerial) {
  return {
    type: UPDATED,
    filterSerial
  }
}

export function filterSerialDeleted(filterSerial) {
  return {
    type: DELETED,
    filterSerial
  }
}

export function addFilterSerial(data) {
  return dispatch => {
    return api.filterSerial.post(data).then(filterSerial => {
      dispatch(filterSerialAdded(filterSerial))
    })
  }
}

export function copyFilterSerial(id) {
  return dispatch => {
    return api.filterSerial.copy(id).then(filterSerial => {
      dispatch(filterSerialAdded(filterSerial))
    })
  }
}

export function updateFilterSerial(data) {
  return dispatch => {
    return api.filterSerial.patch(data).then(filterSerial => {
      dispatch(filterSerialUpdated(filterSerial));
    })
  }
}

export function deleteFilterSerial(data) {
  return dispatch => {
    return api.filterSerial.delete(data).then(() => {
      dispatch(filterSerialDeleted({
        id: data.id,
      }))
    })
  }
}