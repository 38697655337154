import React, {Component} from 'react';
import {connect} from "react-redux";
import {ListGroup} from "reactstrap";
import {addQuestionFromTemplate} from "scenes/Survey/Structure/data/questions/actions";
import {ReactSortable} from "react-sortablejs";
import _ from 'lodash';
import PropTypes from 'prop-types';
import GlobalTemplatesModal from '../Templates/components/TemplatesModal';
import striptags from "striptags";
import QuestionInfo from "components/QuestionInfo";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import classnames from "classnames";
import GlobalQuestionTemplatesAvailable from "components/PlanRestrictionsModals/modals/GlobalQuestionTemplatesAvailable";
import OnMoving from "../OnMoving";
import OnAdding from "../OnAdding";
import {toggleQuestionCard} from "scenes/Survey/Structure/actions"

class GlobalTemplates extends Component {

  constructor(props) {
    super(props);

    this.state = {
      useTemplate: false,
      adding: false,
    };

    this.onEndQuestions = this.onEndQuestions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.shouldRenderTemplate = this.shouldRenderTemplate.bind(this);
  }

  onChange(template) {
    this.setState({...this.state, useTemplate: template})
  }

  onEndQuestions(evt, templateId){
    if(evt.from === evt.to){
      return false;
    }

    this.setState({
      adding: true,
    });

    const page = this.props.pages[parseInt(evt.to.parentElement.firstChild.attributes['data-id'].nodeValue)];

    this.props.addQuestionFromTemplate({
      templates: [templateId],
      page: page.id,
      position: evt.newIndex
    }).then(questions => {
      if (this.props.hideInStructure === 'questions' || this.props.hideInStructure === 'structure' ) {
        questions.map(question => this.props.toggleQuestionCard(question.id));
      }
      evt.item.remove();
    })
  }

  shouldRenderTemplate(template) {
    if (typeof this.props.searchText !== 'string')
      return true;

    const searchingText = this.props.searchText.toLowerCase();

    return template.content.toLowerCase().includes(searchingText) || template.type.includes(searchingText);
  }

  render() {
    const {templates, surveyPlan} = this.props;
    const {adding} = this.state;

    if(adding){
      this.setState({
        adding: false,
      });

      return null;
    }

    return (
      <React.Fragment>
        {_.map(templates, template => this.shouldRenderTemplate(template) && <ListGroup className="nav-dropdown-items" key={template.id}>
          <div onClick={() => surveyPlan.globalQuestionTemplates ? this.onChange(template) : this.props.setPlanRestrictionsModals(GlobalQuestionTemplatesAvailable.getName())}>
            <ReactSortable
              list={[{}]}
              setList={() => {}}
              group={{
                name: 'questions',
                pull: 'clone',
                put: false,
                revertClone: true,
              }}
              sort={false}
              onEnd={(e) => this.onEndQuestions(e, template.id)}
              filter=".sortable-ignore"
            >
              <div role="question" className={classnames({
                'line-wrap py-1 d-flex align-items-center': true,
                'not-active sortable-ignore': !surveyPlan.globalQuestionTemplates,
              })}>
                <a className="fas fa-grip-vertical dragg float-left mr-3 mt-0" />
                <ul className="mb-0 pl-0 d-flex align-items-center">
                  <li className="float-left mr-2">
                    <QuestionInfo question={template} />
                  </li>
                  <li className="overflow-hidden float-left w-100">
                    <h5 className="mb-0">{striptags(template.content)}</h5>
                  </li>
                </ul>
                <OnMoving/>
                <OnAdding/>
              </div>
            </ReactSortable>
          </div>
        </ListGroup>)}
        {this.state.useTemplate && <GlobalTemplatesModal questionTemplates={[this.state.useTemplate.id]} toggleModal={() => this.onChange(null)}/>}
      </React.Fragment>
    )
  }
}

GlobalTemplates.propTypes = {
  templates: PropTypes.object.isRequired,
  setPlanRestrictionsModals: PropTypes.func.isRequired,
  surveyPlan: PropTypes.shape({
    globalQuestionTemplates: PropTypes.bool.isRequired,
  }),
  searchText: PropTypes.string
};

function mapStateToProps(state) {
  return {
    pages: state.survey.structure.data.pages,
    surveyPlan: state.user.userPlan.plan.survey,
    templates: state.globalTemplates,
    hideInStructure: state.survey.structure.hideInStructure
  }
}

export default connect(mapStateToProps, {addQuestionFromTemplate, setPlanRestrictionsModals, toggleQuestionCard})(GlobalTemplates);