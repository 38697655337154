import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {fetchPlans} from "../../Plans/actions";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import RespondentsActions from './RespondentsActions';
import PropTypes from 'prop-types';
import api from 'api';
import moment from 'moment';
import Checkbox from "components/Checkbox";
import UserBulkBanModal from "./UserBulkBanModal";
import UserBulkUnbanModal from "./UserBulkUnbanModal";
import {Badge, Button} from 'reactstrap';
import Tooltip from "../../../../components/Tooltip";
import UsersDeleteModal from "./UsersDeleteModal";

class RespondentsList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      shiftPressed: false,
      searching: false,
      data: [],
      checked: [],
      banCategories: [],
      banUser: null,
      unbanUser: null,
      banModal: false,
      unbanModal: false,
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.table = React.createRef();
    this.toggleChecked = this.toggleChecked.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleBan = this.toggleBan.bind(this);
    this.toggleUnban = this.toggleUnban.bind(this);
    this.onPageToggleCheck = this.onPageToggleCheck.bind(this);
    this.isPageFullyChecked = this.isPageFullyChecked.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount(){
    document.addEventListener('keydown', e => {
      if(e.key === 'Shift'){
        this.setState({
          shiftPressed: true,
        })
      }
    });
    document.addEventListener('keyup', e => {
      if(e.key === 'Shift'){
        this.setState({
          shiftPressed: false,
        })
      }
    });

    this.props.fetchPlans();
    api.ban.get.categories().then(categories => {
      this.setState({banCategories: categories});
    })
  }

  componentWillReceiveProps(nextProps) {
    const filtersWereChanged = this.props.search !== nextProps.search || this.props.regBefore !== nextProps.regBefore || this.props.regAfter !== nextProps.regAfter || this.props.refUser !== nextProps.refUser || this.props.onlyBanned !== nextProps.onlyBanned || this.props.banCategory !== nextProps.banCategory;
    if (filtersWereChanged) {
      clearTimeout(this.state.searchTimeout);
      this.setState({
        searchTimeout: setTimeout(() => {
          this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, nextProps);
        }, 1000)
      });
    }
  }

  toggle(property) {
    if (this.state[property]) {
      this.setState({[property]: !this.state[property], banUser: null, unbanUser: null});

    } else {
      this.setState({[property]: !this.state[property]});
    }
  }

  toggleSelectAll(){
    const {checked, data} = this.state;

    if(checked.length === data.length){
      this.setState({
        checked: [],
      });
    }else{
      this.setState({
        checked: data.map(d => d.id),
      });
    }
  }

  toggleChecked(id) {
    const {shiftPressed} = this.state;
    const tableRows = this.getCurrentRows();

    let checked = [];
    if(shiftPressed && this.state.checked.length > 0){
      const idStart = this.state.checked[this.state.checked.length-1];

      let adding = false;
      checked = _.merge(this.state.checked, tableRows.filter(_id => {
        if(_id === idStart){
          adding = true;
        }
        if(_id === id){
          adding = false;
        }


        return adding;
      }), [id]);
    }else{
      checked = _.xor(this.state.checked, [id]);
    }

    this.setState({checked});
  }

  toggleBan(userId) {
    this.setState({banModal: true, banUser: [userId]})
  }

  toggleUnban(userId) {
    this.setState({unbanModal: true, unbanUser: [userId]})
  }

  onPageToggleCheck() {
    let checked = this.getCurrentRows();

    if (JSON.stringify(checked) === JSON.stringify(this.state.checked)) {
      checked = []
    }

    this.setState({checked})
  }

  isPageFullyChecked() {
    if (!this.table) {
      return false;
    }

    return this.getCurrentRows().length === this.state.checked.length;
  }

  getCurrentRows() {
    if (this.table.current) {
      const pageSize = this.table.current.state.pageSize;
      const page = this.table.current.state.page;

      const currentRows = this.state.data.slice(page * pageSize, page * pageSize + pageSize);

      return _.map(currentRows, row => row.id);
    }

    return [];
  }

  refresh() {
    this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, this.props)
  }

  fetchData(state, props) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted,
        search: props.search,
        ref: props.refUser,
        onlyBanned: props.onlyBanned,
        banCategory: props.banCategory,
        regBefore: props.regBefore && moment(props.regBefore).format('YYYY-MM-DD 23:59:59'),
        regAfter: props.regAfter && moment(props.regAfter).format('YYYY-MM-DD 00:00:00'),
      }
    };

    api.user.post.searchRespondents(data).then(res => {
      this.setState({searching: false, loadedFirst: true, data: res.respondents, count: res.count});
    });
  }

  render() {
    const {userLogged} = this.props;

    const columns = [{
      Header: <Checkbox id="check" checked={this.isPageFullyChecked()} tooltip={false} onClick={this.onPageToggleCheck}/>,
      id: 'check',
      accessor: user => <Checkbox id="check" checked={this.state.checked.includes(user.id)} tooltip={false} onClick={() => this.toggleChecked(user.id)}/>,
      sortable: false,
      filterable: false
    }, {
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      id: 'refId',
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.refId" />,
      accessor: user => {
        if(!user.refId){
          return null;
        }
        return <Badge color={user.refVerified ? 'success' : 'warning'} className="border border-white" style={{fontSize: '1.5em'}}>{user.refId}</Badge>
      }
    }, {
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.username" />,
      accessor: 'username'
    }, {
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.ip" />,
      accessor: 'ip'
    },
    // {
    //   id: 'password',
    //   Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.password" />,
    //   accessor: user => {
    //     if(!userLogged.roles.includes('ROLE_SUPER_ADMIN')){
    //       return <i>Brak dostępu</i>
    //     }
    //
    //     if(user.plainPassword){
    //       return user.plainPassword;
    //     }
    //     if(user.passwordOldMd5){
    //       return user.passwordOldMd5;
    //     }
    //
    //     return <i>Brak hasła</i>;
    //   }
    // },
      {
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.email" />,
      accessor: 'email'
    }, {
      id: 'plan',
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.plan" />,
      accessor: user => (user.userPlan && user.userPlan.plan) ? user.userPlan.plan.name : <FormattedMessage id="admin.respondents.listItems.plan.noPlan" />,
      sortable: false
    }, {
      id: 'cashPoints',
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.cashPoints" />,
      accessor: user => _.find(user.pointTotal, points => points.type === 2).total
    }, {
      id: 'socialPoints',
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.socialPoints" />,
      accessor: user => _.find(user.pointTotal, points => points.type === 1).total
    }, {
      id: 'createdAt',
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.createdAt" />,
      accessor: user => moment(user.createdAt).format('YYYY-MM-DD HH:mm')
    }, {
      id: 'lastActivityAt',
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.lastActivityAt" />,
      accessor: user => user.lastActivityAt && moment(user.lastActivityAt).format('YYYY-MM-DD HH:mm')
    },
    {
      id: 'lastMobileActivityAt',
      Header: <FormattedMessage id="admin.respondents.listItems.tableHeader.lastMobileActivity" />,
      accessor: user => user.lastMobileActivity && moment(user.lastMobileActivity.createdAt).format('YYYY-MM-DD HH:mm')
    },
    {
      id: 'verified',
      Header: 'Zweryfikowany',
      accessor: user => {
        const id = 'user-' + user.id + '-verified';

        return <div>
          {user.verified ? <React.Fragment>
            <Badge color="success" id={id}>Tak</Badge>
            <Tooltip id={id} msg={user.verifiedMsg} />
          </React.Fragment> : 'Nie'}
        </div>
      }
    },
    {
      id: 'ban',
      Header: 'Ban',
      show: this.props.onlyBanned,
      width: 220,
      accessor: user => {
        if(!user.ban){
          return ''
        }
        return <div className="d-flex flex-column">
          <span><FormattedMessage id={`admin.respondents.ban.category-${user.ban.category}`} /></span>
          <span>{user.ban.comment || <i className="text-secondary">Brak komentarza</i> }</span>
        </div>
      }
    },
    {
      id: 'actions',
      Header: <FormattedMessage id="_.table.header.actions"/>,
      accessor: user => <RespondentsActions user={user} toggleBan={this.toggleBan} toggleUnban={this.toggleUnban} categories={this.state.banCategories} handleChange={this.refresh}/>,
      sortable: false
    }];

    return (
      <React.Fragment>
        <ReactTable
          minRows={0}
          className={'table-wrap'}
          data={this.state.data}
          columns={columns}
          showPagination={!_.isEmpty(this.state.data)}
          PaginationComponent={Pagination}
          noDataText={<FormattedMessage id="admin.respondents.listItems.table.noUsers" />}
          loading={this.state.searching}
          defaultPageSize={50}
          getTrGroupProps={(state, rowInfo) => {
            return {className: rowInfo.original.isBanned && "bg-danger"};
          }}
          ref={this.table}
          onPageChange={() => this.setState({checked: []})}
          onPageSizeChange={() => this.setState({checked: []})}
          onFetchData={(state, props) => this.fetchData(state, this.props, state.sorted)}
          rowsCount={this.state.count}
          manual
          onSortedChange={sorted => this.setState({sorted})}
        />
        <Button className="mr-2" color={'secondary'} onClick={() => this.toggleSelectAll()}>Zaznacz/Odznacz wszystko</Button>

        <UserBulkBanModal isOpen={this.state.banModal} toggle={() => this.toggle('banModal')} checked={this.state.banUser || this.state.checked} onUpdate={this.refresh} categories={this.state.banCategories}/>
        <UserBulkUnbanModal isOpen={this.state.unbanModal} toggle={() => this.toggle('unbanModal')} checked={this.state.unbanUser || this.state.checked} onUpdate={this.refresh} />

        {this.state.checked.length > 0 && <Button color="danger" onClick={() => this.toggle('banModal')}><FormattedMessage id="admin.respondents.userBulkBanModal.ban" /></Button>}
        {this.state.checked.length > 0 && <Button color="success" onClick={() => this.toggle('unbanModal')}><FormattedMessage id="admin.respondents.userBulkUnbanModal.unban" /></Button>}
        {(this.state.checked.length > 0 && userLogged.roles.includes('ROLE_SUPER_ADMIN')) && <UsersDeleteModal checked={this.state.checked} onDeleted={this.refresh} />}
      </React.Fragment>
    )
  }
}

RespondentsList.propTypes = {
  search: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    userLogged: state.user,
  }
}

export default connect(mapStateToProps, {fetchPlans}, null, {forwardRef: true})(RespondentsList);
