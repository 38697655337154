import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader} from "reactstrap";
import SurveyCollectorPanelRealizationAutomateTable
  from "../../componentsNew/Survey/SurveyCollectorPanelRealizationAutomateTable";

const SurveyCollectorPanelRealizationAutomate = props => {
  return (
    <main>
      <Card>
        <CardHeader><h2><i className="fas fa-dog-leashed mr-2" /> <span>Automatyczna realizacja</span></h2></CardHeader>
        <hr className="my-0" />
        <CardBody>
          <SurveyCollectorPanelRealizationAutomateTable
            survey={props.match.params.survey}
          />
        </CardBody>
      </Card>
    </main>
  );
};

SurveyCollectorPanelRealizationAutomate.propTypes = {

};

export default SurveyCollectorPanelRealizationAutomate;
