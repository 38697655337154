import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Row, Col, Form, FormGroup, Input, Label, Alert} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {updateEmail} from "reducers/user/actions";
import ChangeEmailCancelButton from "../../../../components/User/ChangeEmailCancelButton";

class ProfileFormEmailChange extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {
        email: props.user.email,
        password: '',
      },
      errors: {},
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(e){
    e.preventDefault();
    this.setState({ loading: true });

    this.props.updateEmail(this.state.data)
      .then(() => {
        this.setState({ loading: false, errors: {} })
      })
      .catch(error => {
        this.setState({ loading: false, errors: error.response.data.form.errors });
      });
  }

  onChange(e){
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  }

  render() {
    const {data, errors, loading} = this.state;
    const {user} = this.props;

    return <div className="py-4">
      {!!user.isDuringEmailChange && <Alert color="info" className="text-center">Oczekiwanie na potwierdzenie zmiany adresu e-mail. <ChangeEmailCancelButton /></Alert>}

      {!user.isDuringEmailChange && <Form onSubmit={this.onSubmit} className="needs-validation">
        <h1 className="text-center">Zmiana adresu e-mail</h1>
        <Row>
          <Col md={3}>
            <Label data-role="label" for="input-type-1"><FormattedMessage id="profile.form.emailChange.label.email" /></Label>
          </Col>
          <Col md={9}>
            <FormattedMessage id="profile.form.emailChange.label.email">{
              (message) =>
                <Input type="text" id="email" name="email" className="custom" placeholder={message} value={data.email} invalid={!!errors.email} onChange={this.onChange}/>
            }</FormattedMessage>
            {errors.email && <div className="invalid-feedback">{errors.email.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Label data-role="label" for="input-type-1"><FormattedMessage id="profile.form.emailChange.label.password" /></Label>
          </Col>
          <Col md={9}>
            <FormattedMessage id="profile.form.emailChange.label.password">{
              (message) =>
                <Input type="password" id="password" name="password" className="custom" placeholder={message} invalid={!!errors.password} value={data.password} onChange={this.onChange}/>
            }</FormattedMessage>
            {errors.password && <div className="invalid-feedback">{errors.password.join('; ')}</div>}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormattedMessage id="_.button.save">
              {(message) => <LaddaButton
                className="btn btn-success float-right"
                loading={loading}
                data-style={ZOOM_OUT}
              >
                {message}
              </LaddaButton>}
            </FormattedMessage>
          </Col>
        </Row>
      </Form>}
    </div>
  }
}

ProfileFormEmailChange.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, {updateEmail})(ProfileFormEmailChange);
