import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {ButtonGroup, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {connect} from "react-redux";
import Select from "react-select";
import Button from "../../../components/Button";
import {FormattedMessage} from "react-intl";
import ButtonCreateSurvey from "../../../components/ButtonCreateSurvey";
import {updateProfile} from "../../../reducers/user/actions";
import DatePicker from "react-datepicker";
import moment from "moment";
import SurveyTags from "../../../components/Survey/SurveyTags";

const MobileView = props => {
  const createSurveyButtonRef = useRef();
  const [searchVisible, setSearchVisible] = React.useState(false);
  const [filtersVisible, setFiltersVisible] = React.useState(false);
  const [showSurveysCategories, setShowSurveysCategories] = React.useState(props.user.surveysCategoriesVisible);
  const [showEmpty, setShowEmpty] = React.useState(false);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) { isInitialMount.current = false; } else { props.onChangeShowSurveysCategories(showSurveysCategories) }
  }, [showSurveysCategories]);
  useEffect(() => {
    if (isInitialMount.current) { isInitialMount.current = false; } else { props.onChangeShowEmpty(showEmpty) }
  }, [showEmpty]);

  return (
    <React.Fragment>
      <ButtonCreateSurvey classnames="d-none" ref={createSurveyButtonRef}/>

      <div className="mobile-bottom-nav d-block d-md-none" style={{height: '56px', zIndex: '1030'}}>
        <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between text-center h-100" style={{fontSize: '1.35em'}}>
          {props.plan.surveysCategories && <div className="flex-grow-1" onClick={() => {
            setShowSurveysCategories(!showSurveysCategories);

            const formData = new FormData();
            formData.append('surveysCategoriesVisible', !showSurveysCategories);
            props.updateProfile(formData);
          }}><i className={classnames({'fas fa-folder-open': true, 'text-primary': showSurveysCategories})}/></div>}
          {showSurveysCategories && <div className="flex-grow-1" onClick={() => setShowEmpty(!showEmpty)}><i className={classnames({'far fa-folder-open': true, 'text-primary': showEmpty})}/></div>}
          <div className="flex-grow-1" onClick={() => document.getElementById('surveys-categories-list').scrollTop = 0}><i className="fas fa-home" /></div>
          <div className={classnames({
            'flex-grow-1': true,
            'text-primary': filtersVisible,
          })} onClick={() => setFiltersVisible(!filtersVisible)}><i className="fas fa-filter" /></div>
          <div className="flex-grow-1" onClick={() => createSurveyButtonRef.current.toggleModal()}><i className="fas fa-plus" /></div>
          <div className={classnames({
            'flex-grow-1': true,
            'text-primary': searchVisible,
          })} onClick={() => setSearchVisible(!searchVisible)}><i className="fas fa-search" /></div>
          <div className={classnames({
            'flex-grow-1': true,
            'text-primary': props.withReports,
          })} onClick={() => props.onChangeWithReports(!props.withReports)}><i className="fas fa-exclamation-triangle" /></div>
        </div>
      </div>
      <div className="bg-white" style={{position: 'fixed', left: '0', width: '100%', bottom: '56px', zIndex: '1030'}}>
        {filtersVisible && <div className="d-flex flex-column">
          <ButtonGroup className="w-100" style={{zIndex: '1'}}>
            <Button color={!props.activity ? "primary" : "secondary"} className="ml-0" onClick={() => props.onChangeActivity(false)}><FormattedMessage id="surveys.button.filter.activity.all"/></Button>
            <Button color={props.activity === 'active' ? "primary" : "secondary"} className="ml-0" onClick={() => props.onChangeActivity('active')}><FormattedMessage id="surveys.button.filter.activity.active"/></Button>
            <Button color={props.activity === 'paused' ? "primary" : "secondary"} className="ml-0" onClick={() => props.onChangeActivity('paused')}><FormattedMessage id="surveys.button.filter.activity.paused"/></Button>
          </ButtonGroup>

          <div className="d-flex flex-row flex-nowrap" style={{zIndex: '1'}} >
            <ButtonGroup className="w-100">
              <Button color={props.favorite ? "secondary" : "primary"} className="ml-0" onClick={() => props.onChangeFavorite(false)}><FormattedMessage id="surveys.button.filter.favorite.false"/></Button>
              <Button color={props.favorite ? "primary" : "secondary"} className="ml-0" onClick={() => props.onChangeFavorite(true)}><FormattedMessage id="surveys.button.filter.favorite.true"/></Button>
            </ButtonGroup>
          </div>

          <div>
            <SurveyTags isUser={true} onChange={props.onChangeSurveyTags} />
          </div>
            {Object.keys(props.clients).length > 0 && <div>
              <Select
                className="flex-grow-1"
                menuPlacement={'top'}
                value={props.client && {label: props.clients[props.client].name, value: props.client}}
                options={_.map(props.clients, client => {
                  return {label: client.name, value: client.id}
                })}
                onChange={option => {
                  props.onChangeClient(option);
                }}
                isClearable
                placeholder="Wybierz klienta"
              />
            </div>}
            {Object.keys(props.groups.data.groups) > 0 && <Select
              className="flex-grow-1"
              isMulti={true}
              menuPlacement={'top'}
              value={_.map(props.assignedTo, assigned => {
                const user = _.find(props.groupsUsers, user => parseInt(user.userId) === assigned);
                return {
                  label: user.email,
                  value: assigned
                }
              })}
              options={_.map(props.groupsUsers, user => {
                return {label: user.email, value: parseInt(user.userId)}
              })}
              onChange={options => props.onChangeAssignedTo(options ? options.map(option => option) : [])}
              placeholder="Wybierz osoby"
            />}
          <div className="row">
            <div className="col pr-0">
              <DatePicker
                  dropdownMode="select"
                  className="form-control w-100 mb-0"
                  isClearable={true}
                  selected={props.dateFrom ? moment(props.dateFrom) : null}
                  onChange={props.onChangeDateFrom}
                  dateFormat="Y-MM-DD"
                  placeholderText="Data stworzenia od"
              />
            </div>
            <div className="col pl-0">
              <DatePicker
                  dropdownMode="select"
                  className="form-control w-100 mb-0"
                  isClearable={true}
                  selected={props.dateTo ? moment(props.dateTo) : null}
                  onChange={props.onChangeDateTo}
                  dateFormat="Y-MM-DD"
                  placeholderText="Data stworzenia do"
              />
            </div>
          </div>
        </div>}
        {searchVisible && <InputGroup className="mb-0 w-100">
          <Input
            autoFocus
            placeholder="Wyszukaj..."
            onChange={e => props.onChangeQuery(e.target.value)}
            value={props.query}
          />
          <InputGroupAddon addonType={'append'}><InputGroupText><i className="fas fa-search" /></InputGroupText></InputGroupAddon>
        </InputGroup>}
      </div>
    </React.Fragment>
  );
};

MobileView.propTypes = {
  onChangeQuery: PropTypes.func.isRequired,
  onChangeClient: PropTypes.func.isRequired,
  onChangeAssignedTo: PropTypes.func.isRequired,
  onChangeFavorite: PropTypes.func.isRequired,
  onChangeActivity: PropTypes.func.isRequired,
  onChangeWithReports: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onChangeShowEmpty: PropTypes.func.isRequired,
  onChangeShowSurveysCategories: PropTypes.func.isRequired,
  onChangeDateFrom: PropTypes.func.isRequired,
  onChangeDateTo: PropTypes.func.isRequired,
  onChangeSurveyTags: PropTypes.func.isRequired,
  dateFrom: PropTypes.any.isRequired,
  dateTo: PropTypes.any.isRequired,
  query: PropTypes.string.isRequired,
  activity: PropTypes.any.isRequired,
  withReports: PropTypes.any.isRequired,
  favorite: PropTypes.any.isRequired,
  client: PropTypes.any.isRequired,
  assignedTo: PropTypes.array.isRequired,
  surveyTags: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  let groupsUsers = [];

  _.each(state.groups.data.groups, group => {
    group.user.userId = group.user.id;
    groupsUsers.push(group.user);
    _.each(group.users, user => {
      groupsUsers.push(user);
    })
  });

  return {
    user: state.user,
    plan: state.user.userPlan.plan,
    groups: state.groups,
    clients: state.clients.data.clients,
    groupsUsers: groupsUsers,
  }
}

export default connect(mapStateToProps, {updateProfile})(MobileView);