import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import HelpModeIconTooltip from "components/HelpModeIconTooltip";
import {Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row} from "reactstrap";

class Tokens extends PureComponent {
  render() {
    const {collector} = this.props;

    return <React.Fragment>
      <HelpModeIconTooltip className="mb-2">Statystyki tego kolektora</HelpModeIconTooltip>
      <Row>
        <Col className="d-flex align-items-md-stretch">
          <Card className="w-100 border-l orange">
            <CardHeader><h2>Tokeny</h2></CardHeader>
            <hr className="my-0" />
            <CardBody>
              <ListGroup>
                <ListGroupItem>Liczba: {collector.tokensCount}</ListGroupItem>
                <ListGroupItem>Użytych: {collector.usedTokensCount}</ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  }
}

Tokens.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default Tokens;