import React, {Component} from 'react';
import PlansTable from "./PlansTable";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Alert, Card} from "reactstrap";
import CardHeader from "reactstrap/es/CardHeader";
import CardBody from "reactstrap/es/CardBody";
import api from "../../../api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import history from "services/history";
import appRoutes from "appRoutes";
import {updateUserPlan} from "../../../reducers/user/actions";


class DemoPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      plans: [],
    };

    this.handleChoosePlan = this.handleChoosePlan.bind(this);
  }

  componentDidMount(){
    api.plan.get.plans(1).then(plans => {
      this.setState({
        plans
      });
    });
  }

  handleChoosePlan(plan){
    this.props.updateUserPlan({plan})
      .then(res => {
        history.push(appRoutes.surveys.list);
      });
  }

  render() {
    const {plans, saving} = this.state;
    const {user} = this.props;

    if(_.isEmpty(plans)){
      return <LoadingSpinner />
    }

    return (
      <Card>
        <CardHeader><FormattedMessage tagName="h2" id="plan.cardHeader"/></CardHeader>
        <hr className="my-0" />
        <CardBody>
          <Alert className="text-center mb-3" color="info">
            {user.userPlan ?
              <span>Zmień plan który testujesz</span>
              :
              <span>Wybierz jeden z poniższych planów i testuj go przez 14 dni za darmo</span>
            }
          </Alert>
          <PlansTable plans={plans} bestseller={plans[1].id} onChoosePlan={this.handleChoosePlan} />
        </CardBody>
      </Card>
    )
  }
}

DemoPlans.propTypes = {
  user: PropTypes.shape({
    userPlan: PropTypes.shape({
      expireDate: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, {updateUserPlan})(DemoPlans);