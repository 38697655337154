import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";

class QuestionSummaryGMap extends Component {

  render() {
    const {questionSummary} = this.props;
    let dataExists = !_.isEmpty(questionSummary.data.data);

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <Chart id={questionSummary.question.id} type={questionSummary.chartType} n={questionSummary.data.n} data={questionSummary.data}/>
    )
  }
}

export default QuestionSummaryGMap;