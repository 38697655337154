import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Alert, Card, CardHeader, CardBody, CardFooter} from "reactstrap";
import Attribute from "./components/Attribute";
import CreateAttribute from "./components/CreateAttribute";
import "./style.scss";

class Attributes extends Component {

  render() {
    const {attributes} = this.props;

    return (
      <Card id="question-attributes">
        <CardHeader><h2>Lista atrybutów</h2></CardHeader>
        <CardBody>
          {attributes.length === 0 && <Alert color="warning" className="text-center">Brak utworzonych atrybutów</Alert>}
          {attributes.map(attribute => {

            return <Attribute key={attribute.id} attribute={attribute} />
          })}
        </CardBody>
        <CardFooter>
          <CreateAttribute question={this.props.question} />
        </CardFooter>
      </Card>
    );
  }
}

Attributes.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),),
};

function mapStateToProps(state, props) {
  return {
    attributes: state.survey.structure.data.questions[props.question.id].attributes.map(attributeId => state.survey.structure.data.attributes[attributeId]),
  }
}

export default connect(mapStateToProps)(Attributes);