import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Alert, Button, Col, FormGroup, Label, Row} from "reactstrap";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import InputNumber from "../../../../../components/InputNumber";
import Select from "react-select";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

class Limit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: props.limit || null,
    };

    this.removeLimit = this.removeLimit.bind(this);
  }

  removeLimit(){
    this.setState({
      ...this.state.limit,
      limit: null
    });

    this.props.onPropertyChange(null);
  }

  onChangeProperty(name, value){
    this.setState({
      ...this.state.limit,
      limit: {
        ...this.state.limit,
        [name]: value
      }
    }, () => {
      this.props.onPropertyChange({
        [name]: value
      });
    });
  }

  render() {
    const {limit} = this.state;
    const {errors} = this.props;

    if(!limit){
      return <Alert color="warning" className="text-center">
        <span className="d-block">Limity wypełnień dla tej ankiety nie są włączone</span>
        <Button color="primary" className="mt-2" onClick={() => this.setState({
          limit: {},
        })}>Kliknij, aby skonfigurować</Button>
      </Alert>
    }

    return (
      <React.Fragment>
        <Row>
          <Alert color="info" className="w-100 d-flex align-items-center">
            <span className="d-block">Aktualna liczba wypełnień w ramach limitu: {limit.supplemented || 0} z {limit.limit || 0}</span>
            <Button color="danger" className="ml-auto" onClick={this.removeLimit}>Usuń limit wypełnień</Button>
          </Alert>
        </Row>

        <FormGroup row className="mb-2">
          <Label lg={3} className="text-lg-right">
            Limit wypełnień
          </Label>
          <Col lg={9}>
            <InputNumber onChange={value => this.onChangeProperty('limit', value)} value={limit.limit || 0} />
            {errors.limit && <div className="invalid-feedback d-block">{errors.limit.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label lg={3} className="text-lg-right">
            Rodzaj limitu
          </Label>
          <Col lg={9}>
            <Select
              placeholder="Wybierz..."
              options={['daily', 'weekly', 'monthly'].map(value => {
                return {
                  label: <FormattedMessage id={'survey.limit.period.' + value} />,
                  value
                }
              })}
              value={!limit.period ? null : {
                label: <FormattedMessage id={'survey.limit.period.' + limit.period} />,
                value: limit.period,
              }}
              onChange={(e) => this.onChangeProperty('period', e.value)}
            />
            {errors.period && <div className="invalid-feedback d-block">{errors.period.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label lg={3} className="text-lg-right">
            Dni tygodnia
          </Label>
          <Col lg={9}>
            <Select
              placeholder="Wszystkie lub wybierz..."
              isMulti={true}
              options={[1,2,3,4,5,6,7].map(day => {
                return {
                  label: <FormattedMessage id={'_.date.dayOfWeek.' + day} />,
                  value: day
                }
              })}
              value={!limit.daysOfWeek ? null : Object.values(limit.daysOfWeek).map(day => {
                return {
                  label: <FormattedMessage id={'_.date.dayOfWeek.' + day} />,
                  value: day
                }
              })}
              onChange={e => this.onChangeProperty('daysOfWeek', e.map(v => v.value))}
            />
            {errors.daysOfWeek && <div className="invalid-feedback d-block">{errors.daysOfWeek.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label lg={3} className="text-lg-right">
            Licz od daty
          </Label>
          <Col lg={9}>
            <DateTimePicker
              placeholder="Od teraz lub wybierz datę..."
              defaultValue={limit.dateStart ? new Date(limit.dateStart) : null}
              date={true}
              time={false}
              format="Y-MM-DD"
              onChange={value => this.onChangeProperty('dateStart', value ? moment(value).format('YYYY-MM-DD') : null)}
            />
            {errors.dateStart && <div className="invalid-feedback d-block">{errors.dateStart.join('; ')}</div>}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label lg={3} className="text-lg-right">
            Zakres godzin
          </Label>
          <Col lg={9}>
            <Row>
              <Col lg={6}>
                <DateTimePicker
                  placeholder="Od godziny..."
                  defaultValue={limit.hourFrom ? new Date('1, 1, 1, ' + limit.hourFrom) : null}
                  date={false}
                  time={true}
                  onChange={value => this.onChangeProperty('hourFrom', value ? moment(value).format('HH:mm') : null)}
                />
                {errors.hourFrom && <div className="invalid-feedback">{errors.hourFrom.join('; ')}</div>}
              </Col>
              <Col lg={6}>
                <DateTimePicker
                  placeholder="Do godziny..."
                  defaultValue={limit.hourTo ? new Date('1, 1, 1, ' + limit.hourTo) : null}
                  date={false}
                  time={true}
                  onChange={value => this.onChangeProperty('hourTo', value ? moment(value).format('HH:mm') : null)}
                />
                {errors.hourTo && <div className="invalid-feedback">{errors.hourTo.join('; ')}</div>}
              </Col>
            </Row>
          </Col>
        </FormGroup>
      </React.Fragment>
    );
  }
}

Limit.propTypes = {
  onPropertyChange: PropTypes.func.isRequired,
  setPlanRestrictionsModals: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  limit: PropTypes.shape({
    supplemented: PropTypes.number,
    period: PropTypes.oneOf(['daily', 'weekly', 'monthly']),
    limit: PropTypes.number,
    daysOfWeek: PropTypes.array,
    dateStart: PropTypes.string,
    hourFrom: PropTypes.string,
    hourTo: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(Limit);