import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import CollectorsList from "./CollectorsList";
import CollectorsAvailable from "./CollectorsAvailable";
import api from 'api';
import qs from "query-string";
import {toast} from "react-toastify";
import appRoutes from "appRoutes";
import {Card, CardHeader, CardBody} from "reactstrap";
import { connect } from 'react-redux';
import LoadingSpinner from "../../../../components/LoadingSpinner";
import {Link} from "react-router-dom";

class Collectors extends Component {

  constructor(props) {
    super(props);
    const {location} = this.props;

    this.state = {
      collectors: [],
      loading: true,
      filters: qs.parse(location.search)
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.refreshCollectors = this.refreshCollectors.bind(this);
  }

  componentDidMount() {
    this.refreshCollectors();
  }

  handleChange(collectorId, property, value) {
    let collectors = this.state.collectors;
    let collectorIndex = collectors.indexOf(_.find(collectors, collector => collector.id === collectorId));

    collectors[collectorIndex][property] = value;
    this.setState({collectors});

    this.refreshCollectors();
  }

  handleCopy(collector) {
    let collectors = this.state.collectors;
    collectors.unshift(collector);

    this.props.history.push(appRoutes.survey.collectors.collector.view(this.props.match.params.survey, collector.id));

    this.setState({collectors});
  }

  handleDelete(collectorId) {
    const collectors = this.state.collectors.filter(collector => collector.id !== collectorId);
    this.setState({collectors});
  }

  refreshCollectors() {
    const {filters} = this.state;

    this.setState({loading: true}, () => {
      api.survey.get.collectors({id: this.props.match.params.survey}, filters)
        .then(collectors => this.setState({collectors, loading: false}))
        .catch(e => {
          toast.error(e.response.data.errors[0].message);
          this.props.history.push(appRoutes.surveys.list);
        });
    });
  }

  render() {
    const {loading, filters} = this.state;

    const surveyId = this.props.match.params.survey;
    return (
      <main>
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <h2><FormattedMessage id="survey.surveyStructure.cardHeader.collectors" /> {this.props.surveyPlan.collectorsMax > 0 && <small>(Maksymalna liczba kolektorów: {this.props.surveyPlan.collectorsMax})</small>}</h2>
            <Link title="Pobierz dane z tabeli do pliku CSV" className="btn btn-primary" to={api.survey.get.collectorsCsv({id: this.props.match.params.survey}, filters)} target="_blank"><i className="fas fa-download" /></Link>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <CollectorsAvailable surveyId={surveyId} handleCreate={this.refreshCollectors} collectors={this.state.collectors} hasSurveyBaseCollector={!!this.state.collectors.find(collector => collector.type === 'BaseSurveys')} hasTopSurveysCollector={!!this.state.collectors.find(collector => collector.type === 'TopSurveys')}/>
            {loading && <LoadingSpinner />}
            {!loading && this.state.collectors.length > 0 && <CollectorsList handleOnChange={() => this.refreshCollectors()} surveyId={surveyId} collectors={this.state.collectors} loading={this.state.loading} handleCollectorChange={this.handleChange} handleCollectorDelete={this.handleDelete} history={this.props.history} handleCollectorCopy={this.handleCopy}/>}
          </CardBody>
        </Card>
      </main>
    )
  }
}

function mapStateToProps(state) {
  return {
    surveyPlan: state.user.userPlan.plan.survey
  }
}

export default connect(mapStateToProps)(Collectors);