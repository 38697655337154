import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {ModalHeader, ModalBody, ModalFooter, Modal, Button} from "reactstrap";
import {updateAddress} from "../actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import 'ladda/dist/ladda-themeless.min.css';
import AddressForm from './AddressForm';

class AddressEditButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      editing: false,
      errors: {}
    };
    this.addressUpdate = this.addressUpdate.bind(this);
    this.addressForm = React.createRef();
  }

  addressUpdate(e, data){
    e.preventDefault();

    this.setState({
      editing: true,
      errors: {},
    });

    this.props.updateAddress({
      id: this.props.address.id,
      data: data
    })
      .then(() => {
        this.setState({
          modal: false,
          editing: false
        })
      })
      .catch((e) => {
        if(e.response.data.form.errors){
          this.setState({
            editing: false,
            errors: e.response.data.form.errors,
          });
        }
    });
  }

  toggleEditModal(){
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { editing, errors } = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleEditModal()} size="lg">
      <ModalHeader toggle={() => this.toggleEditModal()} tag="h2"><FormattedMessage id="addressEditButton.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <AddressForm errors={errors} onSubmit={this.addressUpdate} address={this.props.address} ref={this.addressForm}/>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.save">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={editing}
                data-style={ZOOM_OUT}
                onClick={e => this.addressUpdate(e, this.addressForm.current.state)}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) =>
                <Button color="secondary" className="mb-0 ml-3" onClick={() => this.toggleEditModal()}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="" className="m-0 p-0" onClick={() => this.toggleEditModal()}><i className="fas fa-edit"/></Button>
        {modal}
      </React.Fragment>
    )
  }
}

AddressEditButton.propTypes = {
  address: PropTypes.object.isRequired,
};

export default connect(null, { updateAddress }, null, {forwardRef: true})(AddressEditButton);