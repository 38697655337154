import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {reverseColumns} from "scenes/Survey/Structure/data/questions/actions";

class ActionReverse extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      saving: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
    this.setState({ modal: !this.state.modal });
  }

  reverseColumns(){
    this.setState({saving: true});

    this.props.reverseColumns(this.props.question.id)
      .then(() => this.setState({saving: false, modal: false}))
      .catch(() => this.setState({saving: false}));
  }

  render() {
    const {saving} = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
      <ModalHeader toggle={this.toggleModal} tag="h2"><FormattedMessage id="columns.actions.reverse.cardHeader"/></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="columns.actions.reverse.cardBody"/></p>
      </ModalBody>
      <ModalFooter>
        <div className="direction-group d-flex justify-content-end">
          <div className="direction-right">
            <FormattedMessage id="_.button.yes">{
              (message) => <LaddaButton
                className="btn btn-primary btn-ladda mb-0 ml-3"
                loading={saving}
                data-style={ZOOM_OUT}
                onClick={() => this.reverseColumns()}
              >
                {message}
              </LaddaButton>
            }</FormattedMessage>
            <FormattedMessage id="_.button.cancel">{
              (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.toggleModal}>{message}</Button>
            }</FormattedMessage>
          </div>
        </div>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button onClick={this.toggleModal}><FormattedMessage id="columns.actions.reverse.button.reverse"/></Button>
        {this.state.modal && modal}
      </React.Fragment>
    )
  }
}

ActionReverse.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};


export default connect(null, {reverseColumns})(ActionReverse);