import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import SelectBaskets from "../../../../../SelectBaskets";

class QuestionBaskets extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalBaskets: props.action.baskets || [],
    };

    this.toggleModalBaskets = this.toggleModalBaskets.bind(this);
  }

  toggleModalBaskets(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {action, baskets, changeable} = this.props;
    const {modalOpen, modalBaskets} = this.state;

    if (!changeable) {
      return action.baskets.map((id, i, arr) => {
        const tooltipId = "basketTooltipId-" + Math.random().toString(36).substring(7);
        let divider = i < arr.length - 1 && '| ';
        const basket = baskets.find(q => q.id === id);

        return <React.Fragment key={id}>
          <span className="pointer" id={tooltipId}>{basket.position + 1}. </span>
          <UncontrolledTooltip target={tooltipId}>
            <span className="img-30" dangerouslySetInnerHTML={{__html: basket.stripedContent}}/>
          </UncontrolledTooltip>
          {divider}
        </React.Fragment>
      })
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalBaskets} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalBaskets}>Wybierz koszyki</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-75 d-inline-block">
          <SelectBaskets questionId={action.question} value={modalBaskets} onChange={baskets => {
            this.setState({
              modalBaskets: baskets,
            })
          }} />
        </div>
        <div className="w-25 d-inline-block pl-3">
          Wybranych koszyków: {modalBaskets.length}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleBasketsChanged(modalBaskets);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={!_.isEmpty(action.baskets) ? 'secondary' : 'warning'} className="mx-1 pointer d-inline-flex flex-wrap" onClick={this.toggleModalBaskets}>{!_.isEmpty(action.baskets) ? action.baskets.map((id, i, arr) => {
          let divider = i < arr.length - 1 && <span className="mx-1">|</span>;
          const basket = baskets.find(q => q.id === id);

          return <React.Fragment key={id}><span className="ch-25 img-20 question-badge ws-nowrap d-inline-block" dangerouslySetInnerHTML={{__html: basket.position+1 + '. ' + basket.stripedContent}} /> {divider}</React.Fragment>;
        }) : 'Wybierz koszyki...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

QuestionBaskets.defaultProps = {
  changeable: true
};

QuestionBaskets.propTypes = {
  action: PropTypes.shape({
    question: PropTypes.number.isRequired,
    baskets: PropTypes.array.isRequired,
  }).isRequired,
  handleBasketsChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state, props) {
  let baskets = [];
  if(props.action.question){
    baskets = state.survey.structure.data.questions[props.action.question].baskets.map(aId => state.survey.structure.data.baskets[aId]);
    let position = 0;
    baskets = baskets.map(basket => {
      const ret = {
        ...basket,
        position
      };

      position++;
      return ret;
    });
  }


  return {
    baskets,
  }
}

export default connect(mapStateToProps)(QuestionBaskets);