import React, {PureComponent} from 'react';
import SelectValue from "../../components/SelectValue";
import classnames from "classnames";
import {Col, Row} from "reactstrap";

class SurveyLogoMaxHeight extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectMenuOpen: false
    };
  }

  clearValues(){
    this.props.handlerUpdate({'$survey-logo-maxHeight': ''});
  }

  render() {
    const {selectMenuOpen} = this.state;

    const props = {
      ownStyles: this.props.ownStyles,
      ownStylesDefault: {'$survey-logo-maxHeight': ''},
      handlerUpdate: this.props.handlerUpdate,
    };

    const inactive =
      [false, '150px'].indexOf(props.ownStyles['$survey-logo-maxHeight'] || false) >= 0
    ;

    return (
      <Row className={classnames({
        'py-1 px-3 mb-0 align-items-center': true,
        'inactive': inactive && !selectMenuOpen,
      })}>
        <label className="col col-4 mb-0 d-flex align-items-center"><span>Maksymalna wysokość loga</span> {!inactive && <i className="icon-clear-value fas fa-trash ml-0" onClick={() => this.clearValues()} />}</label>
        <Col className="col-8">
          <SelectValue
            {...props}
            keyName={"$survey-logo-maxHeight"}
            options={[
              {label: '150 pikseli', value: ''},
              {label: 'Oryginalna wysokość obrazka' , value: '100%'},
            ]}
            handleMenuOpen={() => this.setState({selectMenuOpen: true})}
            handleMenuClose={() => this.setState({selectMenuOpen: false})}
          />
        </Col>
      </Row>
    );
  }
}

export default SurveyLogoMaxHeight;