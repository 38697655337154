export default function isGroupManager(user) {
	if (!user) {
		return false;
	}

	const isCompany = user.type === 'Company';

	if ((isCompany && (_.isEmpty(user.userGroups) || user.userGroups[0].role === 'admin'))) {
		return true;
	}

	if (user.userGroups.length > 0 && user.userGroups[0].privilageSurveyShareDefault.accessEditUsers) {
		return true;
	}

	return false;
}