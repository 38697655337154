import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, InputGroup, Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import SurveysList from './SurveysList';
import qs from "query-string";
import Button from "../../../../componentsReusable/Button";

class Surveys extends Component {
  constructor(props) {
    super(props);

    const params = qs.parse(location.search);

    this.state = {
      filters: {
        search: params.search || "",
        onlyDeletedUsers: false,
      }
    };

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(e) {
    this.setState({filters: {...this.state.filters, search: e.target.value}})
  }

  render() {
    const {filters} = this.state;

    return (
      <Row className="animated fadeIn">
        <Col>
          <Card>
            <CardHeader>
              <div className="direction-group">
                <div className="direction-left d-flex justify-content-start">
                  <h2 className="float-left mr-4">
                    <FormattedMessage id="admin.surveys.cardHeader" />
                  </h2>
                </div>
                <div className="d-flex flex-row flex-nowrap align-items-center ml-auto float-right">
                  <Button tooltip={'Pokaż ankiety tylko usuniętych użytkowników'} color={filters.onlyDeletedUsers ? 'primary' : 'secondary'} onClick={() => this.setState({filters: {...this.state.filters, onlyDeletedUsers: !filters.onlyDeletedUsers}})}><i className="fas fa-users-slash" /></Button>
                  <div className="custom-search-input w-lg-100 float-right mt-1 ml-2">
                    <InputGroup className="p-0 mb-0">
                      <Input className="p-0" placeholder="Wyszukaj ankiety..." onChange={this.onSearch} value={filters.search}/>
                      <span className="input-group-btn py-1">
                      <button className="btn" type="button"><i className="fas fa-search" /></button>
                    </span>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </CardHeader>
            <hr className="my-0" />
            <CardBody>
              <Row>
                <Col>
                  <SurveysList filters={this.state.filters} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default Surveys;
