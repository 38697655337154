import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {deleteQuestion} from "scenes/Survey/Structure/data/questions/actions";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

class ModalDelete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      deleting: false
    }
  }

  questionDelete(){
    this.setState({deleting: true});

    this.props.deleteQuestion({
      id: this.props.question.id
    })
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id="question.headerActions.modalDelete.modalHeader"/></ModalHeader>
        <hr className="my-0" />
        <ModalBody>
          <p><FormattedMessage id="question.headerActions.modalDelete.modalBody"/></p>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.modal.delete.confirmation">{
                (message) => <LaddaButton
                  className="btn btn-danger btn-ladda mb-0 ml-3"
                  data-style={ZOOM_OUT}
                  loading={this.state.deleting}
                  onClick={() => this.questionDelete()}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggleModal}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalDelete.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default connect(null, {deleteQuestion})(ModalDelete);