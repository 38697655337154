import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, InputGroupAddon, Tooltip } from "reactstrap";
import Select from 'react-select';
import { connect } from 'react-redux';
import {updateQuestion} from "scenes/Survey/Structure/data/questions/actions";
import classnames from "classnames";

class BptoChangesType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      saving: false,
      question: {
        changesType: props.question.changesType
      }
    };
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.onChange = this.onChange.bind(this);
  };

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(e) {
    const { question } = this.props;
    this.setState({
      saving: true,
      question: {
        changesType: e.value
      }
    });
    this.props.updateQuestion({
      id: question.id,
      question: {
        changesType: e.value
      }
    }).then(() => {
      this.setState({
        saving: false
      });
    });

  }

  render() {
    const { question, tooltip, saving } = this.state;

    const options = [
        {label: 'o wartość', value: 'value'},
        {label: 'o procent', value: 'percentage'},
    ];

    return (
      <div
        id={"changesType-" + this.props.question.id}
        className={"float-left mr-1 mb-1 w-100 w-lg-auto input-group"}
        style={{minWidth: 182}}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon active": true,
            })}
            color="secondary"
          >
          <i className="fa-solid fa-percent"/>
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltip}
              target={"changesType-" + this.props.question.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="question.settings.bptoChangesType.button.tooltip" />
            </Tooltip>
          </Button>
        </InputGroupAddon>
        <Select
          classNamePrefix="react-select"
          className="react-select float-left"
          isClearable={false}
          isLoading={saving}
          isDisabled={saving}
          onChange={this.onChange}
          value={_.find(options, option => option.value === question.changesType)}
          options={options}
        />
      </div>
    )
  }
}

BptoChangesType.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    changesType: PropTypes.string.isRequired
  }).isRequired,
  updateQuestion: PropTypes.func.isRequired
};

export default connect(null, { updateQuestion })(BptoChangesType);
