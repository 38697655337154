import React, { useEffect, useRef } from 'react';

const useDidMountEffect = (func) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      func();

      return;
    }
  });
}

export default useDidMountEffect;