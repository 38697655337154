import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Input} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {updateRow} from "scenes/Survey/Structure/data/rows/actions";

class RowDescription extends Component {
  constructor(props){
    super(props);

    this.state = {
      saving: false,
      data: {
        description: props.row.description || ''
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      data: {
        ...this.state.data,
        description: nextProps.row.description || ''
      }
    })
  }

  onChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        description: e.target.value
      }
    });
  }

  onBlur(){
    const {data} = this.state;
    const {row} = this.props;

    this.setState({
      saving: true
    });

    this.props.updateRow({
      id: row.id,
      row: data
    }).then(() => {
      this.setState({saving: false});
    }).catch(() => {
      this.setState({saving: false});
    })
  }

  render() {
    const {data, saving} = this.state;

    return (
      <FormattedMessage id="row.image.description.placeholder">{placeholder => <Input
        className={"mt-2"}
        disabled={saving}
        type="text"
        bsSize={"sm"}
        placeholder={placeholder}
        value={data.description}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />}</FormattedMessage>
    );
  }
}

RowDescription.propTypes = {
  rowId: PropTypes.number.isRequired,
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
  }),
  updateRow: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    row: Object.values(state.survey.structure.data.rows).find(row => row.id === props.rowId)
  }
}

export default connect(mapStateToProps, {updateRow})(RowDescription);