import api from "api";

export const REQUEST = 'Survey/data/filters/REQUEST';
export const SUCCESS = 'Survey/data/filters/SUCCESS';
export const ERROR = 'Survey/data/filters/ERROR';

export const SET = 'Survey/data/filters/SET';
export const DELETED = 'Survey/data/filters/DELETED';
export const ADDED = 'Survey/data/filters/ADDED';
export const UPDATED = 'Survey/data/filters/UPDATED';

export function setFilters(filters) {
  return {
    type: SET,
    filters
  }
}

export function filterDeleted(filter) {
  return {
    type: DELETED,
    filter
  }
}
export function filterAdded(filter) {
  return {
    type: ADDED,
    filter
  }
}

export function filterUpdated(filter, expiredId) {
  return {
    type: UPDATED,
    filter,
    expiredId
  }
}

export function deleteFilter(data) {
  return dispatch => {
    return api.filter.delete(data).then(() => {
      dispatch(filterDeleted({
        id: data.id,
      }))
    })
  }
}

export function addFilter(data) {
  return dispatch => {
    return api.filter.post(data).then((filter) => {
      dispatch(filterAdded(filter))
    })
  }
}

export function updateFilter(data) {
  return dispatch => {
    return api.filter.patch(data).then(filter => {
      dispatch(filterUpdated(filter, data.filter.id));
    })
  }
}