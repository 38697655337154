import * as React from 'react';
import {Button, Col, Input, Row, Tooltip} from "reactstrap";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import LoadingSpinner from "../../../../../../../components/LoadingSpinner";
import reactHtmlParser from "react-html-parser";
import striptags from "striptags";
import {isMobile, isTablet} from "react-device-detect";
import HtmlEditor from "../../../../../../../componentsReusable/HtmlEditor";
import getCKConfigByPlan from "../../../../../../../utils/ckeditor/questionConfig";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import api from "../../../../../../../api";
import classnames from "classnames";
import Checkbox from "../../../../../../../components/Checkbox";
import ModalSimple from "../../../../../../../componentsReusable/ModalSimple";

function EndingScreen(props) {
	const {endingScreen, plan, survey} = props;
	const [endingTextEditing, setEndingTextEditing] = React.useState(false);
	const [endingNameEditing, setEndingNameEditing] = React.useState(false);
	const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
	const [savingEditing, setSavingEditing] = React.useState(false);
	const [deleted, setDeleted] = React.useState(false);
	const [id, setId] = React.useState(endingScreen.id ? endingScreen.id : null);
	const [content, setContent] = React.useState(endingScreen.content ? endingScreen.content : "");
	const [isDefault, setIsDefault] = React.useState(endingScreen.isDefault ? endingScreen.isDefault : false);
	const [name, setName] = React.useState(endingScreen.name ? endingScreen.name : "");
	const allowToEditDefault = plan.survey.endingScreenNumber > 1;

	const deleteScreen = () => {
		api.survey.delete.endingScreens(survey.id, id);
		setOpenDeleteModal(false);
		setDeleted(true)
	}

	const update = (data) => {
		if (!data) {
			data = {
				content,
				isDefault,
				name
			}
		}

		setSavingEditing(true);

		if (id) {
			api.survey.patch.endingScreens(survey.id, id, data).then(() => {
				setSavingEditing(false);
			})
		} else {
			api.survey.post.endingScreens(survey.id, data).then((response) => {
				setId(response.data.id);
				setSavingEditing(false);
			})
		}
	}

	if (deleted) {
		return <React.Fragment />
	}

	const editor = <React.Fragment>
		<Row>
			<Col>
				{(isMobile || isTablet)
					? <Input value={content} onChange={e => setContent(e.target.value)} onBlur={update} autoFocus={true} />
					: <HtmlEditor
						onChange={e => {
							setContent(e)
						}}
						data={content}
						config={getCKConfigByPlan(plan)}
					/>}
			</Col>
		</Row>
	</React.Fragment>;

	return (
		<React.Fragment>
			{endingNameEditing === false && <h2 onClick={() => {
				if (allowToEditDefault) {
					setEndingNameEditing(true)
				}
			}} className={classnames({
				"d-print-none": true,
				"pointer": allowToEditDefault
			})}>{name} {allowToEditDefault && id && <i className="fas fa-trash pointer" onClick={() => setOpenDeleteModal(true)}/>}</h2>}
			{endingNameEditing === true && <Input value={name} onChange={e => setName(e.target.value)} onBlur={() => {
				update()
				setEndingNameEditing(false)
			}} autoFocus={true} />}
			{endingTextEditing
				? editor
				: savingEditing
					? <LoadingSpinner width={25} classNames="my-0"/>
					: content
						? <span class={"ck-content"} onClick={() => setEndingTextEditing(true)}>{reactHtmlParser(content)}</span>
						: <i onClick={() => setEndingTextEditing(true)}><FormattedMessage id="surveyStructure.endingText.isEmpty"/></i>
			}
			{allowToEditDefault && <Row className="mt-3">
				<Col>
					<h2>Czy jest to domyślny ekran zakończenia?</h2>
					<Checkbox id={'endingScreen' + endingScreen.id} tooltip={false} checked={isDefault} text={<span>Czy jest to domyślny ekran zakończenia?</span>}
						onClick={() => {
							setIsDefault(!isDefault)
							update({
								content: content,
								name: name,
								isDefault: !isDefault
							});
						}}
					/>
				</Col>
			</Row>}
			{(endingTextEditing || endingNameEditing) && <Row className="mt-3">
				<Col>
					<FormattedMessage id="_.button.save">{
						(message) => <LaddaButton
							className="btn btn-primary btn-ladda mr-2"
							loading={savingEditing}
							data-style={ZOOM_OUT}
							onClick={() => {
								update()
								setEndingNameEditing(false);
								setEndingTextEditing(false);
							}}
						>{message}</LaddaButton>
					}</FormattedMessage>
					<FormattedMessage id="_.button.cancel">{
						(message) => <Button onClick={() => {
							setEndingNameEditing(false);
							setEndingTextEditing(false);
						}}>{message}</Button>
					}</FormattedMessage>
				</Col>
			</Row>}
			<ModalSimple onAccept={deleteScreen}
				onCancel={() => setOpenDeleteModal(false)} modalOpen={openDeleteModal}
				headerText={"Uwaga!"}
				bodyText={"Czy chcesz usunąć ten ekran zakończenia?"} />
			<br />
		</React.Fragment>
	);
}

EndingScreen.propTypes = {

};

function mapStateToProps(state) {
	return {
		survey: state.survey.structure.data.survey,
		plan: state.user.userPlan.plan
	}
}

export default connect(mapStateToProps)(EndingScreen);