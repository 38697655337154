import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "components/Button";
import api from "../../api";

const SurveysCategoryRemove = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);
  const [removing, setRemoving] = React.useState(false);

  const remove = () => {
    setRemoving(true);

    api.surveysCategories.delete(props.surveysCategory.id)
      .then(() => {
        setModalOpen(false);
        props.onRemoved();
      }).finally(() => {
        setRemoving(false);
    })
  }

  return (
    <React.Fragment>
      <i className="fas fa-times text-danger pointer visible-visibilitiable" onClick={toggleModalOpen} />
      {modalOpen && <Modal isOpen toggle={toggleModalOpen} size="md">
        <ModalHeader toggle={toggleModalOpen}>Potwierdź</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          Czy na pewno usunąć sekcję <strong>{props.surveysCategory.name}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>Anuluj</Button>
          <Button
            color="danger"
            onClick={remove}
          >Usuń sekcję</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
};

SurveysCategoryRemove.propTypes = {
  surveysCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onRemoved: PropTypes.func.isRequired,
};

export default SurveysCategoryRemove;