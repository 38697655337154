import React, { Component } from 'react';
import {connect} from "react-redux";
import {Switch, Redirect, Route} from 'react-router-dom';
import PropTypes from "prop-types";
import {ToastContainer} from "react-toastify";

import GuestRoute from "./routes/GuestRoute";
import UserRoute from "routes/UserRoute";
import AdminRoute from "routes/AdminRoute";

import ContainerDefault from "containers/Default";
import ContainerSurvey from "containers/Survey";
import ContainerAccount from "containers/Account";


import SignLogin from "scenes/Sign/Login";
import SignRegister from "scenes/Sign/Register";
import SignForgotPassword from "scenes/Sign/ForgotPassword";
import SignPasswordReset from "scenes/Sign/PasswordReset";
import AnalysisShare from "scenes/AnalysisShare";
import AnalysisCharts from "scenes/AnalysisCharts";
import SummaryShared from "scenes/SurveyShared/SummaryShared";
import QuotasShared from "scenes/SurveyShared/QuotasShared";

import Header from "components/Header";

import appRoutes from "appRoutes";
import LoadingSpinner from "components/LoadingSpinner";
import PlanRestrictionsModals from "./components/PlanRestrictionsModals";
import VersionLabel from 'components/VersionLabel';

import Script from "react-load-script";
import { CKEditorContext } from '@ckeditor/ckeditor5-react';
import { Context, ContextWatchdog } from 'ckeditor5';
import { ReactTableDefaults } from 'react-table'

import anychart from 'anychart';
anychart.licenseKey('swresearch.pl-8afe4dff-f08fa906');

import 'core-js';
import 'regenerator-runtime/runtime';

import 'ckeditor5/dist/ckeditor5.css';

Object.assign(ReactTableDefaults, {
  noDataText: 'Brak wyników',
  loadingText: 'Trwa wczytywanie danych...'
});

class App extends Component {

  isRouteAnon(route) {
    const anonRoutes = [
        appRoutes.users.login,
        appRoutes.users.register,
        appRoutes.users.forgotPassword,
        '/resetuj-haslo'
    ];

    let toReturn = true;

    anonRoutes.forEach((anon) => {
      if (route.includes(anon)) {
        toReturn = false;
      }
    });

    return toReturn;
  }

  render() {
    const {location, isAuthenticated, isAdmin, config, user} = this.props;

    if(_.isEmpty(config) || (isAuthenticated && _.has(user, 'token'))){
      return <LoadingSpinner/>
    }

    if(isAuthenticated === false && this.isRouteAnon(window.location.pathname)) {
      localStorage.setItem('redirectAfterLogin', window.location.pathname + window.location.search);
    }

    const showVersionLabel = config.data.showVersionLabel && (location.pathname === appRoutes.home || location.pathname === appRoutes.surveys.list);

    return (
      <React.Fragment>
        <CKEditorContext context={ Context } contextWatchdog={ ContextWatchdog }>
          <ToastContainer position="top-right" autoClose={5000} style={{
            zIndex: 1999
          }}/>
          <PlanRestrictionsModals />

          {showVersionLabel && <VersionLabel />}

          <Switch>
            <GuestRoute location={location} path={appRoutes.users.login} name="Login" component={SignLogin}/>
            <GuestRoute location={location} path={appRoutes.users.register} name="Login" component={SignRegister}/>
            <GuestRoute location={location} path={appRoutes.users.forgotPassword} name="ForgotPassword" component={SignForgotPassword}/>
            <GuestRoute location={location} path={appRoutes.users.passwordReset()} name="PasswordReset" component={SignPasswordReset}/>
            <Route location={location} path={appRoutes.analysis.chartsByUid()} name="Analiza" children={<AnalysisCharts />}/>
            <Route location={location} path={appRoutes.analysis.shared()} name="Analiza" children={<AnalysisShare />}/>
            <Route location={location} path={appRoutes.surveyShared.summary()} name="Podsumowanie" children={<SummaryShared />}/>
            <Route location={location} path={appRoutes.surveyShared.quotas()} name="Kwoty" children={<QuotasShared />}/>

            <UserRoute location={location} path={appRoutes.account.home} name="Ankieteo" component={ContainerAccount}/>
            <UserRoute location={location} path={appRoutes.survey.main()} name="Ankieteo" component={ContainerSurvey}/>
            <UserRoute location={location} path={appRoutes.home} name="Ankieteo" component={ContainerDefault}/>
            <AdminRoute location={location} path={appRoutes.admin.main} name="Administrator" component={ContainerDefault}/>

            {isAuthenticated ? <Redirect exact from="/" to={isAdmin ? appRoutes.admin.main : appRoutes.surveys.list}/> : <Redirect exact from="/" to={appRoutes.users.login}/>}

          </Switch>
        </CKEditorContext>
      </React.Fragment>
    )
  }
}

App.defaultProps = {
  isAuthenticated: false,
  isAdmin: false,
};

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    config: state.config,
    isAuthenticated: Object.keys(state.user).length > 0,
    isAdmin: state.user.roles && (state.user.roles.includes('ROLE_SUPER_ADMIN') || state.user.roles.includes('ROLE_ADMIN')),
  }
}

export default connect(mapStateToProps)(App);
