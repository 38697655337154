import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import Select from "react-select";
import _ from "lodash";

class SelectSets extends Component {
  constructor(props){
    super(props);

    this.select = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  onChange(e){
    const {isMulti} = this.props;

    if(isMulti){
      this.select.current.setState({isOpen: true, menuIsOpen: true});
    }

    if(isMulti){
      this.props.onChange(e.map(obj => obj.value))
    }else{
      this.props.onChange(e.value);
    }
  }

  render() {
    const {sets, value, isMulti} = this.props;

    return (
      <Select
        placeholder={isMulti ? "Wybierz zestawy" : "Wybierz zestaw"}
        ref={this.select}
        value={value && (!isMulti ?
          {
            label: 'Zestaw ' + (sets.find(s => s.id === value).positionInSurvey+1),
            value,
          }
          :
          value.map(sId => {
            return {
              label: 'Zestaw ' + (sets.find(s => s.id === sId).positionInSurvey+1),
              value: sId,
            }
          }
        ))}
        isOpen={false}
        isMulti={isMulti}
        onChange={this.onChange}
        options={sets.map(set => {
          return {
            label: 'Zestaw ' + (set.positionInSurvey+1),
            value: set.id,
          }
        })}
      />
    );
  }
}

SelectSets.defaultProps = {
  isMulti: true,
};

SelectSets.propTypes = {
  isMulti: PropTypes.bool,
  sets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    positionInSurvey: PropTypes.number.isRequired,
  }).isRequired,).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number,]),
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let sets = [];
  let positionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    let set = state.survey.structure.data.sets[setId];
    set.positionInSurvey = positionInSurvey;
    sets.push(set);
    positionInSurvey++;
  });

  return {
    sets,
  }
}

export default connect(mapStateToProps)(SelectSets);