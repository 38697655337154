import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import SelectAnswers from "../../../../../../../Structure/components/SelectAnswers";
import {Input} from "reactstrap";
import SelectExcludings from "../../../../../../../Structure/components/SelectExcludings";


class ColumnThead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: false,
      filterExcluding: false,
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterExcludingChange = this.onFilterExcludingChange.bind(this);
  }

  static getColSpan(question){
    return 1 + (question.answers.find(answer => answer.isOpen) ? 1 : 0) + (question.excludings.length > 0 ? 1 : 0);
  }

  componentWillUnmount() {
    const {question} = this.props;

    this.props.onFilterChange('q' + question.id, false);
    this.props.onFilterChange('q' + question.id + 'excl', false);
  }

  onFilterChange(value){
    const {question} = this.props;

    if(value.length === 0){
      value = false;
    }

    this.setState({
      filter: value
    }, () => {
      const key = 'q' + question.id;

      this.props.onFilterChange(key, this.state.filter === false ? false : {
          question: question.id,
          answers: this.state.filter,
        }
      );
    });
  }

  onFilterExcludingChange(value){
    const {question} = this.props;

    if(value.length === 0){
      value = false;
    }

    this.setState({
      filterExcluding: value
    }, () => {
      const key = 'q' + question.id + 'excl';

      this.props.onFilterChange(key, this.state.filterExcluding === false ? false : {
          question: question.id,
          excludings: this.state.filterExcluding,
        }
      );
    });
  }

  render() {
    const {filter, filterExcluding} = this.state;
    const {question} = this.props;

    return <React.Fragment>
      <th className="text-center border-left border-right pointer" onClick={() => filter === false && this.setState({filter: null}) }>
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filter !== false,
          'align-items-center': filter === false,
        })}>
          <div className="w-100">Odpowiedź</div>
          {filter !== false && <React.Fragment>
            <SelectAnswers
              isClearable={true}
              value={filter}
              questionId={question.id}
              onChange={this.onFilterChange}
            />
          </React.Fragment>}
        </div>
      </th>
      {question.answers.find(answer => answer.isOpen) && <th className="text-center border-left border-right">
        <div className={classnames({
          'd-flex h-100 text-center align-items-center': true,
        })}>
          <div className="w-100">Odpowiedź wpisana</div>
        </div>
      </th>}
      {question.excludings.length > 0 && <th
        className="text-center border-left border-right pointer"
        onClick={() => filterExcluding === false && this.setState({filterExcluding: null})}
      >
        <div className={classnames({
          'd-flex h-100 text-center': true,
          'flex-column': filterExcluding !== false,
          'align-items-center': filterExcluding === false,
        })}>
          <div className="w-100">Wykluczająca</div>
          {filterExcluding !== false && <SelectExcludings
            isClearable={true}
            isMulti={true}
            value={filterExcluding}
            questionId={question.id}
            onChange={this.onFilterExcludingChange}
          />}
        </div>
      </th>}

    </React.Fragment>;
  }
}

ColumnThead.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['single']),
    answers: PropTypes.arrayOf(PropTypes.shape({
      isOpen: PropTypes.bool.isRequired,
    }),),
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default ColumnThead;