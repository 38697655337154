import React, {Component} from 'react';
import PropTypes from "prop-types";

import {GoogleMap, withGoogleMap, withScriptjs, Marker} from "react-google-maps";

class MapComponent extends Component {
  render() {
    const {markers} = this.props;

    return (
      <React.Fragment>
        <GoogleMap
          defaultZoom={8}
          defaultCenter={{ lat: 52.237049, lng: 21.017532 }}
          onClick={(e) => this.addMarker(e)}
        >
          {markers.map((marker, key) => {
            return <Marker
              key={key}
              position={{ lat: marker.lat, lng: marker.lng }}
            />;
          })}
        </GoogleMap>
      </React.Fragment>
    );
  }
}

MapComponent.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired),
};

export default withScriptjs(withGoogleMap(MapComponent));