import React, {Component} from 'react';
import PropTypes from "prop-types";
import AnyChart from "anychart-react";

class ChartDonut extends Component {

  render() {
    const {id, data} = this.props;

    return (
      <AnyChart
        id={'question-' + id}
        type="pie"
        innerRadius="35%"
        height={400}
        data={data}
        yAxis={{
          labels: {
            wordWrap: 'break-word',
            wordBreak: 'break-all',
            hAlign: 'right'
          }
        }}
        xAxis={{
          labels: {
            wordWrap: 'break-word',
            wordBreak: 'break-all',
          }
        }}
      />
    )
  }
}

ChartDonut.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,).isRequired,
};

export default ChartDonut;