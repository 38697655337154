import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Badge, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import Select from "react-select";

class EndingScreenFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      endingScreen: props.action.endingScreen || [],
    };

    this.toggleModalSets = this.toggleModalSets.bind(this);
  }

  toggleModalSets(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {action, changeable, endingScreens} = this.props;
    const {modalOpen, endingScreen} = this.state;
    let endScreen =  endingScreen && endingScreen.name ? endingScreen : endingScreens.find((screen) => screen.id === endingScreen);

    if (!changeable) {
      if(action.endingScreen){
        return endScreen.name;
      }else{
        return <i>Brak ekranu końcowego</i>
      }
    }

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModalSets} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModalSets}>Wybierz ekran końcowy</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <div className="w-100 d-inline-block">
          <Select
              value={endingScreen ? {
                label: endingScreen.name,
                value: endingScreen.id
              } : {label: "Wybierz ekran zakończenia", value: null}}
              onChange={e => {
                let newScreen = endingScreens.find((screen) => screen.id === e.value);
                this.setState({
                  endingScreen: newScreen,
                })
              }}
              options={_.map(endingScreens, screen => {
                return {
                  label: screen.name,
                  value: screen.id,
                }
              })}
          />
        </div>
        <div className="d-inline-block mt-3">
          Wybrany ekran końcowy {endScreen ? endScreen.name : "Brak"}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.choose">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={false}
            data-style={ZOOM_OUT}
            onClick={() => {
              this.props.handleSetsChanged(endingScreen.id);
              this.setState({
                modalOpen: false,
              })
            }}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Badge color={endScreen ? 'secondary' : 'warning'} className="mx-1 pointer" onClick={this.toggleModalSets}>{endScreen ? endScreen.name : 'Wybierz ekrań końcowy...'}</Badge>
        {modal}
      </React.Fragment>
    );
  }
}

EndingScreenFilter.defaultProps = {
  changeable: true
};

EndingScreenFilter.propTypes = {
  action: PropTypes.shape({
    endingScreen: PropTypes.object,
  }).isRequired,
  handleSetsChanged: PropTypes.func,
  changeable: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    endingScreens: state.survey.structure.data.survey.endingScreens
  }
}

export default connect(mapStateToProps)(EndingScreenFilter);