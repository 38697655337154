import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button, Input, Row, Col, Card, CardBody, Form, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {surveyLink} from "../../../../services/surveyLink";
import Select from "react-select";
import {toast} from "react-toastify";
import {CopyToClipboard} from "react-copy-to-clipboard";

class CodeGenerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: false,
      config: {},
    };

    this.getCode = this.getCode.bind(this);
    this.getScript = this.getScript.bind(this);
    this.changeType = this.changeType.bind(this);
    this.onChangeConfig = this.onChangeConfig.bind(this);
  }

  componentDidMount() {
    this.changeType('popup');
  }

  getScript(){
    const {type} = this.state;
    let initName = '';
    if(type === 'popup'){
      initName = 'initPopup';
    }else if(type === 'widget'){
      initName = 'initWidget';
    }

    let src;
    // src = "'//localhost/repo/frontend/widget/widget.min.js'";
    src = "('https:' == document.location.protocol ? 'https://' : 'http://') + 'ankieteo.pl/js/ankieteo/widget.min.js'";

    return "var script = document.createElement('script');script.type = 'text/javascript';script.async = true;script.src = " + src + ";script.onload = function() {widgetBox.initConfig(config);widgetBox." + initName + "();};var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(script, s);";
  }

  getCode(){
    const {type, config} = this.state;

    let code = '';

    if(type === 'popup'){
      code = "" +
        "<script type=\"text/javascript\">\n" +
        "widgetBox = {};\n" +
        "(function() {\n" +
        "\tvar config = {\n" +
        "\t\turl: '"+surveyLink(this.props.collector.guid, true)+"',\n" +
        "\t\twidthPopup: "+config.widthPopup+",\n" +
        "\t\theightPopup: "+config.heightPopup+",\n" +
        "\t\ttime: "+config.time+",\n" +
        "\t\tprobability: "+config.probability+",\n" +
        "\t}\n" +
        "\t" + this.getScript() + "\n" +
        "})();\n" +
        "</script>"
      ;
    }else if(type === 'widget'){
      code = "" +
        "widgetBox = {};\n" +
        "(function() {\n" +
        "\tvar config = {\n" +
        "\t\turl: '"+surveyLink(this.props.collector.guid, true)+"',\n" +
        "\t\ttext: '"+config.text+"',\n" +
        "\t\twidthWidget: "+config.widthWidget+",\n" +
        "\t\theightWidget: "+config.heightWidget+",\n" +
        "\t\tvertical: '"+config.vertical+"',\n" +
        "\t\thorizontal: '"+config.horizontal+"',\n" +
        "\t\taction: '"+config.action+"',\n" +
        "\t\twidthPopup: "+config.widthPopup+",\n" +
        "\t\theightPopup: "+config.heightPopup+",\n" +
        "\t}\n" +
        "\t" + this.getScript() + "\n" +
        "})();\n"
      ;

      let func = new Function("return " + "function () { " + code + " }")().bind(this);
      func();

      code = "<script type=\"text/javascript\">\n" + code + "</script>";
    }else if(type === 'iframe'){
      code = "<iframe src=\""+surveyLink(this.props.collector.guid, true)+"\" scrolling=\"auto\" frameborder=\"0\" width=\""+config.width+"\" height=\""+config.height+"\"></iframe>"
      ;
    }

    return code;
  }

  changeType(type){
    if(type === 'popup'){
      this.setState({
        type,
        config: {
          widthPopup: 1040,
          heightPopup: 600,
          time: 0,
          probability: 1,
        }
      });
    }else if(type === 'widget'){
      this.setState({
        type,
        config: {
          text: 'Wypełnij ankietę',
          widthWidget: 450,
          heightWidget: 500,
          vertical: 'bottom',
          horizontal: 'right',
          action: 'widget',
          widthPopup: 1040,
          heightPopup: 600,
        }
      });
    }else if(type === 'iframe'){
      this.setState({
        type,
        config: {
          width: 1040,
          height: 500,
        }
      });
    }
  }

  onChangeConfig(key, value){
    this.setState({
      config: {
        ...this.state.config,
        [key]: value,
      }
    })
  }

  getForm(){
    const {type, config} = this.state;

    if(type === 'popup'){
      const optionsProbability = [
        {
          value: 1,
          label: 'Pokaż zawsze',
        }
      ];
      _.map([2,3,4,5,10,15,20], v => {
        optionsProbability.push({
            value: v,
            label: '1/' + v,
          }
        );
      });

      return <Form>
        <FormGroup row>
          <Label md={2}>Szerokość:</Label>
          <Col md={10}>
            <InputGroup>
              <Input type="number" onChange={e => this.onChangeConfig('widthPopup', e.target.value)} value={config.widthPopup} />
              <InputGroupAddon addonType="append"><InputGroupText>px</InputGroupText></InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Wysokość:</Label>
          <Col md={10}>
            <InputGroup>
              <Input type="number" onChange={e => this.onChangeConfig('heightPopup', e.target.value)} value={config.heightPopup} />
              <InputGroupAddon addonType="append"><InputGroupText>px</InputGroupText></InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Pokaż popup po:</Label>
          <Col md={10}>
            <InputGroup>
              <Input type="number" onChange={e => this.onChangeConfig('time', e.target.value)} value={config.time} />
              <InputGroupAddon addonType="append"><InputGroupText>sec.</InputGroupText></InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Prawdopodobieństwo pokazania się popupu:</Label>
          <Col md={10}>
            <Select
              menuPlacement="top"
              options={optionsProbability}
              onChange={option => this.onChangeConfig('probability', option.value)}
              value={optionsProbability.find(v => v.value === config.probability)}
            />
          </Col>
        </FormGroup>
        <Row>
          <Col>
            <Button
              onClick={() => {
                let code = this.getCode().split("\n");
                delete code[0];
                delete code[code.length-1];
                let func = new Function("return " + "function () { " + code.join("\n") + " }")().bind(this);
                func();
              }}
            >Pokaż popup</Button>
          </Col>
        </Row>
      </Form>
    }else if(type === 'iframe'){
      return <Form>
        <FormGroup row>
          <Label md={2}>Szerokość:</Label>
          <Col md={10}>
            <InputGroup>
              <Input type="number" onChange={e => this.onChangeConfig('width', e.target.value)} value={config.width} />
              <InputGroupAddon addonType="append"><InputGroupText>px</InputGroupText></InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md={2}>Wysokość:</Label>
          <Col md={10}>
            <InputGroup>
              <Input type="number" onChange={e => this.onChangeConfig('height', e.target.value)} value={config.height} />
              <InputGroupAddon addonType="append"><InputGroupText>px</InputGroupText></InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
      </Form>
    }else if(type === 'widget'){
      const optionsVertical = [
        {
          value: 'top',
          label: 'Na górze',
        },
        {
          value: 'middle',
          label: 'Po środku',
        },
        {
          value: 'bottom',
          label: 'Na dole',
        },
      ];
      const optionsHorizontal = [
        {
          value: 'left',
          label: 'Z lewej',
        },
        {
          value: 'center',
          label: 'Po środku',
        },
        {
          value: 'right',
          label: 'Z prawej',
        },
      ];
      const optionsAction = [
        {
          value: 'widget',
          label: 'Otwórz widget',
        },
        {
          value: 'popup',
          label: 'Otwórz popup',
        },
        {
          value: 'newTab',
          label: 'Otwórz w nowej karcie',
        },
      ];

      return <Form>
        <FormGroup row>
          <Label md={2}>Treść widgetu:</Label>
          <Col md={10}>
            <Input type="text" onChange={e => this.onChangeConfig('text', e.target.value)} value={config.text} />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label md={2}>Położenie w pionie:</Label>
          <Col md={10}>
            <Select
              menuPlacement="top"
              options={optionsVertical}
              onChange={option => this.onChangeConfig('vertical', option.value)}
              value={optionsVertical.find(v => v.value === config.vertical)}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label md={2}>Położenie w poziomie:</Label>
          <Col md={10}>
            <Select
              menuPlacement="top"
              options={optionsHorizontal}
              onChange={option => this.onChangeConfig('horizontal', option.value)}
              value={optionsHorizontal.find(v => v.value === config.horizontal)}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label md={2}>Po kliknięciu:</Label>
          <Col md={10}>
            <Select
              menuPlacement="top"
              options={optionsAction}
              onChange={option => this.onChangeConfig('action', option.value)}
              value={optionsAction.find(v => v.value === config.action)}
            />
          </Col>
        </FormGroup>

        {config.action === 'widget' && <React.Fragment>
          <FormGroup row>
            <Label md={2}>Szerokość:</Label>
            <Col md={10}>
              <InputGroup>
                <Input type="number" onChange={e => this.onChangeConfig('widthWidget', e.target.value)} value={config.widthWidget}/>
                <InputGroupAddon addonType="append"><InputGroupText>px</InputGroupText></InputGroupAddon>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={2}>Wysokość:</Label>
            <Col md={10}>
              <InputGroup>
                <Input type="number" onChange={e => this.onChangeConfig('heightWidget', e.target.value)} value={config.heightWidget}/>
                <InputGroupAddon addonType="append"><InputGroupText>px</InputGroupText></InputGroupAddon>
              </InputGroup>
            </Col>
          </FormGroup>
        </React.Fragment>}
        {config.action === 'popup' && <React.Fragment>
          <FormGroup row>
            <Label md={2}>Szerokość:</Label>
            <Col md={10}>
              <InputGroup>
                <Input type="number" onChange={e => this.onChangeConfig('widthPopup', e.target.value)} value={config.widthPopup}/>
                <InputGroupAddon addonType="append"><InputGroupText>px</InputGroupText></InputGroupAddon>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={2}>Wysokość:</Label>
            <Col md={10}>
              <InputGroup>
                <Input type="number" onChange={e => this.onChangeConfig('heightPopup', e.target.value)} value={config.heightPopup}/>
                <InputGroupAddon addonType="append"><InputGroupText>px</InputGroupText></InputGroupAddon>
              </InputGroup>
            </Col>
          </FormGroup>
        </React.Fragment>}
      </Form>
    }
  }

  render() {
    const {type, config} = this.state;

    const types = {
      popup: {
        name: 'Popup',
        description: 'Uruchamia Twoją ankietę w nowym oknie / w nowej karcie przeglądarki potencjalnego respondenta. Po wyborze ustawień, skopiuj kod i wklej go na swojej stronie www.'
      },
      iframe: {
        name: 'Iframe',
        description: 'Umieszcza Twoją ankietę na stronie www. Po wyborze ustawień, skopiuj kod i wklej go na swojej stronie www, w miejscu, w którym ma pojawić się ankieta.'
      },
      widget: {
        name: 'Widget',
        description: 'Wyświetla pływający element graficzny zachęcający do udziału w ankiecie. Po kliknięciu pojawi się pierwsze pytanie z Twojej ankiety.'
      },
    }

    return <React.Fragment>
      <Row className="my-4">
        <Col>
          <div className="d-flex flex-column align-content-center flex-md-row flex-nowrap justify-content-around">
            {_.map(types, (t, typeName) => {

              return <div
                className="px-3 w-100"
                style={{maxWidth: '680px'}}
              >
                <Card
                  className="w-100 pointer"
                  onClick={() => this.changeType(typeName)}
                  style={{
                    border: type === typeName ? '3px solid #32ace8' : '3px solid #fff',
                  }}
                >
                  <CardBody>
                    <h1 className="text-center">{t.name}</h1>
                    <p>{t.description}</p>
                  </CardBody>
                </Card>
              </div>
            })}
          </div>
        </Col>
      </Row>
      {this.state.type !== false && <Row>
        <Col lg={8}>
          {this.getForm()}
        </Col>
        <Col lg={4}>


          <CopyToClipboard
            text={this.getCode()}
            onCopy={() => {
              toast.success("Skopiowano kod");
            }}
          >
            <Button size="sm" color="primary" className="mb-1"><i className="fas fa-copy" /> <span>Skopiuj poniższy kod</span></Button>
          </CopyToClipboard>
          <Input
            type="textarea"
            className="bg-dark text-white"
            rows={20}
            style={{fontSize: '0.8em', lineHeight: '14px'}}
            readOnly
            value={this.getCode()}
          />
        </Col>
      </Row>}

      {type === 'iframe' && <div className="bg-light w-100 p-3 d-flex flex-column align-content-center">
        <h1 className="text-center">Podgląd</h1>
        <div className="mx-auto mt-3" dangerouslySetInnerHTML={{__html: this.getCode()}} />
      </div>}
    </React.Fragment>;
  }
}

CodeGenerator.propTypes = {
  collector: PropTypes.shape({
    guid: PropTypes.string.isRequired,
  }).isRequired,
};

export default CodeGenerator;