import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {InputGroupAddon, Tooltip, Button} from "reactstrap";
import Select from 'react-select';
import classnames from "classnames";
import striptags from 'striptags';
import _ from "lodash";

class FetchCafeteryPositions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      showSelect: !!props.question.fetchCafeteryPositions,
      question: {
        fetchCafeteryPositions: props.question.fetchCafeteryPositions
      }
    };
    this.onChange = this.onChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleSelect() {
    this.setState({
      showSelect: !this.state.showSelect
    }, _ => {
      if (!this.state.showSelect && !!this.state.question.fetchCafeteryPositions) {
        this.onChange({value: null})
      }
    });
  }

  onChange(e) {
    this.setState({
      ...this.state,
      question: {
        ...this.state.question,
        fetchCafeteryPositions: e.value
      }
    }, () => this.props.handleUpdate(this.state.question));
  };

  render() {
    const {questions} = this.props;
    const {tooltip, question} = this.state;

    const options = _.map(questions, question => {
      return {
        label: `P${question.positionInSurvey}. ${striptags(question.content)}`,
        value: question.id
      }
    });

    const value = !!question.fetchCafeteryPositions ? {
      value: question.fetchCafeteryPositions,
      label: striptags(questions.filter(q => q.id === question.fetchCafeteryPositions)[0].content)
    } : null;

    return (
      <div
        id={"fetchCafeteryPositions-" + this.props.question.id}
         className={classnames({
           "mr-1 float-left mb-1": true,
           "w-auto": !this.state.showSelect,
           "w-100 w-lg-auto input-group": this.state.showSelect
         })}
      >
        <InputGroupAddon addonType="prepend">
          <Button
            className={classnames({
              "btn-icon": true,
              "active": !!question.fetchCafeteryPositions,
            })}
            color="secondary"
            onClick={() => this.toggleSelect()}
          >
            <i className="fa-solid fa-list-ol"/>
            <Tooltip
              delay={0}
              placement="top"
              isOpen={tooltip}
              target={"fetchCafeteryPositions-" + this.props.question.id}
              toggle={() => this.toggleTooltip()}
            >
              <FormattedMessage id="question.settings.fetchCafeteryPositions.title" />
            </Tooltip>
          </Button>
        </InputGroupAddon>
        {this.state.showSelect && <Select
          classNamePrefix="react-select"
          className="react-select float-left"
          placeholder={"Wybierz..."}
          value={value}
          options={options}
          onChange={(e) => this.onChange(e)}
        />}
      </div>
    );
  }
}

FetchCafeteryPositions.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    fetchCafeteryPositions: PropTypes.number
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  let questions = [];

  _.each(state.survey.structure.data.survey.setBlocks, setBlockId => {
    _.each(state.survey.structure.data.sets[setBlockId].blockPages, blockId => {
      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          questions.push(state.survey.structure.data.questions[questionId])
        })
      });
    });
  });

  const answerOrMatrixQuestionTypes = ['single', 'multi', 'ranked', 'numericMulti', 'matrix', 'matrixMulti'];
  const answerOrMatrixQuestions = _.filter(questions, question => answerOrMatrixQuestionTypes.includes(question.type) && question.id !== props.question.id);

  return {
    questions: answerOrMatrixQuestions
  }
}

export default connect(mapStateToProps)(FetchCafeteryPositions);
