export function loiToMinCPConverter(loi) {
	const minutes = Math.ceil(loi / 60);
	const cp = Math.ceil(minutes / 5);
	if (cp < 1) {
		return 1;
	}

	if (cp > 10) {
		return 10;
	}

	return cp;
}