import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";
import PropTypes from 'prop-types';
import api from 'api';
import reactHTMLParser from "react-html-parser";
import ModalEmailVars from "../../../../CollectorEmail/ModalEmailVars";
import striptags from "striptags";
import TabTarget from "../../TabTarget";
import {toast} from "react-toastify";
import ModalSimple from "../../../../../../../componentsReusable/ModalSimple";
import HtmlEditor from "../../../../../../../componentsReusable/HtmlEditor";

class TabTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      editorContentOpen: false,
      editorContentReminderOpen: false,
      modalOpen: false
    };

    if (props.collector.sendEmail) {
      this.state.template = props.collector.sendEmail.template;
    } else {
      this.state.template = {
        subject: null,
        content: null,
        subjectReminder: null,
        contentReminder: null
      }
    }

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onContentChange = this.onContentChange.bind(this);
  }

  onContentChange(contentType, content){
    this.setState({
      template: {
        ...this.state.template,
        [contentType]: content
      }
    });
  };

  onChange(e){
    this.setState({
      template: {
        ...this.state.template,
        [e.target.name]: e.target.value
      }
    });
  }

  onClick(editorType){
    this.setState({
      [editorType]: !this.state[editorType]
    });
  }

  bulkEdit(template) {
    let bulkEditData = {};
    const keys = Object.keys(template);
    keys.forEach((key) => {
      if (Array.isArray(template[key]) && template[key].length > 0) {
        bulkEditData[key] = template[key];
      } else if (Array.isArray(template[key]) === false && template[key] !== null) {
        bulkEditData[key] = template[key];
      }
    });

    this.setState({saving: true});
    let toSend = {
      sendEmail: {
        template: bulkEditData
      }
    };
    api.surveyCollector.bulk(this.props.collector.ids, toSend)
    .then(() => {
      toast.success(<FormattedMessage id="collectors.bulkEditForm.toast.success" />);
      this.setState({saving: false, modalOpen: false});
      this.props.handleUpdate(toSend)
    })
    .catch(e => {
      this.setState({
        saving: false,
        errors: _.has(e, 'response.data.form.errors') ? e.response.data.form.errors : {},
      })
    });
  }

  onSubmit(e){
    e.preventDefault();
    const {template} = this.state;

    if (this.props.bulkEdit === true) {
      this.setState({modalOpen: true});
    } else {
      this.setState({saving: true});
      api.surveyCollector.patch({
        id: this.props.collector.id,
        surveyCollector: {
          sendEmail: {
            template
          }
        }
      }).then(collector => {
        this.setState({saving: false});
        this.props.handleUpdate(collector);
      }).catch(() => {
        this.setState({saving: false});
      });
    }
  }

  render() {
    const {saving, template, editorContentOpen, editorContentReminderOpen, modalOpen} = this.state;
    const {collector} = this.props;

    return (
      <Form onSubmit={this.onSubmit}>
        <ModalEmailVars collector={collector} />

        <FormGroup>
          <Label for="subject"><FormattedMessage id="surveyCollector.typeEmail.template.subject"/></Label>
          <Input
            id="subject"
            name="subject"
            onChange={this.onChange}
            value={template.subject || ""}
          />
        </FormGroup>
        <FormGroup className="mb-2">
          <Label for="content"><FormattedMessage id="surveyCollector.typeEmail.template.content"/></Label>
          {editorContentOpen ?
            <React.Fragment>
              <HtmlEditor
                data={template.content}
                onChange={(event) => this.onContentChange('content', event)}
                onBlur={(e) => this.onClick('editorContentOpen')}
              />
              <small>Liczba znaków: {template.content ? striptags(template.content.replace(/&nbsp;/g, "")).length : 0}</small>
            </React.Fragment> :
            <div onClick={() => this.onClick('editorContentOpen')}>{template.content ? reactHTMLParser(template.content) : <i>Brak treści, kliknij aby edytować</i>}</div>
          }
        </FormGroup>
        <FormGroup>
          <Label for="subjectReminder"><FormattedMessage id="surveyCollector.typeEmail.template.subjectReminder"/></Label>
          <Input
            id="subjectReminder"
            name="subjectReminder"
            onChange={this.onChange}
            value={template.subjectReminder || ""}
          />
        </FormGroup>
        <FormGroup>
          <Label for="contentReminder"><FormattedMessage id="surveyCollector.typeEmail.template.contentReminder"/></Label>
          {editorContentReminderOpen ?
            <React.Fragment>
              <HtmlEditor
                data={template.contentReminder}
                  onChange={(event) => this.onContentChange('contentReminder', event)}
                  onBlur={() => this.onClick('editorContentReminderOpen')}
              />
              <small>Liczba znaków: {template.contentReminder ? striptags(template.contentReminder.replace(/&nbsp;/g, "")).length : 0}</small>
            </React.Fragment> :
            <div onClick={() => this.onClick('editorContentReminderOpen')}>{template.contentReminder ? reactHTMLParser(template.contentReminder) : <i>Brak treści, kliknij aby edytować</i>}</div>
          }
        </FormGroup>

        <ModalSimple onAccept={() => this.bulkEdit(template)}
            onCancel={() => {this.setState({modalOpen: false})}}
            modalOpen={modalOpen} headerText={"Masowa edycja"}
            bodyText={"Czy na pewno chcesz dokonać grupowej zmiany kolektorów?"}/>

        <div className="direction-group">
          <div className="direction-right">
            <LaddaButton
              loading={saving}
              className="btn btn-primary btn-ladda mt-2"
              data-style={ZOOM_OUT}
            >
              <FormattedMessage id="_.button.save"/>
            </LaddaButton>
          </div>
        </div>
      </Form>
    )
  }
}

TabTemplate.defaultProps = {
  bulkEdit: false
}

TabTemplate.propTypes = {
  collector: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  bulkEdit: PropTypes.bool.isRequired
};

export default TabTemplate;