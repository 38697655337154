import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Col, Row} from "reactstrap";
import Rotate from "./components/Rotate";
import RandPages from "./components/RandPages";
import NavigationButtons from "./components/NavigationButtons";
import RandAndRotatePagesTable from "./components/RandAndRotatePagesTable";
import Disabled from "./components/Disabled";
import {updateBlock} from "scenes/Survey/Structure/data/blocks/actions";
import _ from "lodash";
import DisplayName from "./components/DisplayName";

class Settings extends Component {

  constructor(props) {
    super(props);

    this.handledUpdateBlock = this.handledUpdateBlock.bind(this);
  }

  handledUpdateBlock(block){
    this.props.updateBlock({
      id: this.props.block.id,
      block
    });
  }

  render() {
    const { block, pages } = this.props;
    const shouldRenderTable = block.rotatePages || block.randPages > 0;

    return (
      <Row>
        <Col className="p-4">
          <h5><FormattedMessage id="block.settings.header"/></h5>

          <Rotate block={block} handleUpdate={this.handledUpdateBlock}/>
          <RandPages block={block} handleUpdate={this.handledUpdateBlock}/>
          <Disabled block={block} handleUpdate={this.handledUpdateBlock}/>
          <DisplayName block={block} handleUpdate={this.handledUpdateBlock}/>
          <NavigationButtons pages={pages} block={block}/>

          {shouldRenderTable && <RandAndRotatePagesTable block={block} />}
        </Col>
      </Row>
    )
  }
}

Settings.propTypes = {
  block: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.number.isRequired,
      randQuestions: PropTypes.number.isRequired,
      timeMax: PropTypes.number.isRequired,
      timeMin: PropTypes.number.isRequired,
      rotateQuestions: PropTypes.bool.isRequired,
      hideNavigationClearButton: PropTypes.bool.isRequired,
      hideNavigationNextButton: PropTypes.bool.isRequired,
      hideNavigationPreviousButton: PropTypes.bool.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.number,
      ),
    })
  ).isRequired,
  updateBlock: PropTypes.func.isRequired
};

function mapStateToProps(state, props) {
  return {
    pages: _.filter(state.survey.structure.data.pages, function (page) {
      return state.survey.structure.data.blocks[props.block.id].pages.indexOf(page.id) > -1;
    })
  }
}

export default connect(mapStateToProps, { updateBlock })(Settings);
