import * as React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, Col, FormGroup, Input, Label} from "reactstrap";
import HelpModeIconTooltip from "../../../../../components/HelpModeIconTooltip";
import Select from "react-select";
import api from "../../../../../api";
import {toast} from "react-toastify";
import webhookEdit from "./webhookEdit";
import WebhookEdit from "./webhookEdit";

function Webhook(props) {
	const [endpoint, setEndpoint] = React.useState("");
	const [status, setStatus] = React.useState(0);
	const [newWebhookError, setNewWebhookError] = React.useState({});
	const [webhooks, setWebhooks] = React.useState(false);
	if (props.surveyId && webhooks === false) {
		api.survey.get.webhooks({id: props.surveyId}).then((webhooks) => {
			setWebhooks(webhooks);
		})
	}

	const getOptions = () => {
		return [
			{
				label: 'Strona zaproszenia',
				value: 1,
			},
			{
				label: 'Przerwanie ankiety',
				value: 2
			},
			{
				label: 'Zakończenie ankiety -  pełne wypełnienie ankiety (complete)',
				value: 3
			},
			{
				label: 'Zakończenie ankiety -  brak kwalifikacji do ankiety (screenout)',
				value: 4
			}
		];
	}

	const create = () => {
		api.survey.post.webhooks(props.surveyId, {
			data: {
				endpoint,
				status
			}
		}).then(webhook => {
			webhooks.push(webhook);
			setWebhooks(webhooks);
			setEndpoint("");
			setStatus(0);
			setNewWebhookError({});
			toast.success("Dodanie webhook się powiodło");
		}).catch(res => {
			if (res.response && res.response.data.form) {
				setNewWebhookError(res.response.data.form.errors);
			}

			toast.error("Dodanie webhook nie powiodło się");
		});
	}

	return (
		<div>
			{webhooks && webhooks.map((webhook, index) => {
				return (
					<WebhookEdit webhook={webhook} index={index} surveyId={props.surveyId}/>
				);
			})}
			<FormGroup>
				<Label lg={12} className="text-lg-center">
					Dodaj nowy webhook
				</Label>
			</FormGroup>
			<FormGroup row>
				<Label lg={3} className="text-lg-right">
					Endpoint
					<HelpModeIconTooltip className="ml-2">Adres url do którego ma być wywołany
						webhook za pomocą metody POST</HelpModeIconTooltip>
				</Label>
				<Col lg={9}>
					<Input onChange={(e) => setEndpoint(e.target.value)} value={endpoint}/>
					{newWebhookError.endpoint && <div className="d-block invalid-feedback">{newWebhookError.endpoint.join('; ')}</div>}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label lg={3} className={"text-lg-right"}>
					Status
					<HelpModeIconTooltip className="ml-2">Określa status kiedy zostanie wysłany webhook</HelpModeIconTooltip>
				</Label>
				<Col lg={9} className="mb-2">
					<Select
						className={"react-select"}
						options={getOptions()}
						placeholder="Wybierz status webhook"
						value={status ? getOptions().find(option => option.value === status) : null}
						onChange={option => {
							setStatus(option.value);
						}}
					/>
					{newWebhookError.status && <div className="d-block invalid-feedback">{newWebhookError.status.join('; ')}</div>}
				</Col>
			</FormGroup>
			<FormGroup row className="flex-row-reverse">
				<Button onClick={() => create()} className="ladda-button btn btn-primary btn-ladda">Stwórz</Button>
			</FormGroup>
		</div>
	);
}

Webhook.propTypes = {
	surveyId: PropTypes.number.isRequired
};

function mapStateToProps(state) {
	return {}
}

export default connect(mapStateToProps)(Webhook);