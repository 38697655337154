import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import { Col, Form, FormGroup, Input, Label} from "reactstrap";
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from "react-select";
import { connect } from 'react-redux';
import Autocomplete from "react-async-autocomplete";
import api from 'api';
import Switch from "../../../../components/Switch";

class RespondentForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: {
        verified: props.user ? props.user.verified : false,
        verifiedMsg: props.user ? props.user.verifiedMsg : '',
        email: props.user ? props.user.email : '',
        firstname: props.user ? props.user.firstname : '',
        lastname: props.user ? props.user.lastname : '',
        username: props.user ? props.user.username : '',
        sex: props.user ? props.user.sex  : 'M',
        birthdate: props.user && props.user.birthdate ? moment(props.user.birthdate) : null,
        city: props.user && props.user.city ? props.user.city.id : null,
        userPlan: {
          expireDate: moment(props.user ? props.user.userPlan.expireDate : new Date()).format('YYYY-MM-DD'),
          plan: (props.user && props.user.userPlan.plan) ? props.user.userPlan.plan.id : null
        },
      }
    };

    if (!props.user) {
      this.state.user.password = '';
      this.state.user.email = '';
    }

    this.autocomplete = React.createRef();
    this.onChange = this.onChange.bind(this);
    this.onPlanChange = this.onPlanChange.bind(this);
    this.onPlanExpireDateChange = this.onPlanExpireDateChange.bind(this);
    this.autocompleteCity = this.autocompleteCity.bind(this);
    this.onSelectCity = this.onSelectCity.bind(this);
  }

  onChange(e) {
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value
      }
    });
  }

  onPlanChange(e) {
    this.setState({
      user: {
        ...this.state.user,
        userPlan: {
          ...this.state.user.userPlan,
          plan: e.value
        }
      }
    })
  }

  onPlanExpireDateChange(date) {
    this.setState({
      user: {
        ...this.state.user,
        userPlan: {
          ...this.state.user.userPlan,
          expireDate: date.format('YYYY-MM-DD')
        }
      }
    })
  }

  autocompleteCity(query) {
    api.user.get.cities(query)
      .then((result) => {
        this.autocomplete.current.setItems(result);
      })
  }

  onSelectCity(city) {
    this.setState({
      user: {
        ...this.state.user,
        city: city.id
      }
    });

    return city.name;
  }

  render() {
    const {user} = this.state;
    const {errors, onSubmit, plans} = this.props;

    const sexOptions = [
      {
        label: <FormattedMessage id="admin.respondents.respondentForm.label.sex.m" />,
        value: 'M'
      },{
        label: <FormattedMessage id="admin.respondents.respondentForm.label.sex.f" />,
        value: 'F'
      }
    ];

    const planOptions = _.map(plans, plan => {
      let name = plan.name;
      if (plan.demo) {
        name += ' - demo';
      }
      return {label: name, value: plan.id}
    });

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.email" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="email" id="email" value={user.email} invalid={!!errors.email} onChange={this.onChange}/>
            {errors.email && <p className="help-block text-danger">{errors.email}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.firstname" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="firstname" id="firstname" value={user.firstname} invalid={!!errors.firstname} onChange={this.onChange}/>
            {errors.firstname && <p className="help-block text-danger">{errors.firstname}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.lastname" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="lastname" id="lastname" value={user.lastname} invalid={!!errors.lastname} onChange={this.onChange}/>
            {errors.lastname && <p className="help-block text-danger">{errors.lastname}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.username" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="username" id="username" value={user.username} invalid={!!errors.username} onChange={this.onChange}/>
            {errors.username && <p className="help-block text-danger">{errors.username}</p>}
          </Col>
        </FormGroup>
        {!this.props.user && <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.email" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="email" name="email" id="email" value={user.email} invalid={!!errors.email} onChange={this.onChange}/>
            {errors.email && <p className="help-block text-danger">{errors.email}</p>}
          </Col>
        </FormGroup>}
        {!this.props.user && <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.password" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="password" name="password" id="password" value={user.password} invalid={!!errors.password} onChange={this.onChange}/>
            {errors.password && <p className="help-block text-danger">{errors.password}</p>}
          </Col>
        </FormGroup>}
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.sex" /></Label>
          <Col lg={9} className="pt-1">
            <Select
              isClearable={false}
              value={_.find(sexOptions, option => option.value === user.sex)}
              options={sexOptions}
              onChange={e => this.onChange({target: {name: 'sex', value: e.value}})}
            />
            {errors.sex && <p className="help-block text-danger">{errors.sex}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.birthdate" /></Label>
          <Col lg={9} className="pt-1">
            <DatePicker
              selected={user.birthdate ? moment(user.birthdate) : null}
              onChange={(date) => this.onChange({target: {name: 'birthdate', value: date.format('YYYY-MM-DD')}})}
              dateFormat="Y-MM-DD"
              locale={localStorage.language}
              dropdownMode="select"
            />
            {errors.birthdate && <p className="help-block text-danger">{errors.birthdate}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.city" /></Label>
          <Col lg={9} className="pt-1">
            <Autocomplete
              ref={this.autocomplete}
              renderItem={({item}) => <span>{item.name}</span>}
              onChange={this.autocompleteCity}
              onSelect={this.onSelectCity}
              value={this.props.user && this.props.user.city ? this.props.user.city.name : ""}
            />
            {errors.city && <p className="help-block text-danger">{errors.city}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.plan" /></Label>
          <Col lg={9} className="pt-1">
            <Select
              isClearable={false}
              value={_.find(planOptions, option => option.value === user.userPlan.plan)}
              options={planOptions}
              onChange={this.onPlanChange}
            />
            {errors.userPlan && errors.userPlan.plan && <p className="help-block text-danger">{errors.userPlan.plan}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.respondents.respondentForm.label.planExpireDate" /></Label>
          <Col lg={9} className="pt-1">
            <DatePicker
              selected={moment(user.userPlan.expireDate)}
              onChange={this.onPlanExpireDateChange}
              dateFormat="Y-MM-DD"
              locale={localStorage.language}
              dropdownMode="select"
            />
            {errors.userPlan && errors.userPlan.expireDate && <p className="help-block text-danger">{errors.userPlan.expireDate}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right">Zweryfikowany</Label>
          <Col lg={9} className="pt-1">
            <Switch onChange={verified => this.setState({user: {...user, verified}})} checked={!!user.verified} />
          </Col>
        </FormGroup>
        {user.verified && <FormGroup row>
          <Label lg={3} className="text-lg-right">Zweryfikowany - opis</Label>
          <Col lg={9} className="pt-1">
            <Input type="textarea" rows={4} name="verifiedMsg" id="verifiedMsg" value={user.verifiedMsg} onChange={this.onChange}/>
          </Col>
        </FormGroup>}
      </Form>
    )
  }
}

RespondentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  user: PropTypes.object,
  plans: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    plans: state.admin.plans.data.plans
  }
}

export default connect(mapStateToProps, {}, null, {forwardRef: true})(RespondentForm);
