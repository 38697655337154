import React, {Component} from 'react';
import {Form, Label, Input, Row, Col} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class AddressForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: props.address ? props.address.email : '',
    };

    for (let i = 1; i <= props.columnsCount; i++) {
      let columnName = "column_" + i;
      this.state[columnName] = props.address && props.address[columnName] ? props.address[columnName]: ''
    }

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  render() {
    const {errors, columns, columnsCount} = this.props;

    let userColumnsInputs = [];
    for (let i = 1; i <= columnsCount; i++) {
      let columnName = "column_" + i;
      let columnIndex = i - 1;

      userColumnsInputs .push(
        <Row className="mb-1" key={Object.values(columns)[columnIndex].id}>
          <Col lg={3}>
            <Label for={columnName}>{Object.values(columns)[columnIndex].name}</Label>
          </Col>
          <Col lg={9}>
            <Input className="mb-0" type="text" name={columnName} id={columnName} invalid={!!errors[columnName]} value={this.state[columnName]} onChange={this.onChange}/>
            {errors[columnName] && <div className="invalid-feedback">{errors[columnName].join('; ')}</div>}
          </Col>
        </Row>
      );
    }

    return (
      <Form onSubmit={e => this.props.onSubmit(e, this.state)}>
        <Row className="mb-1">
          <Col lg={3}>
            <Label for="email"><FormattedMessage id="addressForm.label.email" /></Label>
          </Col>
          <Col lg={9}>
            <Input className="mb-0" type="email" name="email" id="email" invalid={!!errors.email} value={this.state.email} onChange={this.onChange}/>
            {errors.email && <div className="invalid-feedback">{errors.email.errors.join('; ')}</div>}
          </Col>
        </Row>
        {userColumnsInputs}
      </Form>
    )
  }
}

AddressForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  address: PropTypes.object,
  errors: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    columnsCount: Object.keys(state.user.respondentBaseColumns).length,
    columns: state.user.respondentBaseColumns
  }
}

export default connect(mapStateToProps, {}, null, {forwardRef: true})(AddressForm);