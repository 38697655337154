import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import ConfigurationCreateButton from "../Configurations/components/ConfigurationCreateButton";
import ConfigurationList from "../Configurations/components/ConfigurationList";
import UserEmailDomainBlacklist from "./UserEmailDomainBlacklist";

const UserEmailDomainBlacklistView = props => {
  return (
    <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader>
            <div className="direction-group">
              <div className="direction-left d-flex justify-content-start">
                <h2 className="float-left mr-4">
                  Lista zablokowanych domen
                </h2>
              </div>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <UserEmailDomainBlacklist />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

UserEmailDomainBlacklistView.propTypes = {

};

export default UserEmailDomainBlacklistView;