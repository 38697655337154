import React, {Component} from 'react';
import ShareCheckForm from './ShareCheckForm';
import {Alert, Row, Container} from 'reactstrap';
import api from "api";
import withParams from "../../utils/withParams";
import QuotasList from "components/Survey/Quotas/components/QuotasList";

class QuotasShared extends Component {

  constructor(props) {
    super(props);

    this.state = {
      surveyId: null,
      surveyName: '',
      loading: true,
    };
  }

  render() {
    const {surveyId, surveyName} = this.state;

    return (
      <main className="app align-items-center">
        <Row className="justify-content-center">
          <React.Fragment>
            {!surveyId && <ShareCheckForm
              apiCheckFn={api.surveyShared.quotas.check}
              title="Lista kwot"
              guid={this.props.params.guid}
              handle={data => {
                this.setState({
                  surveyId: data.surveyId,
                  surveyName: data.surveyName,
                })
                document.title = `Ankieteo - ${data.surveyName}`;
              }}
            />}
            {surveyId && <div className="d-flex flex-column w-100" style={{maxWidth: '1000px'}}>
              <h1 className="text-center">{surveyName}</h1>
              <QuotasList
                surveyId={surveyId}
                ref={'quotasList'}
                editable={false}
              />
            </div>}
          </React.Fragment>
        </Row>
      </main>
    )
  }
}

export default withParams(QuotasShared);