import React, {Component} from 'react';
import PropTypes from "prop-types";
import Select from "react-select";
import {Button, InputGroup, InputGroupAddon, Tooltip} from "reactstrap";
import classnames from "classnames";

const options = [
  { name: 'Polska', lat: 52.0136673, lng: 19.4960659, zoom: 5.5 },
  { name: 'Warszawa', lat: 52.2313295, lng: 21.0156407, zoom: 9.5 },
  { name: 'Kraków', lat: 50.0513742, lng: 19.9959453, zoom: 10 },
  { name: 'Wrocław', lat: 51.1271402, lng: 17.0057747, zoom: 10.25 },
  { name: 'Twójmiasto', lat: 54.3858053, lng: 18.6085009, zoom: 9 },
  { name: 'Śląsk', lat: 50.2596893, lng: 18.9890196, zoom: 8.5 },
  { name: 'Poznań', lat: 52.4068102, lng: 16.916066, zoom: 9.5 },
  { name: 'Europa', lat: 49.17674, lng: 17.7811489, zoom: 3.7 },
  { name: 'USA', lat: 37.7463657, lng: -99.9516614, zoom: 4 },
];

class DefaultMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: false,
    };

    this.onChange = this.onChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  onChange(value){
    const option = options.find(o => o.name === value.value);

    this.props.handleUpdate({
      lat: option.lat,
      lng: option.lng,
      zoom: option.zoom,
    });
  }

  render() {
    const {tooltip} = this.state;
    const {question} = this.props;

    let value = null;

    if(question.lat){
      value = options.find(o => o.lat === question.lat && o.lng === question.lng && o.zoom === question.zoom);

      if(value){
        value = {
          label: value.name,
          value: value.name,
        }
      }
    }

    return <InputGroup className="mr-1 float-left w-100 w-lg-auto mb-1" id={"defaultMap-" + this.props.question.id}>
      <InputGroupAddon addonType="prepend">
        <Button
          className={classnames({
            "btn-icon active": true,
          })}
          color="secondary"
        >
          <i className="fa-solid fa-map-location-dot"/>
          <Tooltip
            delay={0}
            placement="top"
            isOpen={tooltip}
            target={"defaultMap-" + this.props.question.id}
            toggle={() => this.toggleTooltip()}
          >Domyślny obszar ustawienia mapy</Tooltip>
        </Button>
      </InputGroupAddon>
      <Select
        classNamePrefix="react-select"
        className="react-select float-left"
        options={options.map(option => {
          return {
            label: option.name,
            value: option.name,
          }
        })}
        value={value}
        onChange={this.onChange}
      />
    </InputGroup>
  }
}

DefaultMap.propTypes = {
  question: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default DefaultMap;
