import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Stat from "./Stat";
import api from "api";

class StatNumbers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: {},
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    setTimeout(() => {
      this.fetchData();
    }, 10);
  }

  fetchData(){
    const {dateFrom, dateTo} = this.props;

    this.setState({
      loading: true,
      data: {},
    });

    api.account.stats.main({
      dateFrom,
      dateTo,
    })
      .then(data => {
        this.setState({
          loading: false,
          data
        });
      })
      .catch(e => {
        this.setState({
          loading: false,
        });
      })
  }

  render() {
    const {data} = this.state;

    return (
      <div className="d-flex justify-content-between flex-column flex-md-row">
        <Stat
          title={"Aktywne ankiety"}
          numberCurrent={data.activeSurveys && data.activeSurveys.count}
        />
        <Stat
          title={"Wszystkie ankiety"}
          numberCurrent={data.allSurveys && data.allSurveys.count}
          numberCompare={data.allSurveys && data.allSurveys.countCompare}
          compared={"30 dni"}
        />
        <Stat
          title={"Wywiady"}
          numberCurrent={data.responses && data.responses.count}
          numberCompare={data.responses && data.responses.countCompare}
          compared={"30 dni"}
          tooltip={"Liczba wszystkich zakończonych wywiadów. Nie są liczone wywiady odrzucone i niepełne"}
        />
        <Stat
          title={"Użytkownicy"}
          numberCurrent={data.users && data.users.count}
          numberCompare={data.users && data.users.countCompare}
          compared={"30 dni"}
        />
      </div>
    );
  }
}

StatNumbers.propTypes = {
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
};

export default StatNumbers;