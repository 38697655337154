import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import QuotaForm from "./QuotaForm";
import api from "api";


class ButtonAddQuota extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      saving: false,
      errors: {},
      quota: this.getQuotaFromProps(props),
    };

    this.form = React.createRef();
    this.toggleModal = this.toggleModal.bind(this);
    this.handlerOnSubmit = this.handlerOnSubmit.bind(this);
  }

  getQuotaFromProps(props){
    let quota = props.quota;

    quota.conds = quota.conds.map(cond => {
      cond.question = cond.question.id;
      cond.answers = cond.answers.map(answer => answer.id);

      return cond;
    });

    return quota;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      quota: this.getQuotaFromProps(nextProps)
    })
  }

  toggleModal(){
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  handlerOnSubmit(data){
    const {quota} = this.props;

    this.setState({
      saving: true,
      errors: {}
    });

    api.quota.patch(quota.id, data)
      .then(quota => {
        this.setState({
          saving: false,
          modalOpen: false,
        });

        this.props.onSaved(quota);
      })
      .catch(res => {
        this.setState({
          saving: false,
          errors: res.response.data.form.errors,
        });

      });
  }

  render() {
    const {modalOpen, saving, errors, quota} = this.state;
    const {surveyId} = this.props;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal} tag="h2">Edytuj kwotę</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <QuotaForm
          quota={quota}
          surveyId={surveyId}
          onSubmit={this.handlerOnSubmit}
          errors={errors}
          ref={this.form}
        />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={() => this.form.current.onSubmit()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <a className="ml-2" onClick={this.toggleModal}>
          <i className="fas fa-edit"/>
        </a>
        {modal}
      </React.Fragment>
    );
  }
}

ButtonAddQuota.propTypes = {
  surveyId: PropTypes.number.isRequired,
  onSaved: PropTypes.func.isRequired,
  quota: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ButtonAddQuota;