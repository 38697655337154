import React, {Component} from 'react';
import PropTypes from 'prop-types';
import striptags from "striptags";
import classnames from "classnames";

class ColumnTbody extends Component {
  getExcluding(excludingId){
    return this.props.question.excludings.find(excluding => excluding.id === parseInt(excludingId));
  }

  render() {
    const {question, questionResponse} = this.props;

    let td = [];
    for(let position = 0; position < question.answersCount; position++){
      let detail = false;

      if(questionResponse){
        if(!questionResponse.excluding && questionResponse.details.find(detail => detail.position === position)){
          detail = questionResponse.details.find(detail => detail.position === position);
        }
      }

      td.push(<td className={classnames({
        'text-center': true,
        'border-left': position === 0,
      })}>
        {detail ? detail.lat : ''}
      </td>);

      td.push(<td className={classnames({
        'text-center': true,
      })}>
        {detail ? detail.lng : ''}
      </td>);

      td.push(<td className={classnames({
        'text-center': true,
        'border-right': position === question.answersCount-1,
      })}>
        {detail ? detail.formattedAddress : ''}
      </td>);
    }

    return <React.Fragment>
      {td}

      {question.excludings.length > 0 && <td className="text-center border-left border-right">
        {(questionResponse && questionResponse.excluding) ? <div className="" dangerouslySetInnerHTML={{__html: striptags(this.getExcluding(questionResponse.excluding).content)}} /> : ''}
      </td>}
    </React.Fragment>;
  }
}

ColumnTbody.propTypes = {
  question: PropTypes.shape({
    type: PropTypes.oneOf(['gMap']),
    answersCount: PropTypes.number.isRequired,
    excludings: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),),
  }).isRequired,
  questionResponse: PropTypes.shape({
    details: PropTypes.arrayOf(PropTypes.shape({

    }).isRequired,),
  }),
};

export default ColumnTbody;