import React, {Component} from 'react';
import PropTypes from "prop-types";

import ChartPie from "./ChartPie";
import ChartDonut from "./ChartDonut";
import ChartLine from "./ChartLine";
import ChartColumn from "./ChartColumn";
import ChartPie3d from "./ChartPie3d";
import ChartBar from "./ChartBar";
import ChartTagCloud from "./ChartTagCloud";
import ChartHeat from "./ChartHeat";
import ChartStackedBar from "./ChartStackedBar";
import ChartStackedBarPercent from "./ChartStackedBarPercent";
import ChartHistogram from "./ChartHistogram";
import ChartGMap from "./ChartGMap";
import ChartStackedBarNegValues from "./ChartStackedBarNegValues";
import ChartDifferential from "./ChartDifferential";

class Chart extends Component {

  constructor(props) {
    super(props);
  }

  getChartComponent(){
    const {id, type, data, n} = this.props;

    switch (type) {
      case 'pie': return <ChartPie id={id} data={data}/>;
      case 'pie3d': return <ChartPie3d id={id} data={data}/>;
      case 'donut': return <ChartDonut id={id} data={data}/>;
      case 'line': return <ChartLine id={id} data={data} n={n}/>;
      case 'column': return <ChartColumn id={id} data={data} n={n}/>;
      case 'histogram': return <ChartHistogram id={id} data={data} n={n}/>;
      case 'bar': return <ChartBar id={id} data={data} n={n}/>;
      case 'tagCloud': return <ChartTagCloud id={id} data={data}/>;
      case 'heat': return <ChartHeat id={id} data={data} n={n}/>;
      case 'stackedBar': return <ChartStackedBar id={id} data={data} n={n}/>;
      case 'stackedBarPercent': return <ChartStackedBarPercent id={id} data={data} n={n}/>;
      case 'gmap': return <ChartGMap id={id} data={data}/>;
      case 'stackedBarNegValues': return <ChartStackedBarNegValues id={id} data={data} n={n}/>;
      case 'differential': return <ChartDifferential id={id} data={data} scaleSize={this.props.scaleSize}/>;
    }
  }

  render() {
    const {id} = this.props;

    return (
      <React.Fragment>
        {this.getChartComponent()}
      </React.Fragment>
    )
  }
}

Chart.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  n: PropTypes.number.isRequired
};

export default Chart;