import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import Switch from "components/Switch";
import {updateSurvey} from "../../../Structure/data/survey/actions";
import { connect } from 'react-redux';
import classnames from "classnames";

class SocialMediaShareSwitch extends PureComponent {
  constructor(props) {
    super(props);
    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(value){
    this.props.updateSurvey({
      id: this.props.survey.id,
      survey: {
        shares: {
          [this.props.type]: value
        }
      }
    })
      .then(() => this.props.handlerUpdate());
  }

  render() {
    if (!this.props.survey.shares) {
      return null;
    }

    return (
      <Row className={classnames({
        'py-1 px-3 align-items-center': true,
        'inactive': !this.props.survey.shares[this.props.type],
      })}>
        <label className="col col-4">Udostępnianie - {this.props.type}</label>
        <Col className="col-8">
          <Switch onChange={this.updateValue} checked={this.props.survey.shares[this.props.type]} />
        </Col>
      </Row>
    );
  }
}

SocialMediaShareSwitch.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  survey: PropTypes.object.isRequired
};

export default connect(null, {updateSurvey})(SocialMediaShareSwitch);