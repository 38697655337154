import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import {updateUser} from '../actions';
import {FormattedMessage} from 'react-intl';

class GroupUserBlockModal extends Component{
  constructor(props){
    super(props);

    this.state = {
      blocked: props.user.blocked,
      saving: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const {blocked} = this.state;

    this.setState({
      saving: true
    });

    this.props.updateUser({
      groupId: this.props.group.id,
      groupUserId: this.props.user.id,
      data: {
        blocked: !blocked
      }
    })
    .then(user => {
      this.setState({
        saving: false,
        blocked: user.blocked
      }, () => this.props.toggleModal());
    })
    .catch(() => {
      this.setState({
        saving: false,
      });
    });
  }

  render() {
    const {blocked, saving} = this.state;
    const {user, isOpen} = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.props.toggleModal} tag="h2"><FormattedMessage id={blocked ? 'groupUserBlockModal.modalHeader.disable' : 'groupUserBlockModal.modalHeader.enable'}/></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
          <p><FormattedMessage id={blocked ? 'groupUserBlockModal.modalBody.disable' : 'groupUserBlockModal.modalBody.enable'}/> {user.email}?</p>
        </ModalBody>
        <ModalFooter>
          <div className="direction-group d-flex justify-content-end">
            <div className="direction-right">
              <FormattedMessage id="_.button.yes">{
                (message) => <LaddaButton
                  className="btn btn-primary btn-ladda mb-0 ml-3"
                  loading={saving}
                  data-style={ZOOM_OUT}
                  onClick={this.onSubmit}
                >
                  {message}
                </LaddaButton>
              }</FormattedMessage>
              <FormattedMessage id="_.button.cancel">{
                (message) => <Button color="secondary" className="mb-0 ml-3" onClick={this.props.toggleModal}>{message}</Button>
              }</FormattedMessage>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

GroupUserBlockModal.propTypes = {
  user: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default connect(null, {updateUser})(GroupUserBlockModal);
