import React, {Component} from 'react';
import PropTypes from "prop-types";
import AnyChart from "anychart-react";

class ChartBar extends Component {

  render() {
    const {id, data, n} = this.props;

    let height = 130;
    height += (this.props.data.length * 65);

    let stage = anychart.graphics.create();
    var chart = anychart.bar(data);
    chart.yScale().minimum(0);
    chart.yScale().maximum(n);
    chart.labels().enabled(true).format(row => Math.round(row.value / n * 1000) / 10 + '%');
    chart.labels().background('#ffffff');
    chart.labels().fontColor("#000000");
    chart.labels().position("center");

    var tooltip = chart.getSeries(0).tooltip();
    tooltip.format(row => "Liczba wskazań: " + row.value + "\nProcentowo: " + Math.round(row.value / n * 1000) / 10 + "%");

    var yAxis2 = chart.yAxis(1);
    yAxis2.orientation("top");
    yAxis2.labels().format(row => Math.round(row.tickValue / n * 1000) / 10 + '%');

    var xLabels = chart.xAxis().labels();
    xLabels.wordWrap("break-word");
    xLabels.wordBreak("break-all");
    xLabels.width('20%');
    xLabels.hAlign("right");

    return (
      <AnyChart
        id={'question-' + id}
        height={height}
        instance={stage}
        charts={[chart]}
        yAxis={{
          labels: {
            wordWrap: 'break-word',
            wordBreak: 'break-all',
          }
        }}
      />
    )
  }
}

ChartBar.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,).isRequired,
  n: PropTypes.number.isRequired
};

export default ChartBar;