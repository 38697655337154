import api from "api";
import * as questionsActions from "../questions/actions";
import {ATTRIBUTE_ADDED} from "../questions/actions";

export const SET = 'Survey/data/attributes/SET';
export const ADDED = 'Survey/data/attributes/ADDED';
export const DELETED = 'Survey/data/attributes/DELETED';
export const UPDATED = 'Survey/data/attributes/UPDATED';
export const LEVEL_ADDED = 'Survey/data/attributes/LEVEL_ADDED';

export function setAttributes(attributes) {
  return {
    type: SET,
    attributes
  }
}
export function attributeAdded(attribute) {
  return {
    type: ADDED,
    attribute
  }
}
export function attributeDeleted(attribute) {
  return {
    type: DELETED,
    attribute
  }
}
export function attributeUpdated(attribute) {
  return {
    type: UPDATED,
    attribute
  }
}
export function levelAdded(level) {
  return {
    type: LEVEL_ADDED,
    level
  }
}





export function addAttribute(data) {
  return dispatch => {
    return api.question.attributes.post(data).then(data => {
      dispatch(attributeAdded(data.data));
      dispatch(questionsActions.attributeAdded(data.data));

      return data.data;
    });
  }
}

export function deleteAttribute(data) {
  return dispatch => {
    return api.question.attributes.delete(data.id).then(res => {
      dispatch(attributeDeleted({
        id: data.id,
        question: {
          id: data.question.id
        }
      }))
    })
  }
}

export function updateAttribute(id, data) {
  return dispatch => {
    return api.question.attributes.patch(id, data).then(data => {
      dispatch(attributeUpdated(data.data))
    })
  }
}