import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import Select from "react-select";
import {Button, Col, Form, FormGroup} from "reactstrap";
import api from "api";
import {ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";
import {toast} from "react-toastify";

class AddRespondents extends Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      saving: false,
      loadingRespondentBases: false,
      respondentBases: [],
      value: []
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    this.setState({
      loadingRespondentBases: true,
    });

    api.respondentBase.get({
      page: 0,
      pageSize: 1000
    })
      .then(data => {
        this.setState({
          loadingRespondentBases: false,
          respondentBases: data.respondentBases,
        });
      })
      .catch(() => {
        this.setState({
          loadingRespondentBases: false,
        });
      });
  }

  onChange(values){
    this.setState({
      values
    });
  }

  onSubmit(e){
    e.preventDefault();

    const {values} = this.state;

    if(_.isEmpty(values)){
      return;
    }

    this.setState({
      saving: true
    });

    api.surveyCollector.email.post.addresses.respondentBases({
      id: this.props.collector.id,
      data: {
        respondentBases: values.map(value => value.value),
      },
    }).then(data => {
      this.setState({saving: false});
      this.props.handleAddAddresses(data.addresses);
    }).catch((error) => {
      _.map(error.response.data.form.errors.addresses, err => {
        toast.warn(err.respondentAddress.join('; '));
      });

      this.setState({
        saving: false
      });
    });
  }

  render() {
    const {visible, saving, loadingRespondentBases, respondentBases, values} = this.state;

    if(!visible){
      return <Button color="primary" className="mb-2" onClick={() => this.setState({visible: true})}>Dodaj adresy</Button>
    }

    return (
      <Form className="my-3" onSubmit={this.onSubmit}>
        <FormGroup row>
          <Col sm={8} md={9} lg={10} style={{
            zIndex: 2
          }}>
            <Select
              placeholder={<FormattedMessage id="surveyCollector.typeEmail.addresses.addRespondents.selectPlaceholder"/>}
              isMulti={true}
              isLoading={loadingRespondentBases}
              value={values}
              onChange={this.onChange}
              options={respondentBases.map(base => {
                return {
                  label: base.name + ' ('+base.addressesCount+')',
                  value: base.id
                }
              })}
            />
          </Col>
          <Col sm={4} md={3} lg={2}>
            <LaddaButton
              loading={saving}
              className="btn btn-primary btn-ladda w-100"
              data-style={ZOOM_OUT}
              disabled={_.isEmpty(values)}
            >
              <i className="fas fa-plus" /> <FormattedMessage id="surveyCollector.typeEmail.addresses.addRespondents.buttonSubmit"/>
            </LaddaButton>
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

AddRespondents.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  handleAddAddresses: PropTypes.func.isRequired
};

export default AddRespondents;
