import _ from "lodash";

import pl from './messages/pl';
import en from './messages/en';
require('@formatjs/intl-pluralrules/polyfill')
require('@formatjs/intl-pluralrules/locale-data/pl')
require('@formatjs/intl-pluralrules/locale-data/en')

require('@formatjs/intl-relativetimeformat/polyfill')
require('@formatjs/intl-relativetimeformat/locale-data/pl')
require('@formatjs/intl-relativetimeformat/locale-data/en')

export const messagesLangs = {
  en,
  pl,
};

if(_.isEmpty(localStorage.language)){
  localStorage.language = 'pl';
}

export default {
  locale: localStorage.language,
  messages: messagesLangs[localStorage.language]
}