import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import ReactTable from 'react-table';
import Pagination from "components/Pagination";
import api from 'api';
import moment from "moment";
import appRoutes from "appRoutes";
import PropTypes from "prop-types";

class PanelShippingList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
    this.actions = this.actions.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const filtersWereChanged = this.props.search !== nextProps.search;
    if (filtersWereChanged) {
      clearTimeout(this.state.searchTimeout);
      this.setState({
        searchTimeout: setTimeout(() => {
          this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, nextProps);
        }, 1000)
      });
    }
  }

  fetchData(state, props) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        sort: state.sorted,
        search: props.search,
      }
    };

    api.surveyCollector.panel.post.searchCollectors(data).then(res => {
      this.setState({searching: false, data: res.panels, count: res.count});
    });
  }

  stats(collector) {
    if (!collector.sendEmail) {
      return <p className="m-0"><FormattedMessage id="admin.panelShippingList.stats.noSendEmail" /></p>
    }

    return (
      <div>
        <p className="m-0"><FormattedMessage id="admin.panelShippingList.stats.addressesCount" />: {collector.sendEmail.addressesCount}</p>
        <p className="m-0"><FormattedMessage id="admin.panelShippingList.stats.sentCount" />: {collector.sendEmail.sentCount}</p>
      </div>
    )
  }

  actions(collector) {
    return (
      <i className="fas fa-edit pointer" onClick={() => this.props.history.push(appRoutes.survey.collectors.collector.view(collector.survey.id, collector.id))}/>
    )
  }

  render() {
    const columns = [{
      Header: <FormattedMessage id="admin.panelShippingList.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      id: 'surveyName',
      Header: <FormattedMessage id="admin.panelShippingList.listItems.tableHeader.surveyName" />,
      accessor: collector => collector.survey.name
    }, {
      id: 'surveyId',
      Header: 'Id ankiety',
      accessor: collector => collector.survey.id
    }, {
      id: 'stats',
      Header: <FormattedMessage id="admin.panelShippingList.listItems.tableHeader.stats" />,
      accessor: this.stats,
      sortable: false
    }, {
      id: 'createdAt',
      Header: <FormattedMessage id="admin.panelShippingList.listItems.tableHeader.createdAt" />,
      accessor: collector => moment(collector.createdAt).format('YYYY-MM-DD HH:mm')
    }, {
      id: 'actions',
      Header: <FormattedMessage id="admin.panelShippingList.listItems.tableHeader.actions" />,
      accessor: this.actions,
      sortable: false
    }];

    return (
      <ReactTable
        minRows={0}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={20}
        showPagination={!_.isEmpty(this.state.data)}
        PaginationComponent={Pagination}
        noDataText={<FormattedMessage id="admin.panelShippingList.listItems.table.noShipping" />}
        loading={this.state.searching}
        onFetchData={this.fetchData}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    )
  }
}

PanelShippingList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
};

export default PanelShippingList;