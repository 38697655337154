import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Button, Tooltip} from "reactstrap";
import classnames from "classnames";

class Rotate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      block: {
        rotatePages: props.block.rotatePages,
      },
      tooltip: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleRotate = this.toggleRotate.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleRotate() {
    this.setState({
      block: {
        rotatePages: !this.state.block.rotatePages
      }
    }, () => {
      this.props.handleUpdate(this.state.block)
    });
  }

  render() {
    let { tooltip } = this.state;
    return (
      <Button
        className={classnames({
          "float-left mr-1 btn-icon mb-1": true,
          "active": this.state.block.rotatePages
        })}
        color={'secondary'}
        id="rotate"
        onClick={this.toggleRotate}
      >
        <i className="fas fa-random" />

        <Tooltip
          delay={0}
          placement="top"
          isOpen={tooltip}
          target="rotate"
          toggle={() => this.toggleTooltip()}
        >
          <FormattedMessage id="block.settings.rotate.button.tooltip"/>
        </Tooltip>
      </Button>
    )
  }
}

Rotate.propTypes = {
  block: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default Rotate;