import {SET, UPDATED} from './actionTypes';
import _ from "lodash";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.responses;

    case UPDATED:
      let response = _.find(state, response => response.id === action.response.id);
      state[state.indexOf(response)] = {...response, ...action.response};

    default:
      return state;
  }
};