import React, {PureComponent} from 'react';
import SelectValue from "../../components/SelectValue";
import classnames from "classnames";
import {Col, Row, Tooltip} from "reactstrap";

const KEY_NOT_CHECKED = '$question-answerArea-radioNotChecked';
const KEY_CHECKED = '$question-answerArea-radioChecked';

class Radio extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selectMenuOpen: false,
      tooltip: {
        radioNotChecked: false,
        radioChecked: false,
      }
    };
  }

  toggleTooltip(key){
    this.setState({
      tooltip: {
        ...this.state.tooltip,
        [key]: !this.state.tooltip[key]
      }
    })
  }

  clearValues(){
    this.props.handlerUpdate({[KEY_NOT_CHECKED]: ''});
    this.props.handlerUpdate({[KEY_CHECKED]: ''});
  }

  render() {
    const {selectMenuOpen} = this.state;

    const props = {
      ownStyles: this.props.ownStyles,
      ownStylesDefault: this.props.ownStylesDefault,
      handlerUpdate: this.props.handlerUpdate,
    };

    const inactive =
      [false, ''].indexOf(props.ownStyles[KEY_NOT_CHECKED] || false) >= 0
      && [false, ''].indexOf(props.ownStyles[KEY_CHECKED] || false) >= 0
    ;

    return (
      <Row className={classnames({
        'py-1 px-3 mb-0 align-items-center': true,
        'inactive': inactive && !selectMenuOpen,
      })}>
        <label className="col col-4 mb-0 d-flex align-items-center"><span>Ikony wyboru odpowiedzi w pytaniach jednokrotnego wyboru</span> {!inactive && <i className="icon-clear-value fas fa-trash ml-0" onClick={() => this.clearValues()} />}</label>
        <Col className="col-8">
          <Row>
            <Col xs={6} id="key-radio-notChecked">
              <SelectValue
                {...props}
                keyName={KEY_NOT_CHECKED}
                options={[
                  {label: <i className="far fa-circle" /> , value: "'\\F111'"},
                  {label: <i className="far fa-square" /> , value: "'\\F0C8'"},
                  {label: 'Brak ikony' , value: 'none'},
                ]}
                handleMenuOpen={() => this.setState({selectMenuOpen: true})}
                handleMenuClose={() => this.setState({selectMenuOpen: false})}
              />
              <Tooltip
                delay={0}
                placement="top"
                isOpen={this.state.tooltip.radioNotChecked}
                target={'key-radio-notChecked'}
                toggle={() => this.toggleTooltip('radioNotChecked')}
              >
                Odpowiedź nie wybrana
              </Tooltip>
            </Col>
            <Col xs={6} id="key-radio-checked">
              <SelectValue
                {...props}
                keyName={KEY_CHECKED}
                options={[
                  {label: <i className="far fa-dot-circle" /> , value: "'\\F192'"},
                  {label: <i className="far fa-check-square" /> , value: "'\\F14A'"},
                  {label: 'Brak ikony' , value: 'none'},
                ]}
                handleMenuOpen={() => this.setState({selectMenuOpen: true})}
                handleMenuClose={() => this.setState({selectMenuOpen: false})}
              />
              <Tooltip
                delay={0}
                placement="top"
                isOpen={this.state.tooltip.radioChecked}
                target={'key-radio-checked'}
                toggle={() => this.toggleTooltip('radioChecked')}
              >
                Odpowiedź wybrana
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

Radio.propTypes = {};

export default Radio;