import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Input} from "reactstrap";
import _ from "lodash";
import {connect} from "react-redux";
import {cityProvinces} from "../../../../SurveyCollector/components/CollectorPanel/Tabs/CollectorPanelForm";

const SurveyFilterTextarea = props => {
  const [text, setText] = React.useState(props.filtersText);
  const timerRef = useRef(null);

  React.useEffect(() => {
    setText(props.filtersText);
  }, [props.filtersText]);

  React.useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      props.onChange(parseTextToFilters(text), text);
    }, 100);
  }, [text]);

  const parseTextToFilters = (text) => {
    let filters = text.split("\n").filter(filter => filter);

    filters = _.uniqBy(filters, function (filter) {
      return filter;
    });

    filters = filters.map(s => {
      let filter = {};

      if(s.match(/^(\[(\d+)\])?/g)){
        let match = [
          ...s.matchAll(/^(\[(\d+)\])?/g),
        ][0]
        filter.id = match[2];
        s = s.replace(match[0], '')
      }

      if(s.match(/^(\{S\})/g)){
        filter = {
          ...filter,
          ...parseSerialFilter(s.replace('{S}', '')),
        }
      }else if(s.match(/^(\{I\})/g)){
        filter = {
          ...filter,
          ...parseIterationFilter(s.replace('{I}', '')),
        }
      }else{
        const splitted = s.split(';');

        filter = {
          ...filter,
          type: 'conditional',
          name: splitted[0] || '',
          limit: 0,
          blocks: parseBlocks(splitted[1]) || [],
          actions: parseActions(splitted[2]) || [],
        }

        const regex = /(.+)(\[\d+\])+/g
        if(filter.name.match(regex)){
          const parsed = regex.exec(filter.name);
          const limit = parsed[2].replace('[', '').replace(']', '')
          filter.name = filter.name.replace(`[${limit}]`, '')
          filter.limit = parseInt(limit)
        }
      }

      return filter;
    });



    return filters || [];
  }

  const parseBlocks = (t) => {
    if(!t){
      return [];
    }

    let block = {
      children: [],
      maxCondsToMet: 0,
      minCondsToMet: 1,
      type: 'AND'
    }

    const blockSetting = [
      ...t.matchAll(/(\^(\d+)-(\d+)\s)+/g)
    ];
    if(blockSetting.length > 0){
      block.minCondsToMet = parseInt(blockSetting[0][2]);
      block.maxCondsToMet = parseInt(blockSetting[0][3]);
    }
    if(t.match(/(\^)+/g)){
      block.type = 'OR';
    }

    block.condsQuestion = t.split('^').at(-1).split(' ').at(-1).split('|').map(cond => parseCond(cond)).filter(c => c);

    return [
      block
    ];
  }

  const parseCond = (t) => {
    if(t.match(/Q(\d+)!?$/g)){
      const match = [
        ...t.matchAll(/Q(\d+)!?$/g)
      ][0];
      const qPosition = parseInt(match[1]);
      const question = props.questions[qPosition-1];
      return {
        check: 'visibility',
        question: question ? question.id : null,
        visibility: t.indexOf('!') < 0,
      }
    }else if(t.match(/Q(\d+)E?&?!?=(\d+,?)+/g)){
      const match = [
        ...t.matchAll(/Q(\d+)/g)
      ][0];
      const qPosition = parseInt(match[1]);
      const question = props.questions[qPosition-1];

      let cond = {
        check: 'detail',
        question: question ? question.id : null,
        detailType: t.indexOf('!') >= 0 ? 'notSelected' : 'selected',
        condType: t.indexOf('&') >= 0 ? 'and' : 'or',
        details: [],
        answers: [],
        columns: [],
        rows: [],
        excluding: {
          excludingType: 'selected',
          excludings: [],
        }
      };

      if(question){
        let cafetery = t.split('=').at(-1);
        if(t.indexOf('E') >= 0){
          cond.check = 'excluding';
          if('excludings' in question){
            cond.excluding = {
              excludingType: t.indexOf('!') >= 0 ? 'notSelected' : 'selected',
              excludings: cafetery.split(',').map(ePosition => {
                const excluding = question.excludings[parseInt(ePosition)-1];
                if(!excluding){
                  return false;
                }

                return excluding;
              }).filter(e => e)
            };
          }
        }else{
          if(cafetery.indexOf('-') >= 0){
            if('rows' in question && 'columns' in question){
              cond.details = cafetery.split(',').map(detail => {
                const d = detail.split('-');
                const row = question.rows[parseInt(d[0])-1];
                const column = question.columns[parseInt(d[1])-1];
                if(!row || !column){
                  return false;
                }

                return {
                  row,
                  column,
                };
              }).filter(d => d);
            }
          }else{
            if('answers' in question){
              cond.answers = cafetery.split(',').map(aPosition => {
                const answer = question.answers[parseInt(aPosition)-1];
                if(!answer){
                  return false;
                }

                return {
                  answer,
                }
              }).filter(a => a);
            }
          }
        }
      }

      return cond
    }else if(t.match(/^R=(.*)+/g)){
      t = t.replace(/^R=/g, '')

      let cond = {
        type: 'userProfile',
        condType: 'and',
        sexM: false,
        sexF: false,
        ageFrom: null,
        ageTo: null,
        cityTown: false,
        cityVillage: false,
        cityLmFrom: null,
        cityLmTo: null,
        cityProvinces: [],
      };

      t.split(',').forEach(c => {
        c = c.toLowerCase();
        if(c.match(/(płeć|plec)+\[(k|m)+\]/g)){
          if(c.match(/m+/g)){
            cond.sexM = true
          }
          if(c.match(/k+/g)){
            cond.sexF = true
          }
        }else if(c.match(/(wiek|lm)\[(-?\d+)+\]/g)){
          let valueFrom = null
          let valueTo = null

          let v = c.match(/(\d?\-?\d+)+/g)[0]
          if(v.indexOf('-') >= -1){
            if(v.indexOf('-') === 0){
              valueTo = parseInt(v.replace('-',''))
            }else{
              v = v.split('-')
              valueFrom = parseInt(v[0])
              valueTo = parseInt(v[1])
            }
          }else{
            valueFrom = parseInt(v)
          }

          if(c.match(/(wiek)+/g)){
            cond.ageFrom = valueFrom
            cond.ageTo = valueTo
          }else if(c.match(/(lm)+/g)){
            cond.cityLmFrom = valueFrom
            cond.cityLmTo = valueTo
          }
        }else if(c.match(/(woj)\[(.*)+\]/g)){
          let woj = c.replaceAll(/(woj\[)|(\])/g, '').toUpperCase().split('/')

          cond.cityProvinces = woj.map(wojName => {
            const k = cityProvinces.indexOf(wojName);
            if(k !== false){
              return cityProvinces[k]
            }

            return false
          }).filter(v => !!v)

        }else if(c === 'm'){
          cond.cityTown = true
        }else if(c === 'w'){
          cond.cityVillage = true
        }
      })

      return cond;
    }

    return false
  }

  const parseActions = (t) => {
    if(!t){
      return [];
    }
    return t.split('|').map(a => {
      try {
        if(a.match(/^((Q|P|B|S)\!?){1}=+(\d(,\d+)*)?/g)){
          let action = {
            actionType: "actionVisibility",
            visibility: a.indexOf('!') < 0,
          }
          let positions = a.match(/(\d(,\d+)*)+/g);
          if(positions){
            positions = positions[0].split(',');

            if(a.indexOf('Q') >= 0){
              action.type = 'questions';
              action.questions = positions.map(qPosition => {
                const q = props.questions[qPosition-1];
                return q ? q.id : false;
              }).filter(id => id)
            }else if(a.indexOf('P') >= 0){
              action.type = 'pages';
              action.pages = positions.map(pPosition => {
                const p = props.pages[pPosition-1];
                return p ? p.id : false;
              }).filter(id => id)
            }else if(a.indexOf('B') >= 0){
              action.type = 'blocks';
              action.blocks = positions.map(bPosition => {
                const b = props.blocks[bPosition-1];
                return b ? b.id : false;
              }).filter(id => id)
            }else if(a.indexOf('S') >= 0){
              action.type = 'sets';
              action.sets = positions.map(sPosition => {
                const s = props.sets[sPosition-1];
                return s ? s.id : false;
              }).filter(id => id)
            }
          }

          return action;
        }else if(a.match(/^(Q\d+(A|R|C)?\!?){1}=+(\d(,\d+)*)?/g)) {
          const qPosition = a.match(/(Q\d+)/g)[0].replace('Q', '');
          const question = props.questions[parseInt(qPosition-1)];

          let action = {
            actionType: "actionVisibility",
            type: 'questionAnswers', //questionRows, questionColumns
            visibility: a.indexOf('!') < 0,
            question: question ? question.id : null,
            rows: [],
            columns: [],
            answers: [],
          }

          if(question){
            let cafetery = a.match(/=(\d(,\d+)*)+/g);
            if(cafetery){
              cafetery = cafetery[0].replace('=', '').split(',');
            }else{
              cafetery = [];
            }
            if(a.indexOf('R') >= 0){
              action.type = 'questionRows';
              action.rows = cafetery.map(c => {
                return question.rows[c-1];
              }).filter(id => id)
            }else if(a.indexOf('C') >= 0){
              action.type = 'questionColumns';
              action.columns = cafetery.map(c => {
                return question.columns[c-1];
              }).filter(id => id)
            }else{
              action.answers = cafetery.map(c => {
                return question.answers[c-1];
              }).filter(id => id)
            }
          }

          return action;
        }else if(a.match(/(SO|END|JUMP)=?/g)) {
          let action = {
            actionType: "actionJump",
            type: 'question'
          }

          if(a.match(/(SO)+/g)) {
            action.status = 4;
            action.type = 'surveyEnd';
          }else if(a.match(/(END)+/g)) {
            action.status = 3;
            action.type = 'surveyEnd';
          }else if(a.match(/(JUMP)+=?(Q|P|B|S)?\d?/g)) {
            const position = a.match(/\d+/g);
            if(a.indexOf('=Q') >= 0){
              const question = props.questions[parseInt(position-1)];
              action.type = 'question';
              action.question = question ? question.id : null;
            }
            if(a.indexOf('=P') >= 0){
              const page = props.pages[parseInt(position-1)];
              action.type = 'page';
              action.page = page.id;
            }
            if(a.indexOf('=B') >= 0){
              const block = props.blocks[parseInt(position-1)];
              action.type = 'block';
              action.block = block.id;
            }
            if(a.indexOf('=S') >= 0){
              const set = props.sets[parseInt(position-1)];
              action.type = 'set';
              action.set = set.id;
            }
          }


          return action;
        }
      }catch(error){
        return {};
      }

      return {};
    }).filter(action => !!action.type)
  }

  const parseSerialFilter = t => {
    let filter = {
      type: 'serial',
      name: '',
      action: 1,
      hideQuestionToCondsMin: 0,
      questionFrom: {
        filterBy: "answers",
        question: null,
        answers: [],
        rows: [],
        columns: [],
      },
      questionTo: {
        filterBy: "answers",
        question: null,
      }
    };

    const x = t.split(';');
    filter.name = x[0] || '';

    //questionFrom
    if(x.length >= 2){
      let qf = x[1];
      if(qf.match(/^\{(\d)\}/g)){
        let match = [
          ...qf.matchAll(/^\{(\d)\}/g)
        ][0]
        console.log('match', match);
        filter.hideQuestionToCondsMin = parseInt(match[1]);
      }
      if(qf.match(/Q(\d+)(A|R|C)?/g)) {
        let match = [
          ...qf.matchAll(/Q(\d+)(A|R|C)?/g)
        ][0]
        const qPosition = parseInt(match[1]);
        const question = props.questions[qPosition-1];
        if (question) {
          filter.questionFrom.question = question.id;

          let cafetery = qf.match(/=(\d(,\d+)*)+/g);
          if (cafetery) {
            cafetery = cafetery[0].replace('=', '').split(',');
          } else {
            cafetery = [];
          }

          if (qf.indexOf('R') >= 0 && 'rows' in question) {
            filter.questionFrom.filterBy = 'rows';
            filter.questionFrom.rows = cafetery.map(r => {
              return question.rows[r - 1];
            }).filter(id => id)
          } else if (qf.indexOf('C') >= 0 && 'columns' in question) {
            filter.questionFrom.filterBy = 'columns';
            filter.questionFrom.columns = cafetery.map(c => {
              return question.columns[c - 1];
            }).filter(id => id)
          } else if ('answers' in question) {
            filter.questionFrom.filterBy = 'answers';
            filter.questionFrom.answers = cafetery.map(a => {
              return question.answers[a - 1];
            }).filter(id => id)
          }
        }
      }
    }


    //questionTo
    if(x.length >= 3){
      let qt = x[2];
      if(qt.match(/Q(\d+)(R|C|A)?/g)){
        let match = [
          ...qt.matchAll(/Q(\d+)/g)
        ][0]
        const qPosition = parseInt(match[1]);
        const question = props.questions[qPosition-1];
        if(question){
          filter.questionTo.question = question.id;

          if(qt.indexOf('R') >= 0 && 'rows' in question){
            filter.questionTo.filterBy = 'rows';
          }else if(qt.indexOf('C') >= 0 && 'columns' in question){
            filter.questionTo.filterBy = 'columns';
          }else if('answers' in question) {
            filter.questionTo.filterBy = 'answers';
          }
        }
      }

      if(qt.indexOf('!') >= 0){
        filter.action = 2;
      }
    }

    return filter
  }

  const parseIterationFilter = t => {
    let filter = {
      type: 'iterative',
      name: '',
      action: 1,
      hideQuestionToCondsMin: 0,
      questionFrom: {
        filterBy: "answers",
        question: null,
      },
      questionsTo: []
    };

    const x = t.split(';');
    filter.name = x[0] || '';

    //questionFrom
    if(x.length >= 2){
      let qf = x[1];
      if(qf.match(/Q(\d+)/g)){
        let match = [
          ...qf.matchAll(/Q(\d+)/g)
        ][0]
        const qPosition = parseInt(match[1]);
        const question = props.questions[qPosition-1];
        if(question){
          filter.questionFrom.question = question.id;

          let cafetery = qf.match(/=(\d(,\d+)*)+/g);
          if(cafetery){
            cafetery = cafetery[0].replace('=', '').split(',');
          }else{
            cafetery = [];
          }

          if('answers' in question) {
            filter.questionFrom.filterBy = 'answers';
            filter.questionFrom.answers = cafetery.map(a => {
              return question.answers[a-1];
            }).filter(id => id)
          }
        }
      }
    }

    //questionsTo
    if(x.length >= 3){
      filter.questionsTo = x[2].split('|').map(qt => {
        if(qt.match(/Q(\d+)/g)){
          let match = [
            ...qt.matchAll(/Q(\d+)/g)
          ][0]
          const qPosition = parseInt(match[1]);
          const question = props.questions[qPosition-1];

          let data = {
            filterBy: 'answers',
            question: question ? question.id : null,
          }

          if(question){
            if(qt.indexOf('R') >= 0 && 'rows' in question){
              data.filterBy = 'rows';
            }else if(qt.indexOf('C') >= 0 && 'columns' in question){
              data.filterBy = 'columns';
            }else if('answers' in question) {
              data.filterBy = 'answers';
            }
          }

          return data
        }

        return false;
      }).filter(qt => qt)
    }

    return filter
  }

  return (
    <Input
      readOnly={props.blocked}F
      type="textarea"
      rows={5}
      value={text}
      onChange={e => setText(e.target.value)}
    />
  );
};

SurveyFilterTextarea.defaultProps = {
  filtersText: '',
  blocked: false,
};

SurveyFilterTextarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  filtersText: PropTypes.string,
  blocked: PropTypes.bool,
};

function mapStateToProps(state) {
  let sets = [];
  let blocks = [];
  let pages = [];
  let questions = [];
  let sPositionInSurvey = 0;
  let bPositionInSurvey = 0;
  let pPositionInSurvey = 0;
  let qPositionInSurvey = 0;

  _.each(state.survey.structure.data.survey.setBlocks, setId => {
    sPositionInSurvey++;
    _.each(state.survey.structure.data.sets[setId].blockPages, blockId => {
      bPositionInSurvey++;

      _.each(state.survey.structure.data.blocks[blockId].pages, pageId => {
        pPositionInSurvey++;

        _.each(state.survey.structure.data.pages[pageId].questions, questionId => {
          qPositionInSurvey++;

          let q = state.survey.structure.data.questions[questionId];
          q.positionInSurvey = qPositionInSurvey;
          questions.push(q)
        })

        let p = state.survey.structure.data.pages[pageId];
        p.positionInSurvey = pPositionInSurvey;
        pages.push(p)
      });

      let b = state.survey.structure.data.blocks[blockId];
      b.positionInSurvey = bPositionInSurvey;
      blocks.push(b);
    });

    let s = state.survey.structure.data.sets[setId];
    s.positionInSurvey = sPositionInSurvey;
    sets.push(s);
  });

  return {
    sets,
    blocks,
    pages,
    questions,
  }
}

export default connect(mapStateToProps)(SurveyFilterTextarea);
