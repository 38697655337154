import update from 'immutability-helper';
import {SET, ADDED, UPDATED, DELETED, QUESTION_TO_DELETED, QUESTION_TO_ADDED, QUESTION_TO_UPDATED} from './actions';
import * as questionActions from "../questions/actions";
import _ from 'lodash';

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.filterIterative;

    case ADDED:
      return {
        ...state,
        [action.filterIterative.id]: action.filterIterative
      };

    case UPDATED:
      return update(state, {
        [action.filterIterative.id]: {
          $set: action.filterIterative
        }
      });

    case questionActions.DELETED:
      _.each(state, filter => {
        filter.questionsTo = _.filter(filter.questionsTo, questionTo => questionTo.question !== action.question.id);
      });
      return state;

    case DELETED:
      return update(state, {
        $unset: [action.filterIterative.id]
      });

    case QUESTION_TO_DELETED:
      const filterIterative = _.find(state, filter => _.find(filter.questionsTo, questionTo => questionTo.id === action.questionTo.id));

      return update(state, {
        [filterIterative.id]: {
          questionsTo: {
            $set: _.filter(filterIterative.questionsTo, questionTo => questionTo.id !== action.questionTo.id)
          }
        }
      });

    case QUESTION_TO_ADDED:
      return update(state, {
        [action.filterIterativeId]: {
          questionsTo: {
            $set: [..._.find(state, filter => filter.id === action.filterIterativeId).questionsTo, action.questionTo]
          }
        }
      });

    case QUESTION_TO_UPDATED:
      let questionsTo = _.find(state, filter => filter.id === action.filterIterativeId).questionsTo;
      let questionTo = _.find(questionsTo, questionTo => questionTo.id === action.oldQuestionToId);
      questionsTo[questionsTo.indexOf(questionTo)] = action.questionTo;

      return update(state, {
        [action.filterIterativeId]: {
          questionsTo: {
            $set: questionsTo
          }
        }
      });

    default:
      return state;
  }
};