import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import SliderValue from "../components/SliderValue";
import ButtonNext from "./ButtonNext";
import ButtonPrev from "./ButtonPrev";
import ButtonClear from "./ButtonClear";
import {isBrowser} from "react-device-detect";
import NextStatement from "./nextStatement";
import PrevStatement from "./prevStatement";

class Navigation extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(){
    if(!document.getElementById('color-picker')){
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  render() {
    console.log('r')
    const {isOpen} = this.state;

    const props = {
      ownStyles: this.props.ownStyles,
      ownStylesDefault: this.props.ownStylesDefault,
      handlerUpdate: this.props.handlerUpdate,
    };

    return (
      <Dropdown toggle={this.toggle} isOpen={isOpen} inNavbar>
        <DropdownToggle className="py-3"><i className="fas fa-arrow-circle-right" /></DropdownToggle>
        {isOpen && <DropdownMenu className="p-2" style={{
          height: isBrowser ? 'auto' : window.innerHeight - 100,
          width: isBrowser ? 650 : window.innerWidth,
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}>
          <div className="d-block">
            <h3 className="text-center">Nawigacja ankiety</h3>
            <Button color="" onClick={(e) => this.toggle(e)} className="ml-auto px-3" style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}><i className="fas fa-times mt-1"/> </Button>
          </div>
          <hr className="my-0 mb-3"/>
          <SliderValue
            {...props}
            keyName="$survey-nav-fontSize"
            label="Wielkość czcionki"
            minValue={8}
            maxValue={35}
            stepValue={1}
          />
          <hr className="my-0"/>
          <ButtonNext {...props} />
          <hr className="my-0"/>
          <ButtonPrev {...props} />
          <hr className="my-0"/>
          <ButtonClear {...props} />
          <hr className="my-0"/>
          <NextStatement {...props} />
          <hr className="my-0"/>
          <PrevStatement {...props} />
          <hr className="my-0"/>
        </DropdownMenu>}
      </Dropdown>
    );
  }
}

Navigation.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  ownStyles: PropTypes.object,
  ownStylesDefault: PropTypes.object,
};

export default Navigation;
