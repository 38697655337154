import api from "api";

export const REQUEST = 'Survey/data/filterIterative/REQUEST';
export const SUCCESS = 'Survey/data/filterIterative/SUCCESS';
export const ERROR = 'Survey/data/filterIterative/ERROR';

export const SET = 'Survey/data/filterIterative/SET';
export const ADDED = 'Survey/data/filterIterative/ADDED';
export const UPDATED = 'Survey/data/filterIterative/UPDATED';
export const DELETED = 'Survey/data/filterIterative/DELETED';
export const QUESTION_TO_DELETED = 'Survey/data/filterIterative/QUESTION_TO_DELETED';
export const QUESTION_TO_ADDED = 'Survey/data/filterIterative/QUESTION_TO_ADDED';
export const QUESTION_TO_UPDATED = 'Survey/data/filterIterative/QUESTION_TO_UPDATED';

export function setFilterIterative(filterIterative) {
  return {
    type: SET,
    filterIterative
  }
}

export function filterIterativeAdded(filterIterative) {
  return {
    type: ADDED,
    filterIterative
  }
}

export function filterIterativeUpdated(filterIterative) {
  return {
    type: UPDATED,
    filterIterative
  }
}

export function filterIterativeDeleted(filterIterative) {
  return {
    type: DELETED,
    filterIterative
  }
}

export function questionToDeleted(questionTo) {
  return {
    type: QUESTION_TO_DELETED,
    questionTo
  }
}


export function questionToAdded(questionTo, filterIterativeId) {
  return {
    type: QUESTION_TO_ADDED,
    questionTo,
    filterIterativeId
  }
}

export function questionToUpdated(questionTo, filterIterativeId, oldQuestionToId) {
  return {
    type: QUESTION_TO_UPDATED,
    questionTo,
    filterIterativeId,
    oldQuestionToId
  }
}

export function addFilterIterative(data) {
  return dispatch => {
    return api.filterIterative.post(data).then(filterIterative => {
      dispatch(filterIterativeAdded(filterIterative))
    })
  }
}

export function copyFilterIterative(id) {
  return dispatch => {
    return api.filterIterative.copy(id).then(filterIterative => {
      dispatch(filterIterativeAdded(filterIterative))
    })
  }
}

export function updateFilterIterative(data) {
  return dispatch => {
    return api.filterIterative.patch(data).then(filterIterative => {
      dispatch(filterIterativeUpdated(filterIterative));
    })
  }
}

export function deleteFilterIterative(data) {
  return dispatch => {
    return api.filterIterative.delete(data).then(() => {
      dispatch(filterIterativeDeleted({
        id: data.id,
      }))
    })
  }
}

export function deleteQuestionTo(data) {
  return dispatch => {
    return api.filterIterative.questionTo.delete(data).then(() => {
      dispatch(questionToDeleted({
        id: data.id,
      }))
    })
  }
}

export function addQuestionTo(data) {
  return dispatch => {
    return api.filterIterative.questionTo.post(data).then(questionTo => {
      dispatch(questionToAdded(questionTo, data.filterIterativeId))
    })
  }
}

export function updateQuestionTo(data) {
  return dispatch => {
    return api.filterIterative.questionTo.patch(data).then(questionTo => {
      dispatch(questionToUpdated(questionTo, data.filterIterativeId, data.data.id));
    })
  }
}