import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import SurveyBgImg, {KEY as SurveyBgImg_KEY} from "./SurveyBgImg";
import SurveyBgSize from "./SurveyBgSize";
import SurveyFontFamily from "./SurveyFontFamily";
import BgColor from "../components/BgColor";
import SliderValue from "../components/SliderValue";
import InvitationText from "./InvitationText";
import EndingText from "./EndingText";
import {isBrowser} from "react-device-detect";

class General extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(){
    if(!document.getElementById('color-picker') && !document.getElementById('patterns-modal')){
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  render() {
    const {isOpen} = this.state;

    const props = {
      ownStyles: this.props.ownStyles,
      ownStylesDefault: this.props.ownStylesDefault,
      handlerUpdate: this.props.handlerUpdate,
    };

    return (
      <Dropdown toggle={this.toggle} isOpen={isOpen} inNavbar>
        <DropdownToggle className="py-3"><i className="fas fa-cogs" /></DropdownToggle>
        {isOpen && <DropdownMenu className="p-2" style={{
          height: isBrowser ? 'auto' : window.innerHeight - 100,
          width: isBrowser ? 650 : window.innerWidth,
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}>
          <div className="d-block">
            <h3 className="text-center">Ogólny wygląd</h3>

            <Button color="" onClick={(e) => this.toggle(e)} className="ml-auto px-3" style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}><i className="fas fa-times mt-1"/> </Button>
          </div>
          <hr className="my-0 mb-3"/>
          <SliderValue
            {...props}
            keyName="$survey-width"
            label="Szerokość ankiety"
            minValue={300}
            maxValue={2000}
            stepValue={50}
          />
          <hr className="my-0 mb-2"/>
          <SurveyFontFamily {...props} />
          <hr className="my-0"/>
          <BgColor
            {...props}
            keyName="$survey-bgColor"
            label="Kolor tła"
          />
          <hr className="my-0 mb-2"/>
          <SliderValue
            {...props}
            keyName="$survey-bgColorOpacity"
            label="Widoczność formularza"
            minValue={0}
            maxValue={100}
            stepValue={1}
            measure="%"
            percentage
          />
          <hr className="my-0"/>
          <SurveyBgImg {...props} />
          <hr className="my-0"/>
          {(
            (this.props.ownStyles[SurveyBgImg_KEY] && this.props.ownStyles[SurveyBgImg_KEY] !== 'none')
            || (!this.props.ownStyles[SurveyBgImg_KEY] && this.props.ownStylesDefault[SurveyBgImg_KEY] && this.props.ownStylesDefault[SurveyBgImg_KEY] !== 'none')
          ) && <React.Fragment>
            <SurveyBgSize {...props} styleKey='$survey-bgSize' label="Rozmiar zdjęcia w tle - desktop"/>
            <hr className="my-0"/>
            <SurveyBgSize {...props} styleKey='$survey-bgSize-mobile' label="Rozmiar zdjęcia w tle - mobile"/>
            <hr className="my-0"/>
          </React.Fragment>}
          <BgColor
            {...props}
            keyName="$survey-header-bgColor"
            label="Kolor tła w nagłówku i stopce"
          />
          <hr className="my-0"/>
          <InvitationText {...props} />
          <hr className="my-0"/>
          <EndingText {...props} />
        </DropdownMenu>}
      </Dropdown>
    );
  }
}

General.propTypes = {
  handlerUpdate: PropTypes.func.isRequired,
  ownStyles: PropTypes.object,
  ownStylesDefault: PropTypes.object,
};

export default General;
