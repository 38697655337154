import {SET, UPDATED} from './actions';
import update from "immutability-helper";

const initialState = [];

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.survey;

    case UPDATED:
      return update(state, {
        $merge: action.survey
      });

    default:
      return state;
  }
};