import update from 'immutability-helper';
import {DELETED, SET, ADDED, UPDATED} from './actions';

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET:
      return action.quotaNews;

    case DELETED:
      return update(state, {
        $unset: [action.quotaNew.id]
      });

    case ADDED:
      return {
        ...state,
        [action.quotaNew.id]: action.quotaNew
      };

    case UPDATED:
      return update(state, {
        [action.quotaNew.id]: {
          $set: action.quotaNew
        }
      });

    default:
      return state;
  }
};
